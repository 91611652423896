import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isNil} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import WhiteButton from '../../../button/WhiteButton';
import {deviceService} from '../../../../services';
import DevicePowerIcon from '../../../device/DevicePowerIcon';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import NumberInput from '../../../input/NumberInput';
import {getErrorMessage} from '../../../../helper/responseHandler';


const SETTING_TYPE = {
    SIGNAGE_ID_SHOW: 'SIGNAGE_ID_SHOW', 
    SIGNAGE_ID_HIDE: 'SIGNAGE_ID_HIDE', 
    SIGNAGE_ID_AUTOSET: 'SIGNAGE_ID_AUTOSET'
};

const ScanPopupContent = ({onChange}) => {
    const {t} = useTranslation();
    const [childCount, setChildCount] = useState(0);

    const onChangeCount = (e, value) => {
        setChildCount(value);
        onChange(e, value);
    };

    useEffect(() => {
        onChange(null, childCount);
    }, []);

    return (
        <>
            <div className='mb10'>
                {t('MIS_SID_SERVER_CASEP_SCAN_SECONDARY_SIGNAGE_CONNECTED')}
            </div>
            <div className='mt12'>
                <span className='float_l mr8'>{t('MIS_SID_SERVER_CASEP_NUM_OF_SECONDARY_DEVICES')}</span>
                <span><NumberInput value={childCount} onChange={onChangeCount} min={0} max={16} forceInt /></span>
            </div>
        </>
    );
};

const ConnectedDeviceDetail = ({device}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [slaves, setSlaves] = useState([]);
    const childScanCountRef = useRef(0);

    const onScan = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            height: 250,
            title: t('MIS_SID_SCAN_DEVICE'),
            yesTitle: t('COM_TV_SID_SCAN'),
            noTitle: t('BUTTON_CANCEL_P'),
            message: <ScanPopupContent onChange={(e, value) => childScanCountRef.current = value} />,
            useMessageLine: false,
            onClickYes: () => {
                if(isNil(childScanCountRef.current)) {
                    toastr.warning(t('MIS_SID_SERVER_CASEP_ENTER_NUM_OF_SECONDARY_DEVICES'));
                    return;
                }
                deviceService.scanChildDevices(device.deviceId, childScanCountRef.current).then(res => {
                    toastr.success(t('MIS_SID_SACN_STARTED_TAK_WHILE_DEPENDING_CONNECTION'));
                    dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                }).catch(error => toastr.error(getErrorMessage(error)));
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));
    };

    const onIdAutoSet = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('MIS_SID_ID_AUTO_SET'),
            message: t('MIS_SID_DEVICE_ID_ASSIGNED_AGAIN_LAYOUT_CHANGE'),
            onClickYes: () => {
                deviceService.updateSignageSetting(device.deviceId, SETTING_TYPE.SIGNAGE_ID_AUTOSET).then(res => {
                    toastr.success(t('MIS_SID_SETTINGS_AUTO_ID_STARTED_CONNECTION'));
                    dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                }).catch(error => toastr.error(getErrorMessage(error)));
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));
    };

    const onShowLayout = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.VWL_LAYOUT,
            type: commonConstants.VWL_LAYOUT,
            layoutType: 'SIGNAGE',
            deviceId: device.deviceId,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.VWL_LAYOUT))
        }));
    };

    const onShowId = () => {
        deviceService.updateSignageSetting(device.deviceId, SETTING_TYPE.SIGNAGE_ID_SHOW).then(res => {
            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    const onHideId = () => {
        deviceService.updateSignageSetting(device.deviceId, SETTING_TYPE.SIGNAGE_ID_HIDE).then(res => {
            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    useEffect(() => {
        deviceService.fetchSignageSlaveInfo(device.deviceId).then(res => {
            setSlaves(res.items);
        }).catch(error => toastr.error(getErrorMessage(error)));
    }, [device.deviceId]);

    // html += '<tr><td>'+value.childId+'</td>';
	// 				if(value.isConnected==true)
	// 				html += '<td class="align_c"><span class="cir_icon on"></span></td>';
	// 			else
	// 				html += '<td class="align_c"><span class="cir_icon off"></span></td>';
	// 			html += '<td>'+value.panelStatus+'</td><td>'+value.inputSource+'</td><td>'+value.lastScannedTime+'</td>';
    return (
        <>
        <div className='detail_view mini_5 mt15'>
            <div className="pop_list" style={{height: 328, overflowX: 'auto'}}>
                <table>
                    <colgroup>
                        <col width="39px"/>
                        <col width="81px"/>
                        <col width="54px"/>
                        <col width="106px"/>
                        <col width="169px"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('DID_SCHEDULE_ID')}</th>
                            <th>{t('TABLE_POWER_P')}</th>
                            <th>{t('MIS_SID_DISPLAY_STATUS')}</th>
                            <th>{t('COM_DID_LFD_SOURCE')}</th>
                            <th>{t('MIS_SID_VIEW_DEVICE_TIME')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            slaves.map(slave => {
                                return (
                                    <tr key={slave.childId}>
                                        <td>{slave.childId}</td>
                                        <td><DevicePowerIcon power={slave.connected} /></td>
                                        <td>{slave.panelStatus === '-' ? slave.panelStatus : t(slave.panelStatus)}</td>
                                        <td>{slave.inputSource}</td>
                                        <td>{slave.lastScannedTime === 'MIS_SID_NOT_SCANNED_YET' ? t(slave.lastScannedTime) : slave.lastScannedTime}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="popup_btn_wrap clearfix" style={{margin:0}}>
                <div className="mb10">
                    <WhiteButton id='scanBtn' name={t('MIS_SID_SCAN_DEVICE')} style={{marginRight: '8px'}} onClick={onScan} />
                    <WhiteButton id='idAutoSetBtn' name={t('MIS_SID_ID_AUTO_SET')} style={{marginRight: '8px'}} onClick={onIdAutoSet} />
                    <WhiteButton id='showVWLLayoutBtn' name={t('MIS_BUTTON_SHOW_LAYOUT_P')} onClick={onShowLayout} />
                </div>
                <div>
                    <WhiteButton id='showIDbtn' name={t('COM_MIS_SID_SHOW_ID')} style={{marginRight: '8px'}} onClick={onShowId} />
                    <WhiteButton id='hideIDbtn' name={t('MIS_SID_HIDE_ID')} onClick={onHideId} />
                </div>
            </div>
        </div>
        </>
    );
};

export default ConnectedDeviceDetail;