import React from 'react';
import {connect, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import {popupAction, settingsAction} from "../../actions";
import MyInformation from './MyInformation';

const MyInformationManager = (props) => {

    const {data, mode, changed, password} = useSelector(state => ({
        data : state.settings.myAccount.data,
        changed : state.settings.myAccount.changed,
        mode : state.settings.myAccount.mode,
        password : state.settings.myAccount.password
    }));

    return (
        <MyInformation {...props} myinfo={data} viewmode={mode} changedData={changed} password={password}/>
    )

}
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadMyAccount: (myAccount) =>  dispatch(settingsAction.loadMyAccount(myAccount))
    })
)(withTranslation()(MyInformationManager));
