import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../radio/RadioGroup";
import "./IpAddress.css";


const IpAddress = ({ handleValues, handleSign, orgValues, orgSign }) => {
    const [eqValue, setEqValue] = useState(orgSign === "eq" && orgValues.length ? orgValues[0].value : "");
    const [rgValue, setRgValue] = useState(orgSign === "rg" && orgValues.length ?
        {
            start: orgValues[0].start,
            end: orgValues[0].end
        } : {
            start: "",
            end: ""
        });
    const [sign, setSign] = useState(orgSign ? orgSign : "eq");
    const [showInfo, setShowInfo] = useState(false);

    const { t } = useTranslation();
    const onChangeSign = (e, value) => {
        setSign(value);
        handleSign(value);
        if (value === "eq") {
            handleValues([
                {
                    value: eqValue
                }
            ]);
        } else if (value === "rg") {
            handleValues([rgValue]);
        }
    };

    const onChangeEqValue = e => {
        setEqValue(e.target.value);
        handleValues([
            {
                value: e.target.value
            }
        ]);
    };

    const onChangeRgStartValue = e => {
        const newValue = {
            ...rgValue,
            start: e.target.value
        };
        setRgValue(newValue);
        handleValues([newValue]);
    };

    const onChangeRgEndValue = e => {
        const newValue = {
            ...rgValue,
            end: e.target.value
        };
        setRgValue(newValue);
        handleValues([newValue]);
    };
    return (
        <table className="dev_ip">
            <tr id="operator">
                <th
                    style={{ width: "130px", verticalAlign: "top", paddingTop: "10px" }}
                >
                    {t("MIS_SID_OPERATOR")}
                </th>
                <td>
                    <RadioGroup
                        propertyName={"sign"}
                        selects={[
                            { title: t("MIS_SID_EQUAL"), value: "eq" },
                            { title: t("MIS_SID_WEBAUTHOR_RANGE"), value: "rg" }
                        ]}
                        value={sign}
                        onChange={onChangeSign}
                        multiLang={true}
                    />
                </td>
            </tr>
            <tr>
                <th>{t("COM_SID_IP_ADDRESS")}</th>
                <td>
                    {sign === "eq" && (<>
                        <input
                            type="text"
                            id="name"
                            className="ip_address"
                            style={{ width: "140px" }}
                            value={eqValue}
                            onChange={onChangeEqValue}
                        />
                        <div className="ruleset_condition_quest_img_noti">                          
                            <a href="#" class="ipaddress_tooltip_icon"
                                onMouseEnter={()=>setShowInfo(true)} onMouseLeave={()=>setShowInfo(false)}/>
                            <div className="ipadress_tooltip_message" style={{display: showInfo ? '' : 'none'}}>
                                {t("MIS_SID_YOU_CAN_USE_THE_STAR_SYMBOL")}
                                {<br/>}
                                {t("MIS_SID_EXAMPLE_HUNDRED")}                     
                            </div>
                        </div>
                    </>)}
                    {sign === "rg" && (
                        <div>
                            <input
                                type="text"
                                id="start"
                                className="ip_address"
                                style={{ width: "140px" }}
                                value={rgValue.start}
                                onChange={onChangeRgStartValue}
                            />
                            {" - "}
                            <input
                                type="text"
                                id="end"
                                className="ip_address"
                                style={{ width: "140px" }}
                                value={rgValue.end}
                                onChange={onChangeRgEndValue}
                            />
                        </div>
                    )}
                </td>
            </tr>
        </table>
    );
};

export default IpAddress;
