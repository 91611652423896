import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {statisticsService} from '../../services';
import {connect} from 'react-redux';
import './Statistics.css';
import './ApprovedDevice.css';
import '../../components/table/react-table.css';
import SelectBox from '../../components/selectbox/SelectBox';
import {StatisticsCalendar} from './StatisticsCalendar';
import {TableWithBar} from './TableWithBar';
import Moment from 'moment';
import {chartColor, color} from '../../constants/StatisticsConstants';
import {toastr} from 'helper/toastrIntercept';
import fileDownload from "js-file-download";
import {optionStackBar} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

const ContentType = ({currContent}) => {
    const {t} = useTranslation();

    const user = JSON.parse(localStorage.getItem('user'));

    const timeOptions = [
        {value: 'aWeekAgo', title: 'COM_MESSAGE_STATISTICS_MENU_A_WEEK_AGO_P'},
        {value: 'aMonthAgo', title: 'MESSAGE_STATISTICS_MENU_A_MONTH_AGO_P'},
        {value: 'threeMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_THREE_MONTH_AGO_P'},
        {value: 'sixMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_SIX_MONTH_AGO_P'},
        {value: 'aYearAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_A_YEAR_AGO_P'},
        {value: 'custom', title: 'COM_SID_CUSTOM'},
    ];

    const initEndDate = new Date();
    const initStartDate = new Date();
    initStartDate.setDate(initEndDate.getDate() - 6);
    const [period, setPeriod] = useState({time: timeOptions[0].value, startDate: initStartDate, endDate: initEndDate});
    const [typeData, setTypeData] = useState({
        totalTypeCount: 0, totalTypeItemCount: 0, typeTable: [], typeChart: {
            labels: [],
            datasets: [{
                backgroundColor: chartColor(1),
                borderWidth: 1,
                data: [],
            }],
        }
    });
    const [dateData, setDateData] = useState({
        totalDateCount: 0, totalDateItemCount: 0, dateTable: [], dateChart: {
            labels: [],
            datasets: [{
                backgroundColor: chartColor(1),
                borderWidth: 1,
                data: [],
            }],
        }
    });
    const [request, setLastRequest] = useState({
        startDate: undefined,
        endDate: undefined,
    });
    const [loadingbar, setLoadingBar] = useState(false);

    const fetchData = () => {
        setLoadingBar(true);
        let startDate = Moment(period.startDate).format('YYYY-MM-DD');
        let endDate = Moment(period.endDate).format('YYYY-MM-DD');
        Promise.all([
            statisticsService.fetchStatisticsContents({
                data: 'countByType',
                format: 'periodTable',
                startDate: startDate,
                endDate: endDate,
            }),
            statisticsService.fetchStatisticsContents({
                data: 'countByDate',
                format: 'typeTable',
                startDate: startDate,
                endDate: endDate,
            }),
            statisticsService.fetchStatisticsContents({
                data: 'countByDate',
                format: 'typeChart',
                startDate: startDate,
                endDate: endDate,
            }),
        ]).then(res => {
            let contentTypeData = res[0].items;
            let typeChart = {
                labels: [],
                datasets: [{
                    backgroundColor: chartColor(1),
                    borderWidth: 1,
                    data: [],
                }],
            };
            if (contentTypeData.length > 100) {
                toastr.warning(t('MIS_SID_20_THERE_IS_TOO_MUCH_DATA_TO_GENERATE_A_CHART'));
            } else {
                typeChart = {
                    labels: contentTypeData && contentTypeData.length > 0 ? contentTypeData.map((item) => (item.type)) : [],
                    datasets: contentTypeData && contentTypeData.length > 0 ?
                        [{
                            data: contentTypeData.map((item) => (item.count)),
                            backgroundColor: chartColor(contentTypeData.length),
                        }] : [{
                            backgroundColor: chartColor(1),
                            borderWidth: 1,
                            data: [],
                        }],
                };
            }
            setTypeData({
                ...typeData,
                typeChart: typeChart,
                typeTable: contentTypeData && contentTypeData.length > 0 ? contentTypeData.map((item) => ({col1: item.type, col2: item.count})) : [],
                totalTypeCount: contentTypeData && contentTypeData.length > 0 ? contentTypeData.reduce((pre, value) => pre + (Number(value['count']) || 0), 0) : 0,
                totalTypeItemCount: contentTypeData && contentTypeData.length > 0 ? contentTypeData.length : 0,
            });
            let contentDateTableData = res[1].items;
            let contentDateChartData = res[2].items;
            let dateChart = {
                labels: [],
                datasets: [{
                    backgroundColor: chartColor(1),
                    borderWidth: 1,
                    data: [],
                }],
            };
            if (contentDateChartData.length > 100) {
                toastr.warning(t('MIS_SID_20_THERE_IS_TOO_MUCH_DATA_TO_GENERATE_A_CHART'));
            } else {
                let contentChartDatas = [];
                if (contentDateChartData && contentDateChartData.length > 0) {
                    contentDateChartData[0].countListByContentType.forEach(content => {
                        let findContentChartData = contentChartDatas.find(contentChartData => contentChartData.type === content.contentType);
                        !findContentChartData && contentChartDatas.push({
                            type: content.contentType,
                            counts: [],
                        });
                    });
                }
                contentDateChartData.forEach(data => {
                    data.countListByContentType.forEach((dataContent, index) => {
                        if (index < contentChartDatas.length) {
                            let findContentChartData = contentChartDatas.find(contentChartData => contentChartData.type === dataContent.contentType);
                            findContentChartData && findContentChartData.counts.push(dataContent.count);
                        }
                    });
                });
                dateChart = {
                    labels: contentDateChartData && contentDateChartData.length > 0 ? contentDateChartData.map((data) => (data.date)) : [],
                    datasets: contentChartDatas && contentChartDatas.length > 0 ?
                        contentChartDatas.map((content, index) => {
                            return {
                                type: 'bar',
                                label: content.type,
                                data: content.counts,
                                backgroundColor: color[index % color.length],
                            }
                        }) : [],
                };
            }
            setDateData({
                ...dateData,
                dateChart: dateChart,
                dateTable: contentDateTableData && contentDateTableData.length > 0 ? contentDateTableData.map((item) => ({col1: item.date, col2: item.type + ' : ' + item.count})) : [],
                totalDateCount: contentDateTableData && contentDateTableData.length > 0 ? contentDateTableData.reduce((pre, value) => pre + (Number(value['count']) || 0), 0) : 0,
                totalDateItemCount: contentDateTableData && contentDateTableData.length > 0 ? contentDateTableData.length : 0,
            });
            setLastRequest({
                ...request,
                startDate: startDate,
                endDate: endDate,
            });
            setLastRequest({
                ...request,
                startDate: startDate,
                endDate: endDate,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onClickExport = (docType) => {
        setLoadingBar(true);
        const {startDate, endDate} = request;
        statisticsService.fetchStatisticsContentsFileDownload(docType, {
            data: "count",
            startDate: startDate,
            endDate: endDate,
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    const onClickDateOption = (time) => {
        let startDate;
        let endDate;
        if (time === 'custom') {
            startDate = period.startDate;
            endDate = period.endDate;
        } else {
            startDate = new Date();
            endDate = new Date();
            switch (time) {
                case 'aWeekAgo':
                    startDate.setDate(endDate.getDate() - 7);
                    break;
                case 'aMonthAgo':
                    startDate.setMonth(endDate.getMonth() - 1);
                    break;
                case 'threeMonthAgo':
                    startDate.setMonth(endDate.getMonth() - 3);
                    break;
                case 'sixMonthAgo':
                    startDate.setMonth(endDate.getMonth() - 6);
                    break;
                case 'aYearAgo':
                    startDate.setFullYear(endDate.getFullYear() - 1);
                    break;
                default:
                    break;
            }
        }
        setPeriod({time, startDate, endDate});
    };

    const {totalTypeCount, totalTypeItemCount, typeTable, typeChart} = typeData;
    const {totalDateCount, totalDateItemCount, dateTable, dateChart} = dateData;
    return (
        <Fragment>
            <div className="contents_buttonWrap" style={{display: currContent === 'CONTENT_TYPE' ? 'block':'none'}}>
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => onClickExport('EXCEL')}/>
                </div>
            </div>
            <div className="statistics_list mt10" style={{overflowX: 'auto', height:"calc(100% - 87px)",display: currContent === 'CONTENT_TYPE' ? 'block':'none'}}>
                <div className="contents_buttonWrap">
                    <div style={{width: '669px', height: 'inherit'}}>
                        <span className='statistics_period float_l'>{t('TEXT_PERIOD_P')}</span>
                        <SelectBox defaultTitle={t('MESSAGE_UPLOADER_SEL_PERIOD_P')} multiLang={true} classname='float_l mr8' width={209}
                                   selects={timeOptions} onClick={onClickDateOption}/>
                        <StatisticsCalendar date={period}
                                            disabled={period.time !== 'custom'}
                                            dateFormat={user ? user.dateFormat : "yyyy-MM-dd"}
                                            onChangeStart={(startDate) => {
                                                if (Moment(startDate).isBefore(Moment(period.endDate))) {
                                                    setPeriod({...period, startDate})
                                                } else {
                                                    let endDate = startDate;
                                                    setPeriod({...period, startDate, endDate});
                                                }
                                            }}
                                            onChangeEnd={(endDate) => {
                                                if (Moment(period.startDate).isBefore(Moment(endDate))) {
                                                    setPeriod({...period, endDate})
                                                } else {
                                                    let startDate = endDate;
                                                    setPeriod({...period, startDate, endDate});
                                                }
                                            }}/>
                        <button className="base float_l w72" onClick={fetchData}>{t('BUTTON_SEARCH')}</button>
                    </div>
                </div>
                <TableWithBar title={t('MESSAGE_STATISTICS_SUMMARY_CONTENT_TITLE_P')}
                              col1={t('TABLE_APPROVAL_TYPE_P')}
                              col2={t('TABLE_CONTENT_COUNT_P')}
                              tableWidth={'290px'}
                              tableData={typeTable}
                              chartData={typeChart}
                              totalCount={totalTypeCount}
                              totalItemCount={totalTypeItemCount}/>
                <TableWithBar title={t('MIS_SID_20_REGISTERED_CONTENT_BY_DATE')}
                              col1={t('TEXT_DATE_P')}
                              col2={t('TABLE_TYPE_P')}
                              tableWidth={'290px'}
                              tableData={dateTable}
                              chartData={dateChart}
                              totalCount={totalDateCount}
                              totalItemCount={totalDateItemCount}
                              barOption={optionStackBar(dateChart)}/>
            </div>
            {loadingbar && <LoadingBar />}
        </Fragment>
    );
};

export default connect()(ContentType);
