import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import MISDialog from "../MISDialog";
import './RoleUserListPopup.css';
import {userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {cloneDeep} from "lodash";
import {getErrorMessage} from "../../../helper/responseHandler";

class RoleUserListPopup extends Component {
    state = {
        users: [],
    };

    componentDidMount() {
        this.fetchCheckExistLdapSyncGroupList();
    }

    fetchCheckExistLdapSyncGroupList = () => {
        const {t, role} = this.props;
        userService.fetchUserRoleUserList(role.roleId).then((res) => {
            this.setState({
                users: cloneDeep(res.items.ids),
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    }

    render() {
        const {t, role} = this.props;
        const {users} = this.state;
        return (
            <MISDialog
                dialog={{
                    title: role.roleName + ' - ' + t('MIS_TEXT_SHORTCUT_USER_VIEW_P'),
                    closeOnEscape: true,
                    modal: true,
                    width: 340,
                    height: 250,
                    onClose: this.props.onClose,
                }}>
                {users && users.length > 0 ?
                    <div className='role_user_pop_wrap'>
                        <div className='role_user_detail_view user_info'>
                            <div className='role_user_list'>
                                <table>
                                    <tbody>
                                    {
                                        users.map((user, index) => <tr key={index}><th>{user}</th></tr>)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> :
                    <div>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
                }
            </MISDialog>
        );
    };
}
export default withTranslation()(RoleUserListPopup);