import React, {useImperativeHandle, useRef} from 'react'
import AddedContent from "./AddedContent";
import {useTranslation} from "react-i18next";
import './RandomInsertArea.css';

import DroppableSpace from "./DroppableSpace";
import {ItemTypes} from "./ItemTypes";
import {DropTarget} from "react-dnd";
import {convertAddedContent} from "./playlistUtil";
import {RANDOM} from "../../constants";

const RandomInsertArea = React.forwardRef(({
       randomContents, onDrop, onItemClick, effectClick, updateItem, checkList,
       deviceType, playlistType, onDelete, onSettingPopup, moveItem, settingButtonClick, connectDropTarget
   }, ref) => {

    const {t} = useTranslation();

    const elementRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current,
    }));

    return connectDropTarget(
        <div className={"random-insert animated ui-droppable droppable"}
             style={{overflowY: 'auto', height: 250, width: 'calc(100% - 27px)', display: 'flex', flexDirection: 'column', paddingTop: 15}}>
            <div style={{height: 30, display: 'flex', width: '100%'}}>
                <span className="float_l"><strong>{t("MIS_SID_RANDOM_INSERTS")}</strong></span>
                <span style={{paddingLeft: 10}} className="float_l">{t("MIS_SID_20_FILES_RANDOMLY_INSERTED_IN_GENERAL_PLAYLISTS")}</span>
            </div>
            <ul className="clearfix" style={{width: '100%', display: 'flex', flexFlow: 'wrap', alignContent: 'flex-start', paddingTop: 15}}>
                {
                    randomContents != null && randomContents.length > 0 ?
                        randomContents.map(
                            (content) => {
                                return (
                                    <div style={{display: 'flex', height: 'fit-content'}}>
                                        <DroppableSpace index={content.orderIndex} onDrop={onDrop} position={"prev"} moveItem={moveItem} mode={RANDOM}/>
                                        <AddedContent
                                            content={content}
                                            index={content.orderIndex}
                                            effectClick={() => {effectClick(content.orderIndex)}}
                                            deleteButtonClick={() => onDelete(content.orderIndex)}
                                            settingButtonClick={(item) => onSettingPopup(item, content.orderIndex)}
                                            itemClick={(content) => {onItemClick(content, content.orderIndex)}}
                                            mode={'RANDOM'}
                                            playlistType={playlistType}
                                            deviceType={deviceType}
                                            updateItem={updateItem}
                                            checkList={checkList}
                                        />
                                        <DroppableSpace index={content.orderIndex} onDrop={onDrop} position={"post"} moveItem={moveItem} mode={RANDOM}/>
                                    </div>
                                )
                            }
                        ) : ''
                }
            </ul>
        </div>
    )
});

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        dropResult: monitor.getDropResult(),
    }
}
export default DropTarget(
    ItemTypes.ContentItem,
    {
        drop(props, monitor, component) {
            monitor.isOver() && monitor.canDrop() && props.onDrop(convertAddedContent(monitor.getItem().dragItem), monitor.getItem().id, props.id);
        },
    }, collect)(RandomInsertArea);