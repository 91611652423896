import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService} from '../../services';
import {popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {toastr} from 'helper/toastrIntercept';
import {useTrGroupProps} from '../../helper/tables';
import {updateCache} from "../../helper/cache/tableCache";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const heightOffset = 204;
const defaultSort = {id: 'createDate', desc: true};

const WarningRule = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAllRef, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        deviceService.fetchDeviceWarningRules({
            startIndex: page * pageSize + 1,
            pageSize,
            searchText: keyword,
            // sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
            sortColumn: snakeCase(id),
        }).then(res => {
            if (res.items.length === 0 && page * pageSize + 1 > 1) {
                setFilter({...filter, page: page - 1})
            } else {
                setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
                updateCache('DEVICE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent);
            }
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [defaultSort]});
    };

    const updateDimensions = () => {
        setStyle({height: window.innerHeight - heightOffset});
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const searchPlaceHolder = t('MIS_SID_20_WARNING_RULE');

    const onSave = (requestBody) => {
        if(requestBody.ruleId) {
            deviceService.updateDeviceWarningRulesByRuleId(requestBody).then(res => {
                if(res.status === 'Success') {
                    toastr.success(t('MIS_TEXT_FTP_MODIFIED_P'));
                    dispatch(popupAction.closePopup(commonConstants.ADD_RULE));
                    fetchData();
                }
            })
            .catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
            });
        } else {
            deviceService.createDeviceWarningRules(requestBody).then(res => {
                if(res.status === 'Success') {
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                    dispatch(popupAction.closePopup(commonConstants.ADD_RULE));
                    fetchData();
                }
            })
            .catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
            });
        }

    };

    const onApplyGroups = (requestBody) => {
        deviceService.updateWarningRuleGroups(requestBody).then(res => {
            if(res.status === 'Success') {
                toastr.success(t('MIS_TEXT_FTP_MODIFIED_P'));
                fetchData();
            }
        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
        });

        dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
    };

    const onAdd = () => {
        openRulePopup();
    };

    const onDelete = () => {
        const deleteIds = checkBoxRefs.current.filter(checkBox => {
            return checkBox.checked === true;
        }).map(checkBox => checkBox.id);

        if(deleteIds.length > 0) {
            dispatch(popupAction.addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t('COM_BUTTON_CONFIRM'),
                message: t('COM_TV_SID_DO_YOU_WANT_TO_DELETE_THE_SELECTED_ITEMS_KR_SELECTED'),
                onClickYes: () => handleDelete(deleteIds),
                onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
            }));
        }
    };

    const handleDelete = (deleteIds) => {
        deviceService.deleteDeviceWarningRules({ids: [...deleteIds]}).then(res => {
            if(res.status === 'Success') {
                toastr.success(t('ALERT_SUCCESS_DELETE'));
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                fetchData();
            }
        })
        .catch(error => {
            console.log(error);
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
        });
    };

    const onClickRuleName = (rule) => {
        console.log(rule.ruleId);
        openRulePopup(rule.ruleId);
    };

    const openRulePopup = (ruleId) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.ADD_RULE,
            type: commonConstants.ADD_RULE,
            onSave: (requestBody) => onSave(requestBody),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_RULE)),
            addPopup: (id, type, mode, save, close) => dispatch(popupAction.addPopup(
                id, type, mode, save, close
            )),
            closePopup: (id) => dispatch(popupAction.closePopup(id)),
            ruleId: ruleId
        }))
    };

    const onClickGroup = (rule) => {
        console.log(rule.deviceGroupIds);
        openGroupPopup(rule, rule.deviceGroupIds ? rule.deviceGroupIds.split(',') : []);
    };

    const openGroupPopup = (rule, deviceGroupIds) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            save: (groups) => handleApplyGroup(rule, groups),
            checkbox: true,
            checkedKeys: deviceGroupIds,
            expandedKeys: deviceGroupIds,
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }))
    };

    const handleApplyGroup = (rule, groupIds) => {
        let groupIdArray = groupIds.map(group => { return group.groupId ? group.groupId : group });

        let requestBody = {
            ruleId: rule.ruleId,
            groupIds: groupIdArray,
            startIndex: 1,
            pageSize: 50,
        };

        onApplyGroups(requestBody)
    };

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAllRef}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].ruleId}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_ORGANIZATION_P'),
            accessor: 'organizationName',
            sortable: false
        },
        {
            Header: t('TABLE_TYPE_P'),
            accessor: 'deviceType',
        },
        {
            Header: t('LIST_TITLE_NAME'),
            accessor: 'ruleName',
            Cell: data => <span className={"data_name"} onClick={() => onClickRuleName(data.original)}>{data.value}</span>
        },
        {
            Header: t('COM_TEXT_INFORMATION_P'),
            accessor: 'ruleDescription',
        },
        {
            Header: t('LIST_TITLE_DATE'),
            accessor: 'createDate',
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        },
        {
            Header: t('BUTTON_APPLY'),
            sortable: false,
            Cell: data => <span className='link_cell' onClick={() => onClickGroup(data.original)}>{data.original.deviceGroupIds ? 'Y' : 'N'}</span>
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%', display: props.currContent === 'LEDBOX_WARNING_RULE' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id='ADD_RULE' name={t('COM_BUTTON_ADD')} onClick={onAdd}/>
                    <WhiteButton id='DELETE_RULE' name={t('COM_BUTTON_DELETE')} disable={selectedCnt <= 0} onClick={onDelete} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide}/>
            </div>
        </div>
    );
};

export default WarningRule;