import React from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from '../checkbox/Checkbox';
import { deviceOptions } from '../misopt';

const SourceFilter = ({sources = [], onChange}) => {
    const {t} = useTranslation();

    return (
        <div className='dev_filter_type_checkbox'>
            <h5><span className="device_type" style={{background:'#883939'}}></span>{t("COM_DID_LFD_SOURCE")}</h5>
            <div style={{overflowY:'auto'}}>
                <ul style={{overflowY: 'auto'}}>
                    <li className={sources.length === deviceOptions.inputSource.length ? "on" : ""}>
                        <Checkbox id={"source_filter_all"}
                            name={t('TEXT_TITLE_ALL_DEVICE_VIEW_P')}
                            onChange={e => onChange(e.target.checked, {value: 'all'})}
                            checked={sources.length === deviceOptions.inputSource.length}
                        />
                    </li>
                    {
                        deviceOptions.inputSource.map((option, i) => {
                            return (
                                <li key={option.value} className={sources.includes(option) ? "on" : ""}>
                                    <Checkbox id={"source_filter_"+option.value}
                                        name={option.title}
                                        checked={sources.includes(option)}
                                        onChange={e => onChange(e.target.checked, option)}
                                    />
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default SourceFilter;