import React from 'react';

const MediaTagFilterStatus = ({mediaTags,selectedMediaTag}) => {
    
    const countToShow = 10;
    var selectedMediaTagShown = selectedMediaTag.length > countToShow ? selectedMediaTag.slice(0, countToShow):selectedMediaTag;
    var selectedMediaTagHidden = selectedMediaTag.length > countToShow ? selectedMediaTag.slice(countToShow, selectedMediaTag.length):[];

    const getTagNameByTagId = tagId => {
        let tag = mediaTags.find(tag => tag.tagId == tagId)
        return tag ? tag.tagName : '';
    }

    return (
        <span id="filter_box_tagFilter">
            {
                selectedMediaTagShown && selectedMediaTagShown.map(item => (
                    <span className="filter_status_tag_filter" title={getTagNameByTagId(item)}>
                        <span>{getTagNameByTagId(item)}</span>
                    </span>
                ))
            }
            {
                selectedMediaTagHidden && selectedMediaTagHidden.length > 0 && (
                    <span className="filter_status_tag_filter" title={(selectedMediaTagHidden.map(item => getTagNameByTagId(item))).join(', ')}>
                        <span>...</span>
                    </span>
                )
            }
        </span>
    )
}
export default MediaTagFilterStatus;