import React, {useEffect, useRef, useState} from "react";
import {dashboardService} from "../../../../services";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {isNil, isNull} from "lodash";
import toastr from 'toastr';
import {useDispatch, useSelector} from "react-redux";
import {popupAction} from "../../../../actions";
import {dashboardConstants} from "../../../../constants/DashboardConstants";
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import {useOutsideClick} from "../../../../helper";
import {commonConstants} from "../../../../constants";
import NEW_ICON from '../../../../images/icon/ic_noti_new.svg';
import {useMISOpt} from "../../../../components/misopt";
import Moment from 'moment';

const NoticeRow = ({notice, onClick, showNotices, needCloseButton = false, handleDelete}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat}}} = useMISOpt();

    const handleDeleteNotice = (e, noticeId) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_DID_MAIN_NOTICE"),
            message: `${t("MIS_SID_CBFEB_ITEM_DELETED_FROM_ALL_USERS'_NOTICES")} ${t('MIS_SID_CBFEB_SURE_WANT_DELETE_SELECTED_ITEMS')}`,
            onClickYes: ()=> handleDelete(noticeId),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    return (
        <div style={{display: 'flex', flexGrow:9, flexFlow: 1, pointer: needCloseButton === false ? 'cursor' : ''}} className={'row'} onClick={onClick}>

            <div className={'item title'} style={{paddingLeft: 10, width: '80%', flexGrow: 7, flexFlow:1, lineHeight: '35px', fontSize: 13}} >
                <div style={{display: 'flex'}}>
                    <div style={{maxWidth: 60}} >
                        {
                            !isNull(notice.noticeWriteDate) && Moment().diff(Moment(notice.noticeWriteDate), 'days') < 4 && <span className={'item new'}></span>
                        }
                        {
                            notice.noticeImportant === 0 && <span className={'item import'}></span>
                        }
                    </div>
                    <div style={{marginLeft: 5}}>{notice.noticeTitle}</div>
                </div>
            </div>
            <div className={'item writer'} style={{flexGrow: 3, minWidth: 180}}>{notice.noticeUserId} | {Moment(notice.noticeWriteDate).format(dateFormat.toUpperCase())}</div>
            {
                needCloseButton ?
                    <span className={'close'} onClick={(e)=>handleDeleteNotice(e, notice.noticeId)}></span> :
                    <span className={classNames('arrow', {'up' : showNotices})}></span>
            }

        </div>
    )

}

const Notices = ({notices, handleDelete, fetchNotice}) => {

    const dispatch = useDispatch();

    const handleNotice = (noticeId) => {
        dispatch(
            popupAction.addPopup({
                id: dashboardConstants.EDIT_NOTICE_POPUP,
                type: dashboardConstants.EDIT_NOTICE_POPUP,
                selectId: noticeId,
                onSave: ()=> {
                    dispatch(popupAction.closePopup(dashboardConstants.EDIT_NOTICE_POPUP));
                    fetchNotice();
                },
                onDelete: ()=> {
                    dispatch(popupAction.closePopup(dashboardConstants.EDIT_NOTICE_POPUP));
                    fetchNotice();
                },
                onClose: () => dispatch(popupAction.closePopup(dashboardConstants.EDIT_NOTICE_POPUP))
            })
        );
    }


    return (
        <div className={'notices'} style={{zIndex: 1, position: 'relative'}}>

            {
                notices.length > 0 &&
                notices.map(
                    notice =>
                    <div key={notice.noticeId} onClick={()=>handleNotice(notice.noticeId)}>
                        <NoticeRow notice={notice} needCloseButton={true} handleDelete={(e)=>handleDelete(e, notice.noticeId)}/>
                        <div className={'content'}>
                            {notice.noticeContent}
                        </div>
                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <div className={'border'}/>
                        </div>

                    </div>
                )
            }
        </div>
    )
}

const Notice = () => {

    const ref = useRef();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [notices, setNotices] = useState([]);
    const [showNotices, setShowNotices] = useState(false);

    const {reload} = useSelector(state => state.dashboard);

    useOutsideClick(ref, ()=> {
        setShowNotices(false);
    })

    const fetchNotice = () => {
        dashboardService.fetchNoticeList(1, 100)
            .then(
                res => {
                    setNotices(res.items.noticeList)
                }

            ).catch(e => toastr.error(getErrorMessage(e)))
    }

    const handleNoticePopup = () => {
        dispatch(
            popupAction.addPopup({
                id: dashboardConstants.ADD_NOTICE_POPUP,
                type: dashboardConstants.ADD_NOTICE_POPUP,
                onSave: ()=> fetchNotice(),
                onClose: () => dispatch(popupAction.closePopup(dashboardConstants.ADD_NOTICE_POPUP))
            })
        )
    }

    const handleDelete = (e, noticeId) => {
        dashboardService.deleteNotice(noticeId)
            .then(
                res => toastr.success(t('ALERT_SUCCESS_DELETE'))
            ).catch(e => toastr.error(getErrorMessage(e)))
            .finally(()=>{
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                fetchNotice();
            })
    }

    const handleNotices = () => {
        setShowNotices(!showNotices);
    }

    useEffect(()=> {
        fetchNotice();
    }, [reload])

    return (
        <>
            <div className={'notice_container'} style={{borderRadius: 4, position: 'relative',height: 35, backgroundColor: '#ffffff',width: '100%', display: 'inline-block', flexFlow: 1}} ref={ref}>
                {
                    !isNil(notices) && notices.length > 0 &&
                        <NoticeRow notice={notices[0]} onClick={handleNotices} showNotices={showNotices}/>

                }
                {
                    showNotices && <Notices notices={notices} handleDelete={handleDelete} fetchNotice={fetchNotice}/>
                }
            </div>
            <div style={{lineHeight: '35px', marginLeft: 5, marginRight: 10, width: 40, flexGrow: 1}}>
                <button className={'dashboard_base'} onClick={handleNoticePopup} title={t("MIS_SID_CBFEB_NEW_NOTICE")}>
                    <span className="dashboard_custom_write_btn"></span>
                </button>
            </div>
        </>
    )
}
export default Notice;