import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {menuAction, popupAction} from "../../../actions";
import {rulesetService} from "../../../services/ruleset.service";
import MISDialog from "../MISDialog";
import TextInput from '../../input/TextInput';
import {SearchButton} from "../../button/SearchButton";
import GroupsPopup from '../GroupsPopup';
import './SaveRulesetPopup.css';
import {getMixString} from "../../../language/languageUtils";
import {toastr} from 'helper/toastrIntercept';
import {commonConstants} from '../../../constants';
import {generateSaveObject} from '../../../helper/ruleset/rulesetUtils';
import {getErrorMessage} from '../../../helper/responseHandler';

const SaveRulesetPopup = ({id, mode, ruleset, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [rulesetName, setRulesetName] = useState(ruleset.name !== undefined ? ruleset.name : '');
    const [description, setDescription] = useState(ruleset.description !== undefined ? ruleset.description : '');
    const [popups, setPopups] = useState({
        rulesetGroupPopup: false,
    });
    const [group, setGroup] = useState({
        groupId: ruleset.groupId,
        groupName: ruleset.groupName
    });
    const [defaultContent, setDefaultContent] = useState({
        defaultPlay: ruleset.defaultPlay,
        defaultPlayName: ruleset.defaultPlayName
    });

    const closePopup = () => {
        dispatch(popupAction.closePopup('SAVE_RULESET_LOADING_POPUP'));
        dispatch(popupAction.closePopup(id));
        if(onClose) {
            onClose();
        }
    };

    const valueCheck = () => {
        if(rulesetName === undefined || rulesetName.length === 0) {
            toastr.error(getMixString(["MIS_SID_MIX_ENTER_A_NAME", "MIS_SID_RULESET"]));
            return false;
        }
        
        if (rulesetName.trim() === '' ) {                       
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return false;
        }

        if(group === undefined || group.groupId === undefined) {
            toastr.error(t("MESSAGE_COMMON_SELECT_GROUP_P"));
            return false;
        }
        
        return true;
    }

    const saveRuleset = () => {

        if(!valueCheck()) {
            return;
        }

        dispatch(popupAction.addPopup({id: 'SAVE_RULESET_LOADING_POPUP', type : commonConstants.LOADING_POPUP}));

        let isTemp = false;

        if(mode !== "SAVE_AS_FROM_LIST") {
            for(let i = 0; i < ruleset.rules.length; i++) {
                const elem = document.getElementById("rule_area_" + ruleset.rules[i].rule_id);
                if(elem.querySelector(".ruleset_condition_no_result_button") !== null) {
                    isTemp = true;
                    break;
                }
            }
        }
        
        if(mode === 'NEW') {
            const r = generateSaveObject(ruleset, rulesetName, group, defaultContent, description);
            r.isTemp = isTemp;
            rulesetService.createRuleset(r).then(
                res => {
                    dispatch(menuAction.removeTab('NEW_RULESET'));
                    toastr.success(t('COM_TEXT_SUCCESS_P'));
                }
            ).catch(error => {toastr.error(error)})
            .finally(() => {
                closePopup();
            });
        }
        else if(mode === "SAVE_AS" || mode === "SAVE_AS_FROM_LIST") {
            if(mode === "SAVE_AS_FROM_LIST") {
                isTemp = ruleset.isTemp;
            }
            const r = generateSaveObject(ruleset, rulesetName, group, defaultContent, description, true);
            r.isTemp = isTemp;

            rulesetService.createRuleset(r).then(
                res => {
                    if(mode === "SAVE_AS") {
                        dispatch(menuAction.removeTab('EDIT_RULESET'));
                    }
                    
                    toastr.success(t('COM_TEXT_SUCCESS_P'));
                }
            ).catch(error => {toastr.error(error)})
            .finally(() => {
                closePopup();
            });
        }
        else {
            const r = generateSaveObject(ruleset, rulesetName, group, defaultContent, description);
            r.isTemp = isTemp;
            rulesetService.updateRuleset(r).then(
                res => {
                    dispatch(menuAction.removeTab('EDIT_RULESET'));
                    toastr.success(t('COM_TEXT_SUCCESS_P'));
                }
            ).catch(e => {
                toastr.error(getErrorMessage(e));
            })
            .finally(() => {
                closePopup();
            });
        }
    };

    const showRulesetGroupPopup = () => {
        setPopups({...popups, rulesetGroupPopup: !popups.rulesetGroupPopup});
    }

    const showDefaultContentSelectPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                type: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                handleSelected: (selectedContents) => {
                    if(selectedContents.length === 1) {
                        setDefaultContent({
                            defaultPlay: selectedContents[0].contentId,
                            defaultPlayName: selectedContents[0].contentName
                        });
                    }
                },
                contentType: "content",
                multiSelect: false,
                device: {
                    deviceType: ruleset.deviceType,
                    deviceTypeVersion: ruleset.deviceTypeVersion
                },
                onClose: () => dispatch(popupAction.closePopup(commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP))
            })
        );
    }

    const setRulesetGroup = (groups) => {
        if(groups !== undefined && groups.length > 0) {
            showRulesetGroupPopup();
            setGroup(groups[0]);
        }
        else {
            toastr.error(t('MESSAGE_COMMON_SELECT_GROUP_P'));
        }
    }

    const dialogProps ={
        title: mode === "SAVE_AS" || mode === "SAVE_AS_FROM_LIST" ? t("BUTTON_SAVE_AS_P") : t("COM_BUTTON_SAVE"),
        width :525,
        height :130,
        position: {x: -300, y: -200},
        modal:true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose:() => closePopup()
    };

    const emptyContent = () => {
        setDefaultContent({
            defaultPlay: "",
            defaultPlayName: "",
        });        
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("COM_BUTTON_SAVE"), id: 'saveSaveRulesetBtn', onClick: () => saveRuleset()},
                    {title: t("BUTTON_CANCEL_P"), id: 'cancelSaveRulesetBtn', onClick: ()=> closePopup()}
                ]
        }}>
            { popups.rulesetGroupPopup &&
                <GroupsPopup
                    mode="ruleset"
                    checkbox={false}
                    close={() => showRulesetGroupPopup()}
                    save={(groups) => setRulesetGroup(groups)}/>
            }
            <div>
                <table style={{width: "100%"}}>
                    <colgroup>
                        <col width="139px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr className="ruleset">
                            <th>{getMixString(['MIS_SID_MIX_NAME', 'MIS_SID_RULESET'])}</th>
                            <td>
                                <TextInput width={309} maxLength={200} placeholder={getMixString(["MIS_SID_MIX_ENTER_A_NAME", "MIS_SID_RULESET"])} value={rulesetName} onChange={(e) => setRulesetName(e.target.value)}/>
                            </td>
                        </tr>
                        <tr className="ruleset">
                            <th>{t('TEXT_GROUP_P')}</th>
                            <td>
                                <div className="float_l mr10">
                                    <SearchButton onClick={showRulesetGroupPopup}/>
                                </div>
                                <div className="tag_input type2 float_l" style={{width: 238, background: 'none'}}>
                                    <input
                                        type="text"
                                        style={{
                                            width: '100%',
                                            background: 'none',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                        value={group.groupName}
                                        readOnly
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr className="ruleset">
                            <th>{t('COM_TEXT_DEFAULT_CONTENT_P')}</th>
                            <td>
                                <div className="float_l mr10">
                                    <SearchButton onClick={showDefaultContentSelectPopup}/>
                                </div>
                                <div className="tag_input type2 float_l" style={{width: 238, background: 'none'}}>
                                    <input
                                        type="text"
                                        style={{
                                            width: '100%',
                                            background: 'none',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                        value={defaultContent.defaultPlayName}
                                        readOnly
                                    />
                                    <span onClick={emptyContent} className={"ruleset_empty_button"}></span>
                                </div>
                            </td>
                        </tr>
                        <tr className="ruleset">
                            <th>{t('TEXT_DESCRIPTION_P')}</th>
                            <td>
                                <TextInput width={309} placeholder={t("MIS_SID_CAFEB_ENTER_DESCRIPTION")} value={description} maxLength={200} onChange={(e) => setDescription(e.target.value)}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    )
}

export default SaveRulesetPopup;