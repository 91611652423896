import React from 'react';
import classNames from 'classnames';
import WhiteButton from "../button/WhiteButton";
import {commonConstants} from "../../constants";
import './DimPopup.css';
import 'rc-time-picker/assets/index.css';
import Checkbox from "../checkbox/Checkbox";
import DatePicker from "react-datepicker";
import SelectBox from "../selectbox/SelectBox";
import TimePicker from 'rc-time-picker';
import Moment from 'moment';
import ContentPopup from "./schedule/ContentPopup";
import {withTranslation} from "react-i18next";

class DimPopup extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {

    }

    closePopup = (id) => {
        const {closePopup} = this.props;
        closePopup(id);
    }

    renderSchedulePopup(body) {
        const { handleScheduleDate, openContentPopup, t} = this.props;

        return (
            <table>
                <colgroup><col width="184px" /><col width="" /></colgroup>
                <tbody>
                <tr className="contentTr">
                    <th>{t("DID_ADMIN_NOTICE_CONTENT")}</th>
                    <td>
                        <div style={{display:'block-inline'}}>
                            <div className="" style={{float:'left'}}>
                                <span id="selectContentInput">
                                    <input type="text" id="selectContentName" value={body.contentName} readOnly="" style={{width:220}} />
                                </span>
                            </div>
                            <div style={{float:'left'}}>
                                <button className="contentSelectSearchBtn" onClick={openContentPopup}></button>
                            </div>
                        </div>
                        <span style={{display:'inline-block',marginLeft:7,marginTop:10}}>
                            <Checkbox id={"scheduleContentLock"} name={t("BUTTON_CHECK_OUT")} />
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                    <td>
                        <div className="input_date">
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={body.start}
                                onChange={date => handleScheduleDate(date, 'start')}
                            />
                            <button type="button" />
                        </div>
                        ~
                        <div className="input_date">
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={body.end}
                                onChange={date => handleScheduleDate(date, 'end')}
                            />
                            <button type="button" />
                        </div>
                        <span style={{marginLeft:1}}>
                            <Checkbox id={"neverExpired"} name={t("MIS_SID_20_NEVER_EXPIRE")} />
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>{t("TEXT_REPEAT_P")}</th>
                    <td>
                        <SelectBox title={t("TEXT_ONCE_P")} width={120} value={"once"} selects={[
                            {id:'SCHEDULE_TYPE_ONCE', value:'once', title: t("TEXT_ONCE_P")},
                            {id:'SCHEDULE_TYPE_DAILY', value:'daily', title: t("TEXT_DAILY_P")},
                            {id:'SCHEDULE_TYPE_WEEKLY', value:'day_of_week', title: t("TEXT_WEEKLY_P")},
                            {id:'SCHEDULE_TYPE_MONTHLY', value:'day_of_month', title: t("TEXT_MONTHLY_P")}
                            ]} />
                    </td>
                </tr>
                <tr>
                    <th>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                    <td>
                        <TimePicker
                            showSecond={true}
                            defaultValue={Moment(body.start)}
                            className="scheduleTime"
                            format={'hh:mm:ss a'}
                            use12Hours
                            inputReadOnly
                            allowEmpty={false}
                        />
                        ~
                        <TimePicker
                            showSecond={true}
                            defaultValue={Moment(body.end)}
                            className="scheduleTime"
                            format={'hh:mm:ss a'}
                            use12Hours
                            inputReadOnly
                            allowEmpty={false}
                        />
                        <span style={{marginLeft:10}}>
                            <Checkbox id={"24hours"} name={t("COM_IDS_STR_24_HOURS")} />
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    render() {
        const style = {left: 619, top: 243, position: 'absolute'};
        const {type, id, title, body, buttons, closePopup, contentPopup, closeContentPopup, selectContent, message} = this.props;
        const show = true;
        return (
            <div>
                <div className={"dim_background"} style={{display: (show > 0 ? 'block' : 'none')}}></div>
                <div className="dim_wrap" style={style}>
                    {
                        contentPopup != null && contentPopup ?
                            <ContentPopup {...contentPopup} closeContentPopup={closeContentPopup} selectContent={selectContent}/> : ''
                    }
                    <div className={
                        classNames(
                            {
                                'popup_wrap': true,
                                'type1': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_1,
                                'type2': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_2,
                                'type3': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_3,
                                'type4': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_4,
                                'type5': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_5,
                                'type6': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_6,
                                'type7': type === commonConstants.COMMON_POPUP_TYPE_MODAL_TYPE_7,
                                'schedulePopup' : type === commonConstants.COMMON_POPUP_CREATE_SCHEDULE
                            })} id={id}>
                        <div>
                            <div className="popup_header">
                                <h1>{title}</h1>
                                <div>
                                    <button className="pop_circle" onClick={()=>closePopup()}>
                                        <span className="close"/>
                                    </button>
                                </div>
                            </div>

                            <div className="popup_contents">
                                {type === commonConstants.COMMON_POPUP_CREATE_SCHEDULE ?
                                    <div className={"detail_view"}>
                                        {
                                            type === commonConstants.COMMON_POPUP_CREATE_SCHEDULE ? this.renderSchedulePopup(body) : ''
                                        }
                                    </div>
                                    :
                                    <div className={"detail_view mini_p mt46 mb50"} dangerouslySetInnerHTML={{__html:message}}>
                                    </div>
                                }
                            </div>
                            <div className="popup_btn_wrap">
                                <div style={{float: 'left'}}>
                                    {
                                        buttons != null && buttons.leftButtons != null && buttons.leftButtons.length > 0 ?
                                            buttons.leftButtons.map(
                                                button => <WhiteButton {...button}/>
                                            ) : ''
                                    }
                                </div>
                                <div style={{float: 'right'}}>
                                    {
                                        buttons != null && buttons.rightButtons != null && buttons.rightButtons.length > 0 ?
                                            buttons.rightButtons.map(
                                                button => {
                                                    if (button.id === 'CLOSE_POPUP') {
                                                        return <WhiteButton key={button.id} id={button.id} name={button.name}
                                                                            onClick={()=>closePopup()}/>
                                                    } else {
                                                        return <WhiteButton {...button}/>
                                                    }

                                                }
                                            ) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(DimPopup);