import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import React, {useEffect, useRef, useState} from "react";
import {SearchButton} from "../../../button/SearchButton";
import TextInput from "../../../input/TextInput";
import {settingConstants} from "../../../../constants";
import RadioGroup from "../../../radio/RadioGroup";

const OfflineActivationPopup= ({onSave, onClose, type, licKey, hwUniqueKey,title}) => {
    const {t} = useTranslation();
    const akFileInput = useRef();
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);

    const internetOption = [
        {value: true, title: t('COM_MIS_TEXT_CONNECTED_P')},
        {value: false, title: t('TEXT_DISCONNECTED_P')}
    ];
    const onClickFile = () => {
        akFileInput.current.click();
    };

    const setFileInformation = (event) => {
        if(event.target.files[0] !== undefined) {
            setFileName(event.target.files[0].name);
            setFile(event.target.files[0])
        }
    };

    const [licKeyInput, setLicKeyInput] = useState({
        key0: '',
        key1: '',
        key2: '',
        key3: ''
    });
    useEffect(() => {
        initLicKeyinput();
    },[]);

    const initLicKeyinput = () => {
        if (type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE) {
            const tempLicKey = licKey.split('-');
            setLicKeyInput({...licKeyInput, key0: tempLicKey[0], key1: tempLicKey[1], key2: tempLicKey[2], key3: tempLicKey[3] });
        }
    }

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 620,
                    height: 250,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_ONLINE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: () => onSave(licKey,file),
                            disable: false,
                        },
                        {
                            id: 'ACTIVATION_ONLINE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                <div className="detail_view mini_p mb15">
                    <table>
                        <colgroup>
                            <col width="182px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        {
                            type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE &&
                            <tr>
                                <th>{t('COM_MIS_TEXT_INTERNET_CONNECTION_P')}</th>
                                <td>
                                    <RadioGroup propertyName='internetType' selects={internetOption} value={false} disabled={true}  />
                                </td>
                            </tr>
                        }
                        {
                            type == settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE &&
                            <tr>
                                <th>{t('COM_TEXT_LICENSE_KEY_P')}</th>
                                <td>
                                    <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKeyInput.key0} />
                                    <span className="space float_l mr8">-</span>
                                    <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKeyInput.key1} />
                                    <span className="space float_l mr8">-</span>
                                    <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKeyInput.key2}/>
                                    <span className="space float_l mr8">-</span>
                                    <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKeyInput.key3} />
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('COM_MIS_TEXT_H_W_UNIQUE_KEY_P')}</th>
                            <td>
                                <TextInput width={220} value={hwUniqueKey} disabled={true}/>
                            </td>
                        </tr>
                        {
                            type == settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE &&
                            <tr>
                                <td colSpan="2">
                                    <div
                                        style={{"whiteSpace": "initial"}}>1. {t('MIS_TEXT_MAKE_A_NOTE_OF_THE_LICENSE_KEY_AND_UNIQUE_KEY_P')}</div>
                                </td>
                            </tr>
                        }
                        {
                            type == settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE &&
                            <tr>
                                <td colSpan="2">
                                    <div
                                        style={{"whiteSpace": "initial"}}>2. {t('COM_MIS_TEXT_ACCESS_LICENSE_SERVER_WHERE_INTERNET_IS_POSSIBLE_P')}</div>
                                </td>
                            </tr>
                        }
                        {
                            type == settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE &&
                            <tr>
                            <td colSpan="2">
                            <div
                            style={{"whiteSpace": "initial"}}>3. {t('MIS_TEXT_ENTER_THE_LICENSE_KEY_AND_UNIQUE_KEY_TO_THE_LICENSESERVER_P')}</div>
                            </td>
                            </tr>
                        }
                        {
                            type == settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE &&
                                <tr>
                                    <td colSpan="2">
                                        <div
                                            style={{"whiteSpace": "initial"}}>4. {t('COM_MIS_TEXT_CONNECT_THE_USB_TO_THIS_PC_AND_PRESS_BELOW_BUTTON_P')}</div>
                                    </td>
                                </tr>
                        }
                        <tr>
                            <th>{t('COM_MIS_BUTTON_READ_ACTIVATIONKEY_P')}</th>
                            <td>
                                <SearchButton onClick={onClickFile} />
                                <input type="file" ref={akFileInput} style={{display:'none'}} onChange={setFileInformation}/>
                                    <div className="tag_input float_l mr8" style={{width:'150px'}}>
                                        <input type="text" value={fileName}/>
                                    </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </MISDialog>
        </div>
    )
};

export default OfflineActivationPopup