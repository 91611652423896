import MISDialog from "../MISDialog";
import React, {useEffect, useState} from "react";
import {rulesetService} from "../../../services";
import {useTranslation} from "react-i18next";
import {rulesetConstants} from "../../../constants";
import TextInput from "../../input/TextInput";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import EditableSelectBox from "../../selectbox/EditableSelectBox";

const KeywordPopup = ({ id, keyword, onClose, onSave, mode }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const [keywordInfo, setKeywordInfo] = useState({
        keywordName: "",
        keywordValueList: [],
        keywordId: ""
    });

    const [keywordValues,setKeywordValues] = useState([]); // load exist tagValues + will be added tag values
    const [addedKeywordValues, setAddedKeywordValues] = useState([]); // only will be added tag values
    const [deletedKeywordValues, setDeletedKeywordValues] = useState([]); // only will be deleted tag values


    useEffect(() => {
        fetchTag();
    },[]);

    const fetchTag = () => {
        if (mode === rulesetConstants.EDIT_TAG_POPUP) {
            Promise.all([                
                rulesetService.editResultKeyword(keyword.keywordId)
            ]).then(res => {

                setKeywordInfo({
                    ...keywordInfo,
                    keywordId: res[0].items.keywordId,
                    keywordName: res[0].items.keywordName,
                    keywordValues: res[0].items.keywordValues
                });
                const tempValues = [];
                //res[1].items.map(item => item.tagConditionId != -1 ? tempValues.push({text: item.tagCondition,value: item.tagCondition, id: item.tagConditionId}) : null)
                setKeywordValues(tempValues);
            });
        }
    }


    return (
        <div>
            <MISDialog
                dialog={{
                    title: t("MIS_SID_ADD_KEYWORD"),
                    closeOnEscape: true,
                    modal: true,
                    width: 400,
                    height: 100,
                    // position: {x: -300, y: -400},
                    onClose: onClose
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: "DEVICE_SAVE",
                            title: t("COM_BUTTON_SAVE"),
                            onClick: () => {
                                const newKeywordInfo = { ...keywordInfo, keywordValueList : addedKeywordValues};
                                setKeywordInfo(newKeywordInfo);                            
                                onSave(newKeywordInfo);
                            },
                            disable: false
                        },
                        {
                            id: "DEVICE_CANCEL",
                            title: t("COM_BUTTON_CLOSE_P"),
                            onClick: ()=>closePopup()
                        }
                    ]
                }}
            >
                <div className="detail_view mini_p ">
                    <table>
                        <colgroup>
                            <col width="40%" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t("MIS_SID_KEYWORD_NAME")}</th>
                                <td>
                                    <TextInput
                                        width={340}
                                        placeholder={t("MESSAGE_COMMON_ENTER_NAME_P")}
                                        value={keywordInfo.keywordName}
                                        onChange={e =>
                                            setKeywordInfo({
                                                ...keywordInfo,
                                                keywordName: e.target.value
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            {
                               <tr>
                               <th>{t('MIS_TEXT_TAG_VALUE_P')}</th>
                               <td style={{display: "inline-flex"}}>
                                   {
                                       keywordValues && keywordValues.length > 0 &&
                                       <EditableSelectBox width={300} oldValues={keywordValues} setAddedValues={setAddedKeywordValues} setDeletedValues={setDeletedKeywordValues}/>
                                   }
   
                                   {
                                       keywordValues.length === 0 &&
                                       <EditableSelectBox width={300} oldValues={[]} setAddedValues={setAddedKeywordValues} setDeletedValues={setDeletedKeywordValues}/>
                                   }
                               </td>
                           </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};
export default KeywordPopup;
