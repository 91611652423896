import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import MISDialog from "../../MISDialog";
import DataLinkTableInformation from "./DataLinkTableInformation";
import {userService} from "../../../../services/user.service";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {popupAction} from "../../../../actions";
import {commonConstants, settingConstants} from "../../../../constants";
import {useDispatch} from "react-redux";
import {getMixString} from "../../../../language/languageUtils";
import {isNil} from 'lodash';

const AssignDataLinkTablePopup = ({onClose, datalinkInfo}) => {
    const {t} = useTranslation();

    /*
    1. fetch organization info
    2. fetch datalink table info
    3. fetch datalink saved info (user choise)
     */
    const dispatch = useDispatch();
    const [fetchComplete, setFetchComplete] = useState(false);
    const [orgNames, setOrgNames] = useState({});
    const [organizations, setOrganizations] = useState({});
    const [datalinkTables, setDatalinkTables] = useState({});
    const [tableToSave, setTableToSave] = useState([]);

    useEffect(()=> {
        prepareDatalinkTableAssign()
            .then(res => {

            })
            .catch(err=>{});
    }, []);

    useEffect(()=>{
        if(organizations.length !== 0) {
            let tempOrgNames = [];
            let orgNameArr = [];
            for (let [key, value] of Object.entries(organizations)) {
                for (let [key1, value1] of Object.entries(value)) {
                    if(key1 === 'groupName'){
                        tempOrgNames.push({orgName:value1});
                        orgNameArr.push(value1);
                    }
                }
            }
            setOrgNames(orgNameArr);
            fetchSelectedTables(tempOrgNames, datalinkInfo.serverName);
        }
        
    },[organizations, datalinkInfo.server_name]);

    const prepareDatalinkTableAssign = async () => {
        dispatch(popupAction.addPopup({id:commonConstants.LOADING_POPUP, type : commonConstants.LOADING_POPUP}));
        let catchHappened = false;
        const [org, update, dlkTables] = await Promise.all([
                await userService.fetchOrganizations(),
                await settingService.updateDatalinkTables(datalinkInfo.serverName)
                .catch(err=>{
                    catchHappened = true;
                    toastr.error(getMixString(["MIS_SID_MIX_CANNOT_CONNECT_TO_SERVER", datalinkInfo.serverName]));
                    dispatch(popupAction.closePopup(settingConstants.ASSIGN_DATALINK_TABLE_POPUP));
                }).finally(() => {
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                }),
                await settingService.fetchDatalinkTables(datalinkInfo.serverName)
            ]);
        setOrganizations(org.items.filter(item => item.groupId !== 0));
        setDatalinkTables({...dlkTables.items});
        if(isNil(dlkTables) || isNil(dlkTables.items) || dlkTables.items.length <= 0){
            if (!catchHappened) {
                toastr.error(t('MESSAGE_COMMON_NO_DATA_P'));
            }
            onClose && onClose();
        }
    };

    const fetchSelectedTables = (orgNameList, serverName) => {
        return settingService.fetchSelectedDatalinkTables(orgNameList, serverName)
            .then(res=>{
                let temp = [];
                if(res.items.length > 0){
                    res.items.forEach(element=>{
                        element.v2DatalinkServerTableList.forEach(table => {
                            table.organizationName = element.organizationName;
                            temp.push(table);
                        });
                    })
                }
                setTableToSave(temp);
            }).catch(err => {
                getErrorMessage(err);
            }).finally(() => {
                setFetchComplete(true);
            });
    };

    const updateOrganizations = (arr) => {
        setOrgNames(arr);
    };

    const updateTableToSave = (obj, bool) => {
        obj = [].concat(obj);
        if(bool){
            setTableToSave([...tableToSave, ...obj.filter(o => {
                let ret = true;
                tableToSave.forEach(t => {
                    if(t.organizationName === o.organizationName && t.dynaName === o.dynaName && t.serverName === o.serverName 
                        && t.serviceName === o.serviceName && t.tableName === o.tableName){
                        ret = false;
                        return;
                    }
                });
                return ret;
            })]);
        } else {
            setTableToSave(tableToSave.filter(t => {
                let ret = true;
                obj.forEach(o => {
                    if(t.organizationName === o.organizationName && t.dynaName === o.dynaName && t.serverName === o.serverName 
                        && t.serviceName === o.serviceName && t.tableName === o.tableName){
                        ret = false;
                        return;
                    }
                });
                return ret;
            }));
        }
    };

    const onSaveDatalinkTableInfo = () => {
        settingService.saveDatalinkTables(tableToSave, datalinkInfo.serverName)
            .then(res=>{
                if(res.items === true){
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                }
            })
            .catch(err=>{
                toastr.error(t('MESSAGE_SCHEDULE_DATA_SAVE_FAILED_P'));
            });
        dispatch(popupAction.closePopup(settingConstants.ASSIGN_DATALINK_TABLE_POPUP));
    };

    return(fetchComplete === true && Object.entries(organizations).length!==0 && Object.entries(datalinkTables).length!==0 &&
        <MISDialog
            dialog={{
                title:t('MIS_SID_ASSIGN_TABLE'),
                modal:true,
                closeOnEscape: true,
                isDraggable: false,
                scroll: true,
                scrollWidth: Object.values(datalinkTables).length*110,
                width: Object.values(datalinkTables).length>6?1170:[390, 520, 650, 780, 910, 1040][Object.values(datalinkTables).length-1],
                height: Object.values(orgNames).length>8?500:Object.values(orgNames).length*40+120,
                onClose: onClose}}
                buttons={{
                    rightButtons: [
                        {
                            id: 'BUTTON_OK_P',
                            title: t('BUTTON_OK_P'),
                            onClick: ()=> onSaveDatalinkTableInfo(),
                            disable: false
                        },
                        {
                            id: 'BUTTON_CANCEL_P',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}
        >
       <DataLinkTableInformation
            organizations={Object.values(orgNames)}
            setOrganizations={updateOrganizations}
            datalinkTables={Object.values(datalinkTables)}
            setTableToSave={updateTableToSave}
            tableToSave={tableToSave}
        />
        </MISDialog>
    );
};

export default AssignDataLinkTablePopup;