import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import SearchBar from '../../components/search/SearchBar';
import './SubmenuRulesetContainer.css';
import Button from '../../components/button/Button';
import SelectBox from '../../components/selectbox/SelectBox';
import {rulesetService} from '../../services/ruleset.service';
import PerfectScrollbar from 'perfect-scrollbar';
import {toastr} from 'helper/toastrIntercept';
import {Loading} from '../../components/loading/Loading';
import SubmenuConditionBox from '../../components/ruleset/SubmenuConditionBox';
import SubmenuPlayBox from '../../components/ruleset/SubmenuPlayBox';
import Backend from 'react-dnd-html5-backend';
import {DndProvider} from "react-dnd";
import {menuAction, popupAction} from '../../actions';
import {commonConstants} from '../../constants';
import {MagicInfoDndProvider} from "../../components/dnd/dndHooks";

const PAGE_SIZE = 50;

class SubmenuRulesetContainer extends React.Component {
    constructor(props) {
        super(props);
        const mode = this.props.menu.submenu.mode === undefined ? 'CONDITION' : this.props.menu.submenu.mode;

        this.state = {
            isFetching: false,
            style: {
                marginTop: 15,
                overflowY: 'auto',
                height: 900,
            },
            mode: mode,
            condition: {
                filter: {
                    isPublic: true,
                    startIndex: 1,
                    pageSize: PAGE_SIZE,
                    searchText: '',
                    sortDirection: 'desc',
                    orderBy: 'modify_date'
                },
                list: []
            },
            play: {
                filter: {
                    isPublic: true,
                    startIndex: 1,
                    pageSize: PAGE_SIZE,
                    searchText: '',
                    sortDirection: 'desc',
                    orderBy: 'modify_date'
                },
                list: []
            }
        }
    }

    componentDidMount() {
        this._updateDimensions();
        window.addEventListener("resize", this._updateDimensions.bind(this));
        const ps = new PerfectScrollbar('#external-events', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
            suppressScrollX: true
        });

        document.querySelector('#external-events').addEventListener('ps-y-reach-end', () => {
            if (!this.state.isFetching) {
                switch(this.state.mode) {
                    case 'CONDITION':
                        const conditionStartIndex = this.state.condition.filter.startIndex + PAGE_SIZE;
                        
                        this.setState({
                            ...this.state,
                            condition: {
                                ...this.state.condition,
                                filter: {
                                    ...this.state.condition.filter,
                                    startIndex: conditionStartIndex
                                }
                            }
                        }, () => {
                            this.fetchConditions();
                        });
                        break;
                    case 'PLAY':
                        const playStartIndex = this.state.condition.filter.startIndex + PAGE_SIZE;

                        this.setState({
                            ...this.state,
                            play: {
                                ...this.state.play,
                                filter: {
                                    ...this.state.play.filter,
                                    startIndex: playStartIndex
                                }
                            }
                        }, () => {
                            this.fetchPlays();
                        });
                        break;
                }
            }
        });

        switch (this.state.mode) {
            case 'CONDITION':
                this.fetchConditions();
                break;
            case 'PLAY':
                this.fetchPlays();
                break;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateDimensions, true);
    }

    _updateDimensions() {
        let height = window.innerHeight - 205;
        const {style} = this.state;

        this.setState({
                style : {
                    ...style,
                    height: height,
                }
            }
        )
    }

    fetchConditions() {
        if(this.state.isFetching) {
            return;
        }
        
        this.setState({
            isFetching: true
        });
        const {filter} = this.state.condition;
        rulesetService.fetchConditionFilter(filter)
            .then(res => {
                this.setState({
                    ...this.state,
                    condition: {
                        ...this.state.condition,
                        list: this.state.condition.list.concat(res.items)
                    },
                    isFetching: false
                });
            }).catch((res) => {
                toastr.error(res);
                this.setState({
                    isFetching: false
                })
            });
    }

    fetchPlays() {
        if(this.state.isFetching) {
            return;
        }

        this.setState({
            isFetching: true
        });
        const {filter} = this.state.play;
        const {deviceType, deviceTypeVersion} = this.props;
        filter.deviceType = deviceType;
        filter.deviceTypeVersion = deviceTypeVersion;

        rulesetService.fetchPlayFilter(filter)
            .then(res => {
                this.setState({
                    ...this.state,
                    play: {
                        ...this.state.play,
                        list: this.state.play.list.concat(res.items)
                    },
                    isFetching: false
                });
            }).catch((res) => {
                toastr.error(res);
                this.setState({
                    isFetching: false
                });
            });
    }

    changeLibraryType(mode) {
        const container = document.querySelector('#external-events');
        container.scrollTop = 0;

        this.setState({
            mode: mode
        });

        this.props.menu.submenu.mode = mode;

        if(mode === "CONDITION") {
            if(this.state.condition.list.length === 0) {
                this.fetchConditions();
            }
        }
        else if(mode === "PLAY") {
            if(this.state.play.list.length === 0) {
                this.fetchPlays();
            }
        }
    }

    onSearch(value) {
        const {mode} = this.state;

        if(mode === "CONDITION") {
            this.setState({
                ...this.state,
                condition : {
                    ...this.state.condition,
                    filter: {
                        ...this.state.condition.filter,
                        searchText: value
                    },
                    list: []
                }
            }, () => {
                this.fetchConditions();
            });
        }

        else if(mode === "PLAY") {
            this.setState({
                ...this.state,
                play : {
                    ...this.state.play,
                    filter: {
                        ...this.state.play.filter,
                        searchText: value
                    },
                    list: []
                }
            }, () => {
                this.fetchPlays();
            });
        }
    }

    onNewButton = (e) => {
        const {addPopup, closePopup, loadContent, menu} = this.props;
        const {mode} = this.state;

        if(mode === "CONDITION") {
            addPopup({
                id: commonConstants.RULESET_CONDITION_POPUP,
                type: commonConstants.RULESET_CONDITION_POPUP,            
                isLibrary : true,
                mode : "NEW",
                save : (condition) => {
                    condition.isPublic = true;
                    rulesetService.createCondition(condition).then(
                        res => {
                            menu.submenu.key++;
                            loadContent(menu.currentContentId);
                        }
                    ).catch(error => {toastr.error(error)})
                    .finally();
                },
                close: () => closePopup(commonConstants.RULESET_CONDITION_POPUP)
            });
        }

        else if(mode === "PLAY") {
            addPopup({
                type: commonConstants.CREATE_PLAY_POPUP, 
                id: commonConstants.CREATE_PLAY_POPUP,            
                mode: "NEW",
                save : (play) => {
                    play.isPublic = true;
                    rulesetService.createPlay(play).then(
                        res => {
                            menu.submenu.key++;
                            loadContent(menu.currentContentId);
                        }
                    ).catch(error => {toastr.error(error)})
                    .finally();
                },
                close: () => closePopup(commonConstants.RULESET_PLAY_POPUP)
            });
        }
    }

    getConditionBoxWidth = () => {
        const {subMenuWidth} = this.props;
        let numberOfBox;
        if(subMenuWidth >= 479) {
            numberOfBox = 3;
        }
        else if(subMenuWidth >= 330) {
            numberOfBox = 2;
        }
        else {
            numberOfBox = 1;
        }

        return Math.floor(((subMenuWidth - 36) - (4 * (numberOfBox - 1))) / numberOfBox) - 17;        
    }

    render() {
        const {condition, play, isFetching, mode} = this.state;
        const {t, subMenuWidth} = this.props;
        const libraryTypeButtons = [
            {key: 'RULESET_LIBRARY_CONDITION_BUTTON', title: "MIS_SID_CONDITION_RULE", value:'CONDITION', popup: false, onClick: ()=>this.changeLibraryType('CONDITION')},
            {key: 'RULESET_LIBRARY_PLAY_BUTTON', title: "MIS_SID_PLAY_RULE", value:'PLAY', popup: false, onClick: ()=>this.changeLibraryType('PLAY')},
        ];

        const keyword = mode === "CONDITION" ? condition.filter.searchText : play.filter.searchText;
        
        const marginSide = 18;
        const boxWidth = this.getConditionBoxWidth();
        
        return(
            <div className="main_lnb" id="makeRulesetTabWrap">
                <div className="ruleset_lnb_title">
                    <SelectBox
                        classname={"float_l mr10"}
                        selects={libraryTypeButtons}
                        value={this.state.mode}
                        whiteButton={true}
                        width={(subMenuWidth - 46) / 2} />
                    <div style={{marginTop: "14px", marginLeft: "5px"}}>
                        <SearchBar
                            key={"ruleset_searchbar_" + mode}
                            width={(subMenuWidth - 46) / 2 - 2}
                            type={"contentSchedule"}
                            onClickSearch={(value)=>this.onSearch(value)}
                            keyword={keyword} />
                    </div>
                </div>

                <div className="divider" style={{width: subMenuWidth - marginSide * 2}}></div>

                <Button
                    id={"NEW_SUBMENU_RULESET"}
                    title={t("TEXT_NEW_P")}
                    style={{
                        marginTop: "15px",
                        marginLeft: marginSide + "px",
                        marginRight: marginSide + "px",
                        width: (subMenuWidth - marginSide * 2) + "px"
                    }}
                    onClick={(e) => {
                        this.onNewButton();
                    }}
                />

                <div
                    id="external-events"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        height: this.state.style.height,
                        overflow: 'auto',
                        marginTop: '10px',
                        width: '100%'}}>
                    {
                        isFetching &&
                        <div className={'loading'}>
                            <Loading
                                isLoading={this.state.isFetching}
                            />
                        </div>
                    }

                    <MagicInfoDndProvider>
                        <ul style={{paddingLeft: (marginSide - 2) + "px", paddingRight: (marginSide - 2) + "px"}}>
                            {
                                mode === 'CONDITION' && condition !== null && condition.list.length > 0 &&
                                condition.list.map((item) => <SubmenuConditionBox condition={item} key={"SubmenuConditionBox_" + item.conditionId} width={boxWidth}/> )
                            }
                            {
                                mode === 'PLAY' && play !== null && play.list.length > 0 &&
                                play.list.map((item) => <SubmenuPlayBox play={item} key={"SubmenuPlayBox_" + item.resultId} width={boxWidth}/>)
                            }
                        </ul>
                    </MagicInfoDndProvider>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        menu : state.menu,
    }),
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadContent: (id) => dispatch(menuAction.loadContent(id))
    })
)(withTranslation()(SubmenuRulesetContainer));