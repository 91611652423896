import React, {useState} from 'react';
import {connect} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import Select from '../../selectbox/Select';
import {popupAction} from "../../../actions";
import {rulesetConstants} from "../../../constants"
import uuid from "uuid";
import './PlayPopup.css';
import TextInput from "../../input/TextInput";
import PlaylistType from './play/PlaylistType';
import ContentType from './play/ContentType';
import {toastr} from 'helper/toastrIntercept';
import Checkbox from '../../checkbox/Checkbox';
import {getMixString} from '../../../language/languageUtils';
import TooltipIcon from '../../icon/TooltipIcon';

const PlayPopup = ({ device, mode, save, close, play, isLibrary }) => {
    const playTypeList = [];
    const { t } = useTranslation();
    Object.entries(rulesetConstants.PLAY_TYPES).forEach(([key, value]) => {
        playTypeList.push({
            value: key,
            sign: value.sign,
            title: value.sid
        });
    });

    const [name, setName] = useState(play ? play.resultName : "");
    const [type, setType] = useState(play ? play.contentsType.toLowerCase() : playTypeList[0].value);
    const [sign, setSign] = useState(play ? play.type : "static");
    const [description, setDescription] = useState(play ? play.description : "");
    const [contentList, setContentList] = useState(play ? play.contentList : []);
    const [defaultDuration, setDefaultDuration] = useState(play ? play.defaultDuration : 5);
    const [addToLibrary, setAddToLibrary] = useState(isLibrary === undefined && play ? play.isPublic : false);
    const [filter, setFilter] = useState(play ? play.filter : {});

    const onTypeChange = (e, value) => {
        setType(value);
        addContentList([]);
        if(play !== undefined) play.contentList = [];
    };

    const addContentList = (contents) => {
        setContentList(contents);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    const verifyPlay = play => {

        if (play.resultName === "") {
            toastr.error(t(getMixString(["MIS_SID_MIX_ENTER_A_NAME", "MIS_SID_PLAY_RULE"])));
            return false;
        }else if (play.resultName.trim() === "") {
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return false;
        }

        if (play.contentList.length === 0) {
            toastr.error(t('MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P'));
            return false;
        }

        if (play.contentsType === "content") {
            if (play.defaultDuration < 5) {
                toastr.error(t('MESSAGE_UPLOADER_ERROR_PLAYTIME_P'));
                play.defaultDuration = 5;                 
            }
        }

        return true;

    };

    const getPlay = (play) => {
        const currentType = type;

        if (mode === "NEW") {
            play = JSON.parse(
                JSON.stringify(rulesetConstants.PLAY_TYPES[currentType])
            );
            play.resultId = uuid();
            play.isPublic = isLibrary ? true : addToLibrary;

        } else if (mode === "EDIT") {
            play.isPublic = addToLibrary;
        }

        play.resultName = name;
        play.description = description;
        play.type = sign;
        play.contentsType = type;
        play.deviceType = device.deviceType;
        play.deviceTypeVersion = device.deviceTypeVersion;
        play.defaultDuration = defaultDuration;
        play.filter = filter;

        play.contentList = contentList;

        return play;
    };

    let rightButton = [];
    const saveAsButton = {
        title: t("BUTTON_SAVE_AS_P"),
        disable : mode==="NEW"?true:false,        
        onClick: () => {
            const newPlay = getPlay(play);
            newPlay.resultId = uuid();
            if (newPlay) {
                if (verifyPlay(newPlay)) {
                    save(newPlay, 'save_as');
                    close();
                }
            }
        }     
    }
    const saveButton = {        
        title: t("COM_BUTTON_SAVE"),
        onClick: () => {
            const newPlay = getPlay(play);
            if (newPlay) {
                if (verifyPlay(newPlay)) {
                    save(newPlay);
                    close();
                }
            }
        }        
    };

    const closeButton =  {
        title: t("BUTTON_CANCEL_P"),
        onClick: () => close()
    };
    if(isLibrary){
        rightButton.push(saveAsButton);        
    }
    rightButton.push(saveButton);
    rightButton.push(closeButton); 

    
    return (
        <div>
            <MISDialog
                dialog={{
                    title: t("MIS_SID_PLAY_RULE"),
                    modal: true,                    
                    closeOnEscape: true,
                    allowMinimize: false,
                    width: 700,
                    //height: 350,                    
                    onClose: () => close()
                }}
                buttons={{
                    rightButtons: rightButton
                }}
            >
                <div className="ruleset_setting">
                    <div className="ruleset_play_detail_view">
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{ width: "150px" }}>{t("LIST_TITLE_NAME")}</th>
                                    <td>
                                        <TextInput
                                            width={500}
                                            value={name}
                                            maxLength={100}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("TABLE_TYPE_P")}</th>
                                    <td>
                                        <Select
                                            width={200}
                                            value={type}
                                            selects={playTypeList}
                                            onChange={onTypeChange}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: "#f0f2f5" }}>
                                    <td colSpan={2}>
                                        {type === "content" && (
                                            <ContentType playType={type} mode={mode} play={play} addContentList={addContentList} device={device} setSign={setSign} setDefaultDuration={setDefaultDuration} handleFilter={handleFilter} />
                                        )}
                                        {type === "playlist" && (
                                            <PlaylistType playType={type} mode={mode} play={play} addContentList={addContentList} device={device} setSign={setSign}
                                            />
                                        )}

                                    </td>
                                </tr>
                                {!isLibrary && (
                                    <tr>
                                        <td colspan="3">
                                        <span onClick={() => { setAddToLibrary(!addToLibrary) }}>
                                            <Checkbox
                                                name={t('MIS_SID_ADD_TO_LIBRARY_IMMEDIATELY')}
                                                checked={addToLibrary}
                                                onClick={() => { setAddToLibrary(!addToLibrary) }}
                                            />
                                        </span>
                                        <TooltipIcon message={t("MIS_SID_YOU_CAN_ADD_TO_RULE_LIBRARY_IMMEDIATELY_WHEN_SAVING")}/>
                                        </td>
                                    </tr>)}
                                <tr>
                                    <th>{t("TEXT_DESCRIPTION_P")}</th>
                                    <td>
                                        <TextInput
                                            width={300}
                                            value={description}
                                            maxLength={200}
                                            placeholder={t("MIS_SID_CAFEB_ENTER_DESCRIPTION")}
                                            onChange={e => {
                                                setDescription(e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default connect(
    state => ({}),
    dispatch => ({
        closePopup: id => dispatch(popupAction.closePopup(id)),
        openDetailView: type => dispatch(popupAction.openDetailView(type))
    })
)(withTranslation()(PlayPopup));
