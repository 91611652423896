import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './SaveAsTemplate.css';
import {useDispatch} from "react-redux";
import {menuAction, popupAction} from "../../../actions";
import TextInput from "../../input/TextInput";
import {toastr} from 'helper/toastrIntercept';
import {contentService} from "../../../services";
import {commonConstants, contentConstants} from "../../../constants";

const SaveAsTemplate = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {id, contentId} = props;

    const [templateName, setTemplateName] = useState('');
    const [templateGroup, setTemplateGroup] = useState({
        templateGroupId: '',
        templateGroupName: ''
    });

    const {templateGroupName} = templateGroup;

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const onClickSaveButton = () => {
        const {templateGroupId} = templateGroup;

        if(templateGroupId === '' || templateName.trim() === ''){
            toastr.error(t('COM_MIS_TEXT_YOU_MUST_FILL_IN_ALL_OF_THE_FIELDS_P'));
        }else{
            const params = {
                groupId: templateGroupId,
                templateName : templateName
            };

            contentService.convertTemplate(params, contentId).then(res => {
                if(res.status === 'Success'){
                    toastr.success(t('TEXT_SUCCESS_P'));
                    dispatch(menuAction.reloadGroup('CONTENT_TEMPLATE_BY_FOLDER', -1));
                }
            }).catch(error => {
                let errorMessage = error.errorMessage;
                switch(error.errorCode) {
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                }
                toastr.error(errorMessage);
            }).finally( () => {
                closePopup(id);
            });
        }
    };

    const onClickTemplateGroupButton = () => {
        const params = {
            groupType : contentConstants.TEMPLATE_GROUP
        };
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: 'TEMPLATE_GROUP',
            mode: 'content',
            save: saveTemplateGroup,
            params: params,
            close: () =>  dispatch(popupAction.closePopup('TEMPLATE_GROUP'))
        }));
    };

    const saveTemplateGroup = (groups) => {
        setTemplateGroup({templateGroupId: groups[0].groupId, templateGroupName: groups[0].groupName});
        dispatch(popupAction.closePopup("TEMPLATE_GROUP"));
    };

    return (
        <div>
            <MISDialog dialog={{
                title: t('MIS_SID_SERVER_SAVE_AS_TEMPLATE'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 457,
                height: 78,
                position: {x: -150, y: -200},
                onClose: () => closePopup(id),
            }}
                       buttons={{
                           rightButtons: [
                               {
                                   id: 'SAVE',
                                   title: t('COM_BUTTON_SAVE'),
                                   onClick: () => onClickSaveButton(),
                               },
                               {
                                   id: 'CANCEL',
                                   title: t('BUTTON_CANCEL_P'),
                                   onClick: () => closePopup(id),
                               }
                           ],
                       }}>
                <div className="save_as_template_popup">
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('COM_MIS_TABLE_TEMPLATE_NAME_P')}</th>
                            <td><TextInput width={265} maxLength={60} value={templateName} placeholder={t('MIS_SID_SERVER_ENTER_A_TEMPLATE_NAME')} onChange={e => setTemplateName(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <th>{t('MIS_SID_SERVER_TEMPLATE_GROUP')}</th>
                            <td>
                                <TextInput width={207} value={templateGroupName} disabled={true} readOnly={true} placeholder={t('MIS_SID_SERVER_SELECT_A_TEMPLATE_GROUP')} onChange={e => setTemplateGroup(e.target.value)}/>
                                <button class='circle_btn' onClick={onClickTemplateGroupButton}>
                                    <span></span>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default SaveAsTemplate;