import React, {Component, Fragment} from "react";
import './SyncPlaylistEditor.css';
import WhiteButton from "../../../components/button/WhiteButton";
import {withTranslation} from "react-i18next";
import SyncGroup from "./SyncGroup";
import cloneDeep from "lodash/cloneDeep";
import {secondsToString, stringToSeconds} from "../../../helper";
import _isEqual from "lodash/isEqual";
import {toastr} from 'helper/toastrIntercept';
import {DEVICE_TYPES as DeviceConstants} from "../../../constants/DevicesConstants";
import {changeSecondToHMSTime} from "../../../containers/playlist/playlistUtil"

class SyncPlaylistEditor extends Component {
    state = {
        mode: undefined,
        groups: [],
        isDeleteItemsEnabled: false,
        isDeleteGroupsEnabled: false,
        maxItemCount: 1,
        contents: undefined,
    };

    constructor(props) {
        super(props);
        this.onAddGroup = this.onAddGroup.bind(this);
        this.onDeleteGroup = this.onDeleteGroup.bind(this);
        this.onDeleteGroupItems = this.onDeleteGroupItems.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onChangeItemTime = this.onChangeItemTime.bind(this);
        this.onUpdateItemTime = this.onUpdateItemTime.bind(this);
        this.onClickItem = this.onClickItem.bind(this);
        this.onCheckSyncGroup = this.onCheckSyncGroup.bind(this);
        this.onCheckDeleteGroup = this.onCheckDeleteGroup.bind(this);
        this.onClickMinusContentCount = this.onClickMinusContentCount.bind(this);
        this.onClickPlusContentCount = this.onClickPlusContentCount.bind(this);
        this.onUpdateImageItem = this.onUpdateImageItem.bind(this);
        this.onReplaceItem = this.onReplaceItem.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {playlistInfo: {sync}} = nextProps;
        if (sync && (!_isEqual(sync, prevState.groups))) {
            return {
                groups: cloneDeep(sync)
            };
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.mode === this.props.mode) {
            if ( _isEqual(this.props.playlistInfo, prevProps.playlistInfo)) {
                return
            } else {
                this.setState({mode: undefined})
                return;
            }
        }
        if (this.props.playlistInfo.sync) {
            let groups = cloneDeep(this.props.playlistInfo.sync);
            let maxItemCount = 0;
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                if (group.isSyncCheck) {
                    if(maxItemCount < group.items.length) {
                        maxItemCount = group.items.length;
                    }
                } else {
                    if(maxItemCount < group.items.length) {
                        maxItemCount = group.items.length;
                    }
                }
            }
            this.setState({
                mode: this.props.mode,
                groups: groups,
                isDeleteItemsEnabled: false,
                isDeleteGroupsEnabled: false,
                maxItemCount: maxItemCount,
            }, this.props.updateItem(groups));
        } else if (this.props.mode === 'EDIT' &&
            (!_isEqual(prevProps.playlistInfo.contents, this.props.playlistInfo.contents) ||
                !this.props.playlistInfo.contentList && this.props.playlistInfo.contents && this.props.playlistInfo.contents.length > 0 )) { // lastMemory
            let groups = [];
            let playlistInfo = cloneDeep(this.props.playlistInfo);
            for(let i = 0; i < playlistInfo.syncGroupCount; i++) {
                let items = [];
                let itemIndexCount = 0;
                for(let j = 0; j < playlistInfo.contents.length; j++) {
                    let item = playlistInfo.contents[j];
                    if(Number(item.syncPlayId) === i + 1) {
                        item.groupIndex = i;
                        item.itemIndex = itemIndexCount;
                        item.isDeleteCheck = false;
                        item.time = {hour: secondsToString(item.contentDuration, 'HH'), min: secondsToString(item.contentDuration, 'mm'), sec: secondsToString(item.contentDuration, 'ss')};
                        items.push(item);
                        itemIndexCount ++;
                    }
                }
                groups.push({
                    groupIndex : i,
                    isSyncCheck: playlistInfo.syncGroupStatus[i] === 'Y' ? true : false,
                    isDeleteCheck: false,
                    items: items,
                })
            }
            let maxItemCount = 0;
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                if (group.isSyncCheck) {
                    if(maxItemCount < group.items.length) {
                        maxItemCount = group.items.length;
                    }
                }else {
                    if(maxItemCount < group.items.length) {
                        maxItemCount = group.items.length;
                    }
                }
            }
            this.setState({
                mode: this.props.mode,
                groups: groups,
                isDeleteItemsEnabled: false,
                isDeleteGroupsEnabled: false,
                maxItemCount: maxItemCount,
            }, this.props.updateItem(groups));
        } else if (this.props.mode === 'NEW' && !this.props.playlistInfo.sync) {
            const stateGroups = [{
                groupIndex: 0,
                isSyncCheck: true,
                isDeleteCheck: false,
                items: [{groupIndex: 0, itemIndex: 0, isDeleteCheck: false}],
            }, {
                groupIndex: 1,
                isSyncCheck: true,
                isDeleteCheck: false,
                items: [{groupIndex: 1, itemIndex: 0, isDeleteCheck: false}],
            }];

            this.setState({
                mode: this.props.mode,
                groups: stateGroups,
                isDeleteItemsEnabled: false,
                isDeleteGroupsEnabled: false,
                maxItemCount: 1,
                contents: undefined,
            }, this.props.updateItem(stateGroups));
        }
    }

    onAddGroup() {
        const {t} = this.props;
        let groups = this.state.groups;
        if (groups.length === 60) {
            toastr.error(t('MIS_TEXT_ITEM_MAXIMUM_P').replace('%d', "60"));
            return;
        }
        let groupIndexs = groups.map(group => group.groupIndex);
        let groupIndex = groupIndexs.length > 0 ? Math.max(...groupIndexs) + 1 : 0;
        for(let i = 0; i < groups.length; i++) {
            let index = groupIndexs.find(groupIndex => groupIndex === i);
            if(index === undefined) {
                groupIndex = i;
                break;
            }
        }
        let groupItems = [];
        for(let i = 0; i < this.state.maxItemCount; i++) {
            groupItems.push({groupIndex: groupIndex, itemIndex: i, isDeleteCheck: false});
        }
        groups.push({
            groupIndex: groupIndex,
            isSyncCheck: true,
            isDeleteCheck: false,
            items: groupItems,
        });
        this.setState({
            groups: groups,
        }, this.props.updateItem(groups));
    }

    onDeleteGroup() {
        let newGroups = [];
        let groups = this.state.groups;
        const {t} = this.props;

        for(let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if(!group.isDeleteCheck) {
                newGroups.push(group);
            }
        }
        if (newGroups.length === 0) {
            toastr.error(t("MIS_SID_20_AT_LEAST_ONE_GROUP_MUST_EXIST"));
            return;
        } else {
            this.setState({
                groups: newGroups,
                isDeleteGroupsEnabled: false,
            }, this.props.updateItem(newGroups));
        }
    }

    onDeleteGroupItems() {
        let groups = this.state.groups;
        for(let i = 0; i < groups.length; i++) {
            let group = groups[i];
            let newItems = [];
            let newItemIndex = 0;
            for(let j = 0; j < group.items.length; j++) {
                let item = group.items[j];
                if(!item.isDeleteCheck) {
                    item.itemIndex = newItemIndex;
                    newItems.push(item);
                    newItemIndex ++;
                }
            }
            group.items = newItems;
        }
        let maxItemCount = 0;
        for (let i = 0; i < groups.length; i++) {
            let group = this.state.groups[i];
            if (group.isSyncCheck) {
                if(maxItemCount < group.items.length) {
                    maxItemCount = group.items.length;
                }
            }
        }

        this.setState({
            groups: groups,
            maxItemCount: maxItemCount,
            isDeleteItemsEnabled: false,
        }, this.props.updateItem(this.state.groups));
    }

    onAddItem(groupIndex, itemIndex, item) {
        const {t} = this.props;
        if (this.state.maxItemCount === 200) {
            toastr.error(t('MIS_TEXT_ITEM_MAXIMUM_P').replace("%d", "200"));
            return;
        }
        if(itemIndex === item.itemIndex) {
            return;
        }
        let groups = this.state.groups;
        let selectedGroup = groups.find(group => group.groupIndex === groupIndex);
        let moveItems = selectedGroup.items.filter((item, index)=>item.isDeleteCheck);
        if (moveItems.length > 1) {
            moveItems.forEach((item) => {
                let oldIndex = item.itemIndex;
                let newIndex = itemIndex > oldIndex ? itemIndex - 1 : itemIndex;
                if (selectedGroup.isSyncCheck) {
                    groups.forEach((group) => {
                        if (group.isSyncCheck) {
                            let newItem = group.items[oldIndex];
                            group.items.splice(oldIndex, 1);
                            group.items.splice(newIndex, 0, newItem);
                            group.items.forEach((item, index) => {
                                item.groupIndex = group.groupIndex;
                                item.itemIndex = index;
                                item.isDeleteCheck = false;
                            });
                        }
                    });
                } else {
                    let newItem = selectedGroup.items[oldIndex];
                    selectedGroup.items.splice(oldIndex, 1);
                    selectedGroup.items.splice(newIndex, 0, newItem);
                    selectedGroup.items.forEach((item, index) => {
                        item.itemIndex = index;
                        item.isDeleteCheck = false;
                    });
                }
            })
        } else {
            if (item.groupIndex === undefined) {
                item.groupIndex = groupIndex;
                item.time = item.playTimeInSeconds && item.playTimeInSeconds ? {
                    hour: secondsToString(item.playTimeInSeconds, 'HH'),
                    min: secondsToString(item.playTimeInSeconds, 'mm'),
                    sec: secondsToString(item.playTimeInSeconds, 'ss'),
                } : item.mediaType === 'DLK' ? {hour: '00', min:'01', sec:'00'}: {hour: '00', min: '00', sec: '05'};
                item.playTime = item.playTimeInString ? item.playTimeInString : item.mediaType === 'DLK' ? '00:01:00' : undefined;
                if (selectedGroup.isSyncCheck) {
                    groups.forEach((group) => {
                        if (group.isSyncCheck) {
                            group.items.splice(itemIndex, 0, cloneDeep(item));
                            group.items.forEach((item, index) => {
                                item.groupIndex = group.groupIndex;
                                item.itemIndex = index;
                                item.isDeleteCheck = false;
                            });
                        }
                    });
                } else {
                    selectedGroup.items.splice(itemIndex, 0, cloneDeep(item));
                    selectedGroup.items.forEach((item, index) => {
                        item.itemIndex = index;
                        item.isDeleteCheck = false;
                    });
                }
            } else {
                let oldIndex = item.itemIndex;
                let newIndex = itemIndex > oldIndex ? itemIndex - 1 : itemIndex;
                if (selectedGroup.isSyncCheck) {
                    groups.forEach((group) => {
                        if (group.isSyncCheck) {
                            let newItem = group.items[oldIndex];
                            group.items.splice(oldIndex, 1);
                            group.items.splice(newIndex, 0, newItem);
                            group.items.forEach((item, index) => {
                                item.groupIndex = group.groupIndex;
                                item.itemIndex = index;
                                item.isDeleteCheck = false;
                            });
                        }
                    });
                } else {
                    let newItem = selectedGroup.items[oldIndex];
                    selectedGroup.items.splice(oldIndex, 1);
                    selectedGroup.items.splice(newIndex, 0, newItem);
                    selectedGroup.items.forEach((item, index) => {
                        item.itemIndex = index;
                        item.isDeleteCheck = false;
                    });
                }
            }
        }
        let maxItemCount = 0;
        for (let i = 0; i < groups.length; i++) {
            let group = this.state.groups[i];
            if (group.isSyncCheck) {
                if(maxItemCount < group.items.length) {
                    maxItemCount = group.items.length;
                }
            } else {
                if(maxItemCount < group.items.length) {
                    maxItemCount = group.items.length;
                }
            }
        }
        this.setState({
            groups: groups,
            maxItemCount: maxItemCount,
        }, this.props.updateItem(groups));
    }

    onClickItem(groupIndex, itemIndex, isDeleteCheck) {
        let groups = this.state.groups;
        let group = groups.find(group => group.groupIndex === groupIndex);
        let isSyncCheck = group.isSyncCheck;
        if(isSyncCheck) {
            for (let i = 0; i < groups.length; i++) {
                let group = this.state.groups[i];
                if (group.isSyncCheck) {
                    group.items[itemIndex].isDeleteCheck = isDeleteCheck;
                }
            }
        } else {
            group.items[itemIndex].isDeleteCheck = isDeleteCheck;
        }
        let isDeleteItemsEnabled = false;
        for(let i = 0; i < this.state.groups.length; i++) {
            let group = this.state.groups[i];
            for(let j = 0; j < group.items.length; j++) {
                let item = group.items[j];
                if(item.isDeleteCheck) {
                    isDeleteItemsEnabled = true;
                    break;
                }
            }
            if(isDeleteItemsEnabled) {
                break;
            }
        }
        this.setState({
            groups: groups,
            isDeleteItemsEnabled: isDeleteItemsEnabled,
        });
    }

    onDeleteItem(groupIndex, itemIndex) {
        let groups = this.state.groups;
        groups.map((group, index)=>{
            if (index === groupIndex) {
                group.items[itemIndex] = {groupIndex: groupIndex, itemIndex: itemIndex, isDeleteCheck:  group.items[itemIndex].isDeleteCheck};
            } else {
                if (group.isSyncCheck && group.items[itemIndex]) {
                    group.items[itemIndex].time = group.items[itemIndex].playTimeInSeconds ?
                        {hour: secondsToString(group.items[itemIndex].playTimeInSeconds, 'HH'), min: secondsToString(group.items[itemIndex].playTimeInSeconds, 'mm'), sec: secondsToString(group.items[itemIndex].playTimeInSeconds, 'ss')}
                        :  group.items[itemIndex].time;
                }
            }
        })
        this.setState({
            groups: groups
        }, this.props.updateItem(groups));
    }

    onChangeItemTime(groupIndex, itemIndex, time) {
        let groups = this.state.groups;
        let group = groups.find(group => group.groupIndex === groupIndex);
        group.items[itemIndex].time = time;
        this.setState({
            groups: groups
        });
    }

    onUpdateItemTime(groupIndex, itemIndex, time) {
        let groups = this.state.groups;
        let group = groups.find(group => group.groupIndex === groupIndex);
        let isSyncCheck = group.isSyncCheck;
        if(isSyncCheck) {
            let minPlayTime = -1;
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                if (group.isSyncCheck && group.items[itemIndex].contentId && group.items[itemIndex].mediaType !== 'IMAGE') {
                    let duration = stringToSeconds(group.items[itemIndex].playTime, 'HH:mm:ss');
                    if(minPlayTime === -1) {
                        minPlayTime = duration;
                    } else if (duration < minPlayTime) {
                        minPlayTime = duration;
                    }
                }
            }
            let timeDuration = stringToSeconds(time.hour + ":" + time.min + ":" + time.sec, 'HH:mm:ss');
            if(minPlayTime !== -1 && timeDuration > minPlayTime) {
                time = {hour: secondsToString(minPlayTime, 'HH'), min: secondsToString(minPlayTime, 'mm'), sec: secondsToString(minPlayTime, 'ss')};
            }
            groups.forEach(group => {
                if(group.isSyncCheck && group.items[itemIndex].time) {
                    group.items[itemIndex].time = cloneDeep(time);
                }
            });
        } else {
            group.items[itemIndex].time = time;
        }
        this.setState({
            groups: groups
        }, this.props.updateItem(groups));
    }

    onUpdateImageItem(groupIndex, itemIndex, imageSource) {
        let groups = this.state.groups;
        let group = groups.find(group => group.groupIndex === groupIndex);
        group.items[itemIndex].imageSource = imageSource;
        this.setState({
            groups: groups
        });
    }

    replaceImpossibleMediaType(groups, groupIndex, itemIndex, item) {
        let typeCheck = false;
        groups.filter((group, index) => index !== groupIndex).forEach((syncGroup)=>{
            if (syncGroup.items[itemIndex].contentId && syncGroup.items[itemIndex].mediaType !== item.mediaType) {
                typeCheck = true;
            }
        });
        return typeCheck;
    }

    onReplaceItem(groupIndex, itemIndex, item) {
        const {playlistInfo: {deviceType, deviceTypeVersion}, t} = this.props;
        let groups = this.state.groups;
        let selectedGroup = groups.find(group => group.groupIndex === groupIndex);
        let oldItem = selectedGroup.items[itemIndex];
        item.groupIndex = oldItem.groupIndex;
        item.itemIndex = oldItem.itemIndex;
        item.isDeleteCheck = oldItem.isDeleteCheck;
        item.time = item.playTimeInSeconds ? {hour: secondsToString(item.playTimeInSeconds, 'HH'), min: secondsToString(item.playTimeInSeconds, 'mm'), sec: secondsToString(item.playTimeInSeconds, 'ss'),} :
            item.mediaType === 'DLK' ? {hour: '00', min:'01', sec:'00'}: {hour: '00', min: '00', sec: '05'}
        item.playTime = item.playTimeInString ? item.playTimeInString : item.mediaType === 'DLK' ? '00:01:00': undefined;

        let isSyncCheck = selectedGroup.isSyncCheck;
        if (isSyncCheck && deviceType === DeviceConstants.SPLAYER && deviceTypeVersion < 4 && this.replaceImpossibleMediaType(groups, groupIndex, itemIndex, item)) {
            toastr.error(t("MIS_SID_20_FILE_TYPES_MUST_BE_ADDED_IN_THE_SAME_ORDER"));
        } else {
            selectedGroup.items.splice(itemIndex, 1);
            selectedGroup.items.splice(itemIndex, 0, item);

            if(isSyncCheck) {
                let durations = [];
                groups.forEach(group => {
                    if(group.isSyncCheck && group.items[itemIndex].time) {
                        durations.push(stringToSeconds(group.items[itemIndex].time.hour + ":" + group.items[itemIndex].time.min + ":" + group.items[itemIndex].time.sec, 'HH:mm:ss'));
                    }
                });
                let minDuration = Math.min(...durations);
                let minTime = {hour: secondsToString(minDuration, 'HH'), min: secondsToString(minDuration, 'mm'), sec: secondsToString(minDuration, 'ss')};
                groups.forEach(group => {
                    if(group.isSyncCheck && group.items[itemIndex].time) {
                        group.items[itemIndex].time = cloneDeep(minTime);
                    }
                });
            }
            this.setState({
                groups: groups
            }, this.props.updateItem(groups));
        }
    }

    onCheckSyncGroup(groupIndex) {
        let groups = this.state.groups;
        let selectedGroup = groups.find(group => group.groupIndex === groupIndex);
        let isSyncCheck = !selectedGroup.isSyncCheck;
        let groupItems = [];
        if(isSyncCheck) {
            for(let i = 0; i < this.state.maxItemCount; i++) {
                groupItems.push({groupIndex: groupIndex, itemIndex: i, isDeleteCheck: false});
            }
        } else {
            groupItems.push({groupIndex: groupIndex, itemIndex: 0, isDeleteCheck: false});
        }
        selectedGroup.isSyncCheck = isSyncCheck;
        selectedGroup.items = groupItems;
        groups.forEach((group) => {
            group.items.forEach((item) => {
                item.isDeleteCheck = false;
            });
        });
        this.setState({
            groups: groups
        }, this.props.updateItem(groups));
    }

    onCheckDeleteGroup(groupIndex) {
        let isDeleteGroupsEnabled = false;
        let groups = this.state.groups;
        let selectedGroup = groups.find(group => group.groupIndex === groupIndex);
        selectedGroup.isDeleteCheck = !selectedGroup.isDeleteCheck;
        for(let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if(group.isDeleteCheck) {
                isDeleteGroupsEnabled = true;
                break;
            }
        }
        this.setState({
            groups: groups,
            isDeleteGroupsEnabled: isDeleteGroupsEnabled,
        }, this.props.updateItem(groups));
    }

    onClickMinusContentCount() {
        if(this.state.maxItemCount < 1) {
            return;
        }
        let maxItemCount = this.state.maxItemCount - 1;
        let groups = this.state.groups;
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.isSyncCheck) {
                group.items.splice(group.items.length - 1, 1);
            }
        }
        this.setState({
            groups: groups,
            maxItemCount: maxItemCount,
        }, this.props.updateItem(groups));
    }

    onClickPlusContentCount() {
        const {t} = this.props;
        if (this.state.maxItemCount === 200) {
            toastr.error(t('MIS_TEXT_ITEM_MAXIMUM_P').replace("%d", "200"));
            return;
        }
        let maxItemCount = this.state.maxItemCount + 1;
        let groups = this.state.groups;
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.isSyncCheck) {
                group.items.push({groupIndex: group.groupIndex, itemIndex: group.items.length, isDeleteCheck: false});
            }
        }
        this.setState({
            groups: groups,
            maxItemCount: maxItemCount,
        }, this.props.updateItem(groups));
    }

    render() {
        let {t, playlistInfo, editorHeight} = this.props;
        let {mode, isDeleteItemsEnabled, isDeleteGroupsEnabled, maxItemCount} = this.state;
        if(!mode || !playlistInfo) {
            return (null);
        }
        return (
            <div className='sync-playlists' style={{height:editorHeight}}>
                <div className="playlist_top">
                    <div className="float_l" style={{marginLeft: 0}}>
                        {playlistInfo.playlistName && <div className="sync-playlists-playlist-name">{playlistInfo.playlistName}</div>}
                        <table>
                            <tbody>
                            <tr>
                                <td style={{verticalAlign: 'middle'}}>
                                    <span>{t("COM_TABLE_DURATION_P")} : {changeSecondToHMSTime(this.props.syncPlayDuration)}{"\t"}</span>
                                    <span className="mr8">{t("TABLE_CONTENT_COUNT_P")}</span></td>
                                <td>
                                    <button className="pop_circle minusContentBtn" style={{paddingTop: 10, marginTop: 7}}><span onClick={this.onClickMinusContentCount}/></button>
                                </td>
                                <td style={{verticalAlign: 'middle', paddingLeft: 18}}>
                                    <input type="text" className="light_gray" style={{width: 45}} value={maxItemCount} readOnly={true}/>
                                </td>
                                <td>
                                    <button className="pop_circle plusContentBtn" style={{paddingTop: 10, marginTop: 7}}><span onClick={this.onClickPlusContentCount}/></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="float_r">
                        <WhiteButton width={100} disable={!isDeleteItemsEnabled} name={t("COM_BUTTON_DELETE")} onClick={this.onDeleteGroupItems}/>
                        {
                            mode !== 'EDIT' &&
                            <Fragment>
                                <WhiteButton width={100} name={t("COM_IDS_STR_ADD_NEW_GROUP")} onClick={this.onAddGroup}/>
                                <WhiteButton width={100} disable={!isDeleteGroupsEnabled} name={t("TEXT_TITLE_DELETE_GROUP_P")} onClick={this.onDeleteGroup}/>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="sync-playlists-body">
                    {this.renderGroups()}
                </div>
            </div>
        )
    }

    renderGroups() {
        let {groups} = this.state;
        return groups.map((group, index) => {
            return (
                <SyncGroup key={index} group={group} onAddItem={this.onAddItem} onDeleteItem={this.onDeleteItem} onClickItem={this.onClickItem}
                           onCheckSyncGroup={this.onCheckSyncGroup} onCheckDeleteGroup={this.onCheckDeleteGroup} onUpdateItemTime={this.onUpdateItemTime}
                           onChangeItemTime={this.onChangeItemTime} onUpdateImageItem={this.onUpdateImageItem} onReplaceItem={this.onReplaceItem}/>
            )
        })
    }
}

export default withTranslation()(SyncPlaylistEditor);

