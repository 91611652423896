import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { rulesetConstants } from "../../../../constants";
import Select from "../../../selectbox/Select";
import TextInput from "../../../input/TextInput";
import { getMixString } from "../../../../language/languageUtils";

const ScreenSize = ({ handleValues, handleSign, orgValues, orgSign }) => {
  const sizeOperatorList = [];
  const { t } = useTranslation();
  Object.entries(rulesetConstants.CONDITION_SCREENSIZE_OPERATOR).forEach(
    ([key, value]) => {
      sizeOperatorList.push({
        value: value.sign,
        title: getMixString([value.sid,''])
      });
    }
  );

  const [operator, setOperator] = useState(orgSign?orgSign:sizeOperatorList[0].value);
  const [size, setSize] = useState(orgValues?orgValues[0].value:"0");

  const onTypeChange = (e, value) => {
    setOperator(value);
    handleSign(value);
  };

  const onChangeSize = e => {
    setSize(e.target.value.replace(/[^0-9]/g, ''));
    const newValue = [{ value: e.target.value.replace(/[^0-9]/g, '') }];
    handleValues(newValue);
  };

  return (
    <table className="dev_ip">
      <tr id="operator">
        <th>{t("MIS_SID_OPERATOR")}</th>
        <td style={{ width: "210px" }}>
          <Select
            width={204}
            value={operator}
            selects={sizeOperatorList}
            onChange={onTypeChange}
          />
        </td>
        <td style={{ width: "101px" }}>
          <TextInput
            width={100}            
            value={size}
            onChange={e=>onChangeSize(e)}
            onKeyDown={(e) => {return e.keyCode !== 69}} 
          ></TextInput>
        </td>
        <td>Inch</td>
      </tr>
    </table>
  );
};

export default ScreenSize;
