import React from 'react'
import {useTranslation} from 'react-i18next';
import IMAGEIcon from '../../../images/icon/file_type_icon_02.png';
import classNames from "classnames";

const IMAGE = ({onlyIcon = false, embedded = false}) => {
    const {t} = useTranslation();
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={IMAGEIcon}/>
            {
                !onlyIcon && t('DID_CMS_IMAGE')
            }
        </span>
    )
}
export default IMAGE;