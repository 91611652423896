import React, {useState} from 'react';
import AdvertisementScheduler from "./scheduler/AdvertisementScheduler";
import {ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY, scheduleConstants} from "../../../constants";
import './AdvertisementManager.css';
import {useDispatch, useSelector} from "react-redux";
import SupportedDeviceIcon from "../../../components/icon/SupportedDeviceIcon";
import WhiteButton from "../../../components/button/WhiteButton";
import {useTranslation} from "react-i18next";
import {menuAction} from "../../../actions";
import SaveContentSchedulePopup from "../../../components/popup/schedule/content/SaveContentSchedulePopup";

const AdvertisementManager = ({mode,currContent}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [saveContentSchedulePopup, setSaveContentSchedulePopup] = useState({
        show: false,
        mode: undefined
    });

    const {program, currentContentId} = useSelector(state => ({
        program : state.schedules.contents.advertisement[mode],
        currentContentId: state.menu.currentContentId
    }))

    const {adSlots} = program.channels[0].frame;

    const cancelProgram = () => {
        closeTab();
    }

    const saveProgram = () => {
        showSaveContentSchedulePopup();
    }

    const saveAsProgram = () => {
        setSaveContentSchedulePopup({
            ...saveContentSchedulePopup,
            show: true,
            mode: scheduleConstants.CONTENT_SCHEDULE_SAVE_AS_MODE
        })
    }

    const closeTab = () => {
        if (mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
            dispatch(menuAction.removeTab('NEW_ADVERTISEMENT'))
        } else {
            dispatch(menuAction.removeTab('EDIT_ADVERTISEMENT'))
        }
    }

    const showSaveContentSchedulePopup = () => {
        setSaveContentSchedulePopup({...saveContentSchedulePopup, show: !saveContentSchedulePopup.show, mode: undefined});        
    }

    return (

        <div className={'advertisement_manager_wrap'}
             style ={{display: (currentContentId === 'NEW_ADVERTISEMENT' && mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) || (currentContentId === 'EDIT_ADVERTISEMENT' && mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE) ? 'block':'none'}}>

            {
                saveContentSchedulePopup.show &&
                <SaveContentSchedulePopup
                    mode={saveContentSchedulePopup.mode !== undefined ? saveContentSchedulePopup.mode : mode}
                    stateProgram={program}
                    closePopup={showSaveContentSchedulePopup}/>
            }

            <div id="leftButtonList" style={{padding: 20}}>

                <div style={{float:'left'}}>

                    <WhiteButton id={'AD_SCHEDULE_SAVE'} name={t('COM_BUTTON_SAVE')} onClick={saveProgram}/>
                    <WhiteButton id={'AD_SCHEDULE_SAVE_AS'} name={t("BUTTON_SAVE_AS_P")} onClick={saveAsProgram}/>
                    <WhiteButton id={'AD_SCHEDULE_CANCEL'} name={t('BUTTON_CANCEL_P')} onClick={cancelProgram}/>
                </div>
                <div style={{float:'right'}}>
                    <SupportedDeviceIcon deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion} />
                </div>
            </div>

            <AdvertisementScheduler
                mode={mode}
                defaultView={ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY}
                slots={adSlots}
                deviceType={program.deviceType}
                deviceTypeVersion={program.deviceTypeVersion}
            />

        </div>
    )
}
export default AdvertisementManager;