import {useState} from "react";
import PowerThumb from "../../images/img/thumb_img_power.png";
import {
    APLAYER, DEVICE_TYPE_ANDROID, DEVICE_TYPE_FLIP, DEVICE_TYPE_I, DEVICE_TYPE_KIOSK, DEVICE_TYPE_LEDBOX,
    DEVICE_TYPE_LITE,
    DEVICE_TYPE_S, DEVICE_TYPE_SIGNAGE, DEVICE_TYPE_WPLAYER,
    FLIP,
    IPLAYER, KIOSK, LEDBOX,
    LPLAYER, RKIOSK,
    RSPLAYER,
    SIGNAGE,
    SPLAYER,
    WPLAYER
} from "../../constants";
import DeviceFlipOnImg from "../../images/device/device_flip_on.png";
import PanelOffImg from "../../images/device/panel_off.png";
import {toInteger, isNil} from "lodash";
import DeviceSVideoOnImg from "../../images/device/device_s_video_on.png";
import DeviceComponentOnImg from "../../images/device/device_component_on.png";
import DeviceAvOnImg from "../../images/device/device_av_on.png";
import DevicePcOnImg from "../../images/device/device_pc_on.png";
import DeviceDviOnImg from "../../images/device/device_dvi_on.png";
import DeviceBncOnImg from "../../images/device/device_bnc_on.png";
import DeviceHdmi1OnImg from "../../images/device/device_hdmi1_on.png";
import DeviceHdmi2OnImg from "../../images/device/device_hdmi2_on.png";
import DeviceHdmi3OnImg from "../../images/device/device_hdmi3_on.png";
import DeviceHdmi4OnImg from "../../images/device/device_hdmi4_on.png";
import DeviceDisplayPortOnImg from "../../images/device/device_display_port_on.png";
import DeviceTvOnImg from "../../images/device/device_tv_on.png";
import DeviceDTvOnImg from "../../images/device/device_dtv_on.png";
import DeviceSamsungWorkspaceImg from "../../images/device/device_samsungworkspace.png";
import DeviceNetworkStandbyImg from "../../images/device/device_network_standby.png";
import DeviceWebBrowserOnImg from "../../images/device/device_web_browser.png";
import DeviceHdbaseTOnImg from "../../images/device/device_hdbase_t.png";
import DeviceWidiOnImg from "../../images/device/device_widi.png";
import DeviceUrlLauncherOnImg from "../../images/device/device_url_launcher.png";
import DeviceKioskOnImg from "../../images/device/device_kiosk_on.png";
import DeviceExtOnImg from "../../images/device/device_ext.png";
import DeviceOnImg from "../../images/device/device_on.png";
import {deviceService} from "../../services";


const getDeviceImage = (url, defaultImg) => {
    let image = defaultImg ? defaultImg : DeviceOnImg;

    if(url && url.startsWith('/MagicInfo/restapi')) {
        return deviceService.fetchDeviceImage(url).then(res => {
            if(res.items)
                return res.items.src;
            else
                return '';
        });
    }

    return new Promise((resolve, reject) => resolve(image));
};

export const getImageUrls = (device) => {
    let captureUrl = PowerThumb, thumbUrl = PowerThumb;

    if(device.power) {
        if(device.deviceType === FLIP) {
            if(device.deviceTypeVersion === 1){
                thumbUrl = DeviceFlipOnImg;
                captureUrl = DeviceFlipOnImg;
            } else {
                return getDeviceImage(device.captureUrl, DeviceFlipOnImg).then(res => [DeviceFlipOnImg, res]);
            }
        } else if(device.panelStatus === false) {
            thumbUrl = PanelOffImg;
            captureUrl = PanelOffImg;
        } else {
            switch(toInteger(device.basicSource)) {
                case 4 :
                    return getDeviceImage(device.captureUrl, DeviceSVideoOnImg).then(res => [DeviceSVideoOnImg, res]);
                case 8 :
                    return getDeviceImage(device.captureUrl, DeviceComponentOnImg).then(res => [DeviceComponentOnImg, res]);
                case 12 :
                case 13 :
                    return getDeviceImage(device.captureUrl, DeviceAvOnImg).then(res => [DeviceAvOnImg, res]);
                case 14 :
                    return getDeviceImage(device.captureUrl, DeviceExtOnImg).then(res => [DeviceExtOnImg, res]);
                case 85 :
                    return getDeviceImage(device.captureUrl, DeviceHdbaseTOnImg).then(res => [DeviceHdbaseTOnImg, res]);
                case 20 :
                    return getDeviceImage(device.captureUrl, DevicePcOnImg).then(res => [DevicePcOnImg, res]);
                case 24 :
                case 31 :
                    return getDeviceImage(device.captureUrl, DeviceDviOnImg).then(res => [DeviceDviOnImg, res]);
                case 30 :
                    return getDeviceImage(device.captureUrl, DeviceBncOnImg).then(res => [DeviceBncOnImg, res]);
                case 33 :
                case 34 :
                    return getDeviceImage(device.captureUrl, DeviceHdmi1OnImg).then(res => [DeviceHdmi1OnImg, res]);
                case 35 :
                case 36 :
                    return getDeviceImage(device.captureUrl, DeviceHdmi2OnImg).then(res => [DeviceHdmi2OnImg, res]);
                case 49 :
                case 50 : 
                    return getDeviceImage(device.captureUrl, DeviceHdmi3OnImg).then(res => [DeviceHdmi3OnImg, res]);
                case 51 :
                case 52 : 
                    return getDeviceImage(device.captureUrl, DeviceHdmi4OnImg).then(res => [DeviceHdmi4OnImg, res]);
                case 37 :
                case 38 :
                    return getDeviceImage(device.captureUrl, DeviceDisplayPortOnImg).then(res => [DeviceDisplayPortOnImg, res]);
                case 48 :
                    return getDeviceImage(device.captureUrl, DeviceTvOnImg).then(res => [DeviceTvOnImg, res]);
                case 64 :
                    return getDeviceImage(device.captureUrl, DeviceDTvOnImg).then(res => [DeviceDTvOnImg, res]);
                case 97 :
                    return getDeviceImage(device.captureUrl, DeviceWidiOnImg).then(res => [DeviceWidiOnImg, res]);
                case 99 :
                    return getDeviceImage(device.captureUrl, DeviceUrlLauncherOnImg).then(res => [DeviceUrlLauncherOnImg, res]);
                case 101 :
                    return getDeviceImage(device.captureUrl, DeviceWebBrowserOnImg).then(res => [DeviceWebBrowserOnImg, res]);                    
                case 102 :
                    return getDeviceImage(device.captureUrl, DeviceSamsungWorkspaceImg).then(res => [DeviceSamsungWorkspaceImg, res]);
                case 103:
                    return getDeviceImage(device.captureUrl, DeviceKioskOnImg).then(res => [DeviceKioskOnImg, res]);                    
                case 1000:
                    return getDeviceImage(device.captureUrl, DeviceNetworkStandbyImg).then(res => [DeviceNetworkStandbyImg, res]);                    
                default:
                    return new Promise(resolve => {
                        let ret = [];
                        getDeviceImage(device.thumbUrl).then(res => {
                            ret.push(res);
                        }).catch(() => {
                            ret.push(thumbUrl);
                        }).finally(() => {
                            getDeviceImage(device.captureUrl).then(res => {
                                ret.push(res);
                            }).catch(() => {
                                ret.push(captureUrl);
                            }).finally(() => {
                                resolve(ret);
                            });
                        })
                    });
            }
        }
    }

    return new Promise((resolve, reject) => resolve([thumbUrl, captureUrl]));
}

export const getDeviceVersion = device => {
    switch(device.deviceType) {
        case IPLAYER:
        case APLAYER:
            return (device.applicationVersion || '').split(';')[0];
        case WPLAYER:
            return (device.playerVersion || '');
        case FLIP:
        case SIGNAGE:
        case RSPLAYER:
        default:
            return (device.firmwareVersion || '').split(';')[0];
    }
}

export const useDeviceMouseHook = () => {
    const [hovered, setHovered] = useState(false);
    const toggleHover = ho => {
        if(isNil(ho))
            setHovered(!hovered);
        else
            setHovered(ho);    
    }

    return [toggleHover, hovered];
}

export const getDeviceTypeStr = (deviceType) => {
    switch (deviceType) {
        case SPLAYER :
            return DEVICE_TYPE_S;
        case LPLAYER :
            return DEVICE_TYPE_LITE;
        case WPLAYER:
            return DEVICE_TYPE_WPLAYER;
        case SIGNAGE:
            return DEVICE_TYPE_SIGNAGE;
        case LEDBOX:
            return DEVICE_TYPE_LEDBOX;
        case IPLAYER:
            return DEVICE_TYPE_I;
        case APLAYER:
            return DEVICE_TYPE_ANDROID;
        case FLIP:
            return DEVICE_TYPE_FLIP;
        case RKIOSK:
        case KIOSK:
            return DEVICE_TYPE_KIOSK;
        default :
            return deviceType;
    }
}