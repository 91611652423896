import React, {memo} from 'react';
import classNames from 'classnames';
import WhiteButton from "../button/WhiteButton";
import ContextMenuForFrame from "./ContextMenuForFrame";

const defaultFrame = [
    {
        "id": 0,
        "frameName": "Frame 1",
        "templateType": "fixed",
        "resolution": "",
        "x": 0,
        "y": 0,
        "width": 100,
        "height": 100
    }]



const RenderFrame = ({templateId, frame = {}, wrapWidth, wrapHeight, mode, onSelectFrame, selected = -1, resolution, reloadTemplateFrames, editTemplateFrame, secondPage = false}) => {

    const isDefaultFrame = frame.frames !== undefined && frame.frames.length === 0 || false;
    const customFixedStyle = {
        cursor: 'pointer',
        border: '1px solid #ddd',
        width: wrapWidth,
        height:wrapHeight

    }

    const frameStyle = {
        cursor: 'pointer'
    }

    const selectFrame = (index) => {
        if (onSelectFrame) {
            onSelectFrame(index, frame);
        }
    }

    const frameType = (f) => {
        if (
            (f.frameType !== undefined && f.frameType === 'FIXED' ) ||
            (f.frameType !== undefined && f.frameType === 'CUSTOM_FIXED') ||
            /*(f.frameType !== undefined && f.frameType === 'CUSTOM') ||*/
            (f.templateType !== undefined && f.templateType === 'FIXED')
            ) {
            return 'FIXED';
        }
        return 'CUSTOM';


    }

    return(
        <div className={
            classNames(
                {'fixed' : isDefaultFrame},
                {
                    'fixed': !isDefaultFrame && frameType(frame.frames !== undefined && frame.frames.length > 0 ? frame.frames[0] : frame) === 'FIXED',
                    'custom': !isDefaultFrame && frameType(frame.frames !== undefined && frame.frames.length > 0 ? frame.frames[0] : frame) === 'CUSTOM'
                })}
                style={!isDefaultFrame && frame.frames !== undefined && frame.frames.length > 0 && frame.frames[0].frameType === 'CUSTOM' ? customFixedStyle : frameStyle}
        >
            {
                !secondPage && !isDefaultFrame && frame.frames !==undefined && frame.frames.length > 0 && frame.frames[0].templateId !== undefined &&
                    <ContextMenuForFrame templateId={frame.frames[0].templateId} reloadTemplateFrames={reloadTemplateFrames} editTemplateFrame={()=>editTemplateFrame(frame.frames[0].templateId)}/>
            }
            {
                isDefaultFrame &&
                    <FixedFrame on={selected === 0} frame={frame} wrapWidth={wrapWidth} wrapHeight={wrapHeight} onSelect={()=>selectFrame(0)} showFrameName={secondPage}/>
            }
            {
                !isDefaultFrame && frame.frames !== undefined && frame.frames.length > 0 &&
                frame.frames.map(
                     (f, index) =>{
                        const type = frameType(f);
                         if (type === 'CUSTOM') {
                             return (<CustomFrame key={index} on={selected === index} frame={f} wrapWidth={wrapWidth} wrapHeight={wrapHeight} onSelect={()=>selectFrame(index)} resolution={resolution} showFrameName={secondPage}/>);
                         } else if (type === 'FIXED') {
                             return (<CustomFixedFrame key={index} on={selected === index} frame={f} wrapWidth={wrapWidth} wrapHeight={wrapHeight} onSelect={()=>selectFrame(index)} showFrameName={secondPage}/>);
                         }
                    }
                )
            }
            {
                !isDefaultFrame && (frame.frames === undefined || (frame.frames !== undefined && frame.frames.length === 0)) &&
                    <FixedFrame on={selected === 0} frame={frame} wrapWidth={wrapWidth} wrapHeight={wrapHeight} onSelect={()=>selectFrame(0)}/>
            }

        </div>
    )
}
export default memo(RenderFrame);


export const FixedFrame = ({on = false, frame, wrapWidth = 209, wrapHeight = 115, onSelect, showFrameName}) => {
    const width = Math.round(wrapWidth * (frame.width * 0.01));
    const height = Math.round(wrapHeight * (frame.height * 0.01));
    const x = Math.round(wrapWidth * (frame.x * 0.01));
    const y = Math.round(wrapHeight * (frame.y * 0.01));
    const frameName = showFrameName ? frame.frameName : "";
    return (
        <div className={classNames({on: on === true})} style={{border:'1px solid #ddd', position:'absolute', cursor:'pointer',left: x, top:y, width: width, height: height, lineHeight: height + "px"}} onClick={onSelect}>{frameName}</div>
    )
}

export const CustomFrame = ({on = false, frame, wrapWidth = 209, wrapHeight = 115, onSelect, resolution, showFrameName}) => {
    const resolutionW = resolution.width;
    const resolutionH = resolution.height;
    const rel_x = Math.floor((frame.x*wrapWidth)/resolutionW);
    const rel_y = Math.floor((frame.y*wrapHeight)/resolutionH);
    const rel_w = Math.floor((frame.width*wrapWidth)/resolutionW);
    const rel_h = Math.floor((frame.height*wrapHeight)/resolutionH);
    const frameName = showFrameName ? frame.frameName : "";
    return (
        <div className={classNames({on: on === true})} style={{border:'1px solid #ddd',position:'absolute',cursor:'pointer',left:rel_x,top:rel_y,width:rel_w,height:rel_h,lineHeight:rel_h + "px"}} onClick={onSelect}>{frameName}</div>
    )
}

export const CustomFixedFrame = ({on = false, frame, wrapWidth = 209, wrapHeight = 115, onSelect, showFrameName}) => {
    const width = Math.round(wrapWidth * (frame.width * 0.01));
    const height = Math.round(wrapHeight * (frame.height * 0.01));
    const x = Math.round(wrapWidth * (frame.x * 0.01));
    const y = Math.round(wrapHeight * (frame.y * 0.01));
    const frameName = showFrameName ? frame.frameName : "";
    return (
        <div className={classNames({on: on === true})} style={{border: '1px solid #ddd',position:'absolute',cursor:'pointer',left:x,top:y,width:width,height:height, lineHeight:height + "px"}} onClick={onSelect}>{frameName}</div>
    )
}

export const RenderButtons = ({buttons}) => {
    return (
        buttons.map(
            (button, index) => <WhiteButton key={index} id={button.id} name={button.name} onClick={button.onClick}  />
        )
    )
}