import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../checkbox/Checkbox';
import './DeviceListColumnTool.css';

export const useViewColumns = (storageName, defaultViewColumns) => {
    const [viewColumns, setViewColumns] = useState(defaultViewColumns);
    const userId = useRef('');

    const onChangeViewColumns = e => {
        const newColumns = [...viewColumns];
        const dataName = e.target.getAttribute('data-name');
        const found = newColumns.find(col => col.value === dataName);

        if(found) {
            found.checked = e.target.checked
            localStorage.setItem(`${storageName}_${userId.current}`, JSON.stringify(newColumns));
            setViewColumns(newColumns);
        }
    };

    useEffect(() => {
        const {id} = JSON.parse(localStorage.getItem('user'));
        userId.current = id;
        setViewColumns(Object.assign([], JSON.parse(localStorage.getItem(`${storageName}_${userId.current}`)), defaultViewColumns));
    }, []);

    return [viewColumns, onChangeViewColumns];
};

const DeviceListColumnTool = ({selects = [], onChange}) => {
    const {t} = useTranslation();
    const [showOption, setShowOption] = useState(false);
    const optionsRef = useRef();

    useEffect(() => {
        if(showOption) {
            if(optionsRef && optionsRef.current) {
                optionsRef.current.focus();
            }
        }
    }, [showOption]);

    return (
        <div className='device_table_columntool_wrap'>
            <div className='device_table_columntool' onMouseDown={() => setShowOption(!showOption)}></div>
            {
                showOption && selects.length > 0 &&
                <div className="option_list" tabIndex={-1} onBlur={() => setShowOption(false)} ref={optionsRef}>
                    <ul>
                        {
                            selects.map((column, i) => {
                                return (
                                    <li key={`${column.value}-${i}`}>
                                        <Checkbox id={`${column.value}-${i}`} name={t(column.title)} propertyName={column.value} checked={column.checked} onChange={onChange} />
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    );
};

export default DeviceListColumnTool;