import React from 'react';
import {useTranslation} from "react-i18next";

const ContentUseFilterStatus = ({contentUseLabel}) => {
    const {t} = useTranslation();
    return (
        <span id="filter_box_contentUsingStatus">
            <span className="filter_status_content_using_status">
                <span>{t(contentUseLabel)}</span>
            </span>
        </span>
    )
}
export default ContentUseFilterStatus;