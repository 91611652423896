import React, {useCallback, useEffect, useState} from "react";
import './CMArea.css';
import {dashboardConstants} from "../../../constants/DashboardConstants";
import {dashboardService} from "../../../services";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useMISOpt} from "../../../components/misopt";
import {menuAction} from "../../../actions";
import {getMixString} from "../../../language/languageUtils";


const ResourceStatus = ({id, running, total}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const Icon = ({id}) => {
        switch(id) {
            case dashboardConstants.CONTENT_RESOURCE:
                return <div>{t('TEXT_CONTENT_P')}</div>
            case dashboardConstants.PLAYLIST_RESOURCE:
                return <div>{t('TEXT_TITLE_PLAYLIST_P')}</div>
            case dashboardConstants.RULE_RESOURCE:
                return <div>{t('MIS_SID_RULESET')}</div>
            case dashboardConstants.SCHEDULE_RESOURCE:
                return <div>{t('TEXT_TITLE_SCHEDULE_P')}</div>
        }
    }

    const getTooltip = useCallback(()=> {
        switch(id) {
            case dashboardConstants.CONTENT_RESOURCE:
                return getMixString(['MIS_SID_MIXCBFEB_RUNNING_REWOURCE', t('TEXT_CONTENT_P')]);
            case dashboardConstants.PLAYLIST_RESOURCE:
                return getMixString(['MIS_SID_MIXCBFEB_RUNNING_REWOURCE', t('TEXT_TITLE_PLAYLIST_P')]);
            case dashboardConstants.RULE_RESOURCE:
                return getMixString(['MIS_SID_MIXCBFEB_RUNNING_REWOURCE',t('MIS_SID_RULESET')]);
            case dashboardConstants.SCHEDULE_RESOURCE:
                return getMixString(['MIS_SID_MIXCBFEB_RUNNING_REWOURCE', t('TEXT_TITLE_SCHEDULE_P')]);
        }
    }, [])

    const onAllContents = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('CONTENT'))
        }).then(dispatch(menuAction.moveTab({id: 'CONTENT', title : t('TEXT_CONTENT_P'), active: true, close: true}, 'ALL_CONTENT')))
    }

    const onAllPlaylists = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('PLAYLIST'))
        }).then(dispatch(menuAction.moveTab({id: 'PLAYLIST', title : t('TEXT_TITLE_PLAYLIST_P'), active: true, close: true}, 'ALL_PLAYLIST')))
    }

    const onAllRulSets = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('RULESET'))
        }).then(dispatch(menuAction.moveTab({id: 'RULESET', title : t('MIS_SID_RULESET'), active: true, close: true}, 'ALL_RULESET')))
    }

    const onAllSchedules = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('SCHEDULE'))
        }).then(dispatch(menuAction.moveTab({id: 'SCHEDULE', title : t('TEXT_TITLE_SCHEDULE_P'), active: true, close: true})))
    }

    const handleClick = () => {
        switch (id) {
            case dashboardConstants.CONTENT_RESOURCE:
                onAllContents();
                break;
            case dashboardConstants.PLAYLIST_RESOURCE:
                onAllPlaylists();
                break;
            case dashboardConstants.RULE_RESOURCE:
                onAllRulSets();
                break;
            case dashboardConstants.SCHEDULE_RESOURCE:
                onAllSchedules();
                break;
        }
    }

    const title = getTooltip();

    return (
        <div className={'resource'} style={{cursor: 'pointer'}} title={title} onClick={handleClick}>
            <div style={{flexGrow: 1, padding: 10}}>
                <div className={'icon'}><Icon id={id} /></div>
            </div>
            <div style={{flexGrow: 3, lineHeight: '55px', verticalAlign: 'bottom', textAlign: 'right'}}>
                <span className={'running'} style={{fontSize: 36}}>{running}</span>
                <span className={'total'} style={{fontSize: 20}}>/{total}</span>
            </div>

        </div>
    )
}

const CMArea = ({}) => {

    const [resources, setResources] = useState([])
    const {t} = useTranslation();

    const {reload} = useSelector(state => state.dashboard);

    const [loading, setLoading] = useState(true);

    const fetchResource = () => {
        dashboardService.fetchResourceForCM()
            .then(
                res => {
                    if (res && res.items) {
                        setResources(res.items);
                    }
                }
            )
            .catch(e => console.log(e))
            .finally(()=>setLoading(false));

    }

    useEffect(()=> {
        fetchResource();
    }, [reload])

    return  resources.length > 0 &&  (
        <div className={'cm_area'}>
            <div className={'area_title'} style={{paddingLeft: 10}}>{t('MIS_SID_CBFEB_RESOURCE_STATUS')}</div>
            <div className={'resource_wrap'} style={{width: '100%', display: 'flex', height: 70, lineHeight: '70px', flex: 1, justifyContent: 'space-around'}}>
                {
                    resources.length > 0 &&
                        resources.map(
                            resource => <ResourceStatus key={resource.resourceType} id={resource.resourceType} running={resource.runningListCnt} total={resource.allListCnt}/>
                        )
                }
            </div>
        </div>
        )
}
export default CMArea;