import React from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from '../checkbox/Checkbox';

export const notificationOption = [
    {label:"MIS_SID_TIME_ZONE_NOT_SET", value:"has_alarm_no_timezone"},
    {label:"MIS_SID_INSUFFICIENT_CAPACITY", value:"has_alarm_not_enough_storage"},
    {label:"MIS_SID_SCHEDULE_NOT_PUBLISHED", value:"has_alarm_no_schedule_deployed"},
    {label:"MIS_SID_SCHEDULE_PUBLICATION_FAILED", value:"has_alarm_failed_schedule_deploy"},
    {label:"MIS_SID_CONTENT_DOWNLOAD_INCOMPLETE", value:"has_alarm_content_download"},
    {label:"COM_TEXT_DEFAULT_CONTENT_P", value:"filter_default_content"}
];

const NotificationFilter = ({notifications = [], onChange}) => {
    const {t} = useTranslation();

    return (
        <div className='dev_filter_type_checkbox'>
            <h5><span className="device_type" style={{background:'#60cfa6'}}></span>{t("COM_TV_NOTIFICATIONS_KR_VOICE")}</h5>
            <div style={{overflowY:'auto'}}>
                <ul style={{overflowY: 'auto'}}>
                    {
                        notificationOption.map((option, i) => {
                            return (
                                <li key={option.value} className={notifications.includes(option.value) ? "on" : ""} style={{whiteSpace: 'nowrap'}}>
                                    <Checkbox id={"notification_filter_"+option.value}
                                        name={t(option.label)}
                                        checked={notifications.includes(option.value)}
                                        onChange={e => onChange(e.target.checked, option.value)}
                                    />
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default NotificationFilter;