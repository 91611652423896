import React, {useCallback, useEffect, useRef, useState} from 'react'
import {contentService, playlistService, scheduleService} from "../../../services";
import WhiteButton from "../../../components/button/WhiteButton";
import {useTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {useDispatch, useSelector} from "react-redux";
import {popupAction, scheduleAction} from "../../../actions";
import {
    commonConstants,
    CONTENT_SCHEDULE_CONFIG,
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD,
    scheduleConstants,
    playlistConstants
} from "../../../constants";
import ContentThumbnail from "../../../components/image/ContentThumbnail";
import Checkbox from "../../../components/checkbox/Checkbox";
import './ContentScheduleSubTable.css';
import Moment from "moment";
import DateInput from "../../../components/input/DateInput";
import TimeInput from "../../../components/input/TimeInput";
import moment from "moment-timezone";
import classNames from 'classnames';
import {snakeCase, toUpper, isNil} from "lodash";
import {inputSourceToString, simpleConvertSchedule, isValidExpirationDate, setThumbNailFileId} from "../../../helper/schedule/scheduleUtils";
import {progressAction} from "../../../actions/progress.action";
import {ProgressConstants} from "../../../constants/ProgressConstants";
import {getErrorMessage} from "../../../helper/responseHandler";
import {misInit, useMISOpt} from "../../../components/misopt";
import {rulesetService} from "../../../services/ruleset.service";
import TimePicker2 from "../../../components/input/TimePicker2";
import {getSupportedContentType} from "../../../helper/schedule/scheduleUtils";


const RenderSimpleADScheduleRow = ({schedule, slot}) => {

    return (
        <tr >
            <td>
                <div style={{display: 'flex'}}>
                    <span style={{
                        display: 'table-cell',
                        lineHeight: '56px',
                        paddingLeft: 20
                    }}>{schedule.contentName}</span>
                </div>
            </td>
            <td>{slot.slotName}</td>
            <td>
                <div style={{display: 'flex'}}>
                    {
                        schedule.startDate
                    }
                    <span>~</span>
                    {
                        schedule.stopDate
                    }
                </div>

            </td>
            <td>
                <div style={{display: 'flex'}}>
                    {
                        schedule.startTime
                    }
                    <span>~</span>
                    {
                        schedule.stopTime
                    }
                </div>
            </td>
        </tr>
    )
}
const RenderSimpleScheduleRow = ({updateSchedule, oriSchedule, channel, frame, programType, deviceType, deviceTypeVersion, isFirstRender, subFrame = false}) => {
    const {misopt: {user: {dateFormat}}} = useMISOpt();
    const [schedule, setSchedule] = useState({
        ...oriSchedule
    })

    const [update, setUpdate] = useState(false);

    const {dragInfo} = useSelector(state => ({
        dragInfo : state.schedules.contents.dragInfo
    }))

    const immutableSchedule = useRef();

    if (isFirstRender) {
        immutableSchedule.current = oriSchedule;
    }

    const {misOption, misOptionSchedule, misOptionContent} = useMISOpt();

    const getPriority = (deviceType, deviceTypeVersion) => {
        let device;
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            device = misInit.devices.find(device => device.deviceType === deviceType);
        } else {
            device = misInit.devices.find(device => device.deviceType === deviceType && device.deviceTypeVersion === deviceTypeVersion)
        }
        return device !== undefined ? device.priority : 1;
    }

    const programPriority = useCallback(()=>{
        return getPriority(deviceType.toUpperCase(), deviceTypeVersion);
    }, [])

    const isSupportRuleSet = useCallback(()=> {
        const scheduleInfo = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion);        
        if (scheduleInfo !== undefined && scheduleInfo.contentSchedule !== undefined && scheduleInfo.contentSchedule.contents !== undefined)
            return scheduleInfo.contentSchedule.contents.ruleset;
        return false;
    }, [])

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const channelNo = channel.channelNo;
    const frameId = frame.frameId;
    //SF00201520 Adding permission check for frames
    let hasPermissionForFrame = frame.hasPermissionForFrame;
    if (hasPermissionForFrame === undefined)
        hasPermissionForFrame = true;

    const handleScheduleDate = (date, mode) => {
        if (schedule.repeatType === 'ONCE') {
            return false;
        }
        const updatedSchedule = Object.assign({}, schedule);
        if (mode === 'start') {
            if (date.getTime() > Moment(updatedSchedule.endDate+' 23:59:59').toDate().getTime()) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            }
            updatedSchedule.startDate = Moment(date).format('YYYY-MM-DD');
        } else {
            if (date.getTime() < Moment(updatedSchedule.startDate+' 00:00:00').toDate().getTime()) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            }
            updatedSchedule.endDate = Moment(date).format('YYYY-MM-DD');
        }
        setSchedule({...schedule, ...updatedSchedule});
    }

    const handleTime = (event, value, type) => {
        const updatedSchedule = Object.assign({}, schedule);
        if (type === 'start') {
            updatedSchedule.startTime = value;
        } else {
            updatedSchedule.endTime = value;
            if (updatedSchedule.startTime === '00:00:00' && value === '23:59:59') {
                updatedSchedule.isAllDayPlay = true;
            } else {
                updatedSchedule.isAllDayPlay = false;
            }
        }
        setSchedule({...schedule, ...updatedSchedule});

    }

    const handleExpired = () => {
        const updatedSchedule = Object.assign({}, schedule);
        updatedSchedule.isInfinitePlay = !updatedSchedule.isInfinitePlay;
        if (updatedSchedule.isInfinitePlay) {
            updatedSchedule.endDate = '2999-12-31';
        }else{
            updatedSchedule.endDate = updatedSchedule.startDate;
        }
        setSchedule({...schedule, ...updatedSchedule});
    }

    const handleAllDayPlay = () => {
        const updatedSchedule = Object.assign({}, schedule);
        updatedSchedule.isAllDayPlay = !updatedSchedule.isAllDayPlay;
        if (updatedSchedule.isAllDayPlay) {
            updatedSchedule.startTime = '00:00:00';
            updatedSchedule.endTime = '23:59:59';
        } else {
            if (immutableSchedule.current.startTime && immutableSchedule.current.endTime) {
                updatedSchedule.startTime = immutableSchedule.current.startTime;
                updatedSchedule.endTime = immutableSchedule.current.endTime;
            }
        }
        setSchedule({...schedule, ...updatedSchedule});

    }

    const getRepeatType = repeatType => {
        switch (repeatType) {
            case 'DAILY':
                return t("TEXT_DAILY_P");
            case 'ONCE':
                return t("TEXT_ONCE_P");
            case 'WEEKLY':
                return t("TEXT_WEEKLY_P");
            case 'MONTHLY':
                return t("TEXT_MONTHLY_P");
        }
    }

    const onDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!hasPermissionForFrame) {
            toastr.error(t("ERROR_NO_ROLE"));
            return false;
        }
        if (dragInfo) {
            const {id, contentType, mediaType} = dragInfo;

            if (subFrame
                && (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE
                    || contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_RULE_SET)) {
                toastr.error(t("TEXT_NOT_APPLY_P"));
                return false;
            }

            if (contentType !== scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE ) {
                if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT && !getSupportedContentType(programType, deviceType).includes(mediaType)) {
                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                    return false;
                } else if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST && !CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter.includes(Number(mediaType))) {
                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                    return false;
                }
            }

            dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
            if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST) {
                playlistService.fetchPlaylistById(id)
                    .then(
                        res=> {
                            if (res) {
                                const {playlistName, contents, deviceType : playlistDeviceType, deviceTypeVersion : playlistDeviceTypeVersion, playlistType, tags} = res.items;
                                let thumbFileId = undefined
                                if (playlistType === '5') {
                                    if (tags.length > 0 && tags[0].contents.length > 0) {
                                        thumbFileId = tags[0].contents[0].thumbFileId;
                                    }
                                } else {
                                    if (contents !== undefined && contents.length > 0) {
                                        thumbFileId = contents[0].thumbFileId;
                                    }
                                }
                                if (thumbFileId === undefined) {
                                    thumbFileId = 'NO_IMAGE'
                                }
                                const supportedPlaylistFilter = CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter;
                                if (supportedPlaylistFilter) {
                                    const found = supportedPlaylistFilter.find(type => type === parseInt(playlistType, 10));
                                    if (found === undefined) {
                                        toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
                                        return false;
                                    }
                                }
                                const priority = getPriority(playlistDeviceType.toUpperCase(), playlistDeviceTypeVersion);
                                if (programPriority() >= priority) {
                                    setSchedule({...schedule, thumbnailFileId: thumbFileId, contentName: playlistName, contentId: id, contentType: contentType});
                                    setUpdate(true);
                                } else {
                                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                    return false;
                                }

                            }
                        }
                    ).catch(e=>toastr.error(getErrorMessage(e)))
                    .finally(()=>dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))
            } else if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT) {
                contentService.fetchContentById(id)
                    .then(
                        res => {
                            if (res) {
                                const {contentName, isSupportWPlayer, isSupportAPlayer, thumbFileId, expirationDate, mediaType, deviceType : contentDeviceType, deviceTypeVersion : contentDeviceTypeVersion} = res.items;

                                const priority = getPriority(contentDeviceType.toUpperCase(), contentDeviceTypeVersion);
                                if (expirationDate !== undefined) {
                                    const expiredDate = Moment(expirationDate, 'YYYYMMDD').endOf('day');
                                    if (expiredDate < Moment().startOf('day')) {
                                        toastr.error(t("MIS_SID_EXPIRED_CONTETN_NOT_ADDED"));
                                        return false;
                                    }
                                }
                                
                                if (deviceType.toUpperCase() === 'WPLAYER') {
                                    if(!isSupportWPlayer){
                                        toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                        return false;
                                    }
                                }else if(deviceType.toUpperCase() === 'APLAYER'){
                                    if(!isSupportAPlayer){
                                        toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                        return false;
                                    }
                                }else{                                                      
                                    const {fileTypes} = misOptionContent(deviceType.toUpperCase(), deviceTypeVersion) || undefined;
                                    if (fileTypes !== undefined) {
                                        const found = fileTypes.find(type => type.mediaType === mediaType);
                                        if (found === undefined) {
                                            toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
                                            return false;
                                        }
                                    }                 
                                    if (programPriority() < priority) {
                                        toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                        return false;
                                    }
                                }  

                                setSchedule({
                                    ...schedule,
                                    thumbnailFileId: thumbFileId,
                                    contentName: contentName,
                                    contentId: id,
                                    contentType: contentType,
                                    expirationDate: expirationDate
                                });
                                setUpdate(true);                               
                                
                            }                            
                        }
                    ).catch(e => toastr.error(getErrorMessage(e)))
                    .finally(dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))

            } else if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE) {
                if (deviceType.toUpperCase() === 'IPLAYER') {
                    setSchedule({...schedule, thumbnailFileId: null, contentName: id, contentId: id, contentType: 'HW_IS'});
                    setUpdate(true);
                } else {
                    const updateScheduleForInputSource = () => {
                        setSchedule({...schedule, thumbnailFileId: null, contentName: id, contentId: id, inputSource: id, contentType: 'HW_IS', repeatType: 'WEEKLY',repeatedDayOfWeekList: ['MON','TUE','WED','THU','FRI','SAT','SUN'], endDate: '2999-12-31'});
                        setUpdate(true);
                    }
                    const scheduleOption = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion);
                    if (scheduleOption && scheduleOption.contentSchedule && scheduleOption.contentSchedule.contents && scheduleOption.contentSchedule.contents.inputSource) {
                        if (scheduleOption.contentSchedule.contents.inputSources !== undefined) {
                            const isSupportInputSource = scheduleOption.contentSchedule.contents.inputSources.some(input => {return id === input})
                            if (isSupportInputSource) {
                                updateScheduleForInputSource()
                            } else {
                                toastr.error(t("MESSAGE_DEVICE_INVALID_MODEL_P"));
                            }
                        } else {
                            updateScheduleForInputSource()
                        }
                    } else {
                        toastr.error(t("MESSAGE_DEVICE_INVALID_MODEL_P"));
                    }
                }
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            } else if (contentType === 'RULESET') {                
                if (isSupportRuleSet()) {
                    rulesetService.fetchRulesetById(id)
                        .then(
                            res=> {
                                if (res) {
                                    const {name} = res.items;
                                    const priority = getPriority(deviceType, deviceTypeVersion);
                                    
                                    if (programPriority() >= priority) {
                                        setSchedule({
                                            ...schedule,
                                            thumbnailFileId: 'RULESET_THUMBNAIL',
                                            contentName: name,
                                            contentId: id,
                                            contentType: 'RULESET'
                                        });
                                        setUpdate(true);
                                    } else {
                                        toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                    }

                                }
                            }
                        ).catch(e=> toastr.error(getErrorMessage(e)))
                        .finally(()=>dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))
                } else {
                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                }
            }

            else {
                toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            }
        }
    }

    const allowDrop = (e) => {
        e.preventDefault();
    }

    useEffect(()=> {
        updateSchedule(channelNo, frameId, schedule);
    }, [schedule])

    return (
        <tr >
            <td>
                <div style={{display: 'flex'}} onDrop={onDrop} onDragOver={allowDrop} className={classNames({'updated': update})}>
                    {
                        schedule.thumbnailFileId !== null && <ContentThumbnail id={schedule.thumbnailFileId} filename={schedule.thumbnailFileName} width={100} height={56}/>
                    }
                    <span style={{
                        display: 'table-cell',
                        lineHeight: '56px',
                        paddingLeft: 20
                    }}>{inputSourceToString(schedule.contentName)}</span>
                </div>
            </td>
            <td>{channel.channelNo}</td>
            {
                schedule.contentType !== undefined && schedule.contentType === 'HW_IS' &&
                <td>
                    {t('COM_SID_NOT_AVAILABLE_KR_USE')}
                </td>
            }
            {
                schedule.contentType !== undefined && schedule.contentType !== 'HW_IS' &&
                <td>
                    <div style={{display: 'flex'}}>
                        <DateInput
                            dateFormat={dateFormat}
                            date={Moment(schedule.startDate, 'YYYY-MM-DD').toDate()}
                            onChange={date => handleScheduleDate(date, 'start')}
                            type={'schedule_date_warp'}
                            maxDate={Moment(schedule.endDate, 'YYYY-MM-DD').toDate()}
                            disabled={schedule.repeatType === 'ONCE' || !hasPermissionForFrame}/>
                        <span>~</span>
                        <DateInput
                            dateFormat={dateFormat}
                            date={Moment(schedule.endDate, 'YYYY-MM-DD').toDate()}
                            onChange={date => handleScheduleDate(date, 'end')}
                            type={'schedule_date_warp'}
                            minDate={Moment(schedule.startDate, 'YYYY-MM-DD').toDate()}
                            disabled={schedule.isInfinitePlay || schedule.repeatType === 'ONCE' || !hasPermissionForFrame}/>
                    </div>
                    {
                        schedule.repeatType !== 'ONCE' &&
                        <div style={{marginTop: 5}}>
                            <Checkbox id={'never_expired_' + schedule.scheduleId} name={t("MIS_SID_20_NEVER_EXPIRE")} onChange={handleExpired} disabled={schedule.repeatType === 'ONCE' || !hasPermissionForFrame} checked={schedule.isInfinitePlay}/>
                        </div>
                    }
                </td>
            }


            <td>
                {getRepeatType(schedule.repeatType)}
            </td>
            <td>
                <div style={{display: 'flex'}}>
                    {
                        schedule !== undefined && schedule.startTime !== undefined &&
                        <TimePicker2 width={100} time={schedule.startTime} onChange={(event, value)=>handleTime(event, value, 'start')} disabled={schedule.isAllDayPlay || !hasPermissionForFrame}/>
                    }
                    <span>~</span>
                    {
                        schedule != undefined && schedule.endDate !== undefined &&
                        <TimePicker2 width={100} time={schedule.endTime} onChange={(event, value)=>handleTime(event, value, 'end')} disabled={schedule.isAllDayPlay || !hasPermissionForFrame}/>
                    }
                </div>
                <div style={{marginTop: 5}}>
                    <Checkbox id={'hours_24_' + schedule.scheduleId} name={t("COM_IDS_STR_24_HOURS")} checked={schedule.isAllDayPlay} onChange={handleAllDayPlay} disabled={!hasPermissionForFrame}/>
                </div>
            </td>
        </tr>
    )
}

export const ContentScheduleSubTable = ({programId, onExpandedChange}) => {

    const {t} = useTranslation();
    const [program, setProgram] = useState({});
    const dispatch = useDispatch();
    const [disableSaveAndPublish, setDisableSaveAndPublish] = useState(false);

    const programRef = useRef(true);

    const getContentSchedule = () => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        scheduleService.fetchContentScheduleById(programId).then(
            res=> {
                if (res) {
                    let program = Object.assign({}, res.items);
                    setDisableSaveAndPublish(false);
                    program.channels.map(
                        channel => {

                            if (channel.frame.events === undefined) {
                                channel.frame.events = [];
                            } else {
                                channel.frame.events !==undefined && channel.frame.events.length > 0 && channel.frame.events.map(
                                    event => {
                                        const end = moment(event.endDate+' '+event.startTime).add(event.durationInSeconds, 's');
                                        event.endTime = end.format('HH:mm:ss');
                                    }
                                )
                            }
                            channel.frame.frames !== undefined && channel.frame.frames.length > 0 && channel.frame.frames.map(
                                frame => {
                                    if (frame.events === undefined) {
                                        frame.events = [];
                                    } else {
                                        frame.events !== undefined && frame.events.map(
                                            event => {
                                                const end = moment(event.endDate+' '+event.startTime).add(event.durationInSeconds, 's');
                                                event.endTime = end.format('HH:mm:ss');
                                            }
                                        )
                                    }
                                }
                            )
                        }
                    )
                    setThumbNailFileId(program);
                    setProgram({
                        ...program
                    })

                }
            }
        ).catch(
            e => {
                toastr.error(getErrorMessage(e))
                setDisableSaveAndPublish(true);
            }
        ).finally(
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
        )

    }

    const updateSchedule = (channelNo, frameId, schedule) => {
        const channels = program.channels;
        for (const channel of channels ){
            if (channel.channelNo === channelNo) {
                if (channel.frame !== undefined && channel.frame.frameId == frameId && channel.frame.events !== undefined && channel.frame.events.length > 0) {
                    const events = []
                    for (const event of channel.frame.events) {
                        events.push(event.scheduleId === schedule.scheduleId ? schedule : event);
                    }
                    channel.frame.events = events;
                }
                if (channel.frame !== undefined && channel.frame.frames !== undefined && channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                    for (const frame of channel.frame.frames) {
                        if (frame.events !== undefined && frame.events.length > 0) {
                            const events = []
                            for (const event of frame.events) {
                                events.push(event.scheduleId === schedule.scheduleId ? schedule : event);
                            }
                            frame.events = events;
                        }
                    }
                }
            }
        }
        setProgram({
            ...program,
            channels: channels
        })
    }

    const openExpiredContentsPopup = (expirationList) => {
        if (expirationList.length > 0) {
            // show popup
            dispatch(popupAction.addPopup({
                type: playlistConstants.INCLUDE_EXPIRED_CONTENT,
                id: playlistConstants.INCLUDE_EXPIRED_CONTENT,
                expiredContentList: expirationList,
                onClose: ()=> dispatch(popupAction.closePopup(playlistConstants.INCLUDE_EXPIRED_CONTENT))
            }))
            return false
        } else {
            return true;
        }
    }

    const publishContentSchedule = () => {
        const saveProgram = Object.assign({}, program);
        const expirationList = [];

        if(isNil(saveProgram.channels)){
            return;
        }
        for(let i = 0; i < saveProgram.channels.length; i++) {
            const channel = saveProgram.channels[i];

            channel.frame.lineData = toUpper(snakeCase(channel.frame.lineData));
            if (channel.frame.events !== undefined && channel.frame.events.length > 0) {
                const events = [];
                for(let j = 0; j < channel.frame.events.length; j++) {
                    const event = channel.frame.events[j];
                    const startTime = Moment(event.startTime, 'HH:mm:ss');
                    const endTime = Moment(event.endTime, 'HH:mm:ss');

                    if(!startTime.isBefore(endTime)) {
                        toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                        return;
                    }

                    events.push(simpleConvertSchedule(event));
                }

                channel.frame.events = events;
                if(events != null && events.length > 0){
                    for (const event of events) {
                        if (isValidExpirationDate(event)) {
                            expirationList.push({contentName: event.contentName, expiredDate: event.expirationDate});
                        }
                    }
                }
            }
            if (channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                channel.frame.frames.map(
                    frame => {
                        if (frame.lineData !== undefined && frame.lineData !== '') {
                            frame.lineData = toUpper(snakeCase(frame.lineData));
                        }
                        const events = [];
                        frame.events !== undefined && frame.events.length > 0 && frame.events.map(event => events.push(simpleConvertSchedule(event)))
                        frame.events = events;
                        if(events != null && events.length > 0){
                            for (const event of events) {
                                if (isValidExpirationDate(event)) {
                                    expirationList.push({contentName: event.contentName, expiredDate: event.expirationDate});
                                }
                            }
                        }
                    }
                )
            }
        }

        if(expirationList.length > 0){
            openExpiredContentsPopup(expirationList);
        }else{
            scheduleService.updateContentSchedule(saveProgram.programId, saveProgram).then(
                res=>{
                    if (saveProgram.deviceGroups !== undefined && saveProgram.deviceGroups.length > 0) {
                        dispatch(progressAction.addProgress(ProgressConstants.MODE_CONTENT_SCHEDULE, programId, saveProgram.programName));
                    }
                    toastr.success(t("MIS_MESSAGE_SCHEDULE_SAVE_SUCCESS_P"));
                }
            ).catch(
                res=>{
                    toastr.error(res);
                }
            ).finally(
                onExpandedChange()
            )
        }
    }

    useEffect(()=>{
        getContentSchedule()
    }, [])

    useEffect(()=> ()=> {
        dispatch(scheduleAction.unLoadSimpleScheduleSubmenu(programId));
    }, [])

    useEffect(()=> {
        if (programRef.current && program !== undefined && program.channels !== undefined && program.channels.length > 0) {
            programRef.current = false;
        }
    } ,[program])

    return (
            <div className={'content_schedule_sub_table'}>

                    <table>
                        <tbody className="simpleSchTable">
                        {
                            program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                            <tr>
                                <th style={{width:300}}>{t("DID_ADMIN_NOTICE_CONTENT")}</th>
                                <th style={{width:50}}>{t("COM_TEXT_CHANNEL_P")}</th>
                                <th style={{width:180}}>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                <th style={{width:80}}>{t("TEXT_REPEAT_P")}</th>
                                <th style={{width:180}}>{t("TABLE_PLAY_TIME_P")}</th>
                            </tr>

                        }
                        {
                            program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                            <tr>
                                <th style={{width:300}}>{t("DID_ADMIN_NOTICE_CONTENT")}</th>
                                <th style={{width:50}}>{t("MIS_SID_20_SLOTS")}</th>
                                <th style={{width:180}}>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                <th style={{width:180}}>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                            </tr>
                        }

                        {
                            program !== undefined && program.channels !== undefined && program.channels.map(
                                channel => {
                                    let currentEvents = [];
                                    if (channel.frame.events !== undefined && channel.frame.events.length > 0) {
                                        {
                                            currentEvents.push(channel.frame.events.map(
                                                schedule => <RenderSimpleScheduleRow isFirstRender={programRef.current} oriSchedule={schedule} channel={channel} frame={channel.frame} updateSchedule={updateSchedule} programType={program.programType} deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion}/>
                                            ));
                                        }
                                    }
                                    if (channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                                        for (const frame of channel.frame.frames) {
                                            if (frame.events !== undefined && frame.events.length > 0) {
                                                {
                                                    currentEvents.push(frame.events.map(
                                                        schedule => <RenderSimpleScheduleRow subFrame={true} isFirstRender={programRef.current} oriSchedule={schedule} channel={channel} frame={frame} updateSchedule={updateSchedule} programType={program.programType} deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion}/>
                                                    ));
                                                }
                                            }
                                        }
                                    }
                                    if (channel.frame.adSlots !== undefined && channel.frame.adSlots.length > 0) {
                                        for (const slot of channel.frame.adSlots) {
                                            if (slot.events !== undefined && slot.events.length > 0) {
                                                currentEvents.push(slot.events.map(
                                                    schedule => <RenderSimpleADScheduleRow schedule={schedule} slot={slot} />
                                                ));
                                            }
                                        }
                                    }
                                    return currentEvents;
                                }
                            )
                        }
                        </tbody>
                    </table>

                <div style={{margin: 15}}>
                    <WhiteButton name={t("MIS_SID_SERVER_SAVE_AND_PUBLISH")} disable = {disableSaveAndPublish} onClick={publishContentSchedule}/>
                </div>
            </div>
    )

}
