import {playlistService} from '../services';
import {playlistConstants} from "../constants";

export const playlistAction = {
    initNewPlaylist,
    loadPlaylist,
    initEditPlaylist,
    updatePlaylist,
    destroyPlaylist,
    loadTrashBasketPopup,
    destroyLastMemoryPlaylist,
    destroyEmptyPlaylist,
};

function initNewPlaylist(playlistInfo) {
    return dispatch => {
        dispatch(success(playlistInfo))
    }

    function success(playlistInfo) {
        return {
            type: playlistConstants.INIT_NEW_PLAYLIST,
            playlistInfo
        }
    }
    function error() {

    }
}

function initEditPlaylist(playlist) {
    return dispatch => {
        dispatch(success(playlist));
    }

    function success(playlist) {
        return {
            type: playlistConstants.INIT_EDIT_PLAYLIST,
            playlistInfo: playlist
        }
    }
}

function loadPlaylist({playlistId}) {
    return dispatch => {
        playlistService.fetchPlaylistDetailById(playlistId)
            .then(playlist => {
                if (playlist.items.playlistType !== '5') {
                    playlistService.fetchPlaylistContentsById(playlistId).then(({items}) => {
                        dispatch(success(playlist.items, items, playlistId));
                    }).catch(
                        err => dispatch(error(err))
                    )
                } else {
                    dispatch(tagPlaylistSuccess(playlist.items, playlistId));
                }
            }).catch(
            err => dispatch(error(err))
        )
    };

    function tagPlaylistSuccess(playlist, playlistId) {
        playlist.playlistId = playlistId;

        return {
            type: playlistConstants.LOAD_PLAYLIST,
            playlist
        }
    }

    function success(playlist, contentDetail, playlistId) {
        playlist.playlistId = playlistId;
        playlist.contents = playlist.contents.map((content, index)=> {
            return {...content, ...contentDetail[index]}
        });
        return {
            type: playlistConstants.LOAD_PLAYLIST,
            playlist,
        }
    }
    function error() {

    }
}

function updatePlaylist(mode, playlist) {
    return {
        type: playlistConstants.UPDATE_PLAYLIST,
        mode,
        playlist
    }
}

function destroyPlaylist(mode) {
    return {
        type: playlistConstants.DESTROY_PLAYLIST,
        mode: mode
    }
}

function destroyLastMemoryPlaylist(mode, playlist) {
    return {
        type: playlistConstants.DESTROY_LAST_PLAYLIST,
        mode,
        playlist
    }
}

function destroyEmptyPlaylist(mode, playlist) {
    return {
        type: playlistConstants.DESTROY_EMPTY_PLAYLIST,
        mode,
        playlist
    }
}

function loadTrashBasketPopup(loadTrashBasket) {
    return {
        type: playlistConstants.LOAD_TRASH_BASKET,
        loadTrashBasket : loadTrashBasket
    }
}