import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import SearchBar from "../../../components/search/SearchBar";
import Checkbox from "../../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../../components/icon/SupportedDeviceIcon";
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import {commonConstants, SCHEDULE_FILTERS, SCHEDULE_PAGE_SIZE_OPTIONS, scheduleConstants} from "../../../constants";
import {useTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter, usePrevious, useResizeWindow} from '../../../helper';
import {snakeCase} from "lodash";
import {
    convertMessageScheduleFromServer,
    DeviceGroups,
    renderNodata
} from "../../../helper/schedule/scheduleUtils";
import {useMISOpt} from "../../../components/misopt";
import {getErrorMessage} from "../../../helper/responseHandler";
import Moment from "moment";
import DeviceRelativeTimeCell from '../../../components/device/DeviceRelativeTimeCell';
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../../helper/tables";
import {updateCache} from "../../../helper/cache/tableCache";
import { closeDetailPopup } from '../../../helper';

const AllMessageSchedule = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {getAuthority, misOption} = useMISOpt();
    const [authority] = useState(getAuthority('SCHEDULE'));

    const {misOptionSchedule} = useMISOpt();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [showNoData, setShowNoData] = useState(props.cache.isLoaded && props.cache.totalSize === 0 ? true : false);

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], groupId, searchText, devGroupIds, startModifiedDate, endModifiedDate} = filter;

        setData({...data, loading: true});
        scheduleService.fetchMessageScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            productType: 'PREMIUM',
            groupId: groupId,
            searchText: searchText,
            devGroupIds: devGroupIds,
            startModifiedDate: startModifiedDate,
            endModifiedDate: endModifiedDate,
            sortColumn: id === 'modifiedDate' ? 'MODIFY_DATE' : snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'desc' : 'asc'
        }).then(res => {
            if(res.totalCount === 0) {
                setShowNoData(true);
            }
            else {
                setShowNoData(false);
            }
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SCHEDULE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        });
    }

    const openPopup = messageId => {
        dispatch(popupAction.openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'SCHEDULE_MESSAGE', id: messageId}))
    }

    const editMessage = () => {
        const messageId = getCheckedId();
        scheduleService.fetchMessageScheduleById(messageId).then(
            res=> {
                if (res.items) {
                    const tab = {id: 'EDIT_MESSAGE', title: t("MIS_SID_20_EDIT_MESSAGE"), active: true, close: true};
                    let multiple = false;
                    const {deviceType, deviceTypeVersion} = res.items;
                    const schedule = misOptionSchedule(deviceType, parseInt(deviceTypeVersion, 10));
                    if (schedule !== undefined && schedule.messageSchedule !== undefined) {
                        multiple = schedule.messageSchedule.multiple;
                    }
                    new Promise(()=>{
                        const messages = convertMessageScheduleFromServer(res.items);
                        dispatch(scheduleAction.initEditMessage(messages));
                    }).then(
                        dispatch(menuAction.addTab(tab))
                    ).then(
                        dispatch(menuAction.loadContent('EDIT_MESSAGE', multiple))
                    )

                }
            }
        ).catch (e => toastr.error(getErrorMessage(e)))
    }

    const closePopup = (id) => {
        dispatch(popupAction.closePopup(id));
    }

    const deleteMessage = () => {
        const messageIds = getCheckedId();
        const params = {
            deleteMethod: "GO_TO_RECYCLEBIN",
            ids: messageIds
        }
        scheduleService.deleteMessageByIds(params).then(
            res=> {
                toastr.success(t("ALERT_SUCCESS_DELETE"));
                closeDetailPopup(messageIds, closePopup);
                dispatch(menuAction.reloadGroup('MESSAGE_SCHEDULE_BY_GROUP'));
            }
        ).catch(
            err=> {
                toastr.error(getErrorMessage(err));
            }
        ).finally(()=>{
            setFilter({...filter, reload: !filter.reload});
            dispatch(popupAction.closePopup(messageIds[0]));
            dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
        })
    }



    const moveMessageGroup = (groups) => {
        if (groups !==undefined && groups.length > 0) {
            const {groupId, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                const messageId = getCheckedId();
                scheduleService.moveMessageSchedule(messageId, groupId).then(
                    res => {
                        toastr.success(t("BUTTON_EDIT_GROUP_P"));
                        dispatch(menuAction.reloadGroup('MESSAGE_SCHEDULE_BY_GROUP'));
                    }
                ).catch(
                    err => {
                        toastr.error(getErrorMessage(err));
                    }
                ).finally(()=>{
                    setFilter({...filter, reload: !filter.reload});
                    dispatch(popupAction.closePopup(messageId[0]));
                })
            }
        }
    }


    const copyMessageSchedule =(messageName, messageGroupId, deviceGroups) => {
        const messageId = getCheckedId();
        const deviceGroupIds =[];
        if (deviceGroups !== undefined && deviceGroups.length > 0) {
            deviceGroups.map(g=>deviceGroupIds.push(g.groupId));
        }
        scheduleService.copyMessageSchedule(messageId, {
            messageName : messageName,
            messageGroupId: messageGroupId,
            deviceGroupIds: deviceGroupIds
        }).then(
            res=> {
                toastr.success(t("COM_TEXT_SUCCESS_P"));
                dispatch(menuAction.reloadGroup('MESSAGE_SCHEDULE_BY_GROUP'));
                setFilter({...filter, reload: !filter.reload});
            }
        ).catch(
            err => {
                toastr.error(getErrorMessage(err));
            }
        ).finally(()=>{
            dispatch(popupAction.closePopup(messageId[0]));
        })
    }

    const republishPopup = (groups) => {
        let ids = [];
        const messageId = getCheckedId();
        if (groups !==undefined && groups.length > 0) {
            ids = groups.map(group=> group.groupId);
        }
        scheduleService.rePublishMessageSchedule(messageId[0], ids)
            .then(
                res=> {
                    if (res) {
                        toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"))
                    }
                }
            )
            .catch(e=> toastr.error(getErrorMessage(e)))
            .finally(
                ()=> {
                    //dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
                    dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
                    setFilter({...filter, force: !filter.force});
                }
            )
    }

    const getCheckedId = () => {
        return selected.current.map(s => items[s].messageId);
    }

    const openDeleteMessagePopup = () => {
        const messageId = getCheckedId();
        if (messageId !== undefined && messageId.length > 0) {
            const popup = {
                type: commonConstants.COMMON_CONFIRM_POPUP,
                id: messageId[0],
                title: t("COM_BUTTON_DELETE"),
                message: t("MESSAGE_SCHEDULE_DELETE_MESSAGE_P"),
                onClickYes: ()=>deleteMessage(),
                onClose: ()=>dispatch(popupAction.closePopup(messageId[0]))
            }
            dispatch(popupAction.addPopup(popup));
        }

    }

    const openMoveGroupPopup = () => {
        const messageId = getCheckedId();
        const popup = {
            type: commonConstants.COMMON_GROUP_POPUP,
            id: messageId[0],
            mode: 'message_schedule',
            save: (groups)=>moveMessageGroup(groups),
            close: ()=>dispatch(popupAction.closePopup(messageId[0]))
        }
        dispatch(popupAction.addPopup(popup));
    }

    const openCopyMessagePopup = () => {
        const messageId = getCheckedId();
        const popup = {
            type: commonConstants.SAVE_MESSAGE_SCHEDULE,
            mode: scheduleConstants.CONTENT_SCHEDULE_COPY_MODE,
            id: messageId[0],
            onSave: (messageName, messageGroupId, deviceGroupIds)=>copyMessageSchedule(messageName, messageGroupId, deviceGroupIds),
            onClose: ()=>dispatch(popupAction.closePopup(messageId[0]))
        }

        scheduleService.fetchMessageScheduleById(messageId).then(
            res=> {
                if (res.items) {
                    const {deviceType, deviceTypeVersion} = res.items;
                    popup.deviceType =  deviceType;
                    popup.deviceTypeVersion = deviceTypeVersion;

                    dispatch(popupAction.addPopup(popup));

                }
            }
        ).catch(e => toastr.error(getErrorMessage(e)))

    }

    const getPriority = (deviceType, deviceTypeVersion) => {
        const device = misOption(deviceType, Math.floor(deviceTypeVersion));
        return device !== undefined ? device.priority : 999999;
    }

    const openRepublishPopup = () => {
        const messageId = getCheckedId();
        const id = messageId[0];
        const program = items.find(
            program => program.messageId === id
        );
        const {deviceType, deviceTypeVersion} = program;
        const priority = getPriority(deviceType, deviceTypeVersion);

        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            allowSelectOrganization: false,
            programId: messageId[0],
            allEnabled: false,
            checkbox: true,
            priority: priority,
            searchKey: 'MESSAGE_SCHEDULE_ID',
            save: (groups)=>republishPopup(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }));

    }

    const handleExport = (docType) => {
        const copiedFilter = JSON.parse(JSON.stringify(filter));
        copiedFilter.pageSize = data.totalCount;
        scheduleService.exportMessageSchedule(copiedFilter, docType ).then(
            res=> {

            }
        )
    };

    const onChangeScheduleName = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const onClickDetailSearch = (searchDetail) => {
        const groupIds = [];
        if (searchDetail.deviceGroups !== undefined && searchDetail.deviceGroups.length > 0) {
            searchDetail.deviceGroups.map(group=>groupIds.push(group.groupId));
        }
        setFilter({
            ...filter,
            page: 0,
            devGroupIds: groupIds,
            startModifiedDate: searchDetail.date.startDate !== undefined && searchDetail.date.startDate !== '' ? Moment(searchDetail.date.startDate).format('YYYY-MM-DD') : undefined,
            endModifiedDate: searchDetail.date.endDate !== undefined && searchDetail.date.endDate !== '' ? Moment(searchDetail.date.endDate).format('YYYY-MM-DD') : undefined
        });
    };

    const columns = useMemo(()=>[
        {
            accessor: "messageId",
            show: false
        },
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllMessageSchedule_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].messageId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },
        {
            Header: t("COM_TEXT_MESSAGE_NAME_P"),
            accessor: "messageName",
            Cell: (props) => <span className={"data_name"} onClick={()=>openPopup(props.original.messageId)}>{props.original.messageName}</span>,
            width: 500
        },
        {
            Header: t("BUTTON_DETAIL_P"),
            accessor: "messageStatus",
            sortable: false,
            width: 150,
            Cell: ({value}) => {
                let sidString;
                switch(value) {
                    case "noUsed":
                        sidString = t("MIS_SID_NOT_USED");
                        break;
                    case "reservation":
                        sidString = t("TEXT_RESERVATION_P");
                        break;
                    case "displaying":
                        sidString = t("TEXT_DISPLAYING_P");
                        break;
                    case "completed":
                        sidString = t("MSG_FINISHED");
                        break;
                    default:
                        sidString = value;
                        break;
                }
                return (
                    <span>{sidString}</span>
                );
            }
        },
        {
            Header: t("COM_MIS_TEXT_SUPPORTED_DEVICE_TYPE_P"),
            Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion}/>,
            sortable: false,
            width: 200
        },
        {
            Header: t("TEXT_DEVICE_GROUP_P"),
            Cell: (props) => props.original.deviceGroups !== null && props.original.deviceGroups !== undefined && props.original.deviceGroups.length > 0 ? <DeviceGroups deviceGroups={props.original.deviceGroups} width={150}/> : '',
            sortable: false,
            width: 150
        },
        {
            Header: t("MIS_TEXT_TAG_MAP_DEVICE_COUNT_P"),
            accessor: "deviceCount",
            sortable: false,
            width: 200
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifiedDate",
            Cell: ({value}) => <DeviceRelativeTimeCell value={value}/>,
            minWidth: 200
        }
    ], [items]);

    useResizeWindow(() => {
        setStyle({height: window.innerHeight - 205});
    });

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []); 

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const preGroupId = usePrevious(props.groupId);
    const preCurrContent =  usePrevious(props.currContent);

    useEffect(()=> {
        if (
            (preCurrContent !== undefined && preCurrContent !== props.currContent) ||
            (preGroupId !== undefined && preGroupId !== props.groupId)
        ) {
            const pageSize =  getPageSize(props.divide);
            setFilter({...SCHEDULE_FILTERS['ALL_MESSAGE_SCHEDULE'], groupId : props.groupId, pageSize});
        }
    }, [props.groupId])

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%', display:(props.currContent === 'ALL_MESSAGE_SCHEDULE' || props.currContent === 'MESSAGE_SCHEDULE_BY_GROUP') ? 'block':'none'}} className={'message_schedule_table_warp'}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_SCHEDULE_EDIT"} name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1}  onClick={editMessage} authority={authority.CREATE || authority.MANAGE}></WhiteButton>
                    <WhiteButton id={"CONTENT_SCHEDULE_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={openDeleteMessagePopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_MOVE"} name={t("BUTTON_MOVE_P")} disable={selectedCnt < 1} onClick={openMoveGroupPopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_COPY"} name={t("COM_IDS_EDIT_COPY")} disable={selectedCnt !== 1} onClick={openCopyMessagePopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_REPUBLISH"} name={t("MIS_SID_20_REPUBLISH")} disable={selectedCnt !== 1} onClick={openRepublishPopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={()=>handleExport('EXCEL')} />
                </div>
                <div className="rightButton">
                    <SearchBar enableDetail id="messageDetailSchedule"
                               placeholder={t("COM_TEXT_MESSAGE_NAME_P")}
                               onClickSearch={onChangeScheduleName}
                               onClickDetailSearch={onClickDetailSearch}
                               width={210}
                               searchDetails={[
                                   {title: 'deviceGroup', type: 'popup'},
                                   {title: 'date', type: 'date'}
                               ]}
                               keyword={filter.searchText}/>
                </div>
            </div>
            {
                showNoData &&
                renderNodata()
            }
            {
                !showNoData &&
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    onSortedChange={onSortedChange}
                    columns={columns}
                    style={style}
                    getTrGroupProps={getTrGroupPropsType2}
                    usePagination={true}
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        defaultPageSize: pageSize,
                        pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: props.divide
                    }}
                />
            }
        </div>
    )

}
export default AllMessageSchedule;