import React,{useState}  from 'react';
import {useDispatch} from 'react-redux';
import {menuAction} from '../../actions/';
import './MenuButton.css';
import {tabsConstants} from "../../constants/TabsConstants";

const MenuButton = ({id, active, title, icon}) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const addTab = () => {
        if(disabled)
        return;
        setDisabled(true);
        const tab = tabsConstants[id];
        dispatch(menuAction.addTab(tab));
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
    }

    return (
        <li className={(active === true)? 'on' : ''}  onClick={addTab} >
            <a id={id} className={(disabled === true)? 'disabled' : ''} href="#">
                <span className={icon}></span>
                {title}
            </a>
        </li>
    )
}
export default MenuButton