import React from 'react';
import {ProgressConstants} from "../constants/ProgressConstants";

export const progressAction = {
    addProgress,
    updateProgress,
    removeProgress,
    destroyProgress
};

function addProgress(mode, id, title) {

    return {
        type: ProgressConstants.ADD_PROGRESS,
        mode: mode,
        id: id,
        title: title
    }
}

function updateProgress(id, percent) {

    return {
        type: ProgressConstants.UPDATE_PROGRESS,
        id: id,
        percent: percent
    }
}

function removeProgress(id) {

    return {
        type: ProgressConstants.REMOVE_PROGRESS,
        id: id
    }
}

function destroyProgress() {

    return {
        type: ProgressConstants.DESTROY_PROGRESS
    }
}