import React from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from './MISDialog';
import './ConfirmPopup.css';
import {messageLine} from '../../helper';

const ConfirmPopup = ({id, title, message, width = 400, height = 200, useMessageLine = true, yesTitle, noTitle, useNoButton = true, onClickYes, onClose, textAlign}) => {
    const {t} = useTranslation();
    return (
        <div className='confirm_popup_wrap'>
            <MISDialog
                dialog={{
                    popupId: id,
                    title,
                    closeOnEscape: true,
                    modal: true,
                    width,
                    height,
                    position: {x: -width / 2, y: -height / 2},
                    onClose
                }}
                buttons={
                    useNoButton ?
                        {
                            rightButtons: [
                                {
                                    id: 'CONFIRM_YES',
                                    title: yesTitle ? yesTitle : t('BUTTON_OK_P'),
                                    onClick: onClickYes,
                                },
                                {
                                    id: 'CONFIRM_NO',
                                    title: noTitle ? noTitle : t('COM_BUTTON_CLOSE_P'),
                                    onClick: onClose,
                                }
                            ]
                        } :
                        {
                            rightButtons: [
                                {
                                    id: 'CONFIRM_YES',
                                    title: yesTitle ? yesTitle : t('BUTTON_OK_P'),
                                    onClick: onClickYes,
                                },
                            ]
                        }
                }>
                <div style={{height: (height - 150), textAlign: textAlign ? textAlign: ''}}>
                    {
                        useMessageLine ? messageLine(message) : message
                    }
                </div>
            </MISDialog>
        </div>
    );
};

export default ConfirmPopup;