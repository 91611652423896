import React, {useState, useEffect } from 'react';
import MISDialog from "../../MISDialog";
import { useTranslation } from 'react-i18next';
import Checkbox from "../../../checkbox/Checkbox";
import isNil from 'lodash/isNil';
import "./ServerManagementPopup.css";

const NotificationSettingPopup = ({onClose, mode, items, onSave, title}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState([]);
    

    const onClickSave = () => {
        onSave(selected);
    }

    useEffect(() => {
        setCheckStatus();
    }, []);


    const onChange = (e) => {
        if('checkAll' === e.target.getAttribute('id')){
            e.target.checked ? setSelected(items.map(item => item.userId)) : setSelected([]);
        } else {
            const userId = e.target.getAttribute('data-name');
            let tmpSelected = [...selected];
            if(e.target.checked){
                if(!tmpSelected.includes(userId)){
                    tmpSelected.push(userId);
                }
            } else {
                if(tmpSelected.includes(userId)){
                    tmpSelected =tmpSelected.filter(id => id !== userId );
                }
            }
            setSelected(tmpSelected);
        }
    }

    const setCheckStatus = () => {
        let selected = [];
        items.forEach((item)=>{
            const notiList = [...item.notifications];
            if(notiList.includes(mode)){
                selected.push(item.userId);
            }
        });
        setSelected(selected);
    }

    return(
        <MISDialog
            dialog={{
                title: title,
                modal: true,
                closeOnEscape: true,
                width: 970,
                height: 500,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: ()=> onClickSave(),
                        disable: false
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}>
            <div className="server_setting notifications check_list none_scrolling" style={{height: '500px', overflowY : 'auto', overflowX: 'hidden'}}>
            <table style={{width:'100%'}}>
                <colgroup>
                    <col width="20%"></col>
                    <col width="20%"></col>
                    <col width="20%"></col>
                    <col width="20%"></col>
                    <col width=""></col>
                </colgroup>
                <tbody>
                    <tr>    
                        <th>{t("TABLE_ORGANIZATION_P")}</th>
                        <th>{t("COM_DID_ADMIN_USER_USERID")}</th>
                        <th>{t("COM_TEXT_USER_NAME_P")}</th>
                        <th>{t("COM_TEXT_EMAIL_P")}</th>
                        <th><Checkbox id={'checkAll'} onChange={onChange} checked={selected.length === items.length}/>
                            {t("TABLE_ALARM_P")}</th>
                    </tr>
                    {
                        !isNil(items) && items.length > 0 &&
                        items.map((item) =>
                            <tr key={item.userId}>
                                <td>{item.organization}</td>
                                <td>{item.userId}</td>
                                <td>{item.userName}</td>
                                <td>{item.email}</td>
                                <td>
                                    <Checkbox id={item.userId} propertyName={item.userId} onChange={onChange} checked={selected.includes(item.userId)}/>
                                </td>
                            </tr>
                        )
                    }
                    
                </tbody>
            </table>

            </div>
        </MISDialog>
    )
}

export default NotificationSettingPopup;