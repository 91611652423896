import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './SSSPWebApp.css';
import {useDispatch, useSelector} from "react-redux";
import {contentAction, popupAction} from "../../../actions";
import TextInput from "../../input/TextInput";
import {toastr} from 'helper/toastrIntercept';
import ProgressBar from "../../progressbar/ProgressBar";
import {contentService} from "../../../services";
import withMISOpt from "../../misopt";
import TimeInput from "../../input/TimeInput";
import {contentConstants} from "../../../constants";
import {validateSpecialChar} from "../../../helper/contentValidator";

const SSSPWebApp = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const myContentGroupId = useSelector(({content}) => content.myContentGroupId);

    const {id, content, editMode = false} = props;
    const [ssspContent, setSsspContent] = useState({
        wgtFile: null,
        confFile: null,
        webContentName: editMode ? content.contentName : '',
        refreshInterval: editMode ? content.refreshInterval : '00:01:00'
    });

    const [buttons, setButtons] = useState({
        rightButtons: [
            {
                id: 'SAVE',
                title: t('COM_BUTTON_SAVE'),
                onClick: () => onClickSaveButton(),
            },
            {
                id: 'CANCEL',
                title: t('BUTTON_CANCEL_P'),
                onClick: () => closePopup(id),
            }
        ],
    });

    const [hasError, setHasError] = useState(false);
    const [wgtFileName, setWgtFileName] = useState("");
    const [confFileName, setConfFileName] = useState("");
    const [percentage, setPercentage] = useState(0);
    const [fetchData, setFetchData] = useState(false);

    const {wgtFile, confFile, webContentName, refreshInterval} = ssspContent;


    const wgtFileUploader = useRef();
    const confFileUploader = useRef();
    const selectedWgtFileInput = useRef();
    const selectedConfFileInput = useRef();
    const wgtFileNameDiv = useRef();
    const confFileNameDiv = useRef();
    const fileProgress = useRef();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const onClickAddWgtFile = () => {
        wgtFileUploader.current.click();
    };

    const onClickAddConfFile = () => {
        confFileUploader.current.click();
    };

    const onClickCancelWgtFile = () => {
        wgtFileUploader.current.value = '';
        setSsspContent({...ssspContent, wgtFile : null});
        wgtFileNameDiv.current.style.display = 'none';
        setWgtFileName("");
    };

    const onClickCancelConfFile = () => {
        confFileUploader.current.value = '';
        setSsspContent({...ssspContent, confFile : null});
        confFileNameDiv.current.style.display = 'none';
        setConfFileName("");
    };

    const setWgtFile = (event) => {
        if(event.target.files[0] !== undefined) {
            const ext = event.target.files[0].name.split('.').pop();
            if(ext !== 'wgt'){
                toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
                setHasError(true);
            }

            setSsspContent({...ssspContent, wgtFile : event.target.files[0]});
            setWgtFileName(event.target.files[0].name);
            wgtFileNameDiv.current.style.display = 'inline-block';
        }
    };

    const setConfFile = (event) => {
        if(event.target.files[0] !== undefined) {
            const ext = event.target.files[0].name.split('.').pop();
            if(ext !== 'xml'){
                toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
                setHasError(true);
            }

            setSsspContent({...ssspContent, confFile : event.target.files[0]});
            setConfFileName(event.target.files[0].name);
            confFileNameDiv.current.style.display = 'inline-block';
        }
    };

    const fetchUpload = (fileItem) => {
        if(webContentName === '' || wgtFile === null || confFile === null){
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
            setFetchData(false);
        }else if(!validateSpecialChar(webContentName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
            setFetchData(false);
        }else{
            setPercentage(0);
            fileProgress.current.style.display = 'block';
            let formData = new FormData();
            formData.append("files", fileItem[0]);
            formData.append("files", fileItem[1]);
            formData.append("contentType", contentConstants.CONTENT_TYPE_SAPP);
            formData.append("webContentName", webContentName);
            formData.append("refreshInterval", refreshInterval);

            if(editMode){
                formData.append("updatedContentId", content.contentId);
                formData.append("mode", contentConstants.UPDATE);
                formData.append("groupId", content.groupId);
            }else {
                formData.append("groupId", myContentGroupId === "" ? 0 : myContentGroupId);
            }

            contentService.uploadContent(formData, webContentName, (filename, loaded) => setPercentage(loaded))
                .then(res => {
                    if(res.status === 'Success'){
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        dispatch(contentAction.reloadContent(true));
                        setButtons({
                            leftButtons:[],
                            rightButtons: [
                                {
                                    id: 'OK',
                                    title: t('BUTTON_OK_P'),
                                    onClick: () => closePopup(id),
                                }
                            ]});
                    }
                })
                .catch(error => {
                    let errorMessage = error.message.errorMessage;
                    switch(error.errorCode) {
                        case '403001':
                            errorMessage = t('ERROR_NO_ROLE');
                            break;
                        default :
                            errorMessage = t('MIS_SID_FAILED_UPLOAD_WEB_APP_CHECK_FILE');
                            break;
                    }
                    toastr.error(errorMessage);
                }).finally(() => {
                    setFetchData(false);
            });
        }
    };

    const onClickSaveButton = () => {
        setFetchData(true);
    };

    const onChangeTimePicker = (value) => {
        setSsspContent({...ssspContent, refreshInterval: value});
    };

    useEffect( () => {
        if(fetchData) {
            const files = [wgtFile, confFile];
            fetchUpload(files);
        }
    }, [fetchData])

    return (
        <div>
            <MISDialog dialog={{
                title: t('MIS_SID_UPLOAD_SSSP_WEB_APP'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 550,
                height: 257,
                position: {x: -150, y: -200},
                onClose: () => closePopup(id),
            }}
                       buttons={buttons}>
                <div className="sssp_web_app_popup">
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('TEXT_CONTENT_NAME_P')}</th>
                            <td><TextInput width={300} maxLength={100} value={webContentName} onChange={e => setSsspContent({...ssspContent, webContentName: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>{t('MIS_SID_WEB_APP_PACKAGE')}</th>
                            <td><input name="wgtFile" id="wgtFile" type="file" accept='.wgt' style={{display: 'none'}} ref={wgtFileUploader} onChange={setWgtFile}/>
                                <button className="circle_btn circle_border base search float_l mr8"
                                        onClick= {onClickAddWgtFile}><span className=""></span></button>
                                <div className="tag_input float_l" ref={wgtFileNameDiv}>
                                    <input type="text" value={wgtFileName} ref={selectedWgtFileInput}/>
                                    <button onClick={onClickCancelWgtFile}></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('MIS_SID_CONFIGURATION_FILE')}</th>
                            <td><input name="xmlFile" id="xmlFile" type="file" accept='.xml' style={{display: 'none'}} ref={confFileUploader} onChange={setConfFile}/>
                                <button className="circle_btn circle_border base search float_l mr8"
                                        onClick= {onClickAddConfFile}><span className=""></span></button>
                                <div className="tag_input float_l" ref={confFileNameDiv}>
                                    <input type="text" value={confFileName} ref={selectedConfFileInput}/>
                                    <button onClick={onClickCancelConfFile}></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_LFD_SID_REFRESH_INTERVAL')}</th>
                            <td>
                                <TimeInput width={162} value={refreshInterval} onChange={(e, value)=> onChangeTimePicker(value)} style={{margin:0}}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div className="progress" style={{display: 'none'}} ref={fileProgress}>
                                    <ProgressBar width={460} height={20} background={"#e8e8e8"} fillerColor={"#4be48c"} useLabel={true} percentage={percentage}/>
                                </div>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <td colSpan="2"><span className='warringText'>{t('MIS_SID_WEB_APP_PACKAGE_SELECT_SSSP_WEB_APP_FILE')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><span className='warringText'>{t('MIS_SID_CONFIGURATION_FILE_SELECT_CONFIGURATION_FILE_SSSP_WEB_APP_FILE')}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default withMISOpt(SSSPWebApp);