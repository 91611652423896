import React from 'react';
import {useTranslation} from "react-i18next";

const ContentApprovalFilterStatus = ({approvalStatusLabel}) => {
    const {t} = useTranslation();
    return (
        <span id="filter_box_approval_filter">
            <span className="filter_status_approval_filter">
                <span>{t(approvalStatusLabel)}</span>
            </span>
        </span>
    )
}
export default ContentApprovalFilterStatus;