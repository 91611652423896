import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {isNil} from "lodash";
import {useMISOpt} from "../../../../components/misopt";
import moment from 'moment-timezone';
import {dashboardService} from "services";
import {Loading} from "../../../../components/loading/Loading";

Date.prototype.format = function(f) {
    if (!this.valueOf()) return " ";
    const d = this;
    return f.replace(/(yyyy|yy|MM|DD|E|hh|mm|ss|a\/p)/gi, function($1) {
        switch ($1) {
            case "MM": return (d.getMonth() + 1).zf(2);
            case "DD": return d.getDate().zf(2);
            default: return $1;
        }
    });
};
String.prototype.string = function(len){var s = '', i = 0; while (i++ < len) { s += this; } return s;};
String.prototype.zf = function(len){return "0".string(len - this.length) + this;};
Number.prototype.zf = function(len){return this.toString().zf(len);};


const ErrorWarningByDate = () => {

    const {t} = useTranslation();
    const [chartData, setChartData] = useState({});
    const [max, setMax] = useState(10);
    const [loading, setLoading] = useState(true);
    const [showChart, setShowChart] = useState(true);

    const {misopt: {user: {dateFormat}}} = useMISOpt();

    const _makeChart = (deviceHealthByDate) => {
        const format = dateFormat.toUpperCase().replace('YYYY-', '').replace('/YYYY', '');
        const data = {};
        const labels = [];
        const errors = [];
        const warnings = [];

        let index=  0;
        deviceHealthByDate.reverse().map(
            device => {
                if (index=== 6) {
                    labels.push(t("TEXT_TODAY_P"));
                } else {
                    labels.push(moment(device.date).format(format));
                }
                errors.push(device.error);
                warnings.push(device.warning);
                index++;
            }
        )
        data.labels = labels;
        data.datasets = [
            {
                label: 'Error',
                borderColor: 'rgba(235,92,65,1)',
                backgroundColor:'rgba(235,92,65,0.2)',
                pointRadius: 4,
                pointBackgroundColor: '#ffffff',
                data: errors,
            },
            {
                label: 'Warning',
                borderColor: 'rgba(248,173,56,1)',
                backgroundColor: 'rgba(248,173,56,0.2)',
                pointRadius: 4,
                pointBackgroundColor: '#ffffff',
                data: warnings,
            }
        ];

        setChartData(data);
    }

    const fetchData = () => {
        dashboardService.fetchDeviceHealthDailyStatus().then(res => {
            if (res && res.items) {
                _makeChart(res.items);
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            setShowChart(false);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(()=> {
        fetchData();
    }, [])

    useEffect(()=> {
        const {datasets} = chartData;
        if (!isNil(datasets) && datasets.length > 0) {
            const errorMax = Math.max(...datasets[0].data);
            const warningMax = Math.max(...datasets[1].data);
            const updateMax = Math.max(10, errorMax, warningMax);

            setMax(updateMax);
        }
    }, [chartData])

    return (
        <div style={{width: '50%', height: 240, margin: 10, display: 'inline-block'}}>
            {
                showChart ?
                    ((!loading) ?
                        <Line
                            data={chartData}
                            options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false,
                                },
                                animation: {
                                    duration: 0
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            display: false
                                        },
                                        ticks: {
                                            fontSize: 10,
                                            fontColor: '#454545'
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                            fontSize: 10,
                                            fontColor: '#909090',
                                            callback: function (value) { if (value % 1 === 0) { return value; } },
                                            max: max
                                        },
                                        gridLines: {
                                            drawBorder: false
                                        },
                                    }]
                                }
                            }}

                        /> : <div className={'area_center'}><Loading></Loading></div>)
                    : <div className={'area_center no_data'}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
            }
        </div>
    )
}
export default ErrorWarningByDate;