import {authHeader, createParams, createQuery, getLocale, handleBinDownloadResponse, handleResponse} from '../helper';
import {fetchForDownload, fetchWithToken as fetch} from '../helper/fetchIntercept.js';

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const statisticsService = {
    fetchStatisticsContents,
    fetchStatisticsDevices,
    fetchStatisticsSummary,
    fetchStatisticsDevicesFileDownload,
    fetchStatisticsContentsFileDownload,
    fetchYearList,
};

function fetchStatisticsContents(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(params)
    };
    return fetch(backendHost + '/restapi/v2.0/ems/statistics/contents', requestOptions).then(handleResponse);
}

function fetchStatisticsDevices(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(params)
    };
    return fetch(backendHost + '/restapi/v2.0/ems/statistics/devices', requestOptions).then(handleResponse);
}

function fetchStatisticsSummary(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
    };
    let params = {
        'data': data,
    };
    return fetch(backendHost + '/restapi/v2.0/ems/statistics/summary?' + createQuery(params), requestOptions).then(handleResponse);
}

function fetchStatisticsDevicesFileDownload(exportType, filter) {
    const locale = getLocale();
    return fetchForDownload(`${backendHost}/restapi/v2.0/ems/statistics/devices/export?exportType=${exportType}&locale=${locale}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleBinDownloadResponse);
}

function fetchStatisticsContentsFileDownload(exportType, filter) {
    const locale = getLocale();
    return fetchForDownload(`${backendHost}/restapi/v2.0/ems/statistics/contents/export?exportType=${exportType}&locale=${locale}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleBinDownloadResponse);
}

function fetchYearList(organizationId, type) {
    return fetch(`${backendHost}/restapi/v2.0/ems/statistics/detailreport/yearlist?organizationId=${organizationId}&type=${type}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}