import React, {useCallback, useEffect, useState} from "react";
import {dashboardConstants} from "../../../constants/DashboardConstants";
import {useTranslation} from "react-i18next";
import ConnectedInformation from "./components/ConnectedInformation";
import HealthInformation from "./components/HealthInformation";
import {dashboardService} from "../../../services";
import {isNil} from "lodash";
import {useMISOpt} from "../../../components/misopt";
import {useDispatch, useSelector} from "react-redux";
import './DMArea.css';
import {menuAction} from "../../../actions";

export const ResourceStatus = ({loading = false, id, running, total}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const getTitle = (id) => {
        switch(id) {
            case dashboardConstants.CONNECTED_RESOURCE:
                return "COM_MIS_TEXT_CONNECTED_P";
            case dashboardConstants.DISCONNECTED_RESOURCE:
                return "MIS_TEXT_DISCONNECTED_P";
            case dashboardConstants.ERROR_RESOURCE:
                return "COM_TEXT_TITLE_ERROR_P";
            case dashboardConstants.WARNING_RESOURCE:
                return "TEXT_TITLE_WARNING_P";
        }
    }

    const getBackgroundColor = (id) => {
        switch (id) {
            case dashboardConstants.CONNECTED_RESOURCE:
                return '#58C814'
            case dashboardConstants.DISCONNECTED_RESOURCE:
                return '#9DA5AE'
            case dashboardConstants.ERROR_RESOURCE:
                return '#EB5C41'
            case dashboardConstants.WARNING_RESOURCE:
                return '#F8AD38'
        }
    }

    const onAllDevices = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('DEVICE'))
        }).then(dispatch(menuAction.moveTab({id: 'DEVICE', title : t("TEXT_TITLE_DEVICE_P"), active: true, close: true})))
    }

    const onErrorDevices = () => {
        new Promise((resolve)=> {
            resolve(dispatch(menuAction.removeTab('DEVICE')))
        }).then(dispatch(menuAction.moveTab({id: 'DEVICE', title : t("TEXT_TITLE_DEVICE_P"), active: true, close: true}, 'DEVICE_HEALTH', 'DEVICE_HEALTH_ERROR')))
    }

    const onWarningDevices = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('DEVICE'))
        }).then(dispatch(menuAction.moveTab({id: 'DEVICE', title : t("TEXT_TITLE_DEVICE_P"), active: true, close: true}, 'DEVICE_HEALTH','DEVICE_HEALTH_WARNING')))
    }

    const handleClick = () => {
        switch(id) {
            case dashboardConstants.CONNECTED_RESOURCE:
            case dashboardConstants.DISCONNECTED_RESOURCE:
                onAllDevices();
                break;
            case dashboardConstants.ERROR_RESOURCE:
                onErrorDevices();
                break;
            case dashboardConstants.WARNING_RESOURCE:
                onWarningDevices();
                break;
        }
    }

    const title = getTitle(id);
    const backgroundColor = getBackgroundColor(id);

    return (
        <div className={'resource_status'} style={{backgroundColor: backgroundColor, cursor: 'pointer'}} onClick={handleClick}>
            {
                !loading &&
                    <div style={{display: 'inline-block', width: '100%'}}>
                        <div className={'resource_title'}>
                            {t(title)}
                        </div>
                        <div className={'resource_info'}>
                            {
                                (id === dashboardConstants.CONNECTED_RESOURCE || id === dashboardConstants.DISCONNECTED_RESOURCE) &&
                                <div style={{verticalAlign: 'bottom', textAlign: 'right', marginRight: 20}}>
                                    <span style={{fontSize: 42}}>{running}</span>
                                    <span style={{fontSize: 20, fontWeight: 'none'}}>/{total}</span>
                                </div>
                            }
                            {
                                (id === dashboardConstants.WARNING_RESOURCE || id === dashboardConstants.ERROR_RESOURCE) &&
                                <div style={{verticalAlign: 'bottom', textAlign: 'right', marginRight: 20}}>
                                    <div style={{fontSize: 42}}>{total}</div>
                                </div>
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

const DMArea = () => {

    const [resources, setResources] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const {reload} = useSelector(state => state.dashboard);


const {getAuthority} = useMISOpt();
    const deviceAuthority = useCallback(()=> {
        return getAuthority("DEVICE");
    }, [])

    const authority = deviceAuthority().READ;

    const fetchResourceStatusCount = () => {
        setLoading(true);
        dashboardService.fetchConnectionAndHealthStatus().then(res => {
            if (res && res.items) {
                setResources({
                    status: res.items
                })
            }
        }).catch(error => {
            setLoading(false);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(()=> {
        if (authority) {
            fetchResourceStatusCount();
        }
    }, [reload])

    return authority && (
        <div className={'dm_area'} style={{height: 690}}>
            <div className={'area_title'}>{t('COM_TV_SID_DEVICE_STATUS_KR_MIS')}</div>
            <div style={{display: 'inline-block', width: '100%'}}>
                <ConnectedInformation loading={loading} status={!isNil(resources) && resources.status} />
                <HealthInformation loading={loading} status={!isNil(resources) && resources.status} />
            </div>

        </div>
    )

}
export default DMArea;