import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import MISDialog from "../../../components/popup/MISDialog";
import TextInput from "../../../components/input/TextInput";
import Checkbox from "../../../components/checkbox/Checkbox";
import DateInput from "../../../components/input/DateInput";
import './EditNoticePopup.css';
import Moment from "moment";
import {toastr} from 'helper/toastrIntercept';
import {dashboardService} from "../../../services";
import {getErrorMessage} from "../../../helper/responseHandler";
import {deviceAction, menuAction, popupAction} from "../../../actions";
import {commonConstants} from "../../../constants";
import {connect} from "react-redux";

class EditNoticePopup extends Component {
    state = {
        notice: {},
        mode: null,
        title: null,
        startDate: null,
        endDate: null,
        important: false,
        always: false,
        content: null,
        isStateChange: false,
    };

    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.delete = this.delete.bind(this);
        this.save = this.save.bind(this);
        this.onTitleInputChanged = this.onTitleInputChanged.bind(this);
        this.onContentInputChanged = this.onContentInputChanged.bind(this);
        this.onImportCheckChanged = this.onImportCheckChanged.bind(this);
        this.onAlwaysCheckChanged = this.onAlwaysCheckChanged.bind(this);
        this.getString = this.getString.bind(this);

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    componentDidMount() {
        this.fetchGetNoticeDetail(this.props.selectId);
    }

    close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    delete() {
        const {addPopup, closePopup, t} = this.props;
        addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_DID_MAIN_NOTICE"),
                message: `${t("MIS_SID_CBFEB_ITEM_DELETED_FROM_ALL_USERS'_NOTICES")} ${t('MIS_SID_CBFEB_SURE_WANT_DELETE_SELECTED_ITEM')}`,
                onClickYes: ()=>this.fetchDeleteNotice(this.props.selectId),
                onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
            });
    }

    save() {
        let {selectId, t} = this.props;
        let {title, content, startDate, endDate, important} = this.state;
        if(!this.isValid(title) || !this.isValid(content) || !this.isValid(startDate) || !this.isValid(endDate)) {
            toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
            return;
        }
        this.fetchUpdateNotice(selectId, {
            noticeId: selectId,
            noticeTitle: title,
            noticeSubject: content,
            noticeStartDate: startDate,
            noticeEndDate: endDate,
            important: important,
        });
    }

    onChangeDate(name, date) {
        if (name === 'start') {
            let startDate = Moment(date);
            let endDate = Moment(this.state.endDate);
            if (endDate < startDate) {
                endDate = Moment(date);
            }
            this.setState({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            });
        } else if (name === 'end') {
            let endDate = Moment(date);
            let startDate = Moment(this.state.startDate);
            if (endDate < startDate) {
                startDate = Moment(date);
            }
            this.setState({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            });
        }
    }

    onTitleInputChanged(value) {
        this.setState({
            isStateChange: true,
            title: value,
        });
    }

    onContentInputChanged(value) {
        this.setState({
            isStateChange: true,
            content: value,
        });
    }

    onImportCheckChanged() {
        this.setState({
            isStateChange: true,
            important: !this.state.important,
        });
    }

    onAlwaysCheckChanged() {
        let always = !this.state.always;
        let endDate = always ? '2999-12-31' : Moment(new Date()).format('YYYY-MM-DD');
        let startDate = this.state.startDate;
        if(!always) {
            if (Moment(startDate) > Moment(endDate)) {
                startDate = Moment(new Date()).format('YYYY-MM-DD');
            }
        }
        this.setState({
            always: always,
            startDate: startDate,
            endDate: endDate,
        });
    }

    isValid(value) {
        if(!value || value.trim().length <= 0) {
            return false;
        }
        return true;
    }

    getString(value) {
        let textArea = document.createElement("textarea");
        textArea.innerHTML = value;
        return textArea.value;
    }

    fetchGetNoticeDetail = (id) => {
        dashboardService.fetchNoticeDetail(id).then(res => {
            this.setState({
                notice: res.items,
                mode: res.items.mode,
                title: res.items.title,
                startDate: res.items.startDate,
                endDate: res.items.endDate,
                important: res.items.priority === 0 ? true : false,
                always: res.items.endDate === '2999-12-31' ? true : false,
                content: res.items.subject,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchUpdateNotice = (id, data) => {
        const {t} = this.props;
        dashboardService.updateNotice(id, data).then(res => {
            toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_EDIT_P"));
            this.props.onSave();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchDeleteNotice = (id) => {
        const {t, closePopup} = this.props;
        closePopup(commonConstants.COMMON_CONFIRM_POPUP);
        dashboardService.deleteNotice(id).then(res => {
            toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
            this.props.onDelete();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    }

    render() {
        let {mode} = this.state;
        if(!mode) {
            return (null);
        }
        return (
            <Fragment>
                {
                    mode === 'view' ? this.renderViewMode() : this.renderWriteMode()
                }
            </Fragment>
        );
    };

    renderWriteMode() {
        const {t} = this.props;
        let {title, startDate, endDate, important, always, content} = this.state;
        let dateFormat = this.user ? this.user.dateFormat : "YYYY-MM-DD";
        return (
            <MISDialog
                dialog={{title: t("COM_DID_MAIN_NOTICE"), closeOnEscape: true, width: 650, height: 442, modal: true, onClose: this.close}}
                buttons={{
                    rightButtons: [
                        {id: "DELETE", title: t("COM_BUTTON_DELETE"), onClick: this.delete},
                        {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: this.save},
                        {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: this.close}
                    ]
                }}>
                <div className='edit-notice-popup'>
                    <div className='edit-notice-popup-title'>
                        <div className='edit-notice-popup-title title'>{t('COM_SID_TITLE')}</div>
                        <TextInput width={410} maxLength={50} value={this.getString(title)} onChange={(e)=> this.onTitleInputChanged(e.target.value)}/>
                        <div style={{width: '10px'}}/>
                        <Checkbox id={'edit-notice-important'} name={t("TEXT_IMPORTANT_P")} checked={important} onChange={this.onImportCheckChanged}/>
                    </div>
                    <div className='edit-notice-popup-period'>
                        <div className='edit-notice-popup-period title'>{t('TEXT_NOTICE_PERIOD_P')}</div>
                        <DateInput className='edit-notice-popup-period date' width={130} dateFormat={dateFormat} date={startDate} maxDate={new Date(endDate)} onChange={(date) => this.onChangeDate('start', date)}/>
                        <div style={{width: '10px'}}/>
                        ~
                        <div style={{width: '10px'}}/>
                        <DateInput className='edit-notice-popup-period date' width={130} dateFormat={dateFormat} date={endDate} minDate={new Date()} disabled={always} onChange={(date) => this.onChangeDate('end', date)}/>
                        <div style={{width: '40px'}}/>
                        <Checkbox id={'edit-notice-always'} name={t("TEXT_ALWAYS_P")} checked={always} onChange={() => this.onAlwaysCheckChanged()} />
                    </div>
                    <div className='edit-notice-popup-content'>
                        <div className='edit-notice-popup-content title'>{t('DID_ADMIN_NOTICE_CONTENT')}</div>
                        <textarea style={{width: '524px', height: '352px'}} maxLength="1000" value={this.getString(content)} onChange={(e)=> this.onContentInputChanged(e.target.value)}/>
                    </div>
                </div>
            </MISDialog>
        ) ;
    };

    renderViewMode() {
        const {t} = this.props;
        let {title, content} = this.state;
        return (
            <MISDialog
                dialog={{title: t("COM_DID_MAIN_NOTICE"), closeOnEscape: true, width: 640, height: 546, modal: true, onClose: this.close}}
                buttons={{
                    rightButtons: [
                        {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: this.close}
                    ]
                }}>
                <div className='edit-notice-view-popup'>
                    <div className='edit-notice-popup-title'>
                        <div className='edit-notice-popup-title title'>{t('COM_SID_TITLE')}</div>
                        <TextInput width={410} readOnly={true} value={title}/>
                    </div>
                    <div className='edit-notice-popup-content'>
                        <div className='edit-notice-popup-content title'>{t("COM_DID_MAIN_NOTICE")}</div>
                        <textarea style={{width: '468px', height: '335px'}} readOnly={true} value={content}/>
                    </div>
                </div>
            </MISDialog>
        );
    };
}

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
    })

)(withTranslation()(EditNoticePopup))