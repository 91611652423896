import React, {useEffect} from 'react';
import {
    commonConstants,
    contentConstants,
    playlistConstants,
    scheduleConstants,
    settingConstants
} from "../../constants";
import {connect, useDispatch} from "react-redux";

import {
    ConditionPopup,
    CreatePlayPopup,
    CreateRulesetPopup,
    DeleteUsedRulesetPopup,
    FormatSettingPopup,
    KeywordPopup,
    PlayPopup,
    PreviewPopup,
    RuleTreeOptionPopup,
    SaveRulesetPopup,
    SelectMultipleContentsPopup,
    VerificationDeviceListPopup,
} from "../../components/popup/ruleset";


import {
    CreatePlaylistPopup,
    PlaylistCategoryPopup,
    PlaylistCopyPopup,
    PlaylistDetailView,
    PlaylistGroupPopup,
    PlaylistNotDeletePopup,
} from "../../components/popup/playlist";

import {
    AddToPlaylist,
    ApprovePopup,
    ContentDetailView,
    ContentMultiEdit,
    ContentShare,
    CreateCIFS,
    CreateFTP,
    CreateStreaming,
    CreateWebUrl,
    CreateAds,
    ExpiredContent,
    FolderShare,
    InformationFTPCIFS,
    PollingHistory,
    SaveAsTemplate,
    SSSPWebApp,
    UploadContent,
    UploadWebContent,
    UsedContentDelete
} from '../../components/popup/content';

import {
    ContentScheduleDetailView,
    CreateContentSchedulePopup,
    EventScheduleDetailView,
    EventScheduleManagerDetailView,
    MessageScheduleDetailView,
    SaveContentSchedulePopup,
    SaveMessageSchedulePopup
} from '../../components/popup/schedule';

import {
    AddPreset,
    AddRule,
    AppliedStatusDetailPopup,
    AppliedWarningRulePopup,
    BackupPlayPopup,
    DeviceApprovalPopup,
    DeviceDetailView,
    EditAppliedStatusPopup,
    EditConnectionPopup,
    EditCustomizeAppliedStatusPopup,
    EditCustomizePopup,
    EditDevice,
    EditFlipDevice,
    EditLedCabinetPopup,
    EditPresetDetail,
    EditSoftwarePopup,
    LedSunriseSunsetTimesPopup,
    ErrorHistoryPopup,
    ErrorReportPopup,
    ExpirationDatePopup,
    LogDataCollectConfirmPopup,
    MemoPopup,
    PresetPublishStatusPopup,
    PresetResultPopup,
    PublishCustomizePopup,
    PublishSoftwarePopup,
    QuickControlPopup,
    RegisterCustomizePopup,
    RegisterSoftwarePopup,
    TvChannelPopup,
    TypeConversionPopup,
    VwlLayoutPopup,
    VwlLayoutSelectDeviceToReplacePopup,
    SelectDevicesPopup,
} from "../../components/popup/device";

import {DeviceGroupPopup,} from '../../components/popup/statistics';

import {
    AddLDAPPopup,
    AddOrganizationGroupPopup,
    AddOrganizationPopup,
    AddRolePopup,
    AddUserGroupSelectionPopup,
    AddUserLdapOrganizationSelectionPopup,
    AddUserPopup,
    ApproveUserPopup,
    ChangeMultiOrganizationPopup,
    ChangeOrganizationCopyPopup,
    ChangeOrganizationPopup,
    ChangeRolePopup,
    DevicePermissionPopup,
    EditLDAPPopup,
    EditOrganizationGroupPopup,
    EditRolePopup,
    EditUserPopup,
    LDAPDevicePermissionPopup,
    LDAPOrganizationSelectionPopup,
    LDAPUserGroupSelectionPopup,
    PasswordResetPopup,
    RejectUnapprovedPopup,
    RemoveUserPopup,
    RoleUserListPopup,
} from '../../components/popup/user';

import {
    ActivationPopup,
    DbAccessInfoPopup,
    DeviceServiceHistoryPopup,
    FreeLicensePopup,
    LicenseHistoryPopup,
    LicenseAssignPopup,
    EditLicenseAssignPopup,
    E2ECompanyInfoPopup,
    E2ELicenseHistoryPopup,
    E2ELicenseSwapPopup,
    LogDownloadPopup,
    NotificationSetting2divisionPopup,
    NotificationSettingPopup,
    OfflineActivationPopup,
    OnlineActivationPopup,
    PrivacyPolicyPopup,
    ReturnLicensePopup,
    InsightIndexSettingPopup,
    RegisterLoginPageImagePopup
} from '../../components/popup/setting';

import {
    InsightInformationPopup
} from '../../components/popup/insight';

import TagPopup from '../../components/popup/tag/TagPopup';
import EdgeServerPopup from '../../components/popup/setting/edge/EdgeServerPopup';

import CreateMessageSchedulePopup from "../../components/popup/schedule/message/CreateMessageSchedulePopup";
import AlertPopup from '../../components/popup/AlertPopup';
import CustomPopup from '../../components/popup/CustomPopup';
import ConfirmPopup from '../../components/popup/ConfirmPopup';
import GroupsPopup from "../../components/popup/GroupsPopup";
import {menuAction, popupAction, scheduleAction} from "../../actions";
import {useTranslation} from "react-i18next";
import SavePlaylistPopup from "../../components/popup/playlist/SavePlaylistPopup";
import PlaylistSettingPopup from "../../components/popup/playlist/PlaylistSettingPopup";
import TagSettingPopup from '../../components/popup/tag/TagSettingPopup';
import GetTagInformationPopup from "../../components/popup/tag/GetTagInformationPopup";
import {LoadingPopup} from "../../components/loading/LoadingPopup";
import ContentScheduleProgressPopup from "../../components/popup/schedule/content/ContentScheduleProgressPopup";
import LogoutPopup from "../../components/popup/common/LogoutPopup";
import TutorialMoviePopup from "../../components/popup/common/TutorialMoviePopup";
import RemoteServerPopup from "../../components/popup/setting/remote/RemoteServerPopup";
import DataLinkPopup from "../../components/popup/setting/datalink/DataLinkPopup";
import AssignDataLinkTablePopup from "../../components/popup/setting/datalink/AssignDataLinkTablePopup";
import RulesetDetailPopup from '../../components/popup/ruleset/RulesetDetailPopup';
import ExpirationContentPopup from "../../components/popup/playlist/ExpirationContentPopup";
import SelectDatalinkTablePopup from '../../components/popup/ruleset/condition/SelectDataLinkTablePopup';
import SelectDatalinkTagPopup from '../../components/popup/ruleset/condition/SelectDatalinkTagPopup';
import VwlLayoutSettingPopup from "../../components/popup/device/detail/VwlLayoutSettingPopup";
import TagMindMapPopup from "../../components/popup/tag/TagMindMapPopup";
import RulesetContentDownloadStatusPopup from '../../components/popup/device/detail/RulesetContentDownloadStatusPopup';
import AdvertisementGuidePopup from "../../components/popup/playlist/AdvertisementGuidePopup";
import RetrySoftwarePopup from "../../components/popup/device/software/RetrySoftwarePopup";
import {ContentPopup} from "../../components/popup";
import TagInformationForSyncPopup from "../../components/popup/schedule/content/TagInformationForSyncPopup";
import {MoreEventPopup} from "../../components/popup/schedule/content/MoreEventPopup";
import AddExternalLinkPopup from "../../components/popup/setting/external-link/AddExternalLinkPopup";
import {dashboardConstants} from "../../constants/DashboardConstants";
import NewNoticePopup from "../dashboard/popup/NewNoticePopup";
import {dashboard} from "../../reducers/dashboard.reducer";
import WidgetPopup from "../../components/popup/dashboard/WidgetsPopup";
import EditNoticePopup from "../dashboard/popup/EditNoticePopup";

const PopupContainer = ({common}) => {

    const {popups} = common;
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const KEYBOARD_ESC = 27;

    const createEventSchedule = type => {
        const tab = {id: 'NEW_EVENT', title : t("MIS_TEXT_EVENT_NEW_EVENT_P"), active: true, close: true}
        new Promise(()=>
            dispatch(scheduleAction.initEventSchedule(type))
        ).then(
            dispatch(menuAction.loadContent('NEW_EVENT'))
        ).then(
            dispatch(menuAction.addTab(tab))
        ).finally(
            dispatch(popupAction.closePopup('CREATE_EVENT_SCHEDULE'))
        )
    }

    const handleGlobalKeydown = (e) => {
        if ( e.keyCode == KEYBOARD_ESC) {
            const popupId = findPopupIdByHighestZIndex();
            if (popupId) {
                dispatch(popupAction.closePopup(popupId));
            }
        }
    }

    const findPopupIdByHighestZIndex = () => {
        let highest = 100, popup;
        const elements = document.querySelectorAll('.popups_container .ui-dialog');
        for (let i=0; i<elements.length; i++) {
            const zIndex = Number.parseInt(
                document.defaultView.getComputedStyle(elements[i], null).getPropertyValue("z-index"),
                10
            );
            if (zIndex > highest) {
                highest = zIndex;
                popup = elements[i];
            }
        }
        if (popup) {
            return popup.getAttribute('popup-id');
        }
        return null;
    }

    useEffect(()=> {
        document.addEventListener('keydown', handleGlobalKeydown);
        return () => {
            document.removeEventListener('keydown', handleGlobalKeydown);
        }
    }, [])


    return (
        <div className={'popups_container'}>
        {
            popups !== null && popups.length > 0 &&
                popups.map(
                    popup => {
                        switch (popup.type) {
                            case commonConstants.COMMON_DETAIL_VIEW:
                                switch (popup.viewType) {
                                    case 'CONTENT' :
                                        return <ContentDetailView key={popup.id} {...popup}/>
                                    case 'PLAYLIST':
                                        return <PlaylistDetailView key={popup.id} {...popup} />
                                    case 'PLAYLIST_COPY':
                                        return <PlaylistCopyPopup key={popup.id} {...popup}/>
                                    case 'PLAYLIST_GROUP_POPUP':
                                        return <PlaylistGroupPopup key={popup.id} {...popup}/>
                                    case 'PLAYLIST_NOT_DELETE_POPUP':
                                        return <PlaylistNotDeletePopup key={popup.id} {...popup}/>
                                    case 'PLAYLIST_CATEGORY_POPUP':
                                        return <PlaylistCategoryPopup key={popup.id} {...popup}/>
                                    case 'SCHEDULE_CONTENT':
                                        return <ContentScheduleDetailView key={popup.id}  {...popup} />
                                    case 'SCHEDULE_MESSAGE':
                                        return <MessageScheduleDetailView key={popup.id}  {...popup}/>
                                    case 'SCHEDULE_EVENT':
                                        return <EventScheduleDetailView key={popup.id}  {...popup}/>
                                    case 'SCHEDULE_EVENT_MANAGER':
                                        return <EventScheduleManagerDetailView key={popup.id}  {...popup}/>
                                    case 'DEVICE':
                                        return <DeviceDetailView key={popup.id}  {...popup} />
                                    case 'DEVICE_SW_APPLIED_STATUS':
                                        return <AppliedStatusDetailPopup key={popup.id}   {...popup}/>
                                    case 'RULESET':
                                        return <RulesetDetailPopup key={popup.id}  {...popup}/>
                                    default :
                                        return '';
                                }
                            case commonConstants.COMMON_GROUP_POPUP:
                                return <GroupsPopup {...popup} />
                            case commonConstants.COMMON_CONFIRM_POPUP:
                                return <ConfirmPopup {...popup} />
                            case commonConstants.COMMON_ALERT_POPUP:
                                return <AlertPopup {...popup} />
                            case commonConstants.COMMON_CUSTOM_POPUP:
                                return <CustomPopup {...popup} />
                            case commonConstants.UPLOAD_CONTENT :
                                return <UploadContent {...popup}/>;
                            case commonConstants.INFORMATION_CONTENT :
                                return <InformationFTPCIFS {...popup}/>;
                            case commonConstants.UPLOAD_WEB_CONTENT:
                                return <UploadWebContent {...popup}/>;
                            case commonConstants.CREATE_FTP:
                                return <CreateFTP {...popup}/>;
                            case commonConstants.CREATE_CIFS:
                                return <CreateCIFS {...popup}/>;
                            case commonConstants.POLLING_HISTORY:
                                return <PollingHistory {...popup}/>;
                            case commonConstants.CREATE_STREAMING:
                                return <CreateStreaming {...popup}/>;
                            case commonConstants.CREATE_WEB_URL:
                                return <CreateWebUrl {...popup}/>;
                            case commonConstants.CREATE_ADS:
                                return <CreateAds {...popup}/>;
                            case commonConstants.SSSP_WEB_APP:
                                return <SSSPWebApp {...popup}/>;
                            case commonConstants.CONTENT_APPROVE:
                                return <ApprovePopup {...popup}/>;
                            case commonConstants.ADD_TO_PLAYLIST:
                                return <AddToPlaylist {...popup}/>;
                            case commonConstants.SAVE_AS_TEMPLATE:
                                return <SaveAsTemplate {...popup}/>;
                            case commonConstants.CONTENT_MULTI_EDIT:
                                return <ContentMultiEdit {...popup}/>;
                            case commonConstants.EXPIRED_CONTENT:
                                return <ExpiredContent {...popup}/>;
                            case commonConstants.USED_CONTENT_DELETE:
                                return <UsedContentDelete {...popup}/>;
                            case commonConstants.CONTENT_SHARE:
                                return <ContentShare {...popup}/>;
                            case commonConstants.FOLDER_SHARE:
                                return <FolderShare {...popup}/>;
                            case commonConstants.CREATE_PLAYLIST:
                                return <CreatePlaylistPopup {...popup} />
                            case commonConstants.PLAYLIST_SETTING:
                                return <PlaylistSettingPopup {...popup} />
                            case commonConstants.CREATE_CONTENT_SCHEDULE:
                                return <CreateContentSchedulePopup {...popup}/>
                            case commonConstants.CREATE_MESSAGE_SCHEDULE:
                                return <CreateMessageSchedulePopup {...popup}/>
                            case commonConstants.CREATE_EVENT_SCHEDULE:
                                return createEventSchedule('new')
                            case commonConstants.UPDATE_DEVICE:
                                return <EditDevice {...popup}/>
                            case commonConstants.UPDATE_FLIP_DEVICE:
                                return <EditFlipDevice {...popup}/>
                            case commonConstants.QUICK_CONTROL_DEVICE:
                                return <QuickControlPopup {...popup} />
                            case commonConstants.TAG_SETTING:
                                return <TagSettingPopup {...popup } />
                            case commonConstants.SELECT_DEVICES:
                                return <SelectDevicesPopup {...popup } />
                            case commonConstants.APPROVE_DEVICE:
                                return <DeviceApprovalPopup {...popup} />
                            case commonConstants.REGISTER_SOFTWARE:
                                return <RegisterSoftwarePopup {...popup} />
                            case commonConstants.EDIT_SOFTWARE:
                                return <EditSoftwarePopup {...popup} />
                            case commonConstants.PUBLISH_SOFTWARE:
                                return <PublishSoftwarePopup {...popup} />
                            case commonConstants.RETRY_PUBLISH_SOFTWARE:
                                return <RetrySoftwarePopup {...popup} />
                            case commonConstants.EDIT_APPLIED_STATUS:
                                return <EditAppliedStatusPopup {...popup} />
                            case commonConstants.DEVICE_ERROR_REPORT:
                                return <ErrorReportPopup {...popup} />
                            case commonConstants.REGISTER_CUSTOMIZE:
                                return <RegisterCustomizePopup {...popup} />
                            case commonConstants.EDIT_CUSTOMIZE:
                                return <EditCustomizePopup {...popup} />
                            case commonConstants.PUBLISH_CUSTOMIZE:
                                return <PublishCustomizePopup {...popup} />
                            case commonConstants.EDIT_CUSTOMIZE_APPLIED_STATUS:
                                return <EditCustomizeAppliedStatusPopup {...popup} />
                            case commonConstants.ADD_RULE:
                                return <AddRule {...popup} />
                            case commonConstants.ADD_PRESET:
                                return <AddPreset {...popup} />
                            case commonConstants.EDIT_PRESET_DETAIL:
                                return <EditPresetDetail {...popup} />
                            case commonConstants.PRESET_PUBLISH_STATUS:
                                return <PresetPublishStatusPopup {...popup} />
                            case commonConstants.VWL_LAYOUT:
                                return <VwlLayoutPopup {...popup} />
                            case commonConstants.VWL_LAYOUT_SETTING:
                                return <VwlLayoutSettingPopup {...popup} />
                            case commonConstants.EDIT_CONNECTION:
                                return <EditConnectionPopup {...popup} />
                            case commonConstants.EDIT_LED_CABINET:
                                return <EditLedCabinetPopup {...popup} />
                            case commonConstants.APPLIED_WARNING_RULE:
                                return <AppliedWarningRulePopup {...popup} />
                            case commonConstants.EXPIRATION_DATE:
                                return <ExpirationDatePopup {...popup} />
                            case commonConstants.DEVICE_TYPE_CONVERSION:
                                return <TypeConversionPopup {...popup} />
                            case commonConstants.TV_CHANNEL:
                                return <TvChannelPopup {...popup} />
                            case commonConstants.COLLECT_LOG_DEVICE:
                                return <LogDataCollectConfirmPopup {...popup} />
                            case commonConstants.BACKUP_PLAY:
                                return <BackupPlayPopup {...popup} />
                            case commonConstants.VWL_LAYOUT_DEVICE_REPLACE:
                                return <VwlLayoutSelectDeviceToReplacePopup {...popup} />
                            case commonConstants.ERROR_HISTORY:
                                return <ErrorHistoryPopup {...popup} />
                            case commonConstants.RULESET_CONTENT_DOWNLOAD_STATUS_POPUP:
                                return <RulesetContentDownloadStatusPopup {...popup} />
                            case commonConstants.PRESET_RESULT:
                                return <PresetResultPopup {...popup} />
                            case commonConstants.DEVICE_MEMO:
                                return <MemoPopup {...popup} />
                            case commonConstants.LED_SUNRISE_SUNSET_TIMES:
                                return <LedSunriseSunsetTimesPopup {...popup} />

                                    /* for schedules */
                            case commonConstants.SAVE_MESSAGE_SCHEDULE:
                                return <SaveMessageSchedulePopup {...popup}/>
                            case commonConstants.SAVE_PLAYLIST:
                                return <SavePlaylistPopup {...popup} />
                            case playlistConstants.INCLUDE_EXPIRED_CONTENT:
                                return <ExpirationContentPopup {...popup} />


                            case contentConstants.SELECT_CONTENT_POPUP:
                                return <ContentPopup {...popup} />

                            case commonConstants.PLAYLIST_SETTING:
                                return <PlaylistSettingPopup {...popup} />
                            case commonConstants.ADVERTISEMENT_GUIDE:
                                return <AdvertisementGuidePopup  {...popup} />
                            case playlistConstants.LOAD_TAG_INFORMATION:
                                return <GetTagInformationPopup {...popup} />

                            case commonConstants.SAVE_MESSAGE_SCHEDULE:
                                return <SaveMessageSchedulePopup {...popup}/>
                            case commonConstants.SAVE_CONTENT_SCHEDULE:
                                return <SaveContentSchedulePopup {...popup} />
                            case commonConstants.CONTENT_SCHEDULE_DETAIL_PROGRESS_POPUP:
                                return <ContentScheduleProgressPopup {...popup}/>

                            case commonConstants.LOADING_POPUP:
                                return <LoadingPopup {...popup} />
                            case scheduleConstants.TAG_INFO_POPUP:
                                return <TagInformationForSyncPopup {...popup}/>

                            /* Ruleset */
                            case commonConstants.CREATE_RULESET:
                                return <CreateRulesetPopup {...popup} />
                            case commonConstants.SAVE_RULESET:
                                return <SaveRulesetPopup {...popup} />
                            case commonConstants.USED_RULESET_DELETE:
                                return <DeleteUsedRulesetPopup {...popup} />
                            case commonConstants.RULESET_CONDITION_POPUP:
                                return <ConditionPopup {...popup}/>
                            case commonConstants.CREATE_PLAY_POPUP:
                                return <CreatePlayPopup {...popup} />
                            case commonConstants.RULESET_PLAY_POPUP:
                              return <PlayPopup {...popup} />
                            case commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP:
                                return <SelectMultipleContentsPopup {...popup} />                            
                            case commonConstants.RULESET_RULETREE_OPTION_POPUP:
                                return <RuleTreeOptionPopup {...popup} />
                            case commonConstants.RULESET_PLAY_FORMAT_SETTING_POPUP:
                                return <FormatSettingPopup {...popup} />
                            case commonConstants.RULESET_DETIAL_POPUP:
                                return <RulesetDetailPopup {...popup} />
                            case commonConstants.RULESET_PLAY_KEYWORD_SETTING_POPUP:
                                return <KeywordPopup {...popup} />
                            case commonConstants.RULESET_VERIFICATION_DEVICE_LIST_POPUP:
                                return <VerificationDeviceListPopup {...popup} />
                            case commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP :
                                return <SelectDatalinkTablePopup {...popup} />
                            case commonConstants.RULESET_SELECT_DATALINK_TAG_POPUP  :
                                return <SelectDatalinkTagPopup {...popup} />
                            case commonConstants.RULESET_CONDITION_PREVIEW_POPUP  :
                                return <PreviewPopup {...popup} />

                            /* Statistics */
                            case commonConstants.DEVICE_GROUP_SELECTION:
                                return <DeviceGroupPopup {...popup}/>

                            /* User */
                            case commonConstants.LOGOUT_POPUP:
                                return <LogoutPopup />
                            case commonConstants.TUTORIAL_MOVIE_POPUP:
                                return <TutorialMoviePopup {...popup}/>
                            case commonConstants.ADD_USER:
                                return <AddUserPopup {...popup} />
                            case commonConstants.ADD_USER_GROUP_SELECTION:
                                return <AddUserGroupSelectionPopup {...popup} />
                            case commonConstants.ADD_USER_LDAP_ORGANIZATION_SELECTION:
                                return <AddUserLdapOrganizationSelectionPopup {...popup} />
                            case commonConstants.ADD_ORGANIZATION:
                                return <AddOrganizationPopup {...popup} />
                            case commonConstants.REMOVE_USER:
                                return <RemoveUserPopup {...popup} />
                            case commonConstants.EDIT_USER:
                                return <EditUserPopup {...popup} />
                            case commonConstants.APPROVE_USER:
                                return <ApproveUserPopup {...popup} />
                            case commonConstants.PASSWORD_RESET:
                                return <PasswordResetPopup {...popup} />
                            case commonConstants.REJECT_UNAPPROVED:
                                return <RejectUnapprovedPopup {...popup} />
                            case commonConstants.CHANGE_ORGANIZATION:
                                return <ChangeOrganizationPopup {...popup} />
                            case commonConstants.CHANGE_ORGANIZATION_COPY:
                                return <ChangeOrganizationCopyPopup {...popup} />
                            case commonConstants.CHANGE_ORGANIZATION_MULTI:
                                return <ChangeMultiOrganizationPopup {...popup} />
                            case commonConstants.CHANGE_ROLE:
                                return <ChangeRolePopup {...popup} />
                            case commonConstants.DEVICE_PERMISSION:
                                return <DevicePermissionPopup {...popup} />
                            case commonConstants.ADD_ORGANIZATION_GROUP:
                                return <AddOrganizationGroupPopup {...popup} />
                            case commonConstants.EDIT_ORGANIZATION_GROUP:
                                return <EditOrganizationGroupPopup {...popup} />
                            case commonConstants.ADD_ROLE:
                                return <AddRolePopup {...popup} />
                            case commonConstants.EDIT_ROLE:
                                return <EditRolePopup {...popup} />
                            case commonConstants.VIEW_ROLE_USER_LIST:
                                return <RoleUserListPopup {...popup} />
                            case commonConstants.ADD_LDAP_ORGANIZATION:
                                return <AddLDAPPopup {...popup} />
                            case commonConstants.EDIT_LDAP_ORGANIZATION:
                                return <EditLDAPPopup {...popup} />
                            case commonConstants.LDAP_USER_GROUP_SELECTION:
                                return <LDAPUserGroupSelectionPopup {...popup}/>
                            case commonConstants.LDAP_ORGANIZATION_SELECTION:
                                return <LDAPOrganizationSelectionPopup {...popup}/>
                            case commonConstants.LDAP_DEVICE_GROUP_SELECTION:
                                return <LDAPDevicePermissionPopup {...popup}/>

                            /* Setting */
                            case settingConstants.FIRST_ACTIVATION_POPUP_INIT:
                            case settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT:
                                return <ActivationPopup {...popup} />
                            case settingConstants.FIRST_ACTIVATION_POPUP_ONLINE:
                            case settingConstants.ADDITIONAL_ACTIVATION_POPUP_ONLINE:
                                return <OnlineActivationPopup {...popup} />
                            case settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE:
                            case settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE:
                                return <OfflineActivationPopup {...popup} />

                            case settingConstants.RETURN_LICENSE_POPUP_INIT:
                            case settingConstants.RETURN_LICENSE_POPUP_CONFIRM:
                                return <ReturnLicensePopup {...popup} />

                            case settingConstants.FREE_LICENSE_POPUP:
                                return <FreeLicensePopup {...popup} />
                            case settingConstants.LICENSE_HISTORY_POPUP:
                                return <LicenseHistoryPopup {...popup} />
                            case settingConstants.LICENSE_ASSIGN_POPUP:
                                return <LicenseAssignPopup {...popup} />
                            case settingConstants.EDIT_LICENSE_ASSIGN_POPUP:
                                return <EditLicenseAssignPopup {...popup} />
                            case settingConstants.E2E_COMPANY_INFO_POPUP:
                                return <E2ECompanyInfoPopup {...popup} />
                            case settingConstants.E2E_LICENSE_HISTORY_POPUP:
                                return <E2ELicenseHistoryPopup {...popup} />
                            case settingConstants.E2E_LICENSE_SWAP_POPUP :
                                return <E2ELicenseSwapPopup {...popup} />

                            case settingConstants.ADD_TAG_POPUP:
                                return <TagPopup {...popup} />
                            case settingConstants.EDIT_TAG_POPUP:
                                return <TagPopup {...popup} />
                            case settingConstants.EDIT_EDGE_POPUP:
                                return <EdgeServerPopup {...popup} />
                            case settingConstants.ADD_REMOTE_CONTROL_SERVER_POPUP:
                                return <RemoteServerPopup {...popup} />
                            case settingConstants.EDIT_REMOTE_CONTROL_SERVER_POPUP:
                                return <RemoteServerPopup {...popup} />
                            case settingConstants.EDIT_DATALINK_SERVER_POPUP:
                                return <DataLinkPopup {...popup}/>
                            case settingConstants.ADD_DATALINK_SERVER_POPUP:
                                return <DataLinkPopup {...popup}/>
                            case settingConstants.ASSIGN_DATALINK_TABLE_POPUP:
                                return <AssignDataLinkTablePopup {...popup}/>
                            case settingConstants.NOTIFICATIONS_SETTING_POPUP:
                                return <NotificationSettingPopup {...popup} />
                            case settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP:
                                return <NotificationSetting2divisionPopup key={"NotificationSetting2divisionPopup"} {...popup} />
                            case settingConstants.LOG_DOWNLOAD_POPUP:
                                return <LogDownloadPopup {...popup} />
                            case settingConstants.DB_ACCESS_POPUP:
                                return <DbAccessInfoPopup {...popup} />
                            case settingConstants.VIEW_TAG_MIND_MAP_POPUP:
                                return <TagMindMapPopup {...popup} />
                            case settingConstants.SERVICE_HISTORY_POPUP:
                                return <DeviceServiceHistoryPopup {...popup} />
                            case settingConstants.PRIVACY_POLICY_POPUP:
                                return <PrivacyPolicyPopup  key={"PrivacyPolicyPopup"} {...popup} />
                            case scheduleConstants.CONTENT_SCHEDULE_MORE_EVENTS_POPUP:
                                return <MoreEventPopup {...popup}/>
                            case settingConstants.ADD_EXTERNAL_LINK_POPUP:
                                return <AddExternalLinkPopup {...popup}/>
                            case settingConstants.ADD_INSIGHT_INDEX_POPUP:
                            case settingConstants.EDIT_INSIGHT_INDEX_POPUP:
                                return <InsightIndexSettingPopup {...popup}/>
			    case settingConstants.REGISTER_LOGIN_PAGE_IMAGE:
                                return <RegisterLoginPageImagePopup {...popup}/>
                            case dashboardConstants.ADD_NOTICE_POPUP:
                                return <NewNoticePopup {...popup}/>
                            case dashboardConstants.DASHBOARD_WIDGET_POPUP:
                                return <WidgetPopup {...popup}/>
                            case dashboardConstants.EDIT_NOTICE_POPUP:
                                return <EditNoticePopup {...popup}/>

                            /*INSIGHT*/
                            case commonConstants.INSIGHT_INFORMATION :
                                return <InsightInformationPopup {...popup}/>
                        }
                    }
                )
        }
        </div>
    )

}
export default connect(
    state => ({
        common : state.common
    }),
    null
)(PopupContainer);