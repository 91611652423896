import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import moment from 'moment';
import {deviceOptions, useMISOpt} from '../../../misopt'
import RadioGroup from '../../../radio/RadioGroup';
import {deviceService} from '../../../../services';
import DateInput from '../../../input/DateInput';
import {dateToString, toMomentDateTimeFormat} from '../../../../helper';
import './PublishSoftwarePopup.css';
import TimeSelect from '../../../selectbox/TimeSelect';

const RetrySoftwarePopup = ({softwareId, onClose}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    
    const now = new Date();
    const [software, setSoftware] = useState({
        publishType: 'NOW',
        appliedType: '',
        deployAppliedVer: '',
        rsvDate: now,
        rsvTime: moment()
    });

    const onClickPublish = () => {
        const {rsvDate, rsvTime} = software;

        const rsvDateTime = moment(rsvDate);
        rsvDateTime.hour(rsvTime.hour());
        rsvDateTime.minute(rsvTime.minute());

        deviceService.retryPublishSoftware(
            softwareId,
            software.publishType === 'NOW' ? 'NOW' : rsvDateTime.valueOf()
        ).then(res => {
            toastr.success(t('MESSAGE_DEVICE_SOFTWARE_FILE_DEPLOY_SUCCESS_P'));
            onClose && onClose();
        }).catch(error => {
            console.log(error);
            toastr.error(t('MESSAGE_DEVICE_SOFTWARE_FILE_DEPLOY_FAIL_P'));
            onClose && onClose();
        });
    };

    return (
        <MISDialog 
            dialog={{
                title: t('COM_COMMONCTRL_RETRY'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 500,
                height: 100,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickPublish,
                        // disable: !isEmpty(error)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'><div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('MIS_ID_PUBLISH_TIME')}</th>
                                <td>
                                    <RadioGroup selects={deviceOptions.swPublishOptions} value={software.publishType} onChange={(e, value) => setSoftware({...software, publishType: value})} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('COM_ID_SCHEDULE_SCHEDULE_PUBLISH')}</th>
                                <td>
                                    <span className='mr8'>
                                        <DateInput width={120} minDate={now} date={dateToString(software.rsvDate)} disabled={software.publishType !== 'RESERVED'} onChange={value => setSoftware({...software, rsvDate: value})} />
                                    </span>
                                    <span>
                                        <TimeSelect style={{width: 120}} format='hh:mm A' disabled={software.publishType !== 'RESERVED'} value={software.rsvTime} showSecond={false} onChange={value => setSoftware({...software, rsvTime: value})} />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default RetrySoftwarePopup;