import React from 'react';
import {useTranslation} from "react-i18next";
import MISDialog from '../../MISDialog';
import './LogDataCollectConfirmPopup.css';

const LogDataCollectConfirmPopup = ({onClickYes, onClose}) => {
    const {t} = useTranslation();

    const onClickSave = () => {
        onClickYes();
    };

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_20_COLLECT_LOG'),
                closeOnEscape: true,
                modal: true,
                width: 350,
                height: 200,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickSave,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div className="log_data_collect_popup">
                <div style={{height: '190px'}}>
                    <div className="notify_wrap ft13">{t('MIS_SID_SEVER_PERMOFMANCE_AFFCTED_FUCNTION')}<br/>{t('COM_SID_ARE_YOU_SURE_WANT_TO_CONTINUE')}</div>
                </div>

                <div className="t_line mt20"></div>
            </div>

        </MISDialog>
    );
};

LogDataCollectConfirmPopup.defaultProps = {
    onSave: () => {},
};

export default LogDataCollectConfirmPopup;
