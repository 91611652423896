import React, {Component, Fragment} from "react";
import {DragSource, DropTarget} from "react-dnd";
import './SyncPlaylistEditor.css';
import {withTranslation} from "react-i18next";
import {ItemTypes} from "../ItemTypes";
import NextSpace from "./NextSpace";
import _isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import PlaylistTooltip from "../PlaylistTooltip";
import {contentService} from "../../../services";

class SyncGroupItem extends Component {
    state = {
        item: {},
        hover: false,
        imageSource: undefined,
        time: undefined,
        tooltip: undefined,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.state.item.contentId && this.state.imageSource === undefined) {
            this.fetchPlaylistItemThumbnail(this.state.item.thumbFileId, 100, 86);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {item} = nextProps;
        if (nextProps.item && (!_isEqual(item, prevState.item))) {
            return {
                item: cloneDeep(item),
                imageSource: item.imageSource,
                time: item.time ? cloneDeep(item.time) : undefined,
            };
        }
        return null;
    }

    onClickDeleteButton = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onDeleteItem(this.state.item.groupIndex, this.state.item.itemIndex);
        this.setState({hover: false})
    }

    onClickItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let item = this.state.item;
        this.props.onClickItem(item.groupIndex, item.itemIndex, !item.isDeleteCheck);
    }

    onInputChangedTime = (hour, min, sec) => {
        let item = this.state.item;
        let time = {
            hour: hour,
            min: min,
            sec: sec,
        };
        this.props.onChangeItemTime(item.groupIndex, item.itemIndex, time);
    }

    onBlurHour = () => {
        let {item} = this.state;
        let {hour, min, sec} = this.state.time;
        let final = hour;
        if (!this.isNumeric(hour)) {
            final = "00";
        } else {
            let time = Number(hour);
            if (time >= 24) {
                final = "23";
            } else if (time < 0) {
                final = "00";
            } else {
                final = time.toString();
                if (final.length === 1) {
                    final = "0" + final;
                }
            }
        }
        let time = {
            hour: final,
            min: min,
            sec: sec,
        };
        this.props.onUpdateItemTime(item.groupIndex, item.itemIndex, time);
    }

    onBlurMinute = () => {
        let {item} = this.state;
        let {hour, min, sec} = this.state.time;
        let final = min;
        if (!this.isNumeric(min)) {
            final = "00";
        } else {
            let time = Number(min);
            if (time >= 60) {
                final = "59";
            } else if (time < 0) {
                final = "00";
            } else {
                final = time.toString();
                if (final.length === 1) {
                    final = "0" + final;
                }
            }
        }
        let time = {
            hour: hour,
            min: final,
            sec: sec,
        };
        this.props.onUpdateItemTime(item.groupIndex, item.itemIndex, time);
    }

    onBlurSecond = () => {
        let {item} = this.state;
        let {hour, min, sec} = this.state.time;
        let final = sec;
        if (!this.isNumeric(sec)) {
            final = "00";
        } else {
            let time = Number(sec);
            if (time >= 60) {
                final = "59";
            } else if ((hour* 3600 + min*60)===0 && time < 5) {
                final = "05";
            } else {
                final = time.toString();
                if (final.length === 1) {
                    final = "0" + final;
                }
            }
        }
        let time = {
            hour: hour,
            min: min,
            sec: final,
        };
        this.props.onUpdateItemTime(item.groupIndex, item.itemIndex, time);
    }

    onHoverOn = (e) => {
        const tooltipPosition = {x: this.itemRef.getBoundingClientRect().right - this.itemRef.getBoundingClientRect().x - 60 ,y:0}
        if (e.view.innerWidth < e.pageX + e.target.offsetWidth + 300) {
            tooltipPosition.x = -250;
        }

        this.setState({
            hover: true,
            tooltip: tooltipPosition
        });
    }

    onHoverOff = () => {
        this.setState({
            hover: false,
            tooltip: undefined,
        });
    }

    isNumeric = (value) => {
        return /^\d+$/.test(value);
    }

    fetchPlaylistItemThumbnail = (id, width, height, resolution) => {
        const param = {
            width: width,
            height: height,
            resolution: resolution
        };
        Object.keys(param).forEach(key => param[key] === undefined && delete param[key]);
        contentService.fetchContentThumbnail(id, param).then(res => {
            if (res && res.body && res.body.items) {
                const {src} = res.body.items;
                let item = this.state.item;
                this.props.onUpdateImageItem(item.groupIndex, item.itemIndex, src);
            }
        });
    }

    render() {
        let {item} = this.state;
        return (
            <Fragment>
                {item.contentId ? this.renderNormalItem() : this.renderEmptyItem()}
            </Fragment>
        );
    }

    renderNormalItem() {
        let {t, connectDragSource, connectDragPreview, isDragging} = this.props;
        let {item, hover, imageSource, time, tooltip} = this.state;
        let isEditTime = item.playTime ? false : true;
        return (
            <Fragment>
                <div className="sync-playlists-group-item"
                     ref={ref => {
                         this.itemRef = ref
                     }}>
                    <div className='sync-playlists-group-item-thumbnail'>
                        <div onClick={(e) => this.onClickItem(e)} onMouseOver={(e) => this.onHoverOn(e)} onMouseLeave={(e) => this.onHoverOff(e)}>
                            {connectDragSource(connectDragPreview(
                                <div>
                                    <img style={{background: '#ddd'}} className='sync-playlists-group-item-thumbnail-img' src={imageSource}/>
                                    {item.mediaType !== 'IMAGE' && <div className="sync-playlists-group-item-check-playtime" onMouseOver={(e)=> e.stopPropagation()}>{item.playTime}</div>}
                                    {item.isDeleteCheck && <div className="sync-playlists-group-item-check-on"/>}
                                    {!isDragging && hover && <div className='sync-playlists-group-item-delete-button' onClick={(e) => this.onClickDeleteButton(e)}/>}
                                </div>
                            ))}
                        </div>
                        <NextSpace groupIndex={item.groupIndex} itemIndex={item.itemIndex} onAddItem={this.props.onAddItem} onHoverItem={this.props.onHoverItem}/>
                    </div>
                    <div className="sync-playlists-group-item-name">{item.contentName}</div>
                    <div className="sync-playlists-group-item-time">
                        <input type="text" value={time.hour} onChange={(e) => this.onInputChangedTime(e.target.value, time.min, time.sec)}
                               onBlur={this.onBlurHour} disabled={!isEditTime}/>
                        <span className="space">:</span>
                        <input type="text" value={time.min} onChange={(e) => this.onInputChangedTime(time.hour, e.target.value, time.sec)}
                               onBlur={this.onBlurMinute} disabled={!isEditTime}/>
                        <span className="space">:</span>
                        <input type="text" value={time.sec} onChange={(e) => this.onInputChangedTime(time.hour, time.min, e.target.value)}
                               onBlur={this.onBlurSecond} disabled={!isEditTime}/>
                    </div>
                    {!isDragging && hover && <PlaylistTooltip tooltipTop={tooltip.y} tooltipLeft={tooltip.x} content={item} playlistType={"3"}/>}
                </div>
            </Fragment>
        );
    }

    renderEmptyItem() {
        let {connectDragSource, connectDropTarget, connectDragPreview, isOverCurrent, canDrop, isDragging} = this.props;
        let {item} = this.state;
        return (
            <div className='sync-playlists-group-item-thumbnail'>
                {connectDropTarget(connectDragSource(connectDragPreview(
                    <div>
                        <div className="sync-playlists-group-item-none" onClick={(e) => this.onClickItem(e)}>
                            <div className="add_lfd"/>
                            <div className="add_img"/>
                            <div className="add_vod"/>
                            {canDrop && isOverCurrent && <div className="sync-playlists-group-item-empty-box-selected"/>}
                        </div>
                        {item.isDeleteCheck && <div className="sync-playlists-group-item-check-on" onClick={(e) => this.onClickItem(e)}/>}
                    </div>
                )))}
                <NextSpace groupIndex={item.groupIndex} itemIndex={item.itemIndex} onAddItem={this.props.onAddItem} onHoverItem={this.props.onHoverItem}/>
            </div>
        );
    }
}

const drop = {
    drop(props, monitor, component) {
        const didDrop = monitor.didDrop()
        if (didDrop) {
            return;
        }
        const item = monitor.getItem().dragItem;
        props.onReplaceItem(props.item.groupIndex, props.item.itemIndex, cloneDeep(item));
    },
    canDrop(props, monitor) {
        const item = monitor.getItem().dragItem;
        if (item.itemIndex === undefined || item.groupIndex === undefined) {
            return true;
        }
        return false;
    }
};

const dropCollect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        dropResult: monitor.getDropResult(),
    }
};

const drag = {
    beginDrag(props, monitor, component) {
        return {dragItem: props.item};
    },
    isDragging(props, monitor) {
        return monitor.getItem().id === props.id
    },
};

const dragCollect = (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
};

export default DropTarget(ItemTypes.ContentItem, drop, dropCollect)(DragSource(ItemTypes.ContentItem, drag, dragCollect)(withTranslation()(SyncGroupItem)));