import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {round} from 'lodash';
import MISDialog from '../../MISDialog';
import ProgressBar from '../../../progressbar/ProgressBar';
import './PresetPublishStatusPopup.css';
import { deviceService } from '../../../../services';

const PresetPublishStatusPopup = ({preset, onClose, onClickDevice}) => {
    const {t} = useTranslation();
    const [showDetail, setShowDetail] = useState(true);
    const [publishStatus, setPublishStatus] = useState([]);
    const [completedDeployCount, setCompletedDeployCount] = useState([]);
    const {name, totalDeployCount = 0} = preset;

    const onClickDeviceName = (deviceId) => {
        onClickDevice && onClickDevice(deviceId);
    };

    useEffect(() => {
        deviceService.fetchDevicePresetDeployStatus(preset.preconfigId).then(res => {
            setPublishStatus(res.items);
            setCompletedDeployCount(res.items.filter(status => status.publishStatus === 1).length);
        }).catch(error => console.log(error));
    }, [preset]);

    return (
        <div className='preset_publish_status_wrap'>
            <MISDialog
                dialog={{
                    title: t('MIS_SID_PUBLISHING_DOT'),
                    isDraggable: true,
                    closeOnEscape: true,
                    modal: true,
                    width: 660,
                    height: 500,
                    onClose
                }}
            >
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="184px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('LIST_TITLE_NAME')}</th>
                                <td>{name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="uploading mb19">
                    <span className="total_status_txt">{t('MIS_SID_TOTAL_STATUS')}</span>
                    <span className="file_total">{`${completedDeployCount}/${totalDeployCount} ${t('TEXT_DEVICE_P')}`}</span>
                    <ProgressBar width='100%' height={20} percentage={round(completedDeployCount / totalDeployCount * 100, 1)} fillerColor='#4be48c' background="#e8e8e8" useLabel={true} />
                </div>
                <div className="float_l mt25">
                    <button className="detail_btn on" onClick={() => setShowDetail(!showDetail)}>{t('BUTTON_DETAIL_P')}</button>
                </div>
                {
                    showDetail &&
                    <div className="publish_status_detail detail_view mt35 pb25">
                        <div style={{height: 300, overflow: 'auto', width: '100%'}}>
                        <table className='publish_status_detail_table'>
                            <colgroup>
                                <col width="187px"/>
                                <col width="161px"/>
                                <col/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t('TEXT_DEVICE_GROUP_P')}</th>
                                    <th>{t('TABLE_DEVICE_NAME_P')}</th>
                                    <th>{t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    publishStatus.map((status, i) => {
                                        return (
                                            <tr key={`status_${i}`}>
                                                <td>{status.deviceGroupName}</td>
                                                <td><a href="#" onClick={() => onClickDevice(status.deviceId)}>{status.deviceName}</a></td>
                                                <td>{status.publishStatus == 1 ? t('MIS_SID_SERVER_APPLIED') : t('TEXT_NOT_APPLY_P')}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                }
            </MISDialog>
        </div>
    );
};

export default PresetPublishStatusPopup;