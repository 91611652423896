import classNames from "classnames";
import ReactDOM from "react-dom";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MISDialog from '../MISDialog';
import './CreateAds.css';
import { useDispatch, useSelector } from "react-redux";
import { contentAction, popupAction } from "../../../actions";
import TextInput from "../../input/TextInput";
import NumberInput from '../../input/NumberInput';
import Checkbox from "../../checkbox/Checkbox";
import { contentService } from "../../../services";
import { toastr } from 'helper/toastrIntercept';
import { validateSpecialChar } from "../../../helper/contentValidator";
import { commonConstants, DEVICE_TYPES } from "../../../constants/";
import { SearchButton } from "../../button/SearchButton";
import { isNil } from 'lodash';
import TimeInput from 'components/input/TimeInput';
import { ADS_CONTENT_SUGGESTION_LIST_TYPE } from '../../../constants';
import { validateEventName } from "../../../helper/contentValidator";

const CreateAds = (props) => {
    const { t } = useTranslation();
    const myContentGroupId = useSelector(({ content }) => content.myContentGroupId);
    const { id, content, editMode = false } = props;
    const dispatch = useDispatch();
    const selectBox = useRef();

    const [fetchData, setFetchData] = useState(false);
    const [buttons, setButtons] = useState({
        rightButtons: [
            {
                id: 'SAVE',
                title: t('COM_BUTTON_SAVE'),
                onClick: () => onClickSaveButton(),
            },
            {
                id: 'CANCEL',
                title: t('BUTTON_CANCEL_P'),
                onClick: () => closePopup(id),
            }
        ],
    });

    const [adsContent, setAdsContent] = useState({
        adsContentName: editMode ? content.adsContentName : '',
        publisherId: editMode ? content.publisherId : '',
        publisherName: editMode ? content.publisherName : '',
        apiKey: editMode ? content.apiKey : '',
        apiSecret: editMode ? content.apiSecret : '',
        adUnitId: editMode ? content.adUnitId : '',
        imageTypeSet: editMode ? content.imageTypeSet : 'N',
        imageDuration: editMode ? content.imageDuration : 5,
        videoTypeSet: editMode ? content.videoTypeSet : 'N',
        videoDuration: editMode ? content.videoDuration : 5,
        defaultContentName: editMode ? content.defaultContentName : '',
        defaultContentFileId: editMode ? content.defaultContentFileId : '',
    });

    const [disableImageContent, setDisableImageContent] = useState(editMode ? content.imageTypeSet === "N" : true);
    const [disableVideoContent, setDisableVideoContent] = useState(editMode ? content.videoTypeSet === "N" : true);

    const [tempPublisherInfoList, setTempPublisherInfoList] = useState([]);
    const [publisherInfoList, setPublisherInfoList] = useState([]);
    const [adUnitIdList, setAdUnitIdList] = useState([]);
    const [tempAdUnitIdList, setTempAdUnitIdList] = useState([]);
    const [showPublisherInfoList, setShowPublisherInfoList] = useState(false);
    const [showAdUnitIdList, setShowAdUnitIdList] = useState(false);

    const [errorText, setErrorText] = useState(undefined);

    const { adsContentName, publisherId, publisherName, apiKey, apiSecret, adUnitId, imageTypeSet, imageDuration, videoTypeSet, videoDuration, defaultContentName, defaultContentFileId } = adsContent;

    const imageContentEnable = useRef();
    const videoContentEnable = useRef();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(selectBox.current);
        if (!domNode || !domNode.contains(event.target)) {
            setShowAdUnitIdList(false);
            setShowPublisherInfoList(false);
        }
    };

    const toggleImageContent = () => {
        if (imageContentEnable.current.checked) {
            setDisableImageContent(false);
            setAdsContent({ ...adsContent, imageTypeSet: 'Y' });
        } else {
            setDisableImageContent(true);
            setAdsContent({ ...adsContent, imageTypeSet: 'N' });
        }
    };

    const onChangeImageDuration = (value) => {
        setAdsContent({ ...adsContent, imageDuration: value });
    };

    const toggleVideoContent = () => {
        if (videoContentEnable.current.checked) {
            setDisableVideoContent(false);
            setAdsContent({ ...adsContent, videoTypeSet: 'Y' });
        } else {
            setDisableVideoContent(true);
            setAdsContent({ ...adsContent, videoTypeSet: 'N' });
        }
    };

    const onChangeVideoDuration = (value) => {
        setAdsContent({ ...adsContent, videoDuration: value });
    };

    useEffect(() => {
        document.addEventListener('click', (evt) => { handleClickOutside(evt) }, true);

        if (editMode) {
            if (content.imageTypeSet === "Y") {
                imageContentEnable.current.checked = true;
                setDisableImageContent(false);
            }
            if (content.videoTypeSet === "Y") {
                videoContentEnable.current.checked = true;
                setDisableVideoContent(false);
            }
        } else {
            imageContentEnable.current.checked = false;
            videoContentEnable.current.checked = false;
        }

        contentService.fetchAdsContentSuggestionInfo().then(res => {
            if (res && res.items.publisherInfoList && res.items.publisherInfoList.length > 0) {
                setTempPublisherInfoList(res.items.publisherInfoList);
                setPublisherInfoList(res.items.publisherInfoList);
            }
            if (res && res.items.adUnitIdList && res.items.adUnitIdList.length > 0) {
                setTempAdUnitIdList(res.items.adUnitIdList);
                setAdUnitIdList(res.items.adUnitIdList);
            }
        }).catch(error => {
            setShowAdUnitIdList(false);
            setShowPublisherInfoList(false);
        })
    }, []);

    const emptyContent = () => {
        setAdsContent({ ...adsContent, defaultContentFileId: "", defaultContentName: "" });
    };

    const showDefaultContentSelectPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                type: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                handleSelected: (selectedContents) => {
                    if (selectedContents.length === 1) {
                        setAdsContent({
                            ...adsContent, defaultContentName: selectedContents[0].contentName,
                            defaultContentFileId: selectedContents[0].mainFileId,
                        });
                    }
                },
                contentType: "content",
                multiSelect: false,
                device: {
                    deviceType: DEVICE_TYPES['WPLAYER'].deviceType,
                    deviceTypeVersion: DEVICE_TYPES['WPLAYER'].deviceTypeVersion
                },
                fetchForAdsContent: true,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP))
            })
        );
    }

    const checkContentParams = (adsContentParams) => {
        const isEmptyParam = Object.values(adsContentParams).every(x => !isNil(x) && x.toString().trim() !== '');
        return isEmptyParam;
    }

    const onClickSaveButton = () => {
        setFetchData(true);
    };

    useEffect(() => {
        if (fetchData) {
            saveAdsContent();
        }
    }, [fetchData])

    const setAdUnitId = (e) => {
        setAdsContent({ ...adsContent, adUnitId: e.target.value });
        const tempList = adUnitIdList.filter(value => value.match(e.target.value));
        if (tempList.length > 0 && e.target.value.length) {
            setShowAdUnitIdList(true);
            setTempAdUnitIdList(tempList);
        } else {
            setShowAdUnitIdList(false);
        }
    }

    const setAdUnitIdInfo = (e) => {
        const adUnitId = adUnitIdList.find(value => value === e.target.innerText);
        setAdsContent({ ...adsContent, adUnitId: adUnitId })
        setShowAdUnitIdList(false);
    }

    const setPublisherId = (e) => {
        setAdsContent({ ...adsContent, publisherId: e.target.value });
        const tempList = publisherInfoList.filter(value => value.publisherId.match(e.target.value));
        if (tempList.length > 0 && e.target.value.length) {
            setShowPublisherInfoList(true);
            setTempPublisherInfoList(tempList);
        } else {
            setShowPublisherInfoList(false);
        }
    }

    const setPublisherInfo = (e) => {
        const publisherInfo = publisherInfoList.find(value => value.publisherId === e.target.innerText);
        setAdsContent({
            ...adsContent, publisherId: publisherInfo.publisherId,
            publisherName: publisherInfo.publisherName,
            apiKey: publisherInfo.apiKey,
            apiSecret: publisherInfo.apiKeySecret
        });
        setShowPublisherInfoList(false);
    }

    const deleteSuggestionInfo = (e, suggestionType) => {
        const suggestionId = e.target.previousSibling.innerText;
        contentService.deleteAdsContentSggestionInfo(suggestionType, suggestionId).then(res => {
            if (res && res.items && res.items.successList && res.items.successList.length > 0) {
                contentService.fetchAdsContentSuggestionInfo().then(res => {
                    if (suggestionType === ADS_CONTENT_SUGGESTION_LIST_TYPE.PUBLISHER_INFO_LIST && res) {
                        const suggestionText = adsContent.publisherId;
                        const publisherInfoList = res.items.publisherInfoList || [];
                        const tempPublisherInfoFilteredList = publisherInfoList.filter(value => value.publisherId.match(suggestionText)) || [];
                        setPublisherInfoList(publisherInfoList);
                        setTempPublisherInfoList(tempPublisherInfoFilteredList);
                        const showList = tempPublisherInfoFilteredList.length > 0 ? true : false;
                        setShowPublisherInfoList(showList);
                    }
                    else if (suggestionType === ADS_CONTENT_SUGGESTION_LIST_TYPE.AD_UNIT_ID_LIST && res) {
                        const suggestionText = adsContent.adUnitId;
                        const adUnitIdList = res.items.adUnitIdList || [];
                        const tempAdUnitIdFilteredList = adUnitIdList.filter(value => value.match(suggestionText)) || [];
                        setAdUnitIdList(adUnitIdList);
                        setTempAdUnitIdList(tempAdUnitIdFilteredList);
                        const showList = tempAdUnitIdFilteredList.length > 0 ? true : false;
                        setShowAdUnitIdList(showList);
                    }
                }).catch(error => {
                    toastr.error(t('MIS_MESSAGE_COMMON_CANT_GET_DATA_P'));
                })
            } else {
                toastr.error(t('ALERT_FAIL'));
            }
        }).catch(error => {
            toastr.error(t('ALERT_FAIL'));
        });
    }

    const saveAdsContent = () => {
        const { adUnitId, ...adsContentParams } = adsContent;
        if (!checkContentParams(adsContentParams)) {
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
            setFetchData(false);
        } else if (!validateSpecialChar(adsContentName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
            setFetchData(false);
        } else if (!validateEventName(publisherId) || !validateEventName(adUnitId)) {
            toastr.error(t('COM_SID_HOTEL_MIX_INVALID_SPECIAL_CHAR_ENTERED').replace("<<A>>",'[ ] \/ \\'));
            setFetchData(false);
        } else if (!imageContentEnable.current.checked && !videoContentEnable.current.checked) {
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
            setFetchData(false);
        } else {
            setErrorText(undefined);
            let promise;
            if (editMode) {
                const param = {
                    ...adsContent,
                    groupId: content.groupId
                };
                promise = contentService.editAdsContent(content.contentId, param);
            } else {
                const param = {
                    ...adsContent,
                    groupId: myContentGroupId === "" ? 0 : myContentGroupId
                };
                promise = contentService.createAdsContent(param);
            }

            if (promise) {
                promise.then(res => {
                    if (res.status === 'Success') {
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        dispatch(contentAction.reloadContent(true));
                        closePopup(id);
                    }
                }).catch(error => {
                    let errorMessage = t('MIS_MESSAGE_CONTENT_FAIL_TO_ADD_CONTENT_P');
                    if (error.errorCode == '400010') {
                        errorMessage = t('MIS_SID_NO_CHANGES_HAVE_BEEN_MADE');
                    }
                    toastr.error(errorMessage);
                    closePopup(id);
                });
            }
        }
    };

    return (
        <div className='upload_ads_content_popup_wrap'>
            <MISDialog dialog={{
                title: editMode ? t('MIS_SID_CBAUG_EDIT_ADS') : t('MIS_SID_CBAUG_ADD_ADS'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 550,
                height: 575,
                position: { x: -150, y: -200 },
                onClose: () => closePopup(),
            }}
                buttons={buttons}>
                <div className='upload_ads_content_popup'>
                    <div className="detail_view mb15">
                        <table>
                            <colgroup>
                                <col width="203px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('TEXT_CONTENT_NAME_P')}</th>
                                    <td>
                                        <TextInput width={280} maxLength={100} placeholder={t('COM_IDS_ERR_CONTENTS_NAME')} value={adsContentName}
                                            onChange={(e) => setAdsContent({ ...adsContent, adsContentName: e.target.value })} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3>{t('MIS_SID_CBAUG_PUBLISHER_INFO')}</h3>
                    <div className="detail_view mb15">
                        <table>
                            <colgroup>
                                <col width="203px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('MIS_SID_CBAUG_PUBLISHER_ID')}</th>
                                    <td>
                                        <div className={classNames("menu_select_box")} ref={selectBox}>
                                            <TextInput width={280} maxLength={150} placeholder={t('MIS_SID_CBAUG_ENTER_PUBLISHER_ID')} value={publisherId}
                                                onChange={(e) => { setPublisherId(e) }} />
                                            <div className="option_list" style={{ 'display': (showPublisherInfoList ? 'block' : 'none'), width: '100%' }}>
                                                <div><span></span>
                                                    <ul className={"lnb"} style={{ height: '100px', maxHeight: '150px', overflow: 'auto' }}>
                                                        {
                                                            tempPublisherInfoList.length > 0 &&
                                                            tempPublisherInfoList.map(
                                                                (value) =>
                                                                    <li>
                                                                        <a href="#" onClick={(e) => { setPublisherInfo(e) }} style={{ backgroundImage: 'none' }}><span style={{ textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', width: '230px' }}>{value.publisherId}</span></a>
                                                                        <button className="message_closebox" onClick={(e) => deleteSuggestionInfo(e, ADS_CONTENT_SUGGESTION_LIST_TYPE.PUBLISHER_INFO_LIST)}></button>
                                                                    </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('COM_COMMONCTRL_NAME')}</th>
                                    <td>
                                        <TextInput width={280} maxLength={150} placeholder={t('MIS_SID_CBAUG_ENTER_A_NAME')} value={publisherName}
                                            onChange={(e) => setAdsContent({ ...adsContent, publisherName: e.target.value })} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('MIS_SID_CBAUG_API_KEY')}</th>
                                    <td>
                                        <TextInput width={280} maxLength={150} placeholder={t('MIS_SID_CBAUG_ENTER_API_KEY')} value={apiKey}
                                            onChange={(e) => setAdsContent({ ...adsContent, apiKey: e.target.value })} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('MIS_SID_CBAUG_SECRET_KEY')}</th>
                                    <td>
                                        <TextInput width={280} maxLength={150} placeholder={t('MIS_SID_CBAUG_ENTER_SECRET_KEY')} value={apiSecret}
                                            onChange={(e) => setAdsContent({ ...adsContent, apiSecret: e.target.value })} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3>{t('MIS_SID_CBAUG_ADS_INFO')}</h3>
                    <div className="detail_view mb15">
                        <table>
                            <colgroup>
                                <col width="203px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('MIS_SID_CBAUG_AD_UNIT_ID')}</th>
                                    <td>
                                        <div className={classNames("menu_select_box")} ref={selectBox}>
                                            <TextInput width={280} maxLength={150} placeholder={t('MIS_SID_CBAUG_ENTER_AD_UNIT_ID')} value={adUnitId}
                                                onChange={(e) => setAdUnitId(e)} />
                                            <div className="option_list" style={{ 'display': (showAdUnitIdList ? 'block' : 'none'), width: '100%' }}>
                                                <div><span></span>
                                                    <ul className={"lnb"} style={{ height: '100px', maxHeight: '150px', overflow: 'auto' }}>
                                                        {
                                                            tempAdUnitIdList.length > 0 &&
                                                            tempAdUnitIdList.map(
                                                                (value) =>
                                                                    <li>
                                                                        <a href="#" onClick={(e) => { setAdUnitIdInfo(e) }} style={{ backgroundImage: 'none' }}><span style={{ textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', width: '230px' }}> {value}</span></a>
                                                                        <button className="message_closebox" onClick={(e) => deleteSuggestionInfo(e, ADS_CONTENT_SUGGESTION_LIST_TYPE.AD_UNIT_ID_LIST)}></button>
                                                                    </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('MIS_SID_CONTENT_TYPE')}</th>
                                    <td>
                                        <div className="content_type" style={{ display: "flex", height: "10px" }}>
                                            <div>
                                                <Checkbox id={"imageDuration"} name={t('TEXT_IMAGE_P')} ref={imageContentEnable} onChange={toggleImageContent} />
                                            </div>
                                            <div>
                                                {
                                                    !disableImageContent &&
                                                    <div className="content_type_input">
                                                        <div>{t('COM_TABLE_DURATION_P')}</div>&nbsp;&nbsp;
                                                        <NumberInput propertyName='imageDuration' value={imageDuration} onChange={(e, value) => onChangeImageDuration(value)} min={5} max={300} forceInt />
                                                        <div>{t('TEXT_SEC_P')}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>
                                        <div className="content_type" style={{ display: "flex", height: "10px" }}>
                                            <div>
                                                <Checkbox id={"videoDuration"} name={t('COM_SID_VIDEO')} ref={videoContentEnable} onChange={toggleVideoContent} />&nbsp;
                                            </div>
                                            <div>
                                                {
                                                    !disableVideoContent &&
                                                    <div className="content_type_input">
                                                        <div>{t('COM_TABLE_DURATION_P')}</div>&nbsp;&nbsp;
                                                        <NumberInput propertyName='videoDuration' value={videoDuration} onChange={(e, value) => onChangeVideoDuration(value)} min={5} max={300} forceInt />
                                                        <div>{t('TEXT_SEC_P')}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('COM_TEXT_DEFAULT_CONTENT_P')}</th>
                                    <td>
                                        <div className="float_l mr10">
                                            <SearchButton onClick={showDefaultContentSelectPopup} />
                                        </div>
                                        <div className="float_l" style={{ width: 216, background: 'none' }}>
                                            <input
                                                type="text"
                                                style={{
                                                    width: '100%',
                                                    background: 'none',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                                value={defaultContentName}
                                                readOnly
                                            />
                                            <span onClick={emptyContent} className={"content_empty_button"}></span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default CreateAds;
