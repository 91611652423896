class Stack{


    constructor(){
        this.top = -1;
        this.dataStore = [];
    }
    push(element){
        this.removeAll(element);
        this.top++;
        this.dataStore[this.top] = element;
    }
    pop(){
        if(this.top>-1){
            let val = this.dataStore[this.top];
            this.top--;

            this.removeAll(val);

            return val;
        }
        return null;
    }
    removeAll(val) {
        this.dataStore = this.dataStore.filter(v => v != val)
        this.top = this.dataStore.length-1;
    }
    peek(){
        return this.dataStore[this.top];
    }
    get length(){
        return this.top+1;
    }
    clear(){
        this.top = 0;
        this.dataStore = [];
    }
}
export default Stack;