import React from 'react';
import {DASHBOARD_ACTION} from "../constants";

const init = {
    loading: false,
    reload: false,
    widgets : []
}

export const dashboard = (state = init, action) => {

    switch (action.type) {
        case DASHBOARD_ACTION.UPDATE_WIDGETS :
            return {
                ...state,
                widgets : action.widgets
            }
        case DASHBOARD_ACTION.RELOAD_DASHBOARD :
            return {
                ...state,
                reload: !state.reload
            }
        default:
            return state;
    }


}