export const commonConstants = {

    COMMON_POPUP_TYPE_MODAL_TYPE_1 : 'COMMON_POPUP_TYPE_MODAL_TYPE_1',
    COMMON_POPUP_TYPE_MODAL_TYPE_2 : 'COMMON_POPUP_TYPE_MODAL_TYPE_2',
    COMMON_POPUP_TYPE_MODAL_TYPE_3 : 'COMMON_POPUP_TYPE_MODAL_TYPE_3',
    COMMON_POPUP_TYPE_MODAL_TYPE_4 : 'COMMON_POPUP_TYPE_MODAL_TYPE_4',
    COMMON_POPUP_TYPE_MODAL_TYPE_5 : 'COMMON_POPUP_TYPE_MODAL_TYPE_5',
    COMMON_POPUP_TYPE_MODAL_TYPE_6 : 'COMMON_POPUP_TYPE_MODAL_TYPE_6',
    COMMON_POPUP_TYPE_MODAL_TYPE_7 : 'COMMON_POPUP_TYPE_MODAL_TYPE_7',
    COMMON_POPUP_TYPE_MODAL_TYPE_8 : 'COMMON_POPUP_TYPE_MODAL_TYPE_8',
    COMMON_POPUP_CREATE_SCHEDULE: 'COMMON_POPUP_CREATE_SCHEDULE',
    COMMON_POPUP_TYPE_MODELESS : 'COMMON_POPUP_TYPE_MODELESS',
    COMMON_POPUP_ACTIVE_POPUP : 'COMMON_POPUP_ACTIVE_POPUP',
    COMMON_CONFIRM_POPUP: 'COMMON_CONFIRM_POPUP',
    COMMON_ALERT_POPUP: 'COMMON_ALERT_POPUP',
    COMMON_GROUP_POPUP: 'COMMON_GROUP_POPUP',
    COMMON_CUSTOM_POPUP: 'COMMON_CUSTOM_POPUP',
    LOADING_POPUP: 'LOADING_POPUP',
    SELECT_CONTENTS_POPUP: 'SELECT_CONTENTS_POPUP',
    SELECT_MULTIPLE_CONTENTS_POPUP: 'SELECT_MULTIPLE_CONTENTS_POPUP',
    LOGOUT_POPUP: 'LOGOUT_POPUP',
    TUTORIAL_MOVIE_POPUP: 'TUTORIAL_MOVIE_POPUP',

    CONTENT_TYPE_APPLICATION_JSON: 'application/json',


    COMMON_REQUEST_POPUP: 'COMMON_REQUEST_POPUP',
    COMMON_UPDATE_POPUP: 'COMMON_UPDATE_POPUP',
    COMMON_ADD_POPUP : 'COMMON_ADD_POPUP',
    COMMON_OPEN_POPUP : 'COMMON_OPEN_POPUP',
    COMMON_CLOSE_POPUP: 'COMMON_CLOSE_POPUP',


    COMMON_DETAIL_VIEW : 'COMMON_DETAIL_VIEW',
    COMMON_OPEN_DETAIL_VIEW: 'COMMON_OPEN_DETAIL_VIEW',

    CONTENT_SCHEDULE_DETAIL_PROGRESS_POPUP : 'CONTENT_SCHEDULE_DETAIL_PROGRESS_POPUP',

    CREATE_RULESET: 'CREATE_RULESET',
    SAVE_RULESET: 'SAVE_RULESET',
    RULESET_CONDITION_POPUP: 'RULESET_CONDITION_POPUP',
    RULESET_PLAY_POPUP: 'RULESET_PLAY_POPUP',
    CREATE_PLAY_POPUP: 'CREATE_PLAY_POPUP',
    RULESET_RULETREE_OPTION_POPUP : 'RULESET_RULETREE_OPTION_POPUP',
    RULESET_PLAY_FORMAT_SETTING_POPUP : 'RULESET_PLAY_FORMAT_SETTING_POPUP',
    RULESET_DETIAL_POPUP: 'RULESET_DETAIL_POPUP',
    RULESET_PLAY_KEYWORD_SETTING_POPUP :'RULESET_PLAY_KEYWORD_SETTING_POPUP',
    RULESET_VERIFICATION_DEVICE_LIST_POPUP : 'RULESET_VERIFICATION_DEVICE_LIST_POPUP',
    RULESET_SELECT_DATALINK_TABLE_POPUP : 'RULESET_SELECT_DATALINK_TABLE_POPUP',
    RULESET_SELECT_DATALINK_TAG_POPUP : 'RULESET_SELECT_DATALINK_TAG_POPUP',
    RULESET_CONTENT_DOWNLOAD_STATUS_POPUP: 'RULESET_CONTENT_DOWNLOAD_STATUS_POPUP',
    USED_RULESET_DELETE: 'USED_RULESET_DELETE',
    RULESET_CONDITION_PREVIEW_POPUP : 'RULESET_CONDITION_PREVIEW_POPUP',

    UPLOAD_CONTENT: 'UPLOAD_CONTENT',
    INFORMATION_CONTENT: 'INFORMATION_CONTENT',
    UPLOAD_WEB_CONTENT: 'UPLOAD_WEB_CONTENT',
    SSSP_WEB_APP: 'SSSP_WEB_APP',
    CREATE_FTP: 'CREATE_FTP',
    CREATE_CIFS:  'CREATE_CIFS',
    CREATE_STREAMING: 'CREATE_STREAMING',
    CREATE_ADS: 'CREATE_ADS',
    CREATE_WEB_URL: 'CREATE_WEB_URL',
    CONTENT_APPROVE : 'CONTENT_APPROVE',
    ADD_TO_PLAYLIST: 'ADD_TO_PLAYLIST',
    SAVE_AS_TEMPLATE: 'SAVE_AS_TEMPLATE',
    CONTENT_MULTI_EDIT: 'CONTENT_MULTI_EDIT',
    USED_CONTENT_DELETE: 'USED_CONTENT_DELETE',
    CONTENT_SHARE: 'CONTENT_SHARE',
    FOLDER_SHARE: 'FOLDER_SHARE',
    EXPIRED_CONTENT: 'EXPIRED_CONTENT',
    POLLING_HISTORY: 'POLLING_HISTORY',

    CREATE_PLAYLIST : 'CREATE_PLAYLIST',
    CREATE_CONTENT_SCHEDULE: 'CREATE_CONTENT_SCHEDULE',
    CREATE_MESSAGE_SCHEDULE: 'CREATE_MESSAGE_SCHEDULE',
    CREATE_EVENT_SCHEDULE: 'CREATE_EVENT_SCHEDULE',

    SAVE_MESSAGE_SCHEDULE: 'SAVE_MESSAGE_SCHEDULE',
    SAVE_CONTENT_SCHEDULE: 'SAVE_CONTENT_SCHEDULE',

    UPDATE_DEVICE: 'UPDATE_DEVICE',
    UPDATE_FLIP_DEVICE: 'UPDATE_FLIP_DEVICE',
    QUICK_CONTROL_DEVICE: 'QUICK_CONTROL_DEVICE',
    TAG_SETTING: 'TAG_SETTING',
    APPROVE_DEVICE: 'APPROVE_DEVICE',
    SAVE_PLAYLIST: 'SAVE_PLAYLIST',
    PLAYLIST_SETTING: 'PLAYLIST_SETTING',
    ADVERTISEMENT_GUIDE: 'ADVERTISEMENT_GUIDE',
    REGISTER_SOFTWARE: 'REGISTER_SOFTWARE',
    EDIT_SOFTWARE: 'EDIT_SOFTWARE',
    PUBLISH_SOFTWARE: 'PUBLISH_SOFTWARE',
    RETRY_PUBLISH_SOFTWARE: 'RETRY_PUBLISH_SOFTWARE',
    EDIT_APPLIED_STATUS: 'EDIT_APPLIED_STATUS',
    DEVICE_ERROR_REPORT: 'DEVICE_ERROR_REPORT',
    REGISTER_CUSTOMIZE: 'REGISTER_CUSTOMIZE',
    EDIT_CUSTOMIZE: 'EDIT_CUSTOMIZE',
    PUBLISH_CUSTOMIZE: 'PUBLISH_CUSTOMIZE',
    EDIT_CUSTOMIZE_APPLIED_STATUS: 'EDIT_CUSTOMIZE_APPLIED_STATUS',
    ADD_RULE: 'ADD_RULE',
    ADD_PRESET: 'ADD_PRESET',
    EDIT_PRESET_DETAIL: 'EDIT_PRESET_DETAIL',
    VWL_LAYOUT: 'VWL_LAYOUT',
    VWL_LAYOUT_SETTING: 'VWL_LAYOUT_SETTING',
    EDIT_CONNECTION: 'EDIT_CONNECTION',
    EDIT_LED_CABINET: 'EDIT_LED_CABINET',
    APPLIED_WARNING_RULE: 'APPLIED_WARNING_RULE',
    EXPIRATION_DATE: 'EXPIRATION_DATE',
    DEVICE_TYPE_CONVERSION: 'DEVICE_TYPE_CONVERSION',
    TV_CHANNEL: 'TV_CHANNEL',
    COLLECT_LOG_DEVICE: 'COLLECT_LOG_DEVICE',
    BACKUP_PLAY: 'BACKUP_PLAY',
    VWL_LAYOUT_DEVICE_REPLACE: 'VWL_LAYOUT_DEVICE_REPLACE',
    ERROR_HISTORY: 'ERROR_HISTORY',
    PRESET_PUBLISH_STATUS: 'PRESET_PUBLISH_STATUS',
    PRESET_RESULT: 'PRESET_RESULT',
    DEVICE_MEMO: 'DEVICE_MEMO',
    LED_SUNRISE_SUNSET_TIMES: 'LED_SUNRISE_SUNSET_TIMES',
    SELECT_DEVICES: "SELECT_DEVICES",
  
    ADD_USER: 'ADD_USER',
    ADD_USER_GROUP_SELECTION: 'ADD_USER_GROUP_SELECTION',
    ADD_ORGANIZATION: 'ADD_ORGANIZATION',
    ADD_USER_LDAP_ORGANIZATION_SELECTION: 'ADD_USER_LDAP_ORGANIZATION_SELECTION',
    CHANGE_ORGANIZATION: 'CHANGE_ORGANIZATION',
    CHANGE_ORGANIZATION_COPY: 'CHANGE_ORGANIZATION_COPY',
    CHANGE_ROLE: 'CHANGE_ROLE',
    REMOVE_USER: 'REMOVE_USER',
    EDIT_USER: 'EDIT_USER',
    CHANGE_ORGANIZATION_MULTI: 'CHANGE_ORGANIZATION_MULTI',

    APPROVE_USER: 'APPROVE_USER',
    PASSWORD_RESET: 'PASSWORD_RESET',
    PASSWORD_RESET_WARNING: 'PASSWORD_RESET_WARNING',
    REJECT_UNAPPROVED: 'REJECT_UNAPPROVED',
    DEVICE_PERMISSION: 'DEVICE_PERMISSION',
    ADD_ORGANIZATION_GROUP: 'ADD_ORGANIZATION_GROUP',
    EDIT_ORGANIZATION_GROUP: 'EDIT_ORGANIZATION_GROUP',
    ADD_ROLE: 'ADD_ROLE',
    EDIT_ROLE: 'EDIT_ROLE',
    VIEW_ROLE_USER_LIST: 'VIEW_ROLE_USER_LIST',
    ADD_LDAP_ORGANIZATION: 'ADD_LDAP_ORGANIZATION',
    EDIT_LDAP_ORGANIZATION: 'EDIT_LDAP_ORGANIZATION',

 
    LDAP_USER_GROUP_SELECTION: 'LDAP_USER_GROUP_SELECTION',
    LDAP_ORGANIZATION_SELECTION: 'LDAP_ORGANIZATION_SELECTION',
    LDAP_DEVICE_GROUP_SELECTION: 'LDAP_DEVICE_GROUP_SELECTION',

    DEVICE_GROUP_SELECTION: 'DEVICE_GROUP_SELECTION',

    INSIGHT_INFORMATION: 'INSIGHT_INFORMATION',

    LOCATION_MAX_INPUT_LENGTH: 200,
    MAX_PORT_VALUE: 65535,
    MIN_PORT_VALUE: 1,
    SMTP_SERVER_PORT_MAX_LENGTH: 5,
    MAP_LOCATION_MAX_INPUT_LENGTH: 100,
    MAX_RC_DEVICES_COUNT: 10,
    PROXY_MIN_PORT_RANGE: 0,
    PROXY_MAX_PORT_RANGE: 65535,
    WEB_PAGE_URL_MAX_LENGTH: 1000,
    PC_SOURCE: '20'
}
