import React from 'react';
import {connect} from 'react-redux';
import {popupAction} from '../../../actions';
import './PollingHistory.css';
import {withTranslation} from 'react-i18next';
import withMISOpt from '../../misopt';
import MISDialog from "../MISDialog";
import Size from "../../utils/Size";
import {getMixString} from "../../../language/languageUtils";
import MagicInfoTable from "../../table/MagicInfoTable";

class PollingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pollingHistory: props.contentPollingHistories || [],
            leftColumns: [
                {
                    Header: props.t('COM_TV_UPDATED_KR_NALJA'),
                    accessor: 'pollingTime',
                    sortable: false,
                    width: 240,
                    Cell: ({row, value}) => {
                        return <span className='link_cell' onClick={() => this.onClickPollingHistory(row._index)}>{value}</span>
                    }
                },
                {
                    Header: props.t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS'),
                    accessor: 'pollingStatus',
                    sortable: false,
                    width: 110,
                    Cell: ({value}) => <span>{value === 'SUCCESS' ? props.t('TEXT_SUCCESS_P') : props.t('TEXT_FAIL_P')}</span>
                },
                {
                    Header: props.t('COM_TV_SID_ITEMS'),
                    accessor: 'fileCount',
                    sortable: false,
                }
            ],
            rightColumns: [
                {
                    Header: props.t('TEXT_FILE_NAME_P'),
                    accessor: 'fileName',
                    sortable: false,
                    width: 240,
                    Cell: ({value}) => <span className='file_name_cell'>{value}</span>
                },
                {
                    Header: props.t('COM_TEXT_SIZE_P'),
                    accessor: 'fileSize',
                    sortable: false,
                    width: 110,
                    Cell: ({value}) => <Size className='size_cell' size={value}/>
                },
                {
                    Header: props.t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS'),
                    accessor: 'fileStatus',
                    sortable: false,
                }
            ],

            selectHistoryIndex: '',
        };
    }

    closePopup = () => {
        const {closePopup, id} = this.props;
        closePopup(id);
    };

    onClickPollingHistory = (index) => {
        this.setState({selectHistoryIndex: index});
    };

    render() {
        const {t} = this.props;
        const {pollingHistory, leftColumns, rightColumns, selectHistoryIndex} = this.state;
        return (
            <div className='polling_history_popup'>
                <div className='dim_background'/>
                <MISDialog
                    dialog={{
                        title:
                            <div>
                                <span className='main_title'>{t('MIS_SID_POLLING_HISTORY')}</span>
                                <span className='title_notice'>{getMixString(['MIS_SID_MIX_CAFEB_IF_SPCIAL_CHAR_EXCLUD_DOWN', '\'<\', \'>\', \'&\''])}</span>
                            </div>,
                        isDraggable: false,
                        closeOnEscape: true,
                        modal: true,
                        width: 926,
                        height: 375,
                        position: {x: -400, y: -225},
                        onClose: () => this.closePopup(),
                        useEllipsis: false
                    }}
                    buttons={{
                        rightButtons: [
                            {
                                title: t('COM_BUTTON_CLOSE_P'),
                                onClick: () => this.closePopup(),
                            }
                        ]
                    }}>
                    <div className='history_table_wrap'>
                        <div className='history_table_left'>
                            <MagicInfoTable
                                data={pollingHistory && pollingHistory.length > 0 ? pollingHistory : []}
                                minRows={0}
                                multiSort={false}
                                columns={leftColumns}
                                showPagination={false}
                                className="-striped -highlight"
                                manual
                                style={{minHeight: '370px'}}
                                resizable={false}
                            />
                        </div>
                        {
                            selectHistoryIndex !== '' &&
                            <div className='history_table_right'>
                                <MagicInfoTable
                                    data={pollingHistory[selectHistoryIndex].filePollingLogs}
                                    minRows={0}
                                    multiSort={false}
                                    columns={rightColumns}
                                    showPagination={false}
                                    className="-striped -highlight"
                                    manual
                                    style={{minHeight: '370px'}}
                                    resizable={false}
                                />
                            </div>
                        }
                        {
                            selectHistoryIndex === '' &&
                            <div className='history_not_selected'>{t('MIS_SID_SELECT_POLLING_HISTORY_TO_VIEW_FILE_LIST_AND_STATUS_INFORMATION')}</div>
                        }
                    </div>
                </MISDialog>
            </div>
        )
    }
}

export default connect(
    state => ({}

    ),
    dispatch => ({
        addPopup: (id) => dispatch(popupAction.addPopup(id)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)
(withTranslation()(withMISOpt(PollingHistory)));