import axios from 'axios';
import {logout, refreshToken} from "./fetchIntercept";
import {history} from "./history";

const _axios = axios.create();

_axios.interceptors.response.use(
    response => response ,
    error => {
        let originalRequest = error.config;
        if(error.message !== undefined && error.message === 'CANCEL'){
            return Promise.reject(error);
        }else if(error.response === undefined || (error.response.errorCode !== undefined && error.response.errorCode === '401003')){
            return refreshToken().then(res => {
                originalRequest.headers.api_key = res.body.token;
                return axios(originalRequest);
            }).catch(err => {
                logout();
                history.push('/login');
            });
        }

        return Promise.reject(error);
    }
);

const getCancelTokenSource = () => {
    return axios.CancelToken.source();
}

export {
    _axios,
    getCancelTokenSource
}