import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './ExpiredContent.css';
import {renderExpireDateInFormat} from "../../../helper";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import MagicInfoTable from "../../table/MagicInfoTable";
import {useTrGroupProps} from '../../../helper/tables';
import {useMISOpt} from "../../../components/misopt";

const ExpiredContent = ({expiredContents, id}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat}}} = useMISOpt();
    console.log(expiredContents);
    const [data, setData] = useState({
        items: expiredContents,
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const [style, setStyle] = useState({
        height: '276px'
    });

    const {items, loading} = data;

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const [getTrGroupPropsType1] = useTrGroupProps(null, null, null, 'content_tr_group');

    return (
        <div className='expired_content_popup'>
            <MISDialog
                dialog={{
                    title: t('DID_MAIN_CONTENT'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 650,
                    height: 316,
                    position: {x: -150, y: -200},
                    onClose: closePopup,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'Confirm',
                            title: t('COM_BUTTON_CONFIRM'),
                            onClick: closePopup,
                        }
                    ],
                }}>
                <div className="notify_wrap">
                    {t('MIS_SID_EXPIRED_CONTETN_NOT_ADDED')}
                </div>
                <MagicInfoTable
                    data={items}
                    minRows={0}
                    getTrGroupProps={getTrGroupPropsType1}
                    showPagination={false}
                    resizable={false}
                    loading={loading}
                    columns={
                        [
                            {
                                Header: t("TEXT_CONTENT_NAME_P"),
                                width: 487,
                                accessor: 'contentName',
                            },
                            {
                                Header: t('COM_EXPIRATION_DATE_KR_DATE'),
                                accessor: "expirationDate",
                                Cell: data => <div><span>{renderExpireDateInFormat(data.value,dateFormat.toUpperCase())}</span></div>
                            }
                        ]
                    }
                    className="-striped -highlight"
                    manual
                    style={style}
                />
            </MISDialog>
        </div>
    );
};

export default ExpiredContent;