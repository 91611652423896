import React from 'react';
import Checkbox from "../../../components/checkbox/Checkbox";
import {isArray, isNil} from 'lodash';
import {useTranslation} from "react-i18next";
import './WeeklyContainer.css';

const WeeklyContainer = ({width = 100,height= 50, checked, onClick}) => {

    const {t} = useTranslation();

    const days = [
        {id: 'weeklyMon', value: 'MON', name: t('COM_TEXT_DAY_MONDAY_P')},
        {id: 'weeklyTue', value: 'TUE', name: t('COM_TEXT_DAY_TUESDAY_P')},
        {id: 'weeklyWed', value: 'WED', name: t('COM_TEXT_DAY_WEDNESDAY_P')},
        {id: 'weeklyThu', value: 'THU', name: t('COM_TEXT_DAY_THURSDAY_P')},
        {id: 'weeklyFri', value: 'FRI', name: t('COM_TEXT_DAY_FRIDAY_P')},
        {id: 'weeklySat', value: 'SAT', name: t('COM_TEXT_DAY_SATURDAY_P')},
        {id: 'weeklySun', value: 'SUN', name: t('COM_TEXT_DAY_SUNDAY_P')}
    ]

    const getChecked = value => {
        let dayList = checked;
        if (!isArray(checked)) {
            dayList = checked.split(',')
        }
        const day = dayList !== undefined && dayList.find(day => day.toUpperCase() === value) || undefined;
        if (!isNil(day) && day.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <div className={'weekly_container'}>
            <ul style={{display: 'inline'}}>
            {
                days.map(
                    (day, index) => <li><Checkbox key={index} id={day.id} propertyName={day.value} name={day.name} checked={getChecked(day.value)} onClick={onClick}/></li>
                )
            }
            </ul>
        </div>
    )
}
export default WeeklyContainer;