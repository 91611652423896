import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import { EditTimeZone, EditNetwork, EditSmartFeature, EditPeriod } from '../edit/EditSetup';
import '../edit/EditDevice.css';

const EditPresetSetup = (props) => {
    return (
        <div className='device_edit_panel_wrap mt28'>
            <EditTimeZone {...props} />
            <EditNetwork {...props} />
            <EditSmartFeature {...props} />
            <EditPeriod {...props} />
        </div>
    );
};

export default EditPresetSetup;