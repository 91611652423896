import React, {useState, useEffect } from 'react';
import MISDialog from "../../MISDialog";
import { useTranslation } from 'react-i18next';
import { isNil, get, set }  from 'lodash';
import "./ServerManagementPopup.css";
import Tree, {TreeNode} from "rc-tree/es";
import { Loading } from "../../../loading/Loading";

const NotificationSetting2divisionPopup = ({onClose, users, notis, onSave, title}) => {
    const {t} = useTranslation();
    const [userData, setUserData] = useState({users: [...users], selected: null, changed: false});
    const [treeData, setTreeData] = useState({tree: [], selected:[]});
    const [init, setInit] = useState({tmpParent:[]}); //tmpparent has to be excepted when save.
    const [loading, setLoading] = useState(false);

    const notiText = { 
        "TIMEZONE_NOT_SET": "MIS_SID_TIME_ZONE_NOT_SET",
        "SCHEDULE_NOT_PUBLISHED": "MIS_SID_SCHEDULE_NOT_PUBLISHED",
        "CONTENT_DOWNLOAD_ERROR": "MIS_SID_SERVER_CONTENT_DOWNLOAD_ERROR",
        "EXPIRE_SCHEDULE": "MIS_SID_SERVER_SCHEDULES_TO_EXPIRE",
        "EXPIRE_PLAYLIST": "MIS_SID_SERVER_PLAYLISTS_TO_EXPIRE",
        "ADD_CONTENT": "MIS_SID_SERVER_CONTENT_ADDED",
        "EDIT_CONTENT": "MIS_SID_SERVER_CONTENT_EDITED",
        "DELETE_CONTENT": "MIS_SID_SERVER_CONTENT_DELETED",
        "ADD_PLAYLIST": "MIS_SID_SERVER_PLAYLIST_ADDED",
        "EDIT_PLAYLIST": "MIS_SID_SERVER_PLAYLIST_EDITED",
        "DELETE_PLAYLIST": "MIS_SID_SERVER_PLAYLIST_DELETED",
        "ADD_CONTENT_SCHEDULE": "MIS_SID_SERVER_SCHEDULE_ADDED",
        "EDIT_CONTENT_SCHEDULE": "MIS_SID_SERVER_SCHEDULE_EDITED",
        "DELETE_CONTENT_SCHEDULE": "MIS_SID_SERVER_SCHEDULE_DELETED"
    }
    
    
    useEffect(() => {
        getTreeNodes();
    }, []);

    const onClickSave = () => {
        onSave(userData.users);
    }

    const getTitle = (data) => {
        return !isNil(notiText[data.type])?t(notiText[data.type]):data.description;
    }

    const getTreeNodes = () => {
        let nodes = [];
        let tmpExcepted = [];
        const treedata = makeTreeData();
        [...treedata].map((data) => {
            if(isNil(data.children)) {
                nodes.push(<TreeNode key={data.type} checked={true} title={getTitle(data)}  isLeaf={true}/>);
            } else {
                let childnodes = [];
                data.children.map((child) => {
                    childnodes.push(<TreeNode checked={true} title={getTitle(child)} key={child.type} isLeaf={true} />)
                })
                nodes.push(<TreeNode selectable={false} title={getTitle(data)} key={data.type} isLeaf={false}>{childnodes}</TreeNode>);
                tmpExcepted.push(data.type);
            }
        });
        let userDatas = [...userData.users];
        userDatas.map((user) => {
            let others = [];
            const userNotis = user.notifications;
            const notiTypes = [];
            notis.map((noti) => {
                notiTypes.push(noti.type);
            });
            userNotis.map((usernoti) => {
                if(!notiTypes.includes(usernoti)){
                    others.push(usernoti);
                }
            });
            user.others = others;
        });

        setUserData({...userData, users: userDatas});
        setTreeData({...treeData, tree: nodes});
        setInit({...init, tmpParent : tmpExcepted});
    }

    const makeTreeData = () => {
        let treenodes = {};
        const tmpNotis = [...notis];
        tmpNotis.map((tmpNoti) => {
            let title = tmpNoti.description;
            if(title.charAt(0) == "["){
                let prefix = title.substr(0, title.indexOf("]") + 1);

                if(isNil(treenodes[prefix])){
                    treenodes[prefix] = {};
                    treenodes[prefix].type = prefix;
                    treenodes[prefix].description = prefix;
                    treenodes[prefix].children = [];
                } 
                treenodes[prefix].children.push(tmpNoti);
            } else {
                set(treenodes, tmpNoti.type, tmpNoti);
            }
        });
        let tree = [];
        Object.keys(treenodes).map((key) => {
            tree.push(treenodes[key]);
        })

        return tree;
    }

    const onClickNotificationCheckbox = (checked, e) => {
        init.tmpParent.map((parent)=>{
            if(checked.indexOf(parent) >= 0){
                checked = checked.filter(item => item !== parent);
            }
        });
       
        let tmpUsers = [...userData.users];
        let otherCategory = [];
        tmpUsers.map((user) => {
            if(user.userId == userData.selected){
                user.notifications = [...checked];
                if(!isNil(user.others) && user.others.length > 0){
                    user.notifications = user.notifications.concat(user.others);
                }
            }
        });

        setUserData({...userData, users: tmpUsers});
        setTreeData({...treeData, selected: checked});

    }

    const onLoadTreeData = (userNotis) => {
        setLoading(true);
        let selected = [];
        if(!isNil(notis)){
            notis.map((notification) => {
                if([...userNotis].includes(notification.type)){
                    selected.push(notification.type);
                }
            })
        }
        setTreeData({...treeData, selected: selected});
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }
    
    const selectUser = (userId) => {
        setUserData({...userData, selected: userId});
        users.map((user) => {
            if(user.userId == userId){
                onLoadTreeData([...user.notifications]);
                return;
            }
        })
    }

    const getUserNotificationsCount = (userNotis) => {
        if(isNil(userNotis)){
            return 0;
        }
        let count = 0;
        notis.map((notification) => {
            if([...userNotis].includes(notification.type)){
                count += 1;
            }
        });
        return count;
    }

    return(
        <MISDialog
            dialog={{
                title: title,
                modal: true,
                closeOnEscape: true,
                width: 970,
                height: 500,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: ()=> onClickSave(),
                        disable: false
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}>
            <div className="float_l right_line" style={{height: '500px', width: '500px', overflowY: 'auto'}}>
                <span>{t("TEXT_TITLE_USER_LIST_P")}</span>
                <div className="server_setting notifications none_scrolling check_list mr10 mt10">
                    <table style={{width:'100%'}}>
                        <colgroup>
                            <col width="25%"></col>
                            <col width="25%"></col>
                            <col width="25%"></col>
                            <col width="25%"></col>
                        </colgroup>
                        <tbody>
                            <tr>    
                                <th>{t("TABLE_ORGANIZATION_P")}</th>
                                <th>{t("COM_DID_ADMIN_USER_USERID") + " / " + t("LIST_TITLE_NAME")}</th>
                                <th>{t("TABLE_ROLE_NAME_P")}</th>
                                <th>{t("COM_TV_SID_ITEMS")}</th>
                            </tr>
                            {
                                !isNil(userData.users) && userData.users.length > 0 &&
                                userData.users.map((user) =>
                                    <tr className={userData.selected == user.userId? 'on' : ''} key={user.userId} onClick={()=>selectUser(user.userId)}>
                                        <td>{user.organization}</td>
                                        <td>{user.userId} / {user.userName}</td>
                                        <td>{user.roleName}</td>
                                        <td>{getUserNotificationsCount(user.notifications)}</td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="float_r" style={{height: '500px', width: '460px', overflow: 'auto'}}>
                <span>{t("MIS_SID_SERVER_ERROR_WARNING")}</span>
                {
                    loading && 
                    <div style={{textAlign: 'center', paddingTop: '200px'}}><Loading /></div>
                }
                {
                    !loading && 
                    <div className="server_setting notifications none_scrolling check_list mr10 mt10">
                    {
                        (isNil(userData.selected) || (isNil(treeData.tree) || treeData.tree.length == 0)) &&
                        <span>{t("MIS_SID_SELECT_USERS_TO_RECEIVE_ALARM_MAIL")}</span>
                    }
                    {
                        !isNil(userData.selected) && !isNil(treeData.tree) && treeData.tree.length > 0 &&
                        <Tree 
                            style={{'display':'block'}}
                            onCheck={onClickNotificationCheckbox}
                            checkable
                            checkedKeys={treeData.selected}
                        >
                            {treeData.tree}
                        </Tree>
                    }   
                    </div>
                }
            </div>
        </MISDialog>
    )
}

export default NotificationSetting2divisionPopup;