import React from 'react';
import './MediaTagTab.css';
import {withTranslation} from "react-i18next";
import WhiteButton from "../../button/WhiteButton";
import ReactDOM from 'react-dom';
import {settingService} from "../../../services";
import {commonConstants, contentConstants} from "../../../constants";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import TagValueSelectCell from "../tag/TagValueSelectCell";

function checkSelectedCondition(conditionList, selectedConditionList) {
    let tagConditionList = [];

    if(conditionList === undefined || conditionList === null || conditionList === '') {
        return tagConditionList;
    }

    if(selectedConditionList === undefined || selectedConditionList === null || selectedConditionList === '') {
        return tagConditionList;
    }

    selectedConditionList.map((tagCondition) => {
        if(tagCondition.checkCondition === undefined) {
            tagConditionList.push({...tagCondition, checkCondition: true});
        } else {
            tagConditionList.push({...tagCondition});
        }
    });

    conditionList.map((condition) => {
        let isDuplicated = false;
        selectedConditionList.map((tagCondition) => {
            if(tagCondition.tagConditionId === condition.tagConditionId) {
                isDuplicated = true;
            }
        });
        if(!isDuplicated) {
            tagConditionList.push({...condition, checkCondition: false});
        }

    });

    return tagConditionList;
}

function makeTagTitle(t, tag, isChange) {
    const conditionList = tag.contentTagConditions ? tag.contentTagConditions : tag.tagConditions;

    let conditionTitle = '';

    if(conditionList === undefined || conditionList === null || conditionList === '') {
        return '';
    }

    if(isChange) {
        let selectedCount = 0;
        conditionList.map((tagCondition, index) => {
            if (tagCondition.checkCondition || tagCondition.checkCondition === undefined) {
                selectedCount++;
                if (selectedCount > 1) {
                    conditionTitle += ',';
                }
                conditionTitle += tagCondition.tagCondition === 'NOT_ASSIGN' ? t('COM_DID_VWL_NOT_ASSIGNED') : tagCondition.tagCondition;
            }
            if (index === (conditionList - 1)) {
                conditionTitle = conditionTitle.substring(0, conditionTitle.lastIndexOf(','));
            }
        });
    } else {
        conditionList.map((tagCondition, index) => {
            if(tag.tagType === contentConstants.TAG_TYPE_BOOLEAN) {
                conditionTitle = tagCondition.conditionValue === 'true' ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P");
            } else {
                conditionTitle += tagCondition.conditionValue === 'NOT_ASSIGN' ? t('COM_DID_VWL_NOT_ASSIGNED') : tagCondition.conditionValue;
                if (index < (conditionList.length - 1)) {
                    conditionTitle += ',';
                }
            }
        });
    }

    return conditionTitle;
}

class MediaTagTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contentTags: props.tags || [],

            addCondition: {
                tagId: '',
                tagCondition: '',
            }
        };
        this.onChangeTagInfo = props.onChangeTagInfo;
    }

    componentDidMount() {
        let contentTagList = [];
        let conditionTitle = '';
        const {t} = this.props;
        const {contentTags} = this.state;

        contentTags.map((tag) => {
            contentTagList.push({
                ...tag,
                showCondition: false,
                tagConditionTitle: makeTagTitle(t, tag, tag.contentTagConditions),
                contentTagConditions:
                    tag.contentTagConditions ?
                        tag.contentTagConditions :
                        tag.tagConditions ?
                            tag.tagConditions.map((condition) => {
                                return {
                                    tagId: tag.tagId,
                                    tagConditionId: condition.conditionId,
                                    tagCondition: condition.conditionValue === 'NOT_ASSIGN' ? t('COM_DID_VWL_NOT_ASSIGNED') : condition.conditionValue,
                                    checkCondition: tag.tagType === contentConstants.TAG_TYPE_TEXT,
                                };
                            }) : [],
                checkAll: false,
            });
            conditionTitle = '';
        });

        this.setState({contentTags: contentTagList});
    // }
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        let contentTagList = [];
        const {contentTags} = this.state;

        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || event.target.id === '') {
            contentTags && contentTags.map((tag) => {
                contentTagList.push({...tag, showCondition: false});
            });

            this.setState({contentTags: contentTagList});
        }
    };

    onChangeCondition = (selectedTagList, selectTag) => {
        this.setState({contentTags: selectedTagList});
        this.props.onChangeTagInfo(selectedTagList);
    };

    openTagSettingPopup = () => {
        const {addPopup, zIndex} = this.props;
        const {contentTags} = this.state;

        addPopup({
            id: commonConstants.TAG_SETTING,
            type: commonConstants.TAG_SETTING,
            tagType: 'media',
            zIndex: zIndex + 1,
            showDevice: false,
            showTagValue: true,
            tagListType: 'media',
            selectedTags: contentTags,
            onApply: this.onTagSettingPopupApply,
            onClose: this.onTagSettingPopupClose,
        });
    };

    onTagSettingPopupApply = (selectedTagList) => {
        const {closePopup} = this.props;

        console.log("openTagSettingPopup : " + selectedTagList.length);

        let finalSelectedTags = [];

        selectedTagList.map((tag) => {
            finalSelectedTags.push({
                ...tag,
                showCondition: false,
            });
        });

        this.setState({contentTags: finalSelectedTags});
        this.props.onChangeTagInfo(finalSelectedTags);

        closePopup(commonConstants.TAG_SETTING);
    };

    onTagSettingPopupClose = (originalTagList) => {
        const {closePopup} = this.props;

        this.setState({contentTags: originalTagList});
        closePopup(commonConstants.TAG_SETTING);
    };

    render() {
        const {t, hasWritePermission} = this.props;
        const {contentTags} = this.state;

        return (
            <div className='content_detail_tag_wrap' style={{marginTop: '13px', position: 'relative'}}>
                {hasWritePermission && <WhiteButton style={{marginTop: 0}} name={t('MIS_SID_TAG_SETTING')} width={100} onClick={()=> this.openTagSettingPopup()} />}
                <div style={{paddingTop: '60px'}}>
                    <div className='content_detail_popup_tag' style={{height: "370px", marginTop: "15px"}}>
                        <table>
                            <colgroup>
                                <col width="251px"/>
                                <col width="148px"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th style={{width: '251px'}}>{t("MIS_TEXT_TAG_NAME_P_KR_MIS20")}</th>
                                    <th style={{width: '148px'}}>{t("MIS_TEXT_TAG_VALUE_P")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                contentTags && contentTags.map((tag, index) => {
                                    return (
                                        <tr key={tag.tagId}>
                                            <td><div title={tag.tagName} style={{width:240, whiteSpace:"normal",wordWrap:"break-word"}}>{tag.tagName}</div></td>
                                            <td>
                                                <TagValueSelectCell useAdd={true}
                                                                    useSelectAll={true}
                                                                    originalTag={tag}
                                                                    tagList={contentTags}
                                                                    selectedTagList={contentTags}
                                                                    onChangeCondition={this.onChangeCondition}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id)),
    })
)(withTranslation()(MediaTagTab));