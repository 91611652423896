import React, {useState} from 'react';
import {useDrop} from "react-dnd";

const ButtonArea = ({accept, onButtonClick, addNode, areaStyle, buttonStyle, buttonClassName}) => {

    if(accept === undefined) {
        accept = 'conditionBox';
    }

    const [{ isOver, isOverCurrent, canDrop } ,drop] = useDrop({
        accept: accept,
        drop : (item)=> onDrop(item),
        collect: monitor => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        })
    })

    const onDrop = (item) => {
        if(addNode !== undefined) {
            addNode(item);
        }
    }

    let style = {
        position: "absolute",
        top: "36px",
        left: "190px",
        width: "60px",
        height: "85px",
        border: "4px solid transparent",
        zIndex: 11,
    };

    if(areaStyle !== undefined) {
        Object.assign(style, areaStyle);
    }

    if(buttonStyle === undefined) {
        buttonStyle = { left: "-20px" };
    }

    if(buttonClassName === undefined) {
        buttonClassName = "ruleset_condition_add_button condition_plus_and";
    }

    const className = isOver ? "ruletree-label-drag-hover" : undefined;

    return (
        <div className={className} ref={drop} style={style}>
            <button className={buttonClassName} style={buttonStyle} onClick={(e) => onButtonClick(e)} />
        </div>
    )
}
export default ButtonArea; 