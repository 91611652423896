import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isNil} from 'lodash';
import Moment from 'react-moment';
import {toastr} from 'helper/toastrIntercept';
import {useTranslation} from 'react-i18next';
import {popupAction} from "../../../../actions";
import {useMISOpt} from '../../../misopt';
import WhiteButton from '../../../button/WhiteButton';
import {deviceService} from '../../../../services';
import DevicePowerIcon from '../../../device/DevicePowerIcon';
import NumberInput from '../../../input/NumberInput';
import {getErrorMessage} from '../../../../helper/responseHandler';
import {toMomentDateTimeFormat} from '../../../../helper';
import {commonConstants} from "../../../../constants";
import './ExternalPowerDetail.css';

const EXTERNAL_POWER_COMMAND = {
    CLEAR_EXTERNAL_POWER_FAULT: 'CLEAR_EXTERNAL_POWER_FAULT'
};

const ExternalPowerDetail = ({device, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const [installedPower, setInstalledPower] = useState(0);
    const [externalPowers, setExternalPowers] = useState([]);
    const [externalPowerCount, setExternalPowerCount] = useState(0);
    const [lastUpdated, setLastUpdated] = useState();

    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const onChangeInstalledPower = (e, value) => {
        setInstalledPower(value);
    }

    const onClickApply = () => {
        if(isNil(installedPower) || installedPower <= 0){
            return;
        }
        deviceService.scanExternalPower(device.deviceId, installedPower).then(res => {
            if(res.status === 'Success'){
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
            } else {
                toastr.error(t('ALERT_FAIL'));
            }
        }).catch(error => toastr.error(getErrorMessage(error)));
    }
    
    const sendClearFault = () => {
        deviceService.sendExternalPowerCommand(device.deviceId, EXTERNAL_POWER_COMMAND.CLEAR_EXTERNAL_POWER_FAULT).then(res => {
            if(res.status === 'Success'){
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
            } else {
                toastr.error(t('ALERT_FAIL'));
            }
        }).catch(error => toastr.error(getErrorMessage(error)));
    }

    const onRefresh = () => {
        addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
        deviceService.getCurrentExternalPowerInfo(device.deviceId, res => {
            if(res === 'Timeout' || res.status !== 'Success'){
                toastr.error(t('MIS_MESSAGE_COMMON_CANT_GET_DATA_P'));
            } else {
                if(res && res.successList && res.successList.length > 0){
                    const {externalPowerModelList: powers, externalPowerCount: powerCount, lastUpdateTime} = res.successList[0];
                    setExternalPowers(powers ? powers : []);
                    setInstalledPower(powers ? powers.length : 0);
                    setExternalPowerCount(powerCount || 0);
                    setLastUpdated(lastUpdateTime);
                }
            }
            closePopup('EDIT_DEVICE_LOADING');
        }, 15);
    }

    useEffect(() => {
        deviceService.fetchExternalPowerInfo(device.deviceId).then(res => {
            if(res.status === 'Success'){
                const {items: {externalPowerModelList: powers, externalPowerCount: powerCount, lastUpdateTime}} = res;
                setExternalPowers(powers ? powers : []);
                setInstalledPower(powers ? powers.length : 0);
                setExternalPowerCount(powerCount || 0);
                setLastUpdated(lastUpdateTime);
            } else {
                toastr.error(t('MIS_MESSAGE_COMMON_CANT_GET_DATA_P'));
            }
        }).catch(error => toastr.error(getErrorMessage(error)));
    }, [device.deviceId]);

    return (
        <div className='detail_view mini_5 mt15'>
            <table className="mt5">
                <colgroup>
                    <col width="183px"/><col width="100px"/><col width=""/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>{t('MIS_SID_CBFEB_INSTALLED_EXTERNAL_POWER')}</th>
                        <td><NumberInput width={70} value={installedPower} min={0} max={16} onChange={onChangeInstalledPower} disabled={!device.power} forceInt /></td>
                        <td><WhiteButton id={'POWER_CONNECTION_APPLY'} name={t('BUTTON_APPLY')} width={80} onClick={onClickApply} disable={!device.power || isNil(installedPower) || installedPower <= 0}/></td>
                    </tr>
                    <tr>
                        <th>{t('MIS_SID_CBFEB_CURRENTLY_RUNNING')}</th>
                        <td style={{paddingLeft:12}}>{externalPowerCount}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div style={{margin: '20px 0px'}}>
                <span className="detail_table_title" style={{fontSize:15, verticalAlign: "middle"}}>{t('LIST_TITLE_MODEL_NAME')}</span>
                <button id="btnRefresh" className="replash2 float_r" onClick={onRefresh} disabled={!device.power}></button>
                <WhiteButton id='btnClearFault' name={t('MIS_SID_CBFEB_CLEAR_FAULT')} width={80} style={{float: 'right', marginRight: '8px'}} onClick={sendClearFault} disable={!device.power}/>
            </div>         
            <div className="external_power_wrapper">
                <ul>
                {
                    externalPowers && externalPowers.length > 0 && externalPowers.map(power => {
                        return (
                            <li>
                                <DevicePowerIcon power={power.externalPowerStatus == '1'}/> <span>{power.externalPowerModelName}</span>
                            </li>
                        )
                    })
                }
                {
                    externalPowers && externalPowers.length > 0 && externalPowers.length % 2 === 1 && <li style={{borderBottom:0}}><span>&nbsp;</span></li>
                }
                {
                    (!externalPowers || externalPowers.length <= 0) && 
                    <li className="empty">
                        <span>{t('MESSAGE_COMMON_NO_DATA_P')}</span>
                    </li>
                }
                </ul>
            </div>
            {
                !isNil(lastUpdated) && 
                <div style={{margin: '10px 15px'}}>
                    <span className="float_r">{t('COM_SID_LAST_UPDATED')} : <Moment format={dateTimeFormat}>{lastUpdated}</Moment></span>
                </div>                
            }
        </div>
    );
};

export default connect(
    state => ({
    }),
    dispatch => ({
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup))
    })
)(ExternalPowerDetail);