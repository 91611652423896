import toastr from 'toastr';
import store from '../store';

const isLoggedIn = () => {
    const {loggedIn} = store.getState().authentication;
    return loggedIn;
}

const misToastr = {
    error: (message, force = false)=> {
        if (force || isLoggedIn()) {
            toastr.error(message)
        }
    },
    success : (message)=> {
        toastr.success(message);
    },
    warning: (message)=> {
        toastr.warning(message);
    },
    info : (message) => {
        toastr.info(message);
    }
}

export {
    misToastr as toastr
}