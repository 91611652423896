import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import './SubmenuMessageContainer.css';
import classNames from 'classnames';
import {toastr} from 'helper/toastrIntercept';
import {scheduleAction} from "../../../actions/";

class SubmenuMessageContainer extends React.Component{

    constructor(props) {
        super(props);
    }


    removeMessage = (e, type, index) => {
        e.stopPropagation();

        const {messages, removeMessage, t} = this.props;
        let message;
        if (type === 'new') {
            message = messages.newMessage.message;
        } else {
            message = messages.editMessage.message;
        }
        if (message.messageList.length > 1) {
            removeMessage(type, index);
        } else {
            toastr.error(t("COM_MIV_CAN_NOT_DELETE_ANY_MORE"));
        }
    }

    addMessage = () => {
        const {type, addMessage, messages, t} = this.props;
        if (type === 'new') {
            if (messages.newMessage.message.messageList.length === 10) {
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"))
            } else {
                addMessage(type);
            }
        } else {
            if (messages.editMessage.message.messageList.length === 10) {
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"))
            } else {
                addMessage(type);
            }
        }

    }

    render() {
        const {type, messages, loadMessage, t} = this.props;
        return (
            <div className={"submenu_message_container"} style={{overflowY: 'auto'}}>
                <div className="lnb_buttonwrap" style={{height:38,position:'relative'}} >
                    <button className="base noline float_l" style={{width: 206}} id="messageAddBtn" onClick={()=>this.addMessage(type)} >{t("MIS_TEXT_ADD_MESSAGE_P")}</button>
                </div>
                <div className="lnb">
                    <ul id="lnbMessageLissts">
                        {
                            type === 'new' && messages.newMessage !== undefined &&
                            messages.newMessage.message.messageList.map(
                                (message, index) =>
                                    <li key={index} className={classNames({'on': index===messages.newMessage.currentMessageIndex})} onClick={()=>loadMessage(type,index)}>
                                        <a href="#"
                                           style={{backgroundImage: 'none'}}>{t("TEXT_MESSAGE_P")}</a>
                                        <button className="message_closebox" onClick={(e)=>this.removeMessage(e, type, index)}></button>
                                    </li>
                            )
                        }
                        {
                            type === 'edit' && messages.editMessage !== undefined &&
                            messages.editMessage.message.messageList.map(
                                (message, index) =>
                                    <li key={index} className={classNames({'on': index===messages.editMessage.currentMessageIndex})} onClick={()=>loadMessage(type,index)}>
                                        <a href="#"
                                           style={{backgroundImage: 'none'}}>{t("TEXT_MESSAGE_P")}</a>
                                        <button className="message_closebox" onClick={(e)=>this.removeMessage(e, type, index)}></button>
                                    </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        messages: state.schedules.messages
    }),
    dispatch => ({
        addMessage: (type)=>dispatch(scheduleAction.addMessage(type)),
        removeMessage: (type, messageId)=>dispatch(scheduleAction.removeMessage(type, messageId)),
        loadMessage: (type, messageId)=>dispatch(scheduleAction.loadMessage(type, messageId)),
        setRemoveIndex: (mode, index)=> dispatch(scheduleAction.setRemoveIndex(mode, index)),
    })
)(withTranslation()(SubmenuMessageContainer));