import {isNil} from "lodash";
import {settingConstants} from "../../constants";
import {getMixString} from "../../language/languageUtils";
import i18n from '../../language/i18n';

export {
    isEnableMfa,
    getStoredDeviceInfo,
    getStoredDevicePeriodInfo,
    isEnableAuthStoredDevice,
    updateMfaInfo
}


const isEnableMfa = () => {
    let isEnableMfa = false;

    if(!isNil(localStorage.getItem(settingConstants.MFA))){

        let mfaInfo = JSON.parse(localStorage.getItem(settingConstants.MFA));
        isEnableMfa = mfaInfo.useMFA;
    }
    return isEnableMfa;
}

const getMfaInfo = () => {
    let mfaInfo = {};
    if(!isNil(localStorage.getItem(settingConstants.MFA))){
        mfaInfo =  JSON.parse(localStorage.getItem(settingConstants.MFA));
    }
    return mfaInfo;
}


const updateMfaInfo = (newMfaInfo) => {
    let mfaInfo = {};
    if(!isNil(localStorage.getItem(settingConstants.MFA))){
        mfaInfo =  JSON.parse(localStorage.getItem(settingConstants.MFA));
    }

    const newData = {...mfaInfo, ...newMfaInfo};
    localStorage.setItem(settingConstants.MFA, JSON.stringify(newData));
    return newData;

}



const getStoredDeviceInfo = () => {

    const mfaInfo = getMfaInfo();
    return (!isNil(mfaInfo) && !isNil(mfaInfo.mfaList))? mfaInfo.mfaList.find((item) => {return item.mfaType === settingConstants.MFA_STORED_DEVICE}) : {};
}


const isEnableAuthStoredDevice = () => {

    let isEnableAuthStoredDevice = false;

    const authDevice = getStoredDeviceInfo();

    if(!isNil(authDevice)){
        isEnableAuthStoredDevice = authDevice.authEnable;
    }

    return isEnableAuthStoredDevice;
}


const getStoredDevicePeriodInfo = () => {

    let storedDevicePeriod = {};

    const authDevice = getStoredDeviceInfo();
    if(!isNil(authDevice)){
        storedDevicePeriod = {
            "usePeriod" : authDevice.usePeriod,
            "period" : authDevice.period,
        };
    }

    return storedDevicePeriod;
}

export const getOtpLoginErrorMsg = (e) => {


    let errorMsg = {
        error1:' ',
        error2:' '
    };

    if (isNil(e.errorCode)) {
        errorMsg.error1 = i18n.t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN");
        return;
    }

    const failCount = ( !isNil(e.errorDetails) &&  !isNil(e.errorDetails.failCount) ) ? e.errorDetails.failCount : 0;
    const retryCount = ( !isNil(e.errorDetails) &&  !isNil(e.errorDetails.retryCount) ) ? e.errorDetails.retryCount : 0;

    switch(e.errorCode){
        case "401013":

            // otp invalid

            errorMsg.error1 = i18n.t(getMixString(['MIS_SID_MIX_CAJUL_WRONG_CODE_PLZ_AGAIN', failCount, retryCount]));
            errorMsg.error2 = i18n.t(getMixString(['MIS_SID_CAJUL_THE_OTP_AUTH_NOT_USED_AFTER_FIVE_INCORRECT_ATTEMPTS', retryCount]));

            break;

        case "401015" :

            errorMsg.error1 = i18n.t(getMixString(['MIS_SID_MIX_CBFEB_ENTERED_INCORRECT_ATTEMPTS_ACCOUNT_BLOCKED_TEMPORARILY', retryCount]));
            //errorMsg.error2 = i18n.t("COM_TV_SID_CMS_PLEASE_CONTACT_YOUR_SYSTEM_OR_COMPANY_ADMINISTRATOR");
            break;

        default :

            errorMsg.error1 = i18n.t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN");
            break;
    }
    return errorMsg;
}
