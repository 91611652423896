import React from 'react'
import ADSIcon from '../../../images/icon/file_type_icon_17.png';
import classNames from 'classnames';

const ADS = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={ADSIcon}/>
            {
                !onlyIcon && 'ADS'
            }
        </span>
    )
}
export default ADS;