import React, {useEffect, useState} from 'react';
import {scheduleService} from "../../../../services";
import {connect, useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import ScheduleViewCalendar from "../ScheduleViewCalendar";
import EventScheduleDetail from './EventScheduleDetail';
import {getErrorMessage} from "../../../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';
import {useMISOpt} from "../../../misopt";
import {commonConstants} from "../../../../constants";

const EventScheduleDetailView = ({id,zIndex}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [event, setEvent] = useState();
    const [open, setOpen] = useState(false);
    const [authority, setAuthority] = useState({});
    const {getAuthority} = useMISOpt();

    useEffect(()=> {
        setAuthority(getAuthority('SCHEDULE'));
        scheduleService.fetchEventScheduleById(id).then(
            res=> {
                setEvent(res.items.data);
                setOpen(true);
            }
        ).catch((e)=> toastr.error(getErrorMessage(e)))
    }, [])

    const closePopup = (id) => {
        dispatch(popupAction.closePopup(id));
    }

    const saveEventSchedule = () => {
        const {eventScheduleName} = event;
        if(eventScheduleName == null || eventScheduleName == ''){
            toastr.error(t("MESSAGE_SCHEDULE_ENTER_SCH_NAME_P"));
            return;
        }
        if(eventScheduleName.trim() === ''){                                  
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return;
        }
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}))
        scheduleService.updateEventScheduleNameGroup(id, event)
            .then(
                res => {
                    if (res) {
                        toastr.success(t("TEXT_SUCCESS_P"))
                        closePopup(id)
                    }
                }
            )
            .catch(
                e=> toastr.error(getErrorMessage(e))
            )
            .finally(()=>{
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
                dispatch(menuAction.forceReloadContent('ALL_EVENT_SCHEDULE'));
            })
    }

    const editEventSchedule = () => {
        const tab = {id: 'EDIT_EVENT', title: t("MIS_SID_20_EDIT_EVENT"), active: true, close: true};
        new Promise(()=> {
            const {selectedEventList} = event;
            selectedEventList.map(
                selectedEvent => {
                    const {duration} = selectedEvent;
                    const durationHour = Math.floor(duration / 3600);
                    const durationMin = Math.floor((duration - (durationHour * 3600)) / 60);
                    selectedEvent.durationMin = durationMin;
                    selectedEvent.durationHour = durationHour;
                }
            )
            dispatch(scheduleAction.initEventSchedule('edit',event));
        }).then(
            dispatch(menuAction.loadContent('EDIT_EVENT'))
        ).then(
            dispatch(menuAction.addTab(tab))
        ).then(
            dispatch(popupAction.closePopup(id))
        )
    }

    const updateEvent = (updatedEvent) => {
        setEvent(updatedEvent);
    }


    const dialogProps = {title: event !== undefined && event.eventScheduleName !== undefined ? event.eventScheduleName : '' ,isDraggable: true, closeOnEscape : true, allowMinimize : true, width :880, height :550, position: {x: -300, y: -400},zIndex: zIndex, popupId:id, onClose: () => closePopup(id)};
    const buttons = {
        rightButtons: [
            {
                id: 'EDIT_EVENT',
                title: t("MIS_SID_20_EDIT_EVENT"),
                onClick: () => editEventSchedule(),
                authority: authority.CREATE || authority.MANAGE
            },
            {
                id: 'SAVE_EVENT_SCHEDULE',
                title: t("COM_BUTTON_SAVE"),
                onClick: () => saveEventSchedule(),
                authority: authority.CREATE || authority.MANAGE
            },
            {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: () => closePopup(id)}
        ]
    }

    return (
        <>
        {
            open &&
                <MISDialog
                    dialog={dialogProps}
                    buttons={buttons}
                    height={400}
                >

                    <div className="popup_contents clearfix">
                        <div className="playlist_imgview" style={{width:400}}>
                            <ScheduleViewCalendar type={"EVENT"} program={event}/>
                        </div>
                        <div className="playlist_detail ml25 mb5" style={{width:'45%'}}>
                            <EventScheduleDetail event={event} updateEvent={updateEvent}/>
                        </div>
                    </div>

                </MISDialog>
        }
        </>
    )





}
export default connect(
    null,
    dispatch => ({
        closePopup: (id)=>dispatch(popupAction.closePopup(id))
    })

)(EventScheduleDetailView);