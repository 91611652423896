import React, {useEffect, useState} from 'react';
import Iframe from 'react-iframe';

const ExternalLink = (props) => {

    return (
        <Iframe url={props.url}
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/>
    );
};

export default ExternalLink;