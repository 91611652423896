import {useTranslation} from "react-i18next";
import React, {useRef, useMemo} from "react";
import MISDialog from "../../MISDialog";

const LedSunriseSunsetTimesPopup = ({onClose, sunriseSunsetData}) => {

    const {dimmingSunriseSunsetTimes: data, dimmingSunriseSunset: {latitude, longitude}} = sunriseSunsetData;
    const {t} = useTranslation();
    const tableRef = useRef();
    const tableWidth = 100 * data.length + 100;
    
    const moveTableHeader = (e) => {
        const thead = tableRef.current.getElementsByClassName("table-head")[0];
        const tbody = tableRef.current.getElementsByClassName("table-body")[0];
        thead.style.transform = `translate(-${tbody.scrollLeft}px, 0)`;
    }
    
    const today = useMemo(() => new Date(), []);

    const yesterday = useMemo(() => {
        const y = new Date();
        y.setDate(today.getDate() - 1);
        return y;
    }, []);
    
    const tomorrow = useMemo(() => {
        const t = new Date();
        t.setDate(today.getDate() + 1);
        return t;
    }, []);

    return (
        <div>
            <MISDialog
                dialog={{
                    title: t('TMP_TSIM_LED_SUNRISE_SUNSET_TIME'),
                    closeOnEscape: true,
                    modal: true,
                    width: 810,
                    height: 190,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ORGANIZATION_LICENSE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                <div>
                    <div className={'mb15'} style={{height:28, lineHeight:2.2}}>
                        <span style={{float:'left'}}>
                            <span style={{marginLeft:12, marginRight:10}}>{t('TMP_TSIM_CURRENT_LOCATION')} :</span>
                            <span>{latitude}, {longitude}</span>
                        </span>
                    </div>
                </div>
                <div className="server_setting none_scrolling check_list" style={{overflow:"hidden"}} ref={tableRef}>
                    <table style={{width:tableWidth}} class="table-head">
                        <colgroup>
                            <col width="100px"/>
                            {
                                data.map(day => {
                                    return (
                                        <col width="100px"/>            
                                    )
                                })
                            }
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{padding:'12px'}}>{t('TEXT_DAY_P')}</th>
                                {
                                    data.map(day => {
                                        if(today.getMonth()+1 == day.month && today.getDate() == day.day){
                                            return (
                                                <th style={{padding:'12px', color:'#3196ff'}}>{t('TEXT_TODAY_P')}</th>
                                            )
                                        } else if(yesterday.getMonth()+1 === day.month && yesterday.getDate() === day.day){
                                            return (
                                                <th style={{padding:'12px'}}>{t('MIS_TEXT_STATISTICS_POP_YESTERDAY_P')}</th>
                                            )
                                        } else if(tomorrow.getMonth()+1 === day.month && tomorrow.getDate() === day.day){
                                            return (
                                                <th style={{padding:'12px'}}>{t('TMP_TSIM_TOMORROW')}</th>
                                            )
                                        } else {
                                            return (
                                                <th style={{padding:'12px'}}>{day.month}-{day.day}</th>
                                            )
                                        }
                                    })
                                }
                            </tr>
                        </thead>
                    </table>
                    <div style={{height:'100px', overflow:'auto'}} onScroll={moveTableHeader} class="table-body">
                        <table style={{width:tableWidth}}>
                            <colgroup>
                                <col width="100px"/>
                                {
                                    data.map(day => {
                                        return (
                                            <col width="100px"/>            
                                        )
                                    })
                                }
                            </colgroup>                            
                            <tbody>
                                <tr>
                                    <td style={{borderRight:'1px solid #f0f0f0'}}>{t('TMP_TSIM_LED_SUNRISE')} AM</td>
                                    {
                                        data.map(day => {
                                            return (
                                                <td style={{borderRight:'1px solid #f0f0f0'}}>
                                                    <span style={{color:today.getMonth()+1 == day.month && today.getDate() == day.day?'#3196ff':undefined}}>{day.sunriseHour}:{day.sunriseMinute}</span></td>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td style={{borderRight:'1px solid #f0f0f0'}}>{t('TMP_TSIM_LED_SUNSET')} PM</td>
                                    {
                                        data.map(day => {
                                            return (
                                                <td style={{borderRight:'1px solid #f0f0f0'}}>
                                                    <span style={{color:today.getMonth()+1 == day.month && today.getDate() == day.day?'#3196ff':undefined}}>{day.sunsetHour}:{day.sunsetMinute}</span></td>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
}

export default LedSunriseSunsetTimesPopup;