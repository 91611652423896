import {useTranslation} from "react-i18next";
import {userService} from "../../../services";
import {toastr} from "../../../helper/toastrIntercept";
import Moment from "react-moment";
import BASKET from "../../../images/icon/icon_list_delete_nor.png";
import {isEmpty} from "lodash";
import MagicInfoTable from "../../table/MagicInfoTable";
import React, {useState} from "react";
import MISDialog from "../MISDialog";
import {useMISOpt} from "../../../components/misopt";
import {toMomentDateTimeFormat} from "../../../helper";


const StoredDevicePopup = ({ userId, storedDeviceData, onClose}) => {
    const {t} = useTranslation();
    const [storedDevice, setStoredDevice] = useState(storedDeviceData);
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);


    const onDeleteStoredDevice = (deviceId) => {

        userService.deleteStoredDeviceById(userId, deviceId).then(res => {
            if (res.status === 'Success') {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));

                storedDevicesPopup();

            }

        }).catch(err => {

            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));

        });
    }

    const columns = [
        {
            Header: t('MIS_TEXT_DEVICE_TYPE_P'),
            accessor: "deviceInfo",
            width: 280,
            Cell: ({value}) => {
                const deviceInfoArray = value.split(":");
                const deviceAuthInfo = JSON.parse(localStorage.getItem('device_' + userId));

                return (<div>
                        <span>{deviceInfoArray[1]}</span>
                        {
                            deviceAuthInfo && String(deviceAuthInfo.id) === String(deviceInfoArray[0]) &&
                            <span className = "current">Current</span>
                        }
                    </div>
                );
            }
        },
        {
            Header: t('TEXT_LAST_LOGIN_DATE_P'),
            accessor: 'latestDate',
            width: 160,
            Cell: ({value}) => <Moment format={dateTimeFormat}>{value}</Moment>,
        },
        {
            Header: t('COM_BUTTON_DELETE'),
            accessor: "authDeviceId",
            width: 50,
            align: 'center',
            Cell: ({value}) => <img width="12px" height="14px" src={BASKET} className={"basket"}
                                    onClick={() => onDeleteStoredDevice(value)}/>,
        }
    ];

    const storedDevicesPopup = () => {

        userService.fetchStoredDevices(userId).then(res => {

            let storedDevice = res.items.map(data => ({
                ...data,
                deviceInfo: `${data.authDeviceId}:${data.osName} ${data.osVersion} ${data.browserName} ${data.browserVersion}`
            }));

            setStoredDevice(storedDevice);

        }).catch(err => {
            err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
        });
    }

    return (
        <div className='device_memo_popup'>
            <MISDialog
                dialog={{
                    title: t('MIS_SID_CASEP_TRUSTED_DEVICES'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 540,
                    height: 228,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'COM_BUTTON_CLOSE_P',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className="detail_view mini_p" style={{width:530, height: 246}}>
                    <div>
                        {
                            isEmpty(storedDevice) ?
                                <div>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
                                :
                                <div>
                                    <div>

                                        <MagicInfoTable
                                            data={storedDevice}
                                            columns={columns}
                                        />

                                    </div>
                                    <div style={{
                                        marginTop: 10,
                                        marginLeft: 5
                                    }}>{t('MIS_SID_MIX_CAJUL_DIVICES').replace("<<A>>", storedDevice.length)} </div>
                                </div>
                        }
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default StoredDevicePopup;
