import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {popupAction} from "../../../../actions";
import './PeripheralsDetail.css';
import './DeviceDetailView.css';
import CircleButton from '../../../button/CircleButton';
import RefreshIcon from '../../../../images/icon/refresh_normal.png';
import {deviceService} from '../../../../services';
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from '../../../../helper/responseHandler';
import {isNil} from 'lodash';
import {commonConstants} from "../../../../constants";

const PeripheralsDetail = ({device, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const [peripherals, setPeripherals] = useState([]);

    const onRefresh = () => {
        addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
        if(device.power){
            deviceService.getDeviceCurrentGeneralInfo(device.deviceId, res => {
                closePopup('EDIT_DEVICE_LOADING');
                if(res && res.items && res.items.successList && res.items.successList.length > 0){
                    const {peripherals} = res.items.successList[0];
                    setPeripherals(peripherals);
                    toastr.success(t('MSG_FINISHED'));
                } else {
                    setPeripherals([]);
                    toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                }                
            }, 15, 1500).catch(error => toastr.error(getErrorMessage(error)));
        } else {
            toastr.error(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
        }
    }

    useEffect(() => {
        deviceService.fetchDeviceGeneralInfo(device.deviceId).then(res => {
            if(res && res.items && res.items.successList && res.items.successList.length > 0){
                const {generalConf : {peripherals}} = res.items.successList[0];
                setPeripherals(peripherals);
            } else {
                setPeripherals([]);
            }
        }).catch(error => toastr.error(getErrorMessage(error)));
    }, [device.deviceId]);

    return (
        <div className="detail_view mini_8 mt15" style={{overflow:'hidden'}}>
            <div style={{marginBottom: '20px'}}>
                <span>&nbsp;</span>
                {
                    device.power &&
                    <CircleButton style={{float:'right'}} onClick={onRefresh}>
                            <span style={{display: 'inline-block', height: 28, width: 28, background: `url(${RefreshIcon}) no-repeat 5px 6px`}}></span>
                    </CircleButton> 
                }               
            </div>
            <div className="detail_table table_head mt9">
                <table width="100%;">
                    <colgroup>
                        <col width="120px"/>
                        <col width=""/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('TABLE_PORT_P')}</th>
                            <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                        </tr>
                    </thead>
                </table>            
            </div>
            <div className="detail_table table_body" style={{height:'340px'}}>
                <table width="99%;">
                    <colgroup>
                        <col width="120px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            peripherals && peripherals.length > 0 &&
                            peripherals.map((peripheral) => {
                                return (
                                    <tr>
                                        <td>
                                            <span>{peripheral.type}</span>
                                            {
                                                !isNil(peripheral.port) && <span> {peripheral.port}</span>
                                            }                                            
                                        </td>
                                        <td>
                                            {peripheral.name}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {
                            (isNil(peripherals) || peripherals.length <= 0) && 
                            <tr>
                                <td colspan="2" style={{textAlign: "center"}}>{t("MESSAGE_COMMON_NO_DATA_P")}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

PeripheralsDetail.defaultProps = {
    device: {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(PeripheralsDetail);
