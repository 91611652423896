import React, {useEffect, useRef} from 'react';

const LedCabinetLayoutOpener = ({layoutInfo}) => {
    const {layoutAuthorPath, userId, deviceId, deviceGroupId, token, language} = layoutInfo;
    const formRef = useRef();
    console.log(layoutInfo);
    
    useEffect(() => {
        formRef.current.submit();
    }, []);

    // 			var userNameInput = '<input type="hidden" name="username" value="'+userId+'" />';
	// 			var passwordInput = '<input type="hidden" name="password" value="'+password+'" />';
	// 			var tokenInput = '<input type="hidden" name="token" value="'+token+'" />';
	// 			var language = '<input type="hidden" name="language" value="'+language+'" />';
	// 			var deviceType = '<input type="hidden" name="deviceType" value="LED" />';
	// 			var deviceId = '<input type="hidden" name="deviceId" value="'+selDeviceId+'" />';
	// 			var groupId = '<input type="hidden" name="deviceGroupId" value="'+selGroupId+'" />';
    // 			var editType  = '<input type="hidden" name="editType" value="NEW" />';
    
    	// 			$(layoutauthorForm).empty().append(userNameInput).append(passwordInput).append(tokenInput).append(language).append(deviceType).append(deviceId).append(groupId).append(editType);
	// 			$(layoutauthorForm).submit();

    return (
        <form action={layoutAuthorPath} target='LayoutauthorWindow' method='post' ref={formRef} style={{display: 'none'}}>
            <input type='hidden' name='username' value={userId} />
            <input type='hidden' name='token' value={token} />
            <input type='hidden' name='language' value={language} />
            <input type="hidden" name="deviceType" value="LED" />
            <input type="hidden" name="deviceId" value={deviceId} />
            <input type="hidden" name="deviceGroupId" value={deviceGroupId} />
            <input type="hidden" name="editType" value="NEW" />
        </form>
    );
};

export default LedCabinetLayoutOpener;