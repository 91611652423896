import React, {forwardRef} from 'react';
import './WhiteButton.css';

const WhiteButton = ({id, name, disable, selected, onClick, onMouseDown, onMouseUp, width, style, authority = true, tooltip = false, tooltipText}, ref) => {
    const buttonStyle  = {...style};
    const title = tooltip ? (tooltipText ? tooltipText : name) : '';

    if (width != undefined) {
        buttonStyle.width = width;
    }
    if (!authority) {
        return;
    }

    return (
        <button 
            id={id} 
            className={"whiteButton "+ (disable ? 'disabled' : selected ? 'selected' : '')} 
            disabled={disable} 
            onClick={onClick} 
            onMouseDown={onMouseDown} 
            onMouseUp={onMouseUp} 
            ref={ref} 
            style={buttonStyle}
            title={title}>
            {name}
        </button>
    )
};
export default forwardRef(WhiteButton);