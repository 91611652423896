import React, {useEffect, useState} from "react";
import MISDialog from "../MISDialog";
import {useTranslation, withTranslation} from "react-i18next";
import {commonConstants, settingConstants} from "../../../constants";
import {popupAction} from "../../../actions";
import {useDispatch} from "react-redux";
import Moment from "react-moment";
import SalesNPlaybackTimeChart from "./SalesNPlaybackTimeChart";
import {insightService} from "../../../services";
import ContentThumbnail from "../../image/ContentThumbnail";
import {useMISOpt} from '../../../components/misopt';
import {toMomentDateTimeFormat} from '../../../helper/utils';

const InsightInformationPopup = ({contentId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [insightContent, setInsightContent] = useState({});
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    useEffect(() => {
        insightService.getInsightInformationByContentId(contentId).then(
            res => {
                setInsightContent(res.items);
            }
        ).finally(() => {
            //closePopup('CONTENT_DETAIL');
        });
    }, []);

    const closePopup = () => {
        dispatch(popupAction.closePopup(commonConstants.INSIGHT_INFORMATION))
    };

    return (
        <MISDialog
            dialog={{
                title: t("MIS_SID_CBFEB_INSIGHT_INFORMATION"),
                modal: true,
                closeOnEscape: true,
                width: 850,
                onClose: () => closePopup(),
            }}
            buttons={{
                rightButtons: [
                    {id: "CANCEL", title: t("COM_BUTTON_CLOSE_P"), onClick: closePopup}
                ]
            }}>

            {
            /*    content !== undefined &&*/
                <div>
                    <div className="type1">
                        <div className="popup_contents clearfix" style={{display:'inline'}}>
                            <div className="playlist_imgview mt25" style={{minHeight: 450}}>
                                <div className="preview_wrap" align="center" style={{width:370}}>
                                    <ContentThumbnail id={insightContent.thumbFileId} resolution ={'ORIGIN'} width={370} height={208} textAlign={true}/>
                                </div>
                            </div>
                            <div className="content_detail ml50 mb15" style={{width:420, float: 'left'}}>
                                <div className="detail_view" id="detailsDiv">
                                    <div style={{overflowY: 'auto', maxHeight: '550px'}}>
                                        {
                                            insightContent &&
                                            <div style={{overflowX: 'hidden'}}>
                                                <table style={{borderSpacing: 0}}>
                                                    <colgroup>
                                                        <col width="100px"/>
                                                        <col width=""/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th><span className="popupTh">{t('TEXT_CONTENT_NAME_P')}</span>
                                                        </th>
                                                        <td>{insightContent.contentName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="popupTh">{t('BUTTON_DETAIL_P')}</span></th>
                                                        <td>{insightContent.contentTotalSize} {insightContent.contentType} {insightContent.contentPlayTime}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="popupTh">{t('TEXT_LAST_MODIFIED_DATE_P')}</span>
                                                        </th>
                                                        <td><Moment format={dateTimeFormat}>{insightContent.contentLastModifiedDate}</Moment></td>
                                                    </tr>
                                                    <tr>
                                                        <th><span className="popupTh">{t('COM_MIS_TEXT_PRODUCT_NAME_P')}</span></th>
                                                        <td>{insightContent.productIds}</td>
                                                    </tr>
                                                    {
                                                        insightContent.duration &&
                                                        <tr>

                                                            <th><span className="popupTh">{insightContent.duration.nickname}</span></th>
                                                            <td>{insightContent.duration.startDate}~{insightContent.duration.endDate}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <th><span
                                                            className="popupTh">{t('MIS_SID_INSIGHT_CBJAN_TOTAL_SALES')}</span>
                                                        </th>
                                                        <td> {insightContent.totalPriceUnit} {insightContent.totalPrice}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><span
                                                            className="popupTh">{t('MIS_SID_INSIGHT_CBJAN_SALES_INCREASE')}</span>
                                                        </th>
                                                        <td>{insightContent.salesGrowthRate} %</td>
                                                    </tr>
                                                    <tr>
                                                        <th><span
                                                            className="popupTh">{t('MIS_SID_CBFEB_SALES_PER_PLAYING_HOUR')}</span>
                                                        </th>
                                                        <td>{insightContent.totalPrice / (insightContent.totalPlaybackSecond / 3600)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><span
                                                            className="popupTh">{t('MIS_SID_TOTAL_PLAYBACK_TIME')}</span>
                                                        </th>
                                                        <td>{insightContent.totalPlaybackSecond}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><span
                                                            className="popupTh">{t('MIS_SID_TOTAL_PLAYBACK_COUNT')}</span>
                                                        </th>
                                                        <td>{insightContent.totalPlaybackCount}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                {
                                                    insightContent.days &&
                                                    <SalesNPlaybackTimeChart days={insightContent.days}/>
                                                }
                                                </div>
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </MISDialog>

    )
}

export default withTranslation()(InsightInformationPopup);