import React from 'react';
import {useTranslation} from 'react-i18next';
import Moment from "react-moment";
import {useMISOpt} from '../misopt';
import {toMomentDateTimeFormat} from '../../helper';

const DeviceRelativeTimeCell = ({value = null, format, useBr}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    if(value) {
        return (
            <>
                <span>{diffMin(value, t)}</span>
                {useBr && <br/>}
                <span>(<Moment format={format || dateTimeFormat}>{value}</Moment>)</span>
            </>
        );
    } else {
        return (
            <div></div>
        );
    }
};

DeviceRelativeTimeCell.defaultProps = {
    useBr: false,
};

const diffMin = (sTime,t) =>{
    let res = "";
    const diff = parseInt((Number((new Date()).getTime()) - Number(sTime)) / (1000*60) );
    const about       = t("MIS_TEXT_ABOUT_P");
    const monthAgo    = t("MIS_TEXT_MONTH_AGO_P");
    const monthsAgo   = t("MIS_TEXT_MONTHS_AGO_P");
    const dayAgo      = t("MIS_TEXT_DAY_AGO_P");
    const daysAgo     = t("MIS_TEXT_DAYS_AGO_P");
    const hourAgo     = t("MIS_TEXT_HOUR_AGO_P");
    const hoursAgo    = t("MIS_TEXT_HOURS_AGO_P");
    const minAgo      = t("MIS_TEXT_MINUTE_AGO_P");
    const minsAgo     = t("MIS_TEXT_MINUTES_AGO_P");
    const aMomentAgo  = t("MIS_TEXT_A_MOMENT_AGO_P");
    const year        = t("MIS_SID_1_YEAR_AGO");
    let   years       = t("COM_TV_SID_MIX_YEARS_AGO");

    if(diff >= 0){

        if(diff > 60*24*30*12){

            if(parseInt(diff/(60*24*30*12))=== 1){
                res =  about +" "+ year;
            }else{
                years = years.replace("<<A>>", String(parseInt(diff/(60*24*30*12))));
                res =  about +" " +years;
            }
        }else if(diff > 60*24*30){
            if(parseInt(diff/(60*24*30)) === 1){
                res = about +" "+ (parseInt(diff/(60*24*30))) +" "+ monthAgo;
            }else{
                res = about +" "+ (parseInt(diff/(60*24*30))) +" "+ monthsAgo;
            }
        }else if(diff > 60*24){
            if(parseInt(diff/(60*24)) === 1){
                res =  about +" "+ (parseInt(diff/(60*24))) +" "+ dayAgo;
            }else{
                res =  about +" "+ (parseInt(diff/(60*24))) +" "+ daysAgo;
            }
        }
        else if(diff > 60){
            if(parseInt(diff/60) === 1){
                res = about +" "+ parseInt((diff/60)) +" "+ hourAgo;
            }else{
                res = about +" "+ parseInt((diff/60)) +" "+ hoursAgo;
            }

        }else if(diff > 0){
            if(diff === 1){
                res = about +" "+ (diff) +" "+ minAgo;
            }else{
                res = about +" "+ (diff) +" "+ minsAgo;
            }
        }else{
            res = aMomentAgo;
        }
    }
    return res;
}

export default DeviceRelativeTimeCell;