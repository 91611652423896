import React from 'react';
import Checkbox from "../checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import ContentIcon from "../icon/contents";
import classNames from "classnames";

const ContentFileTypes = ({id, contents, toggleContentAll, toggleContent, contentCheckBox, filterMode=true, contentCheckAll, selectedContents=[]}) => {

    const {t} = useTranslation();

    const isChecked = (content)=>{
        if(selectedContents.length == contents.length || (selectedContents.length > 0 && selectedContents[0] ==='ALL'))
            return true;
        else {
            let idx = selectedContents.findIndex( (item) => (item ==content));
            return idx >= 0 ? true: false
        }
    }


    return (
        <div className={classNames({"filterMode" : !filterMode, "dev_filter_type_checkbox" : filterMode})} id="filter_list_content_type">

            <h5 style={{display: filterMode ? '':'none'}}><span className="content_type" style={{background:'#ffc446'}}></span>{t("COM_LIST_TITLE_FILE_TYPE")}</h5>

            <div className='content_check_box' style={{overflowY:'auto'}}>
                <ul style={{overflowY: 'auto'}}>
                    <li>
                        <Checkbox id={id+"_contentTab_content_all"}
                                  classname={'text_all'}
                                  name={t("TEXT_ALL_P")}
                                  ref={contentCheckAll}
                                  onChange={toggleContentAll}
                                  checked={(selectedContents.length == contents.length || selectedContents[0] ==='ALL') ? true : false}
                        />
                    </li>
                    {
                        contents && contents.map(
                            (content, index) => (
                                <li key={index}>
                                    <Checkbox id={id+"_contentTab_content_" + content.toLowerCase()}
                                              name={<ContentIcon type={content}/>}
                                              ref={ref => contentCheckBox[content] = ref}
                                              onChange={()=>toggleContent(content)}
                                              checked = {isChecked(content)}
                                    />
                                </li>
                            )
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
export default ContentFileTypes;