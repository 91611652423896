import React, {useEffect, useState} from "react";
import {MoonLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {menuAction} from "../../../../actions";
import {dashboardService} from "../../../../services";
import {toastr} from "../../../../helper/toastrIntercept";
import {getErrorMessage} from "../../../../helper/responseHandler";
import './CustomUser.css';
import {dashboardConstants} from "../../../../constants/DashboardConstants";
import {SortableHandle} from "react-sortable-hoc";

const CustomUser = ({removeWidget}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [mouseOver, setMouseOver] = useState(false);

    const {reload} = useSelector(state => state.dashboard);

    const onAllUserClickEvent = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('USER'))
        }).then(dispatch(menuAction.moveTab({id: 'USER', title : t("TEXT_TITLE_USER_P"), active: true, close: true}, 'ALL_USER')))
    }

    const onWithdrawnUserClickEvent = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('USER'))
        }).then(dispatch(menuAction.moveTab({id: 'USER', title : t("TEXT_TITLE_USER_P"), active: true, close: true}, 'WITHDRAWN_USERS')))
    }

    const fetchGetUserInfo = () => {
        setLoading(true)
        dashboardService.fetchUserInfo().then(res => setUser(res.items))
            .catch(err => toastr.error(getErrorMessage(err, err.errorMessage)))
            .finally(() => setLoading(false));
    };

    const DragHandle = SortableHandle(() =>
        <div className='title'>{t("TEXT_USER_SUMMARY_P_KR_MIS20")}</div>
    );

    useEffect(()=> {
        fetchGetUserInfo();
    }, [reload])


    return (
        <div className={'custom_widget'} onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)}>

            <div className='header'>
                <DragHandle />
                {
                    mouseOver && <div className={'close'} onClick={(e)=>removeWidget(e, dashboardConstants.DASHBOARD_CUSTOM_USER_WIDGET)}></div>
                }
            </div>
            <div className='custom_widget_body'>
                {loading && <MoonLoader css={`position: absolute;top: calc(50%);left: calc(50% - 40px);`} color="#6e78c8"/>}
                {!loading &&
                <div className='user-summary'>
                    <div style={{display: 'flex', justifyContent:'center', width: '100%', marginTop: 60}}>
                        <div style={{width: '50%', cursor: 'pointer'}} onClick={onAllUserClickEvent}>
                            <div className={'count'}>{user.totalInCount}</div>
                            <div className={'title'}>{t("BUTTON_ALL_USERS_P")}</div>
                        </div>
                        <div style={{width: '50%', cursor: 'pointer'}} onClick={onWithdrawnUserClickEvent}>
                            <div className={'count'}>{user.totalOutCount}</div>
                            <div className={'title'}>{t("TEXT_TITLE_WITHDRAWAL_P")}</div>
                        </div>
                    </div>

                </div>
                }
            </div>


        </div>
    )
}
export default CustomUser;