import React, {memo, useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {contentService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import Size from "../../components/utils/Size";
import {connect, useSelector} from "react-redux";
import {contentAction, menuAction, popupAction} from "../../actions";
import {commonConstants, contentConstants} from "../../constants";
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import './Content.css'
import ContentThumbnail from "../../components/image/ContentThumbnail"
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {toastr} from 'helper/toastrIntercept';
import {useCheckRefForContent, useFilter} from "../../helper";
import {useMISOpt} from "../../components/misopt";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";

const ContentRecycleBin = (props) => {

    const {t} = useTranslation();

    const loadTrashBasket = useSelector(({content}) => content.loadTrashBasket);

    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        items: props.cache.items !== undefined ? props.cache.items : [],
        loading: false,
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({
        height: '500px'
    });

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;
    const {addPopup, closePopup, loadTrashBasketPopup, reloadGroup, updateSubMenuCounter} = props;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt, initData, toggleSelectAllForCardView, toggleCard, toggleRowWithIndex] = useCheckRefForContent(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;
        setData({...data, loading: true});
        contentService.fetchContentFilter({
            isThumbnail: true,
            isUsedContent: true,
            startIndex: (page * pageSize) + 1,
            pageSize: pageSize,
            searchText: keyword,
            groupType: 'DELETED',
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            setData({
                ...data,
                loading: false,
                items: res.items,
                totalCount: res.totalCount
            });

            if(loadTrashBasket){
                loadTrashBasketPopup(false);
                deleteAllContentPopup("deleteAllContentPopup");
            }
            // update cache
            updateCache('CONTENT', {items: res.items, filter: filter, totalCount: res.totalCount})
        });
    };

    const restoreContent = (id) => {
        const targetContentIds = getCheckedContentId();
        if(targetContentIds.length === 0){
            return;
        }
        const params = {
            contentIds : targetContentIds
        }
        contentService.restoreContentById(params)
            .then(res => {
                    toastr.success(t('COM_TEXT_SUCCESS_P'));
                    fetchData();
                    reloadGroup('MY_CONTENT');
                    reloadGroup('BY_USER');
                    reloadGroup('SHARED_CONTENT');
                    updateSubMenuCounter("CONTENT");
                }
            ).catch(error => {
                let errorMessage = error.errorMessage;
                switch(error.errorCode) {
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                    default:
                        errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                        break;
                }
                toastr.error(errorMessage);
            }).finally(() => {
                closePopup(id);
            });
    };

    const getCheckedContentId = () => {
        return selected.current.map(key => items[key].contentId);
    };

    const restoreContentPopup = (id) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("BUTTON_RESTORE_P"),
            message: t('MESSAGE_CONTENT_CONFIRM_RESTORE_CONTENT_P'),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => restoreContent(id),
            onClose: ()=> closePopup(id)
        });
    };

    const deleteContentPopup = (id) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("COM_BUTTON_DELETE"),
            message: <>{t('MIS_SID_CBFEB_IF_DELETE_SELECTED_ITEMS_DATCANNOT_RESTORED')}<br/>{t('MIS_SID_CBFEB_SURE_WANT_DELETE_SELECTED_ITEMS')}</>,
            useMessageLine: false,
            height: 90,
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => deleteContent(id),
            onClose: ()=> closePopup(id)
        });
    };

    const deleteContent = (id) => {
        const targetContentIds = getCheckedContentId();
        if(targetContentIds.length === 0){
            return;
        }

        const requestBody = {
            contentIds: targetContentIds,
            deleteMethod: contentConstants.DELETE_FORCE
        };

        contentService.deleteContentById(requestBody)
            .then(res => {
                toastr.success(t('MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P'));
                fetchData();
            }).catch(error => {
                let errorMessage = error.errorMessage;
                switch(error.errorCode) {
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                    default:
                        errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                        break;
                }
                toastr.error(errorMessage);
            }).finally(() => {
                closePopup(id);
            });
    }

    const deleteAllContentPopup = (id) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("COM_BUTTON_DELETE_ALL_P"),
            message: t('MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P'),
            onClickYes: () => deleteAllContent(id),
            onClose: ()=> closePopup(id)
        });
    };

    const deleteAllContent = (id) => {
        contentService.deleteAll()
            .then(res => {
                toastr.success(t('MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P'));
                setFilter({
                    ...filter,
                    page: 0,
                });
            }).catch(error => {
            let errorMessage = error.errorMessage;
            switch(error.errorCode) {
                case '403001':
                    errorMessage = t('ERROR_NO_ROLE');
                    break;
                case '400012':
                    errorMessage = t('MESSAGE_CONTENT_RECYCLE_BIN_IS_EMPTY_P');
                    break;
                default:
                    errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                    break;
            }
            toastr.error(errorMessage);
        }).finally(() => {
            closePopup(id);
        });
    };

    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate} />
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'ContentRecycle_All'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].contentId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 40,
            sortable: false,
            resizable: false
        },
        {
            accessor: "contentId",
            show: false
        },
        {
            Header: t("DID_MAIN_CONTENT"),
            width: 130,
            accessor: 'thumbFileId',
            sortable: false,
            Cell: (props) => <div><ContentThumbnail id={props.original.thumbFileId} width={100} height={56} textAlign={true}/></div>
        },
        {
            Header: t("TEXT_CONTENT_NAME_P"),
            accessor: "contentName",
            width: 479,
            Cell: (props) => <span title={props.original.contentName}>{props.original.contentName}</span>
        },
        {
            Header: t("COM_TV_SID_DETAILS"),
            width: 190,
            sortable: false,
            Cell: props => <div><span>{props.original.mediaType}</span><Size value={", "} size={props.original.totalSize} /></div>
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            accessor: "deviceType",
            width: 260,
            sortable: false,
            Cell: props => <SupportedDeviceIcon
                deviceType={props.original.deviceType}
                deviceTypeVersion={props.original.deviceTypeVersion}
                mediaType={props.original.mediaType}
                fileType={props.original.mainFileExtension}
            />
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "lastModifiedDate",
            width: 180,
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId"
        }
    ], [items]);


    useEffect(() => {
        setStyle({height: window.innerHeight - 198});
        setAuthority(getAuthority('CONTENT_PLAYLIST_RULESET'));
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched){
            fetchData();
        }
    }, [filter]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'content_tr_group');
    return (
        <div style={{width: '100%',display: props.currContent === 'CONTENT_RECYCLE_BIN' ? 'block':'none'}}>
            <div className="contents_buttonWrap overflow_hidden">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_RESTORE"} name={t("BUTTON_RESTORE_P")} disable={selectedCnt < 1}
                                 onClick={()=>restoreContentPopup("restoreContentPopup")}
                                 authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                    <WhiteButton id={"CONTENT_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1}
                                 onClick={()=>deleteContentPopup("deleteContentPopup")}
                                 authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                    <WhiteButton id={"CONTENT_DELETE_ALL"} name={t("BUTTON_EMPTY_RECYCLE_BIN_P")}
                                 onClick={()=>deleteAllContentPopup("deleteAllContentPopup")}
                                 authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="contentSearch" placeholder={t("TEXT_CONTENT_NAME_P")} onClickSearch={onKeywordChange} />
                </div>
            </div>
            <div className='content_list_view'>
                <MagicInfoTable
                    data={items}
                    page={page}
                    pages={pages}
                    loading={loading}
                    minRows={0}
                    sorted={sorted}
                    onSortedChange={onSortedChange}
                    getTrGroupProps={getTrGroupPropsType2}
                    showPagination={false}
                    columns={columns}
                    className="-striped -highlight"
                    style={style}
                    manual
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    usePagination
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        defaultPageSize: pageSize,
                        pageSizeOptions: contentConstants.PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: props.divide
                    }}
                />
            </div>
        </div>
    );
};

export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadTrashBasketPopup: loadTrashBasket => dispatch(contentAction.loadTrashBasketPopup(loadTrashBasket)),
        reloadGroup: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
        updateSubMenuCounter: (submenuId) => dispatch(menuAction.updateSubMenuCounter(submenuId)),
    })
)(memo(ContentRecycleBin));
