import React, {useEffect, useMemo, useState} from 'react'
import {settingService} from "../../services";
import WhiteButton from "../../components/button/WhiteButton";
import {Trans, useTranslation} from "react-i18next";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import Checkbox from "../../components/checkbox/Checkbox";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useDispatch} from "react-redux";
import {popupAction} from '../../actions';
import {commonConstants, SETTING_PAGE_SIZE_OPTIONS, settingConstants} from "../../constants";
import {toastr} from 'helper/toastrIntercept';
import Pagination from "../../components/table/Pagination";
import {getErrorMessage} from "../../helper/responseHandler";
import {snakeCase} from "lodash";
import {useTrGroupProps} from "../../helper/tables";

const EdgeServers = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('setting', SETTING_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [{id: 'hostName', desc: false}],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
    });

    const [visibleData, setVisibleData] = useState({
        items:[],
        clickState:[]
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(()=>{
        const handleResize = () => {
            setStyle({height: window.innerHeight - 203});
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(()=>{
        fetchData();
    }, [filter]);

    const fetchMasterEdgeServers = () => {
        const {page, pageSize, sorted: [{id, desc}]} = filter;
        settingService.fetchMasterEdgeServers({
            startIndex: page * pageSize + 1,
            pageSize,
            sortColumn : snakeCase(id),
            sortOrder : desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages:Math.ceil(res.totalCount / pageSize)});
            let clickState = [];
            res.items.forEach(server=>{
                clickState[server.uuid] = true;
            });
            setVisibleData({...visibleData, items: res.items, clickState: clickState});
        }).catch(error => setData([]))

    };

    const fetchData = async (state, filter) => {
        setData({
            ...data,
            loading: true
        });
        await fetchMasterEdgeServers(data);
    };

    const fetchSlaveEdgeServers = (connectedMasterUuid) => {
        console.log("fetchSlaveEdgeServers " + connectedMasterUuid);
        settingService.fetchSlaveEdgeServers(connectedMasterUuid)
            .then(res => {
                updateVisibleData('insert', connectedMasterUuid, res.items);
            }).catch(
        );
    };

    const renderSslCondition = (edgeInfo) => {
        if(edgeInfo.httpPort !== 0 && edgeInfo.httpsPort !== 0){
            return "both";
        }
        if(edgeInfo.httpPort !== 0 && edgeInfo.httpsPort === 0){
            return "http";
        }
        if(edgeInfo.httpPort === 0 && edgeInfo.httpsPort !== 0){
            return "https";
        }
        return "none";
    };

    const onEditEdge = (event) =>{
        let selectedEdge;
        let selectedUuid;
        let edgeInfoArray = {data:items};
        let checkCount = 0;
        checkBoxRefs.current.forEach(checkbox => {
            if(checkbox.checked){
                checkCount++;
                selectedUuid = checkbox.getAttribute('data-name');
            }
        });
        if(checkCount !== 1){
            toastr.error(t('MESSAGE_DEVICE_SELECT_ONE_CHECKBOX_P'));
            return;
        }else{
            selectedEdge = visibleData.items.find(server=>server.uuid === selectedUuid);
        }
        if(selectedEdge.status === 'NEED_LICENSE'){
            toastr.error(t('MIS_SID_CAFEB_LICENSE_REQUIRED_DOT'));
            return;
        }

        editEdgeConfigPopup(
            event,
            selectedEdge,
            edgeInfoArray.data.findIndex(info=>info.uuid===selectedEdge.uuid));
    };

    const deleteEdgeServers = (edgeToDelete) => {

        let promiseAll = [];
        let count = 0;

        edgeToDelete.forEach(uuid=>{
            const promise = settingService.deleteEdge(uuid).then(res => {
                count += res.items;
            });
            promiseAll.push(promise);
        });

        Promise.all(promiseAll).then(() => {
            if(count === promiseAll.length)
                toastr.success(t('TEXT_SUCCESS_P'));
            else
                toastr.error(t('TEXT_FAIL_P'));

            fetchData();
        }).catch((err) => {
            toastr.error(getErrorMessage(err));
        }).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
        });
    };

    const onLinkClicked = async (event, edge) => {

        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        if (edge.status === 'STOPPED')
            return false;

        let token="";
        await settingService.fetchEdgeDashboardInfo()
            .then(res=>{
                token = res.items;
            })
            .catch(err=>{
                toastr.error(t('MESSAGE_SCHEDULE_PROGRAM_ERROR_P'));
            });

        let sslCondition = renderSslCondition(edge);
        let sslUrl = 'https://' + edge.ipAddress + ':' + edge.httpsPort + `/link/${token}`;
        let nonSslUrl = 'http://'+edge.ipAddress+':'+edge.httpPort+`/link/${token}`;
        switch(sslCondition){
            case 'both':
                const isSsl = window.location.href.split(':').includes('https');
                if (isSsl) {
                    await window.open(sslUrl);
                }else{
                    await window.open(nonSslUrl);
                }
                break;
            case 'https':
                await window.open(sslUrl);
                break;
            case 'http':
            case 'none':
            default:
                await window.open(nonSslUrl);
                break;
        }
    };

    const showSlaves = async (hostName, uuid) => {
        let dataToProcess = data.items.filter(server=>{return server.connectedMasterUuid===uuid && server.hostName!==hostName});
        if(visibleData.clickState[uuid]){
            await fetchSlaveEdgeServers(uuid);
            updateVisibleData('insert', uuid, dataToProcess);
        }else{
            updateVisibleData('delete', uuid, dataToProcess);
        }
    };

    const setItemsForRefresh = () => {
        fetchMasterEdgeServers();
    };

    const updateVisibleData = (type, masterUuid, dataToUpdate) => {
        switch(type){
            case 'insert':
                let indexToInsert = visibleData.items.findIndex(server => server.uuid === masterUuid) + 1;
                if(indexToInsert !== -1){
                    dataToUpdate.forEach(server => {
                        visibleData.items.splice(indexToInsert, 0, server);
                        indexToInsert++;
                    });
                    visibleData.clickState[masterUuid] = false;
                    setVisibleData({...visibleData, items: visibleData.items, clickState: visibleData.clickState});
                }
                break;
            case 'delete':
                dataToUpdate.forEach(server=>{
                    let indexToDelete = visibleData.items.findIndex(e => e.uuid===server.uuid);
                    if(indexToDelete !== -1) {
                        visibleData.items.splice(indexToDelete, 1);
                        setVisibleData({...visibleData, items: visibleData.items});
                    }
                });
                visibleData.clickState[masterUuid]=true;
                setVisibleData({...visibleData,clickState: visibleData.clickState});
                break;
             default:
                break;
        }
    };

    const editEdgeConfigPopup = (event, edgeInfo, index) => {

        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        dispatch(popupAction.addPopup({
            type: settingConstants.EDIT_EDGE_POPUP,
            id: settingConstants.EDIT_EDGE_POPUP,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.EDIT_EDGE_POPUP)),
            edgeInfo: edgeInfo,
            setItemsForRefresh: ()=>setItemsForRefresh(),
            index: index
        }));
    };

    const deleteEdgeServerPopup = () => {
        let checkCount = 0;
        let selectedUuidList = [];

        checkBoxRefs.current.forEach(checkbox => {
            if(checkbox.checked){
                checkCount++;
                selectedUuidList = [...selectedUuidList, checkbox.getAttribute('data-name')];
            }
        });

        if(checkCount < 1){
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'))
            return;
        }

        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("ALERT_WANT_DELETE"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => deleteEdgeServers(selectedUuidList),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));
    };

    const getEdgeStatus = (edgeServerInfo) => {
        switch(edgeServerInfo.status){
            case 'NEED_REGISTRATION':
                return t('MIS_SID_NEED_REGISTRATION');
            case 'REGISTRATION_COMPLETE':
                return t('MIS_SID_CAFEB_REGISTRATION_COMPLETE');
            case 'NEED_LICENSE':
                return t('MIS_SID_CAFEB_LICENSE_REQUIRED');
            case 'LICENSE_EXPIRED':
                return t('MIS_SID_CAFEB_LICENSE_EXPIRED');
            case 'NEED_TO_ADD_MORE_CORES':
                return t('MIS_SID_CAFEB_INVALID_LICENSE');
            case 'STORAGE_ALERT':
                return t('MIS_SID_STORAGE_ALERT');
            case 'STOPPED':
                return t('COM_MRMS_CONFIG_SERVICE_STATUS_STOPPED');
            default:
                return t('MIS_SID_EDGE_SERVER');
        }
    };

    const renderAlertButton = (edgeServerInfo) =>{
        if(edgeServerInfo.status !== 'REGISTRATION_COMPLETE' ) {
                return <span className="edge_alert"/>
        }
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'Edge_All'}
                        classname="table"
                        name="check"
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={'Edge_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        propertyName={row.original.uuid}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 40,
            sortable: false,
            resizable: false,
            style: {"testAlign":"center"}
        },
        {
            Header: t("TABLE_SERVER_NAME_P"),
            Cell: (row) => {
                if(row.original.status === 'NEED_LICENSE' || row.original.status === 'NEED_REGISTRATION')
                    return(
                        <div>
                            {row.original.status === 'NEED_REGISTRATION'
                                ? <span className="data_name" onClick={(event) => editEdgeConfigPopup(event, row.original, row.index) }>{row.original.hostName}</span>
                                : <span className="data_name">{row.original.hostName}</span>}
                        </div>
                    )
                return (
                    <div>
                        {row.original.master && row.original.groupCount > 1
                        && <button
                                className={visibleData.clickState[row.original.uuid] ? "show_slave_button" : "hide_slave_button"}
                                onClick={()=>showSlaves(row.original.hostName, row.original.uuid)}
                            />}
                        <span className={row.original.master?"edge_name":"edge_slave_name"} onClick={(event) => editEdgeConfigPopup(event, row.original, row.index) }>{row.original.hostName}</span>
                    </div>
                )
            },
            accessor: "hostName",
            width: 400
        },
        {
            Header: t("TABLE_IP_ADDR_P"),
            accessor: 'ipAddress',
            width: 200
        },
        {
            Header: t("TABLE_WEB_PORT_P"),
            Cell: (row) => {
                if ( row.original.connectedMasterUuid === null )
                    return "-";
                return (renderSslCondition(row.original)==="both"?row.original.httpPort+'/'+row.original.httpsPort:renderSslCondition(row.original)==="http"?row.original.httpPort:row.original.httpsPort)
            },
            sortable: false,
            width: 150
        },
        {
            id: "ftpPort",
            Header: t("COM_SETUP_NEW_STRING26_P"),
            Cell: (row) => {
                if ( row.original.connectedMasterUuid === null )
                    return "-";
                return (renderSslCondition(row.original)==="both"?21+'/'+990:renderSslCondition(row.original)==="http"?21:990)
            },
            sortable: false,
            width: 150
        },
        {
            id: "serverType",
            Header: t("TABLE_APPROVAL_TYPE_P"),
            accessor: "master",
            Cell: (row) => {
                if ( row.original.connectedMasterUuid === null )
                    return "-";
                if(row.original.master){
                    return (<span> {t('TEXT_MAIN_P')} </span>)
                }
                return (<span> {t('MIS_SID_SERVER_CASEP_SECONDARY')} </span>);
            },
            sortable: false,
            width: 150
        },
        {
            Header: t("TEXT_STATUS_P"),
            Cell: (row) => {
              return(<span>{renderAlertButton(row.original)}{getEdgeStatus(row.original)}</span>)
            },
            sortable: false,
            width: 250
        },
        {
            id: "link",
            Header: t("MIS_SID_LINK"),
            Cell: (row) => {
                return (
                    <WhiteButton name={t('COM_TV_SID_DASHBOARD')} width={150} disable={row.original.status === "STOPPED"} onClick={(event)=>onLinkClicked(event, row.original)}/>
                )
            },
            sortable: false,
            minWidth: 210
        }
    ], [visibleData]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow);

    return (
        <div style={{width: '100%',  display: props.currContent === 'EDGE_SERVER' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"EDGE_EDIT"} name={t("TEXT_EDIT_P")} disable={selectedCnt !== 1} onClick={(event) => onEditEdge(event)} />
                    <WhiteButton id={"EDGE_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={()=>deleteEdgeServerPopup()}/>
                </div>
            </div>

            <div className={"device_list_view_wrap"} style={{width: '100%'}}>
                <MagicInfoTable
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    data={visibleData.items}
                    loading={loading}
                    minRows={0}
                    sorted={sorted}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns={columns}
                    style={style}
                    usePagination={true}
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        pageSize: pageSize,
                        pageSizeOptions: SETTING_PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: "setting"
                    }}
                />
            </div>
        </div>
    )
};

export default EdgeServers;