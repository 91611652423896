import {useMISOpt} from "../../components/misopt";
import WhiteButton from "../../components/button/WhiteButton";
import ReactTable from "react-table";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import {settingService} from "../../services";
import Checkbox from "../../components/checkbox/Checkbox";
import Moment from "moment";
import {useTrGroupProps} from "../../helper/tables";
import {popupAction} from "../../actions";
import {commonConstants, settingConstants} from "../../constants";
import {toastr} from 'helper/toastrIntercept';

const E2ELicenses = (props) =>{
    const {misopt} = useMISOpt();
    const {user: {dateFormat}} = misopt;
    const convertedDateFormat = dateFormat.toUpperCase();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [filter, setFilter] = useFilter({
        page: 0,
        pageSize: 5,
        reload: false
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;

    const [style, setStyle] = useState({
        height: '500px'
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const {page, pageSize} = filter;
        setData({...data, loading: true});
        settingService.fetchE2ELicenses(data)
            .then(res => {
                    setData({
                        ...data,
                        loading: false,
                        items: res.items,
                        totalCount: res.totalCount,
                        pages: Math.ceil(res.totalCount / pageSize)
                    });
                }
            )
    }

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const renderCheckAllCell = () => {
        return (
            <Checkbox
                id={'E2ELicense_all'}
                classname={"table"}
                name={"check"}
                ref={checkAll}
                onChange={toggleSelectAll}
            />
        )
    };

    const renderCheckCell = (row) => {
        return (
            <Checkbox id={items[row.index].licenseKey}
                      index={row.index}
                      classname={"table"}
                      name={"check"}
                      ref={setCheckBoxRefs}
                      onChange={toggleRow}
            />
        );
    };

    const getCheckedId = () => {
        return selected.current.map(s => items[s].licenseKey);
    }

    const getCheckedDeviceId = () => {
        if(selected.current.length==0)
            return ''
        else
            return selected.current.map(s => items[s].deviceId);
    }

    const returnE2ELicensePopup = () => {
        const licenseKey = getCheckedId();
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('COM_MIS_BUTTON_RETURN_LICENSE_P'),
            useMessageLine : false,
            message: (
                <>
                    {t('COM_MIS_TEXT_DO_YOU_WANT_TO_RETURN_YOUR_LICENSE_P')}
                    <br/>
                    {t('MIS_TEXT_AFTER_RETURNING_THE_LICENSE_KEY_CONNECTED_DEVICES_ARE_DELETED_P').replace("<<A>>", getCheckedId())}
                </>
            ),
            height: 150,
            licKey: licenseKey,
            onClickYes: handleReturnE2ELicense,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));

    };

    const handleReturnE2ELicense= () => {
        const licKey = getCheckedId();
        settingService.returnE2ELicense(licKey).then(
            res => {
                toastr.success(t("COM_MIS_TEXT_LICENSE_HAS_BEEN_DELETED_P"))
            }
        ).catch(
            res => toastr.error(res)
        ).finally(()=> {
                fetchData();
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
            }
        )
    }

    const e2eLicenseHistoryPopup = () => {
        const deviceId = getCheckedDeviceId();

        dispatch(popupAction.addPopup({
            type: settingConstants.E2E_LICENSE_HISTORY_POPUP,
            id: settingConstants.E2E_LICENSE_HISTORY_POPUP,
            title: t('MIS_BUTTON_LICENSE_HISTORY_P'),
            deviceId:deviceId,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.E2E_LICENSE_HISTORY_POPUP))
        }));

    };

    const e2eLicenseSwapPopup = () => {
        const licKey = getCheckedId();
        const oldDeviceId = getCheckedDeviceId();
        dispatch(popupAction.addPopup({
            type: settingConstants.E2E_LICENSE_SWAP_POPUP,
            id: settingConstants.E2E_LICENSE_SWAP_POPUP,
            title: 'Swap license',
            licKey : licKey,
            oldDeviceId : oldDeviceId,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.E2E_LICENSE_SWAP_POPUP))
        }));
    }

    const columns = useMemo(() => [
        {
            accessor: "licenseKey",
            show: false
        },
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width: 46,
            sortable: false,
            resizable: false
        },
        {
            Header: t("COM_TEXT_LICENSE_KEY_P"),
            accessor: "licenseKey",
            width: 250,
            sortable: false
        },

        {
            Header: t("TABLE_MAC_ADDR_P"),
            accessor: 'deviceId',
            width: 250,
            sortable: false
        },
        {
            Header: t("TABLE_DEVICE_NAME_P"),
            accessor: "deviceName",
            width: 150,
            sortable: false
        },
        {
            Header: t("COM_TABLE_START_DATE_P"),
            accessor: "startDate",
            width: 150,
            Cell: ({value}) => <span>{Moment(value).format(convertedDateFormat)}</span>,
            sortable: false
        },
        {
            Header: t("COM_TABLE_END_DATE_P"),
            accessor: "endDate",
            width: 170,
            Cell: ({value}) => <span>{Moment(value).format(convertedDateFormat)}</span>,
            sortable: false
        },
        {
            Header: t("DID_ADMIN_DEVICE_REGDATE"),
            accessor: "regDate",
            Cell: ({value}) => <span>{Moment(value).format(convertedDateFormat)}</span>,
            width: 170,
            sortable: false
        },
    ],[items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"RETURN_LICENSE"} name={t("COM_MIS_BUTTON_RETURN_LICENSE_P")} disable={selectedCnt !== 1} onClick ={()=>returnE2ELicensePopup()}/>
                    <WhiteButton id={"LICENSE_HISTORY"} name={t("MIS_BUTTON_LICENSE_HISTORY_P")} onClick ={()=>e2eLicenseHistoryPopup()}/>
                    <WhiteButton id={"SWAP_LICENSE"} name={"Swap license"} disable={selectedCnt !== 1} onClick ={()=>e2eLicenseSwapPopup()}/>
                </div>
            </div>

            <div className='content_list_view'>

                <ReactTable
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    data={items}
                    loading={loading}
                    style={style}
                    minRows={0}
                    columns={columns}
                    defaultPageSize={30}
                    getTrGroupProps={getTrGroupPropsType2}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    columns={columns}
                />
            </div>
        </div>

    )
}

export default E2ELicenses