import React, {useState} from 'react';
import MISDialog from "../MISDialog";
import {useTranslation} from "react-i18next";
import './ResetPopup.css';
import {userService} from "../../../services";
import {userActions} from "../../../actions";
import {saveToken} from '../../../helper/fetchIntercept.js';
import {history} from "../../../helper";
import {useDispatch} from "react-redux";
import APPLE_STORE from "../../../images/icon/apple store.png";
import GOOGLE_PLAY from "../../../images/icon/google play.png";
import "./OtpSetupPopup.css";
import Moment from "moment";
import {isNil} from 'lodash';
import {authServerUtil} from 'helper/authserver/authServerUtil';


const OtpSetupPopup = ({mfa, closePopup}, props) => {


    const {t} = useTranslation();
    const dispatch = useDispatch();

    const QRCode = require('qrcode.react');
    const useAuthServer = authServerUtil.isAuthServerUsed();

    const [error1, setError1] = useState('');
    const [error2, setError2] = useState('');

    const dialogProps = {
        title: t("MIS_SID_CAJUL_REGISTER_OTP_TWO_STEP_VERIFI"),
        closeOnEscape: true,
        modal: true,
        hasCloseIcon: false
    };

    const [otp, setOtp] = useState("");
    const [step, setStep] = useState(1);

    const [dialogWidth, setDialogWidth] = useState(560);
    const [dialogHeight, setDialogHeight] = useState(300);


    const done = () => {

        closePopup();

        history.push('/');
    }

    const nextStep = () => {
        if (step === 1) {

            setStep(2);

        } else if (step === 2) {


            const authData = {
                username: mfa.userId,
                password: mfa.password,
                code:mfa.code,
                grantType:mfa.grantType,
                totp: otp,
            };

            userService.setupMfaAuth(authData)
                .then(res => {

                    initErrorMsg();

                    setStep(3);

                    saveToken(res);
                    dispatch(userActions.success(res));

                    setDialogHeight(80);

                }).catch(
                e => {
                    initErrorMsg();

                    if (isNil(e.errorCode)) {
                        setError1(t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN"));
                        return;
                    }

                    switch(e.errorCode){
                        case "401013":

                            // otp invalid
                            setError1(t("MIS_SID_CAJUL_WRONG_CODE_PLZ_AGAIN"));

                            break;

                        case "401014":

                            // otp warning
                            setError1(t("MIS_SID_CAJUL_WRONG_CODE_PLZ_CHECK_MATCH_AND_TRY_AGAIN"));

                            const currentTime = ( !isNil(e.errorDetails) &&  !isNil(e.errorDetails.timestamp) ) ? Moment(e.errorDetails.timestamp).format('HH:mm') : "";
                            const timeZone = ( !isNil(e.errorDetails) &&  !isNil(e.errorDetails.timeZone) ) ? e.errorDetails.timeZone : "";
                            setError2(t("MIS_SID_MIX_CAJUL_CURRNT_TIME_GMT").replace("<<A>>", timeZone).replace("<<B>>", currentTime));
                            break;

                        case "404001" :

                            setError1(t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN"));
                            break;

                        default :

                            setError1(t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN"));
                            break;
                    }

                }
            )
        }
    }

    const onChangeOtp = e => {
        setOtp(e.target.value);
    };

    const closeOtpSetupPopup = () => {
        closePopup();
    }


    const initErrorMsg = () => {
        setError1('');
        setError2('');

    }

    const getButtons = () => {
        if(3 === step){
            return [
                {id: 'DONE_POPUP', title: t("BUTTON_OK_P"), onClick: () => done()}
            ];
        }

        if(useAuthServer){
            return [
                {id: 'NEXT_STEP', title: t("COM_BUTTON_NEXT"), onClick: () => nextStep()}
            ];
        }

        return [
            {id: 'NEXT_STEP', title: t("COM_BUTTON_NEXT"), onClick: () => nextStep()},
            {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: () => closeOtpSetupPopup()}
        ];


    }


    return (
        <>
            {
                <MISDialog
                    dialog={dialogProps}
                    width={dialogWidth}
                    height={dialogHeight}
                    buttons={{
                        rightButtons: getButtons()
                    }}>
                    <div>
                        {
                            step === 1 &&
                            <div style={{width: 532}}>
                                <div style={{height: 54}}>
                                        {t('MIS_SID_CAJUL_REGISTER_OTP_TWO_STEP_VERIFI_TO_PROECT_UR_LOGIN')}

                                </div>
                                <div style={{height: 47}}>
                                        {t('MIS_SID_MIX_CAJUL_INSTALL_AUTH_APP_GPS_AAS').replace("<<A>>", "1. ")}<br/>
                                        {t('MIS_SID_CAJUL_AFTER_INSTALL_CLICK_NEXT_BTN')}
                                </div>
                                <div style={{height: 55}}>
                                    <div style={{textAlign: "center"}}>
                                        <img style={{marginRight: 8}} src={GOOGLE_PLAY}/>
                                        <img src={APPLE_STORE}/>
                                    </div>
                                </div>
                                <div>
                                        {t('MIS_SID_CAJUL_HERE_POPULAR_AUTH_APP_OPTION')}
                                </div>
                                <div>
                                        - Google OTP <br/>
                                        - Microsoft Authenticator <br/>
                                        - Authy <br/>
                                </div>
                            </div>
                        }
                        {
                            step === 2 &&
                            <div>
                                <div style={{height: 55}}>
                                        {t('MIS_SID_MIX_CAJUL_OPEN_OTP_AUTH_APP_SELECT_REGI_BTN').replace("<<A>>", "2. ")}
                                </div>
                                <div style={{height: 35}}>
                                        {t('MIS_SID_MIX_CAJUL_SCAN_QR_IF_UNABLE_MANUALLY_BELOW').replace("<<A>>", "3. ")}
                                </div>
                                <div style={{height: 113}}>
                                    <div style={{textAlign: "center"}}>
                                        <div className="secret_key_area">
                                            <div className="qrcode">
                                                <QRCode
                                                    value={mfa.otpAuthInfo.qrCode}
                                                    size={88}
                                                    includeMargin={false}
                                                />
                                            </div>
                                            <div className="separate_line">

                                            </div>
                                            <div className="key">
                                                <h2>
                                                    {mfa.otpAuthInfo.secretKey}
                                                </h2>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={{height: 25}}>
                                        {t('MIS_SID_MIX_CAJUL_ENTER_SIX_DIGIT_DEPLAYED_IN_APP').replace("<<A>>", "4. ")}
                                </div>
                                <div>

                                        <div style={{position: 'relative'}}>
                                            <input
                                                value={otp}
                                                type="text"
                                                data-name="otp"
                                                maxLength="6"
                                                placeholder={t('MIS_SID_CAJUL_ENTER_CODE')}
                                                style={{width: '197px'}}
                                                onChange={onChangeOtp}
                                            />

                                        </div>

                                </div>
                                <div>
                                        <span className="error_txt_otp">{error1}</span><br/>
                                        <span className="error_txt_otp">{error2}</span>

                                </div>
                            </div>
                        }
                        {
                            step === 3 &&
                            <div>
                                <div> {t('MIS_SID_MIX_CAJUL_OTP_REGISTER_COMPLETE_USE_SIGN_IN_SVR').replace("<<A>>", "MagicINFO Server")}</div>
                            </div>
                        }

                    </div>
                </MISDialog>
            }
        </>
    )
}
export default OtpSetupPopup;
