import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isNil, set} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import Checkbox from '../../../checkbox/Checkbox';
import NumberInput from '../../../input/NumberInput';
import TextInput from '../../../input/TextInput';
import {deviceValidator} from '../../../../helper';
import {deviceService} from "../../../../services";
import './EditConnectionPopup.css';
import {Loading} from '../../../loading/Loading';
import {DeviceFadeInTransition, DeviceFadeOutTransition} from '../../../device/DeviceTransition';

const EditConnectionPopup = ({device, onScan, onClose}) => {
    const {t} = useTranslation();
    const initialScanInfo = {
        childNumber: 0,
        cabinetIPAddress : (device.ipAddress && device.ipAddress.substr(0, device.ipAddress.lastIndexOf('.')) + '.0') || '0.0.0.0',
        autoSetId : true
    };

    const createScanInfos = (setting) => {
        return (
            setting && new Array(installedCabinetGroups).fill().map((e, i) => {
                const res = setting.filter(t => (t.index == i+1));
                return res.length >= 1 ? {...initialScanInfo, ...res[0]} : {index:i+1, ...initialScanInfo};
            })) || 
                new Array(installedCabinetGroups).fill().map((e, i) => ({index : i+1, ...initialScanInfo})
        );
    };

    const [installedCabinetGroups, setInstalledCabinetGroups] = useState(device.supportUhd ? 4 : 1);
    const [scanInfos, setScanInfos] = useState(createScanInfos(device.cabinetGroupSetting));
    const [errors, setErrors] = useState(new Array(32).fill().map(e => ({})));
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        deviceService.fetchDeviceSetupInfo([device.deviceId]).then(res => {
            setLoading(false);
            if(res.items){
                const {successList = [{}]} = res.items;
                setScanInfos(createScanInfos(successList[0].cabinetGroupSetting));            
            }            
        }).catch(() => {
            toastr.error(t('MESSAGE_COMMON_ERROR_P'));
        });
    }, []);

    useEffect(() => {
        if(scanInfos.length < installedCabinetGroups){
            setScanInfos(scanInfos.concat(new Array(installedCabinetGroups - scanInfos.length)).fill().map((e, i) => {
                if(i < scanInfos.length){
                    return scanInfos[i];
                } else {
                    return {...initialScanInfo, index: i+1};
                }
            }));
        } else if(scanInfos.length > installedCabinetGroups){
            setScanInfos(scanInfos.slice(0, installedCabinetGroups));
        } 
    }, [installedCabinetGroups]);

    const onClickScan = () => {
        if(scanInfos.some(info => isNil(info.childNumber))) {
            toastr.error(t('COM_SID_SIGNAGE_ENTER_NUMBER_OF_CABINETS'));
            return;
        }
        onScan(scanInfos.map(info => ({
            ...info,
            autoSetId: info.autoSetId ? 1 : 0,
            deviceType: device.deviceType
        })));
    };

    const onChange = (e, value) => {
        const dataName = e.target.getAttribute('data-name');
        const newScanInfos = [...scanInfos];

        if(e.target.type !== 'checkbox') {
            const dataValue = isNil(value) ? e.target.value : value;
            const newErrors = [...errors];
            const namePath = dataName.split('.');
            const result = deviceValidator(namePath[namePath.length - 1], dataValue);

            set(newScanInfos, dataName, dataValue);
            if(result) {
                if(result.isValid) {
                    set(newErrors, dataName, undefined);
                } else {
                    set(newErrors, dataName, t(result.messageId));
                }
                setErrors(newErrors);
            }
        } else {
            set(newScanInfos, dataName, e.target.checked);
        }
        
        setScanInfos(newScanInfos);
    };

    return (
        <MISDialog 
            dialog={{
                title: t('COM_LFD_EDIT_CONNECTION'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 485,
                height: 290,
                onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_TV_SID_SCAN',
                        title: t('COM_TV_SID_SCAN'),
                        onClick: onClickScan,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}>
            <div className="edit_connection_wrap detail_view mb15">
                <span style={{display: 'block', lineHeight: '18px'}}>
                    {t('MIS_SID_EDIT_THE_CABINET_CONNECTION_SETTINGS')}
                </span>
                <br/>
                <div style={{textAlign:'center', marginTop: 50, display:loading?'block':'none'}}>
                    <DeviceFadeOutTransition transition={!loading} classes={['device_loading_wrap']}>
                        <Loading/>
                    </DeviceFadeOutTransition>
                    <DeviceFadeInTransition transition={!loading}></DeviceFadeInTransition>
                </div>
                {
                    !loading && 
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            {
                                device.supportCabinetSetting &&
                                <>
                                    <tr>
                                        <th style={{fontWeight:'bold'}}>{t('MIS_SID_S_BOX_INFORMATION')}</th>
                                    </tr>
                                    <tr>
                                        <th>{t('COM_SID_IP_ADDRESS')}</th>
                                        <td>{device.ipAddress}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('MIS_SID_SERVER_CBMAR_INSTALLED_CABINET_GROUP')}</th>
                                        <td>
                                            <NumberInput width={100} value={installedCabinetGroups} min={1} max={32} onChange={(e, value) => setInstalledCabinetGroups(value)} forceInt />
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <th colspan="2" style={{height: 10}}></th>
                            </tr>
                            {
                                scanInfos.map((scanInfo, i) => (
                                    <>
                                        <tr>
                                            <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace("<<A>>", i + 1)}</th>
                                            <td className="background_bar"></td>
                                        </tr>
                                        <tr>
                                            <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                                            <td>
                                                <NumberInput width={100} propertyName={`[${i}].childNumber`} value={scanInfo.childNumber} min={0} max={255} onChange={onChange} forceInt />
                                                {
                                                    device.supportCabinetSetting &&
                                                    <Checkbox id={`autoSetID${i+1}`} name={t('MIS_SID_ID_AUTO_SET')} propertyName={`[${i}].autoSetId`} checked={scanInfo.autoSetId} onChange={onChange} />
                                                }
                                            </td>
                                        </tr>
                                        {
                                            device.supportCabinetSetting &&
                                            <tr>
                                                <th>{t('COM_SID_IP_ADDRESS')}</th>
                                                <td>
                                                    <TextInput width='40%' propertyName={`[${i}].cabinetIPAddress`} value={scanInfo.cabinetIPAddress} onChange={onChange} error={errors[i].cabinetIPAddress} />
                                                </td>
                                            </tr>
                                        }
                                    </>
                                ))
                            }
                        </tbody>
                    </table>
                }
            </div>
        </MISDialog>
    );
};

export default EditConnectionPopup;