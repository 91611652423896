import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {connect, useDispatch, useSelector,} from "react-redux";
import {useTranslation} from "react-i18next";
import './PlaylistManager.css';
import {menuAction, playlistAction, popupAction} from "../../actions";
import Select from "../../components/selectbox/Select";
import {contentService} from "../../services/content.service";
import {playlistService, userService} from "../../services";
import {changeSecondToHMSTime} from "../../containers/playlist/playlistUtil";
import Size from "../../components/utils/Size";
import Filter from "../../components/filter/Filter";
import SwitchListNImage from "../../components/switch/SwitchListNImage";
import SearchBar from "../../components/search/SearchBar";
import WhiteButton from "../../components/button/WhiteButton";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import PlaylistEffect from "./PlaylistEffect";
import CategoryButton from "../../components/category/CategoryButton";
import PlaylistEditor from "./PlaylistEditor";
import RandomInsertArea from "./RandomInsertArea";
import {commonConstants} from "../../constants/CommonConstants";
import DraggableItem from "./DraggableItem";
import ConfirmPopup from "../../components/popup/ConfirmPopup";
import {
    addCheckStatusUpdate,
    convertAddedContent,
    convertedPlaylistItemInfo, convertSyncItemToPlaylistItem,
    convertTagContent,
    deleteCheckStatusUpdate,
    offArrowClick,
    getContentsTypeByDeviceType, makeContentsFormatGeneralPlaylist, makeTagsFormatTagPlaylist,
    moveCheckStatusUpdate,
    useSideClick
} from "./playlistUtil";
import {toastr} from 'helper/toastrIntercept';
import SyncPlaylistEditor from "./sync/SyncPlaylistEditor";
import TagListView from "./TagListView";
import TagPlaylistEditor from "./TagPlaylistEditor";
import snakeCase from "lodash/snakeCase";
import {APLAYER, contentConstants, LPLAYER, playlistConstants, SPLAYER, SYNC_ITEM, TAG_ITEM, UPDATE, GENERAL, ADD, DELETE, RANDOM } from "../../constants";
import EmptyImage from '../../images/img/empty_img_3.png';
import {isEmpty, isUndefined} from "lodash";
import {Loading} from "../../components/loading/Loading";
import {getErrorMessage} from "../../helper/responseHandler";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import PlaylistMultiSelectButton from "../../components/button/PlaylistMultiSelectButton";
import flatMap from "lodash/flatMap";
import {updateCache} from "../../helper/cache/tableCache";
import {MagicInfoDndProvider} from "../../components/dnd/dndHooks";
import {isNil} from 'lodash';
import { isFlashSupported } from 'helper/device/isSupportFeature';

const PlaylistManager = ({mode, addPopup, closePopup, reloadGroup, removeTab, updatePlaylist, destroyPlaylist, destroyEmptyPlaylist, destroyLastMemoryPlaylist,currContent,cache}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {newPlaylist, editPlaylist} = useSelector(state => ({
        newPlaylist : state.playlist.newPlaylist,
        editPlaylist: state.playlist.editPlaylist
    }));
    
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const [effectData, setEffectData] = useState({
        showEffectIndex: 0,
        selectedMode: 'GENERAL',
        showEffectList: false,
    });

    const [showLoading, setShowLoading] = useState(false);
    const [showViewMoreLoading, setShowViewMoreLoading] = useState(false);
    const [filteredPlaylistDuration,setFilteredPlaylistDuration] = useState(0);
    const [filteredPlaylistLength,setFilteredPlaylistLength] = useState(0);
    const [tagPlaylistDuration,setTagPlaylistDuration] = useState(0);
    const [syncPlaylistDuration,setSyncPlaylistDuration] = useState(0);

    let playlistInfo = {};

    if (mode === 'NEW') {
        playlistInfo = newPlaylist;
    } else {
        playlistInfo = editPlaylist;
    }
    const [listHeight , setListHeight] = useState(window.innerHeight - 262);
    const [editorHeight , setEditorHeight] = useState(window.innerHeight - 262);
    const [generalHeight, setGeneralHeight] = useState(listHeight - 100);

    useEffect(() => {
        setListHeight(window.innerHeight - 262);
        setEditorHeight(window.innerHeight - 262);
        !showRandomInsertArea && setGeneralHeight(listHeight - 100);
        showRandomInsertArea && setGeneralHeight(listHeight - 100 - 262);
        if(playlistInfo.playlistType === '5'){
            let tagDuration = 0;
            playlistInfo.tags.forEach(element => {
                    tagDuration += (element.duration === -1 ? 0 : element.duration);
            });
            setTagPlaylistDuration(tagDuration);
            playlistInfo.playTime = tagPlaylistDuration;
        }

        if(playlistInfo.playlistType === '3'){
            let syncDuration = 0;
            if(playlistInfo.sync && playlistInfo.sync[0] && playlistInfo.sync[0].items)
            playlistInfo.sync[0].items.forEach(element => {
                if(element.time !== undefined)
                syncDuration +=  (Number(element.time.hour) * 60 * 60 + Number(element.time.min) * 60 + Number(element.time.sec));
            });
            setSyncPlaylistDuration(syncDuration);
        }
    });

    const [showRandomInsertArea, setShowRandomInsertArea] = useState(false);

    useEffect(() => {
        if (playlistInfo.contents) {
            if (playlistInfo.contents.filter((content) => content.randomCount !== 0).length > 0) {
                setShowRandomInsertArea(true)
                setGeneralHeight(listHeight - 262 - 100);
            } else {
                setShowRandomInsertArea(false)
            }
        } else {
            setShowRandomInsertArea(false)
        }
    }, [playlistInfo]);

    const outSide = useRef();

    useSideClick(outSide, (e)=>{
        if (!e.target.classList.contains("playlistImage_effect_button") && effectData.showEffectList) {
            setEffectData({...effectData, showEffectList: false});
            offArrowClick();
        }
    })

    const getContentsTypesByPlaylistType = () => {
        const {playlistType, deviceType, deviceTypeVersion} = playlistInfo;        
        switch (playlistType) {
            case playlistConstants.PLAYLISTTYPES[1].id: // VWL
                return ["IMAGE", "MOVIE"];
            case playlistConstants.PLAYLISTTYPES[2].id: // SYNC
                return ["LFD", "IMAGE", "MOVIE", "DLK"];
            case playlistConstants.PLAYLISTTYPES[3].id: // AD
                return ["LFD", "IMAGE", "MOVIE", "DLK", "SOUND"];
            case playlistConstants.PLAYLISTTYPES[4].id: // TAG
                return [];
            default:
                return getContentsTypeByDeviceType(deviceType, deviceTypeVersion)
        }
    };

        const initFilter = (cache.isLoaded && cache.filter) ? cache.filter : {
        isThumbnail: true,
        isUsedContent: true,
        startIndex: 1,
        pageSize: playlistInfo.view === 'IMAGE' ? 70 : 15,
        contentTypes: getContentsTypesByPlaylistType(),
        deviceType: playlistInfo.deviceType.toUpperCase() === 'IPLAYER' ? 'iPLAYER': playlistInfo.deviceType,
        deviceTypeVersion: playlistInfo.deviceTypeVersion,
        groupId: undefined,
        groupName : '',
        creatorIds: undefined,
        tagIds: undefined,
        fileSizes: undefined,
        expirationStatus: 'VALID',
        searchText: '',
        categoryIds: [],
        sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
        sortOrder: 'DESC',
        groupType: 'ALL',
        refresh: true,
        approvalStatus: 'APPROVED',
        playlistType: playlistInfo.playlistType
    };

    const [contentFilter, setContentFilter] = useState({});

    const [folderFilter, setFolderFilter] = useState({
        status: false,
        groupName: undefined,
    });

    const [contentsMode, setContentsMode] = useState('content');

    useEffect(()=> {
        setContentFilter(initFilter)
        setSelectedContentFilter({})
        if(initFilter.groupId !== undefined) {
            setFolderFilter({status: true, groupName: initFilter.groupName})
        }

    },[playlistInfo]);

    useEffect(()=>{
        if (playlistInfo.playlistType !== '5' && !isEmpty(contentFilter)) {
            if (isEmpty(playlistInfo.contentList) || (!isEmpty(playlistInfo.contentList) && contentFilter.refresh)) {
                setShowLoading(true);
                fetchContentsData();

            } else if (!isEmpty(playlistInfo.contentList) && !contentFilter.refresh) { // viewMore
                fetchContentsData()
            }
        } else {
            setShowLoading(false);
        }
    }, [contentFilter]);

    const filterContentsFolders = (groups) => {
        closePopup(commonConstants.COMMON_GROUP_POPUP);
        setContentFilter({...contentFilter, groupId: groups[0].groupId, groupType: 'GROUPED', refresh: true, startIndex:1,groupName: groups[0].groupName});
        setFolderFilter({status: true, groupName: groups[0].groupName})
    };
    const [selectedContentFilter, setSelectedContentFilter] = useState({});
    const onSaveContentFilter = (result) => {
        setSelectedContentFilter(result);
        if (result.contents[0] === 'ALL') {
            setContentFilter({...contentFilter, creatorIds: result.users, tagIds: result.mediaTags, contentTypes: getContentsTypesByPlaylistType(), fileSizes: result.fileSizes, refresh: true, startIndex:1})
        } else {
            setContentFilter({...contentFilter, creatorIds: result.users, tagIds: result.mediaTags, contentTypes: result.contents, fileSizes: result.fileSizes, refresh: true, startIndex:1})
        }
    };
    const onCategoryCheck = (checkedIds) => {
        setContentFilter({...contentFilter, categoryIds: checkedIds, refresh: true, startIndex:1});
    };
    const onKeywordSearch = (keyword) => {
        setContentFilter({...contentFilter, searchText: keyword, refresh: true, startIndex:1});
    };
    const [contentTotalCount, setContentTotalCount] = useState(0);

    const refreshContentList = (res) => {
        if (contentFilter.refresh) {
            playlistInfo.contentList = res.items;
            res.items.length === 0 && setShowLoading(false); 
        } else {
            playlistInfo.contentList = playlistInfo.contentList.concat(res.items);
            setShowViewMoreLoading(false);
        }
        setContentTotalCount(res.totalCount);
        dispatch(updatePlaylist(mode, playlistInfo));
    }

    const getSupportedContents = (contents,deviceTypeVersion,deviceType) => {
        if(isFlashSupported(deviceType,deviceTypeVersion)){
            return contents;
        }
        return contents.filter(content => content!=='FLASH');
    }

    const fetchContentsData = () => { 
        contentFilter.contentTypes = getSupportedContents(contentFilter.contentTypes,contentFilter.deviceTypeVersion,contentFilter.deviceType);
        contentsMode === 'content' ? contentService.fetchContentFilter({
            ...contentFilter,
            pageSize: playlistInfo.view === 'IMAGE' ? 70 : 15,
        }).then(res => {
            refreshContentList(res);
            updateCache(currContent, {filter: contentFilter}, 'CONTENT');
        }).catch((e)=> {
            if(e.errorCode !== 408900) {
                toastr.error(getErrorMessage(e));   
                setShowViewMoreLoading(false);
                setShowLoading(false)            
            }
        }) : playlistService.fetchPlaylistFilter({
            deviceType: playlistInfo.deviceType === 'IPLAYER' ? 'iPLAYER' : playlistInfo.deviceType,
            deviceTypeVersion: playlistInfo.deviceTypeVersion,
            playlistType: [6], // nested playlist,
            startIndex: contentFilter.startIndex,
            searchText: contentFilter.searchText,
            pageSize: playlistInfo.view === 'IMAGE' ? 70 : 15,
        }).then(res => {
            refreshContentList(res);
        }).catch((e)=> {
            if(e.errorCode !== 408900) {
                toastr.error(getErrorMessage(e));              
            }
            setShowViewMoreLoading(false);
            setShowLoading(false)
        })
    };

    const updateShowRandomInsertArea = () => {
        if (!showRandomInsertArea) {
            setGeneralHeight(listHeight - 262 - 100);
        } else {
            setGeneralHeight(listHeight - 100);
        }
        setShowRandomInsertArea(!showRandomInsertArea);
    };

    const resetRandomInsertArea = () => {
        let generalCheckedItems = checkList.filter(({item})=> item.randomCount === 0);
        setCheckList(generalCheckedItems);
        playlistInfo.contents = playlistInfo.contents.filter((content) => content.randomCount === 0);
        dispatch(updatePlaylist(mode, playlistInfo));
        setShowConfirmPopup(false);
        setShowRandomInsertArea(false);
    };
    const [checkList , setCheckList] = useState([]);
    const [tagCheckList , setTagCheckList] = useState([]);

    const updateSwitchListNImage = (viewType) => {
        if (playlistInfo.view !== viewType) {
            playlistInfo.view = viewType;
            //DF201020-00892: Commented following 2 lines as no need to fetch more content when toggling list/image view.
            //setShowLoading(true);
            //fetchContentsData()
            dispatch(updatePlaylist(mode, playlistInfo));
        }
    };

    const onSettingPopup = (item, index) => {
        addPopup({
            type: commonConstants.PLAYLIST_SETTING,
            id: 'PLAYLIST_SETTING',
            item: item,
            index: index,
            playlistType: playlistInfo.playlistType,
            deviceType: playlistInfo.deviceType,
            onSave: (playlistItem, index, itemMode) => {
                updatePlaylistItem(playlistItem, index, itemMode);
                closePopup('PLAYLIST_SETTING');
            },
            onClose: ()=> closePopup('PLAYLIST_SETTING')
        })
    };

    const savePlaylist = (playlistItem, saveMode) => {
        const convertedPlaylistItem = convertedPlaylistItemInfo(playlistItem);

        if (playlistInfo.playlistType === '5') {
            convertedPlaylistItem.tags = makeTagsFormatTagPlaylist(playlistInfo.tags);
        } else if (playlistInfo.playlistType === '3') {
            convertedPlaylistItem.contents = convertSyncItemToPlaylistItem(playlistInfo.sync);
        } else {
            convertedPlaylistItem.contents =  makeContentsFormatGeneralPlaylist(playlistInfo);
        }

        if (mode === 'NEW') {
            playlistService.addPlaylist(convertedPlaylistItem)
                .then(res => {
                    toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"))
                    closePopup(commonConstants.SAVE_PLAYLIST);
                    reloadGroup("MY_PLAYLIST", 0);
                    closeTab();
                }).catch(err => {
                    openSaveErrorPopup(err);
            });
        } else {
            const copyItem = {...playlistInfo};
            delete copyItem['contentList'];
            const editItem = {...copyItem, ...convertedPlaylistItem}
            saveMode === 'SAVE_AS' ?
            playlistService.saveAsPlaylist(editItem)
                .then(res => {
                    toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"))
                    closePopup(commonConstants.SAVE_PLAYLIST)
                    closeTab();
                }).catch(err => {
                    openSaveErrorPopup(err);
            }) : playlistService.editPlaylist(editItem)
                .then(res => {
                    toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"))
                    closePopup(commonConstants.SAVE_PLAYLIST)
                    closeTab();
                }).catch(err => {
                    openSaveErrorPopup(err);
                })
        }
    }

    const closeTab = () => {
        if (mode === 'NEW') {
            removeTab('NEW_PLAYLIST');
        } else {
            removeTab('EDIT_PLAYLIST');
        }

        dispatch(destroyPlaylist(mode));
    };

    const onLoadedImageSource = (index, source) => {
        const {contentList, playlistType} = playlistInfo;
        if (playlistType !== '5') {
            if (contentList && contentList[index] && source) {
                contentList[index].imageSource = source;
            }
            if(showLoading && contentList && index === contentList.length - 1) {
                setShowLoading(false);
            }
        }
    };

    const addPlaylistItem = (item, index, itemMode) => {
        if(!isNil(item.mediaType) && item.mediaType === 'ADS') {
            item.contentDuration = 0;
        }
        if (itemMode === TAG_ITEM) {
            let tagContents = [...playlistInfo.tags];
            item.duration = playlistInfo.defaultContentDuration;

            if (index || index === 0) {
                tagContents.splice(index+1, 0, item);
            } else {
                tagContents.push(item);
            }
            playlistInfo.tags = tagContents;
        } else if (itemMode === SYNC_ITEM) {
        } else if (itemMode === RANDOM) {
            if (playlistInfo.contents && playlistInfo.contents.length >= 200) {
                toastr.error(t('MIS_TEXT_ITEM_MAXIMUM_P').replace("%d", "200"));
                return;
            }
            updateCheckStatus(index, ADD, RANDOM);
            const randomContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0);
            item.randomCount = 1;
            if (index || index === 0) {
                randomContents.splice(index+1, 0, item);
            } else {
                randomContents.push(item);
            }
            playlistInfo.contents = [...playlistInfo.contents.filter((content) => content.randomCount === 0) , ...randomContents];
        } else {
            if (playlistInfo.contents && playlistInfo.contents.length >= 200) {
                toastr.error(t('MIS_TEXT_ITEM_MAXIMUM_P').replace("%d", "200"));
                return;
            }
            updateCheckStatus(index, ADD, GENERAL);
            const generalContents = playlistInfo.contents.filter((content)=> content.randomCount === 0);
            if (index || index === 0) {
                generalContents.splice(index+1, 0, item);
            } else {
                generalContents.push(item);
            }
            playlistInfo.contents = [...playlistInfo.contents.filter((content) => content.randomCount !== 0) , ...generalContents];
        }
        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const updatePlaylistItem = (item, index, itemMode) => {
        if (itemMode === TAG_ITEM) {
            const tagIndex = playlistInfo.tags.findIndex((tag) => tag.tagId === item.tagId);
            let tagContents = [...playlistInfo.tags]
            let copyItem = {...tagContents[tagIndex], ...item};
            tagContents.splice(tagIndex, 1);
            tagContents.splice(tagIndex, 0, copyItem);
            playlistInfo.tags = tagContents
        } else if (itemMode === SYNC_ITEM) {
            playlistInfo.sync = item;
        } else {
            let generalContents;
            if (item.randomCount === 0){
                generalContents = playlistInfo.contents.filter((content)=> content.randomCount === 0);
            } else {
                generalContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0);
            }

            generalContents.splice(index, 1);
            generalContents.splice(index, 0, item);

            if (item.randomCount === 0){
                playlistInfo.contents = [...playlistInfo.contents.filter((content) => content.randomCount !== 0) , ...generalContents];
            } else {
                playlistInfo.contents = [...playlistInfo.contents.filter((content) => content.randomCount === 0) , ...generalContents];
            }
        }
        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const updateContentPosition = (dragIndex, hoverIndex, itemMode) => {
        if (itemMode === TAG_ITEM) {
            updateTagCheckStatus({hoverIndex, dragIndex}, UPDATE)
            let tagContents =[...playlistInfo.tags]
            const insertItem = {...tagContents[dragIndex]};

            tagContents.splice(dragIndex, 1);
            tagContents.splice(hoverIndex, 0, insertItem);

            playlistInfo.tags = tagContents;
        } else if (itemMode === SYNC_ITEM) {
        } else if (itemMode === RANDOM) {
            updateCheckStatus({hoverIndex, dragIndex}, UPDATE, RANDOM)
            const randomContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0);
            const insertItem = {...randomContents[dragIndex]};

            randomContents.splice(dragIndex, 1);
            randomContents.splice(hoverIndex, 0, insertItem);

            playlistInfo.contents = [...playlistInfo.contents.filter((content) => content.randomCount === 0) , ...randomContents];
        } else {
            updateCheckStatus({hoverIndex, dragIndex}, UPDATE, GENERAL)
            const generalContents = playlistInfo.contents.filter((content)=> content.randomCount === 0);
            const insertItem = {...generalContents[dragIndex]};

            generalContents.splice(dragIndex, 1);
            generalContents.splice(hoverIndex, 0, insertItem);

            playlistInfo.contents = [...generalContents, ...playlistInfo.contents.filter((content) => content.randomCount !== 0)];
        }
        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const deleteTagPlaylistItemList = () => {
        if (tagCheckList.length < 1) {
            toastr.error(t("MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P"))
        } else if (tagCheckList.length === 1) {
            deletePlaylistItem(tagCheckList[0].index,TAG_ITEM);
        } else {
            const checkedIndexList = tagCheckList.map(({index})=> {return index});
            const remainedTags = playlistInfo.tags.filter((tag, tagIndex)=> {
                if (!checkedIndexList.includes(tagIndex)) {
                    return tag
                }
            });
            playlistInfo.tags = remainedTags;
            tagCheckList.splice(0, tagCheckList.length);
            setTagCheckList(tagCheckList);
            dispatch(updatePlaylist(mode, playlistInfo));
        }
    }

    const deletePlaylistItemList = () => {
        if (checkList.length < 1) {
            toastr.error(t("MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P"))
        } else if (checkList.length === 1) {
            checkList[0].item.randomCount === 0 ? deletePlaylistItem(checkList[0].index): deletePlaylistItem(checkList[0].index, RANDOM)
        } else {
            const generalCheckedIndexList = checkList.filter(({item})=> item.randomCount === 0).map(({index})=> {
                return index;
            }).sort();
            const randomCheckedIndexList  = checkList.filter(({item})=> item.randomCount !== 0).map(({index})=> {
                return index;
            }).sort();
            const generalRemainedContents = playlistInfo.contents.filter((content)=> content.randomCount === 0).filter((content, contentIndex)=> {
                if (!generalCheckedIndexList.includes(contentIndex)) {
                    return content
                }
            });
            const randomRemainedContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0).filter((content, contentIndex)=> {
                if (!randomCheckedIndexList.includes(contentIndex)) {
                    return content
                }
            })
            playlistInfo.contents = [...generalRemainedContents, ...randomRemainedContents];
        }
        checkList.splice(0, checkList.length);
        setCheckList(checkList);
        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const updateCheckStatus = (itemIndex, action, itemStatus) => {
        let generalCheckedItems = checkList.filter(({item})=> item.randomCount === 0);
        let randomCheckedItems = checkList.filter(({item})=> item.randomCount !== 0);
        if (action === DELETE) {
            if (itemStatus === GENERAL) {
                generalCheckedItems = deleteCheckStatusUpdate(generalCheckedItems, itemIndex);
            } else {
                randomCheckedItems =  deleteCheckStatusUpdate(randomCheckedItems, itemIndex);
            }
        } else if (action === UPDATE) {
            if (itemStatus === GENERAL) {
                generalCheckedItems = moveCheckStatusUpdate(generalCheckedItems, itemIndex)
            } else {
                randomCheckedItems = moveCheckStatusUpdate(randomCheckedItems, itemIndex)
            }
        } else {
            if (itemIndex === undefined) {
                return;
            }
            if (itemStatus === GENERAL) {
                generalCheckedItems = addCheckStatusUpdate(generalCheckedItems, itemIndex)
            } else {
                randomCheckedItems =  addCheckStatusUpdate(randomCheckedItems, itemIndex)
            }
        }
        setCheckList([...generalCheckedItems, ...randomCheckedItems]);
    }

    const updateTagCheckStatus = (itemIndex, action) => {
        let checkedItems = tagCheckList;
        if (action === DELETE) {
            checkedItems = deleteCheckStatusUpdate(checkedItems, itemIndex.index);
        } else if (action === UPDATE) {
            checkedItems = moveCheckStatusUpdate(checkedItems, itemIndex)
        } else {
            if (itemIndex === undefined) {
                return;
            }
            checkedItems = addCheckStatusUpdate(checkedItems, itemIndex.index)
        }
        setTagCheckList([...checkedItems]);
    }

    const deletePlaylistItem = (itemIndex, itemMode) => {
        if (itemMode === TAG_ITEM) {
            updateTagCheckStatus(itemIndex, DELETE);
            const tagIndex = playlistInfo.tags.findIndex((tag) => tag.tagId === itemIndex.tagId);
            const tagContents = [...playlistInfo.tags];
            tagContents.splice(tagIndex,1);
            playlistInfo.tags = tagContents
        } else if (itemMode === SYNC_ITEM) {
        } else if (itemMode === RANDOM) {
            updateCheckStatus(itemIndex, DELETE, RANDOM);
            const randomContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0);
            randomContents.splice(itemIndex,1);
            playlistInfo.contents = [...playlistInfo.contents.filter((content) => content.randomCount === 0) , ...randomContents];
        } else {
            updateCheckStatus(itemIndex, DELETE, GENERAL);
            const generalContents = playlistInfo.contents.filter((content)=> content.randomCount === 0);
            generalContents.splice(itemIndex,1);
            playlistInfo.contents = [...generalContents, ...playlistInfo.contents.filter((content) => content.randomCount !== 0)];
        }

        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const checkContentStatus = (item, index) => {
        contentService.fetchContentById(item.contentId).then(res => addPlaylistItem(convertAddedContent(res.items, playlistInfo.playlistType), index));
    }
    const checkRandomContentStatus = (item, index) => {
        contentService.fetchContentById(item.contentId).then(res => addPlaylistItem(convertAddedContent(res.items, playlistInfo.playlistType), index, RANDOM));
    }

    const columns = useMemo(() => [
        {
            Header: "Content",
            width: 160,
            accessor: 'thumbFilePath',
            Cell: (props) => <div><DraggableItem item={props.original} type={'LIST'} onClick={e=> {
                !isEmpty(props.original.playlistId) ? addPlaylistItem(convertAddedContent(props.original)) : checkContentStatus(props.original)}} onLoadedImageSource={onLoadedImageSource} index={props.index}/></div>,
            sortable: false,
        },
        {
            Header: <span>{t("TEXT_CONTENT_NAME_P")}</span>,
            accessor: "contentName",
            width: 500,
            Cell: (props) => <span className={"data_name"} onClick={e=> !isEmpty(props.original.playlistId) ? addPlaylistItem(convertAddedContent(props.original)) : checkContentStatus(props.original)}>{props.original.contentName|| props.original.playlistName}</span>,
            sortable: false,
        },
        {
            Header: "Details",
            sortable: false,
            Cell: props =>
                <div>
                    {
                        props.original.mediaType && <tr>{t("TEXT_MEDIA_TYPE_P")} : <span>{props.original.mediaType}</span></tr>
                    }
                    {
                        (props.original.playTimeInString|| props.original.playTime) &&
                        <tr>{t("TABLE_PLAY_TIME_P")} : <span>{props.original.playTimeInString || props.original.playTime}</span></tr>
                    }
                    <tr>{t("COM_TEXT_SIZE_P")} : <Size size={props.original.totalSize} /></tr>
                </div>
        }
    ], [playlistInfo.contentList]);

    const renderListView = () => {
        const {contentList} = playlistInfo;

        return (
            <div style={{width: '100%', background: '#eff0f1', display: 'flex', height: listHeight}}>
                <MagicInfoTable
                    data={contentList}
                    columns={columns}
                    showPagination={false}
                    loading={showLoading}
                    style={{width:'100%', height:'100%'}}
                    className="-striped -highlight"
                    defaultPageSize={1}
                    widthStyle={{width:'100%'}}
                    manual
                    noDataText={t("MESSAGE_COMMON_NO_DATA_P")}
                />
            </div>
        )
    };

    const renderImageView = () => {
        const {contentList} = playlistInfo;

        return (
            <div className="thumbnail_image_view_wrap poplist imageView" style={{height: listHeight, marginTop : '0px'}}>
                <div className="thumbnailview_wrap" style={{width:'100%'}}>
                    <ul className="clearfix">
                        {
                            !isEmpty(contentList) && contentList.length > 0 ?
                            contentList.map(
                                (item, index) => {
                                    return <li key={index} style={{width:100,height:86}}>
                                        <DraggableItem
                                            onClick={() => !isEmpty(item.contentId) ? checkContentStatus(item) : addPlaylistItem(convertAddedContent(item))}
                                            item={item}
                                            index={index}
                                            onLoadedImageSource={onLoadedImageSource}
                                        />
                                    </li>
                                }
                            )
                            : (
                                <div className="list_wrap_empty">
                                    <img src={EmptyImage} style={{marginTop:160}} />
                                    <span className="empty_title">{t('TEXT_TITLE_CONTENT_P')}</span>
                                    <span>{t("MIS_SID_20_UPLOAD_CONTENT_USING_THE_CONTENT_MENU")}</span>
                                </div>
                            )
                        }
                        <li style={{lineHeight: '70px'}}>
                            <Loading isLoading={showViewMoreLoading} />
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    const setShowEffectListWithIndex = (selectedEffectItem, order, mode) => {
        let updateEffectItem;
        const generalContents = playlistInfo.contents.filter((content)=> content.randomCount === 0);
        const randomContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0);
        if (mode === 'GENERAL') {
            updateEffectItem ={...playlistInfo.contents.filter((item)=> item.randomCount === 0)[order].effects, ...selectedEffectItem};
            generalContents[order].effects = updateEffectItem;
        } else {
            updateEffectItem = {...playlistInfo.contents.filter((item)=> item.randomCount !== 0)[order].effects, ...selectedEffectItem};
            randomContents[order].effects = updateEffectItem;
        }
        playlistInfo.contents = [...generalContents , ...randomContents];

        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const setShowEffectListToAll = (selectedEffectItem, mode) => {
        for(var i=0;i<playlistInfo.contents.length;i++){
            setShowEffectListWithIndex(selectedEffectItem, i, mode);
        }
    };

    const setOutEffects = (selectedEffectItem, order, updateEffectItem) => {
        let effects=selectedEffectItem[order].effects;
        effects.outDirection = updateEffectItem.outDirection;
        effects.outDuration = updateEffectItem.outDuration;
        effects.outDelayDirection = updateEffectItem.outDelayDirection;
        effects.outDelayDuration = updateEffectItem.outDelayDuration;
        effects.outDelayDiv = updateEffectItem.outDelayDiv;
        effects.outName = updateEffectItem.outName;
    }

    const setInEffects = (selectedEffectItem, order, updateEffectItem) => {
        let effects=selectedEffectItem[order].effects;
        effects.inDirection = updateEffectItem.inDirection;
        effects.inDuration = updateEffectItem.inDuration;
        effects.inDelayDirection = updateEffectItem.inDelayDirection;
        effects.inDelayDuration = updateEffectItem.inDelayDuration;
        effects.inDelayDiv = updateEffectItem.inDelayDiv;
        effects.inName = updateEffectItem.inName;
    }

    const setEffects = (contents, order, updateEffectItem, type) => {
        (type === 'IN') ? setInEffects(contents, order, updateEffectItem) 
            : setOutEffects(contents, order, updateEffectItem);
    }

    const setShowInOutEffectListWithIndex = (selectedEffectItem, order, mode, type) => {
        let updateEffectItem;
        const generalContents = playlistInfo.contents.filter((content)=> content.randomCount === 0);
        const randomContents = playlistInfo.contents.filter((content)=> content.randomCount !== 0);
        if (mode === 'GENERAL') {
            updateEffectItem ={...playlistInfo.contents.filter((item)=> item.randomCount === 0)[order].effects, ...selectedEffectItem};
            setEffects(generalContents, order, updateEffectItem, type);
        } else {
            updateEffectItem = {...playlistInfo.contents.filter((item)=> item.randomCount !== 0)[order].effects, ...selectedEffectItem};
            setEffects(randomContents, order, updateEffectItem, type);
        }
        playlistInfo.contents = [...generalContents, ...randomContents];

        dispatch(updatePlaylist(mode, playlistInfo))
    };

    const setShowInOutEffectListToAll = (selectedEffectItem, mode, type) => {
        for(var i=0;i<playlistInfo.contents.length;i++){
            setShowInOutEffectListWithIndex(selectedEffectItem, i, mode, type);
        }
    };

    const compareCheckList = (item, index) => {
        const checkedItem = checkList.find(x => x.item.contentId === item.contentId && x.item.randomCount === item.randomCount);
        const checkedItemIndex = checkList.findIndex(x => x.item.contentId === item.contentId);
        if (checkedItem && checkedItem.index === index) {
            checkList.splice(checkedItemIndex, 1);
        } else {
            checkList.push({item: item, index: index});
        }
        setCheckList(checkList);
    };

    const compareTagCheckList = (item, index) => {
        const checkedItem = tagCheckList.find(tagCheckItem=> tagCheckItem.item.tagId === item.tagId)
        const checkedItemIndex = tagCheckList.findIndex(tagCheckItem=> tagCheckItem.item.tagId === item.tagId);
        if (checkedItem) {
            tagCheckList.splice(checkedItemIndex, 1);
        } else {
            tagCheckList.push({item: item, index: index});
        }
        setTagCheckList(tagCheckList);
    };

    const checkSettingPopupByCheckList = () => {
        checkList.length > 1 ? onSettingPopup(checkList) : checkList.length == 1 ? onSettingPopup(checkList[0].item, checkList[0].index) : toastr.error(t("MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P"))
    };

    const checkTagSettingPopupByCheckList = () => {
        tagCheckList.length > 1 ? onSettingPopup(tagCheckList) : tagCheckList.length == 1 ? onSettingPopup(tagCheckList[0].item, tagCheckList[0].index) : toastr.error(t("MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P"))
    }

    const isVisibleRandomButton = () => {
        const {deviceType, deviceTypeVersion, playlistType} = playlistInfo;
        return playlistType == "0" &&!(deviceType == SPLAYER && deviceTypeVersion == '2.0') && !(deviceType == SPLAYER && deviceTypeVersion == '1.0') && deviceType !== LPLAYER && deviceType !== APLAYER
    };

    const useGeneralPlaylistEditor = () =>  {
        const {playlistType} = playlistInfo;
        return (playlistType === '0' || playlistType === '2' || playlistType === '4' || playlistType === '6')
    };

    const [orgFilter, setOrgFilter] = useState({
        organId: 'all',
        searchText: '',
        startIndex: 1,
        pageSize: 1000
    });

    const [orgList, setOrgList] = useState([]);

    const onSelectChange = (e, selectedValue) => {
        setOrgFilter({...orgFilter, organId: selectedValue});
    };

    useEffect(()=> {
        playlistInfo.playlistType === playlistConstants.PLAYLISTTYPES[4].id && Promise.all([
            userService.fetchOrganizations()
        ]).then(values => {
            const orgList = values[0].items.map(org => {
                return {value: org.organizationId, title: org.groupName};
            });
            if( orgList.findIndex(item => item.title === "ROOT") !== -1) {
                orgList.splice(orgList.findIndex(item => item.title === "ROOT"), 1);
            }
            setOrgList([{value: 'all', title: t('COM_SID_ALL')}, {value: -2, title: "Common"},...orgList]);
        }).catch(
            error => console.log(error)
        ).finally(
        );
    },[orgFilter]);

    const openSaveErrorPopup = (err) => {
        err.errorCode === '400013' &&
        addPopup({
            id: playlistConstants.INCLUDE_EXPIRED_CONTENT,
            type: playlistConstants.INCLUDE_EXPIRED_CONTENT,
            expiredContentList: err.items.contents,
            onClose: () => closePopup(playlistConstants.INCLUDE_EXPIRED_CONTENT)
        })
    }

    const isValidTagRange = (tagInfo) => {
        let tagNumberList;
        if(tagInfo.contentTagConditions){
            tagNumberList = tagInfo.contentTagConditions;
        }
        else{
            tagNumberList = tagInfo.tagConditions;
        }
        const tagListSize = tagNumberList.length;
        const regex1 = /^([0-9]+)(-)([0-9]+)$/;
        const regex2 = /^([0-9]+)$/;
        for (let i=0; i<tagListSize; i++) {
            if(tagNumberList[i].tagCondition === ""){
                continue;
            }
            const tagConditions = (tagNumberList[i].tagCondition).split(',');
            for (let j=0; j<tagConditions.length; j++) {
                if(regex1.test(tagConditions[j]) || regex2.test(tagConditions[j])) {
                    continue;
                }
                return false;
            }
        }
        return true;
    };


    const openSavePlaylistPopup = (saveMode) => {
        let emptyCheck = false;
        let errorMessage = t("MESSAGE_CONTENT_SELECT_ONE_OR_MORE_CONTENT_P");

        if (playlistInfo.playlistType === '3') {
            playlistInfo.sync.map((syncGroupItem)=> {
                if (isEmpty(syncGroupItem.items)) {
                    emptyCheck = true;
                } else {
                    syncGroupItem.items.map((syncItem) => {
                        if(isEmpty(syncItem.contentId)) {
                            emptyCheck = true;
                        }
                    })
                }
            });
            errorMessage = t("MIS_SID_SPECIFIED_NUMBER_CONTENT_GROUP");
        } else if (playlistInfo.playlistType === '5'){
            if (playlistInfo.tags && playlistInfo.tags.length < 1) {
                emptyCheck = true;
            }
            const playlistTagInfoSize = playlistInfo.tags.length;
            for(let i = 0; i<playlistTagInfoSize; i++){
                const tagInfo = playlistInfo.tags[i];
                if(tagInfo.tagType === 1){
                    const isValid = isValidTagRange(tagInfo);
                    if(!isValid){
                        toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN').replace('(<<A>>)', ""));
                        return;
                    }
                }
            }
        } else {
            if (playlistInfo.contents && playlistInfo.contents.length < 1) {
                emptyCheck = true;
            }
        }

        if (emptyCheck) {
            toastr.error(errorMessage);
        } else {
            addPopup({
                id: commonConstants.SAVE_PLAYLIST,
                type: commonConstants.SAVE_PLAYLIST,
                mode: saveMode,
                onSave: (playlistItem)=> savePlaylist(playlistItem, saveMode),
                playlistInfo: playlistInfo,
                onClose: () => closePopup(commonConstants.SAVE_PLAYLIST),
                addPopup: addPopup,
                closePopup: closePopup,
            })
        }
    }
    const isNeedSelectTag = () => {
        return (playlistInfo.playlistType === '0' || playlistInfo.playlistType === '6' ) && playlistInfo.deviceType !== LPLAYER;
    }

    const [includedTags, setIncludedTags] = useState([]);

    useEffect(() =>{
        if (playlistInfo.playlistType !== '5' && playlistInfo.playlistType !== '3') {
            let filteredTags = selectedTags.findIndex((selTag) => selTag === 'None') > -1 ? [{id: 'None', name: t("TEXT_NONE_P"), isChecked: true }]:[{id: 'None', name: t("TEXT_NONE_P"), isChecked: false }]
            playlistInfo.contents.map((content) => {
                content.contentTag && content.contentTag.tagValues.map((tag) => {
                    const nameArray = flatMap(filteredTags, (filteredTag) => {
                        return [filteredTag.name]
                    });
                    if (selectedTags.findIndex((includedTag)=> includedTag  === tag) > -1) {
                        nameArray.findIndex((name) => name === tag) < 0 && filteredTags.push({id : tag, name: tag, isChecked: true });
                    } else {
                        nameArray.findIndex((name) => name === tag) < 0 && filteredTags.push({id : tag, name: tag, isChecked: false });
                    }
                })
            });
            setIncludedTags(filteredTags)
        }

    }, [playlistInfo.contents]);

    const [selectedTags, setSelectedTags] = useState([]);

    const [filteredContents, setFilteredContents] = useState({
        general: playlistInfo.contents ? playlistInfo.contents.filter((content) => content.randomCount === 0): [],
        random: playlistInfo.contents ? playlistInfo.contents.filter((content) => content.randomCount !== 0) : [],
    });

    useEffect(()=> {
        if (playlistInfo.playlistType !== '5' && playlistInfo.playlistType !== '3') {
            let filteredGeneralContentByTags = [];
            playlistInfo.contents.filter((content)=> content.randomCount === 0).map((content, index)=> {
                content.contentTag && content.contentTag.tagValues.map((tag) => {
                    selectedTags.includes(tag) && !filteredGeneralContentByTags.includes(content) && filteredGeneralContentByTags.push(content)
                })
                content.orderIndex = index;
                isEmpty(content.contentTag) && selectedTags.includes('None') && filteredGeneralContentByTags.push(content);
            });
            filteredGeneralContentByTags = isEmpty(selectedTags) ? playlistInfo.contents.filter((content)=> content.randomCount === 0) : filteredGeneralContentByTags

            let filteredDuration = 0;
            filteredGeneralContentByTags.forEach(element => {
                if(!isUndefined(element) && element.isSubPlaylist) {
                    const [hours, minutes, seconds] = element.playTime.split(':');
                    filteredDuration +=  (Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds));
                } else {
                    filteredDuration += (element.contentDuration === -1 ? 0 : element.contentDuration);
                }
            });

            let filteredRandomContentByTags = [];
            playlistInfo.contents.filter((content)=> content.randomCount !== 0).map((content, index)=> {
                content.contentTag && content.contentTag.tagValues.map((tag) => {
                    selectedTags.includes(tag) && !filteredRandomContentByTags.includes(content) && filteredRandomContentByTags.push(content)
                })
                content.orderIndex = index;
                isEmpty(content.contentTag) && selectedTags.includes('None') && filteredRandomContentByTags.push(content);
            });
            filteredRandomContentByTags = isEmpty(selectedTags) ? playlistInfo.contents.filter((content)=> content.randomCount !== 0) : filteredRandomContentByTags

            filteredRandomContentByTags.forEach(element => {
                if(!isUndefined(element) && element.isSubPlaylist) {
                    const [hours, minutes, seconds] = element.playTime.split(':');
                    filteredDuration +=  (Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds));
                } else {
                    filteredDuration += (element.contentDuration === -1 ? 0 : element.contentDuration);
                }
            });
            setFilteredPlaylistDuration(filteredDuration);
            setFilteredPlaylistLength(filteredGeneralContentByTags.length + filteredRandomContentByTags.length);

            setFilteredContents({
                general: filteredGeneralContentByTags,
                random: filteredRandomContentByTags
            })
        }
    }, [selectedTags, playlistInfo.contents]);

    const onChangeSelectTagFilter = (items) => {
        let filteredTags = [];
        items.map((tag) =>{
            !isEmpty(tag) && filteredTags.push(tag)
        });
        setSelectedTags(filteredTags);
    };

    const deleteSelectedTags = (deletedTag, index) => {
        const includedTagIds = flatMap(includedTags, (tag) => {
            return [tag.id]
        });
        if (includedTags && includedTagIds.includes(deletedTag)) {
            const tagIndex = includedTags.findIndex((tag) => tag.id === deletedTag);
            includedTags[tagIndex].isChecked = false;
            setIncludedTags(includedTags);
        }
        const remainedTags = [...selectedTags];
        remainedTags.splice(index, 1);
        setSelectedTags(remainedTags);
    };

    const offPlaylistEffect = () => {
        setEffectData({
            ...effectData,
            showEffectList: false
        })
        offArrowClick();
        
    }

    return (
        <div className={"content_playlist_manager_wrap"} style={{display: (currContent === 'NEW_PLAYLIST' && mode === 'NEW' || currContent === 'EDIT_PLAYLIST' && mode === 'EDIT') ? 'block':'none'}}>
            {
                showConfirmPopup &&
                <ConfirmPopup
                    title={t("COM_BUTTON_CONFIRM")}
                    message={t("MIS_SID_20_DELETE_RANDOM_INSERTS_ALL_WILL_BE_DELETED")}
                    onClickYes={resetRandomInsertArea}
                    onClose={() => setShowConfirmPopup(false)}
                />
            }
            <div style={{height: 30}}>
                <div className={"playlist_manager_buttonsWrap"}>
                    <WhiteButton name={t("COM_BUTTON_SAVE")} width={100} onClick={()=> openSavePlaylistPopup('SAVE')} />
                    {
                        mode === 'EDIT' && <WhiteButton name={t("BUTTON_SAVE_AS_P")} width={100} onClick={() => openSavePlaylistPopup('SAVE_AS')} />
                    }
                    <WhiteButton name={t("COM_BUTTON_CLOSE_P")} width={100} onClick={()=>
                        addPopup({
                            id: commonConstants.COMMON_CONFIRM_POPUP,
                            type: commonConstants.COMMON_CONFIRM_POPUP,
                            message: t("MESSAGE_UPLOADER_REALLY_CANCEL_P"),
                            title: t("COM_BUTTON_CONFIRM"),
                            onClickYes: ()=> {
                                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                                closeTab()
                            },
                            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP)
                        })} />
                    <div style={{float: 'right'}}>
                        {
                            <SupportedDeviceIcon deviceType={playlistInfo.deviceType} deviceTypeVersion={playlistInfo.deviceTypeVersion} />
                        }
                    </div>
                </div>
            </div>
            <MagicInfoDndProvider>
                <div className={"height100p half"}>
                    <div className={"playlist_thumbnail"}>
                        <div className={"playlist_thumb_top"} >
                            <div className={"btn_area mt20 clearfix"}>
                                {playlistInfo.playlistType === "0" && playlistInfo.deviceType !== LPLAYER &&
                                    <Select
                                        title={t("DID_MAIN_CONTENT")}
                                        width={120}
                                        value={contentsMode}
                                        selects={[
                                            {id:'CONTENT', value:'content', title: t("DID_MAIN_CONTENT")},
                                            {id:'NESTED_PLAYLIST', value:'nested', title: t("MIS_SID_NESTED")},
                                        ]}
                                        onChange={(e)=> {
                                            setContentsMode(e.target.getAttribute('data-value'));
                                            setContentFilter({...contentFilter, refresh: true, startIndex:1})
                                        }}
                                    />
                                }
                                {
                                    playlistInfo.playlistType === "3" &&
                                    <Fragment>
                                        <div className={'float_l'}><div className='content_title'>{t("TEXT_CONTENT_P")}</div></div>
                                        <div className={"float_r mr10"}><SearchBar keyword={contentFilter.searchText} placeholder={t("TEXT_CONTENT_NAME_P")} onClickSearch={onKeywordSearch} width={150} /></div>
                                        <div className={"float_r mr8"}><SwitchListNImage view={playlistInfo.view} onClick={(viewType)=> updateSwitchListNImage(viewType)}/></div>
                                        <div className={"float_r mr8"}>
                                             <Filter type={"playlist"} right={-247} onSaveFilter={onSaveContentFilter} selected={selectedContentFilter} mediaTypes={getContentsTypesByPlaylistType()} cachedFilter={cache.filter}/>
                                        </div>
                                        <div className={"float_r mr8"}><CategoryButton defaultCheckedKeys={contentFilter.categoryIds} onCategoryCheck={onCategoryCheck} /></div>
                                        <div className={"float_r mr15"}>
                                            <button className={"contentBrowserGroupBtn"} style={{cursor:'pointer'}} onClick={()=>{
                                                addPopup({
                                                    id: commonConstants.COMMON_GROUP_POPUP,
                                                    type: commonConstants.COMMON_GROUP_POPUP,
                                                    mode: 'content',
                                                    title: t("MIS_SID_SELECT_FOLDER"),
                                                    params: {groupType : contentConstants.MY_CONTENT_GROUP},
                                                    save: (groups)=> filterContentsFolders(groups),
                                                    close: ()=>closePopup(commonConstants.COMMON_GROUP_POPUP)
                                                });
                                            }}/>
                                        </div>
                                        {
                                            folderFilter.status && contentsMode == 'content'&&
                                            <div className="tag_input mr8 float_r" style={{maxWidth:50}}>
                                                <span><input type="text" value={folderFilter.groupName} /><button onClick={()=>{
                                                    setFolderFilter({status:false, groupName: undefined})
                                                    setContentFilter({...contentFilter, groupId: undefined, groupType: 'ALL'});
                                                }} /></span>
                                            </div>
                                        }
                                    </Fragment>
                                }
                                {
                                    playlistInfo.playlistType === "5" && <div className={'float_l'}><h3>{t("MIS_SID_MEDIA_TAG")}</h3></div>
                                }
                                {
                                    useGeneralPlaylistEditor() &&
                                    <Fragment>
                                        <div className={"float_r mr10"}><SearchBar keyword={contentFilter.searchText} placeholder={t("TEXT_CONTENT_NAME_P")} onClickSearch={onKeywordSearch} width={150}/></div>
                                        <div className={"float_r mr8"}><SwitchListNImage view={playlistInfo.view} onClick={(viewType)=> updateSwitchListNImage(viewType)}/></div>
                                        {
                                            contentsMode === 'content' &&
                                            <div className={"float_r mr8"}>
                                                <Filter type={"playlist"} right={-247}
                                                        onSaveFilter={onSaveContentFilter}
                                                        selected={selectedContentFilter}
                                                        mediaTypes={getContentsTypesByPlaylistType()}
                                                        cachedFilter={Object.assign(cache.filter,newPlaylist)}/>
                                            </div>
                                        }
                                        {
                                            contentsMode === 'content' &&
                                            <div className={"float_r mr8"}><CategoryButton
                                                defaultCheckedKeys={contentFilter.categoryIds}
                                                onCategoryCheck={onCategoryCheck}/></div>
                                        }
                                        {
                                            contentsMode === 'content' &&
                                            <div className={"float_r mr15"}>
                                                <button className={"contentBrowserGroupBtn"} style={{cursor:'pointer'}} onClick={()=>{
                                                    addPopup({
                                                    id: commonConstants.COMMON_GROUP_POPUP,
                                                    type: commonConstants.COMMON_GROUP_POPUP,
                                                    mode: 'content',
                                                    title: t("MIS_SID_SELECT_FOLDER"),
                                                    params: {groupType : contentConstants.MY_CONTENT_GROUP},
                                                    save: (groups)=> filterContentsFolders(groups),
                                                    close: ()=>closePopup(commonConstants.COMMON_GROUP_POPUP)
                                                });}}
                                            />
                                            </div>
                                        }
                                        {
                                            folderFilter.status &&
                                            <div className="tag_input mr8 float_r" style={{maxWidth:50}}>
                                                <span><input type="text" value={folderFilter.groupName} /><button onClick={()=>{
                                                    setFolderFilter({status:false, groupName: undefined})
                                                    setContentFilter({...contentFilter, groupId: undefined, groupType: 'ALL'});
                                                }} /></span>
                                            </div>
                                        }
                                    </Fragment>
                                }
                                {
                                    playlistInfo.playlistType === "5" &&
                                    <div style={{width: 304, display: 'table-cell', paddingTop: 3,float: 'right'}}>
                                        <Select width={295} selects={orgList} value={orgFilter.organId} defaultTitle={t('COM_SID_ALL')} useEllipsis={true} onChange={onSelectChange}/>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            showLoading &&
                            <div className="list_wrap_loading" style={{height: listHeight}}>
                                <Loading isLoading={true}/>
                            </div>
                        }
                        {
                            playlistInfo.view === 'LIST' && (playlistInfo.playlistType === '5' ? <TagListView filter={orgFilter} mode={mode} tags={playlistInfo.tags} addPlaylistItem={addPlaylistItem} updatePlaylistItem={updatePlaylistItem} deletePlaylistItem={deletePlaylistItem}
                                                                                                        addPopup={addPopup} closePopup={closePopup}/> : renderListView())}
                        {   playlistInfo.view === 'IMAGE' && renderImageView()}
                       <div style={{display:'flex', marginTop: 15}}>
                       {
                           playlistInfo.contentList && contentTotalCount > playlistInfo.contentList.length &&
                           <WhiteButton width={'auto'} name={t("BUTTON_MORE_VIEW_P")}
                                        style={{margin: '0px auto'}}
                                        onClick={()=> {
                                            const pageSize = playlistInfo.view === 'IMAGE' ? 70 : 15;
                                            setShowViewMoreLoading(true);
                                            //DF201020-00892: startIndex updated to be independent of list or image view.
                                            setContentFilter({...contentFilter, startIndex: playlistInfo.contentList.length +1, pageSize: pageSize, refresh: false});
                           }} />
                       }
                       </div>
                    </div>
                    <div className={"playlist_preview height100p float_r"}>
                        {
                            useGeneralPlaylistEditor() &&
                            <div className="playlist_top">
                                <div className="float_l">
                                    <h3 id="playlistName">{playlistInfo.playlistName} </h3><span>{t("TABLE_FILE_COUNT_P")} : {filteredPlaylistLength}{"\n"}</span>
                                    <span style={{ whiteSpace: 'pre-wrap', display: 'block'}}> {t("COM_TABLE_DURATION_P")} : {changeSecondToHMSTime(filteredPlaylistDuration)}</span>
                                </div>
                                <div className="float_r">
                                    <WhiteButton width={100} name={t("COM_BUTTON_DELETE")}
                                                 onClick={() => deletePlaylistItemList()}/>
                                    <WhiteButton width={100} name={t("COM_SID_SETTINGS")}
                                                 onClick={() => checkSettingPopupByCheckList()}/>
                                    {
                                        isVisibleRandomButton() &&
                                        <WhiteButton width={'auto'} name={showRandomInsertArea ? t("MIS_SID_20_DELETE_RANDOM_INSERT") : t("MIS_SID_20_CREATE_RANDOM_INSERT")}
                                                     onClick={() => showRandomInsertArea ? setShowConfirmPopup(true) : updateShowRandomInsertArea()}/>
                                    }
                                </div>
                            </div>
                        }
                        {
                            playlistInfo.playlistType === '5' &&
                            <div className="playlist_top">
                                <div className="float_l">
                                    <h3 id="playlistName">{t("MIS_TEXT_TAG_P")} </h3><span> : {playlistInfo.tags ? playlistInfo.tags.length : ''}</span>
                                    <span style={{ whiteSpace: 'pre-wrap', display: 'block'}}> {t("COM_TABLE_DURATION_P")} : {changeSecondToHMSTime(tagPlaylistDuration)}</span>
                                </div>
                                <div className="float_r">
                                    <WhiteButton width={100} name={t("COM_BUTTON_DELETE")}
                                                 onClick={() => deleteTagPlaylistItemList()}/>
                                    <WhiteButton width={100} name={t("COM_SID_SETTINGS")}
                                                 onClick={() => checkTagSettingPopupByCheckList()}/>
                                </div>
                            </div>
                        }
                        <div className={"playlist_editor_wrap type01"} style={{height:1080, paddingLeft: 30, width: '95%'}}>
                            {
                                useGeneralPlaylistEditor() ?
                                    <>
                                    {
                                        isNeedSelectTag() &&
                                        <h3 style={{marginTop: 12, height: 30}}>
                                            <span className="space float_l mr15">{t("MIS_SID_FILTER_TAGS")}</span>
                                            {
                                                includedTags.length > 0 &&
                                                <PlaylistMultiSelectButton data={includedTags} onChange={onChangeSelectTagFilter}
                                                                           title={t("MIS_SID_SELECT_TAG")} itemAll={false}
                                                                           height={166}/>
                                            }
                                        {
                                            selectedTags.map((tag, index) => {
                                                return (
                                                    <div className="tag_input mr8" style={{maxWidth: 50}}>
                                                    <span>
                                                        <input type="text" value={tag} readOnly="readonly" disabled=""/>
                                                        <button onClick={() => deleteSelectedTags(tag, index)}/></span>
                                                    </div>
                                                    )
                                                })
                                        }
                                        </h3>
                                    }
                                    <PlaylistEditor
                                        contents={filteredContents.general}
                                        effectClick={(index)=> {
                                            setEffectData({
                                                showEffectList: true,
                                                showEffectIndex: index,
                                                selectedMode: 'GENERAL',
                                            });
                                        }}
                                        onDelete={(index)=> {
                                            effectData.showEffectList && offPlaylistEffect();
                                            deletePlaylistItem(index)}}
                                        onSettingPopup={(item, index)=> {
                                            effectData.showEffectList && offPlaylistEffect();
                                            onSettingPopup(item, index)
                                        }}
                                        onItemClick={(item, index) => {
                                            effectData.showEffectList && offPlaylistEffect();
                                            compareCheckList(item, index)
                                        }}
                                        onDrop={(item, index) => {
                                            effectData.showEffectList && offPlaylistEffect();
                                            isEmpty(item.playlistId) ? checkContentStatus(item, index) : addPlaylistItem(item, index)
                                        }}
                                        moveItem={(dragIndex, hoverIndex) => {
                                            effectData.showEffectList && offPlaylistEffect();
                                            updateContentPosition(dragIndex, hoverIndex)
                                        }}
                                        updateItem={(item, index) => {
                                            effectData.showEffectList && offPlaylistEffect();
                                            updatePlaylistItem(item, index)
                                        }}
                                        playlistType={playlistInfo.playlistType}
                                        deviceType={playlistInfo.deviceType}
                                        generalHeight={generalHeight}
                                        checkList={checkList}
                                    />
                            </> :
                                playlistInfo.playlistType === '5' ?
                                    <TagPlaylistEditor
                                        tags={playlistInfo.tags}
                                        onDelete={(index)=> {deletePlaylistItem(index, TAG_ITEM)}}
                                        onSettingPopup={(item, index)=>{onSettingPopup(item, index)}}
                                        onItemClick={(item, index) => {compareTagCheckList(item, index)}}
                                        onDrop={(item, index) => {addPlaylistItem(convertTagContent(item), index, TAG_ITEM)}}
                                        moveItem={(dragIndex, hoverIndex) => updateContentPosition(dragIndex, hoverIndex, TAG_ITEM)}
                                        updateItem={(item, index) => {updatePlaylistItem(item, index, TAG_ITEM)}}
                                        playlistType={playlistInfo.playlistType}
                                        deviceType={playlistInfo.deviceType}
                                        tagCheckList={tagCheckList}
                                    /> :
                                    <SyncPlaylistEditor
                                        editorHeight={editorHeight}
                                        mode={mode}
                                        playlistInfo={playlistInfo}
                                        updateItem={(item, index) => {updatePlaylistItem(item, index, SYNC_ITEM)}}
                                        syncPlayDuration={syncPlaylistDuration}/>

                            }
                            {
                                effectData.showEffectList &&
                                <PlaylistEffect
                                    outSide={outSide}
                                    deviceType={playlistInfo.deviceType}
                                    playlistType={playlistInfo.playlistType}
                                    order={effectData.showEffectIndex}
                                    mode={effectData.selectedMode}
                                    effects={effectData.selectedMode === 'GENERAL' ? playlistInfo.contents.filter((content)=> content.randomCount==0)[effectData.showEffectIndex].effects: playlistInfo.contents.filter((content)=> content.randomCount!==0)[effectData.showEffectIndex].effects}
                                    arrowClick={(selectedEffectItem, order, mode) => setShowEffectListWithIndex(selectedEffectItem, order, mode)}
                                    applyToAll={(selectedEffectItem, mode) => setShowEffectListToAll(selectedEffectItem, mode)}
                                    applyInToAll={(selectedEffectItem, mode) => setShowInOutEffectListToAll(selectedEffectItem, mode, 'IN')}
                                    applyOutToAll={(selectedEffectItem, mode) => setShowInOutEffectListToAll(selectedEffectItem, mode, 'OUT')}
                                />
                            }
                            {
                                showRandomInsertArea &&
                                <RandomInsertArea
                                    onDrop={(item, index)=> {
                                        effectData.showEffectList && offPlaylistEffect();
                                        isEmpty(item.playlistId) ? checkRandomContentStatus(item, index): toastr.error(t("Can not add nested playlist to random insert."))
                                    }}
                                    randomContents={filteredContents.random}
                                    effectClick={(index)=> {
                                        setEffectData({
                                            showEffectList: true,
                                            showEffectIndex: index,
                                            selectedMode: 'RANDOM',
                                        });
                                    }}
                                    onDelete={(index)=> {
                                        effectData.showEffectList && offPlaylistEffect();
                                        deletePlaylistItem(index,RANDOM)
                                    }}
                                    onSettingPopup={(item, index)=> {
                                        effectData.showEffectList && offPlaylistEffect();
                                        onSettingPopup(item, index)
                                    }}
                                    onItemClick={(item, index)=> {
                                        effectData.showEffectList && offPlaylistEffect();
                                        compareCheckList(item, index)
                                    }}
                                    moveItem={(dragIndex, hoverIndex) => {
                                        effectData.showEffectList && offPlaylistEffect();
                                        updateContentPosition(dragIndex, hoverIndex, RANDOM)
                                    }}
                                    playlistType={playlistInfo.playlistType}
                                    deviceType={playlistInfo.deviceType}
                                    updateItem={(item, index) => {
                                        effectData.showEffectList && offPlaylistEffect();
                                        updatePlaylistItem(item, index)
                                    }}
                                    checkList={checkList}
                                />
                            }
                        </div>
                    </div>
                </div>
            </MagicInfoDndProvider>
        </div>
    )
}

export default connect(
    state => ({
        menu: state.menu,
        playlist: state.playlist,
    }),
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        updatePopup: popup => dispatch(popupAction.updatePopup(popup)),
        closePopup: popupId => dispatch(popupAction.closePopup(popupId)),
        reloadGroup: id => dispatch(menuAction.reloadGroup(id)),
        removeTab: id => dispatch(menuAction.removeTab(id)),
        updatePlaylist: (mode, playlistInfo) => dispatch(playlistAction.updatePlaylist(mode, playlistInfo)),
        destroyPlaylist: (type) => dispatch(playlistAction.destroyPlaylist(type)),
        destroyLastMemoryPlaylist: (mode, playlistInfo) => dispatch(playlistAction.destroyLastMemoryPlaylist(mode, playlistInfo)),
        destroyEmptyPlaylist: (mode, playlistInfo) => dispatch(playlistAction.destroyEmptyPlaylist(mode, playlistInfo)),
    })
)(PlaylistManager);
