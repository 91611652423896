import React, {useCallback, useEffect, useRef, useState} from 'react';
import 'rc-time-picker/assets/index.css';
import Checkbox from "../../../checkbox/Checkbox";
import Moment from 'moment';
import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import ContentPopup from "../ContentPopup";
import MonthlyContainer from "../../../../containers/schedule/content/MonthlyContainer";
import Select from "../../../selectbox/Select";
import {toastr} from 'helper/toastrIntercept';
import WeeklyCheckbox from "../../../checkbox/WeeklyCheckbox";
import {isNil, isUndefined, isEmpty} from "lodash";
import DateInput from "../../../input/DateInput";
import {popupAction} from "../../../../actions";
import {useDispatch} from "react-redux";
import RadioGroup from "../../../radio/RadioGroup";
import TextInput from "../../../input/TextInput";
import {useMISOpt} from "../../../misopt";
import {
    commonConstants,
    CONTENT_SCHEDULE_PROGRAM_TYPE_VWL,
    TIME_FORMAT_FOR_VALUE,
    DATE_FORMAT_FOR_VALUE,
    CONTENT_SCHEDULE_REPEAT_TYPE_ONCE,
    CONTENT_SCHEDULE_REPEAT_TYPE_DAILY,
    CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY,
    CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY,
    CONTENT_SCHEDULE_INPUT_SOURCE_TYPE, MEDIA_TYPE_FTP, MEDIA_TYPE_CIFS, CONTENT_SCHEDULE_RULESET_TYPE
} from "../../../../constants";
import TimePicker2 from "../../../input/TimePicker2";
import NumberInput from "../../../input/NumberInput";

const SelectContentsPlaylistsPopup = ({scheduleInfo, closePopup, updateSchedule, removeSchedule, mode, deviceType, deviceTypeVersion, programType, isSubFrames}) => {
    
    const START_TYPE = 'start';
    const END_TYPE = 'end';
    
    const {misopt: {user: {dateFormat}}} = useMISOpt();
    const {misOptionSchedule} = useMISOpt();
    const dispatch = useDispatch();
    const [schedule, setSchedule] =  useState(scheduleInfo);
    const [contentPopup, setContentPopup] = useState(false);
    const [style, setStyle] = useState({
        width: 680,
        height: 200
    })
    
    const ref = useRef();
    const {t} = useTranslation();

    const getScheduleInfo = useCallback(()=> {
        return misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion).contentSchedule;
    }, [])

    const {contents}  = getScheduleInfo();

    const selectContent = (contentId, contentName, thumbnail, filename, fileSize, contentType, playlistType) => {
        
        if (contentType === CONTENT_SCHEDULE_INPUT_SOURCE_TYPE && contents !== undefined && contents.isHW !== undefined && contents.isHW) {
            let updateSchedule = Object.assign({}, initScheduleForDaily(schedule, CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY, true));
            updateSchedule = {...updateSchedule, contentId: contentId, contentName: contentName, title: contentName, contentType: contentType, thumbnailFileId: thumbnail, fileSize: fileSize, playlistType: playlistType};
            setSchedule({...schedule, ...updateSchedule});
        } else {
            setSchedule({...schedule, contentId: contentId, contentName: contentName, title: contentName, contentType: contentType, thumbnailFileId: thumbnail, fileSize: fileSize, playlistType: playlistType});
        }

        openContentPopup();
    }

    const openContentPopup = () => {
        setContentPopup(!contentPopup);
    }

    const getScheduleTime = (type) => {
        if (type === START_TYPE) {
            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                return Moment(schedule.start).format(TIME_FORMAT_FOR_VALUE);
            } else {
                return schedule.startTime;
            }
        } else if (type === END_TYPE) {
            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                return Moment(schedule.end).format(TIME_FORMAT_FOR_VALUE);
            } else {
                return schedule.endTime;
            }
        }
    }

    const onRemoveSchedule = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t("MESSAGE_SCHEDULE_DELETE_QUE_P"),
            onClickYes: ()=>remove(),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const remove = () => {
        removeSchedule(scheduleInfo.scheduleId);
        dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
    }

    const getScheduleDate = useCallback((type)=> {
        if (type === START_TYPE) {
            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                return schedule.start;
            } else {
                return schedule.ranges.start.toDate();
            }
        } else if (type === END_TYPE) {
            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                return schedule.end;
            } else {
                return schedule.ranges.end.toDate();
            }
        }
    }, [schedule.startRecur, schedule.endRecur, schedule.start, schedule.end, schedule.ranges]);

    const handleDate = (date, type) => {
        if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
            if (type === START_TYPE) {
                const end = schedule.end;
                end.setDate(1); // 31일 경우, 31일이 없는 달로 설정하면 달이 바뀜.
                                // ex) 3월 31일 에 setMonth를 4월로 설정하면 4월 31일이 없기때문에 5월로 됨.
                                // 이를 방지하기 위해 1일을 기본 설정으로 둠.
                end.setFullYear(date.getFullYear());
                end.setMonth(date.getMonth());
                end.setDate(date.getDate());
                setSchedule({...schedule, start: date, end: end});
            } else if (type === END_TYPE) {
                const start = schedule.start;
                start.setDate(1);
                start.setFullYear(date.getFullYear());
                start.setMonth(date.getMonth());
                start.setDate(date.getDate());
                setSchedule({...schedule, start: start, end: date});
            }
        } else {
            if (type === START_TYPE) {
                if (date.getTime() > schedule.ranges.end.toDate().getTime()) {
                    const end = Moment(schedule.ranges.end).toDate();
                    end.setFullYear(date.getFullYear());
                    end.setMonth(date.getMonth());
                    end.setDate(date.getDate());
                    setSchedule({...schedule, startRecur: Moment(date).format(DATE_FORMAT_FOR_VALUE), endRecur: Moment(date).add(1, 'days').format(DATE_FORMAT_FOR_VALUE),  ranges: {start: Moment(date), end: Moment(end)}})
                } else {
                    setSchedule({...schedule, startRecur: Moment(date).format(DATE_FORMAT_FOR_VALUE), ranges: {...schedule.ranges, start: Moment(date)}})
                }
            } else if (type === END_TYPE) {
                if (date.getTime() < schedule.ranges.start.toDate().getTime()) {
                    const start = Moment(schedule.ranges.start).toDate();
                    start.setFullYear(date.getFullYear());
                    start.setMonth(date.getMonth());
                    start.setDate(date.getDate());
                    setSchedule({...schedule, startRecur: Moment(date).format(DATE_FORMAT_FOR_VALUE), endRecur: Moment(date).add(1, 'days').format(DATE_FORMAT_FOR_VALUE), ranges: {start: Moment(start), end: Moment(date)}})
                } else {
                    setSchedule({...schedule, endRecur: Moment(date).add(1, 'days').format(DATE_FORMAT_FOR_VALUE), ranges: {...schedule.ranges, end: Moment(date)}})
                }
            }
        }
    }

    const handleTime  = (event, time, type) => {
        let date;
        const {repeatType} = schedule;
        if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
            if (type === START_TYPE) {
                date = schedule.start;
            } else if (type === END_TYPE) {
                date = schedule.end;
            }
            const times = time.split(':');
            if (times !== undefined && times.length > 2) {
                date.setHours(times[0], times[1], times[2]);
                if (type === START_TYPE) {
                    if (date.getTime() > schedule.end.getTime()) {
                        toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                        return false;
                    } else {
                        setSchedule({...schedule, start: date});
                    }
                } else if (type === END_TYPE) {
                    if (date.getTime() < schedule.start.getTime()) {
                        toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                        return false;
                    } else {
                        setSchedule({...schedule, end: date});
                    }
                }
            }
        } else {
            const date = new Date(`${Moment().format(DATE_FORMAT_FOR_VALUE)} ${time}`);
            if (type === START_TYPE) {
                const end = new Date(`${Moment().format(DATE_FORMAT_FOR_VALUE)} ${schedule.endTime}`);
                if (date.getTime() > end.getTime()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                } else {
                    setSchedule({...schedule, startTime: time});
                }
            } else {
                const start = new Date(`${Moment().format(DATE_FORMAT_FOR_VALUE)} ${schedule.startTime}`);
                if (start.getTime() > date.getTime()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                } else {
                    setSchedule({...schedule, endTime: time});
                }
            }
        }
    }

    const handleNeverExpired = e => {

        let {repeatType} = schedule;
        const updateSchedule = schedule;
        if (!schedule.isInfinitePlay) {
            if (!schedule.isAllDayPlay) {
                if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                    updateSchedule.startRecur = Moment(schedule.start).format(DATE_FORMAT_FOR_VALUE);
                    updateSchedule.startTime = Moment(schedule.start).format(TIME_FORMAT_FOR_VALUE);
                    updateSchedule.endTime = Moment(schedule.end).format(TIME_FORMAT_FOR_VALUE);
                } else {
                    updateSchedule.startTime = Moment(schedule.ranges.start).format(TIME_FORMAT_FOR_VALUE);
                }
            } else {
                //24 hour
                if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                    updateSchedule.startTime = '00:00:00';
                    updateSchedule.endTime = '23:59:59';
                }
            }

            if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                if (schedule.start) {
                    updateSchedule.ranges = {start: Moment(schedule.start), end: Moment('2999-12-31 '+schedule.endTime, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`)};
                    updateSchedule.startRecur = Moment(schedule.start).format(DATE_FORMAT_FOR_VALUE);
                } else if (schedule.ranges && schedule.ranges.start) {
                    updateSchedule.ranges = {start: Moment(schedule.ranges.start), end: Moment('2999-12-31 '+schedule.endTime, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`)};                
                } else {
                    updateSchedule.ranges = {start: Moment(updateSchedule.startRecur +' '+updateSchedule.startTime), end: Moment('2999-12-31 '+schedule.endTime, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`)};
                }
            } else {
                if (schedule.ranges && schedule.ranges.start) {
                    updateSchedule.ranges = {start: Moment(schedule.ranges.start), end: Moment('2999-12-31 '+schedule.endTime, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`)};
                } else if (schedule.start) {
                    updateSchedule.ranges = {start: Moment(schedule.start), end: Moment('2999-12-31 '+schedule.endTime, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`)};
                } else {
                    updateSchedule.ranges = {start: Moment(updateSchedule.startRecur +' '+updateSchedule.startTime), end: Moment('2999-12-31 '+schedule.endTime, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`)};
                }
            }

            updateSchedule.endRecur = '3000-01-01';
            updateSchedule.groupId = schedule.scheduleId;
            updateSchedule.daysOfWeek = [0,1,2,3,4,5,6];
        } else {
            updateSchedule.endRecur = schedule.ranges.start.clone().add(1, 'days').format(DATE_FORMAT_FOR_VALUE);
            updateSchedule.ranges = {...updateSchedule.ranges, end: Moment(schedule.ranges.start)};
        }
        updateSchedule.repeatType = schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE ? CONTENT_SCHEDULE_REPEAT_TYPE_DAILY : schedule.repeatType;
        updateSchedule.isInfinitePlay = !schedule.isInfinitePlay;

        setSchedule({...schedule, ...updateSchedule});
    }

    const [beforeCheck24Time,setBeforeCheck24Time] = useState({startTime:'00:00:00', endTime:'23:59:59'});
    const [beforeCheck24,setBeforeCheck24] = useState({start:schedule.start,end:schedule.end});

    const check24Hours = e => {
        
        if (!schedule.isAllDayPlay) {
            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                
                const beforeStartValue = new Date(schedule.start);
                const beforeEndValue = new Date(schedule.end);
                setBeforeCheck24({start: beforeStartValue, end : beforeEndValue});

                const tempStart = schedule.start;                
                const start = new Date(tempStart.setHours(0, 0, 0));
                const tempEnd = schedule.end;
                const end = new Date(tempEnd.setHours(23,59,59));

                setSchedule({...schedule, isAllDayPlay: true, start: start, end: end});
            } else {
               const updateSchedule = {...schedule};
               setBeforeCheck24Time({startTime : updateSchedule.startTime, endTime: updateSchedule.endTime})
               setSchedule({...schedule, isAllDayPlay: true, startTime: '00:00:00', endTime:  '23:59:59'});
            }

        } else {
            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) { 
               setSchedule({...schedule, isAllDayPlay: false, start:beforeCheck24.start, end:beforeCheck24.end});
            } else {
               setSchedule({...schedule, isAllDayPlay: false, startTime:beforeCheck24Time.startTime, endTime:beforeCheck24Time.endTime});
            }
        }
    }

    const getDayNumber = value => {
        switch (value) {
            case 'MON':
                return 1;
            case 'TUE':
                return 2;
            case 'WED':
                return 3;
            case 'THU':
                return 4;
            case 'FRI':
                return 5;
            case 'SAT':
                return 6;
            case 'SUN':
                return 0;
        }
    }
    const onChangeReserveWeekly = e => {
        const value = e.target.getAttribute('data-name');
        if (e.target.checked) {
            setSchedule({...schedule,repeatedDayOfWeekList: schedule.repeatedDayOfWeekList.concat(value)})
        } else {
            setSchedule({...schedule,repeatedDayOfWeekList: schedule.repeatedDayOfWeekList.filter(day => day !== value)})
        }
    }

    const onChangeReserveMonthly = e => {
        const value = e.target.getAttribute('data-value');
        if (schedule.repeatedDateOfMonthList.find(day=> day === parseInt(value)) !== undefined) {
            setSchedule({...schedule, repeatedDateOfMonthList: schedule.repeatedDateOfMonthList.filter(day => day !== parseInt(value))})
        } else {
            setSchedule({...schedule, repeatedDateOfMonthList: schedule.repeatedDateOfMonthList.concat(parseInt(value))})
        }
    }

    const initScheduleForDaily = (scheduleInfo, repeatType, isHW = false) => {

        let {isInfinitePlay, isAllDayPlay} = schedule;
        if (isHW) {
            isInfinitePlay = true;
            scheduleInfo.endRecur = '3000-01-01';
            if (scheduleInfo.ranges) {
                scheduleInfo.ranges.end = Moment(`2999-12-31 ${schedule.endTime}`, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`);
            }
        }

        let start, end;

        if (scheduleInfo.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
            start = Moment(scheduleInfo.start);
            end = Moment(scheduleInfo.end);
            scheduleInfo.ranges = {start: start.clone(), end: end.clone()}
        } else {
            start = Moment(scheduleInfo.ranges.start);
            end = Moment(scheduleInfo.ranges.end);
        }
        
        if(isAllDayPlay) {
            start.set({hours: 0, minutes: 0, seconds: 0});
            end.set({hours: 23, minutes: 59, seconds: 59});
        }
        
        scheduleInfo.groupId = schedule.scheduleId;
        scheduleInfo.startTime = start.format(TIME_FORMAT_FOR_VALUE);
        scheduleInfo.endTime = end.format(TIME_FORMAT_FOR_VALUE);
        scheduleInfo.startRecur = start.format(DATE_FORMAT_FOR_VALUE);

        if (isInfinitePlay) {
            scheduleInfo.endRecur = scheduleInfo.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE ? '3000-01-01' : scheduleInfo.endRecur;
        } else {
            scheduleInfo.endRecur = scheduleInfo.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE ? end.clone().add(1, 'day').format(DATE_FORMAT_FOR_VALUE) : scheduleInfo.endRecur;
        }

        scheduleInfo.repeatedDateOfMonthList = [];
        scheduleInfo.repeatedDayOfWeekList = [];
        scheduleInfo.daysOfWeek = [0,1,2,3,4,5,6];
        if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY) {
            scheduleInfo.repeatedDayOfWeekList = ['MON','TUE','WED','THU','FRI','SAT','SUN'];
        }

        scheduleInfo.repeatType = repeatType;

        return scheduleInfo;
    }

    const handleRepeatType = (value) => {
        let height = 200;
        let updateSchedule = {}
        if (value === CONTENT_SCHEDULE_REPEAT_TYPE_DAILY) {
            updateSchedule = Object.assign({}, initScheduleForDaily(schedule, value));
        } else if (value === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY) {
            height = 230;
            if (schedule.contentType === CONTENT_SCHEDULE_INPUT_SOURCE_TYPE) {
                schedule.isInfinitePlay = true;
            }
            updateSchedule = Object.assign({}, initScheduleForDaily(schedule, value));
        } else if (value === CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY) {
            height = 340;
            updateSchedule = Object.assign({}, initScheduleForDaily(schedule, value));
        } else if (value === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
            updateSchedule = Object.assign({}, schedule);
            if (updateSchedule.isInfinitePlay) {
                updateSchedule.isInfinitePlay = false;
            }
            if (updateSchedule.repeatType !== CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                if (updateSchedule.isAllDayPlay) {
                    const start = Moment(`${updateSchedule.ranges.start.format(DATE_FORMAT_FOR_VALUE)} 00:00:00`, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`);
                    const end = Moment(`${updateSchedule.ranges.start.format(DATE_FORMAT_FOR_VALUE)} 23:59:59`, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`);
                    updateSchedule.start = start.toDate();
                    updateSchedule.end = end.toDate();
                } else {
                    const start = Moment(`${updateSchedule.ranges.start.format(DATE_FORMAT_FOR_VALUE)} ${updateSchedule.startTime}`, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`);
                    const end = Moment(`${updateSchedule.ranges.start.format(DATE_FORMAT_FOR_VALUE)} ${updateSchedule.endTime}`, `${DATE_FORMAT_FOR_VALUE} ${TIME_FORMAT_FOR_VALUE}`);
                    updateSchedule.start = start.toDate();
                    updateSchedule.end = end.toDate();
                }
            } else {
                if (updateSchedule.isInfinitePlay) {
                    updateSchedule.isInfinitePlay = false;
                }
            }
            updateSchedule.ranges = undefined;

            if (updateSchedule.startRecur !== undefined)  {
                updateSchedule.startRecur = undefined;
            }
            if (updateSchedule.endRecur !== undefined) {
                updateSchedule.endRecur = undefined;
            }
            if (updateSchedule.startTime !== undefined) {
                updateSchedule.startTime = undefined
            }
            if (updateSchedule.startTime !== undefined) {
                updateSchedule.startTime = undefined;
            }
            if (updateSchedule.endTime !== undefined) {
                updateSchedule.endTime = undefined;
            }
            if (updateSchedule.daysOfWeek !== undefined) {
                updateSchedule.daysOfWeek = undefined;
            }
            if (updateSchedule.groupId !== undefined) {
                updateSchedule.groupId = undefined;
            }
            updateSchedule.repeatType = value;
        }

        setStyle({...style, height: height});
        setSchedule({...schedule, ...updateSchedule});
    }

    const handleSafetyLock = e => {
        setSchedule({...schedule, isSafetyLockSet: !schedule.isSafetyLockSet})
    }

    const handleCifsSlideTime = (e, value) => {
        setSchedule({...schedule, cifsSlideTransitionTime : value })
    }

    const onUpdateSchedule = () => {
        const valid = () => {
            if (isSubFrames
                && (schedule.contentType === CONTENT_SCHEDULE_INPUT_SOURCE_TYPE
                    || schedule.contentType === CONTENT_SCHEDULE_RULESET_TYPE)) {
                toastr.error(t('TEXT_NOT_APPLY_P'));
                return false;
            }

            if (!isUndefined(schedule.contentId) && !isNil(schedule.contentId) &&
                !isUndefined(schedule.contentName) && !isNil(schedule.contentName) &&
                schedule.contentId !== '' && schedule.contentName !== '') {
            } else {
                toastr.error(t("MESSAGE_CONTENT_SELECT_A_CONTENT_P"));
                return false;
            }

            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY) {
                if(schedule.repeatedDayOfWeekList && schedule.repeatedDayOfWeekList.length < 1){
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }
            }
            else if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY) {
                if(!schedule.repeatedDateOfMonthList || schedule.repeatedDateOfMonthList < 1) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }

                if(!schedule.isInfinitePlay) {
                    const startDate = Moment(schedule.startRecur, DATE_FORMAT_FOR_VALUE);
                    const endDate = Moment(schedule.endRecur, DATE_FORMAT_FOR_VALUE);
                    
                    const availableDates = [];
                    let date = startDate.clone();
                    for(let i = 0; i < 31 && date.isBefore(endDate); i++) {
                        availableDates.push(date.date());
                        date.add(1, "day");
                    }

                    for(let i = 0; i < schedule.repeatedDateOfMonthList.length; i++) {
                        if(!availableDates.includes(schedule.repeatedDateOfMonthList[i])) {
                            toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                            return false;
                        }
                    }
                }
            }

            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                if (schedule.start.getTime() >= schedule.end.getTime()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }
            } else {
                const start = new Date(schedule.startRecur+' '+schedule.startTime);
                const end  = new Date(schedule.startRecur+' '+schedule.endTime);
                if (start.getTime() >= end.getTime()){
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }
            }
            return true;
        }
        if (valid()){
            if (schedule.contentType === CONTENT_SCHEDULE_INPUT_SOURCE_TYPE) {
                schedule.inputSource = schedule.contentId;
                schedule.scheduleType = schedule.inputSource === 'PANEL_OFF' ? '01' : '03'
            } else {
                schedule.inputSource = undefined;
                schedule.scheduleType = '00';
            }

            if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY) {
                const daysOfWeek = [];
                schedule.repeatedDayOfWeekList.map(
                    day => daysOfWeek.push(getDayNumber(day))
                )
                daysOfWeek.sort();
                schedule.daysOfWeek = daysOfWeek;
            } else if (schedule.repeatType !== CONTENT_SCHEDULE_REPEAT_TYPE_ONCE) {
                schedule.daysOfWeek = [0,1,2,3,4,5,6];
            }

            if(schedule.isInfinitePlay && schedule.startRecur === undefined) {
                schedule.startRecur = Moment(schedule.start).format(DATE_FORMAT_FOR_VALUE);
            }

            updateSchedule(schedule, mode);
        }
    }

    const videoWallOption = [
        {value: 'single', title: t("TEXT_PLAYER_P")},
        {value: 'vwl', title: t("COM_DID_RMS_DEVICE_VIDEO_WALL")}
    ];

    const handlePlayerMode = (value) => {
        setSchedule({...schedule, playerMode: value, contentType: '', contentId: '', contentName: ''})
    }

    useEffect(()=> {
        const {repeatType} = schedule;
        let height = 200;
        if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY) {
            height = 230;
        } else if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY) {
            height = 340;
        }
        setStyle({...style, height: height});
    }, [])

    useEffect(()=> {
        const {contentType, cifsSlideTransitionTime} = schedule;
        if (contentType === MEDIA_TYPE_CIFS || contentType === MEDIA_TYPE_FTP) {
            if (cifsSlideTransitionTime < 5) {
                setSchedule({...schedule, cifsSlideTransitionTime: 5});
            }
        }
    }, [schedule.contentType])

    const dialogProps ={title: t("TEXT_CREATE_SCHEDULE_P"), closeOnEscape : true, position: {x: -300, y: -400} , modal:true, onClose:()=> closePopup()};

    const getLeftButtons = useCallback(()=> {
        if (schedule.contentId !== null && schedule.contentId !== undefined && schedule.contentId !== '') {
            return [{id: 'DELETE_SCHEDULE', title: t("COM_BUTTON_DELETE"), onClick: ()=>onRemoveSchedule()}]
        }
        return [];
    }, [])

    const emptyContent = () => {
        setSchedule({
            ...schedule,
            contentId: '',
            contentName: '',
            contentType: ''
        })
    }

    const showDateInput  = useCallback(() => {
        const {contentType} = schedule;
        if (contentType === CONTENT_SCHEDULE_INPUT_SOURCE_TYPE && contents !== undefined && contents.isHW !== undefined && contents.isHW) {
            return false;
        }
        return true;
    }, [schedule.contentType])

    return (

        <MISDialog
            dialog={dialogProps}
            width={style.width}
            height={programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL ? style.height + 25 : style.height}
            ref={ref}
            buttons={{
                leftButtons: getLeftButtons(),
                rightButtons: [
                    {id: 'SAVE_SCHEDULE', title: t("COM_BUTTON_SAVE"), onClick: ()=>onUpdateSchedule()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> closePopup()}
                ]
            }}
            >
            {
                contentPopup &&
                    <ContentPopup
                        isVwlMode={schedule.playerMode !== undefined && schedule.playerMode === 'vwl'}
                        closeContentPopup={openContentPopup}
                        selectContent={selectContent}
                        deviceType={deviceType}
                        deviceTypeVersion={deviceTypeVersion}
                        programType={programType}
                        misOptionSchedule={misOptionSchedule}
                    />
            }

            <div className="popup_contents">
                <div className="detail_view">
                    <table>
                        <colgroup><col width="184px" /><col width="" /></colgroup>
                        <tbody>

                        {
                            programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL &&
                            <>
                                <tr className="contentTr">
                                    <th>{t("TEXT_CONTENT_P")}</th>
                                    <td>
                                        <div style={{display: 'block-inline'}}>
                                            <RadioGroup selects={videoWallOption}
                                                        onChange={(e, value) => handlePlayerMode(value)}
                                                        value={schedule.playerMode} multiLang={false}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="contentTr">
                                    <th></th>
                                    <td>
                                        <div style={{display:'block-inline'}}>
                                            <div className="" style={{float:'left'}}>
                                                <span id="selectContentInput">
                                                    <TextInput value={schedule.contentName} readOnly width={220} isEmptyButton={schedule.contentName===''?false:true} emptyText={emptyContent}/>
                                                </span>
                                            </div>
                                            <div style={{float:'left'}}>
                                                <button className="contentSelectSearchBtn" onClick={openContentPopup}></button>
                                            </div>
                                        </div>
                                        <span style={{display:'inline-block',marginLeft:7,marginTop:10}}>
                                            <Checkbox id={"scheduleContentLock"} name={t("BUTTON_CHECK_OUT")} onClick={handleSafetyLock} checked={schedule.isSafetyLockSet} readOnly/>
                                        </span>
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_VWL &&
                            <>
                                <tr className="contentTr">
                                    <th>{t("TEXT_CONTENT_P")}</th>
                                    <td>
                                        <div style={{display:'block-inline'}}>
                                            <div className="" style={{float:'left'}}>
                                                <span id="selectContentInput">
                                                    <TextInput value={schedule.contentName} readOnly width={252} isEmptyButton={schedule.contentName===''?false:true} emptyText={emptyContent}/>
                                                </span>
                                            </div>
                                            <div style={{float:'left'}}>
                                                <button className="contentSelectSearchBtn" onClick={openContentPopup}></button>
                                            </div>
                                        </div>
                                        <span style={{display:'inline-block',marginLeft:7,marginTop:10}}>
                                            <Checkbox id={"scheduleContentLock"} name={t("BUTTON_CHECK_OUT")} onClick={handleSafetyLock} checked={schedule.isSafetyLockSet} readOnly/>
                                        </span>

                                        {
                                            !isEmpty(schedule.contentType) && !isNil(schedule.contentType) && (schedule.contentType === MEDIA_TYPE_FTP || schedule.contentType === MEDIA_TYPE_CIFS) &&
                                            <div>
                                                <div style={{float: 'left', lineHeight: '25px'}}>{t("MIS_TEXT_FTP_SLIDE_TRANSITION_TIME_P")}</div>
                                                <div style={{float: 'left', marginLeft: 10}}><NumberInput min={5} max={999} value={schedule.cifsSlideTransitionTime} onChange={handleCifsSlideTime} /></div>
                                            </div>
                                        }

                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <th>{t("TEXT_REPEAT_P")}</th>
                            <td>
                                <Select title={t("TEXT_ONCE_P")} width={120} value={schedule.repeatType} onChange={(e, value)=>handleRepeatType(value)} selects={
                                    schedule.contentType !== undefined && schedule.contentType === CONTENT_SCHEDULE_INPUT_SOURCE_TYPE && (contents !== undefined && contents.isHW !== undefined && contents.isHW) ?
                                        [{id:'SCHEDULE_TYPE_WEEKLY', value:CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY, title: "TEXT_WEEKLY_P"}]:
                                        [
                                            {id:'SCHEDULE_TYPE_ONCE', value:CONTENT_SCHEDULE_REPEAT_TYPE_ONCE, title: "TEXT_ONCE_P"},
                                            {id:'SCHEDULE_TYPE_DAILY', value:CONTENT_SCHEDULE_REPEAT_TYPE_DAILY, title: "TEXT_DAILY_P"},
                                            {id:'SCHEDULE_TYPE_WEEKLY', value:CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY, title: "TEXT_WEEKLY_P"},
                                            {id:'SCHEDULE_TYPE_MONTHLY', value:CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY, title: "TEXT_MONTHLY_P"}]

                                } />

                                {
                                    schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY
                                    && <div style={{marginTop: 10}}><WeeklyCheckbox checked={schedule.repeatedDayOfWeekList} onClick={onChangeReserveWeekly}/></div>
                                }

                                {
                                    schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY
                                    && <div style={{marginTop: 10}}><MonthlyContainer checked={schedule.repeatedDateOfMonthList} onClick={onChangeReserveMonthly}/></div>
                                }
                            </td>
                        </tr>
                        {
                            showDateInput() &&
                            <tr>
                                <th>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                <td>
                                    <div style={{display: 'flex'}}>
                                        <DateInput width={120} dateFormat={dateFormat} date={getScheduleDate(START_TYPE)}
                                                   onChange={date => handleDate(date, START_TYPE)}
                                                   type={'schedule_date_warp'}
                                                   maxDate={schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE ? undefined : getScheduleDate(END_TYPE)}/>
                                        <span style={{width: 30, textAlign: 'center'}}>~</span>
                                        <DateInput width={120} dateFormat={dateFormat} date={getScheduleDate(END_TYPE)}
                                                   onChange={date => handleDate(date, END_TYPE)}
                                                   minDate={schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_ONCE ? undefined : getScheduleDate(START_TYPE)}
                                                   disabled={schedule.isInfinitePlay} type={'schedule_date_warp'}/>
                                        <span style={{marginLeft: 10}}>
                                        <Checkbox id={"neverExpired"} name={t("MIS_SID_20_NEVER_EXPIRE")}
                                                  onClick={handleNeverExpired} checked={schedule.isInfinitePlay}/>
                                    </span>
                                    </div>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                            <td>
                                <div style={{display: 'flex'}}>
                                    {/*<TimeInput width={98} showSeconds value={getScheduleTime(START_TYPE)} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(event, value, START_TYPE)} disabled={schedule.isAllDayPlay} timePicker/>*/}
                                    <TimePicker2 disabled={schedule.isAllDayPlay} time={getScheduleTime(START_TYPE)} onChange={(event, value)=>handleTime(event, value, START_TYPE)}/>
                                    <span style={{width: 30, textAlign: 'center'}}>~</span>
                                    <TimePicker2 disabled={schedule.isAllDayPlay} time={getScheduleTime(END_TYPE)} onChange={(event, value)=>handleTime(event, value, END_TYPE)}/>
                                    {/*<TimeInput width={98} showSeconds value={getScheduleTime(END_TYPE)} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(event, value, END_TYPE)} disabled={schedule.isAllDayPlay} timePicker/>*/}

                                    <span style={{marginLeft:10}}>
                                        <Checkbox id={"24hours"} name={t("COM_IDS_STR_24_HOURS")} onClick={check24Hours} checked={schedule.isAllDayPlay}/>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    )


}

export default SelectContentsPlaylistsPopup;