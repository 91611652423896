import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {scheduleAction} from "../../../actions";
import './SubmenuEventContainer.css';
import classNames from 'classnames';
import {toastr} from 'helper/toastrIntercept';

const SubmenuEventContainer = ({type}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const schedules = useSelector(state=>state.schedules.events.schedules);

    let conditionList = {};
    if (type === 'new') {
        conditionList = schedules.new.conditionList;
    } else {
        conditionList = schedules.edit.conditionList;
    }

    let currentIndex;
    if (type === 'new') {
        currentIndex = schedules.new.currentEventConditionIndex;
    } else {
        currentIndex = schedules.edit.currentEventConditionIndex;
    }


    const addEvent = type => {
        if (conditionList.length > 9) {
            toastr.error(t('COM_MIV_CAN_NOT_ADD_ANY_MORE'));
        } else {
            dispatch(scheduleAction.addEvent(type))
        }
    }

    const loadEvent = id => {
        dispatch(scheduleAction.loadEvent(type, id));
    }

    const removeEvent = (e, type, id) => {
        e.stopPropagation();
        if (conditionList.length > 1) {
            dispatch(scheduleAction.removeEvent(type, id));
        } else if (conditionList.length === 1) {
            toastr.error(t('COM_MIV_CAN_NOT_DELETE_ANY_MORE'));
        }
    }

    return (
        <div className={"submenu_event_schedule_container"}>
            <div className="lnb_buttonwrap" style={{height:38,position:'relative'}} >
                <button className="base noline float_l" style={{width: 206}} id="messageAddBtn" onClick={()=>addEvent(type)} >{t("MIS_TEXT_EVENT_NEW_EVENT_P")}</button>
            </div>
            <ul className={"lnb"}>
                {
                    conditionList !== undefined && conditionList.length > 0 && conditionList.map(
                        (condition, index) =>
                            <li key={index} onClick={()=>loadEvent(index)} className={classNames({'on' : currentIndex === index})}>
                                <a href="#" style={{backgroundImage: 'none'}} >{t("COM_IDS_EVENT_MAMAIN")}</a><button className="message_closebox" onClick={(e)=>removeEvent(e, type, index)} ></button>
                            </li>
                    )
                }

            </ul>
        </div>
    )
}
export default SubmenuEventContainer;