import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import SearchBar from "../../../components/search/SearchBar";
import Checkbox from "../../../components/checkbox/Checkbox";
import {useDispatch} from "react-redux";
import {menuAction, popupAction} from "../../../actions";
import {commonConstants, SCHEDULE_PAGE_SIZE_OPTIONS} from "../../../constants";
import {useTranslation} from 'react-i18next';
import DateToStr from "../../../components/utils/DateToStr";
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../../helper';
import {toastr} from 'helper/toastrIntercept';
import {DeviceGroups} from "../../../helper/schedule/scheduleUtils";
import {getErrorMessage} from "../../../helper/responseHandler";
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../../helper/tables";
import {snakeCase} from "lodash";
import {updateCache} from "../../../helper/cache/tableCache";
import {useMISOpt} from "../../../components/misopt";

const EventScheduleRecycleBin = (props) => {

    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('SCHEDULE'));

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const [style, setStyle] = useState({height: '500px'});

    const {page, pageSize, sorted} = filter;

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], searchText} = filter;
        setData({...data, loading: true});
        scheduleService.fetchEventScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            groupType: 'TRASH',
            searchText: searchText,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SCHEDULE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        });
    }

    const showRestoreEventPopup = () => {
        const programIds = getCheckedId();
        if (programIds.length > 0) {
            dispatch(popupAction.addPopup({
                type: commonConstants.COMMON_GROUP_POPUP,
                id: commonConstants.COMMON_GROUP_POPUP,
                mode: 'event_schedule',
                save: (groups)=>restoreEvent(groups, programIds),
                close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
            }));
        }
    }
    const restoreEvent = (groups, programIds) => {
        const groupId = groups[0].groupId;
        if (programIds.length > 0 && groupId !== undefined) {
            scheduleService.restoreEventSchedule(programIds, groupId)
                .then(
                    res=> {
                        if (res) {
                            toastr.success(t("TEXT_SUCCESS_P"))
                        }
                    }
                )
                .catch(res=> toastr.error(res))
                .finally(()=>{
                    dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
                    dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
                    dispatch(menuAction.reloadGroup('EVENT_SCHEDULE_BY_GROUP'));
                    setFilter({...filter, force: !filter.force});
                })
        }
    }

    const showDeleteEventPopup = () => {
        const programIds = getCheckedId();
        if (programIds.length > 0) {
            dispatch(popupAction.addPopup({
                type: commonConstants.COMMON_CONFIRM_POPUP,
                id: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_BUTTON_DELETE"),
                message: <>{t('MIS_SID_CBFEB_IF_DELETE_SELECTED_ITEMS_DATCANNOT_RESTORED')}<br/>{t('MIS_SID_CBFEB_SURE_WANT_DELETE_SELECTED_ITEMS')}</>,
                useMessageLine: false,
                height: 90,
                onClickYes: ()=>deleteEvent(),
                onClose: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
            }));
        }
    }

    const deleteEvent = () => {
        const programIds = getCheckedId();
        if (programIds.length > 0) {
            scheduleService.deleteEventScheduleByIds(programIds, false)
                .then(
                    res=> {
                        if (res) {
                            toastr.success(t("TEXT_SUCCESS_P"))
                        }
                    }
                )
                .catch(res=> toastr.error(res))
                .finally(()=>{
                    dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                    setFilter({...filter, force: !filter.force});
                })
        }
    }

    const showEmptyEventRecyclePopup = () => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("BUTTON_EMPTY_RECYCLE_BIN_P"),
            message: t("MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P"),
            onClickYes: ()=>emptyRecycle(),
            onClose: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));
    }

    const emptyRecycle = () => {
        scheduleService.emptyEventSchedule()
            .then(
                res=> {
                    if (res) {
                        toastr.success(t("TEXT_SUCCESS_P"))
                    }
                }
            )
            .catch(e=>toastr.error(getErrorMessage(e)))
            .finally(()=>{
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                setFilter({...filter, force: !filter.force});
            })
    }

    const onChangeEventName = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const getCheckedId = () => {
        return selected.current.map(s => items[s].programId);
    }

    const columns = useMemo(()=>[
        {
            accessor: "programId",
            show: false
        },
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'eventScheduleRecycleAll'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].programId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },

        {
            Header: t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME"),
            accessor: "scheduleName",
            width: 600
        },
        {
            Header: t("TABLE_ORGANIZATION_P"),
            accessor: "groupName",
            sortable: false,
            width: 150
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            Cell: ({value}) => <DateToStr date={value}/>

        }
    ], [items]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);


    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%', display:props.currContent === 'EVENT_SCHEDULE_RECYCLE_BIN' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"EVENT_SCHEDULE_RESTORE"} name={t("BUTTON_RESTORE_P")} disable={selectedCnt < 1} onClick={showRestoreEventPopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={showDeleteEventPopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_RECYCLE_BIN"} name={t("BUTTON_EMPTY_RECYCLE_BIN_P")} onClick={showEmptyEventRecyclePopup} authority={authority.CREATE || authority.MANAGE}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="eventSchedule" placeholder={t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME")} onClickSearch={onChangeEventName} />
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                columns={columns}
                style={style}
                usePagination={true}
                getTrGroupProps={getTrGroupPropsType2}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: props.divide
                }}
            />
        </div>
    )

}
export default EventScheduleRecycleBin;