import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Slider from "rc-slider";
import Checkbox from "../../../components/checkbox/Checkbox";
import {SearchButton} from "../../../components/button/SearchButton";
import {useDispatch, useSelector} from "react-redux";
import {scheduleAction} from "../../../actions";
import ContentPopup from "../../../components/popup/schedule/ContentPopup";
import TextInput from "../../../components/input/TextInput";
import ContentThumbnail from "../../../components/image/ContentThumbnail";
import Select from "../../../components/selectbox/Select";
import classNames from "classnames";
import TXTImage_6 from "../../../images/img/message_txt_img_6.png";
import TXTImage_7 from "../../../images/img/message_txt_img_7.png";
import TXTImage_8 from "../../../images/img/message_txt_img_8.png";
import './EventCondition.css'
import 'rc-slider/assets/index.css';
import {SketchPicker} from "react-color";
import {toastr} from 'helper/toastrIntercept';

const colorPickerStyle = {
    position: 'absolute',
    top: 115,
    left: 130,
    zIndex: 100
}

const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
}

const EditCondition = ({mode, id, index, condition, conditionType}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {events} = useSelector(state => ({
        events : state.schedules.events.condition[mode]
    }));

    const [contentPopup, setContentPopup] = useState({
        show: false
    });

    const getBool = () => {
        if (conditionType === 'BOOL' && index === 0) {
            return true;
        } else if (conditionType === 'BOOL' && index === 1) {
            return false;
        }
        return '';
    }

    const [eventCondition, setEventCondition] = useState(undefined);
    const [beforeBgColor, setBeforeBgColor] = useState('#FFFFFF');

    useEffect(()=> {
        if (condition) {            
            if(conditionType === 'NUMBER') {
                if(condition.compNumberSign === undefined || condition.compNumberSign === null || condition.compNumberSign === '') {                    
                    condition.compNumberSign =  'gt';
                }
            }            
            condition.compBoolean = getBool();
            condition.content = condition.contentId !== undefined && condition.contentId !== '' && condition.contentId !== null;
            condition.message = condition.messageText !== undefined && condition.messageText !== '';
            condition.channel = condition.channelNo !== undefined && condition.channelNo !== -1 ? true : false;
            
            setEventCondition(condition);
            setNumberTypeInput(condition.compSubNumberSign !== undefined && condition.compSubNumberSign !== '' && condition.compSubNumberSign !== null ? 2 : 1)
            if(condition.message) {
                initMessageWrap(condition);
            }
        }
    }, [])

    const [style, setStyle] = useState({
        messageWrap: {
            overflow:'hidden',
            height:30,
            lineHeight:'30px',
            width:170,
            marginTop:75,
            position:'absolute',
            zIndex:99,
            wordWrap: 'break-word',
            animationTimingFunction: 'linear'
        }
    });

    const [animationStyle, setAnimationStyle] = useState({
        animationDuration: undefined,
        animationName: undefined,
        animationIterationCount: undefined
    });
    
    const [picker, setPicker] = useState({
        fontColorPicker: {
            show : false
        },
        bgColorPicker: {
            show: false
        }
    });

    const [textOption, setTextOption] = useState({
        show: false
    });

    const [numberTypeInput, setNumberTypeInput]  = useState(1)

    const changeCheck = (target) => {
        switch (target) {
            case 'content':
                if (eventCondition.content) {
                    //setEventCondition({...eventCondition, contentId: '', contentPlaylistName: ''});
                    setEventCondition({...eventCondition, content: false});
                } else {
                    //setEventCondition({...eventCondition, channelNo : -1});
                    setEventCondition({...eventCondition, content: true, channel: false});
                }

                break;
            case 'message':
                setEventCondition({...eventCondition, message: !eventCondition.message});
                break;
            case 'channel':
                if (eventCondition.channel) {
                    //setEventCondition({...eventCondition, channelNo : -1});
                    setEventCondition({...eventCondition, channel: false});
                } else {
                    //setEventCondition({...eventCondition, contentId: '', contentPlaylistName: ''});
                    setEventCondition({...eventCondition, channel: true, content :eventCondition.content ? false : eventCondition.content});
                }


                break;
        }
    }

    const updateCompareString = e => {
        const value = e.target.value;
        setEventCondition({...eventCondition, compString: value});
    }

    const removeContent = () => {
        setEventCondition({...eventCondition, contentPlaylistName: '', contentId: '', contentFileId: '', thumbFileId: ''});
    }

    const updateMessageText = e => {
        const value = e.target.value;
        setEventCondition({...eventCondition, messageText: value});
    }

    const handleChannelNo = e => {
        const channelNo = e.target.value;
        if(channelNo == ''){
            setEventCondition({...eventCondition, channelNo: ''});
            return;
        }
        if (Number.isInteger(parseInt(channelNo, 10))) {
            if (channelNo > -1 && channelNo < 1000) {
                setEventCondition({...eventCondition, channelNo: parseInt(channelNo, 10)});
            } else {
                toastr.error(t("MIS_MESSAGE_CHANNEL_INVALID_CHANNEL_NO_P"))
            }
        } else {
            toastr.error(t("MIS_MESSAGE_CHANNEL_INVALID_CHANNEL_NO_P"))
        }

    }

    const onBlurChannelNo = e => {
        if(e.target.value == ""){
            setEventCondition({...eventCondition, channelNo: 1});
        }
    }

    const removeCondition = id => {
        dispatch(scheduleAction.removeEventCondition(mode, id))
    }

    const updateEventCondition = (updateEvent) => {
        dispatch(scheduleAction.updateEventCondition(mode, updateEvent))
    }

    useEffect(()=>{
        //events.eventConditions[id] = eventCondition;

        if (eventCondition !== undefined) {
            const updateEvents = events.eventConditions.map(
                condition => condition.eventConditionId === eventCondition.eventConditionId ? {...condition, eventCondition} : condition
            )
            updateEventCondition(updateEvents);
        }


    }, [eventCondition])

    const openContentPopup = () => {
        if (eventCondition.content) {
            setContentPopup({show: !contentPopup.show});
        }
    }

    const updateContent = (contentId, contentName, thumbnail) => {
        setEventCondition({...eventCondition, contentPlaylistName: contentName, contentId: contentId, thumbFileId: thumbnail});
        openContentPopup();
    }

    const getStrNumberType = (value, size) => {
        switch (value) {
            case 'gt':
                return t('MIS_MIX_TEXT_EVENT_SIGN_GT_P').replace('<<A>>', size);
            case 'ge':
                return t('MIS_MIX_TEXT_EVENT_SIGN_GE_P').replace('<<A>>', size);
            case 'le':
                return t('MIS_MIX_TEXT_EVENT_SIGN_LE_P').replace('<<A>>', size);
            case 'lt':
                return t('MIS_MIX_TEXT_EVENT_SIGN_LT_P').replace('<<A>>', size);
            case 'eq':
                return t('MIS_MIX_TEXT_EVENT_SIGN_EQ_P').replace('<<A>>', size);
        }
    }

    const convertSpeedTextToNumber = (value) => {
        let speed = 20;
        switch(value) {
            case 'SLOW':
                speed = 10;
                break;
            case 'NORMAL':
                speed = 20;
                break;
            case 'FAST':
                speed = 30;
                break;
        }
        return speed
    }

    const convertSpeedTextToDuration = (value) => {
        let speed = convertSpeedTextToNumber(value)
        let animationDuration
        switch (speed) {
            case 30:
                animationDuration = '3s';
                break;
            case 20:
                animationDuration = '10s';
                break;
            case 10:
                animationDuration = '20s';
                break;
        }
        return animationDuration;
    }

    const handleNumberTypeMessage = (index, value) => {
        let {compNumberSign, compSubNumberSign, compSubNumberValue} = eventCondition;
        if (index === 1) {
            compNumberSign = value;
            setNumberTypeInput(1);
            compSubNumberSign = '';
            compSubNumberValue = '';
        } else if (index === 2) {
            compSubNumberSign = value;
        }
        let str = getStrNumberType(compNumberSign, eventCondition.compNumberValue !== undefined ? eventCondition.compNumberValue: '0');
        if (compSubNumberSign !== '') {
            str += ', '+getStrNumberType(compSubNumberSign, eventCondition.compSubNumberValue !== undefined ? eventCondition.compSubNumberValue : '0')
        }
        setEventCondition({...eventCondition, compString: str, compNumberSign: compNumberSign, compSubNumberSign: compSubNumberSign, compSubNumberValue: compSubNumberValue});
    }

    const handleInputNumbers = (e, index) => {
        let {compNumberValue, compSubNumberValue, compNumberSign, compSubNumberSign} = eventCondition;
        const value = e.target.value;
        if (index === 1) {
            compNumberValue = parseInt(value, 10);
        } else if (index === 2) {
            compSubNumberValue = parseInt(value, 10);
        }
        let str = '';
        if (index > 0) {
            str += getStrNumberType(compNumberSign, isNaN(compNumberValue) ? '' : compNumberValue)
            if (index < 2 && compSubNumberValue !== undefined && compSubNumberValue > 0) {
                str += ', '+getStrNumberType(compSubNumberSign, compSubNumberValue)
            }
        }
        if (index > 1) {
            str += ', '+getStrNumberType(compSubNumberSign, isNaN(compSubNumberValue) ? '':compSubNumberValue)
        }
        setEventCondition({...eventCondition, compNumberValue: isNaN(compNumberValue) ? '' : compNumberValue, compSubNumberValue: isNaN(compSubNumberValue) ? '':compSubNumberValue, compString: str});
    }
    const addNumberTypeInput = () => {
        let {compNumberValue, compSubNumberValue, compNumberSign, compSubNumberSign} = eventCondition;
        const checkSub = numberTypeInput === 1 ? true : false;
        if (numberTypeInput === 1) {
            setNumberTypeInput(2);
            if (compNumberSign === 'gt' || compNumberSign === 'ge') {
                compSubNumberSign = 'lt';
            } else if (compNumberSign === 'lt' || compNumberSign === 'le') {
                compSubNumberSign = 'gt';
            }

            compSubNumberValue = '';
        } else if (numberTypeInput === 2) {
            setNumberTypeInput(1);
            compSubNumberSign = '';
            compSubNumberValue = '';
        }
        let str = getStrNumberType(compNumberSign, compNumberValue)
        if (checkSub) {
            str += ', '+getStrNumberType(compSubNumberSign, compSubNumberValue)
        }
        setEventCondition({...eventCondition, compSubNumberSign: compSubNumberSign, compSubNumberValue: compSubNumberValue, compString: str});
    }

    const showTextOption = () => {
        if (eventCondition.message) {
            setTextOption({show : !textOption.show});
        }
    }

    const handleTextOption = (type) => {
        let {fontWeight, fontStyle, textDecoration} = style.messageWrap;
        const {isBold, isItalic, hasUnderline} = eventCondition;
        if (type === 'bold') {
            setEventCondition({...eventCondition, isBold: !isBold});
            if (!isBold) {
                fontWeight = 'bold'
            } else {
                fontWeight = undefined;
            }
        } else if (type === 'italic') {
            setEventCondition({...eventCondition, isItalic: !isItalic});
            if (!isItalic) {
                fontStyle = 'italic'
            } else {
                fontStyle = undefined;
            }
        } else if (type === 'underLine') {
            setEventCondition({...eventCondition, hasUnderline: !hasUnderline});
            if (!hasUnderline) {
                textDecoration = 'underline'
            } else {
                textDecoration = undefined;
            }
        }
        setStyle({...style, messageWrap: {...style.messageWrap, fontWeight: fontWeight,fontStyle: fontStyle, textDecoration: textDecoration}})
    }

    const handleColorPicker = (type) => {
        if (type === 'color') {
            const {show} = picker.fontColorPicker.show;
            setPicker({...picker, fontColorPicker: {show : !show}});
        } else {
            const {show} = picker.bgColorPicker.show;
            setPicker({...picker, bgColorPicker: {show : !show}});
        }
    }

    const handleColorPickerClose = (type) => {
        if (type === 'color') {
            setPicker({...picker, fontColorPicker: {show : false}});
        } else {
            setPicker({...picker, bgColorPicker: {show : false }});
        }
    }

    const handleFontColorPicker = (color) => {
        setEventCondition({...eventCondition, color: color.hex});
        setStyle({...style, messageWrap: {...style.messageWrap, color: color.hex}});
    }

    const handleBgColorPicker = (color) => {
        setEventCondition({...eventCondition, bgColor: color.hex})
        setStyle({...style, messageWrap: {...style.messageWrap, backgroundColor: color.hex}});
    }

    const handleTextSpeed = (value) => {
        let speed = 'NORMAL';
        switch(value) {
            case 10:
                speed = 'SLOW';
                break;
            case 20:
                speed = 'NORMAL';
                break;
            case 30:
                speed = 'FAST';
                break;
        }
        setEventCondition({...eventCondition, speed : speed})

        let animationDuration = '10s';
        switch (value) {
            case 30:
                animationDuration = '3s';
                break;
            case 20:
                animationDuration = '10s';
                break;
            case 10:
                animationDuration = '20s';
                break;
        }
        setAnimationStyle({...animationStyle, animationDuration: animationDuration});
    }

    const handleMessageDirection = (e, value) => {
        const {position} = eventCondition;
        setEventCondition({...eventCondition, direction: value})
        const {animationDuration = '10s'} = animationStyle;
        switch (value) {
            case 'NONE':
                setAnimationStyle({...animationStyle, animationDuration: undefined, animationName: undefined, animationIterationCount: undefined});
                break;
            case 'LEFT':
                setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: 'slideLeft', animationIterationCount: 'infinite'});
                break;
            case 'RIGHT':
                setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: 'slideRight', animationIterationCount: 'infinite'});
                break;
            case 'UP':
                if (position === 'LEFT' || position === 'RIGHT') {
                    setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: 'slideTopLeftRight', animationIterationCount: 'infinite'});
                } else {
                    setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: 'slideTop', animationIterationCount: 'infinite'});
                }
                break;
            case 'DOWN':
                if (position === 'LEFT' || position === 'RIGHT') {
                    setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: 'slideBottomLeftRight', animationIterationCount: 'infinite'});
                } else {
                    setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: 'slideBottom', animationIterationCount: 'infinite'});
                }
                break;
        }
        
    }

    const handleMessagePosition = (e, value) => {
        setEventCondition({...eventCondition, position: value})
        const messageWrap = {...style.messageWrap, width:170, wordWrap: 'none', height:30, left : 'auto', right : 'auto', top : 'auto', bottom : 'auto', marginTop : 0};
        switch (value) {
            case 'MIDDLE':
                setStyle({...style, messageWrap: {...messageWrap, marginTop: 75}})
                break;
            case 'TOP':
                setStyle({...style, messageWrap: {...messageWrap, marginTop: 0}})
                break;
            case 'BOTTOM':
                setStyle({...style, messageWrap: {...messageWrap, marginTop: 145}})
                break;
            case 'LEFT':
                setStyle({...style, messageWrap: {...messageWrap, height: 176, width: 10, wordWrap: 'break-word', left: 3}})
                break;
            case 'RIGHT':
                setStyle({...style, messageWrap: {...messageWrap, height: 176, width: 10, wordWrap: 'break-word', right: 3}})
                break;
        }
    }

    const handleTextFont = (e, value) => {
        setEventCondition({...eventCondition, font: value})
        setStyle({...style, messageWrap: {...style.messageWrap, fontFamily: value}})
    }

    const handleTextSize = (e, value) => {
        setEventCondition({...eventCondition, size: value})
        setStyle({...style, messageWrap: {...style.messageWrap, fontSize: value}})
    }

    const initMessageWrap = (eventCondition) =>{

        let fontWeight, fontStyle, textDecoration, animationDuration, animationName, animationIterationCount;
        if (eventCondition.isBold) {
            fontWeight = 'bold'
        } else {
            fontWeight = undefined;
        }
        if (eventCondition.isItalic) {
            fontStyle = 'italic'
        } else {
            fontStyle = undefined;
        }
        if (eventCondition.hasUnderline) {
            textDecoration = 'underline'
        } else {
            textDecoration = undefined;
        }

        animationDuration= convertSpeedTextToDuration(eventCondition.speed);

        switch (eventCondition.direction) {
            case 'NONE':
               animationDuration= undefined;
               animationName= undefined;
               animationIterationCount = undefined;
                break;
            case 'LEFT':
                animationDuration= animationDuration;
                animationName= 'slideLeft';
                animationIterationCount= 'infinite';
                break;
            case 'RIGHT':
                animationDuration= animationDuration; animationName = 'slideRight';
                animationIterationCount= 'infinite';
                break;
            case 'UP':
                animationDuration= animationDuration;
                animationIterationCount= 'infinite';
                if (eventCondition.position === 'LEFT' || eventCondition.position === 'RIGHT') {
                    animationName= 'slideTopLeftRight';
                } else {
                    animationName= 'slideTop';
                }
                break;
            case 'DOWN':
                animationDuration= animationDuration;
                animationIterationCount= 'infinite'
                if (eventCondition.position === 'LEFT' || eventCondition.position === 'RIGHT') {
                    animationName= 'slideBottomLeftRight';
                } else {
                    animationName= 'slideBottom';
                }
                break;
        }

        //setStyle({...style, messageWrap : {...style.messageWrap, color: eventCondition.color, backgroundColor: eventCondition.bgColor, fontFamily: eventCondition.font, fontSize: eventCondition.size ,fontWeight: fontWeight,fontStyle: fontStyle,textDecoration: textDecoration, animationDuration: animationDuration, animationName: animationName, animationIterationCount: animationIterationCount}});
        const tempStyle = {...style.messageWrap, color: eventCondition.color, backgroundColor: eventCondition.bgColor, fontFamily: eventCondition.font, fontSize: eventCondition.size ,fontWeight: fontWeight,fontStyle: fontStyle,textDecoration: textDecoration}

        switch (eventCondition.position) {
            case 'MIDDLE':
                setStyle({...style, messageWrap : {...tempStyle, marginTop: 75}});
                break;
            case 'TOP':
                setStyle({...style, messageWrap : {...tempStyle,marginTop: 0}});
                break;
            case 'BOTTOM':
                setStyle({...style, messageWrap : {...tempStyle,marginTop: 145}});
                break;
            case 'LEFT':
                setStyle({...style, messageWrap : {...tempStyle,height: 176, width: 10, wordWrap: 'break-word', left: 3}});
                break;
            case 'RIGHT':
                setStyle({...style, messageWrap : {...tempStyle, height: 176, width: 10, wordWrap: 'break-word', right: 3}});
                break;
        }

        setAnimationStyle({...animationStyle, animationDuration: animationDuration, animationName: animationName, animationIterationCount: animationIterationCount});
    }

    useEffect(()=> {
        const updateEvents = Object.assign({}, events);
        updateEvents.eventConditions = updateEvents.eventConditions.map(
            condition => condition.eventConditionId === id ? {...condition, ...eventCondition} : condition
        )
        dispatch(scheduleAction.updateEventCondition(mode, updateEvents))
    }, [eventCondition])

    

    return (
        <div className="event_condition_wrap">
            {
                contentPopup.show && <ContentPopup mode={"event_condition"} closeContentPopup={()=>openContentPopup()} selectContent={(contentId, contentName, thumbnail)=>updateContent(contentId, contentName, thumbnail)} deviceType={'SPLAYER'} deviceTypeVersion={2}/>
            }
            {
                eventCondition !== undefined &&
                    <>
                        {
                            conditionType === 'BOOL' && id === 1 ? '' :
                                <h3>
                                    {t("MIS_TEXT_EVENT_CONDITION_P")}
                                    <button  className="message_closebox" onClick={()=>removeCondition(id)} data-id={id}>
                                        <span className="close"></span>
                                    </button>
                                </h3>
                        }

                        <div style={{width:800,position:'relative'}}>
                            <div>
                                <table>
                                    <colgroup>
                                        <col width="202px"/>
                                        <col width=""/>
                                        <col width=""/>
                                    </colgroup>
                                    <tbody>

                                    <tr>
                                        <td rowSpan="4">
                                            <div className={'thumbnail_box'} style={{border:'1px solid #6c6c6c',width:172,height:172,position:'relative',overflow:'hidden'}}>
                                                <div className="eventEditerbox" style={eventCondition.message ?  style.messageWrap : {}}>
                                                    <div className="textWrap" style={eventCondition.message ? animationStyle : {}}>
                                                        {
                                                            eventCondition.message ? eventCondition.messageText : ''
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    eventCondition !== undefined && eventCondition.thumbFileId !== undefined && eventCondition.thumbFileId !== '' && eventCondition.content &&
                                                    <ContentThumbnail id={eventCondition.thumbFileId} width={172}/>
                                                }
                                            </div>
                                        </td>
                                        {
                                            conditionType === 'TEXT' &&
                                            <td colSpan="2">
                                                <TextInput maxLength={90} width={400} value={eventCondition.compString} onChange={updateCompareString}/>
                                                <span className="space explain">{t("MIS_MESSAGE_EVENT_ENTER_WORD_P")}</span>
                                            </td>

                                        }
                                        {
                                            conditionType === 'NUMBER' &&
                                            <td colSpan="2">
                                                <div>
                                                    <TextInput maxLength={90} width={400} value={eventCondition.compString} disabled/>
                                                    {
                                                        numberTypeInput > 0 &&
                                                        <div style={{display: 'flex', marginTop: 10}}>
                                                            <TextInput width={80} onChange={(e)=>handleInputNumbers(e, 1)} value={eventCondition.compNumberValue} maxLength={7}/>
                                                            <span style={{marginLeft: 30}}/>
                                                            <Select selects={[
                                                                {title: t('MIS_MIX_TEXT_EVENT_SIGN_GT_P').replace('<<A>>', ''), value: 'gt'},
                                                                {title: t('MIS_MIX_TEXT_EVENT_SIGN_GE_P').replace('<<A>>', ''), value: 'ge'},
                                                                {title: t('MIS_MIX_TEXT_EVENT_SIGN_LE_P').replace('<<A>>', ''), value: 'le'},
                                                                {title: t('MIS_MIX_TEXT_EVENT_SIGN_LT_P').replace('<<A>>', ''), value: 'lt'},
                                                                {title: t('MIS_MIX_TEXT_EVENT_SIGN_EQ_P').replace('<<A>>', ''), value: 'eq'}
                                                            ]} onChange={(e, value)=>handleNumberTypeMessage(1, value)} value={eventCondition.compNumberSign} width={200} multiLang={false}/>

                                                            {
                                                                eventCondition.compNumberSign !== 'eq' &&
                                                                    <span style={{marginLeft:20,marginRight:20}}>
                                                                        <button className="base" style={{width: 30}} onClick={addNumberTypeInput}>{numberTypeInput === 1 ? '+' : '-'}</button>
                                                                    </span>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        numberTypeInput > 1 &&
                                                        <div style={{display: 'flex', marginTop: 10}}>
                                                        {
                                                            (eventCondition.compNumberSign === 'gt' || eventCondition.compNumberSign === 'ge') &&
                                                                <>
                                                                    <TextInput width={80} onChange={(e)=>handleInputNumbers(e, 2)} value={eventCondition.compSubNumberValue} maxLength={7}/>
                                                                    <span style={{marginLeft: 30}}/>
                                                                    <Select selects={[
                                                                        {title: t('MIS_MIX_TEXT_EVENT_SIGN_LT_P').replace('<<A>>', ''), value: 'lt'},
                                                                        {title: t('MIS_MIX_TEXT_EVENT_SIGN_LE_P').replace('<<A>>', ''), value: 'le'},
                                                                    ]} onChange={(e, value)=>handleNumberTypeMessage(2, value)} value={eventCondition.compSubNumberSign} width={200} multiLang={false}/>
                                                                </>
                                                        }
                                                        {
                                                            (eventCondition.compNumberSign === 'le' || eventCondition.compNumberSign === 'lt') &&
                                                                <>
                                                                    <TextInput width={80} onChange={(e)=>handleInputNumbers(e, 2)} value={eventCondition.compSubNumberValue} maxLength={7}/>
                                                                    <span style={{marginLeft: 30}}/>
                                                                    <Select selects={[
                                                                        {title: t('MIS_MIX_TEXT_EVENT_SIGN_GT_P').replace('<<A>>', ''), value: 'gt'},
                                                                        {title: t('MIS_MIX_TEXT_EVENT_SIGN_GE_P').replace('<<A>>', ''), value: 'ge'},
                                                                    ]} onChange={(e, value)=>handleNumberTypeMessage(2, value)} value={eventCondition.compSubNumberSign} width={200} multiLang={false}/>
                                                                </>
                                                        }
                                                        </div>

                                                    }

                                                </div>
                                            </td>
                                        }
                                        {
                                            conditionType === 'BOOL' &&
                                            <td colSpan="2">
                                                <TextInput maxLength={90} width={400} value={eventCondition.compBoolean ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P")} disabled/>
                                            </td>

                                        }
                                    </tr>

                                    <tr style={{marginTop: 10}}>
                                        <td>
                                            <Checkbox id={"EVENT_CONDITION_CONTENT_CHK_"+id} name={t("TEXT_CONTENT_P")} onClick={()=>changeCheck('content')} checked={eventCondition.content}/>
                                        </td>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                                <SearchButton onClick={()=>openContentPopup()}/>
                                                <div className="tag_input">
                                        <span>
                                            <input type="text" className="contentName" value={eventCondition.contentPlaylistName} readOnly/>
                                            <button onClick={removeContent}></button>
                                        </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Checkbox id={"EVENT_CONDITION_MESSAGE_CHK_"+id} name={t("TEXT_MESSAGE_P")} onClick={()=>changeCheck('message')} checked={eventCondition.message}/>
                                        </td>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                                <TextInput  maxLength={200} width={237} value={eventCondition.messageText} disabled={!eventCondition.message} onChange={updateMessageText}/>
                                                <span style={{marginLeft: 10}}>
                                        <button className="circle_btn light_gray text mr8" onClick={showTextOption}>
                                            <span className=""></span>
                                        </button>
                                    </span>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Checkbox id={"EVENT_CONDITION_CHANNEL_CHK_"+id} name={t("COM_TEXT_CHANNEL_P")} onClick={()=>changeCheck('channel')} checked={eventCondition.channel}/>
                                        </td>
                                        <td>
                                            <TextInput width={237} value={eventCondition.channelNo !== -1 ? eventCondition.channelNo : ''} disabled={!eventCondition.channel} onChange={handleChannelNo} onBlur={onBlurChannelNo}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div  style={{position:'absolute',left:800,width:700,top:0, display: textOption.show ? '' : 'none'}}>
                                    <div className="contents height100p" style={{background:'#f9fafb',padding:0}}>
                                        <div className="schedule_message" style={{height:200,border:'1px solid #e7e7e7'}}>
                                            <div>
                                                <div className="message_editerbox" style={{height:220,overflow:'hidden',overflowY:'auto'}}>
                                                    <div className="message_wrap" style={{width:700}}>
                                                        <div className="message_editer clearfix" >
                                                            <Select
                                                                id={"EVENT_CONDITION_FONT"}
                                                                value={eventCondition.font}
                                                                multiLang={false}
                                                                selects={[
                                                                    {title: 'Arial', value: 'Arial'},
                                                                    {title: 'Courier', value: 'Courier'},
                                                                    {title: 'Tahoma', value: 'Tahoma'},
                                                                    {title: 'Verdana', value: 'Verdana'},
                                                                ]}
                                                                width={100}
                                                                onChange={handleTextFont}
                                                            />

                                                            <Select
                                                                id={"EVENT_CONDITION_TEXT_SIZE"}
                                                                value={eventCondition.size}
                                                                selects={[
                                                                    {title: "DID_ADMIN_URGENT_SMALL", value: 'SMALL'},
                                                                    {title: "COM_IDS_TXT_DIGITALNR_MEDIUM", value: 'MEDIUM'},
                                                                    {title: "DID_ADMIN_URGENT_LARGE", value: 'LARGE'}
                                                                ]}
                                                                width={100}
                                                                onChange={handleTextSize}
                                                            />

                                                            <div className={"float_l fontColorPicker"}>

                                                                <div className="sp-replacer sp-light eventBgColor" onClick={()=>handleColorPicker('color')}>
                                                                    <div className="sp-preview">
                                                                        <div className="sp-preview-inner" style={{backgroundColor: eventCondition.color}}></div>
                                                                    </div>
                                                                    <div className="sp-dd">▼</div>
                                                                </div>
                                                                { picker.fontColorPicker.show ?
                                                                    <div className={'color_picker_wrap'} style={colorPickerStyle}>
                                                                        <div style={ cover } onClick={ ()=>handleColorPickerClose('color') }/>
                                                                        <SketchPicker color={eventCondition.color} onChange={(color)=>handleFontColorPicker(color)} disableAlpha={true} />
                                                                    </div> : null }
                                                            </div>

                                                            <button className={classNames("base", "un_radius", "mr3", "float_l", {'on': eventCondition.isBold})} onClick={()=>handleTextOption('bold')} >
                                                                <img src={TXTImage_6} />
                                                            </button>
                                                            <button className={classNames("base", "un_radius", "mr3", "float_l", {'on': eventCondition.isItalic})} onClick={()=>handleTextOption('italic')}>
                                                                <img src={TXTImage_7} />
                                                            </button>
                                                            <button className={classNames("base", "un_radius", "mr3", "float_l", {'on': eventCondition.hasUnderline})} onClick={()=>handleTextOption('underLine')}>
                                                                <img src={TXTImage_8} />
                                                            </button>

                                                            <div className={"float_l bgColorPicker"}>
                                                                <span>{t("COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR")}</span>
                                                                <div className="sp-replacer sp-light eventBgColor mr3" onClick={()=>handleColorPicker('bgColor')}>
                                                                    <div className="sp-preview">
                                                                        <div className="sp-preview-inner" style={{backgroundColor: eventCondition.bgColor}}></div>
                                                                    </div>
                                                                    <div className="sp-dd">▼</div>
                                                                </div>
                                                                <Checkbox id={"EVENT_CONDITION_MESSAGE_BGCOLOR_CHK_"+id} name={t("COM_SID_TRANSPARENT")} checked={eventCondition.bgColor === ''} onClick={e => {
                                                                    if(e.target.checked) {
                                                                        setBeforeBgColor(eventCondition.bgColor);
                                                                        setEventCondition({...eventCondition, bgColor: ''});
                                                                        handleBgColorPicker({hex: ''});
                                                                    }
                                                                    else {
                                                                        setEventCondition({...eventCondition, bgColor: beforeBgColor});
                                                                        handleBgColorPicker({hex: beforeBgColor});
                                                                    }
                                                                }} />
                                                                { picker.bgColorPicker.show ?
                                                                    <div className={'color_picker_wrap'} style={colorPickerStyle}>
                                                                        <div style={ cover } onClick={ ()=>handleColorPickerClose('bgColor')}/>
                                                                        <SketchPicker color={eventCondition.bgColor} onChange={(color)=>handleBgColorPicker(color)} disableAlpha={true} />
                                                                    </div> : null }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="message_detail_setting">
                                                        <table>
                                                            <colgroup>
                                                                <col width="150px"/>
                                                                <col width=""/>
                                                            </colgroup>
                                                            <tbody>
                                                            <tr>
                                                                <th colSpan="4" style={{paddingTop:5}}>
                                                        <span className="warring" style={{width:650,height:20,whiteSpace:'initial'}}>
                                                            {t("MIS_MESSAGE_SCHEDULE_MSG_LOCATION_CONSTRAINTS_P")}
                                                        </span>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div style={{display: 'flex'}}>

                                                                        <div style={{position:'relative'}} style={{width: 220}}>
                                                                            <span className="space float_l mr15" style={{paddingLeft: 10}}>{t("TEXT_DIRECTION_P")}</span>
                                                                            <Select classname={"float_l"} width={150} value={eventCondition.direction} selects={[
                                                                                {id: 'message_flow_effect_none', value: 'NONE', title: "TEXT_NONE_P"},
                                                                                {id: 'message_flow_effect_up', value: 'UP', title: "TEXT_SHIFT_TO_THE_TOP_P"},
                                                                                {id: 'message_flow_effect_down', value: 'DOWN', title: "TEXT_SHIFT_TO_THE_BOTTOM_P"},
                                                                                {id: 'message_flow_effect_left', value: 'LEFT', title: "TEXT_SHIFT_TO_THE_LEFT_P"},
                                                                                {id: 'message_flow_effect_right', value: 'RIGHT', title: "TEXT_SHIFT_TO_THE_RIGHT_P"},
                                                                            ]}
                                                                                    onChange={handleMessageDirection}
                                                                            />
                                                                        </div>

                                                                        <div style={{position:'relative'}} style={{width: 220}}>
                                                                            <span className="space float_l mr15" style={{paddingLeft: 10}}>{t("TEXT_POSITION_P")}</span>
                                                                            <Select
                                                                                value={eventCondition.position}
                                                                                width={150}
                                                                                selects={[
                                                                                    {title: t("COM_DID_ADMIN_URGENT_UPPER"), value: 'TOP'},
                                                                                    {title: t("COM_TEXT_MIDDLE_P"), value: 'MIDDLE'},
                                                                                    {title: t("TEXT_BOTTOM_P"), value: 'BOTTOM'},
                                                                                    {title: t("TEXT_LEFT_P"), value: 'LEFT'},
                                                                                    {title: t("TEXT_RIGHT_P"), value: 'RIGHT'}
                                                                                ]}
                                                                                onChange={handleMessagePosition}
                                                                            />
                                                                        </div>



                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div style={{position:'inline-block', display: 'flex'}}>
                                                                        <span className="space mr15" style={{paddingLeft: 10}}>{t("COM_DID_ADMIN_URGENT_SPEED")}</span>
                                                                        <div className="" style={{paddingTop:8, display: 'absolute', width: 150}}>
                                                                            <Slider
                                                                                min={10}
                                                                                defaultValue={20}
                                                                                value={convertSpeedTextToNumber(eventCondition.speed)}
                                                                                max={30}
                                                                                marks={{10: t("TEXT_SLOW_P"), 20: t("TEXT_NORMAL_P"), 30: t("TEXT_FAST_P")}}
                                                                                step={null}
                                                                                onChange={(value)=>handleTextSpeed(value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

const EventCondition = (props) => {
    return(
        <EditCondition {...props}/>
    )
}
export default EventCondition;
