import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {SortableHandle} from "react-sortable-hoc";
import {Doughnut} from "react-chartjs-2";
import {dashboardService} from "../../../../services";
import {toastr} from "../../../../helper/toastrIntercept";
import {getErrorMessage} from "../../../../helper/responseHandler";
import classNames from "classnames";
import './CustomStorage.css';
import {dashboardConstants} from "../../../../constants/DashboardConstants";
import {MoonLoader} from "react-spinners";
import {useSelector} from "react-redux";

const CustomStorage = ({removeWidget}) => {

    const widgetRef = useRef();

    const {t} = useTranslation();

    const [subTab, setSubTab] = useState(undefined);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mouseOver, setMouseOver] = useState(false);
    const [showChart, setShowChart] = useState(true);

    const {reload} = useSelector(state => state.dashboard);

    const renderStorageDetail = (index) => {
        if (!data || data.length === 0 || data.length < index) {
            return (null);
        }
        const {totalSize, usedSize, freeSize} = data[index];
        return (
            <div className="server_detail">
                <ul>
                    <li className="li"><span className="storage_title">{t("TEXT_TOTAL_SIZE_P")}</span><span className="storage_total">{totalSize}</span></li>
                    <li className="li"><span className="storage_title">{t("MIS_SID_20_USED_STORAGE_SPACE")}</span><span className="storage_total">{usedSize}</span></li>
                    <li className="li"><span className="storage_title">{t("TABLE_DISK_SPACE_AVAILABLE_P")}</span><span className="storage_total">{freeSize}</span></li>
                </ul>
            </div>
        );
    }

    const renderChart = (index) => {
        if (!data || data.length === 0 || data.length < index) {
            return (null);
        }

        let dataShallowCopy = JSON.parse(JSON.stringify(data[index]))

        return showChart && (
            <div className='chart'>
                {

                    <Doughnut
                        data={dataShallowCopy}
                        className={'donut'}
                        options={{
                            showDataPercentOnPie : true,
                            maintainAspectRatio: false,
                            cutoutPercentage: 70,
                            legend: {
                                display: false,
                            },
                            animation: {
                                duration: 0
                            },
                            tooltips: {
                                displayColors: false,
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        return data.datasets[0].data[tooltipItem.index] + "%";
                                    },
                                },
                            },
                            elements: {
                                center: {
                                    color: '#252525', // Default is #000000
                                    sidePadding: 20, // Default is 20 (as a percentage)
                                    minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
                                    lineHeight: 25 // Default is 25 (in px), used for when text wraps
                                }
                            }
                        }}
                        redraw={false}
                    />
                }
            </div>
        );
    }

    const fetchGetStorageInfo = () => {
        setLoading(true);
        dashboardService.fetchStorageInfo().then(res => {
            let storages = [];
            res.items.forEach((storage) => {
                let totalSize = storage.totalSize;
                let freeSize = storage.freeSize;
                let usedSize = storage.usedSize;
                let rootPath = storage.rootPath;
                let percent = storage.percent * 1;
                let dataset = [{
                    data: [100 - percent, percent],
                    backgroundColor: [
                        '#3196FF',
                        '#E5E5E5',
                    ],
                    hoverBackgroundColor: [
                        '#3196FF',
                        '#E5E5E5',
                    ],
                }]
                storages.push({
                    path: rootPath,
                    totalSize: totalSize,
                    usedSize: usedSize,
                    freeSize: freeSize,
                    usedPercent: percent,
                    labels: ['Available', 'Used'],
                    datasets: dataset,
                })
            });
            setData(storages);
            setSubTab(0);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => setLoading(false));
    };

    const DragHandle = SortableHandle(() =>
        <div className='title'>{t("MIS_SID_20_SERVER_STORAGE")}</div>
    );

    useEffect(()=> {
        fetchGetStorageInfo();
        const {width} = widgetRef.current.getBoundingClientRect();
        if (width < 300) {
            setShowChart(false);
        } else {
            setShowChart(true);
        }
    }, [reload])

    return (
        <div className={'custom_widget'} onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)} ref={widgetRef}>
            <div className='header'>
                <DragHandle />
                {
                    mouseOver && <div className={'close'} onClick={(e)=>removeWidget(e, dashboardConstants.DASHBOARD_CUSTOM_STORAGE_WIDGET)}></div>
                }
            </div>
            <div className='custom_widget_body storage-summary'>
                <div style={{paddingLeft: 15, paddingRight: 15}}>
                {
                    !loading &&
                    <div className={'storage-tabs'}>
                        <ul>
                            {
                                data !== undefined && data.length > 0 && data.map(
                                    (data, index) => {
                                        return <li onClick={()=> setSubTab(index)} className={classNames({'on' : subTab !== undefined && subTab === index})} key={data.path}><span>{data.path}</span></li>
                                    }
                                )
                            }
                        </ul>
                    </div>

                }
                </div>
                <div className={'server_storage_container'} style={{marginTop: 20}}>
                    {loading && <MoonLoader css={`position: absolute;top: calc(0%);left: calc(50% - 40px);`} color="#6e78c8"/>}

                    {
                        !loading && data.length > 0 && subTab !== undefined && renderStorageDetail(subTab)
                    }
                    {
                        !loading && data.length > 0 && subTab !== undefined && renderChart(subTab)
                    }
                </div>
            </div>
        </div>
    )
}
export default CustomStorage;
