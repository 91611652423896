import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isEmpty, set} from 'lodash';
import { deviceService } from '../../../../services';
import MISDialog from '../../MISDialog';
import './VwlLayoutPopup.css';

const POPUP_WIDTH = 929;
const POPUP_HEIGHT = 511;

const InnerMonitor = ({x, yCount, bezels}) => {
    let monitors = [];
    for(let y = 0; y < yCount; y++) {
        monitors.push((
            <div className='monitor'>
                {bezels[x][y]}
            </div>
        ));
    }
    return (
        <>
            {monitors}
        </>
    );
};

const getSize = (xCount, yCount, monitor) => {
    let xRatio, yRatio, ratio;
    const {width, height} = monitor ? monitor : {};

    if((width * xCount) > POPUP_WIDTH){
        xRatio = POPUP_WIDTH / (width * xCount);
    }else{
        xRatio = 1;
    }
    
    if((height * yCount) > POPUP_HEIGHT){
        yRatio = POPUP_HEIGHT / (height * yCount);
    }else{
        yRatio = 1;
    }
    
    if(xRatio < yRatio){
        ratio = xRatio;
    }else{
        ratio = yRatio;
    }
    
    return {widthRatio: width * ratio, heightRatio : height * ratio, ratio: ratio};
};

const SignageVwlLayout = ({layout, t}) => {
    const {xCount, yCount, monitorList} = layout;
    const [width, height, ratio] = getSize(layout);
    const marginLeft = (POPUP_WIDTH - width * xCount) / 2;
    const data = monitorList.map(m => ([
        m.width,
        m.height,
        m.positionX,
        m.positionY,
        m.bezelLeftright,
        m.bezelTopbottom,
        m.mapId,
        m.deviceName,
        m.ipAddress,
        m.deviceId,
        m.imageUrl,
    ]));
    const monitors = [];
    const bezels = [];

    const sortedData = data;
    sortedData.sort((a,b) => {				
        if (a[3] === b[3]) {
            return a[2] - b[2];  //x-position
        }
        else {
            return a[3] - b[3];  //y-position
        }				
    });

    for(let i = 0, j = 0, cnt = 0; cnt < sortedData.length; cnt++, j++) {
        if(j >= yCount) {
            j = 0;
            i++;
        }

        set(bezels, `[${i}][${j}]`, (
            <div style={{ //monitor_bezel_
                width: width - 1 - 2,
                height: height - 2,
                verticalAlign: 'middle',
                background: '#262626',
                border: '1px solid #fff',
            }}>
                <div style={{ //monitor_img
                    position: 'absolute',
                    marginLeft: `${sortedData[cnt][4] * ratio - 1}px`,
                    marginTop: `${sortedData[cnt][5]*ratio}px`,
                    width: width - (sortedData[cnt][4] * ratio * 2) - 2,
                    height: height - (sortedData[cnt][5] * ratio * 2)-2,
                    background: '#B6B6B6',
                }}>
                    <div style={{
                        width: width-sortedData[cnt][4] * ratio * 2,
                        height: height-sortedData[cnt][5] * ratio * 2,
                        backgroundColor: '#5B5B5B',
                        textAlign: 'left',
                        color: '#FFF'
                    }}>
                        <div>{t('TABLE_DEVICE_NAME_P') + ': ' + sortedData[cnt][7]}</div>
                        <div>{t('COM_SID_IP_ADDRESS') + ': ' + sortedData[cnt][8]}</div>
                        <div>{t('COM_TABLE_DEVICE_ID_P') + ': ' + sortedData[cnt][9]}</div>
                        <div>{t('COM_IDS_DS_PREVIEW_WIDTH') + ': ' + sortedData[cnt][0] + ', ' + t('COM_IDS_HEIGHT') + ': ' + sortedData[cnt][1]}</div>
                    </div>
                </div>
            </div>
        ));
    }

    // for(let x = 0; x < xCount; x++) {
    //     monitors.push((
    //         <div className="monitor" style={{
    //             float: 'left',
    //             marginLeft: `${marginLeft}px`,
    //             width: width - xCount,
    //             height: height - yCount,
    //         }}>
    //             <InnerMonitor key={x} x={x} yCount={yCount} bezels={bezels} />
    //         </div>
    //     ));
    // }

    return (
        <div className='layout_content_wrap'>
            {
                Array(xCount).fill().map((e, x) => {
                    return (
                        <div className="monitor" style={{
                            float: 'left',
                            marginLeft: `${marginLeft}px`,
                            width: width - xCount,
                            height: height - yCount,
                        }}>
                            <InnerMonitor key={x} x={x} yCount={yCount} bezels={bezels} />
                        </div>
                    );
                })
            }
            {/* loadVWLLayoutViewPopup.js */}
        </div>
    )
};

const RenderSignageLayoutItem = ({monitorInfo, t, widthRatio, heightRatio, ratio}) => {
    const marginLeft = monitorInfo.bezelLeftright * ratio / 2;
    const marginTop = monitorInfo.bezelTopbottom * ratio / 2;

    return (
        <div className='signage_layout_item'
             style={{
                 marginLeft: marginLeft + 'px', marginTop: marginTop + 'px',
                 marginRight: marginLeft + 'px', marginBottom: marginTop + 'px',
                 width: (widthRatio - 2 - (marginLeft * 2)) + 'px', height: (heightRatio - 2 - (marginTop * 2)) + 'px'
             }}>
            <div style={{position: 'absolute', color: '#fff'}}>
                <div>{t('DID_SCHEDULE_ID') + ': ' + monitorInfo.mapId}</div>
            </div>
            <div className="vwl_layout_img_capture"/>
        </div>
    )
};

const RenderVwlLayoutItem = ({monitorInfo, t, widthRatio, heightRatio, ratio}) => {
    const [captureUrl, setCaptureUrl] = useState('');

    useEffect(() => {
        if(captureUrl === '') {
            deviceService.fetchDeviceImage('/MagicInfo' + monitorInfo.imageUrl).then(res => {
                setCaptureUrl(res.items.src);
            });
        }
    }, [captureUrl]);

    const marginLeft = monitorInfo.bezelLeftright * ratio / 2;
    const marginTop = monitorInfo.bezelTopbottom * ratio / 2;

    return (
        <div className='vwl_layout_item'
             style={{
                 marginLeft: marginLeft + 'px', marginTop: marginTop + 'px',
                 marginRight: marginLeft + 'px', marginBottom: marginTop + 'px',
                 width: (widthRatio - 2 - (marginLeft * 2)) + 'px', height: (heightRatio - 2 - (marginTop * 2)) + 'px'
             }}>
            <div style={{position: 'absolute'}}>
                <div>{t('TEXT_FILE_NAME_P') + ': ' + monitorInfo.imageFilename}</div>
                <div>{'Map Id' + ': ' + monitorInfo.ipAddress}</div>
                <div>{t('COM_IDS_DS_PREVIEW_WIDTH') + ': ' + monitorInfo.width}</div>
                <div>{t('COM_IDS_HEIGHT') + ': ' + monitorInfo.height}</div>
            </div>
            <div className="vwl_layout_img_capture"
                 style={{
                     width: (widthRatio - 2 - (marginLeft * 2)) + 'px',
                     height: (heightRatio - 2 - (marginTop * 2)) + 'px',
                     backgroundImage: `url('${captureUrl}')`,
            }}/>
        </div>
    )
};

const sortSignageList = (oldMonitorList) => {
    const sortedData = oldMonitorList;
    sortedData.sort((a, b) => {
        if (a.positionY === b.positionY) {
            return a.positionX - b.positionX;  //x-position
        } else {
            return a.positionY - b.positionY;  //y-position
        }
    });
    return sortedData;
};

const SignageLayout = ({layout, t}) => {
    const {linear, xCount, yCount, childLayoutList} = layout;

    const sortedMonitorList = sortSignageList(childLayoutList);
    const {docWidthRatio, docHeightRatio, ratio} = getSize(xCount, yCount, sortedMonitorList[0]);

    return (
        <div className='layout_content_wrap' style={{width: POPUP_WIDTH + 'px', height: POPUP_HEIGHT + 'px'}}>
            <ul className="clearfix">
                <div style={{marginLeft: (POPUP_WIDTH - docWidthRatio * ratio * xCount) + 'px'}}>
                {
                    xCount > 0 && yCount > 0 && sortedMonitorList && sortedMonitorList.map((monitor, index) => {
                        const {widthRatio, heightRatio, ratio} = getSize(xCount, yCount, monitor);
                        if(linear) {
                            return <li key={index} className='signage_layout_item_wrap' style={{width: widthRatio + 'px', height: heightRatio + 'px'}}>
                                <div className='signage_layout_item_frame'>
                                    <RenderSignageLayoutItem monitorInfo={monitor} t={t} widthRatio={widthRatio} heightRatio={heightRatio} ratio={ratio}/>
                                </div>
                            </li>
                        }
                    })
                }
                </div>
            </ul>
        </div>
    )
};

const getDocRatio = (documentWidth, documentHeight) => {
    const intWidth = parseInt(documentWidth);
    const intHeight = parseInt(documentHeight);
    let docWidthRatio;
    let docHeightRatio;
    let ratio;

    if(intWidth >= intHeight) {
        docWidthRatio = POPUP_WIDTH;
        docHeightRatio = (intHeight * POPUP_WIDTH) / intWidth;
        ratio = docHeightRatio / intHeight;
    } else {
        docWidthRatio = (intWidth * POPUP_HEIGHT) / intHeight;
        docHeightRatio = POPUP_HEIGHT;
        ratio = docWidthRatio / intWidth;
    }

    return {docWidthRatio, docHeightRatio, ratio};
};

const VwlLayout = ({layout, t}) => {
    const {linear, xCount, yCount, monitorList, documentWidth, documentHeight} = layout;

    const sortedMonitorList = monitorList;
    const {docWidthRatio, docHeightRatio, ratio} = getDocRatio(documentWidth, documentHeight);

    return (
        <div className='layout_content_wrap' style={{width: (docWidthRatio + 5) + 'px', height: (docHeightRatio + 5) + 'px', position: 'relative'}}>
            <ul className="clearfix">
                {
                    sortedMonitorList && sortedMonitorList.map((monitor, index) => {
                        let widthRatio = docWidthRatio;
                        let heightRatio = docHeightRatio;

                        if(linear) {
                            widthRatio = docWidthRatio / xCount;
                            heightRatio = docHeightRatio / yCount;

                            return <li key={index} className='vwl_layout_item_wrap' style={{width: widthRatio + 'px', height: heightRatio + 'px', display: 'inline-block'}}>
                                <RenderVwlLayoutItem monitorInfo={monitor} t={t} widthRatio={widthRatio} heightRatio={heightRatio} ratio={ratio}/>
                            </li>
                        } else {
                            widthRatio = (monitor.width * docWidthRatio) / documentWidth;
                            heightRatio = (monitor.height * docHeightRatio) / documentHeight;

                            let leftRatio = (monitor.positionX * docWidthRatio) / documentWidth;
                            let topRatio = (monitor.positionY * docWidthRatio) / documentWidth;

                            return <li key={index} className='vwl_layout_item_wrap' style={{width: widthRatio + 'px', height: heightRatio + 'px', left: (leftRatio) + 'px', top: (topRatio) + 'px', position:'absolute'}}>
                                <RenderVwlLayoutItem monitorInfo={monitor} t={t} widthRatio={widthRatio} heightRatio={heightRatio} ratio={ratio}/>
                            </li>
                        }
                    })
                }
            </ul>
        </div>
    )
};

const VwlLayoutPopup = ({layoutType, deviceId, groupId, onClose}) => {
    const {t} = useTranslation();
    const [layout, setLayout] = useState({});

    useEffect(() => {
        if(layoutType === 'SIGNAGE') {
            deviceService.fetchSignageLayout(deviceId).then(res => {
                setLayout(res.items);
            }).catch(error => console.log(error));

            // setLayout({...getSignageRes.items});
        } else if(groupId !== undefined && groupId !== '') {
            deviceService.previewVwlLayoutByGroupId(groupId).then(res => {
                setLayout(res.items);
            }).catch(error => console.log(error));

            // setLayout({...getTempRes.items});
        }
    }, []);

    return (
        <MISDialog
            dialog={{
                title: t('COM_MIV_SID_VW_LAYPUT'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 940,
                height: 550,
                onClose
            }}
        >
            <div className="popup_contents pb25">
                <div className="show_layout">
                    {
                        layoutType === 'SIGNAGE' && layout.linear &&
                        <SignageLayout layout={layout} t={t}/>
                    }
                    {
                        layoutType !== 'SIGNAGE' && /*layout.linear &&*/ groupId !== undefined && groupId !== '' &&
                        <VwlLayout layout={layout} t={t}/>
                    }
                </div>
            </div>
        </MISDialog>
    );
};

const getTempRes = {
    apiVersion: "2.0",
    status: "Success",
    items: {
        linear: false,
        xCount: "0",
        yCount: "0",
        vwtId: "nonlinear_20200504_061935",
        monitorList: [
            {
                width: 1428,
                positionX: 0,
                positionY: 0,
                bezelLeftright: 17,
                bezelTopbottom: 0,
                mapId: "VM000",
                deviceId: "fe-56-3f-b4-e1-1b",
                ipAddress: "192.168.0.8",
                deviceName: "Samsung061",
                imageUrl: "/restapi/v2.0/cms/contents/thumbnails/CAPTURE?capturedFileName=98-83-89-9b-a4-b9.jpg",
                imageId: "CAPTURE",
                imageFilename: "capturedFileName=98-83-89-9b-a4-b9.jpg",
                height: 804
            },
            {
                width: 1074,
                positionX: 1817,
                positionY: 765,
                bezelLeftright: 17,
                bezelTopbottom: 0,
                mapId: "VM001",
                deviceId: "fe-65-f5-ba-d7-ab",
                ipAddress: "192.168.0.3",
                deviceName: "Samsung07-1",
                imageUrl: "/restapi/v2.0/cms/contents/thumbnails/CAPTURE?capturedFileName=98-83-89-9b-a4-b9.jpg",
                imageId: "CAPTURE",
                imageFilename: "capturedFileName=98-83-89-9b-a4-b9.jpg",
                height: 604
            }
        ],
        documentWidth: "2891",
        documentHeight: "1369"
    },
    totalCount: 0,
    startIndex: 0,
    pageSize: 0
};

const getSignageRes = {
    apiVersion: "2.0",
    status: "Success",
    items: {
        documentWidth: 896,
        documentHeight: 498,
        xCount: 1,
        yCount: 2,
        childLayoutList: [
            {
                width: 896,
                positionX: 0,
                positionY: 522,
                bezelLeftright: 10,
                bezelTopbottom: 12,
                mapId: "1",
                height: 498
            },
            {
                width: 896,
                positionX: 0,
                positionY: 0,
                bezelLeftright: 10,
                bezelTopbottom: 12,
                mapId: "2",
                height: 498
            }
        ],
        linear: true
    }
};

export default VwlLayoutPopup;