import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import TextInput from "../../../input/TextInput";
import Checkbox from "../../../checkbox/Checkbox";
import NumberInput from "../../../input/NumberInput";
import WhiteButton from "../../../button/WhiteButton";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {validateSpecialCharForExternalServerName, validateWhiteSpace} from "../../../../helper";


const DataLinkInformation = ({datalinkInfo, updateDatalinkInfo, mode, setNameChecked}) => {
    const {t} = useTranslation();
    const [nameCheckError, setNameCheckError] = useState('');
    const [currentDatalinkInfo, setCurrentDatalinkInfo] = useState(datalinkInfo?datalinkInfo:{
        'serverName':'',
        'ipAddress':'',
        'port':80,
        'privateMode':false,
        'privateIpAddress':'',
        'privateWebPort':8080,
        'ftpPort':10021,
        'period':30,
        'useSsl':false,
        'bypass':false
    });
    const checkServerName = () => {
        if(currentDatalinkInfo.serverName == ''){
            toastr.error(t('MIS_MESSAGE_SETUP_ENTER_SERVERNAME_P'));
            setNameChecked(false);
            return;
        }

        if(currentDatalinkInfo.serverName.length > 60){
            toastr.error(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace("<<A>>",60));
            setNameChecked(false);
            return;
        }

        if(!validateSpecialCharForExternalServerName(currentDatalinkInfo.serverName)){
            toastr.error(t('MIS_SID_ONLY_ALPHANUMERIC_CHARACTERS_AND_FOLLOWING_ALLOWED')  + " ( ( ) - )");
            setNameChecked(false);
            return;
        }

        if(validateWhiteSpace(currentDatalinkInfo.serverName)){
            toastr.error(t('MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG'));
            setNameChecked(false);
            return;
        }

        settingService.checkDataLinkName(currentDatalinkInfo.serverName)
            .then(res=> {
                if(res.items) {
                    setNameChecked(false);
                }else{
                    setNameChecked(true);
                    toastr.success(t('MIS_TEXT_SETUP_CHECK_SERVERNAME_P'));
                }
                setNameCheckError(res.items);
            })
            .catch(err=>{
                setNameChecked(false);
            });
    };

    const getDatalinkInfo = (e, v) => {
        let key = e.target.getAttribute('data-name');
        let value = v?parseInt(v):e.target.value;
        
        if(key === 'port' || key === 'privateWebPort' || key === 'ftpPort' || key === 'period')
            value = isNaN(value)?'':value;

        if(key === 'bypass' || key === 'useSsl' || key === 'privateMode'){
            value = e.target.checked;
        }

        (key === 'serverName') && setNameChecked(false);

        setCurrentDatalinkInfo({
            ...currentDatalinkInfo,
            [key]: value
        });

        updateDatalinkInfo({
            ...currentDatalinkInfo,
            [key]: value
        });
    };

    return(
        <div className='detail_view'>
            <table className='dl_table'>
                <colgroup>
                    <col width="143px"></col>
                    <col></col>
                </colgroup>
                <tbody>
                {mode==='ADD'?
                    <tr>
                        <th>
                            <span>{t('TABLE_SERVER_NAME_P')}</span>
                        </th>
                        <td style={{display:"flex"}}>
                            <TextInput
                                propertyName={'serverName'}
                                width={165}
                                placeholder={t('TABLE_SERVER_NAME_P')}
                                onChange={e=>getDatalinkInfo(e)}
                                value={currentDatalinkInfo.serverName?currentDatalinkInfo.serverName:''}
                                error={nameCheckError?t('MESSAGE_UPLOADER_ALREADY_EXIST_P'):''}
                                maxLength={50}
                            />
                            <span style={{margin:'0 0 0 25px',height:'27px'}}>
                                <WhiteButton
                                    name={t('MIS_TEXT_CHECK_P')}
                                    onClick={()=>checkServerName()}
                                />
                            </span>
                        </td>
                    </tr>:
                    <tr>
                        <th>
                            <span >{t('TABLE_SERVER_NAME_P')}</span>
                        </th>
                        <td style={{display:"flex"}}>
                            <span style={{margin:'5px 0 0 5px'}}>{currentDatalinkInfo.serverName}</span>
                        </td>
                    </tr>
                }
                <tr>
                    <th>
                        <span>{t('TABLE_IP_ADDR_P')}</span>
                    </th>
                    <td>
                        <TextInput
                            propertyName={'ipAddress'}
                            width={250}
                            placeholder={t('TABLE_IP_ADDR_P') + ', ' + t('COM_LIST_TITLE_URL')}
                            onChange={e=>getDatalinkInfo(e)}
                            maxLength={200}
                            value={currentDatalinkInfo.ipAddress?currentDatalinkInfo.ipAddress:''}
                        />
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>{t('TABLE_WEB_PORT_P')}</span>
                    </th>
                    <td>
                        <NumberInput propertyName={'port'} width={84} min={1} max={65535}
                            value={currentDatalinkInfo.port} 
                            onChange={(e, value)=>getDatalinkInfo(e, value)} />
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>{t('COM_SETUP_NEW_STRING26_P')}</span>
                    </th>
                    <td>
                        <NumberInput propertyName={'ftpPort'} width={84} min={1} max={65535}
                            value={currentDatalinkInfo.ftpPort} 
                            onChange={(e, value)=>getDatalinkInfo(e, value)} />
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>{t('COM_TEXT_PERIOD2_P')}</span>
                    </th>
                    <td>
                        <NumberInput
                            propertyName={'period'} width={84} min={1} max={50000}
                            value={currentDatalinkInfo.period}
                            onChange={(e, value)=>getDatalinkInfo(e, value)}/>
                        <span style={{lineHeight:'28px'}}>{t('TEXT_SEC_P')}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>SSL</span>
                    </th>
                    <td>
                        <Checkbox
                            classname='table'
                            propertyName='useSsl'
                            id='use_ssl_check'
                            onChange={e=>{getDatalinkInfo(e)}}
                            checked={currentDatalinkInfo.useSsl}
                            name={t('TEXT_ENABLE2_P')}
                        />
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>{t('MIS_SID_20_BYPASS')}</span>
                    </th>
                    <td>
                        <Checkbox
                            classname='table'
                            propertyName='bypass'
                            id='bypass_check'
                            onChange={e=>{getDatalinkInfo(e)}}
                            checked={currentDatalinkInfo.bypass}
                            name={t('TEXT_ENABLE2_P')}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default DataLinkInformation;