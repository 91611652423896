import React, { useEffect } from 'react';
import {useTranslation} from "react-i18next";
import {isNil, toInteger} from 'lodash';
import RadioGroup from '../../../radio/RadioGroup';
import NumberInput from '../../../input/NumberInput';
import Checkbox from '../../../checkbox/Checkbox';
import Select from '../../../selectbox/Select';
import moment from 'moment';
import { deviceOptions } from '../../../misopt';
import TextInput from '../../../input/TextInput';
import TimeSelect from '../../../selectbox/TimeSelect';
import { getDeviceMessage } from '../../../../helper/device/getDeviceMessage';

export const EditPresetWebBrowserUrl = ({displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {webBrowserUrl} = {}, changed} = displayInfo || {};
    const [webBrowserInterval, webBrowserZoom, webBrowserHomepage, webBrowserPageurl] = (webBrowserUrl || '').split(';');

    const onChangeValue = (e, value, index) => {
        let browserUrlValue = '';

        if(index === 0) {
            browserUrlValue = `${value};${webBrowserZoom};${webBrowserHomepage};${webBrowserPageurl}`;
        } else if(index === 1) {
            browserUrlValue = `${webBrowserInterval};${value};${webBrowserHomepage};${webBrowserPageurl}`;
        } else if(index === 2) {
            browserUrlValue = `${webBrowserInterval};${webBrowserZoom};${value};${webBrowserPageurl}`;
        } else if(index === 3) {
            browserUrlValue = `${webBrowserInterval};${webBrowserZoom};${webBrowserHomepage};${e.target.value}`;
        }

        onChange(e, browserUrlValue);
    };

    const webBrowserRefreshIntervalOptions = [
        {value: "0", title: t('BUTTON_OFF')},
        {value: "300", title: getDeviceMessage("5_minutes", t)},
        {value: "600", title: getDeviceMessage("10_minutes", t)},
        {value: "900", title: getDeviceMessage("15_minutes", t)},
        {value: "1800", title: getDeviceMessage("30_minutes", t)}
    ];

    return (
        <>
            <tr>
                <th>
                    <Checkbox id='webBrowserUrlEnable' name={t('COM_TV_SID_WEB_BROWSER')} checked={changed['webBrowserUrl'] || false}
                        propertyName='webBrowserUrl' onChange={onChangeChecked} />
                </th>
                <td>{t('TEXT_TITLE_REFRESH_INTERVAL_P')}</td>
                <td>
                    <Select width={200} propertyName='webBrowserUrl' selects={webBrowserRefreshIntervalOptions} multiLang={false}
                        onChange={(e, value) => onChangeValue(e, value, 0)} value={webBrowserInterval} disabled={!changed['webBrowserUrl']} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>{t('COM_DID_LFD_ZOOM')}</td>
                <td>
                    <Select width={200} propertyName='webBrowserUrl' selects={deviceOptions.zoomOptions} onChange={(e, value) => onChangeValue(e, value, 1)}
                        value={webBrowserZoom} disabled={!changed['webBrowserUrl']} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>{t('COM_HOME_PAGE')}</td>
                <td>
                    <RadioGroup selects={deviceOptions.homePage} propertyName='webBrowserUrl' onChange={(e, value) => onChangeValue(e, value, 2)} 
                        value={webBrowserHomepage} disabled={!changed['webBrowserUrl']} />
                </td>
            </tr>
            {
                webBrowserHomepage == 1 &&
                <tr>
                    <th></th>
                    <td>{t('COM_LIST_TITLE_URL')}</td>
                    <td>
                        <TextInput propertyName='webBrowserUrl' onChange={(e, value) => onChangeValue(e, value, 3)} value={webBrowserPageurl || ''}
                            disabled={!changed['webBrowserUrl']} />
                    </td>
                </tr>
            }
        </>
    );
};

export const EditPresetCustomLogo = ({displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {customLogo} = {}, changed} = displayInfo || {};
    const [type, displayTime] = (customLogo || '').split(';');

    const onChangeCustomLogo = (e, value, i) => {
        let customLogoValue = '';

        if(i === 0) {
            customLogoValue = `${value};${displayTime}`;
        } else {
            customLogoValue = `${type};${value}`;
        }

        onChange(e, customLogoValue);
    };

    return (
        <>
            {
                customLogo &&
                <>
                    <tr>
                        <th>
                            <Checkbox id='customLogoEnable' name={t('COM_PJT_IDS_SETUP_14')} checked={changed['customLogo'] || false}
                                propertyName='customLogo' onChange={onChangeChecked} />
                        </th>
                        <td colSpan='2'>
                            <RadioGroup propertyName='customLogo' selects={deviceOptions.customLogo} value={type} onChange={(e, value) => onChangeCustomLogo(e, value, 0)}
                                disabled={!changed['customLogo']} />
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <td>{t('COM_LFD_SID_LOGO_DISPLAY_TIME')}</td>
                        <td>
                            <Select width={200} propertyName='customLogo' selects={deviceOptions.customLogoDisplayTime} value={displayTime} 
                                onChange={(e, value) => onChangeCustomLogo(e, value, 1)} disabled={!changed['customLogo']} />
                        </td>
                    </tr>
                </>
            }
        </>
    );
};

export const EditPresetAdvancedOsdDisplay = ({displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {advancedOsdDisplayType} = {}, changed} = displayInfo || {};
    const [advSourceOsd, notOptimumModeOsd, advNoSignalOsd, advMdcOsd, advDownloadStatusOsd] = (advancedOsdDisplayType || '').split(';');

    const onChangeValue = (e, value, i) => {
        let displayTypeValue = '';

        if(i === 0) {
            displayTypeValue = `${value};${notOptimumModeOsd};${advNoSignalOsd};${advMdcOsd};${advDownloadStatusOsd}`;
        } else if(i === 1) {
            displayTypeValue = `${advSourceOsd};${value};${advNoSignalOsd};${advMdcOsd};${advDownloadStatusOsd}`;
        } else if(i === 2) {
            displayTypeValue = `${advSourceOsd};${notOptimumModeOsd};${value};${advMdcOsd};${advDownloadStatusOsd}`;
        } else if(i === 3) {
            displayTypeValue = `${advSourceOsd};${notOptimumModeOsd};${advNoSignalOsd};${value};${advDownloadStatusOsd}`;
        } else if(i === 4) {
            displayTypeValue = `${advSourceOsd};${notOptimumModeOsd};${advNoSignalOsd};${advMdcOsd};${value}`;
        }

        onChange(e, displayTypeValue);
    };

    return (
        <>
            <tr>
                <th>
                    <Checkbox id='advSourceOsdEnable' name={t('COM_DID_LFD_OSD_DISPLAY_TYPE')} checked={changed['advancedOsdDisplayType'] || false}
                        propertyName='advancedOsdDisplayType' onChange={onChangeChecked} />
                </th>
                <td>{t('DID_LFD_SOURCE_OSD')}</td>
                <td>
                    <RadioGroup propertyName='advancedOsdDisplayType' selects={deviceOptions.onOffOptions} onChange={(e, value) => onChangeValue(e, value, 0)} 
                        value={advSourceOsd} disabled={!changed['advancedOsdDisplayType']} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>{t('TEXT_NOT_OPTIMUM_MODE_OSD_P')}</td>
                <td>
                    <RadioGroup propertyName='advancedOsdDisplayType' selects={deviceOptions.onOffOptions} onChange={(e, value) => onChangeValue(e, value, 1)} 
                        value={notOptimumModeOsd} disabled={!changed['advancedOsdDisplayType']} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>{t('COM_DID_LFD_NO_SIGNAL_OSD')}</td>
                <td>
                    <RadioGroup propertyName='advancedOsdDisplayType' selects={deviceOptions.onOffOptions} onChange={(e, value) => onChangeValue(e, value, 2)} 
                        value={advNoSignalOsd} disabled={!changed['advancedOsdDisplayType']} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>{t('COM_DID_LFD_MDC_OSD')}</td>
                <td>
                    <RadioGroup propertyName='advancedOsdDisplayType' selects={deviceOptions.onOffOptions} onChange={(e, value) => onChangeValue(e, value, 3)} 
                        value={advMdcOsd} disabled={!changed['advancedOsdDisplayType']} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>{t('COM_LFD_SID_DOWNLOAD_STATUS_MESSAGE')}</td>
                <td>
                    <RadioGroup propertyName='advancedOsdDisplayType' selects={deviceOptions.onOffOptions} onChange={(e, value) => onChangeValue(e, value, 4)} 
                        value={advDownloadStatusOsd} disabled={!changed['advancedOsdDisplayType']} />
                </td>
            </tr>
        </>
    );
};

export const EditPresetPicture = ({displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {pvMode, mntManual, pvContrast, pvBrightness, pvSharpness, pvColor, pvTint, pvColortone, pvColorTemperature, 
        pvSize, ppcGamma, pvFilmmode, pvHdmiBlackLevel, pvDigitalnr, displayOutputMode} = {}, changed, errors} = displayInfo || {};

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_IDS_TXT_PICTURE')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Checkbox id='pvModeEnable' name={t('COM_SID_PICTURE_MODE')} checked={changed['pvMode'] || false}
                                    propertyName='pvMode' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='pvMode' selects={deviceOptions.pictureMode} value={pvMode} onChange={onChange} disabled={!changed['pvMode']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvContrastEnable' name={t('TEXT_CONTRAST_P')} checked={changed['pvContrast'] || false}
                                    propertyName='pvContrast' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <NumberInput width={70} propertyName='pvContrast' value={pvContrast} onChange={onChange} disabled={!changed['pvContrast']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvBrightnessEnable' name={t('DID_LFD_BRIGHTNESS')} checked={changed['pvBrightness'] || false}
                                    propertyName='pvBrightness' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <NumberInput width={70} propertyName='pvBrightness' value={pvBrightness} onChange={onChange} disabled={!changed['pvBrightness']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvSharpnessEnable' name={t('DID_LFD_SHARPNESS')} checked={changed['pvSharpness'] || false}
                                    propertyName='pvSharpness' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <NumberInput width={70} propertyName='pvSharpness' value={pvSharpness} onChange={onChange} disabled={!changed['pvSharpness']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvColorEnable' name={t('DID_LFD_COLOR')} checked={changed['pvColor'] || false}
                                    propertyName='pvColor' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <NumberInput width={70} propertyName='pvColor' value={pvColor} onChange={onChange} disabled={!changed['pvColor']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvColortoneEnable' name={t('TEXT_COLOR_TONE_P')} checked={changed['pvColortone'] || false}
                                    propertyName='pvColortone' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='pvColortone' selects={deviceOptions.colorTone1.concat(deviceOptions.colorTone2)} value={pvColortone} 
                                    onChange={onChange} disabled={!changed['pvColortone']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvColorTemperatureEnable' name={t('TEXT_COLOR_TEMPERATURE_P')} checked={changed['pvColorTemperature'] || false}
                                    propertyName='pvColorTemperature' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='pvColorTemperature' selects={deviceOptions.colorTemperature} value={pvColorTemperature} multiLang={false} 
                                    onChange={onChange} disabled={!changed['pvColorTemperature']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvTintEnable' name={t('COM_TV_SID_TINT_GR')} checked={changed['pvTint'] || false}
                                    propertyName='pvTint' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <NumberInput width={70} propertyName='pvTint' value={pvTint} onChange={onChange} disabled={!changed['pvTint']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvSizeEnable' name={t('COM_SID_PICTURE_SIZE')} checked={changed['pvSize'] || false}
                                    propertyName='pvSize' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='pvSize' selects={deviceOptions.pictureSize} value={pvSize} onChange={onChange} disabled={!changed['pvSize']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='mntManualEnable' name={`${t('COM_TEXT_LAMP_CONTROL_P')}(${t('TEXT_MANUAL_P')})`} checked={changed['mntManual'] || false}
                                    propertyName='mntManual' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <NumberInput width={70} propertyName='mntManual' value={mntManual} onChange={onChange} disabled={!changed['mntManual']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='ppcGammaEnable' name={t('COM_DID_LFD_GAMMA')} checked={changed['ppcGamma'] || false}
                                    propertyName='ppcGamma' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='ppcGamma' selects={deviceOptions.gammaOptions} onChange={onChange} value={ppcGamma} disabled={!changed['ppcGamma']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvHdmiBlackLevelEnable' name={t('COM_DID_LFD_HDMI_BLACK_LEVEL')} checked={changed['pvHdmiBlackLevel'] || false}
                                    propertyName='pvHdmiBlackLevel' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='pvHdmiBlackLevel' selects={deviceOptions.hdmiBalckLevel} value={pvHdmiBlackLevel}
                                    onChange={onChange} disabled={!changed['pvHdmiBlackLevel']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvDigitalnrEnable' name={t('COM_TV_SID_DIGITAL_CLEAN_VIEW')} checked={changed['pvDigitalnr'] || false}
                                    propertyName='pvDigitalnr' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='pvDigitalnr' selects={deviceOptions.pcDigitalCleanView} value={pvDigitalnr} onChange={onChange} disabled={!changed['pvDigitalnr']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='pvFilmmodeEnable' name={t('DID_LFD_FILM_MODE')} checked={changed['pvFilmmode'] || false}
                                    propertyName='pvFilmmode' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <Select width={200} propertyName='pvFilmmode' selects={deviceOptions.filmMode} value={pvFilmmode} onChange={onChange} disabled={!changed['pvFilmmode']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='displayOutputModeEnable' name={t('COM_SID_PICTURE_LED_OUTPUT_RESOLUTION')} checked={changed['displayOutputMode'] || false}
                                    propertyName='displayOutputMode' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='displayOutputMode' selects={deviceOptions.outputResolution} value={displayOutputMode} onChange={onChange} disabled={!changed['displayOutputMode']} multiLang={false} />
                                {/* <Select width={200} propertyName='displayOutputMode' selects={deviceOptions.filmMode} value={displayOutputMode} onChange={onChange} disabled={!changed['displayOutputMode']} /> */}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const timeFormat = 'hh:mm A';
const defaultTime = '12:00AM';
const displayTimeFormat = 'hh:mm A';

export const EditPresetMaintenance = ({displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {mntAuto} = {}, changed} = displayInfo || {};
    const [mntAutoIsEnable, mntAutoMaxHour, mntAutoMaxMin, mntAutoMaxAmpm, mntAutoMaxValue, 
        mntAutoMinHour, mntAutoMinMin, mntAutoMinAmpm, mntAutoMinValue] = (mntAuto || '').split(';');

    const mntAutoMaxTime = mntAutoMaxAmpm == 1 ? `${mntAutoMaxHour}:${mntAutoMaxMin} AM` : `${mntAutoMinHour}:${mntAutoMinMin} PM`;
    const mntAutoMinTime = mntAutoMinAmpm == 1 ? `${mntAutoMinHour}:${mntAutoMinMin} AM` : `${mntAutoMinHour}:${mntAutoMinMin} PM`;

    const onChangeMntAutoEnabled = (e, value) => {
        onChange(e, `${value};${mntAutoMaxHour};${mntAutoMaxMin};${mntAutoMaxAmpm};${mntAutoMaxValue};${mntAutoMinHour};${mntAutoMinMin};${mntAutoMinAmpm};${mntAutoMinValue}`);
    };

    const onChangeMaxTime = value => {
        const ampm = value.hour() < 12 ? 1 : 0;
        onChange({'data-name': 'mntAuto'}, `${mntAutoIsEnable};${ampm === 1 ? value.hour() : value.hour() - 12};${value.minute()};${ampm};${mntAutoMaxValue};${mntAutoMinHour};${mntAutoMinMin};${mntAutoMinAmpm};${mntAutoMinValue}`);
    };

    const onChangeMinTime = value => {
        const ampm = value.hour() < 12 ? 1 : 0;
        onChange({'data-name': 'mntAuto'}, `${mntAutoIsEnable};${mntAutoMaxHour};${mntAutoMaxMin};${mntAutoMaxAmpm};${mntAutoMaxValue};${ampm === 1 ? value.hour() : value.hour() - 12};${value.minute()};${ampm};${mntAutoMinValue}`);
    };

    const onChangeMaxValue = (e, value) => {
        onChange(e, `${mntAutoIsEnable};${mntAutoMaxHour};${mntAutoMaxMin};${mntAutoMaxAmpm};${value};${mntAutoMinHour};${mntAutoMinMin};${mntAutoMinAmpm};${mntAutoMinValue}`);
    };

    const onChangeMinValue = (e, value) => {
        onChange(e, `${mntAutoIsEnable};${mntAutoMaxHour};${mntAutoMaxMin};${mntAutoMaxAmpm};${mntAutoMaxValue};${mntAutoMinHour};${mntAutoMinMin};${mntAutoMinAmpm};${value}`);
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_TITLE_MAINTENANCE_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Checkbox id='mntAutoEnable' name={t('COM_TV_SID_SCREEN_LAMP_SCHEDULE')} checked={changed['mntAuto'] || false}
                                    propertyName='mntAuto' onChange={onChangeChecked} />
                            </th>
                            <td colSpan="2">
                                <RadioGroup propertyName='mntAuto' selects={deviceOptions.onOffOptions} value={mntAutoIsEnable} disabled={!changed['mntAuto']}
                                    onChange={onChangeMntAutoEnabled} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('COM_TEXT_MAX_TIME_P')}</td>
                            <td>
                                <TimeSelect id='mntAutoMaxTime' propertyName='mntAuto' value={moment(mntAutoMaxTime, timeFormat)} onChange={onChangeMaxTime} disabled={!changed['mntAuto']} 
                                    showSecond={false} format={timeFormat} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('TEXT_MAX_VALUE_P')}</td>
                            <td><NumberInput propertyName='mntAuto' value={parseInt(mntAutoMaxValue)} onChange={onChangeMaxValue} disabled={!changed['mntAuto']} forceInt /></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('COM_TEXT_MIN_TIME_P')}</td>
                            <td>
                                <TimeSelect id='mntAutoMinTime' propertyName='mntAuto' value={moment(mntAutoMinTime, timeFormat)} onChange={onChangeMinTime} disabled={!changed['mntAuto']} 
                                    showSecond={false} format={timeFormat} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('COM_DID_LFD_MIN_VALUE')}</td>
                            <td><NumberInput propertyName='mntAuto' value={parseInt(mntAutoMinValue)} onChange={onChangeMinValue} disabled={!changed['mntAuto']} forceInt /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
 };

const repeatMode = [...deviceOptions.burnProtectionModeRepeat1, ...deviceOptions.burnProtectionModeRepeat2];
const intervalMode = [...deviceOptions.burnProtectionModeInterval1, ...deviceOptions.burnProtectionModeInterval2];

export const EditPresetScreenBurnProtection = ({displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {mntSafetyScreenTimer, mntSafetyScreenRun, mntPixelShift} = {}, changed} = displayInfo || {};
    const [pixelShiftEnable, pixelShiftH, pixelShiftV, pixelShiftTime] = (mntPixelShift || '').split(';');
    const timerType = mntSafetyScreenTimer.split(';')[0];

    let timerOption = "0", timerMode = null, selectedTimerMode, scrSafePeriod, scrSafeTime;
    let scrSafeStartTimeHour, scrSafeStartTimeMin, scrSafeStartTimeAMPM;
    let scrSafeEndTimeHour, scrSafeEndTimeMin, scrSafeEndTimeAMPM;

    if (repeatMode.findIndex((mode) => mode.value === timerType) > -1 || timerType === "1") {
        timerOption = "1";
        timerMode = repeatMode;
        selectedTimerMode = parseInt(timerType) > 1 ? timerType : "3";
        scrSafePeriod = mntSafetyScreenTimer.split(';')[1] ? mntSafetyScreenTimer.split(';')[1] : 0;
        scrSafeTime = mntSafetyScreenTimer.split(';')[1] ? mntSafetyScreenTimer.split(';')[2] : 0;
    } else if (intervalMode.findIndex((mode) => mode.value === timerType) > -1 || timerType === "2") {
        timerOption = "2";
        timerMode = intervalMode;
        selectedTimerMode = parseInt(timerType) > 2 ? timerType : "131";
        scrSafeStartTimeHour =  mntSafetyScreenTimer.split(';')[1] ? mntSafetyScreenTimer.split(';')[1] : '12';
        scrSafeStartTimeMin =  mntSafetyScreenTimer.split(';')[2] ? mntSafetyScreenTimer.split(';')[2] : '00';
        scrSafeStartTimeAMPM =  mntSafetyScreenTimer.split(';')[3] ? (mntSafetyScreenTimer.split(';')[3] === "1" ? 'AM' : 'PM') : 'AM';

        scrSafeEndTimeHour =  mntSafetyScreenTimer.split(';')[4] ? mntSafetyScreenTimer.split(';')[4] : '12';
        scrSafeEndTimeMin =  mntSafetyScreenTimer.split(';')[5] ? mntSafetyScreenTimer.split(';')[5] : '00';
        scrSafeEndTimeAMPM =  mntSafetyScreenTimer.split(';')[6] ? (mntSafetyScreenTimer.split(';')[6] === "1" ? 'AM' : 'PM') : 'AM';
    }

    const onChangePixelShift = (e, value, i) => {
        let pixelShiftValue = '';

        if(i === 0) {
            pixelShiftValue = `${value};${pixelShiftH};${pixelShiftV};${pixelShiftTime}`;
        } else if(i === 1) {
            pixelShiftValue = `${pixelShiftEnable};${value};${pixelShiftV};${pixelShiftTime}`;
        } else if(i === 2) {
            pixelShiftValue = `${pixelShiftEnable};${pixelShiftH};${value};${pixelShiftTime}`;
        } else {
            pixelShiftValue = `${pixelShiftEnable};${pixelShiftH};${pixelShiftV};${value}`;
        }

        onChange(e, pixelShiftValue);
    };
    const onChangePeriod = (e, value) => {
        const safeScreenTimerValue = selectedTimerMode + ";" + value + ";" + scrSafeTime;
        onChange(e, safeScreenTimerValue);
    };

    const onChangeSafetyScreenTimerMode = (e, value) => {
        if (timerOption === "1") {
            const safeScreenTimerValue = value + ";" + scrSafePeriod + ";" + scrSafeTime;
            onChange(e, safeScreenTimerValue);
        } else if (timerOption === "2") {
            const startAmpm = scrSafeStartTimeAMPM === 'AM' ? 1 : 0;
            const endAmpm = scrSafeEndTimeAMPM === 'AM' ? 1 : 0;
            const safeScreenTimerValue = value + ";" + scrSafeStartTimeHour + ";" + scrSafeStartTimeMin + ";" + startAmpm + ";" + scrSafeEndTimeHour + ";" + scrSafeStartTimeMin + ";" + endAmpm;
            onChange(e, safeScreenTimerValue);
        }
    };

    const onChangeSafetyScreenTimer = (e, value) => {
        const safeScreenTimerValue = selectedTimerMode + ";" + scrSafePeriod + ";" + value;
        onChange(e, safeScreenTimerValue);
    };

    const onChangeStartTime = value => {
        if(value) {
            const changedStartTime = value.format(timeFormat);
            const hour =  changedStartTime.slice(0,2);
            const min =  changedStartTime.slice(3,5);
            const ampm =  changedStartTime.slice(6,8) === 'AM' ? 1 : 0;
            const endAmpm = scrSafeEndTimeAMPM === 'AM' ? 1 : 0;
            const safeScreenTimerValue = selectedTimerMode + ";" + hour + ";" + min + ";" + ampm + ";" + scrSafeEndTimeHour + ";" + scrSafeStartTimeMin + ";" + endAmpm;

            onChange({'data-name': 'mntSafetyScreenTimer'}, safeScreenTimerValue);
        }
    };

    const onChangeEndTime = value => {
        if (value) {
            const changedEndTime = value.format(timeFormat);
            const hour =  changedEndTime.slice(0,2);
            const min =  changedEndTime.slice(3,5);
            const ampm =  changedEndTime.slice(6,8) === 'AM' ? 1 : 0;
            const startAmpm = scrSafeStartTimeAMPM === 'AM' ? 1 : 0;
            const safeScreenTimerValue = selectedTimerMode + ";" + scrSafeStartTimeHour + ";" + scrSafeStartTimeMin + ";" + startAmpm + ";" + hour + ";" + min + ";" + ampm;

            onChange({'data-name': 'mntSafetyScreenTimer'}, safeScreenTimerValue);
        }
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_SID_SCREEN_BURN_PROTECTION')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Checkbox id='mntSafetyScreenTimerEnable' name={t('DID_ADMIN_URGENT_TIMER')} checked={changed['mntSafetyScreenTimer'] || false}
                                    propertyName='mntSafetyScreenTimer' onChange={onChangeChecked} />
                            </th>
                            <td colSpan="2">
                                <RadioGroup propertyName='mntSafetyScreenTimer' value={timerOption} selects={deviceOptions.presetScreenBurnProtectionTimer}
                                    onChange={onChange} disabled={!changed['mntSafetyScreenTimer']} />
                            </td>
                        </tr>
                        {
                            timerOption != "0" &&
                            <>
                                <tr>
                                    <th></th>
                                    <td>{t('DID_LFD_MODE')}</td>
                                    <td>
                                        <Select
                                            propertyName='mntSafetyScreenTimer'
                                            width={200}
                                            value={selectedTimerMode || timerMode[0].value}
                                            selects={timerMode}
                                            onChange={onChangeSafetyScreenTimerMode}
                                        />
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            timerOption == "1" &&
                            <>
                                <tr>
                                    <th></th>
                                    <td>{t('TEXT_PERIOD_P')}</td>
                                    <td>
                                        <NumberInput propertyName='mntSafetyScreenTimer' value={toInteger(scrSafePeriod)} min={0} max={10} onChange={onChangePeriod} forceInt />
                                        {t('COM_TEXT_HOUR_P')}
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{t('TEXT_TIME_P')}</td>
                                    <td>
                                        <NumberInput propertyName='mntSafetyScreenTimer' value={toInteger(scrSafeTime)} min={1} max={50}
                                                     step={(scrSafeTime % 10) === 0 ? 10 : 10 - (scrSafeTime % 10)}
                                                     onChange={onChangeSafetyScreenTimer} forceInt />
                                        {t('COM_SID_SEC')}
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            timerOption == "2" &&
                            <>
                                <tr>
                                    <th></th>
                                    <td>{t('COM_SID_START_TIME')}</td>
                                    <td>
                                        <TimeSelect propertyName='mntSafetyScreenTimer' width={120} value={moment(scrSafeStartTimeHour + scrSafeStartTimeMin + scrSafeStartTimeAMPM, timeFormat)} format={displayTimeFormat} onChange={onChangeStartTime} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{t('COM_SID_END_TIME')}</td>
                                    <td>
                                        <TimeSelect propertyName='mntSafetyScreenTimer' width={120} value={moment(scrSafeEndTimeHour + scrSafeEndTimeMin + scrSafeEndTimeAMPM, timeFormat)} format={displayTimeFormat} onChange={onChangeEndTime} />
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <th>
                                <Checkbox id='mntSafetyScreenRunEnable' name={t('COM_TV_IMMEDIATE_DISPLAY')} checked={changed['mntSafetyScreenRun'] || false}
                                    propertyName='mntSafetyScreenRun' onChange={onChangeChecked} />
                            </th>
                            <td colSpan="2">
                                <Select width={200} propertyName='mntSafetyScreenRun' value={mntSafetyScreenRun} selects={deviceOptions.immediateDisplay} 
                                    onChange={onChange} disabled={!changed['mntSafetyScreenRun']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='mntPixelShiftEnableCheck' name={t('TEXT_PIXEL_SHIFT_P')} checked={changed['mntPixelShift'] || false}
                                    propertyName='mntPixelShift' onChange={onChangeChecked} />
                            </th>
                            <td>{t('TEXT_PIXEL_SHIFT_P')}</td>
                            <td>
                                <RadioGroup propertyName='mntPixelShift' value={pixelShiftEnable} selects={deviceOptions.onOffOptions} 
                                    onChange={(e, value) => onChangePixelShift(e, value, 0)} disabled={!changed['mntPixelShift']} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('TEXT_HORIZONTAL_P')}</td>
                            <td>
                                <NumberInput propertyName='mntPixelShift' value={toInteger(pixelShiftH)} onChange={(e, value) => onChangePixelShift(e, value, 1)} disabled={!changed['mntPixelShift']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('TEXT_VERTICAL_P')}</td>
                            <td>
                                <NumberInput propertyName='mntPixelShift' value={toInteger(pixelShiftV)} onChange={(e, value) => onChangePixelShift(e, value, 2)} disabled={!changed['mntPixelShift']} forceInt />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>{t('TEXT_SHIFT_TIME_P')}</td>
                            <td>
                                <NumberInput propertyName='mntPixelShift' value={toInteger(pixelShiftTime)} onChange={(e, value) => onChangePixelShift(e, value, 3)} disabled={!changed['mntPixelShift']} forceInt />
                                {t('COM_SID_MIN')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
 };

export const EditPresetAdvancedSetting = ({displayInfo, onChange, onChangeChecked, setDisplayInfo}) => {
    const {t} = useTranslation();
    const {data: {autoSourceSwitching, maxPowerSaving, brightnessLimit, advancedAutoPower, advancedStandBy, networkStandbyMode,
        ecoSensor, liveMode, minBrightness} = {}, changed} = displayInfo || {};
    const [asw, autoSourceRecovery, autoSourcePrimary, autoSourceSecondary, autoSourceDefaultInput] = (autoSourceSwitching || '').split(';');

    const onChangeAutoSourceSwitching = index => (e, value) => {
        if(index === 0) {
            onChange(e, `${value};${autoSourceRecovery};${autoSourcePrimary};${autoSourceSecondary};${autoSourceDefaultInput}`);
        } else if(index === 1) {
            onChange(e, `${asw};${value};${autoSourcePrimary};${autoSourceSecondary};${autoSourceDefaultInput}`);
        } else if(index === 2) {
            onChange(e, `${asw};${autoSourceRecovery};${value};${autoSourceSecondary};${autoSourceDefaultInput}`);
        } else if(index === 3) {
            onChange(e, `${asw};${autoSourceRecovery};${autoSourcePrimary};${value};${autoSourceDefaultInput}`);
        } else if(index === 4) {
            onChange(e, `${asw};${autoSourceRecovery};${autoSourcePrimary};${autoSourceSecondary};${value}`);
        }
    };

    const primarySourceOptionsExclude = ['96', '99', '101', '97', '103']; // MagicInfo S/Lite, URL Launcher, WebBrowser, WiDi, Kiosk
    const secondarySourceOptionsExclude = ['97', '103']; // WiDi, Kiosk


    let primarySourceOptions = [...deviceOptions.inputSource];
    primarySourceOptions.splice(0, 0, {value:"0", title:"ALL", hide: autoSourceRecovery == 1});
    primarySourceOptions = primarySourceOptions.filter((source) => !primarySourceOptionsExclude.includes(source.value));

    const secondarySourceOptions = deviceOptions.inputSource && deviceOptions.inputSource.filter((source) => !secondarySourceOptionsExclude.includes(source.value));
    const defaultInputSourceOptions = deviceOptions.inputSource && [{value:"0", title:"Last Input"}].concat(deviceOptions.inputSource.filter((source) => !secondarySourceOptionsExclude.includes(source.value)));

    //SF00202614
    useEffect(()=>{
        if(ecoSensor === 0 || !(changed['ecoSensor'] || false)) {
            setDisplayInfo({...displayInfo, data: {...displayInfo.data, minBrightness : null}});
        }
    }, [ecoSensor, changed['ecoSensor']]);
    
    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_TITLE_ADVANCED_SETTING_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Checkbox id='autoSourceSwitchingEnable' name={t('COM_TV_SID_AUTO_SOURCE_SWITCHING')} checked={changed['autoSourceSwitching'] || false}
                                    propertyName='autoSourceSwitching' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='autoSourceSwitching' selects={deviceOptions.autoSourceSwitchingOptions} value={asw} 
                                    onChange={onChangeAutoSourceSwitching(0)} disabled={!changed['autoSourceSwitching']} />
                            </td>
                        </tr>
                        {
                            asw === '1' &&
                            <>
                                <tr>
                                    <th></th>
                                    <td>{t('MIS_SID_PREMIUM_RESTORE_PRIMARY_SOURCE')}</td>
                                    <td>
                                        <RadioGroup propertyName='autoSourceSwitching' selects={deviceOptions.onOffOptions} value={autoSourceRecovery} onChange={onChangeAutoSourceSwitching(1)} disabled={!changed['autoSourceSwitching']} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{t('COM_LFD_TXT_PRIMARY_SOURCE')}</td>
                                    <td>
                                        <Select width={200} propertyName='autoSourceSwitching' selects={primarySourceOptions} value={autoSourcePrimary} onChange={onChangeAutoSourceSwitching(2)} multiLang={false} disabled={!changed['autoSourceSwitching']} />
                                    </td>
                                </tr>
                                {
                                    autoSourcePrimary != 0 &&
                                    <tr>
                                        <th></th>
                                        <td>{t('COM_LFD_TXT_SECONDARY_SOURCE')}</td>
                                        <td>
                                            <Select width={200} propertyName='autoSourceSwitching' selects={secondarySourceOptions} value={autoSourceSecondary} onChange={onChangeAutoSourceSwitching(3)} multiLang={false} disabled={!changed['autoSourceSwitching']} />
                                        </td>
                                    </tr>
                                }
                            </>
                        }
                        {
                            asw === '2' && 
                            <tr>
                                <th></th>
                                <td>{t('COM_SID_TIZENLFD_NEW_INPUT')}</td>
                                <td>
                                    <Select width={200} propertyName='autoSourceSwitching' selects={defaultInputSourceOptions} value={autoSourceDefaultInput} onChange={onChangeAutoSourceSwitching(4)} multiLang={false} disabled={!changed['autoSourceSwitching']} />
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>
                                <Checkbox id='maxPowerSavingEnable' name={t('COM_SID_MAX_ECO_MODE_KOR_MSG')} checked={changed['maxPowerSaving'] || false}
                                    propertyName='maxPowerSaving' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='maxPowerSaving' selects={deviceOptions.onOffOptions} value={maxPowerSaving} 
                                    onChange={onChange} disabled={!changed['maxPowerSaving']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='brightnessLimitEnable' name={t('COM_LFD_SID_BRIGHTNESS_LIMIT')} checked={changed['brightnessLimit'] || false}
                                    propertyName='brightnessLimit' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='brightnessLimit' selects={deviceOptions.onOffOptions} value={brightnessLimit} 
                                    onChange={onChange} disabled={!changed['brightnessLimit']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='advancedAutoPowerEnable' name={t('DID_LFD_AUTO_POWER')} checked={changed['advancedAutoPower'] || false}
                                    propertyName='advancedAutoPower' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='advancedAutoPower' selects={deviceOptions.onOffOptions} value={advancedAutoPower} 
                                    onChange={onChange} disabled={!changed['advancedAutoPower']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='advancedStandByEnable' name={t('TEXT_STANDBY_CONTROL_P')} checked={changed['advancedStandBy'] || false}
                                    propertyName='advancedStandBy' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='advancedStandBy' selects={deviceOptions.standbyControl} value={advancedStandBy} 
                                    onChange={onChange} disabled={!changed['advancedStandBy']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='networkStandbyModeEnable' name={t('COM_LFD_SID_TIZEN_CAMAR_REMOTE_CONFIGURATION')} checked={changed['networkStandbyMode'] || false}
                                    propertyName='networkStandbyMode' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='networkStandbyMode' selects={deviceOptions.onOffOptions} value={networkStandbyMode} 
                                    onChange={onChange} disabled={!changed['networkStandbyMode']} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Checkbox id='ecoSensorEnable' name={t('COM_SID_ECO_SENSOR')} checked={changed['ecoSensor'] || false}
                                    propertyName='ecoSensor' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='ecoSensor' selects={deviceOptions.onOffOptions} value={ecoSensor} 
                                    onChange={onChange} disabled={!changed['ecoSensor']} />
                            </td>
                        </tr>
                        {
                            !isNil(ecoSensor) && ecoSensor == 1 &&
                            <>
                                <tr>
                                    <th></th>
                                    <td>{t('COM_SID_MIN_BRIGHTNESS')}</td>
                                    <td>
                                        <NumberInput propertyName='minBrightness' value={minBrightness} onChange={onChange} forceInt disabled={!changed['ecoSensor']}/>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <th>
                                <Checkbox id='liveModeEnable' name={t('COM_SID_LEDSIGNAGE_LIVE_MODE')} checked={changed['liveMode'] || false}
                                    propertyName='liveMode' onChange={onChangeChecked} />
                            </th>
                            <td>
                                <RadioGroup propertyName='liveMode' selects={deviceOptions.onOffOptions} value={liveMode} 
                                    onChange={onChange} disabled={!changed['liveMode']} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
 };