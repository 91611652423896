export const ProgressConstants = {
    ADD_PROGRESS: 'ADD_PROGRESS',
    REMOVE_PROGRESS: 'REMOVE_PROGRESS',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',
    DESTROY_PROGRESS: 'DESTROY_PROGRESS',



    MODE_CONTENT_SCHEDULE: 'CONTENT_SCHEDULE_PROGRESS'


}