import React, {useRef, useState} from 'react'
import classNames from 'classnames';
import './WScroll.css';
import {useDispatch} from "react-redux";
import {menuAction} from "../../actions";
import {DraggableCore} from 'react-draggable';

const WScroll = ({left}) => {

    const minWidth = 253;
    const maxWidth = 500;

    const resize = useRef();
    const dispatch = useDispatch();

    const [position, setPosition] = useState({
        x: left
    })

    const [active, setActive] = useState(false);

    const updateSubmenuWidth = () => {
        const width = resize.current.offsetLeft;
        dispatch(menuAction.updateSubmenuWidth(width));
    }

    const handleStart = () => {
        setActive(true);
    }

    const handleDrag = (e, data) => {
        const {deltaX} = data;
        const x = position.x+deltaX
        if (x > minWidth && maxWidth > x) {
            setPosition({x: x});
        }
    }

    const handleStop = () => {
        setActive(false);
        updateSubmenuWidth();
    }

    return (
        <DraggableCore
            axis="x"
            onStart={handleStart}
            onDrag={handleDrag}
            onStop={handleStop}>
            <div ref={resize} className={classNames('noSelect', 'resizable', {'on': active })} style={{left: position.x}}></div>
        </DraggableCore>
    )
}
export default WScroll;