import React, {useCallback, useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import './AssociateTagPopup.css';
import {deviceService, playlistService} from "../../../../services";
import ContentThumbnail from "../../../image/ContentThumbnail";
import Backend from "react-dnd-html5-backend";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../../../helper/responseHandler";
import {useDispatch} from "react-redux";
import TagInformationForSyncPopup from "./TagInformationForSyncPopup";


const AssociateTagPopup = ({playlistId, close, save, devices}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [playlist, setPlaylist] = useState({
        loading: false,
        contents: []
    });

    const [tagList, setTagList] = useState({});

    const [tagMapping, setTagMapping]  =  useState(null)

    const [tagInfo, setTagInfo] = useState({
        show: false
    })

    useEffect(()=> {
        if (devices !== undefined){
            const groupIds = devices.map(d => d.groupId);
            Promise.all([
                deviceService.fetchTagsByDeviceGroups(groupIds),
                playlistService.fetchPlaylistDetailById(playlistId)
            ]).then(res => {
                let tagList = res[0].items;
                tagList = tagList.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.tagId === thing.tagId
                    ))
                )
                setTagList(tagList);

                const playlist = res[1].items;
                const contentGroups = [];
                const tagMappingInit = {};
                for (let i=0; i < playlist.syncGroupCount; i++) {
                    const contents = [];
                    for (const content of playlist.contents) {
                        if (content.syncPlayId === String(i+1)) {
                            contents.push(content)
                        }
                    }
                    contentGroups.push({contents: contents, groupOrder: i+1, syncPlayId: String(i+1)})
                    tagMappingInit[String(i)] = [];
                }
                setPlaylist({...playlist, contentGroups: contentGroups})
                setTagMapping(tagMappingInit);

            }).catch(e=> toastr.error(getErrorMessage(e)));
        }
    }, [])


    const Tag = ({tag}) => {

        const [{isDragging}, drag] = useDrag({
            item: {
                type: 'tag',
                tagId: tag.tagId,
                tagName: tag.tagName
            },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });

        return (
            <div  ref={drag} className={'associatedTagWrap'} data-value={tag.tagId}>
                {tag.tagName}
            </div>
        )
    }

    const moveTag = useCallback((tagList, syncGroupId, tag) => {
        let mappingTags = [];
        if (tagMapping !== null && tagMapping[syncGroupId] !== undefined && tagMapping[syncGroupId].length > 0) {
            mappingTags = tagMapping[syncGroupId].concat(tag);
        } else {
            mappingTags = mappingTags.concat(tag);
        }
        setTagMapping({
            ...tagMapping, [syncGroupId]: mappingTags
        });
        setTagList(tagList.filter(t => t.tagId !== tag.tagId));
    }, [tagMapping])

    const onDrop = (item, monitor, index) => {
        moveTag(tagList, index, {tagId: item.tagId, tagName: item.tagName});
        return undefined;
    }

    const removeTagOnMappingTag = (index, tag) => {
        if (tagMapping[index] !== undefined) {
            setTagMapping({...tagMapping, [index]: tagMapping[index].filter(t=> t.tagId !== tag.tagId)})
            setTagList(tagList.concat(tag))
        }
    }

    const SyncGroup = ({group, index}) => {

        const [ ,drop] = useDrop({
            accept: 'tag',
            drop : (item, monitor)=> onDrop(item, monitor, index)
        })

        return (
            <tr>
                <th className={"tagAssociatingDroppableWrap"} data-value={index} ref={drop}>
                    {
                        tagMapping !== undefined && tagMapping !== null && tagMapping[index] !== undefined && tagMapping[index].length > 0 &&
                            tagMapping[index].map(
                                t => <div className={"associatedTagWrap"} style={{background: '#eff2f6'}}>{t.tagName}<button onClick={()=>removeTagOnMappingTag(index, t)}></button></div>
                            )
                    }
                </th>
                {
                    group != undefined && group.contents !== undefined && group.contents.length > 0 &&
                        <PlaylistContent key={index} contents={group.contents}/>

                }
            </tr>
        )
    }

    const PlaylistContent = ({contents}) => {
        return (
            <td>
                <div>
                    {t("TEXT_GROUP_P")}
                    <span>{t("MIS_SID_SYNC_GROUP")}</span>
                </div>
                <RenderContentThumb contents={contents}/>
            </td>
        )
    }

    const RenderContentThumb = ({contents = undefined}) => {
        return (
            <ul>
                {
                    contents !== undefined &&
                    contents.map(
                        (content, index) => <li  key={index}><div><ContentThumbnail id={content.thumbFileId} width={70} height={39}/></div><span>{content.playTime}</span></li>
                    )
                }
            </ul>
        )
    }


    const saveTagMapping = () => {
        const doValid = () => {
            let valid = true;
            if (tagList.length > 0) {
                toastr.error(t('MIS_SID_20_NO_SCHEDULES_PUBLISHED_BECAUSE_THE_TAGS_DONT_MATCH'));
                return false;
            }
            if (tagMapping === null) {
                toastr.error(t('MIS_SID_SELECT_MORE_TAGS_GROUP_SAME_TAG'));
                return false;
            } else {
                const keys = Object.keys(tagMapping);
                for (const key of keys) {
                    if (tagMapping[key] === undefined || tagMapping[key].length === 0) {
                        toastr.error(t('MIS_SID_SELECT_MORE_TAGS_GROUP_SAME_TAG'));
                        return false;
                    }
                }
            }
            return valid;
        }
        if (doValid()) {
            const syncGroupDeviceTagMaps = [];
            Object.keys(tagMapping).map(
                key => syncGroupDeviceTagMaps.push({
                    syncGroupId: key,
                    deviceTagIds: tagMapping[key].map(tag=> tag.tagId)
                })
            )
            save(syncGroupDeviceTagMaps);
        }
    }
    const showTagInfo = () => {
        /*dispatch(popupAction.addPopup({
            type: scheduleConstants.TAG_INFO_POPUP,
            devices: devices,
            id: scheduleConstants.TAG_INFO_POPUP
        }))*/
        setTagInfo({show: !tagInfo.show});
    }

    const dialogProps ={title: t("MIS_SID_ASSOCIATED_TAGS"), closeOnEscape : true, width :740, height : 700 ,position: {x: 740/2*-1, y: 700/2*-1} , modal:true, onClose:()=> close()};

    return(
        <MISDialog
            dialog={dialogProps}
            buttons = {{
                rightButtons: [
                    {title : t("COM_BUTTON_SAVE"), id: 'associate_tag_save_button', onClick: ()=> saveTagMapping()},
                    {title : t("COM_BUTTON_CLOSE_P"), id: 'associate_tag_cancel_button', onClick: ()=> close()}
                ]
            }}
        >

            {
                tagInfo.show &&
                <TagInformationForSyncPopup
                    devices={devices}
                    close={showTagInfo}
                    />
            }
            <div className={'content_schedule_associate_tags'}>
                <DndProvider backend={Backend}>
                <div className="popup_contents" id="tagAssociatingPopup" style={{width:700}}>
                    <div className="notify_wrap fc_999999 mt20">
                        {t("MIS_SID_SELECT_MORE_TAGS_GROUP_SAME_TAG")}
                    </div>
                    <div style={{height: 50}}>
                        <div style={{width:143,height:37}} className="tag_associated_title float_l">
                            <div className="float_l">{t("MIS_SID_ALL_TAGS")}</div>
                            <div className="float_r mr4">
                                <button id="deviceTagInfoBtn" className="circle_btn base information mt5" onClick={showTagInfo}>
                                    <span className=""></span>
                                </button>
                            </div>
                        </div>
                        <div className="tag_associated_title float_l">{t("MIS_SID_ASSOCIATED_TAGS")}</div>
                    </div>
                    <div className="tag_associated clearfix">
                        <div className="float_l mr4" id="tagLists">
                            {
                                tagList !== undefined && tagList.length > 0 &&
                                    tagList.map(
                                        tag => <Tag key={tag.tagId} tag={tag}/>
                                    )
                            }

                        </div>
                        <div className="float_r">

                                <table>
                                    <colgroup>
                                        <col width="151px" />
                                        <col width="" />
                                    </colgroup>
                                    <tbody>
                                        {
                                            playlist.contentGroups !== undefined && playlist.contentGroups.length > 0 && playlist.contentGroups.map(
                                                (group, groupIndex) => <SyncGroup key={groupIndex} group={group} index={groupIndex} />
                                            )
                                        }
                                    </tbody>
                                </table>

                        </div>
                    </div>
                </div>
                </DndProvider>
            </div>
        </MISDialog>
    )

}
export default AssociateTagPopup;