import React from 'react';


function bytesToSize(bytes) {


};

const Size = ({value, size}) => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    return (
        <span>
            {value}
            {
                size == 0 ? '0 Byte' :
                (
                () => {
                    let i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
                    if (i > 2) {
                        return parseFloat((size / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
                    } else {
                        return Math.round(size / Math.pow(1024, i), 2) + ' ' + sizes[i];
                    }
                }
                )()}
        </span>
    )
}
export default Size