import React, { useState, useEffect, useRef } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import GroupsPopup from "../GroupsPopup";

const AddUserGroupSelectionPopup = ({t, title, onClose, onSave, organizationId}) => {

    const close = () => {
        onClose();
    }

    const save = (groups) => {
        onSave(groups);
    }

    return (
        <GroupsPopup
            mode={"selectedUser"}
            checkbox={false}
            save={(groups)=>save(groups)}
            close={()=>close()}
            organizationId={organizationId}
        />
    );
};


export default withTranslation()(AddUserGroupSelectionPopup);