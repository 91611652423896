import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../../switch/SwitchListNImage";
import SearchBar from "../../../search/SearchBar";
import classNames from "classnames";
import ContentThumbnail from "../../../image/ContentThumbnail";
import Size from "../../../utils/Size";
import {contentService, settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import PerfectScrollbar from "perfect-scrollbar";
import ContentTypeFilter from "../../../filter/ContentTypeFilter";
import {Loading} from "../../../loading/Loading";
import DateToStr from "../../../utils/DateToStr";
import {commonConstants, contentConstants, rulesetConstants} from "../../../../constants";
import {snakeCase,isNil,isEmpty,indexOf} from "lodash";
import SupportedDeviceIcon from "../../../icon/SupportedDeviceIcon";
import {getMixString} from "../../../../language/languageUtils";
import MyGroups from "../../schedule/MyGroups";
import {getErrorMessage} from "../../../../helper/responseHandler";
import SubTab from "../../../tab/SubTab";
import {popupAction} from "../../../../actions";
import {useDispatch} from "react-redux";
import {insightService} from "../../../../services/";
import {isFlashSupported} from "../../../../helper/device/isSupportFeature";

const ListView = ({contentType, contents, selectedId, handleSelect,handleInsight,isLoading }) => {
    const { t } = useTranslation();
    return (
        <table style={{ width: "100%" }}>
            <colgroup>
                <col width="87px" />
                <col width="" />
            </colgroup>
            <tbody>
                {contents !== undefined &&
                    contents.length > 0
                    ?
                    contents.map(item => (
                        <tr
                            key={item.contentId}
                            className={classNames({
                                on: selectedId.findIndex(i => i.contentId === item.contentId) !== -1
                            })}
                            onClick={() =>
                                handleSelect(
                                    item.contentId,
                                    item.contentName,
                                    item.thumbFileId,                                    
                                    item.totalSize,
                                    item.mediaType,
                                    item.usable,
                                    item.mainFileId,
                                    item.mainFileName
                                )
                            }
                        >
                            <td className="thum">
                                <div className="thumb_wrap">
                                    <div
                                        style={{
                                            width: "75px",
                                            height: "42px",
                                            textAlign: "center",
                                            lineHeight: "42px"
                                        }}
                                    >
                                        <ContentThumbnail
                                            id={item.thumbFileId}
                                            width={75}
                                            height={42}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h4 className="contentName">
                                    <span className="text-overflow" style={{ width: "300px" }}>
                                        {item.contentName}
                                    </span>
                                </h4>
                                <ul>
                                    <li className="supportedIcon">
                                    <SupportedDeviceIcon deviceType={item.deviceType} deviceTypeVersion={item.deviceTypeVersion}  mediaType={item.mediaType} />
                                    </li>
                                    <li>
                                        {contentType != 'INSIGHT' &&
                                        <span>
                                            <Size size={item.totalSize}/>
                                        </span>
                                        }
                                        {   contentType =='INSIGHT' &&
                                            <>
                                            <span>
                                            <Size size={item.contentTotalSize}/>
                                            </span>
                                            <span style={{marginRight: '20px'}}>
                                                {t('MIS_SID_INSIGHT_CBJAN_SALES')} : {item.totalPriceUnit} {item.totalPrice}
                                            </span>
                                            </>
                                        }
                                    </li>
                                    <li style={{lineHeight:'25px'}}>
                                        {contentType != 'INSIGHT' &&
                                            <span>
                                                <DateToStr date={item.lastModifiedDate} newLine={false}/>
                                            </span>
                                        }
                                        {   contentType=='INSIGHT' &&
                                            <>
                                            <span>
                                                <DateToStr date={item.contentLastModifiedDate} newLine={false}/>
                                            </span>
                                            <div className="info_button" style={{cursor: 'pointer'}} onClick={() => handleInsight(item.contentId,item.usable)}>&nbsp;</div>
                                            </>
                                        }
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    ))
                    : (isLoading ? '' : <div  style={{display: 'flex', justifyContent: 'center'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</div> )
                }
            </tbody>
        </table>
    );
};

const ImageView = ({ contents, selectedId, handleSelect }) => {
    return (
        <div className={"thumbnailView_wrap"}>
            <ul className="clearfix mt20">
                {contents !== undefined &&
                    contents.length > 0 &&
                    contents.map(item => (
                        <li>
                            <div
                                style={{ cursor: "pointer" }}
                                className={classNames("thumbview_box", {
                                    on: selectedId.findIndex(i => i.contentId === item.contentId) !== -1
                                })}
                                onClick={() => handleSelect(item.contentId, item.contentName, item.thumbFileId, item.totalSize, item.mediaType, item.usable, item.mainFileId, item.mainFileName)}
                            >
                                <div style={{ width: 75, height: 42, textAlign: "center" }}>
                                    <ContentThumbnail
                                        id={item.thumbFileId}
                                        width={75}
                                        height={42}
                                    />
                                </div>
                                <div className="device_info">
                                    <span className="contentName">{item.contentName}</span>
                                </div>
                                <div className="check">
                                    <span></span>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export const Contents = ({ device, selectedContents, multiSelect, fetchForAdsContent = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let deviceType;
    let deviceTypeVersion;
    if(device){
        if(device.deviceType === 'IPLAYER') {
            deviceType = 'iPLAYER';
        }else{
            deviceType = device.deviceType;
        }

        deviceTypeVersion = device.deviceTypeVersion;
    }

    const [content, setContent] = useState({
        filter: {
            startIndex: 1,
            pageSize: 35,
            sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
            sortOrder: 'DESC',
            render: false,            
            deviceType: deviceType ? deviceType : '',
            deviceTypeVersion: device?device.deviceTypeVersion:'',            
            contentTypes: fetchForAdsContent ? contentConstants.DEFAULT_MEDIA_SUPPORTED_FOR_ADS_CONTENT : rulesetConstants.RULESET_SUPPORT_CONTENT_TYPE,
            approvalStatus: "APPROVED",
            expirationStatus: "VALID"                     
        },
        insightFilter : '',
        list: [],
        isFetching: false,
        loading: false,
        count: 0
    });

    const [insightContents, setInsightContents] =useState({});

    const [end, setEnd] = useState(false);
    const [view, setView] = useState("LIST");
    const [active, setActive] = useState('ALL');
    const [subTab, setSubTab] = useState('TEXT_CONTENT_P');

    const [subTabs, setSubTabs] = useState( [
        'TEXT_CONTENT_P'
    ]);

    const handleListNImage = () => {
        setView(view === "LIST" ? "IMAGE" : "LIST");
        resetScrollPosition();
    };

    const fetchInsightServerInfo = () =>{
        settingService.fetchCommonConfigInfo()
            .then(res => {
                if(isNil(res.items.insightServer.useServer) || res.items.insightServer.useServer === false) {
                    setSubTabs([
                            'TEXT_CONTENT_P'
                        ])
                }
                else {
                    setSubTabs([
                        'TEXT_CONTENT_P',
                        'COM_DID_LFD_ADVERTISEMENT'
                    ])
                }
            });

    }
    
    const fetchContents = () => {
        setContent({...content, loading: true});
        
        contentService.fetchContentFilter(content.filter)
            .then(res => {
                setContent({
                    ...content,
                    list: content.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: content.count + 1,
                    totalCount: res.totalCount,                    
                    approvalStatus: "APPROVED",
                    filter: {
                        ...content.filter,
                        startIndex: content.filter.startIndex + res.items.length,
                    }
                })
            }).catch((e) => {
                if(e.errorCode === 408900) {
                    return;
                }

                toastr.error(getErrorMessage(e));
            });
    };

    const fetchInsightContents = () => {
        setContent({...content, loading: true});

        insightService.getInsightTop10ContentsInfo()
            .then(res => {
                setInsightContents(res.items)
                let result =  res.items.top10Played.filter(c => {
                    if(content.filter.contentTypes.includes(c.contentType))
                        return c;
                }) // filter with content type

                if(content.filter.searchText!=undefined) {
                    result = result.filter(c => {
                        if (c.contentName.includes(content.filter.searchText))
                            return c;
                    }) // filter with content name
                }

                setContent({
                    ...content,
                    list: result,
                    isFetching: false,
                    loading: false,
                    totalCount: 10,
                    insightFilter :'TOP_10_PLAYED'
                })
            }).catch((e) => {
            if(e.errorCode === 408900) {
                return;
            }
        });


    };

    const handleContentTypes = selected => {
        if(content.insightFilter=='') {
            setContent({
                ...content,
                list: [],
                loading: false,
                isFetching: false,
                count: 0,
                filter: {
                    ...content.filter,
                    startIndex: 1,
                    pages: 0,
                    contentTypes: selected.length === 0 ? ["none"] : selected
                }
            });
        }else{
            let temp = [];
            if (content.insightFilter==='TOP_10_PLAYED'){
                temp = insightContents.top10Played;
            }else if(content.insightFilter==='TOP_10_SOLD'){
                temp = insightContents.top10Sold;
            }else if(content.insightFilter==='TOP_10_SALES_INCREASE'){
                temp = insightContents.top10SalesIncrease;
            }
            let result = temp.filter(c => {
                if(selected.includes(c.contentType))
                    return c;
            })

            if(content.filter.searchText!=undefined) {
                result = result.filter(c => {
                    if (c.contentName.includes(content.filter.searchText))
                        return c;
                }) // filter with content name
            }

            setContent({
                ...content,
                list: result,
                filter: {...content.filter, contentTypes: selected.length === 0 ? ["none"] : selected}
            })
        }
    };

    const [selected, setSelected] = useState([]);

    if (multiSelect === undefined) {
        multiSelect = true;
    }

    const removeThumb = id => {
        const newContent = selected.filter(i => i.contentId !== id);
        setSelected(newContent);
        selectedContents(newContent);
    };

    const handleSelect = (id, name, thumbFileId, size, type, usable, mainFileId, mainFileName) => {
        const item = {
            contentId: id,
            contentName: name,
            thumbFileId: thumbFileId,
            contentSize : size, 
            mediaType : type,
            usable : usable,
            mainFileId : mainFileId,
            mainFileName : mainFileName
        };
        let newSelectedContent = [];

        if(usable==false){
            alert(t('COM_SID_NOT_AVAILABLE_KR_USE'));
            return;
        }
        if (selected.findIndex(i => i.contentId === id) === -1) {
            if (multiSelect) {
                setSelected((prevState)=>([
                    ...prevState,
                    item
                ]));                
                newSelectedContent = selected.concat(item);
            } else {
                newSelectedContent = [item];
                setSelected(newSelectedContent);
            }
        } else {
            newSelectedContent = selected.filter(i => i.contentId !== id);
            setSelected((prevState)=>prevState.filter(i => i.contentId !== id));  
        }
        selectedContents(newSelectedContent);
    };

    const selectAll = () => { 
        if(selected.length === content.list.length){
            setSelected([]);
            selectedContents([]);
        }else{
           const newItem =  content.list.map((item) => {
               return {
                    contentId: item.contentId,
                    contentName: item.contentName,
                    thumbFileId : item.thumbFileId,
                    contentSize : item.totalSize, 
                    mediaType : item.mediaType
               }
           });
           setSelected(newItem);
           selectedContents(newItem);
        }
    };

    const removeAll = () => {
        setSelected([]);
        selectedContents([]);
    };

    const onSearch = value => {
        if(content.insightFilter=='') {
            setContent({
                ...content,
                list: [],
                loading: false,
                isFetching: false,
                count: 0,
                filter: {...content.filter, startIndex: 1, pages: 0, searchText: value}
            });
        }else {
            let temp = [];
            if (content.insightFilter==='TOP_10_PLAYED'){
                temp = insightContents.top10Played;
            }else if(content.insightFilter==='TOP_10_SOLD'){
                temp = insightContents.top10Sold;
            }else if(content.insightFilter==='TOP_10_SALES_INCREASE'){
                temp = insightContents.top10SalesIncrease;
            }
            let result = temp.filter(c => {
                if(content.filter.contentTypes.includes(c.contentType))
                    return c;
            })

            result = result.filter(c => {
                if (c.contentName.includes(value))
                    return c;
            }) // filter with content name

            setContent({
                ...content,
                list: result,
                filter: {...content.filter, startIndex: 1, pages: 0, searchText: value}
            })
        }
    };

    const activeMenu = (menu) => {
        if (menu === 'ALL') {            
            setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, groupId: undefined, groupType: 'ALL'}});
        }

        if(menu === 'TOP_10_PLAYED' || menu === 'TOP_10_SOLD'|| menu === 'TOP_10_SALES_INCREASE') {
            let temp = [], insightFilter = '',result;
            switch (menu) {
                case 'TOP_10_PLAYED' :
                    temp = insightContents.top10Played;
                    insightFilter = 'TOP_10_PLAYED';
                    break;
                case 'TOP_10_SOLD' :
                    temp = insightContents.top10Sold;
                    insightFilter = 'TOP_10_SOLD';
                    break;
                case 'TOP_10_SALES_INCREASE':
                    temp = insightContents.top10SalesIncrease;
                    insightFilter = 'TOP_10_SALES_INCREASE';
                    break;
                default :
                    break;
            }
            if(!isNil(temp) && temp.length>0) {
                result = temp.filter(c => {
                    if (content.filter.contentTypes.includes(c.contentType))
                        return c;
                })


                if (content.filter.searchText != undefined) {
                    result = result.filter(c => {
                        if (c.contentName.includes(content.filter.searchText))
                            return c;
                    }) // filter with content name
                }
            }
            setContent({...content, list: result, insightFilter: insightFilter, loading: isNil(temp)});
            resetScrollPosition();
        }
        setActive(menu);
    };

    const selectGroup = (groupId) => {
        if (groupId) {
            resetScrollPosition();
            setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, groupId: groupId, groupType: 'GROUPED'}});
        }
    };

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_content_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    };

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    const onClickSubTab = (id) => {
        if(subTab==id)
            return;

        if(id==='TEXT_CONTENT_P' ){
            activeMenu('ALL')
            setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, groupId: undefined, groupType: 'ALL'}, insightFilter:''}, );
        }else if(id==='COM_DID_LFD_ADVERTISEMENT'){
            if(isEmpty(insightContents)) {
                fetchInsightContents();
            }
            activeMenu('TOP_10_PLAYED')
            //setContent({...content, list: insightContents.top10Played, insightFilter: 'TOP_10_PLAYED'});
        }
       setSubTab(id)
    };

    const insightInformationPopup = (contentId,usable) => {
        if(usable==false){
            alert(t('COM_SID_NOT_AVAILABLE_KR_USE'));
            return;
        }
        dispatch(popupAction.addPopup({
            id: commonConstants.INSIGHT_INFORMATION,
            type: commonConstants.INSIGHT_INFORMATION,
            contentId : contentId
        }));
    }

  
    useEffect(()=>{
        if (!initScroll.current) {
            const ps = new PerfectScrollbar('#select_content_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_content_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            initScroll.current = true;
            perfectScrollbar.current = ps;
        }else {
            perfectScrollbar.current.update();

        }
    }, [content.list]);

    useEffect(()=>{
        if(content !== undefined && content.insightFilter ==='') {
            if (content !== undefined && !content.loading && !content.isFetching && content.list !== undefined && content.list.length === 0 && content.count < 1) {
                resetScrollPosition();
                fetchInsightServerInfo();
                fetchContents();
            }
            if (content !== undefined && !content.loading && content.isFetching) {
                resetScrollPosition();
                fetchContents();
            }
        }else if (content !== undefined && !content.loading && !content.isFetching && content.list !== undefined && content.list.length === 0 && content.count < 1 && content.insightFilter !='') {
            resetScrollPosition();
        }
    }, [content]);

    useEffect(()=> {
        if (end) {
            if (content.totalCount > content.filter.startIndex){
                setContent({
                    ...content,
                    filter: {
                        ...content.filter,                   
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end]);

    useEffect(() => {
        perfectScrollbar && perfectScrollbar.current.update();
    }, [view]);

    const rulesetSupportedVersions = rulesetConstants.RULESET_SUPPORT_CONTENT_TYPE;
    if(!isFlashSupported(deviceType,deviceTypeVersion)){
        const idxOfFlash = rulesetSupportedVersions.indexOf('FLASH');
        if(idxOfFlash > -1){
            rulesetSupportedVersions.splice(idxOfFlash,1);
        }
    }

    return (
        <div className="" style={{ display: "inline-flex" }}>
            <div className="lnb">
            {!fetchForAdsContent && <SubTab tabs={subTabs} selected={subTab} viewSize={2} onClick={onClickSubTab} style={{display: 'table',marginLeft: '10px',marginRight: 'auto'}}/>}
                { subTab == subTabs[0] &&
                <ul>
                    <li className={classNames({'on': active === 'ALL'})} onClick={() => activeMenu('ALL')}>
                        <a href="#" id="allContentListBtn">{t("COM_SID_ALL")}</a>
                    </li>
                    {
                        <li className={classNames({'on': active === 'MY_CONTENT'})}
                            onClick={() => activeMenu('MY_CONTENT')}>
                            <a href="#" id="myContentListBtn">{t("TEXT_TITLE_MY_CONTENT_VIEW_P")}</a>
                            {
                                active === 'MY_CONTENT' &&
                                <MyGroups mode={'CONTENT'} selectGroup={selectGroup} deviceType={deviceType}
                                          deviceTypeVersion={deviceTypeVersion} mediaTypes={content.filter.contentTypes}
                                          isValidExpired={false}/>
                            }
                        </li>
                    }
                </ul>
                }
                { subTab == subTabs[1] &&
                <ul className={'insight_ul'}>
                    <li className={classNames({'on': active === 'TOP_10_PLAYED'})} onClick={() => activeMenu('TOP_10_PLAYED')}>
                        <a href="#">{t("MIS_SID_CBFEB_TOP_10_PLAYED")}</a>
                    </li>

                    <li className={classNames({'on': active === 'TOP_10_SOLD'})} onClick={() => activeMenu('TOP_10_SOLD')}>
                        <a href="#">{t("MIS_SID_CBFEB_TOP_10_SOLD")}</a>
                    </li>

                    <li className={classNames({'on': active === 'TOP_10_SALES_INCREASE'})} onClick={() => activeMenu('TOP_10_SALES_INCREASE')}>
                        <a href="#">{t("MIS_SID_CBFEB_TOP_10_SALES_INCREASE")}</a>
                    </li>
                </ul>
                }
            </div>
            <div className="schedule_content_wrap">
                <div className="mt10 mr10 mb10 ml20" style={{ height: 30 }}>
                    <div style={{ float: "left" }}>
                        <span className="space"></span>
                    </div>
                    { multiSelect &&
                        <div style={{ float: "left" }}>
                            <span className="space"></span>
                            <div className="select_box">
                                <button
                                    className="base select"
                                    style={{ minWidth: "70px" }}
                                    onClick={selectAll}                            >
                                    <span>{t("BUTTON_ALL_SELECT_P")}</span>
                                </button>
                            </div>
                        </div>
                    }
                    
                    <div style={{ float: "right", display: "flex" }}>
                        <ContentTypeFilter
                            contentTypes={ fetchForAdsContent ? contentConstants.DEFAULT_MEDIA_SUPPORTED_FOR_ADS_CONTENT : rulesetSupportedVersions }                           
                            onChange={(selected) => handleContentTypes(selected)}
                        />
                        <SwitchListNImage view={view} onClick={handleListNImage} />
                        <SearchBar
                            width={120}
                            onClickSearch={onSearch}
                            placeholder={t("TEXT_CONTENT_NAME_P")}
                        />
                    </div>
                </div>
                <div
                    className="pop_list"
                    id={"select_content_scroll_wrap"}
                    style={{
                        height: "365px",
                        borderTop: "1px solid #e7e7e7",
                        display: "block",
                        position: "absolute",
                        width: "100%"
                    }}
                >
                    {
                        content.loading &&
                        <div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div>

                    }
                    {view === "LIST" && (
                        <ListView
                            contentType = {content.insightFilter==''?'NORMAL':'INSIGHT'}
                            contents={content.list}
                            selectedId={selected}
                            handleSelect={handleSelect}
                            handleInsight={insightInformationPopup}
                            isLoading ={content.loading}
                        />
                    )}
                    {view === "IMAGE" && (
                        <ImageView
                            contents={content.list}
                            selectedId={selected}
                            handleSelect={handleSelect}
                        />
                    )}
                </div>
            </div>
            <div className="selected_content_wrap">
                <div className="mt10 mr10 mb10 ml20 clearfix">
                    <div className="float_l">
                        <div className="select_box float_l mr8">
                            <button
                                className="base select"
                                id="removeAllBtn"
                                style={{ minwidth: "78px" }}
                                onClick={removeAll}
                            >
                                <span>{fetchForAdsContent ? t("COM_BUTTON_DELETE") : t("COM_BUTTON_DELETE_ALL_P")}</span>
                            </button>
                        </div>
                        <div className="select_box float_l mr8" style={{ paddingTop: "5px" }}>
                            <span>{getMixString(["MIS_SID_WEBAUTHOR_MIX_SELECTED_ITEMS", selected.length])}</span>
                            <span id="statisticsSelectedCount"></span>
                        </div>
                    </div>
                </div>
                <div className="thumbnailview_wrap">
                    <div className="pop_list" style={{ height: "356px" }}>
                        <ul className="clearfix mt20" id="statisticsSelectedContentsList">
                            {selected.map((v, i) => (
                                <li>
                                    <div>
                                        <button
                                            className="statisticsDelete"
                                            onClick={() => removeThumb(v.contentId)}
                                        ></button>
                                        <input
                                            type="hidden"
                                            className="contentName"
                                            value={v.contentName}
                                            key = {i}
                                        />
                                        <div
                                            style={{
                                                width: "75px",
                                                height: "61px",
                                                textAlign: "center",
                                                lineHeight: "42px"
                                            }}
                                        >
                                            <ContentThumbnail
                                                id={v.thumbFileId}
                                                width={75}
                                                height={42}
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
