import React, {useEffect, useRef, useState} from 'react';
import Moment from 'moment';
import {
    ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY,
    ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY,
    commonConstants,
    defaultADSchedule
} from "../../../../constants";
import classNames from "classnames";
import {useOutsideMouseUp} from "../../../../helper";
import EventBar from "./EventBar";
import {useDispatch, useSelector} from "react-redux";
import {playlistService} from "../../../../services";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../../actions/popup.action";
import {scheduleAction} from "../../../../actions";


const TimeLines = ({mode, view, slots, tableStartTime, tableEndTime, maxWidth, height, openADPlaylistPopup, onUpdateSchedule}) => {

    const dispatch = useDispatch();
    const eventBarRef = useRef(0);

    useOutsideMouseUp(()=> {
        if (mouseDown) {
            onMouseUp();
        }
        if (mouseOver) {
            onMouseUp();
        }
    })

    const {dragInfo} = useSelector(state => ({
        dragInfo : state.schedules.contents.dragInfo
    }))

    const [mouseDown, setMouseDown] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);

    const [eventBar, setEventBar] = useState(undefined);

    const [timeLines, setTimeLines]  = useState([]);
    const [timeWidth, setTimeWidth] = useState(undefined);

    const onMouseDown = (e, index) => {
        const time  = timeLines[index].time;
        const slotIndex = parseInt(e.target.getAttribute('data-slot-index'), 10);
        const slotId = e.target.getAttribute('data-slot-id');
        setEventBar({
            startIndex: index,
            startTime: time,
            endIndex: index+1,
            endTime: timeLines[index+1].time,
            slotId: slotId
        })
        const top = (height * slotIndex) + 4 + slotIndex;
        eventBarRef.current.style.left = timeWidth * (index)+'px';
        eventBarRef.current.style.top = top+'px';
        eventBarRef.current.style.width = timeWidth - 2+'px';
        eventBarRef.current.style.height = (height-9)+'px';

        const startTime = eventBarRef.current.childNodes[0];
        startTime.innerText = timeLines[index].time.format('HH:mm:ss');

        setMouseDown(true);
    }

    const onMouseUp = (e) => {
        if (mouseDown) {
            setMouseDown(false);
            setMouseOver(false);
            setEventBar({
                ...eventBar,
                start: timeLines[eventBar.startIndex].time,
                end: timeLines[eventBar.endIndex].time,
                showPopup: true
            })
        }
    }

    const onMouseMove = (e, index) => {
        if (mouseDown && e) {
            setEventBar({
                ...eventBar,
                endIndex: index,
                endTime: timeLines[index].time,
            })
            eventBarRef.current.style.width = (timeWidth * (index - eventBar.startIndex)) - 2+'px';
            const startTime = eventBarRef.current.childNodes[0];
            startTime.innerText = timeLines[eventBar.startIndex].time.format('HH:mm:ss')+' - '+ timeLines[index].time.format('HH:mm:ss');
        }
    }

    useEffect(() => {
        const openSelectPopup = () => {
            const {start, end} = eventBar;
            const event = Object.assign({}, defaultADSchedule);

            event.startTime = start.format('HH:mm:ss');
            event.startDate = start.format('YYYY-MM-DD');
            event.stopDate = end.format('YYYY-MM-DD');
            event.stopTime = end.format('HH:mm:ss');

            event.slotId = eventBar.slotId;

            if (eventBar.contentId !== undefined && eventBar.init) {
                dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
                playlistService.fetchPlaylistDetailById(eventBar.contentId)
                    .then(
                        res=> {
                            if (res) {
                                event.contentId = res.items.playlistId;
                                event.contentName = res.items.playlistName;
                                event.thumbnails = res.items.contents.map(content => content.thumbFileId);



                                openADPlaylistPopup(event);
                            }
                        }
                    ).catch(
                        e=> toastr.error(getErrorMessage(e)))
                    .finally(
                        ()=>{
                            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                        }
                    )

            } else {
                openADPlaylistPopup(event);
            }
            if (mouseOver) {
                setMouseOver(false)
            }
            if (mouseDown) {
                setMouseDown(false)
            }
        }
        if (eventBar !== undefined && eventBar.showPopup !== undefined && eventBar.showPopup) {
            openSelectPopup();
        }
    }, [eventBar])


    const onEventBarDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const onDragOver = (e) => {

        if (dragInfo !== undefined) {
            const index=  parseInt(e.target.getAttribute('data-index'), 10);
            const slotIndex = parseInt(e.target.getAttribute('data-slot-index'), 10);

            let endIndex = parseInt(index, 10) + 12;
            if (timeLines.length <= endIndex) {
                endIndex = timeLines.length - 1;
            }
            if (!mouseOver) {
                setEventBar({
                    startIndex: index,
                    startTime: timeLines[index].time,
                    endIndex: endIndex,
                    endTime: timeLines[endIndex].time,
                })
                setMouseOver(true)
            }
            if (mouseOver) {
                const top = (height * slotIndex) + 4 + slotIndex;

                if (index !== undefined && index > -1 && index < timeLines.length) {
                    eventBarRef.current.style.left = timeWidth * (index)+'px';
                    eventBarRef.current.style.top = top+'px';
                    eventBarRef.current.style.width = ((timeWidth * 12)  -2)+'px';
                    eventBarRef.current.style.height = parseInt((height-9), 10)+'px';

                    const startTime = eventBarRef.current.childNodes[0];
                    startTime.innerText = timeLines[index].time.format('HH:mm:ss')+ ' - ' +timeLines[endIndex].time.format('HH:mm:ss');
                    eventBarRef.current.setAttribute('data-slot-id', e.target.getAttribute('data-slot-id'));
                    startTime.setAttribute('data-start-index', index);

                }
            }
        }


    }

    const onEventBarDrop= (e) => {

        const slotId = eventBarRef.current.getAttribute('data-slot-id');

        /*const id = e.dataTransfer.getData('id');
        const name = e.dataTransfer.getData('name');
        const thumbnail = e.dataTransfer.getData('thumbnail');*/
        const {id} = dragInfo;
        const startNode = eventBarRef.current.childNodes[0];
        const startIndex = parseInt(startNode.getAttribute('data-start-index'),10);

        const addTime = view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? 12 : 1;

        const start  = timeLines[startIndex].time;
        const end = timeLines[startIndex+addTime].time;

        setMouseDown(false);

        setEventBar({
            start: start,
            end: end,
            contentId: id,
            slotId: slotId,
            init: true,
            showPopup: true
        })

        dispatch(scheduleAction.destroyDragInfo());

    }



    useEffect(()=> {
        const t  = tableStartTime.clone();
        const timeLineEndTime = Moment(tableEndTime);
        const nowDay = Moment().format('YYYY-MM-DD');

        const timeLines = [];
        let count = 0;
        while(t.isBefore(timeLineEndTime)) {
            timeLines.push({time: t.clone(), format:  t.clone().format('YYYY-MM-DD'), isToday: t.format('YYYY-MM-DD') === nowDay ? true : false, isDay: t.format('H') ==  0 ? true: false})
            view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? t.add(2, 'hours') : t.add(1, 'day')
            count++;
        }

        let timeWidth = (maxWidth - 170) / count;
        if (view === ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY) {
            if (timeWidth < 120) {
                timeWidth = 120;
            }
        }

        setTimeLines(timeLines);
        setTimeWidth(timeWidth);
    }, [tableStartTime, tableEndTime])
    
    return (
        <>
            <div className="sc_main_scroll" style={{width : (timeWidth * timeLines.length) + 2}}>
                <div className="sc_main">
                    {
                        slots !== undefined && slots.length > 0 && slots.map(
                            (slot, slotIndex) =>
                                <div className={'timeLine'} style={{height: height - 2}}>
                                    {
                                        timeLines !== undefined && timeLines.length > 0 && timeLines.map(
                                            (t, index) =>
                                                <div key={index}
                                                     className={classNames('noSelect','tl', {'day' : t.isDay, 'today' : t.isToday})}
                                                     style={{width: timeWidth}} data-time={t.format} data-slot-index={slotIndex} data-index={index} data-slot-id={slot.slotId}
                                                     onMouseDown={(e)=>onMouseDown(e, index)}
                                                     onMouseMove={(e)=>onMouseMove(e, index)}
                                                     onMouseUp={onMouseUp}
                                                     onDragOver={onDragOver}
                                                >
                                                </div>
                                        )
                                    }
                                    {
                                        slot.events !== undefined && slot.events.length > 0 && slot.events.map(
                                            (event, index) =>
                                                <EventBar
                                                    key={index}
                                                    event={event}
                                                    scheduleId={event.scheduleId}
                                                    mode={mode}
                                                    slotId={slot.slotId}
                                                    height={height}
                                                    timeWidth={timeWidth}
                                                    maxWidth={(timeWidth * (timeLines.length)) + 2}
                                                    view={view}
                                                    tableStartTime={tableStartTime}
                                                    tableEndTime={tableEndTime}
                                                    onUpdateSchedule={onUpdateSchedule}
                                                    openADPlaylistPopup={openADPlaylistPopup}
                                                    slotIndex={slotIndex}
                                                    slots={slots}
                                                />
                                        )
                                    }
                                </div>
                        )
                    }
                </div>
            </div>
            <div className="schedule_overlay" >
                <div className={'overlayBar'}
                     style={{width:0, height:'0px', top:0, left: 0, display: mouseOver || mouseDown ? '' : 'none'}} ref={eventBarRef}
                     onDragOver={onEventBarDragOver}
                     onDrop={onEventBarDrop}
                >
                    <span className="start_time"/>
                    <span className="end_time"/>
                </div>
            </div>
        </>


    )
}
export default TimeLines;