import React from 'react';
import classNames from 'classnames';
import './DeviceTransition.css';

export const DeviceFadeInTransition = ({duration, transition, classes, children}) => {
    return (
        <div className={classNames('device_fade_in_wrap', {'transition': transition}, ...[classes])}
            style={{transitionDuration: duration}}>
            {children}
        </div>
    );
};

export const DeviceFadeOutTransition = ({duration, transition, classes, children}) => {
    return (
        <div className={classNames('device_fade_out_wrap', {'transition': transition}, ...[classes])}
            style={{transitionDuration: duration}}>
            {children}
        </div>
    );
};
