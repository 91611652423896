
export const dashboardConstants = {

    UNAPPROVED_CONTENT : 'content',
    UNAPPROVED_DEVICE: 'device',
    UNAPPROVED_USER: 'user',

    CONTENT_RESOURCE : 'CONTENT',
    PLAYLIST_RESOURCE : 'PLAYLIST',
    RULE_RESOURCE : 'RULESET',
    SCHEDULE_RESOURCE : 'SCHEDULE',

    CONNECTED_RESOURCE : 'CONNECTED_RESOURCE',
    DISCONNECTED_RESOURCE : 'DISCONNECTED_RESOURCE',
    ERROR_RESOURCE : 'ERROR_RESOURCE',
    WARNING_RESOURCE : 'WARNING_RESOURCE',


    ADD_NOTICE_POPUP : 'ADD_NOTICE_POPUP',
    EDIT_NOTICE_POPUP: 'EDIT_NOTICE_POPUP',

    DASHBOARD_CUSTOM_STORAGE_WIDGET : 3,
    DASHBOARD_CUSTOM_USER_WIDGET : 8,
    DASHBOARD_CUSTOM_SOFTWARE_UPDATE_WIDGET : 15,
    DASHBOARD_CUSTOM_EXTERNAL_SERVER_WIDGET : 16,

    DASHBOARD_WIDGET_POPUP: 'DASHBOARD_WIDGET_POPUP'
}


export const DASHBOARD_ACTION = {
    UPDATE_WIDGETS : 'UPDATE_WIDGETS',
    RELOAD_DASHBOARD: 'RELOAD_DASHBOARD'
}