import React from 'react'
import FLASHIcon from '../../../images/icon/file_type_icon_06.png';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const FLASH = ({onlyIcon = false, embedded = false}) => {
    const {t} = useTranslation();
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={FLASHIcon}/>
            {
                !onlyIcon && t('TEXT_TITLE_FLASH_P')
            }

        </span>
    )
}
export default FLASH;