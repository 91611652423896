import React, {useEffect, useState} from 'react';
import {Bar, Doughnut} from 'react-chartjs-2';
import './Statistics.css';
import './Summary.css';
import {useTranslation} from 'react-i18next';
import {statisticsService} from '../../services';
import {connect} from 'react-redux';
import {chartColor, optionBar, optionDoughnut} from "../../constants/StatisticsConstants";
import {toastr} from 'helper/toastrIntercept';
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

const Summary = ({currContent}) => {
    const {t} = useTranslation();
    const user = JSON.parse(localStorage.getItem('user'));

    const [connectionStatus, setConnectionStatus] = useState({
        datasets: [{
            data: [],
            backgroundColor: ['#2b8cbe', '#a6bddb',],
            hoverBackgroundColor: ['#f03b20', '#f03b20'],
        }],
    });

    const [approvalStatus, setApprovalStatus] = useState({
        datasets: [{
            data: [],
            backgroundColor: ['#78c679', '#c2e699',],
            hoverBackgroundColor: ['#f03b20', '#f03b20',],
        }],
    });

    const [registeredContents, setRegisteredContents] = useState({
        labels: [],
        datasets: [{
            backgroundColor: chartColor(1),
            borderWidth: 1,
            data: [],
        }],
    });
    const [connectionTotal, setConnectionTotal] = useState(0);
    const [approvalTotal, setApprovalTotal] = useState(0);
    const [sum, setSum] = useState(0);
    const [loadingbar, setLoadingBar] = useState(false);
    const fetchData = () => {
        setLoadingBar(true);
        Promise.all([
            statisticsService.fetchStatisticsSummary('connectionStatus'),
            statisticsService.fetchStatisticsSummary('approvalStatus'),
            statisticsService.fetchStatisticsSummary('registeredContents'),
        ]).then(res => {
            let connectionStatus = res[0].items;
            setConnectionTotal(Number(connectionStatus.connectedData.value) + Number(connectionStatus.disconnectedData.value));
            setConnectionStatus({
                labels: [
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_CONNECTED_DEVICE_NUMBER_P'),
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_DISCONNECTED_DEVICE_NUMBER_P')
                ],
                datasets: [{
                    data: [
                        connectionStatus.connectedData.value,
                        connectionStatus.disconnectedData.value,
                    ],
                    backgroundColor: [
                        connectionStatus.connectedData.color,
                        connectionStatus.disconnectedData.color,
                    ],
                    hoverBackgroundColor: [
                        connectionStatus.connectedData.highlight,
                        connectionStatus.disconnectedData.highlight,
                    ]
                }],
            });
            let approvalStatus = res[1].items;
            setApprovalTotal(Number(approvalStatus.approvedData.value) + Number(approvalStatus.unapprovedData.value));
            setApprovalStatus({
                labels: [
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_APPROVE_DEVICE_NUMBER_P'),
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_UNAPPROVE_DEVICE_NUMBER_P')
                ],
                datasets: [{
                    data: [
                        approvalStatus.approvedData.value,
                        approvalStatus.unapprovedData.value,
                    ],
                    backgroundColor: [
                        approvalStatus.approvedData.color,
                        approvalStatus.unapprovedData.color,
                    ],
                    hoverBackgroundColor: [
                        approvalStatus.approvedData.highlight,
                        approvalStatus.unapprovedData.highlight,
                    ]
                }],
            });
            let registeredContents = res[2].items;
            setSum(registeredContents.count.reduce((a, b) => (Number(a) + Number(b)), 0));
            setRegisteredContents({
                labels: registeredContents.type,
                datasets: [{
                    data: registeredContents.count,
                    backgroundColor: chartColor(registeredContents.count.length)
                }],
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    useEffect(() => {
        fetchData();
    }, []);

    const {serverAdmin = false} = user;
    return (
        <div className="statistics_summary" style={{display: currContent === 'SUMMARY' ? 'block':'none'}}>
            <div className="mt35 clearfix">
                <ul>
                    <li>
                        <h6>{t('MESSAGE_STATISTICS_SUMMARY_CONNECTION_TITLE_P')}</h6>
                        <div>
                            <span>
                                <span className="bgc_369860"/>
                                {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_CONNECTED_DEVICE_NUMBER_P')}
                            </span>
                            <span>
                                <span className="bgc_2ecc71"/>
                                {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_DISCONNECTED_DEVICE_NUMBER_P')}
                            </span>
                            <br/>
                            <Doughnut data={connectionStatus} options={optionDoughnut} width={220} height={220}/>
                            <div className="donut-inner"><h3>{connectionTotal}</h3></div>
                        </div>
                    </li>
                    {serverAdmin &&
                    <li>
                        <h6>{t('MESSAGE_STATISTICS_SUMMARY_APPROVE_TITLE_P')}</h6>
                        <div>
                            <span>
                                <span className="bgc_5a3756"/>
                                {t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_APPROVE_DEVICE_NUMBER_P')}
                            </span>
                            <span>
                                <span className="bgc_913d88"/>
                                {t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_UNAPPROVE_DEVICE_NUMBER_P')}
                            </span>
                            <br/>
                            <Doughnut data={approvalStatus} options={optionDoughnut} width={220} height={220}/>
                            <div className="donut-inner"><h3>{approvalTotal}</h3></div>
                        </div>
                    </li>
                    }
                </ul>
            </div>
            <div className="mt15 clearfix">
                <h6>
                    {t('MESSAGE_STATISTICS_SUMMARY_CONTENT_TITLE_P')}
                    <span className="numbering ml10">{sum}</span>
                </h6>
                <div className="ml0">
                    <Bar data={registeredContents} width={800} height={300} options={optionBar}/>
                </div>
            </div>
            {loadingbar && <LoadingBar />}
        </div>
    );
};

export default connect()(Summary);