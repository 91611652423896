import React from 'react'
import FTPIcon from '../../../images/icon/file_type_icon_12.png';
import classNames from "classnames";

const FTP = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={FTPIcon}/>
            {
                !onlyIcon && 'FTP'
            }

        </span>
    )
}
export default FTP;