import React from 'react'
import CIFSIcon from '../../../images/icon/file_type_icon_13.png';
import classNames from 'classnames';

const CIFS = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={CIFSIcon}/>
            {
                !onlyIcon && 'CIFS'
            }
        </span>
    )
}
export default CIFS;