import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../switch/SwitchListNImage";
import SearchBar from "../../search/SearchBar";
import classNames from "classnames";
import ContentThumbnail from "../../image/ContentThumbnail";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import {toastr} from 'helper/toastrIntercept';
import {snakeCase} from "lodash";
import PerfectScrollbar from 'perfect-scrollbar';
import {rulesetService} from "../../../services/ruleset.service";
import DateToStr from "../../utils/DateToStr";
import {Loading} from "../../loading/Loading";
import {getErrorMessage} from "../../../helper/responseHandler";

const ListView = ({ruleSets, selectedId, handleSelect}) => {

    return (
        <table style={{width: '100%'}}>
            <colgroup>
                <col width="87px"/>
                <col width=""/>
            </colgroup>
            <tbody>
            {
                ruleSets !== undefined && ruleSets.length > 0 &&
                ruleSets.map(
                    item  =>
                        <tr key={item.rulesetId} className={classNames({'on' : selectedId !== undefined && selectedId === item.rulesetId})} onClick={()=>handleSelect(item.rulesetId, item.name, item.totalSize, item.thumbFileId)}>
                            <td className="thum">
                                <div className="thumb_wrap">
                                    <div style={{width:'75px',height:'42px',textAlign:'center',lineHeight:'42px'}}>
                                        <ContentThumbnail id={item.thumbFileId} width={75} height={42}/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h4 className="contentName">
                                    <span className="text-overflow" style={{width:'300px'}}>{item.name}</span>
                                </h4>
                                <ul>
                                    <li className="supportedIcon">
                                        <SupportedDeviceIcon deviceType={item.deviceType} deviceTypeVersion={item.deviceTypeVersion}/>
                                    </li>
                                    {/*<li><span><Size size={item.totalSize}/></span></li>*/}
                                    <li><span><DateToStr date={item.modifyDate} newLine={false}/></span></li>
                                </ul>
                            </td>
                        </tr>
                )
            }
            </tbody>
        </table>
    )
}

const ImageView  = ({ruleSets, selectedId, handleSelect}) => {

    return (
        <div className={'thumbnailView_wrap'}>
            <ul className="clearfix mt20">
                {
                    ruleSets !== undefined && ruleSets.length > 0 &&
                    ruleSets.map(
                        item =>
                            <li>
                                <div style={{cursor:'pointer'}} className={classNames('thumbview_box', {'on' : selectedId !== undefined && selectedId === item.rulesetId})} onClick={()=>handleSelect(item.rulesetId, item.name, item.totalSize, item.thumbFileId)}>
                                    <div style={{width:75,height:42,textAlign:'center'}}>
                                        <ContentThumbnail id={item.thumbFileId} width={75} height={42}/>
                                    </div>
                                    <div className="device_info"><span className="contentName">{item.name}</span></div>
                                    <div className="check"><span></span></div></div>
                            </li>
                    )
                }
            </ul>
        </div>
    )
}

export const RuleSets = ({onSelect, selectedId, deviceType, deviceTypeVersion}) => {

    const {t} = useTranslation();

    const [view, setView] = useState('LIST');

    const [ruleSet, setRuleSet] = useState({
        filter: {
            startIndex:	1,
            pageSize: 25,
            deviceType: deviceType,
            deviceTypeVersion: deviceTypeVersion,
            sortColumn: snakeCase('modifyDate').toUpperCase(),
            sortOrder:	'DESC',
            includeTotalSize: true
        },
        list: [],
        isFetching: false,
        loading: false,
        count: 0
    });

    const [end, setEnd] = useState(false);

    const handleSelect = (id, name, totalSize, thumbFileId) => {
        if (onSelect) {
            onSelect(id, name, totalSize, thumbFileId);
        }
    };

    const handleListNImage = () => {
        setView(view === 'LIST' ? 'IMAGE' : 'LIST');
        resetScrollPosition();
    };

    const onSearch = (value) => {
        setRuleSet({...ruleSet, list: [], count: 0, loading: false, isFetching: false, filter: {...ruleSet.filter, searchText: value}});
    };


    const fetchRulesets = () => {
        setRuleSet({
            ...ruleSet,
            loading: true
        })
        rulesetService.fetchRulesetFilter(ruleSet.filter)
            .then(res => {
                setRuleSet({
                    ...ruleSet,
                    list: ruleSet.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: ruleSet.count + 1
                })
            }).catch((e) => {
                if(e.errorCode === 408900) {
                    return;
                }

                toastr.error(getErrorMessage(e));
            });
        
    };

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_playlist_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    };

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    useEffect(()=>{
        if (!initScroll.current) {
            const ps = new PerfectScrollbar('#select_rulset_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_rulset_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            perfectScrollbar.current = ps;
            initScroll.current = true;
        } else {
            perfectScrollbar.current.update();
        }

    }, [ruleSet.list]);

    useEffect(()=> {
        if (end) {
            if (ruleSet.totalCount > ruleSet.filter.startIndex){
                setRuleSet({
                    ...ruleSet,
                    filter: {
                        ...ruleSet.filter,
                        startIndex: ruleSet.filter.startIndex + 25
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end]);

    useEffect(()=>{
        if (ruleSet !== undefined && !ruleSet.loading && !ruleSet.isFetching && ruleSet.list !== undefined && ruleSet.list.length === 0 && ruleSet.count < 1) {
            resetScrollPosition();
            fetchRulesets();
        }
        if (ruleSet !== undefined && !ruleSet.loading && ruleSet.isFetching) {
            resetScrollPosition();
            fetchRulesets();
        }
    }, [ruleSet]);

    useEffect(()=> {
        perfectScrollbar && perfectScrollbar.current.update();
    }, [view]);

    return (
        <div className="" style={{display: 'inline-flex'}}>
            <div className="lnb">
                <ul>
                    <li className="on" onClick={()=>{
                        setRuleSet({
                            filter: {
                                startIndex:	1,
                                pageSize: 25,
                                deviceType: deviceType,
                                deviceTypeVersion: deviceTypeVersion,
                                sortColumn: snakeCase('modifyDate').toUpperCase(),
                                sortOrder:	'DESC'
                            },
                            list: [],
                            isFetching: false,
                            loading: false,
                            count: 0
                        });
                    }}>
                        <a href="#" id="allContentListBtn">{t("COM_SID_ALL")}</a>
                    </li>
                </ul>
            </div>
            <div className="schedule_content_wrap">
                <div className="mt10 mr10 mb10 ml20" style={{height: 30}}>
                    <div style={{float: 'left'}}>
                        <span className="space"></span>
                    </div>
                    <div style={{float: 'right'}}>
                        {/*<Filter type={'schedule'}/>*/}
                        <SwitchListNImage view={view} onClick={handleListNImage}/>
                        <SearchBar width={150} onClickSearch={onSearch} placeholder={t("MIS_SID_RULESET")}/>
                    </div>
                </div>
                <div className="pop_list" id={'select_rulset_scroll_wrap'} style={{height:'365px', borderTop: '1px solid #e7e7e7', display: 'block', position: 'absolute', width: '100%'}}>
                    {
                        ruleSet.loading &&
                        <div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div>

                    }
                    {
                        view === 'LIST' &&
                        <ListView ruleSets={ruleSet.list} selectedId={selectedId} handleSelect={handleSelect}/>
                    }
                    {
                        view === 'IMAGE' &&
                        <ImageView ruleSets={ruleSet.list} selectedId={selectedId} handleSelect={handleSelect}/>
                    }
                </div>
            </div>
        </div>
    )

}