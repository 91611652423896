import {DEVICE_ACTIONS} from '../constants';

const initialState = {

};

export const device = (state = initialState, action) => {
    switch(action.type) {
        case DEVICE_ACTIONS.LOAD_LEDCABINET:
            return {...state, ledCabinetDeviceId: action.deviceId};
        case DEVICE_ACTIONS.REFRESH_DEVICES:
            return {...state, refreshIds: action.deviceIds};
        case DEVICE_ACTIONS.SOFTWARE_DOWNLOADING:
            return {...state, downloading: action.downloading};
        case DEVICE_ACTIONS.CUSTOMIZE_DOWNLOADING:
            return {...state, customizeDownloading: action.downloading};
        default:
            return state;
    }
};