import React from 'react';
import './ProgressBar.css';
import {ContentScheduleFiller, Filler} from "./Filler";

const ProgressBar = ({width, height, background, percentage, fillerColor, useLabel, title, close, onClickProgress}) => {
    return (
        <div className="progress_bar" style={{width: width, height:height, background:background}} onClick={onClickProgress}>
            {
                title !== undefined && title !== '' ?
                    <ContentScheduleFiller percentage={percentage} fillerColor={fillerColor} title={title} close={close}/>
                    :
                    <Filler percentage={percentage} fillerColor={fillerColor} useLabel={useLabel}/>
            }

        </div>
    )
};

export default (ProgressBar);