import React, {useRef, useState} from 'react'
import FilterImage from '../../../images/bg/filter_arrow.png';
import {useTranslation} from "react-i18next";
import {useOutsideClick} from "../../../helper";
import './TutorialPopup.css';
import {popupAction} from "../../../actions";
import {useDispatch} from "react-redux";
import {commonConstants} from "../../../constants";

const TutorialPopup = () => {

    const helpSections = window.globalConfig.helpSections || [];    
    const [show, setShow] = useState(false);
    const {t} = useTranslation();
    const ref = useRef();
    const dispatch = useDispatch();

    useOutsideClick(ref, ()=> {
        if (show){
            setShow(false)
        }
    })

    const showPopup = () => {
        setShow(!show);
    }

    const handleTutorial = (tutorial) => {
        window.open(tutorial.url, "_blank");
    }

    const renderAllHelpLinks =()=>
    {        
        return (<>
            {   
            helpSections.map(
                (section,index) =>
                <>
                {section.showTitle && <h3>{section.title}</h3>}
                {section.data != undefined  &&  section.data.length>0 && renderSectionLinks(section,index)}               
                {section.showRuleAtBottom && <div className="clearfix"></div>}
                </>)
            }            
        </>)
    }
    
 const renderSectionLinks=(section,index)=>
 {
    if(section.showTitle)
    {
        return <>
        <div className="user_detail">
            <ul id={'tutorialMovieList'+index}>
                {
                   renderAllLinks(section,true)
                }
                </ul>
            </div>
        </>
    }    
    return  renderAllLinks(section,false);            
 }
 const renderAllLinks=(section,isLI)=>{     
    const finalData=section.data.map((link, index)=>{                
        return isLI
                ?<li key={index} title={t(link.comment)}>{renderSpan(link)}</li>
                :<h3 style={{cursor: 'pointer'}} key={index} title={t(link.comment)}>{renderSpan(link)}</h3>
       });
    return finalData;    
 }
 const renderSpan=(link)=>
 {    
    return <>
    <span onClick={()=>handleTutorial(link)}>{t(link.text)}</span>
    </>    
 } 

    return (
        <div className={'tutorial_popup'}>
            <button type="button" id="tutorialBtn" className="mr8" onClick={showPopup} ></button>
            <div className={'dim'} style={{display: show ? '':'none'}}></div>
            <div className="tutorial_wrap" style={{display: show ? '':'none', zIndex: 101}} ref={ref}>
                
                <span>
                    <img src={FilterImage}/>
                </span>
                {
                    renderAllHelpLinks()
                }  
                            
             </div>
        </div>

    )
}
export default TutorialPopup;