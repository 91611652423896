import React from 'react';
import {ProgressConstants} from "../constants/ProgressConstants";

const defaultState = {
    progresses: []
}

export const progress = (state = defaultState, action) => {
    switch (action.type) {

        case ProgressConstants.ADD_PROGRESS:
            const progress = {mode: action.mode, id: action.id, title: action.title};
            const foundProgress = state.progresses.find(progress => progress.id === action.id);
            if (foundProgress === undefined) {
                return {
                    progresses: state.progresses.concat(progress)
                }
            }
        case ProgressConstants.UPDATE_PROGRESS:

            return {
                progresses: state.progresses.map(
                    progress => progress.id === action.id ? {...progress, percent: action.percent} : progress
                )
            }

        case ProgressConstants.REMOVE_PROGRESS:

            return {
                progresses: state.progresses.filter(
                    progress => progress.id !== action.id
                )
            }


        default:
            return state;
    }
}
