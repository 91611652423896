import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useOutsideClick} from "../../helper";
import {scheduleService} from "../../services";
import {useDispatch} from "react-redux";
import {popupAction} from "../../actions";
import {commonConstants} from "../../constants";
import {getErrorMessage} from "../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';

const ContextMenuForFrame = ({templateId, reloadTemplateFrames, editTemplateFrame}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef();


    useOutsideClick(ref, () => {
        if (contextMenu.show) {
            setContextMenu({show: false});
        }
    })

    const [contextMenu, setContextMenu] = useState({
        show: false
    })

    const showContextMenu = (e) => {
        e.stopPropagation();
        setContextMenu({
            show: !contextMenu.show
        })
    }

    const editFrame = (e) => {
        e.stopPropagation();
        if (editTemplateFrame) {
            editTemplateFrame();
        }
    }

    const deleteFrame = (e) => {
        e.stopPropagation();
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        scheduleService.deleteTemplateFrameById(templateId)
            .then(res=> {
                toastr.success(t("MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P"))
                if (reloadTemplateFrames) {
                    reloadTemplateFrames();
                }
            })
            .catch(e=> toastr.error(getErrorMessage(e)))
            .finally(
                ()=> dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
            )
    }

    return (
        <div className="button" style={{position:'absolute',top:1, right:2}}>
            <button className="base circle_btn more frame_context_menu" style={{backgroundColor: 'transparent', zIndex:1, position: 'relative'}} onClick={showContextMenu}>
                <span></span>
            </button>
            <div style={{display: contextMenu.show ? '' : 'none', position: 'absolute', padding: 5, border: '1px solid #c5c5c5', backgroundColor: '#fff', zIndex: 1, width: 100}} ref={ref}>
                <ul>
                    <li onClick={editFrame}>{t("MIS_TITLE_FRAME_EDIT_P")}</li>
                    <li onClick={deleteFrame}>{t("MIS_BUTTON_DELETE_TEMPLATE_P")}</li>
                </ul>
            </div>
        </div>
    )
}
export default ContextMenuForFrame;