import React from 'react';
import LPLAYER from '../../../images/icon/LPLAYER.png';

const LitePlayer = ({deviceTypeVersion, text, disabled=false}) => {
    return(
        <span className={"device_icon"}>
            <img src={LPLAYER} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
            {text ? 'Lite Player' : ''}
        </span>
    )
}
export default LitePlayer