import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import DeviceNumberInput from '../../device/DeviceNumberInput';
import RadioGroup from '../../radio/RadioGroup';

const analogDigitalOptions = [
    {value: false, title: 'DID_LFD_ANALOG'},
    {value: true, title: 'DID_LFD_DIGITAL'},
];

const airCableOptions = [
    {value: false, title: 'DID_LFD_AIR'},
    {value: true, title: 'DID_LFD_CABLE'},
];

const TvChannelPopup = ({onSave, onClose}) => {
    const {t} = useTranslation();
    const [channel, setChannel] = useState({
        majorChannel: 0,
        minorChannel: 0,
    });

    const onClickSave = () => {
        onSave && onSave(channel);
    };

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_TITLE_EDIT_CHANNEL_P'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 400,
                height: 180,
                onClose
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}
        >
            <div class="detail_view mini_p mb15">
                <table>
                    <colgroup>
                        <col width="35%"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{`${t('DID_LFD_ANALOG')} / ${t('DID_LFD_DIGITAL')}`}</th>
                            <td>
                                <RadioGroup selects={analogDigitalOptions} value={channel.analogDigital} onChange={(e, value) => setChannel({...channel, analogDigital: value})} />
                                {/* <input id="analogDigitalfalse" style="display:none" name="analogDigital" type="radio" value="false"><label for="analogDigitalfalse" class="mr30">'+$.i18n.prop('DID_LFD_ANALOG')+'</label><input id="analogDigitaltrue" style="display:none" name="analogDigital" type="radio" value="true"><label for="analogDigitaltrue" class="mr30">'+$.i18n.prop('DID_LFD_DIGITAL')+'</label> */}
                            </td>
                        </tr>
                        <tr>
                            <th>{`${t('DID_LFD_AIR')} / ${t('DID_LFD_CABLE')}`}</th>
                            <td>
                                <RadioGroup selects={airCableOptions} value={channel.airCable} onChange={(e, value) => setChannel({...channel, airCable: value})} />
                                {/* <input id="airCablefalse" style="display:none" name="airCable" type="radio" value="false"><label for="airCablefalse" class="mr30">'+$.i18n.prop('DID_LFD_AIR')+'</label><input id="airCabletrue" style="display:none" name="airCable" type="radio" value="true"><label for="airCabletrue" class="mr30">'+$.i18n.prop('DID_LFD_CABLE')+'</label> */}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_LFD_MAJOR_CH')}</th>
                            <td>
                                <DeviceNumberInput width={150} min={0} max={9999} value={channel.majorChannel} onChange={(e, value) => setChannel({...channel, majorChannel: value})} />
                                {/* <div class="control_chn" style="width:150px;"><button class="down"></button><input type="text" id="tv_channel_major_channel" title="" name="" value="" rel="0:9999:1" style="width:47px"><button class="up"></button></div> */}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_LFD_MINOR_CH')}</th>
                            <td>
                                <DeviceNumberInput width={150} min={0} max={9999} value={channel.minorChannel} onChange={(e, value) => setChannel({...channel, minorChannel: value})} />
                                {/* <div class="control_chn" style="width:150px;"><button class="down"></button><input type="text" id="tv_channel_minor_channel" title="" name="" value="" rel="0:9999:1" style="width:47px"><button class="up"></button></div> */}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

export default TvChannelPopup;