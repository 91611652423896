import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../RuleNode.css';
import { popupAction } from "../../../actions";
import { commonConstants } from '../../../constants';

const VerificationCountingBadge = (props) => {
    const dispatch = useDispatch();

    let deviceIds = props.deviceIds;
    if(deviceIds === undefined) {
        deviceIds = [];
    }

    const id = props.id;

    const {newRuleset, editRuleset, currentTab} = useSelector(state => ({
        newRuleset : state.rulesets.newRuleset,
        editRuleset: state.rulesets.editRuleset,
        currentTab: state.menu.currentContentId
    }));

    const deviceMap = currentTab === "NEW_RULESET" ? newRuleset.ruleset.verificationDeviceMap : editRuleset.ruleset.verificationDeviceMap;

    return (
        <div className="verification-mode-result-count" onClick={(e) => {
            e.preventDefault();
            let devices = [];
            deviceIds.map((value) => {
                const device = deviceMap.get(value);
                devices.push(device);
            });

            dispatch(popupAction.addPopup({
                type: commonConstants.RULESET_VERIFICATION_DEVICE_LIST_POPUP,
                id: id,
                devices: devices
            }));
        }}>{deviceIds.length}</div>
    )
}

export default VerificationCountingBadge;