import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {DragSource} from "react-dnd";
import {ItemTypes} from "./ItemTypes";
import {contentService} from "../../services";
import ZoomInPopup from "../../components/popup/playlist/ZoomInPopup";
import {popupAction} from "../../actions";
import {commonConstants} from "../../constants";
import {useDispatch} from "react-redux";

const DraggableItem = ({item, index, connectDragSource, connectDragPreview, onClick, onLoadedImageSource, type}) => {
    const [imageUrl, setImageUrl] = useState('');
    const [showZoomInPopup, setShowZoomInPopup] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => connectDragPreview(img);
    });

    useEffect(()=> {
        setImageUrl("");
        const param = {
            width : 100,
            height : 60
        };
        const thumbId = item.playlistType === "6" ? item.thumbnailInfo.split("|")[0] : item.thumbFileId;
        Object.keys(param).forEach(key => param[key] === undefined && delete param[key]);
        contentService.fetchContentThumbnail(thumbId, param).then((res) => {
            if (res && res.body && res.body.items) {
                const {src} = res.body.items;
                setImageUrl(src);
                onLoadedImageSource(index, src);
            }
        }).catch((err)=> {
            onLoadedImageSource(index);
        });
    }, [item]);

    return connectDragSource(
        <div className={classNames({"thumbview_box" : true})}
             style={{width:100, height:'auto', textAlign:'center', cursor:'pointer'}}
             key={item.id}
             index={index}
             onClick={(e)=> {!showZoomInPopup && onClick(item)}}
        >
            {
                showZoomInPopup &&
                <ZoomInPopup
                    onClose={(e)=> {e.stopPropagation();setShowZoomInPopup(false)}}
                    content={item}
                />
            }
            <img style={{verticalAlign: 'middle'}} src={imageUrl}/>
            {
                !type &&
                <div className="device_info">
                    <div style={{display:'inline-block'}}>
                        <span>{item.contentName || item.playlistName}</span>
                    </div>
                    {
                        (item.mediaType === 'IMAGE' || item.mediaType === 'MOVIE') &&
                        <button className={"zoomInBtn"} onClick={(e) => {
                            e.stopPropagation();
                            dispatch(popupAction.addPopup({id: 'FETCH_TAG_CONDITION_LOADING', type: commonConstants.LOADING_POPUP}));
                            setShowZoomInPopup(true)
                        }}/>
                    }
                </div>
            }
        </div>
    )
}

export default DragSource(
    ItemTypes.ContentItem,
    {
        beginDrag: (props, monitor, component) => {
            return {dragItem: props.item};
        },
        isDragging(props, monitor) {
            return monitor.getItem().id === props.id
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }),
)(DraggableItem)