import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import Moment from 'moment';
import {statisticsService} from '../../services';
import './PlayFrequency.css';
import SelectBox from '../../components/selectbox/SelectBox';
import {StatisticsCalendar} from './StatisticsCalendar';
import {commonConstants} from '../../constants';
import {popupAction} from '../../actions';
import {toastr} from 'helper/toastrIntercept';
import bullet_2 from "../../images/icon/bullet_2.png"
import fileDownload from "js-file-download";
import {connect} from "react-redux";
import ReactTable from "react-table";
import {Bar} from "react-chartjs-2";
import {cloneDeep} from "lodash";
import WhiteButton from "../../components/button/WhiteButton";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

const user = JSON.parse(localStorage.getItem('user'));

const timeOptions = [
    {value: 'yesterday', title: 'MIS_TEXT_STATISTICS_POP_YESTERDAY_P'},
    {value: 'this_week', title: 'MIS_TEXT_STATISTICS_POP_THISWEEK_P'},
    {value: 'last_week', title: 'MIS_TEXT_STATISTICS_POP_LASTWEEK_P'},
    {value: 'this_month', title: 'MIS_TEXT_STATISTICS_POP_THISMONTH_P'},
    {value: 'last_month', title: 'MIS_TEXT_STATISTICS_POP_LASTMONTH_P'},
    {value: 'this_quarter', title: 'MIS_TEXT_STATISTICS_POP_THISQUARTER_P'},
    {value: 'last_quarter', title: 'MIS_TEXT_STATISTICS_POP_LASTQUARTER_P'},
    {value: 'this_year', title: 'COM_MIS_TEXT_STATISTICS_POP_THISYEAR_P'},
    {value: 'last_year', title: 'MIS_TEXT_STATISTICS_POP_LASTYEAR_P'},
    {value: 'custom', title: 'COM_SID_CUSTOM'},
];

const unitOptionsDay = [
    {value: 'day', title: 'TEXT_DAY_P'},
    {value: 'hour', title: 'COM_TEXT_HOUR_P'},
];

const unitOptionsWeek = [
    {value: 'week', title: 'MIS_TEXT_STATISTICS_POP_WEEK_P'},
    {value: 'day', title: 'TEXT_DAY_P'},
    {value: 'hour', title: 'COM_TEXT_HOUR_P'},
];

const unitOptionsMonth = [
    {value: 'month', title: 'TEXT_MONTH2_P'},
    {value: 'dow', title: 'TEXT_WEEKDAY_P'},
];

const unitOptionsQuarter = [
    {value: 'month', title: 'TEXT_MONTH2_P'},
    {value: 'quarter', title: 'MIS_TEXT_STATISTICS_POP_QUARTER_P'},
];

const unitOptionsYear = [
    {value: 'year', title: 'MIS_TEXT_STATISTICS_POP_YEAR_P'},
    {value: 'quarter', title: 'MIS_TEXT_STATISTICS_POP_QUARTER_P'},
];

class PlayFrequency extends Component {
    state = {
        contents: [],
        groups: [],
        items: [],
        loading: false,
        loadingbar: false,
        exportEnable: false,
        period: {
            time: '', unit: '', startDate: new Date(), endDate: new Date(),
        },
        unitOptions: unitOptionsDay,
        playCountChartData: {},
        playDurationChartData: {},
        isSearched : false,
        sorted: [{id: 'durationString', desc: false}],
        request: {
            groupIds: undefined,
            contentIds: undefined,
            time: undefined,
            unit: undefined,
            startDate: undefined,
            endDate: undefined,
        }
    };

    constructor(props) {
        super(props);
        this.tick = {beginAtZero: true, min: 0};
    }

    onClickExport = (docType) => {
        const {groupIds, contentIds, time, unit, startDate, endDate} = this.state.request;
        let param = {
            data: "playFrequency",
            groupIds: groupIds,
            contentIds: contentIds,
            time: time,
            unit: unit,
            startDate: startDate,
            endDate: endDate,
        };
        this.setState({loadingbar: true});
        statisticsService.fetchStatisticsContentsFileDownload(docType, param).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loadingbar: false});});
    };

    onClickSearch = () => {
        const {t} = this.props;
        const {groups, contents, period} = this.state;
        const {time, unit, startDate, endDate} = period;
        let groupIds = groups.map(group => Number(group.groupId));
        let contentIds = contents.map(content => content.contentId);
        if (!contentIds || contentIds.length < 1) {
            toastr.warning(t('MIS_TEXT_STATISTICS_POP_SELECT_GUIDE_CONTENT_P'));
        } else if (!groupIds || groupIds.length < 1) {
            toastr.warning(t('MIS_TEXT_STATISTICS_POP_SELECT_GUIDE_DEVICE_P'));
        } else if (time === '') {
            toastr.warning(t('MESSAGE_SCHEDULE_SELECT_PERIOD_P'));
        } else {
            this.fetchGetPlayFrequencyData(contentIds, groupIds, time, unit, startDate, endDate);
        }
    };

    onClickTimeOption = (time) => {
        let unit = '';
        let unitOptions = {};
        switch (time) {
            case 'custom':
            case 'yesterday':
                unitOptions = unitOptionsDay;
                unit = unitOptionsDay[0].value;
                break;
            case 'this_week':
            case 'last_week':
                unitOptions = unitOptionsWeek;
                unit = unitOptionsWeek[1].value;
                break;
            case 'this_month':
            case 'last_month':
                unitOptions = unitOptionsMonth;
                unit = unitOptionsMonth[0].value;
                break;
            case 'this_quarter':
            case 'last_quarter':
                unitOptions = unitOptionsQuarter;
                unit = unitOptionsQuarter[0].value;
                break;
            case 'this_year':
            case 'last_year':
                unitOptions = unitOptionsYear;
                unit = unitOptionsYear[0].value;
                break;
        }
        this.setState({
            unitOptions: unitOptions,
            period: {
                ...this.state.period,
                time,
                unit
            }
        });
    };

    onClickUnitOption = (unit) => {
        this.setState({
            period: {
                ...this.state.period,
                unit
            }
        });
    };

    saveContent = (contents) => {
        if (contents && contents.length > 0) {
            const newArr = [];
            const cnts = this.state.contents;
            contents.forEach( el => {
              if(typeof cnts !== 'undefined' && cnts.length > 0){
                  let isNon = true;
                  for(let i = 0; i < cnts.length ; i++){
                        if(cnts[i].contentId === el.contentId){
                            isNon = false;
                            break;
                        }
                  }
                  if(isNon)newArr.push(el);
              } else {
                  newArr.push(el)
              }
            });

            this.setState({
                contents : this.state.contents.concat(newArr)
            });
        }
    };

    onClickContent = () => {
        const {addPopup, closePopup} = this.props;
        addPopup({
            id: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
            type: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
            contentType: "statistics",
            device: {},
            multiSelect: true,
            handleSelected: this.saveContent,
            onClose: () => closePopup(commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP),
        });
    };

    deleteContent = (id) => {
        const {contents} = this.state;
        const idx = contents.findIndex(function (content) {
            return content.contentId === id;
        });
        if (idx > -1) {
            contents.splice(idx, 1);
            this.setState({
                contents,
            });
        }
    };

    saveGroup = (groups) => {
        const {closePopup, t} = this.props;
        if(groups === undefined || groups.length <= 0) {
            toastr.error(t("MESSAGE_COMMON_SELECT_GROUP_P"));
            return;
        }

        closePopup(commonConstants.DEVICE_GROUP_SELECTION);
        this.setState({
            groups,
        });
    };

    onClickGroup = () => {
        const {addPopup, closePopup} = this.props;
        const {groups} = this.state;
        addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            checkedKeys: groups.map((group)=> group.groupId),
            selectAllUse: true,
            save: this.saveGroup,
            close: () => closePopup(commonConstants.DEVICE_GROUP_SELECTION),
        });
    };

    deleteDeviceGroup = (id) => {
        const {groups} = this.state;
        const idx = groups.findIndex(function (group) {
            return group.groupId === id;
        });
        if (idx > -1) {
            groups.splice(idx, 1);
            this.setState({
                groups,
            });
        }
    };

    onSortedChange = (newSorted, column, additive) => {
        const [{id, desc}] = newSorted;
        const {items} = this.state;
        this.sort(items, id, desc);
        this.setState({items: items, sorted: [{id, desc}]});
    };

    sort = (items, id, desc) => {
        items.sort(function (a, b) {
            let valueA;
            let valueB;
            if(id === 'playCount') {
                valueA = a[id];
                valueB = b[id];
            } else {
                valueA = a[id].toUpperCase();
                valueB = b[id].toUpperCase();
            }
            if(desc === true) {
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
            } else {
                if (valueA < valueB) {
                    return 1;
                }
                if (valueA > valueB) {
                    return -1;
                }
            }
            return 0;
        });
    };

    fetchGetPlayFrequencyData = (contentIds, groupIds, time, unit, startDate, endDate) => {
        const {t} = this.props;
        this.setState({loading: true, exportEnable: true});
        const prams = {
            data: 'playFrequency',
            format: 'frequencyTable',
            contentIds,
            groupIds,
            time,
            unit,
            startDate: Moment(startDate).format('YYYY-MM-DD'),
            endDate: Moment(endDate).format('YYYY-MM-DD'),
        };
        statisticsService.fetchStatisticsContents(prams).then(res => {
            let items = res.items;
            this.sort(items, 'durationString', false);
            let result = items.reduce(function (obj1, obj2) {
                obj1[obj2.timeString] = obj1[obj2.timeString] || [];
                obj1[obj2.timeString].push(obj2);
                return obj1;
            }, Object.create(null));
            let dates = Object.keys(result);

            let playCountData = [];
            let playDurationData = [];
            dates.forEach(date => {
                let durations = result[date].map(date => date.duration);
                let counts = result[date].map(date => date.playCount);
                playCountData.push({label: date, count: counts.reduce((a, b) => a + b, 0)});
                playDurationData.push({label: date, duration: durations.reduce((a, b) => a + b, 0)})
            })
            let playCountChartData = {
                labels: [],
                datasets: [],
            };
            let datas = [];
            playCountData.forEach((data, index) => {
                playCountChartData.labels.push(data.label);
                datas.push(data.count);
            });
            let dataset = {
                data: datas,
                borderColor: '#EF8A62',
                backgroundColor: '#EF8A62',
            };
            playCountChartData.datasets.push(dataset);

            let playDurationChartData = {
                labels: [],
                datasets: [],
            };
            datas = [];
            playDurationData.forEach((data, index) => {
                playDurationChartData.labels.push(data.label);
                datas.push(data.duration);
            });
            dataset = {
                data: datas,
                borderColor: '#999999',
                backgroundColor: '#999999',
            };
            playDurationChartData.datasets.push(dataset);
            this.sort(items, this.state.sorted[0].id, this.state.sorted[0].desc);
            this.setState({
                items: cloneDeep(items),
                playCountChartData,
                playDurationChartData,
                isSearched: true,
                request: {
                    groupIds: cloneDeep(groupIds),
                    contentIds: cloneDeep(contentIds),
                    time: time,
                    unit: unit,
                    startDate: Moment(startDate).format('YYYY-MM-DD'),
                    endDate: Moment(endDate).format('YYYY-MM-DD'),
                }
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loading: false});});
    };

    render() {
        const {t,currContent} = this.props;
        const {isSearched, loadingbar} = this.state;
        return (
            <div className='play_frequency' style={{display: currContent === 'PLAY_FREQUENCY' ? 'block':'none'}}>
                {this.renderFileExport()}
                <div className='play_frequency_body'>
                    {this.renderSearchPeriod()}
                    <div className="play_frequency_selection">
                        {this.renderSelectContent()}
                        <div style={{width: '29px'}}/>
                        {this.renderSelectGroup()}
                    </div>
                    {isSearched && <Fragment>
                        <div className="play_frequency_table_title mt20">{t('MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_FREQUENCY_P')}</div>
                        {this.renderPlayFrequencyTable()}
                        {this.renderChart()}
                    </Fragment>}
                </div>
                {loadingbar && <LoadingBar />}
            </div>
        );
    }

    renderNoDataTable = () => {
        const {t} = this.props;
        return (<div style={{position: 'absolute', left: '50%', top: '50%', color: '#59616b'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>);
    }

    renderFileExport = () => {
        const {t} = this.props;
        const {items = []} = this.state;
        return (
            <div className="play_frequency_export">
                <div className="leftButton">
                    <WhiteButton disable={this.state.exportEnable?false:true} id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => this.onClickExport('EXCEL')}/>
                </div>
            </div>
        );
    };

    renderSearchPeriod = () => {
        const {t} = this.props;
        const {period, unitOptions} = this.state;
        return (
                <div className="play_frequency_period">
                    <span className="space play_frequency_period_title">
                        <img src={bullet_2} alt={'*'}/>{t('TEXT_PERIOD_P')}
                    </span>
                    <SelectBox defaultTitle={t('MESSAGE_UPLOADER_SEL_PERIOD_P')} multiLang={true} classname='float_l mr8' width={209} selects={timeOptions} onClick={this.onClickTimeOption}/>
                    {
                        period.time === 'custom' &&
                        <div className='float_l mr8'>
                            <StatisticsCalendar date={period}
                                                dateFormat={user ? user.dateFormat : "yyyy-MM-dd"}
                                                onChangeStart={(startDate) => {
                                                    if (Moment(startDate).isBefore(Moment(period.endDate))) {
                                                        this.setState({period: {...this.state.period, startDate}});
                                                    } else {
                                                        let endDate = startDate;
                                                        this.setState({period: {...this.state.period, startDate, endDate}});
                                                    }
                                                }}
                                                onChangeEnd={(endDate) => {
                                                    if (Moment(period.startDate).isBefore(Moment(endDate))) {
                                                        this.setState({period: {...this.state.period, endDate}});
                                                    } else {
                                                        let startDate = endDate;
                                                        this.setState({period: {...this.state.period, startDate, endDate}});
                                                    }
                                                }}/>
                        </div>
                    }
                    {
                        period.time !== '' &&
                        <SelectBox classname='float_l mr8' width={130} defaultTitle={t(unitOptions.find(union => union.value === period.unit).title)} multiLang={true} selects={unitOptions} onClick={this.onClickUnitOption}/>
                    }
                    <button className="base float_l w72" onClick={this.onClickSearch}>{t('BUTTON_SEARCH')}</button>
                </div>
        );
    }

    renderSelectContent = () => {
        const {t} = this.props;
        const {contents} = this.state;
        return (
            <div className="clearfix float_l mr8">
                <div>
                        <span className="space float_l" style={{display: 'inline-block', width: '150px'}}>
                            <img src={bullet_2} alt={'*'}/>{t('COM_TEXT_SELECT_CONTENT_P')}
                        </span>
                    <button className="base float_r" style={{width: '95px', marginBottom: '12px'}} onClick={this.onClickContent}>{t('TEXT_SELECT_P')}</button>
                </div>
                <ul style={{height: '182px'}}>
                    {contents && contents.length > 0 &&
                    (contents).map((content, i) => (
                        <li key={i} style={{wordWrap: 'break-word'}}>
                            {content.contentName}
                            <div style={{float: 'right'}}>
                                <button className="selectedDeviceDelBtn" type="hidden" value={content.contentId} onClick={() => this.deleteContent(content.contentId)}/>
                            </div>
                        </li>
                    ))
                    }
                </ul>
            </div>
        );
    };

    renderSelectGroup = () => {
        const {t} = this.props;
        const {groups} = this.state;
        return (
            <div className="clearfix float_l">
                <div>
                        <span className="space float_l" style={{display: 'inline-block', width: '150px'}}>
                            <img src={bullet_2} alt={'*'}/>{t('TEXT_DEVICE_GROUP_P')}
                        </span>
                    <button className="base float_r" style={{width: '95px', marginBottom: '12px'}} onClick={this.onClickGroup}>{t('TEXT_SELECT_P')}</button>
                </div>
                <ul style={{height: '182px'}}>
                    {groups && groups.length > 0 &&
                    groups.map((group, i) => (
                        <li key={i} style={{display: 'flex'}}>
                            <span style={{flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} title={group.groupNameText}>{group.groupName}</span>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <button className="selectedDeviceDelBtn" type="hidden" value={group.groupId} onClick={() => this.deleteDeviceGroup(group.groupId)}/>
                            </div>
                        </li>
                    ))
                    }
                </ul>
            </div>
        );
    };

    renderPlayFrequencyTable = () => {
        const {t} = this.props;
        const {items, loading, sorted} = this.state;
        return (
            <div className='play_frequency_table'>
                <ReactTable
                    data={items}
                    loading={loading}
                    minRows={0}
                    sorted={sorted}
                    onSortedChange={this.onSortedChange}
                    NoDataComponent={this.renderNoDataTable}
                    getTbodyProps={() => {
                        return {
                            className: 'play_frequency_tr_body',
                        };
                    }}
                    getTrGroupProps={() => {
                        return {
                            className: 'play_frequency_tr_style',
                        };
                    }}
                    getTdProps={() => {
                        return {
                            className: 'play_frequency_td_props',
                        };
                    }}
                    columns={[
                        {
                            Header: t('TEXT_PERIOD_P'),
                            accessor: 'timeString',
                        },
                        {
                            Header: t('TEXT_CONTENT_NAME_P'),
                            accessor: 'contentName',
                        },
                        {
                            Header: t('MESSAGE_STATISTICS_TABLE_COLUMN_CONTENT_PLAY_TIME_P'),
                            accessor: 'playCount',
                        },
                        {
                            Header: t('MIS_SID_20_PLAYBACK_TIME'),
                            accessor: 'durationString',
                        },
                    ]}
                    showPagination={false}
                    className="-striped -highlight"
                    style={{height: '520px'}}
                    manual
                />
            </div>
        );
    };

    renderChart = () => {
        return (
            <div className='play_frequency_table_chart'>
                {this.renderPlayCountChart()}
                {this.renderPlayDurationChart()}
            </div>
        );
    }

    renderPlayCountChart = () => {
        let {t} = this.props;
        let {playCountChartData} = this.state;
        let haveData = playCountChartData && playCountChartData.labels && playCountChartData.labels.length > 0;
        return (
            <div className='pop-file-history_chart_count'>
                {haveData ?
                    <div className='pop-file-history_chart_duration_title'>{t('MESSAGE_STATISTICS_TABLE_COLUMN_CONTENT_PLAY_TIME_P')}</div> :
                    <div className='pop-file-history_chart_duration_title'>{t('MESSAGE_STATISTICS_TABLE_COLUMN_CONTENT_PLAY_TIME_P') + ' - ' + t('MESSAGE_COMMON_NO_DATA_P')}</div>
                }
                {haveData &&
                <div className='pop-file-history_chart_count_body'>
                    <Bar data={playCountChartData} redraw={true}
                         options={{
                             legend: {
                                 display: false,
                             },
                             animation: {
                                 duration: 0,
                             },
                             scales: {
                                 yAxes: [{
                                     ticks: {
                                         ...this.tick,
                                         fontSize: 11,
                                         callback: function (value) {
                                             if (value % 1 === 0) {
                                                 return value;
                                             }
                                         }
                                     },
                                 }]
                             },
                             elements: {point: {radius: 0}},
                             tooltips: {
                                 displayColors: false,
                                 callbacks: {
                                     title: function () {
                                         return null;
                                     },
                                     label: function (tooltipItem) {
                                         return tooltipItem.xLabel + ":" + tooltipItem.yLabel;
                                     },
                                 }
                             }
                         }}/>
                </div>
                }
            </div>
        );
    }

    renderPlayDurationChart = () => {
        let {t} = this.props;
        let {playDurationChartData} = this.state;
        let haveData = playDurationChartData && playDurationChartData.labels && playDurationChartData.labels.length > 0;
        return (
            <div className='pop-file-history_chart_duration'>
                {haveData ?
                    <div className='pop-file-history_chart_duration_title'>{t('TEXT_PLAY_DURATION_P')}</div> :
                    <div className='pop-file-history_chart_duration_title'>{t('TEXT_PLAY_DURATION_P') + ' - ' + t('MESSAGE_COMMON_NO_DATA_P')}</div>
                }
                {haveData &&
                <div className='pop-file-history_chart_duration_body'>
                    <Bar data={playDurationChartData} redraw={true}
                         options={{
                             legend: {
                                 display: false,
                             },
                             animation: {
                                 duration: 0,
                             },
                             scales: {
                                 yAxes: [{
                                     ticks: {
                                         ...this.tick,
                                         fontSize: 11,
                                         callback: function (value) {
                                             if (value % 1 === 0) {
                                                 return value;
                                             }
                                         }
                                     },
                                 }]
                             },
                             elements: {point: {radius: 0}},
                             tooltips: {
                                 displayColors: false,
                                 callbacks: {
                                     title: function () {
                                         return null;
                                     },
                                     label: function (tooltipItem) {
                                         return tooltipItem.xLabel + ":" + tooltipItem.yLabel;
                                     },
                                 }
                             }
                         }}/>
                </div>
                }
            </div>
        );
    }
};

export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(withTranslation()(PlayFrequency));
