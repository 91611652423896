import React from 'react';
import SupportedDeviceIcon from "../../../icon/SupportedDeviceIcon";
import {useTranslation} from "react-i18next";
import WhiteButton from "../../../button/WhiteButton";
import DateToStr from "../../../utils/DateToStr";
import {useDispatch} from "react-redux";
import TextInput from "../../../input/TextInput";
import SelectedDevices from "../../../input/SelectedDevices";
import {popupAction} from "../../../../actions";
import {commonConstants} from "../../../../constants";


const MessageScheduleDetail = ({message, updateMessage}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const handleMessage = (e, name) => {
        const value = e.target.value;
        const updatedMessage = Object.assign({}, message);
        updatedMessage[name] = value;
        updateMessage(updatedMessage);
    }

    const showGroupPopup = () => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: commonConstants.COMMON_GROUP_POPUP,
            mode: 'message_schedule',
            save: (groups)=>handleProgramGroup(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }));
    }

    const handleProgramGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));

            const {groupId, groupName} = groups[0];
            const updatedMessage = Object.assign({}, message);
            updatedMessage.messageGroupId = groupId;
            updatedMessage.messageGroupName = groupName;
            updateMessage(updatedMessage);
        }
    }

    return (
        <div className="detail_view mini_p mt8">
            <table>
                <colgroup>
                    <col width="116px" />
                    <col width="" />
                </colgroup>
                <tbody>
                <tr>
                    <th><span className="popupTh">{t("COM_TEXT_MESSAGE_NAME_P")}</span></th>
                    <td>
                        <TextInput width={250} maxLength={50} value={message!== undefined && message.messageName} onChange={(e)=>handleMessage(e, 'messageName')}/>
                    </td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("COM_MAPP_SID_SUPPORTED_DEVICES")}</span></th>
                    <td className="icon" style={{width: 'auto'}}>
                        {
                            message !== undefined &&
                            <SupportedDeviceIcon deviceType={message !== undefined && message.deviceType} deviceTypeVersion={message !== undefined && message.deviceTypeVersion} />
                        }
                    </td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("COM_TEXT_MODIFY_DATE_P")}</span></th>
                    <td><DateToStr date={message !== undefined && message.messageList[0].modifiedDate} /></td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("TEXT_MESSAGE_GROUP_P")}</span></th>
                    <td>
                        <WhiteButton width={250} name={message !== undefined && message.messageGroupName} onClick={showGroupPopup}/>
                    </td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("TEXT_DEVICE_GROUP_P")}</span></th>
                    <td>
                        <SelectedDevices groups={message !== undefined && message.deviceGroups}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>


    )
}
export default MessageScheduleDetail;