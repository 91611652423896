import React from 'react';
import {EditGeneral, EditFanNTemp, EditSound} from '../edit/EditDisplay';
import {
    EditPresetPicture, 
    EditPresetMaintenance, 
    EditPresetScreenBurnProtection, 
    EditPresetAdvancedSetting
} from './EditPresetDisplayPanel';

const EditPresetDisplay = props => {
    return (
        <div className='device_edit_panel_wrap mt28'>
            <EditGeneral {...props} />
            <EditFanNTemp {...props} />
            <EditPresetPicture {...props} />
            <EditSound {...props} />
            <EditPresetMaintenance {...props} />
            <EditPresetScreenBurnProtection {...props} />
            <EditPresetAdvancedSetting {...props} />
        </div>
    );
};

export default EditPresetDisplay;
