import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useOutsideClick} from "../../helper";
import ContentThumbnail from "../image/ContentThumbnail";


const TooltipThumbnail = ({thumbnailId, width, height, position, zIndex = 1}) => {

    const ref = useRef();

    const [show, setShow] = useState(false);

    useEffect(()=> {
        console.log('TooltipThumbnail', position);

    })

    const renderThumbnail = () => {
        setShow(true);
    }

    useEffect(()=> {
        renderThumbnail();
    }, [thumbnailId])

    return (
        <div style={{position: 'fixed', top:0, left:0, zIndex: zIndex}}>
            <div className={'tooltip_thumbnail'} style={{position: 'absolute', left: position.x, top: position.y}} ref={ref}>
                {
                    show &&
                        <ContentThumbnail id={thumbnailId} width={width} height={height}/>
                }

            </div>
        </div>
    )

}
export default TooltipThumbnail;