import React from 'react'
import LFTIcon from '../../../images/icon/file_type_icon_11.png';
import classNames from "classnames";

const LFT = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={LFTIcon}/>
            {
                !onlyIcon && 'LFT'
            }
        </span>
    )
}
export default LFT;