import {CONTENT_ACTIONS} from "../constants";


export const contentAction = {
    reloadContent,
    setMyContentGroupId,
    loadTrashBasketPopup,
    contentDownloading,
}

function reloadContent(reload) {
    return {
        type: CONTENT_ACTIONS.RELOAD_CONTENT,
        reload,
    }
}

function setMyContentGroupId(myContentGroupId) {
    return {
        type: CONTENT_ACTIONS.SET_MY_CONTENT_GROUP_ID,
        myContentGroupId,
    }
}

function loadTrashBasketPopup(loadTrashBasket) {
    return {
        type: CONTENT_ACTIONS.LOAD_TRASH_BASKET,
        loadTrashBasket,
    }
}

function contentDownloading(downloading) {
    return {
        type: CONTENT_ACTIONS.CONTENT_DOWNLOADING,
        downloading,
    }
}