import React, {useState} from 'react';
import SupportedDeviceIcon from "../../../icon/SupportedDeviceIcon";
import {useTranslation} from "react-i18next";
import WhiteButton from "../../../button/WhiteButton";
import SelectedDevices from "../../../input/SelectedDevices";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../../actions";
import {commonConstants, CONTENT_SCHEDULE_PROGRAM_TYPE_AD} from "../../../../constants";
import DateToStr from "../../../utils/DateToStr";
import TextInput from "../../../input/TextInput";
import {cloneDeep} from "lodash";
import {PROGRAM_TYPES} from '../../../../constants/ScheduleConstants';

const ContentScheduleDetail = ({program, updateProgram}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [groupPopup, setGroupPopup] = useState(false);
    const deviceCount = program.deviceCount;
    const publishedProgress = Math.round(program.publishedDeviceUntilNow / deviceCount * 100)

    const handleData = (e, name) => {
        const value = e.target.value;
        const updatedProgram = Object.assign({}, program);
        updatedProgram[name] = value;
        updateProgram(updatedProgram);
    }

    const showGroupPopup = () => {
        //setGroupPopup(!groupPopup);

        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: commonConstants.COMMON_GROUP_POPUP,
            mode: 'content_schedule',
            save: (groups)=>handleProgramGroup(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }));
    }

    const handleProgramGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            const {groupId, groupName, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                const updatedProgram = cloneDeep(program);
                updatedProgram.programGroupId = groupId;
                updatedProgram.programGroupName = groupName;
                updateProgram(updatedProgram);

                dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
            }
        }
    }

    const getProgramTypeText = (programType) => {
        const programTypeText = PROGRAM_TYPES.find(
            type => type.id === programType
        )
        if (programTypeText) {
            return t(programTypeText.text);
        }
        return programType;
    }
    
    return (
        <div className="detail_view mini_p mt8">
            <table>
                <colgroup>
                    <col width="116px" />
                    <col width="" />
                </colgroup>
                <tbody>
                <tr>
                    <th><span className="popupTh">{t("TEXT_SCHEDULE_NAME_P")}</span></th>
                    <td>
                        <TextInput width={250} maxLength={90} value={program !== undefined && program.programName} onChange={(e)=>handleData(e, 'programName')} />
                    </td>
                </tr>
                {
                    program !== undefined && program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                    <tr>
                        <th><span className="popupTh">{t("MIS_SID_20_NUMBER_OF_SLOTS")}</span></th>
                        <td>{program !== undefined && program.channels !== undefined && program.channels[0].frame.adSlots !== undefined && program.channels[0].frame.adSlots !== undefined && program.channels[0].frame.adSlots.length}</td>
                    </tr>
                }
                {
                    program !== undefined && program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                    <tr>
                        <th><span className="popupTh">{t("MIS_SID_20_NUMBER_OF_CHANNELS")}</span></th>
                        <td>{program !== undefined && program.channels !== undefined && program.channels.length}</td>
                    </tr>
                }
                <tr>
                    <th><span className="popupTh">{t("MIS_SID_20_SCHEDULE_TYPE")}</span></th>
                    <td>{program !== undefined && getProgramTypeText(program.programType)}</td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("COM_MAPP_SID_SUPPORTED_DEVICES")}</span></th>
                    <td className="icon">
                        {
                            program !== undefined && program.deviceType !== undefined && program.deviceTypeVersion !== undefined &&
                                <SupportedDeviceIcon deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion} />
                        }
                    </td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("COM_TEXT_MODIFY_DATE_P")}</span></th>
                    <td><DateToStr date={program !== undefined && program.lastModifiedDate}/></td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("TEXT_SCHEDULE_GROUP_P")}</span></th>
                    <td>
                        <WhiteButton width={250} name={program !== undefined && program.programGroupName} tooltip onClick={showGroupPopup}/>
                    </td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("TEXT_DEVICE_GROUP_P")}</span></th>
                    <td>
                        <SelectedDevices groups={program !== undefined && program.deviceGroups}/>
                    </td>
                </tr>
                {
                    program !== undefined && program.publishedProgress !== undefined &&
                    program.publishedDeviceUntilNow !== undefined &&
                    deviceCount !== undefined && deviceCount > 0 &&
                    <tr>
                        <th><span className="popupTh">{t("MIS_SID_PUBLISH_STATUS")}</span></th>
                        <td>
                            <div className="publish_progress"><span style={{width: publishedProgress+'%'}}></span></div>
                            <span className="publish_txt">{publishedProgress+'% ('+program.publishedDeviceUntilNow+'/'+deviceCount+')'}</span>
                        </td>
                    </tr>
                }
                <tr>
                    <th><span className="popupTh">{t("TEXT_DESCRIPTION_P")}</span></th>
                    <td>
                        <textarea style={{width:260,height:45}} maxLength="200" id="scheduleDescription" onChange={(e)=>handleData(e, 'description')} value={program !== undefined && program.description !== undefined ? program.description : ''}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>


    )
}
export default ContentScheduleDetail;