import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../MISDialog';
import {deviceService} from '../../../services';
import DevicePowerIcon from '../../device/DevicePowerIcon';
import {popupAction} from '../../../actions';
import {commonConstants} from '../../../constants';
import {getErrorMessage} from '../../../helper/responseHandler';
import './VWLLayoutSelectDeviceToReplacePopup.css';

const VwlLayoutSelectDeviceToReplacePopup = ({selDeviceId, modelName, approvalInfo, onOk, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [devices, setDevices] = useState([]);

    const onSelect = index => e => {
        const newDevices = devices.map((device, i) => {
            index === i ? device.selected = true : device.selected = false;
            return device;
        });
        setDevices(newDevices);
    };

    const onClickOk = () => {
        const selectedDevices = devices.filter(device => device.selected);

        if(selectedDevices.length === 0) {
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
        } else {
            const selectedDevice = selectedDevices[0];
            if(modelName !== selectedDevice.deviceModelName) {
                dispatch(popupAction.addPopup({
                    id: commonConstants.COMMON_ALERT_POPUP,
                    type: commonConstants.COMMON_ALERT_POPUP,
                    title: t('MIS_SID_PREMIUM_REPLACE_DEVICE'),
                    message: t('MIS_SID_PREMIUM_SELECTED_GROUP_VIDEO_SELECT_MSG'),
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP))
                }));
            } else {
                dispatch(popupAction.addPopup({
                    id: commonConstants.COMMON_CONFIRM_POPUP,
                    type: commonConstants.COMMON_CONFIRM_POPUP,
                    title: t('MIS_SID_PREMIUM_REPLACE_DEVICE'),
                    useMessageLine: false,
                    message: (
                        <>
                            {t('MIS_SID_PREMIUM_REPLACE_SELECTED_DEVICE_REMOVED')}
                            <br/>
                            {t('MIS_SID_PREMIUM_MIX_EXISTING_DEVICE_MAC_ADDR_NEW').replace("<<A>>", `"${selectedDevice.macAddress}"`).replace("<<B>>", `"${selDeviceId}"`)}
                        </>
                    ),
                    onClickYes: () => {
                        deviceService.replaceVwlDevice(selectedDevice.macAddress, selDeviceId, {
                            newDeviceName: approvalInfo.deviceName,
                            // newDeviceModelName: modelName,
                            newDeviceIpAddr: selectedDevice.ipAddress,
                            newDeviceGroupId: approvalInfo.groupId,
                            newDeviceLocation: approvalInfo.location,
                            newDeviceExpiredDate: approvalInfo.expiredDate,
                        }).then(res => {
                            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                            if(onOk) {
                                onOk();
                            }
                        }).catch(error => toastr.error(getErrorMessage(error)));
                    },
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
                }));
            }
        }
    };

    useEffect(() => {
        deviceService.fetchDeviceFilter({groupId: approvalInfo.groupId}).then(res => {
            setDevices(res.items.map(item => ({...item, selected: false})));
        }).catch(error => console.log(error));
    }, [approvalInfo.groupId]);

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_PREMIUM_REPLACE_DEVICE'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 800,
                height: 370,
                onClose
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickOk,
                    },
                    {
                        id: 'MSG_CANCELED',
                        title: t('MSG_CANCELED'),
                        onClick: onClose,
                    },
                ]
            }}
        >
            <div className="vwl_device_replace_wrap popup_contents clearfix">
                <div className="notify_wrap fc_999999 mt20">
                    <div>{t('MIS_SID_PREMIUM_SELELCT_DEVICE_REPLACED_ADD_MSG')}</div>
                </div>
                <div className="detail_view mt12">
                    <div id="contentPlaylistDiv">
                        <div className="list_wrap list pop_list h372" style={{overflowY:'hidden'}}>
                            <table>
			                    <colgroup>
                                    <col width="20px"/>
                                    <col width="200px"/>
                                    <col width="140px"/>
                                    <col width="140px"/>
                                    <col width="140px"/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{width:40}} className="brnone"></th>
			                            <th style={{width:200}}>{t('TABLE_DEVICE_NAME_P')}</th>
                                        <th style={{width:140}}>{t('DID_ADMIN_LICENSE_MAC')}</th>
                                        <th style={{width:140}}>{t('COM_SID_IP_ADDRESS')}</th>
                                        <th style={{width:140}}>{t('LIST_TITLE_MODEL_NAME')}</th>
                                    </tr>
                                </thead>
                            </table>
			                <div style={{height:340, overflowY:'auto'}}>
                                <table>
                                    <tbody>
                                        {
                                            devices.length > 0 &&
                                            devices.map((device, i) => {
                                                if(modelName !== device.deviceModelName) {
                                                    return null;
                                                }
                                                return (
                                                    <tr key={device.deviceId} role="row">
                                                        <td style={{width:40, paddingLeft: '10px'}}>
                                                            <input type="radio" id={`radio_${device.deviceId}`} className="listRadio" checked={device.selected} onChange={onSelect(i)} />
                                                            <label htmlFor={`radio_${device.deviceId}`} style={{height:15, marginTop:'8px'}}></label>
                                                        </td>
                                                        <td style={{width:200}}>
                                                            <DevicePowerIcon power={device.power} />
                                                            {device.deviceName}
                                                        </td>
                                                        <td style={{width:140}}>{device.macAddress}</td>
                                                        <td style={{width:140}}>{device.ipAddress}</td>
                                                        <td style={{width:140}}>{device.deviceModelName}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MISDialog>
    );
};

export default VwlLayoutSelectDeviceToReplacePopup;