import React, { Component } from 'react';
import RuleNodeEntry from './RuleNodeEntry';
import './RuleTree.css';
import {getMixString} from '../../language/languageUtils';

class RuleTree extends Component {

    constructor(props) {
        super(props);

        this.ruleTree = [{
            type: "start",
            id: "start"
        }];
    }

    addOrButton(parentElem) {
        let orElem = {};
        orElem.entryIdIndex = ++this.entryIdIndex;
        orElem.children = [];
        orElem.parentId = parentElem.elementId;
        orElem.type = 'addOrButton';

        return (
            <RuleNodeEntry elem={orElem} parentElem={parentElem} ruleId={this.ruleId} key={"entryIdIndex_" + orElem.entryIdIndex}/>
        )
    }

    branch(list, parentElem) {
        let lastValue;
        let hasDefaultPlay = false;

        let style;
        if(parentElem && parentElem.type === "start") {
            style = {
                marginLeft: "169px"
            }
        };

        return (
            <div className="ruletree-branch" style={style}>
                {list.map((value, index) => {
                    lastValue = value;

                    if(value.id === "default") {
                        hasDefaultPlay = true;

                        return this.addOrButton(parentElem);
                    }

                    return this.branchList(value, parentElem);
                })}
                { !hasDefaultPlay && lastValue && lastValue.type !== "start" && lastValue.type !== "result" &&
                    this.addOrButton(parentElem)
                }
                { hasDefaultPlay && lastValue &&
                    this.branchList(lastValue, parentElem)
                }
            </div>
        )
    }

    branchList(elem, parentElem) {
        const {mode} = this.props;
        this.entryIdIndex++;
        elem.entryIdIndex = this.entryIdIndex;

        let elementId = elem.entryIdIndex + "_" + elem.id;

        elem.elementId = elementId;

        if(elem.id === "default") {
            elem.result = this.resultMap.get(elem.children[0].id);
            this.rule.totalSize += elem.result.totalSize;
        }

        else {
            switch(elem.type) {
                case "condition" :
                    elem.condition = this.conditionMap.get(elem.id);
                    break;
                case "result" :
                    elem.result = this.resultMap.get(elem.id);
                    this.rule.totalSize += elem.result.totalSize;
                    break;
            }
        }

        let obj = undefined; 
        if (elem.id !== "default" && elem.children && elem.children.length) {
            obj = this.branch(elem.children, elem);
        }

        let entryProps = {
            mode: mode,
            elem: elem,
            parentElem, parentElem,
            branch: obj,
            ruleId: this.ruleId,
            key: "entryIdIndex_" + elem.entryIdIndex,
            conditionMap: this.conditionMap,
            resultMap: this.resultMap
        };

        if(elem.type === "start") {
            entryProps.rule = this.rule;
        }

        return(
            <RuleNodeEntry
                {...entryProps} />
        )
    }

    render() {
        this.conditionMap = this.props.conditionMap;
        this.resultMap = this.props.resultMap;
        this.contentMap = this.props.contentMap;
        this.ruleId = this.props.rule.rule_id;
        this.rule = this.props.rule;
        this.rule.totalSize = 0;

        this.ruleTree[0].ruleId = this.ruleId;
        this.ruleTree[0].children = this.rule.rule_tree;

        if(this.props.entryIdIndex) {
            this.entryIdIndex = this.props.entryIdIndex;
        }

        else {
            this.entryIdIndex = 0;
        }

        const ruleElementId = "rulesetTree_" + this.rule.rule_id;
        const className = this.rule.rule_tree !== undefined && this.rule.rule_tree.length > 0 ? "ruleset_rule" : "ruleset_rule empty";
        
        return (
            <div id={ruleElementId} className={className} rule_id={this.rule.rule_id}>
                
                <div className={"ruletree-wrapper " + ruleElementId}>
                    {this.branch(this.ruleTree)}
                </div>
                { (this.rule.rule_tree === undefined || this.rule.rule_tree.length === 0) &&
                    <div className="ruleset_dragging_guide">{getMixString(["MIS_SID_MIX_ADD_BY_DRAGGING_HERE_CLICKING_THE_PLUS_ICON", "MIS_SID_CONDITION_RULE", "MIS_SID_PLAY_RULE", "MIS_SID_RULESET"])}</div>
                }
            </div>
        )
    }
}

export default RuleTree;