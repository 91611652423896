import React from 'react';
import {Line} from 'react-chartjs-2';
import "chartjs-plugin-streaming";
import './ContentDownloadQueueStatus.css';
import {settingService} from "../../services";
import ContentDownloadQueueTable from "./ContentDownloadQueueTable";

const ContentDownloadQueueStatus = () => {

    const data = {
        datasets: [
            {
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                data: []
            }
        ]
    };

    const onRefresh = () => {
        new Promise((resolve) => {
            settingService.fetchContentDownloadThrottlingInfo()
                .then(res=> {
                    resolve(res.items.status);
                })
        }).then((status)=> {
            data.datasets[0].data.push({x: Date.now(), y: status.length});
        });
    }

    const options = {
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    display: false,
                    type: "realtime",
                    realtime: {
                        onRefresh: onRefresh,
                        refresh: 2000,
                        delay: 2000
                    }
                }
            ]
        }
    };

    return (
        <div style={{height: 500, padding: 15}} className={'content_download_status_container'}>
            <div className={'title'}>
                Content Download Status
            </div>
            <div className={'content_download_status_wrap'}>
                <div className={'chart_wrap'}>
                    <Line id='line-chart' data={data} options={options}/>
                </div>
                <div className={'table_wrap'}>
                    <ContentDownloadQueueTable/>
                </div>
            </div>
        </div>
    )
}
export default ContentDownloadQueueStatus;