import React, {useState} from "react";
import {rulesetConstants} from "../../../../constants";
import {useTranslation} from "react-i18next";
import Select from "../../../selectbox/Select";
import "../ConditionPopup.css";
import Moment from "moment";
import Checkbox from "../../../checkbox/Checkbox";
import {isNil} from "lodash";
import DateInput from "../../../input/DateInput";
import {toastr} from 'helper/toastrIntercept';
import {useMISOpt} from "components/misopt";

const createWeek = orgValues => {
    const orgWeeks = [];
    orgValues.forEach(item => orgWeeks.push(item.value));
    const array = [];
    Object.entries(rulesetConstants.CONDITION_DATE_WEEK_TYPES).forEach(
        ([key, value]) => {
            let checked = false;
            if (!isNil(orgWeeks) && orgWeeks.indexOf(value.name) !== -1) {
                checked = true;
            }
            const week = {
                value: value.name,
                title: value.sid,
                checked: checked
            };
            array.push(week);
        }
    );
    return array;
};

const createMonth = orgValues => {
    const orgMonth = [];
   
    orgValues.forEach((item)=> {
        if(item.value !== undefined && item.value !== null){
            orgMonth.push(item.value.toString());
        }        
    });
    
    const array = [];

    for (var i = 1; i <= 31; i++) {
        let checked = false;
        if (!isNil(orgMonth) && orgMonth.indexOf(i.toString()) !== -1)
            checked = true;
        array.push({
            index: i,
            checked: checked
        });
    }
    
    return array;
};

const CurrentDate = ({    handleRepeatType,    handleValues,    handleSign,    orgValues,    orgRepeatType}) => {
    const {misopt: {user: {dateFormat}}} = useMISOpt();
    const [repeatType, setRepeatType] = useState(
        orgRepeatType ? orgRepeatType : "once"
    );
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [values, setValues] = useState(orgValues ? orgValues : []);
    
    const minDate = new Date(1900,0);
    const maxDate = new Date(2999,11);
    const [weeks, setWeeks] = useState(createWeek(orgValues));
    const [month, setMonth] = useState(createMonth(orgValues));

    const { t } = useTranslation();
    const repeatTypeList = [];

    Object.entries(rulesetConstants.CONDITION_DATE_REPEAT_TYPES).forEach(
        ([key, value]) => {
            repeatTypeList.push({
                value: value.repeatType,
                title: value.sid
            });
        }
    );

    const onTypeChange = (e, value) => {
        setRepeatType(value);
        handleRepeatType(value);
        if (value === "once") {
            handleSign("rg");
        } else if (value === "weekly" || value === "monthly") {
            handleSign("eq");
        }
        setValues([]);
        handleValues([]);
    };

    const onToggleWeek = index => {
        const newWeeks = weeks.map((week, i) =>
            i === index ? { ...week, checked: !week.checked } : week
        );

        setWeeks(newWeeks);
        let newValues = [];
        newWeeks.map((v, i) => {
            if (v.checked) {
                const newValue = { value: v.value };
                newValues.push(newValue);
            }
        });        

        setValues(newValues);
        handleValues(newValues);
    };

    const onToggleMonth = index => {
        const newMonth = month.map((v, i) =>
            index === i ? { ...v, checked: !v.checked } : v
        );

        setMonth(newMonth);

        let newValues = [];
        newMonth.map((v, i) => {
            if (v.checked) {
                const newValue = { value: v.index };
                newValues.push(newValue);
            }
        });
        setValues(newValues);
        handleValues(newValues);
    };
    
    const addDate = e => {
        const length = values.length;
        const value = {
            start: Moment(startDate).format("YYYY.MM.DD"),
            end: Moment(endDate).format("YYYY.MM.DD")
        };
        if(length >= 10){
            toastr.error(t("MESSAGE_COMMON_LIMIT_OVER_LENGTH_P"));
        }else{
            if (startDate === "" || endDate === "") {
                toastr.error(t('MESSAGE_SCHEDULE_SELECT_PERIOD_P'));
                return;
            }
            const newValues = values.concat(value);
            setValues(newValues);
            handleValues(newValues);
        }
    };

    const deleteDate = i => {
        const newValues = [...values];
        newValues.splice(i, 1);
        setValues(newValues);
        handleValues(newValues);
    };

    const onChangeStartDay = value => {
        if (value.getTime() > Moment(endDate).toDate().getTime()) {
            setEndDate(value);
            setStartDate(value);            
        } else {            
            setStartDate(Moment(value));
        }
        setStartDate(value);
    };

    const onChangeEndDay = value => {
        if (value.getTime() < Moment(startDate).toDate().getTime()) {
            setEndDate(value);
            setStartDate(value); 
        }else{
            setEndDate(value);
        }        
    };


    return (
        <table className="ruleset_curr_time" style={{ tableLayout:'fixed'}}>
            <tbody>
                <tr id="setting">
                    <th>
                        {t("TEXT_REPEAT_P")}
                    </th>
                    <td>
                        <Select
                            width={130}
                            value={repeatType}
                            selects={repeatTypeList}
                            onChange={onTypeChange}
                        />
                    </td>
                </tr>
                {repeatType === "once" && (
                    <>
                        <tr>
                            <th></th>
                            <td>
                                <div className="input_date" style={{display:'inline', float: 'left'}}>
                                    <DateInput
                                        dateFormat={dateFormat}
                                        date={startDate}
                                        //maxDate={endDate}
                                        minDate={minDate}
                                        onChange={onChangeStartDay}
                                        width={120}
                                    />                                    
                                </div>
                                <div style={{float: 'left', height: '28px', padding:'4px 5px'}}>-</div>
                                <div className="input_date" style={{display:'inline', float: 'left'}}>
                                    <DateInput
                                        dateFormat={dateFormat}
                                        date={endDate}
                                        minDate={startDate}
                                        maxDate={maxDate}
                                        onChange={onChangeEndDay}
                                        width={120}
                                    />
                                </div>
                                <span style={{ marginLeft: 1 }}>
                                    <button
                                        className="holiday_add"
                                        onClick={addDate}
                                    />
                                </span>
                            </td>
                        </tr>   
                        <tr>
                            <th></th>
                            <td><div style ={{maxWidth:'480px', overflowY:'hidden', overflowX:'auto'}}>
                            {values.map((v, i) => (
                                <div className="ruleset_tag_input mr8 mt8">
                                    <input
                                        type="text"
                                        value={v.start + " ~ " + v.end}
                                        style={{ width: "135px" }}
                                        readOnly
                                    />
                                    <button
                                        onClick={e => deleteDate(i)}
                                    ></button>
                                </div>
                            ))}
                        </div></td></tr>
                    </>
                )}
                {repeatType === "weekly" && (
                    <>
                        <tr>
                            <th></th>
                            <td>
                                {weeks.map((v, i) => {
                                    return (
                                        <div
                                            style={{
                                                marginLeft: "10px",
                                                width: "50px"
                                            }}
                                            className="float_l"
                                        >
                                            <Checkbox
                                                id={"weekly" + v.value}
                                                name={t(v.title)}
                                                checked={v.checked}
                                                onClick={e => onToggleWeek(i)}
                                            />
                                        </div>
                                    );
                                })}
                            </td>
                        </tr>
                    </>
                )}
                {repeatType === "monthly" && (
                    <>
                        <tr>
                            <th></th>
                            <td>
                                <div id="repeatTypeWrap">
                                    {month.map((v, i) => {
                                        return (
                                            <div
                                                className={
                                                    v.checked
                                                        ? "repeatTypeMonCal on"
                                                        : "repeatTypeMonCal"
                                                }
                                                onClick={e => onToggleMonth(i)}
                                            >
                                                {" "}
                                                {v.index}{" "}
                                            </div>
                                        );
                                    })}
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </tbody>
        </table>
    );
};
export default CurrentDate;
