import React, { useState, Children } from 'react';
import {useTranslation} from 'react-i18next';
import Slide from 'rc-slider';
import Checkbox from '../checkbox/Checkbox';
import './ImageTableHeader.css';
import Size1Thumb from '../../images/img/thumb_size1.png';
import Size2Thumb from '../../images/img/thumb_size2.png';

const ImageTableHeader = ({sorting, size, setCheckAllRef, onChangeCheckAll, onChangeSorting, onChangeSize, min=100, max=200, children, isCheckAllDefault}) => {
    const {t} = useTranslation();
    const [showSortingOptions, setShowSortingOptions] = useState(false);

    const onClickSorting = e => {
        setShowSortingOptions(!showSortingOptions);
    };

    const onClickSortItem = e => {
        const sortValue = e.target.getAttribute('data-value');
        if(sortValue === 'asc'){
            onChangeSorting('asc');
        }else if(sortValue === 'desc'){
            onChangeSorting('desc');
        }
    };

    const onBlurSorting = e => {
        setShowSortingOptions(false);
    };
    
    return (
        <div className='image_table_header_wrapper'>
            <table>
                <colgroup>
                    <col width="120px"/>
                    <col width=""/>
                </colgroup>
                <thead>
                <tr>
                    <th className="check_all">
                        <Checkbox id='image_check_all' name={t("BUTTON_ALL_SELECT_P")} ref={setCheckAllRef} onChange={onChangeCheckAll} defaultChecked={isCheckAllDefault}/>
                    </th>
                    <th className="check_info">
                        <div className="sorting_wrap float_r">
                            {children}
                            <div className="size_control_wrap float_l">
                                <div className="slider_box">
                                    <div className="float_l">
                                        <img src={Size1Thumb} />
                                    </div>
                                    <div className="float_l" style={{width:89}}>
                                        <Slide min={min} value={size} max={max} onChange={onChangeSize} />
                                    </div>
                                    <div className="float_l">
                                        <img src={Size2Thumb} />
                                    </div>
                                </div>
                            </div>
                            <div className="select_wrap">
                                <button className="sorting_btn" onClick={onClickSorting} onBlur={onBlurSorting}>
                                    {(sorting === 'asc' || sorting === 'default') && <span className="asc">{t('MIS_SID_20_A_TO_Z')}<span className='arrow'></span></span>}
                                    {sorting === 'desc' && <span className="desc">{t('MIS_SID_20_Z_TO_A')}<span className='arrow'></span></span>}
                                </button>
                                {
                                    showSortingOptions &&
                                    <div className="option_list">
                                        <span></span>
                                        <ul>
                                            <li data-value="asc" onMouseDown={onClickSortItem}>{t('MIS_SID_20_A_TO_Z')}</li>
                                            <li data-value="desc" onMouseDown={onClickSortItem}>{t('MIS_SID_20_Z_TO_A')}</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
    );
};

ImageTableHeader.defaultProps = {
    onChangeSorting: () => {},
}

export default ImageTableHeader;