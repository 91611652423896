import React, {useEffect, useRef, useState} from 'react';
import MISDialog from '../MISDialog';
import {useTranslation} from "react-i18next";
import TextInput from "../../input/TextInput";
import {contentService} from "../../../services";
import Checkbox from "../../checkbox/Checkbox";
import WhiteButton from "../../button/WhiteButton";
import './FolderShare.css'
import CircleButton from "../../button/CircleButton";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import {contentConstants} from "../../../constants";
import {toastr} from 'helper/toastrIntercept';

const FolderShare = ({groupId, id}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [data, setState] = useState({
        organizationGroups: [],
        searchGroupName: "",
        resultOrganizationGroups: [],
    });

    const {organizationGroups = [], searchGroupName = "", resultOrganizationGroups = []} = data;
    const checkAll = useRef();
    const checkBoxRefs = [];

    useEffect(() => {
        getOrganizationGroupInfo();
    }, []);

    const changeSearchText = (value) => {
        setState({
            ...data,
            searchGroupName: value,
        })
    };

    const searchGroup = () => {
        let resultOrganizationGroups = [];
        organizationGroups.forEach(group => {
            if (group.groupName.toLowerCase().includes(searchGroupName.toLowerCase())) {
                resultOrganizationGroups.push(group);
            }
        });
        setState({
            ...data,
            resultOrganizationGroups: resultOrganizationGroups,
        })
        checkAll.current.checked = resultOrganizationGroups.filter(item => item.isChecked === false).length === 0;
    };

    const reset = () => {
        setState({
            ...data,
            searchGroupName: "",
            resultOrganizationGroups: organizationGroups,
        })
        checkAll.current.checked = organizationGroups.filter(item => item.isChecked === false).length === 0;
    };

    const checkAllOrganization = (e) => {
        let resOrgGrpLen = resultOrganizationGroups.length;
        if (e.target.checked) {
            for (let index = 0; index <resOrgGrpLen ; ++index) {
                resultOrganizationGroups[index].isChecked = true;
            }
        } else {
            for (let index = 0; index < resOrgGrpLen; ++index) {
                if(resultOrganizationGroups[index].reason==='N'){                                              //SF[00198390]
                    resultOrganizationGroups[index].isChecked = false;
                }
            }
        }
        setState({
            ...data,
            resultOrganizationGroups: resultOrganizationGroups,
        })
    };

    const checkOrganization = (index) => {
        resultOrganizationGroups[index].isChecked = checkBoxRefs[index].checked;
        checkAll.current.checked = resultOrganizationGroups.filter(item => item.isChecked === false).length === 0;
        setState({
            ...data,
            resultOrganizationGroups: resultOrganizationGroups,
        })
    };

    const save = () => {
        let selectedGroupIds = [];
        resultOrganizationGroups.forEach((group) => {
            if (group.isChecked) {
                selectedGroupIds.push(group.groupId);
            }
        });
        const params = { ids : selectedGroupIds};
        contentService.updateSharedGroupInfo(groupId, params).then(res => {
            if(res.status === 'Success'){
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            }
        }).catch(error => {
            let errorMessage = error.errorMessage;
            switch(error.errorCode) {
                case '403001':
                    errorMessage = t('ERROR_NO_ROLE');
                    break;
            }
            toastr.error(errorMessage);
        }).finally(() => {
            closePopup();
        })
    };

    const getOrganizationGroupInfo = () => {
        const params = {
              groupType : contentConstants.ORGANIZATION,
              sharedGroupId: groupId
        };
        Promise.all([
            contentService.fetchSharedGroupInfoById(groupId),
            contentService.fetchGroups(params)
        ]).then(values => {
                let sharedGroupInfo = [];
                if(values[0].status === 'Success' && values[0].items.organizationDataList !== undefined){
                    sharedGroupInfo = values[0].items.organizationDataList;
                }

                let organizations = [];
                if(values[1].status === 'Success'){
                    organizations = values[1].items.filter(item => item.groupName !== 'ROOT');
                }

                let result = [];
                organizations.forEach((organization, index) => {
                    if(sharedGroupInfo.findIndex(item => item.organizationId === organization.organizationId) === -1) {
                        organizations[index] = {...organization, isChecked : false};
                    }else {
                        organizations[index] = {...organization, isChecked : true};
                    }
                });

                setState({
                    ...data,
                    organizationGroups: organizations,
                    resultOrganizationGroups: organizations
                });
            }).catch(error => {
                console.log(error)
            })
    };

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_SEL_ORGANIZATION_P'),
                closeOnEscape: true,
                modal: true,
                width: 405,
                height: 442,
                onClose: () => closePopup(),
            }}
            buttons={{
                rightButtons: [
                    {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: save},
                    {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: () => closePopup()}
                ]
            }}>
            <div className="organization_group_contents">
                <div className='organization_group_contents_name'>
                    <TextInput width={250} height={27} placeholder={t('MIS_SID_ENTER_ORGANIZTION_NAME')} value={searchGroupName}
                               onChange={e => changeSearchText(e.target.value)}/>
                    <div style={{width: '10px'}}/>
                    <CircleButton id={"SEARCH"} onClick={searchGroup}>
                        <span className='organization_group_contents_search_btn'/>
                    </CircleButton>
                    <div style={{width: '10px'}}/>
                    <WhiteButton id={"RESET"} name={t('COM_DID_LFD_RESET')} onClick={reset} style={{marginRight: '4px'}}/>
                </div>
                <div style={{height: '8px'}}/>
                <div className='organization_group_contents_list'>
                    <div className='organization_group_contents_row'>
                        <Checkbox id={'organization-all'} name={t("SETUP_NEW_STRING29_P")} onChange={checkAllOrganization} ref={checkAll}/>
                    </div>
                    <div>
                        {
                            resultOrganizationGroups && resultOrganizationGroups.map((org, index) =>
                                <div key={index} className='organization_group_contents_row'>
                                    <Checkbox id={'organization-' + index} name={org.groupName}
                                              checked={org.isChecked} onChange={() => checkOrganization(index)}
                                              ref={ref => checkBoxRefs[index] = ref} disabled={org.reason==='Y'}/>                       
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MISDialog>
    );
};

export default FolderShare;