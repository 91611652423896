import React, {Component} from 'react';
import {connect} from 'react-redux';
import WhiteButton from "../../components/button/WhiteButton";
import {withTranslation} from "react-i18next";
import RuleArea from "./RuleArea";
import {rulesetAction} from '../../actions/ruleset.action';
import {getRuleTreeDefaultName, playlistConstants, rulesetConstants} from '../../constants';
import {toastr} from 'helper/toastrIntercept';
import {getMixString} from "../../language/languageUtils";
import './Ruleset.css';
import Checkbox from '../checkbox/Checkbox';
import DateInput from '../../components/input/DateInput';
import TimeInput from '../../components/input/TimeInput';
import {deviceService} from '../../services/device.service';
import {checkCondition} from '../../helper/ruleset/rulesetUtils';
import {MISOptContext} from '../misopt';
import {isEmpty} from "lodash";
import {popupAction} from '../../actions/popup.action';
import {commonConstants} from '../../constants';
import withMISOpt from '../misopt';
import {getErrorMessage} from "../../helper/responseHandler";


class Ruleset extends Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);

        this.conditionMap = new Map();
        this.resultMap = new Map();
        this.contentMap = new Map();
        this.state = {
            height: 900,
            showNotificationModeInfo: false
        };
    }

    getDateFormat() {
        const {user : {dateFormat}}  = this.context;
        return dateFormat;
    }

    assignEntryHeight() {
        document.querySelectorAll(".ruletree-label").forEach(function(value) {
            value.closest(".ruletree-entry").style.height = value.clientHeight + "px";
        });
    }

    assignBranchHeight() {
        // get entries unsorted
        let unsortedEntries = [];
        document.querySelectorAll(".ruletree-entry").forEach(function(value) {
            unsortedEntries.push({
                entryId: parseInt(value.getAttribute("data-entry-id")),
                entry: value
            });
        });

        // sort entries by rendering order
        let entries = unsortedEntries.slice(0);
        entries.sort(function (a, b) {
            return a.entryId - b.entryId;
        });

        // get it in reverse order
        entries.reverse();

        // iterate each entry
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i].entry;

            for(let j = 0; j < entry.children.length; j++) {
                let h = 0;

                let className = entry.children[j].className;
                if(className !== undefined && className === "ruletree-branch") {
                    h += entry.children[j].clientHeight;
                }

                if(h > 0) {
                    entry.style.height = h + "px";
                }
            }
        }
    }

    redraw() {
        this.setState(this.state);
    }

    _updateDimensions() {
        const rulesetEditView = document.querySelector('#ruleset_edit_view');
        if(rulesetEditView) {
            let height = window.innerHeight - rulesetEditView.getBoundingClientRect().top;
            this.setState({
                height: height
            });
        }
    }

    componentDidMount() {
        this._updateDimensions();
        window.addEventListener("resize", this._updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateDimensions, true);
    }

    componentDidUpdate() {
        const {currentContentId} = this.props.menu;
        const {mode} = this.props;

        if((currentContentId === 'NEW_RULESET' && mode === 'NEW') || (currentContentId === 'EDIT_RULESET' && mode === 'EDIT')) {
            this.assignEntryHeight();
            this.assignBranchHeight();

            if(this.updated) {
                this.updated = false;
            }
            else {
                this.redraw();
                this.updated = true;
            }

            const rulesetEditView = document.querySelector('#ruleset_edit_view');
            const rulesetEditViewChildren = document.querySelector('#ruleset_edit_view').children;

            for(let i = 0; i < rulesetEditViewChildren.length; i++) {
                rulesetEditViewChildren[i].style.width = "";
            }

            if(rulesetEditView.getBoundingClientRect().width < rulesetEditView.scrollWidth) {
                for(let i = 0; i < rulesetEditViewChildren.length; i++) {
                    rulesetEditViewChildren[i].style.width = rulesetEditView.scrollWidth + "px";
                }
            }

            this.rules.map((rule) => {
                const elem = document.getElementById("rule_area_" + rule.rule_id);

                if(rule.rule_tree && rule.rule_tree.length > 0) {
                    if(elem.querySelector(".ruleset_condition_no_result_button") !== null) {
                        elem.className = "rule_area no-result";
                    }
                    else {
                        elem.className = "rule_area";
                    }
                }
                
                else {
                    elem.className = "rule_area no-result";
                }
            });
        }
    }

    getVerificationMode = () => {
        return this.currentTab === 'NEW_RULESET' ? this.props.rulesets.newRuleset.verificationMode : this.props.rulesets.editRuleset.verificationMode;
    }

    addRule() {
        const newRuleId = parseInt(this.rules[this.rules.length - 1].rule_id) + 1
        const newRuletreeName = getRuleTreeDefaultName(newRuleId);
        let newRule = {
            rule_id: newRuleId,
            rule_name: newRuletreeName,
            multi_result: true,
            rule_tree: []
        };

        this.rules.push(newRule);
        this.redraw();
    }

    deleteRule = (ruleId) => {
        if(this.rules.length <= 1) {
            toastr.error(getMixString(['MIS_SID_MIX_MUST_HAVE_AT_LEAST_ONE', 'MIS_SID_RULESET', 'MIS_SID_RULE_TREE']));
            return;
        }

        for(let i = 0; i < this.rules.length; i++) {
            if(this.rules[i].rule_id === ruleId) {
                this.rules.splice(i, 1);
                this.redraw();
                break;
            }
        }
    }

    init() {
        const {t} = this.props;
        const conditions = this.props.ruleset.conditions;
        const results = this.props.ruleset.results;
        const contents = this.props.ruleset.contents;

        this.conditionMap.clear();
        this.resultMap.clear();
        this.contentMap.clear();
        
        for(let i = 0; i < conditions.length; i++) {
            this.conditionMap.set(conditions[i].conditionId, conditions[i]);
        }

        for(let i = 0; i < results.length; i++) {
            let mediaTypeSet = new Set();
            let totalSize = 0;

            for(let j = 0; j < results[i].contentList.length; j++) {
                totalSize += results[i].contentList[j].contentSize;
                if(results[i].contentList[j].mediaType) {
                    if(results[i].contentsType === "playlist") {
                        let playlistType = t("TEXT_TITLE_PLAYLIST_P");
                        for(let k = 0; k < playlistConstants.PLAYLISTTYPES.length; k++) {
                            if(playlistConstants.PLAYLISTTYPES[k].id === results[i].contentList[j].mediaType) {
                                playlistType = playlistType + "(" + t(playlistConstants.PLAYLISTTYPES[k].name) + ")";
                                break;
                            }
                        }

                        mediaTypeSet.add(playlistType);
                    }
                    else {
                        mediaTypeSet.add(results[i].contentList[j].mediaType);
                    }
                }
            }

            results[i].totalSize = totalSize;
            results[i].mediaTypes = Array.from(mediaTypeSet).join(', ');

            this.resultMap.set(results[i].resultId, results[i]);
        }

        for(let i = 0; i < contents.length; i++) {
            this.contentMap.set(contents[i].contentId, contents[i]);
        }

        this.rules = this.props.ruleset.rules;

    }

    onChangeVerificationModeCheckBox = (e) => {
        const {setVerificationMode, setFixedHighlightPath} = this.props;
        const verificationMode = this.getVerificationMode();
        const checked = e.target.checked;

        switch(e.target.id) {
            case "rulesetVerificationMode":
                setVerificationMode(this.currentTab, {...verificationMode, enabled: checked});
                setFixedHighlightPath(0, []);
                break;
            case "verificationDateInputCheckbox":
                setVerificationMode(this.currentTab, {...verificationMode, date: {...verificationMode.date, enabled: checked}});
                break;
            case "verificationTimeInputCheckbox":
                setVerificationMode(this.currentTab, {...verificationMode, time: {...verificationMode.time, enabled: checked}});
                break;
            default:
                break;
        }
    }

    getAuthority(id) {
        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    onClickVerificationDeviceGroup = (e) => {
        const authority = this.getAuthority('DEVICE');
        if(authority.READ) {
            const {addPopup, closePopup, setVerificationMode, ruleset, misopt} = this.props;
            const verificationMode = this.getVerificationMode();

            const getPriority = (deviceType, deviceTypeVersion) => {
                const device = misopt.misOption(deviceType, deviceTypeVersion);
                return device !== undefined ? device.priority : 999999;
            }

            addPopup({
                id: commonConstants.DEVICE_GROUP_SELECTION,
                type: commonConstants.DEVICE_GROUP_SELECTION,
                allowSelectOrganization: false,
                allEnabled: false,
                checkbox: true,
                checkedKeys : this.getCheckedDeviceGroupKeys(verificationMode.selectedGroup),
                priority : getPriority(ruleset.deviceType, ruleset.deviceTypeVersion),
                selected : verificationMode.selectedGroup,
                save: (groups)=>{
                    setVerificationMode(this.currentTab, {...verificationMode, selectedGroup: groups});
                    closePopup(commonConstants.DEVICE_GROUP_SELECTION);
                },
                close: ()=> closePopup(commonConstants.DEVICE_GROUP_SELECTION)
            });
        }
        else {
            const {t} = this.props;
            toastr.warning(t('ERROR_NO_ROLE'));
        }
    }

    runVerification = (deviceMap, date, time, parentDeviceIds, nodes) => {
        if(nodes === undefined) {
            return;
        }

        let defaultDeviceIds = JSON.parse(JSON.stringify(parentDeviceIds));

        nodes.map((value) => {
            
            let deviceIds = [];
            if(value.type === "condition") {
                if(value.id === "default") {
                    if(value.result.type === "static") {
                        value.verificationData = {};
                        value.verificationData.deviceIds = defaultDeviceIds;
                    }
                }

                else {
                    let condition = value.condition;

                    if(condition.type === rulesetConstants.CONDITION_TYPES.mediaTag.type) {
                        condition = JSON.parse(JSON.stringify(value.condition));
                        condition.mediaTagValues = new Set();
                        condition.values.map((value) => {
                            condition.mediaTagValues.add(value.value);
                        });
                    }

                    parentDeviceIds.map((value) => {
                        const device = deviceMap.get(value);
                        const conditionCheckResult = checkCondition(condition, date, time, device);
                        if(conditionCheckResult) {
                            deviceIds.push(device.deviceId);
                        }
                    });

                    value.verificationData = {};
                    value.verificationData.deviceIds = deviceIds;

                    defaultDeviceIds = defaultDeviceIds.filter(deviceId => !deviceIds.includes(deviceId));

                    this.runVerification(deviceMap, date, time, deviceIds, value.children);
                }
                
            }

            else if(value.type === "result") {
                if(value.result.type === "static") {
                    value.verificationData = {};
                    value.verificationData.deviceIds = parentDeviceIds;
                }

                this.runVerification(deviceMap, date, time, deviceIds, value.children);
            }
        });
    }

    onClickRun = () => {
        const {t, redraw} = this.props;
        const verificationMode = this.getVerificationMode();
        
        if(verificationMode.selectedGroup && verificationMode.selectedGroup.length > 0) {
            let groupIds = [];
            verificationMode.selectedGroup.map((value) => {
                groupIds.push(value.groupId);
            });
            deviceService.fetchDeviceFilter({groupIds})
            .then(res => {
                let deviceMap = new Map();
                let deviceIds = [];
                res.items.map((value) => {
                    deviceIds.push(value.deviceId);
                    deviceMap.set(value.deviceId, value);
                });
                
                const ruleset = this.currentTab === 'NEW_RULESET' ? this.props.rulesets.newRuleset.ruleset : this.props.rulesets.editRuleset.ruleset;
                const rules = ruleset.rules;

                rules.map((rule) => {
                    rule.verificationData = {};
                    rule.verificationData.deviceIds = deviceIds;
                    ruleset.verificationDeviceMap = deviceMap;
                    this.runVerification(deviceMap, verificationMode.date.enabled ? verificationMode.date.value : undefined, verificationMode.time.enabled ? verificationMode.time.value : undefined, deviceIds, rule.rule_tree);
                });

                redraw();
            }).catch((error) => {
                if(error.errorCode === 408900)
                    return;
                toastr.error(getErrorMessage(error)
                )}
            )
        }
        else {
            toastr.error(t("MESSAGE_SCHEDULE_SEL_DEVICE_GROUP_BEFORE_P"));
        }
    }

    getDeviceGroupButtonText = () => {
        const {t} = this.props;
        const verificationMode = this.getVerificationMode();
        
        if(verificationMode.selectedGroup && verificationMode.selectedGroup.length > 0) {
            let selectedDeviceGroupText = "";
            for(var i = 0; i < verificationMode.selectedGroup.length; i++) {
                if(i > 0) {
                    selectedDeviceGroupText += ", ";
                }
                
                const groupName = verificationMode.selectedGroup[i].groupNameText ? verificationMode.selectedGroup[i].groupNameText : verificationMode.selectedGroup[i].groupName;
                selectedDeviceGroupText += groupName;
            }

            return selectedDeviceGroupText;
        }
        else {
            return t("TEXT_DEVICE_GROUP_P");
        }
    }

    getCheckedDeviceGroupKeys = (groups) => {
        let selectedGroupIds = [];
        if(!isEmpty(groups)){
            groups.map((group) => {
                selectedGroupIds.push(group.groupId);
            })
        }
        return selectedGroupIds;
    }

    render() {
        const dateFormat = this.getDateFormat();
        const {t, functions, mode, setVerificationMode} = this.props;
        const {currentContentId} = this.props.menu;
        const {showNotificationModeInfo} = this.state;
        this.currentTab = currentContentId;

        let verificationMode = undefined;
        let isCurrentTabRuleset = false;
        if((this.currentTab === 'NEW_RULESET' && mode === 'NEW') || (this.currentTab === 'EDIT_RULESET' && mode === 'EDIT')) {
            isCurrentTabRuleset = true;
            verificationMode = this.getVerificationMode();
            this.init();
        }
        
        return (
            <>
                {isCurrentTabRuleset &&
                <div>
                    <div className="contents_buttonWrap" style={{height: "60px"}}>
                        <div className="leftButton">
                            <WhiteButton id="rulesetSave" name={t("COM_BUTTON_SAVE")} onClick={()=>functions.saveRuleset()}/>
                            {
                                (this.currentTab === 'EDIT_RULESET' && mode === 'EDIT') &&
                                    <WhiteButton id="rulesetSaveAs" name={t("BUTTON_SAVE_AS_P")} onClick={()=>functions.saveRuleset(true)}/>
                            }
                            <WhiteButton id="rulesetCancel" name={t("BUTTON_CANCEL_P")} onClick={()=>functions.cancel(currentContentId)}/>
                        </div>
                        <div className="rightButton ruleset_rightbutton_wrap">
                            <div style={{textAlign: 'right'}}>
                                <Checkbox
                                    id={"rulesetVerificationMode"}
                                    name={t("MIS_SID_VERIFICATION_MODE")}
                                    checked={verificationMode.enabled}
                                    onChange={this.onChangeVerificationModeCheckBox}/>
                                <div className="quest_img_noti" style={{float: 'right'}}>
                                    <a onMouseEnter={() => this.setState({showNotificationModeInfo: true})} onMouseLeave={() => this.setState({showNotificationModeInfo: false})}/>
                                    <div className="opacity_notice_wrap" style={{display: showNotificationModeInfo ? '' : 'none'}} id="rulesetDevNotice">
                                        {getMixString(["MIS_SID_MIX_YOU_CAN_PREVIEW_STATUS_DEVICE_GROUP_TIME_ETC_DATALINK_CANNOT_CHECKED_VERIFICATION", "MIS_SID_RULESET", "MIS_SID_VERIFICATION_MODE"])}
                                    </div>
                                </div>
                            </div>
                            { verificationMode.enabled &&
                                <div className="verification_mode">
                                    <div className="menu_select_box">
                                        <input type="hidden" id="verificationDeviceGroupId" />
                                        <button className="base un_radius select" style={{width:131, borderRadius: '5px'}} id="verificationDeviceGroupBtn" onClick={this.onClickVerificationDeviceGroup}>
                                            <span>
                                                <span style={{width: "90px"}}>{this.getDeviceGroupButtonText()}</span>
                                                <span className="arrow"></span>
                                            </span>
                                        </button>
                                    </div>

                                    <Checkbox
                                        id={"verificationDateInputCheckbox"}
                                        style={{marginLeft: "20px"}}
                                        checked={verificationMode.date.enabled}
                                        onChange={this.onChangeVerificationModeCheckBox}/>
                                    <DateInput
                                        disabled={!verificationMode.date.enabled}
                                        dateFormat={dateFormat}
                                        width={120}
                                        height={28}
                                        date={verificationMode.date.value}
                                        onChange={(date) => {
                                            setVerificationMode(this.currentTab, {...verificationMode, date: {enabled: true, value: date}});
                                        }}/>
                                    <Checkbox
                                        id={"verificationTimeInputCheckbox"}
                                        style={{marginLeft: "20px"}}
                                        checked={verificationMode.time.enabled}
                                        onChange={this.onChangeVerificationModeCheckBox}/>
                                    <TimeInput
                                        width={60}
                                        disabled={!verificationMode.time.enabled}
                                        value={verificationMode.time.value}
                                        onChange={(e) => {
                                            setVerificationMode(this.currentTab, {...verificationMode, time: {enabled: true, value: e.target.value}});
                                        }}/>
                                    <WhiteButton name={t("MIS_SID_RUN")} style={{minWidth: "137px"}} onClick={() => this.onClickRun()}/>
                                </div>
                            }
                        </div>
                    </div>
                    
                    <div id="ruleset_edit_view" style={{height: this.state.height, overflow: "auto"}}>
                        { this.rules.map((value, index) => {
                            return <RuleArea
                                conditionMap={this.conditionMap}
                                resultMap={this.resultMap}
                                contentMap={this.contentMap}
                                rule={value}
                                deleteRuleFunc={this.deleteRule}
                                key={"ruleId_" + value.rule_id}
                                verificationModeEnabled={verificationMode.enabled}
                                mode={mode} />
                        })}
                        <div className="ruleset_add_rule_area">
                            <div onClick={() => {this.addRule()}}></div>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
}

export default connect(
    state => ({
        menu: state.menu,
        rulesets: state.rulesets,
    }),
    dispatch => ({
        setVerificationMode: (currentTab, mode) => dispatch(rulesetAction.setVerificationMode(currentTab, mode)),
        setFixedHighlightPath: (ruleId, path) => dispatch(rulesetAction.setFixedHighlightPath(ruleId, path)),
        redraw: () => dispatch(rulesetAction.redraw()),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(withTranslation()(withMISOpt(Ruleset)));