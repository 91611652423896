
export function getDeviceMessage(param, t){
	switch(param){
		case 'save':						return t('COM_BUTTON_SAVE' );
		case 'basicInfo':					return t('COM_SID_BASIC_INFORMATION');
		case 'connectionInfo':				return t('COM_TV_SID_SERVER_NETWORK_SETTING');
		case 'intervalInfo':				return t('COM_TEXT_PERIOD2_P');
		case 'timezoneInfo':				return t('COM_TABLE_TIME_ZONE_INDEX_P');
		case 'vncInfo':						return t('BUTTON_VNC_P');
		case 'managementInfo':				return t('TEXT_MANAGE_P');
		case 'autoSettingInfo':				return t("DID_LFD_SEL_AUTO");
		case 'dataLinkInfo':				return t('MIS_SID_20_LINK_DATA');
		case 'contentProgressInfo':			return t('TEXT_TITLE_CONTENT_DOWNLOAD_STATUS_P');
		case 'downloadServerInfo':			return t('MIS_SID_EDGE_SERVER');
		case 'amsInfo':						return t('COM_SID_VOICE_FACE_RECOGNITION');
		case 'screenBurnProtection':		return t('COM_SID_SCREEN_BURN_PROTECTION');
		case 'etcInfo':						return t('DID_LAYOUTS_COMMON_ETC');
		case 'smartFeature':				return t('MIS_SID_SMART_FEATURE');
		case 'screenCapture':				return t('LIST_TITLE_CAPTURE');
		case 'thumbnail':					return t('COM_TV_SID_THUMBNAIL');
		case 'deviceName':					return t('TABLE_DEVICE_NAME_P');
		case 'deviceMapAddress':			return t('TABLE_MAC_ADDR_P');
		case 'deviceIpAddress':				return t('TABLE_IP_ADDR_P');
		case 'magicinfoServerUrl':			return t('TABLE_MAGICINFO_SERVER_URL_P');
		case 'ftpConnectMode':				return t('TABLE_FTP_CONNECT_MODE_P');
		case 'connectionLimitTime':			return t('TEXT_CONNECTION_LIMIT_TIME_P');
		case 'bandWidth':					return t('MIS_SID_NETWROK_BANDWIDTH_LIMIT');
		case 'proxySetting':				return t('COM_TEXT_PROXY_SETTING_P');
		case 'proxyAddress':				return t('COM_MIS_TEXT_ADDRESS_P');
		case 'proxyPort':					return t('TABLE_PORT_P');
		case 'proxyUserId':					return t('COM_DID_ADMIN_USER_USERID');
		case 'proxyPassword':				return t('TEXT_PASSWORD_P');
		case 'proxyExcludeList':			return t('MIS_SID_PREMIUM_PROXY_EXCEPTION');
		case 'useSemicolons'	:			return t('MIS_SID_PREMIUM_USE_SEMICOLONS_SEPARATE');
		case 'triggerInterval':				return t('TABLE_TRIGGER_INTERVAL_P');
		case 'monitoringInterval':			return t('TABLE_MONITORING_INTERVAL_P');
		case 'childMonitoringInterval':		return t('MIS_SID_SERVER_CASEP_SECONDARY_DEVICE_MONITORING_CYCLE');
		case 'screenCaptureInterval':		return t('TABLE_SCREEN_CAPTURE_INTERVAL_P');
		case 'systemRestartInterval':		return t('TEXT_SYSTEM_RESTART_INTERVAL_P');
		case 'switchPdf':					return t('MIS_SID_20_PDF_CONVERSION_INTERVAL');
		case 'switchPpt':					return t('MIS_SID_20_OFFICE_CONVERSION_INTERVAL');
		case 'logValidityPeriod':			return t('TEXT_PROOF_OF_PLAY_MANAGEMENT_P') + t('TEXT_VALIDITY_PERIOD_P');
		case 'timezoneIndex':				return t('COM_TABLE_TIME_ZONE_INDEX_P');
		case 'dayLightSaving':				return t('TABLE_DAY_LIGHT_SAVING_P');
		case 'contentProgress':				return t('COM_TEXT_SETTING_P');
		case 'contentProgressInterval':		return t('TEXT_INTERVAL_P');
		case 'contentProgressUnit':			return t('TEXT_JOB_UNIT_P');
		case 'contentsDownloadMode': 		return t('MIS_SID_DOWNLOAD_MODE');
		case 'onlyEdgeServer':				return t('MIS_SID_EDGE_SERVER') + 'Only';
		case 'downloadServer':				return t('MIS_SID_EDGE_SERVER');
		case 'repositoryPath':				return t('MIS_SID_20_STORAGE_PATH');
		case 'mntFolderPath':				return t('TEXT_MANAGEMENT_FOLDER_PATH_P');
		case 'ContentManagement':			return t('TEXT_CONTENT_MANAGEMENT_P');
		case 'ContentValidityPeriod':		return t('TEXT_VALIDITY_PERIOD_P');
		case 'ContentValiditySize':			return t('TEXT_VALIDITY_SIZE_P');
		case 'logLevel':					return t('TEXT_LOG_LEVEL_P');
		case 'titleUser':					return t('TEXT_TITLE_USER_P');
		case 'developer':					return t('TEXT_DEVELOPER_P');
		case 'debugger':					return t('TEXT_DEBUGGER_P');
		case 'logValidityDay':				return t('TEXT_VALIDITY_PERIOD_P');
		case 'logValiditySize':				return t('TEXT_VALIDITY_SIZE_P');
		case 'proofManagement':				return t('TEXT_PROOF_OF_PLAY_MANAGEMENT_P');
		case 'proofValidityPeriod':			return t('TEXT_VALIDITY_PERIOD_P');
		case 'proofValiditySize':			return t('TEXT_VALIDITY_SIZE_P');
		case 'StatisticsLastRequestTime':	return t('COM_TEXT_TITLE_STATISTICS_P') + t('MIS_TEXT_LAST_CHECK_TIME_P');
		case 'lastCheckTime':				return t('MIS_TEXT_LAST_CHECK_TIME_P');
		case 'screenRotation':				return t('TEXT_SCREEN_ROTATION_P');
		case 'computerName':				return t('MIS_TEXT_COMPUTERNAME_P');
		case 'useMplayer':					return t('MIS_TEXT_USE_MPPLAYER_P');
		case 'resetPassword':				return t('TEXT_RESET_PASSWORD_P');
		case 'bgColor':						return t('COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR');
		case 'percent':						return '%';
		case 'text.percent':				return '%';
		case 'sec':							return t('COM_SID_SEC');
		case 'min':							return t('COM_SID_MIN');
		case 'rotation':					return "&#176;";
		case 'kbps':						return t('MIS_SID_20_KBPS');
		case 'day':							return t('TEXT_DAY_P');
		case 'mb':							return "MB";
		case 'true':						return t('MIS_TEXT_TRUE_P');
		case 'false':						return t('MIS_TEXT_FALSE_P');
		case 'tunnelingServer':				return t('TABLE_TUNNELING_SERVER_P');
		case 'restartInterval':				return t('TEXT_SYSTEM_RESTART_INTERVAL_P');
		case 'autoIpSet':					return t('MIS_TEXT_AUTO_IP_SET_P');
		case 'autoComputerNameSet':			return t('MIS_TEXT_AUTO_COMPUTERNAME_SET_P');
		case 'autoInfo':					return t('DID_LFD_SEL_AUTO');
		case 'faceRecognition':				return t('COM_SID_VOICE_FACE_RECOGNITION');
		case 'amsPlayMode':					return t('DID_LFD_MODE');
		case 'amsNotUsed':					return t('MIS_SID_NOT_USED');
		case 'amsTrafficNode':				return t('COM_MIS_SID_TRAFFIC');
		case 'amsAudienceMode':				return t('COM_LIST_TITLE_AUDIENCE_MEASUREMENT_KR_AUDIENCE');
		case 'amsTrafficNAudience':			return t('MIS_SID_TRAFFIC_AND_AUDIENCE_MEASURMENT');
		case 'webCam':						return "WebCam";
		case 'fileDataDelSize':				return t('MIS_TEXT_MAX_STORAGE_SIZE_P');
		case 'contentReadyInterval':		return t('MIS_TEXT_PLAY_LOADING_INTERVAL_P');
		case 'playerStartTimeout':			return t('MIS_TEXT_PLAY_WAITING_TIME_P');
		case 'vncPassword':					return t('COM_MIS_TEXT_VNC_PASSWORD_P');
		case 'startTime':					return t('COM_SID_START_TIME');
		case 'endTime':						return t('COM_SID_END_TIME');
		case 'timeDifference':				return t('MIS_TEXT_TIME_DIFFERENCE_P');
		case 'notSupportTimezone':			return t('COM_TV_SID_UNSUPPORTED_VERSION') + t('COM_TABLE_TIME_ZONE_INDEX_P');
		case 'notSupportDst':				return t('COM_TV_SID_UNSUPPORTED_VERSION') + t('TABLE_DAY_LIGHT_SAVING_P');
		case 'magicInfoUrlVWT':				return t('MIS_SID_NOT_EDIT_SERVER_URLS_VIDEO_WALL');
		case 'usedProxy':					return t('MESSAGE_UPLOADER_USE_PROXY_P');
		case 'notUsedProxy':				return t('COM_SETUP_NEW_STRING17_P');
		case 'basicInfo' :					return t('COM_SID_BASIC_INFORMATION' );
		case 'screenCapture' :				return t('LIST_TITLE_CAPTURE');
		case 'thumbnail' :					return t('COM_TV_SID_THUMBNAIL');
		case 'deviceName' :					return t('TABLE_DEVICE_NAME_P');
		case 'deviceMapAddress' :			return t('TABLE_MAC_ADDR_P');
		case 'deviceIpAddress' :			return t('TABLE_IP_ADDR_P');
		case 'getCurrent' :					return t('COM_TV_SID_CURRENT_STATUS_KR_KOR');
		case 'mdcUpdateTime' :				return t('MIS_TEXT_UPDATE_TIME_P');
		case 'generalInfo' :				return t('COM_TEXT_GENERAL_P');
		case 'powerCheck' :					return t('TABLE_POWER_P' );
		case 'panelStatus' :				return t('MIS_SID_EMBEDDED_CBJAN_DISPLAY_PANEL');
		case 'displayStatus':				return t('MIS_SID_DISPLAY_STATUS');
		case 'turnOn' :						return t('TEXT_ON_P');
		case 'turnOff' :					return t('BUTTON_OFF');
		case 'lock' :						return t('BUTTON_CHECK_OUT');
		case 'unLock' :						return t('DID_LFD_UNLOCK');
		case 'time' :						return t('LIST_TITLE_HOUR');
		case 'basicSource' :				return t('COM_DID_LFD_SOURCE');
		case 'basicVolume' :	 			return t('TABLE_VOLUME_P');
		case 'safeLock' :					return t('COM_IDS_TXT_DIRECT_SAFETY_LOCK');
		case 'setting' :					return t('COM_TEXT_SETTING_P');
		case 'settingCancel' :				return t('TEXT_DISABLE_P');
		case 'remoteControl' :				return t('MIS_SID_REMOTE_CONTROL_LOCK');
		case 'basicMute' :					return t('TABLE_MUTE_P');
		case 'panelLock' :					return t('COM_IDS_TXT_BUTTON_LOCK');
		case 'generalOsd' :					return t('DID_LFD_OSD');
		case 'lockAllKey' :					return t('TABLE_ALL_KEY_LOCK_P');
		case 'monitorTemperature' :			return t('COM_IDS_TXT_CURRENT_TEMPERATURE');
		case 'alarmTemperature' :			return t('COM_IDS_TXT_TEMPERATURE_CONTROL');
		
		case 'sensorInternalTemperature' :  return t('MIS_SID_SERVER_INTERNAL_TEMPERATURE');
		case 'sensorInternalHumidity' :  	return t('MIS_SID_SERVER_INTERNAL_HUMIDITY');
		case 'sensorEnvironmentTemperature':return t('MIS_SID_SERVER_AMBIENT_TEMPERATURE');
		case 'sensorFrontglassTemperature':	return t('MIS_SID_SERVER_FRONT_GLASS_TEMPERATURE');
		case 'sensorFrontglassHumidity':	return t('MIS_SID_SERVER_FRONT_GLASS_HUMIDITY');
		
		case 'panelOnTime' :				return t('TABLE_PANEL_ON_TIME_P');
		case 'colorTone' :					return t('TEXT_COLOR_TONE_P');
		case 'videoModeInfo' :				return t('COM_IDS_TXT_PICTURE') + " (VIDEO)";
		case 'videoMode' :					return t('DID_LFD_MODE');
		case 'colorTemperature' :			return t('TEXT_COLOR_TEMPERATURE_P');
		case 'aspectRatio' :				return t('DID_LFD_ASPECT_RATIO');
		case 'digitalNr' :					return t('COM_TV_SID_DIGITAL_CLEAN_VIEW');
		case 'filmMode' :					return t('DID_LFD_FILM_MODE');
		case 'normal' :						return t('TEXT_NORMAL_P');
		case 'low' :						return t('COM_DID_LFD_LOW');
		case 'hdmiBlackLevel' :				return t('COM_DID_LFD_HDMI_BLACK_LEVEL');
		case 'function' :					return t('TEXT_FUNCTION_P');
		case 'direction' :					return t('TEXT_DIRECTION_P');
		case 'soundInfo' :					return t('TEXT_TITLE_SOUND_P');
		case 'soundMode' :					return t('COM_SID_SOUND_MODE');
		case 'soundSrs' :					return t('TEXT_SRS_P');
		case 'maintenanceInfo' :			return t('TEXT_TITLE_MAINTENANCE_P');
		case 'mntAutoMax' :					return t('COM_TV_SID_SCREEN_LAMP_SCHEDULE'); //+'('+t('DID_LFD_SEL_AUTO')+')';
		case 'mntAutoMin' :					return t('COM_TV_SID_SCREEN_LAMP_SCHEDULE'); //+'('+t('DID_LFD_SEL_AUTO')+')';
		case 'mntAutoMaxSub' :				return t('COM_TEXT_MAX_TIME_P');
		case 'mntAutoMinSub' :				return t('COM_TEXT_MIN_TIME_P');
		case 'text.warm1':					return t('COM_IDS_TXT_WARM1');
		case 'text.warm2':					return t('COM_IDS_TXT_WARM2');
		case 'text.cool1':					return t('DID_LFD_COOL') + '&nbsp;1';
		case 'text.cool2':					return t('DID_LFD_COOL') + '&nbsp;2';
		case 'hour' :						return t('COM_TEXT_HOUR_P');
		case 'maxMin' :						return t('LIST_TITLE_MIN2');
		case 'maxAmpm' :					return 'AM/PM';
		case 'maxValue' :					return t('TEXT_MAX_VALUE_P');
		case 'minMin' :						return t('LIST_TITLE_MIN2');
		case 'minAmpm' :					return 'AM/PM';
		case 'minValue' :					return t('COM_DID_LFD_MIN_VALUE');
		
		case 'mntManual' :					return t('COM_TEXT_LAMP_CONTROL_P')+'('+t('TEXT_MANUAL_P')+')';
		//case 'safetyScreenTimer' :			return t('text.safety_screen_timer');
		//case 'safetyScreenRun' :			return t('text.safety_screen_run');
		case 'immediateDisplay':			return t('COM_TV_IMMEDIATE_DISPLAY');
		case 'pixelShift' :					return t('TEXT_PIXEL_SHIFT_P');
		case 'advancedInfo' :				return t('TEXT_TITLE_ADVANCED_SETTING_P');
		case 'autoPower' :					return t('DID_LFD_AUTO_POWER');
		case 'standBy' :					return t('TEXT_STANDBY_CONTROL_P');
		case 'networkStandBy' :				return t('COM_TV_SID_NETWORK_STANDBY');
		case 'userAutoColor' :				return t('TEXT_USER_AUTO_COLOR_P');
		case 'advancedReset' :				return t('COM_DID_LFD_RESET');
		case 'reset' :						return t('COM_DID_LFD_RESET');
		case 'userAuto' :					return t('TEXT_USER_AUTO_COLOR_P');
		case 'osdDisplay' :					return t('COM_DID_LFD_OSD_DISPLAY_TYPE');
		case 'fanControl' :					return t('TEXT_FAN_CONTROL_P');
		case 'fanSpeed' :					return t('TEXT_FAN_SPEED_SETTING_P');
		case 'pcInfo' :						return t('COM_IDS_TXT_PICTURE') + " (PC)";
		case 'pcMagicBright' :				return t('TEXT_MAGICBRIGHT_P');
		case 'pcColortone' :				return t('TEXT_COLOR_TONE_P');
		case 'pcSize' :						return t('COM_TEXT_SIZE_P');
		case 'pcGamma' :					return t('COM_DID_LFD_GAMMA');
		case 'pcHdmiBlackLevel' :			return t('COM_DID_LFD_HDMI_BLACK_LEVEL');
		case 'signalBalanceInfo' :			return t('COM_SID_WHITE_BALANCE');
		case 'status' :						return t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS');
		case 'RGain' :						return t('COM_SID_R_GAIN');
		case 'GGain' :						return t('COM_SID_G_GAIN');
		case 'BGain' :						return t('COM_SID_B_GAIN');
		case 'sharp' :						return t('DID_LFD_SHARPNESS');
		case 'ROffset' :					return t('COM_SID_R_OFFSET');
		case 'GOffset' :					return t('COM_SID_G_OFFSET');
		case 'BOffset' :					return t('COM_SID_B_OFFSET');
		case 'gain' :						return t('COM_DID_LFD_GAIN');
		case 'screenWidth' :				return "Screen Width";
		case 'screenHeight' :				return "Screen Height";
		case 'viewMoreHiddenCategory':		return '';
		case 'viewMore':					return t('BUTTON_MORE_VIEW_P');
		case 'pictureInfo':					return t('COM_IDS_TXT_PICTURE');
		case 'coarse':						return t('TEXT_COARSE_P');
		case 'fine':						return t('TEXT_FINE_P');
		case 'hpos':						return t('TEXT_H_POSITION_P');
		case 'vpos':						return t('TEXT_V_POSITION_P');
		case 'autoAdjustment':				return t('TEXT_AUTO_ADJUSTMENT_P');
		case 'securityInfo':				return t('COM_SID_SECURITY');
		case 'enable2':						return t('TEXT_ENABLE2_P');
		case 'timer':						return t('DID_ADMIN_URGENT_TIMER');
		
		case 'screenTimerOff':					return t('BUTTON_OFF');
		case 'screenTimerRepeatScroll':			return t('TEXT_SCROLL_P') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatPixel':			return t('TEXT_PIXEL_P') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatBar':			return t('TEXT_BAR_P') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatEraser':			return t('TEXT_ERASER_P') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatAllWhite':		return t('TEXT_ALL_WHITE_P') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatSignalPattern':	return t('TEXT_SIGNAL_PATTERN_P') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatRollingBar':		return t('COM_TV_ROLLING_BAR') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerRepeatFadingScreen':	return t('COM_TV_FADING_SCREEN') + '(' + t('TEXT_REPEAT_P') + ')';
		case 'screenTimerIntervalScroll':		return t('TEXT_SCROLL_P') + '(' + t('TEXT_INTERVAL_P') + ')';
		case 'screenTimerIntervalPixel':		return t('TEXT_PIXEL_P') + '(' + t('TEXT_INTERVAL_P') + ')';
		case 'screenTimerIntervalBar':			return t('TEXT_BAR_P') + '(' + t('TEXT_INTERVAL_P') + ')';
		case 'screenTimerIntervalEraser':		return t('TEXT_ERASER_P') + '(' + t('TEXT_INTERVAL_P') + ')';
		case 'screenTimerIntervalRollingBar':	return t('COM_TV_ROLLING_BAR') + '(' + t('TEXT_INTERVAL_P') + ')';
		case 'screenTimerIntervalFadingScreen':	return t('COM_TV_FADING_SCREEN') + '(' + t('TEXT_INTERVAL_P') + ')';
		
		case 'tagInfo':							return t('MIS_TEXT_TAG_NAME_P_KR_MIS20');
		case 'tagList':							return t('MIS_TEXT_TAG_P');
		case 'playerResolution':				return t('MIS_SID_PLAYER_RESOLUTION');
		case 'hasChild':						return t('MIS_SID_DAISY_CHAIN_VIDEO_WALL_MODE');
		
		case 'gammaMode1':					return t('DID_LFD_MODE') + '&nbsp;1';
		case 'gammaMode2':					return t('DID_LFD_MODE') + '&nbsp;2';
		case 'gammaMode3':					return t('DID_LFD_MODE') + '&nbsp;3';
		case 'gammaMode4':					return t('DID_LFD_MODE') + '&nbsp;4';
		case 'gammaMode5':					return t('DID_LFD_MODE') + '&nbsp;5';
		
		case 'mode_repeat':					return t('DID_LFD_MODE');
		case 'mode_interval':				return t('DID_LFD_MODE');
		case 'mode_repeat2':				return '';
		case 'mode_interval2':				return '';
		
		case 'osdDisplay_hide':				return '';
		
		case 'zoom1':						return t('COM_DID_LFD_ZOOM') + '&nbsp;1';
		case 'zoom2':						return t('COM_DID_LFD_ZOOM') + '&nbsp;2';
		case 'osdDisplay_hide':				return '';
		case 'tint':						return t('COM_TV_SID_TINT_GR');
		case 'maxPowerSaving':				return t('COM_SID_MAX_ECO_MODE_KOR_MSG');
		case 'brightnessLimit':				return t('COM_LFD_SID_BRIGHTNESS_LIMIT');
		case 'touchControlLock':			return t('COM_TV_SID_TOUCH_CONTROL_LOCK');
		case 'samsungDeviceManagement':     return t('MIS_SID_ADVANCED_SECURITY');
		case 'usbPort':						return t('MIS_SID_USB_LOCK');
		case 'networkPort':					return t('MIS_SID_NW_LOCK');
		case 'serverNetworkSetting':		return t('MIS_SID_SERVER_NW_SETTINGS_LOCK');
		case 'whiteList':					return t('MIS_SID_SERVER_CASEP_ALLOWLIST');
		case 'selectAddButtonNewList':		return t('MIS_SID_PREMIUM_SELECT_ADD_ADD_NEW_LIST');
		case 'autoSourceSwitching':			return t('COM_TV_SID_AUTO_SOURCE_SWITCHING');
		case 'webBrowserUrl':				return t('text.web_browser_url');
		case 'customLogo':					return t('COM_PJT_IDS_SETUP_14');
		case '3_seconds':					return '3 ' + t('TEXT_SEC_P');
		case '5_seconds':					return '5 ' + t('TEXT_SEC_P');
		case '7_seconds':					return '7 ' + t('TEXT_SEC_P');
		case '5_minutes':					return '5 ' + t('MIS_SID_20_MINUTE_S');
		case '10_minutes':					return '10 ' + t('MIS_SID_20_MINUTE_S');
		case '15_minutes':					return '15 ' + t('MIS_SID_20_MINUTE_S');
		case '30_minutes':					return '30 ' + t('MIS_SID_20_MINUTE_S');
		case 'screenMute':					return t('TABLE_MUTE_P');
		case 'screenMute2':					return t('COM_SCREEN_OFF');
		case 'screenFreeze':				return t('COM_TV_SID_FREEZE');
		case 'mpegNoiseFilter':				return t('COM_SID_MPEG_NOISE_FILTER');
		case 'blackTone':					return t('COM_SID_BLACK_TONE');
		case 'fleshTone':					return t('COM_SID_FLESH_TONE');
		case 'rgbOnlyMode':					return t('COM_SID_RGB_ONLY_MODE');
		case 'pictureEnhancer':				return t('COM_SID_LFD_PICTURE_ENHANCER');
		case 'colorSpace':					return t('COM_SID_COLOR_SPACE');
		case 'ledHdrDre':					return t('COM_SID_TIZEN_LFD_NON_DRE');
		case 'autoMotionPlus':				return t('COM_SID_AUTO_MOTION_PLUS');
		case 'autoMotionPlusJudderReduction':		return t('COM_SID_JUDDER_REDUCTION');   
		case 'screenPosition':				return t('COM_IDS_TXT_SCREEN_POSITION_KR_POS'); 
		case 'urlLauncher':					return t('COM_TV_SID_URL_LAUNCHER');
		case 'pinCode':						return t('MIS_SID_SERVER_PIN_CODE');
		case 'pin_code':					return t('MIS_SID_SERVER_PIN_CODE');
		case 'onoff_timer':					return t('COM_TV_SID_ONOFF_TIMER');
		case 'holidayManagement':			return t('TEXT_HOLIDAY_MANAGEMENT_P');
		case 'protocolPriority':			return t('MIS_SID_CONTENT_DOWNLOAD_PROTOCOL');
		case 'display':						return t('MIS_TEXT_DISPLAY_CONTROL_P');
		case 'time':						return t('TEXT_TIME_P');
		case 'setup':						return t('TEXT_SETUP_P');
		case 'security':					return t('COM_SID_SECURITY');
		case 'currentVersion':				return t('TEXT_CURRENT_VERSION_P');
		case 'disconnected':				return t('TEXT_DISCONNECTED_P');
		case 'StandardAndLive':				return t('TEXT_MDC_PV_STANDARD_P') + ' / ' + t('COM_SID_LIVE');
		case 'rmDataSetting':				return t('MIS_SID_DEVICE_STATUS_DATA_SETTINGS');		
		case '4_hours':						return '4 ' + t('LIST_TITLE_HOUR');
		case '6_hours':						return '6 ' + t('LIST_TITLE_HOUR');
		case '8_hours':						return '8 ' + t('LIST_TITLE_HOUR');
		case '16_hours':					return '16 ' + t('LIST_TITLE_HOUR');
		case 'service':						return t('COM_SID_SERVICE');
		case 'smartDownload':				return t('MIS_SID_SMART_DOWNLOAD');
		case 'bluetoothLock':				return t('MIS_SID_BLUETOOTH_LOCK');
		case 'wifiLock':					return t('MIS_SID_WIFI_LOCK');
		default :							return t(param);		
	}
}
