import React from 'react';
import {useTranslation} from "react-i18next";
import {isNil} from "lodash";
import Checkbox from "./Checkbox";

const WeeklyCheckbox = ({width = 100, checked, onClick}) => {
    const {t} = useTranslation();

    const days = [
        {id: 'weeklyMon', value: 'MON', name: t('COM_TEXT_DAY_MONDAY_P')},
        {id: 'weeklyTue', value: 'TUE', name: t('COM_TEXT_DAY_TUESDAY_P')},
        {id: 'weeklyWed', value: 'WED', name: t('COM_TEXT_DAY_WEDNESDAY_P')},
        {id: 'weeklyThu', value: 'THU', name: t('COM_TEXT_DAY_THURSDAY_P')},
        {id: 'weeklyFri', value: 'FRI', name: t('COM_TEXT_DAY_FRIDAY_P')},
        {id: 'weeklySat', value: 'SAT', name: t('COM_TEXT_DAY_SATURDAY_P')},
        {id: 'weeklySun', value: 'SUN', name: t('COM_TEXT_DAY_SUNDAY_P')}
    ]

    const getChecked = value => {
        const day = checked.find(day => day === value);
        if (!isNil(day) && day.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <div style={{width: width}}>
            {
                days.map(
                    (day, index) => <Checkbox key={index} id={day.id} propertyName={day.value} name={day.name} checked={getChecked(day.value)} onClick={onClick}/>
                )
            }
        </div>
    )
}
export default WeeklyCheckbox;