import React from 'react';
import APLAYER from '../../../images/icon/APLAYER.png';
const APlayer = ({deviceTypeVersion, text, disabled = false}) => {
    return(
        <span className={"device_icon"}>
            <img src={APLAYER} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'APLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
            {text ?  'A Player' : ''}
        </span>
    )
}
export default APlayer