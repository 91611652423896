import React, {useEffect, useState} from 'react';
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {isNil} from 'lodash';
import {settingService, userService} from '../../services';
import TextInput from "../../components/input/TextInput";
import SettingInformation from "../../components/setting/SettingInformation";
import {isEnableMfa, getOtpLoginErrorMsg} from "../common/MfaUtil";
import {useMemo} from "react";
import "../../components/popup/login/OtpSetupPopup.css";
import { saveToken } from 'helper/fetchIntercept';

export const ConfirmPassword  = (props) => {
    const {onChangeMode, password, changePassword, currContent} = props;
    const {t} = useTranslation();

    const [data, setData] = useState({
        userId: '',
        password: password,
        loading: false
    });

    const [error1, setError1] = useState(' ');
    const [error2, setError2] = useState(' ');

    const isMfa = useMemo(() => isEnableMfa(), []);

    useEffect(() => {
        setData({...data, userId: JSON.parse(localStorage.getItem('user')).id});
    }, []);

    const initErrorMsg = () => {
        setError1(' ');
        setError2(' ');

    }

    const checkPassword = () => {

        initErrorMsg();

        if(isNil(data.password) || data.password == ""){

            if(isMfa){
                setError1(t('MIS_SID_CAJUL_ENTER_CODE'));
            }else{
                setError1(t('COM_MESSAGE_USER_ENTER_PASS_P'));
            }



            return;
        }
        if(data.password.length > 0 && data.password.trim() == ""){

            if(isMfa){
                setError1(t('MIS_SID_CAJUL_WRONG_CODE_PLZ_AGAIN'));
            }else{
                setError1(t('COM_TV_THE_PASSWORDS_DO_NOT_MATCH_PLEASE_TRY_AGAIN_KR_LFD'));
            }

            return;
        }
        setData({...data, loading: true});


        settingService.fetchReconfirm(data.password).then(res => {
            setData({
                ...data,
                loading: false
            });

           if(res.status === "Success" && !isNil(res.items)){
                let user = JSON.parse(localStorage.getItem('user'));
                user.token = res.items;
                user.userId = user.id;
                delete user['id'];
                saveToken(user);
                onChangeMode('UPDATE');
            }else{
                if(isMfa){
                    setError1(t('MIS_SID_CAJUL_WRONG_CODE_PLZ_AGAIN'));
                }else{
                    setError1(t('COM_TV_THE_PASSWORDS_DO_NOT_MATCH_PLEASE_TRY_AGAIN_KR_LFD'));
                }

            }
        }).catch((e) => {
            if(isMfa){
                const errorMsg = getOtpLoginErrorMsg(e);
                setError1(errorMsg.error1);
                setError2(errorMsg.error2);
            }else{
                setError1(t('COM_TV_THE_PASSWORDS_DO_NOT_MATCH_PLEASE_TRY_AGAIN_KR_LFD'));
            }
        });
    }

    const onChangePassword= (e) => {
        let password = e.target.value;
        setData({...data, password: password});
        changePassword(password);
    };

    const onKeyPressChangePassword = (e) => {
        if(e.keyCode === 13){
            checkPassword();
        }
    }

    const cancelUpdate = () => {
        onChangeMode('INFO');
    }

    const getConfirmView  = () => {
        const userIdView = [ {id: 'USER_ID', name: <Trans i18nKey={"COM_DID_ADMIN_USER_USERID"} />, value: data.userId}];
        const passwordView = [ {id: 'PASSWORD', name: <Trans i18nKey={"TEXT_PASSWORD_P"} />,
            value: <TextInput autoComplete={"off"} propertyName={'PASSWORD'} type={"password"} placeholder={t("TEXT_PASSWORD_P")} value={data.password}
                              onChange={onChangePassword} onKeyDown={onKeyPressChangePassword}/>}];
        const otpView = [ {id: 'OTP', name: <Trans i18nKey={"MIS_SID_CAJUL_OTP_CODE"} />,
            value: <TextInput autoComplete={"off"} propertyName={'OTP'} type={"number"} placeholder={t("MIS_SID_CAJUL_OTP_CODE")} value={data.password}
                              onChange={onChangePassword} onKeyDown={onKeyPressChangePassword}/>}];
        const errorView = [ {id: 'ERROR', name: '',
            value: <div className="error_txt_otp">{error1}<br/>{error2}</div>}];

        if(isMfa){
            return [...userIdView, ...otpView, ...errorView];
        }else{
            return [...userIdView, ...passwordView, ...errorView];
        }
    }

    return(
        <>
            <div className="settings_list mt20" style={{borderTop: '1px solid#f2f2f2', overflowY: 'auto', overflowX: 'hidden' , display: currContent === 'MY_INFORMATION' ||  currContent === 'ALL_USER' ||  currContent === 'WITHDRAW_MEMBERSHIP'? 'block':'none'}}>
                <SettingInformation
                    id={"CHECK_PASSWORD"}
                    title={""}
                    list={getConfirmView()}
                    buttons={
                        currContent === 'MY_INFORMATION' ?
                        [
                        {id: 'CHECK_PASSWORD', name: <Trans i18nKey={"BUTTON_OK_P"} />, action: () => checkPassword() },
                        {id: 'CANCEL', name: <Trans i18nKey={"BUTTON_CANCEL_P"} />, action: () => cancelUpdate() }
                    ] : [ {id: 'CHECK_PASSWORD', name: <Trans i18nKey={"BUTTON_OK_P"} />, action: () => checkPassword() }]
                    }
                    explain={isMfa ? <Trans i18nKey={"MIS_SID_CAJUL_PLZ_ENT_OTP_AFTER_CHECK_APP_ON_MOBILE"} /> : <Trans i18nKey={"COM_TV_SID_PLEASE_ENTER_YOUR_PASSWORD"} />}
                >
                </SettingInformation>
            </div>
        </>
    )

}
