import React from 'react';
import './DevicePowerIcon.css';

const DevicePowerIcon = ({power, fault}) => {
    return (
        <span className="device_power_wrap" title={fault} style={{
            background: power ? (fault ? '#ff1000' : '#46d5b5') : '#d6d6d6'
        }}></span>
    );
};

export default DevicePowerIcon;