import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../MISDialog';
import './BackupPlayPopup.css';
import RadioGroup from '../../radio/RadioGroup';
import Checkbox from '../../checkbox/Checkbox';
import {deviceService} from '../../../services';
import Select from '../../selectbox/Select';

const backupMode = [
    {value: 'automate', title: 'MIS_SID_AUTO_BACKUP_TRANS'},
    {value: 'customize', title: 'MIS_SID_CUSTOM_BACKUP'},
];

const BackupPlayStep2 = ({data, groupId, backupDevices, targetDevices, onChange}) => {
    const {t} = useTranslation();

    const onChangeBackupMap = (backupId, targetId) => {
        data.backupMap[backupId] = targetId;
        onChange({...data});
    };

    return (
        <div className="popup_contents">
            <div className="detail_view mb15">
                <table>
                    <colgroup>
                        {/* <col width="118px"/> */}
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <RadioGroup selects={backupMode} value={data.backupMode} onChange={(e, value) => onChange({...data, backupMode: value})} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox id='ownContent' name={t('MIS_SID_PLAY_EXISTING_CONTENT')} checked={data.isOwnContent} onChange={e => onChange({...data, isOwnContent: e.target.checked})} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span className="warning">{t('MIS_SID_SERVER_WHEN_PLAY_EXISTING_CONTENT_MSG')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span id="backupModeInfoMsg">
                                    {
                                        data.backupMode === 'automate' ?
                                        t('MIS_SID_NOT_CHANGE_BACKUP_AUTO') : t('MIS_SID_SCHEDULE_TARGET_DEVICE_BACKUP')
                                    }
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td id="backupPlayTable">
                                <div className="statistics_title">
                                    <table>
                                        <colgroup>
                                            <col width="20%" />
                                            <col width="20%" />
                                            <col width="20%" />
                                            <col width="20%" />
                                            <col width="20%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>{t('MIS_SID_TARGET_DEVICE')}</th>
                                                <th>{t('TEXT_TITLE_SCHEDULE_P')}</th>
                                                <th>{t('TABLE_IP_ADDR_P')}</th>
                                                <th>{t('TABLE_MAC_ADDR_P')}</th>
                                                <th>{t('MIS_TEXT_DEVICE_BACKUPPLAYER_P')}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="list">
                                        <table>
                                            <colgroup>
                                                <col width="20%" />
                                                <col width="20%" />
                                                <col width="20%" />
                                                <col width="20%" />
                                                <col width="20%" />
                                            </colgroup>
                                            <tbody>
                                                {
                                                    targetDevices.length === 0 &&
                                                    <tr>
                                                        <td colSpan="5" style={{textAlign:'center'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</td>
                                                    </tr>
                                                }
                                                {
                                                    targetDevices.length > 0 &&
                                                    targetDevices.map(target => {
                                                        return (
                                                            <tr>
                                                                <td>{target.deviceName}</td>
                                                                <td>{target.scheduleName}</td>
                                                                <td>{target.ipAddress}</td>
                                                                <td>{target.deviceId}</td>
                                                                <td>
                                                                    {
                                                                        data.backupMode === 'automate' ?
                                                                        t('MIS_SID_AUTO_SELECT') :
                                                                        <Select width={120} selects={backupDevices.map(device => ({value: device.backupPlayerId, title: device.backupPlayerName}))} 
                                                                            value={data.backupMap[target.deviceId]} onChange={(e, value) => onChangeBackupMap(target.deviceId, value)} />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="warning">{t('MIS_SID_MULTI_BACKUP_NOT_WORK_SYNC')}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const BackupPlayStep1 = ({devices}) => {
    const {t} = useTranslation();
    return (
        <div className="popup_contents" style={{maxHeight: '272px', overflow: 'auto'}}>
            <div className="notify_wrap fc_999999 mt20">{t('MIS_SID_DEVICE_GROUP_MATCHING_BACKUP')}</div>
            <div className="detail_view mt5 mb15">
                <table>
                    <colgroup>
                        <col width="118px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            devices && devices.length > 0 &&
                            devices.map((device, i) => {
                                return (
                                    <tr key={i}>
                                        <th>{device.deviceName}</th>
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            {
                                                                (!device.tagNameList || device.tagNameList.length === 0) &&
                                                                <div className="tag_input float_l mr8" style={{minWidth: '56px'}}>{t('MIS_SID_NO_TAG')}</div>
                                                            }
                                                            {
                                                                device.tagNameList && device.tagNameList.length > 0 &&
                                                                device.tagNameList.split(',').map((tag, j) => <div key={j} className="tag_input float_l mr8">{tag}</div>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>            
            </div>
        </div>
    );
}

export const BackupPlayPopup = ({groupId, deviceIds = [], onSave, onClose}) => {
    const {t} = useTranslation();
    const [step, setStep] = useState(1);
    const [devices, setDevices] = useState([]);
    const [backupDevices, setBackupDevices] = useState([]);
    const [targetDevices, setTargetDevices] = useState([]);
    const [data, setData] = useState({
        backupMode: 'automate',
        isOwnContent: false,
        backupMap: {}
    });

    const onClickNext = () => {
        if(devices.some(device => (!device.tagNameList || device.tagNameList.length === 0))) {
            toastr.error(t('MIS_SID_DEVICE_GROUP_MATCHING_BACKUP'));
            return;
        }
        // if(!tagAllSet){
        //     toastr.error($.i18n.prop('MIS_SID_DEVICE_GROUP_MATCHING_BACKUP'));
        // }else{
        //     drawSelectBackupPlay(groupId);
        //     $('#automate').click();	// default selection
        // }

        deviceService.fetchBackupMode(groupId, deviceIds).then(res => {
            setTargetDevices(res.items.targetList);
            setBackupDevices(res.items.backupList);
            setStep(2);
        }).catch(error => console.log(error));
    };

    const onClickSave = () => {
        let backupMapInfos = [];
        if(data.backupMode === 'customize') {
            for(const prop in data.backupMap) {
                backupMapInfos.push({targetDeviceId: prop, backupPlayId: data.backupMap[prop]});
            }
        }

        deviceService.saveBackupConfig(groupId, {
            ...data,
            deviceIds,
            backupMapInfos,
            deviceCount: deviceIds.length
        }).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            onSave && onSave();
        }).catch(error => console.log(error));
    };

    useEffect(() => {
        deviceService.fetchBackupPlayChecking(groupId).then(res => {
            setDevices(res.items.successList);
        }).catch(error => console.log(error));
    }, []);

    return (
        <MISDialog
            dialog={{
                title: t('MIS_TEXT_DEVICE_BACKUPPLAYER_P'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 800,
                height: step === 1 ? 300 : 470,
                onClose
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'MSG_CANCELED',
                        title: t('MSG_CANCELED'),
                        onClick: onClose,
                    },
                    {
                        id: 'COM_BUTTON_NEXT',
                        title: t('COM_BUTTON_NEXT'),
                        onClick: onClickNext,
                        hide: step === 2
                    },
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickSave,
                        hide: step === 1
                    }
                ]
            }}
        >
            <div className='backup_play_wrap'>
                <div className="device_step step1">
                    <div className={classNames({'on': step === 1})} style={{width:'50%'}}>
                        <span className="step">01</span>
                        <span className="step_title txt1">{t('MIS_SID_ASSIGN_MEDIA_TAG')}</span>
                    </div>
                    <div className={classNames({'on': step === 2})} style={{width:'50%'}}>
                        <span className="step">02</span>
                        <span className="step_title txt1" style={{width: 330}}>{t('MIS_SID_20_SELECT_A_BACKUP_PLAY_DEVICE')}</span>
                    </div>
                </div>
                {
                    step === 1 &&
                    <BackupPlayStep1 devices={devices} />
                }
                {
                    step === 2 &&
                    <BackupPlayStep2 data={data} groupId={groupId} backupDevices={backupDevices} targetDevices={targetDevices} onChange={setData} />
                }
            </div>
        </MISDialog>
    );
};

export default BackupPlayPopup;