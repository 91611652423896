import React from 'react';
import { withTranslation } from 'react-i18next';
import GroupsPopup from "./../GroupsPopup";

const PlaylistGroupPopup = ({t, title, onClose, onSave}) => {

    const close = () => {
        onClose();
    }

    const save = (groups) => {
        onSave(groups);
    }

    return (
            <GroupsPopup
                title = {title}
                mode={"playlist"}
                checkbox={false}
                save={(groups)=>save(groups)}
                close={()=>close()}
            />
    );
};


export default withTranslation()(PlaylistGroupPopup);