import React from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog'
import enableImage from '../../../images/img/eg_enable.png';
import disableImage from '../../../images/img/eg_disable.png';

const AdvertisementGuidePopup = React.forwardRef(({onClose}, ref) => {
    const {t} = useTranslation();

    return (
        <MISDialog
            dialog={{
                title: "e.g. Start Time 09:00, End Time 18:00",
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 720,
                height: 480,
                onClose: onClose,
            }}>
            <div className="devicepop_wrap mt28">
                <table>
                    <tbody>
                    <tr style={{height:25, fontSize:'1.3em'}}>
                        <th style={{borderBottom: '1px solid #efeff0'}}><span>{t("TEXT_ENABLE2_P")}</span></th>
                        <th style={{width:20}}></th>
                        <th style={{borderBottom: '1px solid #efeff0'}}><span>{t("MIS_SID_DIABLED")}</span></th>
                    </tr>
                    <tr style={{paddingTop: 10}}>
                        <td><img src={enableImage} /></td>
                        <td style={{width:40}}></td>
                        <td><img src={disableImage} /></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
});

export default AdvertisementGuidePopup;