import {authHeader, createParams, createQuery, handleResponse, history, objToQueryString} from '../helper';
import {snakeCase} from 'lodash';
import fileDownload from 'js-file-download';
import {fetchWithToken as fetch} from '../helper/fetchIntercept.js';
import {fetchForDownload} from "../helper/fetchIntercept";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const playlistService = {
    deletePlaylist,
    copyPlaylist,
    movePlaylist,
    movePlaylistCheck,
    restorePlaylist,
    editCategory,
    fetchPlaylist,
    fetchPlaylistForSchedule,
    fetchPlaylistById,
    fetchPlaylistFilter,
    fetchPlaylistGroups,
    fetchPlaylistGroupsByUserId,
    fetchPlaylistUsersByOrganizationId,
    fetchPlaylistDetailById,
    updatePlaylist,
    addPlaylist,
    editPlaylist,
    deleteRecyclebin,
    saveAsPlaylist,
    fetchPlaylistContentsById,
    fetchPlaylistTagHistory,
    exportPlaylists,
    addContentToPlaylist,
    createPlaylistGroup,
    renamePlaylistGroup,
    deletePlaylistGroup,
    movePlaylistGroup,
    fetchPlaylistByVersionId,
    searchPlaylistGroupByGroupName,
    fetchPlaylistGroupsByUserIdForByUser
};

function fetchPlaylistTagHistory(playlistId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlistId +'/tag-history', requestOptions).then(handleResponse);
}

function deletePlaylist({playlistIds , deleteMethod , productType }){

    let params = {
        ids : playlistIds || [],
        deleteMethod : deleteMethod || 'GO_TO_RECYCLEBIN',
        productType : productType || '',
    };

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/deleted-playlists', requestOptions).then(handleResponse);
}


function deleteRecyclebin(){

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/recycle-bin', requestOptions).then(handleResponse);
}


function copyPlaylist({playlistId , playlistName, groupId , shareFlag, metaData }){
    let params = {
        playlistName : playlistName || 'NO_TITLE',
        groupId : groupId ||  0,
        shareFlag : shareFlag || 1,
        metaData : metaData || ''
    };

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlistId+'/copy', requestOptions).then(handleResponse);
}


function restorePlaylist({playlistIds}){

    const params = {
        ids : playlistIds || [],
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/restored-playlists', requestOptions).then(handleResponse);
}

function movePlaylist({playlistIds, groupId }){

    const params = {
        ids : playlistIds || [],
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/groups/'+groupId+'/moved-playlists', requestOptions).then(handleResponse);
}

function movePlaylistCheck({playlistIds}){

    if(!Array.isArray(playlistIds)) {
        playlistIds = [playlistIds];
    }

    const params = {
        ids : playlistIds || [],
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/groups/check-move', requestOptions).then(handleResponse);
}

function editCategory({playlistIds , categoryIds }){

    let params = {
        playlistIds : playlistIds || [],
        categoryIds : categoryIds || [],
    };

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/categories', requestOptions).then(handleResponse);
}


function fetchPlaylist(filter = {startIndex: 1, pageSize: 30}) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let obj ={
        pageSize : filter.pageSize,
        startIndex : filter.pages,
    };
    let params = objToQueryString(obj);
    return fetch(backendHost+'/restapi/v2.0/cms/playlists'+params, requestOptions).then(handleResponse);
}

function  exportPlaylists(filter, exportType ) {

    let sortColumn = '', sortOrder = 'DESC';

    if(filter.sorted){
        sortColumn = snakeCase(filter.sorted[0].id);
        sortOrder = filter.sorted[0].desc ? 'DESC' : 'ASC';
    }


    if(!filter.groupType){
        if (typeof filter.groupId !== 'undefined' && filter.groupId !== '' && filter.groupId !== -1) {
            filter.groupType = 'GROUPED';
            filter.userId = '';
        } else {
            if (typeof filter.userId !== 'undefined' && filter.userId !== '') {
                filter.groupType = 'USER';
                filter.groupId = '';
            } else {
                filter.groupType = 'ALL';
                filter.userId = '';
                filter.groupId = '';
            }
        }
    }

    let params ={
        pageSize : 10000000 ,
        startIndex : 1 ,
        exportType : filter.exportType ||'EXCEL',
        userId : filter.userId || '',
        productType : filter.productType || 'PREMIUM',
        sortColumn : sortColumn,
        sortOrder : sortOrder,
        searchText: filter.searchText || '',
        searchCreator: filter.searchCreator || '',
        creatorIds:  filter.creatorIds || [], //
        playlistTypes : filter.playlistType || [],
        startModifiedDate: filter.startModifiedDate || '',
        endModifiedDate: filter.endModifiedDate || '',
        categoryIds : filter.categoryIds || [] ,
        groupType : filter.groupType || 'ALL',
        groupId :  filter.groupId
    };

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    let fileName = (exportType === 'EXCEL' ? "PlaylistList.xls" : "PlaylistList.pdf");
    return fetchForDownload(backendHost+'/restapi/v2.0/cms/playlists/filter/export?exportType=' + exportType, requestOptions)
        .then(res=> res.blob())
        .then(res => {
            fileDownload(res, fileName);
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });

}

function fetchPlaylistForSchedule(filter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/filter', requestOptions).then(handleResponse);
}

function fetchPlaylistFilter(filter){

    let sortColumn = '', sortOrder = 'DESC';

    if(filter.sorted){
        sortColumn = snakeCase(filter.sorted[0].id);
        sortOrder = filter.sorted[0].desc ? 'DESC' : 'ASC';
    }


    if(!filter.groupType) {
        if (typeof filter.groupId !== 'undefined' && filter.groupId !== '' && filter.groupId !== -1) {
            filter.groupType = 'GROUPED';
            filter.userId = '';
        } else {
            if (typeof filter.userId !== 'undefined' && filter.userId !== '') {
                filter.groupType = 'USER';
                filter.groupId = '';
            } else {
                filter.groupType = 'ALL';
                filter.userId = '';
                filter.groupId = '';
            }
        }
    }

    const pageSize = filter.pageSize || 25;

    //[DF201016-00783]
    let startIndex = filter.page || 1;
    if(filter.playlistType!=='undefined' && filter.playlistType[0]===6){
        startIndex=filter.startIndex;
    }else{
        if(startIndex > 1){
            startIndex =  ( startIndex - 1 ) * pageSize + 1;
        }
    }

    let params ={
        userId : filter.userId || '',
        pageSize : pageSize ,
        startIndex : startIndex ,
        productType : filter.productType || 'PREMIUM',
        sortColumn : sortColumn,
        sortOrder : sortOrder,
        searchText: filter.searchText || '',
        searchCreator: filter.searchCreator || '',
        creatorIds:  filter.creatorIds || [], //
        playlistTypes : filter.playlistType || [],
        startModifiedDate: filter.startModifiedDate || '',
        endModifiedDate: filter.endModifiedDate || '',
        categoryIds : filter.categoryIds || [] ,
        groupType : filter.groupType || 'ALL',
        groupId :  filter.groupId ,
        deviceType: filter.deviceType,
        deviceTypeVersion: filter.deviceTypeVersion
    };

    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/filter', requestOptions).then(handleResponse);
}

function fetchPlaylistById(playlistId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlistId, requestOptions).then(handleResponse);
}

function fetchPlaylistByVersionId(playlistId, versionId = 1) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlistId + '/versions/' + versionId, requestOptions).then(handleResponse);
}

function fetchPlaylistGroups(params){
    const { groupId, groupType = "MY_PLAYLIST_GROUP"} = params;
    if(typeof groupId !== 'undefined' && groupId !== '') {
        let param ;
        if (groupType === 'SUB_GROUP'){
            param = `${groupId}/sub-groups`;
        } else {
            param = `${groupId}`;
        }
        return fetch(`${backendHost}/restapi/v2.0/cms/playlists/groups/${param}`, {
            method: 'GET',
            headers: authHeader(),
        }).then(handleResponse);
    } else {
        const query = createQuery(params);
        return fetch(`${backendHost}/restapi/v2.0/cms/playlists/groups?${query}`, {  //MY_PLAYLIST_GROUP, ORGANIZATION
            method: 'GET',
            headers: authHeader(),
        }).then(handleResponse);
    }
}

function fetchPlaylistGroupsByUserIdForByUser(userId = '', organizationId)
{
    if(userId === ''){
        userId = localStorage.getItem('user').id
    };
    return fetch(`${backendHost}/restapi/v2.0/cms/playlists/users/${userId}/groups/${organizationId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchPlaylistGroupsByUserId(userId = '')
{
    if(userId === ''){
        userId = localStorage.getItem('user').id
    };
    return fetch(`${backendHost}/restapi/v2.0/cms/playlists/users/${userId}/groups`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchPlaylistUsersByOrganizationId(organizationId)
{
    return fetch(`${backendHost}/restapi/v2.0/cms/playlists/organizations/${organizationId}/users`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}



function fetchPlaylistDetailById(playlistId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlistId + '/details', requestOptions).then(handleResponse);
}

function fetchPlaylistContentsById(playlistId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlistId + '/contents', requestOptions).then(handleResponse);
}

function updatePlaylist(playlist = {}) {
    let params ={
        playlistName : playlist.playlistName,
        groupId : playlist.groupId,
        metaData: playlist.metaData,
        shareFlag : playlist.shareFlag,
        versionId : playlist.versionId,
    };

    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(params),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+playlist.playlistId, requestOptions).then(handleResponse);
}

function addPlaylist(playlistItem) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(playlistItem)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/playlists', requestOptions).then(handleResponse);
}

function editPlaylist(playlistItem) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(playlistItem)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+ playlistItem.playlistId, requestOptions).then(handleResponse);
}

function saveAsPlaylist(playlistItem) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(playlistItem)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/playlists/'+ playlistItem.playlistId +'/save-as', requestOptions).then(handleResponse);
}

function addContentToPlaylist(requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/contents', requestOptions).then(handleResponse);
}

function createPlaylistGroup(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/groups', requestOptions).then(handleResponse);
}

function renamePlaylistGroup(groupId, requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/groups/' + groupId, requestOptions).then(handleResponse);
}

function deletePlaylistGroup(groupId, requestParam){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/playlists/groups/' + groupId + "?" + createQuery(requestParam), requestOptions).then(handleResponse);
}

function movePlaylistGroup(groupId, destinationGroupId){
    return fetch(`${backendHost}/restapi/v2.0/cms/playlists/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT')
    }).then(handleResponse);
}

function searchPlaylistGroupByGroupName(searchText) {
    return fetch(`${backendHost}/restapi/v2.0/cms/playlists/groups/searched-groups?searchText=${searchText}`, {
        method: 'POST',
        headers: authHeader("POST"),
    }).then(handleResponse)
}