import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {toastr} from 'helper/toastrIntercept';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import Moment from "react-moment";
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService, userService} from '../../services';
import {popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {
    toMomentDateTimeFormat,
    useCheckRefWithSelectedCnt as useCheckRef,
    useFilter,
    usePermission
} from '../../helper';
import {useMISOpt} from '../../components/misopt';
import {useTrGroupProps} from '../../helper/tables';
import {getErrorMessage} from "../../helper/responseHandler";
import {updateCache} from "../../helper/cache/tableCache";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const heightOffset = 204;
const defaultSort = {id: 'createDate', desc: true};

const CustomizeAppliedStatus = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}, getAuthority} = useMISOpt();

    const [getPermission] = usePermission(getAuthority('DEVICE'));
    const hasDeviceSecurity = getPermission('SECURITY');

    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
        deviceSecurity: props.cache.deviceSecurity ? props.cache.deviceSecurity : false
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});


        deviceService.fetchDeviceReservedSoftware({
            startIndex: page * pageSize + 1,
            pageSize,
            softwareType: 'LOGO',
            searchText: keyword,
            sortColumn: snakeCase(id === 'softwareReservationId' ? 'softwareRsvId' : id),
            sortOrder: desc ? 'desc' : 'asc'
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount,
                deviceSecurity: hasDeviceSecurity
            });
            updateCache('DEVICE', {items: res.items, filter: filter, totalCount: res.totalCount, deviceSecurity : hasDeviceSecurity}, props.currContent);
        }).catch(err => {
            if(err.errorCode === 408900)
                return;            
            toastr.error(getErrorMessage(err, err.errorMessage))
        });

    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [defaultSort]});
    };

    const onEdit = () => {
        if(selected.current.length === 0) {
            return;
        }

        const {softwareReservationId} = items[selected.current[0]];
        dispatch(popupAction.addPopup({
            id: commonConstants.EDIT_CUSTOMIZE_APPLIED_STATUS,
            type: commonConstants.EDIT_CUSTOMIZE_APPLIED_STATUS,
            rsvId: softwareReservationId,
            onSave: () => {
                dispatch(popupAction.closePopup(commonConstants.EDIT_CUSTOMIZE_APPLIED_STATUS));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_CUSTOMIZE_APPLIED_STATUS))
        }));
    };

    const onCancel = () => {
        if(selected.current.length === 0) {
            return;
        }

        const {softwareReservationId} = items[selected.current[0]];
        deviceService.cancelSoftwareDeploy(softwareReservationId).then(res => {
            if(res.status === 'Success') {
                toastr.success(t('MESSAGE_DEVICE_SOFTWARE_RESERVATION_CANCEL_SUCCESS_P'));
            }
            fetchData();
        }).catch(error => {
            console.log(error);
            toastr.error(t('MESSAGE_DEVICE_SOFTWARE_RESERVATION_CANCEL_FAIL_P'));
        })
    };

    const onClickSoftware = (rsvId, softwareType) => {

        dispatch(popupAction.openDetailView({
            id: 'DEVICE_SW_APPLIED_STATUS',
            type: commonConstants.COMMON_DETAIL_VIEW, 
            viewType: 'DEVICE_SW_APPLIED_STATUS', 
            rsvId,
            softwareType: softwareType,
            hasDeviceSecurity: data.deviceSecurity,
            onClose: () => dispatch(popupAction.closePopup('DEVICE_SW_APPLIED_STATUS'))
        }));
    };

    const updateDimensions = () => {
        let height = window.innerHeight - heightOffset;
        setStyle({
            height: height
        })
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const searchPlaceHolder = t('LIST_TITLE_NAME');

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_RSV_ID_P'),
            accessor: 'softwareReservationId',
            width: 230,
        },
        {
            Header: t('LIST_TITLE_NAME'),
            accessor: 'softwareName',
            width: 280,
            Cell: ({original, value}) => <span className='link_cell' onClick={() => onClickSoftware(original.softwareReservationId, original.softwareType)}>{value}</span>
        },
        {
            Header: t('TABLE_RESERVATION_DATE_P'),
            accessor: 'reservationDate',
            width: 230,
            Cell: data => {
                if(data.value) {
                    return <Moment format={dateTimeFormat}>{data.value}</Moment>;
                }
                return '-';
            }
        },
        {
            Header: t('COM_TEXT_MODIFY_DATE_P'),
            accessor: 'createDate',
            width: 230,
            Cell: data => <Moment format={dateTimeFormat}>{data.value}</Moment>
        },
        {
            Header: t('TABLE_SUBSCRIBER_P'),
            accessor: 'subscriberId',
            width: 280,
        },
        {
            Header: t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS'),
            accessor: 'downloadStatus',
            sortable: false,
            Cell: data => t(data.value)
        },
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'CUSTOMIZE_APPLIED_STATUS' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id='COM_BUTTON_EDIT' name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} />
                    <WhiteButton id='BUTTON_CANCEL_P' name={t('BUTTON_CANCEL_P')} disable={selectedCnt !== 1} onClick={onCancel} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide} />
            </div>
        </div>
    );
};

export default CustomizeAppliedStatus;