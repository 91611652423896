import React, {useEffect, useMemo, useState} from 'react';
import {settingService} from "../../services";
import WhiteButton from "../../components/button/WhiteButton";
import {Trans, useTranslation} from "react-i18next";
import Checkbox from "../../components/checkbox/Checkbox";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useDispatch} from "react-redux";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import {popupAction} from "../../actions";
import {commonConstants, SETTING_PAGE_SIZE_OPTIONS, settingConstants} from "../../constants";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";
import {snakeCase} from "lodash";
import {useTrGroupProps} from "../../helper/tables";

const DataLinkServers = ({currContent}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('setting', SETTING_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [{id: 'serverName', desc: false}],
    });
    
    const [data, setData] = useState({
        items:[],
        loading: false,
        totalCount: 0
    });

    const [style, setStyle] = useState({ height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(()=>{
        const handleResize = () => {
            setStyle({height: window.innerHeight - 203});
        }
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(()=>{
        fetchData();
    }, [filter]);

    const fetchDataLinks = () => {
        const {page, pageSize, sorted: [{id, desc}], organId} = filter;            
        settingService.fetchDataLinks({
            startIndex: page * pageSize + 1,
            pageSize,
            sortColumn : snakeCase(id),
            sortOrder : desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            res.items.length <= 0 && page > 0 && onPageChange(page - 1);
            setData({...data, loading: false, pages: res.totalCount / pageSize, items: res.items, totalCount: res.totalCount})
        }).catch(error => setData([]))
    };

    const fetchData = () => {
        setData({
            ...data,
            loading: true
        });
        fetchDataLinks();
    };

    const setDlkMainViewData = (serverToUpdate, isAdded) => {
        fetchData();
        /*
        if(isAdded) {
            setData({
                ...data,
                totalCount: data.totalCount + 1,
                [items]: data.items.push(serverToUpdate)
            });
        }else{
            let originalIndex = data.items.findIndex(dlkServer=>serverToUpdate.serverName===dlkServer.serverName);
            data.items.splice(originalIndex, 1);
            setData({
                ...data,
                [items]:data.items.splice(originalIndex, 0, serverToUpdate)
            });
        }*/
    };

    const deleteDataLinkPopup = () => {
        let serverNameList = [];
        let checkCount = 0;
        
        Object.keys(checkBoxRefs.current).forEach(key=>{
            if(checkBoxRefs.current[key] && checkBoxRefs.current[key].checked){
                checkCount ++;
                serverNameList.push(checkBoxRefs.current[key].getAttribute('data-name'));
            }
        });
        if(checkCount < 1){
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
            return;
        }

        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("ALERT_WANT_DELETE"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => deleteDataLinkServers(serverNameList),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));
    };

    const deleteDataLinkServers = (datalinkToDelete) => {
        let promiseAll = [];
        let count = 0;
        datalinkToDelete.forEach(serverName=>{
            const promise = settingService.deleteDataLink(serverName).then(res => {
                count += res.items;
            });
            promiseAll.push(promise);
        });
        Promise.all(promiseAll).then(() => {
            toastr.success(t('TEXT_SUCCESS_P'));
            fetchData();
        }).catch((err) => {
            toastr.error(t('TEXT_FAIL_P') + ':' + getErrorMessage(err));
        }).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
        });
    };

    const editDataLinkServer = (datalink)=> {
        dataLinkPopup(datalink);
    };

    const addDataLinkServer = () => {
        dataLinkPopup();
    };

    const onLinkClick = (dlkServer) => {
        const is_ssl = dlkServer.useSsl?dlkServer.useSsl:false;
        const use_private = dlkServer.privateMode?dlkServer.privateMode:false

        try {
            if(is_ssl){
                if(use_private){
                    window.open('https://'+dlkServer.privateIpAddress + ':' + dlkServer.privateWebPort+'/DataLink');
                }else{
                    window.open('https://'+dlkServer.ipAddress + ':' + dlkServer.port+'/DataLink');
                }
            }else{
                if(use_private){
                    window.open('http://'+dlkServer.privateIpAddress + ':' + dlkServer.privateWebPort+'/DataLink');
                }else{
                    window.open('http://'+dlkServer.ipAddress + ':' + dlkServer.port+'/DataLink');
                }
            }
        } catch(e){
            toastr.error(t("MIS_SID_CONNECTION_FAILED"));
        }
    };

    const assignTables = () => {
        let dlkInfoArray = {data:items};

        let checkedRefs = [];
        Object.keys(checkBoxRefs.current).forEach(serverName=>{
            if(checkBoxRefs.current[serverName].checked){
                checkedRefs.push(checkBoxRefs.current[serverName]);
            }
        });

        if(checkedRefs.length !== 1) {
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P'));
            return;
        }

        dlkInfoArray.data.forEach(dlkServer=>{
            if(checkedRefs[0].getAttribute('data-name') === dlkServer.serverName) {
                assignDataLinkTablePopup(dlkServer);
            }
        });
    };

    const assignDataLinkTablePopup = (dlkServer) => {
        dispatch(popupAction.addPopup({
            type:settingConstants.ASSIGN_DATALINK_TABLE_POPUP,
            id:settingConstants.ASSIGN_DATALINK_TABLE_POPUP,
            onClose: ()=>dispatch(popupAction.closePopup(settingConstants.ASSIGN_DATALINK_TABLE_POPUP)),
            datalinkInfo: dlkServer
        }));
    };

    const dataLinkPopup = (datalink) => {
        dispatch(popupAction.addPopup({
            type:settingConstants.EDIT_DATALINK_SERVER_POPUP,
            id:settingConstants.EDIT_DATALINK_SERVER_POPUP,
            onClose: ()=>dispatch(popupAction.closePopup(settingConstants.EDIT_DATALINK_SERVER_POPUP)),
            datalinkInfo: datalink,
            updateServerList: setDlkMainViewData
        }));
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'datalink_all'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={'datalink_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        propertyName={row.original.serverName}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                        
                    />
                )
            },
            width : 40,
            sortable: false,
            resizable: false,
            style: {"testAlign":"center"}
        },
        {
            Header: t("TABLE_SERVER_NAME_P"),
            Cell: (row) => {
                return(
                    <span className={"data_name"} onClick={()=>editDataLinkServer(row.original)}>
                        {row.original.serverName}
                    </span>
                )
            },
            accessor: 'serverName',
            width : 300
        },

        {
            Header: t("TABLE_IP_ADDR_P"),
            accessor: 'ipAddress',
            width : 200
        },
        {
            Header: t("TABLE_WEB_PORT_P"),
            accessor: "port",
            width : 150
        },

        {
            Header: t("COM_SETUP_NEW_STRING26_P"),
            accessor: "ftpPort",
            width : 150
        },
        {
            Header: t("COM_TEXT_PERIOD2_P"),
            accessor: "period",
            width : 150
        },
        {
            Header: "SSL",
            Cell: (row) =>{
                return <span>{row.original.useSsl?t('BUTTON_YES_P'):t('BUTTON_NO_P')}</span>
            },
            accessor: "useSsl",
            width : 150
        },
        {
            Header: t("MIS_SID_20_BYPASS"),
            Cell: (row) =>{
                return <span>{row.original.bypass?t('BUTTON_YES_P'):t('BUTTON_NO_P')}</span>
            },
            accessor: "bypass",
            width : 150
        },
        {
            Header: t("COM_TV_SID_SERVER_NETWORK_SETTING"),
            Cell: (row) => {
                return <WhiteButton name={t('MIS_SID_LINK')} id={"datalink_link"} onClick={()=>onLinkClick(row.original)} width={102}/>
            },
            sortable: false,
            minWidth : 170
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow);

    return(
        <div style={{width: '100%', display: currContent === 'DATALINK_SERVER' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"DATALINK_ADD"} name={t("COM_BUTTON_ADD")} onClick={()=>addDataLinkServer()}/>
                    <WhiteButton id={"DATALINK_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={()=>deleteDataLinkPopup()} />
                    <WhiteButton id={"DATALINK_ASSIGN_TABLE"} name={t("MIS_SID_ASSIGN_TABLE")} disable={selectedCnt !== 1} onClick={()=>assignTables()}/>
                </div>
            </div>

            <div className={"device_list_view_wrap"} style={{width: '100%'}}>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns={columns}
                    style={style}
                    usePagination={true}
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        pageSize: pageSize,
                        pageSizeOptions: SETTING_PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: "setting"
                    }}
                />
            </div>
        </div>
    )
};
export default DataLinkServers;