import React from 'react';
import './PlaylistTagHistory.css';
import {withTranslation} from "react-i18next";
import Moment from "react-moment";
import MagicInfoTable from "../../../components/table/MagicInfoTable";


class PlayListTagHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    eventTypeName = (type) => {
        const {t} = this.props;
        if(type === '0' ){
            return t('COM_BUTTON_ADD');
        } else if ( type === '1' ){
            return t('COM_BUTTON_DELETE');
        }
    }

    formattedUpdateDate = (timeValue ) => {
        const user = JSON.parse(localStorage.getItem('user'));

        if(typeof user === 'undefined' || user === '') return "";

        const dateFormat = (user.dateFormat || 'YYYY-MM-DD').toUpperCase();
        const timeFormat = user.timeFormat || '24hour';
        let format = '';

        if (timeFormat === 'ampm') {
            format = dateFormat + ' hh:mm A';
        } else {
            format = dateFormat + ' HH:mm';
        }
        return (
            <span><Moment format={format}>{timeValue}</Moment></span>
        );
    }

    render() {
        const {t, tagHistory, tagHistoryLoading} = this.props;

        return (
            <div className="pop_tagHistory_list" >
                <MagicInfoTable
                    data={tagHistory}
                    loading={tagHistoryLoading}
                    minRows={0}
                    multiSort={false}
                    columns={
                        [
                            {
                                Header:t("MIS_TEXT_TAG_NAME_P_KR_MIS20"),
                                width : 129,
                                accessor: "tagName",
                                Cell: props => <div className='tag_content_name_cell'>{props.original.tagName}</div>
                            },
                            {
                                Header:t("TABLE_EVENT_TYPE_P"),
                                width : 90,
                                accessor: "eventType",
                                Cell: props => this.eventTypeName(props.original.eventType)
                            },
                            {
                                Header: t("TEXT_CONTENT_NAME_P"),
                                width: 150,
                                accessor: "contentName",
                                Cell: props => <div className='tag_content_name_cell'>{props.original.contentName}</div>
                            },
                            {
                                Header:t("COM_TEXT_MODIFY_DATE_P"),
                                width : 130,
                                accessor: "eventTime",
                                Cell: props => this.formattedUpdateDate(props.original.eventTime)
                            }
                        ]
                    }
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    style={{height : '400px', width : '104%'}}
                />
            </div>
        );
    }
}

export default withTranslation()(PlayListTagHistory);
