import React, {Fragment, useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import {statisticsService} from '../../services';
import {connect} from 'react-redux';
import './Statistics.css';
import './ApprovedDevice.css';
import '../../components/table/react-table.css';
import SelectBox from '../../components/selectbox/SelectBox';
import {StatisticsCalendar} from './StatisticsCalendar';
import {TableWithBar} from './TableWithBar';
import Moment from 'moment';
import {chartColor, optionDoughnut} from '../../constants/StatisticsConstants';
import {toastr} from 'helper/toastrIntercept';
import fileDownload from "js-file-download";
import WhiteButton from "../../components/button/WhiteButton";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

const ApprovedDevice = ({currContent}) => {
    const {t} = useTranslation();

    const user = JSON.parse(localStorage.getItem('user'));

    const timeOptions = [
        {value: 'aWeekAgo', title: 'COM_MESSAGE_STATISTICS_MENU_A_WEEK_AGO_P'},
        {value: 'aMonthAgo', title: 'MESSAGE_STATISTICS_MENU_A_MONTH_AGO_P'},
        {value: 'threeMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_THREE_MONTH_AGO_P'},
        {value: 'sixMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_SIX_MONTH_AGO_P'},
        {value: 'aYearAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_A_YEAR_AGO_P'},
        {value: 'custom', title: 'COM_SID_CUSTOM'},
    ];

    const [data, setData] = useState({items: [], totalCount: 0, totalItemCount: 0,});

    const initEndDate = new Date();
    const initStartDate = new Date();
    initStartDate.setDate(initEndDate.getDate() - 6);
    const [period, setPeriod] = useState({time: timeOptions[0].value, startDate: initStartDate, endDate: initEndDate});

    const [approvalStatus, setApprovalStatus] = useState({
        datasets: [{
            data: [],
            backgroundColor: ['#78c679', '#c2e699',],
            hoverBackgroundColor: ['#f03b20', '#f03b20',],
        }],
    });
    const [approvalTotal, setApprovalTotal] = useState(0);
    const [requestData, setRequestData] = useState({
        labels: [],
        datasets: [{
            backgroundColor: chartColor(1),
            borderWidth: 1,
            data: [],
        }],
    });
    const [request, setLastRequest] = useState({
        startDate: undefined,
        endDate: undefined,
    });
    const [loadingbar, setLoadingBar] = useState(false);

    const fetchData = () => {
        setLoadingBar(true);
        const {startDate, endDate} = period;
        Promise.all([
            statisticsService.fetchStatisticsDevices({
                data: 'approvalStatusTable',
                startDate: Moment(startDate).format('YYYY-MM-DD'),
                endDate: Moment(endDate).format('YYYY-MM-DD'),
            }),
            statisticsService.fetchStatisticsDevices({
                data: 'requestDateTable',
                startDate: Moment(startDate).format('YYYY-MM-DD'),
                endDate: Moment(endDate).format('YYYY-MM-DD'),
            }),
        ]).then(res => {
            if (res[0].items && res[0].items.length > 0) {
                let statusData = res[0].items[0];
                setApprovalTotal(Number(statusData.approvedData.value) + Number(statusData.unapprovedData.value));
                setApprovalStatus({
                    labels: [
                        t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_APPROVE_DEVICE_NUMBER_P'),
                        t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_UNAPPROVE_DEVICE_NUMBER_P')
                    ],
                    datasets: [{
                        data: [
                            statusData.approvedData.value,
                            statusData.unapprovedData.value,
                        ],
                        backgroundColor: [
                            statusData.approvedData.color,
                            statusData.unapprovedData.color,
                        ],
                        hoverBackgroundColor: [
                            statusData.approvedData.highlight,
                            statusData.unapprovedData.highlight,
                        ]
                    }],
                });
            }
            if (res[1].items && res[1].items.length > 0) {
                let requestData = res[1].items;
                const chartData = requestData.pop();
                if (requestData.length > 100) {
                    toastr.warning(t('MIS_SID_20_THERE_IS_TOO_MUCH_DATA_TO_GENERATE_A_CHART'));
                    setRequestData({
                        labels: [],
                        datasets: [{
                            backgroundColor: chartColor(1),
                            borderWidth: 1,
                            data: [],
                        }],
                    });
                } else {
                    setRequestData({
                        labels: chartData.label.map(label => label.replace('"', '').replace('"', '')),
                        datasets: [{
                            data: chartData.data,
                            backgroundColor: chartColor(chartData.data.length),
                        }],
                    });
                }
                setData({
                    items: requestData && requestData.length > 0 ? requestData.map((item) => ({col1: item.date, col2: item.count})) : [],
                    totalCount: requestData && requestData.length > 0 ? requestData.reduce((pre, value) => pre + (Number(value['count']) || 0), 0) : 0,
                    totalItemCount: requestData && requestData.length > 0 ? requestData.length : 0,
                });
            }
            setLastRequest({
                ...request,
                startDate: Moment(startDate).format('YYYY-MM-DD'),
                endDate: Moment(endDate).format('YYYY-MM-DD'),
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onClickExport = (docType) => {
        const {startDate, endDate} = request;
        setLoadingBar(true);
        statisticsService.fetchStatisticsDevicesFileDownload(docType, {
            data: "approvalStatusTable",
            startDate: startDate,
            endDate: endDate,
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    const onClickDateOption = (time) => {
        let startDate = new Date();
        let endDate = new Date();
        switch (time) {
            case 'aWeekAgo':
                startDate.setDate(endDate.getDate() - 7);
                break;
            case 'aMonthAgo':
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case 'threeMonthAgo':
                startDate.setMonth(endDate.getMonth() - 3);
                break;
            case 'sixMonthAgo':
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case 'aYearAgo':
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            default:
                break;
        }
        setPeriod({time, startDate, endDate});
    };

    const {items = [], totalCount = 0, totalItemCount = 0} = data;
    return (
        <Fragment>
            <div className="contents_buttonWrap" style={{display: currContent === 'APPROVED_DEVICE' ? 'block':'none'}}>
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => onClickExport('EXCEL')}/>
                </div>
            </div>
            <div className="statistics_list mt10" style={{display: currContent === 'APPROVED_DEVICE' ? 'block':'none'}}>
                <div className="contents_buttonWrap">
                    <span className='statistics_period float_l'>{t('TEXT_PERIOD_P')}</span>
                    <SelectBox defaultTitle={t('MESSAGE_UPLOADER_SEL_PERIOD_P')} multiLang={true} classname='float_l mr8' width={209}
                               selects={timeOptions} onClick={onClickDateOption}/>
                    <StatisticsCalendar date={period}
                                        dateFormat={user ? user.dateFormat : "yyyy-MM-dd"}
                                        disabled={period.time !== 'custom'}
                                        onChangeStart={(startDate) => {
                                            if (Moment(startDate).isBefore(Moment(period.endDate))) {
                                                setPeriod({...period, startDate})
                                            } else {
                                                let endDate = startDate;
                                                setPeriod({...period, startDate, endDate});
                                            }
                                        }}
                                        onChangeEnd={(endDate) => {
                                            if (Moment(period.startDate).isBefore(Moment(endDate))) {
                                                setPeriod({...period, endDate})
                                            } else {
                                                let startDate = endDate;
                                                setPeriod({...period, startDate, endDate});
                                            }
                                        }}/>
                    <button className="base float_l w72" onClick={fetchData}>{t('BUTTON_SEARCH')}</button>
                </div>
                <div className="graph_wrap clearfix">
                    <div className="float_l ">
                        <div className="approved_device">
                            <h4>{t('MESSAGE_STATISTICS_SUMMARY_APPROVE_TITLE_P')}</h4>
                            <div>
                                <ul>
                                    <li>
                                        {t('TEXT_ALL_P')}<span>{approvalTotal}</span>
                                    </li>
                                    <li className='approved_device_item' style={{background: approvalStatus.datasets[0].backgroundColor[0]}}>
                                        {t('COM_TEXT_AD_STATUS_APPROVED_P')}<span>{approvalStatus.datasets[0].data[0]}</span>
                                    </li>
                                    <li className='approved_device_item' style={{background: approvalStatus.datasets[0].backgroundColor[1]}}>
                                        {t('COM_TV_SID_UNAPPROVED')}<span>{approvalStatus.datasets[0].data[1]}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="float_l">
                        <div className="statistics_title mt28 pl27 pr1">
                            <div className="float_l" style={{marginLeft: '103px'}}>
                                <Doughnut data={approvalStatus} options={optionDoughnut} width={247} height={247}/>
                                <div className="donut-inner"><h3>{approvalTotal}</h3></div>
                            </div>
                        </div>
                    </div>
                </div>
                <TableWithBar title={t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_REQUESTED_DATE_P')}
                              col1={t('TEXT_DATE_P')}
                              col2={t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_REQUESTED_DEVICE_NUMBER_P')}
                              tableWidth={'222px'}
                              tableData={items}
                              chartData={requestData}
                              totalCount={totalCount}
                              totalItemCount={totalItemCount}
                />
            </div>
            {loadingbar && <LoadingBar />}
        </Fragment>
    );
};

export default connect()(ApprovedDevice);
