import React from 'react';
import {useTranslation} from 'react-i18next';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import Checkbox from '../../../checkbox/Checkbox';
import './AppliedWarningRulePopup.css';

// var thresholdList = warningRule.thresholdList;
// 			if(thresholdList != null){
				
// 				for(var i =0; i < thresholdList.length; i++){
// 					var elementValue = thresholdList[i].element_value;
// 					html += "<tr><td style='width:45%;padding-right:30px;'><input type='checkbox' value='"+thresholdList[i].element_id+"' id='threshold_chk_"+thresholdList[i].element_id+"' ";
// 					if(elementValue != null){
// 						html += "checked" 
// 					}
// 					html += " disabled><label for='threshold_chk_"+thresholdList[i].element_id+"'>"+ titleOSD[thresholdList[i].element_id] +"</label>";
// 					html += "<span className='quest_img_noti' style='float:right;'><a href='#' className='threshold_notice' id='"+thresholdList[i].element_id+"Notice'></a>";
// 					html += "<div className='opacity_notice_wrap' style='padding:10px 20px;z-index:2;display:none;' id='"+thresholdList[i].element_id+"NoticeBox'>";
// 					html += "<span>" + noticeData[thresholdList[i].element_id] +"</span></div></span></td>";
// 					html += "<td><span className='space float_l'>";
					
// 					var greaterTxt = $.i18n.prop('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "");
					
// 					if(elementValue == null){
// 						elementValue = "1";
// 					}
					
// 					html += elementValue;
					
// 					if(thresholdList[i].element_id == 'TEMPERATURE'){
// 						var fvalue = parseInt(elementValue)*9/5+32;
// 						var fvalueString = "("+fvalue+" ℉)";
						
// 						greaterTxt = "℃"+ "<span id='warningRuleTempF' style='margin-left: 5px;'>"+fvalueString+"</span>" + greaterTxt;
// 					}
// 					html += " " + greaterTxt.replace("%d", "")+ "</span></td></tr>" ;
// 				}
//             }
            
const AppliedWarningRulePopup = ({warningRule, onClose}) => {
    const {t} = useTranslation();

    const noticeData = {
        'TEMPERATURE': t('MIS_SID_20_WARNING_RULE_OPERATES_TEMPERATURE_OF_DEVICES_EXCEEDS_SET_VALUE'), 
        'POWER': t('MIS_SID_20_WARNING_RULE_OPERATES_FALSE_CASE_OF_INCORRECT_DEVICE_VOLTAGE_EXCEEDS_THE_SET_VALUE'), 
        'IC': t('MIS_SID_WARNING_RULE_FALSE_CASE_DEVICE_MSG'),
        'LOD' : t('MIS_SID_20_RULE_OPERATES_LED_OPEN_DETESCTION_MSG')
    };

    const titleOSD = {
        'TEMPERATURE': t('DID_LFD_TEMPERATURE'), 
        'POWER': t('TABLE_POWER_P'), 
        'IC': "IC",
        'LOD' : "LOD"
    };

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_20_APPLIED_WARNING_RULE'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 700,
                height: 460,
                onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClose,
                    },
                ]
            }}
        >
            <div className="popup_contents sub">
                <h3>{t('MIS_SID_20_BASIC_SETTINGS')}</h3>
                <div className="detail_view mt20 mb15">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('MIS_SID_20_WARNING_RULE_NAME')}</th>
                                <td style={{
                                    maxWidth: 550,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'}}
                                >
                                    {warningRule.ruleName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('MIS_SID_20_WARNING_RULE_INFORMATION')}</th>
                                <td style={{
                                    maxWidth: 550,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'}}
                                >
                                    {warningRule.ruleDescription}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_ORGANIZATION_P')}</th>
                                <td>
                                    <span>{warningRule.organizationName}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="ledcabinet_warning_rule_wrap popup_contents sub">
                <h3>{t('MIS_SID_20_WARNING_STANDARD')}</h3>
                <div className="detail_view mt20 mb15">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            {
                                warningRule.thresholds && warningRule.thresholds.length > 0 &&
                                warningRule.thresholds.map(threshold => {
                                    const {elementValue = '1'} = threshold;
                                    return (
                                        <tr>
                                            <td style={{width:'45%', paddingRight: '30px'}}>
                                                <Checkbox id={`threshold_chk__${threshold.elementId}`} name={titleOSD[threshold.elementId]} checked={!isNil(threshold.elementValue)} disabled />
                                                <span className='quest_img_noti'>
                                                    <span className='threshold_notice' title={noticeData[threshold.elementId]}></span>
                                                    {/* {
                                                        showNotice[threshold.elementId] &&
                                                        <div className='opacity_notice_wrap' style={{padding:'10px 20px', zIndex:2}}>
                                                            <span>{noticeData[threshold.elementId]}</span>
                                                        </div>
                                                    } */}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='space float_l'>
                                                    {
                                                        threshold.elementId === 'TEMPERATURE' ?
                                                        <>
                                                            {elementValue} ℃ 
                                                            <span style={{marginLeft: '5px'}}>{`(${parseInt(threshold.elementValue || "1")*9/5+32} ℉)`}</span>
                                                            {`${t('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "")}`}
                                                        </> :
                                                        <>
                                                            {threshold.elementValue}&nbsp;
                                                            {`${t('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "")}`}
                                                        </>
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default AppliedWarningRulePopup;