import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import {deviceService} from '../../../../services';
import {IPLAYER, SPLAYER, WPLAYER, KIOSK} from '../../../../constants';
import {useMISOpt} from '../../../misopt';
import TextInput from '../../../input/TextInput';
import Select from '../../../selectbox/Select';
import {SearchButton} from '../../../button/SearchButton';
import Chip from '../../../chip/Chip';
import ProgressBar from '../../../progressbar/ProgressBar';
import RadioGroup from '../../../radio/RadioGroup';


const swFileTypeOption = [
    {value: 0, title: 'COM_TEXT_FIRMWARE_P'},
    {value: 1, title: 'MIS_SID_WEB_APP'}
];

const swFileTypeOptionKiosk = [
    {value: 0, title: 'COM_TEXT_FIRMWARE_P'},
    {value: 1, title: 'COM_LFD_SID_KIOSK_CANOV_KIOSK_APP'}
];

const getSupportedDeviceType = deviceType => {
    switch(deviceType) {
        case 'SPLAYER':
        case 'S2PLAYER':
        case 'S3PLAYER':
        case 'S4PLAYER':
        case 'S5PLAYER':
        case 'S6PLAYER':
        case 'S7PLAYER':
        case 'S9PLAYER':
        case 'S10PLAYER':
        case 'RSPLAYER':
        case 'RSPLAYER4':
        case 'RSPLAYER5':
        case 'RSPLAYER6':
        case 'RSPLAYER7':
        case 'RSPLAYER9':
        case 'RSPLAYER10':
            return {value:'SPLAYER', title:'SPLAYER'};
        case 'SIGNAGE3':
        case 'SIGNAGE4':
        case 'SIGNAGE6':
        case 'RSIGNAGE':
        case 'RSIGNAGE4':
        case 'RSIGNAGE6':
            return {value:'SIGNAGE', title:'SIGNAGE PLAYER'};
        case 'LPLAYER':
            return {value:'LPLAYER', title:'Lite'};
        case 'iPLAYER':
        case 'RIPLAYER':
            return {value:'iPLAYER', title:'iPLAYER'};
        case 'LEDBOX':
        case 'LEDBOX4':
        case 'LEDBOX6':
        case 'LEDBOX9':
        case 'LEDBOX10':
        case 'RLEDBOX4':
        case 'RLEDBOX6':
        case 'RLEDBOX9':
        case 'RLEDBOX10':
        case 'RLEDBOX':
            return {value:'LEDBOX', title:'LEDBOX'};
        case 'FLIP':
        case 'FLIP2':
            return {value:'FLIP', title:'FLIP'};
        case 'FLIP3':
        case 'FLIP4':      // Flip4 is Flip pro
            return {value:'FLIP', title:'FLIP'};
        case 'WPLAYER':
            return {value:'WPLAYER', title:'WPLAYER'};
        case 'KIOSK':
        case 'RKIOSK':
            return {value:'KIOSK', title:'KIOSK'};
        default:
            return {value: deviceType, title: deviceType};
    }
};

const RegisterSoftwarePopup = ({onOk, onClose}) => {
    const {t} = useTranslation();
    const {misopt} = useMISOpt();

    const supportedDeviceTypes = misopt.deviceTypes.map(({deviceFullType}) => getSupportedDeviceType(deviceFullType));
    const deviceTypes = supportedDeviceTypes.filter((sdt, i) => supportedDeviceTypes.findIndex(dt => dt.value === sdt.value) === i);
    const defaultDeviceType = (deviceTypes && deviceTypes.length > 0) ? deviceTypes[0].value : undefined;

    const [software, setSoftware] = useState({
        swFileType: 0,
        deviceType : defaultDeviceType
    });

    const [progress, setProgress] = useState();
    const [disableSave, setDisableSave] = useState(false);

    const swFileInput = useRef();

    const onChangeDeviceType = (e, value) => {
        setSoftware({...software, deviceType: value});
    };

    const onClickFile = () => {
        swFileInput.current.click();
    };

    const onChangeSwFile = e => {
        const file = e.target.files[0];

        if (!(/\.(zip|ZIP)$/i).test(file.name)) {
            toastr.error(t('MESSAGE_DEVICE_SELECT_SW_FILE_P'));
            swFileInput.current.value = '';
            return;
        }

        setSoftware({...software, file});
    };

    const onDeleteSwFile = () => {
        swFileInput.current.value = '';
        setSoftware({...software, file: undefined});
    };

    const onClickOk = () => {
        let softwareType = 'firmware';
        if((software.deviceType === WPLAYER || software.deviceType === KIOSK) && software.swFileType == 1) {
            softwareType = 'webApp';
        }

        if(isNil(software.softwareName)) {
            return;
        }

        setDisableSave(true);
        deviceService.createSoftware({
            ...software, softwareType
        }, percent => setProgress(percent > 95 ? 95 : percent)).then(res => {
            setProgress(100);
            toastr.success(t('MESSAGE_DEVICE_SOFTWARE_FILE_UPLOAD_SUCCESS_P'));
            setTimeout(() => {
                if(onOk) {
                    onOk();
                }
            }, 400);
        }).catch(error => {
            console.log(error);
            // }else if(uploadStatus=='fail'){
        //     if(uploadMsg == 'invalid_ver'){
        //         toastr.error($.i18n.prop('MESSAGE_DEVICE_INVALID_VER_P'));	
        //     } else {
        //         toastr.error($.i18n.prop('MESSAGE_SCHEDULE_DATA_SAVE_FAILED_P'));
        //     }
        // }
            toastr.error(t('MESSAGE_SCHEDULE_DATA_SAVE_FAILED_P'));
            setDisableSave(false);
        }).finally(() => {
        });
    };

    useEffect(() => {
        if(!defaultDeviceType){
            toastr.error(t('COM_TV_THERE_IS_NO_DEVICE_CURRENTLY_AVAILABLE'));
            onClose && onClose();
        }
    }, []);

    return (
        <MISDialog 
            dialog={{
                title: t('TEXT_TITLE_SW_REGISTER_P'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 530,
                height: 230,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickOk,
                        disable: disableSave || isNil(software.softwareName) || isNil(software.file),
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className="detail_view">
                <table>
                    <colgroup>
                        <col width="140px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                            <td>
                                <Select width={265} selects={deviceTypes} value={software.deviceType} multiLang={false} onChange={onChangeDeviceType} />
                            </td>
                        </tr>
                        {
                            (software.deviceType === WPLAYER || software.deviceType === KIOSK) &&
                            <tr>
                                <th>{t('COM_LIST_TITLE_FILE_TYPE')}</th>
                                <td>
                                    <RadioGroup selects={software.deviceType === WPLAYER ? swFileTypeOption : swFileTypeOptionKiosk} value={software.swFileType} onChange={(e, value) => setSoftware({...software, swFileType: value})} />
                                    {/* <input type="radio" id="fileTypeFirmware" name="swFileType" value="0" checked=""><label for="fileTypeFirmware" class="mr30">Firmware</label><input type="radio" id="fileTypeWebApp" name="swFileType" value="1"><label for="fileTypeWebApp">Web App</label> */}
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('COM_TEXT_SOFTWARE_NAME_P')}</th>
                            <td>
                                <TextInput width={265} maxLength={50} value={software.softwareName} placeholder={t('MESSAGE_DEVICE_INPUT_SW_NAME_P')} onChange={e => setSoftware({...software, softwareName: e.target.value})} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_AD_FILE_NAME_P')}</th>
                            <td>
                                <SearchButton onClick={onClickFile} />
                                {
                                    software.file &&
                                    <Chip title={software.file.name} onDelete={onDeleteSwFile} />
                                }
                                <input type="file" ref={swFileInput} style={{display: "none"}} onChange={onChangeSwFile} />
                            </td>
                        </tr>
                        {
                            !isNil(progress) &&
                            <tr>
                                <td colSpan="2">
                                    <ProgressBar width='100%' height={20} percentage={progress} fillerColor='#4be48c' background="#e8e8e8" useLabel={true} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

export default RegisterSoftwarePopup;
