import React, {Component} from 'react';
import ReactTable from "react-table";
import "../../components/table/react-table.css";
import {Trans, withTranslation} from 'react-i18next';
import {statisticsService, userService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import './ContentDetailReport.css'
import Select from "../../components/selectbox/Select";
import WhiteButton from "../../components/button/WhiteButton";
import RadioGroup from "../../components/radio/RadioGroup";
import {systemService} from "../../services/system.service";
import fileDownload from "js-file-download";
import {getErrorMessage} from "../../helper/responseHandler";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

class ContentDetailReport extends Component {
    state = {
        items: [],
        loading: false,
        organizations: [],
        selectedOptionOrganizationIndex: -1,
        years: [],
        selectedOptionYearIndex: -1,
        selectedOptionTypeIndex: 0,
    };

    constructor(props) {
        super(props);
        this.reportTypeOptions = [
            {value: 0, title: 'COM_TEXT_GENERAL_P'},
            {value: 1, title: 'VWL', description:'VWL'},
            {value: 2, title: 'COM_SID_MEDIA', description:'LFD/CIFS/FTP/DLK'}
        ];
    }

    componentDidMount() {
        this.fetchOrganizationGroupOrganizations();
    }

    onChangeOrganization = (value) => {
        this.setState({
            selectedOptionOrganizationIndex: value,
        }, () => {
            this.fetchYears();
        });
    };

    onChangeYear = (value) => {
        this.setState({
            selectedOptionYearIndex: value,
        }, () => {
            if (this.state.selectedOptionOrganizationIndex >= 0) {
                this.fetchContentDetailReportList();
            }
        });
    };

    onClickDownload = (value) => {
        let {t} = this.props;
        systemService.fetchFileDownload({homeDir: 'POP_REPORT', filePath: value}).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            if(err.status === 404)
                toastr.error(t('MESSAGE_DEVICE_NO_RESULT_FILE_P'));
            else
                toastr.error(getErrorMessage(err, err.errorMessage));
        });
    };

    onChangeReportType = (value) => {
        this.setState({
            selectedOptionTypeIndex: Number(value),
        }, () => {
            let {selectedOptionOrganizationIndex, selectedOptionYearIndex} = this.state;
            if (selectedOptionOrganizationIndex >= 0 && selectedOptionYearIndex >= 0) {
                this.fetchContentDetailReportList();
            }
        });
    };

    fetchOrganizationGroupOrganizations = () => {
        userService.fetchOrganizationGroupOrganizations().then(res => {
            let organizations = [];
            res.items.forEach((organization, index) => {
                organizations.push({value: index, id: organization.organizationId, title: organization.groupName,});
            });
            if (organizations && organizations.length > 0) {
                this.setState({
                    organizations: organizations,
                    selectedOptionOrganizationIndex: organizations[0].value,
                }, () => {
                    this.fetchYears();
                });
            } else {
                this.setState({
                    organizations: [],
                    selectedOptionOrganizationIndex: -1,
                    years: [],
                    selectedOptionYearIndex: -1,
                    items: [],
                });
            }
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchYears = () => {
        let {organizations, selectedOptionOrganizationIndex} = this.state;
        statisticsService.fetchYearList(organizations[selectedOptionOrganizationIndex].id, 'CONTENT').then(res => {
            let years = [];
            res.items.yearList.forEach((year, index) => {
                years.push({value: index, id: year, title: year});
            });
            if (years && years.length > 0) {
                this.setState({
                    years: years,
                    selectedOptionYearIndex: years[0].value,
                }, () => {
                    this.fetchContentDetailReportList();
                });
            } else {
                this.setState({
                    years: [],
                    selectedOptionYearIndex: -1,
                    items: [],
                });
            }
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchContentDetailReportList = () => {
        let {organizations, selectedOptionOrganizationIndex, years, selectedOptionYearIndex, selectedOptionTypeIndex} = this.state;
        statisticsService.fetchStatisticsContents({
            organizationId: organizations[selectedOptionOrganizationIndex].id,
            year: years[selectedOptionYearIndex].id,
            data: selectedOptionTypeIndex === 0 ? 'detailReportOnGeneral' : selectedOptionTypeIndex === 1 ? 'detailReportOnVWL' : 'detailReportOnMedia',
            format: 'detailReportTable',
        }).then(res => {
            this.setState({
                items: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    }

    noDataComponent = () => {
        let {t} = this.props;
        return <div style={{position: 'absolute', left: '50%', top: '50%', color: '#59616b'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
    };

    render() {
        let {t,currContent} = this.props;
        let {loading, items, organizations, selectedOptionOrganizationIndex, years, selectedOptionYearIndex, selectedOptionTypeIndex} = this.state;
        return (
            <div style={{width: '100%', display: currContent === 'DETAIL_REPORTS' ? 'block':'none'}}>
                <form name='download' action={backendHost + '/servlet/FileLoader'} target='_blank'>
                    <input type='hidden' name='paramPathConfName' value='POP_REPORT'/>
                    <input type='hidden' name='filepath' value=''/>
                    <input type='hidden' name='download' value='D'/>
                    <input type='hidden' name='cmd' value='SAVEAS'/>
                </form>
                <div className="detail-report-top">
                    <span className='detail-report-top-title'>{t('TEXT_ORGANIZATION_P')}</span>
                    <Select multiLang={false} width={179} value={selectedOptionOrganizationIndex} selects={organizations} onChange={(e, value) => this.onChangeOrganization(value)} useEllipsis={true}/>
                    <div style={{width: '29px'}}/>
                    <span className='detail-report-top-title'>{t('MIS_TEXT_STATISTICS_POP_YEAR_P')}</span>
                    <Select multiLang={false} width={179} value={selectedOptionYearIndex} selects={years} disabled={years && years.length > 0 ? false : true} defaultTitle={t('MESSAGE_COMMON_NO_DATA_P')} onChange={(e, value) => this.onChangeYear(value)}/>
                    <div className='detail-report-top-type'>{t('TABLE_TYPE_P')}</div>
                    <RadioGroup
                        propertyName="reportTypeOptions"
                        selects={this.reportTypeOptions}
                        value={selectedOptionTypeIndex}
                        onChange={(e) => this.onChangeReportType(e.target.getAttribute('data-value'))}/>
                </div>
                <div className='trigger_list_view'>
                    <ReactTable
                        data={items}
                        loading={loading}
                        minRows={0}
                        sortable={false}
                        showPagination={false}
                        NoDataComponent={this.noDataComponent}
                        columns={[
                            {
                                Header: <Trans i18nKey={"TEXT_MONTH2_P"}/>,
                                accessor: "month",
                                Cell: (props) => <div className='trigger_list_view-month'>
                                    <span className='trigger_list_view-month-text' title={props.original.month}>{props.original.month}</span>
                                    <WhiteButton width={86} name={props.original.monthDownload} onClick={() => this.onClickDownload(props.original.fileName)}/>
                                </div>,
                                width: 250,
                            },
                            {
                                Header: <Trans i18nKey={"MIS_SID_20_DETAIL_REPORTS"}/>,
                                accessor: 'weekData',
                                Cell: (props) => {
                                    return props.original.weekData.map((week, index) =>
                                        <WhiteButton key={index} width={86} name={'W' + week.weekOfYear} style={{marginRight: '8px'}} onClick={() => this.onClickDownload(week.fileName)}/>
                                    );
                                },
                                resizable: false,
                            }
                        ]}
                        pageSize={items.length}
                        className="-striped -highlight"
                        manual
                        style={{height: window.innerHeight - 145}}
                        getTableProps={() => {
                            return {
                                className: 'trigger_table'
                            };
                        }}
                        getTrGroupProps={(state, rowInfo) => {
                            return {
                                className: 'trigger_tr_group',
                            };
                        }}
                        getTdProps={() => {
                            return {
                                className: 'trigger_td_props'
                            };
                        }}/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ContentDetailReport);

