import React from 'react'
import LFDIcon from '../../../images/icon/file_type_icon_01.png';
import classNames from "classnames";

const LFD = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={LFDIcon}/>
            {
                !onlyIcon && 'LFD'
            }
        </span>
    )
}
export default LFD;