import React from 'react';
import './SubmenuRuleBox.css';
import {getOpType, getConditionType} from '../../helper/ruleset/rulesetUtils';
import {useDrag} from 'react-dnd';

const SubmenuConditionBox = ({condition, width}) => {

	const [{isDragging}, drag] = useDrag({
		item: {
            type: 'conditionBox',
            condition: condition
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const style = {
		width: width
	};

	const opType = condition.isInvert ? "invert" : getOpType(condition.sign);

	let value = '';

	if(condition.sign == 'rg') {
		condition.values.map((element, index) => {
			let str = element.start + '~' + element.end;
			if(index > 0) {
				value += ', ';
			}
			value += str;
		});
	} else {
		condition.values.map((element, index) => {
			if(index > 0) {
				value += ', ';
			}
			value += element.value;
		});
	}
	
	return (
		<div ref={drag} style={style} className={"box condition"} id={condition.conditionId} rule-node-type={condition.type} >
			<div>
				<div className="description name">{condition.conditionName}</div>
				<div className="description">{getConditionType(condition.type)}</div>
				<div className={"description " + opType}>
					<div className="op-icon circle">
						<div className={"op-icon " + opType}></div>
					</div>
					<div style={{paddingLeft:"17px"}}>{value}</div>
				</div>
			</div>
		</div>
	)
}

export default SubmenuConditionBox;