import {createDndContext, DndProvider} from "react-dnd";
import Backend from "react-dnd-html5-backend";
import React, {useRef} from "react";

const RNDContext = createDndContext(Backend);

export const MagicInfoDndProvider = (props) => {
    const manager = useRef(RNDContext);
    if (!props.children) return null;

    return <DndProvider manager={manager.current.dragDropManager}>{props.children}</DndProvider>;
}
