import React, {useEffect, useRef, useState} from 'react';
import Menu from '../../components/menu/Menu';
import TabContainer from '../../containers/TabContainer';
import ContentContainer from '../../containers/ContentsContainer';
import {useDispatch, useSelector} from "react-redux";
import {menuAction} from "../../actions";
import {PopupContainer, ProgressContainer} from "../../containers/common";
import {acceptFileTypes, misInit, MISOptContext} from '../../components/misopt';
import {deviceService} from '../../services';
import {systemService} from "../../services/system.service";
import LoadingOverlay from "../../components/loading/LoadingOverlay";
import {useTranslation} from "react-i18next";
import jwtDecode from 'jwt-decode';
import {toastr} from 'helper/toastrIntercept'
import {getErrorMessage} from "../../helper/responseHandler";
import {Helmet} from "react-helmet";

const orderDevices = (deviceList) => {
    const order = deviceList.sort(function(a, b) {
        if (b.priority > a.priority) {
            return 1;
        } else {
            return -1;
        }
    });
    return order;
}

const convertDeviceTypeForPlatform = (deviceType, deviceTypeVersion) => {
    switch(deviceType) {
        case 'SIGNAGE' :
            if (deviceTypeVersion < 4) {
                deviceTypeVersion = 3;
            }
            deviceType = 'SPLAYER';
            return {deviceType: deviceType, deviceTypeVersion: deviceTypeVersion}
        case 'LEDBOX':
            if (deviceTypeVersion < 5) {
                deviceTypeVersion = 4;
            }
            deviceType = 'SPLAYER';
            return {deviceType: deviceType, deviceTypeVersion: deviceTypeVersion}
    }
    return {deviceType: undefined, deviceTypeVersion: undefined}
}

const getSupportedDevices = (devices) => {
    if(devices != undefined && devices.length > 0){
        const set = new Set();
        devices.map(device => {
            if(device.showSupportedIcon){
                set.add(device);
            } else {
                if(device.deviceType == "LEDBOX" || device.deviceType == "SIGNAGE"){
                    const main =  misOption("SPLAYER", device.deviceTypeVersion);
                    if(main != undefined){
                        set.add(main);
                    }
                }
            }            
        })
        return Array.from(set);
    }
    return [];
}

const misOption = (deviceType, deviceTypeVersion) => {
    if (deviceType.toUpperCase() === 'IPLAYER' || deviceType.toUpperCase() === 'LPLAYER') {
        return misInit.devices.find(d=> d.deviceType.toUpperCase() === deviceType.toUpperCase());
    }
    const device = misInit.devices.find(
        d=> d.deviceType.toUpperCase() === deviceType.toUpperCase() && d.deviceTypeVersion === deviceTypeVersion
    )
    return device !== undefined ? device : undefined;
}

const MainPage = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);
    const [misopt, setMisopt] = useState({});
    const [csp, setCsp] = useState('');

    const {menus, tabs} = useSelector(state => ({
        menus : state.menu.menus,
        tabs: state.menu.tabs
    }))

    const authentication = useSelector(state => state.authentication);

    const refreshDeviceTypes = () => {
        deviceService.fetchDeviceTypes().then(res => {
            const {deviceList: deviceTypes} = res.items;
            const set = new Set();
            for (const device of deviceTypes) {
                const {deviceType, deviceTypeVersion} = device;
                if (deviceType !== undefined && deviceTypeVersion !== undefined) {
                    const device = misOption(deviceType.toUpperCase(), deviceTypeVersion);
                    if (device !== undefined) {
                        set.add(device)
                    }
                    // check platform
                    const {deviceType : platformDeviceType, deviceTypeVersion : platformDeviceTypeVersion} = convertDeviceTypeForPlatform(deviceType, deviceTypeVersion)
                    if (platformDeviceType !== undefined && platformDeviceTypeVersion !== undefined) {
                        const platformDevice = misOption(platformDeviceType, platformDeviceTypeVersion);
                        if (platformDevice !== undefined) {
                            set.add(platformDevice)
                        }
                    }
                }
            }
            let devices = Array.from(set);
            devices = orderDevices(devices);
            let supportedIconDevices = getSupportedDevices(devices);
            supportedIconDevices = orderDevices(supportedIconDevices);
            setMisopt(prevMisopt => ({
                ...prevMisopt,
                deviceTypes,
                devices,
                supportedIconDevices,
                reload: true
            }));
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    useEffect(()=> {


        if (authentication && authentication.loggedIn == true) {
            //const user = JSON.parse(localStorage.getItem('user'));
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);

                const dateFormat = (user.dateFormat || 'yyyy-MM-dd').replace(/Y/g, 'y').replace(/D/g, 'd');
                const timeFormat = user.timeFormat || '24hour';
                const scheduleFirstDay = user.scheduleFirstDay || 0;
                const locale = user.locale;

                const decode = jwtDecode(user.token)

                Promise.all([
                    deviceService.fetchDeviceTypes(),
                    systemService.fetchMenus(true),
                    systemService.fetchConfigs(["SCHEDULE_MAX_CHANNEL_COUNT", "SECURITY_FILTER_SRC"]),
                ]).then(values => {
                    const {deviceList: deviceTypes} = values[0].items;
                    const set = new Set();
                    for (const device of deviceTypes) {
                        const {deviceType, deviceTypeVersion} = device;
                        if (deviceType !== undefined && deviceTypeVersion !== undefined) {
                            const device = misOption(deviceType.toUpperCase(), deviceTypeVersion);
                            if (device !== undefined) {
                                set.add(device)
                            }
                            // check platform
                            const {deviceType: platformDeviceType, deviceTypeVersion: platformDeviceTypeVersion} = convertDeviceTypeForPlatform(deviceType, deviceTypeVersion)
                            if (platformDeviceType !== undefined && platformDeviceTypeVersion !== undefined) {
                                const platformDevice = misOption(platformDeviceType, platformDeviceTypeVersion);
                                if (platformDevice !== undefined) {
                                    set.add(platformDevice)
                                }
                            }
                        }
                    }
                    let devices = Array.from(set);
                    const {viewAuthority} = decode;
                    const authority = JSON.parse(viewAuthority);
                    devices = orderDevices(devices);
                    let supportedIconDevices = getSupportedDevices(devices);
                    supportedIconDevices = orderDevices(supportedIconDevices);

                    const menus = values[1].items;
                    dispatch(menuAction.initMenus(menus));

                    let scheduleMaxChannelCount = 10;
                    try {
                        const configs = values[2].items;

                        if (configs && configs.configList) {
                            configs.configList.map((config) => {
                                switch (config.key) {
                                    case "SCHEDULE_MAX_CHANNEL_COUNT":
                                        scheduleMaxChannelCount = config.value;
                                        break;
                                    case "SECURITY_FILTER_SRC":
                                        setCsp(config.value);
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                    } catch {
                    }

                    if (scheduleMaxChannelCount === undefined || scheduleMaxChannelCount < 10) {
                        scheduleMaxChannelCount = 10;
                    }

                    setMisopt({
                        authority,
                        devices,
                        supportedIconDevices,
                        misInit,
                        acceptFileTypes,
                        deviceTypes,
                        user: {
                            dateFormat,
                            timeFormat,
                            scheduleFirstDay,
                            dateTimeFormat: `${dateFormat} ${timeFormat === 'ampm' ? 'hh:mm A' : 'HH:mm'}`,
                            locale: locale
                        },
                        scheduleMaxChannelCount: scheduleMaxChannelCount,
                        refreshDeviceTypes,
                    })
                }).catch(
                    e => {
                        toastr.error(getErrorMessage(e));

                    }
                )
            }

            if (window.location.protocol !== 'https:') {
                toastr.warning(t('MIS_SID_SERVER_CBMAR_HTTPS_RECOMMENDED_ENSURE_PROTECTION_SECURITY'));
            }
        }

    }, [authentication])

    useEffect(()=> {
        if (misopt && misopt.user && misopt.authority && misopt.reload === undefined) {
            dispatch(menuAction.addTab({id: 'DASHBOARD', title : t("COM_TV_SID_DASHBOARD"), active: true}));
        }
    }, [misopt])

    useEffect(()=> {
        if (menus !== undefined && menus.length > 0 && tabs !== undefined && tabs.length > 0 && misopt !== undefined && misopt.authority !== undefined) {
            if (loading) {
                setLoading(false)
            }
        }
    }, [menus, tabs, misopt])

    return (
        <MISOptContext.Provider value={misopt}>
            <LoadingOverlay active={loading}>
                <div className="container">
                    <Helmet>
                        {
                            csp && csp !== '' &&
                            <meta http-equiv="Content-Security-Policy" content={`default-src 'self' 'unsafe-inline' data: ${csp};`} />
                        }
                    </Helmet>
                    <PopupContainer />
                    <Menu/>
                    <div className="wrap">
                        <TabContainer />
                        <ContentContainer />
                    </div>
                    <ProgressContainer />
                </div>
            </LoadingOverlay>
        </MISOptContext.Provider>
    )

}
export default MainPage;