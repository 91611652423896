import React, { useState, useEffect }  from 'react'
import SettingInformation from "../../components/setting/SettingInformation";
import { withTranslation, Trans } from "react-i18next";
import { useTranslation } from 'react-i18next';
import { settingService } from '../../services';
import { bytesToSize } from '../../helper/bytesToSize';
import { getMixString } from "../../language/languageUtils";
import {Loading} from "../../components/loading/Loading";

const SystemInformation = (props) => {
    const {t} = useTranslation();
    const [data, setData] = useState({systemInfo: [], osImageVersion: [], jvmInfo:[]});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => { 
        setLoading(true);
        settingService.fetchSystemInformation().then(res => {
            setData({
                systemInfo: res.items.systemInfo, 
                osImageVersion: res.items.osImageVersion,
                jvmInfo: res.items.jvmInfo
            });
        }).finally(()=>{
            setLoading(false);
        });
    }

    const getStorageSize = () => {
        if(data.systemInfo.storages != undefined){
            const storages = data.systemInfo.storages;
            return (
                <>
                {
                    [...storages].map(storage => 
                    <div>({storage.storage}) {getMixString(["MIS_SID_MIX_FREE_OF", bytesToSize(storage.freeSize), bytesToSize(storage.totalSize)])} </div>
                    )           
                }
                </>
            )
        }
    }

    const getSeconds = (milli) => {
        return (
            <>
                {milli/1000} {t("COM_SID_SEC")}
            </>
        )
    }

    const getWindowHeight = () => {
        return window.innerHeight ;
    }


    return (
        <>
        {
            loading &&
            <div style={{height: getWindowHeight(), textAlign: 'center', paddingTop: getWindowHeight()/2-60}}><Loading /></div>
        }
        {
            !loading &&
            <div className="settings_list mt20" style={{overflowY: 'auto', overflowX: 'hidden', height: window.innerHeight - 140, display: props.currContent === 'SYSTEM_INFO' ? 'block':'none'}}>
                <SettingInformation
                    id={"SYSTEM_INFO"} 
                    title={t("TEXT_SYSTEM_INFO_P")} 
                    list={[
                        {id: 'WAS_INFO', name: <Trans i18nKey={"COM_TABLE_WAS_INFO_P"} />, value: data.systemInfo.tomcatVersion},
                        {id: 'OS_NAME', name: <Trans i18nKey={"COM_TABLE_OS_NAME_P"} />, value: data.systemInfo.osName },
                        {id: 'OS_IMAGE_VERSION', name: <Trans i18nKey={"TABLE_OS_IMAGE_VERSION_P"} />, value: data.systemInfo.osImageVersion},
                        {id: 'STORAGE_SIZE', name: <Trans i18nKey={"MIS_SID_20_STORAGE_SIZE"} />, value: getStorageSize()},
                        {id: 'OS_ARCHITECTURE', name: <Trans i18nKey={"TABLE_OS_ARCHITECTURE_P"} />, value: data.systemInfo.osArchitecture},
                        {id: 'JVM_VERSION', name: <Trans i18nKey={"COM_TABLE_JVM_VERSION_P"} />, value: data.systemInfo.jvmVersion},
                        {id: 'JVM_VENDOR', name: <Trans i18nKey={"COM_TABLE_JVM_VENDOR_P"} />, value: data.systemInfo.jvmVendor}
                    ]}
                >
                </SettingInformation>
                <SettingInformation 
                    id={"OS_IMAGE_VERSION"} 
                    title={t("TABLE_OS_IMAGE_VERSION_P")} 
                    list={[
                        {id: 'PHYSICAL_MEMORY', name: <Trans i18nKey={"COM_TABLE_PHYSICAL_MEMORY_P"} />, value: bytesToSize(data.osImageVersion.physicalMemory)},
                        {id: 'TOTAL_PAGE_FILE', name: <Trans i18nKey={"COM_TABLE_TOTAL_PAGE_FILE_P"} />, value: bytesToSize(data.osImageVersion.totalPageFileSize) },
                        {id: 'OS_IMAGE_VERSION', name: <Trans i18nKey={"TABLE_MEMORY_LOAD_P"} />, value: data.osImageVersion.memoryLoad},
                        {id: 'PROCESS_KERNEL_TIME', name: <Trans i18nKey={"TABLE_PROCESS_KERNEL_TIME_P"} />, value: getSeconds(data.osImageVersion.processKernelTime)},
                        {id: 'AVAILABLE_MEMORY', name: <Trans i18nKey={"COM_TABLE_AVAILABLE_MEMORY_P"} />, value: bytesToSize(data.osImageVersion.availableMemory)},
                        {id: 'FREE_PAGE_FILE', name: <Trans i18nKey={"COM_TABLE_FREE_PAGE_FILE_P"} />, value: bytesToSize(data.osImageVersion.freePageFileSize)},
                        {id: 'PROCESS_USER_TIME', name: <Trans i18nKey={"TABLE_PROCESS_USER_TIME_P"} />, value: getSeconds(data.osImageVersion.processUserTime)}
                    ]}
                >
                </SettingInformation>
                <SettingInformation 
                    id={"CHECK_PASSWORD"} 
                    title={t("COM_TEXT_TITLE_JVM_INFORMATION_P")} 
                    list={[
                        {id: 'FREE_USABLE_MEMORY', name: <Trans i18nKey={"COM_TABLE_FREE_USABLE_MEMORY_P"} />, value: bytesToSize(data.jvmInfo.freeMemorySize)},
                        {id: 'MAX_USED_MEMORY', name: <Trans i18nKey={"COM_TABLE_MAX_USED_MEMORY_P"} />, value: bytesToSize(data.jvmInfo.maxFreeMemory)},
                        {id: 'TOTAL_USABLE_MEMORY', name: <Trans i18nKey={"COM_TABLE_TOTAL_USABLE_MEMORY_P"} />, value: bytesToSize(data.jvmInfo.totalFreeMemorys)}
                    ]}
                >
                </SettingInformation>
            </div>
        }
        </>
    )
}
export default (withTranslation()(SystemInformation));