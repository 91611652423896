import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {scheduleService} from "../../../../services";
import {Loading} from "../../../loading/Loading";
import './TagInformationForSyncPopup.css';

const TagInformationForSyncPopup = ({devices, close}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [deviceInfo, setDeviceInfo] = useState([])

    useEffect(()=> {

        const groupIds = devices.map(group => group.groupId);
        scheduleService.fetchDeviceTagByDeviceIds(groupIds)
            .then(
                res => setDeviceInfo(res.items)
            )

    }, [])

    const dialogProps ={title: t("MIS_SID_ASSOCIATED_TAGS"), closeOnEscape : true, width :400, height : 200 , modal: true, onClose:()=> close()};

    return (
        <MISDialog
            dialog={dialogProps}
            buttons = {{
                rightButtons: [
                    {title : t("BUTTON_OK_P"), id: 'associate_tag_cancel_button', onClick: ()=> close()}
                ]
            }}>
            <div className={'tag_information_for_sync_schedule'}>
                <table>
                    <colgroup>
                        <col width="151px"/>
                        <col width="50%"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th className="deviceName">{t("TABLE_DEVICE_NAME_P")}</th>
                        <th className="tagName">{t("MIS_TEXT_TAG_NAME_P_KR_MIS20")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        deviceInfo.length > 0 ?
                        deviceInfo.map(
                            device =>
                                <tr>
                                    <td>{device.deviceName}</td>
                                    <td>{device.tagName}</td>
                                </tr>
                        ) : <tr><td colSpan={2}><Loading /></td></tr>
                    }
                    </tbody>
                </table>
            </div>
        </MISDialog>
    )
}
export default TagInformationForSyncPopup;