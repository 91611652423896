import React, {useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import Checkbox from "../../../checkbox/Checkbox";
import DateInput from "../../../input/DateInput";
import TimeInput from "../../../input/TimeInput";
import ContentPopup from "../ContentPopup";
import Moment from 'moment';
import {toastr} from 'helper/toastrIntercept';

const SelectADPlaylistPopup = ({adEvent, closePopup, onUpdateSchedule, onRemoveSchedule, deviceType, deviceTypeVersion, programType}) => {

    const {t} = useTranslation();

    const [event, setEvent] =  useState(adEvent);

    const selectContent = (contentId, contentName, thumbnail, filename, fileSize, contentType) => {
        setEvent({...event, contentId: contentId, contentName: contentName, title: contentName, contentType: contentType, thumbnailFileId: thumbnail})
        openContentPopup();
    }

    const [contentPopup, setContentPopup] = useState(false);

    const openContentPopup = () => {
        setContentPopup(!contentPopup);
    }

    const handleDate = (date, type) => {
        const updateDate = Moment(date).format('YYYY-MM-DD');
        let {startDate, stopDate} = event;
        type === 'start' ? startDate = updateDate : stopDate = updateDate;
        setEvent({...event, startDate: startDate, stopDate: stopDate});
    }

    const handleTime = (time, type) => {
        let {startTime, stopTime} = event;
        type === 'start' ? startTime = time : stopTime = time;
        setEvent({...event, startTime: startTime, stopTime : stopTime});
    }

    const handleNeverExpired = () => {
        let {startDate, startTime, stopDate, stopTime} = event;
        if (!event.isInfinitePlay) {
            stopDate = '2999-12-31';
            stopTime = '23:59:59';
        } else {
            stopDate = Moment(startDate, 'YYYY-MM-DD').add('day', 1).format('YYYY-MM-DD');
            stopTime = startTime;
        }
        setEvent({...event, isInfinitePlay: !event.isInfinitePlay, stopDate: stopDate, stopTime: stopTime})
    }

    const saveEvent = () => {
        const doValid = () => {
            if (event.contentName === '') {
                toastr.error(t("COM_TEXT_SELECT_CONTENT_P"));
                return false;
            }
            const start = Moment(event.startDate+' '+event.startTime);
            const end = Moment(event.stopDate+' '+event.stopTime);
            if (start >= end) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            }

            return true;
        }

        if (doValid()) {
            onUpdateSchedule(event.slotId, event);
        }
    }

    const dialogProps ={title: t("MIS_BUTTON_CREATE_PROGRAM_P"), closeOnEscape : true, position: {x: -300, y: -400} , modal:true, onClose:()=> closePopup()};

    return (
        <MISDialog
            dialog={dialogProps}
            width={550}
            height={150}
            buttons={{
                leftButtons: [
                    {id: 'DELETE_SCHEDULE', title: t("COM_BUTTON_DELETE"), onClick: ()=>onRemoveSchedule(adEvent.slotId, event.scheduleId)},
                ],
                rightButtons: [
                    {id: 'SAVE_SCHEDULE', title: t("COM_BUTTON_SAVE"), onClick: ()=>saveEvent()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> closePopup()}
                ]
            }}
        >
            {
                contentPopup &&
                <ContentPopup
                    mode={'advertisement_schedule'}
                    defaultTab={'PLAYLIST'}
                    closeContentPopup={openContentPopup}
                    selectContent={selectContent}
                    deviceType={deviceType}
                    deviceTypeVersion={deviceTypeVersion}
                    programType={programType}
                />
            }

            <div className="popup_contents">
                <div className="detail_view">
                    <table>
                        <colgroup><col width="184px" /><col width="" /></colgroup>
                        <tbody>


                            <tr className="contentTr">
                                <th>{t("TEXT_CONTENT_P")}</th>
                                <td>
                                    <div style={{display:'block-inline'}}>
                                        <div className="" style={{float:'left'}}>
                                            <span id="selectContentInput">
                                                <input type="text" id="selectContentName" value={event.contentName} readOnly style={{width:220}} />
                                            </span>
                                        </div>
                                        <div style={{float:'left'}}>
                                            <button className="contentSelectSearchBtn" onClick={openContentPopup}></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>{t("COM_SID_START_TIME")}</th>
                                <td>
                                    <div style={{display: 'flex'}}>

                                        <DateInput dateFormat={'yyyy-MM-dd'} date={event.startDate} onChange={date => handleDate(date, 'start')} type={'schedule_date_warp'}/>

                                        <TimeInput width={80} showSeconds value={event.startTime} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(value, 'start')}/>

                                        <span style={{marginLeft:5}}>
                                            <Checkbox id={"neverExpired"} name={t("MIS_SID_20_NEVER_EXPIRE")} onClick={handleNeverExpired} checked={event.isInfinitePlay}/>
                                        </span>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("COM_SID_END_TIME")}</th>
                                <td>

                                    <DateInput dateFormat={'yyyy-MM-dd'} date={event.stopDate} onChange={date => handleDate(date, 'end')} disabled={event.isInfinitePlay} type={'schedule_date_warp'}/>

                                    <TimeInput width={80} showSeconds value={event.stopTime} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(value, 'end')} disabled={event.isInfinitePlay}/>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    )

}
export default SelectADPlaylistPopup