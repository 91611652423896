import React, {useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from 'react-i18next';
import isNil from 'lodash/isNil';
import {settingService} from "../../../../services";
import {getErrorMessage} from '../../../../helper/responseHandler';
import DateToStr from "../../../../components/utils/DateToStr";
import {toastr} from 'helper/toastrIntercept';
import WhiteButton from "../../../button/WhiteButton";
import {Loading} from "../../../../components/loading/Loading";
import ServiceHistoryDetailsPopup from './ServiceHistoryDetailsPopup';

const DeviceServiceHistoryPopup = ({onClose, deviceId}) => {
    const {t} = useTranslation();

    const [queue, setQueue] = useState(null);
    const [keepalive, setKeepalive] = useState(null);

    const [open, setOpen] = useState({queue: false, keepalive: false});
    const [detail, setDetail] = useState({data: null, open: false});

    const fetchRequestedQueue = (deviceId) => {
        setOpen({...open, queue: !open.queue});
        if(!open.queue){
            settingService.fetchRequestedQueue(deviceId).then(res => {
                if(res.items){
                    setQueue(res.items);
                }
            }).catch(e => {
                if(!isNil(e.errorCode) && e.errorCode == "404000"){
                    setQueue([]);
                    return;
                }
                toastr.error(getErrorMessage(e));
            });
        }
        
    }

    const fetchKeepalive = (deviceId) => {
        setOpen({...open, keepalive: !open.keepalive});
        settingService.fetchKeepalive(deviceId).then(res => {
            if(res.items){
                setKeepalive(res.items);
            }
        }).catch(e => {
            setKeepalive({});
            toastr.error(getErrorMessage(e));
        })
    }

    const openDetailPopup = (context) => {
        let test = ""
        setDetail({data: context, open:true});
    }
    
    return(
        <div>
            <MISDialog
                dialog={{
                    title: t("TEXT_TITLE_SERVICE_HISTORY_P"),
                    modal: true,
                    closeOnEscape: true,
                    width: 700,
                    height: 500,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'BUTTON_CLOSE',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                <div className="service_history check_list none_scrolling" style={{height: '500px', overflowY : 'auto', overflowX: 'hidden'}}>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td>
                                <div className={'expander' + (open.queue?' open':'')} style={{float:'left'}} onClick={()=>fetchRequestedQueue(deviceId)}></div>
                                <div style={{float:'left'}}>Requested Queue</div>
                            </td>
                        </tr>
                        {
                            open.queue && isNil(queue) && 
                            <tr><td><Loading /></td></tr>
                        }
                        {
                            open.queue && !isNil(queue) && 
                            <tr>
                            <td>
                                <div className="server_setting check_list none_scrolling">
                                    <table style={{width:'100%'}}>
                                        <colgroup>
                                            <col width="40%"></col>
                                            <col width="30%"></col>
                                            <col width="30%"></col>
                                        </colgroup>
                                        <tbody>
                                            <tr>    
                                                <th>{t("COM_TEXT_SERVICE_NAME_P")}</th>
                                                <th>{t("TABLE_CREATE_DATE_P")}</th>
                                                <th>{t("BUTTON_DETAIL_P")}</th>
                                            </tr>
                                            {
                                                queue.length == 0 &&
                                                <tr>
                                                    <td colSpan="3">No data</td>
                                                </tr>

                                            }
                                            {
                                                queue.length > 0 &&
                                                queue.map(item => 
                                                    <tr key={item.serviceId}>
                                                        <td>{item.serviceId}</td>
                                                        <td><DateToStr date={item.createTime}/></td>
                                                        <td>
                                                            <WhiteButton id={'viewDetails'} name={t("BUTTON_DETAIL_P")} onClick={()=>openDetailPopup(item.messageContext)}/>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        }
                        <tr>
                            <td>
                                <div className={'expander' + (open.keepalive?' open':'')} style={{float:'left'}} onClick={()=>fetchKeepalive(deviceId)}></div>
                                <div style={{float:'left'}}>KeepAlive</div>
                            </td>
                        </tr>
                        {
                            open.keepalive && isNil(keepalive) && 
                            <tr><td><Loading /></td></tr>
                        }
                        {
                            open.keepalive && !isNil(keepalive) && 
                            <tr>
                            <td>
                                <div className="server_setting check_list none_scrolling">
                                    <table style={{width:'100%'}}>
                                        <colgroup>
                                            <col width="20%"></col>
                                            <col width="20%"></col>
                                            <col width="20%"></col>
                                            <col width="5%"></col>
                                            <col width="5%"></col>
                                            <col width="10%"></col>
                                            <col width="20%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Program ID</th>
                                                <th>Message ID</th>
                                                <th>Event ID</th>
                                                <th>Input Source</th>
                                                <th>Panel Status</th>
                                                <th>Player Mode</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={keepalive.programId}>
                                                <td className={'full_text'}>{keepalive.programId?keepalive.programId:"-"}</td>
                                                <td className={'full_text'}>{keepalive.messageId?keepalive.messageId:"-"}</td>
                                                <td className={'full_text'}>{keepalive.eventId}</td>
                                                <td className={'full_text'}>{keepalive.inputSource}</td>
                                                <td className={'full_text'}>{keepalive.panelStatus}</td>
                                                <td className={'full_text'}>{keepalive.playerMode}</td>
                                                <td className={'full_text'}>{keepalive.keepaliveTime?<DateToStr date={keepalive.keepaliveTime}/>:'-'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </MISDialog>
            {
                detail.open && 
                <ServiceHistoryDetailsPopup
                    detail={detail.data}
                    onClose={()=>setDetail({...detail, open:false})}
                />
            }
        </div>
    )
}

export default DeviceServiceHistoryPopup;