import React, {Component} from 'react';
import "../../components/table/react-table.css";
import {withTranslation} from 'react-i18next';
import {statisticsService} from '../../services';
import './PopFileHistory.css'
import Select from "../../components/selectbox/Select";
import {Bar} from "react-chartjs-2";
import ReactTable from "react-table";
import {contentConstants} from "../../constants";
import classNames from "classnames";
import fileDownload from "js-file-download";
import {toastr} from 'helper/toastrIntercept';
import Moment from "moment";
import WhiteButton from "../../components/button/WhiteButton";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

class PopFileHistory extends Component {
    state = {
        periods: [],
        selectedOptionPeriodIndex: -1,
        selectedChartIndex: -1,
        selectedDate: "",
        chartData: {},
        receivedChart: [],
        notReceivedChart: [],
        receivedChartTotalCount: 0,
        receivedPageIndex: 0,
        receivedChartPage: 0,
        receivedChartPageSize: contentConstants.PAGE_SIZE_OPTIONS[0],
        notReceivedChartTotalCount: 0,
        notReceivedPageIndex: 0,
        notReceivedChartPage: 0,
        notReceivedChartPageSize: contentConstants.PAGE_SIZE_OPTIONS[0],
        loading: false,
        loadingbar: false,
    };

    constructor(props) {
        super(props);
        this.tick = {beginAtZero: true, min: 0, max: 10};
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    componentDidMount() {
        this.fetchPopFileHistoryPeriods();
    }

    onChangePeriod = (value) => {
        let selectedMonth = this.state.periods[value].id;
        this.setState({
            selectedOptionPeriodIndex: value,
            receivedPageIndex: 0,
            receivedChartPage: 0,
            receivedChartPageSize: contentConstants.PAGE_SIZE_OPTIONS[0],
            notReceivedPageIndex: 0,
            notReceivedChartPage: 0,
            notReceivedChartPageSize: contentConstants.PAGE_SIZE_OPTIONS[0],
        }, () => {
            this.fetchPopFileHistoryChart(selectedMonth);
        });
    };

    onClickExport = (docType) => {
        const {t} = this.props;
        this.setState({loadingbar: true});
        const {periods, selectedOptionPeriodIndex} = this.state;
        statisticsService.fetchStatisticsContentsFileDownload(docType, {
            data: "popFileHistory",
            searchMonth: periods[selectedOptionPeriodIndex].id,
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loadingbar: false});});
    }

    onClickBarChart = (element) => {
        if (element && element.length > 0 &&
            (element[0]._index !== this.state.selectedChartIndex || element[0]._index === this.state.selectedChartIndex  && this.state.selectedChartIndex === 0)) {
            let selectedChartIndex = element[0]._index;
            let dataset = element[0]._chart.data.datasets[0];
            let day = this.state.chartData.labels[selectedChartIndex];
            day = day.length === 1 ? "0" + day : day;
            let selectedDate = this.state.periods[this.state.selectedOptionPeriodIndex].title + "-" + day;
            let bgColor = [];
            dataset.data.forEach((type, index) => {
                bgColor.push(index === selectedChartIndex ? '#3196FF' : '#1AA05A');
            });
            dataset.borderColor = bgColor;
            dataset.backgroundColor = bgColor;

            let chartData = {
                labels: this.state.chartData.labels,
                datasets: [],
            };
            chartData.datasets.push(dataset);
            this.setState({
                chartData: chartData,
                selectedChartIndex: selectedChartIndex,
                selectedDate: selectedDate,
                receivedPageIndex: 0,
                receivedChartPage: 0,
                receivedChartPageSize: contentConstants.PAGE_SIZE_OPTIONS[0],
                notReceivedPageIndex: 0,
                notReceivedChartPage: 0,
                notReceivedChartPageSize: contentConstants.PAGE_SIZE_OPTIONS[0],
            }, () => {
                this.fetchReceivedList(this.state.receivedPageIndex, this.state.receivedChartPageSize, selectedDate);
                this.fetchNonReceivedList(this.state.notReceivedPageIndex, this.state.notReceivedChartPageSize, selectedDate);
            });
        }
    };

    onChangedNotReceivedChartPage = (page) => {
        this.setState({
            notReceivedPageIndex: page * this.state.notReceivedChartPageSize,
            notReceivedChartPage: page,
        }, () => {
            this.fetchNonReceivedList(this.state.notReceivedPageIndex, this.state.notReceivedChartPageSize, this.state.selectedDate);
        });
    };

    onChangedNotReceivedChartPageSize = (pageSize, page) => {
        this.setState({
            notReceivedPageIndex: page,
            notReceivedChartPage: page,
            notReceivedChartPageSize: pageSize,
        }, () => {
            this.fetchNonReceivedList(this.state.notReceivedPageIndex, this.state.notReceivedChartPageSize, this.state.selectedDate);
        });
    };

    onChangedReceivedChartPage = (page) => {
        this.setState({
            receivedPageIndex: page * this.state.receivedChartPageSize,
            receivedChartPage: page,
        }, () => {
            this.fetchReceivedList(this.state.receivedPageIndex, this.state.receivedChartPageSize, this.state.selectedDate);
        });
    };

    onChangedReceivedChartPageSize = (pageSize, page) => {
        this.setState({
            receivedPageIndex: page,
            receivedChartPage: page,
            receivedChartPageSize: pageSize,
        }, () => {
            this.fetchReceivedList(this.state.receivedPageIndex, this.state.receivedChartPageSize, this.state.selectedDate);
        });
    };

    fetchPopFileHistoryPeriods = () => {
        this.setState({loading: true});
        statisticsService.fetchStatisticsContents({
            data: 'popFileHistoryPeriods',
        }).then(res => {
            let periods = [];
            res.items.forEach((period, index) => {
                periods.push({value: index, id: period.tableDate, title: period.tableDate,});
            });
            let selectedMonth = periods[0].title;
            this.setState({
                periods: periods,
                selectedOptionPeriodIndex: periods[0].value,
                selectedChartIndex: -1,
            });
            this.fetchPopFileHistoryChart(selectedMonth);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loading: false})});
    };

    fetchPopFileHistoryChart = (month) => {
        this.setState({loading: true});
        statisticsService.fetchStatisticsContents({
            data: 'popFileHistory',
            format: 'historyChart',
            searchMonth: month,
        }).then(res => {
            let chartData = {
                labels: [],
                datasets: [],
            };
            let currentDay = Moment(new Date()).format('DD');
            let selectedMonthEndDay = Moment(Moment(month).endOf('month').toDate()).format('DD');
            if(Number(currentDay) > Number(selectedMonthEndDay)) {
                currentDay = selectedMonthEndDay;
            }
            let selectedDate = month + "-" + currentDay;
            let selectedChartIndex = 0;
            this.tick = {beginAtZero: true, min: 0, max: 10};
            if (res && res.items && res.items.length > 0) {
                let bgColor = [];
                let datas = [];
                res.items.forEach((item, index) => {
                    chartData.labels.push(item.type);
                    datas.push(item.count);
                    bgColor.push(Number(item.type) === Number(currentDay) ? '#3196FF' : '#1AA05A');
                });
                let max = Math.max(...datas);
                if (max >= 10) {
                    this.tick = {beginAtZero: true};
                }
                let dataset = {
                    data: datas,
                    borderColor: bgColor,
                    backgroundColor: bgColor,
                };
                chartData.datasets.push(dataset);
                this.fetchReceivedList(this.state.receivedPageIndex, this.state.receivedChartPageSize, selectedDate);
                this.fetchNonReceivedList(this.state.notReceivedPageIndex, this.state.notReceivedChartPageSize, selectedDate);
            }
            this.setState({
                chartData: chartData,
                selectedDate: selectedDate,
                selectedChartIndex: selectedChartIndex,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loading: false})});
    };

    fetchReceivedList = (startIndex, pageSize, startDate) => {
        this.setState({loading: true});
        statisticsService.fetchStatisticsContents({
            data: 'popFileHistory',
            format: 'receivedChart',
            startIndex: startIndex,
            pageSize: pageSize,
            startDate: startDate,
        }).then(res => {
            this.setState({
                receivedChart: res.items,
                receivedChartTotalCount: res.totalCount,
                receivedPageIndex: res.startIndex,
                receivedChartPageSize: res.pageSize,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loading: false})});
    };

    fetchNonReceivedList = (startIndex, pageSize, startDate) => {
        this.setState({loading: true});
        statisticsService.fetchStatisticsContents({
            data: 'popFileHistory',
            format: 'notReceivedChart',
            startIndex: startIndex,
            pageSize: pageSize,
            startDate: startDate,
        }).then(res => {
            this.setState({
                notReceivedChart: res.items,
                notReceivedChartTotalCount: res.totalCount,
                notReceivedPageIndex: res.startIndex,
                notReceivedChartPageSize: res.pageSize,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loading: false})});
    };

    noDataComponent = () => {
        let {t} = this.props;
        return <div style={{position: 'absolute', left: '50%', top: '50%', color: '#59616b'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
    };

    render() {
        const {currContent} = this.props;
        const {loadingbar} = this.state;
        return (
            <div className='pop-file-history' style={{display: currContent === 'PROOF_OF_PLAY_FILE_HISTORY' ? 'flex':'none'}}>
                {this.renderHeader()}
                {this.renderChart()}
                {this.renderChartTable()}
                {loadingbar && <LoadingBar />}
            </div>
        )
    };

    renderHeader() {
        let {t} = this.props;
        let {selectedOptionPeriodIndex, periods} = this.state;
        return (
            <div className="pop-file-history-top">
                <div className='pop-file-history-export'>
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => this.onClickExport('EXCEL')}/>
                </div>
                <div className='pop-file-history-period'>
                    <span className='pop-file-history-period-title'>{t('TEXT_PERIOD_P')}</span>
                    <Select multiLang={false} width={200} value={selectedOptionPeriodIndex} selects={periods} onChange={(e, value) => this.onChangePeriod(value)}/>
                </div>
            </div>
        );
    }

    renderChart() {
        let {t} = this.props;
        let {chartData} = this.state;
        return (
            <div className='pop-file-history-chart'>
                <div className='pop-file-history-chart-guide'>
                    <div className='pop-file-history-chart-guide-color-index' style={{background: '#1AA05A'}}/>
                    <div className='pop-file-history-chart-guide-title-index'>{t('MIS_SID_DATA_RECEIVED')}</div>
                </div>
                <div className='pop-file-history-chart-bar'>
                    <Bar data={chartData} width={1400} height={278} redraw={true}
                         options={{
                            showDatapoints: true,
                             legend: {
                                 display: false,
                             },
                             animation: {
                                 duration: 0,
                                 onComplete: function () {
                                     let chart = this.chart;
                                     chart.ctx.textAlign = 'center';
                                 },
                             },
                             scales: {
                                 yAxes: [{
                                     ticks: {
                                         ...this.tick,
                                         fontSize: 11,
                                         callback: function (value) {
                                             if (value % 1 === 0) {
                                                 return value;
                                             }
                                         }
                                     },
                                 }]
                             },
                             elements: {point: {radius: 0}},
                             tooltips: {enabled: false},
                             hover: {animationDuration: 0},
                         }}
                         onElementsClick={this.onClickBarChart}/>
                    <div className='pop-file-history-date' style={{paddingRight: '0px',}}>{t('TEXT_DATE_P')}</div>
                </div>
            </div>
        );
    }

    renderChartTable() {
        const selectedDate = this.user && this.user.dateFormat && this.state.selectedDate !== "" ? Moment(this.state.selectedDate, "YYYY-MM-DD").format(this.user.dateFormat.toUpperCase()) : this.state.selectedDate;
        return (
            <div className='pop-file-history-table'>
                <div className='pop-file-history-table-date'>{selectedDate}</div>
                <div className='pop-file-history-table-body'>
                    {this.renderReceivedChartTable()}
                    <div style={{minWidth: '30px'}}/>
                    {this.renderNotReceivedChartTable()}
                </div>
            </div>
        );
    }

    renderReceivedChartTable() {
        let {t} = this.props;
        let {receivedChart, receivedChartTotalCount, receivedChartPage, receivedChartPageSize} = this.state;
        return (
            <div className='pop-file-history-table-list'>
                {this.renderPageSize(t('MIS_SID_MIX_RECEIVED_COLON').replace("<<A>>", receivedChartTotalCount), receivedChartTotalCount, receivedChartPageSize, contentConstants.PAGE_SIZE_OPTIONS, this.onChangedReceivedChartPageSize)}
                <ReactTable
                    data={receivedChart}
                    minRows={0}
                    sortable={false}
                    showPagination={false}
                    NoDataComponent={this.noDataComponent}
                    style={{
                        height: '362px',
                    }}
                    columns={[
                        {
                            Header: t('TABLE_MAC_ADDR_P'),
                            accessor: "deviceId",
                            Cell: (props) => <span className={"pop-file-history-table-list-column-data"} title={props.original.deviceId}>{props.original.deviceId}</span>,
                            width: 226,
                        },
                        {
                            Header: t('TABLE_DEVICE_NAME_P'),
                            accessor: 'deviceName',
                            Cell: (props) => <span className={"pop-file-history-table-list-column-data"} title={props.original.deviceName}>{props.original.deviceName}</span>,
                            width: 226,
                        },
                        {
                            Header: t('TEXT_DEVICE_GROUP_P'),
                            accessor: 'groupName',
                            Cell: (props) => <span className={"pop-file-history-table-list-column-data"} title={props.original.groupName}>{props.original.groupName}</span>,
                            width: 216,
                            resizable: false,
                        }
                    ]}
                    className="-striped -highlight"
                    manual
                    getTableProps={() => {
                        return {
                            className: 'pop-file-history-tbody-style',
                        };
                    }}
                    getTbodyProps={() => {
                        return {
                            className: 'pop-file-history-tbody-style',
                        };
                    }}
                    getTrGroupProps={(state, rowInfo) => {
                        return {
                            className: 'pop-file-history-tr-style',
                        };
                    }}
                    getTdProps={() => {
                        return {
                            className: 'pop-file-history-td-style'
                        };
                    }}/>
                {this.renderPageNavigation(receivedChartTotalCount, receivedChartPage, receivedChartPageSize, this.onChangedReceivedChartPage)}
            </div>
        );
    }

    renderNotReceivedChartTable() {
        let {t} = this.props;
        let {notReceivedChart, notReceivedChartTotalCount, notReceivedChartPage, notReceivedChartPageSize} = this.state;
        return (
            <div className='pop-file-history-table-list'>
                {this.renderPageSize(t('MIS_SID_MIX_NOT_RECEIVED').replace("<<A>>", notReceivedChartTotalCount), notReceivedChartTotalCount, notReceivedChartPageSize, contentConstants.PAGE_SIZE_OPTIONS, this.onChangedNotReceivedChartPageSize)}
                <ReactTable
                    data={notReceivedChart}
                    minRows={0}
                    sortable={false}
                    showPagination={false}
                    NoDataComponent={this.noDataComponent}
                    style={{
                        height: '362px',
                    }}
                    columns={[
                        {
                            Header: t('TABLE_MAC_ADDR_P'),
                            accessor: "deviceId",
                            Cell: (props) => <span className={"pop-file-history-table-list-column-data"} title={props.original.deviceId}>{props.original.deviceId}</span>,
                            width: 226,
                        },
                        {
                            Header: t('TABLE_DEVICE_NAME_P'),
                            accessor: 'deviceName',
                            Cell: (props) => <span className={"pop-file-history-table-list-column-data"} title={props.original.deviceName}>{props.original.deviceName}</span>,
                            width: 226,
                        },
                        {
                            Header: t('TEXT_DEVICE_GROUP_P'),
                            accessor: 'groupName',
                            Cell: (props) => <span className={"pop-file-history-table-list-column-data"} title={props.original.groupName}>{props.original.groupName}</span>,
                            width: 216,
                            resizable: false,
                        }
                    ]}
                    className="-striped -highlight"
                    manual
                    getTableProps={() => {
                        return {
                            className: 'pop-file-history-tbody-style',
                        };
                    }}
                    getTbodyProps={() => {
                        return {
                            className: 'pop-file-history-tbody-style',
                        };
                    }}
                    getTrGroupProps={(state, rowInfo) => {
                        return {
                            className: 'pop-file-history-tr-style',
                        };
                    }}
                    getTdProps={() => {
                        return {
                            className: 'pop-file-history-td-style'
                        };
                    }}/>
                {this.renderPageNavigation(notReceivedChartTotalCount, notReceivedChartPage, notReceivedChartPageSize, this.onChangedNotReceivedChartPage)}
            </div>
        );
    }

    renderPageSize(title, totalCount, pageSize, pageSizeOptions, onPageSizeChange) {
        return (
            <div className='pop-file-history-table-list-pagination-size'>
                {
                    pageSizeOptions && pageSizeOptions.length > 0 &&
                    <select onChange={(e) => onPageSizeChange(parseInt(e.target.value), 0)} value={pageSize}>
                        {
                            pageSizeOptions.map(ps => <option key={ps} value={ps}>{ps}</option>)
                        }
                    </select>
                }
                <div className='pop-file-history-table-list-received-count'>{title}</div>
            </div>
        );
    }

    renderPageNavigation(totalCount, page, pageSize, onPageChange) {
        const pg = page + 1;
        const totalPage = Math.ceil(totalCount / pageSize);
        const start = totalCount > 0 ? (page * pageSize) + 1 : 0;
        const end = (page + 1) === totalPage ? totalCount : totalCount > 0 ? (page + 1) * pageSize : 0;
        let pages = [];
        if (totalPage < 8) {
            for (let i = 1; i <= totalPage; i++) {
                pages.push(i);
            }
        } else {
            if (pg < 5) {
                for (let i = 1; i <= Math.min(totalPage, 5); i++) {
                    pages.push(i);
                }
                pages = pages.concat([-1, totalPage - 1, totalPage]);
            } else if ((totalPage - pg + 1) < 5) {
                pages = pages.concat([1, 2, -1]);

                for (let i = totalPage - 4; i <= totalPage; i++) {
                    pages.push(i);
                }
            } else {
                pages = pages.concat([1, -1, pg - 1, pg, pg + 1, -1, totalPage]);
            }
        }
        return (
            <div className='pop-file-history-table-list-pagination-wrap'>
                <div className='pop-file-history-table-list-pagination-info'>{`${start} - ${end} / ${totalCount}`}</div>
                <ul className='pop-file-history-table-list-pagination-pages'>
                    {
                        pages.map((e, i) => {
                            if (e === -1) {
                                return <li key={i}>...</li>;
                            }
                            return (
                                <li className={classNames({'active': e === page + 1})} key={`page_${e}`} data-value={e} onClick={() => {
                                    const changed = e - 1;
                                    if (page !== changed) {
                                        onPageChange(changed);
                                    }
                                }}>{e}</li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default withTranslation()(PopFileHistory);