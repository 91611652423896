import React, {useEffect, useRef, useState} from 'react';
import {useMISOpt} from "../misopt";
import './TimePicker2.css'

const TimePicker2 = ({time, onChange, disabled, width = 100}) => {

    const {misopt: {user: {timeFormat}}} = useMISOpt();
    const isSetAmPm = timeFormat === 'ampm' ? true : false;

    const maxPosition = isSetAmPm ? 3 : 2;

    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');
    const [ampm, setAmpm] = useState('');

    const inputRef = useRef();

    const [position, setPosition] = useState(-1);
    const [index, setIndex] = useState(0);

    const INCREASE = 'increase';
    const DECREASE = 'decrease';

    useEffect(()=> {
        const timeArrays = time.split(':');
        if (isSetAmPm) {
            const hourInteger = parseInt(timeArrays[0]);
            if (hourInteger > 12) {
                updateHour(hourInteger-12);
                setAmpm('PM');
            } else {
                updateHour(hourInteger);
                setAmpm('AM');
            }
        } else {
            setHour(timeArrays[0]);
        }
        setMinute(timeArrays[1]);
        setSecond(timeArrays[2]);
    }, [time])

    const onFocus = (e) => {
        setPosition(0);
        setIndex(0);
    }

    const onclick = (e) => {
        let {selectionStart} = inputRef.current;
        if (selectionStart === 2 || selectionStart === 5 || selectionStart === 8) {
            selectionStart = selectionStart-1;
        }

        switch (selectionStart) {
            case 0 : case 1:
                setPosition(0);
                break;
            case 3: case 4:
                setPosition(1);
                break;
            case 6: case 7:
                setPosition(2);
                break;
            case 9: case 10: case 11:
                setPosition(3);
                break;

        }

    }

    const onKeyDown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let keyCode = e.keyCode || e.which;
        if (keyCode >= 96 && keyCode <= 105) {
            // Numpad keys
            keyCode -= 48;
        }
        const inputNumber = String.fromCharCode(keyCode);

        if (keyCode >= 37 && keyCode <= 40) {
            switch (keyCode) {
                case 39:
                    if (position < maxPosition) {
                        setPosition(position+1);
                        setIndex(0);
                    }
                    break;
                case 37:
                    if (position > 0) {
                        setPosition(position-1);
                        setIndex(0);
                    }
                    break;
                case 38:
                    updateTime(INCREASE);
                    break;
                case 40 :
                    updateTime(DECREASE);
                    break;
            }
        } else if (inputNumber >= 0 && inputNumber <= 9) {
            let updateNumber = '';
            if (position === 0) {
                // hour
                updateNumber = hour;
                const secondNumber = Number(hour.charAt(1));
                if (isSetAmPm) {
                    if (secondNumber < 2) {
                        updateNumber = `${secondNumber}${inputNumber}`;
                    } else {
                        updateNumber = `0${inputNumber}`;
                    }
                } else {
                    if (secondNumber < 3) {
                        updateNumber = `${secondNumber}${inputNumber}`;
                    } else {
                        updateNumber = `0${inputNumber}`;
                    }
                }
                setHour(updateNumber);
            } else if (position === 1) {
                // minute
                updateNumber = minute;
                const secondNumber = Number(minute.charAt(1));
                if (secondNumber> 5) {
                    updateNumber = `0${inputNumber}`;
                } else {
                    updateNumber = `${secondNumber}${inputNumber}`;
                }
                setMinute(updateNumber);
            } else if (position === 2) {
                // second
                updateNumber = second;
                const secondNumber = Number(second.charAt(1));
                if (secondNumber> 5) {
                    updateNumber = `0${inputNumber}`;
                } else {
                    updateNumber = `${secondNumber}${inputNumber}`;
                }
                setSecond(updateNumber);
            }
        }
    }

    const onBlur = (e) => {
        let updateTime;
        if (isSetAmPm) {
            updateTime = `${ampm === 'PM' ? parseInt(hour)+12 : hour}:${minute}:${second}`;
        } else {
            updateTime = `${hour}:${minute}:${second}`;
        }
        onChange(inputRef.current, updateTime);
    }

    const updateHour = (h) => {
        if (h < 10) {
            setHour(`0${String(h)}`);
        } else {
            setHour(String(h));
        }
    }

    const updateMinute = (m) => {
        if (m < 10) {
            setMinute(`0${String(m)}`);
        } else {
            setMinute(String(m));
        }
    }

    const updateSecond = (s) => {
        if (s < 10) {
            setSecond(`0${String(s)}`);
        } else {
            setSecond(String(s));
        }
    }

    const updateTime = (cmd) => {
        if (cmd === INCREASE) {
            switch(position) {
                case 0:
                    const intHour = parseInt(hour);
                    const maxHour = isSetAmPm ? 12 : 23;
                    if ((intHour+1) < maxHour) {
                        updateHour(intHour+1);
                    }
                    break;
                case 1:
                    const intMinute = parseInt(minute);
                    const maxMinute = 60;
                    if ((intMinute+1) < maxMinute) {
                        updateMinute(intMinute+1);
                    }
                    break;
                case 2:
                    const intSecond = parseInt(second);
                    const maxSecond = 60;
                    if ((intSecond+1) < maxSecond) {
                        updateSecond(intSecond+1);
                    }
                    break;
                case 3:
                    if (isSetAmPm) {
                        if (ampm === 'AM') {
                            setAmpm('PM');
                        }
                    }
                    break;
            }
        } else if (cmd === DECREASE) {
            switch(position) {
                case 0:
                    const intHour = parseInt(hour);
                    if ((intHour-1) > -1) {
                        updateHour(intHour-1);
                    }
                    break;
                case 1:
                    const intMinute = parseInt(minute);
                    if ((intMinute-1) > -1) {
                        updateMinute(intMinute-1);
                    }
                    break;
                case 2:
                    const intSecond = parseInt(second);
                    if ((intSecond-1) > -1) {
                        updateSecond(intSecond-1);
                    }
                    break;
                case 3:
                    if (isSetAmPm) {
                        if (ampm === 'PM') {
                            setAmpm('AM');
                        }
                    }
                    break;
            }
        }
    }

    useEffect(()=> {
        if (position > -1) {
            switch (position) {
                case 0 :
                    inputRef.current.setSelectionRange(0, 2);
                    break;
                case 1:
                    inputRef.current.setSelectionRange(3, 5);
                    break;
                case 2:
                    inputRef.current.setSelectionRange(6, 8);
                    break;
                case 3:
                    inputRef.current.setSelectionRange(9, 11);
                    break;
            }
        }
    }, [position, index, hour, minute, second, ampm])

    useEffect(()=> {
        if (isSetAmPm) {
            if (Number(hour) > 11) {
                setHour('11');
            }
        } else {
            if (Number(hour) > 23) {
                setHour('23');
            }
        }
    }, [hour])

    useEffect(()=> {
        if (Number(minute) > 59) {
            setMinute('59');
        }
    }, [minute])

    useEffect(()=> {
        if (Number(second) > 59) {
            setSecond('59');
        }
    }, [second])

    return (
        <span className={'time_picker_wrap'}>
            <input className={'time_picker_2'} style={{width: width}} disabled={disabled} value={isSetAmPm ? `${hour}:${minute}:${second} ${ampm}` : `${hour}:${minute}:${second}`} onKeyDown={onKeyDown} onClick={onclick} onFocus={onFocus} onBlur={onBlur} ref={inputRef} />
        </span>
    )
}
export default TimePicker2;