import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import classNames from "classnames";
import {useMISOpt} from '../../misopt'
import {deviceService} from '../../../services';
import Pagination from "../../table/Pagination";
import MagicInfoTable from "../../table/MagicInfoTable";
import Checkbox from "../../../components/checkbox/Checkbox";
import SearchBar from "../../search/SearchBar";
import WhiteButton from '../../button/WhiteButton';
import {getMixString} from "../../../language/languageUtils";
import DeviceGroupTree from "../../../components/tree/DeviceGroupTree";
import DeviceNameCell from '../../../components/device/DeviceNameCell';
import {toastr} from 'helper/toastrIntercept';
import {DEVICE_TYPES} from '../../../constants';
import {isNil} from 'lodash';

import './SelectDevicesPopup.css';

const SelectDevicesPopup = ({onSelect, onClose, filterOptions, message, selectedItems = []}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const [active, setActive] = useState('ALL');
    const [showAll, setShowAll] = useState(false);
    const [tooltip, setTooltip] = useState({visible:false});
    const searchPlaceHolder = `${t('TABLE_DEVICE_NAME_P')},MAC,${t('TABLE_IP_ADDR_P')},${t('ADMIN_DEVICEEVENT_ALARMRULE_ASSIGNRULE_CONTENTS_MODEL')},${t('COM_TEXT_FIRMWARE_P')},${t('TABLE_LOCATION_P')}, ${t('TABLE_DEVICE_SERIAL_P')} `;
    
    const [data, setData] = useState({
        loading: false,
        items: [],
        totalCount: 0,
        selectedCount: selectedItems.length
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;

    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 30,
        ...filterOptions
    });

    const selectedDevicesRef = useRef(selectedItems);
    const MAX_COUNT = 50;

    useEffect(() => {
        fetchDevices();
    }, [filter]);

    const addDevice = () => {
        onSelect(selectedDevicesRef.current);
    };

    const onCheck = (e) => {     
        const index =  e.target.getAttribute("data-index");
        const checkedStatus = e.target.checked;

        onSelectDevice(e, checkedStatus, index);        
    }

    const addAll = () => {
        items.forEach((item, i) => {
            if(selectedDevicesRef.current.filter(device => device.deviceId == item.deviceId).length == 0){
                onSelectDevice({target: {id: item.deviceId}}, true, i, true);
            }
        })
    }

    const deleteAll = () => {
        selectedDevicesRef.current = [];
        setData({...data});
    }

    const onSelectDevice = (e, checked, index, isAll = false) => {
        const thisItem = items.filter(item => item.deviceId == e.target.id);
        if(checked){
            if(checkMaxCount(isAll)){
                selectedDevicesRef.current.push(thisItem[0]);
            } 
        } else{
            selectedDevicesRef.current.splice(selectedDevicesRef.current.findIndex(device => device.deviceId == thisItem[0].deviceId), 1);
        }
        setData({...data});
    }

    const onDelete = deviceId => {
        selectedDevicesRef.current = selectedDevicesRef.current.filter(device => device.deviceId !== deviceId);
        setData({...data});
    };

    const checkMaxCount = (isAll = false) => {
        if(selectedDevicesRef.current.length >= MAX_COUNT){
            if(!isAll){
                toastr.error(t('MIS_SID_CBFEB_UNABLE_SELECT_ANYMORE') + '<br/>' + t('MIS_SID_MIXCBFEB_SOFTWARE_UPDATE_AVAILABLE_FOR_UP_ITEMS').replace('<<A>>', MAX_COUNT));
            } else {
                toastr.error(t('MIS_SID_MIXCBFEB_ONLY_ITEMS_SELECTED').replace('<<A>>', MAX_COUNT) + '<br/>' + t('MIS_SID_MIXCBFEB_SOFTWARE_UPDATE_AVAILABLE_FOR_UP_ITEMS').replace('<<A>>', MAX_COUNT));
            }
            
            return false;
        }
        return true;
    }

    const getTrProps = () => (state, rowInfo) => {
        if(rowInfo && rowInfo.row) {
            return {
                onClick: e => {
                    if(e.target.nodeName === 'INPUT' || e.target.nodeName === 'LABEL' || e.target.nodeName === 'BUTTON') {
                        return;
                    }
                    const thisDeviceId = items[rowInfo.index].deviceId;
                    let checkedStatus = selectedDevicesRef.current.filter(device => device.deviceId == thisDeviceId).length > 0?true:false;
                    onSelectDevice({target: {id: thisDeviceId}}, !checkedStatus, rowInfo.index);
                },
                className: items[rowInfo.index] && selectedDevicesRef.current.filter(device => device.deviceId == items[rowInfo.index].deviceId).length > 0 ? 'selected' : ''
            };
        }
        return {};
    };

    const activeMenu = (menu) => {
        setActive(menu);
        if(menu == 'ALL'){
            setFilter({page: 0, startIndex: 1, pageSize: 30, ...filterOptions});
        }
    };

    const fetchDevices = () => {
        setData({...data, loading: true});
        deviceService.fetchDeviceFilter(filter).then(res => {
            let items = res.items.filter(device => device.deviceType != 'SIG_CHILD');

            setData({...data, items: items, totalCount: res.totalCount, loading: false});
        }).catch(error => {
            console.log(error);
            setData({...data, loading: false});
        });
    }

    const onPageChange = page => {
        setFilter({...filter, page: page, startIndex: (page*filter.pageSize+1)});
    };

    const onChangeGroup = groupId => {
        if(groupId && groupId.length > 0){
            setFilter({...filter, page: 0, startIndex: 1, groupId : groupId[0]});
        }
    }

    const onSearchDevice = searchText => {
        setFilter({...filter, page: 0, startIndex: 1, searchText : searchText});
    }

    const onChangeShowAll = (e) => {
    
        if(e.target.checked){
            setFilter({page: 0, startIndex: 1, pageSize: 30, groupId: filter.groupId});
        } else {
            setFilter({page: 0, startIndex: 1, pageSize: 30, groupId: filter.groupId, ...filterOptions});
        }
        setShowAll(e.target.checked);
    }

    const renderNameCell = (data) => {
        return <DeviceNameCell 
                device={data.original} 
                showIcon={true}
                enableChild={false} 
                disableLink={false} />;      
    }

    const renderfirmwareVersion = (data) => {
        const firmwareData = data.original;
        /*
        const deviceType = firmwareData.deviceType.toUpperCase();
        if(deviceType == DEVICE_TYPES.iPLAYER.deviceType || 
            (deviceType == DEVICE_TYPES.WPLAYER.deviceType && !filterOptions.firmwareIndicators)){
                return (
                    <span title={firmwareData.playerVersion}>
                        {firmwareData.playerVersion};
                    </span>                    
                )
        }*/
        return (
            <span title={firmwareData.firmwareVersion}>
                {firmwareData.firmwareVersion};
            </span>
        )
    }

    const onChangeTooltip = (e, device) => {
        if(e.type === 'mouseover'){
            setTooltip({...tooltip, visible: true, top: e.pageY + 15,
                deviceName: device.deviceName,
                deviceId: device.deviceId,
                groupName: device.groupName
            });
            e.target.closest('.select_wrap').classList.add('hover');
        } else {
            setTooltip({...tooltip, visible: false});
            e.target.closest('.select_wrap').classList.remove('hover');
        }        
    }

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].deviceId}
                        classname="table"
                        name="check"
                        index={row.index}
                        onChange={onCheck}
                        checked={selectedDevicesRef.current.filter(device => device.deviceId == items[row.index].deviceId).length > 0}
                    />
                );
            }
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
            width: 150,
            Cell : renderNameCell
        },
        {
            Header: t('DID_ADMIN_LICENSE_MAC'),
            accessor: 'deviceId',
            width: 120
        },
        {
            Header: t('TABLE_IP_ADDR_P'),
            accessor: 'ipAddress',
            width: 95,
            Cell: data => <span style={{whiteSpace: 'normal',wordBreak:'break-all'}}>{data.value}</span>
        },
        {
            Header: t('TABLE_FIRMWARE_VERSION_P'),
            accessor: 'firmwareVersion',
            Cell: renderfirmwareVersion,
            width: 140,
        },
        {
            Header: t('MIS_SID_SERVER_PLAYER_VERSION'),
            accessor: 'playerVersion',
            Cell: data => <span title={data.value}>{data.value}</span>,
            width: 130,
            minWidth: 130
        }
    ], [items]);

    const getTrGroupPropsType = useCallback((state, rowInfo) => {
        if(rowInfo&& rowInfo.original) {
            if(selectedDevicesRef.current.filter(device => device.deviceId == rowInfo.original.deviceId).length > 0) {
                return {className: 'selected'};
            }
        }
        return {};
    },[items]);

    
    return (
        <MISDialog 
            dialog={{
                title: t('TEXT_PROM_STATISTICS_SELECT_DEVICE_P'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 1130,
                height: 520,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_ADD',
                        title: t('COM_BUTTON_ADD'),
                        onClick: addDevice
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='select_devices_popup' style={{ display: "inline-flex" }}>
                <div className="lnb">
                    <ul>
                        <li className={classNames({'on' : active === 'ALL'})} onClick={()=>activeMenu('ALL')}>
                            <a href="#" id="allBtn">{t("COM_SID_ALL")}</a>
                        </li>
                        <li className={classNames({'on' : active === 'BY_GROUP'}, 'group')} onClick={()=>activeMenu('BY_GROUP')}>
                            <a href="#" id="byGroupBtn">{t("MIS_SID_BY_GROUP")}</a>
                            {
                                active === 'BY_GROUP' && 
                                <DeviceGroupTree onSelect={onChangeGroup} filterOptions={{...filterOptions}} allEnabled={true}/>
                            }
                        </li>
                    </ul>
                </div>
                <div className="list_wrap">
                    <div className="mt10 mr10 mb10 ml20" style={{ height: 30 }}>
                        <div style={{ float: "left", display: "flex" }}>
                            <WhiteButton name={t('BUTTON_ALL_SELECT_P')} onClick={addAll} width={150}/>
                        </div>
                        <div style={{ float: "right", display: "flex" }}>
                            <Checkbox
                                id={'SHOW_ALL'}
                                name={t('COM_SID_ALL')}
                                onChange={onChangeShowAll}
                                checked={showAll}
                                style={{paddingTop: '5px'}}
                            />
                            <SearchBar
                                width={200}
                                onClickSearch={onSearchDevice}
                                placeholder={searchPlaceHolder}
                                toolTip={searchPlaceHolder}
                            />
                        </div>
                    </div>
                    <MagicInfoTable 
                        data={items}
                        noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                        minRows={0}
                        multiSort={false}
                        getTrGroupProps={getTrGroupPropsType}
                        getTrProps={getTrProps()}
                        columns={columns}
                        className="-striped -highlight"
                        style={{width: 690, height: (totalCount>0)? 400 : 450}}
                        usePagination={false}
                        loading={loading}
                        manual
                    />
                    {
                        totalCount > 0 &&
                        <Pagination
                        style={{top: 747, width: 680, borderTop: 0}}
                        totalCount={data.totalCount} page={filter.page} defaultPageSize={filter.pageSize} onPageChange={onPageChange} />
                    }
                </div>
                <div className="select_wrap">
                    <div className="select_text">
                        <div style={{float: 'left'}}>
                            <WhiteButton name={t('COM_IDS_3D_DELETE_ALL_FILE_V2_KR_ALL')} onClick={deleteAll} style={{marginTop:'-10px'}}/>
                        </div>
                        <div style={{float: 'right'}}>
                            <span>selected: {selectedDevicesRef.current.length + " / " + MAX_COUNT}</span> 
                        </div>
                        
                    </div>
                    <div style={{height: '450px', overflowY: 'auto'}}>
                    {
                        selectedDevicesRef.current &&
                        selectedDevicesRef.current.map(device => 
                            <>
                                <div className='selected_device_wrap'>
                                    <span onMouseOver={e => {onChangeTooltip(e, device)}} onMouseOut={e => {onChangeTooltip(e, device)}}>{device.deviceName} ({device.deviceId})</span><br/>
                                    <span class="delete_wrap"><button className="delete_button" onClick={()=>onDelete(device.deviceId)}></button></span>
                                </div>
                            </>)
                    }
                    </div>
                    {
                        tooltip.visible && 
                        <div className='tooltip' style={{position:'fixed', top: tooltip.top}}>
                            <span>{t("TABLE_DEVICE_NAME_P")} : {tooltip.deviceName}</span><br/>
                            <span>{t("COM_TABLE_DEVICE_ID_P")} : {tooltip.deviceId}</span><br/>
                            <span>{t("TEXT_GROUP_P")} : {tooltip.groupName}</span> 
                        </div>                        
                    }
                </div>
            </div>
            {
                !isNil(message) && <div style={{padding:'10px 0'}}>{t(message)}</div>
            }
        </MISDialog>
    );
};

export default SelectDevicesPopup;