import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {menuAction, popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import './UserPopup.css';
import classNames from "classnames";
import {Loading} from "../../loading/Loading";
import {userService} from "../../../services";
import LoadingOverlay from "../../loading/LoadingOverlay";

const RejectUnapprovedPopup = ({ onClose,userIds,initList}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        loading : false,
        reasonText: ""
    });

    const onTextAreaChange = reasonText => {
        setData({...data, reasonText});
    };

    const handleReject = () => {
        setData({...data, loading: true});
        userService.updateUnapprovedUsers({approvalStatus: 'REJECTED', rejectReason: data.reasonText, ids: userIds}).then(res => {
            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
        }).catch(error => {
            toastr.error(error);
        }).finally(() => {
            setData({...data, loading: false});
            dispatch(popupAction.closePopup('REJECT_USER'));
            dispatch(menuAction.updateSubMenuCounter('USER'));
            initList();
        });
    };

    return (
        <>

            {
                data.loading == true &&
                <LoadingOverlay active={data.loading}/>
            }

        <MISDialog
            dialog={{
                title: t('TEXT_REJECT_REASON_P'),
                closeOnEscape: true,
                modal: true,
                width: 410,
                height: 150,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('BUTTON_OK_P'),
                        onClick: handleReject,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div>
                <div className="notify_wrap ft13">{t('TEXT_REJECT_REASON_P')}</div>
                <div>
                    <textarea style={{width: '383px', radius: '3px', height: '82px'}}
                              className={classNames('mt20')}
                              placeholder={t('TEXT_REJECT_REASON_P')}
                              onChange={(e) => onTextAreaChange(e.target.value)}
                              maxLength={2000}>
                    </textarea>
                </div>
            </div>

        </MISDialog>
    </>
    );
};

export default RejectUnapprovedPopup;
