import {DEVICE_ACTIONS} from '../constants';

export const deviceAction = {
    loadLedCabinet,
    refreshDevices,
    softwareDownloading,
    customizeDownloading,
};

function loadLedCabinet(deviceId) {
    return {
        type: DEVICE_ACTIONS.LOAD_LEDCABINET,
        deviceId,
    };
}

function refreshDevices(deviceIds) {
    return {
        type: DEVICE_ACTIONS.REFRESH_DEVICES,
        deviceIds,
    };
}

function softwareDownloading(downloading) {
    return {
        type: DEVICE_ACTIONS.SOFTWARE_DOWNLOADING,
        downloading,
    };
}

function customizeDownloading(downloading) {
    return {
        type: DEVICE_ACTIONS.CUSTOMIZE_DOWNLOADING,
        downloading,
    };
}