import {authHeader, handleResponse} from '../helper';
import {fetchWithToken as fetch} from '../helper/fetchIntercept.js';


const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const ruleManagerService = {
    getRuleManagerServerInformation,
};

function getRuleManagerServerInformation(){

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(backendHost+'/restapi/v2.0/sms/system/rulemanager/systems', requestOptions).then(handleResponse);
}

