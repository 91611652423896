import React, {useRef} from 'react';
import './ContextMenu.css';
import {useOutsideClick} from "../../helper";
import {useTranslation} from "react-i18next";
import classNames from 'classnames';

const ContextMenu = ({type, groupCode, groupId, position, menu, close, zIndex = 9999, authority = true}) => {

    const GROUP_ID_ADMINISTRATORS = '0';
    const {t} = useTranslation();

    const ref = useRef();

    useOutsideClick(ref, ()=> {
        close();
    })

    return (
        <div style={{position: 'fixed', top:0, left:0, zIndex: zIndex}}>
            <div className={'group_context_menu'} style={{position: 'absolute', left: position.x, top: position.y}} ref={ref}>
                {
                    groupCode &&
                    <>
                        <div className="group_code disabled">
                            <a href="#" rel="0" style={{color: 'rgb(0, 0, 0)', cursor: 'default'}}>
                                <i className="group_info"></i>
                                <span>&nbsp;</span>
                                {`${t('COM_SID_SIGANGE_GROUP_CODE')} : ${groupCode}`}
                            </a>
                        </div>
                        {/* <div className="context_separator"><a href="#">&nbsp;</a></div> */}
                    </>
                }
                {
                    menu !== undefined && menu.length > 0 &&
                        menu.map(
                            (m, index) => {
                                let isDisable = false;
                                if(authority){
                                    isDisable = m.disabled;
                                } else {
                                    isDisable = true;
                                }

                                if (m.id === 'create_by_user_group' || m.id === 'rename_by_user_group' || m.id === 'delete_by_user_group') {
                                    if (groupId === GROUP_ID_ADMINISTRATORS) {
                                        isDisable = true;
                                    }
                                }

                                return (<div key={index} onClick={isDisable ? undefined : m.onClick} className={classNames('normal', {'disabled' : isDisable === true})}><a><i></i><span></span>{t(m.name)}</a></div>)
                            }
                        )
                }
            </div>
        </div>
    )
}
export default ContextMenu;