import {useTranslation} from "react-i18next";
import React from "react";
import NumberInput from "../../../input/NumberInput";
import EdgeDropDown from "./EdgeDropDown";
import * as moment from 'moment'

const EditSyncConfiguration = ({edgeInfo, setEdgeInfo, setEdgeInfo2}) => {
    const {t} = useTranslation();
    const backupOptions = [t('TEXT_ALL_P'), t('MIS_SID_FROM_NOW'), t('MIS_SID_ONLY_REQUESTED'), t('MIS_SID_FROM_ONE_MONTH_AGO')];
    const backupOptionsEnum = ['ALL', 'FROM_NOW','ONLY_REQUESTED','ONE_MONTH'];

    const deleteOptions = [t('DID_SCHEDULE_SINGLE_ONCE_A_DAY'),t('COM_TEXT_IMMEDIATELY_P')];
    const deleteOptionsEnum = ['ONCE_A_DAY','IMMEDIATE'];

    const translateValue = (valueToChange, from, to) =>{
        let indexOf = from.indexOf(valueToChange);
        return to[indexOf];
    };

    const renderDate = (input) => {
        return t('MIS_SID_MIX_FROM').replace('<<A>>', moment(new Date(input)).format('YYYY-MM-DD HH:mm'));
    };

    const updateEdgeInfo = (e, v) => {
        let key = e.target.name;
        let value;

        if(key === ''){
            key = e.target.getAttribute('data-name');
            value = v?parseInt(v):e.target.value;
            value = isNaN(value)?'':value;
        }
        if(e.target.name === 'deleteCondition'){
            value = translateValue(e.target.value, deleteOptions, deleteOptionsEnum);
        }
        if(e.target.name === 'syncCondition'){
            value = translateValue(e.target.value, backupOptions, backupOptionsEnum);
            setSyncCondition(key, value);
            return;
        }
        setEdgeInfo(key,value);
    };

    const setSyncCondition = (key, value) => {
        let fromDate;
        if (value === 'ONE_MONTH') {
            fromDate = moment().subtract(1,'months').calendar();
            fromDate = new Date(fromDate).getTime();
        } else if (value === 'ALL') {
            fromDate = new Date(0).getTime();
        } else if (value === 'ONLY_REQUESTED') {
            fromDate = new Date(0).getTime();
        } else {
            fromDate = new Date().getTime();
        }

        setEdgeInfo2(key, value, 'initialSyncTime', fromDate);
    };

    return(
        <div className='detail_view'>
            <table className='edge_table'>
                <colgroup>
                    <col width='123px'/>
                </colgroup>
                <tbody className='tbody'>
                <tr>
                    <th>{t('COM_SETUP_ID_STRING5_P')}</th>
                    <td><EdgeDropDown
                        width={203}
                        height={backupOptions.length}
                        disabled={!edgeInfo.master}
                        name="syncCondition"
                        display={translateValue(edgeInfo.syncCondition, backupOptionsEnum, backupOptions)}
                        options={backupOptions}
                        onSelect={updateEdgeInfo}/>
                        {
                            edgeInfo.syncCondition !== 'ONLY_REQUESTED' &&
                            <span style={{margin:'5px 0 0 12px'}}>
                                {renderDate(edgeInfo.initialSyncTime)}
                            </span>
                        }
                    </td>
                </tr>
                <tr>
                    <th>{t('COM_TEXT_PERIOD2_P')}</th>
                    <td>
                        <NumberInput propertyName={'syncPeriod'} width={203} min={1} max={60}
                                     value={edgeInfo.syncPeriod}
                                     disabled={!edgeInfo.master}
                                     onChange={(e, value)=>{updateEdgeInfo(e, value)}} />
                        <span style={{margin:"5px 0 0 12px"}}>{t('MIS_SID_20_MINUTE_S')}</span>
                    </td>
                </tr>
                <tr>
                    <th>{t('COM_BUTTON_DELETE')}</th>
                    <td><EdgeDropDown
                        width={203}
                        height={deleteOptions.length}
                        disabled={!edgeInfo.master}
                        name="deleteCondition"
                        display={translateValue(edgeInfo.deleteCondition,deleteOptionsEnum,deleteOptions)}
                        onSelect={updateEdgeInfo}
                        options={deleteOptions}/></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
};

export default EditSyncConfiguration;