import React, {useEffect, useState} from 'react';
import {ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY, ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY} from "../../../../constants";
import Moment from "moment";
import {useTranslation} from "react-i18next";

const TimeHeader = ({view, startTime, endTime, maxWidth, left=0}) => {

    const {t} = useTranslation();
    const now = Moment(0, 'HH');
    const time  = Moment(startTime);
    const timeLineEndTime = Moment(endTime);

    const [timeHeaders, setTimeHeaders] = useState([]);
    const [timeWidth, setTimeWidth] = useState(0);

    const getWeekDay = (day) => {
        switch (day) {
            case 0 : return t('COM_TEXT_DAY_SUNDAY_P');
            case 1 : return t('COM_TEXT_DAY_MONDAY_P');
            case 2 : return t('COM_TEXT_DAY_TUESDAY_P');
            case 3 : return t('COM_TEXT_DAY_WEDNESDAY_P');
            case 4 : return t('COM_TEXT_DAY_THURSDAY_P');
            case 5 : return t('COM_TEXT_DAY_FRIDAY_P');
            case 6 : return t('COM_TEXT_DAY_SATURDAY_P');
        }
    }

    useEffect(()=> {

        const timeHeaders = [];
        let count = 0;
        while(time.isBefore(timeLineEndTime)) {
            timeHeaders.push({date: time.format('DD'), day: getWeekDay(time.weekday()), isToday: time.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ? true: false})
            time.add(1, 'days');
            count++;
        }

        let timeWidth = maxWidth/ count;
        if (view === ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY) {
            if (timeWidth < 120) {
                timeWidth = 120;
            }
        }
        setTimeWidth(timeWidth);
        setTimeHeaders(timeHeaders)

    }, [startTime, endTime])

    return (
        <div className="sc_header_scroll" style={{left: left}}>
            {
                timeHeaders !== undefined && timeHeaders.length > 0 && timeHeaders.map(
                    timeHeader => <div className={'sc_time'} style={{width: timeWidth}}><span><span className={timeHeader.isToday ? 'today' : ''}>{timeHeader.date}</span></span>{timeHeader.day}</div>
                )
            }
        </div>

    )
}
export default TimeHeader;