import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import moment from 'moment';
import {popupAction} from "../../../../actions";
import {commonConstants, SOFTWARE_TYPES, DEVICE_TYPES} from '../../../../constants';
import {deviceOptions, useMISOpt} from '../../../misopt'
import RadioGroup from '../../../radio/RadioGroup';
import Select from '../../../selectbox/Select';
import {deviceService} from '../../../../services';
import DateInput from '../../../input/DateInput';
import WhiteButton from '../../../button/WhiteButton';
import {dateToString, toMomentDateTimeFormat, toMomentTimeFormat} from '../../../../helper';
import './PublishSoftwarePopup.css';
import TimeSelect from '../../../selectbox/TimeSelect';
import PublishGroup from '../../../device/PublishGroup';
import {getMixString} from "../../../../language/languageUtils";
import Moment from "moment";

const PublishSoftwarePopup = ({softwareId, onPublish, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}, deviceTypes}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const [selectedDevices, setSelectedDevices] = useState([]);

    const now = new Date();
    const [software, setSoftware] = useState({
        publishType: 'NOW',
        appliedType: 'DEVICE',
        rsvDate: now,
        rsvTime: moment(),
        selectedDeviceIds: []
    });

    const onDelete = deviceId => {
        const newGroups = selectedDevices.filter(device => device.deviceId !== deviceId);
        setSelectedDevices(newGroups);
    };

    const onClickPublish = () => {
        const {groups, rsvDate, rsvTime} = software;

        if(selectedDevices.length == 0){
            toastr.error(t('MESSAGE_DEVICE_SELECT_DEVICE_P'));
            return;
        }

        if( software.publishType !== 'NOW') {
            if(isNil(rsvDate) || rsvDate === null) {
                toastr.error(t('MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P'));
                return;
            }

            if(isNil(rsvTime) || rsvTime === null) {
                toastr.error(t('MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P'));
                return;
            }
        }

        const rsvDateTime = moment(rsvDate);
        rsvDateTime.hour(rsvTime.hour());
        rsvDateTime.minute(rsvTime.minute());

        let deviceIds = selectedDevices.map(device => device.deviceId);

        deviceService.publishSoftware({
            softwareId: software.softwareId,
            appliedType: software.appliedType,
            deployAppliedVer:"VERSION_ALL",
            rsvDate: software.publishType === 'NOW' ? 'NOW' : rsvDateTime.valueOf(),
            deviceIds: deviceIds
        }).then(res => {
            toastr.success(t('MESSAGE_DEVICE_SOFTWARE_FILE_DEPLOY_SUCCESS_P'));
            if(onPublish) {
                onPublish();
            }
        }).catch(error => {
            if(error.errorCode === '400633') {
                const currentTime = ( !isNil(error.errorDetails) &&  !isNil(error.errorDetails.timestamp) ) ? Moment(error.errorDetails.timestamp).format('HH:mm') : "";
                const timeZone = ( !isNil(error.errorDetails) &&  !isNil(error.errorDetails.timeZone) ) ? error.errorDetails.timeZone : "";
                toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN').replace('<<A>>', t('MIS_ID_PUBLISH_TIME')) + '</br>' +t("MIS_SID_MIX_CAJUL_CURRNT_TIME_GMT").replace("<<A>>", timeZone).replace("<<B>>", currentTime));
            } else if(error.errorCode === '500633') {
                toastr.error(t('MIS_SID_ALREADY_APPLIED_FIRWMARE')); //+' : '+json.errorMessage.ALREADY_FIRMWARE
            } else if(error.errorCode === '500634') {
                toastr.error(t('MIS_SID_SERVER_DISCONNECTED_DEVICE')); //+' : '+json.errorMessage.OFFLINE_DEVICE);
            } else {
                toastr.error(t('MESSAGE_DEVICE_SOFTWARE_FILE_DEPLOY_FAIL_P'));
            }
            onClose && onClose();
        });
    };

    const onSelectDevice = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.SELECT_DEVICES,
            type: commonConstants.SELECT_DEVICES,
            onSelect: (devices) => {
                setSelectedDevices(devices);
                dispatch(popupAction.closePopup(commonConstants.SELECT_DEVICES));
            }, 
            message: 'MIS_SID_CBFEB_ONLY_DEVICES_MATCH_FIRMWARE_DISPLAYED',
            onClose: () => dispatch(popupAction.closePopup(commonConstants.SELECT_DEVICES)),
            filterOptions : getFilterOptions(),
            selectedItems: [...selectedDevices]
        }));
    }
    
    const getFilterOptions = () => {
        let filter = {};
        const deviceType = software.deviceType.toUpperCase();

        const splayerDevices = deviceTypes.filter(device => (device.deviceType.toUpperCase() == deviceType || device.deviceType.toUpperCase() == 'R' + deviceType));
        let filterDeviceTypes = splayerDevices.map(device => device.deviceFullType);

        if(deviceType === DEVICE_TYPES.iPLAYER.deviceType 
            || (deviceType === DEVICE_TYPES.WPLAYER.deviceType && software.softwareType === SOFTWARE_TYPES.WEBAPP.value)
            || (deviceType === DEVICE_TYPES.KIOSK.deviceType && software.softwareType === SOFTWARE_TYPES.WEBAPP.value)){
            filter = {deviceType: filterDeviceTypes};
        } else {
            filter = {firmwareIndicators: software.firmwareIndicators, deviceType: filterDeviceTypes};
        }
        
        // i/W(webapp)/KIOSK(webapp) - deviceType, others - indicator + deviceType
        return filter;
    }

    useEffect(() => {
        deviceService.fetchSoftwareForPublish(softwareId).then(res => {
            setSoftware({
                ...res.items,
                publishType: 'NOW',
                appliedType: 'DEVICE',
                rsvDate: now,
                rsvTime: moment()
            });
        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_BDP_SID_ASC_POPUP_LIST_TEXT_PREPARING_KR_DOT') + t('COM_PLEASE_WAIT_A_MOMENT_AND_TRY_AGAIN'));
            onClose && onClose();
        }).finally(() => {

        });
    }, [softwareId]);

    return (
        <div className="publish_software_popup">
            <MISDialog 
                dialog={{
                    title: t('MIS_SID_PUBLISH'),
                    modal: true,
                    isDraggable: false,
                    closeOnEscape: true,
                    width: 700,
                   // height: 480,
                    // position: {x: -380, y: -350},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'MIS_SID_PUBLISH',
                            title: t('MIS_SID_PUBLISH'),
                            onClick: onClickPublish,
                            // disable: !isEmpty(error)
                        },
                        {
                            id: 'BUTTON_CANCEL_P',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='devicepop'>
                    <div className="detail_view"  style={{paddingBottom: '15px'}}>
                        <table>
                            <colgroup>
                                <col width="140px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                                    <td>{software.deviceType}</td>
                                </tr>
                                <tr>
                                    <th>{t('COM_TEXT_SOFTWARE_NAME_P')}</th>
                                    <td>{software.softwareName}</td>
                                </tr>
                                {
                                    software.softwareType && software.softwareType !== '' &&
                                    <tr>
                                        <th>{t('TEXT_SOFTWARE_TYPE_P')}</th>
                                        <td>{software.softwareType === SOFTWARE_TYPES.WEBAPP.value ? t('MIS_SID_WEB_APP') : ''}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>{t('TABLE_SOFTWARE_VERSION_P')}</th>
                                    <td>{software.softwareVersion}</td>
                                </tr>  
                                {
                                    software.deviceModelNameList && software.deviceModelNameList.length > 0 && 
                                    <tr>
                                        <th>{t('LIST_TITLE_APPLIED_MODEL')}</th>
                                        <td title={software.deviceModelNameList.join(", ")}>
                                            <div className={"text_ellipsis"} style={{width: '500px'}}>{software.deviceModelNameList.join(", ")}</div>
                                        </td>
                                    </tr>  
                                }                              
                            </tbody>
                        </table>
                    </div>
                    <div className="detail_view t_line">
                        <table>
                            <colgroup>
                                <col width="140px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('MIS_ID_PUBLISH_TIME')}</th>
                                    <td>
                                        <RadioGroup selects={deviceOptions.swPublishOptions} value={software.publishType} onChange={(e, value) => setSoftware({...software, publishType: value})} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('COM_ID_SCHEDULE_SCHEDULE_PUBLISH')}</th>
                                    <td>
                                        <span className='mr8'>
                                            <DateInput width={120} minDate={now} dateFormat={dateFormat} date={dateToString(software.rsvDate)} disabled={software.publishType !== 'RESERVED'} onChange={value => setSoftware({...software, rsvDate: value})} />
                                        </span>
                                        <span>
                                            <TimeSelect style={{width: 120}} format={toMomentTimeFormat(timeFormat)} disabled={software.publishType !== 'RESERVED'} value={software.rsvTime} showSecond={false} onChange={value => setSoftware({...software, rsvTime: value})} />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('MIS_SID_TARGET_DEVICE')}</th>
                                    <td>
                                        <WhiteButton name={t('COM_BUTTON_ADD')} onClick={onSelectDevice}/>
                                        <span style={{paddingLeft: '12px'}}>{getMixString(["MIS_SID_WEBAUTHOR_MIX_SELECTED_ITEMS", selectedDevices.length])}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>
                                        <div className='group_container' style={{height:'150px'}}>
                                            {
                                                selectedDevices &&
                                                selectedDevices.map(device => 
                                                    <>
                                                        <div className='sw_publish_group_wrap'>
                                                            <span>{device.deviceName} ({device.deviceId})</span>
                                                            <span><button className="delete_button" onClick={()=>onDelete(device.deviceId)}></button></span>
                                                        </div>
                                                    </>)
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default PublishSoftwarePopup;