import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {deviceService} from '../../services';
import {getPageSize, useFilter} from "../../helper";
import {isNil, snakeCase} from "lodash";
import {SETTING_PAGE_SIZE_OPTIONS, settingConstants} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import {connect} from "react-redux";
import {popupAction} from "../../actions";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {DeviceGroups} from "../../helper/schedule/scheduleUtils";

const ServiceHistory = (props) => {
    const {t} = useTranslation();
    const {addPopup, closePopup} = props;
    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        page: 0,
        pageSize: getPageSize('setting', SETTING_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [{id: 'deviceId', desc: false}],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0
    });

    const [style, setStyle] = useState({
        height: '500px'
    });
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;

    useEffect(() => {
        fetchData();
    }, [filter]);

    const onSearch = (value) => {
        setFilter({...filter, searchText: value, page: 0});
    }

    const fetchData = () => {
        setData({
            ...data,
            loading: true
        });
        fetchDataServiceHistory();
    };

    const fetchDataServiceHistory = () => {
        const {page, pageSize, sorted: [{id, desc}], organId, searchText} = filter;
        setData({...data, loading: true});
        deviceService.fetchDeviceFilter({
            startIndex: page * pageSize + 1,
            pageSize,
            groupType: 'ALL',
            organId: organId,
            sortColumn: snakeCase(id),
            sortOrder: desc === true ? 'desc' : 'asc',
            searchText: searchText,
        }).then(res => {
            setData({
                ...data,
                loading: false,
                items: res.items,
                totalCount: res.totalCount,
                pages: Math.ceil(res.totalCount / pageSize)
            });
        });
    }

    useEffect(() => {
        setStyle({height: window.innerHeight - 202});
    }, []);

    const openPopup = (deviceId) => {
        addPopup({
            id: settingConstants.SERVICE_HISTORY_POPUP,
            type: settingConstants.SERVICE_HISTORY_POPUP,
            deviceId: deviceId,
            onClose: () => closePopup(settingConstants.SERVICE_HISTORY_POPUP)
        });
    }

    const columns = [
        {
            accessor: "deviceId",
            show: false
        },
        {
            Header: t("TABLE_MAC_ADDR_P"),
            accessor: "deviceId",
            width: 200
        },
        {
            Header: t("TABLE_DEVICE_NAME_P"),
            accessor: "deviceName",
            width: 350
        },
        {
            Header: t("TABLE_IP_ADDR_P"),
            accessor: "ipAddress",
            width: 250,
            sortable: false
        },
        {
            Header: t("TABLE_DEVICE_MODEL_NAME_P"),
            accessor: "deviceModelName",
            width: 200,
            sortable: false
        },
        {
            Header: t("TABLE_FIRMWARE_VERSION_P"),
            accessor: "firmwareVersion",
            Cell: ({value}) => {
                if (isNil(value)) {
                    return '';
                }
                let arr = value.split(";");
                let deviceGroups = [];
                arr.map((val, i) => {
                    let device = {groupId: i, groupName: val}
                    deviceGroups.push(device);
                });
                return <DeviceGroups deviceGroups={deviceGroups} width={200}/>
            } ,
            width: 200,
            sortable: false
        },
        {
            Header: t("BUTTON_VIEW_P"),
            accessor: "detailView",
            Cell: (props) => <WhiteButton id={props.original.deviceId} width={'40px'} name={t("BUTTON_VIEW_P")}
                                          onClick={() => openPopup(props.original.deviceId)}/>,
            sortable: false
        }
    ];

    return (
        <div style={{width: '100%', display: props.currContent === 'SERVICE_HISTORY' ? 'block' : 'none'}}
             className={'service_history'}>
            <div className="contents_buttonWrap">
                <div className="rightButton">
                    <SearchBar id="deviceSearch"
                               placeholder={t("TABLE_MAC_ADDR_P") + "," + t("TABLE_DEVICE_NAME_P") + "," + t("TABLE_IP_ADDR_P") + "," + t("TABLE_DEVICE_MODEL_NAME_P")}
                               onClickSearch={onSearch}/>
                </div>
            </div>
            <div className={"device_list_view_wrap"} style={{width: '100%'}}>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    onSortedChange={onSortedChange}
                    columns={columns}
                    style={style}
                    usePagination={true}
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        pageSize: pageSize,
                        pageSizeOptions: SETTING_PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: "setting"
                    }}
                />
            </div>
        </div>
    );
}
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(ServiceHistory);
