import React, { Component } from 'react';
import store from '../../store';
import RuleTree from "./RuleTree";
import { withTranslation } from "react-i18next";
import { getRuleTreeDefaultName } from '../../constants';
import { Menu, Item, contextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import './RuleArea.css';
import { rulesetAction, popupAction } from '../../actions';
import { commonConstants } from '../../constants';
import Size from "../utils/Size";

class RuleArea extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ruleTreeName : this.props.rule.rule_name,
            showNoResultInfo: false
        };
    }

    onOptionMenuItemClick(id) {
        switch(id) {
            case 'RENAME':
                this.rename();
                break;
            case 'DELETE':
                this.deleteRule();
                break;
            case 'DUPLICATE':
                this.duplicate();
                break;
        }
    }

    rename() {
        const {t} = this.props;

        store.dispatch(
            popupAction.addPopup({
                id: commonConstants.RULESET_RULETREE_OPTION_POPUP,
                type: commonConstants.RULESET_RULETREE_OPTION_POPUP,
                title: t('BUTTON_CHANGE_NAME_P'),
                ruleTreeName: this.ruleName,
                onSave: (name) => {
                    this.props.rule.rule_name = name;
                    this.setState({ruleTreeName: name});
                }
            })
        );
    }

    deleteRule() {
        this.props.deleteRuleFunc(this.props.rule.rule_id);
    }

    duplicate() {
        const {t, mode, rule} = this.props;

        store.dispatch(
            popupAction.addPopup({
                id: commonConstants.RULESET_RULETREE_OPTION_POPUP,
                type: commonConstants.RULESET_RULETREE_OPTION_POPUP,
                title: t('MIS_SID_DUPLICATE'),
                ruleTreeName: this.ruleName,
                onSave: (name) => {
                    store.dispatch(rulesetAction.duplicate(mode, rule.rule_id, name));
                }
            })
        );
    }

    showMenu(e, menuId) {
        e.preventDefault();
        contextMenu.show({
            id: menuId,
            event: e,
        });
    }

    setVisibleRuleTree(ruleId) {
        const style = document.getElementById('rulesetTree_' + ruleId).style;
        if(style !== undefined && style.display !== undefined && style.display === "none") {
            document.getElementById('rulesetTree_' + ruleId).style.display = "block";
        }
        else {
            document.getElementById('rulesetTree_' + ruleId).style.display = "none";
        }
        
    }

    render() {
        const {t, verificationModeEnabled, mode} = this.props;
        const {showNoResultInfo} = this.state;
        const conditionMap = this.props.conditionMap;
        const resultMap = this.props.resultMap;
        const contentMap = this.props.contentMap;
        const rule = this.props.rule;
        this.ruleName = rule.rule_name ? rule.rule_name : getRuleTreeDefaultName(rule.rule_id);
        const optionMenuId = "ruleOption_" + rule.rule_id;

        return(
            <div id={"rule_area_" + rule.rule_id} className="rule_area" rule_id={rule.rule_id}>
                <div className="ruleset_rule_title">
                    <div
                        className="no_result_icon"
                        onMouseEnter={(e) => {
                            try {
                                if(e.target.parentNode.parentNode.classList.contains('no-result')) {
                                    this.setState({showNoResultInfo: true});
                                }
                            } catch(ex) {
                            }
                        }}
                        onMouseLeave={() => this.setState({showNoResultInfo: false})}
                    />
                    <div className="opacity_notice_wrap" style={{display: showNoResultInfo ? '' : 'none'}}>
                        <table>
                            <colgroup>
                                <col width="119px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr><td>{t("MIS_SID_AN_INCOMPLETE_ITEM_EXISTS")}</td></tr>
                            </tbody>
                        </table>
                    </div>
                    { !verificationModeEnabled &&
                        <span>
                            {this.ruleName}
                        </span>
                    }
                    { verificationModeEnabled &&
                        <span>{this.ruleName}{'\u00A0\u00A0\u00A0\u00A0'}({t("MIS_SID_TOTAL_SIZE")}: <Size size={rule.totalSize}/>)</span>
                    }
                    <FoldButton ruleId={rule.rule_id}/>
                    <button className="option_btn" onClick={(e) => this.showMenu(e, optionMenuId)} />
                    <Menu id={optionMenuId} style={{zIndex:20}}>
                        <Item onClick={() => this.onOptionMenuItemClick('RENAME')}>{t('BUTTON_CHANGE_NAME_P')}</Item>
                        <Item onClick={() => this.onOptionMenuItemClick('DELETE')}>{t('COM_BUTTON_DELETE')}</Item>
                        <Item onClick={() => this.onOptionMenuItemClick('DUPLICATE')}>{t('MIS_SID_DUPLICATE')}</Item>
                    </Menu>
                </div>
                <RuleTree
                    mode={mode}
                    conditionMap={conditionMap}
                    resultMap={resultMap}
                    contentMap={contentMap}
                    rule={rule}
                    entryIdIndex={mode === "NEW" ? 0 : 10000} />
            </div>
        )
    }
}

class FoldButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fold: false
        };
    }

    toggle(e, ruleId) {
        const style = document.getElementById('rulesetTree_' + ruleId).style;
        if(style !== undefined && style.display !== undefined && style.display === "none") {
            document.getElementById('rulesetTree_' + ruleId).style.display = "block";
        }
        else {
            document.getElementById('rulesetTree_' + ruleId).style.display = "none";
        }

        this.setState({
            fold: !this.state.fold
        });

        if(this.state.fold) {
            store.dispatch(rulesetAction.redraw());
        }
    }

    render() {
        const ruleId = this.props.ruleId;
        const fold = this.state.fold;
        return <button className={ fold ? "fold_btn fold" : "fold_btn"} onClick={(e) => this.toggle(e, ruleId)}/>
    }
}

export default withTranslation()(RuleArea);