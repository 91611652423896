import React, {useState} from 'react';
import MISDialog from "../MISDialog";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import {commonConstants} from "../../../constants";
import ReactPlayer from 'react-player'
import {Loading} from "../../loading/Loading";

const TutorialMoviePopup = ({url}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [ready, setReady] = useState(false);

    const closePopup = () => {
        dispatch(popupAction.closePopup(commonConstants.TUTORIAL_MOVIE_POPUP))
    }

    const onReady = () => {
        setReady(true);
    }

    const dialogProps ={title: t("COM_TV_SID_TUTORIAL"), closeOnEscape : true, modal: true, onClose:()=> closePopup()};

    return (
        <MISDialog
            dialog={dialogProps}
            width={640}
            height={350}
            buttons={{
                rightButtons: [
                    {id: 'LOGOUT_BUTTON', title: t("COM_BUTTON_CLOSE_P"), onClick: ()=>closePopup()}]
            }}
        >

            <div style={{background:'#e6ebf0', position: 'fixed', display: !ready ? 'flex':'none', justifyContent: 'center', height: 350, width: 640, lineHeight: '350px', }}>
                {
                    <div style={{display: 'fixed'}}><Loading size={15}/></div>
                }
            </div>

            <div style={{background:'#e6ebf0', position: 'fixed', height: 350, lineHeight: '350px', display: ready ? '':'none'}}>
            {
                url !== undefined && url !== '' &&
                    <ReactPlayer url={url} preload
                                 onReady={onReady} />
            }
            </div>
        </MISDialog>
    )
}
export default TutorialMoviePopup;