import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import MISDialog from "../../MISDialog";
import {settingService} from "../../../../services";
import Select from "../../../selectbox/Select";
import RadioGroup from "../../../radio/RadioGroup";
import TextInput from "../../../input/TextInput";
import {commonConstants, settingConstants} from "../../../../constants";
import Checkbox from "../../../checkbox/Checkbox";
import {SearchButton} from "../../../button/SearchButton";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../../actions";
import {useDispatch} from "react-redux";
import {userValidator} from "../../../../helper/userValidator";
import {getErrorMessage} from "../../../../helper/responseHandler";

const FreeLicensePopup = ({onClose, type, title, hwUniqueKey}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [internetType, setInternetType] = useState(true);

    const companyNameRef = useRef();
    const divisionRef = useRef();
    const addressRef = useRef();
    const eMailRef = useRef();
    const phoneNumRef = useRef();


    const akFileInput = useRef();
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [showDesc, setShowDesc] = useState(false);

    const [freeLicOption, setFreeLicOption] = useState([
        { value:'01015A-L6VPSS-MOE2JC-GRZ5', title: 'Unified Player License 2 (I, S, Signage)' },
        { value:'01064A-RR6UU4-XE2GE5-JLCW', title: 'RM Player'},
        //{ value:'01011N-FRDGVS-NCRI4K-T6OS', title: 'Android'},
        { value:'010311-6YPNK6-KC3EAO-XHEL', title: 'Lite'}
    ]);

    const [dialogHeight, setDialogHeight] = useState('400px');

    const [productType, setProductType] = useState('');

    const changeProductType =(value) => {
        setProductType(value)
        const tempLicKey = value.split('-');
        setLicKey({...licKey, key0: tempLicKey[0], key1: tempLicKey[1], key2: tempLicKey[2], key3: tempLicKey[3] });
    };

    const showDescription = (e) => {
        setShowDesc(!showDesc);
    }

    const internetOption = [
        {value: true, title: t('COM_MIS_TEXT_CONNECTED_P')},
        {value: false, title: t('MIS_TEXT_DISCONNECTED_P')}
    ];
    const onChangeValue= (e, value) =>{
        setInternetType(value);
        if(value==false) {
            setDialogHeight('300px');
        }else {
            setDialogHeight('400px');
        }
    }

    const [licKey, setLicKey] = useState({
        key0: '',
        key1: '',
        key2: '',
        key3: ''
    });
    const [company,setCompany] = useState({
        companyName: '',
        division: '',
        address: '',
        phoneNumber: '',
        email: ''
    });

    const isAgreeRef = useRef();

    const onClickFile = () => {
        akFileInput.current.click();
    };

    const setFileInformation = (event) => {
        if(event.target.files[0] !== undefined) {
            setFileName(event.target.files[0].name);
            setFile(event.target.files[0])
        }
    };

    useEffect(() => {
        fetchFreeLicense();
    }, []);

    const fetchFreeLicense = () => {
        settingService.fetchAvailableFreeLicense()
            .then(res => {
                    const filtered =[];
                    res.items.filter( item => {
                            const temp = freeLicOption.find(list => list.value == item);
                            temp && filtered.push(temp);
                        }
                    );
                    setFreeLicOption(filtered);
                }
            ).catch(
            err => {
                dispatch(popupAction.closePopup(settingConstants.FREE_LICENSE_POPUP));
                toastr.error(getErrorMessage(err));
            }
        )
    }


    const onSave = (internetType) => {
        if (productType == '') {
            toastr.error(t('COM_MIS_TEXT_PLEASE_SELECT_THE_LICENSE_KEY_P'));
            return;
        }

        if(internetType) {
            if(companyNameRef.current.value == '' || divisionRef.current.value == '' || addressRef.current.value == '' || phoneNumRef.current.value == '' || eMailRef.current.value == ''){
                toastr.error(t('COM_MIS_TEXT_YOU_MUST_FILL_IN_ALL_OF_THE_FIELDS_P'));
                return;
            }

            const {isValid, messageId} = userValidator('email', eMailRef.current.value);

            if (!isValid) {
                toastr.error(t(messageId));
                return;
            }

            if(!isAgreeRef.current.checked){
                toastr.error(t('COM_MIS_TEXT_YOU_NEED_TO_AGREE_TO_PROVIDE_INFORMATION_TOPROCEED_P'));
                return;
            }

            saveFreeLicenseOnline(productType, company);

        }else{
            saveFreeActivationOffline(productType,file);
        }

    }

    const saveFreeLicenseOnline = (licenseKey, company) => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        settingService.freeActivationOnline(licenseKey, company).then(
            res => {
                toastr.success(t('COM_MIS_TEXT_ACTIVATION_HAS_BEEN_COMPLETED_P'));
                showReLoginPopup();
            }
        ).catch(
            err => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            dispatch(popupAction.closePopup(settingConstants.FREE_LICENSE_POPUP));
            dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP));
        })
    }

    const saveFreeActivationOffline = (licenseKey, file) => {
        let formData = new FormData();
        formData.append("file", file);
        settingService.freeActivationOffline(licenseKey, formData).then(
            res => {
                toastr.success(t('COM_MIS_TEXT_ACTIVATION_HAS_BEEN_COMPLETED_P'));
                showReLoginPopup();
            }
        ).catch(
            err => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(settingConstants.FREE_LICENSE_POPUP));
        })
    }

    const showReLoginPopup = () => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_ALERT_POPUP,
            id: commonConstants.COMMON_ALERT_POPUP,
            title: t("COM_TEXT_INFORMATION_P"),
            message: t("MIS_MESSAGE_COMMON_LOGOUT_SETTING_CHANGE_P"),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP))
        }));
    };

    const errorCodeToErrMsg = (errorCode, errorMessage) => {
        switch (errorCode) {
            case '400803' :
                return 'Communicating with SLM server is not available now. Please check the network. Please try using "Offline Activation".';
            case '400804' :
                return 'The license of the same type can be used only once. You need go to the SLM site to build up using the "Extension" menu. Please merge the license keys.';
            case '400805' :
                return 'The error is because the SSL communication is not available. Please check whether the SSL 443 port is blocked. Please try using "Offline Activation".';
            case '400806' :
                return 'The license key is being used in another PC. Please carry out "Return license" on the PC that was previously in use, and then check whether the license has been returned to the SLM server.';
            case '400807' :
                return 'The License Key that you input is wrong. Please input a correct value.';
            case '400808' :
                return t('COM_MIS_TEXT_LICENSE_IS_BEING_USED_P');
            case '400809' :
                return t('MIS_TEXT_LICENSE_KEY_FOR_THE_SELECTED_PRODUCT_IS_NOT_P');
            case '400813' :
                return t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P');
            case '400814' :
                return t('MIS_TEXT_FREE_LICENSE_COULD_NOT_BE_DELETED_P');
            case '400815' :
                return t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P');
            case '400817' :
                return t('COM_MIS_TEXT_HW_UNIQUE_KEY_IS_INVALID_P');
            case '400818' :
                return t('MIS_TEXT_FREE_LICENSE_COULD_NOT_BE_ADDED_P');
            case '400819' :
                return 'Failed to delete a license. (Deactivation key is invalid)';
            case '500810' :
                return 'SLM DB ERROR';
            case '400816':
                return errorMessage;
            default :
                return t('COM_MIS_TEXT_ERROR_SLM_LICENSE_SERVER_P');
        }
    }


    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 720,
                    height: dialogHeight,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_ONLINE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: () => onSave(internetType),
                            disable: false,
                        },
                        {
                            id: 'ACTIVATION_ONLINE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>

                <div className='detail_view mini_p '>
                    <table>
                        <colgroup>
                            <col width="40%"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>

                        <tr>
                            <th>{t('TEXT_VENDING_VM_CONTROL_PRODUCT_P')}</th>
                            <td>

                                    <Select selects={freeLicOption} width={340} value={productType}
                                            onChange={(e, value) => changeProductType(value)} defaultTitle ={t('TEXT_SELECT_P')} multiLang={ false}/>
                            </td>
                        </tr>

                        <tr>
                            <th>{t('COM_MIS_TEXT_INTERNET_CONNECTION_P')}</th>
                            <td>
                                <RadioGroup propertyName='internetType' selects={internetOption} onChange={(e, value) => onChangeValue(e, value)} value={internetType} multiLang={false} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_LICENSE_KEY_P')}</th>
                            <td>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key0} disabled = {true}/>
                                <span className="space float_l mr8">-</span>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key1} disabled = {true}/>
                                <span className="space float_l mr8">-</span>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key2} disabled = {true}/>
                                <span className="space float_l mr8">-</span>
                                <TextInput wrapperStyle={{float: 'left', marginRight: 8}} width={80} value={licKey.key3} disabled = {true}/>
                            </td>
                        </tr>
                        {
                            internetType == true &&
                            <tr>
                                <th>{t('COM_MIS_TEXT_COMPANY_NAME_P')}</th>
                                <td>
                                    <TextInput width={220} value={company.companyName} ref={companyNameRef}
                                               onChange={(e) => setCompany({...company, companyName: e.target.value})}/>
                                </td>
                            </tr>
                        }
                        {
                            internetType == true &&
                                    <tr>
                                        <th>{t('MIS_TEXT_DIVISION_P')}</th>
                                        <td>
                                            <TextInput width={220} value={company.division} onChange={(e) => setCompany({...company, division: e.target.value})} ref={divisionRef}/>
                                        </td>
                                    </tr>
                        }
                        {
                            internetType == true &&
                            <tr>
                                <th>{t('COM_MIS_TEXT_ADDRESS_P')}</th>
                                <td>
                                    <TextInput width={220} value={company.address}
                                               onChange={(e) => setCompany({...company, address: e.target.value})} ref={addressRef}/>
                                </td>
                            </tr>
                        }
                        {
                            internetType == true &&
                                    <tr>
                                        <th>{t('COM_TEXT_EMAIL_P')}</th>
                                        <td>
                                            <TextInput width={220} value={company.email} onChange={(e) => setCompany({...company, email: e.target.value})} ref={eMailRef}/>
                                        </td>
                                    </tr>
                        }
                        {
                            internetType == true &&
                            <tr>
                                <th>{t('COM_MIS_TEXT_PHONE_NUMBER_P')}</th>
                                <td>
                                    <TextInput width={220} value={company.phoneNumber}
                                               onChange={(e) => setCompany({...company, phoneNumber: e.target.value})} ref={phoneNumRef}/>
                                </td>
                            </tr>
                        }

                        {
                            internetType == false &&
                            <tr>
                                <th>{t('COM_MIS_TEXT_H_W_UNIQUE_KEY_P')}</th>
                                <td>
                                    <TextInput width={220} value={hwUniqueKey} disabled={true}/>
                                </td>
                            </tr>
                        }
                        {
                            internetType == false &&
                            <tr>
                                <td colSpan="2">
                                    <div
                                        style={{"whiteSpace": "initial"}}>{t('MIS_TEXT_MAKE_A_NOTE_OF_THE_FREE_LICENSE_KEY_AND_UNIQUE_KEY_P')}</div>
                                </td>
                            </tr>
                        }
                        {
                            internetType == false &&
                            <tr>
                                <td colSpan="2">
                                    <div
                                        style={{"whiteSpace": "initial"}}> {t('COM_MIS_TEXT_ENTER_THE_FREE_LICENSE_KEY_AND_UNIQUE_KEY_TO_THE_LICENSESERVER_P')}</div>
                                </td>
                            </tr>
                        }
                        {
                            internetType == false &&
                            <tr>
                                <th>{t('COM_MIS_BUTTON_READ_ACTIVATIONKEY_P')}</th>
                                <td>
                                    <SearchButton onClick={onClickFile}/>
                                    <input type="file" ref={akFileInput} style={{display: 'none'}}
                                           onChange={setFileInformation}/>
                                    <div className="tag_input float_l mr8" style={{width: '150px'}}>
                                        <input type="text" value={fileName}/>
                                    </div>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {
                    internetType == true &&<>
                    <div>
                        <Checkbox id = {'chkAgree'} name={t("MIS_SID_20_I_AGREE_TO_PROVIDE_THIS_INFORMATION_TO_SAMSUNG")}  ref={isAgreeRef} style={{'width': '200px'}} defaultChecked={false} />
                    </div>
                        <div>
                            <button className="detail_btn mt14 mb10" style={{width: '200px', 'textAlign': 'left'}} onClick={showDescription} >
                                <span></span>
                                {t('COM_MIS_BUTTON_TERMS_AND_CONDITIONS_P')}
                            </button>
                        </div>
                        <span style={{display:showDesc?'inline':'none'}}>This legal notice (this “Notice”) applies to the MagicInfo server used to provide information to support technical issues related to your Samsung product and to any correspondence or dealings between you and Samsung Electronics Co., Ltd., (“Samsung”) with regard to such technical support. As part of the MagicInfo server, you may be asked to create and save an error report containing data about your PC information, LFD, log information, event records, screen captures, Dr. Watson Logs, crash dumps and compressed file for content. Once saved, you may select to send a copy of the Error Report to the Samsung technician, in order to better understand and help resolve your technical issue. Samsung will only keep the Error Report as necessary to resolve your technical issue and will delete the Error Report upon resolution of your technical issue.</span>
                    </>
                }
            </MISDialog>
        </div>
    )
};
export default FreeLicensePopup;