import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {isNil} from 'lodash';
import './TextInput.css';

const TextInput = ({
    width = 200, 
    propertyName,
    type = 'text',
    value, 
    maxLength, 
    error,
    success,
    errorWidth,
    errorMarginTop,
    onChange,
    onKeyDown,
    onBlur,
    readOnly,
    placeholder,
    children,
    disabled,
    textAlign,
    wrapperStyle,
    name,
    isEmptyButton = false,
    emptyText,
    autoComplete
}, ref) => {
    return (
        <span className='text_input_wrapper' style={wrapperStyle}>
            <div style={{position:"relative"}}>
                <input 
                    autoComplete={autoComplete}
                    style={{width, textAlign}}
                    className={classNames({'error': !!error})} 
                    type={type}
                    data-name={propertyName} 
                    value={!isNil(value)?value:''} 
                    onChange={onChange} 
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={disabled}
                    name={name}
                    ref={ref}
                />
                {children}
                {
                    isEmptyButton
                        &&<span onClick={emptyText} className={'empty_button'}></span>
                }
            </div>
            {
                error &&
                <span className="error autoline" style={{width: errorWidth, marginTop: errorMarginTop}}>{error}</span>
            }
            {
                success &&
                <span className="success autoline" style={{width: errorWidth, marginTop: errorMarginTop}}>{success}</span>
            }
        </span>
    );
};

export default forwardRef(TextInput);
