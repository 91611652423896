import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Moment from 'moment';
import FullCalendar from '../../../components/fullcalendar/react'
import momentPlugin from '../../../components/fullcalendar/moment';
import dayGridPlugin from "../../../components/fullcalendar/daygrid";
import './ScheduleDetailViewCalendar.css';
import Tooltip from "tooltip.js";
import {
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD, CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY, CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY,
    DATE_FORMAT_FOR_VALUE, MESSAGE_SCHEDULE_REPEAT_TYPE_MONTHLY, MESSAGE_SCHEDULE_REPEAT_TYPE_WEEKLY,
    scheduleConstants
} from "../../../constants";
import {popupAction} from "../../../actions";
import {useDispatch} from "react-redux";
import {getFirstDayOfWeek} from "../../../helper/schedule/scheduleUtils";


const colors = ["#80cbff", "#6ee6a9", "#ff92b1", "#b22222", "#ff8c00", "#7B68EE"]

const ScheduleViewCalendar = ({type, program}) => {
    
    const CONTENT_SCHEDULE_TYPE = 'CONTENT';
    const MESSAGE_SCHEDULE_TYPE = 'MESSAGE';
    const EVENT_SCHEDULE_TYPE = 'EVENT';
    const MOVE_CALENDAR_FIRST = 'first';
    const MOVE_CALENDAR_LAST = 'last';
    const MOVE_CALENDAR_PREV = 'prev';
    const MOVE_CALENDAR_NEXT = 'next';

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [schedule, setSchedules] = useState({
        currentChannel: undefined,
        currentEventIndex: undefined,
        currentMessageIndex: undefined,
        currentSlotIndex: undefined,
        events: []
    });

    const [tooltip, setTooltip] = useState({
        show: false
    })

    const oldMessageIndex = useRef(0);

    const isAdvertisement = useCallback(()=> {
        return program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD
    }, [program])

    const changeChannel = move => {
        if (program !== undefined && program.channels !== undefined && program.channels.length > 0) {

            const channelIndex = program.channels.findIndex(channel => channel.channelNo === schedule.currentChannel.channelNo);
            switch (move) {
                case MOVE_CALENDAR_FIRST:
                    if (channelIndex !== 0) {
                        updateContentSchedules(0);
                    }
                    break;
                case MOVE_CALENDAR_LAST:
                    if (isAdvertisement()) {
                        if (channelIndex !== program.channels[0].frame.adSlots.length -1) {
                            updateContentSchedules(program.channels[0].frame.adSlots.length-1);
                        }
                    } else {
                        if (channelIndex !== program.channels.length -1) {
                            updateContentSchedules(program.channels.length-1);
                        }
                    }
                    break;
                case MOVE_CALENDAR_PREV:
                    if (channelIndex-1 >= 0) {
                        updateContentSchedules(channelIndex-1);
                    }
                    break;
                case MOVE_CALENDAR_NEXT:
                    if (isAdvertisement()) {
                        if (channelIndex + 1 < program.channels[0].frame.adSlots.length) {
                            updateContentSchedules(channelIndex+1);
                        }
                    } else {
                        if (channelIndex + 1 < program.channels.length) {
                            updateContentSchedules(channelIndex+1);
                        }
                    }

                    break;
            }
        }
    }

    const changeSlot = move => {
        if (program !== undefined && program.channels[0].frame.adSlots !== undefined && program.channels[0].frame.adSlots.length > 0) {
            const {currentSlotIndex} = schedule;
            switch (move) {
                case MOVE_CALENDAR_FIRST:
                    if (currentSlotIndex !== 0) {
                        updateContentSchedules(0);
                    }
                    break;
                case MOVE_CALENDAR_LAST:
                    if (currentSlotIndex !== program.channels[0].frame.adSlots.length -1) {
                        updateContentSchedules(program.channels[0].frame.adSlots.length -1);
                    }
                    break;
                case MOVE_CALENDAR_PREV:
                    if (currentSlotIndex-1 >= 0) {
                        updateContentSchedules(currentSlotIndex-1)
                    }
                    break;
                case MOVE_CALENDAR_NEXT:
                    if (currentSlotIndex + 1 < program.channels[0].frame.adSlots.length) {
                        updateContentSchedules(currentSlotIndex + 1)
                    }
                    break;
            }
        }
    }

    const changeMessage = move => {
        if (program !== undefined && program.messageList !== undefined) {
            const {currentMessageIndex} = schedule;
            switch (move) {
                case MOVE_CALENDAR_FIRST:
                    if (currentMessageIndex !== 0) {
                        setSchedules({...schedule, currentMessageIndex: 0});
                    }
                    break;
                case MOVE_CALENDAR_LAST:
                    if (currentMessageIndex !== program.messageList.length -1) {
                        setSchedules({...schedule, currentMessageIndex: program.messageList.length-1});
                    }
                    break;
                case MOVE_CALENDAR_PREV:
                    if (currentMessageIndex-1 >= 0) {
                        setSchedules({...schedule, currentMessageIndex: currentMessageIndex-1});
                    }
                    break;
                case MOVE_CALENDAR_NEXT:
                    if (currentMessageIndex + 1 < program.messageList.length) {
                        setSchedules({...schedule, currentMessageIndex: currentMessageIndex+1});
                    }
                    break;
            }
        }
    }

    const changeEvent = move => {
        if (schedule.currentEventIndex !== undefined && program.selectedEventList != undefined && program.selectedEventList.length > 0) {
            switch (move) {
                case MOVE_CALENDAR_PREV:
                    if (schedule.currentEventIndex > 0) {
                        setSchedules({...schedule, currentEventIndex: schedule.currentEventIndex-1});
                    }
                    break;
                case MOVE_CALENDAR_NEXT:
                    if (schedule.currentEventIndex < program.selectedEventList.length-1) {
                        setSchedules({...schedule, currentEventIndex: schedule.currentEventIndex+1});
                    }
                    break;
            }
        }
    }

    const getBackgroundColor = index => {
        if (index >= colors.length) {
            index = index % colors.length;
        }
        return colors[index];
    }

    const getEvent = (schedule, index) => {
        if(schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY || schedule.repeatType === MESSAGE_SCHEDULE_REPEAT_TYPE_WEEKLY) {
            const daysOfWeek = schedule.repeatedDayOfWeekList.map((value) => {
                if(value === "SUN") {
                    return 0;
                }
                else if(value === "MON") {
                    return 1;
                }
                else if(value === "TUE") {
                    return 2;
                }
                else if(value === "WED") {
                    return 3;
                }
                else if(value === "THU") {
                    return 4;
                }
                else if(value === "FRI") {
                    return 5;
                }
                else if(value === "SAT") {
                    return 6;
                }
            });
            return {daysOfWeek: daysOfWeek, title: '', startRecur: Moment(schedule.startDate).format(DATE_FORMAT_FOR_VALUE), endRecur: Moment(schedule.endDate).add("1", "d").format(DATE_FORMAT_FOR_VALUE), backgroundColor: getBackgroundColor(index), extendedProps: {title: schedule.contentName}};
        } else if (schedule.repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY || schedule.repeatType === MESSAGE_SCHEDULE_REPEAT_TYPE_MONTHLY){
            return {
                title: '', startRecur: Moment(schedule.startDate).format(DATE_FORMAT_FOR_VALUE), endRecur: Moment(schedule.endDate).add("1", "d").format(DATE_FORMAT_FOR_VALUE), 
                backgroundColor: getBackgroundColor(index),
                extendedProps: {...schedule, title: schedule.contentName}
            };
        }
        else {
            return {title: '', start: Moment(schedule.startDate).format(DATE_FORMAT_FOR_VALUE), end: Moment(schedule.endDate).add("1", "d").format(DATE_FORMAT_FOR_VALUE), backgroundColor: getBackgroundColor(index), extendedProps: {title: schedule.contentName}};
        }
    }

    const updateContentSchedules = (channelIndex) => {
        let events = [];
        if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
            program.channels[0].frame !== undefined &&
            program.channels[0].frame.adSlots !== undefined &&
            program.channels[0].frame.adSlots[channelIndex].events !== undefined && program.channels[0].frame.adSlots[channelIndex].events.length > 0 &&
            program.channels[0].frame.adSlots[channelIndex].events.map(
                (event, index) => events.push({title: '', start: Moment(event.startDate).format(DATE_FORMAT_FOR_VALUE), end: Moment(event.stopDate).add("1", "d").format(DATE_FORMAT_FOR_VALUE), backgroundColor: getBackgroundColor(index), extendedProps: {title: event.eventName}})
            )
            setSchedules({currentSlotIndex: channelIndex, events: events})
        } else {
            const channel = program.channels[channelIndex];
            channel.frame.events !== undefined && channel.frame.events.length > 0 && channel.frame.events.map(
                (schedule, index) => {
                    events.push(getEvent(schedule, index));
                }
            )
            channel.frame.frames !==undefined && channel.frame.frames.length > 0 && channel.frame.frames.map(
                frame => {
                    if (frame.events !== undefined && frame.events.length > 0) {
                        frame.events.map(
                            (schedule, index) => {
                                events.push(getEvent(schedule, index));
                            }
                        )
                    }
                }
            )
            setSchedules({currentChannel: channel, events: events})
        }
    }



    const updateMessageSchedules = index => {
        let events = [];
        if (program !== undefined && program.messageList !== undefined && program.messageList.length > index) {
            const message = program.messageList[index];
            const weekdays = message.weekdays? message.weekdays.split(",") : undefined;
            const monthdays = message.monthdays? message.monthdays.split(",") : undefined;
            events.push(getEvent({...message, repeatedDayOfWeekList: weekdays, repeatedDateOfMonthList: monthdays}, index));
        }
        setSchedules({...schedule, events: events, currentMessageIndex: index})
    }

    const oldEventIndex = useRef(undefined);
    const updateEventSchedules = (index) => {
        let endDate = program.selectedEventList[index].stopDate? Moment(program.selectedEventList[index].stopDate, DATE_FORMAT_FOR_VALUE).add("1", "d").format(DATE_FORMAT_FOR_VALUE):program.selectedEventList[index].stopDate;
        let events = [];
        events.push({title: '', start: program.selectedEventList[index].startDate, end: endDate, backgroundColor: getBackgroundColor(index), extendedProps: {title: program.selectedEventList[index].eventName}});
        oldEventIndex.current = index;

        setSchedules({...schedule, currentEventIndex: index, events: events})
    }

    useEffect(()=> {
        if (schedule.currentEventIndex !== undefined && oldEventIndex.current !== schedule.currentEventIndex) {
            updateEventSchedules(schedule.currentEventIndex)
        }
    }, [schedule])

    const eventRender = (info) => {
        const {repeatType, repeatedDateOfMonthList, title} = info.event.extendedProps
        if (repeatType === CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY || repeatType === 'day_of_month') {
            const {start, end}  = info.event;
            const date = start.getDate();
            if (repeatedDateOfMonthList.find(day => day.toString() === date.toString()) == undefined) {
                return false;
            }
        }
        const tooltip = new Tooltip(info.el, {
            title: title,
            placement: 'top',
            trigger: 'hover click',
            container: 'body'
        });

    }

    const eventLimitClick = ({segs})  => {
        const events = segs !== undefined && segs.map(
            seg => seg.eventRange.def.extendedProps.title
        ) || [];

        dispatch(popupAction.addPopup({
            type: scheduleConstants.CONTENT_SCHEDULE_MORE_EVENTS_POPUP,
            id: scheduleConstants.CONTENT_SCHEDULE_MORE_EVENTS_POPUP,
            events: events,
            close: ()=>dispatch(popupAction.closePopup(scheduleConstants.CONTENT_SCHEDULE_MORE_EVENTS_POPUP))
        }))
    }


    useEffect(()=>{
        if (type === EVENT_SCHEDULE_TYPE) {
            setSchedules({currentChannel: undefined, currentEventIndex: 0, events: []})
        } else {
            setSchedules({currentChannel: undefined, events: []})
        }
        return ()=> {

        }
    },[])

    useEffect(()=> {
        if (program !== undefined) {
            if (type === CONTENT_SCHEDULE_TYPE) {
                updateContentSchedules(0);
            } else if (type === MESSAGE_SCHEDULE_TYPE) {
                updateMessageSchedules(0);
            }
        }
    }, [program])

    useEffect(()=> {
        if (schedule.currentMessageIndex !== undefined && type === MESSAGE_SCHEDULE_TYPE && oldMessageIndex.current !== schedule.currentMessageIndex) {
            oldMessageIndex.current = schedule.currentMessageIndex;
            updateMessageSchedules(schedule.currentMessageIndex);
        }
    }, [schedule])

    const channelNameNo = useCallback(()=> {
        let nameNChannel = '';
        if (schedule !== undefined && schedule.currentChannel !== undefined){
            nameNChannel = t("COM_TEXT_CHANNEL_P");
        }
        if (schedule !== undefined && schedule.currentChannel !== undefined && schedule.currentChannel.channelNo !== undefined) {
            nameNChannel += ' ' + schedule.currentChannel.channelNo;
        }
        return nameNChannel;

    }, [schedule])

    const slotName = useCallback(()=> {
        let slotName = '';
        if (schedule !== undefined && schedule.currentSlotIndex !== undefined){
            slotName = program.channels[0].frame.adSlots[schedule.currentSlotIndex].slotName
        }
        return slotName;
    }, [schedule])

    const messageName = useCallback(()=> {
        let messageName =  '';
        if (schedule !== undefined && schedule.currentMessageIndex !== undefined) {
            messageName = t("TEXT_MESSAGE_TEXT_P") + (schedule.currentMessageIndex+1);
        }
        return messageName;
    }, [schedule])

    const eventName = useCallback(()=> {
        let eventName = '';
        if (program !== undefined && program.selectedEventList !== undefined && schedule.currentEventIndex !== undefined) {
            eventName = program.selectedEventList[schedule.currentEventIndex].eventName
        }
        return eventName;
    }, [schedule])

    return (
        program !== undefined &&
        <div className="detail_View_calendar calendar_wrap_pop ml25" align="center">
            {
                type ===  CONTENT_SCHEDULE_TYPE && program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev2" onClick={()=>changeChannel(MOVE_CALENDAR_FIRST)}>
                            <span></span></button>
                        <button className="calendar_arrow_pop prev" onClick={()=>changeChannel(MOVE_CALENDAR_PREV)}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeChannel(MOVE_CALENDAR_NEXT)}>
                            <span></span></button>
                        <button className="calendar_arrow_pop next2" onClick={()=>changeChannel(MOVE_CALENDAR_LAST)}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName" className={'schedule_name'}>{channelNameNo()}</span>
                </div>
            }
            {
                type ===  CONTENT_SCHEDULE_TYPE && program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev2" onClick={()=>changeSlot(MOVE_CALENDAR_FIRST)}>
                            <span></span></button>
                        <button className="calendar_arrow_pop prev" onClick={()=>changeSlot(MOVE_CALENDAR_PREV)}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeSlot(MOVE_CALENDAR_NEXT)}>
                            <span></span></button>
                        <button className="calendar_arrow_pop next2" onClick={()=>changeSlot(MOVE_CALENDAR_LAST)}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName" className={'schedule_name'}>{slotName()}</span>
                </div>
            }
            {
                type ===  EVENT_SCHEDULE_TYPE &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev" onClick={()=>changeEvent(MOVE_CALENDAR_PREV)}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeEvent(MOVE_CALENDAR_NEXT)}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName" className={'schedule_name'}>{eventName()}</span>
                </div>
            }

            {
                type === MESSAGE_SCHEDULE_TYPE &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev2" onClick={()=>changeMessage(MOVE_CALENDAR_FIRST)}>
                            <span></span></button>
                        <button className="calendar_arrow_pop prev" onClick={()=>changeMessage(MOVE_CALENDAR_PREV)}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeMessage(MOVE_CALENDAR_NEXT)}>
                            <span></span></button>
                        <button className="calendar_arrow_pop next2" onClick={()=>changeMessage(MOVE_CALENDAR_LAST)}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName">{messageName()}</span>
                </div>
            }


            <div className="schedule_calendar_view" style={{marginTop: (type != CONTENT_SCHEDULE_TYPE ? -30 : '')}}>

                {
                    tooltip.show &&
                        <div style={{display: 'absolute', width: 10, height: 10, backgroundColor: 'black'}}>{tooltip.title}</div>
                }


                <FullCalendar
                    defaultView="dayGridMonth"
                    height={400}
                    header={{
                        left: '',
                        center: 'prev, title, next',
                        right: 'today'
                    }}
                    buttonText={{
                        today: t("TEXT_TODAY_P"),
                    }}
                    titleFormat={'YYYY.MM'}
                    views={{month: {eventLimit: 3}}}
                    columnFormat={{
                        month: 'dddd',
                        week: 'D dddd',
                        day: 'MM.DD dddd'
                    }}
                    events={schedule.events}
                    Color={'yellow'}
                    textColor={'black'}
                    timezone={'local'}
                    eventConstraint={{start: '00:00', end: '24:00'}}
                    allDaySlot={false}
                    eventLimit={true}
                    eventLimitClick={eventLimitClick}
                    plugins={[ dayGridPlugin, momentPlugin ]}
                    timeFormat={'HH:mm'}
                    eventRender={eventRender}
                    firstDay={getFirstDayOfWeek()}
                />

            </div>

        </div>
    )


}
export default ScheduleViewCalendar;