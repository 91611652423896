import React from 'react';
import classNames from 'classnames';

const PercentFrame = ({frames, selectedFrame = -1, wrapWidth, wrapHeight, onSelectFrame, removeFrame}) => {

    const closeBtn = () => <div class="closeBtn" style="z-index:100;"></div>

    const selectFrame = index => {
        onSelectFrame(index);
    }

    const Frame = ({index, x, y, width, height, main, lineIndex}) => {
        return (
            <div className="fixel_frame" style={{cursor:'pointer', left:x, top: y, width: width, height: height, lineHeight: height}}>
                {
                    main !== undefined && main === false &&
                        <CloseButton lineIndex={lineIndex} />
                }
                <div className={classNames('frame', {'on' : selectedFrame === index})} style={{top:1,left:1,width:(width-1),height:(height-1)}} data-value={index} onClick={()=>selectFrame(index)}></div>
            </div>
        )
    }

    const CloseButton = ({lineIndex}) => {
        return (
            <div className="closeBtn" onClick={()=>removeFrame(lineIndex)} style={{zIndex:100}}></div>
        )
    }

    return(
        <div className="custom_frame mt20 clearfix mb20" id="createNewFrameWrap" style={{height:365,textAlign:'center',position:'relative'}}>
            {
                frames !== undefined && frames.length > 0 &&
                    frames.map(
                        (frame, index) => {
                            const x = Math.floor(wrapWidth * (frame.x * 0.01));
                            const y = Math.floor(wrapHeight * (frame.y * 0.01));
                            const width = Math.floor(wrapWidth * (frame.width * 0.01));
                            const height = Math.floor(wrapHeight * (frame.height * 0.01));
                            return <Frame index={index} x={x} y={y} width={width} height={height} main={frame.type} lineIndex={frame.lineIndex}/>
                        }
                    )
            }
        </div>
    )
}
export default PercentFrame;