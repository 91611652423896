export const optionDoughnut = {
    legend: {display: false},
    animation: {
        duration: 1,
    },
    tooltips: {
        displayColors: false,
    }
};

export const color = ['#F4D5D6', '#F6D7C3', '#F2E7C3', '#E0F3AF', '#CAEEBB', '#BDE8D6', '#BFE5E8', '#B5E0F1', '#B5D1F1', '#F1C9E8', '#FCD8EC', '#FDCDE8', '#FDDFE5'];

export const STATISTICS_SUPPORT_CONTENT_TYPE = ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK','FTP','CIFS','STRM','VWL','HTML','URL',"LFT",'SAPP','ADS'];

export const optionBar = {
    legend: {display: false},
    tooltips: {enabled: false},
    hover: {animationDuration: 0},
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 0
        }
    },
    animation: {
        duration: 1,
        onComplete: function () {
            let chart = this.chart;
            chart.ctx.textAlign = 'center';

            this.data.datasets.forEach(function (dataset, i) {
                chart.controller.getDatasetMeta(i).data.forEach(function (bar, j) {
                    chart.ctx.fillText(dataset.data[j], bar._model.x, bar._model.y - 5);
                });
            });
        },
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                min: 0,
                callback: function (value) {
                    if (value % 1 === 0) {
                        return value;
                    }
                }
            },
        }]
    },
};

export const optionStackBar = (chartData) => {
    return {
        legend: {display: false},
        tooltips: {
            enabled: false,
            custom: function (tooltipModel) {
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = `<div style='background:#000; display: inline-flex; flex-direction: column; padding: 10px; color: #f0f0f0; font-size: 12px; line-height: 14px; border-radius: 6px;'></div>`;
                    document.body.appendChild(tooltipEl);
                }
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }
                let innerHtml = '';
                if (chartData && chartData.labels && chartData.labels.length > 0 && tooltipModel.title) {
                    let index = chartData.labels.findIndex(label => label === tooltipModel.title[0]);
                    let titleLine = chartData.labels[index];
                    let bodyLines = [];
                    chartData.datasets.forEach(dataset => {
                        bodyLines.push({
                            title: dataset.label,
                            count: dataset.data[index],
                            backgroundColor: dataset.backgroundColor,
                        })
                    });
                    innerHtml += '<div style="font-weight: bold; font-size: 13px">' + titleLine + '</div>';
                    bodyLines.forEach((body, index) => {
                        let iconHtml = `<div style="background:${body.backgroundColor}; width: 10px; height: 10px; margin: 3px 5px 0px 0px; float: left"></div>`;
                        let bodyHtml = `<div>` + body.title + ' : ' + body.count + `</div>`;
                        innerHtml += '<div style="display: flex;">' + iconHtml + bodyHtml + '</div></div>';
                    });
                    let tableRoot = tooltipEl.querySelector('div');
                    tableRoot.innerHTML = innerHtml;

                    let position = this._chart.canvas.getBoundingClientRect();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                } else {
                    let tableRoot = tooltipEl.querySelector('div');
                    tableRoot.innerHTML = innerHtml;
                }
            }
        },
        hover: {animationDuration: 0},
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 15,
                bottom: 0
            }
        },
        animation: {
            duration: 1,
        },
        scales: {
            xAxes: [{
                stacked: true,
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: function (value) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    }
                },
            }]
        },
    }
};

export const chartColor = (length) => {
    let result = [];
    while (length > 0) {
        result = result.concat(color);
        length -= 13;
    }
    return result;
};
