import {history} from './history';


export function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            let array = obj[key];
            if (key === 'sorted') {
                for (const arrayKey in array) {
                    for( let object in array[arrayKey] ) {
                        if (object === 'id') {
                            keyValuePairs.push('orderBy=' + encodeURIComponent(array[arrayKey][object]));
                        } else if (object === 'desc') {
                            if (array[arrayKey][object]) {
                                keyValuePairs.push('order=desc');
                            } else {
                                keyValuePairs.push('order=asc');
                            }
                        }
                    }
                }
            } else if (key === 'filtered') {
                array.map(
                    filter => {
                        keyValuePairs.push('filtered['+filter['id']+']' + '=' + encodeURIComponent(filter['value']));
                    }
                )
            } else if (key === 'columns') {
                if (obj['filtered'] != null) {
                    let query = '';
                    for (const arrayKey in array) {
                        if (array[arrayKey]['accessor'] !== 'idx') {
                            if (query !== '') {
                                query += ',';
                            }
                            query += array[arrayKey]['accessor'];
                        }
                    }
                    keyValuePairs.push('columns=' + query);
                }
            } else {
                keyValuePairs.push(encodeURIComponent(key) + '=' + array.map(el => encodeURIComponent(el)).join(','));
            }

        } else {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
    }
    return '?'+keyValuePairs.join('&');
}

export function createQuery (params) {
    if(!params)
        return "";
    Object.keys(params).forEach(key => params[key] === undefined ? delete params[key] : {});
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

export function createQuery2 (params) {
    if(!params)
        return "";
    return Object.keys(params).map(k => k + '=' + params[k]).join('&');
}

export function handleResponse(response) {
    if (response.body instanceof ReadableStream) {
        return response.text()
            .then(text => {
                const data = text && JSON.parse(text);
                return data;
            })
    }
    return response.body;
}

export function handleResponseForOrganization(response, needChildGroup) {
    if (response.body instanceof ReadableStream) {
        return response.text()
            .then(text => {
                const data = text && JSON.parse(text);
                return data;
            })
    }

    if (!needChildGroup) {
        response.body.items = response.body.items.filter(item => item.groupDepth <= 1);
    }

    return response.body;
}

export function handleDownloadResponse(response) {

    return new Promise((resolve, reject) => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                history.push('/login');
            }
            return reject(response);
        } else {
            return response.text().then(text => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        history.push('/login');
                    }

                    return new reject(text);
                }
                resolve({text: text, ok: response.ok});
            });
        }
    });

}

export function handleBinDownloadResponse(response) {
    return new Promise((resolve, reject) => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                history.push('/login');
            }
            return reject(response);
        } else {
            return response.blob().then(blob => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        history.push('/login');
                    }

                    return new reject(blob);
                }
                let fileName = response.headers.get('content-disposition');
                if (fileName) {
                    fileName = fileName.replace(/"/gi, '');
                    if (fileName && fileName.length > 0) {
                        let fileNames = fileName.split(';');
                        if (fileNames.length > 0) {
                            fileNames = fileNames[1].split('=');
                            if (fileNames.length > 0) {
                                fileName = fileNames[1];
                            }
                        }
                    }
                } else {
                    // 잠실서버의 문제인지 response.headers.get('content-disposition') 값이 null 임.
                    fileName = "unknown";
                }
                resolve({blob, fileName});
            });
        }
    })
}

export function createParams(values) {
    return JSON.stringify(values);
}
