import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {isNil} from "lodash";
import {useTranslation} from "react-i18next";
import {getDeviceTypeStr} from "../../../../helper/device/deviceUtils";
import {useSelector} from "react-redux";
import Chart from 'chart.js';
import {dashboardService} from "services";
import {Loading} from "../../../../components/loading/Loading";

const ConnectionByDeviceType = () => {

    const [chartData, setChartData] = useState();
    const {t} = useTranslation();
    const [tick, setTick] = useState({});
    const [loading, setLoading] = useState(true);
    const [showChart, setShowChart] = useState(true);

    const {reload} = useSelector(state => state.dashboard);

    const _makeChart = (connectedDevices) => {
        const data = {};
        const labels = [];
        const connections = [];
        const disConnections = [];

        connectedDevices.map(
            device => {
                labels.push(getDeviceTypeStr(device.deviceType));
                connections.push(device.connected);
                disConnections.push(device.disconnected);
            }
        )

        data.labels = labels;
        data.datasets = [
            {
                label: 'connected',
                data: connections,
                backgroundColor: '#58C814',
                yAxisID: 'connected',
                borderWidth: 0
            },
            {
                label: 'disconnected',
                data: disConnections,
                backgroundColor: '#9DA5AE',
                yAxisID: 'disconnected',
                borderWidth: 0
            },
        ]
        setChartData(data);
    }

    const fetchData = () => {

        dashboardService.fetchDeviceConnectionInfo().then(res => {
            if (res && res.items) {
                _makeChart(res.items);
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            setShowChart(false);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(()=> {
        fetchData();
    }, [reload])


    useEffect(()=> {
        if (chartData && chartData.datasets && chartData.datasets.length > 0) {
            const connections = chartData.datasets[0].data;
            const disConnections = chartData.datasets[1].data;
            const max = Math.max(10, Math.max(...connections), Math.max(...disConnections));
            if (max > 10) {
                setTick({suggestedMax: max, fontColor: '#909090',fontSize: 10});
            } else {
                setTick({max: 10, fontColor: '#909090', fontSize: 10});
            }
        }
    }, [chartData])

    return (
        <div style={{width: '100%', height: 200}}>
            <div style={{marginBottom: 10, color: '#241f20'}}>{t('MIS_SID_CBFEB_CONNECTED_DEVICES_DEVICE_TYPE')}</div>
            {
                showChart ?
                    ((!isNil(chartData) && !loading) ?
                        <Bar
                            height={170}
                            data={chartData}
                            options={{
                                showDatapoints: true,
                                maintainAspectRatio: false,
                                legend: {
                                    display: false,
                                },
                                animation: {
                                    duration: 0
                                },
                                elements: { point: { radius: 0 } },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            display: false
                                        },
                                        ticks: {
                                            fontColor: '#454545',
                                            fontSize: 10,
                                        },
                                        barThickness: 10,
                                        maxBarThickness: 10
                                    }],
                                    yAxes: [{
                                        gridLines: {
                                            drawBorder: false
                                        },
                                        id: 'connected',
                                        position: 'left',
                                        ticks: {
                                            beginAtZero: true,

                                            callback: function (value) { if (value % 1 === 0) { return value; } },
                                            ...tick
                                        },
                                    }, {
                                        gridLines: {
                                            drawBorder: false
                                        },
                                        id: 'disconnected',
                                        position: 'right',
                                        display: false,
                                        ticks: {
                                            beginAtZero: true,
                                            callback: function (value) { if (value % 1 === 0) { return value; } },
                                            ...tick
                                        }
                                    }]
                                }
                            }}

                        /> : <div className={'area_center'}> <Loading></Loading></div>)
                    : <div className={'area_center no_data'}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
            }
        </div>
    )
}
export default ConnectionByDeviceType;