import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../radio/RadioGroup";
import TextInput from "../../../input/TextInput";
import Preview from "./Preview";
import { getPatternType } from "../../../../helper/ruleset/rulesetUtils";

const DeviceModelName = ({ handleValues,  orgValues}) => {

    const [nameRule, setNameRule] = useState(orgValues.length ? orgValues[0].value.replace(/\*/g,'') : "");
    const [sign, setSign] = useState(
        orgValues.length ? getPatternType(orgValues[0].value): "eq");

  const { t } = useTranslation();

  const makeValue = (orginal,sign) => {
    let value = "";
    if (sign === "eq") {
        value = orginal;
    } else if (sign === "sw") {
        value = orginal + "*";
    } else if (sign === "ct") {
        value = "*" + orginal + "*";
    }
    return value;
};

const onChangeSign = (e, value) => {
    setSign(value);
    const newValue = [{ value: makeValue(nameRule,value) }];
    handleValues(newValue);     
};

  const onChangeNameRule = e => {
    setNameRule(e.target.value);
    const newValue = [{ value: makeValue(e.target.value,sign) }];
    handleValues(newValue);
  };

  return (
    <table className="dev_ip">
      <tr id="operator">
        <th
          style={{ width: "130px", verticalAlign: "top", paddingTop: "10px" }}
        >
          {t("MIS_SID_OPERATOR")}
        </th>
        <td>
          <RadioGroup
            propertyName={"sign"}
            selects={[
                { title: t("MIS_SID_EQUAL"), value: "eq" },
                { title: t("MIS_SID_START_WITH"), value: "sw" },
                { title: t("MIS_SID_CONTAINS"), value: "ct" }
            ]}
            value={sign}
            onChange={onChangeSign}
            multiLang={true}
          />
        </td>
      </tr>
      <tr>
        <th>{t("TABLE_DEVICE_MODEL_NAME_P")}</th>
        <td>
          <TextInput
            width={300}
            maxLength={50}
            value={nameRule}
            onChange={onChangeNameRule}
          ></TextInput>
        </td>
      </tr>
    </table>
  );
};

export default DeviceModelName;
