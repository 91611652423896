import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import MISDialog from "../MISDialog";
import {settingConstants} from "../../../constants";
import {settingService} from "../../../services";
import TextInput from "../../input/TextInput";
import Select from "../../selectbox/Select";
import EditableSelectBox from "../../selectbox/EditableSelectBox";

const TagPopup = ({onSave, onClose,type, value, tagId, title, zIndexChange = false}) => {
    const {t} = useTranslation();

    const [tagInfo, setTagInfo] = useState({
        tagName : '',
        tagValue : '',
        tagDesc : '',
        tagType : 0,
        tagOrgan: -2,
        tagOrgName :''
    });
    const [tagOrganOption, setTagOrganOption] = useState([]);

    const [tagValues, setTagValues] = useState([]); // load exist tagValues + will be added tag values
    const [addedTagValues, setAddedTagValues] = useState([]); // only will be added tag values
    const [deletedTagValues, setDeletedTagValues] = useState([]); // only will be deleted tag values
    const tagNameRef = useRef();

    const tagTypeMediaOption = [
        { value:0, title:t('DID_LFD_TEXT') },
        { value:1, title:t('COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER')},
        { value:2, title:t('MIS_TEXT_TRUE_OR_FALSE_P')}
    ];

    const tagTypeVariableOption = [
        { value:0, title:t('DID_LFD_TEXT') },
    ];

    useEffect(() => {
        fetchTag();
        tagNameRef.current.focus();
    },[]);

    const fetchTag = () => {
        if (type == settingConstants.ADD_TAG_POPUP) {
            settingService.fetchTagOrgan({})
                .then(res => {
                    const orgList = res.items.map(org => {
                        return {value: org.groupId, title: org.groupName};
                    });
                    setTagOrganOption(orgList);
                });
        }else if (type == settingConstants.EDIT_TAG_POPUP) {
            Promise.all([
                settingService.getTag(tagId),
                settingService.fetchContentTagConditions(tagId)
            ]).then(res => {

                setTagInfo({
                    ...tagInfo,
                    tagId: res[0].items.tagId,
                    tagName: res[0].items.tagName,
                    tagValue: res[0].items.tagValue,
                    tagDesc: res[0].items.tagDesc,
                    tagOrgan: res[0].items.tagOrgan,
                    tagType: res[0].items.tagType,
                    tagOrgName: res[0].items.tagOrgName
                });
                const tempValues = []
                res[1].items.map(item => item.tagConditionId != -1 ? tempValues.push({text: item.tagCondition,value: item.tagCondition, id: item.tagConditionId, tagConditionId : parseInt(item.tagConditionId), tagCondition: item.tagCondition}) : null)
                setTagValues(tempValues);
            });
        }
    }

    const getTitle = (value) => {
        const resultData =  tagTypeMediaOption.find( option =>  option.value === value);
        return resultData.title;
    }

    return(
        <div>
        <MISDialog
            classname={zIndexChange ? "tag_popup" : undefined}
            dialog={{
                title: title,
                closeOnEscape: true,
                modal: true,
                width: 620,
                height: 220,
                // position: {x: -300, y: -400},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: () => onSave(tagInfo, addedTagValues, deletedTagValues,tagValues),
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div className='detail_view mini_p '>
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>{t('MIS_TEXT_TAG_NAME_P_KR_MIS20')}</th>
                        <td>
                            <TextInput width={300} placeholder={t('MESSAGE_COMMON_ENTER_NAME_P')} value={tagInfo.tagName}
                                       onChange={(e) => setTagInfo({...tagInfo, tagName: e.target.value})} maxLength={100} ref={tagNameRef}/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('MIS_SID_TAG_TYPE')}</th>
                        <td>
                            {
                                type == settingConstants.ADD_TAG_POPUP &&
                                value == 'media' &&
                                <Select selects={tagTypeMediaOption} width={300} value={tagInfo.tagType}
                                        onChange={(e, value) => setTagInfo({...tagInfo, tagType: value})}/>
                            }
                            {
                                type == settingConstants.ADD_TAG_POPUP &&
                                value == 'variable' &&
                                <Select selects={tagTypeVariableOption} width={300} value={tagInfo.tagType}
                                        onChange={(e, value) => setTagInfo({...tagInfo, tagType: value})}/>
                            }
                            {
                                type == settingConstants.EDIT_TAG_POPUP &&
                                <span>{getTitle(tagInfo.tagType)}</span>
                            }
                        </td>
                    </tr>
                    {
                        tagInfo.tagType == 0 &&
                        <tr>
                            <th>{t('MIS_TEXT_TAG_VALUE_P')}</th>
                            <td style={{display: "inline-flex"}}>
                                {
                                    tagValues.length == 0 &&
                                    <EditableSelectBox width={300} defaultTitle={t('MIS_SID_SELECT_TO_ADD_A_VALUE')} oldValues={[]} setAddedValues={setAddedTagValues} setDeletedValues={setDeletedTagValues} valueMaxLimit={50}/>
                                }
                                {
                                    tagValues && tagValues.length > 0 &&
                                    <EditableSelectBox width={300} oldValues={tagValues} setAddedValues={setAddedTagValues} setDeletedValues={setDeletedTagValues} valueMaxLimit={50}/>
                                }
                            </td>
                        </tr>
                    }

                    <tr>
                        <th>{t('TEXT_ORGANIZATION_P')}</th>
                        <td>
                            {
                                type == settingConstants.ADD_TAG_POPUP &&
                                <Select selects={tagOrganOption} width={300} defaultTitle={t('COM_MESSAGE_DEVICE_SELECT_ITEM_P')} multiLang={false} value={tagInfo.tagOrgan} useEllipsis={true}
                                        onChange={(e, value) => setTagInfo({...tagInfo, tagOrgan: value})}/>
                            }
                            {
                                type == settingConstants.EDIT_TAG_POPUP &&
                                <span>{tagInfo.tagOrgName}</span>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>{t('TEXT_DESCRIPTION_P')}</th>
                        <td>
                            <TextInput width={300} placeholder={t('MESSAGE_SCHEDULE_ENTER_MESSAGE_HERE_P')} value={tagInfo.tagDesc}
                                       onChange={(e)=> setTagInfo({...tagInfo, tagDesc:e.target.value})} maxLength={30}/>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>

        </MISDialog>
        </div>
    );
};

export default TagPopup;