import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from '../../../checkbox/Checkbox';
import {SearchButton} from "../../../button/SearchButton";
import ContentPopup from "../../schedule/ContentPopup";
import ContentThumbnail from "../../../image/ContentThumbnail";
import Size from "../../../utils/Size";
import PresetCustomContent from "./PresetCustomContent";
import CustomizeContentPopup from "./CustomizeContentPopup";
import {deviceService} from "../../../../services";

const EditPresetCustom = ({customInfo, setInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const [showContentPopup, setShowContentPopup] = useState(false);
    const [popupType, setPopupType] = useState("defaultContent");

    const {
        data: {
            customLogo,
            customLogoName,
            customLogoThumbnail,
            customLogoFileName,
            customLogoFileSize,
            defaultContent,
            defaultContentName,
            defaultContentThumbnail,
            defaultContentFileName,
            defaultContentFileSize,
        } = {},
        changed
    } = customInfo;
    const [selectMenu, setSelectMenu] = useState('');

    useEffect(() => {
        let softwareIds = [];

        if (customInfo.data.customLogo && customInfo.data.customLogo !== '') {
            softwareIds.push({type: 'customLogo', softwareId: customInfo.data.customLogo});
        }

        if (customInfo.data.defaultContent && customInfo.data.defaultContent !== '') {
            softwareIds.push({type: 'defaultContent', softwareId: customInfo.data.defaultContent});
        }

        fetchData(softwareIds);
    }, []);

    const fetchData = (softwareIds) => {
        let customLogoInfo = {data: {customLogo: ''}};
        let defaultContentInfo = {data: {defaultContent: ''}};

        Promise.all(softwareIds.map(software => deviceService.fetchDeviceRegisteredSoftwareById(software.softwareId))).then(
            response => {
                response.map(res => {
                    if (res.status === 'Success') {

                        if (res.items.softwareType === '03') {
                            customLogoInfo = {
                                data: {
                                    customLogo: res.items.softwareId,
                                    customLogoName: res.items.softwareName,
                                    customLogoThumbnail: res.items.filePath,
                                    customLogoFileName: res.items.fileName,
                                    customLogoFileSize: res.items.fileSize,
                                }
                            };
                        } else {
                            defaultContentInfo = {
                                data: {
                                    defaultContent: res.items.softwareId,
                                    defaultContentName: res.items.softwareName,
                                    defaultContentThumbnail: res.items.filePath,
                                    defaultContentFileName: res.items.fileName,
                                    defaultContentFileSize: res.items.fileSize,
                                }
                            };
                        }
                    }
                });

                onChange(
                    '',
                    '',
                    {data: {...customLogoInfo.data, ...defaultContentInfo.data}, changed: {...changed}},
                    setInfo
                );
            }
        );

        /*deviceService.fetchDeviceRegisteredSoftwareById(softwareId).then(res => {
            console.log("res");
            if(res.status === 'Success'){
                onChange(
                    {
                        contentId: res.items.softwareId,
                        contentName: res.items.softwareName,
                        thumbFileId: res.items.filePath,
                        fileName: res.items.fileName,
                        fileSize: res.items.fileSize
                    },
                    customInfo.data.customLogo === softwareId ? 'customLogo' : 'defaultContent',
                    customInfo,
                    setInfo
                );
            }
        }).catch(error => {
            console.log("error");
        }).finally(() => {

        });*/
    };

    const onCheckChange = (e, selectMenu) => {
        const initCustomLogoInfo = {
            ...customInfo,
            data: {
                ...customInfo.data,
                customLogo: '',
                customLogoName: '',
                customLogoThumbnail: '',
                customLogoFileName: '',
                customLogoFileSize: '',
            },
        };

        const initDefaultContentInfo = {
            ...customInfo,
            data: {
                ...customInfo.data,
                defaultContent: '',
                defaultContentName: '',
                defaultContentThumbnail: '',
                defaultContentFileName: '',
                defaultContentFileSize: '',
            },
        };

        if (!e.target.checked) {
            if (selectMenu === 'customLogo') {
                onChangeChecked(e, initCustomLogoInfo, setInfo);
            } else {
                onChangeChecked(e, initDefaultContentInfo, setInfo);
            }
        } else {
            onChangeChecked(e, customInfo, setInfo);
        }
    };

    const onClickContentPopup = (selectMenu) => {
        setShowContentPopup(!showContentPopup);
        setSelectMenu(selectMenu);
        if (selectMenu === 'customLogo') {
            setPopupType('03') // customLogo
        } else {
            setPopupType('04') // defaultContent
        }
    };

    const onSelectContent = (selectContentId, selectContentName, selectThumbFileId, selectFileName, selectFileSize) => {
        setShowContentPopup(!showContentPopup);
        onChange(
            {
                contentId: selectContentId,
                contentName: selectContentName,
                thumbFileId: selectThumbFileId,
                fileName: selectFileName,
                fileSize: selectFileSize
            },
            selectMenu,
            customInfo,
            setInfo
        );
    };

    const onCloseContent = () => {
        setShowContentPopup(!showContentPopup);
    };

    const onClickDeleteContent = (selectMenu) => {
        onChange({
            contentId: '',
            contentName: '',
            thumbFileId: '',
            fileName: '',
            fileSize: ''
        }, selectMenu, customInfo, setInfo);
    };

    return (
        <div className='device_edit_panel_wrap mt28'>
            {
                showContentPopup &&
                <CustomizeContentPopup
                    closeContentPopup={onCloseContent}
                    selectContent={onSelectContent}
                    mode={'event_condition'}
                    type={popupType}
                />
            }
            <div className="devicepop setup mb26">
                <h3>{t('COM_SID_MAGIC_BRIGHT_CUSTOM_KOR_MSG')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width="30px"/>
                            <col width="130px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th rowSpan='2' style={{verticalAlign: 'top', padding: '13px 0 0 5px'}}>
                                <Checkbox id='softwareCustomLogoEnable' name={t('COM_PJT_IDS_SETUP_14')}
                                          checked={changed['customLogo']}
                                          propertyName='customLogo' onChange={e => {
                                    onCheckChange(e, 'customLogo')
                                }}/>
                            </th>
                            <td>
                                <SearchButton disabled={!changed['customLogo']}
                                              onClick={() => onClickContentPopup('customLogo')}/>
                            </td>
                            <td style={{paddingLeft: '10px'}}>
                                {
                                    customLogo !== '' && customLogo !== undefined && changed['customLogo'] &&
                                    <div style={{
                                        height: '90%',
                                        width: '150px',
                                        background: '#f3f3f3',
                                        borderRadius: '15px',
                                        padding: '5px 6px 5px 10px',
                                        color: '#59616b',
                                        fontSize: '12px'
                                    }}
                                    >
                                        <span style={{
                                            width: '130px',
                                            display: 'inline-block',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {customLogoName}
                                        </span>
                                        <button id={"deleteBtn_" + customLogo} className="selectedDeviceDelBtn"
                                                style={{verticalAlign: 'middle', float: 'right', cursor: 'pointer'}}
                                                onClick={() => onClickDeleteContent('customLogo')}
                                        />
                                    </div>
                                }
                            </td>
                        </tr>
                        {
                            customLogoThumbnail !== '' && customLogoThumbnail !== undefined && changed['customLogo'] &&
                            <PresetCustomContent thumbnail={customLogoThumbnail}
                                                 fileName={customLogoFileName}
                                                 fileSize={customLogoFileSize}
                            />
                        }
                        {
                            (customLogoThumbnail === '' || customLogoThumbnail === undefined) &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        </tbody>
                        <tbody>
                        <tr>
                            <th rowSpan='2' style={{verticalAlign: 'top', padding: '13px 0 0 5px'}}>
                                <Checkbox id='softwareDefaultContentEnable' name={t('COM_TEXT_DEFAULT_CONTENT_P')}
                                          checked={changed['defaultContent']}
                                          propertyName='defaultContent' onChange={e => {
                                    onCheckChange(e, 'defaultContent')
                                }}/>
                            </th>
                            <td>
                                <SearchButton disabled={!changed['defaultContent']}
                                              onClick={() => onClickContentPopup('defaultContent')}/>
                            </td>
                            <td style={{paddingLeft: '10px'}}>
                                {
                                    defaultContent !== '' && defaultContent !== undefined && changed['defaultContent'] &&
                                    <div style={{
                                        height: '90%',
                                        width: '150px',
                                        background: '#f3f3f3',
                                        borderRadius: '15px',
                                        padding: '5px 6px 5px 10px',
                                        color: '#59616b',
                                        fontSize: '12px'
                                    }}
                                    >
                                        <span style={{
                                            width: '130px',
                                            display: 'inline-block',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {defaultContentName}
                                        </span>
                                        <button id={"deleteBtn_" + defaultContent} className="selectedDeviceDelBtn"
                                                style={{verticalAlign: 'middle', float: 'right', cursor: 'pointer'}}
                                                onClick={() => onClickDeleteContent('defaultContent')}
                                        />
                                    </div>
                                }
                            </td>
                        </tr>
                        {
                            defaultContentThumbnail !== '' && defaultContentThumbnail !== undefined && changed['defaultContent'] &&
                            <PresetCustomContent thumbnail={defaultContentThumbnail}
                                                 fileName={defaultContentFileName}
                                                 fileSize={defaultContentFileSize}
                                                 type={popupType}
                            />
                        }
                        {
                            (defaultContentThumbnail === '' || defaultContentThumbnail === undefined) &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EditPresetCustom;