import {rulesetConstants, commonConstants, getRuleTreeDefaultName} from '../constants';

export const rulesetAction = {
    initNewRuleset,
    initEditRuleset,
    addCondition,
    editCondition,
    addPlay,
    editPlay,
    openConditionPopup,
    redraw,
    setVerificationMode,
    setHighlightPath,
    setFixedHighlightPath,
    copy,
    duplicate
}

function initNewRuleset(device = {deviceType: "SPLAYER", deviceTypeVersion: 6}) {
    const ruletreeName = getRuleTreeDefaultName(1);
    if(device.deviceType === "IPLAYER" && device.deviceTypeVersion === undefined) {
        device.deviceTypeVersion = 1;
    }
    let ruleset = {};
    ruleset.name = "";
    ruleset.creator = "admin";
    ruleset.multiRule = true;
    ruleset.syncEnable = false;
    ruleset.defaultPlay = "";
    ruleset.organizationId = 0;
    ruleset.rules = [];
    ruleset.rules.push({
        rule_id: 1,
        rule_name: ruletreeName,
        multi_result: true,
        rule_tree: [
        ]
    });
    ruleset.conditions = [];
    ruleset.results = [];
    ruleset.contents = [];
    ruleset.deviceType = device.deviceType;
    ruleset.deviceTypeVersion = device.deviceTypeVersion;

    return {
        type: rulesetConstants.INIT_NEW_RULESET,
        device: device,
        ruleset: ruleset,
        verificationMode: false
    }
}

function initEditRuleset(ruleset) {
    return {
        type: rulesetConstants.INIT_EDIT_RULESET,
        ruleset: ruleset,
        verificationMode: false
    }
}

function addCondition(currentTab, condition) {
    return {
        type: rulesetConstants.ADD_CONDITION,
        currentTab: currentTab,
        newCondition: condition
    }
}

function editCondition(currentTab, condition) {
    return {
        type: rulesetConstants.EDIT_CONDITION,
        currentTab: currentTab,
        condition: condition
    }
}

function addPlay(currentTab, play) {
    return {
        type: rulesetConstants.ADD_PLAY,
        currentTab: currentTab,
        newPlay: play
    }
}

function editPlay(currentTab, play) {
    return {
        type: rulesetConstants.EDIT_PLAY,
        currentTab: currentTab,
        play: play
    }
}

function redraw() {
    return {
        type: rulesetConstants.REDRAW
    }
}

function openConditionPopup(popup) {
    return {
        type: commonConstants.COMMON_ADD_POPUP,
        popup: popup
    }
}

function setVerificationMode(currentTab, mode) {
    return {
        type: rulesetConstants.SET_VERIFICATION_MODE,
        currentTab: currentTab,
        verificationMode: mode
    }
}

function setHighlightPath(ruleId, path) {
    return {
        type: rulesetConstants.HIGHLIGHT_PATH,
        ruleId: ruleId,
        highlightPath: path
    }
}

function setFixedHighlightPath(ruleId, path) {
    return {
        type: rulesetConstants.FIXED_HIGHLIGHT_PATH,
        ruleId: ruleId,
        fixedHighlightPath: path
    }
}

function copy(currentTab, copyData) {
    return {
        type: rulesetConstants.COPY,
        currentTab: currentTab,
        copyData: copyData
    }
}

function duplicate(mode, ruleId, newRuleName) {
    return {
        type: rulesetConstants.DUPLICATE,
        mode: mode,
        ruleId: ruleId,
        newRuleName: newRuleName
    }
}