import uuid from "uuid";
import Moment from "moment";
import i18n from "../language/i18n";
import {userDateFormat} from "../helper/user/userDateFormat";



export const SCHEDULE_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const CONTENT_SCHEDULE_PROGRAM_TYPE_LFD = 'LFD';
export const CONTENT_SCHEDULE_PROGRAM_TYPE_VWL = 'VWL';
export const CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC = 'SYNC';
export const CONTENT_SCHEDULE_PROGRAM_TYPE_AD = 'ADV';

export const ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY = 'WEEKLY';
export const ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY = 'MONTHLY';
export const ADVERTISEMENT_SCHEDULE_VIEW_LIST = 'LIST';

export const TIME_FORMAT_FOR_VALUE = 'HH:mm:ss';
export const DATE_FORMAT_FOR_VALUE = 'YYYY-MM-DD';
export const CONTENT_SCHEDULE_REPEAT_TYPE_ONCE = 'ONCE';
export const CONTENT_SCHEDULE_REPEAT_TYPE_DAILY = 'DAILY';
export const CONTENT_SCHEDULE_REPEAT_TYPE_WEEKLY = 'WEEKLY';
export const CONTENT_SCHEDULE_REPEAT_TYPE_MONTHLY = 'MONTHLY';

export const MESSAGE_SCHEDULE_REPEAT_TYPE_WEEKLY = 'day_of_week';
export const MESSAGE_SCHEDULE_REPEAT_TYPE_MONTHLY = 'day_of_month';

export const CONTENT_SCHEDULE_INPUT_SOURCE_TYPE = 'HW_IS';
export const CONTENT_SCHEDULE_RULESET_TYPE = 'RULESET';

export const PROGRAM_TYPES = [
    {id: 'LFD', value: 'LFD', text: 'COM_TEXT_GENERAL_P'},
    {id: 'VWL', value: 'VWL', text: 'COM_DID_RMS_DEVICE_VIDEO_WALL'},
    {id: 'SYNC', value: 'SYNC', text: 'MIS_SID_SYNC_PLAY'},
    {id: 'ADV', value: 'ADV', text: 'COM_DID_LFD_ADVERTISEMENT'},
]

export const exportOption = [
    {value:'EXCEL', title:'COM_TEXT_EXCEL_P'},
    /*{value:'PDF', title:'DID_LAYOUTS_COMMON_PDF'},*/
];

export const CONTENT_SCHEDULE_CONFIG = {
    LFD : {
        supportedContent: true,
        supportedContentFilter:
            ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK','FTP','CIFS','STRM','HTML','URL','SAPP']
        ,
        supportedContentOnlyForWPlayer:
            ['ADS']
        ,
        supportedPlaylist: true,
        supportedPlaylistFilter:
            [0,5]
        ,
        supportedInputSource: true,
        supportedRuleSet: true,
    },
    VWL : {
        supportedContent: true,
        supportedContentFilter:
            /*['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK','DLKT','FTP','CIFS','STRM','HTML','URL','SAPP','VWL']*/
            ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK', 'FTP','CIFS','STRM','HTML','URL','SAPP','VWL']
        ,
        supportedPlaylist: true,
        supportedPlaylistFilter:
            [0,2,5]
        ,
        supportedInputSource: true,
        supportedRuleSet: false
    },
    SYNC : {
        supportedContent: true,
        supportedContentFilter:
            /*['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK','DLKT','FTP','CIFS','STRM','HTML','URL','SAPP']*/
            ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK', 'FTP','CIFS','STRM','HTML','URL','SAPP']
        ,
        supportedContentOnlyForWPlayer:
            ['ADS']
        ,
        supportedPlaylist: true,
        supportedPlaylistFilter:
            [0,2,3,5]
        ,
        supportedInputSource: true,
        supportedRuleSet: false
    },
    ADV : {
        supportedContent: false,
        supportedPlaylist: true,
        supportedPlaylistFilter:
            [4]
        ,
        supportedInputSource: false,
        supportedRuleSet: false
    }
}

export const CONTENT_SCHEDULE_CONTENT_MODE = 'CONTENT';
export const CONTENT_SCHEDULE_PLAYLIST_MODE = 'PLAYLIST';
export const CONTENT_SCHEDULE_INPUT_SOURCE_MODE = 'INPUT_SOURCE';
export const CONTENT_SCHEDULE_RULE_SET_MODE = 'RULE_SET';
export const CONTENT_SCHEDULE_ALL = 'ALL';

export const scheduleConstants = {

    /* for content schedule */
    INIT_CONTENT_SCHEDULE: 'INIT_CONTENT_SCHEDULE',
    UPDATE_CONTENT_SCHEDULE: 'UPDATE_CONTENT_SCHEDULE',
    DESTROY_CONTENT_SCHEDULE: 'DESTROY_CONTENT_SCHEDULE',
    ADD_SIMPLE_SCHEDULE: 'ADD_SIMPLE_SCHEDULE',
    UPDATE_CONTENT_AD_SCHEDULE: 'UPDATE_CONTENT_AD_SCHEDULE',
    INIT_CONTENT_ADVERTISEMENT_SCHEDULE: 'INIT_CONTENT_ADVERTISEMENT_SCHEDULE',
    START_DRAG: 'START_DRAG',

    /* for message schedule */
    INIT_NEW_MESSAGE: 'INIT_NEW_MESSAGE',
    INIT_EDIT_MESSAGE: 'INIT_EDIT_MESSAGE',
    ADD_MESSAGE: 'ADD_MESSAGE',
    LOAD_MESSAGE: 'LOAD_MESSAGE',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    SET_REMOVE_INDEX: 'SET_REMOVE_INDEX',
    UPDATE_MESSAGE: 'UPDATE_MESSAGE',
    DESTROY_MESSAGE: 'DESTROY_MESSAGE',

    /* for event schedule */
    INIT_NEW_EVENT_CONDITION: 'INIT_NEW_EVENT_CONDITION',
    INIT_EDIT_EVENT_CONDITION: 'INIT_EDIT_EVENT_CONDITION',
    ADD_EVENT_CONDITION: 'ADD_EVENT_CONDITION',
    REMOVE_EVENT_CONDITION: 'REMOVE_EVENT_CONDITION',
    DESTROY_EVENT_CONDITION: 'DESTROY_EVENT_CONDITION',
    UPDATE_EVENT_CONDITION: 'UPDATE_EVENT_CONDITION',

    INIT_NEW_EVENT: 'INIT_NEW_EVENT',
    INIT_EDIT_EVENT: 'INIT_EDIT_EVENT',

    INIT_EVENT_SCHEDULE: 'INIT_EVENT_SCHEDULE',
    ADD_EVENT_SCHEDULE: 'ADD_EVENT_SCHEDULE',
    REMOVE_EVENT_SCHEDULE: 'REMOVE_EVENT_SCHEDULE',
    LOAD_EVENT: 'LOAD_EVENT',
    UPDATE_EVENT: 'UPDATE_EVENT',

    LOAD_SIMPLE_CONTENT_SCHEDULE: 'LOAD_SIMPLE_CONTENT_SCHEDULE',
    UNLOAD_SIMPLE_CONTENT_SCHEDULE: 'UNLOAD_SIMPLE_CONTENT_SCHEDULE',


    CONTENT_SCHEDULE_TYPE_CONTENT : 'CONTENT',
    CONTENT_SCHEDULE_TYPE_PLAYLIST : 'PLAYLIST',
    CONTENT_SCHEDULE_TYPE_INPUT_SOURCE : 'INPUT_SOURCE',
    CONTENT_SCHEDULE_TYPE_RULE_SET : 'RULE_SET',

    CONTENT_SCHEDULE_TYPE_GENERAL: '0',
    CONTENT_SCHEDULE_TYPE_VIDEO_WALL: '1',
    CONTENT_SCHEDULE_TYPE_SYNC_PLAY: '2',
    CONTENT_SCHEDULE_TYPE_ADVERTISEMENT: '3',

    CONTENT_SCHEDULE_NEW_MODE : 'new',
    CONTENT_SCHEDULE_EDIT_MODE : 'edit',
    CONTENT_SCHEDULE_COPY_MODE : 'copy',
    CONTENT_SCHEDULE_SAVE_AS_MODE : 'saveAs',

    TAG_INFO_POPUP : 'TAG_INFO_POPUP',

    DESTROY_DRAG_INFO : 'DESTROY_DRAG_INFO',

    CONTENT_SCHEDULE_MORE_EVENTS_POPUP: 'CONTENT_SCHEDULE_MORE_EVENTS_POPUP'

}

const createUUID = () => {
    return uuid().toLowerCase();
}

export const defaultADContentSchedule = (adScheduleInfo) => {
    const frameId = createUUID(), programId =  createUUID();
    const channelName = i18n.t('COM_TV_SID_NEW_CHANNEL');
    const {getCurrentDateTimeStringInUserFormat} = userDateFormat();
    const slots = [];
    for (let i=0; i<adScheduleInfo.numberOfSlot; i++) {
        slots.push(defaultSlot(adScheduleInfo.slotDuration, programId, frameId, i+1));
    }

    return {
        programId: programId,
        programName: "[NEW AD Schedule] " + getCurrentDateTimeStringInUserFormat(),
        programType: '',
        backgroundMusic: "",
        description: "",
        deviceGroupIds: [],
        deviceType: "",
        deviceTypeVersion: 1,
        ipAddress: "",
        priority: 0,
        programGroupId: -1,
        programGroupName: '',
        resolution: {
            height: 1080,
            width: 1920
        },
        isDeployReserved: false,
        deployReservation: {},
        isContentSyncOn: false,
        isPlayFromLastPlaybackPoint: false,
        bgmContentId: '',
        bgmContentName: '',
        isBgmContentMute: false,
        channels: [
            {
                channelName: channelName, channelNo: 1, frame: {
                    frameName: 'frame 0',frameIndex: 0, frameId: frameId, isMainFrame: true, x: 0, y: 0, width: 100, height: 100, lineData: 'ZeroFrameOnly',
                    adSlots: slots
                }
            }
        ],
        slotCount : adScheduleInfo.numberOfSlot,
        slotDuration: adScheduleInfo.slotDuration
    }
}

export const defaultSlot = (slotDuration, programId, frameId, index) => {
    const slotId = createUUID();
    return {
        durationInSeconds: slotDuration,
        events: [],
        slotId: slotId,
        slotIndex: index,
        slotName: "Slot "+(index),
        frameId: frameId,
        programId: programId
    }
}

export const defaultContentSchedule = () =>{
    const frameId = createUUID();
    const channelName = i18n.t('COM_TV_SID_NEW_CHANNEL');
    const {getCurrentDateTimeStringInUserFormat} = userDateFormat();
    return {
        currentFrameId: frameId,
        currentChannelNo: 1,
        program: {
            programId: createUUID(),
            programName: "[NEW Schedule] " + getCurrentDateTimeStringInUserFormat(),
            programType: '',
            backgroundMusic: "",
            description: "",
            deviceGroupIds: [],
            deviceType: "",
            deviceTypeVersion: 1,
            deviceTags:[],
            ipAddress: "",
            priority: 0,
            programGroupId: -1,
            programGroupName: '',
            resolution: {
                height: 1080,
                width: 1920
            },
            isDeployReserved: false,
            deployReservation: {},
            isContentSyncOn: false,
            isPlayFromLastPlaybackPoint: false,
            bgmContentId: '',
            bgmContentName: '',
            isBgmContentMute: false,
            channels: [
                {
                    channelName: channelName, channelNo: 1, frame: {
                        frameName: 'frame 0',frameIndex: 0, frameId: frameId, isMainFrame: true, x: 0, y: 0, width: 100, height: 100, lineData: 'ZeroFrameOnly', events: [], frames: []
                    }
                }
            ]
        }
    }
}


export const defaultMessage = {
    bgColor: "",
    bgImage: 0,
    bold: "N",
    color: "#000000",
    createDate: Moment().toDate(),
    direction: "none",
    duration: 0,
    font: "Arial",
    italic: "N",
    messageText: "",
    monthdays: "",
    position: "middle",
    repeatType: "once",
    size: "4",
    speed: "normal",
    startDate: Moment().format('YYYY-MM-DD'),
    endDate: Moment().format('YYYY-MM-DD'),
    startTime: "00:00:00",
    endTime: "00:00:00",
    underline: "N",
    userId: "",
    weekdays: ""
}

export const defaultMessageSchedule = (deviceType, deviceTypeVersion =1) => {
    const {getCurrentDateTimeStringInUserFormat} = userDateFormat();
    return {
        creatorId: "",
        deviceGroupIds: "",
        deviceGroupNames: "",
        deviceType: deviceType,
        deviceTypeVersion: deviceTypeVersion,
        isInstant: "Y",
        messageGroupId: -1,
        messageGroupName: "",
        messageId: createUUID(),
        messageName: "[NEW Message] " +getCurrentDateTimeStringInUserFormat(),
        messageList: [
            defaultMessage
        ]
    }
}

export const defaultCondition = () => {
    const eventConditionId = createUUID();
    return {
        bgColor: "",
        bgImage: "",
        isBold: false,
        channelNo: -1,
        color: "#000000",
        compBoolean: "",
        compNumberSign: "",
        compNumberValue: "",
        compString: "",
        compSubNumberSign: "",
        compSubNumberValue: "",
        conditionIndex: "",
        contentId: "",
        contentPlaylistName: "",
        direction: "NONE",
        eventId: "",
        font: "Arial",
        isItalic: false,
        messageText: "",
        playlistId: "",
        position: "MIDDLE",
        size: "MEDIUM",
        speed: "NORMAL",
        thumbFileId: "",
        thumbFileName: "",
        hasUnderline: false,

        eventConditionId: eventConditionId
    }

}

export const defaultEventCondition = {
    eventId: createUUID(),
    eventName: "",
    eventType: "TEXT",
    eventConditions: [],
    eventDescription: "",
    isDatalinkEnabled: false,
    datalinkInput: "",
    datalinkServerAddress: "",
}

export const defaultEventConditionForSchedule = {
    duration: 0,
    endConditionIndex: 0,
    eventId: "",
    onChannel: false,
    onContent: false,
    onMessage: false,
    startConditionIndex: 0,
    startDate: Moment().format('YYYY-MM-DD'),
    stopDate: Moment().format('YYYY-MM-DD')
}

export const defaultEventSchedule = () => {
    const scheduleId = createUUID();
    const {getCurrentDateTimeStringInUserFormat} = userDateFormat();
    return {
        conditionList: [
            defaultEventConditionForSchedule
        ],
        selectedEventList: [
            defaultEventConditionForSchedule
        ],
        deviceGroupIds: [],
        eventGroupId: -1,
        eventGroupName: "",
        eventScheduleName: "[NEW Event] " + getCurrentDateTimeStringInUserFormat(),
        eventScheduleId: scheduleId,
        currentEventConditionIndex: 0
    }

}

export const defaultADSchedule = {
    contentId: '',
    contentName: '',
    contentType: '',
    durationInSeconds: -1,
    fileSize: -1,
    isInfinitePlay: false,
    scheduleId: '',
    startDate: '',
    startTime: '00:00:00',
    stopDate: '',
    stopTime: '',
    thumbnails: []
}
export const inputSources = [
    {text: 'TV(Antenna)', value: 'DTV', icon: 'input_icon_1'},
    {text: 'HDMI 1', value: 'HDMI1', icon: 'input_icon_2'},
    {text: 'HDMI 2', value: 'HDMI2', icon: 'input_icon_3'},
    {text: 'HDMI 3', value: 'HDMI3', icon: 'input_icon_4'},
    {text: 'HDMI 4', value: 'HDMI4', icon: 'input_icon_4'},
    {text: 'AV', value: 'AV', icon: 'input_icon_6'},
    {text: 'Display Port', value: 'DISPLAY_PORT', icon: 'input_icon_7'},
    {text: 'Display Port2', value: 'DISPLAY_PORT2', icon: 'input_icon_7'},
    {text: 'DVI', value: 'DVI', icon: 'input_icon_8'},
    {text: 'Component', value: 'COMPONENT', icon: 'input_icon_9'},
    {text: 'S - Video', value: 'S-VIDEO', icon: 'input_icon_10'},
    {text: 'PC', value: 'PC', icon: 'input_icon_11'},
    {text: 'BNC', value: 'BNC', icon: 'input_icon_12'},
    {text: 'ATV', value: 'ATV', icon: 'input_icon_13'},
    {text: 'MagicInfo-Lite', value: 'MAGICINFO-LITE', icon: 'input_icon_14'},
    {text: 'MagicInfo', value: 'MAGICINFO', icon: 'input_icon_14'},
    {text: 'Plug In Module', value: 'PLUGIN_MODULE', icon: 'input_icon_15'},
    {text: 'Panel Off', value: 'PANEL_OFF', icon: 'input_icon_16'},
    {text: 'HDBaseT', value: 'HD_BASE_T', icon: 'input_icon_16'},
    {text: 'Web Browser', value: 'WEB_BROWSER', icon: 'input_icon_11'},
    /*{text: 'Web Browser', value: 'URL_LAUNCHER', icon: 'input_icon_11'},
    {text: 'WiFi', value: 'WIFI', icon: 'input_icon_11'},
    {text: 'Ext', value: 'EXT', icon: 'input_icon_11'},*/
]

export const MESSAGE_SCHEDULE_MULTIPLE_REPEAT_TYPE=[
    {id: 'message_repeat_once', value: 'once', title: "TEXT_ONCE_P"},
    {id: 'message_repeat_daily', value: 'daily', title: "TEXT_DAILY_P"},
    {id: 'message_repeat_weekly', value: 'day_of_week', title: "TEXT_WEEKLY_P"},
    {id: 'message_repeat_monthly', value: 'day_of_month', title: "TEXT_MONTHLY_P"}
]


export const MESSAGE_SCHEDULE_NOT_SUPPORT_MULTIPLE_REPEAT_TYPE=[
    {id: 'message_repeat_once', value: 'once', title: "TEXT_ONCE_P"}
]

export const SCHEDULE_FILTERS = {
    ALL_CONTENT_SCHEDULE : {
        force: false,
        page: 0,
        searchText: '',
        groupId: undefined,
        sorted: [{id: 'modifyDate', desc: true}],
        reload: false,
        scheduleTypes: ['LFD', 'VWL', 'SYNC', 'ADV'],
        devGroupIds: undefined,
        startModifiedDate: undefined,
        endModifiedDate: undefined
    },
    CONTENT_SCHEDULE_RECYCLE_BIN : {
        force: false,
        page: 0,
        keyword: '',
        sorted: [{id: 'modifyDate', desc: true}],
    },
    ALL_MESSAGE_SCHEDULE : {
        page: 0,
        searchText: '',
        groupId: undefined,
        sorted: [{id: 'modifyDate', desc: true}],
        reload: false,
        devGroupIds: undefined,
        startModifiedDate: undefined,
        endModifiedDate: undefined
    },
    MESSAGE_SCHEDULE_RECYCLE_BIN : {
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{id: 'modify_date', desc: true}],
        reload: false,
        searchText: undefined
    },
    ALL_EVENT_SCHEDULE : {
        force: false,
        page: 0,
        searchText: '',
        groupId: undefined,
        sorted: [{id: 'modifyDate', desc: true}],
        devGroupIds: undefined,
        startModifiedDate: undefined,
        endModifiedDate: undefined
    },
    EVENT_SCHEDULE_RECYCLE_BIN : {
        page: 0,
        keyword: '',
        sorted: [{id: 'lastdeployDate', desc: true}],
        reload: false
    },
    EVENT_SCHEDULE_MANAGER: {
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{id: 'modify_date', desc: true}],
        reload: false
    }

}