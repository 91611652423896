import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import EmptyImage7 from '../../../images/img/empty_img_7.png';
import classNames from 'classnames';

import InputSourceThumb1 from '../../../images/img/thumb_input_source_1.png';
import InputSourceThumb2 from '../../../images/img/thumb_input_source_2.png';
import InputSourceThumb3 from '../../../images/img/thumb_input_source_3.png';
import InputSourceThumb5 from '../../../images/img/thumb_input_source_5.png';
import InputSourceThumb6 from '../../../images/img/thumb_input_source_6.png';
import InputSourceThumb7 from '../../../images/img/thumb_input_source_7.png';
import InputSourceThumb8 from '../../../images/img/thumb_input_source_8.png';
import InputSourceThumb9 from '../../../images/img/thumb_input_source_9.png';
import InputSourceThumb10 from '../../../images/img/thumb_input_source_10.png';
import InputSourceThumb13 from '../../../images/img/thumb_input_source_13.png';
import InputSourceThumb11 from '../../../images/img/thumb_input_source_11.png';
import InputSourceThumb15 from '../../../images/img/thumb_input_source_14.png';
import {inputSources} from "../../../constants";
import {useMISOpt} from "../../misopt";


export const InputSources = ({deviceType, deviceTypeVersion, onSelect, selectedId}) => {

    const [selected, setSelected] = useState({
        text: '',
        icon: EmptyImage7
    });

    const {misOptionSchedule} = useMISOpt();

    const inputSourceList = useCallback(()=>{
        const scheduleInfo = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion);
        if (scheduleInfo !== undefined) {
            const {contentSchedule : {contents}} = scheduleInfo;
            if (contents !== undefined && contents.inputSources !== undefined) {
                const rtnInputSources = [];
                contents.inputSources.map(input =>{
                    const source = inputSources.find(i => i.value === input);
                    if (source){
                        rtnInputSources.push(source)
                    }
                });
                return rtnInputSources;
            }
        }
        return inputSources;
    }, [])

    const inputSource = inputSourceList();

    const {t} = useTranslation();

    const RenderLi = ({data, icon, text, onClick, selectedId}) => {

        return (
            <li className="more_list" onClick={onClick} className={classNames({'on' : selectedId === data})}>
                <a href="#"><span className={icon}></span>{text}</a>
            </li>
        )
    }

    const onClick = (id, name) => {
        const input = inputSource.find(i => i.value === id);
        setSelected({text: input.text, icon : input.icon});
        
        if (onSelect) {
            onSelect(id, name, '','','','HW_IS');
        }
    }

    const getIcon = (icon) => {
        
        switch (icon) {
            case 'input_icon_1':
                return (InputSourceThumb1)
            case 'input_icon_2':
                return (InputSourceThumb2)
            case 'input_icon_3':
                return (InputSourceThumb2)
            case 'input_icon_4':
                return (InputSourceThumb2)
            case 'input_icon_5':
                return (InputSourceThumb5)
            case 'input_icon_6':
                return (InputSourceThumb3)
            case 'input_icon_7':
                return (InputSourceThumb5)
            case 'input_icon_8':
                return (InputSourceThumb8)
            case 'input_icon_9':
                return (InputSourceThumb6)  // -> component
            case 'input_icon_10':
                return (InputSourceThumb9)
            case 'input_icon_11':
                return (InputSourceThumb10)
            case 'input_icon_12':
                return (InputSourceThumb11)
            case 'input_icon_13':
                return (InputSourceThumb1)
            case 'input_icon_14':
                return (InputSourceThumb13)
            case 'input_icon_15':
                return (InputSourceThumb15)
            case 'input_icon_16':
                return (InputSourceThumb7)
        }
        return (
            EmptyImage7
        )
    }

    return (
        <div>
            <div className="input_source_wrap" id={'select_content_scroll_wrap'} >
                <ul id="inputSourceWrapIconLists">
                    {
                        inputSource.map(
                            input=><RenderLi selectedId={selectedId} data={input.value} icon={input.icon} text={input.text} onClick={()=>onClick(input.value, input.text)}/>
                        )
                    }
                </ul>
            </div>
            <div className="schedule_content_wrap">
                <div className="pop_list" style={{height: 330, borderTop: 0, overflowY: 'hidden'}}>
                    <div className="list_wrap_empty noline">
                            {
                                selected !== undefined &&
                                <div>
                                    <img src={getIcon(selected.icon)} style={{marginTop:75}}/>
                                    <span className="empty_title">{selected.text}</span>
                                    <span>{t("MIS_SID_20_SELECT_THE_INPUT_SOURCE_YOULL_BE_USING_ON_YOUR_DISPLAY")}</span>
                                </div>
                            }
                    </div>
                    <div className="input_source_txt">
                        {t("COM_BDP_SID_INPUT_POPUP_DESCRIPTION_1_3_MSG")}
                    </div>
                </div>
            </div>
        </div>
    )

}