import React, {useRef, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import DataLinkInformation from "./DataLinkInformation";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../../actions";
import {settingConstants} from "../../../../constants";
import {validatePort} from "../../../../helper";

const DataLinkPopup = ({onClose, datalinkInfo, updateServerList}) => {

    const originalServerName = useRef(datalinkInfo?datalinkInfo.serverName:null);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const title = datalinkInfo?datalinkInfo.serverName:t('MIS_SID_20_DATALINK_SERVER');
    const mode = datalinkInfo?'EDIT':'ADD';
    const [datalinkPopupInfo, setDatalinkPopupInfo] = useState(datalinkInfo?datalinkInfo:{});
    const [nameChecked, setNameChecked] = useState(false);
    const {
        serverName,
        ipAddress,
        port,
        period,
        ftpPort,
        bypass,
        useSsl,
        privateMode,
        privateIpAddress,
        privateWebPort} = datalinkPopupInfo;


    const updateDatalinkInfo = (obj) => {
        setDatalinkPopupInfo({
            ...obj
        });
    };

    const datalinkPortCheck = () => {
        let port;
        let ftp_port_check;
        let private_port;

        port = validatePort(datalinkPopupInfo.port);
        ftp_port_check = validatePort(datalinkPopupInfo.ftpPort);
        if(datalinkPopupInfo.privateMode){
            private_port = validatePort(datalinkPopupInfo.privateWebPort);
            return port && private_port && ftp_port_check;
        }
        return port && ftp_port_check;
    };


    const datalinkNullCheck = () =>{
        if(privateMode && (!privateIpAddress || privateIpAddress === '' || privateIpAddress === null))
            return false;

        return !(serverName === null || serverName === undefined || serverName === "" ||
            ipAddress === null || ipAddress === undefined || ipAddress === "" ||
            port === null || port === 0 || port === undefined || port === "" ||
            ftpPort === null || ftpPort === 0 || ftpPort === undefined || ftpPort === ""
            || period === null || period === undefined || period==="");

    };

    const datalinkNameCheck = () => {
        // if there has been a name change but name_check is not operated, return false
        return nameChecked;
    };

    const datalinkPeriodCheck = () => {
        return !(period<0 || period > 999999999);
    };

    const addDataLink = () => {
        if(!serverName || serverName === ''){
            toastr.error(t('MIS_MESSAGE_SETUP_ENTER_SERVERNAME_P'));        
            return;
        }

        if(!datalinkNameCheck()){
            toastr.error(t('MIS_TEXT_SETUP_ASK_CHECK_SERVERNAME_P'));
            return;
        }

        if(!datalinkPortCheck()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        if(!datalinkPeriodCheck()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        if(!datalinkNullCheck()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        settingService.addDataLink(datalinkPopupInfo)
            .then(res=>{
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                updateServerList(datalinkPopupInfo, true);
            })
            .catch(err=>{
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
            });
        dispatch(popupAction.closePopup(settingConstants.EDIT_DATALINK_SERVER_POPUP));
    };
    const editDataLink = () => {

        if(!datalinkNullCheck()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        if(!datalinkPortCheck()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        settingService.editDataLink(datalinkPopupInfo)
            .then(res=>{
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                updateServerList(datalinkPopupInfo, false);
            })
            .catch(err=>{
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
            });
        dispatch(popupAction.closePopup(settingConstants.EDIT_DATALINK_SERVER_POPUP));
    };


    const onSaveDataLink = async () => {
        switch(mode){
            case 'ADD':
                await addDataLink();
                break;
            case 'EDIT':
                await editDataLink();
                break;
            default:
                toastr.error(t('TEXT_TITLE_ERROR_P'));
        }
    };


    return(
        <MISDialog
            dialog={{
                title:title,
                modal:true,
                closeOnEscape: true,
                width: 400,
                //height: datalinkPopupInfo.private_mode?384:314,
                onClose: onClose}}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: ()=> onSaveDataLink(),
                        disable: false
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}
        >
        <DataLinkInformation
            datalinkInfo = {datalinkInfo}
            updateDatalinkInfo = {updateDatalinkInfo}
            mode={mode}
            setNameChecked={setNameChecked}
        />
        </MISDialog>
    )
};


export default DataLinkPopup;
