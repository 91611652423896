import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {statisticsService} from '../../services';
import {connect, useDispatch} from 'react-redux';
import './Statistics.css';
import './ConnectionHistory.css';
import '../../components/table/react-table.css';
import SelectBox from '../../components/selectbox/SelectBox';
import {StatisticsCalendar} from './StatisticsCalendar';
import Moment from 'moment';
import {commonConstants} from '../../constants';
import {popupAction} from '../../actions';
import ReactTable from 'react-table';
import {toastr} from 'helper/toastrIntercept';
import bullet_2 from "../../images/icon/bullet_2.png"
import fileDownload from "js-file-download";
import WhiteButton from "../../components/button/WhiteButton";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {cloneDeep} from "lodash";
import {getErrorMessage} from "../../helper/responseHandler";

const ConnectionHistory = ({currContent}) => {
    const {t} = useTranslation();

    const user = JSON.parse(localStorage.getItem('user'));

    const dispatch = useDispatch();

    const timeOptions = [
        {value: 'aWeekAgo', title: 'COM_MESSAGE_STATISTICS_MENU_A_WEEK_AGO_P'},
        {value: 'aMonthAgo', title: 'MESSAGE_STATISTICS_MENU_A_MONTH_AGO_P'},
        {value: 'threeMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_THREE_MONTH_AGO_P'},
        {value: 'sixMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_SIX_MONTH_AGO_P'},
        {value: 'aYearAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_A_YEAR_AGO_P'},
        {value: 'custom', title: 'COM_SID_CUSTOM'},
    ];

    const sortColumns = [
        {id: 'macAdd', value: 'macAdd'},
        {id: 'deviceName', value: 'deviceName'},
        {id: 'deviceGroup', value: 'deviceGroup'},
        {id: 'connected', value: 'connected'},
        {id: 'disconnected', value: 'disconnected'},
        {id: 'duration', value: 'duration'},
    ];

    const [filter, setFilter] = useState({
        sorted: [{id: sortColumns[5].id, desc: true}],
    });

    const [request, setLastRequest] = useState({
        groupIds: undefined,
        startDate: undefined,
        endDate: undefined,
    });
    const [loadingbar, setLoadingBar] = useState(false);

    const [data, setData] = useState({
        groups: [],
        selected: [],
        items: [],
        loading: false,
        exportEnable: false,
    });
    const initEndDate = new Date();
    const initStartDate = new Date();
    initStartDate.setDate(initEndDate.getDate() - 6);
    const [period, setPeriod] = useState({time: undefined, startDate: initStartDate, endDate: initEndDate, disabled:true, title:""});

    const onClickExport = (docType) => {
        setLoadingBar(true);
        const {groupIds, startDate, endDate} = request;
        statisticsService.fetchStatisticsDevicesFileDownload(docType, {
            data: "connectionHistoryTable",
            startDate: startDate,
            endDate: endDate,
            groupIds: groupIds,
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    const onClickSearch = () => {
        const groupIds = data.groups.map(group => Number(group.groupId));
        const {startDate, endDate} = period;
        if (groupIds && groupIds.length > 0) {
            setData({...data, loading: true});
            const prams = {
                data: 'connectionHistoryTable',
                groupIds,
                startDate: Moment(startDate).format('YYYY-MM-DD'),
                endDate: Moment(endDate).format('YYYY-MM-DD'),
            };
            statisticsService.fetchStatisticsDevices(prams).then(res => {
                setData({...data, loading: false, items: res.items, exportEnable: true});
                setLastRequest({
                    ...request,
                    groupIds: cloneDeep(groupIds),
                    startDate: Moment(startDate).format('YYYY-MM-DD'),
                    endDate: Moment(endDate).format('YYYY-MM-DD'),
                });
            }).catch(err => {
                toastr.error(getErrorMessage(err, err.errorMessage))
            });
        } else {
            toastr.warning(t('MESSAGE_SCHEDULE_SELECT_DEV_GROUP_P'));
        }
    };

    const onClickTimeOption = (time) => {
        let startDate = new Date();
        let endDate = new Date();
        let disabled = true;
        switch (time) {
            case 'aWeekAgo':
                startDate.setDate(endDate.getDate() - 7);
                break;
            case 'aMonthAgo':
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case 'threeMonthAgo':
                startDate.setMonth(endDate.getMonth() - 3);
                break;
            case 'sixMonthAgo':
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case 'aYearAgo':
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            default:
                disabled = false;
                break;
        }
        setPeriod({time, startDate, endDate, disabled});
    };

    const saveGroup = (groups) => {
        if(groups === undefined || groups.length <= 0) {
            toastr.error(t("MESSAGE_COMMON_SELECT_GROUP_P"));
            return;
        }

        dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION));
        setData({...data, groups});
    };

    const onClickGroup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            checkedKeys: data.groups.map((group)=> group.groupId),
            selectAllUse: true,
            save: saveGroup,
            close: () => dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION)),
        }));
    };

    const deleteDeviceGroup = (id) => {
        let {groups} = data;
        const idx = groups.findIndex(function (item) {
            return item.groupId === id;
        });
        if (idx > -1) {
            groups.splice(idx, 1);
            setData({...data, groups});
        }
    };

    const onSortedChange = (newSorted, column, additive) => {
        const [{id, desc}] = newSorted;
        setFilter({...filter, sorted: [{id, desc}]});
    };

    const getGroupName = (group) => {
        return typeof(group.groupName) === 'object' ? group.groupNameText : group.groupName;
    }

    const {items = [], loading = false} = data;
    const {sorted} = filter;

    const columns = useMemo(() => [
        {
            Header: t('TABLE_MAC_ADDR_P'),
            accessor: 'macAdd',
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
        },
        {
            Header: t('TEXT_DEVICE_GROUP_P'),
            accessor: 'deviceGroup',
        },
        {
            Header: t('COM_MIS_TEXT_CONNECTED_P'),
            accessor: 'connected',
            Cell: (props) => 
            <><DeviceRelativeTimeCell value={props.original.connected && props.original.connected.length > 0 ? Moment(props.original.connected) : null}/>
            {
                props.original.bootReason &&
                <span>{props.original.bootReason}</span>
            }
            </>,
        },
        {
            Header: t('TEXT_DISCONNECTED_P'),
            accessor: 'disconnected',
            Cell: (props) => 
            <><DeviceRelativeTimeCell value={props.original.disconnected && props.original.disconnected.length > 0 ? Moment(props.original.disconnected) : null}/>
            {
                props.original.shutdownReason &&
                <span>{props.original.shutdownReason}</span>
            }
            </>,
        },
        {
            Header: t('TABLE_DURATION_P'),
            accessor: 'duration',
        },
    ]);

    const noDataComponent = () => {
        return <div style={{position: 'absolute', left: '50%', top: '50%', color: '#59616b'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
    };

    return (
        <div style={{height: '100%', display: currContent === 'CONNECTION_HISTORY' ? 'block':'none'}} >
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton disable={data.exportEnable?false:true} id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => onClickExport('EXCEL')}/>
                </div>
            </div>
            <div className="contents_buttonWrap" style={{height:'95px'}}>
                <div style={{width: '669px', height: 'inherit'}}>
                    <span className='statistics_period float_l'><img src={bullet_2} alt={'*'}/>{t('TEXT_DEVICE_GROUP_P')}</span>
                    <ul className='float_l' style={{height: '100%', border:'1px solid #e5e5e5', overflowY:'scroll', width:'470px'}}>
                        {data.groups && data.groups.length > 0 &&
                        (data.groups).map((group, i) => (
                            <li key={i} style={{display: 'flex', borderBottom:'1px solid #e5e5e5', lineHeight:'30px', paddingLeft:'15px'}}>
                                <span style={{flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} title={getGroupName(group)}>{getGroupName(group)}</span>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <button className="selectedDeviceDelBtn" type="hidden" value={group.groupId} onClick={() => deleteDeviceGroup(group.groupId)}/>
                                </div>
                            </li>
                        ))
                        }
                    </ul>                    
                    <button className="base float_l w72" onClick={onClickGroup}>{t('TEXT_SELECT_P')}</button>
                </div>
            </div>

            <div className="contents_buttonWrap">
                <div style={{width: '669px', height: 'inherit'}}>
                    <span className='statistics_period float_l'>{t('TEXT_PERIOD_P')}</span>
                    <SelectBox defaultTitle={t('MESSAGE_UPLOADER_SEL_PERIOD_P')} value={period.time} multiLang={true} classname='float_l mr8' width={209}
                               selects={timeOptions} onClick={onClickTimeOption}/>
                    <StatisticsCalendar date={period}
                                        dateFormat={user ? user.dateFormat : "yyyy-MM-dd"}
                                        disabled={period.time !== 'custom'}
                                        onChangeStart={(startDate) => {
                                            if (Moment(startDate).isBefore(Moment(period.endDate))) {
                                                setPeriod({...period, startDate})
                                            } else {
                                                let endDate = startDate;
                                                setPeriod({...period, startDate, endDate});
                                            }
                                        }}
                                        onChangeEnd={(endDate) => {
                                            if (Moment(period.startDate).isBefore(Moment(endDate))) {
                                                setPeriod({...period, endDate})
                                            } else {
                                                let startDate = endDate;
                                                setPeriod({...period, startDate, endDate});
                                            }
                                        }}
                                        disabled={period.disabled}/>
                    <button className="base float_l w72" onClick={onClickSearch}>{t('BUTTON_SEARCH')}</button>
                </div>
            </div>
            {
                request.groupIds && request.groupIds.length > 0 &&
                <>
                    <div className="contents_buttonWrap">
                        <div className="contents_buttonwrap mt20"><h4>{t('COM_MIS_MESSAGE_STATISTICS_LEFT_MENU_SUB_CONNECTION_HISTORY_P')}</h4></div>
                    </div>
                    <div className='connect_history_view'>
                        <ReactTable
                            data={items}
                            loading={loading}
                            minRows={0}
                            sorted={sorted}
                            pageSize={items.length}
                            NoDataComponent={noDataComponent}
                            getTbodyProps={() => {
                                return {
                                    className: 'connect_history_tr_body',
                                };
                            }}
                            getTrGroupProps={() => {
                                return {
                                    className: 'connect_history_tr_group',
                                };
                            }}
                            getTdProps={(state, rowInfo, colInfo) => {
                                return {
                                    className: 'connect_history_td_props',
                                };
                            }}
                            onSortedChange={onSortedChange}
                            columns={columns}
                            showPagination={false}
                            style={{width: '100%', height: '100%', borderLeft: 'none', borderRight: 'none', borderBottom: 'none'}}/>
                    </div>
                </>
            }
            {loadingbar && <LoadingBar />}
        </div>
    );
};

export default connect()(ConnectionHistory);
