import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import './SelectSupportedDevice.css';
import { useMISOpt} from "../misopt";
import { device } from 'reducers/device.reducer';
import {MEDIA_TYPE_CHECK_FOR_WPLAYER_ICON} from "../../constants";

const SelectSupportedDevice = ({type, deviceType, deviceTypeVersion, onChange, selectedDevice, isAplayerSupport, isWplayerSupport, mediaType = undefined}) => {
    const [devices, setDevices] = useState([]);
    const [selected, setSelected] = useState([]);
    const [receivedDevice, setReceivedDevice] = useState({priority: 1});

    const {supportedIconDevices, misOptionFindHigherVersion} = useMISOpt();

    useEffect(()=>{
        const devices = supportedIconDevices;
        if (type === 'addToPlaylist') {
            const option = misOptionFindHigherVersion(deviceType.toUpperCase(), deviceTypeVersion);
            const priority = option !== undefined ? option.priority : 1;
            setReceivedDevice({priority});
        }
        setDevices(devices)
    }, [deviceType, deviceTypeVersion])

    const selectDevice = (idx) => {

        let selected = [];
        if (devices[idx].priority < 0) {
            selected.push(idx);
        } else {
            devices.map(
                (device, index) => idx >= index ? selected.push(index) : ''
            )
        }
        setSelected(selected);
        if (onChange !== undefined) {
            const device = devices[idx];
            onChange(device);
        }
        if (selectedDevice !== undefined) {
            selectedDevice(devices, selected);
        }
    }

    const getLastIndexRuleset = () => {
        let lastIndex = 0;
        devices.forEach((device,index) => {
            if(device.ruleset) {
                lastIndex++;
            }
        });
        return lastIndex;
    }

    const checkDisable = (device) => {
        let isDisable = false;

        if(device.deviceType === 'APLAYER' && isAplayerSupport) {
            return false;
        }
       
        //SF[00213907]- To check LFD content with Lite player
        const media= mediaType && mediaType.find((item) => item==='LFD');
        if(media && device.deviceType === 'LPLAYER')
            return true;
        

        mediaType && mediaType.map((type)=> {
            if (MEDIA_TYPE_CHECK_FOR_WPLAYER_ICON.includes(type) && deviceType === 'WPLAYER') {
                isDisable = true;
            }
        })
        if (deviceType === 'WPLAYER' && receivedDevice.priority === -2 && device.deviceType !== 'WPLAYER') {
            return true;
        }
        return receivedDevice.priority > device.priority && device.deviceType !== 'WPLAYER' || !isWplayerSupport && device.deviceType === 'WPLAYER' || isDisable && device.deviceType !== 'WPLAYER'
    }

    return(
        <div className={"select_support_devices_wrap"}>
            {devices.length > 0 &&
                devices.map(
                    (device, index) => {
                        if (type === 'schedule') {
                            if (device.contentSchedule) {
                                return <button key={index} className={classNames(device.className, {
                                    'first': index === 0,
                                    'last': index === devices.length -1,
                                    on: selected.includes(index)
                                })} deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion}
                                               onClick={() => selectDevice(index)}>{device.text}</button>
                            }
                        }else if (type === 'message') {
                            if (device.messageSchedule) {
                                return <button key={index} className={classNames(device.className, {
                                    'first': index === 0,
                                    'last': index === devices.length - 1,
                                    on: selected.includes(index)
                                })} deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion}
                                               onClick={() => selectDevice(index)}>{device.text}</button>
                            }
                        } else if (type === 'playlist') {
                            if (device.playlist) {
                                return <button key={index} className={classNames(device.className, {'first': index === 0, 'last' : index === devices.length-1, on : selected.includes(index)})} deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion} onClick={()=>selectDevice(index)}>{device.text}</button>
                            }
                        } else if (type === 'ruleset') {
                            if (device.ruleset) {
                                return <button key={index} className={classNames(device.className, {
                                    'first': index === 0,
                                    'last': index === getLastIndexRuleset() - 1,
                                    on: selected.includes(index)
                                })} deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion}
                                               onClick={() => selectDevice(index)}>{device.text}</button>
                            }
                        } else if (type === 'addToPlaylist') {
                            if (device.playlist) {
                                return <button key={index} disabled={checkDisable(device)}
                                               className={classNames(device.className, {'first': index === 0, 'last' : index === devices.length-1, on : selected.includes(index), disable: checkDisable(device)})}
                                               deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion} onClick={()=>selectDevice(index)}>{device.text}</button>
                            }
                        }
                    }
                )
            }
        </div>
    )
    
}
export default SelectSupportedDevice;