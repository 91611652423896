import React, {useEffect, useMemo, useState} from 'react'
import {settingService} from "../../services";
import WhiteButton from "../../components/button/WhiteButton";
import {Trans, useTranslation} from "react-i18next";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import Checkbox from "../../components/checkbox/Checkbox";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useDispatch} from "react-redux";
import {popupAction} from "../../actions";
import {commonConstants, SETTING_PAGE_SIZE_OPTIONS, settingConstants} from "../../constants";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";
import {snakeCase} from "lodash";
import {useTrGroupProps} from "../../helper/tables";

const RemoteServers = (props) =>{
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('setting', SETTING_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [{id: 'serverName', desc: false}],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const setRmMainViewData = (serverToUpdate, isAdded) => {
        fetchData();
        /*
        if(isAdded) {
            setData({
                ...data,
                totalCount: data.totalCount + 1,
                [items]: data.items.push(serverToUpdate)
            });
        }else{
            let originalIndex = data.items.findIndex(rmServer=>serverToUpdate.serverName===rmServer.serverName);
            data.items.splice(originalIndex, 1);
            setData({
                ...data,
                [items]:data.items.splice(originalIndex, 0, serverToUpdate)
            });            
        }*/
    };

    useEffect(()=>{
        const handleResize = () => {
            setStyle({height: window.innerHeight - 203});
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }           
    }, []);

    useEffect(()=>{
        fetchData();
    }, [filter]);

    useEffect(()=>{
        //updateRemoteState();
    }, [items]);


    const remoteControlsPopup = (rmInfo) => {
        dispatch(popupAction.addPopup({
            type: settingConstants.EDIT_REMOTE_CONTROL_SERVER_POPUP,
            id: settingConstants.EDIT_REMOTE_CONTROL_SERVER_POPUP,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.EDIT_REMOTE_CONTROL_SERVER_POPUP)),
            rmInfo: rmInfo,
            setRmMainViewData: (obj, isAdded)=>setRmMainViewData(obj, isAdded)
        }));
    };

    const addRemoteServer = () => {
        remoteControlsPopup();
    };

    const deleteRemoteServerPopup = () => {
        let serverNameList = [];
        let checkCount = 0;

        checkBoxRefs.current.forEach(checkbox => {
            if(checkbox.checked){
                checkCount ++;
                serverNameList = [...serverNameList, checkbox.getAttribute('data-name')];
            }
        });

        if(checkCount < 1){
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
            return;
        }

        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("ALERT_WANT_DELETE"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => deleteRemoteServers(serverNameList),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));
    };

    const deleteRemoteServers = (serverNameList) => {
        let promiseAll = [];
        let count = 0;

        serverNameList.forEach(serverName=>{
            const promise = settingService.deleteRemoteControls(serverName).then(res => {
                count += res.items;
            });
            promiseAll.push(promise);
        });

        Promise.all(promiseAll).then(() => {
            toastr.success(t('TEXT_SUCCESS_P'));
            fetchData();
        }).catch((err) => {
            toastr.error(t('TEXT_FAIL_P') + ':' + getErrorMessage(err));
        }).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
        });        
    };

    const editRemoteServer = (rmInfo) => {
        remoteControlsPopup(rmInfo);
    };

    const fetchRemoteControls = () => {
        const {page, pageSize, sorted: [{id, desc}], organId} = filter;
        settingService.fetchRemoteControls({
            startIndex: page * pageSize + 1,
            pageSize,
            sortColumn : snakeCase(id),
            sortOrder : desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            //res.items.length <= 0 && page > 0 && onPageChange(page - 1);
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages:Math.ceil(res.totalCount / pageSize)}); //, pages:Math.ceil(res.totalCount / pageSize)
        }).catch(error => setData([]))
    };

    const updateRemoteState = () => {
        if(data.items !== undefined && data.items !== null && data.items.length > 0){
            data.items.forEach(rmserver=>{
                settingService.getRemoteStatus(rmserver.serverName)
                    .then(res=>{
                        if(res.items === 'ON')
                            rmserver.status="ON";
                        else
                            rmserver.status="OFF";
                    }).catch(err=>{
                        rmserver.status="OFF";
                    }).finally(()=>{
                        setData({...data, items:data.items});
                    })
            });
        }
    };

    const fetchData = async (state, filter) => {
        const data = {
            startIndex: page*pageSize + 1,
            pageSize,
            sortColumn: sorted[0].id,
            sortOrder: sorted[0].desc ? 'desc' : 'asc',
            filtered: []
        };
        setData({...data, loading: true});
        await fetchRemoteControls(data);
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id='RemoteControlServer_All'
                        classname="table"
                        name="check"
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={'RemoteControlServer_' + row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        propertyName={row.original.serverName}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 40,
            sortable: false,
            resizable: false,
            style: {"testAlign":"center"}
        },
        {
            Header: t("TABLE_SERVER_NAME_P"),
            Cell: (row) => {
                return(
                    <span className="data_name" onClick={() => editRemoteServer(row.original)}>
                        {row.original.serverName}
                    </span>
                )
            },
            accessor: "serverName",
            width: 300
        },
        {
            Header: t("TABLE_IP_ADDR_P"),
            accessor: 'ipAddress',
            width: 300
        },
        {
            Header: t("TABLE_WEB_PORT_P"),
            accessor: "port",
            width: 200
        },
        {
            Header: t("MIS_SID_PRIVATE_IIP_ADDRESS"),
            accessor: "privateIpAddress",
            Cell: (row) =>{
                return <span>{row.original.privateMode?(row.original.privateIpAddress?row.original.privateIpAddress:'-'):'-'}</span>
            },
            width: 200
        },
        {
            Header: "SSL" ,
            accessor: "useSsl",
            Cell: (row) =>{
                return <span>{row.original.useSsl?t('BUTTON_YES_P'):t('BUTTON_NO_P')}</span>
            },
            width: 200
        },
        {
            Header: t("TEXT_STATUS_P"),
            sortable: false,
            accessor: "status",
            Cell: (row) => {
                return <span>{row.original.status}</span>
            },
            minWidth: 100
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow);

    return (
        <div style={{width: '100%', display: props.currContent === 'REMOTE_CONTROL_SERVER' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"REMOTE_CONTROL_ADD"} name={t("COM_BUTTON_ADD")} onClick={()=>addRemoteServer()}/>
                    <WhiteButton id={"REMOTE_CONTROL_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={()=>deleteRemoteServerPopup()} />
                </div>
            </div>

            <div className={"device_list_view_wrap"} style={{width: '100%'}}>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    style={style}
                    usePagination={true}
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        pageSize: pageSize,
                        pageSizeOptions: SETTING_PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: "setting"
                    }}
                />
            </div>
        </div>
    );
};

export default RemoteServers;