import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import '../../../components/table/react-table.css';
import MISDialog from '../MISDialog'
import ReactTable from "react-table";
import {renderExpireDateInFormat} from "../../../helper";
import {useMISOpt} from "../../../components/misopt";


const ExpirationContentPopup = React.forwardRef(({onClose, expiredContentList}, ref) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat}}} = useMISOpt();
    const contentColumns = useMemo(() => [
        {
            Header: t("TEXT_CONTENT_NAME_P"),
            accessor: "contentName",
            Cell: (props) => <span style={{textAlign:'center'}} className={"data_name"}>{props.original.contentName}</span>,
            width: 127
        },
        {
            Header: t("COM_EXPIRATION_DATE_KR_DATE"),
            accessor: "expiredDate",
            Cell: props => <div><span style={{textAlign:'center'}}>{renderExpireDateInFormat(props.original.expiredDate,dateFormat.toUpperCase())}</span></div>
        }
    ], []);

    return (
        <MISDialog
            dialog={{
                title: t("TEXT_CONTENT_P"),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 650,
                height: 373,
                onClose: onClose,
                zIndex:2002
            }}
            buttons={{
                rightButtons: [
                    {
                        title: t('COM_BUTTON_CONFIRM'),
                        onClick: onClose,
                    }
                ]
            }}>
                <div>
                    <div className="notify_wrap fc_999999 mt20">{t("MIS_SID_EXPIRED_CONTETN_NOT_ADDED")}</div>
                    <div className={'mt11 list pop_list'} style={{width:600, height:300 , overflowY: 'auto', overflowX: 'hidden'}}>
                        <ReactTable
                            data={expiredContentList}
                            minRows={4}
                            columns={contentColumns}
                            style={{width:'100%'}}
                            className="-striped -highlight"
                            showPagination={false}
                        />
                    </div>
                </div>
        </MISDialog>
    );
});

export default ExpirationContentPopup;