import React from 'react';
import {useTranslation} from 'react-i18next';

const NoDataRow = ({show, colSpan, className, align = 'center'}) => {
    const {t} = useTranslation();
    if(show) {
        return (
            <tr>
                <td colSpan={colSpan} className={className} style={{textAlign:align}} >{t('MESSAGE_COMMON_NO_DATA_P')}</td>
            </tr>
        );
    } else {
        return null;
    }
};

export default NoDataRow;