import React from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {isNil, toInteger} from 'lodash';
import Moment from 'react-moment';
import TextInput from '../../../input/TextInput';
import Checkbox from '../../../checkbox/Checkbox';
import WhiteButton from '../../../button/WhiteButton';
import Size from '../../../utils/Size';
import {APLAYER, IPLAYER, SIGNAGE_CHILD} from '../../../../constants';
import {useMISOpt} from '../../../misopt';
import {toMomentDateTimeFormat} from '../../../../helper';
import {connect} from "react-redux";
import {popupAction} from "../../../../actions";
import {commonConstants} from "../../../../constants";
import {deviceService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from '../../../../helper/responseHandler';

const EditInformation = ({
    deviceIds,
    generalInfo,
    onChange,
    reloadTabData,
    addPopup,
    closePopup
}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const {data: {deviceName, deviceType, macAddress, ipAddress, deviceModelName, location, mapLocation, sendCleanStorageFlag,
        playerVersion, firmwareVersion, osImageVersion, cpuType, memSize, hddSize, videoAdapter, videoMemory,
        videoDriver, diskSpaceUsage = '', diskSpaceRepository, diskSpaceAvailable = '', createDate, screenSize, resolution, 
        ewfState, deviceModelCode, serialDecimal, hasE2ELicense, recommendPlay, thirdApplicationVersion, thirdApplicationLastUpdated}, changed, errors} = generalInfo || {};

    const onChangeSendCleanStorageFlag = e => {
        onChange(e, e.target.checked);
    };

    const onChangeRecommendPlayFlag = e => {
        onChange(e, e.target.checked);
    };

    const onClickUninstall = () => {
        addPopup({
            id: commonConstants.COMMON_CUSTOM_POPUP,
            type: commonConstants.COMMON_CUSTOM_POPUP,
            height: '120px',
            title: t('COM_LFD_SID_KIOSK_CANOV_UNINSTALL_KIOSK_APP'),
            message: <div>
                <div>{t('COM_LFD_SID_KIOSK_CANOV_UNINSTALL_KIOSK_APP_REINTALL')}</div><div><br/></div>
                <div>{t('COM_LFD_SID_KIOSK_CANOV_SLELECT_KEEP_REUSE_REINSTALLED')}</div><div>{t('COM_LFD_SID_KIOSK_CANOV_DELELTE_REMOVE_DATA_APP')}</div>
            </div>,
            buttons: {
                rightButtons: [
                    {id: 'CUSTOM_KEEP', title: t('COM_TV_SID_HEALTH_CAFEB_KEEP'), onClick: () => {
                            requestUninstall(true);
                            closePopup(commonConstants.COMMON_CUSTOM_POPUP);
                        }
                    },
                    {id: 'CUSTOM_DELETE', title: t('COM_BUTTON_DELETE'), onClick: () => {
                            requestUninstall(false);
                            closePopup(commonConstants.COMMON_CUSTOM_POPUP);
                        }
                    },
                    {id: 'CUSTOM_CANCEL', title: t('COM_BUTTON_CLOSE_P'), onClick: () => closePopup(commonConstants.COMMON_CUSTOM_POPUP)}
                ]
            },
            onClose: () => closePopup(commonConstants.COMMON_CUSTOM_POPUP)
        });
    }

    const requestUninstall = keepData => {
        const option = keepData?'KEEP':'DELETE';
        deviceService.sendProcessCommand(deviceIds, 'UNINSTALL_THIRD_APPLICATION', option).then(res => {
            if(res && res.status === 'Success') {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'))
            } else {
                toastr.error(t('ALERT_FAIL'));
            }
        }).catch(error => toastr.error(getErrorMessage(error))).finally(() => {
            reloadTabData(0);
        });
    }

    const firmwareVersions = isNil(firmwareVersion) ? [] : firmwareVersion.split(';');
    const thirdApplicationVersionInfo = isNil(thirdApplicationVersion)? [] : thirdApplicationVersion.split(';');
    const thirdApplicationUninstalling = thirdApplicationVersionInfo.length > 1 && thirdApplicationVersionInfo[1].toLowerCase() === 'uninstalling';
    const thirdApplicationNotInstalled = thirdApplicationVersionInfo.length > 0 && thirdApplicationVersionInfo[0] === '';

    return (
        <div className='device_edit_panel_wrap mt28'>
            <div className="devicepop mb26">
                <h3>{t('COM_SID_BASIC_INFORMATION')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className={classNames({'changed': changed['deviceName'] === true})}>{t('TABLE_DEVICE_NAME_P')}</th>
                                <td>
                                    <TextInput 
                                        width={200} 
                                        propertyName='deviceName' 
                                        errorWidth={530} 
                                        value={deviceName || ''} 
                                        error={errors['deviceName']}
                                        onChange={onChange} 
                                        maxLength={50} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_MAC_ADDR_P')}</th>
                                <td>
                                    {
                                        deviceIds.length === 1 ? deviceIds[0] : deviceIds.join(',')
                                    }
                                </td>
                            </tr>
                            {
                                deviceType !== SIGNAGE_CHILD &&
                                <tr>
                                    <th>{t('TABLE_IP_ADDR_P')}</th>
                                    <td>{ipAddress}</td>
                                </tr>
                            }
                            <tr>
                                <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                                <td>{deviceModelName}</td>
                            </tr>
                            <tr>
                                <th className={classNames({'changed': changed['location'] === true})}>{t('TABLE_LOCATION_P')}</th>
                                <td>
                                    <TextInput maxLength={commonConstants.LOCATION_MAX_INPUT_LENGTH} width={250} propertyName='location' errorWidth={530} value={location || ''} onChange={onChange} />
                                </td>
                            </tr>
                            <tr>
                                <th className={classNames({'changed': changed['mapLocation'] === true})}>{t('MIS_SID_20_MAP_LOCATION')}</th>
                                <td>
                                    <TextInput width={250} propertyName='mapLocation' maxLength={commonConstants.MAP_LOCATION_MAX_INPUT_LENGTH} errorWidth={530} value={mapLocation || ''} onChange={onChange} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="devicepop information mb26">
                <h3>{t('TABLE_VERSION_P')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            {
                                deviceType !== SIGNAGE_CHILD && playerVersion && 
                                <tr>
                                    <th>{t('MIS_SID_SERVER_PLAYER_VERSION')}</th>
                                    <td>{playerVersion}</td>
                                </tr>
                            }
                            {
                                firmwareVersions &&
                                <tr>
                                    <th>{t('TABLE_FIRMWARE_VERSION_P')}</th>
                                    <td>
                                        {
                                            firmwareVersions.map((version, i) => <div key={i}>{version}</div>)
                                        }
                                    </td>
                                </tr>
                            }
                            {
                                osImageVersion &&
                                <tr>
                                    <th>{t('TABLE_OS_IMAGE_VERSION_P')}</th>
                                    <td className="autoline">{osImageVersion}</td>
                                </tr>
                            }
                            {
                                thirdApplicationVersionInfo.length > 0 && 
                                <tr>
                                    <th>{t('COM_LFD_SID_KIOSK_CANOV_KIOSK_APP')}</th>
                                    <td className="autoline" style={{paddingBottom:thirdApplicationUninstalling?20:0}}>
                                        <div style={{float:'left', lineHeight: '20px'}}>
                                            {
                                                thirdApplicationNotInstalled && <> ({t('MIS_SID_CBFEB_KIOSK_APP_NOT_INSTALLED')})</>
                                            }
                                            {
                                                !thirdApplicationNotInstalled && <> {t('COM_LIST_TITLE_VERSION')}</>
                                            }
                                            {
                                                !isNil(thirdApplicationLastUpdated) && <><br/>{t('COM_SID_LAST_UPDATED')}</>
                                            }
                                            {
                                                !thirdApplicationNotInstalled && thirdApplicationUninstalling && <><br/><div style={{position:'absolute'}}>({t('MIS_SID_CBFEB_PLEASE_CLICK_CURRENT_STATUS_BUTTON_CHECK_CURRENT_STATUS')})</div></>
                                            }                                            
                                        </div>
                                        <div style={{float:'left', lineHeight: '20px', marginLeft:'15px'}}>
                                            {
                                                !thirdApplicationNotInstalled && <> {thirdApplicationVersionInfo[0]}</>
                                            }
                                            {
                                                !thirdApplicationNotInstalled && thirdApplicationUninstalling && <> ({t('COM_LFD_SID_KIOSK_CANOV_UNISTALLING_DOT')})</>
                                            }
                                            {
                                                !isNil(thirdApplicationLastUpdated) && <><br/><Moment format={dateTimeFormat}>{thirdApplicationLastUpdated}</Moment></>
                                            }
                                        </div>
                                        { 
                                            !thirdApplicationUninstalling && !thirdApplicationNotInstalled && 
                                            <div style={{float:'left', marginLeft: '80px'}}>
                                                <WhiteButton id='UNINSTALL_KIOSK_APP' name={t('COM_LFD_SID_KIOSK_CANOV_UNINSTALL_KIOSK_APP')} width={150} onClick={onClickUninstall} disable={!generalInfo.power} />
                                            </div>
                                        }
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                deviceType !== SIGNAGE_CHILD &&
                <div className="devicepop information mb26">
                    <h3>{t('TEXT_SYSTEM_P')}</h3>
                    <div className="detail_view mini_p mt14 mb15">
                        <table>
                            <colgroup>
                                <col width="203px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>CPU</th>
                                    <td id="cpu_type">{cpuType}</td>
                                </tr>
                                <tr>
                                    <th>{t('COM_TABLE_MEMORY_SIZE_P')}</th>
                                    <td id="mem_size">{memSize}</td>
                                </tr>
                                <tr>
                                    <th>{t('MIS_SID_20_STORAGE_SIZE')}</th>
                                    <td id="hdd_size">{hddSize}</td>
                                </tr>
                                <tr>
                                    <th>{t('MIS_SID_20_VIDEO_CARD')}</th>
                                    <td id="video_adapter">{videoAdapter}</td>
                                </tr>
                                <tr>
                                    <th>{t('TABLE_VIDEO_MEMORY_P')}</th>
                                    <td id="video_memory">{videoMemory}</td>
                                </tr>
                                <tr>
                                    <th>{t('TABLE_VIDEO_DRIVER_P')}</th>
                                    <td id="video_driver">{videoDriver}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {
                deviceType !== SIGNAGE_CHILD &&
                <div className="devicepop information mb26">
                    <h3>{t('MIS_SID_20_STORAGE_SIZE')}</h3>
                    <div className="detail_view mini_p mt14 mb15">
                        <table>
                            <colgroup>
                                <col width="203px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('MIS_SID_20_USED_STORAGE_SPACE')}</th>
                                    <td id="disk_space_usage">
                                        {
                                            diskSpaceUsage && diskSpaceUsage.split(';').map((e, i) => {
                                                const [drive, size] = e.split(':');
                                                return (
                                                    drive && size &&
                                                    <div key={`usage-${i}`}>
                                                        {drive}: <Size size={toInteger(size * 1024)} />
                                                    </div>
                                                );
                                            })
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TABLE_DISK_SPACE_AVAILABLE_P')}</th>
                                    <td id="disk_space_available">
                                        {
                                            (diskSpaceAvailable == undefined || diskSpaceAvailable == "") ?
                                            !isNil(diskSpaceRepository) && <Size size={toInteger(diskSpaceRepository)} /> :
                                            diskSpaceAvailable.split(';').map((e, i) => {
                                                const [drive, size] = e.split(':');
                                                return (
                                                    drive && size &&
                                                    <div key={`available-${i}`}>
                                                        {drive}: <Size size={toInteger(size * 1024)} />
                                                    </div>
                                                );
                                            })
                                        }
                                    </td>
                                </tr>
                                {
                                    deviceType !== IPLAYER &&
                                    <tr>
                                        <th></th>
                                        <td id="sendCleanStorageFlagWrapper">
                                            <Checkbox id='sendCleanStorageFlag' name={t('MIS_SID_EMPTY_STORAGE')} propertyName='sendCleanStorageFlag' checked={sendCleanStorageFlag || false} onChange={onChangeSendCleanStorageFlag} />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <div className="devicepop information mb26">
                <h3>{t('DID_LAYOUTS_COMMON_ETC')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            <tr style={{display: hasE2ELicense ? 'table-row' : 'none'}}>
                                <th>{t('MIS_SID_SERVER_ANALYTICS')}</th>
                                <td id="recommendPlayWrapper">
                                    <Checkbox id='recommendPlay' name='Analytics' propertyName='recommendPlay' checked={recommendPlay} onChange={onChangeRecommendPlayFlag} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_APPROVAL_DATE_P')}</th>
                                <td id="create_date"><Moment format={dateTimeFormat}>{createDate}</Moment></td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_SCREEN_SIZE_P')}</th>
                                <td id="screen_size">{screenSize}</td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_RESOLUTION_P')}</th>
                                <td id="resolution">{resolution}</td>
                            </tr>
                            {
                                !isNil(ewfState) &&
                                <tr>
                                    <th>{t('TABLE_EWF_STATE_P')}</th>
                                    <td id="ewf_state">{`${ewfState}`}</td>
                                </tr>
                            }
                            <tr>
                                <th>{t('ADMIN_DEVICEINFO_DEVICEMODEL_CONTENTS_CODE')}</th>
                                <td id="device_model_code">{deviceModelCode}</td>
                            </tr>
                            <tr>
                                <th>{t('MIS_TEXT_SERIAL_KEY_P')}</th>
                                <td id="serial_decimal">{serialDecimal}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

EditInformation.defaultProps = {
    generalInfo: {
        data: {},
        changed: {},
        errors: {},
    },
    onChange: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(EditInformation);