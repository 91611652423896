import React, {memo, useEffect, useMemo, useState} from "react";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import "../../components/table/react-table.css";
import {useDispatch} from "react-redux";
import {commonConstants, RULESET_PAGE_SIZE_OPTIONS} from "../../constants";
import {useTranslation} from "react-i18next";
import {rulesetService} from '../../services/ruleset.service';
import {popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import {getMixString} from '../../language/languageUtils';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import {getErrorMessage} from '../../helper/responseHandler';
import {useMISOpt} from "../../components/misopt";
import {snakeCase} from "lodash";
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import MagicInfoTable from '../../components/table/MagicInfoTable';
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";

const pageSizeOptions = [10, 25, 50, 100];

const AllPlayLibrary = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({ height: "500px" });
    const { items = [], loading = false, totalCount = 0, pages = 0 } = data;
    const { page, pageSize, sorted } = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {
            page,
            pageSize,
            keyword,
            sorted: [{ id, desc }],
            groupId
        } = filter;
        setData({ ...data, loading: true });
        rulesetService
            .fetchPlayFilter({
                isPublic: true,
                startIndex: page * pageSize + 1,
                pageSize,
                groupId: groupId,
                searchText: keyword,
                orderBy: snakeCase(id).toUpperCase(),
                sortDirection: desc ? "DESC" : "ASC"
            })
            .then(res => {
                setData({ ...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize) });
                updateCache('RULESET', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent);
            });
    };

    const savePlay = play => {
        play.isPublic = true;
        rulesetService
            .createPlay(play)
            .then(res => {
                toastr.success(t("ALERT_SUCCESS_CREATED"));
                fetchData();
            })
            .catch(error => {
                toastr.error(getErrorMessage(error));
            })
            .finally();
    };

    const updatePlay = play => {
        play.isPublic = true;
        rulesetService
            .editPlay(play)
            .then(res => {
                toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_EDIT_P"));
                fetchData();
            })
            .catch(error => {
                toastr.error(getErrorMessage(error));
            })
            .finally();
    };

    const createPlayPopup = () => {
        dispatch(
            popupAction.addPopup({
                type: commonConstants.CREATE_PLAY_POPUP,
                id: commonConstants.CREATE_PLAY_POPUP,
                isLibrary: true,
                mode: "NEW",
                save: play => {
                    savePlay(play);
                },
                close: () => dispatch(popupAction.closePopup(commonConstants.RULESET_PLAY_POPUP))
            })
        );
    };

    const editPlayPopup = playId => {
        if (playId === undefined) {
            const playRuleIds = getCheckedId();
            if (playRuleIds.length > 1 || playRuleIds.length === 0) {
                toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
            } else {
                const playRuleId = playRuleIds[0];
                rulesetService
                    .fetchPlayById(playRuleId)
                    .then(res => {
                        if (res.items) {
                            dispatch(
                                popupAction.addPopup({
                                    type: commonConstants.RULESET_PLAY_POPUP,
                                    id: commonConstants.RULESET_PLAY_POPUP,
                                    play: res.items,
                                    mode: "EDIT",
                                    isLibrary: true,
                                    device: {
                                        deviceType: res.items.deviceType,
                                        deviceTypeVersion: res.items.deviceTypeVersion
                                    },
                                    save: (play, saveAs) => {
                                        if (saveAs !== undefined) {
                                            savePlay(play);
                                        } else {
                                            updatePlay(play);
                                        }
                                    },
                                    close: () => dispatch(popupAction.closePopup(commonConstants.RULESET_PLAY_POPUP))
                                })
                            );
                        }
                    })
                    .catch(error => toastr.error(getErrorMessage(error)))
                    .finally();
            }
        } else {
            rulesetService
                .fetchPlayById(playId)
                .then(res => {
                    if (res.items) {
                        dispatch(
                            popupAction.addPopup({
                                type: commonConstants.RULESET_PLAY_POPUP,
                                id: commonConstants.RULESET_PLAY_POPUP,
                                play: res.items,
                                mode: "EDIT",
                                isLibrary: true,
                                device: {
                                    deviceType: res.items.deviceType,
                                    deviceTypeVersion: res.items.deviceTypeVersion
                                },
                                save: (play, saveAs) => {
                                    if (saveAs !== undefined) {
                                        savePlay(play);
                                    } else {
                                        updatePlay(play);
                                    }
                                },
                                close: () => dispatch(popupAction.closePopup(commonConstants.RULESET_PLAY_POPUP))
                            })
                        );
                    }
                })
                .catch(error => toastr.error(getErrorMessage(error)))
                .finally();
        }
    };

    const deletePlayPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_BUTTON_DELETE"),
                message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                onClickYes: handleDelete,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
            })
        );
    };

    const getValues = contents => {
        let returnValue = "";
        let returnHTML = "";

        if (contents !== null && contents !== undefined) {
            contents.forEach(item => {
                returnValue += ",";
                returnValue += item.contentName;
            });
        }

        returnValue = returnValue.replace(",", "");

        returnHTML = contents.map((v, i) => {
            return "\n" + v.contentName;
        });

        return <span title={returnHTML}>{returnValue}</span>;
    };

    const handleDelete = () => {
        const playIds = getCheckedId();
        const params = {
            playIds: playIds
        };
        rulesetService
            .deletePlays(params)
            .then(res => {
                toastr.success(t("ALERT_SUCCESS_DELETE"));
                fetchData();
            })
            .catch(error => toastr.error(getErrorMessage(error)))
            .finally(dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)));
    };

    useEffect(() => {
        setStyle({ height: window.innerHeight - 204 });
        setAuthority(getAuthority("CONTENT_PLAYLIST_RULESET"));
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const getCheckedId = () => {
        return selected.current.map(s => items[s].resultId);
    };

    const columns = useMemo(
        () => [
            {
                accessor: "resultId",
                show: false
            },
            {
                id: "checkbox",
                width: 40,
                sortable: false,
                Header: () => {
                    return <Checkbox id={"AllPlay_all"} classname={"table"} name={"check"} onChange={toggleSelectAll} ref={checkAll} />;
                },
                Cell: row => {
                    return <Checkbox id={items[row.index].resultId} index={row.index} classname={"table"} name={"check"} onChange={toggleRow} ref={setCheckBoxRefs} />;
                },
                resizable: false
            },
            {
                Header: t("LIST_TITLE_NAME"),
                accessor: "resultName",
                Cell: props => (
                    <span className={"data_name"} title={props.original.resultName} onClick={() => editPlayPopup(props.original.resultId)}>
                        {props.original.resultName}
                    </span>
                ),
                width: 350
            },
            {
                Header: t("TABLE_TYPE_P"),
                accessor: "contentsType",
                Cell: ({ value }) => (value === "CONTENT" ? t("TEXT_CONTENT_P") : t("TEXT_TITLE_PLAYLIST_P")),
                width: 150,
                sortable: false
            },
            {
                Header: t("MIS_SID_OPERATOR"),
                accessor: "type",
                Cell: ({ value }) => (value === "static" ? t("MIS_SID_EQUAL") : t("MIS_SID_AUTO_MAPPING")),
                width: 130,
                sortable: false
            },
            {
                Header: t("COM_LFD_VALUE"),
                accessor: "contentList",
                Cell: props => getValues(props.original.contentList),
                sortable: false,
                width: 320
            },
            {
                Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
                Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion} />,
                width: 150,
                sortable: false
            },
            {
                Header: t("COM_TEXT_MODIFY_DATE_P"),
                accessor: "modifyDate",
                width: 200,
                Cell: ({value}) => <DeviceRelativeTimeCell value={value} />
            },
            {
                Header: t("TEXT_CREATOR_P"),
                accessor: "creator"                
            }            
        ],
        [items]
    );

    if (props.groupId !== filter.groupId) {
        setFilter({ ...filter, groupId: props.groupId });
    }

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{ width: "100%",display: props.currContent === 'LIBRARY_MANAGER_PLAY' ? 'block':'none' }} className={"ruleset_table_wrap"}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id="createPlayButton" name={t("TEXT_TITLE_NEW_P")} authority={authority.CREATE || authority.MANAGE} onClick={() => createPlayPopup()} />
                    <WhiteButton id="editPlayButton" name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} authority={authority.CREATE || authority.MANAGE} onClick={() => editPlayPopup()} />
                    <WhiteButton id="deletePlayButton" name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE} onClick={() => deletePlayPopup()} />
                </div>
                <div className="rightButton">
                    <SearchBar id="playSearch" placeholder={getMixString(["MIS_SID_MIX_NAME", "MIS_SID_PLAY_RULE"])} onClickSearch={onKeywordChange} enableDetail={false} keyword={filter.keyword}/>
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                pageSizeOptions={pageSizeOptions}
                sorted={sorted}
                onSortedChange={onSortedChange}
                style={style}
                columns={columns}
                getTrGroupProps={getTrGroupPropsType2}
                usePagination={true}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    defaultPageSize: pageSize,
                    pageSizeOptions: RULESET_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: props.currContent
                }}
            />
        </div>
    );
};

export default memo(AllPlayLibrary);
