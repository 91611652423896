import {getMixString} from '../language/languageUtils';

export const RULESET_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const getRuleTreeDefaultName = (id) => {
    return getMixString(["MIS_SID_MIX_NAME", "MIS_SID_RULE_TREE"]) + " " + id;
};

export const rulesetConstants = {
    INIT_NEW_RULESET: "INIT_NEW_RULESET",
    INIT_EDIT_RULESET: "INIT_EDIT_RULESET",
    REDRAW: "REDRAW",
    ADD_CONDITION: "ADD_CONDITION",
    EDIT_CONDITION: "EDIT_CONDITION",
    ADD_PLAY: "ADD_PLAY",
    EDIT_PLAY: "EDIT_PLAY",
    SET_VERIFICATION_MODE: "SET_VERIFICATION_MODE",
    HIGHLIGHT_PATH: "HIGHLIGHT_PATH",
    FIXED_HIGHLIGHT_PATH: "FIXED_HIGHLIGHT_PATH",
    ADD_KEYWORD: "ADD_KEYWORD",
    EDIT_KEYWORD: "EDIT_KEYWORD",
    
    COPY: "COPY",
    PASTE: "PASTE",
    DUPLICATE: "DUPLICATE",
    
    RULESET_SUPPORT_CONTENT_TYPE : ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH','SOUND','DLK','FTP','CIFS','STRM','HTML','URL'],
    
    CONDITION_TYPES: {
        currTime: {
            type: "curr_time",
            sid: "TEXT_TIME_P",
            source: "internal",
            valueType: "time",
            sign: "rg",
            isPublic: false,
            isInvert: false
        },
        currDate: {
            type: "curr_date",
            sid: "TEXT_DATE_P",
            source: "internal",
            valueType: "text",
            sign: "eq",
            isPublic: false,
            isInvert: false,
            repeatType: "once"
        },
        deviceNm: {
            type: "device_nm",
            sid: "TABLE_DEVICE_NAME_P",
            source: "internal",
            valueType: "text",
            sign: "eq",
            isPublic: false,
            isInvert: false
        },
        devIp: {
            type: "dev_ip",
            sid: "COM_SID_IP_ADDRESS",
            source: "internal",
            valueType: "text",
            sign: "eq",
            isPublic: false,
            isInvert: false
        },
        devMdnm: {
            type: "dev_mdnm",
            sid: "TABLE_DEVICE_MODEL_NAME_P",
            source: "internal",
            valueType: "text",
            sign: "eq",
            isPublic: false,
            isInvert: false
        },
        screenRotation: {
            type: "screen_rotation",
            sid: "TEXT_SCREEN_ROTATION_P",
            source: "internal",
            valueType: "number",
            sign: "eq",
            isPublic: false,
            isInvert: false
        },
        screenSize: {
            type: "screen_size",
            sid: "TABLE_SCREEN_SIZE_P",
            source: "internal",
            valueType: "number",
            sign: "eq",
            isPublic: false,
            isInvert: false
        },
        mediaTag: {
            type: "media_tag",
            sid: "MIS_SID_MEDIA_TAG",
            source: "internal",
            valueType: "tag",
            sign: "ia",
            isPublic: false,
            isInvert: false
        },
        datalink: {
            type: "datalink",
            sid: "MIS_SID_DATALINK",
            source: "external",
            valueType: "text",
            sign: "eq",
            isPublic: false,
            isInvert: false
        }
    },
    PLAY_TYPES: {
        content: {
            sign: "eq",
            sid: "TEXT_CONTENT_P",
            isPublic: false
        },
        playlist: {
            sign: "eq",
            sid: "TEXT_TITLE_PLAYLIST_P",
            isPublic: false
        }
    },
    CONDITION_SCREENSIZE_OPERATOR: {
        equalTo: {
            sign: "eq",
            sid: "MIS_SID_EQUAL_TO"
        },
        greaterThan: {
            sign: "gt",
            sid: "MIS_SID_GREATER_THAN"
        },
        greaterThanEqualTo: {
            sign: "ge",
            sid: "MIS_SID_GREATER_THAN_OR_EQUAL_TO"
        },
        lessThan: {
            sign: "lt",
            sid: "MIS_SID_LESS_THAN"
        },
        lessThanEqualTo: {
            sign: "le",
            sid: "MIS_SID_LESS_THAN_OR_EQUAL_TO"
        }
    },
    CONDITION_DATE_REPEAT_TYPES: {
        once: {
            repeatType: "once",
            sid: "TEXT_ONCE_P"
        },
        weekly: {
            repeatType: "weekly",
            sid: "TEXT_WEEKLY_P"
        },
        monthly: {
            repeatType: "monthly",
            sid: "TEXT_MONTHLY_P"
        }
    },
    CONDITION_DATE_WEEK_TYPES: {
        mon: {
            name: "mon",
            sid: "COM_TEXT_DAY_MONDAY_P"
        },
        tue: {
            name: "tue",
            sid: "COM_TEXT_DAY_TUESDAY_P"
        },
        wed: {
            name: "wed",
            sid: "COM_TEXT_DAY_WEDNESDAY_P"
        },
        thu: {
            name: "thu",
            sid: "COM_TEXT_DAY_THURSDAY_P"
        },
        fri: {
            name: "fri",
            sid: "COM_TEXT_DAY_FRIDAY_P"
        },
        sat: {
            name: "sat",
            sid: "COM_TEXT_DAY_SATURDAY_P"
        },
        sun: {
            name: "sun",
            sid: "COM_TEXT_DAY_SUNDAY_P"
        }
    }
};

export const RULESET_FILTERS = {
    ALL_RULESET : {
        page: 0,
        keyword: '',
        groupId: undefined,
        sorted: [{id: 'modifyDate', desc: true}],
        customFilter: {}
    },
    RULESET_RECYCLE_BIN : {
        page: 0,
        keyword: '',
        sorted: [{id: 'modifyDate', desc: true}],
    },
    LIBRARY_MANAGER_CONDITION : {
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{ id: "modifyDate", desc: true }]
    },
    LIBRARY_MANAGER_PLAY : {
        page: 0,
        keyword: '',
        groupId: '',
        sorted: [{ id: "modifyDate", desc: true }]
    }
}