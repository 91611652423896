import React, {useEffect, useRef, useState} from 'react';
import ReactTable from "react-table";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {commonConstants} from "../../../constants";
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import {userService} from "../../../services";
import './UserPopup.css';
import {toastr} from 'helper/toastrIntercept';

const ChangeOrganizationPopup = ({onClickYes, onClose, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const timer = useRef(null);

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const [data, setData] = useState({
        loading: false,
        orgList: []
    });

    const [filter, setFilter] = useState({
        selected: -1
    });

    const [style, setStyle] = useState({
        height: '325px'
    });

    const {orgList} = data;

    useEffect(() => {
        userService.fetchOrganizations()
            .then(res => {
                const orgList = res.items.map(org => {
                    return {value: org.organizationId, title: org.groupName};
                });
                setData({...data, orgList: orgList})
            })
    }, [filter]);


    const onClickCopy = (isContentCopy) => {
        onClickYes(orgList[filter.selected].value, isContentCopy);
        onClose();
    };

    const onClickOk = () => {
        if (filter.selected === -1) {
            toastr.error(t('MESSAGE_USER_SEL_ORGANIZATION_P'));
            return;
        }

        onClose();

        addPopup({
            type: commonConstants.CHANGE_ORGANIZATION_COPY,
            id: commonConstants.CHANGE_ORGANIZATION_COPY,
            title: t("TEXT_TITLE_CHANGE_USER_ORGAN_P"),
            message: t("MESSAGE_USER_COPY_CONTENT_NEW_ORGAN_P"),
            onClickCopy: onClickCopy,
            onClose: () => closePopup(commonConstants.CHANGE_ORGANIZATION_COPY)
        });
    };

    return (
        <MISDialog
            dialog={{
                title: t('BUTTON_EDIT_ORGAN_P'),
                closeOnEscape: true,
                modal: true,
                width: 260,
                height: 330,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickOk,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('COM_BUTTON_CLOSE_P'),
                        onClick: onClose,
                    }
                ]
            }}
            >

            <div style={{height: '325px', width: '250px', borderLeftStyle:'solid', borderLeftColor:'#e7e7e7', borderLeftWidth: '1px'}} className={'change_organization_wrap'}>
                <ReactTable
                    data={orgList}
                    minRows={0}
                    loading={data.loading}
                    columns={
                        [
                            {
                                Header: <Trans i18nKey={"SETUP_NEW_STRING29_P"}/>,
                                accessor: "title",
                                width: 248,
                                sortable: false,
                                Cell: row => <div><span title={row.value}>{row.value}</span></div>
                            }
                        ]
                    }
                    resizable={false}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    style={style}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    setFilter({
                                        selected: rowInfo.index
                                    })
                                },
                                style: {
                                    background: rowInfo.index === filter.selected ? '#e6f2ff' : 'white'
                                }
                            }
                        }else{
                            return {}
                        }
                    }
                    }
                />
            </div>
        </MISDialog>
    );
};

ChangeOrganizationPopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(ChangeOrganizationPopup);