import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { popupAction } from "../../../../actions";
import MISDialog from "../../MISDialog";
import ReactTable from "react-table";
import ReactDataGrid from "react-data-grid";
import { rulesetService } from "../../../../services";
import {Loading} from "../../../loading/Loading";

const SelectDatalinkTagPopup = ({id, url, onApply, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState("false");
    const [layout, setLayout] = useState([]);
    const [datalinkTableName,setDatalinkTableName] = useState('');
    
    let selectData = '';

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

 

    const dialogProps ={
        title: t("MIS_SID_DATALINK"),
        width :700,
        height :250,
        position: {x: -300, y: -200},
        modal:true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose:() => closePopup()
    };

    useEffect(() => {
        loadTable(url);
    }, []);


    
    const loadTable = (url) => {   
        const updatedUrl = url.substr(0,url.lastIndexOf("/"));  
        rulesetService.fetchDatalinkTable(url).then(
            res=> {
                if(res.items){
                    const json = JSON.parse(res.items);                
                    const header = json.layout.map((v,i) => {
                        return {
                        key : v.data,
                        name: v.data,
                        sortable: false,
                        width : v.width,               
                    }});
    
                    setColumns(header);
                    setRows(json.data);
                    setLayout(json.layout);                
                    setLoading('false');
                }               
            }
        )
    }
    

    return (
        <MISDialog
            dialog={dialogProps}
            buttons={{
                rightButtons: [
                    {
                        title: t("COM_BUTTON_SAVE"),
                        onClick: ()=> onApply(selectData)
                    },
                    {
                        title: t("BUTTON_CANCEL_P"),
                        onClick: onClose
                    }
                ]
            }}
        >
            <div className="popup_contents" style={{ padding: "10px 20px" }}>
                <div className="pop_list" style={{overflowY: "hidden", height: "460px",border: "1px solid rgb(231, 231, 231)"}}>
          
                   
                    <div
                    id="datalinkTable"
                    className="float_r"
                    style={{
                        height: "100%",
                        overflow: "hidden",
                        width:  "100%",
                        marginTop: "5px",
                        display : 'inline'
                    }}
                    >
                        <ReactDataGrid columns={columns} minColumnWidth={30} rowGetter={i=> rows[i]} rowsCount={rows.length} minWidth={580} cellRangeSelection={{
                        //onStart: args => console.log(rows),
                        //onUpdate: args => console.log(rows),
                        onComplete: args => {
                            
                            const startRow = args.topLeft.rowIdx;
                            const endRow = args.bottomRight.rowIdx;
                            const startColumn = args.topLeft.idx;
                            const endColumn = args.bottomRight.idx;
                            // if((startRow!==endRow) && (startColumn === endColumn) && (datalink_grid.countRows()-1 === endRow) && (startRow === 0)){	 // 
                            //     // select 1 column (line)
                            //    selectData = datalinkTableName+"."+layout[startColumn]["data"];
                            // }else if((startRow===endRow) && (startColumn==endColumn)){
                            //    // select only one cell
                            //    selectData =  datalinkTableName +"."+layout[startColumn]["data"]+"."+startRow;
                               
                            // }else if((startRow!==endRow)&&(startColumn!==endColumn) && (datalink_grid.countRows()-1 === endRow) && (startRow === 0)){ // 
                            //    // select many columns
                            //    selectData =  datalinkTableName +"."+layout[startColumn]["data"]+"."+startRow+":"+endRow;
                            // }else{
                            if(args.topLeft.idx !== -1 && args.bottomRight.idx !== -1){
                                selectData = layout[endColumn]["data"];                                
                            }                   

                        }
                    }}/>
                    </div>
                    <div id="DataLinkContainer">
                    {
                        loading &&
                        <div style={{width:'100%', height:'100%', background: '#fff', display: 'flex'}}><Loading /></div>

                    }      
                   
                </div>
            </div>                
        </div>        
        </MISDialog>
    )
}

export default SelectDatalinkTagPopup;
