import {authHeader, handleBinDownloadResponse, handleResponse, objToQueryString} from '../helper';
import {fetchForDownload, fetchWithToken as fetch} from '../helper/fetchIntercept.js';

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const systemService = {
    fetchMenus,
    fetchMenuById,
    fetchFileDownload,
    fetchAlerts,
    fetchConfigs,

    //Compare DB Schema
    fetchDatabaseCheckingItems,
    refreshDatabaseCheckingItems,
    solveDatabaseCheckingItems
}


function fetchMenus(onlyMenu = false) {
    return fetch(`${backendHost}/restapi/v2.0/sms/system/menus?onlyMenu=${onlyMenu}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchMenuById(id, onlyMenu = false) {
    return fetch(`${backendHost}/restapi/v2.0/sms/system/menus/${id}?ts=${new Date().getTime()}&onlyMenu=${onlyMenu}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchFileDownload(param){
    return fetchForDownload(`${backendHost}/restapi/v2.0/sms/system/file-download${objToQueryString(param)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleBinDownloadResponse);
}

function fetchAlerts() {
    return fetch(`${backendHost}/restapi/v2.0/sms/system/alerts`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchConfigs(configIds) {
    let param;
    if(Array.isArray(configIds)) {
        param = configIds.join();
    }
    else {
        param = configIds;
    }

    return fetch(`${backendHost}/restapi/v2.0/sms/system/configs/${param}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDatabaseCheckingItems() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/sms/system/db-scheme', requestOptions).then(handleResponse);
}

function refreshDatabaseCheckingItems() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/sms/system/db-scheme', requestOptions).then(handleResponse);
}

function solveDatabaseCheckingItems(testId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    return fetch(backendHost+`/restapi/v2.0/sms/system/db-scheme/${testId}`, requestOptions).then(handleResponse);
}