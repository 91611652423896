import React from 'react'
import DLKIcon from '../../../images/icon/file_type_icon_08.png';
import classNames from "classnames";

const DLK = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={DLKIcon}/>
            {
                !onlyIcon && 'DLK'
            }
        </span>
    )
}
export default DLK;