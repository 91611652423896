import React from 'react';
import {defaultPlaylist, defaultTagPlaylist, playlistConstants} from "../constants/PlaylistConstants";
import {convertAddedContent} from "../containers/playlist/playlistUtil";

const initialState  = {
    newPlaylist: {...defaultPlaylist},
    editPlaylist: {...defaultPlaylist},
    loadTrashBasket: false,
};

export const playlist = (state= initialState, action) => {
    const {newPlaylist, editPlaylist} = state;
    const {playlistInfo} = action;

    switch (action.type) {
        case playlistConstants.INIT_NEW_PLAYLIST:
            const {playlistType, deviceType: {deviceType, deviceTypeVersion}} = playlistInfo;
            const playlist = playlistType.id === '5' ? {...defaultTagPlaylist} : {...defaultPlaylist}
            if (playlistType.id === '5') {
                playlist.defaultContentDuration = playlistType.contentDuration;
            }
            if (playlistInfo.contentItems) {
                playlist.contents = playlistInfo.contentItems.map((content)=> { return convertAddedContent(content)});
            }

            return {
                ...state,
                newPlaylist: {
                    ...playlist,
                    playlistType: playlistInfo.playlistType.id,
                    deviceType,
                    deviceTypeVersion,
                    init: false,
                }
            };

        case playlistConstants.INIT_EDIT_PLAYLIST:
            const playlistFormat = action.playlistInfo.playlistType === '5' ? {...defaultTagPlaylist} : {...defaultPlaylist};
            return {
                ...state,
                editPlaylist: {
                    ...playlistFormat,
                    playlistType: action.playlistInfo.playlistType,
                    deviceType: action.playlistInfo.deviceType,
                    deviceTypeVersion: action.playlistInfo.deviceTypeVersion,
                    init: false,
                },
            };

        case playlistConstants.LOAD_PLAYLIST:
            const selectedPlaylist = action.playlist;
            return {
                ...state,
                editPlaylist: {...editPlaylist, ...selectedPlaylist}
            };

        case playlistConstants.UPDATE_PLAYLIST:
            if (action.mode === 'NEW') {
                return {
                    ...state,
                    newPlaylist
                }
            } else {
                return {
                    ...state,
                    editPlaylist
                }
            }

        case playlistConstants.DESTROY_PLAYLIST:
            if (action.mode === 'NEW') {
                let initPlaylist = {...defaultPlaylist};
                if (state.newPlaylist.playlistType === '5') {
                    initPlaylist = {...defaultTagPlaylist};
                }
                return {
                    ...state,
                    newPlaylist: initPlaylist,
                }
            } else {
                let initPlaylist = {...defaultPlaylist}
                if (state.newPlaylist.playlistType === '5') {
                    initPlaylist = {...defaultTagPlaylist};
                }
                return {
                    ...state,
                    editPlaylist: initPlaylist,
                }
            }

        case playlistConstants.DESTROY_EMPTY_PLAYLIST:
            if (action.mode === 'NEW') {
                return {
                    ...state,
                    newPlaylist: undefined,
                }
            } else {
                return {
                    ...state,
                    editPlaylist: undefined,
                }
            }

        case playlistConstants.DESTROY_LAST_PLAYLIST:
            if (action.mode === 'NEW') {
                return {
                    ...state,
                    newPlaylist,
                }
            } else {
                return {
                    ...state,
                    editPlaylist,
                }
            }

        case playlistConstants.LOAD_TRASH_BASKET:
            return {
                ...state,
                loadTrashBasket: action.loadTrashBasket
            }

        default :
            return state;

    }
}