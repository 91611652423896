import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import WhiteButton from '../button/WhiteButton';
import Select from '../selectbox/Select';
import './DeviceListSettingPopper.css';
import FilterArrow3 from '../../images/bg/filter_arrow3.png';
import { useOutsideClick } from '../../helper';

const pollingIntervalOptions = [
    {value: 15, title: "15"},
    {value: 30, title: "30"},
    {value: 60, title: "60"},
];

const pollingNumberOptions = [
    {value: 15, title: "15"},
    {value: 30, title: "30"},
];

const DeviceListSettingPopper = ({pollingInterval, pollingNumber, onBlur, onSave, onCancel}) => {
    const {t} = useTranslation();
    const [pollingInt, setPollingInterval] = useState(pollingInterval);
    const [pollingNum, setPollingNumber] = useState(pollingNumber);
    const popperRef = useRef();

    useOutsideClick(popperRef, onBlur);

    const onClickSave = () => {
        onSave(pollingInt, pollingNum);
    };

    useEffect(() => {
        popperRef.current.focus();
    }, []);

    return (
        <div className="device_setting_popper_wrap" ref={popperRef}>
            <span className='popper_arrow'><img src={FilterArrow3}/></span>
            <h3 className='popper_header'>{t('COM_SID_SETTINGS')}</h3>
            <div className="popper_body clearfix">
                <table className='popper_body_table'>
                    <colgroup>
                        <col width="170px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t('MIS_TEXT_FTP_POLLING_INTERVAL_P')}</th>
                            <td>
                                <Select width={80} selects={pollingIntervalOptions} multiLang={false} value={pollingInt} 
                                    onChange={(e, value) => setPollingInterval(value)} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('MIS_SID_NUMBER_OF_POLLING_DEVICES')}</th>
                            <td>
                                <Select width={80} selects={pollingNumberOptions} multiLang={false} value={pollingNum} 
                                    onChange={(e, value) => setPollingNumber(value)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="popper_footer">
                <WhiteButton id='SETTING_SAVE' name={t('COM_BUTTON_SAVE')} width={102} onClick={onClickSave} />
                <WhiteButton id='SETTING_CANCEL' name={t('BUTTON_CANCEL_P')} width={102} onClick={onCancel} />
            </div>
        </div>
    );
};

DeviceListSettingPopper.defaultProps = {
    onSave: () => {}
};

export default DeviceListSettingPopper;