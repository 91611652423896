import React, {useEffect, useRef, useState} from "react";
import {MoonLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {dashboardService} from "../../../../services";
import {dashboardConstants} from "../../../../constants/DashboardConstants";
import classNames from 'classnames';
import './CustomExternalServers.css';
import {useDispatch, useSelector} from "react-redux";
import {SortableHandle} from "react-sortable-hoc";
import {menuAction} from "../../../../actions";
import {useMouseHover} from "../../../../helper";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

const CustomExternalServers = ({removeWidget}) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [servers, setServers] = useState([]);
    const [mouseOver, setMouseOver] = useState(false);

    const ref = useRef();

    const {reload} = useSelector(state => state.dashboard);

    const fetchExternalServers = () => {
        setLoading(true);
        dashboardService.fetchExternalServers()
            .then(
                res => {
                    if (res && res.items) {
                        setServers(res.items);
                    }
                }
            )
            .catch(e=> console.log(e)
            ).finally(()=>setLoading(false));
    }

    const getServerName = (id) => {
        switch(id) {
            case 'remoteControl':
                return 'Remote Control Server';
            case 'insight':
                return 'Insight Server';
            case 'edge':
                return t("MIS_SID_EDGE_SERVER");
            case 'datalink':
                return t("MIS_SID_20_DATALINK_SERVER");
        }
    }

    const DragHandle = SortableHandle(() =>
        <div className='title'>{t("MIS_SID_CBFEB_EXTERNAL_SERVER")}</div>
    );

    const RenderServer = ({total, running, id, statuses=[]}) => {

        const dispatch = useDispatch();

        const StatusRow = ({status}) => {

            return (
                <li style={{height:30, lineHeight: '30px', padding: 5, width: 220}} className={'server_info'}>
                    <div style={{display: 'flex'}}>
                        <div style={{width: 120, overflow: 'hidden', height:25, lineHeight: '25px', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} title={status.serverName}><span className={classNames('power', status.isServerChk === 'ERR' ? 'off' : 'on')}></span>{status.serverName}</div>
                        <div style={{width: 80, overflow: 'hidden', height:25, lineHeight: '25px', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} title={status.ipAddress}>{status.ipAddress}</div>
                    </div>
                </li>
            )
        }


        const onRemoteControls = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('SETTING'))
            }).then(dispatch(menuAction.moveTab({id: 'SETTING', title : t('COM_TEXT_SETTING_P'), active: true, close: true}, 'EXTERNAL_SERVER_MANAGEMENT','REMOTE_CONTROL_SERVER')))
        }

        const onInsight = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('SETTING'))
            }).then(dispatch(menuAction.moveTab({id: 'SETTING', title : t('COM_TEXT_SETTING_P'), active: true, close: true}, 'SERVER_MANAGEMENT','SERVER_SETTINGS')))
        }

        const onEdge = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('SETTING'))
            }).then(dispatch(menuAction.moveTab({id: 'SETTING', title : t('COM_TEXT_SETTING_P'), active: true, close: true}, 'EXTERNAL_SERVER_MANAGEMENT','EDGE_SERVER')))
        }

        const onDataLink = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('SETTING'))
            }).then(dispatch(menuAction.moveTab({id: 'SETTING', title : t('COM_TEXT_SETTING_P'), active: true, close: true}, 'EXTERNAL_SERVER_MANAGEMENT','DATALINK_SERVER')))
        }


        const handleClick = () => {
            switch (id) {
                case 'remoteControl':
                    onRemoteControls();
                    break;
                case 'insight':
                    onInsight();
                    break;
                case 'edge':
                    onEdge();
                    break;
                case 'datalink':
                    onDataLink();
                    break;
            }
        }

        const [visibleTooltip, setVisibleTooltip] = useState(false);

        const onVisibleChange = (visible) => {
            if (statuses.length > 0) {
                setVisibleTooltip(visible);
            }
        }
        return (
            <Tooltip
                trigger={['hover']}
                placement="top"
                visible={visibleTooltip}
                onVisibleChange={onVisibleChange}
                overlay={statuses && statuses.length > 0 && <div style={{backgroundColor: '#fff', width: '250px', display: 'inline-block', maxHeight: 130, overflowY: 'auto', zIndex:1, padding: 20}} >
                    <ul>
                        {
                            statuses && statuses.length > 0 && statuses.map(
                                status => <StatusRow key={status.serverName} status={status}/>
                            )
                        }
                    </ul>
                </div>}
            >
                <div className={'server_row'} style={{cursor: 'pointer'}} data-id={id} onClick={handleClick} ref={ref} >
                    <div className={'server_name'}>
                        {getServerName(id)}
                    </div>
                    <div className={'server_information'}>
                        <div className={'chart'} style={{}}>
                            <div style={{width: `${total > 0 ? (running/total)*100 : 0}%`, backgroundColor : '#3196FF', height: '100%'}}></div>
                            <span className={'number'} style={{color : running > 0 ? '#fff' : '#000'}}>{running}</span>
                        </div>
                        <div className={'total_area'} style={{flexGrow: 1, marginTop: -7}}>
                            <span className={'total'} >{total}</span>
                        </div>
                    </div>
                </div>
            </Tooltip>
        )
    }

    useEffect(()=> {
        fetchExternalServers();
    }, [reload])

    return (
        <div className={'custom_widget'} onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)}>


            <div className='header'>
                <DragHandle />
                {
                    mouseOver && <div className={'close'} onClick={(e)=>removeWidget(e, dashboardConstants.DASHBOARD_CUSTOM_EXTERNAL_SERVER_WIDGET)}></div>
                }
            </div>

            <div className='custom_widget_body'>
                {loading && <MoonLoader css={`position: absolute;top: calc(50%);left: calc(50% - 40px);`} color="#6e78c8"/>}
                {
                    !loading &&
                    <div className={'external_servers'}>

                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <div className={'underline'} style={{marginBottom: 5}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '50%', lineHeight: '35px', color: '#70a7e6', fontWeight: 'bold'}}></div>
                                    <div style={{width: '50%'}}>
                                        <div className={'external_servers_title'} style={{position: 'inline-block'}}>
                                            <div style={{float: 'left'}}>{t("TEXT_RUNNING_P")}</div>
                                            <div style={{float: 'right', paddingRight: 5}}>{t("LIST_TITLE_TOTAL")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingLeft: 15, paddingRight: 15}}>
                        {
                            servers.length > 0 && servers.map(
                                server => <RenderServer key={server.id} id={server.id} running={server.runningCnt} total={server.totalServerCnt} statuses={server.severInfolist} />
                            )
                        }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default CustomExternalServers;