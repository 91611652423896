import React, {useCallback, useEffect, useRef, useState} from "react";
import {withTranslation} from "react-i18next";
import RadioGroup from "../../radio/RadioGroup";
import MISDialog from "../MISDialog";
import {popupAction} from "../../../actions";
import {commonConstants, rulesetConstants} from "../../../constants";
import {rulesetService} from "../../../services";
import {useDispatch} from "react-redux";
import Select from "../../selectbox/Select";
import WhiteButton from "../../button/WhiteButton";
import ReactTable from "react-table";
import Checkbox from "../../checkbox/Checkbox";
import {getMixString} from "../../../language/languageUtils";
import {toastr} from 'helper/toastrIntercept';

const FormatSettingPopup = ({ t, id, onClose, onApply }) => {
    const dispatch = useDispatch();
    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };
    const [stepCount, setSetpCount] = useState("2");
    const [pageFilter, setPageFilter] = useState({
        startIndex: 1,
        pageSize: 1000
    });

    const [filter, setFilter] = useState({
        filterValue1 : "",
        filterValue2 : "",
        filterValue3 : "",
        filterValue4 : ""
    });

    const [keywordNames,setKeywordNames] = useState([getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_1ST_WEEK']),
    getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_1ST_WEEK']),
    getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_1ST_WEEK']),
    getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_1ST_WEEK'])]);
    
    const [data, setData] = useState({
        keywordList: [],
        selectedKeywordList:[],
    });

    const {keywordList, selectedKeywordList} = data;

    const addBtn = useRef();
    const deleteBtn = useRef();
    const checkBoxes = [];
    let selectedCnt = 0;

    const keywordNameList = keywordList.map((item) => {
        return { title: item.keywordName, value: item.keywordId, valueList : item.keywordValues}
    });

    const fetchKeywordList = () => {
        dispatch(
            popupAction.addPopup({
                type: commonConstants.LOADING_POPUP,
                id: commonConstants.LOADING_POPUP
            })
        );
        Promise.all([
            rulesetService.fetchResultKeyword(pageFilter.startIndex, pageFilter.pageSize),
        ]).then(values => {
            const keywordList = values[0].items.map(item => {
                const keywordValueList = item.keywordValues.split(";");
                const keyword = {
                    keywordId: item.keywordId,
                    keywordName: item.keywordName,
                    keywordValueList: keywordValueList,
                    keywordValues : item.keywordValues
                };
                return keyword;
            });
            setData({...data,keywordList});
        }).catch(error => console.log(error))
        .finally(dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));

    }

    useEffect(fetchKeywordList,[pageFilter]);
 

    const onChangeStep = (e, value) => {
        setSetpCount(value);
    };

    const onChangeFilterValue = (value,index) => {

        const newKeywordNames = [...keywordNames];
        newKeywordNames[index] = value;
        setKeywordNames(newKeywordNames);

       const item =  keywordNameList.filter((item) => {
           return item.value === value;
       });
       
       const keywordValue = item[0].valueList;       
       let newFilter = {};
       if(index === 0){
        newFilter = {...filter, filterValue1 :  keywordValue};
       }else if(index === 1){
        newFilter = {...filter, filterValue2 :  keywordValue};
       }else if(index === 2){
        newFilter = {...filter, filterValue3 :  keywordValue};
       }
       else if(index === 3){
        newFilter = {...filter, filterValue4 :  keywordValue};
       }       
        setFilter(newFilter);
            
    };

    const addKeyword = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.RULESET_PLAY_KEYWORD_SETTING_POPUP,
                type: commonConstants.RULESET_PLAY_KEYWORD_SETTING_POPUP,
                mode : rulesetConstants.ADD_KEYWORD,
                onSave: (keyword)=> {
                    rulesetService.createResultKeyword(keyword).then(res => {
                        toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                    }).catch(err => {
                        toastr.error(err.statusText);
                    }).finally(()=>{                        
                        dispatch(popupAction.closePopup(commonConstants.RULESET_PLAY_KEYWORD_SETTING_POPUP));
                        fetchKeywordList();
                    });
                },
                onClose: ()=>dispatch(popupAction.closePopup(commonConstants.RULESET_PLAY_KEYWORD_SETTING_POPUP))               
            })
        );
    }

    const deleteKeyword = () => {

    }

    const toggleRow = rowIndex => {
        if(checkBoxes[rowIndex].checked) {
            selectedCnt ++;
            if (selectedCnt  > 0){
                setButton(deleteBtn, false);
            }
            else {
                setButton(deleteBtn, true);
            }      

        } else {
            selectedCnt --;
            if(selectedCnt === 0) {
                setButton(deleteBtn, true);
            }
            else if (selectedCnt === 1){
                setButton(deleteBtn, false);
            }
        }
    };

    
    const setButton = (ref, disable) => {
        if(disable) {
            ref.current.disabled = true;
            ref.current.className = 'whiteButton disabled';
        } else {
            ref.current.disabled = false;
            ref.current.className = 'whiteButton';
        }
    };


    const tableColumns =[
        {id: 'checkbox', width: 40, sortable: false, Cell: row => {
            return (
                <Checkbox
                    id={'AllPlay_' + row.index}
                    classname={"table"}
                    name={"check"}
                    onChange={() => toggleRow(row.index)}
                    ref={ref => checkBoxes.push(ref)}
                />
            )
        }},
        {Header: t("MIS_SID_KEYWORD_NAME"),sortable: false, accessor: 'keywordName', ellipse: true},
        {Header: t("MIS_SID_20_VALUE"),sortable: false, accessor: 'keywordValues'}
    ];

    const getTrGroupProps = useCallback((state, rowInfo) => {
        if (rowInfo && rowInfo.original) {
            return {
                style: {
                    background: rowInfo.original.isSelected === true ? '#e6f2ff' : 'white'
                }
            }
        }else{
            return {}
        }
    },[keywordList]);

    return (
        <MISDialog
            dialog={{
                title: t("MIS_SID_FORMAT_SETTINGS"),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                allowMinimize: false,
                width: 900,
                height: 500,
                position: { x: -350, y: -150 },
                onClose: onClose
            }}
            buttons={{
                rightButtons: [
                    {
                        title: t("BUTTON_APPLY"),
                        onClick: () => {
                            onApply(filter);
                            closePopup();
                        }
                    },
                    {
                        title: t("COM_BUTTON_CLOSE_P"),
                        onClick: () => closePopup()
                    }
                ]
            }}
        >
            <div className='tag_setting_header'>
                <span style = {{marginRight:'10px'}}>{t("MIS_SID_MAPPING_STEP")} </span>
                <span> 
                        <RadioGroup
                            propertyName={"stepCount"}
                            selects={[
                                { title: "2", value: "2" },
                                { title: "3", value: "3" },
                                { title: "4", value: "4" }
                            ]}
                            value={stepCount}
                            onChange={onChangeStep}
                            multiLang={true}
                        />
                   </span>
                </div>
                <div style={{marginTop:'16px'}}>
                    {stepCount > 1 &&   <>                      
                            <Select
                                width={200}
                                defaultTitle = {getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_1ST_WEEK'])}
                                value={keywordNames[0]}
                                selects={keywordNameList}
                                onChange={(e,value)=>onChangeFilterValue(value,0)}
                            ></Select>
                            {"_"}
                            <Select
                                width={200}
                                defaultTitle = {getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_2ND_WEEK'])}
                                value={keywordNames[1]}
                                selects={keywordNameList}
                                onChange={(e,value) => onChangeFilterValue(value,1)}
                            ></Select> </>
                     }
                     {stepCount > 2 &&
                     <>
                        {"_"}
                            <Select
                                width={200}
                                defaultTitle = {getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_3RD_WEEK'])}
                                value={keywordNames[2]} 
                                selects={keywordNameList}
                                onChange={(e,value) => onChangeFilterValue(value,2)}
                            ></Select>
                    </>
                     }
                      {stepCount > 3 &&
                     <>
                        {"_"}
                            <Select
                                width={200}
                                defaultTitle ={getMixString(['MIS_SID_MIX_SELECT_KEYWORD','COM_TV_SID_4TH_WEEK'])}
                                value={keywordNames[3]}
                                selects={keywordNameList}
                                onChange={(e,value) => onChangeFilterValue(value,3)}
                            ></Select>
                    </>
                     }                
             </div>

             <div style={{marginTop:'16px', marginBottom:'16px'}}>
                <WhiteButton name={t("COM_BUTTON_ADD")} ref={addBtn} onClick={addKeyword} style={{marginLeft:"5px;"}}/>
                <WhiteButton name={t("COM_BUTTON_DELETE")} ref={deleteBtn} onClick={deleteKeyword} style={{marginLeft:"5px;"}}/>
                </div>
                <div >
          
            <ReactTable
                data={keywordList}
                style={{height: '370px', width: '600px'}}
                minRows={0}
                columns={tableColumns}                   
                manual
                showPagination={false}
                getTrGroupProps={getTrGroupProps}
            /></div>
        </MISDialog>
    );
};
export default withTranslation()(FormatSettingPopup);

