import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import TextInput from '../../input/TextInput'
import './ApprovePopup.css';
import {contentConstants} from "../../../constants";

const ApprovePopup = ({ data, onSubmit, onClose, isRejectReason = false}) => {
    const {t} = useTranslation();
    const {action, comment: value} = data;
    const [commentValue, setCommentValue] = useState(value);

    return (
        <div className='content_approve_popup'>
            <MISDialog
                dialog={{
                    title: action === contentConstants.APPROVED ? t('COM_BUTTON_APPROVAL'): t('BUTTON_REJECT_P') ,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 351,
                    height: 95,
                    position: {x: -150, y: -200},
                    onClose: onClose,
                }}
                buttons={
                    isRejectReason ? {
                        rightButtons : [{
                            id: 'APPROVE_OK',
                            title: t('BUTTON_OK_P'),
                            onClick: onClose,
                        }]
                    } : {
                    rightButtons: [
                        {
                            id: 'APPROVE_SUBMIT',
                            title: t('BUTTON_OK_P'),
                            onClick: () => onSubmit({...data, comment:commentValue}),
                        },
                        {
                            id: 'APPROVE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ],
                }}>
                <div className="detail_view mini_p">
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{action === contentConstants.APPROVED ? t('MIS_SID_APPROVAL_COMMENT'): t('TEXT_REJECT_REASON_P')}</th>
                            <td>
                                {isRejectReason ? commentValue : <TextInput width={228} maxLength={200} value={commentValue} onChange={e => setCommentValue(e.target.value)}></TextInput>}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default ApprovePopup;