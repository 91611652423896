import React, {useEffect, useRef, useState} from 'react';
import MISDialog from '../MISDialog';
import {useTranslation} from "react-i18next";
import {userService} from "../../../services";
import Checkbox from "../../checkbox/Checkbox";
import './AddOrganizationGroupPopup.css'
import {toastr} from 'helper/toastrIntercept';
import cloneDeep from "lodash/cloneDeep";

const ChangeMultiOrganizationPopup  = ({onSave, onClose,organs}) => {

    const {t} = useTranslation();

    const [data, setState] = useState({
        organizations: []
    });

    const {organizations = []} = data;
    const checkAll = useRef();
    const checkBoxRefs = [];

    useEffect(() => {
        fetchOrganizationGroupOrganizations(organs);
    }, []);

    const checkAllOrganization = (e) => {
        if (e.target.checked) {
            for (let index = 0; index < organizations.length; ++index) {
                organizations[index].isChecked = true;
            }
        } else {
            for (let index = 0; index < organizations.length; ++index) {
                organizations[index].isChecked = false;
            }
        }
        setState({
            ...data,
            organizations: organizations,
        })
    };

    const checkOrganization = (index) => {
        organizations[index].isChecked = checkBoxRefs[index].checked;
        checkAll.current.checked = true;
        for (let index = 0; index < organizations.length; ++index) {
            if (!organizations[index].isChecked) {
                checkAll.current.checked = false;
                break;
            }
        }
        setState({
            ...data,
            organizations: organizations,
        })
    };

    const save = () => {
        let selectedGroup = [];
        organizations.forEach((organization) => {
            if (organization.isChecked) {
                selectedGroup.push(organization);
            }
        });
        onSave(selectedGroup);
    };

    const fetchOrganizationGroupOrganizations = (organs) => {
        console.log(organs)
        userService.fetchOrganizationGroupOrganizations().then(res => {
            let all = res.items;
            for (let i = 0; i < all.length; i++) {
                let checked = organs.find(org => org.organizationId == all[i].groupId) == undefined ? false : true
                all[i] = {
                    ...all[i],
                    isChecked: checked
                }
            }
            setState({
                ...data,
                organizations: cloneDeep(all)
            })
        }).catch(
            err => {
                toastr.error(err);
            }
        );
    };

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_SERVER_ADD_ORGANIZTION_GROUP'),
                closeOnEscape: true,
                modal: true,
                width: 405,
                height: 442,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: save},
                    {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: onClose}
                ]
            }}>
            <div className="organization_group_contents">
                <div className='organization_group_contents_name'>
                    <span className='organization_group_contents_title'>{t('TEXT_SEL_ORGANIZATION_P')}</span>
                </div>
                <div className='organization_group_contents_list'>
                    <div className='organization_group_contents_row'>
                        <Checkbox id={'organization-all'} name={t("COM_SID_ALL")} onChange={checkAllOrganization} ref={checkAll}/>
                    </div>
                    <div>
                        {
                            organizations.map((organization, index) =>
                                <div key={index} className='organization_group_contents_row'>
                                    <Checkbox id={'organization-' + index} name={organization.groupName}
                                              checked={organization.isChecked} onChange={() => checkOrganization(index)}
                                              ref={ref => checkBoxRefs[index] = ref}/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MISDialog>
    );
};
export default ChangeMultiOrganizationPopup;