import React, {useCallback, useEffect, useRef, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './AddToPlaylist.css';
import {commonConstants} from "../../../constants";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import Checkbox from "../../checkbox/Checkbox";
import ContentThumbnail from "../../image/ContentThumbnail";
import {playlistService} from "../../../services/playlist.service";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import {toastr} from 'helper/toastrIntercept';
import {contentService} from "../../../services";
import DeviceRelativeTimeCell from "../../device/DeviceRelativeTimeCell";
import ReactTable from "react-table";
import {useCheckRefWithSelectedCnt as useCheckRef} from "../../../helper";
import {useTrGroupProps} from "../../../helper/tables";
import {getErrorMessage} from "../../../helper/responseHandler";

const AddToPlaylist = ({ contentItems, checkResult, onClose}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const tableRef = useRef();

    const [filter, setFilter] = useState({
        groupId: 0,
        page: 0,
        pageSize: 25,
        startIndex: 1,
        productType: "PREMIUM",
        sorted: [{id: 'lastModifiedDate', desc: true}]
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        recordsTotal: 0,
        pages: 0
    });
    const [end, setEnd] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState({
        deviceType: '',
        deviceTypeVersion:''
    });

    const [style, setStyle] = useState({
        height: '417px'
    });

    const {page, pageSize, startIndex, productType, sorted:{id, desc}} = filter;
    const {items, loading, recordsTotal} = data;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const addContent = () => {
        let playlistIds = selected.current.map(key => items[key].playlistId);
        let contentIds = contentItems.map(item => item.contentId);
        if(playlistIds.length < 1){
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
        }else{
            playlistService.addContentToPlaylist({
                playlistIds: playlistIds,
                contentIds:contentIds,
            }).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                dispatch(popupAction.closePopup("CONTENT_ADD_TO_PLAYLIST"));
            });
        }
    };

    const addToNewPlaylist = () => {
        dispatch(popupAction.closePopup("CONTENT_ADD_TO_PLAYLIST"));
        dispatch(popupAction.addPopup({
            type: commonConstants.CREATE_PLAYLIST,
            id: 'NEW_PLAYLIST',
            contentItems: contentItems,
            deviceType:deviceInfo.deviceType,
            deviceTypeVersion:deviceInfo.deviceTypeVersion})
        );
    };

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'add_to_playlist_tr_group');

    const fetchData = () => {
        if(!loading){
            setData({
                ...data,
                loading: true,
            });
        }


        let contentIds = contentItems.map(item => item.contentId);

        setDeviceInfo({
            deviceType:checkResult.deviceType,
            deviceTypeVersion:checkResult.deviceTypeVersion
        });

        contentService.fetchAvailablePlaylist({
            pageSize: pageSize,
            startIndex: (page * pageSize) + 1,
            productType: productType,
            contentIds: contentIds,
        }).then(res => {
            setData({
                ...data,
                loading: false,
                items: res.items.recordsTotal > 0 ? items.concat(res.items.playlist) : items,
                recordsTotal: res.items.recordsTotal
            })
        }).catch(e => {
            toastr.error(getErrorMessage(e));
            setData({
                ...data,
                loading: false
            });
        });
    };

    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate} />
    };

    const fetchMoreData = (e) => {
        if(e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight){
            setEnd(true);
        }
    };

    useEffect(() => {
        if(end){
            if(((page + 1) * pageSize) < recordsTotal && !loading){
                setData({
                    ...data,
                    loading: true,
                });
                setFilter({...filter, page: page + 1});
            }
            setEnd(false);
        }
    }, [end]);

    useEffect(() => {
        fetchData();
    }, [filter])

    useEffect(() => {
        let tbody = tableRef.current.getElementsByClassName("rt-tbody")[0];
        tbody.addEventListener("scroll", fetchMoreData, true);
        return () => {
            tbody.removeEventListener('scroll', fetchMoreData);
        }
    }, []);



    const getMixedString = () => {
        const string = t('MIS_SID_MIX_20_ADD_OTHER_FILES_TO_THE_SELECTED_PLAYLISTS');
        const size = contentItems.length - 1;
        const result = string.replace("<<A>>", contentItems[0].contentName).replace("<<B>>", size);
        return result;
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'AllContent_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].playlistId}
                        index={row.index}
                        classname="table"
                        name="check"
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 41,
            sortable: false,
            resizable: false
        },
        {
            Header: t("TEXT_TITLE_CONTENT_P"),
            width: 137,
            accessor: 'thumbFileId',
            Cell: (props) => <div style={{textAlign:'center'}}><ContentThumbnail id={props.original.thumbFileId} width={100} height={54} textAlign={true}/></div>
        },
        {
            Header: t('TABLE_PLAYLIST_NAME_P'),
            width: 241,
            accessor: "playlistName"
        },
        {
            Header: t('COM_MAPP_SID_SUPPORTED_DEVICES'),
            width: 171,
            Cell: props => <div style={{textAlign:'center'}}>
                <SupportedDeviceIcon deviceType={props.original.deviceType}
                                     deviceTypeVersion={props.original.deviceTypeVersion}
                />
            </div>
        },
        {
            Header: t('MESSAGE_STATISTICS_TABLE_COLUMN_CONTENT_NUMBER_P'),
            width: 131,
            headerStyle: {textAlign: 'center'},
            accessor: "contentCount"
        },
        {
            Header: t('COM_TEXT_MODIFY_DATE_P'),
            headerStyle: {textAlign: 'center'},
            accessor: "status",
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)
        }
    ], [items]);

    return (
        <div className='content_add_to_playlist_popup' ref={tableRef}>
            <MISDialog
                dialog={{
                    title: t('MIS_TEXT_TITLE_ADD_CONTENT_TO_PLAYLIST_P'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 900,
                    height: 471,
                    position: {x: -150, y: -200},
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: [
                        {
                            id: 'ADD_TO_NEW_PLAYLIST',
                            title: t('TEXT_ADD_TO_NEW_PLAYLIST_P'),
                            onClick: addToNewPlaylist,
                        }
                    ],
                    rightButtons: [
                        {
                            id: 'ADD',
                            title: t('COM_BUTTON_ADD'),
                            onClick: addContent,
                        },
                        {
                            id: 'CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ],
                }}>
                <div className="notify_wrap">
                    <div>{getMixedString()}</div>
                    <div style={{lineHeight:'20px'}}>{t('MIS_SID_20_SOUND_FILE_TYPES_NOT_ADD_TO_THE_PLAYLIST')}</div>
                </div>
                <ReactTable
                    data={items}
                    minRows={0}
                    getTrGroupProps={getTrGroupPropsType2}
                    showPagination={false}
                    resizable={false}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    columns={columns}
                    className="-striped -highlight"
                    manual
                    style={style}
                />
            </MISDialog>
        </div>
    );
};

export default AddToPlaylist;