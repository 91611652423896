import React, {Component} from "react";
import './SyncPlaylistEditor.css';
import SyncGroupItem from './SyncGroupItem';
import {withTranslation} from "react-i18next";
import Checkbox from "../../../components/checkbox/Checkbox";
import FrontSpace from "./FrontSpace";
import EndSpace from "./EndSpace";
import cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";

class SyncGroup extends Component {
    state = {
        group: {},
    };

    constructor(props) {
        super(props);
        this.onClickDeleteGroup = this.onClickDeleteGroup.bind(this);
        this.onClickSyncGroup = this.onClickSyncGroup.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {group} = nextProps;
        if (group && !_isEqual(group, prevState.group)) {
            return {
                group: cloneDeep(group),
            };
        }
        return null;
    }

    onClickDeleteGroup() {
        this.props.onCheckDeleteGroup(this.state.group.groupIndex);
    }

    onClickSyncGroup() {
        this.props.onCheckSyncGroup(this.state.group.groupIndex);
    }

    render() {
        let {t, mode} = this.props;
        let {groupIndex, items, isDeleteCheck, isSyncCheck} = this.state.group;
        return (
            <div className="sync-playlists-group">
                <div className='sync-playlists-group-title'>
                    <Checkbox id={'GROUP_CHECK' + groupIndex} name={t("TEXT_GROUP_P") + (groupIndex + 1)} classname={'sync-playlists-group-title-group'} checked={isDeleteCheck} onChange={this.onClickDeleteGroup}/>
                    <div style={{width: '20px'}}/>
                    <Checkbox id={'SYNC_CHECK' + groupIndex} name={t("COM_TEXT_SYNC_P_KR_EBD")} checked={isSyncCheck} onChange={this.onClickSyncGroup}/>
                </div>
                <div className="sync-playlists-group-items">
                    <FrontSpace groupIndex={groupIndex} itemIndex={0} onAddItem={this.props.onAddItem}/>
                    {items.map((item, index) =>
                        <SyncGroupItem key={index} item={item}
                                       onAddItem={this.props.onAddItem} onDeleteItem={this.props.onDeleteItem} onClickItem={this.props.onClickItem}
                                       onUpdateItemTime={this.props.onUpdateItemTime}
                                       onUpdateImageItem={this.props.onUpdateImageItem} onReplaceItem={this.props.onReplaceItem}
                                       onChangeItemTime={this.props.onChangeItemTime}/>
                    )}
                    {items.length > 0 && <EndSpace groupIndex={groupIndex} itemIndex={items.length} onAddItem={this.props.onAddItem}/>}
                </div>
            </div>
        );
    }
}

export default (withTranslation()(SyncGroup));

