import React from 'react';
import './VersionTable.css';
import {withTranslation} from "react-i18next";
import Moment from "react-moment";
import {MISOptContext} from "../../../components/misopt";
import {toMomentDateTimeFormat} from '../../../helper';

class VersionTable extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        this.state = {
            versionList: props.versions,
            selectedVersionId: props.selectedVersionId,
        };
        this.onChangeVersionId = props.onChangeVersionId;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.versions !== nextProps.versions) {
            let versions = [];

            nextProps.versions && nextProps.versions.map((version) => {
                if(nextProps.selectedVersionId) {
                    if(version.versionId == nextProps.selectedVersionId) {
                        versions.push({...version, selected: true});
                    } else {
                        versions.push({...version, selected: false});
                    }
                } else {
                    versions.push({...version, selected: version.isActive === true || version.isActive === 'Y'});
                }

            });

            return {versionList: versions};
        }
        return null;
    }

    onRadioClick = (selectValue) => {
        let {versionList} = this.state;

        versionList && versionList.map((version) => {
            if(version.versionId == selectValue) {
                version.selected = true;
                this.onChangeVersionId(version.versionId);
            } else {
                version.selected = false;
            }
        });

        this.setState({versionList});
    };

    render() {
        const {t, isChangeable} = this.props;
        const {versionList} = this.state;
        const {user: {dateFormat, timeFormat}}  = this.context;
        const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

        return (
            <div style={{paddingTop: '30px', position: 'relative'}}>
                <div className='pop_version_list' style={{height: "350px", marginTop: "15px"}}>
                    <table style={{width: '100%'}}>
                        <colgroup>
                            <col width="100px"/>
                            <col width="130px"/>
                            <col width="130px"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th style={{width: '115px'}}>{t("COM_LIST_TITLE_VERSION")}</th>
                            <th style={{width: '130px'}}>{t("COM_TEXT_MODIFY_DATE_P")}</th>
                            <th style={{width: '130px'}}>{t("TEXT_TITLE_USER_P")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            versionList && versionList.map((version, index) => {
                                return (
                                    <tr className={version.selected ? 'on' : ''} value={version.versionId}
                                        onClick={() => {this.onRadioClick(version.versionId)}}>
                                        <td style={{width: '160px'}}>
                                            {
                                                isChangeable &&
                                                <input type="radio" id={version.versionId} name={version.versionId} value={version.versionId}/>
                                            }
                                            <label for="version" >{version.versionId}
                                                {(version.isActive === true || version.isActive === 'Y') && <span>Current</span>}
                                            </label>
                                        </td>
                                        <td>
                                            <Moment format={dateTimeFormat}>{version.lastModifiedDate}</Moment>
                                        </td>
                                        <td> {version.createdBy} </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withTranslation()(VersionTable);