import React from 'react';
import classNames from 'classnames';
import './MonthlyContainer.css';
import {isArray} from "lodash";

const MonthlyContainer = ({checked, onClick}) => {

    const month = [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5},
        {name: '6', value: 6},
        {name: '7', value: 7},
        {name: '8', value: 8},
        {name: '9', value: 9},
        {name: '10', value: 10},
        {name: '11', value: 11},
        {name: '12', value: 12},
        {name: '13', value: 13},
        {name: '14', value: 14},
        {name: '15', value: 15},
        {name: '16', value: 16},
        {name: '17', value: 17},
        {name: '18', value: 18},
        {name: '19', value: 19},
        {name: '20', value: 20},
        {name: '21', value: 21},
        {name: '22', value: 22},
        {name: '23', value: 23},
        {name: '24', value: 24},
        {name: '25', value: 25},
        {name: '26', value: 26},
        {name: '27', value: 27},
        {name: '28', value: 28},
        {name: '29', value: 29},
        {name: '30', value: 30},
        {name: '31', value: 31}
    ]

    const getChecked = day => {
        let days = checked;
        if (!isArray(checked)) {
            days = checked.split(',');
        }
        return days !== undefined && days.find(t => parseInt(t, 10) === day) !== undefined
    }

    return (
        <div className="repeat_type_monthly" style={{width:224,height:160,border:'1px solid #B4B9C3'}}>
            {
                month.map(
                    (day, index) => <div key={index} className={classNames("repeatTypeMonCal", {'on' : getChecked(day.value)})} data-value={day.value} onClick={onClick}>{day.name}</div>
                )
            }

        </div>
    )
}
export default MonthlyContainer;