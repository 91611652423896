import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../../switch/SwitchListNImage";
import SearchBar from "../../../search/SearchBar";
import classNames from "classnames";
import ContentThumbnail from "../../../image/ContentThumbnail";
import {deviceService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import PerfectScrollbar from 'perfect-scrollbar';
import DateToStr from "../../../utils/DateToStr";
import {Loading} from "../../../loading/Loading";
import {DEVICE_CUSTOM_FILES_OPTIONS} from "../../../../constants";
import Size from "../../../utils/Size";

const getFilePath = (type, path) => {
    const ind = path.lastIndexOf('.');

    if (type === '04') {
        return path.substring(0, ind) + '.png';
    } else {
        const ext = path.substring(ind);
        if(DEVICE_CUSTOM_FILES_OPTIONS['customLogo'].acceptFilesVideo.indexOf(ext) >= 0) {
            return path.substring(0, ind) + '.png';
        }
        return path;
    }
};

const ListView = ({contents, selectedId, handleSelect}) => {

    return (
        <table style={{width: '100%'}}>
            <colgroup>
                <col width="87px"/>
                <col width=""/>
            </colgroup>
            <tbody>
            {
                contents !== undefined && contents.length > 0 &&
                contents.map(
                    item  =>
                        <tr key={item.softwareId}
                            className={classNames({'on' : selectedId !== undefined && selectedId === item.softwareId})}
                            onClick={()=>handleSelect(item.softwareId, item.softwareName, item.filePath, item.fileName, item.fileSize)}
                        >
                            <td className="thum">
                                <div className="thumb_wrap">
                                    <div style={{width:'85px',height:'50px',textAlign:'center',lineHeight:'50px'}}>
                                        <ContentThumbnail id={'CUSTOM_LOGO'} width={85} height={50} style={{width: '85px', height: '50px'}} params={{logoFilePath: getFilePath(item.softwareType, item.filePath)}}/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h4 className="contentName">
                                    <span className="text-overflow" style={{width:'300px'}}>{item.softwareName}</span>
                                </h4>
                                <ul>
                                    <li><span><Size size={item.fileSize} /></span></li>
                                    <li><span><DateToStr date={item.createDate} newLine={false}/></span></li>
                                </ul>
                            </td>
                        </tr>
                )
            }
            </tbody>
        </table>
    )
}

const ImageView  = ({contents, selectedId, handleSelect}) => {
    return (
        <div className={'thumbnailView_wrap'}>
            <ul className="clearfix mt20">
                {
                    contents !== undefined && contents.length > 0 &&
                    contents.map(
                        item =>
                            <li>
                                <div style={{cursor:'pointer'}} className={classNames('thumbview_box', {'on' : selectedId !== undefined && selectedId === item.softwareId})} onClick={()=>handleSelect(item.softwareId, item.softwareName, item.filePath, item.fileName, item.fileSize)}>
                                    <div style={{width:75,height:42,textAlign:'center'}}>
                                        <ContentThumbnail id={'CUSTOM_LOGO'} width={75} height={42} style={{width: '75px', height: '42px'}} params={{logoFilePath: getFilePath(item.softwareType, item.filePath)}}/>
                                    </div>
                                    <div className="device_info"><span className="contentName">{item.softwareName}</span></div>
                                    <div className="check"><span></span></div></div>
                            </li>
                    )
                }
            </ul>
        </div>
    )
}


export const CustomizeContents = ({filter, mode='content', onSelect, selectedId = undefined, type}) => {

    const {t} = useTranslation();

    const [content, setContent] = useState({
        filter: filter,
        list: [],
        isFetching: false,
        loading: false,
        count: 0,
        totalCount: 0
    })

    const [end, setEnd] = useState(false);

    const [view, setView] = useState('LIST');

    const handleSelect = (id, name, thumbFileId, mainFileName, totalSize) => {
        onSelect(id, name, thumbFileId, mainFileName, totalSize);
    };

    const fetchContents = () => {
        setContent({...content, loading: true})
        deviceService.fetchDeviceRegisteredSoftware(content.filter)
            .then(res => {
                setContent({
                    ...content,
                    list: content.filter.startIndex === 1 ? res.items : content.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: content.count + 1,
                    totalCount: res.totalCount,
                    filter: {...content.filter }
                })
            }).catch(res=> toastr.error(res));
    }

    const saveFilter = (filter) => {
        const {contents, users, tags, fileSizes} = filter;
        const getTagIds = (tags) => {
            const rtnTags = [];
            tags !== undefined && tags.length > 0 && tags.map(
                tag => rtnTags.push(tag.tagId)
            )
            return rtnTags;
        }
        const getUserIds = (users) => {
            const rtnUsers = [];
            users !== undefined && users.length > 0 && users.map(
                user => rtnUsers.push(user)
            )
            return rtnUsers.toString();
        }
        const updateTags = getTagIds(tags);
        const updateUsers = getUserIds(users);
        setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, contentTypes: contents, userId: updateUsers, tagIds: updateTags, fileSizes: fileSizes}})
    }

    const handleContentTypes = (selected) => {
        setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, contentTypes: selected}})
    }

    const handleListNImage = () => {
        setView(view === 'LIST' ? 'IMAGE' : 'LIST');
    }

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_preset_content_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    }

    const initScroll = useRef(false);

    useEffect(()=>{
        if (!initScroll.current && content.list.length > 0) {
            const newPs = new PerfectScrollbar('#select_preset_content_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_preset_content_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            initScroll.current = true;
        }
    }, [content.list])


    const onSearch = (value) => {
        setContent({...content, list: [], loading: false, isFetching: true,
            filter: {...content.filter, startIndex : 1 , searchText: value}});
        resetScrollPosition();
    }

    useEffect(()=>{
        if (content !== undefined && !content.loading && !content.isFetching && content.list !== undefined && content.list.length === 0 && content.count < 1) {
            resetScrollPosition();
            fetchContents();
        }
        if (content !== undefined && !content.loading && content.isFetching) {
            fetchContents();
        }
    }, [content])


    // useEffect(()=>{
    //     fetchContents();
    // }, [content.filter])

    useEffect(()=> {
        if (end) {
            const startIndex = content.filter.startIndex + 30;
            if(startIndex  <= content.totalCount){
                setContent({
                    ...content,
                    filter: {
                        ...content.filter,
                        startIndex: startIndex
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end])

    const filterContents = (list) => {
        return list.filter((item)=> item.softwareType === type)
    }

    return (
        <div className="" style={{display: 'inline-flex'}}>
            <div className="customize_content_wrap">
                <div className="mt10 mr10 mb10 ml20" style={{height: 30}}>
                    <div style={{float: 'left'}}>
                        <span style={{lineHeight: '30px'}}>{content.totalCount + t('COM_LFD_SID_ITEMS_S')}</span>
                        <span className="space"></span>
                    </div>
                    <div style={{float: 'right', display: 'flex'}}>
                        <SearchBar width={150} onClickSearch={onSearch} placeholder={t("LIST_TITLE_NAME") + ', ' + t('TEXT_CREATOR_P')} keyword={content.filter.searchText}/>
                        <SwitchListNImage view={view} onClick={handleListNImage}/>
                    </div>
                </div>
                <div className="pop_list" id={'select_preset_content_scroll_wrap'} style={{height:'430px', borderTop: '1px solid #e7e7e7', display: 'block', position: 'absolute', width: '100%'}}>
                    {
                        content.loading &&
                        <div style={{width:'100%', height:'100%', background: '#fff', opacity: 0.1, display: 'flex'}}><Loading /></div>

                    }
                    {
                        view === 'LIST' &&
                            <ListView contents={filterContents(content.list)} selectedId={selectedId} handleSelect={handleSelect}/>
                    }
                    {
                        view === 'IMAGE' &&
                            <ImageView contents={filterContents(content.list)} selectedId={selectedId} handleSelect={handleSelect}/>
                    }

                </div>
            </div>
        </div>
    )

}