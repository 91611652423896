import React from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";

export const MoreEventPopup = ({position, events, close}) => {
    const {t} = useTranslation();
    const dialogProps = {title: t("DID_MAIN_CONTENT"), modal:true, onClose: ()=> close()};
    return (
        <MISDialog
            dialog={dialogProps}
        >
            <div style={{height: 120, width: 120, overflow: 'auto'}}>
                {
                    events !== undefined && events.map(
                        event => <div title={event}>{event}</div>
                    )
                }

            </div>
        </MISDialog>
    )
}
