import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Ruleset from "../../components/ruleset/Ruleset";
import {menuAction, popupAction} from "../../actions";
import {commonConstants} from "../../constants/CommonConstants";
import {getMixString} from '../../language/languageUtils';
import {toastr} from 'helper/toastrIntercept';
import {MagicInfoDndProvider} from "../../components/dnd/dndHooks";

const RulesetManager = ({mode}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {newRuleset, editRuleset} = useSelector(state => ({
        newRuleset : state.rulesets.newRuleset,
        editRuleset: state.rulesets.editRuleset,
    }));
    let ruleset;

    try {
        if(mode === 'NEW') {
            ruleset = newRuleset.ruleset;
        }
        else {
            ruleset = editRuleset.ruleset;
        }
    } catch(e) {
        ruleset = undefined;
    }

    const saveRuleset = (saveAs) => {
        try {
            for(let i = 0; i < ruleset.rules.length; i++) {
                if(ruleset.rules[i].rule_tree === undefined || ruleset.rules[i].rule_tree.length === 0) {
                    toastr.error(getMixString(['MIS_SID_MIX_CAFEB_EMPTY_EXISTS_NOT_SAVE', 'MIS_SID_RULE_TREE']));
                    return;
                }
            }

            return dispatch(popupAction.addPopup({
                type: commonConstants.SAVE_RULESET,
                id: 'SAVE_RULESET',
                mode: saveAs ? "SAVE_AS" : mode,
                ruleset: ruleset
            }));
        } catch(e) {
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }
    };

    const cancel = (currentTab) => {
        return dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: 'CANCEL_RULESET',
            title: t("COM_BUTTON_CONFIRM"),
            message: t("MESSAGE_UPLOADER_REALLY_CANCEL_P"),
            height: '100px',

            onClickYes: () => {
                dispatch(menuAction.removeTab(currentTab));
                dispatch(popupAction.closePopup('CANCEL_RULESET'));
            },
            onClose: () => dispatch(popupAction.closePopup('CANCEL_RULESET'))
        }));
    }

    const functions = {
        saveRuleset: saveRuleset,
        cancel: cancel
    };

    return(
        <>
        {ruleset &&
            <MagicInfoDndProvider>
                <Ruleset ruleset={ruleset} functions={functions} mode={mode} />
            </MagicInfoDndProvider>
        }
        {!ruleset &&
            <div></div>
        }
            
        </>
    )
    
}

export default RulesetManager;