import React, {useRef, useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Line} from 'react-chartjs-2';
import './SystemUsageDetail.css'
import {deviceService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';

const SystemUsageDetail = ({device, t, status, onChange}) => {
    const MAX_ELEMENT = 10;
    const buttonStart = useRef();
    const buttonStop = useRef();
    const {deviceId, power} = device;
    const [isStarting, setIsStarting] = useState(status);
    const [isLoadingFinished, setIsLoadingFinished] = useState(false);
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: "CPU",
                backgroundColor: "rgba(109,140,242,0.2)",
                borderColor: "rgba(109,140,242,1)",
                pointBackgroundColor: "rgba(109,140,242,1)",
                pointStrokeColor: "#fff",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "rgba(109,140,242,1)",
                data: [],
            },
            {
                label: "RAM",
                backgroundColor: "rgba(248,108,121,0.2)",
                borderColor: "rgba(248,108,121,1)",
                pointBackgroundColor: "rgba(248,108,121,1)",
                pointStrokeColor: "#fff",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "rgba(248,108,121,1)",
                data: []
            },
            {
                label: "Network",
                backgroundColor: "rgba(58,203,99,0.2)",
                strokeColor: "rgba(58,203,99,1)",
                pointBackgroundColor: "rgba(58,203,99,1)",
                pointStrokeColor: "#fff",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "rgba(58,203,99,1)",
                data: []
            }
        ],
        maxValue : 80
    });

    useEffect(()=>{
        if(isStarting){
            onStartUsage();
        }
    }, [isStarting])

    const fetchUsageData = () => {
        deviceService.fetchDeviceSystemUsage(deviceId).then(res => {
            if (data.datasets[0].data.length === MAX_ELEMENT) {
                data.datasets[0].data.splice(0, 1);
                data.datasets[1].data.splice(0, 1);
                data.datasets[2].data.splice(0, 1);
                data.labels.splice(0, 1);
            }

            data.datasets[0].data.push(res.items.cpu);
            data.datasets[1].data.push(res.items.mem);
            data.datasets[2].data.push(res.items.network);
            data.labels.push(res.items.time);
            data.maxValue = Math.max(parseInt(Math.ceil(Math.max(res.items.cpu, res.items.mem, res.items.network) / 10) * 10), 1);

            setIsLoadingFinished(true);
            setData({...data});

            if (buttonStop.current === null || buttonStop.current === undefined) {
                return;
            }

            if (buttonStop.current.style.display === '') {
                setTimeout(function () {
                    fetchUsageData();
                }, 1000);
            }
        }).catch(error => {
            toastr.error(error.statusText);
        });
    };

    const onStartUsage = () => {
        buttonStart.current.style.display = 'none';
        buttonStop.current.style.display = '';

        setIsLoadingFinished(false);
        setIsStarting(true);

        fetchUsageData();
    }

    const onClickStartUsage = () => {
        onStartUsage();
        onChange(true);
    };

    const onClickStopUsage = () => {
        buttonStart.current.style.display = '';
        buttonStop.current.style.display = 'none';

        data.datasets[0].data = [];
        data.datasets[1].data = [];
        data.datasets[2].data = [];
        data.labels = [];

        onChange(false);
    };

    return (
        <div className='detail_view mini_4 mt15'>
            <div>
                <button
                    className='base'
                    onClick={onClickStartUsage}
                    style={{width: 102, marginBottom: 5}}
                    disabled={power !== true}
                    ref={buttonStart}
                >
                    {t('COM_BUTTON_START_P')}
                </button>
                <button
                    className='base'
                    onClick={onClickStopUsage}
                    style={{width: 102, marginBottom: 5, display: 'none'}}
                    disabled={power !== true}
                    ref={buttonStop}
                >
                    {t('COM_BUTTON_STOP_P')}
                </button>
            </div>
            <div style={{display: isStarting ? '' : 'none'}}>
                <div style={{width: 400, height: 300}}>
                    {
                        !isLoadingFinished &&
                        <div id="deviceUsageLoading98-83-89-88-8f-df">{t('MESSAGE_DEVICE_WAIT_FOR_LOADING_P')}</div>
                    }
                    <div style={{display: isLoadingFinished ? '' : 'none'}}>
                        <Line data={data} width={400} height={300} options={{
                            responsive: false,
                            legend: {
                                display: false,
                            },
                            animation: {
                                duration: 1000,
                                easing: 'linear'
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                }],
                                yAxes: [{display: true, ticks: {beginAtZero: true, min: 0, max: data.maxValue}}]
                            },
                            elements: {
                                point: {
                                    radius: 3
                                }
                            },
                            tooltips: {
                                mode :'label',
                                titleFontSize: 14,
                                bodyFontSize : 14,
                                callbacks: {
                                    label: function(tooltipItem, data) {
                                        return ' ' + tooltipItem.value;
                                    }
                                }
                            }
                        }}/>
                    </div>
                </div>
                <ul className="usage_name">
                    <li><span className="bgc_6d8cf2"></span>CPU (%)</li>
                    <li><span className="bgc_f86c79"></span>RAM (%)</li>
                    <li><span className="bgc_3acb63"></span>NETWORK (%)</li>
                </ul>
            </div>
        </div>
    );
};

export default withTranslation()(SystemUsageDetail);
