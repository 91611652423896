import React from 'react';
import './PublishGroup.css';

const PublishGroup = ({group, onDelete}) => {
    return (
        <div className='sw_publish_group_wrap'>
            <span>{group.groupName}</span>
            <span><button className="delete_button" onClick={onDelete}></button></span>
        </div>
    );
};

export default PublishGroup;