import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import RadioGroup from "../../../radio/RadioGroup";
import { popupAction } from "../../../../actions";
import { commonConstants } from "../../../../constants";
import NumberInput from "../../../input/NumberInput";
import Preview from "./Preview";
import MultiSelectButton from "../../../button/MultiSelectButton";
import WhiteButton from "../../../button/WhiteButton";

const makeValue = (orginal,sign) => {
    let value = "";
    if (sign === "eq") {
        value = orginal;
    } else if (sign === "sw") {
        value = orginal + "*";
    } else if (sign === "ct") {
        value = "*" + orginal + "*";
    }
    return value;
};

const ContentType = ({
    playType,
    mode,
    play,
    device,
    setDefaultDuration,
    addContentList,
    setSign,
    handleFilter
}) => {
    const [type, setType] = useState(play ? play.type : "static");
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onChangeType = (e, value) => {
        setSign(value);
        setType(value);
    };

    const [contents, setContents] = useState(play ? play.contentList : []);
    const [imageDuration, setImageDuration] = useState(play ? play.defaultDuration : 5);
    const [filter, setFilter] = useState(
        play
            ? play.filter
            : {
                  filterSign: "eq",
                  filterExpression: "",
                  filterExpressionType: "text",
                  filterSrcType: "device_name",
                  filterDstType: "content_name",
                  filterValue1: "",
                  filterValue2: "",
                  filterValue3: "",
                  filterValue4: "",
                  filterSeperator: "_"
              }
    );
    

    const [filterList1, setFilterList1] = useState([]);
    const [filterList2, setFilterList2] = useState([]);
    const [filterList3, setFilterList3] = useState([]);
    const [filterList4, setFilterList4] = useState([]);

    const selectContents = selected => {
        const newContents = selected;
        setContents(newContents);
        addContentList(newContents);
    };

    const handleImageDuration = (e, value) => {
        setImageDuration(value);
        setDefaultDuration(value);
    };

    const deleteContent = id => {
        const newContent = contents.filter(i => i.contentId !== id);
        setContents(newContent);
        addContentList(newContent);
    };

    const onChangeFilterSign = (e, value) => {
        setFilter({ ...filter, filterSign: value, filterExpression : makeValue(filter.filterExpression,filter.filterSign)});
        handleFilter({ ...filter, filterSign: value, filterExpression : makeValue(filter.filterExpression,filter.filterSign)});

    };

    const onChangeFilterValue1 = index => {
        let updateValues = "";
        index.forEach(value => {
            updateValues += ";" + filterList1[value].name;
        });

        setFilter({ ...filter, filterValue1: updateValues.replace(";", "") });        

    };

    const onChangeFilterValue2 = index => {

        let updateValues = "";
        index.forEach(value => {
            updateValues += ";" + filterList2[value].name;
        });

        setFilter({ ...filter, filterValue2: updateValues.replace(";", "") });    

    };

    const onChangeFilterValue3 = index => {

        let updateValues = "";
        index.forEach(value => {
            updateValues += ";" + filterList3[value].name;
        });

        setFilter({ ...filter, filterValue3: updateValues.replace(";", "") });
   
    };

    const onChangeFilterValue4 = index => {

        let updateValues = "";
        index.forEach(value => {
            updateValues += ";" + filterList4[value].name;
        });

        setFilter({ ...filter, filterValue4: updateValues.replace(";", "") });
 
    };

    const onApplyFormatSetting = filterItem => {
        
        const keywordValueList1 = filterItem.filterValue1.split(";");
        const keywordValueList2 = filterItem.filterValue2.split(";");
        const keywordValueList3 = filterItem.filterValue3.split(";");
        const keywordValueList4 = filterItem.filterValue4.split(";");

        const filterTemp1 = [];
        keywordValueList1.forEach((el, index) => {
            filterTemp1.push({ id: index, name: el, isChecked: true });
        });

        const filterTemp2 = [];
        keywordValueList2.forEach((el, index) => {
            filterTemp2.push({ id: index, name: el, isChecked: true });
        });

        const filterTemp3 = [];
        keywordValueList3.forEach((el, index) => {
            filterTemp3.push({ id: index, name: el, isChecked: true });
        });

        const filterTemp4 = [];
        keywordValueList4.forEach((el, index) => {
            filterTemp4.push({ id: index, name: el, isChecked: true });
        });

        setFilterList1(filterTemp1);
        setFilterList2(filterTemp2);
        setFilterList3(filterTemp3);
        setFilterList4(filterTemp4);

        let filterExpression = "";
        if(filterItem.filterValue1 !== ""){
            filterExpression += "%{0}"; 
        }

        if(filterItem.filterValue2 !== ""){
            filterExpression += "_%{1}"; 
        }

        if(filterItem.filterValue3 !== ""){
            filterExpression += "_%{2}"; 
        }

        if(filterItem.filterValue4 !== ""){
            filterExpression += "_%{3}"; 
        }
        const newFilter = {
            ...filter,
            filterValue1: filterItem.filterValue1,
            filterValue2: filterItem.filterValue2,
            filterValue3: filterItem.filterValue3,
            filterValue4: filterItem.filterValue4,
            filterExpression : makeValue(filterExpression,filter.filterSign)
        };
        setFilter(newFilter);
        handleFilter(newFilter);

    };


    const openSettingPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.RULESET_PLAY_FORMAT_SETTING_POPUP,
                type: commonConstants.RULESET_PLAY_FORMAT_SETTING_POPUP,
                filter: filter,
                onApply: onApplyFormatSetting,
                onClose: () =>
                    dispatch(popupAction.closePopup(commonConstants.RULESET_RULETREE_OPTION_POPUP))
            })
        );
    };

    const openContentsPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                type: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                handleSelected: selectContents,
                contentType: playType,
                device: device,
                onClose: () =>
                    dispatch(popupAction.closePopup(commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP))
            })
        );
    };

    return (
        <>
            <table className="content_result">
                <tbody>
                    {/* <tr>
                        <th>{t("MIS_SID_AUTO_MAPPING")}</th>
                        <td>
                            <RadioGroup
                                selects={[
                                    { title: t("MIS_SID_EQUAL"), value: "static" },
                                    { title: t("MIS_SID_AUTO_MAPPING"), value: "dynamic" }
                                ]}
                                value={type}
                                onChange={onChangeType}
                                multiLang={true}
                            />
                        </td>
                    </tr> */}
                    {type === "static" && (
                        <tr>
                            <th width="150px">{t("DID_MAIN_CONTENT")}</th>
                            <td>
                                <button
                                    className="base searchBtn"
                                    onClick={openContentsPopup}
                                ></button>
                                <div className="ruleset_play_selected_item_box">
                                    {contents.map((item, index) => (
                                        <div
                                            style={{
                                                padding: "5px",
                                                borderBottom: "1px solid #e7e7e7",
                                                textOverflow : "ellipsis",
                                                overflow:"hidden", 
                                                whiteSpace:"normal"
                                            }}
                                        >
                                            <input type="hidden" value={item.contentId} key={index} />
                                            <span>{item.contentName}</span>
                                            <button
                                                style={{ float: "right" }}
                                                className="selectedDeviceDelBtn"
                                                onClick={() => {
                                                    deleteContent(item.contentId);
                                                }}
                                            ></button>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    )}
                    {type === "dynamic" && (
                        <>
                            <tr>
                                <th>{t("MIS_SID_MIX_MAPPING_WITH")}</th>
                                <td>{t("TABLE_DEVICE_NAME_P")}</td>
                            </tr>
                            <tr>
                                <th>{t("COM_IDS_EDIT_FORMAT")}</th>
                                <td>
                                    {filterList1.length > 0 &&
                                        filterList1[0].name !== "" && (
                                            <MultiSelectButton
                                                itemAll={true}
                                                title={filterList1[0].name + "..."}
                                                data={filterList1}
                                                onChange={onChangeFilterValue1}                                                
                                            />
                                        )}
                                    {filterList2.length > 0 &&
                                        filterList2[0].name !== "" && (
                                            <>
                                                {" "}
                                                {" _ "}
                                                <MultiSelectButton
                                                    itemAll={true}
                                                    title={filterList2[0].name+ "..."}
                                                    data={filterList2}
                                                    onChange={onChangeFilterValue2}
                                                />{" "}
                                            </>
                                        )}
                                    {filterList3.length > 0 &&
                                        filterList3[0].name !== "" && (
                                            <>
                                                {" "}
                                                {" _ "}
                                                <MultiSelectButton
                                                    itemAll={true}
                                                    title={filterList3[0].name + "..."}
                                                    data={filterList3}
                                                    onChange={onChangeFilterValue3}
                                                />
                                            </>
                                        )}
                                    {filterList4.length > 0 &&
                                        filterList4[0].name !== "" && (
                                            <>
                                                {" "}
                                                {" _ "}
                                                <MultiSelectButton
                                                    itemAll={true}
                                                    title={filterList4[0].name + "..."}
                                                    data={filterList4}
                                                    onChange={onChangeFilterValue4}
                                                />{" "}
                                            </>
                                        )}

                                    <WhiteButton
                                        name={t("COM_SID_SETTINGS")}
                                        onClick={openSettingPopup}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>{t("TEXT_CONTENT_NAME_P")}</th>
                                <td>
                                    <RadioGroup
                                        propertyName={"sign"}
                                        selects={[
                                            { title: t("MIS_SID_EQUAL"), value: "eq" },
                                            { title: t("MIS_SID_START_WITH"), value: "sw" },
                                            { title: t("MIS_SID_CONTAINS"), value: "ct" }
                                        ]}
                                        value={filter.filterSign}
                                        onChange={onChangeFilterSign}
                                        multiLang={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>
                                    <Preview filter={filter} />
                                </td>
                            </tr>
                        </>
                    )}
                    <tr>
                        <th width="150px">{t("COM_MIA_SID_DEFAULT_DURATION")}</th>
                        <td>
                            <NumberInput
                                propertyName="imageDuration"
                                value={imageDuration}
                                onChange={handleImageDuration}
                                min={0}                                
                            />
                            <span>{t("TEXT_SEC_P")}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default ContentType;
