import React from 'react';
import './GroupButton.css';

class GroupButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const onClick = this.props.onClick || null;
        return(
            <div className={"group_button_wrap float_l"}>
                <button className="group_button base" style={{backgroundColor:'#333c4e',color: '#dedede'}} onClick={onClick}></button>
            </div>
        )
    }

}
export default GroupButton;