import React, { useState } from "react";
import { rulesetService } from "../../../../services";
import { useTranslation } from "react-i18next";

const Preview = ({ sign, value, type }) => {
    const { t } = useTranslation();

    const [filter, setfilter] = useState({
        page: 0,
        pageSize: 1000,
        type: type,
        sign: sign,
        value: value
    });

    if (filter.sign !== sign || filter.value !== value) {
        setfilter({
            ...filter,
            sign: sign,
            value: value
        });
    }

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const { page, pageSize } = filter;

    const fetchData = () => {
        const { page, pageSize, type, sign, value } = filter;
        rulesetService
            .fetchConditionPreview({
                startIndex: page,
                pageSize,
                type: type,
                sign: sign,
                value: value
            })
            .then(res => {
                setData({
                    ...data,
                    loading: false,
                    items: res.items,
                    totalCount: res.totalCount,
                    pages: Math.ceil(res.totalCount / pageSize)
                });
            });
    };

    return (
        <>
            <div className="preview">
                <button className="replash2" style={{ float: "left" }} onClick={fetchData}></button>
                <span style={{ padding: "4px", float: "left" }}>
                    {t("MIS_SID_PREVIEW_TARGET_DEVICE")}
                    {" ("}<span>{data.items.length}</span>{")"}
                </span>
            </div>
            <div className="selected_item_box_condition" style={{ clear: "both", width: "98%" }}>
                <div className="pop_list">
                    <table>
                        <thead>
                            <tr>
                                { type === "dev_mdnm" &&
                                    <th style={{ width: "100px" }}>
                                        <span>{t("TABLE_DEVICE_MODEL_NAME_P")}</span>
                                    </th>
                                }
                                <th style={{ width: "100px" }}>
                                    <span>{t("TEXT_DEVICE_GROUP_P")}</span>
                                </th>
                                <th style={{ width: "100px" }}>
                                    <span>{t("TABLE_DEVICE_NAME_P")}</span>
                                </th>                           
                                {(type === "device_nm" || type === "dev_mdnm") &&  
                                    <th style={{ width: "100px" }}>
                                        <span>{t("COM_SID_IP_ADDRESS")}</span>
                                    </th>
                                }
                                { type === "screen_rotation"&&
                                    <th style={{ width: "100px" }}>
                                        <span>{t("TEXT_SCREEN_ROTATION_P")}</span>
                                    </th>
                                }
                                {type === "screen_size" && 
                                    <th style={{ width: "100px" }}>
                                        <span>{t("TABLE_SCREEN_SIZE_P")}</span>
                                    </th>
                                }                                         
                            </tr>
                        </thead>
                    </table>
                    <div
                        style={{
                            height: "77px",
                            overflowY: "auto",
                            //backgroundColor: "white"
                        }}
                    >
                        <table id="devicePreviewTable">
                            <tbody>
                                {data.items.map((item, index) => (
                                    <tr>
                                         {type === "dev_mdnm" &&
                                            <td style={{ width: "100px" }}>
                                                <span>{item.deviceModelName}</span>
                                            </td> 
                                        }                                        
                                        <td style={{ width: "100px" }}>
                                            <span>{item.deviceGroupName}</span>
                                        </td>                                        
                                        <td style={{ width: "100px" }}>
                                            <span>{item.deviceName}</span>
                                        </td>
                                       
                                        {(type === "device_nm" || type === "dev_mdnm") &&                             
                                            <td style={{ width: "100px" }}>
                                                <span>{item.ipAddress}</span>
                                            </td>
                                        }
                                        {type === "screen_rotation" &&
                                            <td style={{ width: "100px" }}>
                                                <span>{item.screenRotation==="0"?t("COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE"):t("COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT")}</span>
                                            </td> 
                                        }
                                        { type === "screen_size" && 
                                            <td style={{ width: "100px" }}>
                                                <span>{item.screenSize}</span>
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preview;
