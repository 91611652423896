import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import {deviceService} from '../../../../services';
import {deviceOptions} from '../../../misopt';
import RadioGroup from '../../../radio/RadioGroup';
import Select from '../../../selectbox/Select';
import NumberInput from '../../../input/NumberInput';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import {getErrorMessage} from '../../../../helper/responseHandler';
import {DeviceFadeInTransition, DeviceFadeOutTransition} from '../../../device/DeviceTransition';
import {Loading} from '../../../loading/Loading';
import './EditLedCabinetPopup.css';

const EditLedCabinetPopup = ({device, cabinetIds, onSave, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [cabinet, setCabinet] = useState({});
    const [loading, setLoading] = useState(false);
    const pollingHandle = useRef();
    const pollingCount = useRef(0);
    const {deviceId, deviceName} = device;
    const checkAndClearPollingInterval=()=>{
        if(pollingCount.current >= 20) {
            clearPollingInterval();
            dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));
            return true;
        }
        return false;
    }
    const clearPollingInterval=()=>{
        clearInterval(pollingHandle.current);
        pollingCount.current = 0;
    }

    const fetchCurrentData = showInitMessage => {
        deviceService.fetchCurrentLedCabinet(deviceId, cabinetIds).then(res => {
            //dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
            if(showInitMessage) {
                toastr.info(t('MIS_TEXT_INITIALIZING_P'));
            }
                        
            pollingHandle.current = setInterval(() => {
                deviceService.fetchCurrentLedCabinetStatus(deviceId, res.items.requestId).then(res => {

                    if(res && res.items && res.items.successList && res.items.successList.length > 0){
                        clearPollingInterval();
                        toastr.success(t('MSG_FINISHED'));
                        dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));
                    } else {
                        pollingCount.current ++;
                        if(checkAndClearPollingInterval())
                        {
                            toastr.error(t('ALERT_FAIL'));
                        }
                        return;                        
                    }
    
                    deviceService.fetchDeviceLedCabinetsByIds(deviceId, cabinetIds).then(res => {
                        if(res.status === 'Fail') {
                            pollingCount.current ++;
                            checkAndClearPollingInterval();
                        } else {
                            setCabinetData(res.items);
                        }
                    }).catch(error => console.log(error));

                }).catch(error => {
                    pollingCount.current ++;
                    checkAndClearPollingInterval();
                }).catch(error => {
                    if(error !== 'Fail') {
                        toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                    }
                });
            }, 1500);
        }).catch(error => {
            toastr.error(getErrorMessage(error));
            dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));
        });
    };

    const onClickSave = () => {
        dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
        deviceService.updateDeviceLedCabinet(deviceId, cabinetIds, {
            onScreenDisplay: cabinet.onScreenDisplay,
            gamut: cabinet.gamut,
            backlight: cabinet.backlight,
            pixelRgbCc: cabinet.pixelRgbCc,
            moduleRgbCc: cabinet.moduleRgbCc,
            edgeCorrection: cabinet.edgeCorrection,
        }).then(res => {
            // toastr.success(t('COM_TEXT_SUCCESS_P'));
            fetchCurrentData();
        }).catch(error => {
            toastr.error(t('ALERT_FAIL'));
            dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));
        }); //.finally(() => dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING')));

        if(onSave) {
            onSave();
        }
    };

    const setCabinetData = cabinets => {
        const onScreenDisplayArr = cabinets.filter(cab => cab.onScreenDisplay || cab.onScreenDisplay == 0).map(cab => cab.onScreenDisplay);
        const gamutArr = cabinets.filter(cab => cab.gamut || cab.gamut == 0).map(cab => cab.gamut);
        const backlightArr = cabinets.filter(cab => cab.backlight || cab.backlight == 0).map(cab => cab.backlight);
        const maxBacklightArr = cabinets.filter(cab => cab.maxBacklight).map(cab => cab.maxBacklight);
        const pixelRgbCcArr = cabinets.filter(cab => cab.pixelRgbCc || cab.pixelRgbCc == 0).map(cab => cab.pixelRgbCc);
        const moduleRgbCcArr = cabinets.filter(cab => cab.moduleRgbCc || cab.moduleRgbCc == 0).map(cab => cab.moduleRgbCc);
        const edgeCorrectionArr = cabinets.filter(cab => cab.edgeCorrection || cab.edgeCorrection == 0).map(cab => cab.edgeCorrection);

        setCabinet({
            onScreenDisplayArr,
            gamutArr,
            backlightArr,
            maxBacklightArr,
            pixelRgbCcArr,
            moduleRgbCcArr,
            edgeCorrectionArr,
            onScreenDisplay: onScreenDisplayArr.length > 0 ? onScreenDisplayArr[0] : undefined,
            gamut: gamutArr.length > 0 ? gamutArr[0] : undefined,
            backlight: backlightArr.length > 0 ? backlightArr[0] : undefined,
            maxBacklight: maxBacklightArr.length > 0 ? maxBacklightArr[0] : undefined,
            pixelRgbCc: pixelRgbCcArr.length > 0 ? pixelRgbCcArr[0] : undefined,
            moduleRgbCc: moduleRgbCcArr.length > 0 ? moduleRgbCcArr[0] : undefined,
            edgeCorrection: edgeCorrectionArr.length > 0 ? edgeCorrectionArr[0] : undefined,
        });
    };

    const onRefresh = () => {
        dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
        fetchCurrentData();
    };

    useEffect(() => {
        setLoading(true);
        deviceService.fetchDeviceLedCabinetsByIds(deviceId, cabinetIds).then(res => {
            const cabinets = res.items;
            if(cabinetIds.length === 1 && (!cabinets || isNil(cabinets[0].gamut) || isNil(cabinets[0].backlight) 
                || isNil(cabinets[0].pixelRgbCc) || isNil(cabinets[0].moduleRgbCc) || isNil(cabinets[0].edgeCorrection))){

                dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
                fetchCurrentData(true);
            } else {
                setCabinetData(cabinets);
            }

        }).catch(error => toastr.error(getErrorMessage(error)))
        .finally(() => setLoading(false));
    }, [deviceId, cabinetIds]);

    return (
        <div className='edit_led_cabinet_wrap'>
            <MISDialog
                dialog={{
                    title: deviceName,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 865,
                    height: 450,
                    onClose,
                }}
                buttons={{
                    leftButtons: [
                        {
                            id: 'COM_TV_SID_CURRENT_STATUS_KR_KOR',
                            title: t('COM_TV_SID_CURRENT_STATUS_KR_KOR'),
                            onClick: onRefresh,
                        }
                    ],
                    rightButtons: [
                        {
                            id: 'COM_BUTTON_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            disable: !cabinet.changed,
                        },
                        {
                            id: 'COM_BUTTON_CLOSE_P',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}
            >
                <div className="devicepop_wrap">
                    <DeviceFadeOutTransition transition={!loading} classes={['device_loading_wrap']}>
                        <Loading />
                    </DeviceFadeOutTransition>
                    <DeviceFadeInTransition transition={!loading}>
                        <div className="devicepop cabinet_control" style={{border:0}}>
                            <div className="devicepop cabinet_control mb26">
                                <h3>{t('COM_LFD_SID_CABINET_SETTINGS')}</h3>
                                <div className="detail_view mini_p mb15">
                                    <table>
                                        <colgroup>
                                            <col width="203px"/>
                                            <col width=""/>
                                        </colgroup>
                                        <tbody>
                                            {
                                                cabinet.onScreenDisplayArr && cabinet.onScreenDisplayArr.length > 0 &&
                                                <tr>
                                                    <th>{t('COM_TV_SID_SCREEN_DISPLAY')}</th>
                                                    <td colSpan="2">
                                                        <RadioGroup selects={deviceOptions.onOffOptions} value={cabinet.onScreenDisplay} onChange={(e, value) => setCabinet({...cabinet, onScreenDisplay: value, changed: true})} />
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                cabinet.gamutArr && cabinet.gamutArr.length > 0 &&
                                                <tr>
                                                    <th>{t('MIS_SID_SERVER_GAMUT')}</th>
                                                    <td colSpan="2">
                                                        <Select width={120} selects={deviceOptions.gamutOptions} value={cabinet.gamut} onChange={(e, value) => setCabinet({...cabinet, gamut: value, changed: true})} />
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                cabinet.backlightArr && cabinet.backlightArr.length > 0 && cabinet.maxBacklight &&
                                                <tr>
                                                    <th>{t('MIS_SID_SERVER_BACKLIGHT')}</th>
                                                    <td colSpan="2">
                                                        <NumberInput width={100} min={0} max={cabinet.maxBacklight} value={cabinet.backlight} onChange={(e, value) => setCabinet({...cabinet, backlight: value, changed: true})} forceInt />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="devicepop cabinet_control mb26" style={{border:0}}>
                                <h3>{t('COM_LFD_SID_CABINET_CALIBRATION')}</h3>
                                <div className="detail_view mini_p mt14 mb15">
                                    <table>
                                        <colgroup>
                                            <col width="203px"/>
                                            <col width=""/>
                                        </colgroup>
                                        <tbody>
                                            {
                                                cabinet.pixelRgbCcArr && cabinet.pixelRgbCcArr.length > 0 &&
                                                <tr>
                                                    <th>{t('COM_LFD_SID_PIXEL_RGB_CC')}</th>
                                                    <td colSpan="2">
                                                        <RadioGroup selects={deviceOptions.onOffOptions} value={cabinet.pixelRgbCc} onChange={(e, value) => setCabinet({...cabinet, pixelRgbCc: value, changed: true})} />
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                cabinet.moduleRgbCcArr && cabinet.moduleRgbCcArr.length > 0 &&
                                                <tr>
                                                    <th>{t('COM_LFD_SID_MODULE_RGB_CC')}</th>
                                                    <td colSpan="2">
                                                        <RadioGroup selects={deviceOptions.onOffOptions} value={cabinet.moduleRgbCc} onChange={(e, value) => setCabinet({...cabinet, moduleRgbCc: value, changed: true})} />
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                cabinet.edgeCorrectionArr && cabinet.edgeCorrectionArr.length > 0 &&
                                                <tr>
                                                    <th>{t('COM_LFD_SID_EDGE_CORRECTION')}</th>
                                                    <td colSpan="2">
                                                        <RadioGroup selects={deviceOptions.onOffOptions} value={cabinet.edgeCorrection} onChange={(e, value) => setCabinet({...cabinet, edgeCorrection: value, changed: true})} />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </DeviceFadeInTransition>
                </div>
            </MISDialog>
        </div>
    );
};

export default EditLedCabinetPopup;