import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import {userService} from "../../../services";
import AddOrganizationDetailedInformation from './AddOrganizationDetailedInformation';
import {checkPostValidation, checkPreValidation, handleChange} from './common/userCommons';
import {commonConstants} from "../../../constants";

const useUserInfo = (initialInfo, t) => {
    const [userInfo, setUserInfo] = useState(initialInfo);
    
    const onChangeUserInfo = (e, value) => {
        const newInfo = handleChange(e, value, userInfo, t);
        setUserInfo(newInfo);
    };

    return [userInfo, setUserInfo, onChangeUserInfo];
};

const createUpdateInfo = ({dataField}) => {
    const updateInfo = {userInfo: {}};
    for (let fieldName in dataField) {
        switch (fieldName) {
            case 'email':
            case 'jobPosition':
            case 'mobileNum':
            case 'password':
            case 'phoneNum':
            case 'team':
            case 'userId':
            case 'userName':
                updateInfo.userInfo[fieldName] = dataField[fieldName];
                break;
            default:
                updateInfo[fieldName] = dataField[fieldName];
        }
    }
    return updateInfo;
};

const AddOrganizationPopup = ({onClickYes, onClose, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const timer = useRef(null);
    const [userInfo, setUserInfo, onChangeUserInfo] = useUserInfo({
        dataField: {
            userId: null,
            password: null,
            userName: null,
            email: null,
            mobileNum: null,
            phoneNum: null,
            team: null,
            jobPosition: null,
            organizationName: null,
        },
        errors: {}
    }, t);

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const displayErrorStatus = (errorCode) => {
        if (errorCode === '400009') {
            toastr.error(t('MESSAGE_COMMON_EXIST_ORGAN_P'));
        }
    };

    const onClickSave = () => {
        userService.checkUserIdAvailability(userInfo.dataField.userId).then(res => {
            if (res.items.result === 0) {
                const {errors} = userInfo;
                errors['userId'] = t('MESSAGE_USER_ID_NOT_AVAILABLE_P').replace('<br>', ' ');
                setUserInfo({...userInfo});
            } else {
                if (checkPreValidation(userInfo, setUserInfo, t)) {
                    return;
                }

                if (checkPostValidation(userInfo, t)) {
                    return;
                }

                addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});
                userService.saveOrganizations(createUpdateInfo(userInfo)).then(res => {
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));

                    const newInfo = {...userInfo, errors: {}};
                    setUserInfo(newInfo);

                    onClickYes();
                    onClose();
                }).catch(error => {
                    displayErrorStatus(error.errorCode);
                }).finally(() => {
                    closePopup(commonConstants.LOADING_POPUP);
                });
            }
        });
    };

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_TITLE_CREATE_ORGAN_P'),
                closeOnEscape: true,
                modal: true,
                width: 730,
                height: 680,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <AddOrganizationDetailedInformation userInfo={userInfo} onChange={onChangeUserInfo}/>

        </MISDialog>
    );
};

AddOrganizationPopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(AddOrganizationPopup);