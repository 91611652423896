import React, {useCallback, useEffect, useState} from 'react';
import {dashboardConstants} from "../../../../constants/DashboardConstants";
import DEVICE_ICON from "../../../../images/icon/ic_noti_device.svg";
import CONTENT_ICON from "../../../../images/icon/ic_noti_content.svg";
import USER_ICON from "../../../../images/icon/ic_noti_user.svg";
import {dashboardService} from "../../../../services";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {menuAction} from "../../../../actions";
import {isNull, isNil} from "lodash";

const UnApprovedResource = ({authorities}) => {

    const Badge = ({number}) => {

        const width = number < 10 ? 10 : 22;
        const radius = number < 10 ? 12 : 8;

        return (
            <span style={{
                minWidth: width,
                textAlign: 'center',
                fontSize: '11px',
                color: '#fff',
                display: 'inline-block',
                height: 20,
                background: '#3196FF',
                borderRadius: radius,
                lineHeight: '20px',
                paddingLeft: '6px',
                paddingRight: '6px',
                marginLeft: '-12px',
                position: 'absolute'}}
            >{number > 99 ? '99+' : number}</span>
        )
    }

    const Resource = ({id, count = 0}) => {
        const {t} = useTranslation();
        const dispatch = useDispatch();

        const Icon = useCallback(()=> {
            switch(id) {
                case dashboardConstants.UNAPPROVED_DEVICE :
                    return <img src={DEVICE_ICON} title={t("MIS_SID_CBFEB_UNAPPROVED_DEVICES")} />
                case dashboardConstants.UNAPPROVED_CONTENT:
                    return <img src={CONTENT_ICON} title={t("MIS_SID_20_UNAPPROVED_CONTENT")}/>
                case dashboardConstants.UNAPPROVED_USER:
                    return <img src={USER_ICON} title={t("MIS_SID_CBFEB_UNAPPROVED_USERS")} />
            }
        }, [])

        const handleClick = () => {
            switch (id) {
                case dashboardConstants.UNAPPROVED_DEVICE :
                    onUnapprovedDevice();
                    break;
                case dashboardConstants.UNAPPROVED_CONTENT:
                    onUnapprovedContent();
                    break;
                case dashboardConstants.UNAPPROVED_USER:
                    onUnapprovedUser();
                    break;
            }
        }

        const onUnapprovedUser = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('USER'))
            }).then(dispatch(menuAction.moveTab({id: 'USER', title : t("TEXT_TITLE_USER_P"), active: true, close: true}, 'UNAPPROVED')))
        }

        const onUnapprovedDevice = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('DEVICE'))
            }).then(dispatch(menuAction.moveTab({id: 'DEVICE', title : t("TEXT_TITLE_DEVICE_P"), active: true, close: true}, 'DEVICE','UNAPPROVED_DEVICE')))
        }

        const onUnapprovedContent = () => {
            new Promise(()=> {
                dispatch(menuAction.removeTab('CONTENT'))
            }).then(dispatch(menuAction.moveTab({id: 'CONTENT', title : t("TEXT_CONTENT_P"), active: true, close: true}, 'UNAPPROVED_CONTENT')))
        }

        return (
            <div style={{width: 65, position: 'relative', cursor: 'pointer'}} onClick={handleClick}>
                <Icon id={id} />
                <Badge number={count}/>
            </div>
        )

    }

    const device = authorities.device;
    const content = authorities.content;
    const user = authorities.user;

    const {reload} = useSelector(state => state.dashboard);

    const [unApprovedResources, setUnApprovedResources] = useState({})

    const fetchUnapprovedResources = () => {
        if (device.APPROVAL || content.MANAGE || user.MANAGE ) {
            dashboardService.fetchUnapprovedResource()
                .then(
                    res => {
                        if (res && res.items) {
                            const {items} = res;
                            const unApproved = {}
                            items.map(
                                item => {
                                    if (item.id === dashboardConstants.UNAPPROVED_CONTENT) {
                                        unApproved.content = item.count;
                                    } else if (item.id === dashboardConstants.UNAPPROVED_DEVICE) {
                                        unApproved.device = item.count;
                                    } else if (item.id === dashboardConstants.UNAPPROVED_USER) {
                                        unApproved.user = item.count;
                                    }
                                }
                            )
                            setUnApprovedResources(unApproved);
                        }
                    }
                ).catch(e => console.log(e));
        }
    }

    useEffect(()=> {
        fetchUnapprovedResources();
    }, [reload])

    return (
        <div style={{display: 'flex', lineHeight: '35px', flexGrow: 1, flexFlow: 3}}>
            {
                !isNil(unApprovedResources.content) && content.MANAGE && <Resource id={dashboardConstants.UNAPPROVED_CONTENT} count={unApprovedResources.content}/>
            }
            {
                !isNil(unApprovedResources.device) && device.APPROVAL && <Resource id={dashboardConstants.UNAPPROVED_DEVICE} count={unApprovedResources.device}/>
            }
            {
                !isNil(unApprovedResources.user) && user.MANAGE && <Resource id={dashboardConstants.UNAPPROVED_USER} count={unApprovedResources.user}/>
            }
        </div>
    )
}
export default UnApprovedResource;