import React, {useEffect, useRef, useState} from "react";
import ConnectionByDeviceType from "./ConnectionByDeviceType";
import ConnectionRealTimeChart from "./ConnectionRealTimeChart";
import ConnectionHistory from "./ConnectionHistory";
import {ResourceStatus} from "../DMArea";
import {dashboardConstants} from "../../../../constants";

const ConnectedInformation = ({loading, status}) => {

    return (
        <div className={'dm_container'} style={{float: 'left'}}>
            <div style={{margin: 10, height: 590}}>
                <div className={'device_status_box'} style={{width: '100%', display: 'flex', flex: 1, justifyContent: 'space-around'}}>
                    <ResourceStatus loading={loading} id={dashboardConstants.CONNECTED_RESOURCE} running={status.connected} total={status.connected + status.disConnected}/>
                    <ResourceStatus loading={loading} id={dashboardConstants.DISCONNECTED_RESOURCE} running={status.disConnected} total={status.connected + status.disConnected}/>
                </div>
                {
                        <>
                            <div style={{padding: 10}}>
                                <ConnectionByDeviceType/>
                            </div>
                            <div style={{display: 'flex', margin: 5}}>
                                <ConnectionRealTimeChart />
                                <ConnectionHistory />
                            </div>
                        </>
                }
            </div>
        </div>
    )
}
export default ConnectedInformation;