import React, { forwardRef, memo } from 'react';
import './Checkbox.css';

const Checkbox = ({id, name, index, classname, style, propertyName, onClick, onChange, checked, disabled, defaultChecked, showTitle=false}, ref) => {
    return (
        <span className="checkboxWrap" style={style}>
            <input type="checkbox" id={id} data-name={propertyName} onClick={onClick} onChange={onChange} checked={checked} data-index={index} ref={ref} disabled={disabled} defaultChecked={defaultChecked}/>
            <label className={classname} htmlFor={id} title={showTitle?name:''}>{name}</label>
        </span>
    )
};

export default memo(forwardRef(Checkbox));