
const getUserId = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user).id;
    }
    return null;
}

export function getPageSize(divide, defaultSize = 25) {
    const defaultSizes = [].concat(defaultSize).filter((t) => (t !== undefined && t !== null));
    return (divide&&divide=='default')? defaultSizes[0] : (localStorage.getItem(divide.toUpperCase() + `_pageSize_${getUserId()}`) || defaultSizes[0]);
}