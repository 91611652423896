import { rulesetConstants } from "../constants"
import { assignUUID } from '../helper/ruleset/rulesetUtils';
import Moment from "moment";

const initialState  = {
}

const duplicate = (ruleset, ruleId, newRuleName) => {
    let newRule;
    for(let i = 0; i < ruleset.rules.length; i++) {
        if(ruleset.rules[i].rule_id === ruleId) {
            newRule = JSON.parse(JSON.stringify(ruleset.rules[i]));
            break;
        }
    }

    if(newRule) {
        for(let i = 0; i < newRule.rule_tree.length; i++) {
            assignUUID(ruleset, newRule.rule_tree[i]);
        }

        let maxId = 0;

        for(let i = 0; i < ruleset.rules.length; i++) {
            const n = parseInt(ruleset.rules[i].rule_id);
            if(maxId < n) {
                maxId = n;
            }
        }

        newRule.rule_id = (maxId + 1).toString();
        newRule.rule_name = newRuleName;

        ruleset.rules.push(newRule);
    }
}

export const rulesets = (state = initialState, action) => {
    let ruleset;
    
    switch(action.type) {
        case rulesetConstants.INIT_NEW_RULESET:
            return {
                ...state,
                newRuleset: {
                    ruleset: {...action.ruleset},
                    verificationMode: {
                        enabled: false,
                        selectedGroup: [],
                        date: {enabled: false, value: Moment(new Date()).format('YYYY-MM-DD')},
                        time: {enabled: false, value: '00:00:00'}
                    }
                },
                highlightPath: {
                    ruleId: 0,
                    path: []
                },
                fixedHighlightPath: {
                    ruleId: 0,
                    path: []
                }
            }
        case rulesetConstants.INIT_EDIT_RULESET:
            return {
                ...state,
                editRuleset: {
                    ruleset: {...action.ruleset},
                    verificationMode: {
                        enabled: false,
                        selectedGroup: [],
                        date: {enabled: false, value: Moment(new Date()).format('YYYY-MM-DD')},
                        time: {enabled: false, value: '00:00:00'}
                    }
                },
                highlightPath: {
                    ruleId: 0,
                    path: []
                },
                fixedHighlightPath: {
                    ruleId: 0,
                    path: []
                }
            }

        case rulesetConstants.ADD_CONDITION:
            if(action.currentTab === "NEW_RULESET") {
                ruleset = state.newRuleset.ruleset;
            }
            else {
                ruleset = state.editRuleset.ruleset;
            }

            ruleset.conditions.push(action.newCondition);
            return {
                ...state
            }

        case rulesetConstants.EDIT_CONDITION:
            if(action.currentTab === "NEW_RULESET") {
                ruleset = state.newRuleset.ruleset;
            }
            else {
                ruleset = state.editRuleset.ruleset;
            }

            for(let i = 0; i < ruleset.conditions.length; i++) {
                if(ruleset.conditions[i].conditionId === action.condition.conditionId) {
                    ruleset.conditions.splice(i, 1, action.condition);
                    break;
                }
            }

            return {
                ...state
            }

        case rulesetConstants.ADD_PLAY:
            if(action.currentTab === "NEW_RULESET") {
                ruleset = state.newRuleset.ruleset;
            }
            else {
                ruleset = state.editRuleset.ruleset;
            }
            ruleset.results.push(action.newPlay);
            return {
                ...state
            }

        case rulesetConstants.EDIT_PLAY:
            if(action.currentTab === "NEW_RULESET") {
                ruleset = state.newRuleset.ruleset;
            }
            else {
                ruleset = state.editRuleset.ruleset;
            }

            for(let i = 0; i < ruleset.results.length; i++) {
                if(ruleset.results[i].resultId === action.play.resultId) {
                    ruleset.results.splice(i, 1, action.play);
                    break;
                }
            }

            return {
                ...state
            }
            
        case rulesetConstants.REDRAW:
            return {
                ...state
            }

        case rulesetConstants.SET_VERIFICATION_MODE:
            if(action.currentTab === "NEW_RULESET") {
                return {
                    ...state,
                    newRuleset: {
                        ...state.newRuleset,
                        verificationMode: action.verificationMode
                    }
                }
            }
            
            else {
                return {
                    ...state,
                    editRuleset: {
                        ...state.editRuleset,
                        verificationMode: action.verificationMode
                    }
                }
            }

        case rulesetConstants.HIGHLIGHT_PATH:
            return {
                ...state,
                highlightPath: {
                    ruleId: action.ruleId,
                    path: action.highlightPath
                }
            }

        case rulesetConstants.FIXED_HIGHLIGHT_PATH:
            return {
                ...state,
                fixedHighlightPath: {
                    ruleId: action.ruleId,
                    path: action.fixedHighlightPath
                }
            }

        case rulesetConstants.COPY:
            if(action.currentTab === "NEW_RULESET") {
                return {
                    ...state,
                    newRuleset: {
                        ...state.newRuleset,
                        copyData: action.copyData
                    }
                }
            }
            else {
                return {
                    ...state,
                    editRuleset: {
                        ...state.editRuleset,
                        copyData: action.copyData
                    }
                }
            }

        case rulesetConstants.DUPLICATE:
            if(action.mode === "NEW") {
                ruleset = state.newRuleset.ruleset;
            }
            else {
                ruleset = state.editRuleset.ruleset;
            }

            duplicate(ruleset, action.ruleId, action.newRuleName);
            return {
                ...state
            }

        default:
            return state;
    }
}