import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MagicInfoTable from "../../../table/MagicInfoTable";
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from '../../../../constants';
import MISDialog from '../../MISDialog';
import {useDeviceDetail, useFilter, isNumber, toMomentDateTimeFormat} from '../../../../helper';
import './AppliedStatusDetailPopup.css';
import {deviceService} from '../../../../services';
import {popupAction} from "../../../../actions";
import {useDispatch} from "react-redux";
import {useTrGroupProps} from '../../../../helper/tables';
import Moment from 'moment';
import {useMISOpt} from '../../../misopt';
import {isNil} from 'lodash';

const getTotalCount = (status, data) => {
    return status === 'ALL' ? data.countOfReserved :
        status === 'DOWNLOADING' ? data.countOfDownloading :
        status === 'DOWNLOAD_FINISHED' ? data.countOfDownloadFinished :
        status === 'UPGRADE_SUCCESS' ? data.countOfUpgradeSuccess :
        status === 'UPGRADE_FAIL' ? data.countOfUpgradeFail : 0;
};

const AppliedStatusDetailPopup = ({rsvId, softwareType, onClose, hasDeviceSecurity}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();

    const [filter, setFilter, onPageChange, onPageSizeChange, ] = useFilter({
        page: 0,
        pageSize: DEVICE_PAGE_SIZE_OPTIONS[0],
        status: 'ALL',
    });

    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const [data, setDate] = useState({
        items: {},
        loading: false,
    });

    const [renderDeviceNameCell, , ] = useDeviceDetail(false, hasDeviceSecurity);

    const fetchData = () => {
        const {page, pageSize, status} = filter;

        deviceService.fetchSoftwareStatus({
            startIndex: page * pageSize + 1,
            pageSize,
            reservationId: rsvId,
            status
        }).then(res => {
            setDate(res.items)
        }).catch(error => console.log(error));
    };

    useEffect(() => {
        fetchData();
    }, [rsvId, filter]);

    const renderDeviceModelName = (row) => {
        if(typeof  row.deviceModelName === undefined || row.deviceModelName === ''){
            return (<i>Deleted device</i>);
        } else {
            return (<span>{row.deviceModelName}</span>);
        }
    };

    const renderProgress = (row) => {
        if(typeof  row.progress !== undefined && row.progress === 'Unknown'){
            return (t('TEXT_UNKNOWN_P'));
        } else if(isNumber(row.progress)){
            return (<span>{row.progress}%</span>);
        } else {
            return (<span>{row.progress}</span>);
        }
    }

    const renderServiceStartDate = (row) => {
        if(isNil(row.serviceStartDate)){
            return (t(' '));
        } else {
            return (<span>{Moment(row.serviceStartDate).format(dateTimeFormat)}</span>);
        }
    }

    const renderDownloadStatus = (row) => {
        let failStatus = '', downloadStatus = row.downloadStatus;
        switch(row.downloadStatus){
            case 'UPGRADE_SUCCESS':
                downloadStatus = t('MIS_TEXT_UPGRADE_SUCCESS_P');
                break;
            case 'UPGRADE_FAIL':
                downloadStatus = t('MIS_TEXT_UPGRADE_FAIL_P');
                failStatus = row.failStatus;
                break;
            case 'DOWNLOADING':
                downloadStatus = t('MSG_DOWNLOAD');
                break;
            case 'DOWNLOAD_FINISHED':
                downloadStatus = t('MIS_TEXT_DOWNLOAD_FINISHED_P');
                break;
            case 'STANDBY':
                downloadStatus = t('COM_TEXT_STANDBY_P');
                break;

        }

        if(typeof failStatus !== undefined && failStatus !== ''){
            return (<span title={failStatus}>{downloadStatus}<br/>({failStatus})</span>);
         } else {
            return (<span>{downloadStatus}</span>);
        }
    }

    const onClickRetry = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.RETRY_PUBLISH_SOFTWARE,
            type: commonConstants.RETRY_PUBLISH_SOFTWARE,
            softwareId: rsvId,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.RETRY_PUBLISH_SOFTWARE)),
        }));
    };

    let columns;

    if(softwareType === 'software') {
        columns = [
            {
                Header: t('TABLE_DEVICE_NAME_P'),
                accessor: 'deviceName',
                Cell: renderDeviceNameCell,
                width: 120,
                //resizable: false,                
            },
            {
                Header: t('TABLE_DEVICE_MODEL_NAME_P'),
                accessor: 'deviceModelName',
                Cell: props => renderDeviceModelName(props.original),
                width: 130,
            },
            {
                Header: t('TEXT_GROUP_P'),
                accessor: 'groupName',
                width: 100
            },
            {
                Header: t('TEXT_DEPLOY_TIME_P'),
                accessor: 'serviceStartDate',
                width: 120,
                Cell: props => renderServiceStartDate(props.original)
            },
            {
                Header: t('COM_DID_LFD_FIRMWARE_VER'),
                accessor: 'applicationVersion',
                width: 100
            },
            {
                Header: t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS'),
                accessor: 'progress',
                Cell: props => renderProgress(props.original),
                width: 80
            },
            {
                Header: t('TABLE_RESULT_P'),
                accessor: 'downloadStatus',
                Cell: props => renderDownloadStatus(props.original),
            }
        ];
    } else {
        columns = [
            {
                Header: t('TABLE_DEVICE_NAME_P'),
                accessor: 'deviceName',
                Cell: renderDeviceNameCell,
                width: 120,
                //resizable: false,
            },
            {
                Header: t('TABLE_DEVICE_MODEL_NAME_P'),
                accessor: 'deviceModelName',
                Cell: props => renderDeviceModelName(props.original),
                width: 130,
            },
            {
                Header: t('TEXT_GROUP_P'),
                accessor: 'groupName',
                width: 100
            },
            {
                Header: t('TEXT_DEPLOY_TIME_P'),
                accessor: 'serviceStartDate',
                width: 120,
                Cell: props => renderServiceStartDate(props.original)
            },
            {
                Header: t('LIST_TITLE_NAME'),
                accessor: 'softwareName',
                width: 100
            },
            {
                Header: t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS'),
                accessor: 'progress',
                Cell: props => renderProgress(props.original),
                width: 80
            },
            {
                Header: t('TABLE_RESULT_P'),
                accessor: 'downloadStatus',
                Cell: props => renderDownloadStatus(props.original),
            }
        ];
    }

    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');

    return (
        <div className='applied_status_popup_wrap'>
            <MISDialog
                dialog={{
                    title: (type => {
                        switch(type){
                            case '03':
                                return t('TEXT_TITLE_SW_DEPLOY_HISTORY_P') + ' - ' + t('COM_PJT_IDS_SETUP_14');
                            case '04':
                                return t('TEXT_TITLE_SW_DEPLOY_HISTORY_P') + ' - ' + t('COM_TEXT_DEFAULT_CONTENT_P');
                            case '06':
                                return t('TEXT_TITLE_SW_DEPLOY_HISTORY_P') + ' - ' + t('MIS_SID_CASEP_CERTIFICATE');
                            default:
                                return t('TEXT_TITLE_SW_DEPLOY_HISTORY_P');
                        }                        
                    })(softwareType),
                    modal: true,
                    isDraggable: false,
                    closeOnEscape: true,
                    width: 1120,
                    height: 500,
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: [
                        {
                            id: 'COM_COMMONCTRL_RETRY',
                            title: t('COM_COMMONCTRL_RETRY'),
                            hide: filter.status !== 'UPGRADE_FAIL',
                            onClick: onClickRetry,
                        }
                    ],
                    rightButtons: [
                        {
                            id: 'COM_BUTTON_CLOSE_P',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='applied_status_detail_wrap devicepop'>
                    <span className='float_l'>
                        <div>{t('MIS_SID_PUBLISH_STATUS')}</div>
                        <ul>
                            <li><span className="sw_status_item" onClick={() => setFilter({...filter, status: 'ALL'})}>{t('LIST_TITLE_TOTAL')} : {data.countOfReserved}</span></li>
                            <li><span className="sw_status_item" onClick={() => setFilter({...filter, status: 'DOWNLOADING'})}>{t('MSG_DOWNLOAD')} : {data.countOfDownloading}</span></li>
                            <li><span className="sw_status_item" onClick={() => setFilter({...filter, status: 'DOWNLOAD_FINISHED'})}>{t('MIS_TEXT_DOWNLOAD_FINISHED_P')} : {data.countOfDownloadFinished}</span></li>
                            <li><span className="sw_status_item" onClick={() => setFilter({...filter, status: 'UPGRADE_SUCCESS'})}>{t('MIS_TEXT_UPGRADE_SUCCESS_P')} : {data.countOfUpgradeSuccess}</span></li>
                            <li><span className="sw_status_item" onClick={() => setFilter({...filter, status: 'UPGRADE_FAIL'})}>{t('MIS_TEXT_UPGRADE_FAIL_P')} : {data.countOfUpgradeFail}</span></li>
                        </ul>
                    </span>
                    <span className="status_data float_r">
                        <MagicInfoTable
                            data={data.reservedDevices}
                            getTrGroupProps={getTrGroupPropsType1}
                            columns= {columns}
                            style={{width: 830, height: 415, border: '0px'}}
                            usePagination={true}
                            paginationOptions={{
                                totalCount: getTotalCount(filter.status, data),
                                page: filter.page,
                                pageSize: filter.pageSize,
                                onPageChange: onPageChange,
                                onPageSizeChange: onPageSizeChange,
                                style:{'position':'relative'}
                            }}
                        />                        
                    </span>
                </div>
            </MISDialog>
        </div>
    );
};

export default AppliedStatusDetailPopup;