import React from 'react';
import {useTranslation} from 'react-i18next';
import Moment from 'react-moment';
import MISDialog from '../../MISDialog';
import {preconfigItems, useMISOpt} from '../../../misopt';
import {toMomentDateTimeFormat} from '../../../../helper';
import {getDeviceMessage} from '../../../../helper/device/getDeviceMessage';
import './PresetResultPopup.css';

const PresetResultPopup = ({presetResult, onClose}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const {preconfig = {}, preconfigResult = {}} = presetResult;
    let index = 0;

    return (
        <div className='preset_popup_wrap'>
            <MISDialog
                dialog={{
                    title: t('MIS_SID_SERVER_PRESET_REPORT'),
                    modal: true,
                    isDraggable: true,
                    closeOnEscape: true,
                    width: 700,
                    height: 'auto',
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'COM_BUTTON_CLOSE_P',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}
            >
                <div className='preset_result_wrap'>
                    <div className="preconfig_result details noline">
                        <div className="preconfig_result_pop basic">
                            <table>
                                <colgroup>
                                    <col width="140px"/>
                                    <col width=""/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>{t('LIST_TITLE_NAME')}</th>
                                        <td>{preconfig.name}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('TEXT_LAST_MODIFIED_DATE_P')}</th>
                                        <td>
                                            <Moment format={dateTimeFormat}>{preconfig.updateTime}</Moment>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="devicepop details noline">
                        <div className="preconfig_result_pop mb26">
                            <div className="detail_view sub_list_head mini_p mt14 mb15">
                                <table>
                                        <colgroup>
                                            <col style={{width: 100}}/>
                                            <col style={{width: 160}}/>
                                            <col style={{width: 150}}/>
                                            <col/>
                                        </colgroup>
                                    <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t('DID_MAIN_CATEGORY')}</th>
                                                <th>{t('COM_SID_MENU')}</th>
                                        <th>{t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS')}</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.entries(preconfigResult).map(([part, partData]) => {
                                                return Object.entries(partData).map(([entity, value]) => {
                                                    const values = value.split(';');
                                                    return Object.entries(preconfigItems[part]).map(([mainkey, mainitem]) => {
                                                        return Object.entries(mainitem).map(([itemKey, itemValue]) => {
                                                            if(entity !== itemKey) {
                                                                return null;
                                                            }
                                                            return (
                                                                    <tr key={itemKey}>
                                                                        <td style={{width: 87}}>{++ index}</td>
                                                                        <td style={{width: 147}}>{getDeviceMessage(part, t)}</td>
                                                                        <td style={{width: 137}}>{getDeviceMessage(itemValue.title || itemKey, t)}</td>
                                                                    {
                                                                        values.length > 1 ?
                                                                            <td style={{width: 277}}>{values[0]}<br/>{values[1]}</td> :
                                                                            <td style={{width: 277}}>{value}</td>
                                                                    }
                                                                </tr>
                                                            );
                                                        });
                                                    });
                                                });
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default PresetResultPopup;