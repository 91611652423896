import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {popupAction} from '../../../actions';
import {useTranslation} from 'react-i18next';
import {commonConstants} from '../../../constants';
import MISDialog from '../MISDialog';
import TextInput from '../../input/TextInput';
import {getMixString} from '../../../language/languageUtils';
import {toastr} from 'helper/toastrIntercept';
import './SaveRulesetPopup.css';

const RuleTreeOptionPopup = ({title, ruleTreeName, onSave}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState(ruleTreeName !== undefined ? ruleTreeName : '');

    const closePopup = () => {
        dispatch(popupAction.closePopup(commonConstants.RULESET_RULETREE_OPTION_POPUP));
    };

    const save = () => {
        if(!name && name.length === 0) {
            toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
            return;
        }
        closePopup();
        if(onSave !== undefined) {
            onSave(name);
        }
    };

    const dialogProps ={
        title: title,
        width :525,
        height :50,
        position: {x: -300, y: -200},
        modal:true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose:() => closePopup()
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("COM_BUTTON_SAVE"), id: 'saveSaveRulesetBtn', onClick: () => save()},
                    {title: t("BUTTON_CANCEL_P"), id: 'cancelSaveRulesetBtn', onClick: ()=> closePopup()}
                ]
        }}>
            <div>
                <table>
                    <colgroup>
                        <col width="139px"/>
                        <col width=""/>
                    </colgroup>

                    <tbody>
                        <tr className="ruleset">
                            <th>{t('LIST_TITLE_NAME')}</th>
                            <td>
                                <TextInput
                                    width={300}
                                    value={name}
                                    maxLength={200}
                                    placeholder={getMixString(["MIS_SID_MIX_ENTER_A_NAME", "MIS_SID_RULE_TREE"])}
                                    onChange={(e) => setName(e.target.value)}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </MISDialog>
    )
}

export default RuleTreeOptionPopup;