import React, {useEffect, useRef} from 'react';

const RMServerOpener = ({url, token, deviceIds}) => {
    const formRef = useRef();
    const from = window.location;
    const getAccessToken = () => {
        const user = localStorage.getItem('user');
        if (user) {
            const json = JSON.parse(user);
            if (json && json.token) {
                return json.token;
            }
        }
        return '';
    }
    const getLocale = () => {
        const locale = localStorage.getItem('MagicInfoPremiumLanguage');
        if (locale) {
            return locale;
        }
        return 'en';
    }
    const accessToken = getAccessToken();
    const locale = getLocale();

    useEffect(() => {

        formRef.current.submit();
    }, []);

    return (
        <form action={url} target='RMServer' method='post' ref={formRef} style={{display: 'none'}}>
            {
                token &&
                <input type='hidden' name='token' value={token} />
            }
            {
                accessToken &&
                <input type='hidden' name='accessToken' value={accessToken} />
            }

            {
                from &&
                <input type='hidden' name='from' value={from} />
            }
            {
                deviceIds &&
                <input type='hidden' name='deviceIds' value={deviceIds.join(',')} />
            }
            {
                locale &&
                <input type='hidden' name='locale' value={locale} />
            }
        </form>
    );
};

export default RMServerOpener;