import React from 'react';
import {useTranslation} from "react-i18next";
import './SubmenuRuleBox.css';
import {getOpType, getConditionType} from '../../helper/ruleset/rulesetUtils';
import {useDrag} from 'react-dnd';

const SubmenuPlayBox = ({play, width}) => {
    const {t} = useTranslation();
    const [{isDragging}, drag] = useDrag({
		item: {
            type: 'playBox',
            play: play
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
    });

    const style = {
		width: width
	};

    let contentTypeSID = "";
    if(play.contentsType.toUpperCase() === "CONTENT") {
        contentTypeSID = "TEXT_CONTENT_P";
    }
    else if(play.contentsType.toUpperCase() === "PLAYLIST") {
        contentTypeSID = "TEXT_TITLE_PLAYLIST_P";
    }

    let contentsName = "";
        
    if(play.type === "dynamic") {
        contentsName = play.filter.expression;
    }

    else {
        if(play.contentList && play.contentList.length > 0) {
            play.contentList.map((value, index) => {
                if(index > 0) {
                    contentsName += ", ";
                }

                contentsName += value.contentName;
            });
        }
    }
    
    return (
        <div ref={drag} style={style} className={"box play"} id={play.resultId}>
            <div className="description name">{play.resultName}</div>
            <div className="description">{t(contentTypeSID)}</div>
            <div className="description">{contentsName}</div>
        </div>
    )
}

export default SubmenuPlayBox;