import React from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import MISDialog from "../../MISDialog";
const DbAccessInfoPopup = ({title, data, width = 391, height = 233, position, onClose}) => {
    const {t} = useTranslation();

    const getDataView =() =>{
        return (
            data != undefined &&
            data.map(item => 
                <div>- {item.key} : {item.value}</div>
            )
        )
    }
    return (
            <MISDialog
                dialog={{
                    title,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width,
                    height,
                    position,
                    onClose
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ALERT_CLOSE',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div>
                {
                   getDataView()
                }
                </div>
            </MISDialog>
    );
};
export default DbAccessInfoPopup;