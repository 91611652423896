import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useOutsideClick} from "../../../helper";
import {dashboardAction, popupAction} from "../../../actions";
import {dashboardConstants} from "../../../constants";
import './WidgetsPopup.css';
import Checkbox from "../../checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import {dashboardService} from "../../../services";
import {getErrorMessage} from "../../../helper/responseHandler";
import toastr from 'toastr';

const WidgetPopup = () => {

    const ref = useRef();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {widgets} = useSelector(state => state.dashboard);

    const closePopup = () => {
        dispatch(popupAction.closePopup(dashboardConstants.DASHBOARD_WIDGET_POPUP));
    }

    useOutsideClick(ref, ()=> {
        closePopup();
    })

    const RenderWidget = ({id}) => {

        const getWidgetName = (id) =>{
            switch (id) {
                case dashboardConstants.DASHBOARD_CUSTOM_STORAGE_WIDGET : return 'MIS_SID_20_SERVER_STORAGE';
                case dashboardConstants.DASHBOARD_CUSTOM_USER_WIDGET: return 'TEXT_USER_SUMMARY_P_KR_MIS20';
                case dashboardConstants.DASHBOARD_CUSTOM_SOFTWARE_UPDATE_WIDGET : return 'MIS_SID_SOFTWARE_UPDATE_SUMMARY';
                case dashboardConstants.DASHBOARD_CUSTOM_EXTERNAL_SERVER_WIDGET : return 'MIS_SID_CBFEB_EXTERNAL_SERVER';
            }
        }

        return (
            <span>{t(getWidgetName(id))}</span>
        )
    }

    const handleWidgets = (id) => {
        const updateWidgets = widgets.map(widget => widget.widgetId === id ? {...widget, active: !widget.active} : {...widget});
        dashboardService.updateWidgets(updateWidgets)
            .then(
                res => {
                    dispatch(dashboardAction.updateWidgets(updateWidgets));
                }
            ).catch(e => toastr.error(getErrorMessage(e)))
    }

    return (
        <div className={'dashboard_widget_popup'}>
            <div className={'dim'}></div>
            <div className={'widgets_info_wrap'} ref={ref}>
                <ul>
                {
                    widgets.length > 0  && widgets.map(
                        widget => <li key={widget.widgetId}><Checkbox id={widget.widgetId} checked={widget.active} onClick={()=>handleWidgets(widget.widgetId)}/> <RenderWidget id={widget.widgetId} /></li>
                    )
                }
                </ul>
            </div>
        </div>
    )
}
export default WidgetPopup;