import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import MISDialog from "../MISDialog";
import ReactTable from "react-table";
import {toastr} from 'helper/toastrIntercept';
import "./UserPopup.css";

const AddUserLdapOrganizationSelectionPopup = ({onClose, onSave, closePopup, remoteLdapOrgList}) => {
    const {t} = useTranslation();
    const timer = useRef(null);

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const [data, setData] = useState({
        loading: false,
        items: remoteLdapOrgList
    });

    const [filter, setFilter] = useState({
        selected: -1
    });

    const [style, setStyle] = useState({
        height: '100%'
    });

    const {items, loading} = data;

    const onClickOk = () => {
        if (filter.selected === -1) {
            toastr.error(t('MIS_SID_20_PLEASE_SELECT_AN_LDAP_ORGANIZATION'));
            return;
        }

        onSave(items[filter.selected].value);
        onClose();
    };

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_20_SELECT_LDAP_ORGANIZATION'),
                closeOnEscape: true,
                modal: true,
                width: 450,
                height: 500,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickOk,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('COM_BUTTON_CLOSE_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div style={{height: '100%', width: '100%', borderLeftStyle:'solid', borderLeftColor:'#e7e7e7', borderLeftWidth: '1px'}} className={'ldap_select_wrap'}>
                <ReactTable
                    minRows={0}
                    data={items}
                    TheadComponent={_ => null}
                    loading={loading}
                    columns={
                        [
                            {
                                accessor: "value",
                            }
                        ]
                    }
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    style={style}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    setFilter({
                                        selected: rowInfo.index
                                    })
                                },
                                style: {
                                    background: rowInfo.index === filter.selected ? '#e6f2ff' : 'white'
                                }
                            }
                        }else{
                            return {}
                        }
                    }
                    }
                />
            </div>
        </MISDialog>
    );
};


export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(AddUserLdapOrganizationSelectionPopup);