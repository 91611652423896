import React from 'react';
import './DeviceNumberInput.css';
import { useNumberInput } from '../input/NumberInput';

const DeviceNumberInput = ({width = 80, propertyName, value, min = 0, onChange, disabled, max = 100, step = 1}) => {
    const [onClickUp, onClickDown, onChangeNumber, onBlurNumber] = useNumberInput(value, min, max, step, true, onChange);

    return (
        <div className="device_number_input_wrap">
            <button type="button" className="down_btn" data-name={propertyName} onMouseDown={onClickDown} disabled={disabled}></button>
            <input value={value} style={{width}} disabled={disabled} min={min} max={max} onChange={onChangeNumber} onBlur={onBlurNumber} />
            <button type="button" className="up_btn" data-name={propertyName} onMouseDown={onClickUp} disabled={disabled}></button>
        </div>
    );
};

export default DeviceNumberInput;