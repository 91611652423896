import React, {useEffect, useState} from 'react';
import './ProgressContainer.css';
import ProgressBar from "../../components/progressbar/ProgressBar";
import {useDispatch, useSelector} from "react-redux";
import {ProgressConstants} from "../../constants/ProgressConstants";
import {scheduleService} from "../../services/schedule.service";
import {useInterval} from "../../helper";
import {progressAction} from "../../actions/progress.action";
import {popupAction} from "../../actions";
import {commonConstants} from "../../constants";
import {updatePublishStatus} from "../../helper/schedule/scheduleUtils";

const Progress = ({mode, id, title, interval, onClickProgress}) => {

    const dispatch = useDispatch();

    const [isPolling, setIsPolling] = useState(false);
    const [percentage, setPercentage] = useState(0);

    useInterval(() => {
        startPolling();
    }, percentage > 99 ? null : interval);

    const startPolling = () => {
        setIsPolling(true);
        runPolling();
    }

    const runPolling = () => {
        switch (mode) {
            case ProgressConstants.MODE_CONTENT_SCHEDULE:
                fetchContentScheduleProgressInfo(id);
                break;

            default :
                const percent = percentage+30 > 99 ? 100 : percentage+30;
                setPercentage(percent);
                break;
        }
    }

    const fetchContentScheduleProgressInfo = (id) => {
        scheduleService.fetchContentScheduleProgressById(id).then(
            res=> {
                if (res) {
                    const percent = parseInt(res.items.completePercentage, 10) > 99 ? 100  : parseInt(res.items.completePercentage,10);
                    setPercentage(percent);
                    updatePublishStatus(id, percent, res.items.completeDeviceCount, res.items.totalDeviceCount);
                } else {
                    return Promise.reject({status: res.error, res});
                }
            }
        ).then(

        )
    }

    const closeProgress = () => {
        setIsPolling(false);
        dispatch(progressAction.removeProgress(id));
    }

    useEffect(()=> {
        if (percentage >= 100) {
            setIsPolling(false);
        }
    }, [percentage])

    return (
        <ProgressBar width={300} height={40} background={'rgb(232, 232, 232)'} percentage={percentage} fillerColor={'#4be48c'} title={title} close={()=>closeProgress()} onClickProgress={onClickProgress}/>
    )
}

const ProgressContainer = () => {

    const dispatch = useDispatch();

    const {progresses} = useSelector(state => ({
        progresses : state.progress.progresses
    }));

    const onClickProgress = (progress) => {
        if (progress !== undefined && progress.mode === 'CONTENT_SCHEDULE_PROGRESS') {
            const {id} = progress;
            scheduleService.fetchContentScheduleById(id)
                .then(
                    res=> {
                        if (res) {
                            const program = res.items;
                            dispatch(popupAction.openDetailView({type: commonConstants.CONTENT_SCHEDULE_DETAIL_PROGRESS_POPUP, id: `CONTENT_SCHEDULE_PROGRESS_${id}`, program: program}));
                        }
                    }
                )
        }
    }

    return (
        <div className={"progressBarWrap"}>
            {
                progresses !== undefined && progresses.length > 0 &&
                    progresses.map(
                        (progress, index) => <Progress key={index} {...progress} interval={3000} onClickProgress={()=>onClickProgress(progress)}/>
                    )
            }
        </div>
    )

}
export default ProgressContainer;