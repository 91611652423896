import React from 'react'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { popupAction } from "../../../../actions";
import MISDialog from "../../MISDialog";
import ReactTable from "react-table";
import Size from '../../../../components/utils/Size';

const RulesetContentDownloadStatusPopup = ({id, items}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    }

    const dialogProps = {
        title: t("MIS_SID_RULESET"),
        closeOnEscape : true,
        width :782,
        height :300,
        position: {x: -300, y: -400},
        modal:true,
        onClose:() => closePopup()
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("BUTTON_OK_P"), id: 'forceDeleteRuleset', onClick: () => { closePopup() }},
                ]
            }}>

            <div style={{width: '100%'}} className={'ruleset_table_wrap'}>
                <ReactTable
                    data={items}
                    minRows={0}
                    showPagination={false}
                    resizable={true}
                    columns={[
                        {
                            Header: t("TEXT_CONTENT_P"),
                            width: 500,
                            accessor: 'contentName'
                        },
                        {
                            Header: t("ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE"),
                            width: 100,
                            accessor: 'rulesetName',
                            Cell: props => <Size size={props.original.totalSize} />
                        },
                        {
                            Header: t("TEXT_STATUS_P"),
                            width: 100,
                            accessor: 'progress'
                        }
                    ]}
                    className="-striped -highlight"
                    manual
                    style={{
                        height: '250px'
                    }}
                />
            </div>

        </MISDialog>
    )
}

export default RulesetContentDownloadStatusPopup;