import React from 'react';
import {withTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import './Category.css';
import Category from "./Category";
import classNames from 'classnames';
import {isNil} from "lodash";

class CategoryButton extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            defaultCheckedKeys: props.defaultCheckedKeys || []
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isNil(nextProps.defaultCheckedKeys) && this.props.defaultCheckedKeys !== nextProps.defaultCheckedKeys) {
            this.setState({
                defaultCheckedKeys: nextProps.defaultCheckedKeys
            })
        }
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                show: false
            });
        }
    }

    openCategory = () => {
        const {show} = this.state;
        if (!show ) {
                    this.setState({
                        show: true
                    })
        } else {
            this.setState({
                show: !show
            })
        }
    }

    render(){
        const {t, whiteButton} = this.props;
        return(
            <div className={"float_l"} style={{marginRight: 10}}>
                <button className={classNames('base', 'select','category_button',{'white' : whiteButton !== undefined && whiteButton})} id="categoryBtn" style={{width:99}} onClick={()=>this.openCategory()}>
                    <span>{t("DID_MAIN_CATEGORY")}<span className="arrow"></span></span></button>
                {
                    this.state.show &&
                    <Category  {...this.props} defaultCheckedKeys={this.state.defaultCheckedKeys} checkStrictly={true} defaultExpandAll={true} filtering />
                }
            </div>
        )
    }

}
export default withTranslation()(CategoryButton);