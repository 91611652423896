import MISDialog from "../../MISDialog";
import React, {useEffect, useState,useRef} from "react";
import {useTranslation} from "react-i18next";
import {settingConstants} from "../../../../constants";
import TextInput from "../../../input/TextInput";
import Select from "../../../selectbox/Select";
import {settingService} from "../../../../services";
import EditableSelectBox from "../../../selectbox/EditableSelectBox";
import {getErrorMessage} from "../../../../helper/responseHandler";
import toastr from 'toastr';
import {isNil, isEmpty} from "lodash";

const InsightIndexSettingPopup = ({onClickYes, onClose, type, indexId}) => {

    const {t} = useTranslation();

    const [insightOrgans, setInsightOrgans] = useState([]);

    const [insightIndex, setInsightIndex] = useState({
        activated: true,
        common: true,
        description: '',
        indexName: '',
        updateValues: [],
        organizationId: -1,
        organizationName :''
    });

    const [indexValues, setIndexValues] = useState([]);

    const [addedInsightValues, setAddedInsightValues] = useState([]);
    const [deletedInsightValues, setDeletedInsightValues] = useState([]);
    const indexNameRef = useRef();

    const updateInsightIndex = (e) => {
        const target = e.target.getAttribute('data-name');
        setInsightIndex({
            ...insightIndex,
            [target]: e.target.value
        })
    }

    const onSave = () => {

        const valid = ()  => {
            if (isNil(insightIndex.indexName) || isEmpty(insightIndex.indexName)) {
                toastr.error(t("MESSAGE_COMMON_ENTER_NAME_P"));
                return false;
            }
            if (isEmpty(insightIndex.indexName.trim())) {
                toastr.error(t('MESSAGE_COMMON_CANT_NAME_SPACE_P'));
                return false;
            }
            return true;
        }
        if (valid()) {
            const updateValues = [];
            if (type === settingConstants.ADD_INSIGHT_INDEX_POPUP) {
                if (addedInsightValues.length > 0 ) {
                    addedInsightValues.map(v => updateValues.push({value: v}));
                    insightIndex.values = updateValues;
                }
                settingService.addInsightIndex(insightIndex)
                    .then(
                        res => toastr.success(t("TEXT_SUCCESS_P"))
                    )
                    .catch(e => toastr.error(getErrorMessage(e)))
                    .finally(()=>{
                        onClickYes();
                        onClose();
                    })
            } else if (type === settingConstants.EDIT_INSIGHT_INDEX_POPUP) {
                const filteredIndexValues = insightIndex.values.filter(it => !deletedInsightValues.includes(it.valueId));

                if (addedInsightValues.length > 0 ) {
                    addedInsightValues.map(v => filteredIndexValues.push({value: v}));
                }
                insightIndex.values = filteredIndexValues;
                settingService.updateInsightIndex(insightIndex)
                    .then(
                        res => toastr.success(t("TEXT_SUCCESS_P"))
                    )
                    .catch(e => toastr.error(getErrorMessage(e)))
                    .finally(()=>{
                        onClickYes();
                        onClose();
                    })
            }

        }

    }

    useEffect(()=> {
        if (type === settingConstants.EDIT_INSIGHT_INDEX_POPUP) {
            settingService.fetchInsightIndex(indexId)
                .then(res => {
                    if (res.items) {
                        setInsightIndex(res.items);
                        const updateValues = [];
                        res.items.values.map(v => updateValues.push({text: v.value, value: v.value, id: v.valueId}));
                        setIndexValues(updateValues);
                    }
                });
        }
    }, [type])

    useEffect(()=> {
        if (type === settingConstants.ADD_INSIGHT_INDEX_POPUP) {
            settingService.fetchInsightIndexOrgan()
                .then(res => {
                    if (res && res.items.length > 0) {
                        const organizations = [];
                        res.items.map(
                            org => organizations.push({title: org.groupName, value: org.groupId})
                        )
                        if (organizations.length > 0) {
                            setInsightOrgans(organizations);
                        }
                    }
                })
                .catch(e => toastr.error(getErrorMessage(e)));
        }
        indexNameRef.current.focus();
    }, [])

    return (
        <MISDialog
            dialog={{
                title: type === settingConstants.ADD_INSIGHT_INDEX_POPUP ?  t("MIS_SID_ADD_INDEX") : t("MIS_SID_EDIT_INDEX"),
                closeOnEscape: true,
                modal: true,
                width: 480,
                height: 220,
                // position: {x: -300, y: -400},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: () => onSave(),
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div className='detail_view mini_p '>
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>{t("MIS_SID_INDEX_NAME")}</th>
                        <td>
                            <TextInput width={300} maxLength={100} placeholder={t("MIS_SID_INDEX_NAME")} propertyName={'indexName'} onChange={updateInsightIndex} value={insightIndex.indexName} ref={indexNameRef}/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("COM_LFD_VALUE")}</th>
                        <td>
                            {
                                indexValues.length === 0 &&
                                <EditableSelectBox width={300} defaultTitle={t('MIS_SID_SELECT_TO_ADD_A_VALUE')} oldValues={[]} setAddedValues={setAddedInsightValues} setDeletedValues={setDeletedInsightValues} valueMaxLimit={50}/>
                            }
                            {
                                indexValues.length > 0 &&
                                <EditableSelectBox width={300} oldValues={indexValues} setAddedValues={setAddedInsightValues} setDeletedValues={setDeletedInsightValues} valueMaxLimit={50}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>{t("TABLE_ORGANIZATION_P")}</th>
                        <td>
                            {
                                type === settingConstants.ADD_INSIGHT_INDEX_POPUP &&
                                <Select selects={insightOrgans} width={300} defaultTitle={"Common"} multiLang={false}
                                        value={insightIndex.organizationId} useEllipsis={true}
                                        onChange={(e, value) => setInsightIndex({
                                            ...insightIndex,
                                            organizationId: value
                                        })}/>
                            }
                            {

                                type === settingConstants.EDIT_INSIGHT_INDEX_POPUP &&
                                <span>{insightIndex.organizationName}</span>

                            }
                        </td>
                    </tr>
                    <tr>
                        <th>{t("TEXT_DESCRIPTION_P")}</th>
                        <td>
                            <TextInput width={300} maxLength={30} placeholder={t("TEXT_DESCRIPTION_P")} propertyName={'description'} onChange={updateInsightIndex} value={insightIndex.description} />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>


        </MISDialog>
    )
}
export default InsightIndexSettingPopup;