import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../../switch/SwitchListNImage";
import SearchBar from "../../../search/SearchBar";
import classNames from "classnames";
import ContentThumbnail from "../../../image/ContentThumbnail";
import Size from "../../../utils/Size";
import {playlistService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import PerfectScrollbar from "perfect-scrollbar";
import {Loading} from "../../../loading/Loading";
import DateToStr from "../../../utils/DateToStr";
import {getMixString} from "../../../../language/languageUtils";
import MyGroups from "../../schedule/MyGroups";
import SupportedDeviceIcon from "../../../icon/SupportedDeviceIcon";


const ListView = ({playlists, selectedId, handleSelect}) => {
    const {t} = useTranslation();
    return (
        <table style={{width: "100%"}}>
            <colgroup>
                <col width="87px"/>
                <col width=""/>
            </colgroup>
            <tbody>
            {playlists !== undefined &&
            playlists.length > 0 &&
            playlists.map(item => (
                <tr
                    key={item.playlistId}
                    className={classNames({
                        on: selectedId.findIndex(i => i.contentId === item.playlistId) !== -1
                    })}
                    onClick={() =>
                        handleSelect(
                            item.playlistId,
                            item.playlistName,
                            getThumbnailId(item.thumbnailInfo, 0),
                            item.totalSize,
                            item.playlistType,
                            //item.playTime
                        )
                    }
                >
                    <td className="thum">
                        <div className="thumb_wrap">
                            <div
                                style={{
                                    width: "75px",
                                    height: "42px",
                                    textAlign: "center",
                                    lineHeight: "42px"
                                }}
                            >
                                <ContentThumbnail
                                    id={getThumbnailId(item.thumbnailInfo, 0)}
                                    width={75}
                                    height={42}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <h4 className="contentName">
                            <span className="text-overflow" style={{width: '300px'}}>{item.playlistName}</span>
                        </h4>
                        <ul>
                            <li className="supportedIcon">
                                {t("COM_MAPP_SID_SUPPORTED_DEVICES")} : <SupportedDeviceIcon
                                deviceType={item.deviceType} deviceTypeVersion={item.deviceTypeVersion}/>
                            </li>
                            <li>{t("ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE")} : <span><Size
                                size={item.totalSize}/></span></li>
                            <li>{t("COM_TEXT_MODIFY_DATE_P")} : <span><DateToStr date={item.lastModifiedDate}
                                                                                 newLine={false}/></span></li>
                        </ul>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

const ImageView = ({playlists, selectedId, handleSelect}) => {
    return (
        <div className={"thumbnailView_wrap"}>
            <ul className="clearfix mt20">
                {playlists !== undefined &&
                playlists.length > 0 &&
                playlists.map(item => (
                    <li>
                        <div
                            style={{cursor: "pointer"}}
                            className={classNames("thumbview_box", {
                                on: selectedId.findIndex(i => i.contentId === item.playlistId) !== -1
                            })}
                            onClick={() => handleSelect(item.playlistId, item.playlistName, getThumbnailId(item.thumbnailInfo, 0), item.totalSize, item.playlistType, item.playTime)}
                        >
                            <div style={{width: 75, height: 42, textAlign: "center"}}>
                                <ContentThumbnail
                                    id={getThumbnailId(item.thumbnailInfo, 0)}
                                    width={75}
                                    height={42}
                                />
                            </div>
                            <div className="device_info">
                                <span className="contentName">{item.playlistName}</span>
                            </div>
                            <div className="check">
                                <span></span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const getThumbnailId = (thumbnailInfo, index) => {
    const images = thumbnailInfo.split("|");
    if (images.length > 1) {
        return images[index]
    } else {
        return ''
    }
};

export const Playlists = ({device, selectedContents, multiSelect}) => {
    const {t} = useTranslation();

    let deviceType;
    let deviceTypeVersion;
    if (device) {
        if (device.deviceType === 'IPLAYER') {
            deviceType = 'iPLAYER';
        } else {
            deviceType = device.deviceType;
        }

        deviceTypeVersion = device.deviceTypeVersion;
    }

    const [playlist, setPlaylist] = useState({
        filter: {
            startIndex: 1,
            pageSize: 35,
            sortColumn: 'last_modified_date',
            sortOrder: 'desc',
            playlistTypes: [0],
            deviceType: deviceType ? deviceType : undefined,
            deviceTypeVersion: device ? device.deviceTypeVersion : undefined
        },
        list: [],
        isFetching: false,
        loading: false,
        count: 0
    })

    const [end, setEnd] = useState(false);

    const [view, setView] = useState("LIST");

    const [selected, setSelected] = useState([]);

    const [active, setActive] = useState('ALL');

    const handleListNImage = () => {
        setView(view === "LIST" ? "IMAGE" : "LIST");
        resetScrollPosition();
    };

    const fetchPlaylists = () => {
        setPlaylist({...playlist, loading: true});
        playlistService.fetchPlaylistForSchedule(playlist.filter)
            .then(res => {
                setPlaylist({
                    ...playlist,
                    list: playlist.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: playlist.count + 1,
                    totalCount: res.totalCount,
                    filter: {
                        ...playlist.filter,
                        startIndex: playlist.filter.startIndex + res.items.length
                    }
                })
            }).catch(res => toastr.error(res));
    }


    const onSearch = (value) => {
        setPlaylist({
            ...playlist,
            list: [],
            loading: false,
            isFetching: false,
            count: 0,
            filter: {...playlist.filter, startIndex: 1, pages: 0, searchText: value}
        });
    }


    if (multiSelect === undefined) {
        multiSelect = true;
    }

    const activeMenu = (menu) => {
        if (menu === 'ALL') {
            setPlaylist({
                ...playlist,
                list: [],
                loading: false,
                isFetching: false,
                count: 0,
                filter: {...playlist.filter, startIndex: 1, pages: 0, groupId: undefined, groupType: 'ALL'}
            })
        }
        setActive(menu);
    }

    const selectGroup = (groupId) => {
        if (groupId) {
            resetScrollPosition();
            setPlaylist({
                ...playlist,
                list: [],
                loading: false,
                isFetching: false,
                count: 0,
                filter: {...playlist.filter, startIndex: 1, pages: 0, groupId: groupId, groupType: 'GROUPED'}
            })
        }
    }

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_playlist_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    }

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    useEffect(() => {
        if (!initScroll.current) {
            const ps = new PerfectScrollbar('#select_playlist_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_playlist_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            perfectScrollbar.current = ps;
            initScroll.current = true;
        } else {
            perfectScrollbar.current.update();
        }
    }, [playlist.list]);

    useEffect(() => {
        if (playlist !== undefined && !playlist.loading && !playlist.isFetching && playlist.list !== undefined && playlist.list.length === 0 && playlist.count < 1) {
            resetScrollPosition();
            fetchPlaylists();
        }
        if (playlist !== undefined && !playlist.loading && playlist.isFetching) {
            resetScrollPosition();
            fetchPlaylists();
        }
    }, [playlist]);

    useEffect(() => {
        if (end) {
            if (playlist.totalCount > playlist.filter.startIndex) {
                setPlaylist({
                    ...playlist,
                    filter: {
                        ...playlist.filter,
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end]);

    const removeThumb = id => {
        const newContent = selected.filter(i => i.contentId !== id);
        setSelected(newContent);
        selectedContents(newContent);
    };

    const handleSelect = (id, name, thumbFileId, size, type, playTime) => {
        const item = {
            contentId: id,
            contentName: name,
            thumbFileId: thumbFileId,
            contentSize: size,
            mediaType: type,
            //playTime : playTime
        };
        let newSelectedContent = [];
        if (selected.findIndex(i => i.contentId === id) === -1) {
            if (multiSelect) {
                setSelected((prevState) => ([
                    ...prevState,
                    item
                ]));
                newSelectedContent = selected.concat(item);
            } else {
                newSelectedContent = [item];
            }
        } else {
            newSelectedContent = selected.filter(i => i.contentId !== id);
            setSelected((prevState) => prevState.filter(i => i.contentId !== id));
        }
        selectedContents(newSelectedContent);
    };

    const selectAll = () => {
        if (selected.length === playlist.list.length) {
            setSelected([]);
            selectedContents([]);
        } else {
            const newItem = playlist.list.map((item) => {
                return {
                    contentId: item.playlistId,
                    contentName: item.playlistName,
                    thumbFileId: getThumbnailId(item.thumbnailInfo, 0),
                    contentSize: item.totalSize,
                    mediaType: item.playlistType
                }
            });
            setSelected(newItem);
            selectedContents(newItem);
        }
    };

    const removeAll = () => {
        setSelected([]);
        selectedContents([]);
    }

    return (
        <div className="" style={{display: "inline-flex"}}>
            <div className="lnb">
                <ul>
                    <li className={classNames({'on': active === 'ALL'})} onClick={() => activeMenu('ALL')}>
                        <a href="#" id="allContentListBtn">{t("COM_SID_ALL")}</a>
                    </li>
                    <li className={classNames({'on': active === 'MY_CONTENT'})}
                        onClick={() => activeMenu('MY_CONTENT')}>
                        <a href="#" id="myContentListBtn">{t("TEXT_TITLE_MY_PLAYLIST_VIEW_P")}</a>
                        {
                            active === 'MY_CONTENT' && <MyGroups mode={'PLAYLIST'} selectGroup={selectGroup} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} playlistTypes={playlist.filter.playlistTypes}/>
                        }
                    </li>
                </ul>
            </div>
            <div className="schedule_content_wrap">
                <div className="mt10 mr10 mb10 ml20" style={{height: 30}}>
                    <div style={{float: "left"}}>
                        <span className="space"></span>
                    </div>
                    {multiSelect &&
                    <div style={{float: "left"}}>
                        <span className="space"></span>
                        <div className="select_box">
                            <button
                                className="base select"
                                style={{minWidth: "70px"}}
                                onClick={selectAll}>
                                <span>{t("BUTTON_ALL_SELECT_P")}</span>
                            </button>
                        </div>
                    </div>
                    }
                    <div style={{float: "right", display: "flex"}}>
                        <SwitchListNImage view={view} onClick={handleListNImage}/>
                        <SearchBar
                            width={120}
                            onClickSearch={onSearch}
                            placeholder={t("TABLE_PLAYLIST_NAME_P")}
                        />
                    </div>
                </div>
                <div
                    className="pop_list"
                    id={"select_playlist_scroll_wrap"}
                    style={{
                        height: "365px",
                        borderTop: "1px solid #e7e7e7",
                        display: "block",
                        position: "absolute",
                        width: "100%"
                    }}
                >
                    {
                        playlist.loading &&
                        <div style={{display: 'flex', justifyContent: 'center'}}><Loading/></div>

                    }
                    {view === "LIST" && (
                        <ListView
                            playlists={playlist.list}
                            selectedId={selected}
                            handleSelect={handleSelect}
                        />
                    )}
                    {view === "IMAGE" && (
                        <ImageView
                            playlists={playlist.list}
                            selectedId={selected}
                            handleSelect={handleSelect}
                        />
                    )}
                </div>
            </div>
            <div className="selected_content_wrap">
                <div className="mt10 mr10 mb10 ml20 clearfix">
                    <div className="float_l">
                        <div className="select_box float_l mr8">
                            <button
                                className="base select"
                                id="removeAllBtn"
                                style={{minwidth: "78px"}}
                                onClick={removeAll}
                            >
                                <span>{t("COM_BUTTON_DELETE_ALL_P")}</span>
                            </button>
                        </div>
                        <div className="select_box float_l mr8" style={{paddingTop: "5px"}}>
                            <span>{getMixString(["MIS_SID_WEBAUTHOR_MIX_SELECTED_ITEMS", selected.length])}</span>
                            <span id="statisticsSelectedCount"></span>
                        </div>
                    </div>
                </div>
                <div className="thumbnailview_wrap">
                    <div className="pop_list" style={{height: "356px"}}>
                        <ul className="clearfix mt20" id="statisticsSelectedContentsList">
                            {selected.map((v, i) => (
                                <li>
                                    <div>
                                        <button
                                            className="statisticsDelete"
                                            onClick={() => removeThumb(v.contentId)}
                                        ></button>
                                        <input
                                            type="hidden"
                                            className="contentName"
                                            value={v.contentName}
                                        />
                                        <div
                                            style={{
                                                width: "75px",
                                                height: "61px",
                                                textAlign: "center",
                                                lineHeight: "42px"
                                            }}
                                        >
                                            <ContentThumbnail
                                                id={v.thumbFileId}
                                                width={75}
                                                height={42}
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
