import React from 'react';
import {useTranslation} from "react-i18next";
import {toUpper} from 'lodash';
import './RadioGroup.css';

const RadioGroup = ({width, propertyName, selects, value, multiLang, disabled, onChange}) => {
    const {t} = useTranslation();
    return (
        <span className='radio_group_wrap'>
            {
                selects.map(e => {
                    if(e.hide) {
                        return null;
                    }
                    return (
                        <div key={`radio_${e.title}_${e.value}`} className="mr30" data-name={propertyName} data-value={e.value} onMouseDown={evt => !disabled && onChange(evt, e.value)}>
                            <input id={`radio_${e.title}_${e.value}`} style={{display: 'none'}} data-name={propertyName} data-value={e.value} type="radio" value={e.value} checked={toUpper(value) === toUpper(e.value)} readOnly disabled={disabled} />
                            <label htmlFor={`radio_${e.title}_${e.value}`} data-name={propertyName} data-value={e.value} title={e.description ? e.description : undefined}>{multiLang ? t(e.title) : e.title}</label>
                        </div>
                    );
                })
            }
        </span>
    );
};

RadioGroup.defaultProps = {
    width: 200,
    selects: [],
    value: '',
    multiLang: true,
    onChange: () => {},
};

export default RadioGroup;