import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import {getDeviceTypeStr} from "../../../../helper/device/deviceUtils";
import {useSelector} from "react-redux";
import {dashboardService} from "../../../../services";
import {Loading} from "../../../../components/loading/Loading";

const DeviceHealthByDeviceType = () => {

    const [chartData, setChartData] = useState({});
    const {t} = useTranslation();
    const [tick, setTick] = useState({});
    const [loading, setLoading] = useState(true);
    const [showChart, setShowChart] = useState(true);

    const {reload} = useSelector(state => state.dashboard);

    const _makeChart = (deviceHealth) => {
        const labels = [];
        const errors = [];
        const warning = [];
        deviceHealth.map(
            device => {
                labels.push(getDeviceTypeStr(device.deviceType));
                errors.push(device.error);
                warning.push(device.warning);
            }
        )
        const data = {};
        data.labels = labels;
        data.datasets = [
            {
                label: 'error',
                data: errors,
                backgroundColor: '#EB5C41',
                yAxisID: 'error',

            },
            {
                label: 'warning',
                data: warning,
                backgroundColor: '#F8AD38',
                yAxisID: 'warning',

            },
        ]
        setChartData(data);
    }

    const fetchData = () => {
        dashboardService.fetchDeviceHealthResource().then(res => {
            if (res && res.items) {
                _makeChart(res.items);
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            setShowChart(false);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(()=> {
        fetchData();
    }, [reload])

    useEffect(()=> {
        if (chartData && chartData.datasets && chartData.datasets.length > 0) {
            const errors = chartData.datasets[0].data;
            const warning = chartData.datasets[1].data;
            const max = Math.max(10, Math.max(...errors), Math.max(...warning));
            if (max > 10) {
                setTick({suggestedMax: max, fontSize: 10, fontColor: '#909090'});
            } else {
                setTick({max: 10, fontSize: 10, fontColor: '#909090'})
            }
        }
    }, [chartData])

    return (
        <div style={{width: '100%', height: 200}}>
            <div style={{marginBottom: 10, color: '#241f20'}}>{t('MIS_SID_CBFEB_DEVICE_HEALTH_DEVICE_TYPE')}</div>
            {
                showChart ?
                    ((!loading) ?
                        <Bar
                            height={170}
                            data={chartData}
                            options={{
                                showDatapoints: true,
                                maintainAspectRatio: false,
                                legend: {
                                    display: false,
                                },
                                animation: {
                                    duration: 0
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            display: false
                                        },
                                        barThickness: 10,
                                        maxBarThickness: 10,
                                        ticks: {
                                            fontSize: 10,
                                            fontColor: '#454545'
                                        }
                                    }],
                                    yAxes: [{
                                        id: 'error',
                                        position: 'left',
                                        ticks: {
                                            beginAtZero: true,
                                            callback: function (value) { if (value % 1 === 0) { return value; } },
                                            ...tick
                                        },
                                        gridLines: {
                                            drawBorder: false
                                        },
                                    }, {
                                        id: 'warning',
                                        position: 'right',
                                        display: false,
                                        ticks: {
                                            beginAtZero: true,
                                            callback: function (value) { if (value % 1 === 0) { return value; } },
                                            ...tick
                                        },
                                        gridLines: {
                                            drawBorder: false
                                        },
                                    }]
                                }
                            }} /> : <div className={'area_center'}> <Loading></Loading></div>)
                    : <div className={'area_center no_data'}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
            }
        </div>
    )

}
export default DeviceHealthByDeviceType;