import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {scheduleService} from "../../../../services/schedule.service";
import Select from "../../../selectbox/Select";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../../../helper/responseHandler";

const EventCondition = ({programId, closePopup}) => {

    const {t} = useTranslation();

    const [eventProgram, setEventProgram]= useState([]);

    const [selects, setSelects] = useState([]);
    const [subSelects, setSubSelects] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const [selectedEventCondition, setSelectedEventCondition] = useState(undefined);

    const [selectedEventType, setSelectedEventType] = useState(undefined);


    const sendEventCondition = () => {
        if (selectedEventCondition !== undefined && selectedEventCondition !== '') {
            scheduleService.sendEventScheduleCondition(selectedEvent, programId.slice(0, 1), selectedEventCondition, false)
                .then(
                    res=> {
                        toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_EDIT_P"));
                    }
                )
                .catch(e=>toastr.error(getErrorMessage(e)))
                .finally(
                    closePopup()
                )
        } else {
            toastr.error(t("MIS_MESSAGE_EVENT_SELECT_START_CONDITION_P"));
        }
    }

    const handleEventCondition = (e, value) => {
        setSelectedEvent(value);
    }

    const handleSubEventCondition = (e, value) => {
        setSelectedEventCondition(value);
    }

    useEffect(()=> {
        scheduleService.fetchEventScheduleById(programId[0])
            .then(
                res=> {
                    if (res) {
                        const {selectedEventList} = res.items.data;
                        setEventProgram(selectedEventList);
                    }
                }
            )
    }, [])

    useEffect(()=> {
        const updatedSelect = [];
        if (eventProgram !== undefined && eventProgram.length > 0) {
            eventProgram.map(
                event => updatedSelect.push({value: event.eventId, title: event.eventName, type: event.conditionType.toUpperCase()})
            )
        }
        setSelects(updatedSelect)

    }, [eventProgram])

    useEffect(()=> {
        if (selectedEvent !== undefined && selectedEvent !== '') {
            scheduleService.fetchEventConditionById(selectedEvent)
                .then(
                    res => {
                        if (res) {
                            const {eventConditions, eventType} = res.items;
                            const updatedEventConditions = [];
                            if (eventConditions !== undefined && eventConditions.length > 0) {
                                if(eventType === 'TEXT') {
                                    eventConditions.map(
                                        c => updatedEventConditions.push({title: c.compString, value: c.compString})
                                    )
                                }
                                else if(eventType === 'BOOL') {
                                    updatedEventConditions.push({title: t('MIS_TEXT_TRUE_P'), value: true})
                                    updatedEventConditions.push({title: t('MIS_TEXT_FALSE_P'), value: false})
                                }
                            }
                            setSubSelects(updatedEventConditions);
                            setSelectedEventType(eventType);
                        }
                    }
                )
        }

    }, [selectedEvent])

    const dialogProps ={title: t("MIS_BUTTON_EVENT_SEND_CONDITION_P"), closeOnEscape : true, modal:true, onClose:()=> closePopup()};

    return (
        <MISDialog
            dialog={dialogProps}
            width={350}
            height={150}
            buttons={{
                rightButtons: [
                    {id: 'SEND_EVENT_CONDITION', title: t("MIS_BUTTON_EVENT_SEND_CONDITION_P"), onClick: ()=>sendEventCondition()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=>closePopup()}]
            }}
        >
            <div className="detail_view mini_p mt40 mb20">
                <table>
                    <colgroup></colgroup>
                    <tbody id="eventConditionTbody">
                    <tr>
                        <th>{t("MIS_TEXT_EVENT_CONDITION_P")}</th>
                        <td>
                            {
                                selects !== undefined && selects.length > 0 &&

                                    <Select
                                        selects={selects}
                                        multiLang={false}
                                        width={200}
                                        value={selectedEvent}
                                        onChange={handleEventCondition}
                                    />
                            }
                        </td>
                    </tr>
                    { selectedEventType && (selectedEventType === 'TEXT' || selectedEventType === 'BOOL') &&
                        <tr>
                            <th></th>
                            <td>
                                <Select
                                selects={subSelects}
                                multiLang={false}
                                width={200}
                                value={selectedEventCondition}
                                defaultTitle={t("TEXT_SELECT_P")}
                                onChange={handleSubEventCondition}
                                />
                            </td>
                        </tr>
                    }
                    { selectedEventType && selectedEventType === 'NUMBER' &&
                        <tr>
                            <th></th>
                            <td>
                            <input
                                className="numeric"
                                type="number"
                                style={{width: "178px"}}
                                onChange={(e) => handleSubEventCondition(e, e.target.value)}
                            />
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>


        </MISDialog>
    )
}
export default EventCondition;