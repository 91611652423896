import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import ContentFileTypes from "./ContentFileTypes";
import './ContentTypeFilter.css'
import {useOutsideClick} from "../../helper";

const ContentTypeFilter = ({contentTypes, onChange}) => {

    const {t} = useTranslation();
    const [show, setShow]  = useState(false);
    const mediaTypes = contentTypes;

    const [selected, setSelected] = useState(mediaTypes);

    const outSide = useRef();
    useOutsideClick(outSide, ()=>{
        if (show) {
            setShow(false);
        }
    });

    const contentCheckBox = [];
    let contentCheckAll = useRef();

    const toggleContentAll = (e) => {
        if(e.target.checked){
            for(let i = 0; i < mediaTypes.length; i++){
                if(!contentCheckBox[mediaTypes[i]].checked){
                    contentCheckBox[mediaTypes[i]].checked = true;
                }
            }
            setSelected(mediaTypes);
        }else {
            for(let i = 0; i < mediaTypes.length; i++){
                if(contentCheckBox[mediaTypes[i]].checked){
                    contentCheckBox[mediaTypes[i]].checked = false;
                }
            }
            setSelected([]);
        }
    };

    const showPopup =() => {
        setShow(!show);
    }

    const toggleContent = (type) => {
        if (contentCheckBox[type].checked) {
            setSelected(selected.concat(type));
        } else {
            setSelected(selected.filter(content=> content !== type))
        }
    };

    useEffect(()=> {        

        onChange(selected);
    }, [selected])


    return (
        <div className={"content_type_filter_wrap"}>
            <div className="select_box float_l mr8">
                <button className="base select" id="loadContentFileType" style={{minWidth:99}} onClick={showPopup}>
                    <span>{t("TABLE_TYPE_P")}<span className="arrow"></span></span>
                </button>
            </div>
            <div style={{display: show ? '':'none'}} ref={outSide}>
                <ContentFileTypes id={'content_filter'} contentCheckAll={contentCheckAll} toggleContentAll={(e)=>toggleContentAll(e)} toggleContent={(type)=>toggleContent(type)} contentCheckBox={contentCheckBox} filterMode={false} contents={mediaTypes}
                selectedContents={selected}/>
            </div>
        </div>

    )
}
export default ContentTypeFilter;