import React from 'react'
import HTMLIcon from '../../../images/icon/file_type_icon_14.png';
import classNames from "classnames";

const HTML = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={HTMLIcon}/>
            {
                !onlyIcon && 'HTML'
            }
        </span>
    )
}
export default HTML;