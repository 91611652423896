import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from "react";
import MISDialog from "../../MISDialog";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../../actions";
import {toastr} from 'helper/toastrIntercept';
import { settingService } from "services";
import SearchBar from "../../../search/SearchBar";
import {settingConstants} from "../../../../constants";
import {isNil} from 'lodash';
import LicenseSummary from "containers/setting/LicenseSummary";
import TooltipIcon from "components/icon/TooltipIcon";
import { getErrorMessage } from "../../../../helper/responseHandler";
import {getLicenseSummary} from '../../../../helper';

const LicenseAssignPopup = ({onClose, title, editable = true}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [orgLicenseList, setOrgLicenseList] = useState([]);
    const [licenseTypes, setLicenseTypes] = useState([]);

    const onClickEdit = () => {
        dispatch(popupAction.addPopup({
            type: settingConstants.EDIT_LICENSE_ASSIGN_POPUP,
            id: settingConstants.EDIT_LICENSE_ASSIGN_POPUP,
            title: t("MIS_SID_CBFEB_ALLOCATE_ORGANIZATION"),
            onSave: loadData,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.EDIT_LICENSE_ASSIGN_POPUP)),
            licenseTypes: licenseTypes
        }));
    }

    const loadOrganizations = orgName => {
        settingService.fetchOrganizationLicense().then(res => {
            if(!res) {
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                return;
            } else if(res.errorCode !== '200'){
                toastr.error(res.errorMessage);
                return;
            }

            const {items} = res;
            if(!isNil(orgName))
                setOrgLicenseList(items.filter(item => {
                    if(!isNil(item.organizationName)) 
                        return (item.organizationName.toLowerCase().indexOf(orgName.toLowerCase()) >= 0);
                    else 
                        return false;
                }));
            else             
                setOrgLicenseList(items);            
        });
    }

    const loadData = () =>  {
        loadOrganizations();
        Promise.all([settingService.fetchLicenses(), settingService.fetchOrganizationLicense()]).then(res => {
            setLicenseTypes(getLicenseSummary(res[0].items, res[1].items));
        }).catch(e=>toastr.error(getErrorMessage(e))); 
    }

    useEffect(()=> {
        loadData();
    }, []);

    const rightButtons = [];
    editable && rightButtons.push({
        id: 'ORGANIZATION_LICENSE_EDIT',
        title: t('MIS_SID_CBFEB_ALLOCATE_ORGANIZATION'),
        onClick: onClickEdit
    });
    rightButtons.push({
        id: 'ORGANIZATION_LICENSE_CLOSE',
        title: t('COM_BUTTON_CLOSE_P'),
        onClick: onClose
    });

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    isDraggable: true,
                    width: 680,
                    height: 390,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: rightButtons
                }}>
                <div>
                    <div className={'mb15'} style={{height:28, lineHeight:2.2}}>
                        <div className={'mb5'} style={{float:'left'}}>
                            <div style={{marginLeft:12, marginRight:10, float:'left'}}>{t('COM_TV_SID_AVAILABLE_KR_MIS20')} |</div>
                            <div style={{float:'left', width:350}}>
                                {
                                    licenseTypes.map((type, i) => {
                                        return (
                                            <span style={{paddingRight:10}}>
                                                {type.productName + ' : ' + type.available} 
                                                <TooltipIcon message={<LicenseSummary licenseType={type.productName} totalCount={type.totalCount}/>} style={{width:370, height:240}}/>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <span style={{float:'right'}}>
                            <SearchBar id="logSearch" placeholder={t("SETUP_NEW_STRING29_P")} onClickSearch={loadOrganizations} onBlurSearch={loadOrganizations} width={150}/>
                        </span>
                    </div>
                </div>
                <div className="server_setting none_scrolling check_list">
                    <table style={{width:'100%'}}>
                        <colgroup>
                            <col width="182px"/>
                            <col width=""/>
                            <col width=""/>
                            <col width=""/>
                            <col width=""/>
                            <col width=""/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowspan="2">{t('SETUP_NEW_STRING29_P')}</th>
                                <th colspan="2">{'Unified Player'}</th>
                                <th colspan="2">{'RM Player'}</th>
                                <th rowspan="2">{t('MESSAGE_STATISTICS_TABLE_COLUMN_APPROVE_TOTAL_DEVICE_NUMBER_P')}</th>
                            </tr>
                            <tr>
                                <th>{t('COM_TV_SID_DEVICES_KR_MIS20')}</th>
                                <th>{t('MIS_SID_CBFEB_ALLOCATED')}</th>
                                <th>{t('COM_TV_SID_DEVICES_KR_MIS20')}</th>
                                <th>{t('MIS_SID_CBFEB_ALLOCATED')}</th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{height:'300px', overflow:'auto'}}>
                        <table style={{width:'100%'}}>
                            <colgroup>
                                <col width="182px"/>
                                <col width=""/>
                                <col width=""/>
                                <col width=""/>
                                <col width=""/>
                                <col width=""/>
                            </colgroup>                            
                            <tbody>
                            {
                                orgLicenseList.length <= 0 && 
                                <tr>
                                    <td colspan='6' style={{textAlign:'center'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</td> 
                                </tr>
                            }
                            {
                                orgLicenseList.length > 0 && orgLicenseList.map((orgLic) => {
                                    let unified, rm, lite;
                                    orgLic.orgLicenseEntities.forEach(entity => {
                                        if(entity.productCode === '01015A')
                                            unified = entity;
                                        else if(entity.productCode === '01064A')
                                            rm = entity;
                                    });

                                    return (
                                        <tr>
                                            <td><p style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'155px'}} title={orgLic.organizationName}>{orgLic.organizationName}</p></td>
                                            <td>{unified.usedLicenseCount}</td>
                                            <td>{unified.assigned ? unified.maxLicenseCount : '-'}</td>
                                            <td>{rm.usedLicenseCount}</td>
                                            <td>{rm.assigned ? rm.maxLicenseCount : '-'}</td>
                                            <td>{unified.usedLicenseCount + rm.usedLicenseCount}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
}

export default LicenseAssignPopup;