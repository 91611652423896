import React, {useCallback} from "react";
import CustomStorage from "./components/CustomStorage";
import './CustomArea.css';
import CustomUser from "./components/CustomUser";
import CustomSoftwareUpdate from "./components/CustomSoftwareUpdate";
import CustomExternalServers from "./components/CustomExternalServers";
import {dashboardConstants} from "../../../constants/DashboardConstants";
import {useDispatch, useSelector} from "react-redux";
import {dashboardAction} from "../../../actions";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import arrayMove from 'array-move';
import {dashboardService} from "../../../services";
import {useTranslation} from "react-i18next";

const SortableItem = SortableElement(({widgetId, removeWidget}) => {
    switch (widgetId) {
        case dashboardConstants.DASHBOARD_CUSTOM_STORAGE_WIDGET:
            return <CustomStorage removeWidget={removeWidget}/>;
        case dashboardConstants.DASHBOARD_CUSTOM_USER_WIDGET:
            return <CustomUser removeWidget={removeWidget}/>;
        case dashboardConstants.DASHBOARD_CUSTOM_SOFTWARE_UPDATE_WIDGET:
            return <CustomSoftwareUpdate removeWidget={removeWidget}/>;
        case dashboardConstants.DASHBOARD_CUSTOM_EXTERNAL_SERVER_WIDGET:
            return <CustomExternalServers removeWidget={removeWidget}/>;
    }
});

const SortableList = SortableContainer(({widgets, removeWidget}) => {

    return (
        <div className={'custom_container'}>
        {
            widgets.map((widget, index) => (
                widget.active && <SortableItem key={widget.widgetId} index={index} widgetId={widget.widgetId} removeWidget={removeWidget}/>
            ))
        }
        </div>
    );
});

const CustomArea = ({}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {widgets = []} = useSelector(state => state.dashboard);

    const removeWidget = (e, id) => {
        const updateWidgets = widgets.map(
            widget => widget.widgetId === id ? {...widget, active: false} : {...widget}
        )
        dashboardService.updateWidgets(updateWidgets)
            .then(res => dispatch(dashboardAction.updateWidgets(updateWidgets)))
            .catch(e => console.log(e));
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newWidget = Object.assign([], widgets);
        const updateWidgets = arrayMove(newWidget, Number(oldIndex), Number(newIndex));
        dashboardService.updateWidgets(updateWidgets)
            .then(res => dispatch(dashboardAction.updateWidgets(updateWidgets)))
            .catch(e => console.log(e));
    };

    const isShowTitle = useCallback(()=> {
        if (widgets.length > 0) {
            return widgets.some(widget => widget.active === true)
        }
        return false;
    }, [widgets])

    const showTitle = isShowTitle();

    return (
        <div className={'custom_area'}>
            {
                showTitle && <div className={'area_title'}>{t('MIS_SID_CBFEB_SERVER_STATUS_AND_OTHER')}</div>
            }
            {
                widgets.length > 0 &&
                <SortableList
                    axis="x"
                    helperClass='dashboard-widget-drag-mode'
                    onSortEnd={onSortEnd}
                    useDragHandle={true}
                    widgets={widgets}
                    removeWidget={removeWidget}
                    />
            }
        </div>
    )
}
export default CustomArea;
