import React from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import MISDialog from './MISDialog';
import './AlertPopup.css';

const AlertPopup = ({title, message, width = 391, height = 233, position, onClose, buttonTitle}) => {
    const {t} = useTranslation();
    return (
        <div className='alert_popup_wrap'>
            <MISDialog
                dialog={{
                    title,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width,
                    height,
                    position,
                    onClose
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ALERT_CLOSE',
                            title: buttonTitle || t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='alert_popup_message_wrap'>
                    <span className='alert_popup_message_wrap_text'>{message}</span>
                </div>
            </MISDialog>
        </div>
    );
};
export default AlertPopup;