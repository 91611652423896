import React from 'react';
import './TagSettingPopup.css';

const TagNameCell = ({tag: {tagName}, onClick}) => {
    return (
        <div className='tag_name_block' onClick={onClick}>
            <span className='tag_name_cell' title={tagName}>{tagName}</span>
        </div>
    );
};

TagNameCell.defaultProps = {
    tag: {},
    onClick: () => {},
};

export default React.memo(TagNameCell);