import React from 'react';
import {withTranslation} from "react-i18next";
import GroupsPopup from "../GroupsPopup";

const DevicePermissionPopup = ({t, title, deviceGroupIds, organizationId, userPermissionItems, onClose, onSave}) => {

    const close = () => {
        onClose();
    }

    const save = (groups) => {
        onSave(groups);
    }

    return (
        <GroupsPopup
            mode={"device"}
            submode={"userPermission"}
            checkbox={true}
            disableSearchToolbar={true}
            defaultCheckedKeys={deviceGroupIds}
            expandedKeys={deviceGroupIds}
            organizationId={organizationId}
            userPermissionItems={userPermissionItems}
            save={(groups)=>save(groups)}
            allowEmptySave={true}
            close={()=>close()}
        />
    );
};


export default withTranslation()(DevicePermissionPopup);