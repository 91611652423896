import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from "react-dom";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './SubTab.css';

const ExtraInfoSubTab = ({tabs, selected, selectedIndex, viewSize, onClick, width}) => {
    const {t} = useTranslation();
    
    const [visibility, setVisibility] = useState(false);
    const [ellipsisMenu, setEllipsisMenu] = useState();
    const ellipsisEl = useRef();

    const onChangeVisible = (e, visible) => {
        setVisibility(visible);
    };

    const onClickEllipsisMenu = tab => {
        setEllipsisMenu(tab);
        setVisibility(false);
        onClick && onClick(tab, tabs.indexOf(tab));
    }

    const onClickLast = tab => {
        tabs.forEach((t, i) => {
            if(tab.id === t.id)
                onClick && onClick(tab, i);
        });
    }

    const handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(ellipsisEl.current);
        if (!domNode || !domNode.contains(event.target)) {
            setVisibility(false);
        }
    }

    useEffect(() => {
        (tabs.length === viewSize + 1) && setEllipsisMenu(tabs[viewSize]);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    return (
        <div className='sub_tab_wrap'>
            <ul>
                {
                    tabs.map((tab, index) => {
                        if(viewSize === 0 || index < viewSize) {
                            return (
                                <li 
                                    key={tab} 
                                    className={classNames({on: tab === selected || selectedIndex === index})}
                                    onClick={() => onClick(tab, index)}>
                                    {
                                        typeof tab === 'string' && <span title={t(tab)} style={{maxWidth: width}}>{t(tab)}</span>
                                    }
                                    {
                                        typeof tab === 'object' && <span style={{maxWidth: width}}>{tab.text}</span>
                                    }
                                </li>
                            );
                        } else {
                            return null;
                        }
                    })
                }
                {
                    ellipsisMenu &&
                    <li key={ellipsisMenu} className={classNames({'on':ellipsisMenu === selected || tabs.indexOf(ellipsisMenu) === selectedIndex})} onClick={() => onClickLast(ellipsisMenu)} style={{maxWidth: width ? width:null}}>
                        {
                            typeof ellipsisMenu === 'string' && <span title={t(ellipsisMenu)} style={{maxWidth: width}}>{t(ellipsisMenu)}</span>
                        }
                        {
                            typeof ellipsisMenu === 'object' && <span style={{maxWidth: width}}>{ellipsisMenu.text}</span>
                        }                        
                    </li>
                }
            </ul>
            {
                tabs.length > viewSize + 1 &&
                <div style={{display:'inline-block', position:'relative', width:15, height:30, marginLeft: 5}} ref={ellipsisEl}>
                    <span className="ellipsis_icon" onMouseOver={e => onChangeVisible(e, true)}/>
                    {
                        visibility && <div className="ellipsis_menu" onMouseLeave={e => onChangeVisible(e, false)}>
                            <ul>
                                {
                                    tabs.slice(viewSize).map(tab => {
                                        if(ellipsisMenu !== tab){
                                            return (
                                                <li>
                                                    {
                                                        typeof tab === 'string' && <span title={t(tab)} style={{maxWidth: width}} onClick={() => onClickEllipsisMenu(tab)}>{t(tab)}</span>
                                                    }
                                                    {
                                                        typeof tab === 'object' && <span style={{maxWidth: width}} onClick={() => onClickEllipsisMenu(tab)}>{tab.text}</span>
                                                    }                                                      
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>    
                        </div>
                    }
                </div>
            }
        </div>
    );
};

ExtraInfoSubTab.defaultProps = {
    tabs: [],
    viewSize: 0,
    onClick: () => {},
};

export default ExtraInfoSubTab;