import React, {useEffect, useRef, useState} from 'react';
import {useMISOpt} from "../misopt";
import './DateSelectInput.css';
import moment from 'moment';
import {useOutsideClick} from "../../helper";
import classNames from 'classnames';

const DateSelectInput = ({setDate}) => {


    const now = moment();

    const ref = useRef();

    const {misopt: {user: {dateFormat}}} = useMISOpt();

    const updateDateFormat = dateFormat ? dateFormat.replace('yyyy', 'YYYY').replace('dd', 'DD') : "YYYY-MM-DD";

    const [showPicker, setShowPicker] = useState(false);


    const [currentDate, setCurrentDate] = useState(now.format(updateDateFormat));
    const [month, setMonth] = useState(now.format('MM'));
    const [day, setDay] = useState(now.format('DD'));

    const updateMonth = (month) => {
        setMonth(month);
    }

    const updateDay = (day) => {
        setDay(day);
    }

    useEffect(()=> {
        if (updateDateFormat === 'DD/MM/YYYY') {
            setCurrentDate(`${day}/${month}`);
        } else if (updateDateFormat === 'MM/DD/YYYY') {
            setCurrentDate(`${month}/${day}`);
        } else if (updateDateFormat === 'YYYY-MM-DD') {
            setCurrentDate(`${month}-${day}`);
        }
    }, [month, day])

    useEffect(()=> {
        setDate(currentDate);
    }, [currentDate])


    const RenderDays = ({day}) => {

        return (
            <ul style={{overflowY: 'auto', height: 100, flexGrow: 3, textAlign: 'center'}}>
                {
                    [
                        '01','02','03','04','05','06','07','08','09','10',
                        '11','12','13','14','15','16','17','18','19','20',
                        '21','22','23','24','25','26','27','28','29','30', '31'].map(
                        d => <li key={d} className={classNames('date_picker_row', d === day ? 'selected' : '')} onClick={()=>updateDay(d)}>{d}</li>
                    )
                }
            </ul>
        )
    }

    const RenderMonths = ({month}) => {

        return (
            <ul style={{overflowY: 'auto', height: 100, flexGrow: 3, textAlign: 'center'}}>
                {
                    ['01','02','03','04','05','06','07','08','09','10', '11','12'].map(
                        m => <li key={m} className={classNames('date_picker_row', m === month ? 'selected' : '')} onClick={()=>updateMonth(m)}>{m}</li>
                    )
                }
            </ul>
        )
    }

    useOutsideClick(ref, ()=> {
        setShowPicker(false);
    })

    return (
        <span className={'date_picker_wrap'} ref={ref}>
            <input value={currentDate} onMouseDown={()=>setShowPicker(true)} style={{width: 70}} readOnly={true}/>
            {
                showPicker &&
                <div style={{height: 100, display: 'flex', border: '1px solid #cccccc', backgroundColor: '#fff', position: 'inline-block'}}>
                    {
                        updateDateFormat === 'DD/MM/YYYY' &&
                        <>
                            <RenderDays day={day}/>
                            <RenderMonths month={month} />
                        </>
                    }
                    {
                        updateDateFormat === 'MM/DD/YYYY' &&
                        <>
                            <RenderMonths month={month}/>
                            <RenderDays day={day}/>
                        </>
                    }
                    {
                        updateDateFormat === 'YYYY-MM-DD' &&
                        <>
                            <RenderMonths month={month}/>
                            <RenderDays day={day}/>
                        </>
                    }
                </div>
            }
        </span>
    )
}
export default DateSelectInput;
