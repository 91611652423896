import React from 'react';
import {useTranslation} from "react-i18next";

export const getConnectionStatusOption = t => {
    return [
        {label:t("TEXT_ALL_P"), value:"device_status_view_all"},
        {label:t("COM_MIS_TEXT_CONNECTED_P"), value:"device_status_view_connection"},
        {label:t("TEXT_DISCONNECTED_P"), value:"device_status_view_disconnection"},
        {label:`${t("TEXT_DISCONNECTED_P")} (30 ${t('MIS_SID_20_DAYS')} ~)`, value:"device_status_view_disconnection_30"},
        {label:`${t("TEXT_DISCONNECTED_P")} (60 ${t('MIS_SID_20_DAYS')} ~)`, value:"device_status_view_disconnection_60"},
        {label:`${t("TEXT_DISCONNECTED_P")} (90 ${t('MIS_SID_20_DAYS')} ~)`, value:"device_status_view_disconnection_90"}
    ];
}

const ConnectionStatusFilter = ({connectionStatus, onChange}) => {
    const {t} = useTranslation();
    const connectionStatusOption = getConnectionStatusOption(t);
    
    return (
        <div className='dev_filter_type_radio'>
            <h5><span className="device_type" style={{background:'#ffc446'}}></span>{t("COM_MESSAGE_STATISTICS_LEFT_MENU_SUB_CONNECTION_P")}</h5>
            <div style={{overflowY:'auto'}}>
                <ul style={{overflowY: 'auto'}}>
                    {
                        connectionStatusOption.map((option, i) => {
                            return (
                                <li key={option.value} className={connectionStatus === option.value ? "on" : ""}  onClick={() => onChange(option.value)}>
                                    <a href="#">{option.label}</a>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default ConnectionStatusFilter;