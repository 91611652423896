import React from 'react';
import { withTranslation } from 'react-i18next';
import MISDialog from '../MISDialog';
import Category from "../../category/Category";
import {playlistService} from "../../../services";
import {MISOptContext} from "../../misopt";

const optionStyle = {
    width: '410px',
    height: '350px',
    position: 'relative',
    top: '15px'
};

const innerStyle = {
    height: '300px'
};

class PlaylistCategoryPopup extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            defaultCheckedKeys : [],
        }

    }

    getAuthority(id) {
        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    onCategoryCheck = (e) => {
        this.setState({
            categories : e
        })
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const {playlistIds = []} = this.props;
        if(!playlistIds || playlistIds.length !== 1 ) {
            return null;
        }
        playlistService.fetchPlaylistById(playlistIds[0])
            .then(res => {
                if (typeof res.items.categoryList !== 'undefined' && res.items.categoryList.length > 0) {
                    const defaults = res.items.categoryList.map( el =>  String(el.groupId));
                    this.setState({defaultCheckedKeys: defaults});
                }
            });
    };

    render () {

        const {t, onClose, onSave, title} = this.props;
        const authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');

    return (
        <div className='playlist_copy_popup'>
            <MISDialog
                dialog={{
                    title: title,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 460,
                    height: 400,
                    position: {x: -250, y: -300},
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: [
                    ],
                    rightButtons: [
                        {
                            id: 'PLAYLIST_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: () => onSave(this.state.categories),
                            authority : authority.CREATE || authority.MANAGE
                        },
                        {
                            id: 'PLAYLIST_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ],
                }}>
                <div className="playlist_detail ml20 mb15" style={{width: '410px'}}>
                    <div className="sub_tabwrap clearfix ">
                        <ul>
                            <li className="m1 on" rel="categoryDiv">
                                <a href="#" id="category">{t('DID_MAIN_CATEGORY')}</a>
                            </li>
                        </ul>
                    </div>
                    <Category
                        onCategoryCheck={this.onCategoryCheck}
                        optionStyle={optionStyle}
                        innerStyle={innerStyle}
                        defaultCheckedKeys={this.state.defaultCheckedKeys}
                        checkStrictly={true}
                        defaultExpandAll={true}/>

                </div>
            </MISDialog>
        </div>
    )};
};

export default withTranslation()(PlaylistCategoryPopup);
