import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import MISDialog from "../MISDialog";
import Tree, {TreeNode} from "rc-tree/es";
import './LDAPDevicePermissionPopup.css';
import {userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {cloneDeep} from "lodash";
import {getErrorMessage} from "../../../helper/responseHandler";

const Icon = (device, disabled) => {
    let className = 'customize-icon';
    if(device.parentGroupId === 0) {
        className += ' organization';
    } else if(device.parentGroupId !== 0 && device.type === 'default') {
        className += ' default';
    } else {
        className += ' player ' + device.type;
    }
    return <span className={className} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}}/>
};

class LDAPDevicePermissionPopup extends Component {
    state = {
        originalDeviceData: undefined,
        deviceData: undefined,
        treeNode: undefined,
        checkedKeys: [],
        selectedKeys: [],
    };

    constructor(props) {
        super(props);
        this.checkedKeys= [];
        this.selectedKeys= [];
    }

    componentDidMount() {
        this.fetchDeviceGroupList();
    }

    onCheck = (keys, info) => {
        const strGroupId = info.node.props.eventKey;
        if(info.checked) {
            this.checkedKeys = this.checkedKeys.concat(strGroupId);
            this.selectedKeys = this.selectedKeys.concat(strGroupId);
        } else {
            this.checkedKeys = this.checkedKeys.filter(id => id !== strGroupId);
            this.selectedKeys = this.selectedKeys.filter(id => id !== strGroupId);
        }
        if(info.node.props.children && info.node.props.children.length > 0) {
            this.checkNode(info.checked, info.node.props.children);
        }
        this.setState({
            selectedKeys: this.selectedKeys,
            checkedKeys: this.checkedKeys,
        });
    };

    onSelect = (keys, info) => {
        const strGroupId = info.node.props.eventKey;
        if(info.selected) {
            this.checkedKeys = this.checkedKeys.concat(strGroupId);
            this.selectedKeys = this.selectedKeys.concat(strGroupId);
        } else {
            this.checkedKeys = this.checkedKeys.filter(id => id !== strGroupId);
            this.selectedKeys = this.selectedKeys.filter(id => id !== strGroupId);
        }
        if(info.node.props.children && info.node.props.children.length > 0) {
            this.checkNode(info.selected, info.node.props.children);
        }
        this.setState({
            selectedKeys: this.selectedKeys,
            checkedKeys: this.checkedKeys,
        });
    };

    save = () => {
        const {originalDeviceData, checkedKeys} = this.state;
        let selectedDevices = [];
        checkedKeys.forEach(key => {
            for(let index = 0; index < originalDeviceData.length; index++) {
                let device = originalDeviceData[index];
                if(device.groupId === Number(key)) {
                    selectedDevices.push({
                        groupId: device.groupId,
                        groupName: device.groupName,
                    })
                }
            }
        });
        this.props.onSave(selectedDevices);
    };

    checkNode = (checked, children) => {
        children.forEach(child => {
            if(checked) {
                this.checkedKeys = this.checkedKeys.concat(child.key);
                this.selectedKeys = this.selectedKeys.concat(child.key);
            } else {
                this.checkedKeys = this.checkedKeys.filter(id => id !== child.key);
                this.selectedKeys = this.selectedKeys.filter(id => id !== child.key);
            }
            if(child.props.children && child.props.children.length > 0) {
                this.checkNode(checked, child.props.children);
            }
        });
    };

    sortDeviceTree = (resultItems, searchItem) => {
        for(let i = 0; i < resultItems.length; i++) {
            let resultItem = resultItems[i];
            if(resultItem.groupId === searchItem.parentGroupId) {
                resultItem.children.push({...searchItem, children: []});
                return;
            }
            if(resultItem.children.length > 0) {
                this.sortDeviceTree(resultItem.children, searchItem);
            }
        }
    };

    generatorDeviceTreeNode = (deviceData) => {
        return deviceData.map((device) => {
            let disabled = false;
            if (device.status !== undefined && device.status.disabled) {
                disabled = true;
            }
            if (device.children && device.children.length > 0) {
                return <TreeNode icon={Icon(device, disabled)} disabled={disabled} title={<span><span>{device.groupName}</span><span className='group_count'>{device.resourceCount}</span></span>} key={device.groupId}>{this.generatorDeviceTreeNode(device.children)}</TreeNode>;
            } else {
                return <TreeNode icon={Icon(device, disabled)} disabled={disabled} title={<span><span>{device.groupName}</span><span className='group_count'>{device.resourceCount}</span></span>} key={device.groupId}/>;
            }
        });
    };

    fetchDeviceGroupList = () => {
        const {t, defaultDevices, groupId} = this.props;
        userService.fetchDeviceGroupList(groupId).then((res) => {
            let originalDeviceData = cloneDeep(res.items);
            let devices = [];
            res.items.forEach((item) => {
                if(item.groupDepth === 1) {
                    devices.push({...item, children: []});
                } else {
                    this.sortDeviceTree(devices, item);
                }
            });
            let treeNode = this.generatorDeviceTreeNode(devices);
            let checkedKeys = defaultDevices.map(device => device.deviceGroupId);
            let selectedKeys = defaultDevices.map(device => device.deviceGroupId);
            this.setState({
                originalDeviceData: originalDeviceData,
                deviceData: devices,
                treeNode: treeNode,
                checkedKeys: checkedKeys,
                selectedKeys: selectedKeys,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    render() {
        const {t, onClose} = this.props;
        const {treeNode, checkedKeys, selectedKeys} = this.state;
        return (
            <div className='ldap_device_permission_popup_wrap'>
                <MISDialog
                    dialog={{
                        title: t('TEXT_SEL_GROUP_P'),
                        closeOnEscape: true,
                        modal: true,
                        width: 300,
                        height: 400,
                        onClose: onClose,
                    }}
                    buttons={{
                        rightButtons: [
                            {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: this.save},
                            {id: "CLOSE", title: t("COM_BUTTON_CLOSE_P"), onClick: this.props.onClose}
                        ]
                    }}>
                    <div className={"ldap_device_permission_tree"}>
                        {treeNode &&
                        <Tree
                            multiple={true}
                            showIcon={true}
                            checkable={true}
                            selectable={true}
                            checkStrictly={true}
                            onCheck={this.onCheck}
                            onSelect={this.onSelect}
                            defaultExpandAll
                            checkedKeys={checkedKeys}
                            selectedKeys={selectedKeys}>
                            {treeNode}
                        </Tree>
                        }
                    </div>
                </MISDialog>
            </div>
        );
    };
}

export default withTranslation()(LDAPDevicePermissionPopup);