import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import {useTranslation} from 'react-i18next';
import {userService} from "../../services";
import Pagination from "../../components/table/Pagination";
import {commonConstants, contentConstants, userConstants} from "../../constants";
import {connect, useDispatch} from "react-redux";
import {menuAction, popupAction} from "../../actions";
import {snakeCase} from "lodash";
import {toastr} from 'helper/toastrIntercept';
import fileDownload from "js-file-download";
import {checkButtonDisplayWithAuthority, convertSortColumnId} from "../../components/popup/user/common/userCommons";
import {useMISOpt} from "../../components/misopt";
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";
import {getUnRecognizedData} from "../../helper/utils";

const Unapproved = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [style, setStyle] = useState({
        height: '762px'
    });

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted, keyword} = filter;
    const {addPopup, closePopup} = props;
    const isServerAdmin = localStorage.getItem('user') === null ? false : JSON.parse(localStorage.getItem('user')).serverAdmin;
    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");
    const logInGroupId = localStorage.getItem('user') === null ? -1 : JSON.parse(localStorage.getItem('user')).groupId;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    };

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}], groupId} = filter;

        setData({...data, loading: true});

        userService.fetchUserFilter({
            startIndex: page === 0 ? page + 1 : page * pageSize + 1,
            pageSize: pageSize,
            userStatus: 'NONAPPROVED',
            groupId: groupId,
            searchText: keyword,
            sortColumn: snakeCase(convertSortColumnId(id)),
            sortOrder: desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            res.items.map(item => {
                if (item.groupName === userConstants.ORGAN_NAME_ADMINISTRATORS) {
                    item.groupName = t('TEXT_ROOT_GROUP_NAME_P');
                }
            });

            setData({
                ...data,
                loading: false,
                items: res.items,
                totalCount: res.totalCount,
                pages: Math.ceil(res.totalCount / res.pageSize)
            })
            updateCache('USER', {items: res.items, filter: filter, totalCount: res.totalCount})
        });
    };

    const onClickExport = (docType) => {
        const {sorted: [{id, desc}], keyword} = filter;

        userService.fetchUserFileDownload(docType, {
            groupType: "UNAPPROVED",
            searchText: keyword,
            sortColumn: convertSortColumnId(id),
            sortOrder: desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            let fileName = '';
            if (docType === 'EXCEL') {
                fileName = 'UserList.xls';
            }
            fileDownload(res.blob, fileName);
        }).catch(err => {
            err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
        });
    };


    const approvalPopup = (id, userId) => {
        let requestUserId = userId === undefined ? selected.current.map(s => items[s].userInfo.userId)[0] : userId;

        Promise.all([
            userService.fetchUserById(requestUserId),
            userService.fetchOrganizations()
        ]).then(res => {
            let currentOrgItem = res[1].items.filter(item => item.groupName ===
                (res[0].items.organizationName === userConstants.ORGAN_NAME_ADMINISTRATORS ? 'ROOT' : res[0].items.organizationName));
            let currentOrganizationId = currentOrgItem.length > 0 ? currentOrgItem[0].organizationId : -1;

            addPopup({
                type: commonConstants.APPROVE_USER,
                id: id,
                userItem: res[0].items,
                organizationId: currentOrganizationId,
                onClickYes: () => {
                    setFilter({...filter});
                    dispatch(menuAction.updateSubMenuCounter('USER'));
                    dispatch(menuAction.reloadGroup('USER_BY_GROUP'));
                },
                onClose: () => closePopup(id)
            });
        }).catch(error => {
            toastr.error(error.statusText);
        });
    };

    const rejectPopup = (id) => {
        const unapprovedMoveOrganization = selected.current.filter(s => items[s].unApprovedCause === "MOVE ORGANIZATION");
        const userIds = selected.current.map(s => items[s].userInfo.userId);
        const initList = () => {
            setFilter({...filter});
        }

        if (unapprovedMoveOrganization.length > 0) {
            toastr.error(t('MESSAGE_USER_CANT_REJECT_EDIT_ORGAN_P'));
            return;
        }

        addPopup({
            type: commonConstants.REJECT_UNAPPROVED,
            id: id,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            userIds : userIds,
            initList : initList,
            onClose: () => closePopup(id)
        });
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'ALL'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox id={items[row.index].userInfo.userId}
                              classname="table"
                              name="check"
                              index={row.index}
                              onChange={toggleRow}
                              ref={setCheckBoxRefs}
                    />
                )
            },
            width: 46,
            sortable: false,
            resizable: false
        },
        {
            Header: t("TABLE_TYPE_P"),
            accessor: "unApprovedCause",
            width: 250,
        },

        {
            Header: t("COM_DID_ADMIN_USER_USERID"),
            accessor: 'userInfo.userId',
            Cell: (props) => <span className={"data_name"} onClick={(e) =>
                approvalPopup('APPROVAL_USER', props.original.userInfo.userId)}>{getUnRecognizedData(props.original.userInfo.userId)}</span>,
            width: 250,
        },
        {
            Header: t("COM_TEXT_USER_NAME_P"),
            accessor: "userInfo.userName",
            width: 250,
        },
        {
            Header: t("TABLE_ORGANIZATION_P"),
            accessor: "organizationName"
        }
    ], [items]);

    const buttonDisplayStatus = checkButtonDisplayWithAuthority(userAuthority, logInGroupId, isServerAdmin);
    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'user_unapproved_tr_group');
    return (
        <div style={{width: '100%', height: '100%', display: props.currContent === 'UNAPPROVED' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"APPROVAL"} name={t("COM_BUTTON_APPROVAL")}
                                 onClick={() => approvalPopup('APPROVAL_USER')}
                                 authority={buttonDisplayStatus[userConstants.BUTTON_KEY_APPROVAL_USER]}
                                 disable={selectedCnt !== 1}/>
                    <WhiteButton id={"REJECT"} name={t("BUTTON_REJECT_P")}
                                 onClick={() => rejectPopup('REJECT_USER')}
                                 authority={buttonDisplayStatus[userConstants.BUTTON_KEY_REJECT_USER]}
                                 disable={selectedCnt !== 1}/>
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")}
                                 authority={buttonDisplayStatus[userConstants.BUTTON_KEY_CONTENT_EXPORT]}
                                 onClick={() => onClickExport('EXCEL')}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="userSearch"
                               placeholder={`${t('COM_DID_ADMIN_USER_USERID')}, ${t('COM_TEXT_USER_NAME_P')}`}
                               keyword={keyword}
                               onClickSearch={onKeywordChange}/>
                </div>
            </div>

            <div className='user_list_view'>
                <MagicInfoTable
                    data={items}
                    minRows={0}
                    page={page}
                    pages={pages}
                    loading={loading}
                    sorted={sorted}
                    showPagination={false}
                    columns={columns}
                    onSortedChange={onSortedChange}
                    className="-striped -highlight"
                    style={style}
                    getTrGroupProps={getTrGroupPropsType2}
                />
                <Pagination totalCount={totalCount}
                            page={page}
                            defaultPageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            divide={props.divide}/>
                </div>
        </div>
    );
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(Unapproved);

