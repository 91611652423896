import Moment from "moment";

export function dateToString(date) {
    if (date) {
        let mm = '00' + (date.getMonth()+1);
        let dd = '00' + date.getDate();
        return date.getFullYear() + "-" + mm.slice(-2) + "-" + dd.slice(-2);
    } else {
        return '';
    }
};

export function addDashToString(dateString) {
    if(dateString == '29991231' || dateString === undefined) {
        return "-";
    }else {
        return dateString.slice(0,4) + "-" + dateString.slice(4,6) + "-" + dateString.slice(6);
    }
};
export function renderExpireDateInFormat(dateString,desiredFormat)
{
    let expireDate=addDashToString(dateString);
    if (expireDate=='-')
    {
        return expireDate;
    }
    return Moment(expireDate).format(desiredFormat);
    
}

export function secondsToString(seconds, formatter) {
    return Moment().startOf('day').seconds(seconds).format(formatter);
}

export function stringToSeconds(time, formatter) {
    let date = Moment(time, formatter);
    return date.hour() * 60 * 60 + date.minutes() * 60 + date.seconds();
}