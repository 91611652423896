import React from 'react';
import {RemoveButton} from "../button/RemoveButton";
import classNames from 'classnames';
import './SelectedDevices.css'

const SelectedDevices = ({groups = undefined, deviceGroupIds, deviceGroupNames, removeGroup, disabled = false}) => {

    return (
        <ul style={{height:50,width:'90%',border: '1px solid #e7e7e7',borderRadius: 3,padding:10,marginTop:10,overflowY:'auto'}} className={classNames('selected_device_wrap',{'disabled' : disabled === true})} id="selectedDeviceLists">
            {
                groups !== undefined && groups.length > 0 &&
                groups.map(
                    (group, index) =>
                        <li key={index} style={{display: 'flex'}}>
                            <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',width:'260px',overflow:'hidden'}} title={group.groupName}>
                                {group.groupName}
                            </div>
                            <div>
                                {
                                    !disabled && removeGroup !== undefined
                                    && <RemoveButton removeGroup={()=>removeGroup(group.groupId)} />
                                }

                            </div>
                        </li>
                )
            }
            {
                deviceGroupIds !== undefined && deviceGroupIds.length > 0
                && deviceGroupNames !== undefined && deviceGroupNames.length > 0
                && deviceGroupIds.map(
                    (groupId, index) =>
                        <li key={index} style={{display: 'flex'}}>
                            <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',width:'90%',overflow:'hidden'}} title={deviceGroupNames[index]}>
                                {deviceGroupNames[index]}
                            </div>
                            <div>
                                {
                                    !disabled && removeGroup !== undefined
                                    && <RemoveButton removeGroup={()=>removeGroup(groupId)} />
                                }
                            </div>
                        </li>
                )
            }
        </ul>
    )
}
export default SelectedDevices;