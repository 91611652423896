import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {menuAction, popupAction, rulesetAction} from "../../../actions";
import {useTranslation} from "react-i18next";
import SelectSupportedDevice from "../../utils/SelectSupportedDevice";
import MISDialog from "../MISDialog";
import {getMixString} from "../../../language/languageUtils";
import {toastr} from 'helper/toastrIntercept';
import './CreateRulesetPopup.css';
import TooltipIcon from '../../icon/TooltipIcon';

const CreateRulesetPopup = ({id}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [selectedSupportDevice, setSelectedSupportDevice] = useState({
        devices: [],
        selected: []
    });

    const selectedDevice = (devices, selected) => {
        setSelectedSupportDevice({devices: devices, selected: selected});
    };

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const createRuleset = () => {
        const {devices, selected} = selectedSupportDevice;

        if ((devices !== undefined && devices.length > 0) && (selected !== undefined && selected.length > 0)) {
            const maxPriorityIndex = Math.max(...selected);
            const device = devices[maxPriorityIndex];
            const tab = {id: 'NEW_RULESET', title: getMixString(['MIS_SID_MIX_NEW', 'MIS_SID_RULESET']), active: true, close: true};
            new Promise((resolve) => {
                dispatch(rulesetAction.initNewRuleset(device))
                resolve()
            }).then(
                dispatch(menuAction.addTab(tab))
            ).then(
                dispatch(menuAction.loadContent('NEW_RULESET'))
            ).then(
                dispatch(popupAction.closePopup(id))
            );
        }

        else {
            toastr.error(t("MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P"))
        }
    }

    const dialogProps ={title: getMixString(['MIS_SID_MIX_NEW', 'MIS_SID_RULESET']), closeOnEscape : true, width :782, height :100 ,position: {x: -300, y: -400} , modal:true, onClose:() => closePopup()};

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("COM_TEXT_CREATE_P"), id: 'createNewRulesetBtn', onClick: () => createRuleset()},
                    {title: t("BUTTON_CANCEL_P"), id: 'cancelNewRulestBtn', onClick: ()=> closePopup()}
                ]
            }}>

            <div className="create_ruleset_popup_wrap">
                <div className="quest_img_noti">
                    {t("COM_MAPP_SID_SUPPORTED_DEVICES")}
                    <TooltipIcon message={t("MIS_SID_20_WHEN_YOU_SELECT_A_PLAYER_ALL_HIGHER_VERSIONS_ARE_AUTOMATICALLY_SELECTED")}/>
                </div>
                <div className="device_type_btn mt15" style={{display:'inline-block'}}>
                    <SelectSupportedDevice
                        selectedDevice={selectedDevice}
                        type={"ruleset"}
                    />
                </div>
                <span className="error_txt2" id="supportedDeviceErrorMsg"></span>
            </div>

        </MISDialog>
    )
}

export default CreateRulesetPopup;