import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import EdgeDropDown from "./EdgeDropDown";
import Checkbox from "../../../checkbox/Checkbox";
import NumberInput from "../../../input/NumberInput";
import * as moment from "moment";

const EditAutoStorageManagement = ({edgeInfo, setEdgeInfo, overwriteInfo}) => {
    const {t} = useTranslation();
    console.log('auto storage info=>',edgeInfo);
    const {
        hostName,
        httpPort,
        httpsPort,
        storageLimitByGB,
        storageManagePolicy,
    } = edgeInfo;

    const storageOptions = [t('MIS_SID_MIX_MONTHS').replace('<<A>>','1 '),
        t('MIS_SID_MIX_MONTHS').replace('<<A>>','6 '),
        t('MIS_SID_ONE_YEAR'),
        t('MIS_SID_MIX_YEARS').replace('<<A>>','2 ')];
    const storageOptionsEnum = ['ONE_MONTH','SIX_MONTH','ONE_YEAR','TWO_YEAR'];

    const [storageSpaceChkBoxDisable, setStorageSpaceChkBoxDisable] = useState(false);
    const [storageDurationChkBoxDisable, setStorageDurationChkBoxDisable] = useState(false);

    useEffect(() => {
        setStorageSpaceChkBoxDisable(edgeInfo.storageLimitByGB  === '0' || edgeInfo.storageLimitByGB  === 0);
        console.log('edgeInfo.storageLimitByGB : ' + edgeInfo.storageLimitByGB);
        setStorageDurationChkBoxDisable(edgeInfo.storageManagePolicy === 'ALL');
    }, []);

    const translateValue = (valueToChange, from, to) =>{
        if (valueToChange === 'ALL') {
            return t('COM_SID_ALL');
        }
        let indexOf = from.indexOf(valueToChange);
        return to[indexOf];
    };

    const setInfo = (key, value) => {
        setEdgeInfo(key, value);
    };

    const onStorageDurationChecked = (e) => {
        if(!e.target.checked){
            setEdgeInfo("storageManagePolicy","ALL");
            setStorageDurationChkBoxDisable(true);
        }else{
            setEdgeInfo("storageManagePolicy","ONE_MONTH");
            setStorageDurationChkBoxDisable(false);
        }
    };

    const onStorageLimitChecked = (e) => {
        if(!e.target.checked){
            setEdgeInfo("storageLimitByGB","0");
            setStorageSpaceChkBoxDisable(true);
        }else{
            setEdgeInfo("storageLimitByGB","10");
            setStorageSpaceChkBoxDisable(false);
        }
    };

    const updateEdgeInfo = (e, v) => {
        let key = e.target.name;
        let value;
        if(key === ''){
            key = e.target.getAttribute('data-name');
            value = v?parseInt(v):e.target.value;
            value = isNaN(value)?'':value;
        }

        if(e.target.name === 'storageManagePolicy'){
            value = translateValue(e.target.value, storageOptions, storageOptionsEnum);
        }

        setEdgeInfo(key,value);
    };

    const renderDate = (storageOptions) => {
        let fromDate;
        if (storageOptions === 'ONE_MONTH') {
            fromDate = moment().subtract(1,'months').calendar();
        } else if (storageOptions === 'SIX_MONTH') {
            fromDate = moment().subtract(6,'months').calendar();
        } else if (storageOptions === 'ONE_YEAR') {
            fromDate = moment().subtract(1,'years').calendar();
        } else if (storageOptions === 'TWO_YEAR') {
            fromDate = moment().subtract(2,'years').calendar();
        } else {
            fromDate = new Date(0);
        }

        return t('MIS_SID_MIX_FROM').replace('<<A>>', moment(fromDate).format('YYYY-MM-DD HH:mm'));
    };

    return(
        <div className='detail_view'>
            <table className='edge_table'>
                <colgroup>
                    <col width='200px'/>
                </colgroup>
                <tbody className='tbody'>
                <tr>
                    <th>
                        <Checkbox
                            id={'storageLimit'}
                            className="table"
                            propertyName={'storageLimit'}
                            name={t('MIS_SID_STORAGE_SPACE')}
                            onChange={(e)=>onStorageLimitChecked(e)}
                            checked={storageSpaceChkBoxDisable === false}
                            disabled={!edgeInfo.master}
                        />
                    </th>
                    <td>
                        <NumberInput propertyName={'storageLimitByGB'} width={203} min={1} max={1024*1024}
                                     value={edgeInfo.storageLimitByGB}
                                     disabled={!edgeInfo.master || storageSpaceChkBoxDisable}
                                     onChange={(e, value)=>{updateEdgeInfo(e, value)}} />
                        <span style={{margin:"5px 0 0 12px"}}>GB</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <Checkbox
                            id={'storageDuration'}
                            className="table"
                            propertyName={'storageDuration'}
                            name={t('MIS_SID_STORAGE_DURATION')}
                            onChange={(e)=>onStorageDurationChecked(e)}
                            checked={edgeInfo.storageManagePolicy !== 'ALL'}
                            disabled={!edgeInfo.master}
                        />
                    </th>
                    <td>
                        <EdgeDropDown
                            width={203}
                            height={storageOptions.length}
                            disabled={!edgeInfo.master || storageDurationChkBoxDisable}
                            name="storageManagePolicy"
                            setInfo={setInfo}
                            display={translateValue(edgeInfo.storageManagePolicy,storageOptionsEnum,storageOptions)}
                            options={storageOptions}
                            onSelect={updateEdgeInfo}/>
                        <span style={{margin:'5px 0 0 12px'}}>
                            {renderDate(edgeInfo.storageManagePolicy)}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
};

export default EditAutoStorageManagement;