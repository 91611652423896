
export function authHeader(type) {

    if (type != null && (type === 'POST' || type === 'PUT')) {
        return {'Content-Type': 'application/json', 'accept': 'application/json'};
    }else if(type != null && type == 'FILE'){
        return {'Content-Type': 'multipart/form-data'};
    } else {
        return {'accept': 'application/json'};
    }

    return {}
}
