import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from "react";
import {isNil, cloneDeep} from 'lodash';
import MISDialog from "../../MISDialog";
import {toastr} from 'helper/toastrIntercept';
import { settingService } from "services";
import NumberInput from '../../../input/NumberInput';
import Select from "../../../selectbox/Select";
import SearchBar from "../../../search/SearchBar";
import Checkbox from "../../../checkbox/Checkbox";
import ToggleSwitch from "../../../toggleswitch/ToggleSwitch";
import LicenseSummary from "containers/setting/LicenseSummary";
import TooltipIcon from "components/icon/TooltipIcon";

const EditLicenseAssignPopup = ({onSave, onClose, title, licenseTypes}) => {
    const {t} = useTranslation();

    const [orgLicenseList, setOrgLicenseList] = useState([]);
    const [currentProductCode, setCurrentProductCode] = useState();
    const [currentLicenseInfo, setCurrentLicenseInfo] = useState({});
    const [initialItems, setInitialItems] = useState();

    const handleChangeAssigned = (e) => {
        const value = e.target.checked;
        const orgId = parseInt(e.target.id.split('_')[1]);

        setOrgLicenseList(orgLicenseList.map(orgLic => {
            if(orgLic.organizationId === orgId){
                orgLic.changed = true;
                orgLic.orgLicenseEntities.map(entity => {
                    if(entity.productCode === currentProductCode){
                        entity.assigned = value;
                        if(entity.assigned === false)
                            entity.maxLicenseCount = 0;
                    }
                    return entity;
                })
            }
            return orgLic;
        }));
    };

    const handleChangeMaxCount = (e, orgId, value) => {
        setOrgLicenseList(orgLicenseList.map(orgLic => {
            if(orgLic.organizationId === orgId){
                orgLic.changed = true;
                orgLic.orgLicenseEntities.map(entity => {
                    if(entity.productCode === currentProductCode){
                        entity.maxLicenseCount = value;
                    }
                    return entity;
                })
            }
            return orgLic;
        }));
    };

    const checkPreValidation = orgLicenseList => {
        let ret = true;
        orgLicenseList.forEach(org => {
            let total = 0;
            org.orgLicenseEntities.forEach(lic => {
                if(lic.productCode !== currentProductCode)
                    return;
                
                if(lic.assigned && lic.maxLicenseCount < lic.usedLicenseCount){
                    toastr.error(t('MIS_SID_CBFEB_ENTERED_QUANTITY_MUST_EQUAL_OR_GREATER_THAN_NUMBER_OF_DEVICES'));
                    ret = false;
                }
                total += lic.maxLicenseCount;
            });
            if(total > currentLicenseInfo.totalCount){
                toastr.error(t('MIS_SID_CBFEB_NOT_ENOUGH_LICENSES_ALLOCATE'));
                ret = false;
            }
        });
        if(orgLicenseList.filter(org => { return org.changed; }).length <= 0){
            toastr.warning(t('MIS_SID_NO_CHANGES_HAVE_BEEN_MADE'));
            ret = false;
        }

        return ret;
    }

    const onSaveOrganizationLicense = () => {
        let filteredOrgLicenseList = orgLicenseList.filter(org => {
            return org.changed;
        });
        filteredOrgLicenseList.map(org => {
            org.orgLicenseEntities = org.orgLicenseEntities.filter(entity => {
                return entity.productCode === currentProductCode;
            });
        });

        if(!checkPreValidation(filteredOrgLicenseList))
            return;

        settingService.editOrganizationLicense(orgLicenseList.filter(org => { return org.changed; })).then(res => {
            if(!res || res.errorCode !== '200'){
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                return;
            } else {
                toastr.success(t('COM_MIV_SCHEDULE_SUCCESS_SAVE'));
                onSave && onSave();
                onClose && onClose();
            }
        }).catch((err) => {
            switch(err.errorCode){
                case '400828':
                    toastr.error(t('MIS_SID_CBFEB_NOT_ENOUGH_UNALLOCATED_LICENSES'));
                    break;
                default:
                    toastr.error(err.errorMessage);
            }
        });        
    };

    const onSelectLicenseType = (e, code) => {
        setCurrentProductCode(code);
    };

    const loadOrganizations = orgName => {
        settingService.fetchOrganizationLicense().then(res => {
            if(!res) {
                toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                return;
            } else if(res.errorCode !== '200'){
                toastr.error(res.errorMessage);
                return;
            }
            const {items} = res;
            
            if(!isNil(orgName)){
                const filteredItems = items.filter(item => {
                    if(!isNil(item.organizationName)) 
                        return (item.organizationName.toLowerCase().indexOf(orgName.toLowerCase()) >= 0);
                    else 
                        return false;
                });
                setInitialItems(filteredItems);
                setOrgLicenseList(cloneDeep(filteredItems));
            } else {
                setInitialItems(items);
                setOrgLicenseList(cloneDeep(items));
            }
        });
    }

    const onClickReset = () => {
        setOrgLicenseList(cloneDeep(initialItems));
    }

    useEffect(()=> {
        loadOrganizations();
        setCurrentProductCode(licenseTypes[0].productCode);
    }, []);

    useEffect(()=> {
        licenseTypes && (() => {
            let count = 0, name;
            licenseTypes.forEach(type => {
                if(type.productCode === currentProductCode){
                    count = type.totalCount;
                    name = type.productName;

                    setCurrentLicenseInfo(type);
                }                    
            })
        })()
    }, [currentProductCode]);

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    isDraggable: true,
                    width: 630,
                    height: 390,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: [
                        {
                            id: 'ORGANIZATION_LICENSE_RESET',
                            title: t('COM_DID_LFD_RESET'),
                            onClick: onClickReset
                        }                        
                    ],                    
                    rightButtons: [
                        {
                            id: 'ORGANIZATION_LICENSE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onSaveOrganizationLicense
                        },
                        {
                            id: 'ORGANIZATION_LICENSE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                <div>
                    <div className={'mb15'} style={{height:40, borderBottom:'1px solid #f6f6f6'}}>
                        <span style={{float:'left'}}>
                            <span style={{marginRight:50}}>{t('COM_MIS_TEXT_PRODUCT_NAME_P')}</span>
                            <span>
                                <Select span1Style={{width: 270, display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                        width={295} optionStyle={{zIndex: 2}} selects={licenseTypes.map(license => {return {title: license.productName, value: license.productCode}})} 
                                        value={currentProductCode} onChange={onSelectLicenseType} />
                            </span>
                        </span>
                    </div>
                </div>
                <div>
                    <div className={'mb15'} style={{height:28, lineHeight:2.2}}>
                        <span style={{float:'left'}}>
                            <span style={{marginRight:10}}>{t('COM_TV_SID_AVAILABLE_KR_MIS20')} |</span>
                            <span>{currentLicenseInfo.available}</span>
                            <TooltipIcon message={<LicenseSummary licenseType={currentLicenseInfo.productName} totalCount={currentLicenseInfo.totalCount}/>} style={{width:370, height:240}}/>
                        </span>
                        <span style={{float:'right'}}>
                            <SearchBar id="logSearch" placeholder={t("SETUP_NEW_STRING29_P")} onClickSearch={loadOrganizations} onBlurSearch={loadOrganizations} width={150}/>
                        </span>
                    </div>
                </div>

                <div className="server_setting none_scrolling check_list">
                    <table style={{width:'100%'}}>
                        <colgroup>
                            <col width="182px"/>
                            <col width="130px"/>
                            <col width="130px"/>
                            <col width=""/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{t('SETUP_NEW_STRING29_P')}</th>
                                <th>{t('COM_TV_SID_DEVICES_KR_MIS20')}</th>
                                <th>{t('MIS_SID_CBFEB_ALLOCATION')}</th>
                                <th>{t('TEXT_VENDING_STOCK_MANAGEMENT_QUANTITY_P')}</th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{height:'300px', overflowY:'auto', overflowX:'hidden'}}>
                        <table style={{width:'100%'}}>
                            <colgroup>
                                <col width="182px"/>
                                <col width="130px"/>
                                <col width="130px"/>
                                <col width=""/>
                            </colgroup>                            
                            <tbody>
                            {
                                (isNil(orgLicenseList) || orgLicenseList.length <= 0) && 
                                <tr>
                                    <td colspan='4' style={{textAlign:'center'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</td> 
                                </tr>
                            }
                            {
                                !isNil(orgLicenseList) && !isNil(initialItems) && orgLicenseList.length === initialItems.length && orgLicenseList.length > 0 && orgLicenseList.map((orgLic, i) => {
                                    const licenseEntity = (orgLic.orgLicenseEntities.filter((entity) => {
                                        return entity.productCode === currentProductCode;
                                    }))[0];

                                    const initialEntity = initialItems[i].orgLicenseEntities.filter(e => {
                                        return e.productCode === currentProductCode;
                                    })[0];

                                    if(!licenseEntity || licenseEntity.length <= 0)
                                        return;
                                    else
                                        return (
                                            <tr>
                                                <td><p style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'155px'}} title={orgLic.organizationName}>{orgLic.organizationName}</p></td>
                                                <td>{licenseEntity.usedLicenseCount}</td>
                                                <td>
                                                    <ToggleSwitch id={"assigned_"+orgLic.organizationId} name={licenseEntity.assigned?t('MIS_SID_CBFEB_ALLOCATED'):t('MIS_SID_CBFEB_UNALLOCATED')} onChange={handleChangeAssigned} checked={licenseEntity.assigned} classname={licenseEntity.assigned !== initialEntity.assigned ? 'warning':undefined} style={{color: licenseEntity.assigned !== initialEntity.assigned ? 'red !important' : undefined}}/>
                                                </td>
                                                <td>
                                                    {
                                                        licenseEntity.assigned &&
                                                        <NumberInput inputStyle={{color: licenseEntity.maxLicenseCount !== initialEntity.maxLicenseCount ? 'red' : undefined}}
                                                            width={100} value={licenseEntity.assigned ? licenseEntity.maxLicenseCount : 0} min={0} max={1000000} disabled={!licenseEntity.assigned}
                                                            onChange={(e, value) => handleChangeMaxCount(e, orgLic.organizationId, value)} forceInt />
                                                    }
                                                </td>
                                            </tr>
                                        );
                                })                                
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
}

export default EditLicenseAssignPopup;