import React from 'react';
import './CustomizeContentPopup.css';
import MISDialog from "../../MISDialog";
import {withTranslation} from "react-i18next";
import {isNil, snakeCase} from "lodash";
import {toastr} from 'helper/toastrIntercept';
import {CustomizeContents} from "./CustomizeContents";

class CustomizeContentPopup extends React.Component {
    constructor(props) {
        super(props);

        let showContent = true, contentFilter = {
            startIndex: 1,
            pageSize: 30,
            softwareType: props.type === '03' ? 'LOGO' : 'DEFAULT',
            sortColumn: snakeCase('createDate').toUpperCase(),
            sortOrder: 'DESC',
            render: false
        };
        this.state = {
            loading: true,
            currentTab:  'CONTENT',
            contentFilter: contentFilter
        }
    }


    componentDidMount() {
        const type = this.props.type || 'CONTENT';
        //this.activePopupTab(type);
    }

    onClickSubTab = (id) => {
        this.setState({subTab: id});
    };

    onSelect = (id, name, filePath, fileName, fileSize) => {
        this.setState({
            selectedId: id,
            selectedName: name,
            selectedThumbFileId: filePath,
            selectedFileName: fileName,
            selectedFileSize: fileSize
        })
    }

    onSelectContent = () => {
        const {t, selectContent, closeContentPopup} = this.props;
        if (isNil(this.state.selectedId) || isNil(this.state.selectedName) || isNil(this.state.selectedThumbFileId) ||
            isNil(this.state.selectedFileName) || isNil(this.state.selectedFileSize)
        ) {
            toastr.error(t("COM_TEXT_SELECT_CONTENT_P"));
        } else {
            selectContent(this.state.selectedId, this.state.selectedName, this.state.selectedThumbFileId, this.state.selectedFileName, this.state.selectedFileSize);
        }
    }


    render() {
        const {t, closeContentPopup, selectContent, type} = this.props;
        const dialogProps ={title: t("COM_TEXT_SELECT_CONTENT_P"), closeOnEscape : true, width :620, height :480, modal:true, onClose: ()=>closeContentPopup()};
        const buttons = {
            rightButtons: [
                {id: 'SELECT_CONTENT', title: t("TEXT_SELECT_P"), onClick: ()=>this.onSelectContent()},
                {id: 'CLOSE_CONTENT_POPUP', title: t("COM_BUTTON_CLOSE_P"), onClick: ()=>closeContentPopup()}]}

        return (
            <div className="customize_content_dialog">
                <MISDialog
                    {...dialogProps}
                    dialog = {dialogProps}
                    buttons = {buttons}
                >
                    <CustomizeContents mode={this.props.mode}
                                       filter={this.state.contentFilter}
                                       onSelect={(id, name, filePath, fileName, fileSize)=>this.onSelect(id, name, filePath, fileName, fileSize)}
                                       selectedId={this.state.selectedId}
                                       type={type}
                    />

                </MISDialog>
            </div>
        )
    }

}
export default withTranslation()(CustomizeContentPopup);