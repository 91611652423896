import React from 'react';
import {PulseLoader} from "react-spinners";


export const Loading = ({isLoading, size}) => {

    const loadingSize  = size || 15;
    return (
        <PulseLoader
        size={loadingSize}
        color={'rgb(54, 215, 183)'}
        loading={isLoading}/>
    );
}
