import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/';

const loggerMiddleware = createLogger();

let middleware = [thunkMiddleware];

const configure = () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    middleware.push(loggerMiddleware);

    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(
                ...middleware
            )
        )
    );

    return store;
}

export default configure;
