import {
    authHeader,
    createParams,
    handleBinDownloadResponse,
    handleResponse,
    history,
    objToQueryString
} from '../helper';
import axios from "axios";
import {fetchForDownload, fetchWithToken as fetch} from '../helper/fetchIntercept.js';
import fileDownload from 'js-file-download';
import {merge} from "lodash";
import {setExclusive} from "../helper/fetchUtils";
import {isEnableMfa} from "../containers/common/MfaUtil";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const settingService = {

    //DATALINK SERVER
    fetchDataLinks,
    editDataLink,
    checkDataLinkName,
    deleteDataLink,
    addDataLink,
    saveDatalinkTables,
    editDataLinkByPass,
    fetchDatalinkTables,
    updateDatalinkTables,
    fetchSelectedDatalinkTables,

    //REMOTE CONTROL SERVER
    addRemoteControls,
    editRemoteControls,
    deleteRemoteControls,
    fetchRemoteControls,
    checkRemoteControlName,
    getRemoteStatus,

    //EDGE SERVER
    fetchSlaveEdgeServers,
    fetchMasterEdgeServers,
    fetchOneEdge,
    fetchEdgeDashboardInfo,
    editEdge,
    deleteEdge,
    checkEdgeName,

    //LICENSE
    fetchLicenses,
    checkLicenseKey,
    getHWUniqueKey,
    activationOnline,
    activationOffline,
    checkAdditionalActivation,
    additionalActivationOnline,
    additionalActivationOffline,
    returnLicense,
    freeActivationOnline,
    freeActivationOffline,
    fetchAvailableFreeLicense,
    fetchLicenseHistory,
    fetchOrganizationLicense,
    editOrganizationLicense,
    //Tag
    fetchContentTagConditions,
    fetchTagInfoWithIdAndTagValues,
    fetchTagAndContentsWidthIdAndTagValues,
    fetchTags,
    addTag,
    getTag,
    editTag,
    addTagCondition,
    deleteTagCondition,
    editTagCondition,
    deleteTag,
    fetchTagMappingInfo,

    // insight index
    fetchInsightIndexes,
    fetchInsightIndexOrgan,
    deleteInsightIndexes,
    updateActivateInsightIndexes,
    fetchInsightIndex,
    addInsightIndex,
    updateInsightIndex,
    uploadLoginPageImageFile,

    //MY + SETTING
    fetchMyAccount,
    fetchTagOrgan,
    fetchConfirmPassword,
    fetchCommonConfigInfo,
    fetchReconfirm,
    updateMyAccount,
    withdrawMyAccount,
    fetchServerSettings,
    fetchOrganSettings,
    updateServerSettings,
    updateOrganSettings,
    fetchUsersForNotifications,
    updateExternalServerNotifications,
    updateNotificationsForUsers,
    fetchNotificationTypes,
    fetchSystemInformation,
    fetchDeviceSummary,
    exportDeviceSummary,
    fetchServerLogList,
    downloadLogFile,
    updateAlarmDeviceGroups,
    stopLogCollect,
    fetchDatabaseInfo,
    checkLdapConnection,
    fetchRequestedQueue,
    fetchKeepalive,
    fetchPrivacyPolicyList,
    fetchMfaInfo,
    fetchCommonSettings,
    fetchContentDownloadThrottlingInfo,
    fetchIcpInfo,

    //CATEGORY
    addCategory,
    deleteCategory,
    renameCategory,

    //LOG MANAGMENT
    fetchLog,
    exportServerLog,
    getAlarmMailHistoryInfo,
    fetchAlarmMailHistory,
    exportAlarmMailHistory,
    downloadMailAttachedFile,

    // EXTERNAL LINK
    fetchExternalLink,
    addExternalLink,
    deleteExternalLink,

    //E2E
    fetchE2ELicenses,
    fetchE2ELicenseHistory,
    returnE2ELicense,
    swapE2ELicense,
    fetchCompanyInfo,
    updateCompanyInfo,


    //SLM Direct
    fetchSoldToCodeList,

    //E2E PBP
    fetchBrandCodeList
}

function fetchCommonSettings() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/common-settings', requestOptions).then(handleResponse);
}

function fetchRequestedQueue(deviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/summary/' + deviceId + '/queue', requestOptions).then(handleResponse);
}

function fetchKeepalive(deviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/summary/' + deviceId + '/keepalive', requestOptions).then(handleResponse);
}

function checkLdapConnection(resource) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(resource)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/servers/ldap-connection-check', requestOptions).then(handleResponse);
}

function fetchDatabaseInfo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/database-info', requestOptions).then(handleResponse);
}

function stopLogCollect() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST')
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/log-collect/stop`, requestOptions).then(handleResponse);
}

function updateAlarmDeviceGroups(orgId, values) {
    const body = createParams(values);

    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/notifications/' + orgId + '/device-groups', requestOptions).then(handleResponse);
}

function downloadLogFile(fileName){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };
    return fetchForDownload(backendHost+'/restapi/v2.0/ems/settings/logs/' + fileName + '/download', requestOptions)
            .then(res => res.blob())
            .then(res => {
                fileDownload(res, fileName+".zip");
            }).catch(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        if (response.status === 401) {
                            localStorage.removeItem('user');
                            history.push('/login');
                        }

                        return Promise.reject(data);
                    }).catch(err => { //In case of json() fail.
                        if(response.status === 401){
                            localStorage.removeItem('user');
                            history.push('/login');
                        }
                        return Promise.reject(response);
                    });
                }
            });
}

function fetchServerLogList() {
    const startIndex = 0;
    const pageSize = 13;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/logs/' + startIndex + '/' + pageSize, requestOptions).then(handleResponse);
}

function fetchDeviceSummary() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/summary/devices', requestOptions).then(handleResponse);
}

function exportDeviceSummary(){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };
    return fetchForDownload(backendHost+'/restapi/v2.0/ems/settings/summary/devices/export', requestOptions)
            .then(res=>res.blob())
            .then(res => {
                fileDownload(res, "ServerDeviceReport.xls");
            }).catch(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        if (response.status === 401) {
                            localStorage.removeItem('user');
                            history.push('/login');
                        }

                        return Promise.reject(data);
                    }).catch(err => { //In case of json() fail.
                        if(response.status === 401){
                            localStorage.removeItem('user');
                            history.push('/login');
                        }
                        return Promise.reject(response);
                    });
                }
            });
}

function fetchSystemInformation() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/summary/systems', requestOptions).then(handleResponse);
}

function fetchNotificationTypes(orgId, category){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/notifications/' + orgId + '/' + category, requestOptions).then(handleResponse);
}

function updateNotificationsForUsers(values, orgId){
    const body = createParams(values);

    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/notifications/'+ orgId, requestOptions).then(handleResponse);
}

function updateExternalServerNotifications(values, orgId, notiType){
    const body = createParams(values);

    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/notifications/external-server/'+ orgId + '/' + notiType, requestOptions).then(handleResponse);
}

function fetchUsersForNotifications(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/notifications/users/' + orgId, requestOptions).then(handleResponse);
}

function updateServerSettings(values){
    const body = createParams(values);

    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/servers/configurations', requestOptions).then(handleResponse);
}

function updateOrganSettings(orgId, values){
    const body = createParams(values);

    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    };
return fetch(backendHost+'/restapi/v2.0/ems/settings/servers/configurations/' + orgId, requestOptions).then(handleResponse);
}

function fetchCommonConfigInfo(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/servers/common-config', requestOptions).then(handleResponse);
}

function fetchServerSettings(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/servers/configurations', requestOptions).then(handleResponse);
}

function fetchOrganSettings(orgId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/servers/configurations/' + orgId, requestOptions).then(handleResponse);
}

function withdrawMyAccount(){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/my-account/withdraw', requestOptions).then(handleResponse);
}

function updateMyAccount(values){
    const body = createParams(values);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/my-account', requestOptions).then(handleResponse);
}

function fetchMyAccount(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/my-account', requestOptions).then(handleResponse);

}

function fetchConfirmPassword(password) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let obj ={
        password : password
    };
    let params = objToQueryString(obj);
    return fetch(backendHost+'/restapi/v2.0/ems/settings/my-account/current-password-check'+ params, requestOptions).then(handleResponse);
}


function fetchReconfirm(password) {

    let obj = {};
    let user = JSON.parse(localStorage.getItem('user'));

    if(isEnableMfa()){
        obj = {
            totp : password,
            token : user.token,
            refreshToken : user.refreshToken
        };
    }else{
        obj = {
            password : password,
            token : user.token,
            refreshToken : user.refreshToken
        };
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: createParams(obj)
    };

    return fetch(backendHost+'/restapi/v2.0/auth/reconfirm', requestOptions).then(handleResponse);
}

function fetchDataLinks(filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const params = objToQueryString({
        pageSize : filter.pageSize || 15,
        startIndex : filter.startIndex || 1,
        sortColumn : filter.sortColumn,
        sortOrder : filter.sortOrder
    });
    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-servers/datalink-servers' + params, requestOptions).then(handleResponse);
}

function editDataLink(state) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(state)
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/datalink-servers`, requestOptions).then(handleResponse);
}
function checkDataLinkName(datalinkName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/datalink-servers/${datalinkName}/name-check`, requestOptions).then(handleResponse);
}
function deleteDataLink(serverName) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/datalink-servers/${serverName}`,requestOptions).then(handleResponse);
}
function addDataLink(state) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(state)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-servers/datalink-servers', requestOptions).then(handleResponse);
}

function editDataLinkByPass(serverName, state){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(state)
    };
    return fetch(backendHost + `/restapi/v2.0/ems/settings/external-servers/datalink-servers/bypass`, requestOptions).then(handleResponse);
}

function fetchDatalinkTables(serverName){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost + `/restapi/v2.0/ems/settings/external-servers/datalink-servers/${serverName}/table`, requestOptions).then(handleResponse);
}

function updateDatalinkTables(serverName){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost + `/restapi/v2.0/ems/settings/external-servers/datalink-servers/${serverName}/table-refresh`, requestOptions).then(handleResponse);
}

function saveDatalinkTables(tables, serverName){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(tables)
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/datalink-servers/${serverName}/table`, requestOptions).then(handleResponse);
}

function fetchSelectedDatalinkTables(filter, serverName){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(filter)
    };
    return fetch(backendHost + `/restapi/v2.0/ems/settings/external-servers/datalink-servers/${serverName}/saved-table`, requestOptions).then(handleResponse);
}



function addRemoteControls(state) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(state)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-servers/remote-control-servers', requestOptions).then(handleResponse);
}

function deleteRemoteControls(serverName){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/remote-control-servers/${serverName}`, requestOptions).then(handleResponse);
}

function editRemoteControls(hostName, state){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(state)
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/remote-control-servers/${hostName}`, requestOptions).then(handleResponse);
}

function fetchRemoteControls(filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        //body: JSON.stringify(filter),
    };
    const params = objToQueryString({
        pageSize : filter.pageSize || 15,
        startIndex : filter.startIndex || 1,
        sortColumn : filter.sortColumn,
        sortOrder : filter.sortOrder
    });
    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-servers/remote-control-servers' + params, requestOptions).then(handleResponse);
}

function getRemoteStatus(serverName){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+`/restapi/v2.0/ems/settings/external-servers/remote-control-servers/${serverName}/status`, requestOptions).then(handleResponse);
}

function checkRemoteControlName(serverName){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost + `/restapi/v2.0/ems/settings/external-servers/remote-control-servers/${serverName}/name-check`, requestOptions).then(handleResponse);
}

function fetchSlaveEdgeServers(connectedMasterUuid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(backendHost+`/restapi/v2.0/edge/slave-edge-servers/${connectedMasterUuid}`, requestOptions).then(handleResponse);
}

function fetchMasterEdgeServers(filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const params = objToQueryString({
        pageSize : filter.pageSize || 30,
        startIndex : filter.startIndex || 1,
        sortColumn : filter.sortColumn,
        sortOrder : filter.sortOrder
    });
    return fetch(backendHost + '/restapi/v2.0/edge/master-edge-servers' + params, requestOptions).then(handleResponse);
}

function fetchEdgeDashboardInfo(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/edge/token', requestOptions).then(handleResponse);
}

function fetchOneEdge(hostName){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+`/restapi/v2.0/edge/edge-server/${hostName}`, requestOptions).then(handleResponse);
}

function editEdge(requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(requestBody)
    };
    return fetch(backendHost + '/restapi/v2.0/edge/edge-server', requestOptions).then(handleResponse);
}

function deleteEdge(uuid){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    };
    return fetch(backendHost + `/restapi/v2.0/edge/edge-server/${uuid}`, requestOptions).then(handleResponse);
}

function checkEdgeName(serverName){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost + `/restapi/v2.0/edge/server-name/${serverName}`, requestOptions).then(handleResponse)
}

function fetchLicenses(state) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses', requestOptions).then(handleResponse);
}

function checkLicenseKey(licenseKey) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/'+licenseKey+'/validation-check', requestOptions).then(handleResponse);
}

function getHWUniqueKey() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/HWUniqueKey', requestOptions).then(handleResponse);
}

function activationOnline(licenseKey, company) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(company)
    }

    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/'+licenseKey+'/activations/on-line', requestOptions).then(handleResponse);
}


const retrieveToken = () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.token;
    } catch(e) {
        console.log('retrieveToken',e);
    }
    return null;
}

function activationOffline(licenseKey, formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('FILE')
    }
    const token = retrieveToken();
    let optionsWithToken = requestOptions;
    if (token !== null) {
        optionsWithToken = merge({}, requestOptions, {
            headers: {
                api_key: token
            }
        })
    }
    return axios.post(backendHost + '/restapi/v2.0/ems/settings/licenses/' + licenseKey + '/activations/off-line', formData, optionsWithToken)
        .then(response => {
            return response.data;
        }).catch(res => {
            if (res.status !== 200) {
                if (res.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(res.response.data);
            }
        });
}

function checkAdditionalActivation(licenseKey){
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }

    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/'+licenseKey+'/additional-activations/validation-check', requestOptions).then(handleResponse);
}

function additionalActivationOnline(licenseKey) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
    }

    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/'+licenseKey+'/additional-activations/on-line', requestOptions).then(handleResponse);
}

function additionalActivationOffline(licenseKey, formData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('FILE')
    }
    const token = retrieveToken();
    let optionsWithToken = requestOptions;
    if (token !== null) {
        optionsWithToken = merge({}, requestOptions, {
            headers: {
                api_key: token
            }
        })
    }
    return axios.post(backendHost + '/restapi/v2.0/ems/settings/licenses/' + licenseKey + '/additional-activations/off-line', formData, optionsWithToken)
        .then(response => {
            return response.data;
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });
}

function returnLicense (licenseKey, internetType){

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }

    return fetch(backendHost +'/restapi/v2.0/ems/settings/licenses/' + licenseKey+'?isConnected='+internetType, requestOptions).then(handleResponse);
}

function fetchAvailableFreeLicense(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }

    return fetch(backendHost +'/restapi/v2.0/ems/settings/licenses/available-free-licenses', requestOptions).then(handleResponse);
}

function freeActivationOnline(licenseKey,company){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(company)
    }

    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/'+licenseKey+'/free-activations/on-line', requestOptions).then(handleResponse);
}

function freeActivationOffline(licenseKey,formData){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('FILE')
    }
    const token = retrieveToken();
    let optionsWithToken = requestOptions;
    if (token !== null) {
        optionsWithToken = merge({}, requestOptions, {
            headers: {
                api_key: token
            }
        })
    }
    return axios.post(backendHost + '/restapi/v2.0/ems/settings/licenses/' + licenseKey + '/free-activations/off-line', formData, optionsWithToken)
        .then(response => {
            return response.data;
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });

}

function fetchLicenseHistory(page){
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }

    return fetch(backendHost +'/restapi/v2.0/ems/settings/licenses/issued-licenses?page='+page, requestOptions).then(handleResponse);
}

function fetchOrganizationLicense() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }
    return fetch(backendHost + '/restapi/v2.0/ems/settings/licenses/organization', requestOptions).then(handleResponse);
}

function editOrganizationLicense(requestBody) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody)
    }
    return fetch(backendHost + '/restapi/v2.0/ems/settings/licenses/organization', requestOptions).then(handleResponse);
}

function fetchContentTagConditions(tagId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId+'/conditions', requestOptions).then(handleResponse);
}

function fetchTagInfoWithIdAndTagValues(tagId, tagConditions) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(tagConditions)
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId+'/conditions/thumbnail', requestOptions).then(handleResponse);
}

function fetchTagAndContentsWidthIdAndTagValues(tagId, tagConditions) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(tagConditions)
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId+'/conditions/contents', requestOptions).then(handleResponse);
}

function fetchTags(filter, exclusive = true) {
    const requestOptions = exclusive ? setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(filter)
    }) : {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(filter)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/filter', requestOptions).then(handleResponse);
}

function addTag(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags', requestOptions).then(handleResponse);
}

function getTag(tagId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId, requestOptions).then(handleResponse);
}

function editTag(tagId, requestBody) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId, requestOptions).then(handleResponse);
}

function addTagCondition(tagId, requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
    };

    let params = objToQueryString(requestBody);

    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId+'/conditions' + params, requestOptions).then(handleResponse);
}

function deleteTagCondition (tagId, tagConditionId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader("DELETE"),
    };

    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId+'/conditions/'+ tagConditionId , requestOptions).then(handleResponse);
}

function editTagCondition(tagId, requestBody) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/'+tagId+'/conditions', requestOptions).then(handleResponse);
}

function deleteTag(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/deleted-tags', requestOptions).then(handleResponse);
}

function fetchTagOrgan(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags/groups', requestOptions).then(handleResponse);

}

function fetchTagMappingInfo(tagId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost + '/restapi/v2.0/ems/settings/tags/' + tagId + '/mapping-info', requestOptions).then(handleResponse);
}

function addCategory(categoryName,pGroupId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/categories?categoryName='+categoryName+'&pGroupId='+pGroupId, requestOptions).then(handleResponse);
}

function deleteCategory(categoryId){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/categories/'+categoryId, requestOptions).then(handleResponse);
}

function renameCategory(categoryId, categoryName){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/categories/'+categoryId+'/names/'+categoryName, requestOptions).then(handleResponse);
}

function fetchLog(filter){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: createParams(filter)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/logs/system-logs/filter', requestOptions).then(handleResponse);
}

function exportServerLog(filter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: createParams(filter)
    };
    return fetchForDownload(backendHost+'/restapi/v2.0/ems/settings/logs/system-logs/export', requestOptions).then(handleBinDownloadResponse);
}

function getAlarmMailHistoryInfo(historyId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/logs/alarm-mail-logs/'+historyId, requestOptions).then(handleResponse);
}

function fetchAlarmMailHistory(filter){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let params = objToQueryString(filter);
    return fetch(backendHost+'/restapi/v2.0/ems/settings/logs/alarm-mail-logs'+params, requestOptions).then(handleResponse);
}

function exportAlarmMailHistory(filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let params = objToQueryString(filter);
    return fetchForDownload(backendHost+'/restapi/v2.0/ems/settings/logs/alarm-mail-logs/export'+params, requestOptions).then(handleBinDownloadResponse);
}


function fetchPrivacyPolicyList() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/privacy-policy', requestOptions).then(handleResponse);
}


function fetchMfaInfo() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/mfa/configurations', requestOptions).then(handleResponse);
}


function downloadMailAttachedFile(historyId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };

    return fetchForDownload(backendHost+'/restapi/v2.0/ems/settings/logs/alarm-mail-logs/'+historyId+'/attached-files/download', requestOptions)
        .then(res => res.blob())
        .then(res => {
            fileDownload(res, "download.zip");
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });

}

function fetchE2ELicenses(soldToCode){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let reqCode = soldToCode!=undefined ? soldToCode  : ''
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/licenses' , requestOptions).then(handleResponse);

}
function fetchE2ELicenseHistory(startIndex,deviceId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/issued-licenses?startIndex='+startIndex+'&deviceId='+deviceId, requestOptions).then(handleResponse);

}
function returnE2ELicense(licenseKey){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/'+licenseKey , requestOptions).then(handleResponse);

}
function swapE2ELicense(licenseKey,oldDeviceId,newDeviceId){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/'+licenseKey+'?oldDeviceId='+oldDeviceId+'&newDeviceId='+newDeviceId , requestOptions).then(handleResponse);

}

function fetchCompanyInfo(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/company-info' , requestOptions).then(handleResponse);

}

function updateCompanyInfo(company){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: createParams(company)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/company-info' , requestOptions).then(handleResponse);

}

function fetchSoldToCodeList(soldToCode){
    const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
    let reqCode = soldToCode!=undefined ? soldToCode  : ''
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/sold-to-code?soldToCode='+reqCode , requestOptions).then(handleResponse);
}

function fetchBrandCodeList(){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/licenses/e2e/brand-code', requestOptions).then(handleResponse);
}

function fetchExternalLink() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-links', requestOptions).then(handleResponse);
}

function addExternalLink(linkName, linkUrl) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams({linkId: linkName, linkUrl: linkUrl})
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-links', requestOptions).then(handleResponse);
}

function deleteExternalLink(linkId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader("DELETE"),
    };

    return fetch(backendHost+'/restapi/v2.0/ems/settings/external-links/' + linkId, requestOptions).then(handleResponse);
}

function fetchInsightIndexes(filter) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(filter)
    };
    return fetch(backendHost+'/restapi/v2.0/ems/settings/insight-index/filter', requestOptions).then(handleResponse);
}

function fetchInsightIndexOrgan() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/insight-index/groups', requestOptions).then(handleResponse);
}

function deleteInsightIndexes (indexIds) {
    const requestOptions = {
        method : 'POST',
        headers: authHeader('POST'),
        body: createParams({
            ids : indexIds
        })
    }
    return fetch(backendHost + '/restapi/v2.0/ems/settings/insight-index/delete', requestOptions).then(handleResponse);
}

function updateActivateInsightIndexes(activate, indexIds) {
    const requestOptions = {
        method : 'PUT',
        headers: authHeader('PUT'),
        body: createParams({
            activated: activate,
            indexIdList: indexIds
        })
    }
    return fetch(`${backendHost}/restapi/v2.0/ems/settings/insight-index/activate`, requestOptions).then(handleResponse);
}

function fetchInsightIndex(indexId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    }
    return fetch(`${backendHost}/restapi/v2.0/ems/settings/insight-index/${indexId}`, requestOptions).then(handleResponse);
}

function fetchContentDownloadThrottlingInfo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/sms/system/content-download/throttling', requestOptions).then(handleResponse);
}

function addInsightIndex(insightIndex) {
    const requestOptions = {
        method : 'POST',
        headers: authHeader('POST'),
        body: createParams(insightIndex)
    }
    return fetch(`${backendHost}/restapi/v2.0/ems/settings/insight-index`, requestOptions).then(handleResponse);
}

function updateInsightIndex(insightIndex) {
    const requestOptions = {
        method : 'PUT',
        headers: authHeader('PUT'),
        body: createParams(insightIndex)
    }
    return fetch(`${backendHost}/restapi/v2.0/ems/settings/insight-index/${insightIndex.indexId}`, requestOptions).then(handleResponse);
}
function uploadLoginPageImageFile(imageFile, onProgress) {
    const formData  = new FormData();
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader('FILE'),
        onUploadProgress: (event) => { onProgress(Math.round( (event.loaded * 100) / event.total)) }
    };

    const token = retrieveToken();
    let optionsWithToken = requestOptions;
    if (token !== null) {
        optionsWithToken = merge({}, requestOptions, {
            headers: {
                api_key: token
            }
        })
    }

    for(const name in imageFile) {
        formData.append(name, imageFile[name]);
    }

    return axios.post(`${backendHost}/restapi/v2.0/sms/system/upload-login-page-image`, formData, optionsWithToken
    ).then(response => {
        return response.data;
    }).catch(response => {
        if (response.status !== 200) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                history.push('/login');
            }
            return Promise.reject(response.message);
        }
    });
}
// [V9 RC #4] ICP certificate link add in Log-in screen
function fetchIcpInfo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/icp', requestOptions).then(handleResponse);
}
