import React, {useCallback, useEffect, useState} from "react";
import {MoonLoader} from "react-spinners";
import totalIcon from "../../../../images/icon/icon_total.png";
import downloadingIcon from "../../../../images/icon/icon_downloading.png";
import downloadedIcon from "../../../../images/icon/icon_downloadCompleted.png";
import upgradedIcon from "../../../../images/icon/icon_upgradeSusscess.png";
import failIcon from "../../../../images/icon/icon_upgradeFail.png";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {menuAction} from "../../../../actions";
import {SortableHandle} from "react-sortable-hoc";
import {dashboardService} from "../../../../services";
import {toastr} from "../../../../helper/toastrIntercept";
import {getErrorMessage} from "../../../../helper/responseHandler";
import Moment from "moment";
import './CustomSoftwareUpdate.css';
import {dashboardConstants} from "../../../../constants/DashboardConstants";

const CustomSoftwareUpdate = ({removeWidget}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [softwareUpdateInfo, setSoftwareUpdateInfo] = useState({});
    const [mouseOver, setMouseOver] = useState(false);

    const {reload} = useSelector(state => state.dashboard);

    const getUserFormat = useCallback(()=> {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.dateFormat;
    }, [])

    const [loading, setLoading] = useState(true);

    const DragHandle = SortableHandle(() =>
        <div className='title'>{t("MIS_SID_SOFTWARE_UPDATE_SUMMARY")}</div>
    );

    const onItemClickEvent = () => {
        new Promise(()=> {
            dispatch(menuAction.removeTab('DEVICE'))
        }).then(
            dispatch(menuAction.moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true}, 'SOFTWARE_UPDATE', 'SOFTWARE_APPLIED_STATUS'))
        )
    }

    const fetchGetSoftwareUpdateInfo = () => {
        setLoading(true);
        dashboardService.fetchSoftwareUpdate().then(res => {
            setSoftwareUpdateInfo(res.items)
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => setLoading(false));
    };

    const total = useCallback(()=> {
        if (softwareUpdateInfo) {
            return softwareUpdateInfo.downloadFinished + softwareUpdateInfo.downloading + softwareUpdateInfo.upgradeSuccess + softwareUpdateInfo.upgradeFail;
        }
        return 0;
    }, [softwareUpdateInfo])

    const getStartDate = () => {
        const format = getUserFormat();
        return Moment().add(-7, 'days').format(format.toUpperCase());
    }

    useEffect(() => {
        fetchGetSoftwareUpdateInfo();
    }, [reload])

    return (
        <div className={'custom_widget'} onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)}>

            <div className='header'>
                <DragHandle />
                {
                    mouseOver && <div className={'close'} onClick={(e)=>removeWidget(e, dashboardConstants.DASHBOARD_CUSTOM_SOFTWARE_UPDATE_WIDGET)}></div>
                }
            </div>
            <div className='custom_widget_body' >
                {loading && <MoonLoader css={`position: absolute;top: calc(50%);left: calc(50% - 40px);`} color="#6e78c8"/>}
                {!loading &&
                <div className="software-update">
                    <div style={{paddingLeft: 15, paddingRight: 15}}>
                        <div className={'underline'} style={{cursor: 'pointer'}} onClick={onItemClickEvent}>
                            <div className='software-update-period'>{getStartDate()} ~ {t("TEXT_TODAY_P")}</div>
                        </div>
                    </div>
                    <ul>
                        <li onClick={onItemClickEvent}>
                            <div className='software-update-icon'><img src={totalIcon} style={{marginRight: '11px'}}/></div>
                            <div className='software-update-title'>{t("LIST_TITLE_TOTAL")}</div>
                            <div className='software-update-count' style={{color: '#454545'}}>{total()}</div>
                        </li>
                        <li onClick={onItemClickEvent}>
                            <div className='software-update-icon'><img src={downloadingIcon} style={{marginRight: '11px'}}/></div>
                            <div className='software-update-title'>{t("MSG_DOWNLOAD")}</div>
                            <div className='software-update-count' style={{color: '#198DCB'}}>{softwareUpdateInfo.downloading}</div>
                        </li>
                        <li onClick={onItemClickEvent}>
                            <div className='software-update-icon'><img src={downloadedIcon} style={{marginRight: '11px'}}/></div>
                            <div className='software-update-title'>{t("MIS_TEXT_DOWNLOAD_FINISHED_P")}</div>
                            <div className='software-update-count' style={{color: '#198DCB'}}>{softwareUpdateInfo.downloadFinished}</div>
                        </li>
                        <li onClick={onItemClickEvent}>
                            <div className='software-update-icon'><img src={upgradedIcon} style={{marginRight: '11px'}}/></div>
                            <div className='software-update-title'>{t("MIS_TEXT_UPGRADE_SUCCESS_P")}</div>
                            <div className='software-update-count' style={{color: '#198DCB'}}>{softwareUpdateInfo.upgradeSuccess}</div>
                        </li>
                        <li onClick={onItemClickEvent}>
                            <div className='software-update-icon'><img src={failIcon} style={{marginRight: '11px'}}/></div>
                            <div className='software-update-title'>{t("MIS_TEXT_UPGRADE_FAIL_P")}</div>
                            <div className='software-update-count' style={{color: '#EE3A2F'}}>{softwareUpdateInfo.upgradeFail}</div>
                        </li>
                    </ul>

                </div>
                }
            </div>
        </div>
    )
}
export default CustomSoftwareUpdate;