import React from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from "../checkbox/Checkbox";

const Users = ({id, users, userCheckBox, toggleUser,selectedUsers}) => {

    const {t} = useTranslation();

    const isChecked = (userId) => {
        let rest = selectedUsers.findIndex((item) => (item == userId));
        return rest >= 0 ? true : false
    }

    return (
        <div className="dev_filter_type_checkbox" id="filter_list_userFilter">
            <h5><span className="userFilter" style={{background:'#93bde8'}}></span>{t("COM_DID_ADMIN_USER_USERID")}</h5>
            <div id="user_list">
                <ul className="type_checklist" style={{overflowY:'auto'}}>
                    {
                        users.length > 0 ?
                            users.map(
                                (user) => (
                                    <li>
                                        <Checkbox id={id+'_filter_user_'+user.userInfo.userId}
                                                  name={user.userInfo.userId}
                                                  ref={ref => userCheckBox[user.userInfo.userId] = ref}
                                                  onChange={() => toggleUser(user.userInfo.userId)}
                                                  checked = {isChecked(user.userInfo.userId)}
                                        />
                                    </li>
                                )
                            ) : 'loading'
                    }
                </ul>
            </div>
        </div>
    )
}
export default Users;