import React from 'react';
import {DASHBOARD_ACTION} from '../constants';

export const dashboardAction = {
    updateWidgets,
    reloadDashboard
}

function updateWidgets(widgets) {
    return {
        type: DASHBOARD_ACTION.UPDATE_WIDGETS,
        widgets
    }
}

function reloadDashboard() {
    return {
        type: DASHBOARD_ACTION.RELOAD_DASHBOARD
    }
}