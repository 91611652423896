import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './InformationFTPCIFS.css';
import Select from "../../selectbox/Select"
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';

const InformationFTPCIFS = (props) => {
    const {t} = useTranslation();
    const{contentId, popupType = 'FTP', onClose} = props;

    const [cifsInfo, setCifsinfo] = useState({
        cifsIP: '',
        cifsLoginId: '',
        cifsDirectory: '',
        cifsRefreshInterval: '',
        canRefresh: '',
    });

    const [ftpInfo, setFtpinfo] = useState({
        ftpIP: '',
        ftpLoginId: '',
        ftpDirectory: '',
        portStr: '',
        ftpRefreshInterval: '',
        canRefresh: '',
    });

    const pollingIntervalOption = [
        {title:1, value:1},
        {title:2, value:2},
        {title:3, value:3},
        {title:4, value:4},
        {title:5, value:5},
        {title:6, value:6},
        {title:7, value:7},
        {title:8, value:8},
        {title:9, value:9},
        {title:10, value:10},
        {title:15, value:15},
        {title:20, value:20},
        {title:25, value:25},
        {title:30, value:30}
    ];

    const onChangePollingInterval =(value) => {
        if(popupType === 'FTP') {
            setFtpinfo({
                ...ftpInfo,
                ftpRefreshInterval: value,
            });
        } else {
            setCifsinfo({
                ...cifsInfo,
                cifsRefreshInterval: value,
            });
        }
    };

    const saveInformation = () => {
        if(popupType === 'FTP') {
            const requestBody = {
                ftpRefreshInterval: ftpInfo.ftpRefreshInterval,
            };
            contentService.editFtpSetting(contentId, requestBody).then(res => {
                if(res.status === 'Success') {
                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    onClose();
                }
            })
        } else {
            const requestBody = {
                cifsRefreshInterval: cifsInfo.cifsRefreshInterval,
            };
            contentService.editCifsSetting(contentId, requestBody).then(res => {
                if(res.status === 'Success') {
                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    onClose();
                }
            })
        }
    };

    useEffect(() => {
        if(popupType === 'FTP') {
            contentService.fetchFtpSetting(contentId).then(res => {
                if(res.status === 'Success') {
                    setFtpinfo({
                        ftpIP: res.items.ftpIP,
                        ftpLoginId: res.items.ftpLoginId,
                        ftpDirectory: res.items.ftpDirectory,
                        portStr: res.items.portStr,
                        ftpRefreshInterval: res.items.ftpRefreshInterval,
                        canRefresh: res.items.canRefresh,
                    });
                }
            })
        } else {
            contentService.fetchCifsSetting(contentId).then(res => {
                if(res.status === 'Success') {
                    setCifsinfo({
                        cifsIP: res.items.cifsIP,
                        cifsLoginId: res.items.cifsLoginId,
                        cifsDirectory: res.items.cifsDirectory,
                        cifsRefreshInterval: res.items.cifsRefreshInterval,
                        canRefresh: res.items.canRefresh,
                    });
                }
            })
        }
    }, []);

    return (
        <div className='upload_ftp_content_popup_wrap'>
            <MISDialog dialog={{
                title: t('COM_TEXT_INFORMATION_P'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 407,
                height: 'auto',
                position: {x: -150, y: -200},
                onClose: onClose,
            }}
                       buttons={{
                           rightButtons: [
                               {
                                   id: 'SAVE',
                                   title: t('BUTTON_OK_P'),
                                   onClick: () => saveInformation(),
                               },
                               {
                                   id: 'CANCEL',
                                   title: t('BUTTON_CANCEL_P'),
                                   onClick: onClose,
                               }
                           ],
                       }}>
                <div className='upload_ftp_content_popup'>
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        {
                            popupType === 'FTP' &&
                            <tr>
                                <th>{t('MIS_TEXT_FTP_IP_ADDRESS_P')}</th>
                                <td>
                                    <span>{ftpInfo.ftpIP}</span>
                                </td>
                            </tr>
                        }
                        {
                            popupType === 'CIFS' &&
                            <tr>
                                <th>{t('MIS_TEXT_CIFS_IP_ADDRESS_P')}</th>
                                <td>
                                    <span>{cifsInfo.cifsIP}</span>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('COM_TEXT_LOGIN_ID_P')}</th>
                            <td>
                                <span>{popupType === 'FTP' ? ftpInfo.ftpLoginId : cifsInfo.cifsLoginId}</span>
                            </td>
                        </tr>
                        {
                            popupType === 'FTP' &&
                            <tr>
                                <th>{t('COM_SETUP_NEW_STRING26_P')}</th>
                                <td>
                                    <span>{ftpInfo.portStr}</span>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('MIS_TEXT_FTP_REMOTE_DIR_P')}</th>
                            <td>
                                <span>{popupType === 'FTP' ? ftpInfo.ftpDirectory : cifsInfo.cifsDirectory}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className='pt25'>{t('MIS_TEXT_FTP_POLLING_INTERVAL_P')}</th>
                            <td className='pt25'>
                                <div className="select_box float_l mr8" id="remotePollingBox">
                                    <Select selects={pollingIntervalOption} width={100}
                                            value={popupType === 'FTP' ? ftpInfo.ftpRefreshInterval : cifsInfo.cifsRefreshInterval}
                                            onChange={(e,value) =>onChangePollingInterval(value)}/>
                                </div>
                                <span className="space float_l mr8">{t('COM_SID_MIN')}</span>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default InformationFTPCIFS;