import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import {deviceService} from '../../../../services';
import {deviceOptions, useMISOpt} from '../../../misopt';
import DateInput from '../../../input/DateInput';
import TimeSelect from '../../../selectbox/TimeSelect';
import moment from 'moment';
import {dateToString, toMomentDateTimeFormat} from '../../../../helper';
import './EditAppliedStatusPopup.css';
import RadioGroup from "../../../radio/RadioGroup";
import {isNil} from "lodash";
import Moment from "moment";

const EditAppliedStatusPopup = ({rsvId, onSave, onClose}) => {
    const {t} = useTranslation();
    const {misopt} = useMISOpt();
    const [software, setSoftware] = useState({});
    const now = new Date();

    const onClickSave = () => {
        const {user: {dateFormat, timeFormat}} = misopt;
        const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
        const {rsvDate, rsvTime} = software;
        const rsvDateTime = moment({
            year: rsvDate.getFullYear(), 
            month: rsvDate.getMonth(), 
            day: rsvDate.getDate(), 
            hour: rsvTime.hour(), 
            minute: rsvTime.minute(),
        });

        deviceService.updateSoftwareForDeploy(software.softwareId, software.publishType === 'NOW' ? 'NOW' : rsvDateTime.valueOf()).then(res => {
            toastr.success(t('COM_BUTTON_SAVE') + ' - ' + t('COM_TEXT_SUCCESS_P'));
            if(onSave) {
                onSave();
            }
        }).catch(error => {
            if(error.errorCode === '400633') {
                const currentTime = ( !isNil(error.errorDetails) &&  !isNil(error.errorDetails.timestamp) ) ? Moment(error.errorDetails.timestamp).format('HH:mm') : "";
                const timeZone = ( !isNil(error.errorDetails) &&  !isNil(error.errorDetails.timeZone) ) ? error.errorDetails.timeZone : "";
                toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN').replace('<<A>>', t('MIS_ID_PUBLISH_TIME')) + '</br>' +t("MIS_SID_MIX_CAJUL_CURRNT_TIME_GMT").replace("<<A>>", timeZone).replace("<<B>>", currentTime));
            }else {
                toastr.error(t('COM_BUTTON_SAVE') + ' - ' + t('TEXT_FAIL_P'));
            }
        });
    };

    useEffect(() => {
        deviceService.fetchSoftwareForDeploy(rsvId).then(res => {
            const sw = res.items;
            let rsvDateTime, rsvDate, rsvTime,publishType;
            if(sw.type === 1 && sw.reservationDate) {
                rsvDateTime = moment(sw.reservationDate);
                rsvDate = rsvDateTime.toDate();
                rsvTime = rsvDateTime;
                publishType = 'RESERVED';
            }
            setSoftware(Object.assign({}, sw, {rsvDate, rsvTime,publishType}));
        }).catch(error => console.log(error));
    }, [rsvId]);


    return (
        <div className='edit_applied_status_popup_wrap'>
            <MISDialog
                dialog={{
                    title: t('TEXT_TITLE_EDIT_SOFTWARE_RESERVATION_P'),
                    modal: true,
                    isDraggable: false,
                    closeOnEscape: true,
                    width: 780,
                    height: 'auto',
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'COM_BUTTON_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            disable: !software.rsvDate,
                            // disable: !isEmpty(error)
                        },
                        {
                            id: 'BUTTON_CANCEL_P',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <div className='devicepop'>
                    <div className="detail_view">
                        <table>
                            <colgroup>
                                <col width="240px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                                    <td>
                                        {software.deviceType}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('COM_TEXT_SOFTWARE_NAME_P')}</th>
                                    <td>
                                        {software.softwareName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TEXT_SOFTWARE_FILE_NAME_P')}</th>
                                    <td>
                                        {software.fileName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TABLE_SOFTWARE_VERSION_P')}</th>
                                    <td>
                                        {software.softwareVersion}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('TEXT_CRC_INFO_P')}</th>
                                    <td>
                                        {software.mimeType}
                                    </td>
                                </tr>
                                {
                                    software.type === 1 &&
                                    <tr>
                                        <th>{t('MIS_ID_PUBLISH_TIME')}</th>
                                        <td>
                                            <RadioGroup selects={deviceOptions.swPublishOptions} value={software.publishType} onChange={(e, value) => setSoftware({...software,publishType: value})}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    software.type === 1  &&
                                    <tr>
                                        <th>{t('TEXT_DEPLOYMENT_RESERVATION_P')}</th>
                                        <td>
                                            <DateInput width={120} minDate={now} date={dateToString(software.rsvDate)} onChange={date => setSoftware({...software, rsvDate: date})}  disabled={software.publishType !== 'RESERVED'}/>
                                            <TimeSelect width={80} value={software.rsvTime} style={{marginLeft: '8px'}} onChange={val => setSoftware({...software, rsvTime: val})}  disabled={software.publishType !== 'RESERVED'}/>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th></th>
                                    <td>
                                        {
                                            software.groupNameList && software.groupNameList.length > 0 &&
                                            software.groupNameList[0]
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default EditAppliedStatusPopup;