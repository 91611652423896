import React from 'react';
import DevicePowerIcon from './DevicePowerIcon';
import PlayerIcon from '../icon/players';
import './DevicePowerIcon.css';

const DeviceTitle = ({device}) => {
    return (
        <>
            <DevicePowerIcon power={device.power} />
            <PlayerIcon text={false} deviceType={device.deviceType} deviceTypeVersion={parseInt(device.deviceTypeVersion)} />
            {device.deviceName}
        </>
    );
};

export default DeviceTitle;