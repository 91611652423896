import React from 'react'
import VWLIcon from '../../../images/icon/file_type_icon_09.png';
import classNames from "classnames";

const VWL = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={VWLIcon}/>
            {
                !onlyIcon && 'VWL'
            }
        </span>
    )
}
export default VWL;