import React, {useState} from 'react';
import MISDialog from "../../MISDialog";
import RemoteServerInformation from "./RemoteServerInformation";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../../actions";
import {settingConstants} from "../../../../constants";
import {validatePort} from "../../../../helper";

const RemoteServerPopup = ({onClose, rmInfo, setRmMainViewData}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const title = rmInfo?rmInfo.serverName:'Remote Control Server';
    const mode = rmInfo?'EDIT':'ADD';
    const [rmPopupInfo, setRmPopupInfo] = useState(rmInfo?rmInfo:{useSsl:false});
    const [nameChecked, setNameChecked] = useState(false);

    const updateRmInfo = (obj) => {
        setRmPopupInfo(obj);
    };

    const convertNullToDefault = () => {
        if(rmPopupInfo.privateSsl === null){
            rmPopupInfo.privateSsl = false;
        }
        if(rmPopupInfo.privatePort === null){
            rmPopupInfo.privatePort = 0;
        }
        if(rmPopupInfo.privateMode === null){
            rmPopupInfo.privateMode = false;
        }
        if(rmPopupInfo.connection === null){
            rmPopupInfo.connection = 0;
        }
        if(rmPopupInfo.serverDeviceChk === null){
            rmPopupInfo.serverDeviceChk = 0;
        }
        if(rmPopupInfo.status === null){
            rmPopupInfo.status = "OFF"
        }
        setRmPopupInfo({...rmPopupInfo})
    };

    const remoteServerNameCheck = () => {
        return nameChecked;
    };

    const cleanInput = () => {
        if(isNaN(rmPopupInfo.port))
            return false;
        if(!rmPopupInfo.ipAddress || rmPopupInfo.ipAddress === '' || rmPopupInfo.ipAddress === null)
            return false;
        if(!rmPopupInfo.serverName || rmPopupInfo.serverName === '' || rmPopupInfo.serverName === null)
            return false;
        if(rmPopupInfo.privateMode){
            if(isNaN(rmPopupInfo.privatePort))
                return false;
            if(!rmPopupInfo.privateIpAddress || rmPopupInfo.privateIpAddress === '' || rmPopupInfo.privateIpAddress === null)
                return false;
        }
        return true;
    };

    const remoteServerPortCheck = () => {
        let port;
        let private_port;

        port = validatePort(rmPopupInfo.port);
        if(rmPopupInfo.privateMode){
            private_port = validatePort(rmPopupInfo.privatePort);
            return port && private_port;
        }
        return port;
    };

    const onSaveRemote = async () => {
        convertNullToDefault();
        if(!rmPopupInfo.serverName || rmPopupInfo.serverName === ''){
            toastr.error(t('MIS_MESSAGE_SETUP_ENTER_SERVERNAME_P'));        
            return;
        }

        if(!cleanInput()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        if(!remoteServerPortCheck()){
            toastr.error(t('MESSAGE_DEVICE_INPUT_SERVER_INFORMATION_P'));
            return;
        }

        if(mode === 'ADD'){
            if(!remoteServerNameCheck()){
                toastr.error(t('MIS_TEXT_SETUP_ASK_CHECK_SERVERNAME_P'));
                return;
            }

            await settingService
                .addRemoteControls(rmPopupInfo)
                .then(res=>{
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                    setRmMainViewData(rmPopupInfo, true);
                })
                .catch(err=>{
                    toastr.error(t('COM_TEXT_TITLE_ERROR_P'));
                });
            await dispatch(popupAction.closePopup(settingConstants.EDIT_REMOTE_CONTROL_SERVER_POPUP));
        }else if (mode === 'EDIT'){
            await settingService
                .editRemoteControls(rmPopupInfo.serverName, rmPopupInfo)
                .then(res=>{
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                    setRmMainViewData(rmPopupInfo, false);
                })
                .catch(err=>{
                    toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                });
            await dispatch(popupAction.closePopup(settingConstants.EDIT_REMOTE_CONTROL_SERVER_POPUP));
        }else{
            toastr.error(t('COM_TEXT_TITLE_ERROR_P'));
        }
    };


    return(
        <MISDialog
            dialog={{
                title:title,
                modal:true,
                closeOnEscape: true,
                width: 400,
                height: rmPopupInfo.privateMode?335:220,
                onClose: onClose}}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: ()=> onSaveRemote(),
                        disable: false
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}
        >
        <RemoteServerInformation
            rmInfo = {rmInfo}
            updateRmInfo = {updateRmInfo}
            mode={mode}
            setNameChecked={setNameChecked}
        />
        </MISDialog>
    )
}

export default RemoteServerPopup;