import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isNil} from 'lodash';
import './DeviceDetailView.css';
import WhiteButton from '../../../button/WhiteButton';
import Size from '../../../utils/Size';
import TextInput from '../../../input/TextInput';
import Checkbox from '../../../checkbox/Checkbox';
import './AppLogDataDetail.css';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import {deviceService} from '../../../../services';
import BULLET_1 from "../../../../images/icon/bullet_1.png";
import {toastr} from 'helper/toastrIntercept';
import {useCheckRefForDeviceLog as useCheckRef} from '../../../../helper';
import fileDownload from "js-file-download";
import {getErrorMessage} from '../../../../helper/responseHandler';

const FREE_SIZE_LB = 1024*1024*1024;
const SCRIPT_FOR_APP = 'third_application_nopswd';
const TYPE_FOR_APP = 'third_application';

const AppLogDataDetail = ({device, logInfo, onChange}) => {
    const {t} = useTranslation();
    const pollingHandle = useRef();
    const retryCnt = useRef(0);
    const dispatch = useDispatch();
    const [scriptError, setScriptError] = useState(false);
    const [collectLogDesc, setCollectLogDesc] = useState(device.logCollect.collectInfo === undefined? '' : t('COM_MSG_PROCESSING'));
    const [logCollect, setLogCollect] = useState(device.logCollect.collectInfo === undefined ? {...device.logCollect, collectInfo: {duration: 1}} : {...device.logCollect});
    const [collectedLogList, setCollectedLogList] = useState(device.logCollect.collectedLogList);
    const [data, setData] = useState({
        selected: [],
        selectAll: false,
        items: [],
    });
    const [appLogSize, setAppLogSize] = useState(device.thirdApplicationLogSize && device.thirdApplicationLogSize > 0?device.thirdApplicationLogSize:0);
    
    const {deviceId} = device;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(collectedLogList);

    useEffect(() => {
        onRefresh();
    }, []);

    const onClickDownload = () => {
        const filepathList = [];
        const scriptList = [];
        selected.current.forEach(selectItem => {
            filepathList.push(collectedLogList[selectItem].fileName);
            scriptList.push(collectedLogList[selectItem].script);
        });

        if(selectedCnt === 0) {
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
        } else {
            const parsedFilepath = filepathList.concat().toString();
            let script;
            if(scriptList.length > 0){
                script = scriptList[0];
                if(!scriptList.every(script => script === script)){
                    script = '';
                }
            }
            deviceService.downLoadDeviceCollectedLogs(deviceId, parsedFilepath, script).then(res => {
                fileDownload(res.blob, res.fileName);
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            });
        }
    };

    const onClickEmpty = () => {
        deviceService.deleteDeviceCollectedLogs(deviceId, SCRIPT_FOR_APP).then(res => {
            if(res.status === 'Success') {
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                setData({...data, items: [], selectAll: false});
                checkAll.current.checked = false;
                setCollectedLogList([]);
            }
        }).catch(error => {
            if(error.errorCode === '500011') {
                toastr.error(t('MESSAGE_DEVICE_NO_RESULT_FILE_P'));
            }else{
                toastr.error(error.errorMessage);
            }
            setData({...data, selectAll: false});
            checkAll.current.checked = false;
        })
    };

    const onCollect = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COLLECT_LOG_DEVICE,
            type: commonConstants.COLLECT_LOG_DEVICE,
            useMessageLine: false,
            onClickYes: () => {
                dispatch(popupAction.closePopup(commonConstants.COLLECT_LOG_DEVICE));
                dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));

                deviceService.updateDeviceLogProcess(deviceId, {
                    deviceId: deviceId,
                    chkTimeout: 1,
                    duration: logInfo.duration ? logInfo.duration : '',
                    packetSize: 1,
                    script: SCRIPT_FOR_APP, //logInfo.script,
                    status: 'INIT',
                    step:0
                }).then(res => {
                    if (res.items.status === 'disconnect') {
                        toastr.error(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
                        setCollectLogDesc(t('MIS_SID_LOG_COLLECTION_START_WHEN_CONNECTED'));
                        dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                    } else if (res.items.status === 'init') {
                        toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));

                        pollingHandle.current = setInterval(() => {
                            deviceService.updateDeviceLogProcess(deviceId, {
                                deviceId: deviceId,
                                chkTimeout: 1 + retryCnt.current,
                                duration: logInfo.duration ? logInfo.duration : '',
                                packetSize: 1,
                                script: SCRIPT_FOR_APP, // logInfo.script,
                                status: 'INIT',
                                step:1,
                                requestId: res.items.requestId
                            }).then(res => {
                                retryCnt.current++;
                                if(res.items.status === 'finished') {
                                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                                    clearInterval(pollingHandle.current);
                                    setCollectLogDesc('');
                                    if(res.items.response=='fail'){
                                        toastr.error(t('ALERT_FAIL'));
                                    }
                                } else if (res.items.status === 'disconnect') {
                                    toastr.error(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
                                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                                }

                                if(retryCnt.current === 30) {
                                    toastr.error(t('MIS_MESSAGE_TIME_OUT_P'));
                                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                                    clearInterval(pollingHandle.current);
                                }
                            }).catch(error => {
                                if(error !== 'Fail') {
                                    toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                                }
                            });
                        }, 1000);
                    } else {
                        toastr.warning(t('MIS_SID_20_COLLECTING_LOGS'));
                        dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                    }

                    setScriptError(false);
                }).catch(error => {
                    toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                    clearInterval(pollingHandle.current);
                    setCollectLogDesc('');
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                }).finally(() => {
                    dispatch(popupAction.closePopup(commonConstants.COLLECT_LOG_DEVICE));
                });

                setLogCollect({...logCollect});
            }, 
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COLLECT_LOG_DEVICE))
        }));
    };

    const onStop = () => {
        deviceService.updateDeviceLogProcess(deviceId, {
            deviceId: deviceId,
            chkTimeout: 100,
            duration: logInfo.duration ? logInfo.duration : '',
            packetSize: 1,
            script: SCRIPT_FOR_APP, //logInfo.script,
            status: 'END',
            step:0
        }).then(res => {
            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            if (res.items.status === 'end') {
                setCollectLogDesc('');
                deviceService.fetchDeviceById(deviceId).then(res => {
                    setLogCollect({...logCollect, ...res.items.logCollect});
                });
                onRefresh();
                onChange(SCRIPT_FOR_APP, logInfo.duration, true);
            } else if(res.items.status === 'finished') {
                setCollectLogDesc('');
                onChange(SCRIPT_FOR_APP, logInfo.duration, false);
            }            
        }).catch(error => {
            toastr.error(t('MESSAGE_COMMON_ERROR_P'));
            setCollectLogDesc('');
        })
    };

    const onRefresh = () => {
        dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
        deviceService.fetchDeviceCollectedLogs(deviceId).then(res => {
            const filtered = res.items.filter(item => item.type === TYPE_FOR_APP);
            setData({...data, items: filtered, selectAll: false});
            setCollectedLogList(filtered);
        }).catch(error => {
            toastr.error(error);
        }).finally(() => {
            dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));
        });
    };

    const onRefreshLogSize = () => {
        dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
        deviceService.getDeviceCurrentGeneralInfo(deviceId, res => {
            dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));

            const {successList = [{}]} = res.items;
            if(!isNil(successList[0]) && !isNil(successList[0].thirdApplicationLogSize) && successList[0].thirdApplicationLogSize >= 0){
                device.thirdApplicationLogSize = successList[0].thirdApplicationLogSize;
                setAppLogSize(successList[0].thirdApplicationLogSize);
            }
        }, 15, 1000).catch(error => toastr.error(getErrorMessage(error)));
    }

    const onEmptyAppLog = () => {
        deviceService.sendProcessCommand(deviceId, 'CLEANUP_THIRD_APPLICATION_LOG').then(res => {
            if(res && res.status === 'Success') {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'))
            } else {
                toastr.error(t('ALERT_FAIL'));
            }
        }).catch(error => toastr.error(getErrorMessage(error)));
    }

    return (
        <div className="detail_view mini_8 mt15" style={{overflow:'hidden'}}>
            <div style={{height:105, borderBottom:'1px solid #e7e7e7'}}>
                <table style={{width:'99%'}}>
                    <tbody>
                        <tr>
                            <td style={{padding:0, width:'90%'}}>
                                <span className="detail_table_title" style={{fontSize:15, verticalAlign: "middle"}}>{t('MIS_SID_CBFEB_KIOSK_APP_LOG_DEVICE')}</span>
                            </td>
                            <td style={{width:'10%'}}>
                                <div style={{position:'absolute', right:0, top:40, whiteSpace:'normal', textAlign:'right', width:250, lineHeight:'30px'}}>
                                {
                                    device.power &&
                                    <WhiteButton id='logCollectBtn' name={t('MIS_SID_CBFEB_COLLECT_APP_LOG')} onClick={onCollect} />
                                }
                                {
                                    device.power && <WhiteButton id='emptyAppLog' name={t('MIS_SID_CBFEB_EMPTY_APP_LOG')} onClick={onEmptyAppLog} style={{marginLeft: 8}} />
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style={{paddingLeft:15}}>
                                <div className="float_l" style={{wordWrap:'break-word',whiteSpace:'initial'}}>
                                    <span>{t('MIS_SID_CBFEB_LOG_SIZE')} : <Size size={appLogSize}/></span>
                                    {
                                        device.power && <button id="reloadDeviceAppLogSize" className="replash2 float_r" onClick={onRefreshLogSize} style={{marginLeft:10,marginTop:-5}}></button>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{margin: '15px 0px 37px 0'}}>
                <span className="detail_table_title" style={{fontSize:15, verticalAlign: "middle", marginTop:5}}>{t('MIS_SID_CBFEB_COLLECTED_LOG_SERVER')}</span>
                <div style={{display:'inline-block', float:'right'}}>
                    <button id="logCollectRefreshBtn" className="replash2 float_r" onClick={onRefresh} style={{marginTop:5}}></button>
                    <WhiteButton id='logCollectClearBtn' name={t('MIS_SID_EMPTY_STORAGE')} style={{float: 'right', marginRight: '8px', marginTop:5}} onClick={onClickEmpty} />
                    <WhiteButton id='logCollectDownloadBtn' name={t('BUTTON_DOWNLOAD_P')} style={{float: 'right', marginRight: '8px', marginTop:5}} onClick={onClickDownload} />
                </div>
            </div>
            <div className="detail_table log_list list_wrap log_collect_table mt9">
                <table id="logCollectFilesTable" width="99%;">
                    <colgroup>
                        <col width="30px;"/>
                        <col width="30%"/>
                        <col width="50%"/>
                        <col width=""/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th><Checkbox id='list_check_all_logCol' classname={"table"} name={"check"} onChange={toggleSelectAll} ref={checkAll} /></th>
                            <th>{t('TABLE_LAST_MODIFIED_DATE_P')}</th>
                            <th>{t('TEXT_AD_FILE_NAME_P')}</th>
                            <th>{t('COM_SID_SIZE_M_FILE_SIZE')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            collectedLogList && collectedLogList.length > 0 &&
                            collectedLogList.map((log, index) => {
                                return (
                                    <tr>
                                        <td><Checkbox id={'logCheck' + index} classname={"table"} name={"check"} index={index} onChange={toggleRow} ref={setCheckBoxRefs} /></td>
                                        <td><span>{log['modifiedDate']}</span></td>
                                        <td>{log['fileName']}</td>
                                        <td><Size size={log['size']}/></td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

AppLogDataDetail.defaultProps = {
    device: {},
};

export default AppLogDataDetail;