import React, {memo, useEffect, useRef, useState} from 'react'
import {contentService} from '../../services';
import ContentIcon from "../icon/contents";
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';

const ContentThumbnail = ({id, width, height, resolution, params, data = null, wrapWidth = 100, wrapHeight = 100, style, icon = false, contentType, textAlign = false, version =''}) => {

    const [src, setSrc] = useState(null);
    const imgEl = useRef(null);
    const [thumbWidth, setThumbWidth] = useState();

    const fetchData = (id, queryParam) => {
        Object.keys(queryParam).forEach(key => queryParam[key] === undefined && delete queryParam[key]);
        contentService.fetchContentThumbnail(id, queryParam).then((res) =>{
            try {
                if (res && res.body && res.body.items){
                    setSrc(res.body.items.src);
                } else {
                    throw "Parse Exception"
                }
            } catch (e) {
                toastr.error(e);
            }
        }).catch(
            e=> console.log(e)
        )
    }

    useEffect(()=> {
        const queryParam = {
            ...params,
            width : width ,
            height : height,
            resolution : resolution,
            version: version
        };
        fetchData(id, queryParam);
    }, [id, data]);

    useEffect(() => {
        if(!isNil(imgEl.current))
            setThumbWidth(imgEl.current.naturalWidth);
    }, [src]);

    return(
        <span>
            {
                src !== null &&
                    <div style={{textAlign: textAlign === true ? 'center' : ''}}>
                        {
                            icon && contentType !== undefined
                            && <ContentIcon type={contentType} onlyIcon embedded/>
                        }

                        <img src={src} ref={imgEl} style={{...style, width: !isNil(thumbWidth) ? thumbWidth * wrapWidth / 100 : undefined, verticalAlign:'middle'}}/>
                    </div>
            }
        </span>
    )

}
export default memo(ContentThumbnail);