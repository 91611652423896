import React from 'react';
import {useSelector} from "react-redux";
import ServerSettings from './ServerSettings';

const ServerSettingsManager = (props) => {

    const {orgId, data, changed, me, organs} = useSelector(state => ({
        orgId : state.settings.serverSettings.orgId,
        data : state.settings.serverSettings.data,
        changed : state.settings.serverSettings.changed,
        me : state.settings.serverSettings.myInfo,
        organs : state.settings.serverSettings.organInfo
    }));

    return (
        <ServerSettings {...props} data={data} changed={changed} orgId={orgId} me={me} organs={organs}/>
    )

}
export default ServerSettingsManager;
