import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import {toInteger} from 'lodash';
import NoDataRow from './NoDataRow';
import Size from '../../../utils/Size';
import {rulesetService} from '../../../../services/ruleset.service';
import {getErrorMessage} from '../../../../helper/responseHandler';
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import {useMISOpt} from '../../../misopt';
import {deviceService} from '../../../../services';
import rulesetIcon from '../../../../images/icon/tab_icon_ruleset_on.png';

const ContentDownloadStatusDetail = ({ device }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [contentDownloadStatus, setContentDownloadStatus] = useState([]);
    const [eventDownloadStatus, setEventDownloadStatus] = useState([]);
    const fetchHandle = useRef();
    const {misOption} = useMISOpt();

    const showRulesetContentDownloadStatus = (rulesetId, deviceId) => {
        rulesetService.fetchRulesetContentDownloadStatus(rulesetId, deviceId)
            .then(res => {
                dispatch(popupAction.addPopup({
                    type: commonConstants.RULESET_CONTENT_DOWNLOAD_STATUS_POPUP,
                    id: commonConstants.RULESET_CONTENT_DOWNLOAD_STATUS_POPUP,
                    items: res.items
                }));
            })
            .catch(error => toastr.error(getErrorMessage(error)));
    };
    const option = misOption(device.deviceType.toUpperCase(), toInteger(device.deviceTypeVersion)) || {};

    const fetchStatus = () => {
        deviceService.fetchDeviceContentDownloadStatus(device.deviceId, 'content').then(res => {
            setContentDownloadStatus(res.items);
        }).catch(error => console.log(error));

        if(option.eventSchedule) {
            deviceService.fetchDeviceContentDownloadStatus(device.deviceId, 'event').then(res => {
                setEventDownloadStatus(res.items);
            }).catch(error => console.log(error));
        }
    };

    useEffect(() => {
        fetchStatus();
        fetchHandle.current = setInterval(fetchStatus, 3000);
        return () => clearInterval(fetchHandle.current);
    }, []);

    return (
        <div className='detail_view mini_3 mt15'>
            <span className='detail_table_title'>{t('TEXT_CONTENT_SCHEDULE_P')}</span>
            <div
                id='download_progress_content_table'
                className='none_scrolling detail_table mt9 scrollheight250'>
                <table>
                    <colgroup>
                        <col width='224px' />
                        <col width='83px' />
                        <col width='70px' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('TEXT_CONTENT_P')}</th>
                            <th>{t('ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE')}</th>
                            <th>{t('TEXT_STATUS_P')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <NoDataRow show={contentDownloadStatus.length === 0} colSpan={3} />
                        {   
                            contentDownloadStatus.map((status, i) => {
                                return (
                                    <tr key={i}>
                                        {
                                            status.isRuleset &&
                                            <td>
                                                <span style={{marginRight:5}}><img src={rulesetIcon}/></span>
                                                <span className='link_cell' onClick={(e) => {
                                                    showRulesetContentDownloadStatus(status.contentId, device.deviceId);
                                                }}>
                                                    {status.contentName}
                                                </span>
                                            </td>
                                        }
                                        {
                                            !status.isRuleset &&
                                            <td><span>{status.contentName}</span></td>
                                        }
                                        <td><Size size={status.totalSize} /></td>
                                        <td>{status.progress}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                option.eventSchedule && <>
                    <span className='detail_table_title mt25'>{t('MIS_TEXT_EVENT_SCHEDULE_P')}</span>
                    <div
                        id='download_progress_event_table'
                        className='none_scrolling detail_table mt9 scrollheight250'>
                        <table table-layout='fixed'>
                            <colgroup>
                                <col width='224px'/>
                                <col width='83px'/>
                                <col width='70px'/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>{t('TEXT_CONTENT_P')}</th>
                                <th>{t('ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE')}</th>
                                <th>{t('TEXT_STATUS_P')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <NoDataRow show={eventDownloadStatus.length === 0} colSpan={3}/>
                            {
                                eventDownloadStatus.map((status, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><span>{status.contentName}</span></td>
                                            <td><Size size={status.totalSize}/></td>
                                            <td>{status.progress}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    );
};

export default ContentDownloadStatusDetail;
