import React from 'react';

const UserFilterStatus = ({selectedUsers}) => {

    const countToShow = 10;
    var selectedUserShown = selectedUsers.length > countToShow ? selectedUsers.slice(0, countToShow):selectedUsers;
    var selectedUserHidden = selectedUsers.length > countToShow ? selectedUsers.slice(countToShow, selectedUsers.length):[];

    return (
        <span id="filter_box_userFilter">
            {
                selectedUserShown && selectedUserShown.map(item => (
                    <span className="filter_status_user_filter" title={item}>
                        <span>{item}</span>
                    </span>
                ))
            }
            {
                selectedUserHidden && selectedUserHidden.length > 0 && (
                    <span className="filter_status_user_filter" title={selectedUserHidden.join(', ')}>
                        <span>...</span>
                    </span>
                )
            }            
        </span>
    )
}
export default UserFilterStatus;