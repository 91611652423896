import {toMomentDateTimeFormat} from 'helper/utils';
import moment from "moment";


export const userDateFormat = ()=>{
    let dateFormat="YYYY-MM-DD";
    let timeFormat="HH:mm:ss";
    let dateTimeFormat=dateFormat+" "+timeFormat;    
    const fillUserData=()=>{
        let userData=JSON.parse(localStorage.getItem('user'));
        dateFormat=userData.dateFormat;
        timeFormat=userData.timeFormat;
        dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);        
    }    
    const getUserDateFormat=()=>{        
        return dateFormat;
    }  
    const getUserTimeFormat=()=>{        
        return timeFormat;
    }
    const getUserDateTimeFormat =()=>{        
        return dateTimeFormat;
    }  
    const getDateTimeStringInUserformat = (date) => {         
        return moment(date).format(dateTimeFormat).toString();
    }     
    const getCurrentDateTimeStringInUserFormat = () =>{
        return getDateTimeStringInUserformat(new Date());
    }    
    fillUserData();    
    return {getCurrentDateTimeStringInUserFormat,getDateTimeStringInUserformat,getUserDateFormat,getUserTimeFormat,getUserDateTimeFormat}
}  

