import {userConstants} from '../constants';
import {userService} from '../services';
import {alertActions} from '../actions';
import {history} from '../helper';
import {isNil} from 'lodash';
import { objToQueryString } from 'helper'

export const userActions = {
    login,
    logout,
    loginWithCode,
    initOtp,
    success
};

function handleError(dispatch, error, mfa, params){

    if(isNil(error) || isNil(error.body) || isNil(error.body.errorCode) ){
        dispatch(failure(error));
        return;
    }

    if(isNil(mfa.userId) && !isNil(error.body) && !isNil(error.body.errorDetails) && !isNil(error.body.errorDetails.userId)){
        mfa.userId = error.body.errorDetails.userId;
    }

    switch(error.body.errorCode){
        case '401011' :
            dispatch(initOtp({...mfa,
                type: userConstants.LOGIN_SETUP_OTP,
                otpAuthInfo: error.body.errorDetails.otpAuthInfo}));
            break;
        case '401010' :
            dispatch(initOtp({...mfa,
                type: userConstants.LOGIN_REQUIRED_OTP}));
            break;
        case '401012' :
            localStorage.removeItem('device_' + mfa.userId);

            dispatch(initOtp({...mfa,
                type: userConstants.LOGIN_REQUIRED_OTP, isRemoveTrustedDevice:true}));
            break;
        default :
            let isAdmin = (!isNil(params) && !isNil(params.isAdmin) && params.isAdmin == 'Y') || false;
            
            if(isAdmin) {
                let queryParam = {
                    loginAttempt:'fail'
                };
                window.location.assign(params.redirectUri+objToQueryString(queryParam));
            } else {
                dispatch(failure(error));
                dispatch(alertActions.error(error.toString()));
            }
            break;


    }
}

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));
        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => handleError(dispatch, error, {
                    userId: username,
                    password: password,
                    grantType: 'password'
                }, {})

            );
    };

}

function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

function loginWithCode(params){
    return dispatch => {
        // dispatch(request({ username }));

        let mfa = {
            grantType:'code',
            ...params
        }
        userService.loginWithCode(params)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => handleError(dispatch, error, mfa, params)

            );
    };

}

function logout() {
    /*userService.logout();
    history.push('/login');*/
    userService.deleteToken();
    return { type: userConstants.LOGOUT };
}

function initOtp(mfa) {

    return { type: userConstants.LOGIN_INIT_OTP, mfa };
}
