import React from 'react';
import TimeField from 'react-simple-timefield';
import { isNil } from 'lodash';

const TimeInputElement = props => {
    return <input type='text' {...props} />
};

const TimeInput = ({width=100, value, propertyName, disabled, style, onChange, showSeconds=true, defaultValue}) => {

    const timeFieldStyle = style ? {...style, width} : {
        width: width,
        margin: 7,
        textAlign: "center",
    };

    const getDefaultValue = () => {
        if(isNil(defaultValue)){
            return showSeconds?"00:00:00":"00:00";
        }
        return defaultValue;
    }

    return (
        <TimeField
            value={!isNil(value)?value:getDefaultValue()}
            input={<TimeInputElement disabled={disabled} data-name={propertyName} />}
            onChange={onChange}
            showSeconds={showSeconds}
            style={timeFieldStyle} />
    )
}
export default TimeInput