import React from 'react';
import {toastr} from 'helper/toastrIntercept';
import {isNil, toLower} from 'lodash';
import fileDownload from 'js-file-download';
import {deviceService} from '../../services';
import {
    commonConstants, FLIP,
    IPLAYER, KIOSK,
    LEDBOX,
    RIPLAYER, RKIOSK,
    RLEDBOX,
    RSIGNAGE,
    RSPLAYER,
    SIGNAGE,
    SPLAYER,
    WPLAYER
} from '../../constants';
import {getErrorMessage} from '../../helper/responseHandler';
import {getMixString} from "../../language/languageUtils";

function handleRemoteControl(devices) {
    const {addPopup, closePopup, t} = this.props;
    const {rmServer} = this.state;

    const checkPower = devices.every(
        device => device.power === true
    )

    const isVnc = devices.length === 1 && (devices[0].deviceType === RIPLAYER || devices[0].deviceType === IPLAYER);

    const checkRC = (type) => {
        switch (type) {
            case WPLAYER:
            case LEDBOX:
            case SIGNAGE:
            case RSPLAYER:
            case SPLAYER:
            case RLEDBOX:
            case RSIGNAGE:
            case RKIOSK:
            case FLIP:
                return true;
            default:
                return false;
        }
    }

    const checkDeviceTypes = devices.some(
        device => checkRC(device.deviceType)
    )

    if(!checkPower) {
        toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        return null;
    }

    if(isVnc || checkDeviceTypes) {
        if (isVnc) {
            deviceService.fetchDeviceVnc(devices[0].deviceId, {browserWidth : 1920, browserHeight : 1080}).then(res => {
                fileDownload(res.blob, res.fileName);
            }).catch(error => console.log(error));
        } else {
            addPopup({id: 'REMOTE_CONTROL', type: commonConstants.LOADING_POPUP});
            const deviceIds = devices.map(device => device.deviceId);
            deviceService.fetchDeviceRC(deviceIds, 'start').then(res => {
                const strWindowFeatures = "width=1300,height=1000,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=no";
                let url, token;
                const remoteControlDeviceIds = [];

                if (res && res.items && res.items.successList.length > 0) {
                    res.items.successList.map(
                        (device, index) => {
                            if (index === 0) {
                                url = device.url;
                                token = device.token;
                            }
                            remoteControlDeviceIds.push(device.deviceId);
                        }
                    )
                }
                if (url && token && remoteControlDeviceIds.length > 0) {
                    let windowObject = window.open('about:blank', 'RMServer', strWindowFeatures);
                    this.setState({
                        rmServer: {
                            ...rmServer,
                            show: true,
                            url: url,
                            token: token,
                            deviceIds: remoteControlDeviceIds
                        }
                    });
                    setTimeout(() => {
                        this.setState({rmServer: {show: false}});
                    }, 2000);

                    this.rmServerPollingHandle && clearInterval(this.rmServerPollingHandle);
                    this.rmServerPollingHandle = setInterval(() => {
                        if (windowObject && windowObject.closed) {
                            clearInterval(this.rmServerPollingHandle);
                            this.rmServerPollingHandle = null;
                        }
                    }, 1000);

                    if (isNil(windowObject)) {
                        toastr.error(t('MIS_TEXT_POPUP_HAS_BEEN_BLOCKED_P'));
                    }
                }
                closePopup('REMOTE_CONTROL');
            }).catch(error => {
                closePopup('REMOTE_CONTROL');
                if(error.errorCode === '500618' /*'9999'*/) {
                    toastr.error(t('MIS_TEXT_NOT_CONNECTED_TO_THE_RMSERVER_P'));
                } else if (error.errorCode === '400632') {
                    if(!isNil(error.errorDetails) && !isNil(error.errorDetails.reason) && error.errorDetails.reason.includes('SCREEN_MONITORING_LOCK')){
                        toastr.error(getMixString(['MIS_SID_MIXCBFEB_FUNCTION_NOT_AVAILABLE_WHILE_ON', 'COM_LFD_SID_TIZEN_CADEC_SCREEN_MONITORING_LOCK']));
                    } else {
                        toastr.error(getMixString(['MIS_SID_MIXCBFEB_FUNCTION_NOT_AVAILABLE_WHILE_ON', 'MIS_SID_CBFEB_REMOTE_CONTROL_LOCK']));
                    }
                } else if(error.errorCode === '500619' || error.errorCode === '500632'/*'1111'*/) {
                    addPopup({
                        id: commonConstants.COMMON_CONFIRM_POPUP,
                        type: commonConstants.COMMON_CONFIRM_POPUP,
                        title: t('COM_BUTTON_CONFIRM'),
                        useMessageLine: false,
                        message: (
                            <div>
                                {t('MIS_SID_A_REMOTE_CONTROL_SESSION_IS_ALREADY_RUNNING')}
                                <br/>
                                {/*{t('COM_TV_SID_MIX_WANT_DISCONNECT').replace('<<A>>', device.deviceId)}*/}
                            </div>
                        ),
                        onClickYes: () => {
                            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                            addPopup({id: 'REMOTE_CONTROL', type: commonConstants.LOADING_POPUP});
                            /*deviceService.fetchDeviceRMServerVnc(device.deviceId, 'stop').then(res => {
                                closePopup('REMOTE_CONTROL');
                                if(toLower(res.status) === 'success') {
                                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                                } else {
                                    toastr.error(t('MIS_TEXT_CONNECT_TO_SERVER_PLEASE_WAIT_P'));
                                }
                            }).catch(error => toastr.error(getErrorMessage(error)));*/
                        },
                        onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
                    });
                }else if(error.errorCode === '403001'){
                    toastr.error(getErrorMessage(error));
                } else {
                    toastr.error(t('MIS_TEXT_CONNECT_TO_SERVER_PLEASE_WAIT_P'));
                }
            });
        }

    }
};

export default handleRemoteControl;