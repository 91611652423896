import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const {authentication} =  useSelector(state=> ({
        authentication: state.authentication
    }))


    return (
            <Route {...rest} render={props => (
                authentication !== undefined && authentication.loggedIn !== undefined && authentication.loggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )} />
            )
}