import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import {rulesetService} from "../../../../services";
import Tree, {TreeNode} from "rc-tree/es";
import {jsonToTreeForDatalinkTable} from "../../../../helper";
import {Loading} from "../../../loading/Loading";
import ReactDataGrid from "react-data-grid";
import {toastr} from 'helper/toastrIntercept';
import './SelectDataLinkTablePopup.css';

const SelectDatalinkTablePopup = ({ type, onApply, onClose }) => {
    const { t } = useTranslation();

    const [treeData, setTreeData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [layout, setLayout] = useState([]);
    const [datalinkTableName,setDatalinkTableName] = useState(''); 
    const [serverAddress, setServerAddress] = useState('');    
    const [selectColumnStart, setSelectColumnStart] = useState('');
    const [selectColumnEnd, setSelectColumnEnd] = useState('');
    const [selectData, setSelectData] = useState('');
    const [selectedUrl, setSelectedUrl]  =useState('');
    

    useEffect(() => {
        openTableTree();
    }, []);


    const openTableTree = () => {
        setLoading(true);
        rulesetService.fetchDatalinkServerList().then(
            res=> {
                if(res.items){                    
                    setLoading(false);                    
                    setTreeData(jsonToTreeForDatalinkTable(res.items));
                }                
            }
        )
    };

    const loadTable = (url) => {

        const updatedUrl = url.substr(0,url.lastIndexOf("/"));  
        setSelectedUrl(updatedUrl);
        let arrUrl = url.split("/");
        setSelectData('');
        setDatalinkTableName(arrUrl[arrUrl.length-2]);
        rulesetService.fetchDatalinkTable(updatedUrl).then(
            res=> {
                if(res.items){
                    const json = JSON.parse(res.items);                
                    const header = json.layout.map((v,i) => {
                        return {
                            key : v.data,
                            name: v.data,                            
                            width : v.width, 
                            sortable : true,
                            checked : false,
                            headerRenderer : renderHeader                            
                    }});
                    setColumns(header);
                    setRows(json.data);
                    setLayout(json.layout);                
                    setLoading(false);

                    removeSelectedArea();
                }
            }
        )

    }
   
    const dialogProps = {
        title: t("MIS_SID_DATALINK"),
        width: 900,
        height: 450,
        position: { x: -400, y: -100 },
        modal: true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose: () => onClose()
    };

    const loop = (data) => {        
        return data.map((item) => {            
            if (item.children) {        
                return <TreeNode title={item.name} key={item.key}>{loop(item.children)}</TreeNode>;
            }            
            return (                
                <TreeNode   title={item.name} key={item.key} isLeaf={item.isLeaf} />
            );
        });
    };

    const onSelect = (e, treeNode) => {
        
        let url = treeNode.selectedNodes[0].key;        
        if(url !== null && url.indexOf("DataLink") > 0){
            setServerAddress(url);
            loadTable(url);             
        }else{

        }
    }

    const removeSelectedArea = () => {
        let selectedEl = document.getElementById('columnSelected');
        let selectedEl2 = document.querySelectorAll('.rdg-cell-mask.rdg-selected-range'); 
        let selectedEl3 = document.querySelectorAll('.rdg-cell-mask.rdg-selected');   

        if(selectedEl) selectedEl.remove();
         
        if(selectedEl2.length > 0){    
            selectedEl2.forEach(v => {
                  v.style.zIndex = 0;
              })     
         }
         if(selectedEl3.length > 0){
            selectedEl3.forEach(v => {
                 v.style.zIndex = 0;
             })         
         }
    }

    
    const getTransformX = (transformStyle) => {
        const splitArray = transformStyle.split(",");
        const translateX = splitArray[0].replace(/[^\d.]/g, '');
        return translateX.replace(/[^\d.]/g, '');
    }

    const returnNumber = (value) =>{
        if(typeof(value)==='string'){
            let temp = value.replace(/[^\d.]/g, '');
            temp *=1;
            return temp;
        }else{
            return value;
        }        
    }

    const onMouseDown = (e) => {
      
        removeSelectedArea();

        setSelectColumnStart(e.target.innerText);
        setSelectColumnEnd(e.target.innerText);
        
        const columnEl = e.target.parentElement.parentElement.parentElement;

        let width = columnEl.getBoundingClientRect().width;        
        const left= columnEl.style.left;
        let maskLayer = document.createElement('div');
        let containerEl = document.getElementById('DataLinkContainer');
        let viewPortEl = containerEl.querySelector('.rdg-viewport');
        let gridEl = containerEl.querySelector('.rdg-grid');
        let height = gridEl.getBoundingClientRect().height;

        maskLayer.style.height = `${height}px`;
        maskLayer.style.width = `${width}px`; 
        maskLayer.style.zIndex = '1';     
        maskLayer.style.transform = `translate(${left},-${height}px)`;
        maskLayer.id = 'columnSelected';
        maskLayer.className = 'rdg-cell-mask rdg-selected-range';
        maskLayer.setAttribute('clickLeft',returnNumber(left));
        maskLayer.setAttribute('clickRight',returnNumber(left)+returnNumber(width));
        viewPortEl.appendChild(maskLayer);
    }

    const onMouseOver = (e) => {        
        let target = document.getElementById('columnSelected');
        if(target && target.getAttribute('finish') !== 'true'){

            let targetLeft = returnNumber(getTransformX(target.style.transform));
            let targetWidth = returnNumber(target.style.width);            

            let targetRight = targetLeft + targetWidth;
            const newEl = e.target.parentElement.parentElement.parentElement;  
            const baseWidth = newEl.getBoundingClientRect().width;   
            let newLeft= returnNumber(newEl.style.left);            

            let containerEl = document.getElementById('DataLinkContainer');
            let parentEl = containerEl.querySelector('.rdg-viewport .rdg-grid');        
            let tableHeight = parentEl.getBoundingClientRect().height;
            let originalLeft = returnNumber(target.getAttribute('clickLeft'));            
            let originalRight = returnNumber(target.getAttribute('clickRight'));            

            if(newLeft < targetLeft) {                      
                target.style.width = `${targetWidth+baseWidth}px`;
                target.style.transform = `translate(${newLeft}px,-${tableHeight}px)`;                
                setSelectColumnStart(e.target.innerText);                  
            } 

            if(newLeft >= targetLeft + baseWidth &&  newLeft < originalRight){
                target.style.width = `${targetWidth-baseWidth}px`;
                target.style.transform = `translate(${newLeft}px,-${tableHeight}px)`;                
                setSelectColumnStart(e.target.innerText);      
            }

            if(newLeft >= targetRight){
                target.style.width = `${targetWidth+baseWidth}px`;
                target.style.transform = `translate(${targetLeft}px,-${tableHeight}px)`;                
                setSelectColumnEnd(e.target.innerText);      
            }
               
            if( newLeft <= targetRight - baseWidth && newLeft >= originalLeft){
                target.style.width = `${targetWidth-baseWidth}px`;
                target.style.transform = `translate(${originalLeft}px,-${tableHeight}px)`;                
                setSelectColumnEnd(e.target.innerText);      
            }
        }    
    }

    const onMouseUp = (e) => {
        let selectedEl = document.getElementById('columnSelected');
        if(selectedEl){
            selectedEl.setAttribute('finish',true);
            if(selectColumnStart === selectColumnEnd){
                setSelectData(datalinkTableName+"."+selectColumnStart);                 
            }else{
                setSelectData(datalinkTableName+"."+selectColumnStart+":"+selectColumnEnd);                 
            }
        }
        
    }

    const renderHeader = (item) => {
      return (
        <div onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseOver={onMouseOver}>
            {item.column.name}
        </div>
    )}

    const treeNodes = loop(treeData);

    return (
        <MISDialog
            dialog={dialogProps}
            buttons={{
                rightButtons: [
                    {
                        title: t("COM_BUTTON_SAVE"),
                        onClick: ()=> {
                            if(selectData === ''){
                                toastr.error(t("COM_MESSAGE_DEVICE_SELECT_ITEM_P"));
                            }else{
                                onApply(selectData, selectedUrl, serverAddress);
                            }      
                        }
                    },
                    {
                        title: t("BUTTON_CANCEL_P"),
                        onClick: onClose
                    }
                ]
            }}
        >
            <div className="popup_contents" style={{ padding: "10px 20px" }} onMouseUp={onMouseUp}>
                <div className="pop_list rulsetDatalinkWrap" style={{overflowY: "hidden", height: "460px",border: "1px solid rgb(231, 231, 231)"}}>
                        <div
                            id="datalinkTree"
                            className="float_l"
                            style={{ width: "29%", height: "460px", overflow: "auto", borderRight: "1px solid rgb(231, 231, 231)" }}>                                                
                            { treeData.length > 0 &&
                                <div className={"ruleset" + " " + type} style={{}}>
                                    <Tree
                                        style={{'display':'block'}}
                                        showIcon={false}                                                                                                
                                        onSelect={(key, event) => onSelect(key, event)}     
                                        defaultExpandAll={false}
                                        selectedKeys={selected}
                                        loadedKeys={selected}
                                    >
                                    {treeNodes}
                                </Tree>
                            </div>}
                        </div>
                   
                    <div id="datalinkTable" className="float_r" style={{ height: "100%", overflow: "auto", width:  "70%", marginTop: "5px", display : 'inline' }} >
                   <div id="DataLinkContainer">                   
                   {
                        loading &&
                        <div style={{width:'100%', height:'100%', background: '#fff', display: 'flex'}}><Loading /></div>
                    }
                    {
                        !loading && serverAddress ==='' && <span>{t("MIS_MESSAGE_COMMON_SELECT_DATALINK_TABLE_P")}</span>
                    }    
                   {!loading && serverAddress !=='' && rows !== undefined && rows.length > 0 && columns !== undefined && columns.length > 0 &&
                    <ReactDataGrid 
                        columns={columns} 
                        minColumnWidth={30} 
                        rowGetter={i=> rows[i]} 
                        rowsCount={rows.length} 
                        minWidth={580}                        
                        cellRangeSelection={{
                            onStart: args => {
                                let target = document.getElementById('columnSelected');
                                if(target){
                                    target.remove();
                                }
                            },
                            onComplete: args => {                            
                                const startRow = args.topLeft.rowIdx;
                                const endRow = args.bottomRight.rowIdx;
                                const startColumn = args.topLeft.idx;
                                const endColumn = args.bottomRight.idx;
                                let target = document.getElementById('columnSelected');                                
                                if(target === null){                             
                                    if(args.topLeft.idx !== -1 && args.bottomRight.idx !== -1){
                                        if((startRow === endRow) && (startColumn === endColumn)){
                                            setSelectData(datalinkTableName +"."+layout[startColumn]["data"]+"."+startRow);                                            
                                        }else if(startColumn === endColumn){
                                            setSelectData(datalinkTableName +"."+layout[startColumn]["data"]+"."+startRow+":"+endRow);                                            
                                        }else{ 
                                            setSelectData(datalinkTableName+"."+layout[startColumn]["data"]+":"+layout[endColumn]["data"]+"."+startRow+":"+endRow);                                            
                                        }
                                    }                                    
                                }     
                            }
                        }} /> }
                    </div>
                                  
                </div>
            </div>                
        </div>        
        </MISDialog>
    );
};

export default SelectDatalinkTablePopup;
