import React from 'react';
import {
    defaultADContentSchedule,
    defaultCondition,
    defaultContentSchedule,
    defaultEventConditionForSchedule,
    defaultEventSchedule,
    defaultMessage,
    defaultMessageSchedule,
    scheduleConstants
} from "../constants";

const initialState  = {
    /*newMessage: {},
    editMessage: {},*/
    messages:{},
    events: {
        condition: {
            new: {},
            edit: {}
        }
    },
    contents:{
        simpleSchedule: [],
        new: {},
        edit: {},
        current: {
            mode: '',
        }
    }
}


export const schedules = (state = initialState, action) => {
    const {newMessage, editMessage} = state.messages;
    switch (action.type) {

        case scheduleConstants.INIT_CONTENT_SCHEDULE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                const contentSchedule = defaultContentSchedule();
                contentSchedule.program.deviceType = action.device.deviceType;
                contentSchedule.program.deviceTypeVersion = action.device.deviceTypeVersion;
                contentSchedule.program.priority = action.device.priority;
                contentSchedule.program.programType = action.programType;
                contentSchedule.program.deviceTags = action.deviceTags !== undefined ? action.deviceTags : [];
                contentSchedule.program.deviceGroups = action.program !== undefined && action.program.deviceGroups !== undefined ? action.program.deviceGroups : []
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        new: contentSchedule
                    }
                }
            }  else {
                const channelNo = action.program.channels[0].channelNo;
                const frameId = action.program.channels[0].frame.frameId;
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        edit: {
                            currentFrameId: frameId,
                            currentChannelNo: channelNo,
                            program: {
                                ...action.program
                            }
                        }
                    }
                }
            }
        case scheduleConstants.UPDATE_CONTENT_SCHEDULE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        new: action.program
                    }
                }
            } else {
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        edit: action.program
                    }
                }
            }

        case scheduleConstants.DESTROY_CONTENT_SCHEDULE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        new: {}
                    }
                }
            } else {
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        edit: {}
                    }
                }
            }

        case scheduleConstants.INIT_NEW_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    newMessage: {
                        message: {...defaultMessageSchedule(action.device.deviceType, action.device.deviceTypeVersion)},
                        currentMessageIndex: 0,
                        removeIndex: -1
                    }
                }
            }
        case scheduleConstants.INIT_EDIT_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    editMessage: {
                        message: {...action.message},
                        currentMessageIndex: 0,
                        removeIndex: -1
                    }
                }

            }
        case scheduleConstants.ADD_MESSAGE:
            let message  = defaultMessage;
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        newMessage: {
                            ...newMessage,
                            message: {
                                ...newMessage.message,
                                messageList: newMessage.message.messageList.concat(message)
                            }
                        }
                    }

                }
            } else {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        editMessage: {
                            ...editMessage,
                            message: {
                                ...editMessage.message,
                                messageList: editMessage.message.messageList.concat(message)
                            }
                        }
                    }

                }
            }
        case scheduleConstants.LOAD_MESSAGE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        newMessage: {
                            ...newMessage,
                            currentMessageIndex: newMessage.message.messageList.findIndex((message, index)=> index === action.messageId)
                        }
                    }

                }
            } else {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        editMessage: {
                            ...editMessage,
                            currentMessageIndex: editMessage.message.messageList.findIndex((message, index)=> index === action.messageId)
                        }
                    }
                }
            }
        case scheduleConstants.SET_REMOVE_INDEX:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        newMessage: {
                            ...newMessage,
                            removeIndex: action.index
                        }
                    }

                }
            } else {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        editMessage: {
                            ...editMessage,
                            removeIndex: action.index
                        }
                    }

                }
            }

        case scheduleConstants.REMOVE_MESSAGE:

            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                let currentMessageIndex = newMessage.currentMessageIndex;
                if (newMessage.message.messageList.length-1 <= currentMessageIndex) {
                    currentMessageIndex = 0;
                }
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        newMessage: {
                            ...newMessage,
                            currentMessageIndex: currentMessageIndex,
                            message: {
                                ...newMessage.message,
                                messageList: newMessage.message.messageList.filter((msg,index)=> index!==action.messageId)
                            }
                        }
                    }

                }
            } else {
                let currentMessageIndex = editMessage.currentMessageIndex;
                if (editMessage.message.messageList.length-1 <= currentMessageIndex) {
                    currentMessageIndex = 0;
                }
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        editMessage: {
                            ...editMessage,
                            currentMessageIndex: currentMessageIndex,
                            message: {
                                ...editMessage.message,
                                messageList: editMessage.message.messageList.filter((msg,index)=> index!==action.messageId)
                            }
                        }
                    }

                }
            }
        case scheduleConstants.UPDATE_MESSAGE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        newMessage: {
                            ...newMessage,
                            message: action.message
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        editMessage: {
                            ...editMessage,
                            message: action.message
                        }
                    }

                }
            }

        case scheduleConstants.DESTROY_MESSAGE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        newMessage: {}
                    }

                }
            } else {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        editMessage: {}
                    }

                }
            }

        case scheduleConstants.INIT_NEW_EVENT_CONDITION:
            return {
                ...state,
                events: {
                    ...state.events,
                    condition: {
                        ...state.events.condition,
                        new: action.event
                    }
                }
            }
        case scheduleConstants.INIT_EDIT_EVENT_CONDITION:
            return {
                ...state,
                events: {
                    ...state.events,
                    condition: {
                        ...state.events.condition,
                        edit: action.event
                    }
                }
            }

        case scheduleConstants.ADD_EVENT_CONDITION:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        condition: {
                            ...state.events.condition,
                            new: {
                                ...state.events.condition.new,
                                eventConditions: state.events.condition.new.eventConditions.concat(defaultCondition())
                            }
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        condition: {
                            ...state.events.condition,
                            edit: {
                                ...state.events.condition.edit,
                                eventConditions: state.events.condition.edit.eventConditions.concat(defaultCondition())
                            }
                        }
                    }
                }
            }

        case scheduleConstants.REMOVE_EVENT_CONDITION:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        condition: {
                            ...state.events.condition,
                            new: {
                                ...state.events.condition.new,
                                eventConditions: state.events.condition.new.eventType === 'BOOL' ? [] : state.events.condition.new.eventConditions.filter(event=> event.eventConditionId!== action.id)
                            }
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        condition: {
                            ...state.events.condition,
                            edit: {
                                ...state.events.condition.edit,
                                eventConditions: state.events.condition.edit.eventType === 'BOOL' ? [] : state.events.condition.edit.eventConditions.filter(e => e.eventConditionId!==action.id)
                            }
                        }
                    }
                }
            }
        case scheduleConstants.UPDATE_EVENT_CONDITION:
            if (action.mode  === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        condition: {
                            ...state.events.condition,
                            new: action.eventCondition
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        condition: {
                            ...state.events.condition,
                            edit: action.eventCondition
                        }
                    }
                }
            }

        case scheduleConstants.INIT_EVENT_SCHEDULE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                const newDefaultEvent = defaultEventSchedule();
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            new: newDefaultEvent
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            edit: {
                                ...action.event,
                                conditionList: action.event.selectedEventList,
                                currentEventConditionIndex: 0
                            }
                        }
                    }
                }
            }
        case scheduleConstants.ADD_EVENT_SCHEDULE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE){
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            new: {
                                ...state.events.schedules.new,
                                conditionList: state.events.schedules.new.conditionList.concat(defaultEventConditionForSchedule),
                                selectedEventList: state.events.schedules.new.selectedEventList.concat({})
                            }
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            edit: {
                                ...state.events.schedules.edit,
                                conditionList: state.events.schedules.edit.conditionList.concat(defaultEventConditionForSchedule),
                                selectedEventList: state.events.schedules.edit.selectedEventList.concat({})
                            }
                        }
                    }
                }
            }

        case scheduleConstants.REMOVE_EVENT_SCHEDULE:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE){
                let {currentEventConditionIndex} = state.events.schedules.new;
                if (state.events.schedules.new.conditionList.length-1 <= currentEventConditionIndex) {
                    currentEventConditionIndex = 0;
                }
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            new: {
                                ...state.events.schedules.new,
                                currentEventConditionIndex: currentEventConditionIndex,
                                conditionList: state.events.schedules.new.conditionList.filter((event, index)=> index!== action.id),
                                selectedEventList: state.events.schedules.new.selectedEventList.filter((condition,index)=> index !== action.id)
                            }
                        }
                    }
                }
            } else {
                let {currentEventConditionIndex} = state.events.schedules.edit;
                if (state.events.schedules.edit.conditionList.length-1 <= currentEventConditionIndex) {
                    currentEventConditionIndex = 0;
                }
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            edit: {
                                ...state.events.schedules.edit,
                                currentEventConditionIndex: currentEventConditionIndex,
                                conditionList: state.events.schedules.edit.conditionList.filter((event, index)=> index!== action.id),
                                selectedEventList: state.events.schedules.edit.selectedEventList.filter((condition,index)=> index !== action.id)
                            }
                        }
                    }
                }
            }

        case scheduleConstants.LOAD_EVENT:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            new: {
                                ...state.events.schedules.new,
                                currentEventConditionIndex: action.id
                            }
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            edit: {
                                ...state.events.schedules.edit,
                                currentEventConditionIndex: action.id
                            }
                        }
                    }
                }
            }

        case scheduleConstants.UPDATE_EVENT:
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            new: action.event
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    events: {
                        ...state.events,
                        schedules: {
                            ...state.events.schedules,
                            edit: action.event
                        }
                    }
                }
            }
        case scheduleConstants.LOAD_SIMPLE_CONTENT_SCHEDULE:
            return {
                ...state,
                contents: {
                    ...state.contents,
                    simple: true
                }
            }
        case scheduleConstants.UNLOAD_SIMPLE_CONTENT_SCHEDULE:
            return {
                ...state,
                contents: {
                    ...state.contents,
                    simpleSchedule: state.contents.simpleSchedule.filter(program => program.programId !== action.programId)
                }
            }

        case scheduleConstants.ADD_SIMPLE_SCHEDULE:
            return {
                ...state,
                contents: {
                    ...state.contents,
                    simpleSchedule: state.contents.simpleSchedule.concat({programId: action.programId, programType: action.programType})
                }
            }
        case scheduleConstants.INIT_CONTENT_ADVERTISEMENT_SCHEDULE :
            if (action.mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
                const contentADSchedule = defaultADContentSchedule(action.adScheduleInfo);
                contentADSchedule.deviceType = action.device.deviceType;
                contentADSchedule.deviceTypeVersion = action.device.deviceTypeVersion;
                contentADSchedule.priority = action.device.priority;
                contentADSchedule.programType = action.programType;
                contentADSchedule.deviceGroups = action.program !== undefined && action.program.deviceGroups !== undefined ? action.program.deviceGroups : []
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        advertisement: {
                            ...state.contents.advertisement,
                            new : contentADSchedule
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        advertisement: {
                            ...state.contents.advertisement,
                            edit : action.program
                        }
                    }
                }
            }
        case scheduleConstants.UPDATE_CONTENT_AD_SCHEDULE:
            const {mode} = action;
            const updateChannels = Object.assign([], state.contents.advertisement[mode].channels);
            for (const channel of updateChannels) {
                channel.frame.adSlots = action.slots
            }
            return {
                ...state,
                contents: {
                    ...state.contents,
                    advertisement: {
                        ...state.contents.advertisement,
                        [mode]:  {
                            ...state.contents.advertisement[mode],
                            channels: updateChannels,
                            slotCount : action.slotCount !== undefined ? action.slotCount : state.contents.advertisement[mode].slotCount,
                            slotDuration : action.slotDuration !== undefined ? action.slotDuration : state.contents.advertisement[mode].slotDuration 
                        }
                    }
                }
            }
        case scheduleConstants.START_DRAG:

            return {
                ...state,
                contents: {
                    ...state.contents,
                    dragInfo: {
                        id: action.id,
                        contentType: action.contentType,
                        mediaType: action.mediaType
                    }
                }
            }
        case scheduleConstants.DESTROY_DRAG_INFO:

            return {
                ...state,
                contents: {
                    ...state.contents,
                    dragInfo: undefined
                }
            }
        default:
            return state
    }
}






