import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useInterval} from "../../helper";
import {settingService} from "../../services";

const ContentDownloadQueueTable = () => {

    const {t} = useTranslation();
    const [data, setData] = useState([]);

    useInterval(()=>{
        settingService.fetchContentDownloadThrottlingInfo()
            .then(
                res => setData(res.items.status)
            )
    }, [2000])

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>{t("COM_TABLE_DEVICE_ID_P")}</th>
                        <th>{t("TEXT_CONTENT_NAME_P")}</th>
                    </tr>
                </thead>
            </table>
            <div style={{width: '100%', height: 380, overflow: 'auto'}}>
                <table>
                    <tbody>
                    {
                        data != null && data.length > 0 &&
                        data.map(
                            row => <tr><td>{row.deviceId}</td><td>{row.fileName}</td></tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default ContentDownloadQueueTable;