import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import React from "react";
import {useTranslation} from "react-i18next";
import {bytesToSize} from "../../helper/bytesToSize";

const PlaylistTooltip = ({tooltipTop, tooltipLeft, content, playlistType}) => {
    const {t} = useTranslation();
    return (
        <div className="playlist_tooltip" style={{top: tooltipTop, left: tooltipLeft, width: 'max-content', height:'fit-content', position: 'absolute'}}>
            <div className="contentName"><span>{content.contentName || content.playlistName}</span></div>
            <div className="info">
                <p>{t("COM_MAPP_SID_SUPPORTED_DEVICES")}
                    <span className="icon" style={{marginBottom: 0,marginTop:0, float: 'right', display: 'table', width: 'auto'}}>
                        <SupportedDeviceIcon deviceType={content.deviceType}
                                             deviceTypeVersion={content.deviceTypeVersion}
                                             mediaType={content.mediaType}
                                             fileType={content.mainFileExtension}
                        />
                    </span>
                </p>
                {
                    content.mediaType && <p style={{display: 'inline-block'}}>{t("TEXT_MEDIA_TYPE_P")}
                    <span style={{marginLeft: 10}}>{content.mediaType}</span></p>
                }
                <p>{t("TEXT_TOTAL_SIZE_P")}<span style={{marginLeft: 10}}>{bytesToSize(content.totalSize)}</span>
                </p>
                {
                    playlistType !== '6' && (content.playTime && content.playTime.length > 0)  &&
                    <p>{t("TEXT_PLAY_TIME_P")}<span style={{marginLeft: 10}}>{content.playTime}</span></p>
                }
                {
                    content.effects && content.effects.startDate && content.effects.expiredDate &&
                    <p>{t("MIS_SID_20_PLAYBACK_DATE")}<span style={{marginLeft: 10}}>{content.effects.startDate}~{content.effects.expiredDate}</span></p>
                }
                {
                    content.effects && content.effects.isIndependentPlay=='Y'&&
                    <p>{t("MIS_SID_20_PLAYBACK_TIME")}<span style={{marginLeft: 10}}>{content.effects.startTime!=undefined?content.effects.startTime:'00:00:00'}~{content.effects.expiredTime!=undefined?content.effects.expiredTime:'23:59:59'}</span></p>
                }
                {
                    content.contentTag &&
                    <p style={{width: 220, height: 40, overflow: 'hidden', textOverflow: 'ellipsis'}}>{t("MIS_TEXT_TAG_P")}<span style={{marginLeft: 10}}>
                        {
                            content.contentTag.tagValues.map((tag, index)=> {
                                return tag + ( index !== content.contentTag.tagValues.length - 1 ? ', ' : '' )
                            })
                        }
                    </span></p>
                }
            </div>
        </div>
    )
}

export default PlaylistTooltip;