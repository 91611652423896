import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isNil} from 'lodash';
import './DeviceDetailView.css';
import WhiteButton from '../../../button/WhiteButton';
import Size from '../../../utils/Size';
import TextInput from '../../../input/TextInput';
import Checkbox from '../../../checkbox/Checkbox';
import './LogDataDetail.css';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import {deviceService} from '../../../../services';
import BULLET_1 from "../../../../images/icon/bullet_1.png";
import {toastr} from 'helper/toastrIntercept';
import {useCheckRefForDeviceLog as useCheckRef} from '../../../../helper';
import fileDownload from "js-file-download";

const FREE_SIZE_LB = 1024*1024*1024;
const SCRIPT_FOR_WPLAYER = 'wplayer';
const SCRIPT_FOR_PLATFORM = 'platform';

const LogDataDetail = ({device, logInfo, onChange}) => {
    const {t} = useTranslation();
    const pollingHandle = useRef();
    const retryCnt = useRef(0);
    const dispatch = useDispatch();
    const [scriptError, setScriptError] = useState(false);
    const [isCollectingNotStarted, setIsCollectingNotStarted] = useState((!isNil(logInfo)&&!isNil(logInfo.isStopped))?logInfo.isStopped:true);
    const [collectLogDesc, setCollectLogDesc] = useState(device.logCollect.collectInfo === undefined? '' : t('COM_MSG_PROCESSING'));
    const [logCollect, setLogCollect] = useState(device.logCollect.collectInfo === undefined ? {...device.logCollect, collectInfo: {duration: 1}} : {...device.logCollect});
    const [collectedLogList, setCollectedLogList] = useState(device.logCollect.collectedLogList);
    const [data, setData] = useState({
        selected: [],
        selectAll: false,
        items: [],
    });

    const {deviceId} = device;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(collectedLogList);

    useEffect(() => {
        onRefresh();
        if (!isNil(logCollect.collectInfo) && logCollect.collectInfo.startTime !== undefined)  {
            setIsCollectingNotStarted(false);
        }
    }, []);

    useEffect(()=>{
        onChange(logInfo.script, logInfo.duration, isCollectingNotStarted);
    }, [isCollectingNotStarted])

    const onClickDownload = () => {
        const filepathList = [];
        const scriptList = [];
        selected.current.forEach(selectItem => {
            filepathList.push(collectedLogList[selectItem].fileName);
            scriptList.push(collectedLogList[selectItem].script);
        });

        if(selectedCnt === 0) {
            toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
        } else {
            const parsedFilepath = filepathList.concat().toString();
            let script;
            if(scriptList.length === 1) {
                script = scriptList[0];
            } else if(scriptList.length > 1){
                script = '';
            }
            deviceService.downLoadDeviceCollectedLogs(deviceId, parsedFilepath, script).then(
                res => {
                    fileDownload(res.blob, res.fileName);
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                })
        }
    };

    const onClickEmpty = () => {
        Promise.all([deviceService.deleteDeviceCollectedLogs(deviceId, SCRIPT_FOR_WPLAYER), deviceService.deleteDeviceCollectedLogs(deviceId, SCRIPT_FOR_PLATFORM)]).then(res => {
            if(res[0].status === 'Success') {
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                setData({...data, items: [], selectAll: false});
                checkAll.current.checked = false;
                setCollectedLogList([]);
            }
        }).catch(error => {
            if(error.errorCode === '500011') {
                toastr.error(t('MESSAGE_DEVICE_NO_RESULT_FILE_P'));
            }else{
                toastr.error(error.errorMessage);
            }
            setData({...data, selectAll: false});
            checkAll.current.checked = false;
        })
    };

    const onCollect = () => {
        if(isNil(logInfo.script) || logInfo.script === '') {
            setScriptError(true);
            return;
        }

        dispatch(popupAction.addPopup({
            id: commonConstants.COLLECT_LOG_DEVICE,
            type: commonConstants.COLLECT_LOG_DEVICE,
            useMessageLine: false,
            onClickYes: () => {
                dispatch(popupAction.closePopup(commonConstants.COLLECT_LOG_DEVICE));
                dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));

                deviceService.updateDeviceLogProcess(deviceId, {
                    deviceId: deviceId,
                    chkTimeout: 1,
                    duration: logInfo.duration ? logInfo.duration : '',
                    packetSize: 1,
                    script: logInfo.script,
                    status: 'INIT',
                    step:0
                }).then(res => {
                    if (res.items.status === 'disconnect') {
                        toastr.error(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
                        setCollectLogDesc(t('MIS_SID_LOG_COLLECTION_START_WHEN_CONNECTED'));
                        setIsCollectingNotStarted(false);
                        dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                    } else if (res.items.status === 'init') {
                        toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));

                        pollingHandle.current = setInterval(() => {
                            deviceService.updateDeviceLogProcess(deviceId, {
                                deviceId: deviceId,
                                chkTimeout: 1 + retryCnt.current,
                                duration: logInfo.duration ? logInfo.duration : '',
                                packetSize: 1,
                                script: logInfo.script,
                                status: 'INIT',
                                step:1,
                                requestId: res.items.requestId
                            }).then(res => {
                                retryCnt.current++;
                                if(res.items.status === 'finished') {
                                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                                    clearInterval(pollingHandle.current);
                                    setCollectLogDesc('');
                                    if(res.items.response=='fail'){
                                        setIsCollectingNotStarted(true);
                                        toastr.error(t('ALERT_FAIL'));
                                    }else{
                                        setIsCollectingNotStarted(false);
                                    }
                                } else if (res.items.status === 'disconnect') {
                                    toastr.error(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
                                    setIsCollectingNotStarted(false);
                                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                                }

                                if(retryCnt.current === 60) {
                                    toastr.error(t('MIS_MESSAGE_TIME_OUT_P'));

                                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));

                                    clearInterval(pollingHandle.current);
                                }
                            }).catch(error => {
                                if(error !== 'Fail') {
                                    toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                                }
                            });
                        }, 1000);

                    }

                    /*deviceService.fetchDeviceById(deviceId).then(res => {
                        setLogCollect({...logCollect, collectInfo: {...res.items.logCollect}});
                     });*/

                    setScriptError(false);
                }).catch(error => {
                    toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                    clearInterval(pollingHandle.current);
                    setCollectLogDesc('');
                    setIsCollectingNotStarted(true);
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                }).finally(() => {
                    dispatch(popupAction.closePopup(commonConstants.COLLECT_LOG_DEVICE));
                });

                setLogCollect({...logCollect});
            }, 
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COLLECT_LOG_DEVICE))
        }));
    };

    const onStop = () => {
        deviceService.updateDeviceLogProcess(deviceId, {
            deviceId: deviceId,
            chkTimeout: 100,
            duration: logInfo.duration ? logInfo.duration : '',
            packetSize: 1,
            script: logInfo.script,
            status: 'END',
            step:0
        }).then(res => {
            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            if (res.items.status === 'end') {
                setCollectLogDesc('');
                setIsCollectingNotStarted(true);
                deviceService.fetchDeviceById(deviceId).then(res => {
                    setLogCollect({...logCollect, ...res.items.logCollect});
                });
                onRefresh();
            } else if(res.items.status === 'finished') {
                setCollectLogDesc('');
                setIsCollectingNotStarted(false);
            }
        }).catch(error => {
            toastr.error(t('MESSAGE_COMMON_ERROR_P'));
            setCollectLogDesc('');
            setIsCollectingNotStarted(true);
        })
    };

    const onRefresh = () => {
        dispatch(popupAction.addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP}));
        deviceService.fetchDeviceCollectedLogs(deviceId).then(res => {
            const filtered = res.items.filter(item => item.type === SCRIPT_FOR_WPLAYER || item.type === SCRIPT_FOR_PLATFORM);
            setData({...data, items: filtered, selectAll: false});
            setCollectedLogList(filtered);
        }).catch(error => {
            toastr.error(error);
        }).finally(() => {
            dispatch(popupAction.closePopup('EDIT_DEVICE_LOADING'));
        });
    };

    const onChangeScript = e => {
        setLogCollect({...logCollect, collectInfo: {...logInfo, script: e.target.value}});
        onChange(e.target.value, logInfo.duration, isCollectingNotStarted);
    };

    const onChangeDuration = e => {
        setLogCollect({...logCollect, collectInfo: {...logInfo, duration: e.target.value.replace(/[^0-9]/g, '')}});
        onChange(logInfo.script, e.target.value.replace(/[^0-9]/g, ''), isCollectingNotStarted);
    };

    const collectDisabled = (isNil(device.logCollect.collectInfo) && device.logCollect.availableCnt < 1) || (device.logCollect.freeSizeLong < FREE_SIZE_LB);

    return (
        <div className="detail_view mini_8 mt15" style={{overflow:'hidden'}}>
            <div style={{height:85,paddingTop:5}}>
                <table style={{width:'99%'}}>
                    <tbody>
                        <tr>
                            <td style={{padding:0,height:25,width:'90%'}}>
                                <div className="detail_table_title float_l" style={{wordWrap:'break-word',whiteSpace:'initial'}}>
                                    <img src={BULLET_1} />&nbsp;
                                    <span><strong>{t('MIS_SID_SEVER_PERMOFMANCE_AFFCTED_FUCNTION')}</strong></span>
                                </div>
                            </td>
                            <td rowSpan="3" style={{width:'10%'}}>
                                <div style={{position:'absolute', right:0}}>
                                {
                                    isCollectingNotStarted&&
                                    <WhiteButton id='logCollectBtn' name={t('MIS_SID_20_COLLECT_LOG')} onClick={onCollect} disable={collectDisabled} />
                                }
                                {
                                    !collectDisabled && !isCollectingNotStarted &&
                                    <WhiteButton id='logStopBtn' name={t('COM_BUTTON_STOP_P')} onClick={onStop} />
                                }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding:0,height:25}}>
                                <span className="detail_table_title float_l">
                                    <img src={BULLET_1} />&nbsp;
                                    <strong>{t('TABLE_DISK_SPACE_AVAILABLE_P')} : <Size size={logCollect.freeSizeLong} /></strong>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding:0,height:25}}>
                                <span className="detail_table_title float_l" style={{color:'#DD6B6B'}} id="logCollectErrorMsg">
                                    {
                                        logCollect.freeSizeLong < FREE_SIZE_LB &&
                                        <strong>{t('COM_TV_SID_NOT_ENOUGH_STORAGE_SPACE_MSG')}</strong>
                                    }
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <span className="detail_table_title float_l" style={{fontSize:15}}>{t('COM_TEXT_SETTING_P')}</span>
            </div>
            <div className="detail_table mt9" id="logCollectSettings">
                <table>
                    <colgroup>
                        <col width="237px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>{t('MIS_SID_LOG_SCRIPT')}</th>
                        <td>
                            <textarea id="logCollectScript" propertyName='script' value={logInfo.script} onChange={onChangeScript} style={{width:310,height:40,resize:'none',overflowX:'hidden',overflowY:'auto'}}></textarea>
                            {
                                scriptError &&
                                <div>
                                    <span className="error_txt2">{t('MESSAGE_SCHEDULE_ENTER_MESSAGE_HERE_P')}</span>
                                </div>
                            }
                        </td>
                    </tr>
                    <tr style={{display:'none'}}>
                        <th>{t('COM_SID_START_TIME')}</th>
                        <td>
                            <div className="input_date float_l mr8">
                                <label htmlFor="logCollectStartDate"></label>
                                <input type="text" className="input_date hasDatepicker" style={{width:75}} value="" id="logCollectStartDate" readOnly="" />
                                <button type="button" className="ui-datepicker-trigger"></button>
                            </div>
                            <div>
                                <input type="text" className="scheduleStatTime is-timeEntry" style={{textAlign:'center',width:100}} id="logCollectStartTime" value="00:00:00" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('MIS_SID_LOG_COLLECTION_PREIOD')}</th>
                        <td>
                            <TextInput width={100} maxLength={9} propertyName='duration' value={logInfo.duration} onChange={onChangeDuration} wrapperStyle={{float: 'left', marginRight: 8}} />
                            <span className="space float_l">{t('COM_SID_HOURS')}</span>
                        </td>
                    </tr>
                    <tr style={{display:'none'}}>
                        <th>{t('MIS_SID_20_PACKET_SIZE')}</th>
                        <td>
                            <div className="select_box float_l mr8" id="logCollectSizeBox">
                                <button className="base un_radius select" style={{width:100}} value="1">
                                    <span id="logCollectSize" value="1">1<span className="arrow"></span></span>
                                </button>
                                <div className="option_list tag_list" style={{display: 'none'}} id="logCollectSizeList">
                                    <div><span></span>
                                        <ul>
                                            <li><a href="#" id="1">1</a></li>
                                            <li><a href="#" id="2">2</a></li>
                                            <li><a href="#" id="3">3</a></li>
                                            <li><a href="#" id="4">4</a></li>
                                            <li><a href="#" id="5">5</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <span className="space float_l">MB</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{margin: '15px 0px 37px 0'}}>
                <span className="detail_table_title" style={{fontSize:15, verticalAlign: "middle", marginTop:5}}>{t('MIS_SID_20_COLLECTED_LOGS')}</span>
                {
                    !isNil(logCollect.collectInfo) && !isNil(collectLogDesc) && 
                    <span className="detail_table_title log_collect_desc" style={{color:'#3196ff', marginTop:5}} id="logCollectStatusMsg">
                        <strong>{collectLogDesc}</strong>
                    </span>
                }
                <div style={{display:'inline-block', float:'right'}}>
                    <button id="logCollectRefreshBtn" className="replash2 float_r" onClick={onRefresh} style={{marginTop:5}}></button>
                    <WhiteButton id='logCollectClearBtn' name={t('MIS_SID_EMPTY_STORAGE')} style={{float: 'right', marginRight: '8px', marginTop:5}} onClick={onClickEmpty} />
                    <WhiteButton id='logCollectDownloadBtn' name={t('BUTTON_DOWNLOAD_P')} style={{float: 'right', marginRight: '8px', marginTop:5}} onClick={onClickDownload} />
                </div>
            </div>
            <div className="detail_table log_list list_wrap log_collect_table mt9">
                <table id="logCollectFilesTable" width="99%;">
                    <colgroup>
                        <col width="30px;"/>
                        <col width="30%"/>
                        <col width="50%"/>
                        <col width=""/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <Checkbox id='list_check_all_logCol'
                                          classname={"table"}
                                          name={"check"}
                                          onChange={toggleSelectAll}
                                          ref={checkAll}
                                />
                            </th>
                            <th>{t('TABLE_LAST_MODIFIED_DATE_P')}</th>
                            <th>{t('TEXT_AD_FILE_NAME_P')}</th>
                            <th>{t('COM_SID_SIZE_M_FILE_SIZE')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            collectedLogList && collectedLogList.length > 0 &&
                            collectedLogList.map((log, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <Checkbox id={'logCheck' + index}
                                                      classname={"table"}
                                                      name={"check"}
                                                      index={index}
                                                      onChange={toggleRow}
                                                      ref={setCheckBoxRefs}
                                            />
                                        </td>
                                        <td>
                                            <span>{log['modifiedDate']}</span>
                                        </td>
                                        <td>
                                            {log['fileName']}
                                        </td>
                                        <td>
                                            <Size size={log['size']}/>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

LogDataDetail.defaultProps = {
    device: {},
};

export default LogDataDetail;