import React, {useEffect, useState} from 'react';

import './Insight.css';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {insightService} from '../../services';

import {toastr} from 'helper/toastrIntercept';
import {history} from "../../helper";
import {isNil} from "lodash";
import WhiteButton from "../../components/button/WhiteButton";
import INSIGHT_SAMPLE from '../../images/bg/img_insight.svg';
import https from 'https';


const Insight = (props) => {
    const {t} = useTranslation();

    const [url, setUrl] = useState('');
    const [active, setActive] = useState(false);
    const [loadedUrl, setLoadedUrl] = useState(false);

    const {menus} = useSelector(state => state.menu)
    const width = '100%';
    const height = '100%';
    const frameboarder = 0;

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(()=> {
        if (!isNil(menus)) {
            const found = menus.find(m => m.id === 'INSIGHT');
            const {active = false} = found;
            setActive(active);
            if (active) {
                fetchData();
            }
        }
    }, [menus])

    const fetchData = () => {
        insightService.getInsightServerInformation()
        .then(data => {
            const header = new Headers({
                'Access-Control-Allow-Origin':'*'
            });
            const option = {
                method: 'GET',
                header: header,
                mode: 'no-cors'
            };

            /*
                organizationId = Device Organization ID
                userOrganizationId = User Organization ID
             */
            const {userId, hash, time, lifetime, insightUrl, lang, organizationId, read, manage, userOrganizationId} = data.items;
            // let url = `${insightUrl}?userId=${userId}&hash=${hash}&time=${time}&lifetime=${lifetime}&lang=${lang}`;
            // let url = `${insightUrl}/HttpsEntry`;
            let url = `${insightUrl}/httpsEntry_${lang}.html`;


            if(insightUrl.indexOf("https") >= 0) {
                fetch(url, option)
                    .then((response) => {
                        setUrl(
                            `${insightUrl}?userId=${userId}&hash=${hash}&time=${time}&lifetime=${lifetime}&lang=${lang}&organizationId=${organizationId}&userOrganizationId=${userOrganizationId}&read=${read}&manage=${manage}`
                        );
                    })
                    .catch((error) => {
                        window.open(url, "_blank");
                        history.go('/');
                    })
            } else {
                setUrl(
                    `${insightUrl}?userId=${userId}&hash=${hash}&time=${time}&lifetime=${lifetime}&lang=${lang}&organizationId=${organizationId}&userOrganizationId=${userOrganizationId}&read=${read}&manage=${manage}`
                );
            }
        })
        .catch(error => {
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        })
    }

    const handleOnClick = () => {
        const url = 'https://displaysolutions.samsung.com/solutions/signage-solution/magicinfo/add-on';
        window.open(url, '_blank');
    }

    useEffect(()=> {
        if (url != '') {
            setLoadedUrl(true);
        }
    }, [url])

    return (
        <>
        {
            active ?
                <div className="insightWrap" style={{display: props.currContent === 'INSIGHT' ? 'block':'none'}}>
                    {
                        loadedUrl ?
                            <iframe title="INSIGHT" src={url} frameBorder={frameboarder} width={width} height={height} ></iframe>
                            : ''
                    }

                </div>
                :
                <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, textAlign: 'center'}}>
                    <div style={{width: 576, height: 600, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', lineHeight: 1}}>

                        <div style={{fontSize: 32, color: '#454545'}}>{t('MIS_SID_SERVER_CBMAR_START_MAGICINFO_INSIGHT_NOW')}</div>
                        <div style={{marginTop: 40}}>
                            <img src={INSIGHT_SAMPLE} />
                        </div>
                        <div style={{marginTop: 30, fontSize: 13, color: '#454545'}}>
                            {t('MIS_SID_SERVER_CBMAR_MAGICINFO_PROVIDES_CLEAN_MSG')}
                        </div>
                        <div style={{fontSize: 13, color: '#454545', marginTop: 10}}>
                            {t('MIS_SID_SERVER_CBMAR_NEED_PURCHASE_MAGICINFO_INSIGHT_LICENSE')}
                        </div>
                        <div style={{marginTop: 30}}>
                            <WhiteButton name={t('COM_TV_SID_ACCOUNT_VIEW_DETAILS')} style={{width: 140}} onClick={handleOnClick}/>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default Insight;
