import React from 'react'
import URLIcon from '../../../images/icon/file_type_icon_15.png';
import classNames from "classnames";

const URL = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={URLIcon}/>
            {
                !onlyIcon && 'URL'
            }
        </span>
    )
}
export default URL;