import React from 'react';
import Moment from 'moment';

import {useTranslation} from "react-i18next";
import WhiteButton from "../../../button/WhiteButton";
import SelectedDevices from "../../../input/SelectedDevices";
import {popupAction} from "../../../../actions";
import {commonConstants} from "../../../../constants";
import {cloneDeep} from "lodash";
import {useDispatch} from "react-redux";
import DateToStr from "../../../utils/DateToStr";
import TextInput from "../../../input/TextInput";

const EventScheduleDetail = ({event, index = 0, updateEvent}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const toTime = (second) => {
        if (second !== undefined) {
            const date = Moment().startOf('day').seconds(second);
            return date.format('H')+' '+t("COM_TEXT_HOUR_P")+ date.format('m')+' '+t("MIS_SID_20_MINUTE_S");
        }
        return 0;
    }

    const getContentName = () => {
        if (event.selectedEventList[index] !== undefined && event.selectedEventList[index].eventInfo !== undefined && event.selectedEventList[index].eventInfo.length > 0 &&
            event.selectedEventList[index].startConditionIndex > -1 && event.selectedEventList[index].eventInfo[event.selectedEventList[index].startConditionIndex] !== undefined &&
            event.selectedEventList[index].eventInfo[event.selectedEventList[index].startConditionIndex].contentName !== undefined){
            return event.selectedEventList[index].eventInfo[event.selectedEventList[index].startConditionIndex].contentName;
        }

        if (event.selectedEventList[index] !== undefined && event.selectedEventList[index].eventInfo !== undefined && event.selectedEventList[index].eventInfo.length > 0 &&
            event.selectedEventList[index].endConditionIndex > -1 && event.selectedEventList[index].eventInfo[event.selectedEventList[index].endConditionIndex] !== undefined &&
            event.selectedEventList[index].eventInfo[event.selectedEventList[index].endConditionIndex].contentName !== undefined){
            return event.selectedEventList[index].eventInfo[event.selectedEventList[index].endConditionIndex].contentName;
        }
        return '';
    }

    const showGroupPopup = () => {
        //setGroupPopup(!groupPopup);

        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: commonConstants.COMMON_GROUP_POPUP,
            mode: 'event_schedule',
            save: (groups)=>handleProgramGroup(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }));
    }


    const handleProgramGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            const {groupId, groupName, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                const updatedProgram = cloneDeep(event);
                updatedProgram.eventGroupId = groupId;
                updatedProgram.eventGroupName = groupName;

                updateEvent(updatedProgram);

                dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
            }
        }
    }

    const handleData = (e, name) => {
        const value = e.target.value;
        const updatedProgram = Object.assign({}, event);
        updatedProgram[name] = value;
        updateEvent(updatedProgram);
    }


    return (
        <div className="detail_view mini_p mt8">
            <table>
                <colgroup>
                    <col width="116px" />
                    <col width="" />
                </colgroup>
                <tbody>
                <tr>
                    <th><span className="popupTh">{t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME")}</span></th>
                    <td>
                        {/*<span>{event !== undefined && event.eventScheduleName}</span>*/}
                        <TextInput width={250} maxLength={90} value={event !== undefined && event.eventScheduleName} onChange={(e)=>handleData(e, 'eventScheduleName')} />
                    </td>
                </tr>

                <tr>
                    <th><span className="popupTh">{t("COM_TEXT_MODIFY_DATE_P")}</span></th>
                    <td>
                        <span><DateToStr date={event !== undefined && event.lastModifiedDate} /></span>
                    </td>
                </tr>

                <tr>
                    <th><span className="popupTh">{t("TEXT_SCHEDULE_GROUP_P")}</span></th>
                    <td>
                        <WhiteButton width={250} name={event !== undefined && event.eventGroupName} onClick={showGroupPopup}/>
                    </td>
                </tr>
                <tr>
                    <th><span className="popupTh">{t("TEXT_DEVICE_GROUP_P")}</span></th>
                    <td>
                        <SelectedDevices groups={event !== undefined && event.deviceGroups}/>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
    )
}
export default EventScheduleDetail;