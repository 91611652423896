import React from 'react';
import {isNil} from 'lodash';
import Select from '../selectbox/Select';
import TextInput from '../input/TextInput';
import './SettingInformation.css';
import WhiteButton from "../../components/button/WhiteButton";
import Checkbox from "../../components/checkbox/Checkbox";
import NumberInput from '../input/NumberInput';
import { useTranslation } from 'react-i18next';

const SettingInformation = ({id, title, list, action, buttons, comment, explain}) => {
    const {t} = useTranslation();
    const validateValue = (value) => {
        if(value == undefined || isNil(value)){
            return '';
        }
        return value;
    }
    return (
        <div className={"setting_container"} id={id}>
            <h3>{title}{!isNil(comment) && <span className={'comment'}><span className={'red'}>*</span>{comment}</span>}</h3>
            <div className={"setting_wrap"}>
                { explain &&
                <div style={{marginLeft :16,marginTop:20, marginBottom:20}}>{explain}</div>
                }
                <table id="editTable">
                    <colgroup>
                        <col width="205px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {list && list.length > 0 && list.map(
                            info => <tr key={info.id}><th className={info.changed?"changed":""}>{info.name}{!isNil(info.mandatory)&& info.mandatory && <span className={'red'}>*</span>}</th><td>
                            {
                                (isNil(info.type) || info.type == 'view') &&
                                <>{info.value}</>
                            }    
                            {
                                (!isNil(info.type) && info.type == 'select') &&
                                <Select width={200} propertyName={info.id} selects={info.option} title={info.value} value={info.value} onChange={info.action} multiLang={false} />
                            }
                            {
                                (!isNil(info.type) && info.type == 'input') &&
                                <TextInput propertyName={info.id} value={info.value} onChange={(e) => info.action(e)} />
                            }
                            {
                                (!isNil(info.type) && info.type == 'password') &&
                                <TextInput autoComplete={"off"} propertyName={info.id} type={"password"} placeholder={t("TEXT_PASSWORD_P")} value={info.value} onChange={(e) => info.action(e)} />
                            }
                            {
                                (!isNil(info.type) && info.type == 'checkbox') &&
                                <Checkbox propertyName={info.id} name={info.name} onChange={(e) => info.action(e)} checked={info.check}/>
                            }
                            {
                                (!isNil(info.type) && info.type == 'number') &&
                                <NumberInput value={info.value} onChange={(e) => info.action(e)} min={info.min} max={info.max} />
                            }
                            </td></tr>
                        )}
                        {
                            !isNil(buttons) && buttons.length > 0 &&
                            <tr><td colSpan="2"><div  className="rightButton float_r mr8">
                            {
                                buttons.map(
                                    button => 
                                    <WhiteButton key={button.id} name={button.name}  onClick={() => button.action()} disable={button.disable!=undefined?button.disable:false}/>
                                )
                            }      
                             </div></td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>

    )
}


export default SettingInformation;
