import React, {Component, useEffect, useRef, useState} from "react";
import Moment from "moment";
import {dashboardService} from "../../../../services";
import {toastr} from "../../../../helper/toastrIntercept";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {Line} from "react-chartjs-2";
import {useTranslation, withTranslation} from "react-i18next";
import './ConnectionRealTimeChart.css';


class ConnectionRealTimeChart extends Component {

    state = {
        currentDeviceConnectionDate: null,
        currentDeviceConnectionInfo: {},
        connectionTimes: [],
        connectionCounts: [],
    };

    constructor(props) {
        super(props);
        this.onTimer = this.onTimer.bind(this);
        this.initTime = this.initTime.bind(this);
    }

    componentDidMount() {
        this.charElement = document.getElementById('line-chart');
        this.deviceConnectionInterval = setInterval(this.onTimer, 1000);
        this.initTime();
    }

    componentWillUnmount() {
        clearInterval(this.deviceConnectionInterval);
    }

    initTime() {
        let initConnectionTimes = [];
        let initConnectionCounts = [];
        for (let index = 0; index < 300; index++) {
            initConnectionTimes.push(null);
            initConnectionCounts.push(null);
        }
        this.setState({
            connectionTimes: initConnectionTimes,
            connectionCounts: initConnectionCounts,
        });
    }

    onTimer() {
        let currentDate = Moment(new Date()).format('HH:mm:ss');
        this.fetchGetCurrentDeviceConnectionInfo(currentDate);
    }

    fetchGetCurrentDeviceConnectionInfo = (date) => {
        dashboardService.fetchCurrentDeviceConnectionInfo().then(res => {
            let connectionTimes = this.state.connectionTimes;
            let connectionCounts = this.state.connectionCounts;
            connectionTimes.splice(0, 1);
            connectionCounts.splice(0, 1);
            connectionTimes.push(date);
            connectionCounts.push(res.items.connectionCount);
            this.setState({
                currentDeviceConnectionDate: date,
                currentDeviceConnectionInfo: res.items,
                connectionTimes: connectionTimes,
                connectionCounts: connectionCounts,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err));
        });
    };



    renderChart() {
        let charData = {
            labels: [],
            datasets: [],
        }
        let tick = {beginAtZero: true, min: 0, max: 10, fontSize: 10, fontColor: '#909090'};
        if (this.charElement) {
            let chart = this.charElement.getContext('2d');
            let {connectionTimes, connectionCounts} = this.state;

            charData.labels = connectionTimes;

            /*let gradient = chart.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, 'rgba(128, 171, 224, 0.7)');
            gradient.addColorStop(1, 'rgba(128, 171, 224, 0)');*/
            let connectionDataset = {
                label: "Connection",
                data: connectionCounts,
                borderColor: '#0381FE',
                backgroundColor: 'rgba(3,129,254,0.2)',
                borderWidth: 2,
            };
            charData.datasets.push(connectionDataset);
            let max = Math.max(...connectionCounts);
            if (max > 10) {
                tick = {beginAtZero: true, fontSize: 10, fontColor: '#909090'};
            }
        }
        return (
            <div style={{height: 195}}>
                <Line id='line-chart' data={charData} redraw={true}
                      options={{
                          maintainAspectRatio: false,
                          legend: {
                              display: false,
                          },
                          animation: {
                              duration: 0
                          },
                          scales: {
                              xAxes: [{
                                  display: false,
                                  ticks: {
                                      fontColor: '#454545',
                                      fontSize: 10,
                                  }
                              }],
                              yAxes: [{
                                  gridLines: {
                                      drawBorder: false
                                  },
                                  ticks: tick,
                              }]
                          },
                          elements: {point: {radius: 0}},
                          tooltips: {
                              displayColors: false,
                              xPadding: 10,
                              yPadding: 10,
                              callbacks: {
                                  title: function (tooltipItems, data) {
                                      return null;
                                  },
                                  label: function (tooltipItem, data) {
                                      return tooltipItem.value;
                                  },
                              },
                          }
                      }}/>
            </div>
        );
    }

    renderLabel() {
        let {connectionTimes} = this.state;
        return (
            <div className='realtime-connected-device-guide'>
                <div className='realtime-connected-device-guide-ago'>5 minutes ago</div>
                <div className='realtime-connected-device-guide-current'>{connectionTimes[connectionTimes.length - 1]}</div>
            </div>
        );
    }

    render() {
        const {t} = this.props;
        return (
            <div style={{width: '50%', height: 230, margin: 10, display: 'inline-block'}}>
                <div style={{height: 30, lineHeight: '30px', color: '#241f20'}}>{t('MIS_SID_CONNECTED_DEVICES_REAL_TIME')}</div>
                <div style={{backgroundColor: '#F5F5F5', paddingTop: 35, marginTop: 8, paddingBottom: 11, paddingRight: 10, paddingLeft: 10}}>
                        {this.renderChart()}
                        {this.renderLabel()}
                </div>
            </div>
        )
    };
}

export default withTranslation()(ConnectionRealTimeChart);