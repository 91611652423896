import React, {useCallback, useEffect, useState} from "react";
import Notice from "./components/Notice";
import UnApprovedResource from "./components/UnApprovedResource";
import {useMISOpt} from "../../../components/misopt";
import './NoticeArea.css'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {dashboardAction, popupAction} from "../../../actions";
import {dashboardConstants} from "../../../constants";
import {isNil} from "lodash";


const NoticeArea = ({}) => {

    const {getAuthority} = useMISOpt();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {widgets} = useSelector(state => state.dashboard);

    const getAuthorities = useCallback(()=>{
        const authorities = {};
        authorities.content = getAuthority('CONTENT_PLAYLIST_RULESET');
        authorities.user = getAuthority('USER');
        authorities.device = getAuthority('DEVICE');

        return authorities;
    }, [])

    const handleWidgetsPopup = () => {
        dispatch(popupAction.addPopup({
            type: dashboardConstants.DASHBOARD_WIDGET_POPUP,
            id: dashboardConstants.DASHBOARD_WIDGET_POPUP
        }))
    }

    const refresh = () => dispatch(dashboardAction.reloadDashboard());

    const authorities =  getAuthorities();


    return (
        <div className={'notice_area'} style={{display: 'flex', height: 40, lineHeight: '40px', paddingLeft: 10}}>

            <UnApprovedResource authorities={authorities} />
            <Notice />

            {
                !isNil(widgets) && widgets.length > 0 &&
                <div style={{lineHeight: '35px', marginLeft: 5}}>
                    <button className={'dashboard_base'} title={t("MIS_SID_CBFEB_EDIT_WIDGET")} onClick={handleWidgetsPopup}>
                        <span className="dashboard_custom_add_btn"></span>
                    </button>
                </div>
            }

            <div style={{lineHeight: '35px', marginLeft: 5}} title={t("TEXT_REFRESH_P")}>
                <button className={'dashboard_base '} onClick={refresh}>
                    <span className="dashboard_refresh_btn"></span>
                </button>
            </div>

        </div>
    )

}
export default NoticeArea;