import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../language/i18n';
import {getMixString} from '../language/languageUtils';

export const tabsConstants = {

    DASHBOARD : {
        id:  'DASHBOARD',
        title: <Trans i18nKey={'COM_TV_SID_DASHBOARD'}/> ,
        active: false,
        close: false
    },
    RULESET: {
        id: 'RULESET',
        title: i18n.t('MIS_SID_RULESET'),
        active: false,
        close: true
    },
    NEW_RULESET: {
        id: 'NEW_RULESET',
        title: getMixString(['MIS_SID_MIX_NEW', 'MIS_SID_RULESET']),
        active: false,
        close: true
    },
    EDIT_RULESET: {
        id: 'EDIT_RULESET',
        title: getMixString(['MIS_SID_MIX_CAFEB_EDIT', 'MIS_SID_RULESET']),
        active: false,
        close: true
    },
    CONTENT: {
        id:  'CONTENT',
        title: <Trans i18nKey={'DID_MAIN_CONTENT'}/>,
        active: false,
        close: true
    },
    PLAYLIST: {
        id:  'PLAYLIST',
        title: <Trans i18nKey={"TEXT_TITLE_PLAYLIST_P"} /> ,
        active: false,
        close: true
    },
    NEW_PLAYLIST: {
        id:  'NEW_PLAYLIST',
        title: <Trans i18nKey={"TEXT_PLAYLIST_NEW_P"} />,
        active: false,
        close: true
    },
    EDIT_PLAYLIST: {
        id:  'EDIT_PLAYLIST',
        title: <Trans i18nKey={"TEXT_PLAYLIST_EDIT_P"} />,
        active: false,
        close: true
    },
    SCHEDULE : {
        id:  'SCHEDULE',
        title: <Trans i18nKey={"TEXT_TITLE_SCHEDULE_P"} />,
        active: false,
        close: true
    },
    NEW_SCHEDULE : {
        id:  'NEW_SCHEDULE',
        title: <Trans i18nKey={"LAYERTITLE_NEW_SCHEDULE"}/> ,
        active: false,
        close: true
    },
    NEW_MESSAGE : {
        id:  'NEW_MESSAGE',
        title: <Trans i18nKey={"MIS_SID_20_NEW_MESSAGE"}/> ,
        active: false,
        close: true
    },
    EDIT_SCHEDULE : {
        id:  'EDIT_SCHEDULE',
        title: <Trans i18nKey={"LAYERTITLE_EDIT_SCHEDULE"}/> ,
        active: false,
        close: true
    },
    DEVICE : {
        id:  'DEVICE',
        title: <Trans i18nKey={"TEXT_DEVICE_P"} /> ,
        active: false,
        close: true
    },
    STATISTICS : {
        id:  'STATISTICS',
        title: <Trans i18nKey={"COM_TEXT_TITLE_STATISTICS_P"}/> ,
        active: false,
        close: true
    },
    USER : {
        id:  'USER',
        title: <Trans i18nKey={"TEXT_TITLE_USER_P"}/>,
        active: false,
        close: true
    },
    SETTING : {
        id:  'SETTING',
        title: <Trans i18nKey={"COM_TEXT_SETTING_P"}/> ,
        active: false,
        close: true
    },
    INSIGHT : {
        id:  'INSIGHT',
        title: 'Insight',
        active: false,
        close: true
    },
    RULE_MANAGER: {
        id: 'RULE_MANAGER',
        title: <Trans i18nKey={"MIS_SID_SERVER_ANALYTICS"} />,
        active: false,
        close: true
    }
}