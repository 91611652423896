import React from 'react';
import {useTranslation} from "react-i18next";

const ContentExpirationFilterStatus = ({expirationStatusLabel}) => {

    const {t} = useTranslation();
    return (
        <span id="filter_box_expirationStatus">
            <span className="filter_status_expiration_status">
                <span>{t(expirationStatusLabel)}</span>
            </span>
        </span>
    )
}
export default ContentExpirationFilterStatus;