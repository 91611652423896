import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './TimeSelect.css';

const TimeSelect = ({id, width, value, disabled, style, format, showSecond = false, onChange, inputReadOnly = true, use12Hours = false}) => {
    const inputStyle = width ? {...style, width} : style;
    return (
        <span className='time_select_wrap'>
            <TimePicker inputClassName='time_select_input' id={id} value={value} showSecond={showSecond} format={format} allowEmpty={false}
                style={inputStyle} disabled={disabled} onChange={onChange} inputReadOnly={inputReadOnly} use12Hours={use12Hours} />
        </span>
    );
};

export default TimeSelect;