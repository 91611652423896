import React, { useState, useEffect }  from 'react'
import {isNil, get, set} from 'lodash';
import { withTranslation, Trans } from "react-i18next";
import { useTranslation } from 'react-i18next';
import {Doughnut, Pie} from "react-chartjs-2";
import { settingService } from '../../services';
import WhiteButton from "../../components/button/WhiteButton";
import { getErrorMessage } from "../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';
import "./ServerSettings.css";
import {Loading} from "../../components/loading/Loading";

const LicenseSummary = (props) => {
    const {t} = useTranslation();
    const [data, setData] = useState({assigned: [0,0], unassigned: [0,0]});
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => { 
        setLoading(true);
        settingService.fetchOrganizationLicense().then(res => {
            const {items} = res;
            let assignedLicense = 0;
            let assignedDevice = 0, unassignedDevice = 0;

            items.forEach(item => {
                const licenseInfo = item.orgLicenseEntities.filter(f => f.productName === props.licenseType)[0];
                if(licenseInfo){
                    if(licenseInfo.assigned){
                        assignedLicense += licenseInfo.maxLicenseCount;
                        assignedDevice += licenseInfo.usedLicenseCount;
                    } else {
                        unassignedDevice += licenseInfo.usedLicenseCount
                    }
                }
            });
            setData({assigned: [assignedLicense, assignedDevice], unassigned: [props.totalCount - assignedLicense, unassignedDevice]});
        }).catch(e=>toastr.error(getErrorMessage(e))).finally(() => {setLoading(false)});
    }

    const pData = {
        labels: [t('MIS_SID_CBFEB_UNALLOCATED'), t('MIS_SID_CBFEB_ALLOCATED')],
        datasets : [{ 
            data: [data.unassigned[0], data.assigned[0]],
            backgroundColor: [
                'rgba(185, 205, 229, 1)',
                'rgba(252, 213, 181, 1)',
            ],
            borderWidth: 0
        }]
    };
    const dData = {
        labels: [t('COM_SID_USED_KR_MIS20'), t('COM_TV_SID_AVAILABLE_KR_MIS20'), t('COM_SID_USED_KR_MIS20'), t('COM_TV_SID_AVAILABLE_KR_MIS20')],
        datasets : [{ 
            data: [data.unassigned[1], data.unassigned[0] - data.unassigned[1], data.assigned[1], data.assigned[0] - data.assigned[1]],
            backgroundColor: [
                'rgba(0, 176, 240, 1)',
                'rgba(119, 147, 60, 1)',
                'rgba(155, 187, 89, 1)',
                'rgba(247, 150, 70, 1)',
            ],
            borderWidth: 0
        }]
    };    

    const options = {
        layout: {
            padding: {
                left: 0, right: 0, top: 0, bottom: 0
            }
        },
        legend: {display: false}, //legend: {display: false},
        tooltips: {enabled: false}
    }
    const style = {width:10, height:10, float:'left', margin:'6px 5px 0 0'};

    return (
        <div style={props.style}>
            <div style={{padding:5}}>
                <span style={{fontWeight:'bold', fontSize:14, color:'#303030'}}>{props.licenseType} : {props.totalCount}</span>
            </div>
            {
                loading &&
                <div style={{width:360, height:180, paddingTop:50, textAlign:'center'}}>
                    <Loading isLoading={true}></Loading>
                </div>
            }
            {
                !loading &&
                <div style={{padding:10}}>
                    <div style={{position:'absolute', width:180, height:180}}><Doughnut data={dData} width={180} height={180} options={options}/></div>
                    <div style={{position:'absolute', top:97, left:65, width:90, height:90}}><Pie data={pData} width={90} height={90} options={options}/></div>
                    <div style={{position:'absolute', right:0}}>
                        <table style={{width:150, fontSize:13}}>
                            <tr>
                                <td><div style={Object.assign({backgroundColor: '#F4D6B5'}, style)}/>{t('MIS_SID_CBFEB_ALLOCATED')} : {data.assigned[0]}</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 10}}><div style={Object.assign({backgroundColor: '#9BBB59'}, style)}/>{t('COM_SID_USED_KR_MIS20')} : {data.assigned[1]}</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 10}}><div style={Object.assign({backgroundColor: '#F79646'}, style)}/>{t('COM_TV_SID_AVAILABLE_KR_MIS20')} : { data.assigned[0] - data.assigned[1]}</td>
                            </tr>
                            <tr><td style={{height:10}}></td></tr>
                            <tr>
                                <td><div style={Object.assign({backgroundColor: '#BECCE6'}, style)}/>{t('MIS_SID_CBFEB_UNALLOCATED')} : {data.unassigned[0]}</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 10}}><div style={Object.assign({backgroundColor: '#56AEF1'}, style)}/>{t('COM_SID_USED_KR_MIS20')} : {data.unassigned[1]}</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 10}}><div style={Object.assign({backgroundColor: '#7C9238'}, style)}/>{t('COM_TV_SID_AVAILABLE_KR_MIS20')} : { data.unassigned[0] - data.unassigned[1]}</td>
                            </tr>                        
                        </table>
                    </div>
                </div>
        }
        </div>
    )
}
export default LicenseSummary;