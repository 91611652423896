import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MISDialog from '../../MISDialog';

const ServiceHistoryDetailsPopup = ({ detail, onClose }) => {
    const {t} = useTranslation();

    return (
        <div className='device_memo_popup'>
            <MISDialog
                dialog={{
                    title: t('BUTTON_DETAIL_P'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 600,
                    height: 400,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'DETAIL_CLOSE',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ],
                }}>
                    <div className="detail_view mini_p" style={{height: 300, overflow: 'auto'}}>
                        {detail}
                    </div>
            </MISDialog>
        </div>
    );
};

export default ServiceHistoryDetailsPopup;