import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import TextInput from '../../../input/TextInput';
import {SearchButton} from '../../../button/SearchButton';
import Chip from '../../../chip/Chip';
import {settingService} from '../../../../services';
import ProgressBar from '../../../progressbar/ProgressBar';

const RegisterLoginPageImagePopup = ({customizeType, onSave, onClose}) => {
    const {t} = useTranslation();
    const fileInput = useRef();
    const [imageFile, setImageFile] = useState({});
    const [progress, setProgress] = useState();
    const [disableSave, setDisableSave] = useState(false);

    const onClickFile = () => {
        fileInput.current.click();
    };

    const onChangeImageFile = e => {
        const file = e.target.files[0];
        const ext = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
        const fileCon = {
            acceptFiles: '.png',
            acceptFilesVideo: '',
            maxSize: 1024 * 1024 * 10, // 10MB
            maxSizeVideo: 0,
        };
        const supportedExt = fileCon.acceptFiles.split(',');
        const supportedExtVideo = fileCon.acceptFilesVideo.split(',');
        
        if(fileCon.acceptFiles === '*.*' || supportedExt.findIndex(se => se === ext) >= 0) {
            if(file.size > fileCon.maxSize) {
                toastr.error(t('MIS_MESSAGE_COMMON_EXCEED_CAPACITY_P'));
                fileInput.current.value = '';
                return;
            }
        } else if(fileCon.acceptFilesVideo === '*.*' || supportedExtVideo.findIndex(se => se === ext) >= 0) {
            if(file.size > fileCon.maxSizeVideo) {
                toastr.error(t('MIS_MESSAGE_COMMON_EXCEED_CAPACITY_P'));
                fileInput.current.value = '';
                return;
            }
        } else {
            toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
            fileInput.current.value = '';
            return;
        }

        if(file.fileName > 100) {
            toastr.error(t('COM_EBD_MSG_FILE_NAME_TOO_LONG'));
            fileInput.current.value = '';
            return;
        }

        setImageFile({...imageFile, file});
    };

    const onDeleteImageFile = () => {
        fileInput.current.value = '';
        setImageFile({...imageFile, file: undefined});
    };

    const onClickSave = () => {
        if(isNil(imageFile.file)) {
            toastr.error(t('MESSAGE_UPLOADER_SEL_CONTENT_FILE_P'));
            return;
        }

        if(imageFile.file) {
            setDisableSave(true);
            settingService.uploadLoginPageImageFile({
                ...imageFile
            }, percent => setProgress(percent)).then(res => {
                toastr.success(t('MIS_SID_UPLOAD_COMPLETED'));
                if (onSave) {
                    onSave(imageFile.file.name);
                }
            }).catch(error => {
                console.log(error);
                toastr.error(t('MIS_SID_FAILED_UPLOAD_FILE'));
                setDisableSave(false);
            }).finally(() => {
                // setProgress(undefined);
            });
        }
    };

    return (
        <MISDialog 
            dialog={{
                title: t("MIS_SID_CBOCT_LOING_PAGE_IMAGE"),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 485,
                height: 105,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickSave,
                        disable: disableSave,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('TEXT_SELECT_P')}</th>
                                <td>
                                    <span style={{marginRight: '8px'}}>
                                        <SearchButton onClick={onClickFile} />
                                        <input type='file' ref={fileInput} style={{display: 'none'}} onChange={onChangeImageFile}
                                            accept='png' />
                                    </span>
                                    {
                                        imageFile.file &&
                                        <Chip title={imageFile.file.name} height={20} onDelete={onDeleteImageFile} />
                                    }
                                </td>
                            </tr>
                            {
                                !isNil(progress) &&
                                <tr>
                                    <td colSpan="2">
                                        <ProgressBar width='100%' height={20} percentage={progress} fillerColor='#4be48c' background="#e8e8e8" useLabel={true} />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default RegisterLoginPageImagePopup;