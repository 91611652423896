import React from 'react';
import {CONTENT_ACTIONS} from "../constants";

const initialState = {
    reloadContent: false,
    myContentGroupId: 0,
    loadTrashBasket: false,
};

export const content = (state = initialState, action) => {
    switch (action.type) {
        case CONTENT_ACTIONS.RELOAD_CONTENT:
            return {
                ...state, reloadContent: action.reload
            };
        case CONTENT_ACTIONS.SET_MY_CONTENT_GROUP_ID:
            return {
                ...state, myContentGroupId: action.myContentGroupId
            };
        case CONTENT_ACTIONS.LOAD_TRASH_BASKET:
            return {
                ...state, loadTrashBasket: action.loadTrashBasket
            };
        case CONTENT_ACTIONS.CONTENT_DOWNLOADING:
            return {
                ...state, downloading: action.downloading
            };
        default :
            return state;
    }
};