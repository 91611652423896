import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import './CustomizeRegistered.css';
import fileDownload from 'js-file-download';
import {commonConstants, DEVICE_CUSTOM_FILES_OPTIONS, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import SelectBox from '../../components/selectbox/SelectBox';
import "../../components/table/react-table.css";
import {deviceService} from '../../services';
import {deviceAction, popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import ContentThumbnail from '../../components/image/ContentThumbnail';
import Size from "../../components/utils/Size";
import {useTrGroupProps} from '../../helper/tables';
import {updateCache} from "../../helper/cache/tableCache";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import CertificateThumb from '../../images/thumb/ic_certificate.png';

const heightOffset = 204;
const defaultSort = {id: 'createDate', desc: true};
const registerOptions = [
    {value: 'customLogo', title: 'COM_PJT_IDS_SETUP_14'},
    {value: 'defaultContent', title: 'COM_TEXT_DEFAULT_CONTENT_P'},
    {value: 'certificate', title: 'MIS_SID_CASEP_CERTIFICATE'}
];

const getFilePath = (type, path) => {
    const ind = path.lastIndexOf('.');

    if(type === 'Default Content') {
        return path.substring(0, ind) + '.png';
    } else {
        const ext = path.substring(ind);
        if(DEVICE_CUSTOM_FILES_OPTIONS['customLogo'].acceptFilesVideo.indexOf(ext.toLowerCase()) >= 0) {
            return path.substring(0, ind) + '.png';
        }
        return path;
    }
};

const CustomizeRegistered = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const downloading = useSelector(({device}) => device.customizeDownloading);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        deviceService.fetchDeviceRegisteredSoftware({
            startIndex: page * pageSize + 1,
            pageSize,
            softwareType: 'CUSTOM',
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
            updateCache('DEVICE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent);
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [defaultSort]});
    };

    const onRegister = type => {
        dispatch(popupAction.addPopup({
            id: commonConstants.REGISTER_CUSTOMIZE,
            type: commonConstants.REGISTER_CUSTOMIZE,
            customizeType: type,
            onSave: () => {
                dispatch(popupAction.closePopup(commonConstants.REGISTER_CUSTOMIZE));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.REGISTER_CUSTOMIZE))
        }))
    };

    const onEdit = () => {
        if(selected.current.length === 0) {
            return;
        }

        const softwareId = items[selected.current[0]].softwareId;
        dispatch(popupAction.addPopup({
            id: commonConstants.EDIT_CUSTOMIZE,
            type: commonConstants.EDIT_CUSTOMIZE,
            initSoftware: items[selected.current[0]],
            onSave: () => {
                dispatch(popupAction.closePopup(commonConstants.EDIT_CUSTOMIZE));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_CUSTOMIZE))
        }));
    };

    const onDelete = () => {
        if(selected.current.length === 0) {
            return;
        }

        const softwareIds = selected.current.map(s => items[s].softwareId);
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("ALERT_WANT_DELETE"),
            onClickYes: () => handleDelete(softwareIds),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        }));

    };

    const handleDelete = (softwareIds) => {
        deviceService.deleteSoftware(softwareIds).then(res => {
            toastr.success(t('ALERT_SUCCESS_DELETE'));

            const maxPage = Math.ceil((data.totalCount - softwareIds.length) / filter.pageSize);
            if(filter.page > 0 && filter.page + 1 > maxPage){
                setFilter({...filter, page : maxPage - 1})
            } else {
                fetchData();
            }            
        }).catch(error => console.log(error));
        dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
    };

    const onPublish = () => {
        if(selected.current.length === 0) {
            return;
        }

        const softwareId = items[selected.current[0]].softwareId;
        dispatch(popupAction.addPopup({
            id: commonConstants.PUBLISH_CUSTOMIZE,
            type: commonConstants.PUBLISH_CUSTOMIZE,
            initSoftware: items[selected.current[0]],
            onPublish: () => {
                dispatch(popupAction.closePopup(commonConstants.PUBLISH_CUSTOMIZE));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.PUBLISH_CUSTOMIZE))
        }))
    };

    const onDownload = () => {
        if(selected.current.length === 0) {
            return;
        }
        dispatch(deviceAction.customizeDownloading(true));
        dispatch(popupAction.addPopup({
            id: commonConstants.LOADING_POPUP,
            type: commonConstants.LOADING_POPUP,
            modal:false,
            title: t('MSG_DOWNLOAD'),
            isDraggable: true
        }));

        const {filePath} = items[selected.current[0]];
        deviceService.downloadCustomize(filePath).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error))
        .finally(() => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            dispatch(deviceAction.customizeDownloading(false));
        });
    };

    const updateDimensions = () => {
        let height = window.innerHeight - heightOffset;
        setStyle({
            height: height
        })
    }

    const getDetail = (softwareType, detail) => {
        switch(softwareType){
            case 'wifiCertificate':
                return 'Network';
            case 'sslCertificate':
                return 'TLS(SSL)';
            default:
                return detail;
        }
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const searchPlaceHolder = t('LIST_TITLE_NAME');

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_TYPE_P'),
            accessor: 'softwareType',
            width: 180,
            Cell: ({original}) => {
                let softwareTypeText = '';
                switch(original.softwareType){
                    case '03':
                        softwareTypeText = t('COM_PJT_IDS_SETUP_14');
                        break;
                    case '04':
                        softwareTypeText = t('COM_TEXT_DEFAULT_CONTENT_P');
                        break;
                    case 'wifiCertificate':
                    case 'sslCertificate':
                        softwareTypeText = t('MIS_SID_CASEP_CERTIFICATE');
                        break;
                    default:
                        softwareTypeText = '';
                }
                return (<span>{softwareTypeText}</span>)
            }
        },
        {
            Header: t('TEXT_TITLE_CONTENT_P'),
            accessor: 'filePath',
            width: 180,
            sortable: false,
            Cell: ({original, value}) => {
                if(original.softwareType === 'wifiCertificate' || original.softwareType == 'sslCertificate')
                    return (
                        <img src={CertificateThumb} width={106} height={80} alt="Certificate" title="Certificate"/>
                    )
                else
                    return (
                        <ContentThumbnail id='CUSTOM_LOGO' width={106} height={80} style={{width: '106px', height: '80px'}} params={{logoFilePath: getFilePath(original.softwareType, value)}} data={value}/>
                    )
                }
        },
        {
            Header: t('LIST_TITLE_NAME'),
            accessor: 'softwareName',
            width: 480,
        },
        {
            Header: t('BUTTON_DETAIL_P'),
            width: 180,
            sortable: false,
            Cell: ({original}) => <><div>{getDetail(original.softwareType, original.detail)}</div><div><Size size={original.fileSize}/></div></>
        },
        {
            Header: t('TEXT_LAST_MODIFIED_P'),
            accessor: 'createDate',
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'CUSTOMIZE_REGISTER_PUBLISH' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <SelectBox id="REGISTER_SOFTWARE" title={t('BUTTON_REGISTER_P')} type='SUB' selects={registerOptions} classname='device_menu_select_box' onClick={onRegister} disabled={loading}/>
                    <WhiteButton id='EDIT_SOFTWARE' name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} />
                    <WhiteButton id='DELETE_SOFTWARE' name={t('COM_BUTTON_DELETE')} disable={selectedCnt <= 0} onClick={onDelete} />
                    <WhiteButton id='PUBLISH_SOFTWARE' name={t('MIS_SID_PUBLISH')} disable={selectedCnt !== 1} onClick={onPublish} />
                    <WhiteButton id='DOWNLOAD_SOFTWARE' name={t('BUTTON_DOWNLOAD_P')} disable={selectedCnt !== 1 || downloading } onClick={onDownload} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide}/>
            </div>
        </div>
    );
};

export default CustomizeRegistered;