import React from 'react'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { popupAction } from "../../../actions";
import MISDialog from "../MISDialog";
import { getMixString } from "../../../language/languageUtils";
import ReactTable from "react-table";

const DeleteUsedRulesetPopup = ({id, usedRulesetList, onClickYes}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    }

    usedRulesetList.map((value) => {
        value.scheduleNames = "";
        value.refScheduleList.map((scheduleInfo, index) => {
            if(index > 0) {
                value.scheduleNames += "\n";
            }
            value.scheduleNames += scheduleInfo.name;
        });
    });

    const dialogProps = {
        title: t("COM_BUTTON_DELETE"),
        closeOnEscape : true,
        width :782,
        height :300,
        position: {x: -300, y: -400},
        modal:true,
        onClose:() => closePopup()
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("BUTTON_YES_P"), id: 'forceDeleteRuleset', onClick: () => {
                        onClickYes();
                        closePopup();
                    }},
                    {title: t("BUTTON_NO_P"), id: 'cancelForceDeleteRuleset', onClick: () => closePopup()}
                ]
            }}>

            <div>
                <div>{getMixString(["MIS_SID_MIX_THIS_INCLUDED_SCHEDULE_IF_DELETED_ALSO_REMOVED_FROM_SCHEDULE", "MIS_SID_RULESET"])}</div>
                <div>{t("MIS_SID_DO_YOU_WANT_CONTINUE_DELETING")}</div>
            </div>

            <div style={{width: '100%'}} className={'ruleset_table_wrap mt20'}>
                <ReactTable
                    data={usedRulesetList}
                    minRows={0}
                    showPagination={false}
                    resizable={false}
                    columns={[
                        {
                            Header: getMixString(["MIS_SID_MIX_NAME", "MIS_SID_RULESET"]),
                            width: 390,
                            accessor: 'rulesetName'
                        },
                        {
                            Header: t("TEXT_CONTENT_SCHEDULE_P"),
                            width: 390,
                            accessor: 'scheduleNames',
                            Cell: props => <div>{props.original.scheduleNames.split("\n").map(value => {
                                return (<span>{value}<br/></span>)
                            })}</div>
                        }
                    ]}
                    className="-striped -highlight"
                    manual
                    style={{
                        height: '250px'
                    }}
                />
            </div>

        </MISDialog>
    )
}

export default DeleteUsedRulesetPopup;