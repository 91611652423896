import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './UploadWebContent.css';
import {useDispatch, useSelector} from "react-redux";
import {contentAction, popupAction} from "../../../actions";
import TextInput from "../../input/TextInput";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import {toastr} from 'helper/toastrIntercept';
import ProgressBar from "../../progressbar/ProgressBar";
import {contentService, settingService} from "../../../services";
import withMISOpt from "../../misopt";
import TimeInput from "../../input/TimeInput";
import {contentConstants} from "../../../constants";
import {validateSpecialChar} from "../../../helper/contentValidator";

const UploadWebContent = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const myContentGroupId = useSelector(({content}) => content.myContentGroupId);

    const {id, misopt:{misAcceptFileTypeObj}, content, editMode = false} = props;
    const [webContent, setWebContent] = useState({
        file: null,
        webContentName: editMode ? content.contentName : '',
        startPage: editMode ? content.startPage : '',
        refreshInterval: editMode ? content.refreshInterval : '00:01:00'
    });

    const [buttons, setButtons] = useState({
        rightButtons: [
            {
                id: 'SAVE',
                title: t('COM_BUTTON_SAVE'),
                onClick: () => onClickSaveButton(),
            },
            {
                id: 'CANCEL',
                title: t('BUTTON_CANCEL_P'),
                onClick: () => closePopup(id),
            }
        ],
    });

    const [hasError, setHasError] = useState(false);
    const [fileName, setFileName] = useState("");
    const [percentage, setPercentage] = useState(0);
    const [fetchData, setFetchData] = useState(false);
    const [deviceTypeInfo, setDeviceTypeInfo] = useState({
       deviceType: editMode ? content.deviceType : misAcceptFileTypeObj('html').deviceType,
       deviceTypeVersion: editMode ? content.deviceTypeVersion : misAcceptFileTypeObj('html').deviceTypeVersion
    });

    const {file, webContentName, startPage, refreshInterval} = webContent;
    const {deviceType, deviceTypeVersion} = deviceTypeInfo;

    const [showTag, setShowTag] = useState(false);
    const [mediaTags, setMediaTags] = useState([]);

    const fileUploader = useRef();
    const selectedFileInput = useRef();
    const htmlFileNameDiv = useRef();
    const fileProgress = useRef();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const onClickAddFile = () => {
        fileUploader.current.click();
    };

    const onClickCancelFile = () => {
        fileUploader.current.value = '';
        setWebContent({...webContent, file : null});
        htmlFileNameDiv.current.style.display = 'none';
        setFileName("");
    };

    const setFile = (event) => {
        if(event.target.files[0] !== undefined) {
            const ext = event.target.files[0].name.split('.').pop();
            if(ext !== 'zip'){
                toastr.error(t('COM_MIS_SID_AUTHOR_SELELCT_ZIP_FILE_RESOURCE'));
                setHasError(true);
            }

            setWebContent({...webContent, file : event.target.files[0]});
            setFileName(event.target.files[0].name);
            htmlFileNameDiv.current.style.display = 'inline-block';
        }
    };

    const fetchUpload = (fileItem) => {
        if(file === null || webContentName.trim() === '' || startPage.trim() === ''){
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
            setFetchData(false);
        }else if(!validateSpecialChar(webContentName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
            setFetchData(false);
        }else {
            setPercentage(0);
            fileProgress.current.style.display = 'block';
            let formData = new FormData();

            formData.append("files", fileItem);

            formData.append("contentType", contentConstants.CONTENT_TYPE_WEB);
            formData.append("webContentName", webContentName);
            formData.append("startPage", startPage);
            formData.append("refreshInterval", refreshInterval);

            if(editMode){
                formData.append("groupId",  content.groupId);
                formData.append("updatedContentId", content.contentId);
                formData.append("mode", contentConstants.UPDATE);
            }else {
                formData.append("groupId", myContentGroupId === "" ? 0 : myContentGroupId);
            }

            contentService.uploadContent(formData, fileItem.name, (filename, loaded) => setPercentage(loaded))
                .then(res => {
                    if(res.status === 'Success'){
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        dispatch(contentAction.reloadContent(true));
                        setButtons({
                            leftButtons:[],
                            rightButtons: [
                                {
                                    id: 'CANCEL',
                                    title: t('BUTTON_OK_P'),
                                    onClick: () => closePopup(id),
                                }
                            ]});
                    }
                })
                .catch(error => {
                    setPercentage(0);
                    fileProgress.current.style.display = 'none';
                    if(error.response.data.errorCode === '403001')
                        toastr.error(t('ERROR_NO_ROLE'));
                    else
                        toastr.error(t('MIS_MESSAGE_CONTENT_FAIL_TO_ADD_CONTENT_P'));
                }).finally(() => {
                    setFetchData(false);
            });
        }


    };
    const onClickSaveButton = () => {
        setFetchData(true);
    };

    const onChangeTimePicker = (value) => {
        setWebContent({...webContent, refreshInterval: value});
    };

    const fetchTags = () => {
        settingService.fetchTags({
            startIndex: 1,
            pageSize: 100,
            type: 'media',
            sortColumn: 'tag_value',
            sortOrder: 'asc',
        }).then(res => {
            setMediaTags(res.items);
        });
    };

    const onClickTag = (value) => {
        const result = startPage + '(' + value + ')';
        setWebContent({...webContent, startPage: result});
        setShowTag(false);
    };

    useEffect(() => {
        fetchTags();
    }, [])

    useEffect( () => {
        if(fetchData) {
            fetchUpload(file);
        }
    }, [fetchData])

    return (
        <div>
            <MISDialog dialog={{
                title: t('MIS_SID_WEBAUTHOR_UPLOAD_WEB_CONTENT'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 550,
                height: 312,
                position: {x: -150, y: -200},
                onClose: () => closePopup(id),
            }}
                       buttons={buttons}>
                <div className="upload_web_content_popup">
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('TEXT_CONTENT_NAME_P')}</th>
                            <td><TextInput width={300} maxLength={100} value={webContentName} onChange={e => setWebContent({...webContent, webContentName: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>{t('COM_MAPP_SID_SUPPORTED_DEVICES')}</th>
                            <td>
                                <SupportedDeviceIcon deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} mediaType={'HTML'}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_SID_AUTHOR_WEB_PACKAGE')}</th>
                            <td><input name="htmlFile" id="htmlFile" type="file" accept='.zip' style={{display: 'none'}} ref={fileUploader} onChange={setFile}/>
                                <button className="circle_btn circle_border base search float_l mr8"
                                        onClick= {onClickAddFile}><span className=""></span></button>
                                <div className="tag_input float_l" ref={htmlFileNameDiv}>
                                    <input type="text" value={fileName} ref={selectedFileInput}/>
                                    <button onClick={onClickCancelFile}></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_SID_AUTHOR_START_PAGE')}</th>
                            <td>
                                <div className="select_box float_l mr8 option_list_wrap">
                                    <TextInput width={184} value={startPage} placeholder={"index.html"}
                                               onChange={e => setWebContent({...webContent, startPage: e.target.value})}
                                               onKeyDown={(e) => (e.keyCode === 52 && e.shiftKey) ? setShowTag(true) : undefined}/>
                                    { showTag && mediaTags && mediaTags.length > 0 &&
                                    <div className="option_list" >
                                        <div>
                                            <span></span>
                                            <ul className='taglist_dropdown'>
                                                {
                                                    mediaTags.map(tag => {
                                                        return (
                                                            <li key={tag.tagName} onClick={(e) => onClickTag(tag.tagName)}>
                                                                {tag.tagName}
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_LFD_SID_REFRESH_INTERVAL')}</th>
                            <td>
                                <TimeInput width={162} value={refreshInterval} onChange={(e, value)=> onChangeTimePicker(value)} style={{margin:0}}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div className="progress" style={{display: 'none'}} ref={fileProgress}>
                                    <ProgressBar width={500} height={20} background={"#e8e8e8"} fillerColor={"#4be48c"} useLabel={true} percentage={percentage}/>
                                </div>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <td colSpan="2"><span className='warringText'>{t('MIS_SID_AUTHOR_WEB_PACKAGE_SELELCT_ZIP_HTML_DISPLAY')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><span className='warringText'>{t('MIS_SID_AUTHOR_START_PAGE_ENTER_NAME_OF_HOME')}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default withMISOpt(UploadWebContent);