import React from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from 'react-i18next';
import WhiteButton from "../../../button/WhiteButton";
import {bytesToSize} from '../../../../helper/bytesToSize';
import {useMISOpt} from '../../../misopt';
import {toMomentDateTimeFormat} from '../../../../helper';
import Moment from "react-moment";
import isNil from 'lodash/isNil';

const LogDownloadPopup = ({onClose, items, onDownload}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    return(
        <MISDialog
            dialog={{
                title: t("TEXT_LOG_MANAGEMENT_P"),
                modal: true,
                closeOnEscape: true,
                width: 700,
                height: 500,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_CLOSE',
                        title: t('COM_BUTTON_CLOSE_P'),
                        onClick: onClose
                    }
                ]
            }}>
            <div className="server_setting check_list none_scrolling" style={{height: '500px', overflowY : 'auto', overflowX: 'hidden'}}>
            <table style={{width:'100%'}}>
                <colgroup>
                    <col width="30%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="20%"></col>
                </colgroup>
                <tbody>
                    <tr>    
                        <th>{t("TEXT_FILE_NAME_P")}</th>
                        <th>{t("ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE")}</th>
                        <th>{t("TABLE_LAST_MODIFIED_DATE_P")}</th>
                        <th>{t("BUTTON_DOWNLOAD_P")}</th>
                    </tr>
                    {
                        !isNil(items) && items.length > 0 &&
                        items.map((item, index) =>
                            <tr key={index}>
                                <td>{item.fileName}</td>
                                <td>{bytesToSize(item.fileSize)}</td>
                                <td>
                                    <Moment format={dateTimeFormat}>{item.lastModified}</Moment>
                                </td>
                                <td>
                                    <WhiteButton id={'logDownload'} name={t("BUTTON_DOWNLOAD_P")}  onClick={()=>onDownload(item.fileName)}/>  
                                </td>
                            </tr>
                        )
                    }
                    
                </tbody>
            </table>

            </div>
        </MISDialog>
    )
}

export default LogDownloadPopup;