import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import Draggable from "react-draggable";
import DialogTitle from "./DialogTitle";
import DialogBody from "./DialogBody";
import DialogFooter from "./DialogFooter";
import {isString, isNumber} from 'lodash';
import {globalZIndex, useZIndexPopup} from "../../helper";
import {popupAction} from "../../actions";
import {useDispatch} from "react-redux";

const Dialog = (props) => {

    const [option, setOption] = useState({isMinimized: false, isMaximized: false})
    const [dialogStyle, setDialogStyle] = useState({
        opacity: 0
    });
    const [isActive, setIsActive] = useState(false);
    const [zIndex, updateZIndex] = useZIndexPopup();
    const ref = useRef();

    const onMinimize = () => {
        setOption({ isMinimized: true, isMaximized: false });
    }

    const onMaximize = () => {
        setOption({ isMinimized: false, isMaximized: true });
    }

    const onRestore = () => {
        setOption({ isMinimized: false, isMaximized: false });
    }

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const onClick = () => {
        if (!modal) {
            updateZIndex();
        }
    }

    useEffect(()=> {
        setDialogStyle({...dialogStyle, zIndex: zIndex});
    }, [zIndex])

    useEffect(()=> {
        if (ref && ref.current) {
            const {position: {X, Y}, width, height} = props;
            const style = {
                height: height,
                width: width,
                zIndex: zIndex,
                opacity: 1,
                transition: 'opacity .3s ease-out'
            };

            if(isString(X) && X.toUpperCase() === 'RIGHT'){
                style.right = 0;
            } else if(isString(X) && X.toUpperCase() === 'LEFT'){
                style.left = 0;
            } else if(isNumber(X)){
                style.left = X;
            } else {
                style.left = `calc(50% - ${ref.current.clientWidth/2}px)`;
            }

            if(isString(Y) && Y.toUpperCase() === 'BOTTOM'){
                style.bottom = 0;
            } else if(isString(Y) && Y.toUpperCase() === 'TOP'){
                style.top = 0;
            } else if(isNumber(Y)){
                style.top = Y;
            } else {
                style.top = `calc(50% - ${ref.current.clientHeight/2}px)`;
            }
            setDialogStyle({...dialogStyle, ...style});
        }
    }, [ref.current])

    useEffect(()=> {
        updateZIndex();
    },[])

    const { modal, modalDraggable, popupId, buttons, children, isDraggable } = props;
    const {isMinimized} = option;

    return (
        <div className={cs("ui-dialog-container", { "": modal })} onClick={onClick}>
            {
                modal &&
                    <>
                        <div className="ui-dialog-overlay" style={{zIndex: zIndex}}></div>
                        <div style={dialogStyle} className={cs("ui-dialog", { "minimized": isMinimized})} ref={ref} popup-id={props.popupId}>
                            <DialogTitle
                                title={props.title}
                                hasCloseIcon={props.hasCloseIcon}
                                allowMinimize={props.allowMinimize}
                                allowMaximize={props.allowMaximize}
                                isMinimized={option.isMinimized}
                                isMaximized={option.isMaximized}
                                onMinimize={onMinimize}
                                onMaximize={onMaximize}
                                onRestore={onRestore}
                                onClose={props.onClose}
                                popupId = {props.popupId}
                                modal = {props.modal}
                                useEllipsis={props.useEllipsis}
                            />
                            {
                                !isMinimized && <DialogBody popupId={popupId} modal={modal}>{children}</DialogBody>
                            }
                            {
                                !isMinimized && <DialogFooter buttons={buttons} onClose={onClose}></DialogFooter>
                            }
                        </div>
                    </>
            }
            {
                modalDraggable &&
                <>
                    <div className="ui-dialog-overlay-with-draggable" style={{zIndex: zIndex-1}}></div>
                    <div style={dialogStyle} className={cs("ui-dialog", { "minimized": isMinimized })} ref={ref} popup-id={props.popupId}>
                        <DialogTitle
                            title={props.title}
                            hasCloseIcon={props.hasCloseIcon}
                            allowMinimize={props.allowMinimize}
                            allowMaximize={props.allowMaximize}
                            isMinimized={option.isMinimized}
                            isMaximized={option.isMaximized}
                            onMinimize={onMinimize}
                            onMaximize={onMaximize}
                            onRestore={onRestore}
                            onClose={props.onClose}
                            popupId = {props.popupId}
                            modal = {props.modal}
                            useEllipsis={props.useEllipsis}
                        />
                        {
                            !isMinimized && <DialogBody popupId={popupId} modal={modal}>{children}</DialogBody>
                        }
                        {
                            !isMinimized && <DialogFooter buttons={buttons} onClose={onClose}></DialogFooter>
                        }
                    </div>
                </>
            }
            {
                !modal && !modalDraggable && isDraggable &&
                <>
                    <Draggable handle=".ui-dialog-titlebar" bounds="body">
                        <div style={dialogStyle} className={cs("ui-dialog", { "minimized": isMinimized})} ref={ref} popup-id={props.popupId}>
                            <DialogTitle
                                title={props.title}
                                hasCloseIcon={props.hasCloseIcon}
                                allowMinimize={props.allowMinimize}
                                allowMaximize={props.allowMaximize}
                                isMinimized={option.isMinimized}
                                isMaximized={option.isMaximized}
                                onMinimize={onMinimize}
                                onMaximize={onMaximize}
                                onRestore={onRestore}
                                onClose={props.onClose}
                                popupId = {props.popupId}
                                modal = {props.modal}
                                useEllipsis={props.useEllipsis}
                            />
                            {
                                !isMinimized && <DialogBody popupId={popupId} modal={modal}>{children}</DialogBody>
                            }
                            {
                                !isMinimized && <DialogFooter buttons={buttons} onClose={onClose}></DialogFooter>
                            }
                        </div>
                    </Draggable>
                </>
            }


        </div>
    );

}
Dialog.propTypes = {
    popupId: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    modal: PropTypes.bool,
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    hasCloseIcon: PropTypes.bool,
    allowMinimize: PropTypes.bool,
    allowMaximize: PropTypes.bool,
    isResizable: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    closeOnEscape: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
    buttons: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            onClick: PropTypes.func
        })),
        PropTypes.arrayOf(PropTypes.element)
    ]),
    modalDraggable: PropTypes.bool
};

Dialog.defaultProps = {
    height: 300,
    width: 500,
    modal: false,
    closeOnEscape: true,
    isDraggable: false,
    isResizable: false,
    title: '',
    hasCloseIcon: true,
    allowMinimize: false,
    allowMaximize: false,
    onClose: null,
    buttons: null,
    position: { x: -250, y: -150 },
    zIndex : null,
    modalDraggable: false
};

export default Dialog;