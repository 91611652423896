import React, {useRef, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {commonConstants, settingConstants} from "../../../../constants";
import {popupAction} from "../../../../actions";
import TextInput from "../../../input/TextInput";
import {settingService} from "../../../../services";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';

const AddExternalLinkPopup = ({id, onSave}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [linkName, setLinkName] = useState('');
    const [linkUrl, setLinkUrl] = useState('');

    const closePopup = () => {
        dispatch(popupAction.closePopup('ADD_EXTERNAL_LINK_LOADING_POPUP'));
        dispatch(popupAction.closePopup(id));
    };

    const addExternalLink = () => {
        dispatch(popupAction.addPopup({id: 'ADD_EXTERNAL_LINK_LOADING_POPUP', type : commonConstants.LOADING_POPUP}));
        settingService.addExternalLink(linkName, linkUrl)
            .then(res => {
                if(res && res.status === "Success") {
                    toastr.success(t('MESSAGE_SCHEDULE_SUCCESS_ADD_P'));
                    if(onSave) {
                        onSave();
                    }
                }
            })
            .catch(e => toastr.error(t('COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN').replace(' (<<A>>)', '')))
            .finally(() => closePopup());

    };

    const dialogProps ={
        title: t("MIS_TEXT_TITLE_EXTERNAL_LINK_P"),
        width :500,
        height :100,
        position: {x: -300, y: -200},
        modal:true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose:() => closePopup()
    };

    return (
        <MISDialog
            dialog={dialogProps}
            buttons= {{
                rightButtons: [
                    {title: t("COM_BUTTON_SAVE"), id: 'saveSaveRulesetBtn', onClick: () => addExternalLink()},
                    {title: t("BUTTON_CANCEL_P"), id: 'cancelSaveRulesetBtn', onClick: ()=> closePopup()}
                ]}}
        >
            <div>
                <table style={{width: "100%"}}>
                    <colgroup>
                        <col width="139px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>{t("MIS_TEXT_EXTERNAL_LINK_NAME_P")}</th>
                        <td>
                            <TextInput width={309} maxLength={50} value={linkName} onChange={(e) => setLinkName(e.target.value)}/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("COM_LIST_TITLE_URL")}</th>
                        <td>
                            <TextInput width={309} maxLength={100} value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

export default AddExternalLinkPopup;