import React from "react";
import DeviceHealthByDeviceType from "./DeviceHealthByDeviceType";
import ErrorWarningByCount from "./ErrorWarningByCount";
import ErrorWarningByDate from "./ErrorWarningByDate";
import {useTranslation} from "react-i18next";
import {ResourceStatus} from "../DMArea";
import {dashboardConstants} from "../../../../constants";

const HealthInformation = ({loading, status}) => {

    const {t} = useTranslation();

    return (
        <div className={'dm_container'} style={{float: 'right'}}>
            <div style={{margin: 10, height: 590}}>
                <div className={'device_status_box'} style={{width: '100%', display: 'flex', flex: 1, justifyContent: 'space-around'}}>
                    <ResourceStatus loading={loading} id={dashboardConstants.ERROR_RESOURCE} total={status.error}/>
                    <ResourceStatus loading={loading} id={dashboardConstants.WARNING_RESOURCE} total={status.warning}/>
                </div>
                {
                        <>
                            <div style={{padding: 10}}>
                                <DeviceHealthByDeviceType/>
                            </div>

                            <div style={{margin: 10}}>
                                <div style={{marginTop: 25, marginBottom: 10, color: '#241f20'}}>{t('MIS_SID_CBFEB_DEVICE_HEALTH_DETAILS')}</div>
                                <div style={{display: 'flex', backgroundColor: '#F5F5F5', borderRadius: 2}}>
                                    <ErrorWarningByCount/>
                                    <ErrorWarningByDate/>
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}
export default HealthInformation;