import React, {Fragment, useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import {statisticsService} from '../../services';
import {connect} from 'react-redux';
import './Statistics.css';
import './ConnectionStatus.css';
import '../../components/table/react-table.css';
import Moment from 'moment';
import {optionDoughnut} from "../../constants/StatisticsConstants";
import fileDownload from 'js-file-download';
import {toastr} from 'helper/toastrIntercept';
import WhiteButton from "../../components/button/WhiteButton";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

const ConnectionStatus = ({currContent}) => {
    const {t} = useTranslation();

    const [connectionStatus, setConnectionStatus] = useState({
        datasets: [{
            data: [],
            backgroundColor: ['#2b8cbe', '#a6bddb',],
            hoverBackgroundColor: ['#f03b20', '#f03b20'],
        }],
    });

    const [panelStatus, setPanelStatus] = useState({
        datasets: [{
            data: [],
            backgroundColor: ['#c51b8a', '#fa9fb5', '#fde0dd',],
            hoverBackgroundColor: ['#f03b20', '#f03b20', '#f03b20',],
        }],
    });

    const [connectionStatusInfo, setConnectionStatusInfo] = useState({connectionStatusTotal: 0, connectedCount: 0, disconnectedCount: 0});
    const [panelStatusInfo, setPanelStatusInfo] = useState({panelStatusTotal: 0, pannelOnCount: 0, pannelOffCount: 0, serverDisconnectedCount: 0,});
    const [loadingbar, setLoadingBar] = useState(false);

    const fetchData = () => {
        setLoadingBar(true);
        Promise.all([
            statisticsService.fetchStatisticsSummary('connectionStatus'),
            statisticsService.fetchStatisticsDevices({
                data: 'panelStatusTable',
                endDate: Moment().format('YYYY-MM-DD'),
                startDate: Moment().subtract(6, 'days').format('YYYY-MM-DD'),
            }),
        ]).then(res => {
            let connectStatus = res[0].items;
            setConnectionStatusInfo({
                ...connectionStatusInfo,
                connectionStatusTotal: (Number(connectStatus.connectedData.value) + Number(connectStatus.disconnectedData.value)),
                connectedCount: Number(connectStatus.connectedData.value),
                disconnectedCount: Number(connectStatus.disconnectedData.value),
            });
            setConnectionStatus({
                labels: [
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_CONNECTED_DEVICE_NUMBER_P'),
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_DISCONNECTED_DEVICE_NUMBER_P'),
                ],
                datasets: [{
                    data: [
                        connectStatus.connectedData.value,
                        connectStatus.disconnectedData.value,
                    ],
                    backgroundColor: [
                        connectStatus.connectedData.color,
                        connectStatus.disconnectedData.color,
                    ],
                    hoverBackgroundColor: [
                        connectStatus.connectedData.highlight,
                        connectStatus.disconnectedData.highlight,
                    ]
                }],
            });
            let pannelStatus = res[1].items[0];
            setPanelStatusInfo({
                ...panelStatusInfo,
                panelStatusTotal: (Number(pannelStatus.pannelOff.value) + Number(pannelStatus.pannelOn.value) + Number(pannelStatus.serverDisconnectedData.value)),
                pannelOnCount: Number(pannelStatus.pannelOn.value),
                pannelOffCount: Number(pannelStatus.pannelOff.value),
                serverDisconnectedCount: Number(pannelStatus.serverDisconnectedData.value),
            });
            setPanelStatus({
                labels: [
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_PANELON_DEVICE_NUMBER_P'),
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_PANELOFF_DEVICE_NUMBER_P'),
                    t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_DISCONNECTED_DEVICE_NUMBER_P'),
                ],
                datasets: [{
                    data: [
                        pannelStatus.pannelOn.value,
                        pannelStatus.pannelOff.value,
                        pannelStatus.serverDisconnectedData.value,
                    ],
                    backgroundColor: [
                        pannelStatus.pannelOn.color,
                        pannelStatus.pannelOff.color,
                        pannelStatus.serverDisconnectedData.color,
                    ],
                    hoverBackgroundColor: [
                        pannelStatus.pannelOn.highlight,
                        pannelStatus.pannelOff.highlight,
                        pannelStatus.serverDisconnectedData.highlight,
                    ]
                }],
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onClickExport = (docType) => {
        statisticsService.fetchStatisticsDevicesFileDownload(docType, {
            data: "connectionStatus",
            startDate: Moment().subtract(6, 'days').format('YYYY-MM-DD'),
            endDate: Moment().format('YYYY-MM-DD'),
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    return (
        <Fragment>
            <div className="contents_buttonWrap" style={{display: currContent === 'CONNECTION_STATUS' ? 'block':'none'}}>
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => onClickExport('EXCEL')}/>
                </div>
            </div>
            <div className="statistics_list" id="statisticsLoadConnectionStatus" style={{display: currContent === 'CONNECTION_STATUS' ? 'block':'none'}}>
                <div className="graph_wrap clearfix">
                    <div className="float_l ">
                        <div className="connection_status" id="connectionStatusTable">
                            <h4>{t('COM_MESSAGE_STATISTICS_LEFT_MENU_SUB_CONNECTION_P')}</h4>
                            <div>
                                <ul>
                                    <li>
                                        {t('TEXT_ALL_P')}<span>{connectionStatusInfo.connectionStatusTotal}</span>
                                    </li>
                                    <li className='connection_status_item' style={{background: connectionStatus.datasets[0].backgroundColor[0]}}>
                                        {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_CONNECTED_DEVICE_NUMBER_P')}<span>{connectionStatusInfo.connectedCount}</span>
                                    </li>
                                    <li className='connection_status_item' style={{background: connectionStatus.datasets[0].backgroundColor[1]}}>
                                        {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_DISCONNECTED_DEVICE_NUMBER_P')}<span>{connectionStatusInfo.disconnectedCount}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="float_l">
                        <div className="statistics_title mt28 pl27 pr1">
                            <div className="float_l" style={{marginLeft: '103px'}}>
                                <Doughnut data={connectionStatus} options={optionDoughnut} width={220} height={220}/>
                                <div className="donut-inner"><h3>{connectionStatusInfo.connectionStatusTotal}</h3></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="graph_wrap clearfix mt13">
                    <div className="float_l">
                        <div className="connection_status" id="panelStatusTable">
                            <h4>{t('MESSAGE_STATISTICS_SUMMARY_PANEL_TITLE_P')}</h4>
                            <div>
                                <ul>
                                    <li style={{padding: '7px 20px 0 20px'}}>
                                        {t('TEXT_ALL_P')}<span>{panelStatusInfo.panelStatusTotal}</span>
                                    </li>
                                    <li className='connection_status_item' style={{background: panelStatus.datasets[0].backgroundColor[0], padding: '7px 20px 0 20px'}}>
                                        {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_PANELON_DEVICE_NUMBER_P')}<span>{panelStatusInfo.pannelOnCount}</span>
                                    </li>
                                    <li className='connection_status_item' style={{background: panelStatus.datasets[0].backgroundColor[1], padding: '7px 20px 0 20px'}}>
                                        {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_PANELOFF_DEVICE_NUMBER_P')}<span>{panelStatusInfo.pannelOffCount}</span>
                                    </li>
                                    <li className='connection_status_item' style={{background: panelStatus.datasets[0].backgroundColor[2], padding: '7px 20px 0 20px', color: '#717a89'}}>
                                        {t('MESSAGE_STATISTICS_TABLE_COLUMN_CONNECTION_DISCONNECTED_DEVICE_NUMBER_P')}<span style={{color: '#c6c6c7'}}>{panelStatusInfo.serverDisconnectedCount}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="float_l">
                        <div className="statistics_title mt28 pl27">
                            <div className="float_l" style={{marginLeft: '103px'}}>
                                <Doughnut data={panelStatus} options={optionDoughnut} width={220} height={220}/>
                                <div className="donut-inner"><h3>{panelStatusInfo.panelStatusTotal}</h3></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loadingbar && <LoadingBar />}
        </Fragment>
    );
};

export default connect()(ConnectionStatus);