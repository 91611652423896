import React from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import MISDialog from "../MISDialog";
import './CreatePlaylistPopup.css';

import {menuAction, playlistAction, popupAction} from "../../../actions";
import {playlistConstants} from "../../../constants/PlaylistConstants";
import SelectSupportedDevice from "../../utils/SelectSupportedDevice";
import Select from "../../selectbox/Select";
import TimeSettingWrap from "../../../containers/playlist/TimeSettingWrap";
import {deviceMappingContents} from "../../../containers/playlist/playlistUtil";
import {commonConstants} from "../../../constants/CommonConstants";
import {initCacheTable} from "../../../helper/cache/tableCache";

class CreatePlaylistPopup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            playlistType: playlistConstants.PLAYLISTTYPES[0],
            playlistTypeList : [{ value: 'general', title: "COM_TEXT_GENERAL_P"}],
            height: 240,
            contentDuration: 5,
            deviceMemoDisplay: 'none',
            playlistTypeMemoDisplay: 'none',
            isAplayerSupport: props.contentItems && props.contentItems.findIndex(item => !item.isSupportAPlayer) < 0,
            isWplayerSupport: this.isSupportWplayer(props.contentItems)? true: false,
            errorDisplayStyle: 'none',
            optionDisable: true,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
        this.setState({height: window.innerHeight - 210});
    }

    updateTimeSetting = (updateItem) => {
        this.setState(updateItem);
    }

    closePopup = () => {
        const {closePopup, id} = this.props;
        closePopup(id);
    }

    isSupportWplayer = (contentItems) => {
        let support = true;
        contentItems && contentItems.forEach((item) => {
            if(!item.isSupportWPlayer) {
                support = false;
            }
        })
        return support
    }

    getMediaType = () => {
        const {contentItems} = this.props;
        let mediaTypeArray = contentItems.map((item) => item.mediaType);
        mediaTypeArray =  mediaTypeArray.reduce((acc,curr) => acc.includes(curr) ? acc : [...acc,curr],[]);
        return mediaTypeArray;
    }

    createPlaylist = () => {
        const {closePopup, id, loadContent, initNewPlaylist, addTab, t, contentItems, menu, addPopup, activeTab, destroyPlaylist} = this.props;
        const {playlistType, contentDuration} = this.state;

        if (!this.state.selected) {
            this.setState({
                errorDisplayStyle: 'block'
            })
            return;
        }

        const {devices, selected} = this.state.selected;

        if (playlistType.id === '5'){
            playlistType.contentDuration = contentDuration;
        }

        const findIndex = selected.pop();
        const deviceType = devices.find((item, index) => findIndex === index);

        const playlistInfo = {
            playlistType: playlistType,
            deviceType: deviceType,
            contentItems: contentItems
        };

        if(menu.tabs.filter(tab => tab.id === "NEW_PLAYLIST").length > 0) {
            closePopup(id);
            activeTab("NEW_PLAYLIST");
            addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                message: t("MESSAGE_UPLOADER_REALLY_CANCEL_P"),
                title: t("COM_BUTTON_CONFIRM"),
                onClickYes: ()=> {
                    destroyPlaylist('NEW');
                    const tab = {id: 'NEW_PLAYLIST', title: t("TEXT_PLAYLIST_NEW_P"), active: true, close: true};
                    const add = () => addTab(tab);

                    new Promise((resolve) => {
                        closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                        add();
                        resolve();
                    }).then(
                        loadContent('NEW_PLAYLIST')
                    ).then(
                        initNewPlaylist(playlistInfo)
                    );
                },
                onClose: () => {
                    closePopup(commonConstants.COMMON_CONFIRM_POPUP)}
            })
        }

        else {
            const tab = {id: 'NEW_PLAYLIST', title: t("TEXT_PLAYLIST_NEW_P"), active: true, close: true};
            const add = () => addTab(tab);

            new Promise((resolve) => {
                add();
                resolve();
            }).then(
                loadContent('NEW_PLAYLIST')
            ).then(
                closePopup(id)
            ).then(
                initNewPlaylist(playlistInfo)
            );
        }
    }

    onChangePlaylistType = (e) => {
        const playlistType = playlistConstants.PLAYLISTTYPES.find((type)=> type.value === e.target.getAttribute('data-value'))

        this.setState({
            playlistType
        })
    }

    selectedDevice = (devices, selected) => {
        this.setState({
            selected: {
                devices: devices,
                selected: selected
            },
            errorDisplayStyle: 'none'
        }, ()=>{
        })
    }

    onChangeDevice = (device) => {
        const playlistTypeList = [
            { value: 'general', title: "COM_TEXT_GENERAL_P"},
            { value: 'nested', title: "MIS_SID_NESTED"},
            { value: 'tag', title: "MIS_TEXT_TAG_P"},
            { value: 'isVwl', title: "COM_DID_RMS_DEVICE_VIDEO_WALL"},
            { value: 'sync', title: "MIS_SID_SYNC_PLAY"},
            { value: 'ad', title: "COM_DID_LFD_ADVERTISEMENT"},
        ];

        if (device !== undefined) {
            if (device.text === "S3") {
                playlistTypeList.splice(5, 1);
            } else if (device.text === "S2") {
                playlistTypeList.splice(4, 2);
            } else if (device.text === "S1"){
                playlistTypeList.splice(2, 4);
            } else if (device.text === "Lite") {
                playlistTypeList.splice(1, 5);
            } else if(device.text === "APlayer") {
                playlistTypeList.splice(2, 4);
            } else if (device.text === "W player") {
                playlistTypeList.splice(5, 1);
                playlistTypeList.splice(3, 1);
            }
        }

        if (this.props.contentItems) {
            playlistTypeList.splice(4, 1);
            playlistTypeList.splice(2, 1);

            if (device.text === "S3"|| device.text === "S2") {
                playlistTypeList.splice(3, 1);
            } else if (device.text === "Lite") {
                playlistTypeList.splice(1, 2);
            } else if(device.text === "APlayer" || device.text === "W player" || device.text === 'S1') {
                playlistTypeList.splice(2, 2);
            }
        }

        this.setState({
            playlistTypeList,
            playlistType: playlistConstants.PLAYLISTTYPES[0],
            optionDisable: false,
        })
    }

    render() {
        const {t} = this.props;
        const dialogProps ={title: t("TEXT_PLAYLIST_NEW_P"), closeOnEscape : true, width:724, height : 'auto' ,position: {x: -300, y: -400} , modal:true, onClose:()=> this.closePopup()};
        const {playlistType, deviceMemoDisplay, playlistTypeMemoDisplay, contentDuration, isAplayerSupport, isWplayerSupport, errorDisplayStyle,optionDisable} = this.state;

        return (
            <div className={'create_playlist'}>
                <MISDialog
                    dialog = {dialogProps}
                    buttons = {{
                        rightButtons: [
                            {title : t("COM_TEXT_CREATE_P"), id: 'createPlaylist', onClick: ()=> this.createPlaylist()},
                            {title : t("BUTTON_CANCEL_P"), id: 'closeCreatePlaylist', onClick: ()=> this.closePopup()}
                        ]
                    }}
                >
                    <div className={"create_playlist_popup_wrap"}>
                        <div className="quest_img_noti">{t("COM_MAPP_SID_SUPPORTED_DEVICES")}
                            <a onMouseOver={()=> this.setState({deviceMemoDisplay:'block'})} onMouseLeave={() => this.setState({deviceMemoDisplay:'none'})}/>
                            <div className={"opacity_notice_wrap"}
                                 style={{minWidth: 420, left: 165, position: 'absolute', padding: '10px 20px', zIndex: 2, display: deviceMemoDisplay}}>
                                <table><colgroup><col width={119} /><col width="" /></colgroup><tbody>
                                <tr><td>{t('MIS_SID_20_WHEN_YOU_SELECT_A_PLAYER_ALL_HIGHER_VERSIONS_ARE_AUTOMATICALLY_SELECTED')}
                                </td></tr></tbody></table>
                            </div>
                        </div>
                        <div className="device_type_btn mt15 mb15" style={{display:'inline-block'}}>
                            <SelectSupportedDevice
                                type={this.props.contentItems ? 'addToPlaylist' : "playlist"}
                                onChange={this.onChangeDevice}
                                deviceType={this.props.deviceType}
                                deviceTypeVersion={parseInt(this.props.deviceTypeVersion)}
                                isAplayerSupport={isAplayerSupport}
                                isWplayerSupport={isWplayerSupport}
                                selectedDevice={this.selectedDevice}
                                mediaType={this.props.contentItems ? this.getMediaType() : undefined}
                            />
                        </div>
                        <span className="error_txt2" id="supportedDeviceErrorMsg" style={{display: errorDisplayStyle}}>{t("COM_MIS_SID_20_SELECT_A_SUPPORTED_DEVICE")}</span>

                        <div className="quest_img_noti mt17">{t("MIS_SID_PLAYLIST_TYPE")}
                            <a onMouseOver={()=> this.setState({playlistTypeMemoDisplay:'block'})} onMouseLeave={() => this.setState({playlistTypeMemoDisplay:'none'})}/>
                            <div className={"opacity_notice_wrap"}
                                 style={{minWidth: 600, left: 165, position: 'absolute', padding: '10px 20px', zIndex: 101, opacity: 0.99, display: playlistTypeMemoDisplay}}>
                                <table><colgroup><col width={119} /><col width="" /></colgroup><tbody>
                                <tr><td>{t('MIS_SID_20_GENERAL_CREATE_A_NORMAL_PLAYLIST')}</td></tr>
                                <tr><td>{t('MIS_SID_TAG_CREATE_PLAYLIST_CONTENT_TAGS')}</td></tr>
                                <tr><td>{t('MIS_SID_20_VIDEO_WALL_CREATE_A_PLAYLIST_TO_BE_PLAYED_ON_A_VIDEO_WALL')}</td></tr>
                                <tr><td>{t('MIS_SID_20_AD_CREATE_PLAYLIST_ADD_SCHEDULE')}</td></tr>
                                <tr><td>{t('MIS_SID_20_SYNC_PLAY_CREATE_A_COLLECTIONSIMULTANEOUSLY_BETWEEN_ONE_TO_THE_NEXT')}</td></tr>
                                <tr><td>{t('MIS_SID_NESTED_PLAYLIST_CREATE_GENERAL_PLAYLIST')}</td></tr>
                                </tbody></table>
                            </div>
                        </div>
                        <div className="mt15 mb20">
                            <Select
                                value={this.state.playlistType.value}
                                width={200}
                                selects={this.state.playlistTypeList}
                                optionStyle={{zIndex: 100}}
                                onChange={(e)=> this.onChangePlaylistType(e)}
                                disabled={optionDisable}
                            />
                        </div>
                        {
                            playlistType.id === '5' &&
                            <div className="playlistOption">
                                <div className="quest_img_noti mt17" style={{width:'100%'}}>{t("TEXT_DEFAULT_CONTENT_DURATION_P")}</div>
                                <TimeSettingWrap type={'tag'} content={{contentDuration}} updateItem={this.updateTimeSetting}/>
                            </div>
                        }
                    </div>
                </MISDialog>
            </div>
        )
    }
}
export default connect(
    state => ({
        menu: state.menu,
        playlist: state.playlist
    }),
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
        initNewPlaylist: (playlistInfo)=>dispatch(playlistAction.initNewPlaylist(playlistInfo)),
        addTab: (tab)=> dispatch(menuAction.addTab(tab)),
        activeTab: (id) => dispatch(menuAction.activeTab(id)),
        loadContent: (id) => dispatch(menuAction.loadContent(id)),
        destroyPlaylist: (mode)=>dispatch(playlistAction.destroyPlaylist(mode))
    })
)(withTranslation()(CreatePlaylistPopup));
