import React, { Component } from 'react';
import './RuleNode.css';
import StartNode from './nodes/StartNode'
import ResultNode from './nodes/ResultNode';
import ConditionNode from './nodes/ConditionNode';
import AddOrButtonNode from './nodes/AddOrButtonNode';

class RuleNode extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        this.elem = this.props.elem;
        this.parentElem = this.props.parentElem;
        this.ruleId = this.props.ruleId;
        
        return(
            <div>
                { this.elem.type === "start" &&
                    <StartNode elem={this.elem} rule={this.props.rule} ruleId={this.ruleId} />
                }
                { this.elem.condition && 
                    <ConditionNode elem={this.elem} parentElem={this.parentElem} condition={this.elem.condition} entryIdIndex={this.elem.entryIdIndex} ruleId={this.ruleId} />
                }
                { this.elem.result &&
                    <ResultNode elem={this.elem} parentElem={this.parentElem} result={this.elem.result} entryIdIndex={this.elem.entryIdIndex} ruleId={this.ruleId} isDefault={this.elem.id === "default"}/>
                }
                { this.elem.type === "addOrButton" &&
                    <AddOrButtonNode elem={this.elem} parentElem={this.parentElem} ruleId={this.ruleId}/>
                }
            </div>
        )
    }
}

export default RuleNode;