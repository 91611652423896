import {useTranslation} from "react-i18next";
import {HorizontalBar} from "react-chartjs-2";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {dashboardService} from "services";
import {Loading} from "../../../../components/loading/Loading";

const ErrorWarningByCount = () => {

    const { t } = useTranslation();
    const [chartData, setChartData] = useState({});
    const [loading, setLoading] = useState(true);
    const [showChart, setShowChart] = useState(true);

    const _makeChart = (deviceHealthByHW) => {
        const data = {};
        const labels = [];
        const errors = [];
        const warnings = [];

        deviceHealthByHW.map(
            hw => {
                labels.push(hw.category);
                errors.push(hw.error);
                warnings.push(hw.warning);
            }
        )

        data.labels = labels && labels.length > 0 ? labels : ['Sensor', 'System', 'Black Screen', 'HDMI Out', 'Audio', 'One Connect'];

        data.datasets = [
            {
                label: 'Error',
                backgroundColor: 'rgba(235,92,65,1)',
                borderWidth: 1,
                data: errors,
                stack: 1,
                xAxisID: 'bar-error',
            },
            {
                label: 'Warning',
                backgroundColor: 'rgba(248,173,56,1)',
                borderWidth: 1,
                data: warnings,
                stack: 1,
                xAxisID: 'bar-error',
            }
        ];
        setChartData(data);
    }

    const fetchData = () => {
        dashboardService.fetchDeviceHealthByCategory().then(res => {
            if (res && res.items) {
                _makeChart(res.items);
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            setShowChart(false);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(()=> {
        fetchData();
    }, [])

    const getHeightForChart = useCallback(()=> {
        if (chartData && chartData.labels && chartData.labels.length > 0) {
            const height = chartData.labels.length * 30;
            return height > 200 ? 240 : height;
        }
        return 240;
    }, [chartData])

    const heightForChart = getHeightForChart();

    const [openToolTip, setOpenToolTip] = useState(false);

    const chartRef = useRef(null);

    const positionRef = useRef({});

    const GraphTooltip = ({}) => {

        const position = positionRef.current;

        return (
            <div
                style={{
                    padding: 5,
                    position: "fixed",
                    border: "0px solid",
                    backgroundColor: "#000000",
                    borderRadius: 4,
                    top: position.top,
                    left: position.left,
                    color: '#fff'
                }}
            >
                <div>
                    <div>{position.label}</div>
                    {
                        position.error > 0 &&
                        <div style={{display: 'flex', lineHeight: '8px', height: 13}}>
                            <div style={{width: 10, height: 10, backgroundColor: 'rgba(235,92,65,1)'}}/>
                            <div style={{marginLeft: 3}}>Error: {position.error}</div>
                        </div>
                    }
                    {
                        position.warning > 0 &&
                        <div style={{display: 'flex', lineHeight: '8px', height: 13}}>
                            <div style={{width: 10, height: 10, backgroundColor: 'rgba(248,173,56,1)'}}/>
                            <div style={{marginLeft: 3}}>Warning: {position.warning}</div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div style={{width: '50%', height: heightForChart, margin: 10, display: 'block'}}>
            {
                showChart ?
                    ((!loading) ?
                        <HorizontalBar
                            ref={chartRef}
                            id={'errorWarningByCount'}
                            data={chartData}
                            options={{
                                indexAxis: 'y',
                                showDataPointsOnVertical: true,
                                maintainAspectRatio: false,

                                legend: {
                                    display: false,
                                },
                                animation: {
                                    duration: 0
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            id: "bar-error",
                                            display: false,
                                            stacked: true,
                                            gridLines: {
                                                display: false
                                            }
                                        }
                                    ],
                                    yAxes: [{
                                        borderSkipped: 'top',
                                        gridLines: {
                                            display: false,
                                            color: '#F5F5F5',
                                        },
                                        type: 'category',
                                        stacked: false,
                                        ticks: {
                                            beginAtZero: true,
                                            fontSize: 12,
                                            fontColor: '#454545',
                                            labelOffset: -3,
                                            stepSize: 14
                                        },
                                        barThickness: 10,
                                        maxBarThickness: 10,
                                        afterFit: function (scaleInstance) {
                                            scaleInstance.width = 100; // sets the width to 100px
                                        }
                                    }]

                                },
                                tooltips: {
                                    // Disable the on-canvas tooltip
                                    enabled: false,
                                    custom: (tooltip) => {
                                        let chart = chartRef.current;
                                        if (!chart) {
                                            return;
                                        }

                                        if (tooltip.opacity === 0) {
                                            console.log('opacity');
                                            setOpenToolTip(false);
                                            return;
                                        }

                                        const canvas = chartRef.current.chartInstance.ctx.canvas;
                                        if (canvas) {
                                            const position = canvas.getBoundingClientRect();

                                            const left = position.left + tooltip.caretX;
                                            const top = position.top + tooltip.caretY;
                                            const label = tooltip.dataPoints[0].label;
                                            const error = tooltip.dataPoints[0].xLabel;
                                            const warning = tooltip.dataPoints[1].xLabel;
                                            positionRef.current = { left, top, label, error, warning };
                                            setOpenToolTip(true);
                                        }
                                    }
                                }
                            }}
                        /> : <div className={'area_center'}> <Loading></Loading></div>)
                    : <div className={'area_center no_data'}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
            }
            {openToolTip ? <GraphTooltip /> : <div />}
        </div>
    )
}



export default ErrorWarningByCount;