import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {toUpper} from 'lodash';
import MISDialog from '../MISDialog';
import {SPLAYER, WPLAYER, LPLAYER} from '../../../constants';
import { deviceService } from '../../../services';
import RadioGroup from '../../radio/RadioGroup';
import {getMixString} from '../../../language/languageUtils';

const UnapprovedTypeConversion = ({deviceIds, successCnt, status, deviceType}) => {
    const {t} = useTranslation();

    return (
        <tbody>
            {
                status === 'init' &&
                <tr>
                    <td style={{whiteSpace:'initial'}} colSpan="2">{t('MIS_SID_CONVERT_DEVICE_TYPE_BELOW')}</td>
                </tr>
            }
            {
                status === 'processing' &&
                <>
                    <tr>
                        <td>{t('COM_MSG_PROCESSING')}</td>
                    </tr>
                    <tr>
                        <td>{t('COM_TV_SID_DEVICE_ID_AUTO_SET_PROCESSINGS_KR_MIS')}</td>
                    </tr>
                </>
            }
            {
                status === 'completed' && (
                    successCnt === 0 ?
                    <tr>
                        <td>{t('MIS_SID_ALL_DEVICES_FAILED_CONVERT')}</td>
                    </tr> :
                    <>
                        <tr>
                            <td>{t('MIS_SID_MIX_OUT_OF_DEVICES_CONVERTED_SUCCESSFULLY').replace('<<B>>', deviceIds.length).replace('<<A>>', successCnt)}</td>
                        </tr>
                        <tr>
                            <td>{t('MIS_SID_CONVERT_DEVICES_WILL_BE_REBOOTED')}</td>
                        </tr>
                    </>
                )
            }
            {
                deviceType === WPLAYER &&
                <tr>
                    <td style={{whiteSpace:'initial',backgroundColor:'#D9D9D9',textAlign:'center'}} colSpan="2">
                        W Player -> S Player
                    </td>
                </tr>
            }
            {
                deviceType === SPLAYER &&
                <tr>
                    <td style={{whiteSpace:'initial',backgroundColor:'#D9D9D9',textAlign:'center'}} colSpan="2">
                        S Player -> W Player
                    </td>
                </tr>
            }
        </tbody>
    );
};

const wplayerOptions = [
    {value: 0, title: 'Lite'},
    {value: 1, title: 'S Player'}
];

const lplayerOptions = [
    {value: 1, title: 'S Player'},
    {value: 2, title: 'W Player'}
];

const splayerOptions = [
    {value: 0, title: 'Lite'},
    {value: 2, title: 'W Player'}
]

const TypeConversion = ({deviceIds, successCnt, status, deviceType, supportWPlayer, switchType, onChange}) => {
    const {t} = useTranslation();

    const lpOptions = [...lplayerOptions];
    lpOptions[1].hide = !supportWPlayer;

    const spOptions = [...splayerOptions];
    spOptions[1].hide = !supportWPlayer;

    return (
        <tbody>
            {
                status === 'init' &&
                <>
                    <tr>
                        <td style={{whiteSpace:'initial'}} colSpan="2">{t('MIS_SID_SELELCT_DEVICE_TYPE_CONVERT')}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace:'initial'}} colSpan="2">{t('MIS_SID_SERVER_SCHEDULE_CONTENT_DELETED_CONVERTED')}</td>
                    </tr>
                </>
            }
            {
                status === 'processing' &&
                <>
                    <tr>
                        <td>{t('COM_MSG_PROCESSING')}</td>
                    </tr>
                    <tr>
                        <td>{t('COM_TV_SID_DEVICE_ID_AUTO_SET_PROCESSINGS_KR_MIS')}</td>
                    </tr>
                </>
            }
            {
                status === 'completed' &&
                <>
                    <tr>
                        <td>{getMixString(['MIS_SID_MIX_OUT_OF_DEVICES_CONVERTED_SUCCESSFULLY', successCnt, deviceIds.length])}</td>
                    </tr>
                    <tr>
                        <td>{t('MIS_SID_CONVERT_DEVICES_WILL_BE_REBOOTED')}</td>
                    </tr>
                </>
            }
            <tr>
                <td style={{width:180,display: 'inline'}}>{t('MIS_TEXT_DEVICE_TYPE_P')}</td>
                <td style={{display: '-webkit-inline-box', paddingLeft: 33, lineHeight:'25px'}}>
                    {
                        deviceType === WPLAYER &&
                        <RadioGroup selects={wplayerOptions} value={switchType} onChange={onChange} multiLang={false} />
                    }
                    {
                        deviceType === LPLAYER &&
                        <RadioGroup selects={lpOptions} value={switchType} onChange={onChange} multiLang={false} />
                    }
                    {
                        deviceType !== WPLAYER && deviceType !== LPLAYER &&
                        <RadioGroup selects={spOptions} value={switchType} onChange={onChange} multiLang={false} />
                    }
                </td>
            </tr>
        </tbody>
    );
};

const TypeConversionPopup = ({conversionType, deviceType, deviceIds, supportWPlayer, onOk, onClose}) => {
    const {t} = useTranslation();
    const [status, setStatus] = useState('init');
    const [successCnt, setSuccessCnt] = useState(0);
    const [switchType, setSwitchType] = useState(deviceType === LPLAYER ? 1 : 0);
    const pollingHandle = useRef();
    const retryCnt = useRef(0);

    const onClickOk = () => {
        if(status === 'init') {
            setStatus('processing');
            deviceService.updateDeviceType(deviceIds, conversionType === 'unapproved' ? (deviceType === WPLAYER ? 1 : 2) : switchType).then(res => {
                if(res.items.requestId) {
                    let successDeviceIds = [];
                    pollingHandle.current = setInterval(() => {
                        deviceService.fetchDeviceTypeConversionStatus(deviceIds, res.items.requestId).then(statusRes => {
                            retryCnt.current ++;

                            statusRes.items.map(item => {
                                if(toUpper(item.status) === 'SUCCESS') {
                                    if(successDeviceIds.filter(success => success === item.id).length === 0) {
                                        successDeviceIds.push(item.id);
                                    }
                                }
                            });
    
                            if(successDeviceIds.length === deviceIds.length || retryCnt.current === 30) {
                                setSuccessCnt(successDeviceIds.length);
                                setStatus('completed');
                                clearInterval(pollingHandle.current);
                                successDeviceIds = [];
                            }
                        });
                    }, 1000);
                }
            }).catch(error => console.log(error));
        } else if(status === 'completed') {
            onOk && onOk();
        }
    };

    useEffect(() => {
        return () => {
            if(pollingHandle.current) {
                clearInterval(pollingHandle.current);
            }
        };
    }, [])

    return (
        <MISDialog
            dialog={{
                title: t('MIS_SID_CONVERT'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 550,
                height: 160,
                onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickOk,
                        disable: status === 'processing',
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                        hide: status === 'completed'
                    },
                ]
            }}
        >
            <div className="detail_view mb15">
                <table>
                    {
                        conversionType === 'unapproved' ?
                        <UnapprovedTypeConversion status={status} deviceType={deviceType} deviceIds={deviceIds} successCnt={successCnt} /> :
                        <TypeConversion status={status} deviceType={deviceType} supportWPlayer={supportWPlayer} deviceIds={deviceIds} 
                            successCnt={successCnt} switchType={switchType} onChange={(e, value) => setSwitchType(value)} />
                    }
                </table>
            </div>
        </MISDialog>
    );
};

export default TypeConversionPopup;