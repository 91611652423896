import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {isString} from 'lodash';
import './SubTab.css';

const SubTab = ({tabs, selected, multiLang, className, style, viewSize, onClick}) => {
    const {t} = useTranslation();
    const [current, setCurrent] = useState(0);

    const onClickPrev = () => {
        if(current > 0) {
            setCurrent(current - 1);
        }
    };

    const onClickNext = () => {
        if((current + 1) * viewSize < tabs.length) {
            setCurrent(current + 1);
        }
    };

    return (
        <div className='sub_tab_wrap'>
            <ul className={className} style={style}>
                {
                    viewSize > 0 && tabs.length > viewSize && current > 0 &&
                    <li className='tabtitle arrttn prev'>
                        <button type='button' onClick={onClickPrev}><span></span></button>
                    </li>
                }
                {
                    tabs.map((tab, index) => {
                        if(viewSize === 0 || (index >= viewSize * current && index < viewSize * (current + 1))) {
                            const tabName = isString(tab) ? (multiLang ? t(tab) : tab) : tab.name;
                            return (
                                <li 
                                    key={isString(tab) ? (multiLang ? t(tab) : tab) : `subtab_${tab.id}`} 
                                    className={classNames({on: isString(tab) ? tab === selected : tab.id === selected})}
                                    onClick={() => onClick(isString(tab) ? tab : tab.id)}>
                                    <span title={tab.tooltip ? tab.tooltip : tabName}>{tabName}</span>
                                </li>
                            );
                        } else {
                            return null;
                        }
                    })
                }
                {
                    viewSize > 0 && tabs.length > viewSize && (current + 1) * viewSize < tabs.length &&
                    <li className='tabtitle arrttn next'>
                        <button type='button' onClick={onClickNext}><span></span></button>
                    </li>
                }
            </ul>
        </div>
    );
};

SubTab.defaultProps = {
    tabs: [],
    multiLang: true,
    viewSize: 0,
    onClick: () => {},
};

export default SubTab;