import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {commonConstants} from '../../../constants';
import {menuAction, popupAction, rulesetAction} from '../../../actions';
import './ConditionNode.css';
import {contextMenu, Item, Menu} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import {toastr} from 'helper/toastrIntercept';
import {
    assignUUID,
    checkConditionCount,
    checkDepth,
    getConditionType,
    getOpType,
    getParents
} from '../../../helper/ruleset/rulesetUtils';
import {getMixString,} from "../../../language/languageUtils";
import ButtonArea from "./ButtonArea";
import uuid from "uuid";
import VerificationCountingBadge from './VerificationCountingBadge';
import {rulesetService} from '../../../services';

class ConditionNode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            children: props.elem.children
        };
    }

    addNewAndConditionSave(condition) {
        const addCondition = this.props.addCondition;

        const tmpChildren = this.elem.children;
        this.elem.children = [];

        let newCondition = {
            id : condition.conditionId,
            type: "condition",
            children: tmpChildren
        };
        this.elem.children.push(newCondition);
        
        addCondition(this.currentTab, condition);
    }

    checkCanAddAndCondition = () => {
        const {t} = this.props;

        try {
            if(!checkConditionCount()) {
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
                return false;
            }

            const ruleId = this.props.ruleId;
            let elem = document.querySelector('div[rule_id="' + ruleId + '"]').querySelector('div[data-entry-id="' + this.elem.entryIdIndex + '"]')
            const parentResults = getParents(elem, ".ruletree-entry");
            
            if(!checkDepth(this.elem.children, parentResults.length)) {
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
                return false;
            }
        }
        catch (err) {
            toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            return false;
        }

        return true;
    }

    onNewAndCondition = () => {
        if(!this.checkCanAddAndCondition()) {
            return;
        }

        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;
        
        addPopup({
            id: commonConstants.RULESET_CONDITION_POPUP,
            type: commonConstants.RULESET_CONDITION_POPUP,            
            mode : "NEW",
            save : (condition) => {
                if(condition.isPublic) {
                    this.addToLibrary(condition);
                }
                condition.isPublic = false;
                this.addNewAndConditionSave(condition);
                
                // "!" 버튼 없애기 위함.
                this.setState({
                    children: this.props.elem.children
                });
            },
            close: () => closePopup(commonConstants.RULESET_CONDITION_POPUP)
        });
    }

    openEditCondtiionPopup = () => {
        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;
        const editCondition = this.props.editCondition;
        
        addPopup({
            id: commonConstants.RULESET_CONDITION_POPUP,
            type: commonConstants.RULESET_CONDITION_POPUP,
            mode : "EDIT",
            condition: this.props.condition,
            save : (condition) => {
                if(condition.isPublic) {
                    this.addToLibrary(condition);
                }
                condition.isPublic = false;
                editCondition(this.currentTab, condition);
            },
            close: () => closePopup(commonConstants.RULESET_CONDITION_POPUP)
        });
    }

    addNewPlaySave(play) {

        play.contentsIDList = [];

        play.contentList.map((value, index) => {
            value.name = value.contentName;
            play.contentsIDList.push(value.contentId);
        });
        
        if(play.defaultDuration === undefined) {
            play.defaultDuration = 5;
        }

        const addPlay = this.props.addPlay;
        play.isPublic = false;

        let newPlay = {
            id : play.resultId,
            type: "result",
            children: []
        };

        this.elem.children.push(newPlay);
        addPlay(this.currentTab, play);
    }

    onNewPlayRule = () => {
        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;

        let device;
        if(this.currentTab === "NEW_RULESET") {
            device = {
                deviceType: this.props.rulesets.newRuleset.ruleset.deviceType,
                deviceTypeVersion: this.props.rulesets.newRuleset.ruleset.deviceTypeVersion
            };
        }
        else {
            device = {
                deviceType: this.props.rulesets.editRuleset.ruleset.deviceType,
                deviceTypeVersion: this.props.rulesets.editRuleset.ruleset.deviceTypeVersion
            };
        }

        addPopup({
            id: commonConstants.RULESET_PLAY_POPUP,
            type: commonConstants.RULESET_PLAY_POPUP,   
            mode : "NEW",
            device : device,
            save : (newPlay) => {
                if(newPlay.isPublic) {
                    this.playAddToLibrary(newPlay);
                }
                this.addNewPlaySave(newPlay);

                // "!" 버튼 없애기 위함.
                this.setState({
                    children: this.props.elem.children
                });
            },
            close: () => closePopup(commonConstants.RULESET_PLAY_POPUP)
        });
    }

    deleteNode(deleteChildren) {
        const redraw = this.props.redraw;
        const childrenOfDelNode = deleteChildren ? [] : this.elem.children;

        for(let i = 0; i < this.parentElem.children.length; i++) {
            if(this.parentElem.children[i].id === this.elem.id) {
                this.parentElem.children.splice(i, 1);

                let insertIndex = i;
                childrenOfDelNode.map((value) => {
                    this.parentElem.children.splice(insertIndex++, 0, value);
                });
                
                break;
            }
        }

        redraw();
    }

    onDeleteNode() {
        const { addPopup, closePopup, t } = this.props;
        
        if(this.elem.children && this.elem.children.length > 0) {
            if(this.elem.children[this.elem.children.length - 1].id === "default"
                && this.parentElem.children[this.parentElem.children.length - 1].id === "default") {
                toastr.error(t("COM_TV_SID_EDEN_UNABLE_DELTE"));
                return;
            }
        }

        if(this.elem.children && this.elem.children.length > 0
            && this.elem.children[0].result) {

            addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_BUTTON_CONFIRM"),
                message: getMixString(["MIS_SID_MIX_CAFEB_THIS_LINK_DEL_ITEM_ALSO", "MIS_SID_CONDITION_RULE", "MIS_SID_PLAY_RULE"]) + "\n" + t("ALERT_WANT_DELETE"),
                onClickYes: () => {
                    this.deleteNode(true);
                    closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                },
                onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
            });

            return;
        }

        this.deleteNode(false);
    }

    addNodeByDnd = (item) => {
        if(item.type === 'conditionBox') {
            if(!this.checkCanAddAndCondition()) {
                return;
            }
            
            let condition = JSON.parse(JSON.stringify(item.condition));
            condition.conditionId = uuid();
            condition.isPublic = false;
            this.addNewAndConditionSave(condition);
        }

        else if(item.type === 'playBox') {
            let play = JSON.parse(JSON.stringify(item.play));
            play.resultId = uuid();
            play.isPublic = false;
            this.addNewPlaySave(play);
        }

        this.setState({
            children: this.props.elem.children
        });
    }

    addToLibrary = (newCondition) => {
        const {loadContent, menu} = this.props;
        const condition = JSON.parse(JSON.stringify(newCondition));
        condition.conditionId = uuid();
        condition.isPublic = true;
        rulesetService.createCondition(condition).then(
            res => {
                menu.submenu.key++;
                menu.submenu.mode = "CONDITION";                
                loadContent(menu.currentContentId);
            }
        ).catch(error => {toastr.error(error)})
        .finally();
    }

    playAddToLibrary = (newPlay) => {
        const {loadContent, menu} = this.props;
        const result = JSON.parse(JSON.stringify(newPlay));
        result.resultId = uuid();
        result.isPublic = true;
        rulesetService.createPlay(result).then(
            res => {
                menu.submenu.key++;
                menu.submenu.mode ="PLAY";
                loadContent(menu.currentContentId);
            }
        ).catch(error => {toastr.error(error)})
        .finally();
    }

    copyConditionNode = (withSubItems) => {
        const copy = this.props.copy;
        const copyData = JSON.parse(JSON.stringify(this.elem));
        
        if(!withSubItems) {
            copyData.children = [];
        }

        copy(this.currentTab, copyData);
    }

    pasteCopyData = () => {
        let ruleset;
        let copyData;
        if(this.currentTab === "NEW_RULESET") {
            ruleset = this.props.rulesets.newRuleset.ruleset;
            copyData = JSON.parse(JSON.stringify(this.props.rulesets.newRuleset.copyData));
        }
        else {
            ruleset = this.props.rulesets.editRuleset.ruleset;
            copyData = JSON.parse(JSON.stringify(this.props.rulesets.editRuleset.copyData));
        }

        if(this.elem.children.length > 0 && copyData.children.length > 0) {
            toastr.error(getMixString(['MIS_SID_MIX_ONLY_ONE_CAN_PASTED_BETWEEN_ITEMS', 'MIS_SID_CONDITION_RULE']));
            return;
        }

        if(this.elem.children.length > 0 && copyData.type === "result") {
            toastr.error(getMixString(['MIS_SID_MIX_CANNOT_PASTED_BETWEEN_ITEMS', 'MIS_SID_PLAY_RULE']));
            return;
        }

        assignUUID(ruleset, copyData);

        if(this.elem.children.length === 0) {
            this.elem.children.push(copyData);
        }

        else {
            copyData.children = this.elem.children;
            this.elem.children = [];
            this.elem.children.push(copyData);
        }

        this.props.redraw();
    }

    onOptionMenuItemClick(id) {
        switch(id) {
            case 'ADD_TO_LIBRARY':
                this.addToLibrary(this.elem.condition);
                break;
            case 'COPY':
                this.copyConditionNode(false);
                break;
            case 'COPY_WITH_SUBITEMS':
                this.copyConditionNode(true);
                break;
            case 'DELETE':
                this.onDeleteNode();
                break;
            case 'NEW_PLAY':
                this.onNewPlayRule();
                break;
            case 'NEW_AND_CONDITION':
                this.onNewAndCondition();
                break;
            case 'PASTE':
                this.pasteCopyData();
                break;
            default:
                break;
        }
    }

    showMenu(e, menuId) {
        e.preventDefault();
        contextMenu.show({
            id: menuId,
            event: e,
        });
    }

    render() {
        const {t, rulesets} = this.props;
        const {currentContentId} = this.props.menu;
        this.currentTab = currentContentId;

        this.elem = this.props.elem;
        this.parentElem = this.props.parentElem;
        const condition = this.props.condition;
        const entryIdIndex = this.props.entryIdIndex;
        const ruleId = this.props.ruleId;

        let opType;
        if(condition.isInvert) {
            opType = "invert";
        }
        else {
            opType = getOpType(condition.sign);
        }

        let value = "";
        if(condition.sign === "rg") {
            condition.values.map((element, index) => {
                let str = element.start + "~" + element.end;
                if(index > 0) {
                    value += ", ";
                }
                value += str;
            });
        }

        else {
            condition.values.map((element, index) => {
                if(index > 0) {
                    value += ", ";
                }

                value += element.value;
            });
        }

        const optionMenuId = "condition_" + ruleId + "-" + entryIdIndex;
        const noResultMenuId = "condition_no_result" + ruleId + "-" + entryIdIndex;
        const plusButtonMenuId = "condition_plus_button" + ruleId + "-" + entryIdIndex;

        const highlightPath = this.props.highlightPath;
        const fixedHighlightPath = this.props.fixedHighlightPath;
        let className = "ruletree-label ruletree-label-" + ruleId + "-" + entryIdIndex;
        if(ruleId === highlightPath.ruleId
            && highlightPath.path.indexOf(entryIdIndex) >= 0) {
            className = className + " highlight";
        }
        else if(ruleId === fixedHighlightPath.ruleId
            && fixedHighlightPath.path.indexOf(entryIdIndex) >= 0) {
            className = className + " fixed-highlight";
        }

        const deviceMap = this.currentTab === 'NEW_RULESET' ? rulesets.newRuleset.ruleset.verificationDeviceMap : rulesets.editRuleset.ruleset.verificationDeviceMap;
        const verificationMode = this.currentTab === "NEW_RULESET" ? rulesets.newRuleset.verificationMode.enabled : rulesets.editRuleset.verificationMode.enabled;

        let copyData;
        if(this.currentTab === "NEW_RULESET") {
            copyData = this.props.rulesets.newRuleset.copyData;
        }
        else {
            copyData = this.props.rulesets.editRuleset.copyData;
        }
        
        return(
            <div
                ref={ (divElement) => { this.divElement = divElement } }
                className={className} id={entryIdIndex + "_" + condition.conditionId}
                type="condition"
                rule-node-type={condition.type}
                >
                <button className="option_button" onClick={(e) => this.showMenu(e, optionMenuId)} />
                <div>
                    <div className="description name" onClick={(e) => { this.openEditCondtiionPopup(); }}>{condition.conditionName}</div>
                    <div className="description">{getConditionType(condition.type)}</div>
                    <div className={"description " + opType}>
                        <div className="op-icon circle">
                            <div className={"op-icon " + opType}></div>
                        </div>
                        <div style={{paddingLeft:"17px"}}>{value}</div>
                    </div>
                </div>
                { this.elem.children && this.elem.children.length > 0 &&
                
                    <ButtonArea
                        onButtonClick={(e) => {
                            this.showMenu(e, plusButtonMenuId);
                        }}
                        addNode={this.addNodeByDnd}/>
                }
                { (!this.elem.children || this.elem.children.length === 0) &&
                    <ButtonArea
                        accept={['conditionBox', 'playBox']}
                        areaStyle={{
                            position: "absolute",
                            top: "38px",
                            left: "205px",
                            width: "150px",
                            height: "85px",
                            border: "4px solid transparent"
                        }}
                        buttonClassName="ruleset_condition_no_result_button condition_plus_and"
                        buttonStyle={{
                            left: "-31px"
                        }}
                        onButtonClick={(e) => {
                            this.showMenu(e, noResultMenuId);
                        }}
                        addNode={this.addNodeByDnd} />
                }
                { verificationMode && this.elem.verificationData && this.elem.verificationData.deviceIds &&
                    <VerificationCountingBadge id={condition.conditionId} deviceIds={this.elem.verificationData.deviceIds}/>
                }
                
                <Menu id={optionMenuId} style={{zIndex:20}}>
                    <Item onClick={() => this.onOptionMenuItemClick('ADD_TO_LIBRARY')}>{t('MIS_SID_ADD_TO_LIBRARY')}</Item>
                    <Item onClick={() => this.onOptionMenuItemClick('COPY')}>{t('COM_IDS_EDIT_COPY')}</Item>
                    <Item onClick={() => this.onOptionMenuItemClick('COPY_WITH_SUBITEMS')}>{t('MIS_SID_COPY_WITH_SUB_ITEMS')}</Item>
                    <Item onClick={() => this.onOptionMenuItemClick('DELETE')}>{t('COM_BUTTON_DELETE')}</Item>
                </Menu>
                <Menu id={noResultMenuId} style={{zIndex:20}}>
                    <Item onClick={() => this.onOptionMenuItemClick('NEW_AND_CONDITION')}>{t('MIS_SID_ADD_AND_CONDITION_RULE')}</Item>
                    <Item onClick={() => this.onOptionMenuItemClick('NEW_PLAY')}>{getMixString(['MIS_SID_MIX_ADD', 'MIS_SID_PLAY_RULE'])}</Item>
                    <Item disabled={copyData === undefined} onClick={() => this.onOptionMenuItemClick('PASTE')}>{t('TEXT_PASTE_P')}</Item>
                </Menu>
                <Menu id={plusButtonMenuId} style={{zIndex:20}}>
                    <Item onClick={() => this.onOptionMenuItemClick('NEW_AND_CONDITION')}>{t('MIS_SID_ADD_AND_CONDITION_RULE')}</Item>
                    <Item disabled={copyData === undefined} onClick={() => this.onOptionMenuItemClick('PASTE')}>{t('TEXT_PASTE_P')}</Item>
                </Menu>
            </div>
        )
    }
}

export default connect(
    state => ({
        menu : state.menu,
        rulesets : state.rulesets,
        highlightPath: state.rulesets.highlightPath,
        fixedHighlightPath: state.rulesets.fixedHighlightPath,
    }),
    dispatch => ({
        addCondition: (currentTab, condition) => dispatch(rulesetAction.addCondition(currentTab, condition)),
        editCondition: (currentTab, condition) => dispatch(rulesetAction.editCondition(currentTab, condition)),
        addPlay: (currentTab, play) => dispatch(rulesetAction.addPlay(currentTab, play)),
        redraw: () => dispatch(rulesetAction.redraw()),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        copy: (currentTab, copyData) => dispatch(rulesetAction.copy(currentTab, copyData)),
        loadContent: (id) => dispatch(menuAction.loadContent(id))
    })
)(withTranslation()(ConditionNode));