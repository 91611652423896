import {getMixString} from '../language/languageUtils';
import {LEDBOX} from '../constants/DevicesConstants';
import i18n from '../language/i18n';
import {fromJS} from 'immutable';

const getNewContentMenuItems = () => {

    let newContentMenu = [
        {key: 'UPLOAD_CONTENT', title: "TEXT_TITLE_CONTENT_UPLOAD_P", popup: true},
        {key: 'UPLOAD_WEB_CONTENT', title: "MIS_SID_WEBAUTHOR_UPLOAD_WEB_CONTENT", popup: true},
        {key: 'SSSP_WEB_APP', title: "MIS_SID_UPLOAD_SSSP_WEB_APP", popup: true},
        {key: 'CREATE_FTP', title: 'FTP', popup: true},
        {key: 'CREATE_CIFS', title: 'CIFS', popup: true},
        {key: 'CREATE_STREAMING', title: "TEXT_IS_STREAMING_P", popup: true},
        {key: 'CREATE_WEB_URL', title: "MIS_SID_WEBAUTHOR_WEB_URL", popup: true}
    ];

    if(window.globalConfig && window.globalConfig.content) {
        const enableADSContent = window.globalConfig.content.enableADSContent;
        if(enableADSContent && enableADSContent === true) {
            newContentMenu.push({key: 'CREATE_ADS', title: "MIS_TEXT_ADS_CONTENT_P", popup: true});
        }
    }

    return newContentMenu;
}

export const immutableMenu = fromJS({
    menus: {

        NEW_ADVERTISEMENT: {
            id: 'NEW_ADVERTISEMENT',
            buttons: [],
            nodes: [],
            contents: [],
            hasSubmenu: true,
            showSubmenu: true
        },
        EDIT_ADVERTISEMENT: {
            id: 'EDIT_ADVERTISEMENT',
            buttons: [],
            nodes: [],
            contents: [],
            hasSubmenu: true,
            showSubmenu: true
        },
        NEW_SCHEDULE : {
            id: 'NEW_SCHEDULE',
            buttons: [],
            nodes: [],
            contents: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        EDIT_SCHEDULE : {
            id: 'EDIT_SCHEDULE',
            buttons: [],
            nodes: [],
            contents: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        SIMPLE_SCHEDULE: {
            id: 'SIMPLE_SCHEDULE',
            buttons: [],
            nodes: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        NEW_PLAYLIST : {
            id: 'NEW_PLAYLIST',
            buttons: [],
            nodes: [],
            contents: [],
            hasSubmenu: false,
            showSubmenu: false,
        },

        EDIT_PLAYLIST : {
            id: 'EDIT_PLAYLIST',
            buttons: [],
            nodes: [],
            contents: [],
            hasSubmenu: false,
            showSubmenu: false,
        },

        NEW_MESSAGE : {
            id: 'NEW_MESSAGE',
            buttons: [],
            nodes: [],
            messages: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        EDIT_MESSAGE : {
            id: 'EDIT_MESSAGE',
            buttons: [],
            nodes: [],
            messages: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        NEW_EVENT_CONDITION : {
            id: 'NEW_EVENT_CONDITION',
            buttons: [],
            nodes: [],
            messages: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        EDIT_EVENT_CONDITION : {
            id: 'EDIT_EVENT_CONDITION',
            buttons: [],
            nodes: [],
            messages: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        NEW_EVENT : {
            id: 'NEW_EVENT',
            buttons: [],
            nodes: [],
            messages: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        EDIT_EVENT : {
            id: 'EDIT_EVENT',
            buttons: [],
            nodes: [],
            messages: [],
            hasSubmenu: true,
            showSubmenu: true
        },

        DASHBOARD: {
            id: 'DASHBOARD',
            buttons: [],
            nodes: [],
            hasSubmenu: false,
            showSubmenu: false
        },

        INSIGHT: {
            id: 'INSIGHT',
            buttons: [],
            nodes: [],
            hasSubmenu: false,
            showSubmenu: false
        },

        RULE_MANAGER: {
            id: 'RULE_MANAGER',
            buttons: [],
            nodes: [],
            hasSubmenu: false,
            showSubmenu: false
        },

        LED_CABINET: {
            id: 'LED_CABINET',
            buttons: [],
            nodes: [
                {
                    id: 'DEVICE', title: 'TEXT_TITLE_DEVICE_P', active: false, children: [
                        {
                            id: 'ALL_DEVICE',
                            title: 'TEXT_TITLE_ALL_DEVICE_VIEW_P',
                            active: true,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: '',
                            options: {
                                view: 'IMAGE',
                                filter: [],
                                search: []
                            }
                        },
                        {
                            id: 'DEVICE_BY_GROUP',
                            title: 'TEXT_TITLE_GROUP_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: true,
                            open: false,
                            group:[],
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'UNAPPROVED_DEVICE',
                            title: 'COM_TV_SID_UNAPPROVED',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: true
                },
                {
                    id: 'DEVICE_HEALTH', title: 'COM_SID_DEVICE_HEALTH', active: false, children: [
                        {id: 'DEVICE_HEALTH_ERROR', title: 'COM_TEXT_TITLE_ERROR_P', active: false, children: [], hasGroup: false, icon: '', number: ''},
                        {
                            id: 'DEVICE_HEALTH_WARNING',
                            title: 'TEXT_TITLE_WARNING_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'DEVICE_HEALTH_RESOLVED',
                            title: 'MIS_SID_RESOLVED',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'NOTIFICATIONS', title: 'COM_TV_NOTIFICATIONS_KR_VOICE', active: false, children: [
                        {
                            id: 'CONTENT_DOWNLOAD_INCOMPLETE',
                            title: 'MIS_SID_CONTENT_DOWNLOAD_INCOMPLETE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SCHEDULE_NOT_PUBLISHED',
                            title: 'MIS_SID_SCHEDULE_NOT_PUBLISHED',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SCHEDULES_TO_EXPIRE',
                            title: 'MIS_SID_SERVER_SCHEDULES_TO_EXPIRE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'PLAYLIST_TO_EXPIRE',
                            title: 'MIS_SID_SERVER_PLAYLISTS_TO_EXPIRE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'TIMEZONE_NOT_SET',
                            title: 'MIS_SID_TIME_ZONE_NOT_SET',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'INSUFFICIENT_CAPACITY',
                            title: 'MIS_SID_INSUFFICIENT_CAPACITY',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },

                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'SOFTWARE_UPDATE', title: 'COM_SOFTWARE_UPDATE', active: false, children: [
                        {
                            id: 'SOFTWARE_REGISTER_PUBLISH',
                            title: 'MIS_SID_20_REGISTER_AND_PUBLISH',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SOFTWARE_APPLIED_STATUS',
                            title: 'TEXT_TITLE_SW_DEPLOY_HISTORY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'PRESET_MANAGEMENT',
                    title: 'MIS_SID_PRESET_MANAGEMENT',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'CUSTOMIZE', title: 'COM_SID_MAGIC_BRIGHT_CUSTOM_KOR_MSG', active: false, children: [
                        {
                            id: 'CUSTOMIZE_REGISTER_PUBLISH',
                            title: 'MIS_SID_20_REGISTER_AND_PUBLISH',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'CUSTOMIZE_APPLIED_STATUS',
                            title: 'TEXT_TITLE_SW_DEPLOY_HISTORY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                /*{
                    id: 'LEDBOX_WARNING_RULE',
                    title: getMixString(['MIS_SID_MIX_WARNING_RULE', LEDBOX]),
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },*/
                {
                    id: 'I_PLAYER_REMOTE_JOB',
                    title: getMixString(['MIS_SID_MIX_REMOTE_JOB', 'I Player']),
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                }
            ],
            hasSubmenu: true,
            showSubmenu: true,
        },

        RULESET: {
            id: 'RULESET',
            buttons: [
                {
                    key: 'NEW_RULESET',
                    id: 'NEW_RULESET',
                    title: getMixString(['MIS_SID_MIX_NEW', 'MIS_SID_RULESET']),
                    type: 'BUTTON',
                    popup: true,
                    style : {width:'210px'}}
            ],
            nodes: [
                {
                    id: 'ALL_RULESET',
                    title: 'COM_SID_ALL',
                    active: true,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: '',
                    options: {
                        view: 'LIST'
                    }
                },
                {
                    id: 'RULESET_BY_GROUP',
                    title: 'MIS_SID_BY_GROUP',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group: [],
                    icon: '',
                    number: ''
                },
                {
                    id: 'LIBRARY_MANAGER',
                    title: 'MIS_SID_RULE_MANAGEMENT',
                    active: false,
                    children: [
                        {
                            id: 'LIBRARY_MANAGER_CONDITION',
                            title: 'MIS_SID_CONDITION_RULE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'LIBRARY_MANAGER_PLAY',
                            title: 'MIS_SID_PLAY_RULE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        }
                    ],
                    hasGroup: false,
                    icon: '',
                    number: '',
                    open: false
                },
                {
                    id: 'RULESET_RECYCLE_BIN',
                    title: 'TEXT_TITLE_TRASH_VIEW_P',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: 'basket',
                    number: '',
                    useBasket: true
                }
            ],
            hasSubmenu: true,
            showSubmenu: true
        },

        NEW_RULESET: {
            id: 'NEW_RULESET',
            buttons: [],
            nodes: [],
            hasSubmenu: true,
            showSubmenu: true,
            mode: 'CONDITION',
            key: 1
        },

        EDIT_RULESET: {
            id: 'EDIT_RULESET',
            buttons: [],
            nodes: [],
            hasSubmenu: true,
            showSubmenu: true,
            mode: 'CONDITION',
            key: 1
        },

        CONTENT: {
            id: 'CONTENT',
            buttons: [
                {key: 'WEB_AUTHOR', id: 'WEB_AUTHOR', title :  i18n.t('MIS_SID_SERVER_WEB_AUTHOR'), type: 'BUTTON'},
                {key: 'NEW_CONTENT', id: 'NEW_CONTENT', title : 'TEXT_NEW_P', type: 'SELECT', selects: getNewContentMenuItems()},
            ],
            nodes: [
                {
                    id: 'ALL_CONTENT',
                    title: 'COM_TEXT_TITLE_ALL_CONTENTS_P',
                    active: true,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: '',
                    options: {
                        view: 'LIST',
                        filter: [],
                        search: []
                    }
                },
                {
                    id: 'MY_CONTENT',
                    title: 'TEXT_TITLE_MY_CONTENT_VIEW_P',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group:[],
                    icon: '',
                    number: ''
                },
                {
                    id: 'SHARED_CONTENT',
                    title: 'MIS_SID_SHARED_CONTENTS',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group:[],
                    icon: '',
                    number: ''
                },
                {
                    id: 'BY_USER',
                    title: 'TEXT_TITLE_VIEW_BY_USER_P',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group:[],
                    icon: '',
                    number: ''},
                {
                    id: 'UNAPPROVED_CONTENT',
                    title: 'MIS_SID_20_UNAPPROVED_CONTENT',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'CONTENT_TEMPLATE',
                    title: 'MIS_TEXT_TEMPLATE_P',
                    active: false,
                    children: [{
                        id: 'CONTENT_TEMPLATE_BY_FOLDER',
                        title: 'MIS_SID_BY_FLODER',
                        active: false,
                        children: [],
                        hasGroup: true,
                        open: false,
                        group:[],
                        icon: '',
                        number: ''
                    }],
                    hasGroup: false,
                    open: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'CONTENT_RECYCLE_BIN',
                    title: 'TEXT_TITLE_TRASH_VIEW_P',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: 'basket',
                    number: '',
                    useBasket: true
                }
            ],
            hasSubmenu: true,
            showSubmenu: true
        },
        PLAYLIST: {
            id: 'PLAYLIST',
            buttons: [
                {
                    id: 'NEW_PLAYLIST',
                    title : i18n.t('TEXT_PLAYLIST_NEW_P'),
                    type: 'BUTTON',
                    style : {width:'210px'}
                },
            ],
            nodes: [
                {
                    id: 'ALL_PLAYLIST',
                    title: 'TEXT_TITLE_ALL_PLAYLIST_P',
                    active: true,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: '',
                },
                {
                    id: 'MY_PLAYLIST',
                    title: 'TEXT_TITLE_MY_PLAYLIST_VIEW_P',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group:[],
                    icon: '',
                    number: ''
                },
                {
                    id: 'PLAYLIST_BY_USER',
                    title: 'TEXT_TITLE_VIEW_BY_USER_P',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group:[], icon: '',
                    number: ''
                },
                {
                    id: 'PLAYLIST_RECYCLE_BIN',
                    title: 'TEXT_TITLE_TRASH_VIEW_P',
                    active: false,
                    children: [],
                    hasGroup: false,
                    group:[],
                    icon: 'basket',
                    number: '',
                    useBasket: true
                },
            ],
            hasSubmenu: true,
            showSubmenu: true
        },
        SCHEDULE: {
            id: 'SCHEDULE',
            buttons: [
                {key: 'NEW_SCHEDULE', id: 'NEW_SCHEDULE', title : "LAYERTITLE_NEW_SCHEDULE", type: 'SELECT', selects: [
                        {key: 'CREATE_CONTENT_SCHEDULE', title: "TEXT_CONTENT_P", popup: true},
                        {key: 'CREATE_MESSAGE_SCHEDULE', title: "TEXT_MESSAGE_P", popup: true},
                        {key: 'CREATE_EVENT_SCHEDULE', title: "COM_IDS_EVENT_MAMAIN", popup: true}
                    ]
                }
            ],
            nodes: [
                {
                    id: 'CONTENT', title: 'TEXT_TITLE_CONTENT_P', active: false, children: [
                        {
                            id: 'ALL_CONTENT_SCHEDULE',
                            title: 'TEXT_TITLE_ALL_SCHEDULE_VIEW_P',
                            active: true,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'CONTENT_SCHEDULE_BY_GROUP',
                            title: 'TEXT_TITLE_GROUP_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: true,
                            open: false,
                            group:[],
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'CONTENT_SCHEDULE_RECYCLE_BIN',
                            title: 'TEXT_TITLE_TRASH_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: 'basket',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: true
                },
                {
                    id: 'MESSAGE', title: 'TEXT_MESSAGE_P', active: false, children: [
                        {
                            id: 'ALL_MESSAGE_SCHEDULE',
                            title: 'TEXT_TITLE_ALL_SCHEDULE_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'MESSAGE_SCHEDULE_BY_GROUP',
                            title: 'TEXT_TITLE_GROUP_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: true,
                            open: false,
                            group:[],
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'MESSAGE_SCHEDULE_RECYCLE_BIN',
                            title: 'TEXT_TITLE_TRASH_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: 'basket',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'EVENT', title: 'COM_IDS_EVENT_MAMAIN', active: false, children: [
                        {
                            id: 'ALL_EVENT_SCHEDULE',
                            title: 'TEXT_TITLE_ALL_SCHEDULE_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'EVENT_SCHEDULE_BY_GROUP',
                            title: 'TEXT_TITLE_GROUP_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: true,
                            open: false,
                            group:[],
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'EVENT_SCHEDULE_RECYCLE_BIN',
                            title: 'TEXT_TITLE_TRASH_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: 'basket',
                            number: ''
                        },
                        {
                            id: 'EVENT_SCHEDULE_MANAGER',
                            title: 'MIS_TEXT_EVENT_MANAGEMENT_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
            ],
            hasSubmenu: true,
            showSubmenu: true
        },

        DEVICE: {
            id: 'DEVICE',
            buttons: [],
            nodes: [
                {
                    id: 'DEVICE', title: 'TEXT_TITLE_DEVICE_P', active: false, children: [
                        {
                            id: 'ALL_DEVICE',
                            title: 'TEXT_TITLE_ALL_DEVICE_VIEW_P',
                            active: true,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: '',
                            options: {
                                view: 'IMAGE',
                                filter: [],
                                search: []
                            }
                        },
                        {
                            id: 'DEVICE_BY_GROUP',
                            title: 'TEXT_TITLE_GROUP_VIEW_P',
                            active: false,
                            children: [],
                            hasGroup: true,
                            open: false,
                            group:[],
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'UNAPPROVED_DEVICE',
                            title: 'COM_TV_SID_UNAPPROVED',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: true
                },
                {
                    id: 'DEVICE_HEALTH', title: 'COM_SID_DEVICE_HEALTH', active: false, children: [
                        {id: 'DEVICE_HEALTH_ERROR', title: 'COM_TEXT_TITLE_ERROR_P', active: false, children: [], hasGroup: false, icon: '', number: ''},
                        {
                            id: 'DEVICE_HEALTH_WARNING',
                            title: 'TEXT_TITLE_WARNING_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'DEVICE_HEALTH_RESOLVED',
                            title: 'MIS_SID_RESOLVED',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'NOTIFICATIONS', title: 'COM_TV_NOTIFICATIONS_KR_VOICE', active: false, children: [
                        {
                            id: 'CONTENT_DOWNLOAD_INCOMPLETE',
                            title: 'MIS_SID_CONTENT_DOWNLOAD_INCOMPLETE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SCHEDULE_NOT_PUBLISHED',
                            title: 'MIS_SID_SCHEDULE_NOT_PUBLISHED',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SCHEDULES_TO_EXPIRE',
                            title: 'MIS_SID_SERVER_SCHEDULES_TO_EXPIRE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'PLAYLIST_TO_EXPIRE',
                            title: 'MIS_SID_SERVER_PLAYLISTS_TO_EXPIRE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'TIMEZONE_NOT_SET',
                            title: 'MIS_SID_TIME_ZONE_NOT_SET',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'INSUFFICIENT_CAPACITY',
                            title: 'MIS_SID_INSUFFICIENT_CAPACITY',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },

                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'SOFTWARE_UPDATE', title: 'COM_SOFTWARE_UPDATE', active: false, children: [
                        {
                            id: 'SOFTWARE_REGISTER_PUBLISH',
                            title: 'MIS_SID_20_REGISTER_AND_PUBLISH',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SOFTWARE_APPLIED_STATUS',
                            title: 'TEXT_TITLE_SW_DEPLOY_HISTORY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'PRESET_MANAGEMENT',
                    title: 'MIS_SID_PRESET_MANAGEMENT',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'CUSTOMIZE', title: 'COM_SID_MAGIC_BRIGHT_CUSTOM_KOR_MSG', active: false, children: [
                        {
                            id: 'CUSTOMIZE_REGISTER_PUBLISH',
                            title: 'MIS_SID_20_REGISTER_AND_PUBLISH',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'CUSTOMIZE_APPLIED_STATUS',
                            title: 'TEXT_TITLE_SW_DEPLOY_HISTORY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                /*{
                    id: 'LEDBOX_WARNING_RULE',
                    title: getMixString(['MIS_SID_MIX_WARNING_RULE', LEDBOX]),
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                }, */
                {
                    id: 'I_PLAYER_REMOTE_JOB',
                    title: getMixString(['MIS_SID_MIX_REMOTE_JOB', 'I Player']),
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                }
            ],
            hasSubmenu: true,
            showSubmenu: true
        },
        STATISTICS: {
            id: 'STATISTICS',
            buttons: [],
            nodes: [
                {id: 'SUMMARY', title: 'MESSAGE_STATISTICS_LEFT_MENU_SUMMARY_P', active: true, children: [], hasGroup: false, icon: '', number: ''},
                {
                    id: 'DEVICE', title: 'TEXT_DEVICE_P', active: false, children: [
                        {
                            id: 'CONNECTION_STATUS',
                            title: 'COM_MESSAGE_STATISTICS_LEFT_MENU_SUB_CONNECTION_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'CONNECTION_HISTORY',
                            title: 'COM_MIS_MESSAGE_STATISTICS_LEFT_MENU_SUB_CONNECTION_HISTORY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'APPROVED_DEVICE',
                            title: 'MESSAGE_STATISTICS_PAGE_SUB_TITLE_APPROVE_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'CONTENT', title: 'TEXT_CONTENT_P', active: false, children: [
                        {
                            id: 'CONTENT_TYPE',
                            title: 'MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_TYPE_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'PLAY_FREQUENCY',
                            title: 'MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_FREQUENCY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'DETAIL_REPORTS',
                            title: 'MIS_SID_20_DETAIL_REPORTS',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'STORAGE_SPACE_USAGE',
                            title: 'MIS_SID_STORAGE_SPACE_USAGE',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'PROOF_OF_PLAY_FILE_HISTORY',
                            title: 'MIS_SID_PROOF_OF_PLAY_FILE_HISTORY',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'EVENT_TRIGGER', title: 'MIS_SID_20_EVENT_TRIGGER', active: false, children: [
                        {
                            id: 'EVENT_TRIGGER_DETAIL_REPORTS',
                            title: 'MIS_SID_20_DETAIL_REPORTS',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
            ],
            hasSubmenu: true,
            showSubmenu: true
        },
        USER: {
            id: 'USER',
            buttons: [],
            nodes: [
                {
                    id: 'ALL_USER',
                    title: 'TEXT_TITLE_ALL_USER_VIEW_P',
                    active: true,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'USER_BY_GROUP',
                    title: 'TEXT_TITLE_GROUP_VIEW_P',
                    active: false,
                    children: [],
                    hasGroup: true,
                    open: false,
                    group:[],
                    icon: '',
                    number: ''
                },
                {
                    id: 'UNAPPROVED',
                    title: 'COM_TV_SID_UNAPPROVED',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'WITHDRAWN_USERS',
                    title: 'TEXT_TITLE_WITHDRAWAL_P',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'ROLE',
                    title: 'COM_TEXT_ROLE_P',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'ORGANIZATION_GROUP',
                    title: 'MIS_SID_ORGANIZATION_GROUP',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'SYNC_LDAP_ORGANIZATION',
                    title: 'MIS_SID_SYNC_LDAP_ORGANIZATION',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                }
            ],
            hasSubmenu: true,
            showSubmenu: true
        },
        SETTING: {
            id: 'SETTING',
            buttons: [],
            nodes: [
                {
                    id: 'MY_ACCOUNT', title: 'COM_TV_SID_MY_ACCOUNT', active: false, children: [
                        {
                            id: 'MY_INFORMATION',
                            title: 'MIS_SID_20_MY_INFORMATION',
                            active: true,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'WITHDRAW_MEMBERSHIP',
                            title: 'TEXT_TITLE_USER_WITHDRAWAL_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: true
                },
                {
                    id: 'SERVER_MANAGEMENT', title: 'MIS_BUTTON_SERVER_MANAGEMENT_P', active: false, children: [
                        {
                            id: 'SERVER_SETTINGS',
                            title: 'COM_TEXT_TITLE_SERVER_SETUP_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'DEVICE_SUMMARY',
                            title: 'TEXT_DEVICE_SUMMARY_P_KR_MIS20',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SYSTEM_INFO',
                            title: 'TEXT_SYSTEM_INFO_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'SERVICE_HISTORY',
                            title: 'TEXT_TITLE_SERVICE_HISTORY_P',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        /*{
                            id: 'DOWNLOAD_QUEUE_STATUS',
                            title: 'Content Download Queue',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },*/
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'EXTERNAL_SERVER_MANAGEMENT', title: 'MIS_SID_20_EXTERNAL_SERVER_MANAGEMENT', active: false, children: [
                        {
                            id: 'DATALINK_SERVER',
                            title: 'MIS_SID_20_DATALINK_SERVER',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'EDGE_SERVER',
                            title: 'MIS_SID_EDGE_SERVER',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                        {
                            id: 'REMOTE_CONTROL_SERVER',
                            title: 'Remote Control Server',
                            active: false,
                            children: [],
                            hasGroup: false,
                            icon: '',
                            number: ''
                        },
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'LICENSE_INFO',
                    title: 'MIS_SID_PROSERVICE_CAJUL_LICENSE_MANAGEMENT',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'E2E_LICENSE_INFO',
                    title: 'E2E License Info',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'TAG_MANAGEMENT', title: 'MIS_TEXT_TAG_MANAGEMENT_P', active: false, children: [
                        {id: 'ALL_TAG', title: 'TEXT_ALL_P', active: false, children: [], hasGroup: false, icon: '', number: ''},
                        {id: 'TAG_BY_GROUP', title: 'TEXT_GROUP_P', active: false, children: [], hasGroup: true, group:[], icon: '', number: ''},
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'INSIGHT_INDEX_MANAGEMENT', title: 'MIS_SID_CBFEB_INSIGHT_INDEX_MANAGEMENT', active: false, children: [
                        {id: 'ALL_INSIGHT_INDEX', title: 'TEXT_ALL_P', active: false, children: [], hasGroup: false, icon: '', number: ''},
                        {id: 'INSIGHT_INDEX_BY_GROUP', title: 'TEXT_GROUP_P', active: false, children: [], hasGroup: true, group:[], icon: '', number: ''},
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'CATEGORY_MANAGEMENT',
                    title: 'MIS_SID_CATEGORY_MANAGEMENT',
                    active: false,
                    children: [],
                    hasGroup: false,
                    icon: '',
                    number: ''
                },
                {
                    id: 'LOG_MANAGEMENT', title: 'TEXT_LOG_MANAGEMENT_P', active: false, children: [
                        {id: 'LOG', title: 'TEXT_TITLE_LOG_P', active: false, children: [], hasGroup: false, icon: '', number: ''},
                        {id: 'ALARM_MAIL_HISTORY',title: 'MIS_SID_ALARM_MAIL_HISTORY',active: false,hasGroup: false,icon: '',number: ''},
                    ], hasGroup: false, icon: '', number: '', open: false
                },
                {
                    id: 'EXTERNAL_LINK', title: 'MIS_TEXT_TITLE_EXTERNAL_LINK_P', active: false, children: [
                    ], hasGroup: false, icon: '', number: '', open: false
                }
            ],
            hasSubmenu: true,
            showSubmenu: true
        }
    }
})

export const menuConstants = {


    INIT_MENUS: 'INIT_MENUS',
    UPDATE_SUBMENU_COUNTER: 'UPDATE_SUBMENU_COUNTER',
    UPDATE_RESOURCE_COUNTER: 'UPDATE_RESOURCE_COUNTER',
    RELOAD_GROUP: 'RELOAD_GROUP',
    DESTROY_RELOAD_GROUP: 'DESTROY_RELOAD_GROUP',
    SELECT_GROUP: 'SELECT_GROUP',

    ADD_TAB: 'ADD_TAB',
    ACTIVE_TAB: 'ACTIVE_TAB',
    REMOVE_TAB: 'REMOVE_TAB',
    ACTIVE_GROUP_NODE : 'ACTIVE_GROUP_NODE',
    ACTIVE_CHILD_GROUP_NODE: 'ACTIVE_CHILD_GROUP_NODE',
    LOAD_GROUP_CONTENT: 'LOAD_GROUP_CONTENT',
    LOAD_CONTENT_SCHEDULE: 'LOAD_CONTENT_SCHEDULE',
    UPDATE_TAB: 'UPDATE_TAB',
    SHOW_SUBMENU: 'SHOW_SUBMENU',

    LOAD_CONTENT: 'LOAD_CONTENT',
    UPDATE_SUBMENU: 'UPDATE_SUBMENU',
    LOAD_SIMPLE_SCHEDULE: 'LOAD_SIMPLE_SCHEDULE',
    UPDATE_SUBMENU_WIDTH: 'UPDATE_SUBMENU_WIDTH',

    FORCE_RELOAD_CONTENT: 'FORCE_RELOAD_CONTENT',

    LED_CABINET: 'LED_CABINET',
    REQUEST_CONTENT : 'REQUEST_CONTENT',
    REQUEST_GROUP: 'REQUEST_GROUP',
    RECEIVE_GROUP: 'RECEIVE_GROUP',

    GROUP_CONTENT_LOAD_KEY: 'GROUP_CONTENT_LOAD_KEY'
};

/*menuConstants.LED_CABINET.nodes = menuConstants.DEVICE.nodes;*/

export const getAllItemsLinkIdFromMenu = (menu) => {
    let allItemsLinkId;
    switch(menu) {
        case 'CONTENT':
            allItemsLinkId = 'ALL_CONTENT';
            break;
        case 'PLAYLIST':
            allItemsLinkId = 'ALL_PLAYLIST';
            break;
        case 'RULESET':
            allItemsLinkId = 'ALL_RULESET';
            break;
        case 'CONTENT_SCHEDULE':
            allItemsLinkId = 'ALL_CONTENT_SCHEDULE';
            break;
        case 'MESSAGE_SCHEDULE':
            allItemsLinkId = 'ALL_MESSAGE_SCHEDULE';
            break;
        case 'EVENT_SCHEDULE':
            allItemsLinkId = 'ALL_EVENT_SCHEDULE';
            break;
        case 'DEVICE':
            allItemsLinkId = 'ALL_DEVICE';
            break;
        case 'USER':
            allItemsLinkId = 'ALL_USER';
            break;
        default:
            allItemsLinkId = '';
    }
    return allItemsLinkId;
}
