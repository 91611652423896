import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {scheduleAction} from "../../../actions/";
import {toastr} from 'helper/toastrIntercept';

const SubmenuEventConditionContainer = ({type}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {eventCondition} = useSelector(state => ({
        eventCondition: state.schedules.events.condition[type]
    }));

    const addEventCondition = mode => {
        if(eventCondition.eventConditions.length >= 10){
            toastr.error(t("MIS_TEXT_ITEM_MAXIMUM_P").replace("%d", "10"));
            return;
        }
        if (eventCondition.eventType === 'BOOL') {
            dispatch(scheduleAction.addEventCondition(mode));
        }
        dispatch(scheduleAction.addEventCondition(mode));
    }

    return (
        <div className={"submenu_message_container"} style={{overflowY: 'auto'}}>
            <div className="lnb_buttonwrap" style={{height:38,position:'relative'}} >
                {
                    eventCondition.eventType === 'BOOL' && eventCondition.eventConditions !== undefined && eventCondition.eventConditions.length === 2 ?
                        '' : <button className="base noline float_l" style={{width: 206}} id="messageAddBtn" onClick={()=>addEventCondition(type)} ><span style={{display: 'inline-block',marginRight: '10px'}}>+</span>{t("MIS_TEXT_EVENT_ADD_CONDITION_P")}</button>

                }
            </div>
        </div>
    )
}
export default SubmenuEventConditionContainer;