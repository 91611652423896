import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import SearchBar from "../../../components/search/SearchBar";
import Checkbox from "../../../components/checkbox/Checkbox";
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import {commonConstants, SCHEDULE_FILTERS, SCHEDULE_PAGE_SIZE_OPTIONS} from "../../../constants";
import {useTranslation} from 'react-i18next';
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter, usePrevious, useResizeWindow} from '../../../helper';
import snakeCase from "lodash/snakeCase";
import DateToStr from "../../../components/utils/DateToStr";
import EventCondition from "../../../components/popup/schedule/event/EventCondition";
import {toastr} from 'helper/toastrIntercept';
import {DeviceGroups, renderNodata} from "../../../helper/schedule/scheduleUtils";
import {useMISOpt} from "../../../components/misopt";
import Moment from "moment";
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {getErrorMessage} from "../../../helper/responseHandler";
import {useTrGroupProps} from "../../../helper/tables";
import {updateCache} from "../../../helper/cache/tableCache";
import { closeDetailPopup } from '../../../helper';


const AllEventSchedule = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('SCHEDULE'));

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [conditionPopup, setConditionPopup] = useState(false);

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [showNoData, setShowNoData] = useState(props.cache.isLoaded && props.cache.totalSize === 0 ? true : false);

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], groupId, searchText, devGroupIds, startModifiedDate, endModifiedDate} = filter;
        setData({...data, loading: true});
        scheduleService.fetchEventScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            groupType: 'ALL',
            groupId: groupId,
            searchText: searchText,
            sortColumn: snakeCase(id).toUpperCase(),
            devGroupIds: devGroupIds,
            startModifiedDate: startModifiedDate,
            endModifiedDate: endModifiedDate,
            sortOrder: desc === true ? 'desc' : 'asc'
        }).then(res => {
            if(res.totalCount === 0) {
                setShowNoData(true);
            }
            else {
                setShowNoData(false);
            }
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SCHEDULE', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        });
    }

    const openPopup = programId => {
        const popup = {type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'SCHEDULE_EVENT', id: programId};
        dispatch(popupAction.openDetailView(popup));
    }


    const editProgram = () => {
        const programIds = getCheckedId();
        if (programIds.length === 1) {
            scheduleService.fetchEventScheduleById(programIds[0]).then(
                res=> {
                    const tab = {id: 'EDIT_EVENT', title: t("MIS_SID_20_EDIT_EVENT"), active: true, close: true};
                    new Promise(()=> {
                        const event = res.items.data;
                        const {selectedEventList} = event;
                        selectedEventList.map(
                            selectedEvent => {
                                const {duration} = selectedEvent;
                                const durationHour = Math.floor(duration / 3600);
                                const durationMin = Math.floor((duration - (durationHour * 3600)) / 60);
                                selectedEvent.durationMin = durationMin;
                                selectedEvent.durationHour = durationHour;
                            }
                        )
                        dispatch(scheduleAction.initEventSchedule('edit',event));
                    }).then(
                        dispatch(menuAction.addTab(tab))
                    ).then(
                        dispatch(menuAction.loadContent('EDIT_EVENT'))
                    )
                }
            ).catch(e => toastr.error(getErrorMessage(e)))
        }

    }

    const handleExport = (docType) => {
        const copiedFilter = JSON.parse(JSON.stringify(filter));
        copiedFilter.pageSize = data.totalCount;
        scheduleService.exportEventSchedule(copiedFilter, docType ).then(
            res=> {}
        )
    };

    const getCheckedId = () => {
        return selected.current.map(s => items[s].programId);
    }

    const onChangeScheduleName = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const onClickDetailSearch = (searchDetail) => {
        const groupIds = [];
        if (searchDetail.deviceGroups !== undefined && searchDetail.deviceGroups.length > 0) {
            searchDetail.deviceGroups.map(group=>groupIds.push(group.groupId));
        }
        setFilter({
            ...filter,
            page: 0,
            devGroupIds: groupIds,
            startModifiedDate: searchDetail.date.startDate !== undefined && searchDetail.date.startDate !== '' ? Moment(searchDetail.date.startDate).format('YYYY-MM-DD') : undefined,
            endModifiedDate: searchDetail.date.endDate !== undefined && searchDetail.date.endDate !== '' ? Moment(searchDetail.date.endDate).format('YYYY-MM-DD') : undefined
        });
    };

    const columns = useMemo(()=> [
        {
            accessor: "programId",
            show: false
        },
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllEventSchedule_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].programId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },
        {
            Header: t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME"),
            accessor: "scheduleName",
            Cell: (props) => <span className={"data_name"} onClick={()=>openPopup(props.original.programId)}>{props.original.scheduleName}</span>,
            width: 600
        },
        {
            Header: t("TEXT_DEVICE_GROUP_P"),
            Cell: (props) => props.original.deviceGroups !== null && props.original.deviceGroups !== undefined && props.original.deviceGroups.length > 0 ? <DeviceGroups deviceGroups={props.original.deviceGroups} width={150}/> : '',
            sortable: false,
            width: 150
        },
        {
            Header: t("MIS_TEXT_TAG_MAP_DEVICE_COUNT_P"),
            accessor: "deviceCount",
            sortable: false,
            width: 100
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            width: 200,
            Cell: ({value}) => <DateToStr date={value} newLine={false}/>

        },
        {
            Header: t("TEXT_LASTDEPLOY_DATE_P"),
            accessor: "lastdeployDate",
            Cell: ({value}) => <DateToStr date={value} newLine={false}/>,
            minWidth: 200
        }
    ], [items]);

    const showConditionPopup = () => {
        setConditionPopup(!conditionPopup);
    }

    const showStopPopup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_STOP_P"),
            message: t("MIS_MESSAGE_EVENT_STOP_EVENT_SCHEDULE_P"),
            onClickYes: sendStop,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }
    const sendStop = () => {
        const programIds = getCheckedId();
        if (programIds.length > 0) {
            scheduleService.sendEventScheduleCondition(programIds[0],programIds, '', true)
                .then(
                    res=> {
                        if (res) {
                            toastr.success(t("TEXT_SUCCESS_P"))
                        }
                    }
                )
                .catch(res=> toastr.error(getErrorMessage(res)))
                .finally(()=>{
                    dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                    setFilter({...filter, force: !filter.force});
                })
        }

    }

    const moveGroup = () => {
        const programId = getCheckedId();
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: commonConstants.COMMON_GROUP_POPUP,
            mode: 'event_schedule',
            save: (groups)=>moveProgramGroup(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }));
    }

    const moveProgramGroup = (groups) => {
        if (groups !==undefined && groups.length > 0) {
            const {groupId, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                const programId = getCheckedId();
                scheduleService.moveEventSchedule(programId, groupId).then(
                    res => {
                        toastr.success(t("BUTTON_EDIT_GROUP_P"));
                        dispatch(menuAction.reloadGroup('EVENT_SCHEDULE_BY_GROUP'));
                    }
                ).catch(
                    err => {
                        toastr.error(t("MIS_MESSAGE_COMMON_MOVE_INCORRECT_P"));
                        toastr.error(getErrorMessage(err));
                    }
                ).finally(()=>{
                    dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
                    setFilter({...filter, force: !filter.force});
                })
            }

        }
    }

    const showDeletePopup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t("MESSAGE_SCHEDULE_DELETE_QUE_P"),
            onClickYes: deleteEventSchedule,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const closePopup = (id) => {
        dispatch(popupAction.closePopup(id));
    }

    const deleteEventSchedule = () => {
        const programIds = getCheckedId();
        scheduleService.deleteEventScheduleByIds(programIds, true)
            .then(
                res=> {
                    if (res) {
                        toastr.success(t("TEXT_SUCCESS_P"))
                        closeDetailPopup(programIds, closePopup);
                        dispatch(menuAction.reloadGroup('EVENT_SCHEDULE_BY_GROUP'));
                    }
                }
            )
            .catch(res=> {
                toastr.error(t("MIS_MESSAGE_EVENT_DELETE_USED_EVENT_P"));
                //toastr.error(res)
            })
            .finally(()=>{
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
                setFilter({...filter, force: !filter.force});
            })
    }

    useResizeWindow(() => {
        setStyle({height: window.innerHeight - 205});
    });

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []);    

    useEffect(() => {
        fetchData();
    }, [filter]);

    const preGroupId = usePrevious(props.groupId);
    const preCurrContent = usePrevious(props.currContent);

    useEffect(()=> {
        if (
            (preCurrContent !== undefined && preCurrContent !== props.currContent) ||
            (preGroupId !== undefined && preGroupId !== props.groupId)
        ) {
            const pageSize =  getPageSize(props.divide);
            setFilter({...SCHEDULE_FILTERS['ALL_EVENT_SCHEDULE'], groupId : props.groupId, pageSize});
        }
    }, [props.groupId])

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);
    return (
        <div style={{width: '100%',display:(props.currContent === 'ALL_EVENT_SCHEDULE' || props.currContent === 'EVENT_SCHEDULE_BY_GROUP') ? 'block':'none'}}>

            {
                conditionPopup &&
                    <EventCondition programId={getCheckedId()} closePopup={showConditionPopup}/>
            }
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"EVENT_SCHEDULE_EDIT"} name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} onClick={editProgram} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_DELETE"} disable={selectedCnt < 1} name={t("COM_BUTTON_DELETE")} onClick={showDeletePopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_MOVE"} disable={selectedCnt < 1} name={t("BUTTON_MOVE_P")} onClick={moveGroup} authority={authority.CREATE || authority.MANAGE}/>

                    <WhiteButton id={"EVENT_SCHEDULE_SEND_CONDITION"} disable={selectedCnt !== 1} name={t("MIS_BUTTON_EVENT_SEND_CONDITION_P")} onClick={showConditionPopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_STOP"} disable={selectedCnt === 0} name={t("COM_BUTTON_STOP_P")} onClick={showStopPopup} authority={authority.CREATE || authority.MANAGE}/>
                    
                    <WhiteButton id={"CONTENT_SCHEDULE_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={()=>handleExport('EXCEL')} />
                </div>
                <div className="rightButton">
                    <SearchBar enableDetail id="eventDetailSchedule" placeholder={t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME")} onClickSearch={onChangeScheduleName} width={210}
                               onClickDetailSearch={onClickDetailSearch}
                               searchDetails={[
                                   {title: 'deviceGroup', type: 'popup'},
                                   {title: 'date', type: 'date'}
                               ]}
                               keyword={filter.searchText}/>
                </div>
            </div>
            {
                showNoData &&
                renderNodata()
            }
            {
                !showNoData &&
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    onSortedChange={onSortedChange}
                    columns={columns}
                    style={style}
                    getTrGroupProps={getTrGroupPropsType2}
                    usePagination={true}
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        defaultPageSize: pageSize,
                        pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: props.divide
                    }}
                />
            }
        </div>
    )

}
export default AllEventSchedule;