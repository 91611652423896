import CIFS from './CIFS';
import DLK from './DLK';
import FLASH from './FLASH';
import FTP from './FTP';
import HTML from './HTML';
import IMAGE from './IMAGE';
import LFD from './LFD';
import LFT from './LFT';
import MOVIE from './MOVIE';
import OFFICE from './OFFICE';
import PDF from './PDF';
import SOUND from './SOUND';
import Streaming from './Streaming';
import URL from './URL';
import VWL from './VWL';
import SAPP from './SAPP';
import ADS from './ADS';
import './ContentIcon.css'



const ContentIcon = ({type, onlyIcon = false, embedded = false}) => {
    switch (type) {
        case 'CIFS':
            return CIFS({onlyIcon, embedded}) ;
        case 'DLK' :
            return DLK({onlyIcon, embedded});
        case 'FLASH' :
            return FLASH({onlyIcon, embedded});
        case 'FTP' :
            return FTP({onlyIcon, embedded});
        case 'HTML' :
            return HTML({onlyIcon, embedded});
        case 'IMAGE' :
            return IMAGE({onlyIcon, embedded});
        case 'LFD' :
            return LFD({onlyIcon, embedded});
        case 'LFT':
            return LFT({onlyIcon, embedded});
        case 'MOVIE':
            return MOVIE({onlyIcon, embedded});
        case 'OFFICE':
            return OFFICE({onlyIcon, embedded});
        case 'PDF':
            return PDF({onlyIcon, embedded});
        case 'SOUND':
            return SOUND({onlyIcon, embedded});
        case 'STRM':
            return Streaming({onlyIcon, embedded});
        case 'URL':
            return URL({onlyIcon, embedded});
        case 'VWL':
            return VWL({onlyIcon, embedded});
        case 'SAPP':
            return SAPP({onlyIcon, embedded});
        case 'ADS':
            return ADS({onlyIcon, embedded});
    }
}

export default ContentIcon;