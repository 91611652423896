import React from 'react';
import {withTranslation} from "react-i18next";
import MISDialog from "../MISDialog";
import './UserPopup.css';
import classNames from "classnames";

const ChangeOrganizationCopyPopup = ({t, onClickCopy, onClose}) => {

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_TITLE_CHANGE_USER_ORGAN_P'),
                closeOnEscape: true,
                modal: true,
                width: 400,
                height: 160,
                position: {x: -300, y: -400},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('COM_IDS_EDIT_COPY'),
                        onClick: () => {onClickCopy(true); onClose();},
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_DONT_COPY_P'),
                        onClick: () => {onClickCopy(false); onClose();},
                    }
                ]
            }}>

            <div className={classNames('mt68', 'mb50')}>{t('MESSAGE_USER_COPY_CONTENT_NEW_ORGAN_P')}</div>
        </MISDialog>
    );
};

export default withTranslation()(ChangeOrganizationCopyPopup);