import React from 'react';
import './StatisticsCalendar.css';
import * as PropTypes from 'prop-types';
import DateInput from "../../components/input/DateInput";

export function StatisticsCalendar(props) {
    return (
        <div className="statistics_calendar">
            <div className="date_picker">
                <DateInput
                    className='statistics_calendar date_input'
                    width={120}
                    dateFormat={props.dateFormat ? props.dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd') : "yyyy-MM-dd"}
                    date={props.date.time === '' ? '' : props.date.startDate}
                    disabled={props.disabled}
                    onChange={props.onChangeStart}/>
            </div>
            <span className="statistics_calendar_dash">&nbsp;~&nbsp;</span>
            <div className="date_picker">
                <DateInput
                    className='statistics_calendar date_input'
                    width={120}
                    dateFormat={props.dateFormat ? props.dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd') : "yyyy-MM-dd"}
                    date={props.date.time === '' ? '' : props.date.endDate}
                    disabled={props.disabled}
                    onChange={props.onChangeEnd}/>
            </div>
        </div>
    );
}

StatisticsCalendar.propTypes = {
    date: PropTypes.shape({time: PropTypes.any, startDate: PropTypes.any, endDate: PropTypes.any}),
    dateFormat: PropTypes.string,
    onChangeStart: PropTypes.func,
    onChangeEnd: PropTypes.func,
};
