import React, {useState} from 'react'
import {Trans, useTranslation} from "react-i18next";
import {isNil, toUpper} from 'lodash';
import {settingService} from '../../services';
import WhiteButton from "../../components/button/WhiteButton";
import Select from "../../components/selectbox/Select";
import Checkbox from "../../components/checkbox/Checkbox";
import TextInput from "../../components/input/TextInput";
import NumberInput from "../../components/input/NumberInput";
import SettingInformation from "../../components/setting/SettingInformation";
import RadioGroup from "../../components/radio/RadioGroup"
import TimeInput from "../../components/input/TimeInput";
import {SearchButton} from "../../components/button/SearchButton";
import {getMixString} from "../../language/languageUtils";
import {commonOptions, deviceOptions} from '../../components/misopt';
import {connect} from "react-redux";
import {popupAction} from "../../actions";
import {commonConstants, settingConstants, userConstants, SMTP_PASSWORD_LENGTH} from "../../constants";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";
import './ServerSettings.css';

import {
    alarmMailingWeekly,
    onChangeCheckBox,
    onChangeNumberInput,
    onChangeRadioGroup,
    onChangeSelect,
    onChangeTextInput,
    onChangeTimeInput,
    openPrivacyPolicySettingPopup,
    saveNotificationsSetting
} from './ServerSettings';

const ServerSettingsAdmin = ({settings, onChange, organInfo, myInfo, ...props}) => {
    const {t} = useTranslation();
    const data = {...settings.data};
    const changed = {...settings.changed};
    const { addPopup, closePopup } = props;

    const [loginPageImageFileName, setLoginPageImageFileName] = useState(data.loginPage === undefined ? '' : data.loginPage.imgFileName);

    const generalSettings = (items) => {
        const type = 'general';
        return (
            [
                {id: 'SERVER_SETTING_CONTENT_APPROVAL', name: <Trans i18nKey={"MIS_SID_CONTENT_APPROVAL"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useContentApproval),
                value: <Checkbox id={'useContentApproval'} propertyName={'useContentApproval'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useContentApproval}/>},
                {id: 'SERVER_SETTING_SHOW_NOTIFICATION_NUMBER', name: <Trans i18nKey={"MIS_SID_20_SHOW_NOTIFICATION_NUMBER"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useNotificationCount),
                value: <Checkbox id={'useNotificationCount'} propertyName={'useNotificationCount'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useNotificationCount}/>},
                {id: 'SERVER_SETTING_BACKUP_PLAY', name: <Trans i18nKey={"MIS_SID_20_BACKUP_PLAY"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useBackupPlay),
                value: <Checkbox id={'useBackupPlay'} propertyName={'useBackupPlay'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useBackupPlay}/>},
                {id: 'SERVER_SETTING_DEVICE_POWER_CONTROL', name: <Trans i18nKey={"MIS_SID_DEVICE_POWER_CONTROL"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDevicePowerControl),
                value: <Checkbox id={'useDevicePowerControl'} propertyName={'useDevicePowerControl'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDevicePowerControl}/>},
                {id: 'SERVER_SETTING_NUMBER_OF_DEVICE', name: <Trans i18nKey={"MIS_SID_NUM_OF_DEVICE_GROUP"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDeviceGroupCount),
                value: <Checkbox id={'useDeviceGroupCount'} propertyName={'useDeviceGroupCount'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDeviceGroupCount}/>},
                {id: 'SERVER_SETTING_DEVICE_GROUP_PERMISSION', name: <Trans i18nKey={"MIS_TEXT_DEVICE_GROUP_PERMISSION_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDeviceGroupPermission),
                value: <Checkbox id={'useDeviceGroupPermission'} propertyName={'useDeviceGroupPermission'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDeviceGroupPermission}/>},
                {id: 'SERVER_SETTING_SIGN_UP', name: <Trans i18nKey={"BUTTON_SIGN_UP_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useSignUp),
                value: <Checkbox id={'useSignUp'} propertyName={'useSignUp'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSignUp}/>},
                {id: 'SERVER_SETTING_NUMBER_OF_SIGN_IN_FAIL', name: <Trans i18nKey={"MIS_SID_NUMBER_OF_SIGN_IN_FAILURES"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].signinFailureCount),
                value: <NumberInput propertyName={'signinFailureCount'} min={1} max={10} rangeLabel={true} value={items.signinFailureCount} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/>},
                {id: 'SERVER_SETTING_SIGN_IN_BLOCK_DURATION', name: <Trans i18nKey={"MIS_SID_SIGN_IN_BLOCK_DURATION"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].signinBlockDuration),
                value: <><NumberInput propertyName={'signinBlockDuration'} min={1} rangeLabel={true} value={items.signinBlockDuration} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> <Trans i18nKey={"COM_SID_MIN"} /></>},
               {id: 'SERVER_SETTING_STAY_SIGNED_IN', name: <Trans i18nKey={"MIS_SID_PREMIUM_STAY_SIGNED_IN"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].tokenLifetime),
                value: <><NumberInput propertyName={'tokenLifetime'} min={30} max={480} rangeLabel={false} value={!isNil(items.tokenLifetime)?items.tokenLifetime:30} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> (30 - 480, 8 <Trans i18nKey={"COM_SID_HOURS"} />) <Trans i18nKey={"COM_SID_MIN"} /></>},
                {id: 'SERVER_SETTING_INSUFFICIENT_CAPACITY', name: <Trans i18nKey={"MIS_SID_INSUFFICIENT_CAPACITY"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].insufficientCapacity),
                value: <><NumberInput propertyName={'insufficientCapacity'} min={10} max={5000} rangeLabel={true} value={!isNil(items.insufficientCapacity)?items.insufficientCapacity:10} 
                        onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> MB</>},
            ]
        )
    }

    const passwordPolicySettings = (items) => {
        const type = 'passwordPolicy';

        const optionPasswordChangePeriod = [
            {"value": "1", "title": getMixString(["MIS_SID_MIX_MONTHS", "1"])},
            {"value": "3", "title": getMixString(["MIS_SID_MIX_MONTHS", "3"])},
            {"value": "6", "title": getMixString(["MIS_SID_MIX_MONTHS", "6"])},
            {"value": "custom", "title": t("COM_SID_CUSTOM_KR_MIS")}
        ];

        const passwordChangePeriodView = () => {
            let itemsValue = items.passwordChangePeriod+"";
            let customValue = "";
            let radioValue = itemsValue;
            if(itemsValue != "0" && itemsValue != "1" && itemsValue != "3" && itemsValue != "6"){
                radioValue = "custom";
                if(radioValue == "custom"){
                    if(isNil(items.customPasswordChangePeriod)){
                        customValue = items.passwordChangePeriod=="custom"?"":items.passwordChangePeriod;
                    } else {
                        customValue = items.customPasswordChangePeriod;
                    }
                }else{
                    customValue = items.passwordChangePeriod;
                }
                
            }

            const handlePasswordChangePeriodCheck = (e) => {
                let enable = e.target.checked;
                if(!enable){
                    onChange(type, "passwordChangePeriod", "0");
                }else{
                    onChange(type, "passwordChangePeriod", "1");
                }
            }

            const handlePasswordChangePeriod = (e, value) => {
                onChange(type, "passwordChangePeriod", value);
            }

            const handleCustomPasswordChangePeriod = (e, value) => {
                onChange(type, "customPasswordChangePeriod", value);
            }

            return (
                <>
                    <div className="float_l mr20"> 
                        <Checkbox id={'usePasswordChangePeriod'} propertyName={'usePasswordChangePeriod'} name={t("TEXT_ENABLE2_P")} onChange={handlePasswordChangePeriodCheck} checked={itemsValue!="0"}/>
                    </div>
                    <div className="float_l">
                        <RadioGroup propertyName={'passwordChangePeriod'} selects={optionPasswordChangePeriod} value={radioValue} onChange={handlePasswordChangePeriod} disabled={itemsValue=="0"}/>
                    </div>
                    <div className="float_l">
                        <NumberInput propertyName={'customPasswordChangePeriod'} min={1} rangeLabel={true} value={!isNil(customValue)&&customValue!=""?parseInt(customValue):""} 
                        onChange={handleCustomPasswordChangePeriod} disabled={(itemsValue == "0" || itemsValue == "1" || itemsValue == "3" || itemsValue == "6")}
                        error={radioValue=="custom" && (isNil(customValue)||customValue=="")}/>
                    </div>
                </>
            )
        }

        const periodLevel = [
            {value: 'unlimited', title: 'MIS_SID_CAJUL_UNLIMITED_PERIOD'},
            {value: 'period', title: 'TEXT_PERIOD_P'}
        ];

        const getMfaOptionView = () => {

            const authDevice = items.mfaList ? items.mfaList.find((item) => {return item.mfaType === settingConstants.MFA_STORED_DEVICE}) : {};

            items.isRememberDevices = isNil(items.isRememberDevices)  ? authDevice.authEnable : items.isRememberDevices;
            items.rememberDevicesPeriodType = isNil(items.rememberDevicesPeriodType) ? ((!isNil(authDevice.usePeriod) && authDevice.usePeriod === false) ?  'unlimited' :'period' ) : items.rememberDevicesPeriodType;
            items.rememberDevicesPeriod = isNil(items.rememberDevicesPeriod)? authDevice.period : items.rememberDevicesPeriod;

            return (
                <div>
                    <div>
                        <Checkbox id={'useMFA'} propertyName={'useMFA'} name={t("TEXT_ENABLE2_P")}
                                  onChange={(e) => onChangeCheckBox(e, type, onChange)} checked={items.useMFA}/>
                        <WhiteButton id={'deleteMFAInfo'} name={t("MIS_SID_CAJUL_DEL_INFO")}
                                     style={{marginLeft: '50px'}}
                                     onClick={() => deleteMFAInfoPopup()}
                                     disable={isNil(items.useMFA) || items.useMFA}
                        />
                    </div>
                    <div className='inline_block'>
                            <span className={"otp_info"}>
                                <Trans i18nKey={"MIS_SID_CAJUL_USES_TWO_STEP_VERIFI_VIA_OTP_AT_LOGIN"}/>
                            </span>
                    </div>


                    <div style={{display: !isNil(items.useMFA) && items.useMFA ? 'block' : 'none'}}>

                        <div>
                            <div className="float_l mr38">
                                <Checkbox id={'isRememberDevices'} propertyName={'isRememberDevices'}
                                          name={t("MIS_SID_CASEP_TRUSTED_DEVICES")}
                                          onChange={(e) => onChangeCheckBox(e, type, onChange)}
                                          checked={items.isRememberDevices}/>
                            </div>
                            <div className="float_l">
                                <RadioGroup propertyName={'rememberDevicesPeriodType'} selects={periodLevel}
                                            value={items.rememberDevicesPeriodType}
                                            onChange={(e, value) => onChangeRadioGroup(e, value, type, onChange)}
                                            disabled={!items.isRememberDevices}/>
                            </div>
                            <div>
                                <NumberInput propertyName={'rememberDevicesPeriod'} min={1} rangeLabel={true}
                                             value={items.rememberDevicesPeriod}
                                             disabled={!items.isRememberDevices || items.rememberDevicesPeriodType === 'unlimited'}
                                             onChange={(e, value) => onChangeNumberInput(e, value, type, onChange)}/>

                                <Trans i18nKey={"LIST_TITLE_DAY"}/>
                            </div>
                        </div>
                        <div className='inline_block'>
                            <span className={"otp_info"}>
                                <Trans i18nKey={"MIS_SID_CASEP_TRUSTED_DEVICES_SKIP_STEP"}/>
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            [
                {id: 'SERVER_SETTING_PASSWORD_EXPIRATION_PERIOD', name: <Trans i18nKey={"MIS_SID_PW_EXPIRATION_PERIOD"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].passwordChangePeriod),
                value: passwordChangePeriodView() },
                {id: 'SERVER_SETTING_PREVENT_PASSWORD_REUSE', name: <Trans i18nKey={"MIS_SID_PREVENT_PW_REUSE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].passwordReusePrevent),
                value: <Checkbox id={'passwordReusePrevent'} propertyName={'passwordReusePrevent'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.passwordReusePrevent}/> },
                {id: 'SERVER_SETTING_PASSWORD_CHANGE_AFTER_LOGIN', name: <Trans i18nKey={"MIS_SID_PW_CHANGE_AFTER_LOGIN"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].passwordChangeAfterLogin),
                value: <Checkbox id={'passwordChangeAfterLogin'} propertyName={'passwordChangeAfterLogin'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.passwordChangeAfterLogin}/> },
                {
                    id: 'SERVER_SETTING_TWO_STEP_VERIFI_OTP',
                    name: <Trans i18nKey={"MIS_SID_CAJUL_TWO_STEP_VERIFI_OTP"}/>,
                    changed: !isNil(changed[type]) && (!isNil(changed[type].useMFA) || !isNil(changed[type].isRememberDevices) || !isNil(changed[type].rememberDevicesPeriodType)|| !isNil(changed[type].rememberDevicesPeriod) ),
                    value: getMfaOptionView()
                },
            ]
        )
    }

    const logManagementSettings = (items) => {
        const type = 'logManagement';

        const logLevel = [
            {"value": "ERROR", "title": "ERROR"},
            {"value": "WARN", "title": "WARN"},
            {"value": "INFO", "title": "INFO"}
        ];

        const logFileSize = () => {
            return (
                <><NumberInput propertyName={'logFileSize'} min={100} max={500} rangeLabel={true} value={items.logFileSize} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> MB</>
            )
        }

        const downloadLogFile = (fileName) => {
            settingService.downloadLogFile(fileName).then(res => {
                
            }).catch((e)=> {
                toastr.error(getErrorMessage(e));
            });
        }

        const openDownloadLogPopup = () => {
            addPopup({id: 'SERVER_SETTING_LOADING', type: commonConstants.LOADING_POPUP});
            settingService.fetchServerLogList().then(res => {
                addPopup({
                    id: settingConstants.LOG_DOWNLOAD_POPUP,
                    type: settingConstants.LOG_DOWNLOAD_POPUP,
                    items: res.items,
                    onDownload: fileName => {
                        downloadLogFile(fileName);
                    }, 
                    onClose: () => closePopup(settingConstants.LOG_DOWNLOAD_POPUP)
                });
            }).catch(e => {
                toastr.error(getErrorMessage(e));
            }).finally(()=>{
                closePopup('SERVER_SETTING_LOADING')
            });
        }

        const stopLogCollect = () => {
            settingService.stopLogCollect().then(res => {
                toastr.success(t("COM_TEXT_SUCCESS_P"));
            }).catch((e) => {
                toastr.error(getErrorMessage(e));
            });
        }

        const getPlayerLogCollectView = () => {
        return (
                <>
                    <Checkbox id={'playerLogCollect'} propertyName={'playerLogCollect'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.playerLogCollect}/> 
                    <div className={"red"} style={{paddingBottom: "5px"}}><Trans i18nKey={"MIS_SID_SEVER_PERMOFMANCE_AFFCTED_FUCNTION"} /></div>
                    { items.playerLogCollect &&
                        <div>
                            <div className={'server_setting none_scrolling'} style={{float:'left', height:'150px', overflowY:'auto',border: '1px solid #f0f0f0'}}>
                                <table style={{width: '300px'}}>
                                    <tbody>
                                        <tr>
                                            <th>{t("TABLE_MAC_ADDR_P")}</th>
                                        </tr>
                                        {
                                            isNil(items.logCollectDevices) && 
                                            <tr><td>{t("MESSAGE_COMMON_NO_DATA_P")}</td></tr>
                                        }
                                        {
                                            !isNil(items.logCollectDevices) && 
                                            items.logCollectDevices.map(
                                                device => <tr key={device}><td>{device}</td></tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <WhiteButton  style={{marginLeft: '5px'}} id={'stopLogCollect'} name={t("COM_BUTTON_STOP_P")} onClick={()=>stopLogCollect()} disable={isNil(items.logCollectDevices)?true:false}/>
                            </div>
                        </div>
                    }   
                </>
            ) 
        }

        return (
            [
                {id: 'SERVER_SETTING_LOG_LEVEL_SETTING', name: <Trans i18nKey={"TEXT_LOG_LEVEL_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].logLevel),
                value: <RadioGroup propertyName={'logLevel'} selects={logLevel} value={items.logLevel} onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)} /> },
                {id: 'SERVER_SETTING_LOG_FILE_SIZE', name: <Trans i18nKey={"ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].logFileSize),
                value: logFileSize()},
                {id: 'SERVER_SETTING_LOG_FILE_COUNT', name: <Trans i18nKey={"TEXT_FILE_COUNT_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].maxLogFileCount),
                value: <NumberInput propertyName={'maxLogFileCount'} min={1} rangeLabel={true} value={items.maxLogFileCount} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> },
                {id: 'SERVER_SETTING_LOG_DOWNLOAD', name: <Trans i18nKey={"BUTTON_DOWNLOAD_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].logDownload),
                value: <WhiteButton id={'logDownload'} name={t("BUTTON_DOWNLOAD_P")} onClick={()=>openDownloadLogPopup()} /> },
                {id: 'SERVER_SETTING_LOG_PLAYER_LOG_COLLECTION', name: <Trans i18nKey={"MIS_TEXT_PLAYER_LOG_COLLECT_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].playerLogCollect),
                value: getPlayerLogCollectView()},
            ]
        )
    }

    const checkPortValidationAndChange = (e, type, target) => {
        let value = e.target.value;

        let pNumber = /^[0-9]*$/; 
        if(pNumber.test(value)){
            if(value < commonConstants.MIN_PORT_VALUE || value > commonConstants.MAX_PORT_VALUE){
                toastr.error(t("COM_SID_SIGANGE_INVALID_PORT"));
                onChange(type, target, "");
            }
            else{
                onChangeTextInput(e, type, onChange)
            }
        }
        else{    
            toastr.error(t("COM_SID_SIGANGE_CAN_ONLY_USE_NUMBER"));
            onChange(type, target, "");
        }
    }

    const smtpServerSettings = (items) => {
        const type = 'smtpServer';

        

        const checkUseSmtp = (e) => {
            if(items.isUsedSettings){
                toastr.error(t("MIS_SID_NOT_DISABLE_THE_LDAP_SERVER_USED_BY_ORGANIZATIONS").replace("LDAP", "SMTP"));
                return;
            }
            onChangeCheckBox(e, type, onChange);
        }

        let  common = [
            {id: 'SERVER_SETTING_SMTP_ALARM_MAILING', name: <Trans i18nKey={"TABLE_IS_MAILING_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpServer),
            value: <Checkbox id={'useSmtpServer'} propertyName={'useSmtpServer'} name={t("MESSAGE_SETUP_SEND_MAIL_TO_ADMIN_WHEN_ALARM_P")} onChange={checkUseSmtp} checked={items.useSmtpServer}/>},
            {id: 'SERVER_SETTING_SMTP_SERVER_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpServerAddress),
            value: <TextInput propertyName={'smtpServerAddress'} value={items.smtpServerAddress} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={150} disabled={!items.useSmtpServer}/> },
            {id: 'SERVER_SETTING_SMTP_AUTHENTICATION', name: <Trans i18nKey={"MIS_TEXT_AUTHENTICATION_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpAuth),
            value: <Checkbox id={'useSmtpAuth'} propertyName={'useSmtpAuth'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpAuth} disabled={!items.useSmtpServer}/> },
            {id: 'SERVER_SETTING_SMTP_LOGIN_ID', name: <Trans i18nKey={"COM_TEXT_LOGIN_ID_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpSingInId),
            value: <TextInput propertyName={'smtpSingInId'} value={items.smtpSingInId} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={30} disabled={!(items.useSmtpServer&&items.useSmtpAuth)}/> },
            {id: 'SERVER_SETTING_SMTP_PASSWORD', name: <Trans i18nKey={"TEXT_PASSWORD_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpPassword),
            value: <TextInput propertyName={'smtpPassword'} value={items.smtpPassword} onChange={(e)=>onChangeTextInput(e, type, onChange)} type={"password"} maxLength={SMTP_PASSWORD_LENGTH} disabled={!(items.useSmtpServer&&items.useSmtpAuth)}/> },
            {id: 'SERVER_SETTING_SMTP_PORT', name: <Trans i18nKey={"TABLE_PORT_P"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpServerPort),
            value: <TextInput propertyName={'smtpServerPort'} value={items.smtpServerPort} onChange={(e)=>checkPortValidationAndChange(e, type, "smtpServerPort")}  disabled={!items.useSmtpServer} maxLength={commonConstants.SMTP_SERVER_PORT_MAX_LENGTH} /> },
            {id: 'SERVER_SETTING_SMTP_SSL', name: "SSL", 
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpSSL),
            value: <Checkbox id={'useSmtpSSL'} propertyName={'useSmtpSSL'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpSSL} disabled={!items.useSmtpServer}/> }
        ];
        common.push({id: 'SERVER_SETTING_SMTP_SEPARATE_SETTING', name: <Trans i18nKey={"MIS_TEXT_SEPARATE_SETTINGS_P"} />, 
        changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpSeparateSettings),
        value: <Checkbox id={'useSmtpSeparateSettings'} propertyName={'useSmtpSeparateSettings'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpSeparateSettings}/> });
        return common; 
    }

    const alarmMailingSettings = (items) => {
        const type = 'alarmMailing';
        const mailRetentionPeriod = [
            {"value": "0", "title": "COM_LFD_SID_UNLIMITED_KR_HOTEL"},
            {"value": "7", "title": "MIS_SID_ONE_WEEK"},
            {"value": "15", "title": getMixString(['COM_TV_SID_MIX_DAYS_KR_BLANK', '15'])},
            {"value": "30", "title": getMixString(['COM_TV_SID_MIX_DAYS_KR_BLANK', '30'])},
            {"value": "60", "title": getMixString(['COM_TV_SID_MIX_DAYS_KR_BLANK', '60'])}
        ];

        const onChangeWeekly = (week) => {
            onChange(type, "alarmActivatedDays", week);
        }

        const changeRunTime = (e, value, startOrEnd) => {
            if(startOrEnd == 'start'){
                onChange(type, "alarmEndTime", items.alarmEndTime);
            }else{
                onChange(type, "alarmStartTime", items.alarmStartTime);
            }
            onChangeTimeInput(e, value, type, onChange)
            
        }

        return (
            [ 
                {id: 'SERVER_SETTING_ALARM_MAILING', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useAlarmMailing),
                value: <Checkbox id={'useAlarmMailing'} propertyName={'useAlarmMailing'} name={t("MESSAGE_SETUP_SEND_MAIL_TO_ADMIN_WHEN_ALARM_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useAlarmMailing}/>},
                {id: 'SERVER_SETTING_ALARM_MAILING_RUNTIME', name: <Trans i18nKey={"COM_MIS_TEXT_RUN_TIME_P"} />, 
                changed: !isNil(changed[type])&&(!isNil(changed[type].alarmStartTime)||!isNil(changed[type].alarmEndTime)),
                value: <><TimeInput propertyName={'alarmStartTime'} value={items.alarmStartTime} showSeconds={false} onChange={(e, value)=>changeRunTime(e, value, 'start')} disabled={!items.useAlarmMailing}/>
                        -<TimeInput propertyName={'alarmEndTime'} value={items.alarmEndTime} showSeconds={false} onChange={(e, value)=>changeRunTime(e, value, 'end')} disabled={!items.useAlarmMailing}/></>},
                {id: 'SERVER_SETTING_ALARM_MAILING_WEEKDAYS', name: <Trans i18nKey={"TEXT_REMOTE_JOB_WEEKDAYS_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].alarmActivatedDays),
                value: alarmMailingWeekly(items.alarmActivatedDays, onChangeWeekly, !items.useAlarmMailing)},
                {id: 'SERVER_SETTING_ALARM_MAILING_NOTIFICATIONS', name: <Trans i18nKey={"COM_TV_NOTIFICATIONS_KR_VOICE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].logDownload),
                value: <WhiteButton id={'alarmMailingSettingBtn'} name={t("MIS_SID_ALARM_SETTINGS")} disable={!items.useAlarmMailing} onClick={()=>openNotificationSetting2DivPopup("etc")}/>},
                {id: 'SERVER_SETTING_ALARM_MAILING_PERIOD', name: <Trans i18nKey={"MIS_SID_SERVER_MAIL_RETENTION_PERIOD"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].mailRetentionPeriod),
                value: <RadioGroup propertyName={'mailRetentionPeriod'} selects={mailRetentionPeriod} value={items.mailRetentionPeriod} onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)}  disabled={!items.useAlarmMailing}/> },
                
            ]
        )
    }

    const openNotificationSetting2DivPopup = (category) => {
        const orgId = organInfo.selected;
        Promise.all([
            settingService.fetchUsersForNotifications(orgId),
            settingService.fetchNotificationTypes(orgId, category)
        ]).then(res => {
            const users = res[0].items;
            const notiTypes = res[1].items;
            addPopup({id: 'SERVER_SETTING_LOADING', type: commonConstants.LOADING_POPUP});
            addPopup({
                id: settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP,
                type: settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP,
                mode: category,
                users: users,
                notis: notiTypes,
                title: t("COM_TV_NOTIFICATIONS_KR_VOICE"),
                onSave: userNotifications => {
                    const callback = () => {
                        toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                        closePopup(settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP);
                    } 
                    saveNotificationsSetting(userNotifications, orgId, callback);
                }, 
                onClose: () => closePopup(settingConstants.NOTIFICATIONS_SETTING_2DIV_POPUP)
            });
        }).catch(e => {
            toastr.error(getErrorMessage(e));
        }).finally(()=>{
            closePopup('SERVER_SETTING_LOADING')
        });
    }

    const ldapServerSettings = (items) => {
        const type = 'ldapServer';

        const checkUseLdap = (e) => {
            const target = e.target.getAttribute('data-name');
            const checked = e.target.checked;
            if(items.isUsedSettings){
                toastr.error(t("MIS_SID_NOT_DISABLE_THE_LDAP_SERVER_USED_BY_ORGANIZATIONS"));
                return;
            }
            const id = "SETTING_COMMON_CONFIRM_POPUP";
            if(checked){
                onChange(type, target, checked);
                return;
            }
            addPopup({
                type: commonConstants.COMMON_CONFIRM_POPUP,
                id: id,
                title: t("COM_BUTTON_CONFIRM"),
                message: t("MIS_MESSAGE_DELETE_LDAP_SERVER_INFO_DELETE_ALSO_LDAP_USER_INFO_P").replace("<br>", "\n"),
                yesTitle: t('BUTTON_OK_P'),
                noTitle: t('BUTTON_CANCEL_P'),
                onClickYes: () => {
                    onChange(type, target, checked);
                    closePopup(id);
                },
                onClose: () => closePopup(id)
            });
        }
    
        const getLdapAddress = () => {
            let protocol = 'ldap://';
            if(items.useLdapSSL) {
                protocol = 'ldaps://';
            }
    
            return (
                <>
                    <div style={{float: 'left', marginRight: '2px'}}>{protocol}</div>
                    <div style={{float: 'left'}}>
                        <TextInput propertyName={'ldapServerAddress'} value={items.ldapServerAddress} maxLength={150} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useLdap} />
                    </div>
                </>
            )
        }
        return (
            [
                {id: 'SERVER_SETTING_LDAP_USE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useLdap),
                value: <Checkbox id={'useLdap'} propertyName={'useLdap'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>checkUseLdap(e)} checked={items.useLdap}/>},
                {id: 'SERVER_SETTING_LDAP_SSL', name: 'SSL', 
                changed: !isNil(changed[type])&&!isNil(changed[type].useLdapSSL),
                value: <Checkbox id={'useLdapSSL'} propertyName={'useLdapSSL'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useLdapSSL} disabled={!items.useLdap}/>},
                {id: 'SERVER_SETTING_LDAP_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ldapServerAddress),
                value: getLdapAddress()},
                {id: 'SERVER_SETTING_LDAP_ROOT_DN', name: <Trans i18nKey={"MIS_SID_20_ROOT_DN"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ldapRootDN),
                value: <TextInput propertyName={'ldapRootDN'} value={items.ldapRootDN} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={150} disabled={!items.useLdap} />},
                {id: 'SERVER_SETTING_LDAP_MANAGER_DN', name: <Trans i18nKey={"MIS_SID_20_MANAGER_DN"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ldapManagerDN),
                value: <TextInput propertyName={'ldapManagerDN'} value={items.ldapManagerDN} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={150} disabled={!items.useLdap} />},
                {id: 'SERVER_SETTING_LDAP_PASSWORD', name: <Trans i18nKey={"MIS_SID_20_MANAGER_PASSWORD"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ldapManagerPassword),
                value: <TextInput propertyName={'ldapManagerPassword'} value={items.ldapManagerPassword} type={"password"} onChange={(e)=>onChangeTextInput(e, type, onChange)} maxLength={50} disabled={!items.useLdap} />},
                {id: 'SERVER_SETTING_LDAP_ORGANIZATION_SYNC', name: <Trans i18nKey={"MIS_SID_20_USE_ORGANIZATION_SYNC"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useOrganizationSync),
                value: <Checkbox id={'useOrganizationSync'} propertyName={'useOrganizationSync'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useOrganizationSync} disabled={!items.useLdap}/>},
                {id: 'SERVER_SETTING_LDAP_SEPARATE', name: <Trans i18nKey={"MIS_TEXT_SEPARATE_SETTINGS_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useLdapSeparateSettings),
                value: <Checkbox id={'useLdapSeparateSettings'} propertyName={'useLdapSeparateSettings'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useLdapSeparateSettings}/>}
            ]
        )
    }

    const deleteMFAInfoPopup = () => {

        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: settingConstants.DELETE_MFA_INFO_POPUP,
            title: t("MIS_SID_CAJUL_DEL_INFO"),
            message: t("MIS_SID_CAJUL_A_U_SURE_WANT_DEL_ALL_STORED_OTP_AUTH_INFO"),
            width: 340,
            height: 120,
            onClickYes: () => {

                const deleteMfaData = {"passwordPolicy": {"deleteMfaData": true}};

                settingService.updateServerSettings(deleteMfaData).then(res => {
                    toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                    closePopup(settingConstants.DELETE_MFA_INFO_POPUP);
                }).catch(res => {
                    toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                })

            },
            onClose: () => {
                closePopup(settingConstants.DELETE_MFA_INFO_POPUP);
            }
        });
    }

    const openNotificationSettingPopup = (mode, title) => {
        settingService.fetchUsersForNotifications(organInfo.selected).then(res => {
            const alarmMode = mode + "_SERVER_ERROR";
            addPopup({id: 'SERVER_SETTING_LOADING', type: commonConstants.LOADING_POPUP});
            addPopup({
                id: settingConstants.NOTIFICATIONS_SETTING_POPUP,
                type: settingConstants.NOTIFICATIONS_SETTING_POPUP,
                mode: alarmMode,
                items: res.items,
                title: t(title),
                onSave: selected => {
                    saveExtServerNotificationsSettings(selected, alarmMode);
                }, 
                onClose: () => closePopup(settingConstants.NOTIFICATIONS_SETTING_POPUP)
            });
        }).catch(e => {
            toastr.error(getErrorMessage(e));
        }).finally(()=>{
            closePopup('SERVER_SETTING_LOADING')
        });
        
    }

    const saveExtServerNotificationsSettings = (userIds, mode) => {
        settingService.updateExternalServerNotifications(userIds, organInfo.selected, mode).then(res => {
            toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
            closePopup(settingConstants.NOTIFICATIONS_SETTING_POPUP);
        }).catch(res => {
            toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
        })
    }

    const externalServerSettings = (items) => {
        const type = 'externalServer';
        return (
            [
                {id: 'SERVER_SETTING_EXT_MONITORING_INTERVAL', name: <Trans i18nKey={"TABLE_MONITORING_INTERVAL_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].monitoringInterval),
                value: <><NumberInput propertyName={'monitoringInterval'} min={1} rangeLabel={true} value={items.monitoringInterval} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> {t("MIS_SID_20_MINUTE_S")}</>},
                {id: 'SERVER_SETTING_EXT_MONITORING_ERROR_COUNT', name: <Trans i18nKey={"MESSAGE_STATISTICS_LEFT_MENU_SUB_DEVICE_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].errorOccurenceCount),
                value: <><NumberInput propertyName={'errorOccurenceCount'} min={1} rangeLabel={true} value={items.errorOccurenceCount} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> {t("MIS_SID_20_TIME_S")}</>},
                {id: 'SERVER_SETTING_EXT_MONITORING_DATALINK', name: <Trans i18nKey={"MIS_SID_20_DATALINK_SERVER"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDatalinkMonitoring),
                value: <><Checkbox id={'useDatalinkMonitoring'} propertyName={'useDatalinkMonitoring'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDatalinkMonitoring}/>
                         <WhiteButton id={'datalinkNotifications'} name={t("COM_TV_NOTIFICATIONS_KR_VOICE")} style={{marginLeft : '50px'}} 
                        onClick={()=>openNotificationSettingPopup('DL', 'MIS_SID_20_DATALINK_SERVER')}/></>},
                {id: 'SERVER_SETTING_EXT_MONITORING_EDGE', name: <Trans i18nKey={"MIS_SID_EDGE_SERVER"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useEdgeMonitoring),
                value: <><Checkbox id={'useEdgeMonitoring'} propertyName={'useEdgeMonitoring'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useEdgeMonitoring}/>
                        <WhiteButton id={'edgeNotifications'} name={t("COM_TV_NOTIFICATIONS_KR_VOICE")} style={{marginLeft : '50px'}} 
                        onClick={()=>openNotificationSettingPopup('DN', 'MIS_SID_EDGE_SERVER')}/></>},
                {id: 'SERVER_SETTING_EXT_MONITORING_RM', name: 'Remote Control Server', 
                changed: !isNil(changed[type])&&!isNil(changed[type].useRmMonitoring),
                value: <><Checkbox id={'useRmMonitoring'} propertyName={'useRmMonitoring'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useRmMonitoring}/>
                         <WhiteButton id={'rmNotifications'} name={t("COM_TV_NOTIFICATIONS_KR_VOICE")} style={{marginLeft : '50px'}}  
                         onClick={()=>openNotificationSettingPopup('RM', 'Remote Control Server')}/></>},
            ]
        )
    }

    const statisticsDataSettings = (items) => {
        const type = 'statisticsData';

        const getCollectFrequecyView = () => {
            const optionPopFrequencyLevel = [
                {value: 'year', title: 'MIS_TEXT_STATISTICS_POP_YEAR_P'},
                {value: 'month', title: 'TEXT_MONTH2_P'},
                {value: 'day', title: 'TEXT_DAY_P'},
                {value: 'hour', title: 'COM_TEXT_HOUR_P'},
                {value: 'sec', title: 'COM_SID_SEC'}
            ];
            const optionAmsLevel = [
                {value: 'day', title: 'TEXT_DAY_P'},
                {value: 'hour', title: 'COM_TEXT_HOUR_P'}
            ];
            return (
                <table>
                    <colgroup>
                        <col width="250px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_FREQUENCY_P")}</th>
                            <td>
                                <Select width={200} propertyName={'popFrequencyLevel'} selects={optionPopFrequencyLevel} title={optionPopFrequencyLevel[0].title} 
                                value={items.popFrequencyLevel} onChange={(e, value)=>onChangeSelect(e, value, type, onChange)} multiLang={true} disabled={!items.useStatistics}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }

        const getRetentionPeriodView = () => {
            return (
                <table>
                    <colgroup>
                        <col width="250px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("MIS_SID_MONTHLY_DATA")}</th>
                            <td>
                                <NumberInput propertyName={'cleanMonthPeriod'} min={1} rangeLabel={true} value={items.cleanMonthPeriod} disabled={!items.useStatistics}
                                onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> {t("TEXT_MONTH2_P")}
                            </td>
                        </tr>
                        <tr>
                            <th>{t("MIS_SID_DAILY_HOURLY_EVERY_SECOND_DATA")}</th>
                            <td>
                                <NumberInput propertyName={'cleanDayPeriod'} min={1} rangeLabel={true} value={items.cleanDayPeriod} disabled={!items.useStatistics}
                                onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}/> {t("TEXT_DAY_P")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }

        return (
            [
                {id: 'SERVER_SETTING_STAT_DATA_USE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useStatistics),
                value: <Checkbox id={'useStatistics'} propertyName={'useStatistics'} name={t("MIS_SID_SERVER_CBMAR_COLLECTS_ARHIVES_PLAY_FREQUENCY_MSG")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useStatistics}/>},
                {id: 'SERVER_SETTING_STAT_DATA_COLLECT_PERIOD', name: <Trans i18nKey={"MIS_SID_COLLECTION_PREIOD"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].collectPeriod),
                value: <><NumberInput propertyName={'collectPeriod'} min={1} rangeLabel={true} value={items.collectPeriod} onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)} disabled={!items.useStatistics}/> {t("TEXT_DAY_P")}</>},
                {id: 'SERVER_SETTING_STAT_DATA_COLLECT_FREQUENCY', name: <Trans i18nKey={"MIS_SID_COLLETION_FREQUENCY"} />,
                changed: !isNil(changed[type])&&(!isNil(changed[type].popFrequencyLevel)||!isNil(changed[type].amsFrequencyLevel)),
                value: getCollectFrequecyView() },
                {id: 'SERVER_SETTING_STAT_DATA_RETENTION_PERIOD', name: <Trans i18nKey={"COM_TV_SID_RETENTION_PERIOD"} />,
                changed: !isNil(changed[type])&&(!isNil(changed[type].cleanMonthPeriod)||!isNil(changed[type].cleanDayPeriod)),
                value: getRetentionPeriodView() }
            ]
        )
    }

    const autoTimezoneSettings = (items) => {
        const type = 'autoTimezone';

        const getTimeView = (mode, month, week, day, time) => {
            return(
                <>
                    <div className={'float_l pt5 mr20'}>
                        {t("TEXT_MONTH2_P")} :
                        <Select width={70} propertyName={'dst'+ mode +'Month'} selects={commonOptions.month} title={commonOptions.month[0].title} value={month} 
                                onChange={(e, value)=>onChangeSelect(e, value, type, onChange)} multiLang={true} disabled={!(items.useAutoTimezone&&items.useDST)}/>
                    </div>
                    <div className={'float_l pt5 mr20'}>
                        {t("TEXT_DAY_P")} : 
                        <Select width={100} propertyName={'dst'+ mode +'Week'} selects={commonOptions.week} title={commonOptions.week[0].title} value={week} 
                                onChange={(e, value)=>onChangeSelect(e, value, type, onChange)} multiLang={true} disabled={!(items.useAutoTimezone&&items.useDST)}/>
                        <span style={{paddingLeft: '5px'}}></span>
                        <Select width={70} propertyName={'dst'+ mode +'Day'} selects={commonOptions.day} title={commonOptions.day[0].title} value={day} 
                                onChange={(e, value)=>onChangeSelect(e, value, type, onChange)} multiLang={true} disabled={!(items.useAutoTimezone&&items.useDST)}/>
                    </div>
                    <div className={'float_l pt5'}>
                        {t("TEXT_TIME_P")} : 
                        <TimeInput width={70} propertyName={'dst'+ mode +'Time'} value={time} showSeconds={false} 
                                   onChange={(e, value)=>onChangeTimeInput(e, value, type, onChange)} disabled={!(items.useAutoTimezone&&items.useDST)}/>
                        ex) hh:mm
                    </div>
                </>
            )
        }

        const timeDiffence = [
            {value: 0, title: "+1:00"},
            {value: 1, title: "+2:00"}
        ];

        return (
            [
                {id: 'SERVER_SETTING_AUTO_TIMEZONE_USE', name: <Trans i18nKey={"MIS_SID_AUTIOMATIC_TIME_ZONE"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useAutoTimezone),
                value: <Checkbox id={'useAutoTimezone'} propertyName={'useAutoTimezone'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useAutoTimezone}/>},
                {id: 'SERVER_SETTING_AUTO_TIMEZONE_LIST', name: <Trans i18nKey={"COM_TABLE_TIME_ZONE_INDEX_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].selectedTimeZone),
                value: <Select width={400} propertyName={'selectedTimeZone'} title={deviceOptions.timeZone[0].title} value={!isNil(items.selectedTimeZone)?items.selectedTimeZone:deviceOptions.timeZone[0].value} 
                        selects={deviceOptions.timeZone} onChange={(e, value)=>onChangeSelect(e, value, type, onChange)} disabled={!items.useAutoTimezone} multiLang={false}/>},
                {id: 'SERVER_SETTING_AUTO_TIMEZONE_DST', name: <Trans i18nKey={"TABLE_DAY_LIGHT_SAVING_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].useDST),
                value: <Checkbox id={'useDST'} propertyName={'useDST'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDST} disabled={!items.useAutoTimezone}/>},
                {id: 'SERVER_SETTING_AUTO_TIMEZONE_START', name: <Trans i18nKey={"COM_SID_START_TIME"} />, 
                changed: !isNil(changed[type])&&(!isNil(changed[type].dstStartMonth)||!isNil(changed[type].dstStartWeek)||!isNil(changed[type].dstStartDay)||!isNil(changed[type].dstStartTime)),
                value: getTimeView('Start', items.dstStartMonth, items.dstStartWeek, items.dstStartDay, items.dstStartTime)},
                {id: 'SERVER_SETTING_AUTO_TIMEZONE_END', name: <Trans i18nKey={"COM_SID_END_TIME"} />, 
                changed: !isNil(changed[type])&&(!isNil(changed[type].dstEndMonth)||!isNil(changed[type].dstEndWeek)||!isNil(changed[type].dstEndDay)||!isNil(changed[type].dstEndTime)),
                value: getTimeView('End', items.dstEndMonth, items.dstEndWeek, items.dstEndDay, items.dstEndTime)},
                {id: 'SERVER_SETTING_AUTO_TIMEZONE_DIFFERENCE', name: <Trans i18nKey={"MIS_TEXT_TIME_DIFFERENCE_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].dstTimeDifference),
                value: <RadioGroup propertyName='dstTimeDifference' selects={timeDiffence} value={items.dstTimeDifference} disabled={!(items.useAutoTimezone&&items.useDST)}
                        onChange={(e, value)=>onChangeRadioGroup(e, value, type, onChange)} multiLang={false}/>},
            ]
        )
    }

    const dbEncyptionSettings = (items) => {
        const type = 'general';
        const getDatabaseInfo = () => {
            settingService.fetchDatabaseInfo().then(res => {
                if(!isNil(res) && !isNil(res.items)){
                    const dbInfo = {...res.items};
                    let dbAccessData = [];
                    Object.keys(dbInfo).forEach(key => {
                        let data = {};
                        data.key = key;
                        data.value = dbInfo[key];
                        dbAccessData.push(data);
                    })
                    addPopup({
                        id: settingConstants.DB_ACCESS_POPUP,
                        type: settingConstants.DB_ACCESS_POPUP, 
                        title: t('MIS_SID_DATABASE_ACCESS_INFORMATION'), 
                        data: dbAccessData,
                        onClose: () => closePopup(settingConstants.DB_ACCESS_POPUP),
                    });

                }else{
                    toastr.error(t("MESSAGE_COMMON_ERROR_P"));
                }
            }).catch((e) => {
                toastr.error(getErrorMessage(e));
            })
        }

        const getView = () => {
            let view = [{id: 'SERVER_SETTING_DB_ENCRYPTION', name: <Trans i18nKey={"COM_MRMS_CFGE_CONC_CITRIX_OTHER_ENCRYPTION"} />, 
            changed: !isNil(changed[type])&&!isNil(changed[type].useDbEncryption),
            value: <Checkbox id={'useDbEncryption'} propertyName={'useDbEncryption'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useDbEncryption}/>}];

            if(items.useDbEncryption){
                view.push({id: 'SERVER_SETTING_DB_INFO', name: <Trans i18nKey={"MIS_SID_DATABASE_ACCESS_INFORMATION"} />, 
                value: <WhiteButton id={'getDatabaseInfo'} name={t("MIS_SID_VIEW")} onClick={()=>getDatabaseInfo()} />})
            }

            return view;
        }
        return getView();
    }

    const ruleManagerServerSettings = (items) => {
        const type = 'thirdpartService';
        const selectRuleManageOrgan = (organs) => {
            onChange(type, 'ruleManagerOrgans', organs);
            closePopup('CHANGE_ORGANIZATION_MULTI');
        }
        const openSelectOrgansPopup = (organs) => {
        const id = 'CHANGE_ORGANIZATION_MULTI';
            addPopup({
                type: commonConstants.CHANGE_ORGANIZATION_MULTI,
                id: id,
                title: t("TEXT_SEL_ORGANIZATION_P"),
                message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                organs,
                onSave: selectRuleManageOrgan,
                onClose: () => closePopup(id)
            });
        }
    const selectedOrgans = () => {
            return (
                <>
                <div className="float_l mr10">
                    <SearchButton onClick={()=>openSelectOrgansPopup(items.ruleManagerOrgans)}/>
                </div>
                <div className="float_l" style={{width: 190, background: 'none'}}>
                    <div className={'selectedList'}>
                        {
                            !isNil(items.ruleManagerOrgans) && 
                            items.ruleManagerOrgans.map((organ, index) =>
                                <div key={index} className={'selectedItem'}>
                                    <span>{organ.groupName}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
                </>
            )
        }

        return (
            [
                {id: 'SERVER_SETTING_RULE_MANAGER_URL', name: <Trans i18nKey={"COM_LIST_TITLE_URL"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ruleManagerUrl),
                value: <TextInput propertyName={'ruleManagerUrl'} value={items.ruleManagerUrl} onChange={(e)=>onChangeTextInput(e, type, onChange)}  />},
                {id: 'SERVER_SETTING_RULE_MANAGER_KEY', name: <Trans i18nKey={"COM_BDP_SID_SECURITY_KEY_TEXT"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ruleManagerApiKey),
                value: <TextInput propertyName={'ruleManagerApiKey'} value={items.ruleManagerApiKey} onChange={(e)=>onChangeTextInput(e, type, onChange)}  />},
                {id: 'SERVER_SETTING_RULE_MANAGER_ORGAN', name: <Trans i18nKey={"TEXT_SEL_ORGANIZATION_P"} />, 
                changed: !isNil(changed[type])&&!isNil(changed[type].ruleManagerOrgIds),
                value: selectedOrgans()}
            ]
        )
    }

    const getAutoTimeZoneTitle = () => {
        return (
            <>
                {t("MIS_SID_AUTIOMATIC_TIME_ZONE")}(SPlayer)
                <span className={"red"} style={{paddingLeft:"20px"}}>*{t("MIS_SID_SERVER_SETTING_NOT_BEING_APPLIED_UPPORTING")}</span>
            </>
        )
    }


    const privacyPolicyServerSettings = (items) => {
        const type = 'privacyPolicy';

        if( (!isNil(changed[type]) && !isNil(changed[type].privacyPolicyLocation) && settingConstants.PRIVACY_POLICY_LOCATION_WITHOUT_AGREE === toUpper(changed[type].privacyPolicyLocation) )
            || (settingConstants.PRIVACY_POLICY_LOCATION_WITHOUT_AGREE === toUpper(items.privacyPolicyLocation))){

            return (
                [{
                    id: 'PRIVACY_POLICY_VIEW', name: <Trans i18nKey={"COM_TV_SID_PRIVACY_POLICY_FOR_GER"} />,
                    changed: !isNil(changed[type]) && !isNil(changed[type].privacyPolicy),
                    value: <WhiteButton id={'privacyPolicyBtn'} name={t("MIS_SID_VIEW")}
                                        onClick={() => {
                                            openPrivacyPolicySettingPopup(props, onChange)
                                        }}/>
                },
                    {
                        id: 'DATA_COLLECTION_ENABLE', name: <Trans i18nKey={"MIS_SID_20_COLLECT_LOG"} />,
                        changed: !isNil(changed[type]) && !isNil(changed[type].privacyPolicyEnable),
                        value: <Checkbox id={'privacyPolicyEnable'} propertyName={'privacyPolicyEnable'}
                                         name={t("TEXT_ENABLE2_P")}
                                         onChange={(e) => onChangeCheckBox(e, type, onChange)}
                                         checked={items.privacyPolicyEnable}/>
                    }

                ])
        }else{
            return (
                [{
                    id: 'PRIVACY_POLICY_VIEW', name: <Trans i18nKey={"COM_TV_SID_PRIVACY_POLICY_FOR_GER"} />,
                    changed: !isNil(changed[type]) && !isNil(changed[type].privacyPolicy),
                    value: <WhiteButton id={'privacyPolicyBtn'} name={t("MIS_SID_VIEW")}
                                        onClick={() => {
                                            openPrivacyPolicySettingPopup(props, onChange)
                                        }}/>
                },


                ])
        }


    }

    const insightServerSettings = (items) => {
        const type = 'insightServer';

        return (
            [
                {
                    id: 'INSIGHT_ENALBLE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />, 
                    changed: !isNil(changed[type])&&!isNil(changed[type].useServer),
                    value: <Checkbox id={'useInsight'} propertyName={'useServer'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useServer}/>},
                {
                    id: 'INSIGHT_IP', name: <><Trans i18nKey={"TABLE_IP_ADDR_P"} /></>, 
                    changed: !isNil(changed[type])&&!isNil(changed[type].ip),
                    value: <TextInput propertyName={'ip'} value={items.ip} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useServer} maxLength={255} /> 
                },
                {   
                    id: 'INSIGHT_HTTP_PORT', name: <>HTTP <Trans i18nKey={"TABLE_PORT_P"} /></>, 
                    changed: !isNil(changed[type])&&!isNil(changed[type].httpPort),
                    value: <TextInput propertyName={'httpPort'} value={items.httpPort} onChange={(e)=>checkPortValidationAndChange(e, type, "httpPort")}  disabled={!items.useServer} maxLength={10} /> 
                },
                {   
                    id: 'INSIGHT_HTTPS_PORT', name: <>HTTPS <Trans i18nKey={"TABLE_PORT_P"} /></>, 
                    changed: !isNil(changed[type])&&!isNil(changed[type].httpsPort),
                    value: <TextInput propertyName={'httpsPort'} value={items.httpsPort} onChange={(e)=>checkPortValidationAndChange(e, type, "httpsPort")}  disabled={!items.useServer} maxLength={10} /> 
                },
                {
                    id: 'INSIGHT_KEY', name: <Trans i18nKey={"COM_BDP_SID_SECURITY_KEY_TEXT"} />, 
                    changed: !isNil(changed[type])&&!isNil(changed[type].securityKey),
                    value: <TextInput propertyName={'securityKey'} value={items.securityKey} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useServer} maxLength={30} /> 
                }
            ]
        )
    }

    const updateLoginPageImageFileName = (loginPageImageFileName) => {
        setLoginPageImageFileName(loginPageImageFileName)
        //DF210903-00530
        onChange("loginPage", "imgFileName", loginPageImageFileName)
        closePopup(settingConstants.REGISTER_LOGIN_PAGE_IMAGE)
    }

    const openSelectLoginPageImageSettingPopup = () => {
        settingService.fetchUsersForNotifications(organInfo.selected).then(res => {

            addPopup({id: 'SERVER_SETTING_LOADING', type: commonConstants.LOADING_POPUP});
            addPopup({
                id: settingConstants.REGISTER_LOGIN_PAGE_IMAGE,
                type: settingConstants.REGISTER_LOGIN_PAGE_IMAGE,
                items: res.items,
                title: t("MIS_SID_CBOCT_LOING_PAGE_IMAGE"),
                onSave: loginPageImageFileName => {
                    updateLoginPageImageFileName(loginPageImageFileName);
                },
                onClose: () => closePopup(settingConstants.REGISTER_LOGIN_PAGE_IMAGE)
            });
        }).catch(e => {
            toastr.error(getErrorMessage(e));
        }).finally(()=>{
            closePopup('SERVER_SETTING_LOADING')
        });

    }

    const loginPageSettings = (items) => {
        const type = 'loginPage';
        //[v9 RC #7][Login Screen Customization]
        const MAIN = 'main'
        const SUB = 'sub'
        const optionFontWeightList = [
            {value: 'Normal', title: 'TEXT_NORMAL_P'},
            {value: 'Bold', title: 'COM_IDS_EDIT_BOLD'}
        ];
        


        const getFontWeightValue = (curPropertyName) => {
            let curPropertyWeightField = curPropertyName === MAIN ? 'mainTextFontWeight' : 'subTextFontWeight';
            let curValue = optionFontWeightList.find((e) => e.value == items[curPropertyWeightField]);
            if(isNil(curValue)) {
                //Default font weight for main text is Bold
                if(curPropertyName === MAIN) curValue = optionFontWeightList.find((e) => e.value == 'Bold');

                //Default font weight for sub text is Normal
                if(curPropertyName === SUB) curValue = optionFontWeightList.find((e) => e.value == 'Normal');
            }
            return curValue
        }
        const getFontSizeValue = (curPropertyName) => {
            let curPropertySizeField = curPropertyName === MAIN ? 'mainTextFontSize' : 'subTextFontSize';
            let curValue = items[curPropertySizeField];
            if(isNil(curValue)) {
                //Default font size for main text is 30
                if(curPropertyName === MAIN) curValue = 30;

                //Default font size for sub text is 16
                if(curPropertyName === SUB) curValue = 16;
            }
            return curValue
        }
        const getPropView = (curPropertyName) => {
            return (
                <table>
                    <colgroup>
                        <col width="50px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("DID_LFD_TEXT")}</th>
                            <td>
                            <textarea value={curPropertyName === MAIN ? items.mainText: items.subText} 
                                     onChange={(e) => onChangeTextInput(e, type, onChange)}
                                      disabled={!items.useCustomConfig} maxLength={255}
                                      data-name={curPropertyName === MAIN ? 'mainText' : 'subText'} 
                                      style={{ width: '460px', height: '80px' }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("DID_LFD_COLOR")}</th>
                            <td>
                            <TextInput propertyName={curPropertyName === MAIN ? 'mainTextColor': 'subTextColor'}
                                         value={curPropertyName === MAIN ? items.mainTextColor: items.subTextColor}
										  onChange={(e) => onChangeTextInput(e, type, onChange)}
										  disabled={!items.useCustomConfig} maxLength={7}
                                          placeholder={'#323b46'}
                                          width={'80px'}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("COM_PROPERDLG_FONTSIZE")}</th>
                            <td>
                            <><NumberInput propertyName={curPropertyName === MAIN ? 'mainTextFontSize': 'subTextFontSize'} 
                                    value={getFontSizeValue(curPropertyName)}
                                    min={8} max={36} step={2} rangeLabel={true}
                                    onChange={(e, value)=>onChangeNumberInput(e, value, type, onChange)}
                                      disabled={!items.useCustomConfig} 
                                      inputStyle={{width:"75px"}}/>  {t("MIS_SID_WEBAUTHOR_RANGE")}
                                      </>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("MIS_SID_CBOCT_FONT_WEIGHT")}</th>
                            <td>
                            <Select width={80} propertyName={curPropertyName === MAIN ? 'mainTextFontWeight': 'subTextFontWeight'} 
                                selects={optionFontWeightList} 
                                title={getFontWeightValue(curPropertyName).title} 
                                value={getFontWeightValue(curPropertyName).value} 
                                onChange={(e, value)=>onChangeSelect(e, value, type, onChange)}
                                multiLang={true} disabled={!items.useCustomConfig} useEllipsis={true}
                                direction = {curPropertyName === MAIN ? 'down': 'up'}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }

        return (
            [
                {
                    id: 'CUSTOM_LOGIN_PAGE_ENALBLE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"}/>,
                    changed: !isNil(changed[type]) && !isNil(changed[type].useCustomConfig),
                    value: <Checkbox id={'loginPage'} propertyName={'useCustomConfig'} name={t("TEXT_ENABLE2_P")}
                                     onChange={(e) => onChangeCheckBox(e, type, onChange)}
                                     checked={items.useCustomConfig}/>
                },
                {
                    id: 'LOGIN_PAGE_IMG', name: <Trans i18nKey={"MIS_SID_CBOCT_LOING_PAGE_IMAGE"}/>,
                    changed: !isNil(changed[type]) && (!isNil(changed[type].useCustomLoginPageImage) || !isNil(changed[type].imgFileName)),
                    value: <><Checkbox id={'useCustomLoginPageImage'} propertyName={'useCustomLoginPageImage'}
                                       name={t("TEXT_ENABLE2_P")}
                                       onChange={(e) => onChangeCheckBox(e, type, onChange)}
                                       checked={items.useCustomLoginPageImage}
                                       style={{float: 'left'}} disabled = {!items.useCustomConfig}/>
                        <TextInput propertyName={'loginPageImageFileName'} value={loginPageImageFileName}
                                   onChange={(e) => onChangeTextInput(e, type, onChange)}
                                   disabled={true} maxLength={255}
                                   wrapperStyle={{marginLeft: '50px', float: 'left'}}/>
                        <WhiteButton id={'selectLoginPageImage'} name={t("TEXT_SELECT_P")}
                                     style={{marginLeft: '50px'}}
                                     onClick={() => openSelectLoginPageImageSettingPopup()}
                                    disable = {!items.useCustomLoginPageImage || !items.useCustomConfig}/></>
                },
                {
                    id: 'LOGIN_PAGE_MAIN_TEXT_PROP', name: <Trans i18nKey={"MIS_SID_CBOCT_MAIN_TEXT"}/>,
                    changed: !isNil(changed[type]) && (!isNil(changed[type].mainText)||!isNil(changed[type].mainTextColor)
                                                        || !isNil(changed[type].mainTextFontSize)|| !isNil(changed[type].mainTextFontWeight)),
                    value:getPropView('main')
                },
                {
                    id: 'LOGIN_PAGE_SUB_TEXT_PROP', name: <Trans i18nKey={"MIS_SID_CBOCT_SUB_TEXT"}/>,
                    changed: !isNil(changed[type]) && (!isNil(changed[type].subText)||!isNil(changed[type].subTextColor)
                                                        || !isNil(changed[type].subTextFontSize)|| !isNil(changed[type].subTextFontWeight)),
                    value:getPropView('sub')
                }
            ]
        )
    }

    return(
        <>
        { !isNil(data.general) &&
            <SettingInformation id={'SERVER_SETTING_GENERAL'} title={t("COM_TEXT_GENERAL_P")} list={generalSettings(data.general)}/>
        }
        { !isNil(data.passwordPolicy) &&
            <SettingInformation id={'SERVER_SETTING_PASSWORD_POLICIES'} title={t("MIS_SID_PW_POLICIES")} list={passwordPolicySettings(data.passwordPolicy)}/>
        }
        { !isNil(data.logManagement) &&
            <SettingInformation id={'SERVER_SETTING_LOG_MANAGEMENT'} title={t("TEXT_LOG_MANAGEMENT_P")} list={logManagementSettings(data.logManagement)}/>
        }
        { !isNil(data.smtpServer) &&
            <SettingInformation id={'SERVER_SETTING_SMTP_SERVER'} title={t("MIS_TEXT_SMTP_SERVER_P")} list={smtpServerSettings(data.smtpServer)}/>
        }
        { !isNil(data.alarmMailing) &&
            <SettingInformation id={'SERVER_SETTING_ALARM_MAILING'} title={t("TEXT_TITLE_ALARM_MAILING_SERVICE_P")} list={alarmMailingSettings(data.alarmMailing)}/>
        }
        { !isNil(data.ldapServer) &&
            <SettingInformation id={'SERVER_SETTING_LDAP_SERVER'} title={t("MIS_TEXT_LDAP_SERVER_P")} list={ldapServerSettings(data.ldapServer)}/>
        }
        { !isNil(data.externalServer) &&
            <SettingInformation id={'SERVER_SETTING_EXT_SERVER_MONITORING'} title={t("MIS_SID_20_EXTERNAL_SERVER_MANAGEMENT")} list={externalServerSettings(data.externalServer)}/>
        }
        { !isNil(data.statisticsData) &&
            <SettingInformation id={'SERVER_SETTING_STATIC_DATA_MANAGEMENT'} title={t("MIS_SID_STATIC_DATA_MANAGEMENT")} list={statisticsDataSettings(data.statisticsData)}/>
        }
        { !isNil(data.autoTimezone) &&
            <SettingInformation id={'SERVER_SETTING_AUTO_TIMEZONE'} list={autoTimezoneSettings(data.autoTimezone)}
            title={getAutoTimeZoneTitle()} />
        }
        { !isNil(data.thirdpartService) && !isNil(data.thirdpartService.useRuleManager) && data.thirdpartService.useRuleManager &&
           <SettingInformation id={'RULE_MANAGER'} title={"Rule Manager"} list={ruleManagerServerSettings(data.thirdpartService)}/>
        }
        {  myInfo.userId === userConstants.USER_ID_ADMIN &&  !isNil(data.privacyPolicy) &&
            <SettingInformation id={'SERVER_SETTING_PRIVACY_POLICY'} title={t("COM_TV_SID_PRIVACY_POLICY_FOR_GER")} list={privacyPolicyServerSettings(data.privacyPolicy)}/>
        }
        { !isNil(data.insightServer) &&
           <SettingInformation id={'SERVER_SETTING_INSIGHT'} title={'Insight'} list={insightServerSettings(data.insightServer)}/> 
        }
        { !isNil(data.loginPage) &&
            <SettingInformation id={'SERVER_SETTING_LOGIN_PAGE'} title={'Custom Login Page'} list={loginPageSettings(data.loginPage)}/>
        }
        </>
    )
}
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(ServerSettingsAdmin);
