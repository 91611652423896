import {userValidator} from "../../../../helper/userValidator";
import isNil from "lodash/isNil";
import {userConstants} from "../../../../constants";
import {toastr} from 'helper/toastrIntercept';

export const executeValidate = (propName, dataField) => {
    let validatorResponse = {};
    if (propName === 'confirmPassword') {
        validatorResponse = userValidator(propName, dataField['password'], dataField[propName]);
    } else if(propName === 'nickname') {
        validatorResponse = userValidator(propName, dataField['isNickNameEnabled'], dataField[propName]);
    } else {
        validatorResponse = userValidator(propName, dataField[propName]);
    }
    return validatorResponse;
};

export const updateDetailed = (info, params) => {
    const newInfo = {...info};
    let {dataField} = newInfo;
    let keys = Object.keys(params);

    for (let i = 0; i < keys.length; ++i) {
        dataField[keys[i]] = params[keys[i]];
    }

    return newInfo;
};

export const handleChange = (e, value, info, t) => {
    const propName = e['dataName'] || e.target.getAttribute('data-name');
    const newInfo = {...info};
    const {dataField, errors} = newInfo;

    if (!isNil(value)) {
        dataField[propName] = value;
    } else if (!isNil(e.target.value)) {
        dataField[propName] = e.target.value;
    } else {
        dataField[propName] = e.target.getAttribute('data-value');
    }

    const {isValid, messageId} = executeValidate(propName, dataField);
    if (isValid) {
        delete errors[propName];
    } else {
        if (propName === 'email') {
            errors[propName] = t(messageId).replace('<br>', ' ');
        } else {
            errors[propName] = t(messageId);
        }
    }

    return newInfo;
};

export const checkPreValidation = (userInfo, setUserInfo, t, isApprovalUser = false, isResetPassword = false, needCurrentPassword=true) => {
    let isErrorExist = false;
    const {dataField, errors} = userInfo;

    for (let propName in dataField) {
        delete errors[propName];
    }

    for (let propName in dataField) {
        if (isApprovalUser) {
            if (propName === 'userId' ||
                propName === 'nickname' ||
                propName === 'password' ||
                propName === 'confirmPassword' ||
                propName === 'organizationGroupName' ||
                propName === 'email') {
                continue;
            }
        }

        if (isResetPassword) {
            if (propName === 'userId') {
                continue;
            }
        }

        if (!needCurrentPassword) {
            if (propName === 'userId') {
                continue;
            }
        }

        if (propName === 'userId') {
            if (dataField.ldapSimpleId !== undefined && dataField.ldapSimpleId !== '' && dataField.ldapSimpleId !=="") {
                continue;
            }
        }

        if (propName === 'nickname') {
            if (dataField.ldapSimpleId !== undefined && (dataField.ldapSimpleId === '' ||  dataField.ldapSimpleId === "")) {
                continue;
            }
        }

        if (propName === 'organizationGroupName') {
            if (dataField['organizationId'] !== userConstants.ORGAN_ID_ADMINISTRATOR || dataField['roleId'] === userConstants.ROLE_ID_SERVER_ADMINISTRATOR) {
                continue;
            }
        }

        if (propName === 'password' || propName === 'confirmPassword') {
            if (dataField.ldapSimpleId !== undefined && dataField.ldapSimpleId !== '' && dataField.ldapSimpleId !=="") {
                continue;
            }
        }

        const {isValid, messageId} = executeValidate(propName, dataField);

        if (isValid) {
            delete errors[propName];
        } else {
            if (propName === 'email') {
                errors[propName] = t(messageId).replace('<br>', ' ');
            } else {
                errors[propName] = t(messageId);
            }
        }
    }

    setUserInfo({...userInfo});

    for (let propName in dataField) {
        if (errors.hasOwnProperty(propName)) {
            isErrorExist = true;
            break;
        }
    }

    return isErrorExist;
};

export const checkPostValidation = (userInfo, t) => {
    let isErrorExist = false;
    const {dataField} = userInfo;

    if (dataField['password'] !== undefined && dataField['password'].includes(dataField['userId'])) {
        toastr.error(t('MIS_SID_SERVER_YOUR_PASSWORD_CANNOT_INCLUDE_YOUR_USER_ID'));
        isErrorExist = true;

        return isErrorExist;
    }

    if (dataField['password'] !== undefined && dataField['phoneNum'] !== '' && dataField['password'].includes(dataField['phoneNum'])) {
        toastr.error(t('MIS_SID_PW_NOT_INCLUDE_PHONE'));
        isErrorExist = true;

        return isErrorExist;
    }
};

export const updateRoleListServerAdmin = (roleList, organizationId) => {
    const idx = roleList.findIndex(function (item) {
        return item.value === userConstants.ROLE_ID_SERVER_ADMINISTRATOR
    });

    if (organizationId !== userConstants.ORGAN_ID_ADMINISTRATOR) {
        if (idx > -1) {
            roleList.splice(idx, 1)
        }
    }
};

export const updateRoleListWithCurrentUser = (roleList, userRoleName, refRoleList) => {
    const idx = roleList.findIndex(function (item) {
        return item.title === userRoleName
    });

    if (idx === -1) {
        let roleId;
        let organizationId;
        if (userRoleName === userConstants.ROLE_NAME_SERVER_ADMINISTRATOR) {
            roleId = userConstants.ROLE_ID_SERVER_ADMINISTRATOR;
            organizationId = userConstants.ORGAN_ID_ADMINISTRATOR;
        } else {
            let userRoleItem = refRoleList.filter(item => item.roleName === userRoleName)[0];
            roleId = userRoleItem.roleId;
            organizationId = userRoleItem.organizationId;
        }

        roleList.splice(0, 0, {title: userRoleName, value: roleId, organizationId: organizationId});
    }
};

export const checkButtonDisplayWithAuthority = (userAuthority, logInGroupId, isServerAdmin, isMultiOrganizationManager = false, roleName = '') => {
    let buttonDisplayObj = [];

    buttonDisplayObj[userConstants.BUTTON_KEY_ADD_USER] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_ADD_ORGANIZATION] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_REMOVE_USER] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ORGANIZATION] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ROLE] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_CONTENT_EXPORT] = true;
    buttonDisplayObj[userConstants.BUTTON_KEY_USER_DEVICE_PERMISSION] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_APPROVAL_USER] = false;
    buttonDisplayObj[userConstants.BUTTON_KEY_REJECT_USER] = false;

    if (userAuthority.READ === true && userAuthority.CREATE === true) {
        buttonDisplayObj[userConstants.BUTTON_KEY_ADD_USER] = true;
        buttonDisplayObj[userConstants.BUTTON_KEY_ADD_ORGANIZATION] = false;
        buttonDisplayObj[userConstants.BUTTON_KEY_REMOVE_USER] = true;
        buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ORGANIZATION] = false;
        buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ROLE] = true;
        buttonDisplayObj[userConstants.BUTTON_KEY_USER_DEVICE_PERMISSION] = false;
        buttonDisplayObj[userConstants.BUTTON_KEY_APPROVAL_USER] = true;
        buttonDisplayObj[userConstants.BUTTON_KEY_REJECT_USER] = true;

        if (logInGroupId === 0) {
            buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ORGANIZATION] = true;
        }

        if (isServerAdmin) {
            buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ORGANIZATION] = true;
            buttonDisplayObj[userConstants.BUTTON_KEY_USER_DEVICE_PERMISSION] = true;
        }
    }

    if (userAuthority.MANAGE === true) {

        buttonDisplayObj[userConstants.BUTTON_KEY_USER_DEVICE_PERMISSION] = true;

        if (isServerAdmin) {
            buttonDisplayObj[userConstants.BUTTON_KEY_ADD_ORGANIZATION] = true;
        }

    }

    if (isMultiOrganizationManager) {
        buttonDisplayObj[userConstants.BUTTON_KEY_CHANGE_ORGANIZATION] = false;
    }

    return buttonDisplayObj;
};

export const convertSortColumnId = (id) => {
    let sortColumnId;
    if (id === 'userInfo.userId') {
        sortColumnId = 'user_id'
    } else if (id === 'userInfo.userName') {
        sortColumnId = 'user_name'
    } else if (id === 'organizationName') {
        sortColumnId = 'organization'
    } else if (id === 'groupName') {
        sortColumnId = 'group_name'
    } else if (id === 'roleName') {
        sortColumnId = 'role_name'
    } else if (id === 'ldapId') {
        sortColumnId = 'ldap_user_id'
    } else if (id === 'joinDate') {
        sortColumnId = 'create_date'
    } else if (id === 'withdrawalDate') {
        sortColumnId = 'withdrawal_date'
    } else if (id === 'unApprovedCause') {
        sortColumnId = 'approval_type'
    }

    return sortColumnId;
};