import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import React, {useState, useEffect} from "react";
import RadioGroup from "../../../radio/RadioGroup";
import {settingConstants, LICENSE_INFO} from "../../../../constants";
import {deviceService} from '../../../../services';
import {getMixString} from '../../../../language/languageUtils';

const ReturnLicensePopup = ({onSave, onClose, type, licKey,title}) => {
    const {t} = useTranslation();

    const [internetType, setInternetType] = useState(true);
    const internetOption = [
        {value: true, title: t('COM_MIS_TEXT_CONNECTED_P')},
        {value: false, title: t('TEXT_DISCONNECTED_P')}
    ];

    const onChangeValue= (e, value) =>{
        setInternetType(value);
    }

    const isMigrationLicense = (licKey[0].split('-')[0] === LICENSE_INFO.MIGRATION_PRODUCT_CODE);
    const [shortageQty, setShortageQty] = useState(0);
    
    useEffect(() => {
        isMigrationLicense && deviceService.fetchLicenseUsage()
        .then( res => {
            res.items && res.items.forEach(item => {
                if(item.licenseType === "Unified Player" && item.usedLicenseCount > item.totalLicenseCount){
                    setShortageQty((item.totalLicenseCount - item.usedLicenseCount)*-1)
                }
            })
        })
    }, []);

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 400,
                    height: 150,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: (isMigrationLicense && shortageQty > 0) ? 
                    [
                        {
                            id: 'ACTIVATION_CANCEL',
                            title: t('BUTTON_OK_P'),
                            onClick: onClose,
                            disable: false,
                        }
                    ] : [
                        {
                            id: 'ACTIVATION_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                            disable: false,
                        },
                        {
                            id: 'ACTIVATION_NEXT',
                            title: t('COM_BUTTON_NEXT'),
                            onClick: () => onSave(licKey,internetType)
                        }
                    ]
                }}>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="143px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <table>
                            {
                                type == settingConstants.RETURN_LICENSE_POPUP_CONFIRM &&
                                <tr>
                                    <th>{t('COM_MIS_TEXT_INTERNET_CONNECTION_P')}</th>
                                    <td>
                                        <RadioGroup propertyName='internetType' selects={internetOption} onChange={(e, value) => onChangeValue(e, value)} value={internetType}/>
                                    </td>
                                </tr>
                            }
                        </table>
                        {licKey}
                        <br/><br/>
                        {
                            type == settingConstants.RETURN_LICENSE_POPUP_INIT && isMigrationLicense && shortageQty > 0 && 
                            <>
                                {getMixString(['MIS_SID_MIX_CAAPR_NOT_RETURN_QUANTITY_LESS_TARGET', 'Migration License', 'Unified License'])}  
                                <br/><br/>
                                {getMixString(['MIS_SID_MIX_CAAPR_SHORTAGE_QUANTITY_COLON', shortageQty])}
                            </>
                        }
                        {
                            type == settingConstants.RETURN_LICENSE_POPUP_INIT && isMigrationLicense && shortageQty <= 0 &&
                            <>
                                <br/>{t('COM_MIS_TEXT_DO_YOU_WANT_TO_RETURN_YOUR_LICENSE_P')}
                            </>
                        }                        
                        {
                            type == settingConstants.RETURN_LICENSE_POPUP_INIT && !isMigrationLicense &&
                            <>
                                {t('MIS_TEXT_AFTER_RETURNING_THE_LICENSE_KEY_CONNECTED_DEVICES_ARE_DELETED_P')}
                                <br/>{t('COM_MIS_TEXT_DO_YOU_WANT_TO_RETURN_YOUR_LICENSE_P')}
                            </>
                        }
                        {
                            type == settingConstants.RETURN_LICENSE_POPUP_CONFIRM &&
                            <>
                                {t('MIS_TEXT_YOU_CANNOT_USE_THIS_PROGRAM_AFTER_RETURNING_THE_LICENSE_P')}
                                <br/>{t('COM_MIS_TEXT_DO_YOU_WANT_TO_RETURN_YOUR_LICENSE_P')}
                            </>
                        }
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    )
};

export default ReturnLicensePopup;