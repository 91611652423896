import React from 'react';
import './CircleButton.css';

const CircleButton = ({onClick, onMouseDown, style, children, disable}) => {
    return (
        <button className={"base circle_button "+ (disable ? 'disabled' : '')} style={style} disabled={disable} onClick={onClick} onMouseDown={onMouseDown}>
            {children}
        </button>
    );
};
export default CircleButton;