import React from 'react';
import {commonConstants} from "../constants";

export const popupAction = {
    addPopup,
    updatePopup,
    closePopup,
    openDetailView,
    activePoup
}

function addPopup(popup) {
    return {
        type: commonConstants.COMMON_ADD_POPUP,
        popup: popup
    }
}

function updatePopup(popup) {
    return {
        type: commonConstants.COMMON_UPDATE_POPUP,
        popup: popup
    }
}

function closePopup(id) {
    return {
        type: commonConstants.COMMON_CLOSE_POPUP,
        popupId: id
    }
}

function openDetailView(popup) {
    return {
        type: commonConstants.COMMON_POPUP_TYPE_MODELESS,
        popup: popup
    }
}

function activePoup(maxZIndex,popupId) {
    return {
        type: commonConstants.COMMON_POPUP_ACTIVE_POPUP,
        maxZIndex: maxZIndex,
        activePopupId : popupId
    }
}