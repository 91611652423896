import React from 'react';
import {settingConstants} from "../constants";

const initialState  = {
    myAccount : {
        mode : "",
        data : {},
        changed : {},
        password : ""
    },
    serverSettings : {
        myInfo : {
            orgId: 0,
            role: null
        },
        organInfo: {
            list: {},
            selected: 0
        },
        data : {},
        changed : {}
    }
}

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case settingConstants.LOAD_MY_ACCOUNT:
            return {
                ...state,
                myAccount : {
                    mode : action.mode,
                    data : action.data
                }
            }
        case settingConstants.UPDATE_MY_ACCOUNT:
            return {
                ...state,
                myAccount : {
                    mode : "UPDATE",
                    data : action.data,
                    changed : action.changed 
                }
            }
        case settingConstants.CHECK_PASSWORD:
            return {
                ...state,
                myAccount : {
                    mode : "CONFIRM_PASSWORD",
                    data : action.data,
                    password : action.password
                }
            }
        case settingConstants.LOAD_SERVER_SETTINGS:
            return {
                ...state,
                serverSettings : {
                    data : action.data,
                    changed : {},
                    myInfo : action.myInfo,
                    organInfo : action.organInfo
                }
            }
        case settingConstants.UPDATE_SERVER_SETTINGS:
            return {
                ...state,
                serverSettings : {
                    data : action.data,
                    changed : action.updated,
                    myInfo : action.myInfo,
                    organInfo : action.organInfo
                }
            }
        default:
            return state
    }
}






