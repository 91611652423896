import React from 'react';
import {useTranslation} from "react-i18next";

const DeviceStatus = ({approvalStatus, onClickApprovalStatus, approvalStatusOption}) => {

    const {t} = useTranslation();

    return (
        <div className="dev_filter_type_radio" id="filter_list_approvalFilter">
            <h5><span className="approvalFilter" style={{background:'#60cfa6'}}></span>{t("MESSAGE_STATISTICS_SUMMARY_APPROVE_TITLE_P")}</h5>
            <div>
                <ul className="type_checklist" style={{overflowY:'auto'}}>
                    <li rel="All" className={approvalStatus.value === approvalStatusOption[0].value ? "on" : ""} onClick={onClickApprovalStatus(approvalStatusOption[0])} value="device_status_view_all">
                        <a href="#">{t("TEXT_ALL_P")}</a>
                    </li>
                    <li rel="Approval" className={approvalStatus.value === approvalStatusOption[1].value ? "on" : ""}  onClick={onClickApprovalStatus(approvalStatusOption[1])} value="approval_content">
                        <a href="#">{t("TEXT_APPROVAL_P")}</a>
                    </li>
                    <li rel="Unapproved" className={approvalStatus.value === approvalStatusOption[2].value ? "on" : ""} onClick={onClickApprovalStatus(approvalStatusOption[2])} value="unapproval_content">
                        <a href="#">{t("COM_TV_SID_UNAPPROVED")}</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default DeviceStatus;