import React, {useState} from "react";
import {Bar} from "react-chartjs-2";
import {useTranslation} from "react-i18next";

const SalesNPlaybackTimeChart = ({days}) => {
    //const labels = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ];
    const xlabel = days.map(day=>day.dayId)
    const salesDatas = days.map(day=>day.totalPrice)
    const playBackDatas = days.map(day=>day.totalPlaybackSecond)

    const {t} = useTranslation();

    const data = {
        datasets: [{
            label: t('MIS_SID_INSIGHT_CBJAN_SALES'),
            type: 'line',
            data: salesDatas,
            fill: false,
            borderColor: '#9bbb59',
            backgroundColor: '#9bbb59',
            pointBorderColor: '#9bbb59',
            pointBackgroundColor: '#9bbb59',
            pointHoverBackgroundColor: '#9bbb59',
            pointHoverBorderColor: '#9bbb59',
            yAxisID: 'y-axis-2'
        }, {
            label: t('MIS_SID_20_PLAYBACK_TIME'),
            type: 'bar',
            data: playBackDatas,
            fill: false,
            backgroundColor: '#4f81bd',
            borderColor: '#4f81bd',
            hoverBackgroundColor: '#4f81bd',
            hoverBorderColor: '#4f81bd',
            yAxisID: 'y-axis-1'
        }]
    };

    const options = {
        responsive: true,
        tooltips: {
            mode: 'label'
        },
        elements: {
            line: {
                fill: false
            }
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    gridLines: {
                        display: false
                    },
                    labels: xlabel
                }
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: false
                    },
                    labels: {
                        show: true
                    }
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        display: false
                    },
                    labels: {
                        show: true
                    }
                }
            ]
        }
    };

/*    const plugins = [{
        afterDraw: (chartInstance, easing) => {
            const ctx = chartInstance.chart.ctx;
            ctx.fillText("This text drawn by a plugin", 100, 100);
        }
    }];*/

    return (
        <>
            <Bar
                data={data}
                options={options}
            />
        </>
    )
}
export default SalesNPlaybackTimeChart;