import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import MISDialog from "../MISDialog";
import Tree from 'rc-tree';
import './LDAPOrganizationSelectionPopup.css';
import {userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {cloneDeep} from "lodash";
import {getErrorMessage} from "../../../helper/responseHandler";

class LDAPOrganizationSelectionPopup extends Component {
    state = {
        treeData: [],
        defaultSelectedKey: undefined,
        selectedLDAPOrganization: {},
    };

    constructor(props) {
        super(props);
        this.fetchGetLdapGroupList = this.fetchGetLdapGroupList.bind(this);
        this.save = this.save.bind(this);
        this.generatorTreeData = this.generatorTreeData.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.fetchGetLdapGroupList();
    }

    generatorTreeData(treeData) {
        for (let index = 0; index < treeData.length; index++) {
            let node = treeData[index];
            node.key = node.remoteLdapAllOrganization;
            node.title = node.remoteLdapOrganization;
            node.children = cloneDeep(node.remoteLdapSubOrganizationList);
            if (node.children && node.children.length > 0) {
                this.generatorTreeData(node.children);
            }
        }
    }

    onSelect(selectedKeys, info) {
        this.setState({
            selectedLDAPOrganization: {
                title: info.node.props.title,
                text: info.node.props.remoteLdapOrganization,
                data: info.node.props.remoteLdapAllOrganization,
            },
        });
    }

    save() {
        this.props.onSave(this.state.selectedLDAPOrganization);
    }

    fetchGetLdapGroupList() {
        userService.fetchLdapGroupList(this.props.groupId).then((res) => {
            let treeData = res.items;
            this.generatorTreeData(treeData);
            this.setState({
                treeData: treeData,
                defaultSelectedKey: this.props.ldapOrganization ?
                    [this.props.ldapOrganization] : undefined
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    }

    render() {
        const {t, onClose} = this.props;
        const {treeData, defaultSelectedKey} = this.state;
        return (
            <div className='ldap_organization_popup_wrap'>
                <MISDialog
                    dialog={{
                        title: t('MIS_SID_20_SELECT_LDAP_ORGANIZATION'),
                        closeOnEscape: true,
                        modal: true,
                        width: 300,
                        height: 400,
                        onClose: onClose,
                    }}
                    buttons={{
                        rightButtons: [
                            {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: this.save},
                            {id: "CLOSE", title: t("COM_BUTTON_CLOSE_P"), onClick: this.props.onClose}
                        ]
                    }}>
                    <div className={"ldap_organization_tree"}>
                        {treeData && treeData.length > 0 &&
                        <Tree
                            selectable={true}
                            defaultExpandAll
                            onSelect={this.onSelect}
                            treeData={treeData}
                            defaultSelectedKeys={defaultSelectedKey}
                            defaultCheckedKeys={defaultSelectedKey}/>
                        }
                    </div>
                </MISDialog>
            </div>
        );
    };
}

export default withTranslation()(LDAPOrganizationSelectionPopup);