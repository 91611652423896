import React, {useEffect, useMemo, useState} from 'react';
import {settingService} from "../../services";
import WhiteButton from "../../components/button/WhiteButton";
import {useTranslation, withTranslation} from "react-i18next";
import Checkbox from "../../components/checkbox/Checkbox";
import {useDispatch} from "react-redux";
import {messageLine, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import Moment from "moment";
import {popupAction} from "../../actions";
import {LICENSE_CHARGING_TYPE, commonConstants, settingConstants} from "../../constants";
import {toastr} from 'helper/toastrIntercept';
import {userValidator} from "../../helper/userValidator";
import {useMISOpt} from "../../components/misopt";
import {isEmpty} from 'lodash';
import {useTrGroupProps} from "../../helper/tables";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const Licenses = (props) => {
    const {misopt} = useMISOpt();
    const {user: {dateFormat}} = misopt;
    const convertedDateFormat = dateFormat.toUpperCase();
    const [isE2E, setIsE2E] = useState(false);
    const [e2eLicenseSystem, setE2ELicenseSystem] = useState(settingConstants.E2E_LICENSE_SYSTEM_PBP);

    const [licCount,setLicCount] = useState({
        Unified: 0,
        Lite: 0,
        Android: 0,
        RM: 0
    });


    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [filter, setFilter] = useFilter({
        page: 0,
        pageSize: 5,
        reload: false
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;

    const [style, setStyle] = useState({
        height: '500px'
    });

    useEffect(() => {
        setStyle({height: window.innerHeight - 195});
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const calLicCount = (lics)=> {
        let unified=0,lite=0,android=0,rm =0 ;
        lics.map(lic=> {
            if (lic.productCode == '01015A' || lic.productCode == '01014A' || lic.productCode == '010120' || lic.productCode == '010121' || lic.productCode == '010V31') {
                unified += lic.maxClients;
            } else if (lic.productCode == '010311') {
                lite += lic.maxClients;
            } else if (lic.productCode == '01011N') {
                android += lic.maxClients;
            } else if (lic.productCode == '01064A') {
                rm += lic.maxClients;
            }
        });
        setLicCount({Unified: unified,Lite : lite,Android: android,RM:rm});
    };

    const fetchData = () => {
        const {page, pageSize} = filter;
        setData({...data, loading: true});
        Promise.all([
        settingService.fetchLicenses(data),
        settingService.fetchCommonConfigInfo()])
            .then(res => {
                    setData({
                        ...data,
                        loading: false,
                        items: res[0].items,
                        totalCount: res[0].totalCount,
                        pages: Math.ceil(res[0].totalCount / pageSize)
                    });
                    calLicCount(res[0].items);
                    setIsE2E(res[1].items.general.isE2E);
                    setE2ELicenseSystem(res[1].items.general.e2eLicenseSystem);
                }
            )
    }

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const renderCheckAllCell = () => {
        return (
            <Checkbox
                id={'License_all'}
                classname={"table"}
                name={"check"}
                ref={checkAll}
                onChange={toggleSelectAll}
            />
        )
    };

    const renderCheckCell = (row) => {
        return (
            <Checkbox id={items[row.index].licenseKey}
                      index={row.index}
                      classname={"table"}
                      name={"check"}
                      ref={setCheckBoxRefs}
                      onChange={toggleRow}
                      />
        );
    };

    const getCheckedId = () => {
        return selected.current.map(s => items[s].licenseKey);
    }

    const firstActivationPopup = () => {
        dispatch(popupAction.addPopup({
            type: settingConstants.FIRST_ACTIVATION_POPUP_INIT,
            id : settingConstants.FIRST_ACTIVATION_POPUP_INIT,
            title : t('MIS_SID_20_1ST_TIME_ACTIVATION'),
            currentLicenses : data.items,
            onSave: firstGoToNext,
            onClose: ()=>dispatch(popupAction.closePopup(settingConstants.FIRST_ACTIVATION_POPUP_INIT))
        }));
    };

    const firstGoToNext = (licenseKey, internetType) => {
        if(isEmpty(licenseKey.key0) || isEmpty(licenseKey.key1) || isEmpty(licenseKey.key2) || isEmpty(licenseKey.key3)){
            toastr.warning(t("MESSAGE_COMMON_INPUT_CORRECT_LICENSE_KEY_P"));
            return;
        }
        const licKeyString = licenseKey.key0 + '-' + licenseKey.key1 + '-' + licenseKey.key2 + '-' + licenseKey.key3

        if (!/^[a-zA-Z0-9- ]+$/.test(licKeyString)) {
            toastr.error(t('MESSAGE_COMMON_INPUT_CORRECT_LICENSE_KEY_P'));
            return
        }

        settingService.checkLicenseKey(licKeyString).then(
            res => {
                if (internetType) {
                    activationOnlinePopup(licKeyString);
                } else {
                    activationOfflinePopup(licKeyString);
                }
            }
        ).catch(
            (err) => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(settingConstants.FIRST_ACTIVATION_POPUP_INIT));
        })
    }

    const activationOnlinePopup = (licenseKey) => {
        dispatch(popupAction.addPopup({
            type: settingConstants.FIRST_ACTIVATION_POPUP_ONLINE,
            id: settingConstants.FIRST_ACTIVATION_POPUP_ONLINE,
            licKey: licenseKey,
            title : t('MIS_SID_20_1ST_TIME_ACTIVATION'),
            onSave: saveActivationOnline,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.FIRST_ACTIVATION_POPUP_ONLINE))
        }));
    };

    const activationOfflinePopup = (licenseKey) => {
        settingService.getHWUniqueKey().then(
            res => {
                dispatch(popupAction.addPopup({
                    type: settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE,
                    id: settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE,
                    licKey: licenseKey,
                    hwUniqueKey: res.items,
                    title : t('MIS_SID_20_1ST_TIME_ACTIVATION'),
                    onSave: saveActivationOffline,
                    onClose: () => dispatch(popupAction.closePopup(settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE))
                }));
            }
        )
    };

    const saveActivationOnline = (licenseKey, company, isAgree) => {

        if(company.companyName == '' || company.division == '' || company.address == '' || company.phoneNumber == '' || company.email == ''){
            toastr.error(t('COM_MIS_TEXT_YOU_MUST_FILL_IN_ALL_OF_THE_FIELDS_P'));
            return;
        }

        const {isValid, messageId} = userValidator('email', company.email);

        if (!isValid) {
            toastr.error(t(messageId));
            return;
        }

        if(!isAgree){
            toastr.error(t('COM_MIS_TEXT_YOU_NEED_TO_AGREE_TO_PROVIDE_INFORMATION_TOPROCEED_P'));
            return;
        }

        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        settingService.activationOnline(licenseKey, company).then(
            res => {
                toastr.success(t('COM_MIS_TEXT_ACTIVATION_HAS_BEEN_COMPLETED_P'));
                showReLoginPopup();
            }
        ).catch(
            err => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            dispatch(popupAction.closePopup(settingConstants.FIRST_ACTIVATION_POPUP_ONLINE));
        })
    }

    const saveActivationOffline = (licenseKey, file) => {
        let formData = new FormData();
        formData.append("file", file);
        settingService.activationOffline(licenseKey, formData).then(
            res => {
                toastr.success(t('COM_MIS_TEXT_ACTIVATION_HAS_BEEN_COMPLETED_P'));
                showReLoginPopup();
            }
        ).catch(
            res => {
                toastr.error(errorCodeToErrMsg(res.errorCode, res.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(settingConstants.FIRST_ACTIVATION_POPUP_OFFLINE));
        })
    }

    const additionalActivationPopup = () => {
        const licenseKey = getCheckedId();
        if (licenseKey.length== 1) {
            settingService.checkAdditionalActivation(licenseKey).then(
                res => {
                    dispatch(popupAction.addPopup({
                        type: settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT,
                        id: settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT,
                        title: t('COM_MIS_BUTTON_ADDITIONAL_ACTIVATION_P'),
                        updateLicKey: licenseKey[0],
                        onSave: additionalGoToNext,
                        onClose: () => dispatch(popupAction.closePopup(settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT))
                    }));
                }
            ).catch(
                err => {
                    toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
                }
            )
        } else{
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }
    };

    const additionalGoToNext = (licenseKey, internetType) => {
        if(isEmpty(licenseKey.key0) || isEmpty(licenseKey.key1) || isEmpty(licenseKey.key2) || isEmpty(licenseKey.key3)){
            toastr.warning(t("MESSAGE_COMMON_INPUT_CORRECT_LICENSE_KEY_P"));
            return;
        }
        const licKeyString = licenseKey.key0 + '-' + licenseKey.key1 + '-' + licenseKey.key2 + '-' + licenseKey.key3
        if (internetType) {
            saveAdditionalActivationOnline(licKeyString);
        } else {
            additionalActivationOfflinePopup(licKeyString);
            dispatch(popupAction.closePopup(settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT))
        }
    }

    const additionalActivationOfflinePopup = (licenseKey) => {
        settingService.getHWUniqueKey().then(
            res => {
                dispatch(popupAction.addPopup({
                    type: settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE,
                    id: settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE,
                    title : t('COM_MIS_BUTTON_ADDITIONAL_ACTIVATION_P'),
                    licKey: licenseKey,
                    hwUniqueKey: res.items,
                    onSave: saveAdditionalActivationOffline,
                    onClose: () => dispatch(popupAction.closePopup(settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE))
                }));
            }
        )
    };

    const saveAdditionalActivationOnline = (licenseKey) => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        settingService.additionalActivationOnline(licenseKey).then(
            res => {
                toastr.success(t('COM_MIS_TEXT_ACTIVATION_HAS_BEEN_COMPLETED_P'));
                showReLoginPopup();
            }
        ).catch(
            err => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            dispatch(popupAction.closePopup(settingConstants.ADDITIONAL_ACTIVATION_POPUP_INIT));
        })
    }

    const saveAdditionalActivationOffline = (licenseKey, file) => {
        let formData = new FormData();
        formData.append("file", file);
        settingService.additionalActivationOffline(licenseKey, formData).then(
            res => {
                toastr.success(t('COM_MIS_TEXT_ACTIVATION_HAS_BEEN_COMPLETED_P'));
                showReLoginPopup();
            }
        ).catch(
            err => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(settingConstants.ADDITIONAL_ACTIVATION_POPUP_OFFLINE));
        })
    }


    const showReLoginPopup = () => {
        fetchData();
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_ALERT_POPUP,
            id: commonConstants.COMMON_ALERT_POPUP,
            title: t("COM_TEXT_INFORMATION_P"),
            message: t("MIS_MESSAGE_COMMON_LOGOUT_SETTING_CHANGE_P"),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP))
        }));
    };


    const returnLicensePopup = () => {
        const licenseKey = getCheckedId();
        if (licenseKey.length== 1) {
            dispatch(popupAction.addPopup({
                type: settingConstants.RETURN_LICENSE_POPUP_INIT,
                id: settingConstants.RETURN_LICENSE_POPUP_INIT,
                title: t('COM_MIS_BUTTON_RETURN_LICENSE_P'),
                height: 150,
                licKey: licenseKey,
                onSave: returnGoToNext,
                onClose: () => dispatch(popupAction.closePopup(settingConstants.RETURN_LICENSE_POPUP_INIT))
            }));
        } else{
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }
    };

    const returnGoToNext = (licKey) => {
        dispatch(popupAction.closePopup(settingConstants.RETURN_LICENSE_POPUP_INIT));
        dispatch(popupAction.addPopup({
            type: settingConstants.RETURN_LICENSE_POPUP_CONFIRM,
            id: settingConstants.RETURN_LICENSE_POPUP_CONFIRM,
            title: t('COM_MIS_BUTTON_RETURN_LICENSE_P'),
            licKey : licKey,
            onSave: saveReturnLicense,
            onClose: () => dispatch(popupAction.closePopup(settingConstants.RETURN_LICENSE_POPUP_CONFIRM))
        }));
    }

    const saveReturnLicense = (licKey, internetType) => {
        settingService.returnLicense(licKey,internetType).then(
            res => {
                fetchData();
                if(internetType) {
                    toastr.success(t('COM_MIS_TEXT_LICENSE_HAS_BEEN_DELETED_P'));
                } else {
                    showReturnLicenseKeyPopup(res.items);
                }
            }
        ).catch(
            err => {
                toastr.error(errorCodeToErrMsg(err.errorCode, err.errorMessage));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(settingConstants.RETURN_LICENSE_POPUP_CONFIRM));
        })
    };

    const showReturnLicenseKeyPopup = (returnLicKey) => {
        const msg = ' 1. '+ t("MIS_TEXT_AFTER_RETURNING_THE_LICENSE_KEY_CONNECTED_DEVICES_ARE_DELETED_P") + '<br>' + '2.'+ t("COM_MIS_TEXT_ENTER_THE_LICENSE_RETURN_KEY_TO_THE_LICENSE_SERVER_P")
            + '<br><br>'+ returnLicKey;
        const msgLine = messageLine(msg)
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_ALERT_POPUP,
            id: commonConstants.COMMON_ALERT_POPUP,
            title: t("COM_TEXT_INFORMATION_P"),
            height: '120px',
            message: msgLine,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_ALERT_POPUP))
        }));
    };

    const freeLicensePopup = () => {
        Promise.all([
            settingService.fetchAvailableFreeLicense(),
            settingService.getHWUniqueKey()
        ]).then(values => {
            if (values[0].items.length == 0) {
                toastr.error(t('MIS_TEXT_CANNOT_ISSUE_FREE_LICENSE_ANYMORE_P'));
                return;
            } else {
                dispatch(popupAction.addPopup({
                    type: settingConstants.FREE_LICENSE_POPUP,
                    id: settingConstants.FREE_LICENSE_POPUP,
                    title: t('MIS_BUTTON_FREE_LICENSE_KEY_P'),
                    hwUniqueKey: values[1].items,
                    onClose: () => dispatch(popupAction.closePopup(settingConstants.FREE_LICENSE_POPUP))
                }));
            }
        });
    };

    const licenseHistoryPopup = () => {
        dispatch(popupAction.addPopup({
            type: settingConstants.LICENSE_HISTORY_POPUP,
            id: settingConstants.LICENSE_HISTORY_POPUP,
            title: t('MIS_BUTTON_LICENSE_HISTORY_P'),
            onClose: () => dispatch(popupAction.closePopup(settingConstants.LICENSE_HISTORY_POPUP))
        }));
    };

    const licenseAssignPopup = () => {
        dispatch(popupAction.addPopup({
            type: settingConstants.LICENSE_ASSIGN_POPUP,
            id: settingConstants.LICENSE_ASSIGN_POPUP,
            title: t("MIS_SID_CBFEB_LICENSE_STATUS_ORGANIZATION"),
            onClose: () => dispatch(popupAction.closePopup(settingConstants.LICENSE_ASSIGN_POPUP))
        }));
    };

    const companyInfoPopup = () => {
        dispatch(popupAction.addPopup({
            type: settingConstants.E2E_COMPANY_INFO_POPUP,
            id: settingConstants.E2E_COMPANY_INFO_POPUP,
            title: t('MIS_BUTTON_LICENSE_HISTORY_P'),
            onClose: () => dispatch(popupAction.closePopup(settingConstants.E2E_COMPANY_INFO_POPUP))
        }));

    };

    const errorCodeToErrMsg = (errorCode, errorMessage) => {
        switch (errorCode) {
            case '400803' :
                return 'Communicating with SLM server is not available now. Please check the network. Please try using "Offline Activation".';
            case '400804' :
                return 'The license of the same type can be used only once. You need go to the SLM site to build up using the "Extension" menu. Please merge the license keys.';
            case '400805' :
                return 'The error is because the SSL communication is not available. Please check whether the SSL 443 port is blocked. Please try using "Offline Activation".';
            case '400806' :
                return 'The license key is being used in another PC. Please carry out "Return license" on the PC that was previously in use, and then check whether the license has been returned to the SLM server.';
            case '400807' :
                return 'The License Key that you input is wrong. Please input a correct value.';
            case '400808' :
                return t('COM_MIS_TEXT_LICENSE_IS_BEING_USED_P');
            case '400809' :
                return t('MIS_TEXT_LICENSE_KEY_FOR_THE_SELECTED_PRODUCT_IS_NOT_P');
            case '400813' :
                return t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P');
            case '400814' :
                return t('MIS_TEXT_FREE_LICENSE_COULD_NOT_BE_DELETED_P');
            case '400815' :
                return t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P');
            case '400817' :
                return t('COM_MIS_TEXT_HW_UNIQUE_KEY_IS_INVALID_P');
            case '400818' :
                return t('MIS_TEXT_FREE_LICENSE_COULD_NOT_BE_ADDED_P');
            case '400819' :
                return 'Failed to delete a license. (Deactivation key is invalid)';
            case '500810' :
                return 'SLM DB ERROR';
            case '400816':
                return errorMessage;
            default :
                return t('COM_MIS_TEXT_ERROR_SLM_LICENSE_SERVER_P');
        }
    }

    const columns = useMemo(() => [
        {
            accessor: "licenseKey",
            show: false
        },
        {
            id: "checkbox",
            Header: renderCheckAllCell,
            Cell: renderCheckCell,
            width: 46,
            sortable: false,
            resizable: false
        },
        {
            Header: t("COM_TEXT_LICENSE_KEY_P"),
            accessor: "licenseKey",
            width: 250,
            sortable: false
        },

        {
            Header: t("COM_MIS_TEXT_PRODUCT_NAME_P"),
            accessor: 'productName',
            width: 250,
            sortable: false
        },
        {
            Header: t("COM_TEXT_TITLE_CHARGE_TYPE_P"),
            accessor: "licenseType",
            width: 150,
            sortable: false
        },
        {
            Header: t("COM_MIS_TEXT_QUANTITY_P"),
            accessor: "maxClients",
            width: 150,
            sortable: false
        },
        {
            Header: t("COM_TABLE_START_DATE_P"),
            accessor: "startDate",
            width: 170,
            Cell: ({value}) => <span>{Moment(value).format(convertedDateFormat)}</span>,
            sortable: false
        },
        {
            Header: t("COM_MIS_TEXT_EXPIRATION_DATE_P"),
            accessor: "endDate",
            Cell: ({value}) =>
                value === null ? "-" : <span>{Moment(value).format(convertedDateFormat)}</span>,
            width: 170,
            sortable: false
        },
        {
            Header: t("DID_ADMIN_DEVICE_REGDATE"),
            accessor: "regDate",
            width: 170,
            Cell: ({value}) => <span>{Moment(value).format(convertedDateFormat)}</span>,
        sortable: false
        },
        {
            Header: t("MIS_SID_CAFEB_MAINTENANCE_EXPIRATION"),
            accessor: "maintEndDate",
            Cell: ({value}) => value === null ? '' : <span>{Moment(value).format(convertedDateFormat)}</span>,
            sortable: false
        }         
    ],[items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    const hasOldLicense = () => {
        return data.items.some(item => !['01011N', '01015A', '010311', '01064A'].includes(item.productCode));
    };

    const hasFreeLicense = () => {
        const targetLicenses = data.items.filter(item => ['01015A', '01064A'].includes(item.productCode));
        return !targetLicenses.every(license => license.licenseType === LICENSE_CHARGING_TYPE.CHARGED);
    }

    const hasAvailableLicense = () => {
        const targetLicenses = data.items.filter(item => ['01015A', '01064A'].includes(item.productCode));
        return targetLicenses.length > 0;
    }
    
    return (
        <div>
            <div className="contents_buttonWrap" style={{display: props.currContent === 'LICENSE_INFO' ? 'block':'none'}}>
                <div className="leftButton">
                    <WhiteButton id={"LICENSE_FIRST_ACTIVATE"} name={t("MIS_SID_20_1ST_TIME_ACTIVATION")} onClick ={()=>firstActivationPopup()}/>
                    <WhiteButton id={"LICENSE_ADDITIONAL_ACTIVATE"} name={t("COM_MIS_BUTTON_ADDITIONAL_ACTIVATION_P")} disable={selectedCnt !== 1} onClick ={()=>additionalActivationPopup()}/>
                    <WhiteButton id={"LICENSE_RETURN"} name={t("COM_MIS_BUTTON_RETURN_LICENSE_P")} disable={selectedCnt !== 1} onClick ={()=>returnLicensePopup()}/>
                    <WhiteButton id={"LICENSE_FREE_LICENSE"} name={t("COM_MIS_TEXT_FREELICENSE_P")} onClick ={()=>freeLicensePopup()}/>
                    <WhiteButton id={"LICENSE_HISTORY"} name={t("MIS_BUTTON_LICENSE_HISTORY_P")} onClick ={()=>licenseHistoryPopup()}/>
                    {
                        !isE2E &&
                        <WhiteButton id={"LICENSE_ASSIGN_ORG"} name={t("MIS_SID_CBFEB_LICENSE_STATUS")} disable={hasOldLicense() || hasFreeLicense() || !hasAvailableLicense() || data.items.length <= 0} onClick ={()=>licenseAssignPopup()}/>
                    }
                    {
                        isE2E && e2eLicenseSystem==settingConstants.E2E_LICENSE_SYSTEM_SLM &&
                        <WhiteButton id={"COMPANY_INFO"} name={'Company Info'} onClick={() => companyInfoPopup()}/>
                    }
                </div>
                <div className="rightButton">
                    <div className="device_number_txt">
                        <span id="lic_Unified Player (I, S, Signage)">Unified Player (I, S, Signage) : {licCount.Unified}</span>&nbsp;&nbsp;
                        <span id="lic_Lite Player">Lite Player : {licCount.Lite}</span>&nbsp;&nbsp;
                        <span id="lic_Android Player">Android Player : {licCount.Android}</span>&nbsp;&nbsp;
                        <span id="lic_RM Player">RM Player : {licCount.RM}</span>
                    </div>
                </div>
            </div>

            <div className='content_list_view'>

                <MagicInfoTable
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    data={items}
                    loading={loading}
                    style={style}
                    minRows={0}
                    columns={columns}
                    defaultPageSize={30}
                    getTrGroupProps={getTrGroupPropsType2}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                />
            </div>
        </div>
    )


}
export default withTranslation()(Licenses);