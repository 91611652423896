import React, {useRef, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import {DEVICE_CUSTOM_FILES_OPTIONS, SOFTWARE_TYPES} from '../../../../constants';
import MISDialog from '../../MISDialog';
import TextInput from '../../../input/TextInput';
import {SearchButton} from '../../../button/SearchButton';
import Chip from '../../../chip/Chip';
import {deviceService} from '../../../../services';
import ProgressBar from '../../../progressbar/ProgressBar';
import RadioGroup from '../../../radio/RadioGroup';

const RegisterCustomizePopup = ({customizeType, onSave, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const fileInput = useRef();
    const [software, setSoftware] = useState({});
    const [progress, setProgress] = useState();
    const [disableSave, setDisableSave] = useState(false);

    const certificationTypeOption = [
        SOFTWARE_TYPES.WIFI_CERTIFICATE,
        SOFTWARE_TYPES.SSL_CERTIFICATE
    ];

    const CERTIFICATE = 'certificate';

    useEffect(() => {
        const softwareType = (customizeType === CERTIFICATE)? certificationTypeOption[0].value : customizeType;
        setSoftware({...software, softwareType : softwareType});
    }, []);


    const onClickFile = () => {
        fileInput.current.click();
    };

    const onChangeSwFile = e => {
        const file = e.target.files[0];
        const ext = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
        const fileCon = DEVICE_CUSTOM_FILES_OPTIONS[software.softwareType];
        const supportedExt = fileCon.acceptFiles.split(',');
        const supportedExtVideo = fileCon.acceptFilesVideo.split(',');
        
        if(fileCon.acceptFiles === '*.*' || supportedExt.findIndex(se => se === ext) >= 0) {
            if(file.size > fileCon.maxSize) {
                toastr.error(t('MIS_MESSAGE_COMMON_EXCEED_CAPACITY_P'));
                fileInput.current.value = '';
                return;
            }
        } else if(fileCon.acceptFilesVideo === '*.*' || supportedExtVideo.findIndex(se => se === ext) >= 0) {
            if(file.size > fileCon.maxSizeVideo) {
                toastr.error(t('MIS_MESSAGE_COMMON_EXCEED_CAPACITY_P'));
                fileInput.current.value = '';
                return;
            }
        } else {
            toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
            fileInput.current.value = '';
            return;
        }

        if(file.fileName > 100) {
            toastr.error(t('COM_EBD_MSG_FILE_NAME_TOO_LONG'));
            fileInput.current.value = '';
            return;
        }

        setSoftware({...software, file});
    };

    const onDeleteSwFile = () => {
        fileInput.current.value = '';
        setSoftware({...software, file: undefined});
    };

    const onClickSave = () => {
        if(isNil(software.file)) {
            toastr.error(t('MESSAGE_UPLOADER_SEL_CONTENT_FILE_P'));
            return;
        }

        if(software.file) {
            if(software.softwareName === undefined || software.softwareName.trim() === '') {
                software.softwareName = software.file.name;
            }
            setDisableSave(true);
            deviceService.createSoftware({
                ...software,
                deviceType: 'SPLAYER'
            }, percent => setProgress(percent)).then(res => {
                toastr.success(t('MIS_SID_UPLOAD_COMPLETED'));
                if (onSave) {
                    onSave();
                }
            }).catch(error => {
                console.log(error);
                toastr.error(t('MIS_SID_FAILED_UPLOAD_FILE'));
                setDisableSave(false);
            }).finally(() => {
                // setProgress(undefined);
            });
        }
    };

    return (
        <MISDialog 
            dialog={{
                title: ((type) => {
                    switch(type){
                        case SOFTWARE_TYPES.CUSTOM_LOGO.type:
                            return t('MIS_SID_PREMIUM_REGISTER_CUSTOM_LOGO');
                        case SOFTWARE_TYPES.DEFAULT_CONTENT.type:
                            return t('MIS_SID_20_REGISTER_DEFAULT_CONTENT');
                        case CERTIFICATE:
                            return t('MIS_SID_CASEP_REGISTER_CERTIFICATE');
                    }
                })(customizeType),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 485,
                height: 185,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'BUTTON_OK_P',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickSave,
                        disable: disableSave,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{customizeType === SOFTWARE_TYPES.CUSTOM_LOGO.type || customizeType === SOFTWARE_TYPES.CUSTOM_LOGO.value?
                                    t('MIS_SID_PREMIUM_LOGO_NAME') : t('LIST_TITLE_NAME')}
                                </th>
                                <td>
                                    <TextInput
                                        width={240}
                                        placeholder={((type)=>{
                                            switch(type){
                                                case SOFTWARE_TYPES.CUSTOM_LOGO.type:
                                                    return t('MIS_SID_ENTER_LOGO_NAME');
                                                case SOFTWARE_TYPES.DEFAULT_CONTENT.type:
                                                    return t('COM_IDS_ERR_CONTENTS_NAME');
                                                case CERTIFICATE:
                                                    return t('MIS_SID_CASEP_ENTER_CERTIFICATE_NAME');
                                            }
                                        })(customizeType)}
                                        value={software.softwareName}
                                        onChange={e => setSoftware({...software, softwareName: e.target.value})}
                                        maxLength={50}/>
                                </td>
                            </tr>
                            {
                                customizeType === CERTIFICATE &&
                                <tr>
                                    <th>{t('COM_LIST_TITLE_FILE_TYPE')}</th>
                                    <td>
                                        <RadioGroup selects={certificationTypeOption} value={software.softwareType} onChange={(e, value) => setSoftware({...software, softwareType: value})} />
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>{t('TEXT_FILE_NAME_P')}</th>
                                <td>
                                    <span style={{marginRight: '8px'}}>
                                        <SearchButton onClick={onClickFile} />
                                        <input type='file' ref={fileInput} style={{display: 'none'}} onChange={onChangeSwFile} 
                                            accept={software.softwareType ? DEVICE_CUSTOM_FILES_OPTIONS[software.softwareType].acceptFiles + ',' + DEVICE_CUSTOM_FILES_OPTIONS[software.softwareType].acceptFilesVideo:''} />
                                    </span>
                                    {
                                        software.file &&
                                        <Chip title={software.file.name} height={20} onDelete={onDeleteSwFile} />
                                    }
                                </td>
                            </tr>
                            {
                                !isNil(progress) &&
                                <tr>
                                    <td colSpan="2">
                                        <ProgressBar width='100%' height={20} percentage={progress} fillerColor='#4be48c' background="#e8e8e8" useLabel={true} />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {
                        customizeType === 'customLogo' &&
                        <>
                            <div className="error_txt" style={{lineHeight: '20px'}}>{t('MIS_SID_MIX_IMAGE_UPT_TO_SIZE_MOVIE').replace('<<A>>', '50MB').replace('<<B>>', '150MB')}</div>
                            <div className="error_txt" style={{lineHeight: '20px'}}>(S4 Player : {t('MIS_SID_BMP_IMAGE_TS_MOVIES_150')})</div>
                        </>
                    }
                </div>
            </div>
        </MISDialog>
    );
};

export default RegisterCustomizePopup;