import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import TextInput from '../../../input/TextInput';
import {deviceOptions} from "../../../misopt";
import Checkbox from "../../../checkbox/Checkbox";
import RadioGroup from "../../../radio/RadioGroup";
import Select from "../../../selectbox/Select";
import {get, isNil} from "lodash";
import {EditPinCode} from "./EditSetup";
import {validateProxyPort} from "./../../../../helper/utils";

const EditFlipBasic = ({generalInfo, onChangeGeneralInfo,}) => {
    const {t} = useTranslation();
    const {data: {deviceName, macAddress, ipAddress, deviceModelName, applicationVersion}, changed, errors, responseWarning} = generalInfo || {};
    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_SID_BASIC_INFORMATION')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['deviceName'] === true,
                                'response_warning': !isNil(get(responseWarning, 'deviceName'))})}>{t('TABLE_DEVICE_NAME_P')}</th>
                            <td colSpan='2'>
                                <TextInput width={200} propertyName='deviceName' errorWidth={530}
                                           value={deviceName || ''} error={errors['deviceName']}
                                           onChange={onChangeGeneralInfo} maxLength={50}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_LICENSE_MAC')}</th>
                            <td colSpan='2'>{macAddress}</td>
                        </tr>
                        <tr>
                            <th>{t('TABLE_IP_ADDR_P')}</th>
                            <td colSpan='2'>{ipAddress}</td>
                        </tr>
                        <tr>
                            <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                            <td colSpan='2'>{deviceModelName}</td>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_LFD_FIRMWARE_VER')}</th>
                            <td colSpan='2'>{applicationVersion}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const isDeviceTypeVersionOtherThanOne = deviceVersion => {return (deviceVersion === 2 || deviceVersion === 3 || deviceVersion === 4)};

const EditFlipGeneral = ({deviceTypeVersion, displayInfo, onChangeDisplayInfo}) => {
    const {t} = useTranslation();
    const {data: {basicPower, cleanupUserData, cleanupUserDataInterval, autoSave, autoPowerOff}, changed, error, responseWarning} = displayInfo || {};

    const onChangeChecked = e => {
        if (e.target.checked) {
            onChangeDisplayInfo(e, 0);
        } else {
            onChangeDisplayInfo(e, 1);
        }
    };

    const autoPowerOffList = [
        {value: 0, 'title': 'BUTTON_OFF'},
        {value: 1, 'title': '4 ' + t('COM_SID_HOURS')},
        {value: 2, 'title': '6 ' + t('COM_SID_HOURS')},
        {value: 3, 'title': '8 ' + t('COM_SID_HOURS')},
        {value: 4, 'title': '16 ' + t('COM_SID_HOURS')}
    ];

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_TEXT_GENERAL_P')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['basicPower'],
                                'response_warning': !isNil(get(responseWarning, 'basicPower'))})}>{t('TABLE_POWER_P')}</th>
                            <td colSpan='2'>
                                <Checkbox id='basicPower' propertyName='basicPower' value={basicPower === 0}
                                          onChange={onChangeChecked} name={t('BUTTON_OFF')}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['cleanupUserData'],
                                'response_warning': !isNil(get(responseWarning, 'cleanupUserData'))})}>{t('COM_BUTTON_DELETE_ALL_P')}</th>
                            <td colSpan='2'>
                                <Checkbox id='cleanupUserData' propertyName='cleanupUserData'
                                          value={cleanupUserData === 1} onChange={onChangeChecked}
                                          name={t('COM_BUTTON_DELETE')}/>
                            </td>
                        </tr>
                        {
                            isDeviceTypeVersionOtherThanOne(deviceTypeVersion) &&
                            <tr>
                                <th className={classNames({'changed': changed['cleanupUserDataInterval'],
                                    'response_warning': !isNil(get(responseWarning, 'cleanupUserDataInterval'))})}>{t('COM_LFD_SID_FLIP_PREIOD_FOR_DELETE_ALL')}</th>
                                <td colSpan='2'>
                                    <Select width={200} propertyName='cleanupUserDataInterval'
                                            selects={deviceOptions.flipDeletePeriod} value={cleanupUserDataInterval}
                                            onChange={onChangeDisplayInfo}/>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th className={classNames({'changed': changed['autoSave'],
                                'response_warning': !isNil(get(responseWarning, 'autoSave'))})}>{t('COM_LFD_SID_PENSEE_AUTO_SAVE')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='autoSave' selects={deviceOptions.osdOptions}
                                            value={autoSave} onChange={onChangeDisplayInfo}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['autoPowerOff'],
                                'response_warning': !isNil(get(responseWarning, 'autoPowerOff'))})}>{t('COM_TV_SID_AUTO_POWER_OFF_MENU')}</th>
                            <td colSpan='2'>
                                <Select width={200} propertyName='autoPowerOff' selects={autoPowerOffList}
                                        value={autoPowerOff} onChange={onChangeDisplayInfo}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipSecurity = ({securityInfo, onChangeSecurityInfo}) => {
    const {t} = useTranslation();
    const {data: {miscBlockNetworkConnection, miscBlockUsbPort, captureLock, screenMonitoringLock /*, remoteControlServerLock*/}, changed, errors, responseWarning} = securityInfo || {};
    const blockUsbList = [
        {value: 0, 'title': 'BUTTON_OFF'},
        {value: 1, 'title': 'TEXT_ON_P'},
        {value: 2, 'title': 'COM_EBD_SID_MEETINGROOM_READ_ONLY'}
    ];

    const onChangedRadio = (e, value) => {
        onChangeSecurityInfo(e, value);
    };

    const onChangeScreenMonitoringLock = (e, value) => {
        onChangeSecurityInfo(e, value);
        /*if(value == 1 && remoteControlServerLock != 1)
            onChangeSecurityInfo({'data-name': 'remoteControlServerLock'}, 1);*/
    }

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_SID_SECURITY')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['miscBlockNetworkConnection'],
                                'response_warning': !isNil(get(responseWarning, 'miscBlockNetworkConnection'))})}>{t('MIS_SID_NW_LOCK')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='miscBlockNetworkConnection'
                                            selects={deviceOptions.osdOptions}
                                            value={miscBlockNetworkConnection} onChange={onChangedRadio}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['miscBlockUsbPort'],
                                'response_warning': !isNil(get(responseWarning, 'miscBlockUsbPort'))})}>{t('MIS_SID_USB_LOCK')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='miscBlockUsbPort' selects={blockUsbList}
                                            value={miscBlockUsbPort} onChange={onChangedRadio}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['captureLock'],
                                'response_warning': !isNil(get(responseWarning, 'captureLock'))})}>{t('COM_LFD_SID_FLIP_PIP_CAPTURE')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='captureLock' selects={deviceOptions.osdOptions}
                                            value={captureLock} onChange={onChangedRadio}/>
                            </td>
                        </tr>
                        {
                            !isNil(screenMonitoringLock) &&
                            <tr>
                                <th className={classNames({'changed': changed['screenMonitoringLock'],
                                    'response_warning': !isNil(get(responseWarning, 'screenMonitoringLock'))})}>{t('COM_LFD_SID_TIZEN_CADEC_SCREEN_MONITORING_LOCK')}</th>
                                <td colspan="2">
                                    <RadioGroup propertyName='screenMonitoringLock' selects={deviceOptions.onOffOptions} value={screenMonitoringLock} 
                                        onChange={onChangeScreenMonitoringLock} />
                                </td>
                            </tr>
                        } 
                        {
                            /*!isNil(remoteControlServerLock) &&
                            <tr>
                                <th className={classNames({'changed': changed['remoteControlServerLock'],
                                    'response_warning': !isNil(get(responseWarning, 'remoteControlServerLock'))})}>{t('MIS_SID_CBFEB_REMOTE_CONTROL_LOCK')}</th>
                                <td colspan="2">
                                    <RadioGroup propertyName='remoteControlServerLock' selects={deviceOptions.onOffOptions} value={remoteControlServerLock} 
                                        onChange={onChangeSecurityInfo} disabled={screenMonitoringLock == 1} />
                                </td>
                            </tr>*/
                        }                                               
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipAdvancedSetting = ({setupInfo, displayInfo, onChangeSetupInfo, onChangeDisplayInfo}) => {
    const {t} = useTranslation();
    const [proxy, setProxyInfo] = useState({use: '0', ip: '', port: '', id: '', password: ''});
    const {data: {proxySetting}, changed, errors, responseWarning} = setupInfo || {};
    const {data: {smtp, printServer}, changed:displayChanged, errors:displayErrors, responseWarning:displayResponseWarning} = displayInfo || {};
    const {use = '0', ip = "", port = "", id = "", password = ""} = proxy;

    useEffect(() => {
        if (setupInfo && proxySetting) {
            const proxyInfos = proxySetting.split(';');
            setProxyInfo({
                use: proxyInfos[0],
                ip: proxyInfos[1],
                port: proxyInfos[2],
                id: proxyInfos[3],
                password: proxyInfos[4],
            });
        }
    }, [setupInfo]);

    const onChangedUseProxy = ((e, value) => {
        const proxyInfo = value + ';;;;';
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedIPAddress = ((e, value) => {
        const proxyInfo = use + ';' + e.target.value + ';' + port + ';' + id + ';' + password;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedPort = ((e, value) => {
        const proxyInfo = use + ';' + ip + ';' + e.target.value + ';' + id + ';' + password;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedUserID = ((e, value) => {
        e.target.value = validateProxyPort(e.target.value,port);
        const proxyInfo = use + ';' + ip + ';' + port + ';' + e.target.value + ';' + password;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedPassword = ((e, value) => {
        const proxyInfo = use + ';' + ip + ';' + port + ';' + id + ';' + e.target.value;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangeChecked = e => {
        onChangeDisplayInfo(e, e.target.checked);
    };

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('TEXT_TITLE_ADVANCED_SETTING_P')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['proxySetting'],
                                'response_warning': !isNil(get(responseWarning, 'proxySetting'))})}>{t('COM_TEXT_PROXY_SETTING_P')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='proxySetting' selects={deviceOptions.proxyUsage}
                                            value={use} onChange={onChangedUseProxy}/>
                            </td>
                        </tr>
                        {proxy.use === '2' &&
                        <tr style={{display: 'table-row'}}>
                            <th></th>
                            <td colSpan='2'>
                                <table>
                                    <colgroup>
                                        <col width='170px'/>
                                        <col width=''/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td>{t('COM_MIS_TEXT_ADDRESS_P')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxySetting'
                                                                                value={ip || ''}
                                                                                error={errors['proxySetting']}
                                                                                onChange={onChangedIPAddress}/></form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('TABLE_PORT_P')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxySetting'
                                                                                value={port || ''}
                                                                                error={errors['proxySetting']}
                                                                                onChange={onChangedPort}/></form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('COM_DID_ADMIN_USER_USERID')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxySetting'
                                                                                value={id || ''}
                                                                                error={errors['proxySetting']}
                                                                                onChange={onChangedUserID}/></form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('TEXT_PASSWORD_P')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxySetting'
                                                                                type='password'
                                                                                value={password || ''}
                                                                                error={errors['proxySetting']}
                                                                                onChange={onChangedPassword}/></form>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        }
                        {
                        smtp && 
                        <>
                            <tr>
                                <th className={classNames({'changed': displayChanged['smtp']})}>{t('COM_TV_SID_EMAIL')}</th>
                                <td className={classNames({'changed': displayChanged['smtp.serverName'],
                                    'response_warning': !isNil(get(displayResponseWarning, 'smtp.serverName'))})}>{t('COM_EBD_TSC_SETTINGS_SMTPNAME')}</td>
                                <td><TextInput propertyName='smtp.serverName' onChange={onChangeDisplayInfo} value={smtp.serverName} maxLength={30}/></td>
                            </tr>
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': displayChanged['smtp.port'],
                                    'response_warning': !isNil(get(displayResponseWarning, 'smtp.port'))})}>{t('COM_EBD_TSC_SETTINGS_SMTPPORT')}</td>
                                <td>
                                    <input data-name={'smtp.port'}  type="number" width={190} maxLength={5} value={smtp.port} onChange={onChangeDisplayInfo}/>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': !isNil(displayChanged['smtp.ssl']),
                                    'response_warning': !isNil(get(displayResponseWarning, 'smtp.ssl'))})} colspan="2">
                                    <Checkbox id='smtp.ssl' propertyName='smtp.ssl' name={t('MIS_SID_CBFEB_SSL_CLS')} onChange={onChangeChecked} checked={smtp.ssl}/>
                                </td>
                            </tr>
                        </>
                        }
                        {
                        printServer && 
                        <>
                            <tr>
                                <th className={classNames({'changed': displayChanged['printServer']})}>{t('COM_EBD_SID_PRINT_SERVER')}</th>
                                <td className={classNames({'changed': displayChanged['printServer.ip'],
                                    'response_warning': !isNil(get(displayResponseWarning, 'printServer.ip'))})}>{t('COM_EBD_SETTINGSDLG_SERVERIP')}</td>
                                <td><TextInput propertyName='printServer.ip' onChange={onChangeDisplayInfo} value={printServer.ip} maxLength={30}/></td>
                            </tr>
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': displayChanged['printServer.port'],
                                    'response_warning': !isNil(get(displayResponseWarning, 'printServer.port'))})}>{t('COM_LFD_SID_FLIP_SERVER_PORT_NUMBER')}</td>
                                <td>
                                    <input data-name={'printServer.port'} type="number" width={190} maxLength={5} value={printServer.port} onChange={onChangeDisplayInfo}/>
                                </td>
                            </tr>
                        </>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipInformation = (props) => {
    return (
        <div className='edit_flip_device_wrap mt28'>
            <EditFlipBasic {...props} />
            <EditFlipGeneral {...props} />
            {
                isDeviceTypeVersionOtherThanOne(props.deviceTypeVersion) && <EditFlipSecurity {...props} />
            }
            <EditFlipAdvancedSetting {...props} />
            {
               isDeviceTypeVersionOtherThanOne(props.deviceTypeVersion) && <EditPinCode {...props} onChange={props.onChangeSetupInfo}/>
            }
        </div>
    );
};

export default EditFlipInformation;
