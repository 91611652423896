import React, { useState, useEffect } from "react";
import { rulesetService } from "../../../services";
import { useTranslation } from "react-i18next";
import MISDialog from "../MISDialog";
import "./PreviewPopup.css";
import PerfectScrollbar from "perfect-scrollbar";
import { Loading } from "../../loading/Loading";
import { useDispatch } from "react-redux";
import { popupAction } from "../../../actions";
import { commonConstants } from "../../../constants";
import {isEmpty} from "lodash";

const Preview = ({ condition, type, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = useState({        
        items: [],
        loading: false,
        isFetching: false,
        totalCount: 0,
        count: 0,
        pages: 0,
        filter : {
            startIndex: 0,
            pageSize: 30,
            type: condition.type,
            sign: condition.sign,
            value: condition.values[0].value,
            isInvert : condition.isInvert,
            groupIds: ["0"]
        }
    });
    
    const [end, setEnd] = useState(false);

    const [selectedGroup, setSelectedGroup] = useState([]);

    const fetchData = () => {
        
        setData((prevData) => {return {...prevData, loading: true}});        
        rulesetService
            .fetchConditionPreview(data.filter)
            .then(res => {
                setData((prevData) => {
                    return {
                    ...prevData,
                    loading: false,
                    isFetching: false,
                    items: prevData.items.concat(res.items),
                    count : prevData.count + 1,
                    totalCount: res.totalCount,
                    pages: Math.ceil(res.totalCount / data.filter.pageSize)
                }});
            });
    };

    const getCheckedDeviceGroupKeys = (groups) => {
        let selectedGroupIds = [];
        if(!isEmpty(groups)){
            groups.map((group) => {
                selectedGroupIds.push(group.groupId);
            })
        }
        return selectedGroupIds;
    }


    const openDeviceGroupPopup = () => {
        
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            allEnabled: true,
            save: saveGroup,
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
            checkbox: true,
            selectAll: true,
            selected : selectedGroup,
            checkedKeys : getCheckedDeviceGroupKeys(selectedGroup)
        }));
    };

    const saveGroup = (groups) => {        
        if (groups && groups.length > 0) {
            dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
            const groupIds = groups.map(group => String(group.groupId));            
            setData({        
                items: [],
                loading: false,
                isFetching: false,
                totalCount: 0,
                count: 0,
                pages: 0,
                filter : {...data.filter, groupIds : groupIds}
            });        
      
            setSelectedGroup(groups);
            getDeviceGroupButtonText(); 
        }
    };

   const getDeviceGroupButtonText = () => {
        
        if(selectedGroup && selectedGroup.length > 0) {
            let selectedDeviceGroupText = "";
            for(var i = 0; i < selectedGroup.length; i++) {
                if(i > 0) {
                    selectedDeviceGroupText += ", ";
                }
                if(selectedGroup[i].groupNameText!==undefined){
                    selectedDeviceGroupText += selectedGroup[i].groupNameText;
                }else{
                    return t("TEXT_ALL_P");
                }                
            }

            return selectedDeviceGroupText;
        }
        else {
            return t("TEXT_ALL_P");
        }
    }

    useEffect(()=>{
        const ps = new PerfectScrollbar('#ruleset_preview_scroll_wrap', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
            suppressScrollX: true
        });
        document.querySelector('#ruleset_preview_scroll_wrap').addEventListener('ps-y-reach-end', () => {
            setEnd(true);
        });
    }, [])


    useEffect(()=>{
        if (data !== undefined && !data.loading && !data.isFetching && data.items !== undefined && data.items.length === 0 && data.count < 1) {
            fetchData();
        }
        if (data !== undefined && !data.loading && data.isFetching) {
            fetchData();
        }
    }, [data])

    useEffect(()=> {
        if (end) {
            setData({
                ...data,
                filter: {
                    ...data.filter,
                    startIndex: data.filter.startIndex + 25
                },
                isFetching: true
            });

            setEnd(false);
        }
    }, [end])

    return (
        <MISDialog
            dialog={{
                title: t("COM_DID_VWL_PREVIEW"),
                modal: true,                
                closeOnEscape: true,
               // width: 800,
                height: 350,
                // position: { x: -350, y: -200 },
                onClose: onClose
            }}
            buttons={{
                rightButtons:  [
                    {
                        id: "RULESET_CONDITION_PREVIEW_POPUP",
                        title: t("COM_BUTTON_CLOSE_P"),
                        onClick: onClose
                    }
                ]
            }}
        >
            <div className="ruleset_preview_detail_view_wrap"> 
                <div class="float_l">
                    <span className="detail_table_title mt10">
                        {t("MIS_SID_TARGET_DEVICE")}
                        {" : "}<span>{data.totalCount}</span>
                    </span>
                </div>
                <div class="float_r" style={{display:'inline'}}>
                    <span className="detail_table_title mt10">
                        {t("TEXT_DEVICE_GROUP_P")}                
                    </span>
                    <div className="menu_select_box">
                    <input type="hidden" id="previewDeviceGroupId"/>
                    <button className="base un_radius select" style={{width:200, borderRadius: '5px', marginLeft:'10px'}} id="previewDeviceGroupBtn" onClick={openDeviceGroupPopup}>
                        <span>
                            <span style={{width:'160px'}} title={getDeviceGroupButtonText()}>{getDeviceGroupButtonText()}</span>
                            <span className="arrow"></span>                        
                        </span>
                    </button>
                    </div>                
                </div>
            </div>
            <div className="ruleset_preview_selected_item_box">
                <div className="ruleset_preview_pop_list mt9">
                    <div id="ruleset_preview_scroll_wrap"
                        style={{
                            height: "365px",
                            borderTop: "1px solid #e7e7e7",
                            display: "block" ,
                            position:"relative"                                                                                                 
                        }}
                    >            
                        <table style={{tableLayout:"fixed"}}>
                            <thead>                            
                                <tr>                                
                                    <th style={{ width: "300px" }}>
                                        <span>{t("TEXT_DEVICE_GROUP_P")}</span>
                                    </th>
                                    <th style={{ width: "300px" }}>
                                        <span>{t("TABLE_DEVICE_NAME_P")}</span>
                                    </th>                 
                                    <th style={{ width: "300px" }}>
                                        <span>{t("COM_SID_IP_ADDRESS")}</span>
                                    </th>
                                    { condition.type === "dev_mdnm" &&
                                        <th style={{ width: "150px" }}>
                                            <span>{t("TABLE_DEVICE_MODEL_NAME_P")}</span>
                                        </th>
                                    }
                                    { condition.type === "screen_rotation"&&
                                        <th style={{ width: "150px" }}>
                                            <span>{t("TEXT_SCREEN_ROTATION_P")}</span>
                                        </th>
                                    }
                                    {condition.type === "screen_size" && 
                                        <th style={{ width: "150px" }}>
                                            <span>{t("TABLE_SCREEN_SIZE_P")}</span>
                                        </th>
                                    }                                         
                                </tr>
                            </thead>
                            <tbody>                                       
                            {data.loading && <tr><td colSpan="4" style={{width:"900px"}}><div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div></td></tr>}                           
                            {!data.loading && data.items.length === 0 &&
                                <tr><td colSpan="4" style={{width:"900px"}}>{t("MIS_SID_NO_ITEMS")}</td></tr>
                            }                            
                            {data.items.map((item, index) => (
                                <tr>                                                                       
                                    <td style={{ width: "300px" }}>
                                        <span>{item.deviceGroupName}</span>
                                    </td>                                        
                                    <td style={{ width: "300px" }}>
                                        <span>{item.deviceName}</span>
                                    </td>                 
                                    <td style={{ width: "300px" }}>
                                        <span>{item.ipAddress}</span>
                                    </td>
                                    {condition.type === "screen_rotation" &&
                                        <td style={{ width: "150px" }}> 
                                            <span>{item.screenRotation==="0"?t("COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE"):t("COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT")}</span>
                                        </td> 
                                    }
                                    { condition.type === "screen_size" && 
                                        <td style={{ width: "150px" }}>
                                            <span>{item.screenSize}</span>
                                        </td>
                                    }
                                    {condition.type === "dev_mdnm" &&
                                        <td style={{ width: "150px" }}>
                                            <span>{item.deviceModelName}</span>
                                        </td>
                                    }        
                                </tr>
                            ))}                          
                            </tbody>  
                                                  
                      </table>  
                    </div>               
                </div>
            </div>
        </MISDialog>
    );
};

export default Preview;
