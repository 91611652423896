import React from 'react'
import PDFIcon from '../../../images/icon/file_type_icon_05.png';
import classNames from "classnames";

const PDF = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={PDFIcon}/>
            {
                !onlyIcon && 'PDF'
            }
        </span>
    )
}
export default PDF;