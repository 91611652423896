import React, {useImperativeHandle, useRef, useState} from 'react';
import { DropTarget  } from 'react-dnd';
import {ItemTypes} from "./ItemTypes";
import {convertAddedContent} from "./playlistUtil";
import {RANDOM} from "../../constants";

const DroppableSpace = React.forwardRef(({connectDropTarget, index, children, isOver, isOverCurrent, canDrop, mode}, ref) => {
    const elementRef = useRef(null);

    useImperativeHandle(ref, ()=> ({
        getNode: () => elementRef.current,
    }));

    const boxStyle = isOverCurrent && canDrop ? {height: 86, width: 86, border: '3px solid #dde0e5',
            backgroundImage: 'linear-gradient(top, red, red 70%, transparent 70%, transparent 100%)'}
        : {width: 10, height: 86, float:'left'};

    return connectDropTarget(
        <div ref={elementRef} style={boxStyle}>
        </div>);
})

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        dropResult: monitor.getDropResult(),
    }
}
export default DropTarget(
    [
        ItemTypes.ContentItem,
        ItemTypes.PlaylistItem,
        ItemTypes.TagItem,
    ],
    {
        drop(props, monitor, component) {
            if (!monitor.getItem().dragItem) {
                return;
            } else {
                if (monitor.getItemType() === ItemTypes.ContentItem) {
                    const index = props.position === 'prev' ? props.index -1: props.index;
                    props.onDrop(convertAddedContent(monitor.getItem().dragItem), index)
                } else {
                    if (props.mode === RANDOM) {
                        return null
                    }
                    const index = props.position === 'prev' ? props.index: props.index + 1;
                    if (!component) {
                        return null
                    }
                    const dragIndex = monitor.getItem().index;
                    let hoverIndex = index;
                    if (dragIndex < hoverIndex) {
                        hoverIndex = hoverIndex - 1;
                    }
                    if (dragIndex === hoverIndex) {
                        return
                    }
                    props.moveItem(dragIndex, hoverIndex);
                    monitor.getItem().index = hoverIndex;
                }

                component.getNode().style.width = '10px';
                component.getNode().style.border = '';
            }
        },
        canDrop(props, monitor) {
            const item = monitor.getItem().dragItem;
            return item ? true: false;
        }
    }, collect)(DroppableSpace);