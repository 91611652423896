import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../radio/RadioGroup";
import Preview from "./Preview";

const ScreenRotation = ({ handleValues, orgValues }) => {
    const [rotation, setRotation] = useState(orgValues ? orgValues[0].value : 0);

    const { t } = useTranslation();

    const onChange = (e, value) => {
        setRotation(value);
        const newValue = [
            {
                value: value
            }
        ];
        handleValues(newValue);
    };

    return (
        <table className="dev_ip">
            <tr id="operator">
                <th
                    style={{ width: "130px", verticalAlign: "top", paddingTop: "10px" }}
                >
                    {t("MIS_SID_OPERATOR")}
                </th>
                <td>
                    <RadioGroup
                        propertyName={"rotation"}
                        selects={[
                            {
                                title: t("COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE"),
                                value: 0
                            },
                            {
                                title: t("COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT"),
                                value: 270
                            }
                        ]}
                        value={rotation}
                        onChange={onChange}
                        multiLang={true}
                    />
                </td>
            </tr>
        </table>
    );
};

export default ScreenRotation;
