import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../actions';
import './RecoveryPage.css';
import Checkbox from "../../components/checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import LOGO_SIGNIN from '../../images/img/logo_signin.png';
import Bullet from '../../images/icon/bullet_2.png';
import {LoadingPopup} from "../../components/loading/LoadingPopup";
import ResetPopup from "../../components/popup/login/ResetPopup";
import OtpSetupPopup from "../../components/popup/login/OtpSetupPopup";
import OtpLoginPopup from "../../components/popup/login/OtpLoginPopup";
import Select from "../../components/selectbox/Select";
import TextInput from "../../components/input/TextInput";
import WhiteButton from '../../components/button/WhiteButton';
import {get, isEmpty, isNil, isObject, set} from 'lodash';
import {userService} from "../../services/user.service";
import {toastr} from 'helper/toastrIntercept'
import {checkemail, createQuery2} from "../../helper";
import {userValidator} from "../../helper/userValidator";
import {getMixString} from "../../language/languageUtils";
import {getErrorMessage} from "../../helper/responseHandler";
import {settingService, systemService} from "../../services";
import PrivacyPolicyFooter from "../../components/link/PrivacyPolicyFooter";
import {Loading} from "../../components/loading/Loading";

const RecoveryPage = (props) => {
    const {t} = useTranslation();
    const [baseTable, setBaseTable] = useState();
    const [targetTable, setTargetTable] = useState();
    const [viewDbSchemaDetail, setViewDbSchemaDetail] = useState(false);
    const [viewIssueDetail, setViewIssueDetail] = useState(false);
    const [loadingDbSchema, setLoadingDbSchema] = useState();
    const [issues, setIssues] = useState([]);

    useEffect(() => {
        setLoadingDbSchema(true);
        systemService.fetchDatabaseCheckingItems().then(res => {
            const checkingResult = res && res.items && res.items.filter(item => item.checkResult === false);
            const dbCompareResult = checkingResult.filter(r => r.testId === 10000);
            if(dbCompareResult && dbCompareResult.length > 0){
                const resultJson = JSON.parse(dbCompareResult[0].description)
                resultJson && resultJson.db_integrity && setBaseTable(resultJson.db_integrity.base);
                resultJson && resultJson.db_integrity && setTargetTable(resultJson.db_integrity.target);
            }
            
            const issueCheckResult = checkingResult.filter(r => r.testId !== 10000);
            if(issueCheckResult && issueCheckResult.length > 0){
                setIssues(issueCheckResult);
            }
        }).catch(e => {
            toastr.error(getErrorMessage(e));
        }).finally(() => {
            setLoadingDbSchema(false);
        });
    }, []);

    const toggleDbSchemaDetail = () => {
        setViewDbSchemaDetail(!viewDbSchemaDetail);
        if(viewDbSchemaDetail === false && viewIssueDetail === true){
            setViewIssueDetail(false);
        }
    }

    const toggleIssueDetail = () => {
        setViewIssueDetail(!viewIssueDetail);
        if(viewIssueDetail === false && viewDbSchemaDetail === true){
            setViewDbSchemaDetail(false);
        }
    }

    const executeQuery = testId => {
        systemService.solveDatabaseCheckingItems(testId).then(res => {
            if(res.status === 'Success'){
                toastr.success(testId + " : Solved");
                const filtered = issues.filter(issue => issue.testId !== testId);
                setIssues(filtered);
            } else {
                toastr.error(testId + " : Failed");
            }
        });
    }

    useEffect(() => {
        (issues.length <= 0) && setViewIssueDetail(false);
    }, [issues]);

    return (
        <div className="container">
            <div className="recovery_title">
                <h1>Recovery Page for MagicINFO Server</h1>
            </div>
            <div className="recovery_item" style={viewIssueDetail?{height:'500px'}:undefined}>
                <h1>Checking Known Issues of MagicINFO Database</h1>
                {
                    loadingDbSchema && <Loading isLoading={loadingDbSchema}/>
                }
                {
                    !loadingDbSchema && (issues.length <= 0) && 
                    <div className="description normal">No problem.</div>
                }
                {
                    !loadingDbSchema && (issues.length > 0) && 
                    <>
                        <div className="description error">Some problems have been found. Please solve the problem through technical support.</div>
                        <div>
                            <WhiteButton name={viewIssueDetail ? "Hide Detail" : "Show Detail"} onClick={toggleIssueDetail} disable={false}/>
                        </div>                    
                    </>
                }
                {
                    viewIssueDetail && 
                    <div className="recovery_table_container">
                        <div className="">
                            {
                                issues && issues.length > 0 && 
                                <MiIssue issues={issues} executeQuery={executeQuery}/>
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="recovery_item" style={viewDbSchemaDetail?{height:'500px'}:undefined}>
                <h1>Checking MagicINFO Database Integrity</h1>
                <div className="description">Comparing result between your database and reference. </div>
                {
                    loadingDbSchema && <Loading isLoading={loadingDbSchema}/>
                }
                {
                    !loadingDbSchema && (!baseTable || baseTable.length <= 0) && 
                    <div className="description normal">No problem.</div>
                }
                {
                    !loadingDbSchema && baseTable && baseTable.length > 0 && 
                    <>
                        <div className="description error">Some problems have been found. Please solve the problem through technical support.</div>
                        <div>
                            <WhiteButton name={viewDbSchemaDetail ? "Hide Detail" : "Show Detail"} onClick={toggleDbSchemaDetail} disable={false}/>
                        </div>
                    </>
                }
                {
                    viewDbSchemaDetail && 
                    <div className="recovery_table_container">
                        <div className="recovery_table_wrapper">
                            <h2>Reference</h2>
                            {
                                baseTable && baseTable.length > 0 && baseTable.map(tab => (
                                    <MiTable tab={tab}/>
                                ))
                            }
                        </div>
                        <div className="recovery_table_wrapper">
                            <h2>Yours</h2>
                            {
                                targetTable && targetTable.length > 0 && targetTable.map(tab => (
                                    <MiTable tab={tab}/>
                                ))
                            }
                        </div>                                    
                    </div>
                }

            </div>
        </div>
    );
}


const MiTable = ({tab}) => {
    if(!tab) return null;
    return (
        <>
            <div className={"database_table"}>
                <div>Table Name : {tab.table.toUpperCase()}</div>
                <table>
                    <colgroup>
                        <col width="250px"/>
                        <col width="250px"/>
                        <col width="250px"/>
                    </colgroup>
                    <tr>
                        <th>Column Name</th>
                        <th>Data Type</th>
                        <th>Length</th>
                    </tr>
                    {
                        tab && tab.columns && tab.columns.map(col => {
                            return (
                                <tr>
                                    <td>{col.column}</td>
                                    <td>{col.dataType}</td>
                                    <td>{col.length}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </>
    )
};

const MiIssue = ({issues, executeQuery}) => {
    return (
        <>
            <div className={"database_table"}>
                <div>Issues Detail</div>
                <table>
                    <colgroup>
                        <col width="150px"/>
                        <col width="350px"/>
                        <col width="550px"/>
                        <col width="100px"/>
                    </colgroup>
                    <tr>
                        <th>Issue No.</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Execute</th>
                    </tr>
                    {
                        issues && issues.map(issue => {
                            return (
                                <tr>
                                    <td>{issue.testId}</td>
                                    <td>{issue.title}</td>
                                    <td>{issue.description}</td>
                                    <td><WhiteButton name={"Execute"} onClick={() => executeQuery(issue.testId)} disable={false}/></td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </>
    )
}

export default RecoveryPage;