import React, {Component} from "react";
import {DropTarget} from "react-dnd";
import './SyncPlaylistEditor.css';
import {ItemTypes} from "../ItemTypes";
import cloneDeep from "lodash/cloneDeep";

class FrontSpace extends Component {
    render() {
        let {connectDropTarget, isOverCurrent, canDrop} = this.props
        return connectDropTarget(
            <div className='sync-playlists-group-item-front'>
                {canDrop && isOverCurrent && <div className="sync-playlists-group-item-box"/>}
                {canDrop && isOverCurrent && <div className="sync-playlists-group-item-next-button"/>}
            </div>
        );
    }
}

const drop = {
    drop(props, monitor, component) {
        const didDrop = monitor.didDrop()
        if (didDrop) {
            return;
        }
        const item = monitor.getItem().dragItem;
        props.onAddItem(props.groupIndex, props.itemIndex, cloneDeep(item));
    },
    canDrop(props, monitor) {
        const item = monitor.getItem().dragItem;
        if(item.itemIndex === undefined || item.groupIndex === undefined) {
            return true;
        }
        if(item.groupIndex >= 0 && props.groupIndex !== item.groupIndex) {
            return false;
        }
        if(props.itemIndex === item.itemIndex) {
            return false;
        }
        return true;
    }
}

const collect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        dropResult: monitor.getDropResult(),
    }
};
export default DropTarget(ItemTypes.ContentItem, drop, collect)(FrontSpace);