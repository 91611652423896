import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toastr} from 'helper/toastrIntercept';
import {commonConstants, userConstants} from "../../../constants";
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import {userService} from "../../../services";
import AddUserDetailedInformation from './AddUserDetailedInformation';
import {checkPostValidation, checkPreValidation, handleChange, updateDetailed} from './common/userCommons';

const useUserInfo = (initialInfo, t) => {
    const [userInfo, setUserInfo] = useState(initialInfo);

    const onChangeUserInfo = (e, value) => {
        const newInfo = handleChange(e, value, userInfo, t);
        setUserInfo(newInfo);
    };

    const updateUserInfoPartially = (params) => {
        const newInfo = updateDetailed(userInfo, params);
        setUserInfo(newInfo);
    };

    const checkPreValidationAddUser = () => {
        checkPreValidation(userInfo, setUserInfo, t, true);
    }

    return [userInfo, setUserInfo, updateUserInfoPartially, onChangeUserInfo, checkPreValidationAddUser];
};

const createUpdateInfo = ({dataField}, t) => {
    const updateInfo = {userInfo: {}};
    for (let fieldName in dataField) {
        switch (fieldName) {
            case 'email':
            case 'jobPosition':
            case 'mobileNum':
            case 'password':
            case 'phoneNum':
            case 'team':
            case 'userId':
            case 'userName':
                updateInfo.userInfo[fieldName] = dataField[fieldName];
                break;
            case 'groupName':
            case 'organizationName':
                updateInfo[fieldName] = dataField[fieldName] === t('TEXT_ROOT_GROUP_NAME_P') ? userConstants.ORGAN_NAME_ADMINISTRATORS : dataField[fieldName];
                break;
            //propName unnecessary for api
            case 'isNickNameEnabled':
                continue;
            default:
                updateInfo[fieldName] = dataField[fieldName];
        }
    }
    return updateInfo;
};

const AddUserPopup = ({onClickYes, onClose, addPopup, closePopup, organizationId, groupId}) => {
    const {t} = useTranslation();
    const timer = useRef(null);
    const [userInfo, setUserInfo, updateUserInfoPartially, onChangeUserInfo, checkPreValidationAddUser] = useUserInfo({
        dataField: {
            userId: '',
            password: '',
            confirmPassword: '',
            userName: '',
            email: '',
            mobileNum: '',
            phoneNum: '',
            organizationId: organizationId,
            organizationName: '',
            groupId: groupId,
            groupName: '',
            roleId: userConstants.ROLE_ID_ADMINISTRATORS,
            roleName: userConstants.ROLE_NAME_ADMINISTRATOR,
            organizationGroupName: '',
            team: '',
            jobPosition: '',
            ldapFullId: '',
            ldapSimpleId: '',
            nickname: '',
            isNickNameEnabled: false
        },
        errors: {}
    }, t);

    useEffect(() => {
        return () => {
            if (timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const onClickSave = () => {
        let validateUserId = userInfo.dataField.isNickNameEnabled ? userInfo.dataField.nickname : userInfo.dataField.userId;

        if (validateUserId === '') {
            if (userInfo.dataField.isNickNameEnabled) {
                toastr.error(t('COM_TV_SID_ENTER_YOUR_NICK_NAME'));
            } else {
                toastr.error(t('MESSAGE_USER_ENTER_USER_ID_P'));
            }
            return;
        }

        userService.checkUserIdAvailability(validateUserId).then(res => {
            if (res.items.result === 0) {
                const {errors} = userInfo;
                if (userInfo.dataField.isNickNameEnabled) {
                    errors['nickname'] = t('MESSAGE_USER_ID_NOT_AVAILABLE_P').replace('<br>', ' ');
                } else {
                    errors['userId'] = t('MESSAGE_USER_ID_NOT_AVAILABLE_P').replace('<br>', ' ');
                }
                setUserInfo({...userInfo});
            } else {
                if (checkPreValidation(userInfo, setUserInfo, t)) {
                    return;
                }

                if (checkPostValidation(userInfo, t)) {
                    return;
                }

                addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});
                userService.saveUsers(createUpdateInfo(userInfo, t)).then(res => {
                    toastr.success(t('COM_TEXT_SUCCESS_P') + " - " + t('TEXT_TITLE_ADD_USER_P'));
                    const newInfo = {...userInfo, errors: {}};
                    setUserInfo(newInfo);
                    console.log(newInfo.errors['errorMessage']);
                }).catch(error => {
                    toastr.error(t(error.errorMessage));
                }).finally(() => {
                    closePopup(commonConstants.LOADING_POPUP);
                    onClickYes();
                    onClose();
                });
            }
        });
    };

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_TITLE_ADD_USER_P'),
                closeOnEscape: true,
                modal: true,
                width: 730,
                height: 680,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <AddUserDetailedInformation userInfo={userInfo} updateUserInfoPartially={updateUserInfoPartially} onChange={onChangeUserInfo} checkPreValidation={checkPreValidationAddUser}/>
        </MISDialog>
    );
};

AddUserPopup.defaultProps = {
    onSave: () => {
    },
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(AddUserPopup);