import React from 'react'
import SAPPIcon from '../../../images/icon/file_type_icon_16.png';
import classNames from "classnames";

const SAPP = ({onlyIcon = false, embedded = false}) => {
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={SAPPIcon}/>
            {
                !onlyIcon && 'SAPP'
            }
        </span>
    )
}
export default SAPP;