import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const SelectForEvent =  ({width, selects, value, optionStyle, onChange, defaultTitle, disabled, mode}) => {
    const {t} = useTranslation();
    const [showOptions, setShowOptions] = useState(false);


    const getTitle = (select) => {
        if (select) {
            if (select.compString === undefined || select.compString === null) {
                return select.compBoolean ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P")
            } else {
                return select.compString;
            }
        } else {
            if (selects !== undefined && selects.length > 0) {
                const found = selects.find(e => e.conditionIndex === value);
                if (found !== undefined) {
                    if (found.compString === undefined || found.compString === null) {
                        return found.compBoolean ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P")
                    } else {
                        return found.compString;
                    }
                }
            }
        }

        return defaultTitle;
    }

    const title = getTitle();


    const onSelect = e => {
        const selectedValue = e.target.getAttribute('data-value');
        if(selectedValue !== value) {
            onChange(e, selectedValue);
        }
    };

    const onClick = () => {
        if(showOptions) {
            setShowOptions(false);
        } else {
            setShowOptions(true);
        }
    };

    const onBlur = e => {
        setShowOptions(false);
    };



    return (
        <div className="select_input" style={{width}}>
            <button className={classNames('base', 'un_radius', 'select', {'on': showOptions})} onClick={onClick} onBlur={onBlur} disabled={disabled}>
                <span>{title}<span className="arrow"></span></span>
            </button>
            {
                showOptions &&
                <div className="option_list" style={optionStyle}>
                    <ul>
                        {
                            mode === 'start' && <li key={"-1"} data-value={-1} onMouseDown={onSelect}>{t("MIS_MESSAGE_EVENT_SELECT_START_CONDITION_P")}</li>
                        }
                        {
                            mode === 'end' && <li key={"-1"} data-value={-1} onMouseDown={onSelect}>{t("MIS_SID_20_AFTER_DISPLAY_DURATION")}</li>
                        }
                        {

                            selects.map(e => {
                                return (
                                    <li key={e.conditionIndex} data-value={e.conditionIndex} onMouseDown={onSelect}>{getTitle(e)}</li>
                                );
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    );
};

SelectForEvent.defaultProps = {
    width: 450,
    selects: [],
    value: '',
    multiLang: true,
    onChange: () => {},
    defaultTitle: undefined,
    disabled: false,
    mode: 'start'
};

export default SelectForEvent;