import React from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import MISDialog from './MISDialog';
import './CustomPopup.css';

const CustomPopup = ({title, message, width = 391, height = 233, position, onClose, buttons}) => {
    const {t} = useTranslation();
    return (
        <div className='alert_popup_wrap'>
            <MISDialog
                dialog={{
                    title,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width,
                    height,
                    position,
                    onClose
                }}
                buttons={buttons}>
                <div className='custom_popup_message_wrap'>
                    <span className='custom_popup_message_wrap_text'>{message}</span>
                </div>
            </MISDialog>
        </div>
    );
};
export default CustomPopup;