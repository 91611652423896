import React from 'react';
import KIOSK from '../../../images/icon/KIOSK.png';

const Kiosk = ({deviceTypeVersion, text, disabled}) => {
    let playerName = '';
    if (text) {
        playerName = 'Kiosk';
    }
    return(
        <span className={"device_icon"}>
            {(
                ()=>{
                    switch(deviceTypeVersion) {
                        case 1:
                            return <img src={KIOSK} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'KIOSK'} data-deviceTypeVersion={deviceTypeVersion}/>
                    }
                }
            )()}
            {playerName}
        </span>
    )
}
export default Kiosk