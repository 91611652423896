import React from 'react';
import {useTranslation} from "react-i18next";

const ContentUse = ({contentUse, contentUseOption, onClickContentUse}) => {
    const {t} = useTranslation();
    return (
        <div className="dev_filter_type_radio" id="filter_list_contentUsingStatus">
            <h5>
                <span className="contentUsingStatus" style={{background:'#ff85cd'}}></span>{t("MIS_SID_SERVER_CONTENT_USE")}</h5>
            <div>
                <ul className="type_checklist" style={{overflowY:'auto'}}>
                    <li rel="All" className={contentUse.value === contentUseOption[0].value ? "on" : ""} value="device_status_view_all"
                        onClick={onClickContentUse(contentUseOption[0])}>
                        <a href="#">{t("TEXT_ALL_P")}</a>
                    </li>
                    <li rel="Used" className={contentUse.value === contentUseOption[1].value ? "on" : ""} value="used_content"
                        onClick={onClickContentUse(contentUseOption[1])}>
                        <a href="#">{t("COM_SID_USED_KR_MIS20")}</a>
                    </li>
                    <li rel="Not Used" className={contentUse.value === contentUseOption[2].value ? "on" : ""} value="unused_content"
                        onClick={onClickContentUse(contentUseOption[2])}>
                        <a href="#">{t("MIS_SID_NOT_USED_KR_BLANK")}</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default ContentUse;