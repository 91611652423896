import React from 'react';
import './Chip.css';

const Chip = ({width, height, title, showDelete, onDelete, isWhiteBackground= false}) => {
    return (
        <div className='chip_wrap' style={{width, height, lineHeight: `${height}px`, backgroundColor: isWhiteBackground ? '#333c4e':'#eff2f6', border: isWhiteBackground ? '1px solid #D6DEE8':'none'}}>
            <span style={{color: isWhiteBackground ? '#dedede':'#686b72'}}>{title}</span>
            {showDelete && <span className='delete_btn' onClick={onDelete} />}
        </div>
    );
};

Chip.defaultProps = {
    width: 100,
    height: 25,
    title: '',
    showDelete: true,
};

export default Chip;