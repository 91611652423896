import React, {useEffect, useState} from 'react';
import {deviceService} from "../../services";
import Tree from "rc-tree/es";
import {withTranslation, useTranslation} from "react-i18next";
import {jsonToTreeForDevice} from '../../helper';
import {toastr} from 'helper/toastrIntercept';
import {Loading} from "../loading/Loading";
import './DeviceGroupTree.css';

const DeviceGroupTree = ({onSelect, allEnabled = false, useCheck = false, filterOptions = {}}) => {
    const {t} = useTranslation();
    const [state, setState] = useState({
        groups: [],
        selected: [],
        checkbox : useCheck,
        checkedKeys: [],
        expandedKeys: [],
        checkStrictly: false,
        selectedKeys: [],
        loading: false,
        checkWithChildren: false
    })

    const {loading, checkedKeys} = state;

    useEffect(() => {
        setState({
            ...state,
            loading: true
        })
        setState({...state, loading: true});
        getDeviceGroupData();
    }, []);
    
    const getDeviceGroupData = () => {
        deviceService.searchGroupByName({
            ...filterOptions,
            mode: 'general',
            searchText: '',
            allEnabled: allEnabled
        }).then(
            res => {
                if (res) {
                    const groups = jsonToTreeForDevice(res.items, checkedKeys, state.allowSelectOrganization);
                    setState({
                        ...state,
                        treeData: groups.treeData,
                        defaultCheckedKeys: groups.defaultCheckedKeys,
                        expandedKeys: groups.defaultCheckedKeys !== undefined ? groups.defaultCheckedKeys : [],
                        checkedKeys: checkedKeys !== undefined ? checkedKeys : groups.defaultCheckedKeys,
                        selected: groups.selected !== undefined ? groups.selected : checkedKeys,
                        loading: false
                    })
                } else {
                    toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                }
            }
        );
        
    }

    const findNode = (treeData, groupId) => {
        for(let i = 0; i < treeData.length; i++) {
            const treeNode = treeData[i];
            if(treeNode.groupId == groupId) {
                return treeNode;
            } else {
                if(treeNode.children != undefined){
                    const found = findNode(treeNode.children, groupId);
                    if(found) {
                        return found;
                    }
                }
            }
        }
    
        return null;
    };
    
    const removeInputNode = treeNode => {
        if(treeNode.children && treeNode.children.length > 0) {
            treeNode.children = treeNode.children.filter(node => !node.inputNode);
            treeNode.children.forEach(removeInputNode);
        }
    };

    const selectTreeNode = (tempGroupId, event) => {
        if (!state.checkbox) {

            let group = findGroup(state.treeData, parseInt(event.node.props.eventKey));

            if(group && group.groupId > -1) {
                setState({
                    ...state,
                    selected: [{...group}],
                    selectedKeys: [group.groupId.toString()]
                });
            }
            onSelect([group.groupId]);
        } else {
            const groupId = parseInt(event.node.props.eventKey);
            const {checkedKeys, selected} = state;
            if (checkedKeys !== undefined && checkedKeys.length > 0) {
                const found = checkedKeys.find(id => id === groupId);
                if (found !== undefined) {
                    setState({
                        ...state,
                        checkedKeys: checkedKeys.filter(id => id !== groupId),
                        selected: selected.filter(group => group.groupId !== groupId)
                    });
                } else {
                    setState({
                        ...state,
                        checkedKeys: checkedKeys.concat(groupId),
                        selected: selected.concat({groupId: groupId, groupName: event.node.props.groupName})
                    });
                }
            } else {
                setState({
                    ...state,
                    checkedKeys: [].concat(groupId),
                    selected: selected.concat({groupId: groupId, groupName: event.node.props.groupName})
                });
            }
            onSelect(checkedKeys);
        }
    };

    const findGroup = (groups, groupId) => {
        for(let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if(group.groupId === groupId) {
                return group;
            }
            if(group.children && group.children.length) {
                let childrenGroup = findGroup(group.children, groupId);
                if(childrenGroup && childrenGroup.groupId) {
                    return childrenGroup;
                }
            }
        }
    };

    const onExpand = (expandedKeys) => {
        // or, you can remove all expanded chilren keys.
        setState({
            ...state,
            expandedKeys
        });
    };

    const onCheck = (checkedKeys, e) => {
        const {checked} = e;
        let selectedGroupId = String(e.node.props.groupId);
        let {expandedKeys, checkedKeys : checkedKeysState} = state;
        if (state.checkbox && e.checkedNodes) {
            let {selected: groups} = state;
            if (state.checkWithChildren){
                let updatedCheckedKeys = checkedKeys.checked;
                if (!checked) {
                    groups = state.selected.filter(group => group.groupId !== parseInt(selectedGroupId, 10))
                    updatedCheckedKeys = updatedCheckedKeys.filter(groupId=> groupId !== parseInt(selectedGroupId, 10));
                } else {
                    selectedGroupId = parseInt(selectedGroupId);
                    function searchTree(element, groupId){
                        if(element.groupId == groupId){
                            return element;
                        }else if (element.children != null){
                            let i, result = null;
                            for(i=0; result == null && i < element.children.length; i++){
                                result = searchTree(element.children[i], groupId);
                            }
                            return result;
                        }
                        return null;
                    }
                    const findChildren = (group) => {
                        if (!group.disabled) {
                            groups.push(group);
                            expandedKeys.push(String(group.groupId));
                            updatedCheckedKeys.push(String(group.groupId));
                            if (group.children) {
                                for (const childGroup of group.children) {
                                    findChildren(childGroup);
                                }
                            }
                        }
                    }
                    for (const organizationGroup of state.treeData) {
                        const currentGroup = searchTree(organizationGroup, selectedGroupId);
                        if (currentGroup !== null) {
                            findChildren(currentGroup);
                            break;
                        }
                    }
                }
                setState({
                    ...state,
                    selected: groups,
                    checkedKeys : {
                        ...state.checkedKeys,
                        checked: updatedCheckedKeys
                    },
                    expandedKeys,
                });
            } else {
                groups = [];
                e.checkedNodes.map(node=> {
                    groups.push({groupId: Math.trunc(node.key), groupName: node.props.title, groupNameText: node.props.groupName});
                    expandedKeys.push(node.key);
                });
                setState({
                    ...state,
                    selected: groups,
                    checkedKeys,
                    expandedKeys,
                });
            }
            onSelect(checkedKeys.checked);
        }
    };

    return (
        <div className="group_tree pop_list" style={{overflowX:'auto',borderTop:'1px solid #e7e7e7', padding: 10}}>
            {
                loading === true &&
                <div style={{display: 'flex', justifyContent: 'center'}}><Loading sizes={10}/></div>
            }
            {
                state.treeData !== undefined &&
                <Tree
                    showIcon={true}
                    expandedKeys={state.expandedKeys}
                    onExpand={onExpand}
                    checkable={state.checkbox === undefined ? false : state.checkbox}
                    checkedKeys={checkedKeys}
                    onCheck={onCheck}
                    onSelect={(key, event)=>selectTreeNode(key, event)}
                    selectedKeys={state.selectedKeys}
                    selectable
                    treeData={state.treeData}
                    defaultCheckedKeys={state.defaultCheckedKeys}
                    checkStrictly={true}
                />
            }
        </div>
    )
    
}
export default withTranslation()(DeviceGroupTree);