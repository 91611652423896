import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import './EventManager.css';
import Checkbox from "../../../components/checkbox/Checkbox";
import Moment from "moment";
import NumberInput from "../../../components/input/NumberInput";
import {scheduleService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import Select from "../../../components/selectbox/Select";
import {scheduleAction} from "../../../actions";
import SelectForEvent from "../../../components/selectbox/SelectForEvent";
import ConfirmPopup from "../../../components/popup/ConfirmPopup";
import {menuAction} from "../../../actions/menu.action";
import SaveEventSchedulePopup from "../../../components/popup/schedule/event/SaveEventSchedulePopup";
import WhiteButton from "../../../components/button/WhiteButton";
import DateInput from "../../../components/input/DateInput";
import {scheduleConstants} from "../../../constants";
import {useMISOpt} from "../../../components/misopt";


const EventManager = ({mode}) => {

    const {misopt: {user : {timeFormat, dateFormat}}} = useMISOpt();

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [eventConditions, setEventConditions] = useState([]);

    const {newEvent, editEvent, currentContentId} = useSelector(state => ({
        newEvent : state.schedules.events.schedules.new,
        editEvent: state.schedules.events.schedules.edit,
        currentContentId : state.menu.currentContentId
    }));

    const [style, setStyle] = useState({
        condition: {
            startDateDisabled: true,
            stopDateDisabled: true,
            durationValueDisabled : true,
            hourValueDisabled: true,
            selectStartConditionDisabled: true,
            selectStopConditionDisabled: true
        }
    });

    const [showConfirmPopup, setShowConfirmPopup] = useState({
        show : false
    });

    const [showSavePopup, setShowSavePopup] = useState({
        show: false
    });

    let event = {};
    if (mode === 'new') {
        event = newEvent;
    } else {
        event = editEvent;
    }

    //const neverExpireChk = useRef();
    const contentConditionChk = useRef();
    const messageConditionChk = useRef();
    const channelConditionChk = useRef();

    const getDate = () => {
        const today = new Date();
        const year=today.getYear()+1900;
        const month=today.getMonth()+1;
        const day=today.getDay();
        const date=today.getDate();
        const hour=today.getHours();
        const minute=today.getMinutes();
        const second=today.getSeconds();
        return (year+"-"+month+"-"+date + " "+hour+":"+minute+":"+second);
    }

    const onSavePopup = () => {
        const doValid = () => {

            if (event.conditionList === undefined) {
                toastr.error();
                return false;
            }
            if (event.conditionList.length === 0) {
                toastr.error();
                return false;
            }

            for (const condition of event.conditionList) {
                if (condition.startConditionIndex < -1) {
                    toastr.error(t('MIS_MESSAGE_EVENT_SELECT_START_CONDITION_P'));
                    return false;
                }
                if (!condition.onContent && !condition.onMessage && !condition.onChannel) {
                    toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
                    return false;
                }
                if (condition.startConditionIndex === condition.endConditionIndex) {
                    toastr.error(t('MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_DUPLICATE_P'));
                    return false;
                }

                if (condition.endConditionIndex > 0) {
                    condition.duration = '';
                    condition.durationHour = '';
                    condition.durationMin = '';
                } else {
                    if ((condition.durationHour === '' && condition.durationMin === '') || (condition.durationHour < 1 && condition.durationMin < 1)) {
                        toastr.error(t('MESSAGE_SCHEDULE_DURATION_TIME_MORE_ZERO_P'));
                        return false;
                    }
                    const hour = condition.durationHour;
                    const min = condition.durationMin;
                    condition.duration = (hour * 3600) + (min * 60);
                }

                if (Moment(condition.startDate, 'YYYY-MM-DD').startOf('day').toDate() > Moment(condition.stopDate, 'YYYY-MM-DD').startOf('day').toDate()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P"));
                    return false;
                }
            }

            const usedConditionList = {};
            for (const condition of event.conditionList) {
                const {eventId, endConditionIndex, startConditionIndex} = condition;

                if (usedConditionList[eventId] === undefined) {
                    usedConditionList[eventId] = [];
                }
                if (usedConditionList[eventId].length > 0) {
                    const checkConditions = usedConditionList[eventId].some(
                        conditionId => {
                            if (endConditionIndex > 0) {
                                return conditionId === startConditionIndex || conditionId === endConditionIndex
                            }
                            return conditionId === startConditionIndex
                        }
                    )
                    if (checkConditions) {
                        toastr.error(t('MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_DUPLICATE_P'));
                        return false;
                    }

                }
                usedConditionList[eventId].push(startConditionIndex)
                if (endConditionIndex > 0) {
                    usedConditionList[eventId].push(endConditionIndex)
                }
            }

            return true;
        }
        if (doValid()) {
            setShowSavePopup({show: !showSavePopup.show});
        }

    }

    const closeTab = () => {
        if (mode === 'new') {
            dispatch(menuAction.removeTab('NEW_EVENT'));
        } else {
            dispatch(menuAction.removeTab('EDIT_EVENT'));
        }
    }

    const closeEventManager = () => {
        setShowConfirmPopup({show: true});
    }

    const cancelCloseTab = () => {
        setShowConfirmPopup({show: false});
    }

    const handleScheduleDate = (date, type) => {
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const dateToFormat = Moment(date).format('YYYY-MM-DD');
        if (type === 'start') {
            const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, startDate: dateToFormat} : condition)}
            dispatch(scheduleAction.updateEvent(mode, oneEvent));
        } else {
            const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, stopDate: dateToFormat} : condition)}
            dispatch(scheduleAction.updateEvent(mode, oneEvent));
        }
    }

    const handleEventCondition = (e, eventId) => {
        const currentEventConditionIndex = event.currentEventConditionIndex;
        scheduleService.fetchEventConditionById(eventId).then(
            res => {
                if (res.items && res.items.eventConditions) {
                    const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, eventId: eventId, duration: 0, endConditionIndex: 0, onChannel: false, onContent: false, onMessage: false, startConditionIndex: 0, durationHour: 0, durationMin: 0} : condition)};
                    oneEvent.selectedEventList[currentEventConditionIndex].eventInfo = res.items.eventConditions;
                    dispatch(scheduleAction.updateEvent(mode, oneEvent));                   //neverExpireChk.current.disabled = false;
                }
            }
        )
    }

    const handleStartCondition = (e, conditionIndex) => {
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, startConditionIndex: parseInt(conditionIndex, 10), onChannel: false, onContent: false, onMessage: false} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));
    }

    const handleStopCondition = (e, conditionIndex) => {
        const currentEventConditionIndex = event.currentEventConditionIndex;
        let oneEvent;
        if (conditionIndex < 0) {
            oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, endConditionIndex: parseInt(conditionIndex, 10)} : condition)};
        } else {
            oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, endConditionIndex: parseInt(conditionIndex, 10), duration: 0, durationHour: 0, durationMin: 0} : condition)};
        }
        dispatch(scheduleAction.updateEvent(mode, oneEvent));

    }

    const handleContent = (e) => {
        const checked = e.target.checked;
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, onContent: checked} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));
    }

    const handleMessage = (e) => {
        const checked = e.target.checked;
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, onMessage: checked} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));
    }

    const handleChannel = (e) => {
        const checked = e.target.checked;
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, onChannel: checked} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));
    }

    const handleNeverExpire = () => {
        //const checked = neverExpireChk.current.checked;

        let stopDate;
        if (event.conditionList[event.currentEventConditionIndex].stopDate === '2999-12-31') {
            stopDate = Moment().format('YYYY-MM-DD');
        } else {
            stopDate = '2999-12-31';
        }
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, stopDate: stopDate} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));
    }

    const updateStyle = () => {

        const updateStyle = style;

        let oneEvent;
        if (mode === 'new') {
            oneEvent = newEvent;
        } else {
            oneEvent = editEvent;
        }
        const currentEventConditionIndex = oneEvent.currentEventConditionIndex;


        if (oneEvent.conditionList[currentEventConditionIndex].eventId !== undefined && oneEvent.conditionList[currentEventConditionIndex].eventId !== '') {

            //neverExpireChk.current.disabled = false;
            updateStyle.condition.selectStartConditionDisabled = false;
            updateStyle.condition.selectStopConditionDisabled = false;
            updateStyle.condition.startDateDisabled = false;
            updateStyle.condition.stopDateDisabled = false;
        }

        else {
            updateStyle.condition.selectStartConditionDisabled = true;
            updateStyle.condition.selectStopConditionDisabled = true;
            updateStyle.condition.startDateDisabled = true;
            updateStyle.condition.stopDateDisabled = true;
        }

        /*if (oneEvent.conditionList[currentEventConditionIndex].stopDate === '2999-12-31') {
            updateStyle.condition.stopDateDisabled = true;
            neverExpireChk.current.checked = true;
        }*/

        //cast to integer
        const startConditionIndex = (oneEvent.conditionList[currentEventConditionIndex].startConditionIndex * 1);
        if (startConditionIndex <= 0) {
            contentConditionChk.current.checked = false;
            contentConditionChk.current.disabled = true;

            messageConditionChk.current.checked = false;
            messageConditionChk.current.disabled = true;

            channelConditionChk.current.checked = false;
            channelConditionChk.current.disabled = true;
        }

        if (oneEvent.selectedEventList !== undefined && oneEvent.selectedEventList.length > 0 && oneEvent.selectedEventList[currentEventConditionIndex].eventInfo !== undefined && oneEvent.selectedEventList[currentEventConditionIndex].eventInfo.length > 0) {
            const eventCondition = oneEvent.selectedEventList[currentEventConditionIndex].eventInfo.find(condition=> condition.conditionIndex === startConditionIndex);
            if (eventCondition !== undefined) {                
                const useContent = eventCondition.contentId !== undefined && eventCondition.contentId !== '' && eventCondition.contentId !== null ? false : true;
                const useMessage = eventCondition.messageText !== undefined && eventCondition.messageText !== '' ? false : true;
                const useChannel = eventCondition.channelNo !== undefined && eventCondition.channelNo > -1 ? false : true;
                // update contents status
                contentConditionChk.current.disabled = useContent;
                messageConditionChk.current.disabled = useMessage;
                channelConditionChk.current.disabled = useChannel;
            }
        }
        // cast to integer
        const endConditionIndex = (oneEvent.conditionList[currentEventConditionIndex].endConditionIndex * 1);
        if (endConditionIndex <= 0) {
            updateStyle.condition.hour = false;
            updateStyle.condition.min = false;
        } else {
            updateStyle.condition.hour = true;
            updateStyle.condition.min = true;
        }

        setStyle({...updateStyle});


    }

    const handleMin = (e, value) =>{
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, durationMin: value} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));
    }

    const handleHour = (e, value) => {
        const currentEventConditionIndex = event.currentEventConditionIndex;
        const oneEvent = {...event, conditionList: event.conditionList.map((condition, index) => index === currentEventConditionIndex ? {...condition, durationHour: value} : condition)};
        dispatch(scheduleAction.updateEvent(mode, oneEvent));

    }

    useEffect(()=>{
        // set disabled
        if (mode === 'new') {
            contentConditionChk.current.disabled = true;
            messageConditionChk.current.disabled = true;
            channelConditionChk.current.disabled = true;
            //neverExpireChk.current.disabled = true;
        }
        scheduleService.fetchEventScheduleManagerFilter(
            {
                pageSize: 30,
                pages: 1
            }
        ).then(
            res=>{
                const conditions = res.items.eventIdList;
                let updateConditions = []
                conditions.map(
                    condition => {
                        condition.title = condition.eventName;
                        condition.value = condition.eventId;
                        /*condition.onClick = selectEventCondition;*/
                        updateConditions.push(condition)
                    }
                )
                setEventConditions(updateConditions);
            }
        ).catch(
            err=>toastr.error(err)
        )
    }, [])

    useEffect(()=> {
        updateStyle();
    }, [newEvent, editEvent]);

    return(
        <div className={"event_schedule_wrap"}
             style ={{display: (currentContentId === 'NEW_EVENT' && mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) || (currentContentId === 'EDIT_EVENT' && mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE) ? 'block':'none'}}>

            {
                showConfirmPopup.show &&
                <ConfirmPopup
                    title={t("COM_BUTTON_CONFIRM")}
                    message={t("MESSAGE_UPLOADER_REALLY_CANCEL_P")}
                    onClickYes={closeTab}
                    onClose={cancelCloseTab}

                />
            }
            {
                showSavePopup.show &&
                <SaveEventSchedulePopup
                    mode={mode}
                    onClose={onSavePopup}
                    state={event}
                />
            }

            <div className="contents_buttonwrap mt20">
                <div className="float_l" id="leftButtonList">
                    <WhiteButton width={100} onClick={onSavePopup} name={t("COM_BUTTON_SAVE")}/>
                    <WhiteButton width={100} onClick={closeEventManager} name={t("BUTTON_CANCEL_P")}/>
                </div>
            </div>
            <div id="eventWrap" style={{height:'100% !important'}}>
                <div className="clearfix" id="eventScheduleWrap_1" style={{marginTop: 20,display: 'block'}}>
                    <div className="schedule_message">
                        <div className="mt20"><h2>{t("MIS_TEXT_EVENT_NEW_EVENT_P")}</h2>
                            <div className="message_detail_setting">
                                <table>
                                    <colgroup>
                                        <col width="127px"/>
                                        <col width=""/>
                                    </colgroup>
                                    <tbody id="createEventWrap">
                                    <tr className="line pt10">
                                        <th>{t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME")}</th>
                                        <td>
                                            {
                                                eventConditions !== undefined && eventConditions.length > 0 &&
                                                    <Select defaultTitle={t("MIS_MESSAGE_EVENT_SELECT_EVENT_P")} selects={eventConditions} multiLang={false} value={event.conditionList[event.currentEventConditionIndex].eventId} onChange={(e, selected)=>handleEventCondition(e, selected)} />
                                            }
                                        </td>
                                    </tr>
                                    <tr className="tp20">
                                        <th>{t("COM_TEXT_PERIOD2_P")}</th>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                                <DateInput disabled={style.condition.startDateDisabled} dateFormat={dateFormat} date={new Date(event.conditionList[event.currentEventConditionIndex].startDate)} onChange={date => handleScheduleDate(date, 'start')} maxDate={new Date(event.conditionList[event.currentEventConditionIndex].stopDate)}/>
                                                <span className="space mr10">&nbsp;~&nbsp;</span>
                                                <DateInput dateFormat={dateFormat} date={new Date(event.conditionList[event.currentEventConditionIndex].stopDate)} onChange={date => handleScheduleDate(date, 'stop')} disabled={style.condition.stopDateDisabled || event.conditionList[event.currentEventConditionIndex].stopDate === '2999-12-31' ? true : false} minDate={new Date(event.conditionList[event.currentEventConditionIndex].startDate)}/>
                                                <span style={{marginLeft: 10}}>
                                                    <Checkbox disabled={style.condition.startDateDisabled} id={"eventNeverExpiredChk_"+event.currentEventConditionIndex} name={t("MIS_SID_20_NEVER_EXPIRE")} onClick={handleNeverExpire} checked={event.conditionList[event.currentEventConditionIndex].stopDate === '2999-12-31' ? true : false}/>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("MIS_SID_20_START_CONDITION")}</th>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                                <div>
                                                    <SelectForEvent defaultTitle={t("MIS_MESSAGE_EVENT_SELECT_START_CONDITION_P")}
                                                                    value={event.conditionList[event.currentEventConditionIndex].startConditionIndex}
                                                                    selects={event.selectedEventList !== undefined && event.selectedEventList.length > 0 && event.selectedEventList[event.currentEventConditionIndex].eventInfo}
                                                                    mode={'start'}
                                                                    multiLang={false}
                                                                    disabled={style.condition.selectStartConditionDisabled}
                                                                    width={200}
                                                                    onChange={handleStartCondition}/>
                                                </div>
                                                <div className={"condition_checkbox_wrap"}>
                                                    <Checkbox id={"eventContentChk"} name={t("TEXT_CONTENT_P")} ref={contentConditionChk} checked={event.conditionList[event.currentEventConditionIndex].onContent} onChange={handleContent}/>
                                                    <Checkbox id={"eventMessageChk"} name={t("TEXT_MESSAGE_P")} ref={messageConditionChk} checked={event.conditionList[event.currentEventConditionIndex].onMessage} onChange={handleMessage}/>
                                                    <Checkbox id={"eventChannelChk"} name={t("COM_TEXT_CHANNEL_P")} ref={channelConditionChk} checked={event.conditionList[event.currentEventConditionIndex].onChannel} onChange={handleChannel}/>
                                                </div>
                                            </div>
                                            </td>
                                    </tr>
                                    <tr style={{height:300}}>
                                        <th style={{paddingTop: 15,verticalAlign: 'top'}}>{t("MIS_SID_20_STOP_CONDITION")}</th>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                                <SelectForEvent defaultTitle={t("MIS_SID_20_AFTER_DISPLAY_DURATION")} value={event.conditionList[event.currentEventConditionIndex].endConditionIndex} selects={event.selectedEventList !== undefined && event.selectedEventList.length > 0 && event.selectedEventList[event.currentEventConditionIndex].eventInfo} mode={'end'} multiLang={false} disabled={style.condition.selectStopConditionDisabled} width={200} onChange={handleStopCondition}/>
                                                <span className="space ml10 mr10">{t("TABLE_DURATION_P")}</span>
                                                <NumberInput min={0} max={23} onChange={handleHour} value={event.conditionList[event.currentEventConditionIndex].durationHour !== undefined && !isNaN(event.conditionList[event.currentEventConditionIndex].durationHour)? event.conditionList[event.currentEventConditionIndex].durationHour : ""} disabled={style.condition.selectStopConditionDisabled || style.condition.hour}/>
                                                <span className="space mr10">{t("COM_TEXT_HOUR_P")}</span>
                                                <NumberInput min={0} max={59} onChange={handleMin} value={event.conditionList[event.currentEventConditionIndex].durationMin !== undefined && !isNaN(event.conditionList[event.currentEventConditionIndex].durationMin) ? event.conditionList[event.currentEventConditionIndex].durationMin : ""} disabled={style.condition.selectStopConditionDisabled || style.condition.min}/>
                                                <span className="space mr10">{t("COM_SID_MIN")}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EventManager