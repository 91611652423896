import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {isNil} from 'lodash';
import './Channels.css';
import ChannelPopup from "../popup/schedule/content/ChannelPopup";
import {useOutsideClick} from "../../helper";


const Channels  = ({currentChannel, channels=[], addChannel, updateChannelName,  removeChannel, selectChannel, updateChannel}) => {

    const style = {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: 110,
        position: 'relative',
        display: 'inline-block',
        paddingRight: 10,
        textAlign: 'center'
    }

    const {t} = useTranslation();
    const [state, setState] = useState({
        show: false,
        channelPopup: false
    });

    const outSide = useRef();

    useOutsideClick(outSide, ()=>{
        if (state.show) {
            setState({...state, show: false})
        }
    });

    const onSelectChannel =  channelNo => {
        setState({...state, show: false});
        selectChannel(channelNo);
    }

    const Channel = ({index, channelName, channelNo}) => {
        return (
            <li key={index}>
                <div style={{cursor:'pointer'}} onClick={()=>onSelectChannel(channelNo)}>
                    <span className="channelName">{t(channelName)}</span>
                    <span className="channelNo">{channelNo}</span>
                </div>
            </li>
        )
    }

    const getChannelName = () => {
        if (!isNil(channels) && channels.length > 0) {
            const channel = channels.find(channel => channel.channelNo === currentChannel);

            return t(channel.channelName) +' '+ channel.channelNo;
        }
        return '';
    }

    const onChannelPopup = () => {
        setState({...state, show: false, channelPopup : !state.channelPopup});
    }

    const openSelect = () => {
        setState({...state, show: !state.show});
    }

    return(
        <div className={'content_schedule_channel_wrap'}>

            {
                state.channelPopup &&
                    <ChannelPopup channels={channels} close={onChannelPopup} addChannel={addChannel} updateChannelName={updateChannelName} removeChannel={removeChannel} updateChannel={updateChannel}/>
            }

            <button className="base select" id="selectChannelBox" value="1" style={{width:160,borderRadius:15}} onClick={openSelect}>
                <span style={style}>{getChannelName(currentChannel)}</span>
                <span className="arrow"></span>
            </button>
            <div className="option_list channelList" style={{width: 200,zIndex:999,display:state.show ? '':'none',maxHeight:400,overflowY:'auto',overflowX:'hidden'}} ref={outSide}>
                <div style={{width:200}}><span></span>
                    <ul>
                        {
                            channels !== undefined && channels.length > 0 && channels.map(
                                (channel, index)=> <Channel key={index} index={index} channelName={channel.channelName} channelNo={channel.channelNo} />
                            )
                        }
                        <li className="last">
                            <div style={{cursor:'pointer'}} onMouseDown={()=>onChannelPopup()} >
                                {t("TEXT_TITLE_EDIT_CHANNEL_P")}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )


}
export default Channels;