import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {SearchButton} from "../../../button/SearchButton";
import {scheduleService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {useDispatch, useSelector} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import GroupsPopup from "../../GroupsPopup";
import SelectedDevices from "../../../input/SelectedDevices";
import {useMISOpt} from "../../../misopt";
import {isEmpty} from "lodash";
import {commonConstants, scheduleConstants} from "../../../../constants";
import {deviceTypeVersionToInt} from "../../../../helper/schedule/scheduleUtils";
import {getErrorMessage} from "../../../../helper/responseHandler";


const SaveMessageSchedulePopup = ({mode, onSave, onClose, deviceType, deviceTypeVersion}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misOption} = useMISOpt();

    const messageMode = mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE ? 'newMessage' : 'editMessage';

    let {updateMessage} = useSelector(state => ({
        updateMessage: state.schedules.messages[messageMode] !== undefined && state.schedules.messages[messageMode].message || undefined
    }))

    const [message, setMessage] = useState({
        ...updateMessage
    });

    const [messageGroupPopup, setMessageGroupPopup] = useState({
        show: false
    });

    const [deviceGroupPopup, setDeviceGroupPopup] = useState({
        show: false
    })

    const setMessageName = e => {
        setMessage({...message, messageName: e.target.value});
    }

    const setMessageGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            const {groupId, groupName, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                setMessage({...message, messageGroupId:  groupId, messageGroupName: groupName});
                showMessageGroupPopup();
            }
        }
    }

    const showMessageGroupPopup = () => {
        setMessageGroupPopup({show: !messageGroupPopup.show});
    }

    const showDeviceGroupPopup = () => {
        const type = mode === scheduleConstants.CONTENT_SCHEDULE_COPY_MODE ? deviceType : message.deviceType;
        const typeVersion = mode === scheduleConstants.CONTENT_SCHEDULE_COPY_MODE ? deviceTypeVersion : message.deviceTypeVersion;
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            allowSelectOrganization: false,
            allEnabled: false,
            checkbox: true,
            checkedKeys : getCheckedDeviceGroupKeys(),
            priority : getPriority(type, deviceTypeVersionToInt(typeVersion)),
            selected : message.deviceGroups,
            allGroup: true,
            save: setDeviceGroup,
            close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }));
    }

    const setDeviceGroup = groups => {
        if (groups !== undefined && groups.length > 0) {
            const deviceGroups = []
            groups.map(
                (group) => {
                    deviceGroups.push({groupId: group.groupId, groupName: group.groupNameText !== undefined ? group.groupNameText : group.groupName})
                }
            )
            setMessage({...message, deviceGroups: deviceGroups});
            //showDeviceGroupPopup();
            dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }
    }

    const removeDeviceGroup = id => {
        const {deviceGroups} = message;
        setMessage({...message, deviceGroups: deviceGroups.filter(group => group.groupId !== id)});
    }

    const closeMessageManagerTab = () => {
        if (mode === 'new') {
            dispatch(menuAction.removeTab('NEW_MESSAGE'));
        } else {
            dispatch(menuAction.removeTab('EDIT_MESSAGE'));
        }
        dispatch(scheduleAction.destroyMessage(mode));
    }

    const validMessageSchedule = () => {
        if (message.messageName === undefined || message.messageName === '') {
            toastr.error(t('MESSAGE_SCHEDULE_ENTER_SCH_NAME_P'));
            return false;
        }
        if (message.messageName.trim() === ''){                                  
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return;
        }
        if (message.messageGroupId === undefined || message.messageGroupId === '' || message.messageGroupId === -1) {
            toastr.error(t('MESSAGE_SCHEDULE_SEL_MESSAGE_GROUP_P'));
            return false;
        }
        return true;
    }

    const getPriority = (deviceType, deviceTypeVersion) => {
        const device = misOption(deviceType, deviceTypeVersion);
        return device !== undefined ? device.priority : 999999;
    }

    const saveMessage = () => {

        const updateGroupCounter = () => {
            dispatch(menuAction.reloadGroup('MESSAGE_SCHEDULE_BY_GROUP'));
        }
        if (mode === 'copy') {
            if (validMessageSchedule()) {
                let deviceGroups = [];
                if (message.deviceGroups !== undefined && message.deviceGroups.length > 0) {
                    deviceGroups = message.deviceGroups;
                }
                onSave(message.messageName, message.messageGroupId, deviceGroups);
            }
        } else {
            /*message.deviceGroupNames = message.deviceGroupNames.toString();*/
            if (validMessageSchedule()) {
                if (mode === 'new') {
                    scheduleService.createMessageSchedule(message).then(
                        res => {
                            if (res) {
                                toastr.success(t("TEXT_SUCCESS_P"));
                                updateGroupCounter();
                                // close tab
                                closeMessageManagerTab();
                            }
                        }
                    ).catch(
                        e => {
                            toastr.error(getErrorMessage(e));
                        }
                    ).finally ( ()=>{
                            dispatch(menuAction.updateSubMenuCounter('SCHEDULE'))
                        }
                    )
                } else if (mode === 'edit') {
                    scheduleService.updateMessageSchedule(message, message.messageId).then(
                        res => {
                            if (res) {
                                toastr.success(t("TEXT_SUCCESS_P"));
                                updateGroupCounter();
                                // close tab
                                closeMessageManagerTab();
                            }
                        }
                    ).catch(
                        e => {
                            toastr.error(getErrorMessage(e));
                        }
                    ).finally ( ()=>{
                            dispatch(menuAction.updateSubMenuCounter('SCHEDULE'))
                        }
                    )
                }
            }
        }
    }

    const getCheckedDeviceGroupKeys = () => {
        let selectedGroupIds = [];
        if(!isEmpty(message.deviceGroups)){
            message.deviceGroups.map((group) => {
                selectedGroupIds.push(group.groupId);
            })
        }
        return selectedGroupIds;
    }

    useEffect(()=>{
        if (mode === 'copy') {
            setMessage({message: {}});
        } else if (mode === 'new') {
            scheduleService.fetchMessageScheduleDefaultGroup()
                .then(
                    res => {
                        if (res) {
                            const {groupId, groupName} = res.items;
                            setMessage({...message, messageGroupId:  groupId, messageGroupName: groupName})
                        }
                    }
                )
        }

    },[]);


    const dialogProps ={title: t("COM_BUTTON_SAVE"), closeOnEscape : true, width :360, height :270 , modal:true, onClose:()=> onClose()};
    return(
        <MISDialog
            dialog={dialogProps}
            buttons={{
                rightButtons: [
                    {id: 'SAVE_SCHEDULE', title: t("MIS_SID_SERVER_SAVE_AND_PUBLISH"), onClick: ()=>saveMessage()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> onClose()}]
            }}
        >

            {
                messageGroupPopup.show &&
                <GroupsPopup
                    mode={"message_schedule"}
                    checkbox={false}
                    save={(groups)=>setMessageGroup(groups)}
                    close={()=>showMessageGroupPopup()}
                />
            }

            {
                deviceGroupPopup.show &&
                <GroupsPopup
                    checkedKeys={getCheckedDeviceGroupKeys()}
                    mode={"device"}
                    checkbox={true}
                    priority={getPriority(message.deviceType, message.deviceTypeVersion)}
                    checkWithChildren={true}
                    save={(groups)=>setDeviceGroup(groups)}
                    close={()=>showDeviceGroupPopup()}
                />
            }

            <div className="detail_view mini_p">
                <table>
                    <colgroup>
                        <col width="101px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr className="va_t">
                        <th><span className="popupTh90">{t("COM_TEXT_MESSAGE_NAME_P")}</span></th>
                        <td>
                            <input type="text" id="newMessageSavePopupMessageName" maxLength="50" style={{width:218}} value={message.messageName} onChange={setMessageName} />
                        </td>
                    </tr>
                    <tr>
                        <th><span className="popupTh90">{t("TEXT_MESSAGE_GROUP_P")}</span></th>
                        <td>
                            <div className="float_l mr10">
                                <SearchButton onClick={showMessageGroupPopup}/>
                            </div>
                            <div className="tag_input type2 float_l" style={{width: 150,background:'none'}}>
                                <input type="text" style={{width:'100%',background:'none'}} readOnly value={message.messageGroupName}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th><span className="popupTh90">{t("MIS_SID_20_PUBLISH_TO")}</span></th>
                        <td>
                            <div className="float_l mr10">
                                <SearchButton onClick={showDeviceGroupPopup}/>
                            </div>
                            <div className="float_l" style={{width:200}}>
                                <SelectedDevices groups={message.deviceGroups}  removeGroup={(groupId)=>removeDeviceGroup(groupId)}/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    )
}
export default SaveMessageSchedulePopup;
