import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {deviceService} from '../../../../services';
import MISDialog from '../../MISDialog';
import './VwlLayoutSettingPopup.css';
import Moment from "react-moment";
import ReactDOM from "react-dom";
import {toastr} from 'helper/toastrIntercept';
import {systemService} from "../../../../services/system.service";
import fileDownload from "js-file-download";

const VwlLayoutImage = ({layoutInfo, onClick, index, select}) => {
    const {t} = useTranslation();
    const [mouseOver, setMouseOver] = useState(false);

    const onMouseOver = () => {
        setMouseOver(true);
    };

    const onMouseOut = () => {
        setMouseOver(false);
    };

    return (
        <div className='layout_icon_wrap' onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={() => onClick(index)}>
            <div style={{display: select === index ? 'inline-flex' : 'none'}}>
                <div className='layout_check_bg'/>
                <div className='layout_check'/>
            </div>
            <div className='layout_mouse_over' style={{display: mouseOver? 'block' : 'none'}}>
                <div className='layout_mouse_over_type' style={{display: mouseOver? 'inline-flex' : 'none'}}>
                    {layoutInfo.isLinear ? t('COM_ID_LINEAR') : t('TEXT_TITLE_VWL_TYPE_NONLINEAR_P')}
                </div>
                <div className='layout_mouse_over_name' style={{display: mouseOver? 'inline-flex' : 'none'}}>
                    {layoutInfo.vwtFileName}
                </div>
            </div>
            <div className='layout_content'>
                <div className='layout_icon'/>
                <div className='layout_icon_title'>
                    <Moment format={'lll'}>{layoutInfo.createDate}</Moment>
                </div>
            </div>
        </div>
    )
};

const VwlLayoutSettingPopup = ({ groupId, onClose, vwlLayoutEditorForm}) => {
    const {t} = useTranslation();
    const [layout, setLayout] = useState({});
    const [select, setSelect] = useState('');

    useEffect(() => {
        deviceService.fetchVwlLayoutInfoByGroupId(groupId).then(
            res => {
                if(res.status === 'Success') {
                    let layoutInfoList = [];
                    res.items.vwlLayoutList.map(layoutInfo => {
                        layoutInfoList.push(layoutInfo);
                    });

                    setLayout(layoutInfoList);
                }
            }
        )
    }, [groupId]);

    const onClickOpen = () => {
        deviceService.fetchDeviceConnectionCheckByGroupId(groupId).then(res => {
            if(res.status === 'Success') {
                window.open('', 'VwlLayoutEditor');
                let form = vwlLayoutEditorForm;
                form.setAttribute('action', res.items.layoutAuthorPath);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-username').setAttribute("value", res.items.userId);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-password').setAttribute("value", res.items.userPassword);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-token').setAttribute("value", res.items.token);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-language').setAttribute("value", res.items.language);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-device-type').setAttribute("value", 'LFD');
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-device-group').setAttribute("value", res.items.deviceGroupId);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-content-id').setAttribute("value", layout[select].vwtId);
                ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-type').setAttribute("value", 'EDIT');
                form.submit();
            }
        }).catch(error => {
            toastr.error(error.errorMessage);
        });
    };

    const onClickDownload = () => {
        const filePath = '/vwt/' + layout[select].vwtId + '/' + layout[select].vwtFileName;
        systemService.fetchFileDownload({filePath: filePath, homeDir: 'ALARM_RULE_HOME'}).then(
            res => {
                fileDownload(res.blob, res.fileName);
            }
        )
    };

    const onClickLayout = (index) => {
        setSelect(index);
    };

    return (
        <div className='vwl_layout_open_wrap'>
            <MISDialog
                dialog={{
                    title: t('TEXT_SELECT_LAYOUT_P'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 610,
                    height: 450,
                    // position: {x: -490, y: -310},
                    onClose
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'COM_MIS_TEXT_OPEN_P',
                            title: t('COM_MIS_TEXT_OPEN_P'),
                            disable: select === '',
                            onClick: () => onClickOpen(),
                        },
                        {
                            id: 'BUTTON_DOWNLOAD_P',
                            title: t('BUTTON_DOWNLOAD_P'),
                            disable: select === '',
                            onClick: () => onClickDownload(),
                        },
                        {
                            id: 'COM_BUTTON_CLOSE_P',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose
                        }
                    ]
                }}
            >
                <div className="popup_contents pb25">
                    <div style={{display: 'grid',
                                gridTemplateColumns: '100px 100px 100px 100px 100px 100px'}}
                    >
                        {
                            layout.length > 0 && layout.map((layoutInfo, index) => {
                                return <VwlLayoutImage key={index} layoutInfo={layoutInfo} index={index} onClick={onClickLayout} select={select}/>
                            })
                        }
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default VwlLayoutSettingPopup;