import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import MISDialog from "../../MISDialog";
import TextInput from "../../../input/TextInput";
import Checkbox from "../../../checkbox/Checkbox";

const OnlineActivationPopup= ({onSave, onClose, type, licKey,title}) => {
    const {t} = useTranslation();

    const isAgreeRef = useRef();
    const [isAgree ,setIsAgree] = useState(false);
    const [showDesc, setShowDesc] = useState(false);
    const [company,setCompany] = useState({
        companyName: '',
        division: '',
        address: '',
        phoneNumber: '',
        email: ''
    });

    const handleAgreement = () => {
        const checked = isAgreeRef.current.checked;
        if (checked) {
            setIsAgree(true);
        } else {
            setIsAgree(false);
        }
    };

    const showDescription = (e) => {
        setShowDesc(!showDesc);
    }

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 620,
                    height: 200,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_ONLINE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: () => onSave(licKey,company, isAgree),
                            disable: false,
                        },
                        {
                            id: 'ACTIVATION_ONLINE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>


                <div className="">
                    <table>
                        <colgroup>
                            <col width="182px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('COM_MIS_TEXT_COMPANY_NAME_P')}</th>
                            <td>
                                <TextInput width={220} value={company.companyName} onChange={(e) => setCompany({...company, companyName: e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('MIS_TEXT_DIVISION_P')}</th>
                            <td>
                                <TextInput width={220} value={company.division} onChange={(e) => setCompany({...company, division: e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_TEXT_ADDRESS_P')}</th>
                            <td>
                                <TextInput width={220} value={company.address} onChange={(e) => setCompany({...company, address: e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_EMAIL_P')}</th>
                            <td>
                                <TextInput width={220} value={company.email} onChange={(e) => setCompany({...company, email: e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_TEXT_PHONE_NUMBER_P')}</th>
                            <td>
                                <TextInput width={220} value={company.phoneNumber} onChange={(e) => setCompany({...company, phoneNumber: e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <Checkbox id = {'chkAgree'} name={t("MIS_SID_20_I_AGREE_TO_PROVIDE_THIS_INFORMATION_TO_SAMSUNG")} onClick={(e)=>handleAgreement(e)} ref={isAgreeRef} style = {{'width':'200px'}} checked = {isAgree}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <button className="detail_btn mt14 mb10" style={{width: '200px', 'textAlign': 'left'}} onClick={showDescription} >
                        <span></span>
                        {t('COM_MIS_BUTTON_TERMS_AND_CONDITIONS_P')}
                    </button>
                </div>
                <span style={{display:showDesc?'inline':'none'}}>This legal notice (this “Notice”) applies to the MagicInfo server used to provide information to support technical issues related to your Samsung product and to any correspondence or dealings between you and Samsung Electronics Co., Ltd., (“Samsung”) with regard to such technical support. As part of the MagicInfo server, you may be asked to create and save an error report containing data about your PC information, LFD, log information, event records, screen captures, Dr. Watson Logs, crash dumps and compressed file for content. Once saved, you may select to send a copy of the Error Report to the Samsung technician, in order to better understand and help resolve your technical issue. Samsung will only keep the Error Report as necessary to resolve your technical issue and will delete the Error Report upon resolution of your technical issue.</span>
            </MISDialog>

        </div>
    );
}

export default OnlineActivationPopup;