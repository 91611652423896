import React from 'react';
import {useTranslation} from "react-i18next";

const ContentDeviceFilterStatus = ({selected, deviceTypes}) => {

    const {t} = useTranslation();

    return (
        <span id="filter_box_device_type">
            {
                selected.devices && (selected.devices.length === deviceTypes.length) ?
                    <span className="filter_status_device_type_filter">
                        <span>{t("TEXT_ALL_P")}</span>
                    </span>
                    :
                    selected.devices.map(device => (
                        <span className="filter_status_device_type_filter">
                            <span>{device}</span>
                        </span>
                    ))
            }
        </span>
    )
}
export default ContentDeviceFilterStatus;