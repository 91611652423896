import React, {useState} from 'react';
import classNames from "classnames";
import './LanguageButton.css';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {popupAction} from "../actions";
import {commonConstants} from "../constants";
import {history} from '../helper/history';
import {userService} from "../services";

const LanguageButton = ({language}) => {

    const [display, setDisplay] = useState(false);

    const {i18n,t} = useTranslation();
    const dispatch = useDispatch();

    const show = () => {
        setDisplay(!display);
    }

    const selectLanguage = (lang) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t('MIS_SID_SERVER_SITE_RESET_CHANGE_LANG_PRECEED'),
            onClickYes: () => {
                new Promise(()=>{
                    changeLanguage(lang);
                }).then(
                    closePopup()
                )
            },
            onClose: () => closePopup(),
        }))
    }

    const changeLanguage = (value) => {
        localStorage.setItem('MagicInfoPremiumLanguage', value);
        i18n.changeLanguage(value, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        }).then(
            res => {
                userService.updateLocale(value)
                    .then(()=>history.go('/'))
            }
        )
    }

    const closePopup = () => {
        dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
    }

    const Li = ({value, name, language}) => {
        return (
            <li onMouseDown={()=>selectLanguage(value)}><a href="#" className={language == value ? 'active' : ''} value={value} >{name}</a></li>
        );
    }

    const onBlur = () => {
        setDisplay(false);
    }

    return (
        <div className="language_wrap" >
            <button id="selectLanguageBtn" value={language} onBlur={onBlur} onClick={()=> show()} className={classNames('show' == display)}></button>
            <div className="option_list checklist" style={{display: (display ? 'block' : 'none'), zIndex: 101}}>
                    <div>
                        <ul>
                            <Li name={"한국어"} value={"ko"} language={language} />
                            <Li name={"English"} value={"en"} language={language}/>
                            <Li name={"العربية"} value={"ar"} language={language}/>
                            <Li name={"Deutsch"} value={"de"} language={language}/>
                            <Li value="es" name="Español" language={language}/>
                            <Li value="fa" name="فارسی" language={language} />
                            <Li value="fi" name="Suomalainen" language={language} />
                            <Li value="fr" name="Français" language={language} />
                            <Li value="it" name="Italiano" language={language} />
                            <Li value="ja" name="日本語" language={language} />
                            <Li value="pt" name="Português" language={language} />
                            <Li value="ru" name="русский" language={language} />
                            <Li value="sv" name="svenska" language={language} />
                            <Li value="tr" name="Türk" language={language} />
                            <Li value="zh_CN" name="简体中文" language={language} />
                            <Li value="zh_TW" name="繁體中文" language={language} />
                            <Li value="pl" name="Polski" language={language} />
                            <Li value="vi" name="Tiếng việt nam" language={language} />
                        </ul>
                    </div>
                </div>

        </div>
    )


}
export default LanguageButton;