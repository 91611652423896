import {useTranslation, withTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import React, {useEffect, useState} from "react";
import TextInput from "../../../input/TextInput";
import {settingService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../../actions";
import {settingConstants} from "../../../../constants";
import {useDispatch} from "react-redux";

const E2ECompanyInfoPopup = ({title, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [company, setCompany] = useState({
        soldToCode: '',
        companyName: '',
        division: '',
        address: '',
        email: '',
        phone: '',
        domainName: ''
    });

    useEffect(() =>{
        fetchCompanyInfo();
    },[]);

    const fetchCompanyInfo = () => {
        settingService.fetchCompanyInfo()
            .then((res)=>{
                setCompany({...company, soldToCode:res.items.soldToCode, companyName:res.items.companyName, division: res.items.division, address:res.items.address, email:res.items.email, phone : res.items.phone, domainName : res.items.domainName})
            })
            .catch(()=> {
                    toastr.error('error')
            })
    }

    const saveCompanyInfo = () => {
        settingService.updateCompanyInfo(company)
            .then(res=>{
                toastr.success('updated')
            })
            .catch(()=> {
                toastr.error('error')
            })
            .finally(()=>{
                dispatch(popupAction.closePopup(settingConstants.E2E_COMPANY_INFO_POPUP))
            });
    }
    
    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 500,
                    height: 300,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_ONLINE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: saveCompanyInfo
                        },
                        {
                            id: 'ACTIVATION_ONLINE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="182px"/>
                                <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>Company code</th>
                            <td><TextInput width={300} value={company.soldToCode} onChange={(e) => setCompany({...company, soldToCode: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>Company Name</th>
                            <td><TextInput width={300} value={company.companyName} onChange={(e) => setCompany({...company, companyName: e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Division</th>
                            <td><TextInput width={300} value={company.division} onChange={(e) => setCompany({...company, division: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td><TextInput width={300} value={company.address} onChange={(e) => setCompany({...company, address: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>E-mail</th>
                            <td><TextInput width={300} value={company.email} onChange={(e) => setCompany({...company, email: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>Phone Number</th>
                            <td><TextInput width={300} id="phoneNumber" value={company.phoneNumber} onChange={(e) => setCompany({...company, phoneNumber: e.target.value})} /></td>
                        </tr>
                        <tr>
                            <th>Domain Name</th>
                            <td><TextInput width={300} id="domainName" value={company.domainName} onChange={(e) => setCompany({...company, domainName: e.target.value})}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                
            </MISDialog>

        </div>
    )
}
export default E2ECompanyInfoPopup;