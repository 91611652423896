import React, { useState } from "react";
import { rulesetService } from "../../../../services";
import { useTranslation } from "react-i18next";

const Preview = ({ filter }) => {
    const { t } = useTranslation();
    const [param, setParam] = useState({
        page: 0,
        pageSize: 10
    });

    // if (filter.sign !== sign || filter.value !== value) {
    //     setfilter({
    //         ...filter,
    //         sign: sign,
    //         value: value
    //     });
    // }

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const { page, pageSize } = filter;

    const fetchData = () => {
        const { page, pageSize, type, sign, value } = filter;
        rulesetService
            .fetchResultKeywordPreview({
                startIndex: page,
                pageSize,
                type: type,
                sign: sign,
                value: value
            })
            .then(res => {
                setData({
                    ...data,
                    loading: false,
                    items: res.items,
                    totalCount: res.totalCount,
                    pages: Math.ceil(res.totalCount / pageSize)
                });
            });
    };

    return (
        <>
            <div className="preview">
                <button className="replash2" style={{ float: "left" }} onClick={fetchData}></button>
                <span style={{ padding: "4px", float: "left" }}>{t("COM_DID_VWL_PREVIEW")}</span>
            </div>
            <div className="selected_item_box" style={{ clear: "both", width: "90%" }}>
                <div className="pop_list">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "120px" }}>
                                    <span>{t("TABLE_DEVICE_NAME_P")}</span>
                                </th>
                                <th style={{ width: "200px" }}>
                                    <span>{t("DID_MAIN_CONTENT")}</span>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div
                        style={{
                            height: "77px",
                            overflowY: "auto",
                            backgroundColor: "white"
                        }}
                    >
                        <table id="devicePreviewTable">
                            <tbody>
                                {data.items.map((item, index) => (
                                    <tr>
                                        <td style={{ width: "120px" }}>
                                            <span>{item.deviceName}</span>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <span>{item.contentResult}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preview;
