import MultiSelectButton from "../../button/MultiSelectButton";
import React, {useEffect, useState} from "react";
import {contentService, settingService} from "../../../services";
import toastr from "toastr";
import {getErrorMessage} from "../../../helper/responseHandler";
import {useTranslation} from "react-i18next";
import {settingConstants} from "../../../constants";

const InsightIndexSetting =({onChangeInsightIndexesInfo,onChangeSalesData}) => {

    const [insightIndexes, setInsightIndexes] = useState([]);
    const [salesData, setSalesData] = useState({});
    const {t} = useTranslation();

    const fetchInsightIndexes = () => {
        settingService.fetchInsightIndexes({
            startIndex: 1,
            pageSize: 1000,
        }).then(res => {
            if(res.status === 'Success') {
                const salesData = res.items.filter(insightIndex => {return insightIndex.indexId == settingConstants.INSIGHT_INDEX_PRODUCT_CODE});
                const insightIndexes = res.items.filter(insightIndex => {return insightIndex.indexId != settingConstants.INSIGHT_INDEX_PRODUCT_CODE});

           /*     insightIndexes.forEach((insightIndex, i) => {
                    let selectedValues = [], selectedValueIds = []
                    insightIndex.values.map((v, j) => {
                        if (v.selected == true) {
                            selectedValues.push(v.value)
                            selectedValueIds.push(v.valueId)
                        }
                    })
                    insightIndexes[i].selectedValues = selectedValues.toString();
                    insightIndexes[i].selectedValueIds = selectedValueIds;
                })*/

                setInsightIndexes(insightIndexes);

                /*if (salesData != null) {
                    let selectedSalesValues = [];
                    salesData.values.map((v, j) => {
                        return v.selected == true ? selectedSalesValues.push(v.value) : ''
                    })
                    salesData.selectedValues = selectedSalesValues.toString();

                }*/
                setSalesData(salesData[0]);

            }
        }).catch(e => toastr.error(getErrorMessage(e)))
    };

    useEffect(() => {
        fetchInsightIndexes();
    }, [])

    const onValueChange = (valueId,indexValues) => {
        const insightIndexId = indexValues[0].indexId; // get Insight Index Id
        let selectedValues = [];
        let selectedValueIds = [];
        indexValues.map((indexValue, j) => {
            valueId.map((id, k) => {
                if (id == indexValue.id) {
                    selectedValues.push(indexValue.name);
                    selectedValueIds.push(indexValue.id);
                }
            })
        })

        const updateInsight = [...insightIndexes]
        updateInsight.map((insightIndex,i)=>{
            if(insightIndex.indexId == insightIndexId){
                updateInsight[i].selectedValues = selectedValues.toString();
                updateInsight[i].selectedValueIds = selectedValueIds;
            }
        })
        setInsightIndexes(updateInsight);
        onChangeInsightIndexesInfo(updateInsight);
    }

    const onSalesDataValueChange = (valueId, salesDataValues) => {
        let selectedValues = [];
        let selectedValueIds = [];
        salesDataValues.map((salesDataValue, j) => {
            valueId.map((id, k) => {
                if (id == salesDataValue.id) {
                    selectedValues.push(salesDataValue.name);
                    selectedValueIds.push(salesDataValue.id);
                }
            })
        })
        const updateSalesData = {...salesData}
        updateSalesData.selectedValues = selectedValues.toString();
        updateSalesData.selectedValueIds = selectedValueIds;

        setSalesData(updateSalesData)
        onChangeSalesData(updateSalesData);
    }

    const convertData =  (indexId, values) => {
        const convertedData = [];
        values.forEach(el => {
            convertedData.push({id: el.valueId, name: el.value, isChecked: el.selected, indexId: indexId});
        })
        return convertedData;
    }

    const getTBodyStyle = () => {
        return {display:'block',height:'150px',width:'100%',overflowY: 'scroll',overflowX: 'scroll'};
    }

    const getTHeadStyle = () => {
        return {display:'block', marginTop:'0px'};
    }

    return (
        <div className={'advertisement_tab'}>
            <div className="detail_view">
                <div style={{marginTop:'-30px'}}>
                    {t("MIS_SID_CBFEB_INSIGHT_INDEX")}
                </div>
                <table>
                    <colgroup>
                        <col width="150px"/>
                        <col width=""/>
                    </colgroup>
                    <thead style={getTHeadStyle()}>
                    <tr>
                        <th style={{width: '240px'}}>{t("MIS_SID_INDEX_NAME")}</th>
                        <th style={{width: '200px'}}>{t("COM_LFD_VALUE")}</th>
                    </tr>
                    </thead>
                   <tbody style={getTBodyStyle()}>
                    {
                        insightIndexes && insightIndexes.map((insightIndex, i) => {
                            return (
                                <tr key={insightIndex.indexId}>
                                    <td>
                                        <div title={insightIndex.indexName} style={{width: 240,whiteSpace: "normal",wordWrap: "break-word"}}>{insightIndex.indexName}</div>
                                    </td>
                                    <td>
                                        <MultiSelectButton
                                            title={insightIndexes[i].selectedValues}
                                            data={convertData(insightIndex.indexId, insightIndex.values)}
                                            style={{ borderRadius: "0px", border: "0px"}}
                                            onChange={onValueChange}
                                            itemAll={false}
                                            width={'195px'}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>

                <div style={{marginTop: '20px'}}>{t("MIS_SID_CBFEB_SALES_DATA")}</div>
                <table>
                    <colgroup>
                        <col width="150px"/>
                        <col width=""/>
                    </colgroup>
                    <thead style={getTHeadStyle()}>
                    <tr>
                        <th style={{width: '240px'}}>{t("COM_TEXT_VENDING_PRODUCT_CODE_P_KR_PRODUCT")}</th>
                        <th style={{width: '200px'}}>{t("COM_LFD_VALUE")}</th>
                    </tr>
                    </thead>
                    <tbody style={getTBodyStyle()}>
                    {
                        salesData && salesData.indexId !=undefined &&
                        <tr key={salesData.indexId}>
                            <td>
                                <div title={salesData.indexName} style={{width: 240,whiteSpace: "normal",wordWrap: "break-word"}}>{salesData.indexName}</div>
                            </td>
                            <td>
                                <MultiSelectButton
                                    title={salesData.selectedValues}
                                    data={convertData(salesData.indexId, salesData.values)}
                                    style={{borderRadius: "0px",border: "0px"}}
                                    onChange={onSalesDataValueChange}
                                    itemAll={false}
                                    width = {'195px'}
                                />
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InsightIndexSetting;