import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {dashboardService} from "../../../../services";
import {toastr} from "../../../../helper/toastrIntercept";
import {getErrorMessage} from "../../../../helper/responseHandler";
import './ConnectionHistory.css';

const ConnectionHistory = () => {

    const {t} = useTranslation();
    const deviceConnectionInterval = useRef();

    const [deviceConnectionInfo, setDeviceConnectionInfo] = useState({
        deviceConnectionHistory: {},
        today: [],
        yesterday: []
    });

    //[V9 RC #17] Dashboard Device Status info by ORG
    const buildDataSet = (day,today) => {
       let dataOfDay = new Array(24).fill(0);
       if(day && day.length>0){ 
            let rawTime= day.map((item) =>( new Date(item.time).getHours()));
            rawTime.map((item,index)=> {
                let currHour = new Date().getHours();
                if(today && currHour < item)
                    dataOfDay[item] = 0;
                else
                    dataOfDay[item]+=day[index].connect;
            });
       }
       return dataOfDay;   
    }
    

    const renderChart = () => {
        const charData = {
            labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            datasets: [],
        };
        const {today, yesterday} = deviceConnectionInfo;
        let tick = {beginAtZero:true, min: 0, max: 10, fontSize: 10, fontColor: '#909090'};

        //[V9 RC #17] Dashboard Device Status info by ORG
        if( (yesterday && yesterday.length > 0) || (today && today.length > 0) ) {
            
            var yesterdayData=buildDataSet(yesterday,false);
            var todayData=buildDataSet(today,true);
                   
            let yesterdayDataset = {
                data: yesterdayData,
                borderColor: '#3ACCFF',
                backgroundColor: '#3ACCFF',
            };
            let todayDataset = {
                data: todayData,
                borderColor: '#0381FE',
                backgroundColor: '#0381FE'
            };
            charData.datasets.push(yesterdayDataset);
            charData.datasets.push(todayDataset);

            let max = Math.max(...yesterdayData, ...todayData);
            if(max > 10) {
                tick = {beginAtZero:true, fontSize: 10, fontColor: '#909090'};
            }
        }
        return (
            <div style={{height: 200, paddingLeft: 10, paddingRight: 10}}>
            <Bar data={charData} redraw={true}
                 options={{

                     maintainAspectRatio: false,
                     legend: {
                         display: false,
                     },
                     animation: {
                         duration: 0
                     },
                     scales: {
                         xAxes: [{
                             display: false,
                         }],
                         yAxes: [{
                             gridLines: {
                                 drawBorder: false
                             },
                             ticks: {...tick, fontSize: 10, fontColor: '#909090'}
                         }]
                     },
                     elements: {point: {radius: 0}},
                     tooltips: {
                         displayColors: false,
                         callbacks: {
                             title: function (tooltipItems, data) {
                                 return null;
                             },
                             label: function (tooltipItem, data) {
                                 return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                             },
                         },
                     }
                 }}/>
            </div>
        );
    }

    const renderGuide = () => {
        return (
            <div className='daily-connected-device-guide'>
                <div className='daily-connected-device-guide-color-index' style={{background: '#3ACCFF'}}/>
                <div className='daily-connected-device-guide-title-index'>{t('MIS_TEXT_STATISTICS_POP_YESTERDAY_P')}</div>
                <div className='daily-connected-device-guide-color-index' style={{background: '#0381FE', marginLeft: '10px'}}/>
                <div className='daily-connected-device-guide-title-index'>{t('TEXT_TODAY_P')}</div>
            </div>
        );
    }

    const renderLabel = () => {
        let times = ['0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24'];
        return (
            <div className='daily-connected-device-label'>
                {
                    times.map((time, index) => <div key={index} className='daily-connected-device-label-time'>{time}</div>)
                }
            </div>
        );
    }

    const onTimer = () => {
        fetchGetDeviceConnectionHistory();
    }

    const fetchGetDeviceConnectionHistory = () => {
        dashboardService.fetchDeviceConnectionHistory().then(res => {
            setDeviceConnectionInfo({
                deviceConnectionHistory: res.items,
                today: res.items.today,
                yesterday:  res.items.yesterday
            })
        }).catch(err => {
            err && toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const updateData = () => {

    }

    useEffect(()=> {
        fetchGetDeviceConnectionHistory();
        deviceConnectionInterval.current = setInterval(onTimer, 1000);
        return () => {
            clearInterval(deviceConnectionInterval.current);
        }
    }, [])

    useEffect(()=> {

    }, [])

    return (
        <div style={{width: '50%', height: 230, margin: 10, display: 'inline-block'}}>
        <div style={{height: 30, lineHeight: '30px', color: '#241f20'}}>{t('MIS_SID_CONNECTED_DEVICES_YESTERDAY_TODAY')}</div>
            <div style={{marginTop: 10, backgroundColor: '#F5F5F5'}} >
                {renderGuide()}
                {renderChart()}
                {renderLabel()}
            </div>
        </div>
    )
}
export default ConnectionHistory;