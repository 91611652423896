import React, {useEffect, useState} from 'react';
import {
    Container,
} from '@material-ui/core';
import NoticeArea from "./areas/NoticeArea";
import CMArea from "./areas/CMArea";
import DMArea from "./areas/DMArea";
import CustomArea from "./areas/CustomArea";
import {dashboardService} from "../../services";
import {dashboardAction} from "../../actions";
import {useDispatch} from "react-redux";
import Chart from "chart.js";

Chart.plugins.register({
    afterDraw: (chartInstance) => {
        const ctx = chartInstance.chart.ctx;
        if (chartInstance.config.options.showDatapoints) {
            ctx.font = '10px Tahoma';
            ctx.fillStyle = '#454545';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            chartInstance.data.datasets.forEach(function (dataset) {
                for (let i = 0; i < dataset.data.length; i++) {
                    const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                    const scaleMax = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                    const yPos = (scaleMax - model.y) / scaleMax >= 0.93 ? model.y + 10 : model.y;
                    if (dataset.data[i] > 0) {
                        ctx.fillText(dataset.data[i], model.x, yPos);
                    }

                }
            });
        }



        /*if (chartInstance.config.options.showDataPointsOnVertical) {

            ctx.font = '10px Tahoma';
            ctx.fillStyle = '#ffffff';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            chartInstance.data.datasets.forEach(function (dataset) {
                for (let i = 0; i < dataset.data.length; i++) {
                    const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                    if (dataset.data[i] > 0) {
                        ctx.fillText(dataset.data[i], model.x/2, model.y - 5);
                    }

                }
            });
        }*/
        if (chartInstance.config.options.showDataPercentOnPie) {

            if (chartInstance.data.datasets.length > 0) {
                const data = chartInstance.data.datasets[0].data[0]+'%';
                const centerConfig = chartInstance.config.options.elements.center;
                const fontStyle = centerConfig.fontStyle || 'Arial';
                const txt = data;
                const color = centerConfig.color || '#000';
                ctx.font = "37px " + fontStyle;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                const centerX = ((chartInstance.chartArea.left + chartInstance.chartArea.right) / 2);
                const centerY = ((chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2);

                ctx.fillStyle = color;
                ctx.fillText(txt, centerX, centerY);
            }

        }
    },

})


const DashboardContainer = ({}) => {

    const dispatch = useDispatch();
    const [height, setHeight] = useState(500);

    const getHeight = () => {
        setHeight(window.innerHeight - 145);
    }
    useEffect(()=> {
        getHeight();

        window.addEventListener('resize', getHeight);
        return () => {
            window.removeEventListener('resize', getHeight);
        }
    }, [])

    const fetchWidgets = () => {
        dashboardService.fetchWidgets()
            .then(res => {
                const widgets = res.items;
                dispatch(dashboardAction.updateWidgets(widgets))
            })
    }


    useEffect(()=> {
        fetchWidgets();
    }, [])

    return (
        <Container maxWidth={false} style={{paddingRight: 54, overflowY: 'auto', height: height, minWidth: 930}}>

            <NoticeArea />

            <CMArea />

            <DMArea />

            <CustomArea />

        </Container>
    )
}
export default DashboardContainer;