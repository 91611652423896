import React, {useState} from 'react';
import classNames from 'classnames';
import {isNil, isEmpty, get} from 'lodash';
import {useTranslation} from "react-i18next";
import {deviceOptions} from '../../../misopt';
import RadioGroup from '../../../radio/RadioGroup';
import { isAllNil, isAllNilEmpty } from '../../../../helper';
import Checkbox from '../../../checkbox/Checkbox';
import WhiteButton from '../../../button/WhiteButton';
import TextInput from '../../../input/TextInput';
import './EditSecurity.css';

export const EditBasicSecurity = ({preset, securityInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {mntSafetyLock, miscRemocon, miscPanelLock, miscAllLock, touchControlLock, captureLock} = {}, changed, responseWarning = {}} = securityInfo || {};

    if(!preset && isAllNil(mntSafetyLock, miscRemocon, miscPanelLock, miscAllLock, touchControlLock, captureLock)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_SID_SECURITY')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            (preset || !isNil(mntSafetyLock)) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='mntSafetyLockEnable' name={t('COM_IDS_TXT_DIRECT_SAFETY_LOCK')} checked={changed['mntSafetyLock'] || false}
                                            propertyName='mntSafetyLock' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['mntSafetyLock'],
                                        'response_warning': !isNil(get(responseWarning, 'mntSafetyLock'))
                                    })}>{t('COM_IDS_TXT_DIRECT_SAFETY_LOCK')}</th>
                                }
                                <td>
                                    <RadioGroup propertyName='mntSafetyLock' selects={deviceOptions.onOffOptions} value={mntSafetyLock} onChange={onChange}
                                        disabled={preset && !changed['mntSafetyLock']} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_DISALBE_DEVICE_REMOTE_BUTTON')}</div>
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_CONFRIM_PW_DEVICE_USE')}</div>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(miscRemocon)) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='miscRemoconEnable' name={t('MIS_SID_SERVER_CBMAR_REMOTE_CONTROLLER_LOCK')} checked={changed['miscRemocon'] || false}
                                            propertyName='miscRemocon' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['miscRemocon'],
                                        'response_warning': !isNil(get(responseWarning, 'miscRemocon'))
                                    })}>{t('MIS_SID_SERVER_CBMAR_REMOTE_CONTROLLER_LOCK')}</th>
                                }
                                <td>
                                    <RadioGroup propertyName='miscRemocon' selects={deviceOptions.reverseOnOffOptions} value={miscRemocon} onChange={onChange}
                                        disabled={preset && !changed['miscRemocon']} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_DISABLE_DEVICE_REMOTE')}</div>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(miscPanelLock)) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='miscPanelLockEnable' name={t('COM_IDS_TXT_BUTTON_LOCK')} checked={changed['miscPanelLock'] || false}
                                            propertyName='miscPanelLock' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['miscPanelLock'],
                                        'response_warning': !isNil(get(responseWarning, 'miscPanelLock'))
                                    })}>{t('COM_IDS_TXT_BUTTON_LOCK')}</th>
                                }
                                <td>
                                    <RadioGroup propertyName='miscPanelLock' selects={deviceOptions.onOffOptions} value={miscPanelLock} onChange={onChange}
                                        disabled={preset && !changed['miscPanelLock']} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_DISABLES_DEVICE_PANEL_BUTTON')}</div>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(miscAllLock)) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='miscAllLockEnable' name={t('TABLE_ALL_KEY_LOCK_P')} checked={changed['miscAllLock'] || false}
                                            propertyName='miscAllLock' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['miscAllLock'],
                                        'response_warning': !isNil(get(responseWarning, 'miscAllLock'))
                                    })}>{t('TABLE_ALL_KEY_LOCK_P')}</th>
                                }
                                <td>
                                    <RadioGroup propertyName='miscAllLock' selects={deviceOptions.onOffOptions} value={miscAllLock === -1 ? 0 :miscAllLock} onChange={onChange}
                                        disabled={preset ? !changed['miscAllLock'] : miscAllLock == -1} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_DISALBE_DEVICE_REMOTE_BUTTON')}</div>
                                </td>
                            </tr>
                        }
                        {
                            (preset || (!isNil(touchControlLock) && touchControlLock >= 0)) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='touchControlLockEnable' name={t('COM_TV_SID_TOUCH_CONTROL_LOCK')} checked={changed['touchControlLock'] || false}
                                            propertyName='touchControlLock' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['touchControlLock'],
                                        'response_warning': !isNil(get(responseWarning, 'touchControlLock'))
                                    })}>{t('COM_TV_SID_TOUCH_CONTROL_LOCK')}</th>
                                }
                                <td>
                                    <RadioGroup propertyName='touchControlLock' selects={deviceOptions.onOffOptions} value={touchControlLock} onChange={onChange}
                                        disabled={preset && !changed['touchControlLock']} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_DISALBE_DEVICE_TOUCH_CONTROL')}</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const EditAdvancedSecurity = ({preset, securityInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const [showGuide, setShowGuide] = useState(false);
    const [whitelist, setWhitelist] = useState({show: false, protocol: 'TCP'});
    const {data: {miscServerNetworkSetting, miscBlockUsbPort, bluetoothLock, wifiLock, miscBlockNetworkConnection, miscWhiteList, sourceLock = [], screenMonitoringLock, remoteControlServerLock} = {}, changed, responseWarning = {}} = securityInfo || {};

    if(!preset && isAllNilEmpty(miscServerNetworkSetting, miscBlockUsbPort, bluetoothLock, wifiLock, miscBlockNetworkConnection, miscWhiteList, screenMonitoringLock, remoteControlServerLock)) {
        return null;
    }

    if(preset && sourceLock.length <= 0){
        deviceOptions.inputSource.forEach(source => {
            switch(source.value){
                case '33'/*HDMI1*/: case '35'/*HDMI2*/: case '49'/*HDMI3*/: case '51'/*HDMI4*/: 
                case '37'/*Display_Port*/: 
                case '24'/*DVI*/:
                case '85'/*HDBaseT*/:
                case '80'/*Plug In Module*/: 
                case '32'/*MagicInfo*/: 
                case '20'/*PC*/: 
                    sourceLock.push({text: source.title, value: 0, code: source.value});
                    break;
            }
        });
    }

    sourceLock && sourceLock.sort((a, b) => {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
    });

    const onSaveWhitelist = () => {
        if(whitelist.mode === 'edit') {
            onChange({'data-name': 'miscWhiteList'}, miscWhiteList.split(';').map((wl, i) => {
                if(i === whitelist.index) {
                    return `${whitelist.protocol}:${whitelist.address}:${whitelist.port}`;
                }
                return wl;
            }).join(';'));
        } else {
            onChange({'data-name': 'miscWhiteList'}, 
                miscWhiteList && miscWhiteList.length > 0 ? 
                `${miscWhiteList};${whitelist.protocol}:${whitelist.address}:${whitelist.port}` :
                `${whitelist.protocol}:${whitelist.address}:${whitelist.port}`);
        }
        setWhitelist({show: false});
    };

    const onEditWhitelist = idx => e => {
        const wl = miscWhiteList.split(';')[idx];
        const [protocol, address, port] = wl.split(':');

        setWhitelist({
            show: true,
            mode: 'edit',
            index: idx,
            protocol,
            address,
            port
        });
    };

    const onDeleteWhitelist = idx => e => {
        onChange({'data-name': 'miscWhiteList'}, miscWhiteList.split(';').filter((wl, i) => i !== idx).join(';'));
    };

    const onChangeSourceLock = (e, code) => {
        sourceLock.forEach((source, i) => {
            if(source.code === code){
                source.value = (source.value === 1)?0:1;
                source.changed = true;
            }
        });
        onChange({'data-name': 'sourceLock'}, sourceLock);
    };

    const onChangeScreenMonitoringLock = (e, value) => {
        onChange(e, value);
        if(!preset && value == 1 && remoteControlServerLock != 1)
            onChange({'data-name': 'remoteControlServerLock'}, 1);
    }

    return (
        <div className="devicepop setup mb26" style={{position:'relative'}}>
            <h3>{t('MIS_SID_ADVANCED_SECURITY')}</h3>
            <div className="device_hint" style={{position:'absolute', left:215, top:17}}>{t('MIS_SID_SERVER_CBMAR_SETTING_NOT_CHANGED_DEVICE_SERVER')}</div>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            (preset || !isNil(miscServerNetworkSetting)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='miscServerNetworkSettingLockEnable'
                                                  name={t('MIS_SID_SERVER_NW_SETTINGS_LOCK')}
                                                  checked={changed['miscServerNetworkSetting'] || false}
                                                  propertyName='miscServerNetworkSetting'
                                                  onChange={onChangeChecked}/>
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['miscServerNetworkSetting'],
                                        'response_warning': !isNil(get(responseWarning, 'miscServerNetworkSetting'))
                                    })}>{t('MIS_SID_SERVER_NW_SETTINGS_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='miscServerNetworkSetting' selects={deviceOptions.onOffOptions} value={miscServerNetworkSetting}
                                        onChange={onChange} disabled={preset && !changed['miscServerNetworkSetting']} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(miscBlockUsbPort)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='miscBlockUsbPortEnable' name={t('MIS_SID_USB_LOCK')} checked={changed['miscBlockUsbPort'] || false}
                                            propertyName='miscBlockUsbPort' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['miscBlockUsbPort'],
                                        'response_warning': !isNil(get(responseWarning, 'miscBlockUsbPort'))
                                    })}>{t('MIS_SID_USB_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='miscBlockUsbPort' selects={deviceOptions.reverseOnOffOptions} value={miscBlockUsbPort} 
                                        onChange={onChange} disabled={preset && !changed['miscBlockUsbPort']} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || (!isNil(sourceLock) && sourceLock.length > 0)) &&
                            <>
                                <tr>
                                    {
                                        preset ?
                                        <th>
                                            <Checkbox id='sourceLock' name={t('MIS_SID_CBFEB_INPUT_SOURCE_LOCK')} checked={changed['sourceLock'] || false}
                                                propertyName='sourceLock' onChange={onChangeChecked} />
                                        </th> :
                                        <th className={classNames({
                                            'changed': changed['sourceLock'],
                                            'response_warning': !isNil(get(responseWarning, 'sourceLock'))
                                        })}>{t('MIS_SID_CBFEB_INPUT_SOURCE_LOCK')}</th>
                                    
                                    }
                                    <td className={classNames({'changed': sourceLock[0].changed})}>{sourceLock[0].text}</td>
                                    <td>
                                        <RadioGroup propertyName='sourceLock' selects={deviceOptions.onOffOptions} value={sourceLock[0].value} 
                                            onChange={(e) => onChangeSourceLock(e, sourceLock[0].code)} disabled={preset && !changed['sourceLock']} />
                                    </td>
                                </tr>
                                {
                                    sourceLock.map((source, i) => {
                                        if(i > 0){
                                            return (
                                                <tr>
                                                    <th></th>
                                                    <td className={classNames({'changed': source.changed})}>{source.text}</td>
                                                    <td>
                                                        <RadioGroup propertyName='sourceLock' selects={deviceOptions.onOffOptions} value={source.value} 
                                                            onChange={(e) => onChangeSourceLock(e, source.code)} disabled={preset && !changed['sourceLock']} />
                                                    </td>
                                                </tr>   
                                            )
                                        }
                                    })
                                }
                            </>
                        }
                        {
                            (preset || !isNil(bluetoothLock)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='bluetoothLockEnable' name={t('MIS_SID_BLUETOOTH_LOCK')}
                                                  checked={changed['bluetoothLock'] || false}
                                                  propertyName='bluetoothLock' onChange={onChangeChecked}/>
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['bluetoothLock'],
                                        'response_warning': !isNil(get(responseWarning, 'bluetoothLock'))
                                    })}>{t('MIS_SID_BLUETOOTH_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='bluetoothLock' selects={deviceOptions.onOffOptions} value={bluetoothLock} 
                                        onChange={onChange} disabled={preset && !changed['bluetoothLock']} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(wifiLock)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='wifiLockEnable' name={t('MIS_SID_WIFI_LOCK')} checked={changed['wifiLock'] || false}
                                                  propertyName='wifiLock' onChange={onChangeChecked}/>
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['wifiLock'],
                                        'response_warning': !isNil(get(responseWarning, 'wifiLock'))
                                    })}>{t('MIS_SID_WIFI_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='wifiLock' selects={deviceOptions.onOffOptions} value={wifiLock} 
                                        onChange={onChange} disabled={preset && !changed['wifiLock']} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(screenMonitoringLock)) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='screenMonitoringLock' name={t('COM_LFD_SID_TIZEN_CADEC_SCREEN_MONITORING_LOCK')} checked={changed['screenMonitoringLock'] || false}
                                            propertyName='screenMonitoringLock' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['screenMonitoringLock'],
                                        'response_warning': !isNil(get(responseWarning, 'screenMonitoringLock'))
                                    })}>{t('COM_LFD_SID_TIZEN_CADEC_SCREEN_MONITORING_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='screenMonitoringLock' selects={deviceOptions.onOffOptions} value={screenMonitoringLock} 
                                        onChange={onChangeScreenMonitoringLock} disabled={preset && !changed['screenMonitoringLock']} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_SETS_CURRENT_DEVICE_NOT_SERVER')}</div>
                                </td>
                            </tr>
                        }
                        {
                            !isNil(remoteControlServerLock) &&
                            <tr>
                                {
                                    preset ?
                                    <th className='device_edit_item_title'>
                                        <Checkbox id='remoteControlServerLock' name={t('MIS_SID_CBFEB_REMOTE_CONTROL_LOCK')} checked={changed['remoteControlServerLock'] || false}
                                            propertyName='remoteControlServerLock' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'device_edit_item_title': true,
                                        'changed': changed['remoteControlServerLock'],
                                        'response_warning': !isNil(get(responseWarning, 'remoteControlServerLock'))
                                    })}>{t('MIS_SID_CBFEB_REMOTE_CONTROL_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='remoteControlServerLock' selects={deviceOptions.onOffOptions} value={remoteControlServerLock} 
                                        onChange={onChange} disabled={screenMonitoringLock == 1 || (preset && !changed['remoteControlServerLock'])} />
                                    <div className="mt5 device_hint">{t('MIS_SID_SERVER_CBMAR_DISABLE_REMOTE_CONTROL_FUNCTON')}</div>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(miscBlockNetworkConnection)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='miscBlockNetworkConnectionEnable' name={t('MIS_SID_NW_LOCK')} checked={changed['miscBlockNetworkConnection'] || false}
                                            propertyName='miscBlockNetworkConnection' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['miscBlockNetworkConnection'],
                                        'response_warning': !isNil(get(responseWarning, 'miscBlockNetworkConnection'))
                                    })}>{t('MIS_SID_NW_LOCK')}</th>
                                }
                                <td colspan="2">
                                    <RadioGroup propertyName='miscBlockNetworkConnection' selects={deviceOptions.reverseOnOffOptions} value={miscBlockNetworkConnection} 
                                        onChange={onChange} disabled={preset && !changed['miscBlockNetworkConnection']} />
                                </td>
                            </tr>
                        }                        
                        {
                            miscBlockNetworkConnection == 0 &&
                            <>
                                <tr>
                                    {
                                        preset ?
                                        <th>
                                            <Checkbox id='miscWhiteListEnable' name={t('MIS_SID_SERVER_CASEP_ALLOWLIST')} checked={changed['miscWhiteList'] || false}
                                                propertyName='miscWhiteList' onChange={onChangeChecked} />
                                        </th> :
                                        <th className={classNames({
                                            'changed': changed['miscWhiteList'],
                                            'response_warning': !isNil(get(responseWarning, 'miscWhiteList'))
                                        })}>{t('MIS_SID_SERVER_CASEP_ALLOWLIST')}</th>
                                    }
                                    <td colspan="2">
                                        <span style={{margin:'4px 0',float:'left'}}>{t('MIS_SID_PREMIUM_SELECT_ADD_ADD_NEW_LIST')}</span>
                                        <WhiteButton id='COM_BUTTON_ADD' name={t('COM_BUTTON_ADD')} onClick={() => setWhitelist({show: true, mode: 'add', protocol: 'TCP'})} 
                                            disable={preset && !changed['miscWhiteList']} style={{float: 'right'}} />
                                    </td>
                                </tr>
                                {
                                    (miscWhiteList || '').split(';').map((wl, i) => {
                                        if(isEmpty(wl)) {
                                            return null;
                                        }
                                        return (
                                            <tr key={i}>
                                                <th></th>
                                                <td colspan="2">
                                                    <span>{wl === 'NA' ? t('MIS_SID_PREMIUM_BRACKET_NEW_LIST') : wl}</span>
                                                    <button className="white_list_edit" onClick={onEditWhitelist(i)}></button>
                                                    <button className="white_list_delete" onClick={onDeleteWhitelist(i)}></button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </>
                        }
                    </tbody>
                </table>
                {
                    !isNil(miscBlockNetworkConnection) && miscBlockNetworkConnection == 0 && whitelist.show &&
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width="120px"/>
                            <col/>
                        </colgroup>
                        <tbody>
                            <tr style={{borderTop:'1px solid #f4f4f4'}}>
                                <th></th>
                                <td>{t('LIST_TITLE_PROTOCOL')}</td>
                                <td>
                                    <RadioGroup value={whitelist.protocol} selects={deviceOptions.protocolType} onChange={(e, value) => setWhitelist({...whitelist, protocol: value})} />
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{t('COM_MIS_TEXT_ADDRESS_P')}</td>
                                <td>
                                    <TextInput width={190} maxLength={100} value={whitelist.address} onChange={e => setWhitelist({...whitelist, address: e.target.value})}>
                                        {
                                            showGuide &&
                                            <div className="whitelist_guide">
                                                {t('MIS_SID_PREMIUM_PROT_RANGE_EX_MSG').split('\n').map((txt, i) => <div key={i}>{txt}</div>)}
                                            </div>
                                        }
                                    </TextInput>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>{t('TABLE_PORT_P')}</td>
                                <td>
                                    <TextInput width={190} onKeyDown={(e) => {return e.keyCode !== 69}} maxLength={12} value={whitelist.port} onChange={e => setWhitelist({...whitelist, port: e.target.value.replace(/[^0-9\-\*]/g, '')})}>
                                        <span className='whitelist_question' onMouseOver={() => setShowGuide(true)} onMouseOut={() => setShowGuide(false)}></span>
                                        <WhiteButton id='MSG_CANCELED' name={t('MSG_CANCELED')} onClick={() => setWhitelist({show: false})} style={{float: 'right', marginLeft: '10px'}} />
                                        <WhiteButton id='COM_BUTTON_SAVE' name={t('COM_BUTTON_SAVE')} onClick={onSaveWhitelist} style={{float: 'right'}} disable={isEmpty(whitelist.address) || isEmpty(whitelist.port)} />
                                    </TextInput>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
};

const EditSecurity = (props) => {
    const {t} = useTranslation();

    return (
        <div className='device_edit_panel_wrap mt28'>
            <EditBasicSecurity {...props} />
            <EditAdvancedSecurity {...props} />
        </div>
    );
};

EditSecurity.defaultProps = {
    securityInfo: {
        data: {},
        changed: {},
        errors: {},
    },
    onChange: () => {},
};

export default EditSecurity;