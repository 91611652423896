import React from 'react'
import MOVIEIcon from '../../../images/icon/file_type_icon_03.png';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const MOVIE = ({onlyIcon = false, embedded = false}) => {
    const {t} = useTranslation();
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={MOVIEIcon}/>
            {
                !onlyIcon && t('TEXT_TITLE_MOVIE_P')
            }
        </span>
    )
}
export default MOVIE;