import {withTranslation} from "react-i18next";
import React, {Component} from "react";
import {Minder} from 'react-mind';
import MISDialog from "../MISDialog";
import {contentService, settingService} from "../../../services";
import './TagMindMapPopup.css'
import {toastr} from 'helper/toastrIntercept';
import {cloneDeep} from "lodash";
import {renderToString} from 'react-dom/server'
import PlayerIcon from "../../icon/players";
import {getErrorMessage} from "../../../helper/responseHandler";

class TagMindMapPopup extends Component {
    state = {
        result: [],
        jmInstance: null
    };

    componentDidMount() {
        this.fetchTagMappingInfo();
    }

    componentWillUnmount(){
        if(this.state.jmInstance !== null){
            this.state.jmInstance.select_clear();
        }
    }  

    getPlayIcon = (item) => {
        return renderToString(<PlayerIcon deviceType={item.deviceType} deviceTypeVersion={item.deviceTypeVersion} text=''/>);
    }

    fetchTagMappingInfo = () => {
        const {t, tagId} = this.props;
        settingService.fetchTagMappingInfo(tagId).then((res) => {
            let mappingResult = cloneDeep(res.items);
            let result = [];
            let thumbnails = [];
            mappingResult.forEach((item) => {
                if (!result.find(node => node.id === item.id)) {
                    let topic = item.topic;
                    if (item.parentid === 'playlistNode') {
                        topic = `<span title="${item.topic}" style="display:inline-block;font-size:13pt;padding-left: 10px;overflow:hidden;text-overflow: ellipsis;width:260px">${item.topic}</span><span style="display:block;color:#333333;font-size:12pt;opacity: 0.5;padding-left: 10px;padding-top:3px;;height: 20px;">${item.playlistType}</span>`
                        result.push({...item, depth: 2, topic});
                    } else if (item.parentid === 'contentNode') {
                        topic = `<div class="tag_content"><div class="tag_content_info add" style="height: 56px;"><div><span><i style="width:30px">${t('LIST_TITLE_NAME')}</i>${item.topic}</span><br><span><i style="width:30px">${t('TABLE_TYPE_P')}</i>${item.contentType}</span><br></div></div></div>`;
                        if (item["background-image"] && item["background-image"].length > 0) {
                            let imageUrl = item["background-image"];
                            delete item["background-image"];
                            let params = imageUrl.split('&');
                            let thumbIdParam = params.find(param => param.includes('thumb_id'));
                            if (thumbIdParam) {
                                let thumbId = thumbIdParam.split('=')[1];
                                item.thumbId = thumbId;
                                thumbnails.push({
                                    contentId: item.id,
                                    thumbnailId: thumbId,
                                    width: Number(item.width),
                                    height: Number(item.height),
                                });
                            }
                        }
                        result.push({...item, depth: 2, topic});
                    } else if (item.parentid === 'deviceNode') {
                        let playerIcon = this.getPlayIcon(item);
                        topic = `<span style="padding-left: 10px;">${playerIcon}${item.topic}</span><br><span style="display:block;color:#333333;font-size:12pt;opacity: 0.5;padding-left: 10px;padding-top:3px;height: 20px;">${item.groupPath}</span>`;
                        result.push({...item, depth: 2, topic});
                    } else {
                        if (item.depth == 1) {
                            topic = t(topic);
                        }
                        result.push({...item, depth: 0, topic});
                    }
                }
            });
            if (thumbnails.length > 0) {
                thumbnails.map(thumbnail =>
                    contentService.fetchContentThumbnail(thumbnail.thumbnailId, {width: thumbnail.width, height: thumbnail.height,}).then(
                        res => {
                            let thumbnailData = res.body.items;
                            this.onLoadedImage(thumbnail.contentId, thumbnailData.src);
                    }).catch(err => {
                        console.error(err.errorMessage, err.url);
                        //toastr.error(err.errorMessage);
                    })
                );
            }
            this.setState({
                result: result,
            }, () => {
                this.draw(result)
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage));
        });
    };

    onLoadedImage = (contentId, src) => {
        let data = this.state.result;
        let node = data.find(item => item.id === contentId);
        if (node) {
            node['background-image'] = src;
        }
        this.setState({
            result: data,
        }, () => {
            this.draw(data)
        });
    }

    draw = (data) => {
        document.getElementById('mind_container').innerHTML = '';
        let mind = {
            "meta": {
                "name": "demo",
                "author": "demo@samsung.com",
                "version": "0.2",
            },
            "format": "node_array",
            "data": data,
        }
        let options = {
            container: 'mind_container',
            editable: false,
            theme: 'primary',
            view: {
                line_width: 0.5,
                line_color: '#CBCBCB'
            },
        };
        let jm = Minder.show(options, mind);
        data.forEach(node => {
            let jmNode = jm.get_node(node.id);
            if (node.depth === 2) {
                jmNode._data.view.element.className = 'depth2';
            }
        });
        jm.collapse_all();
        this.setState({...this.state,jmInstance:jm});
    }

    render() {
        return (
            <MISDialog
                dialog={{
                    title: this.props.title,
                    closeOnEscape: true,
                    modal: true,
                    width: 760,
                    height: 643,
                    onClose: this.props.onClose,
                }}>
                <div id='mind_container' className='minder_map'/>
            </MISDialog>
        );
    };
};
export default withTranslation()(TagMindMapPopup);