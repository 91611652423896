import React from 'react';
import './PlaylistNotDeletePopup.css';
import {withTranslation} from "react-i18next";
import MISDialog from '../MISDialog';
import ReactTable from "react-table";
import {MISOptContext} from "../../misopt";


class PlaylistNotDeletePopup extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        this.state = {
            notDeleted : false
        }
    }

    getAuthority(id) {
        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    getTrGroupProps = (state, rowInfo) => {
        if(rowInfo) {
            return {
                className: 'playlistNotDeletePopup_tr_group'
            };
        }
        return {};
    };

    parseList = (list) => {
        const {t} = this.props;

        let itemList = [];
        list.forEach(failItem => {
            let item = {};
            let isDelete = true;
            let deleteMessage = '';

            let  refScheduleList = [];
            if ( typeof  failItem.checkResource !== 'undefined' &&
            typeof failItem.checkResource.refScheduleList !== 'undefined '){
                refScheduleList = failItem.checkResource.refScheduleList;
            }
            let  refRulesetList = [];
            if ( typeof  failItem.checkResource !== 'undefined' &&
                typeof failItem.checkResource.refRulesetList !== 'undefined '){
                refRulesetList = failItem.checkResource.refRulesetList;
            }
            item = {
                playlistName: failItem.playlistName,
                scheduleInfo: refScheduleList.map(array => array.map(schedule => {
                    if(schedule !== null){
                        return schedule.scheduleName + '\n';
                    }
                })),
                ruleSetInfo: refRulesetList.map(array => array.map(ruleset => {
                    if(ruleset !== null){
                        if(isDelete) {
                            isDelete = false;
                            deleteMessage = t('MIS_SID_SYNC_PLAY');
                            if(!this.state.notDeleted){
                                this.setState({notDeleted: true})
                            }
                        }
                        return ruleset.rulesetName + '\n';
                    }
                })),
                deletable: isDelete ? 'O' : 'X\n' + '(ruleset)'
            };
            itemList.push(item);
        });
        return itemList;
    }

    arrayToString = (arr) =>{
        if (typeof arr === 'undefined' ) return "";

        let str = "";
        arr.forEach( s => {
            str += `${s}`
        });
        return str;
    }


    render() {
        const {t, onClose, onSave, failList} = this.props;
        const items= this.parseList(failList);
        const authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');

        return (
            <div className="playlistNotDeletePopup_contents">
                <MISDialog
                    dialog={{
                        title: t('COM_BUTTON_DELETE'),
                        isDraggable: false,
                        closeOnEscape: true,
                        modal: true,
                        width: 600,
                        height: 250,
                        position: {x: -350, y: -200},
                        onClose: onClose,
                    }}
                    buttons={{
                        leftButtons: [
                        ],
                        rightButtons: [
                            {
                                id: 'PLAYLIST_SAVE',
                                title: t("BUTTON_YES_P"),
                                onClick: () => onSave(failList),
                                authority : authority.CREATE || authority.MANAGE,
                                disable : this.state.notDeleted
                            },
                            {
                                id: 'PLAYLIST_CANCEL',
                                title: t("BUTTON_NO_P"),
                                onClick: onClose,
                            }
                        ],
                    }}>
                    <div className="playlistNotDeletePopup_message">
                        <p>{t('MIS_SID_SERVER_PLAYLIST_SCHEDULE_AND_REMOVED_FROM_PLAYLIST')}</p>
                    </div>
                    <ReactTable
                        data={items}
                        minRows={0}
                        getTrGroupProps={this.getTrGroupProps}
                        showPagination={false}
                        resizable={true}
                        loading={false}
                        noDataText={t("MESSAGE_COMMON_NO_DATA_P")}
                        //width={'auto'}
                        columns={
                            [
                                {
                                    Header: t("TABLE_PLAYLIST_NAME_P"),
                                    accessor: 'playlistName',
                                    style: {'whiteSpace':'pre'},
                                    width : 192,
                                    sortable: false,
                                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                                },
                                {
                                    Header: t("TEXT_CONTENT_SCHEDULE_P"),
                                    accessor: 'scheduleInfo',
                                    style: {'whiteSpace':'pre'},
                                    width : 220,
                                    sortable: false,
                                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                                },
                                {
                                    Header: t("MIS_SID_RULESET"),
                                    accessor: 'ruleSetInfo',
                                    style: {'whiteSpace':'pre'},
                                    width : 104,
                                    sortable: false,
                                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                                },
                                {
                                    Header: t("COM_BUTTON_DELETE"),
                                    accessor: 'deletable',
                                    style: {'whiteSpace':'pre', 'textAlign':'center'},
                                    width : 82,
                                    sortable: false,
                                },
                            ]
                        }
                        className="-striped -highlight"
                        manual
                        style={{height: '238px'}}
                    />
                </MISDialog>
            </div>
        );
    }
}
export default withTranslation()(PlaylistNotDeletePopup);