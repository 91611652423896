import React, {Component} from 'react';
import {connect} from "react-redux";
import './StartNode.css';
import {menuAction, popupAction, rulesetAction} from '../../../actions'
import {commonConstants} from '../../../constants';
import ButtonArea from "./ButtonArea";
import uuid from "uuid";
import VerificationCountingBadge from './VerificationCountingBadge';
import {rulesetService} from '../../../services';
import {toastr} from 'helper/toastrIntercept';
import {withTranslation} from "react-i18next";
import {checkConditionCount, checkDepth} from '../../../helper/ruleset/rulesetUtils';

class StartNode extends Component {

    addChildConditionSave(condition) {
        const addCondition = this.props.addCondition;

        const tmpChildren = this.elem.children;
        this.rule.rule_tree = [];

        let newCondition = {
            id : condition.conditionId,
            type: "condition",
            children: tmpChildren
        };
        this.rule.rule_tree.push(newCondition);

        addCondition(this.currentTab, condition);
    }

    checkCanAddAndCondition = () => {
        const {t} = this.props;

        try {
            if(!checkConditionCount()) {
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
                return false;
            }

            if(!checkDepth(this.elem.children, 1)) {
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
                return false;
            }
        }
        catch (err) {
            toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            return false;
        }

        return true;
    }

    addChild = () => {
        if(!this.checkCanAddAndCondition()) {
            return;
        }

        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;
        
        addPopup({
            id: commonConstants.RULESET_CONDITION_POPUP,
            type: commonConstants.RULESET_CONDITION_POPUP,
            mode: "NEW",
            save : (condition) => {
                if(condition.isPublic) {
                    this.addToLibrary(condition);
                }
                condition.isPublic = false;
                this.addChildConditionSave(condition);
            },
            close: () => closePopup(commonConstants.RULESET_CONDITION_POPUP)
        });
    }

    addToLibrary = (newCondition) => {
        const {loadContent, menu} = this.props;
        const condition = JSON.parse(JSON.stringify(newCondition));
        condition.conditionId = uuid();
        condition.isPublic = true;
        rulesetService.createCondition(condition).then(
            res => {
                menu.submenu.key++;
                loadContent(menu.currentContentId);
            }
        ).catch(error => {toastr.error(error)})
        .finally();
    }

    addNodeByDnd = (item) => {
        if(item.type === 'conditionBox') {
            if(!this.checkCanAddAndCondition()) {
                return;
            }
            
            let condition = JSON.parse(JSON.stringify(item.condition));
            condition.conditionId = uuid();
            condition.isPublic = false;
            this.addChildConditionSave(condition);
        }
    }

    render() {
        const {rulesets} = this.props;
        const {currentContentId} = this.props.menu;
        this.currentTab = currentContentId;

        this.elem = this.props.elem;
        this.rule = this.props.rule;
        const ruleId = this.props.ruleId;

        const plusButtonAreaStyle = {
            position: "absolute",
            top: "36px",
            left: "85px",
            width: "70px",
            height: "85px"
        };

        const verificationMode = this.currentTab === "NEW_RULESET" ? rulesets.newRuleset.verificationMode.enabled : rulesets.editRuleset.verificationMode.enabled;
        
        return(
            <div className={"ruletree-label ruletree-start-label ruletree-label-" + ruleId + "-" + this.elem.entryIdIndex} id={this.elem.entryIdIndex + "_start"} type="start">
                <div>Start</div>
                <ButtonArea onButtonClick={this.addChild} addNode={this.addNodeByDnd} areaStyle={plusButtonAreaStyle} buttonStyle={{ left: "-2px"}} />

                { verificationMode && this.rule.verificationData && this.rule.verificationData.deviceIds &&
                    <VerificationCountingBadge id={"start-" + ruleId} deviceIds={this.rule.verificationData.deviceIds}/>
                }
            </div>
        )
    }
}

export default connect(
    state => ({
        menu : state.menu,
        rulesets : state.rulesets,
    }),
    dispatch => ({
        addCondition: (currentTab, condition) => dispatch(rulesetAction.addCondition(currentTab, condition)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadContent: (id) => dispatch(menuAction.loadContent(id))
    })
)(withTranslation()(StartNode));