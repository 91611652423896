import React from 'react';
import {Bar} from 'react-chartjs-2';
import './Statistics.css';
import './TableWithBar.css';
import * as PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {optionBar} from "../../constants";

export function TableWithBar(props) {
    const {t} = useTranslation();
    const {title, chartData, tableWidth, tableData, col1, col2, totalCount, totalItemCount, barOption} = props;
    // const totalItemCount = chartData && chartData.datasets && chartData.datasets.length > 0 && chartData.datasets[0].data ? chartData.datasets[0].data.length : 0;
    // const totalCount = chartData && chartData.datasets && chartData.datasets.length > 0 && chartData.datasets[0].data && chartData.datasets[0].data.length && chartData.datasets[0].data.length > 0 ?
    //     (chartData.datasets[0].data).reduce((pre, value) => pre + value) : 0;

    return <div className="graph_wrap clearfix mt13" style={{display: "flex"}}>
        <div className="float_l">
            <div className="panel_status" style={{width:tableWidth}}>
                <h4 className="ml6">{title}</h4>
                <span className="total_number">{totalItemCount} {t('COM_LFD_SID_ITEMS_S')} </span>
                <div className="list_wrap mt32 pb0">
                    <table>
                        <colgroup>
                            <col className="w50"/><col width=""/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>{col1}</th><th>{col2}</th>
                        </tr>
                        </thead>
                    </table>
                    <div className="statistics_contentType_list">
                        <table>
                            <colgroup>
                                <col className="w70"/><col className="w30"/>
                            </colgroup>
                            <tbody>
                            {tableData && tableData.length > 0
                                ? (tableData).map((item, i) => (<tr key={i}><td>{item.col1}</td><td>{item.col2}</td></tr>))
                                : (<tr><td className="ta_c" colSpan={2}>{t('MESSAGE_COMMON_NO_DATA_P')}</td></tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="foot_total_number clearfix">
                        <span className="w40">
                            <span>{t('LIST_TITLE_TOTAL')}</span>
                        </span>
                        <span className="total mr5 ta_r w40">
                            <span>{totalCount}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="float_l">
            <div className="statistics_title mt28 pl27">
                {totalItemCount > 0 && totalItemCount < 101
                    ? (<Bar data={chartData} width={700} height={300} options={barOption ? barOption : optionBar}/>)
                    : (<div className="no_data">{t('MESSAGE_COMMON_NO_DATA_P')}</div>)
                }
            </div>
        </div>
    </div>;
}

TableWithBar.propTypes = {
    title: PropTypes.string,
    tableWidth: PropTypes.string,
    tableData: PropTypes.any,
    col1: PropTypes.string,
    col2: PropTypes.string,
    chartData: PropTypes.shape({datasets: PropTypes.any, labels: PropTypes.any}),
};