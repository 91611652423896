import React, {useEffect, useMemo, useState} from 'react';
import Moment from 'moment';
import {toastr} from 'helper/toastrIntercept';
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {settingService, userService} from '../../services';
import {isEmpty, isNil, set} from 'lodash';
import {getErrorMessage} from "../../helper/responseHandler";
import {userValidator} from "../../helper/userValidator";
import TextInput from "../../components/input/TextInput";
import WhiteButton from "../../components/button/WhiteButton";
import SettingInformation from "../../components/setting/SettingInformation";
import Select from "../../components/selectbox/Select";
import {history} from '../../helper/history';
import {connect, useDispatch} from "react-redux";
import {popupAction, settingsAction} from "../../actions";
import {commonConstants} from "../../constants";
import {ConfirmPassword} from "./ConfirmPassword";
import {MfaManagement} from "../user/MfaManagement";
import {isEnableMfa} from "../common/MfaUtil";
import {userActions} from "../../actions";

const MyInformation  = (props) => {
    
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const { addPopup, closePopup, loadMyAccount, updateMyAccount, checkPassword } = props;
    const { myinfo, viewmode, changedData, currContent } = props;

    const [data, setData] = useState({
        items: [],
        roles: [], 
        loading: false
    });
    const {items = [], loading = false, roles} = data;

    const [mode, setMode] = useState('INFO');

    const [password, setPassword] = useState('');

    useEffect(()=> {
        if(mode == 'INFO'){
            fetchData();
        }
    }, []);

    const changeMode = (mode) => {
        setMode(mode);
        if(mode == 'INFO'){
            fetchData();
        } else if(mode == 'CONFIRM_PASSWORD'){
            checkPassword(data.items, "");
        } else if(mode == 'UPDATE'){
            updateMyAccount(data.items, {});
        }
    }

    const getUpdatedData = () => {
        userService.fetchUserAssignableRoles().then(res => {
            setData({
                ...data, 
                roles: res.items,
                items : myinfo
            });
        }).catch((e) => {
            toastr.error(getErrorMessage(e));
        });
    }

    const fetchData = () => {
        setData({...data, loading: true});
        addPopup({id: 'MY_INFORMATION_LOADING', type: commonConstants.LOADING_POPUP});
        Promise.all([
            settingService.fetchMyAccount(),
            userService.fetchUserAssignableRoles()
        ]).then(res => {
            setData({
                ...data, 
                loading: false, 
                items: res[0].items,
                roles: res[1].items
            });
            loadMyAccount(res[0].items, "INFO");
        }).catch((e) => {
            toastr.error(getErrorMessage(e));
        }).finally(
            closePopup('MY_INFORMATION_LOADING')
        );
    }

    const saveData = (updateData) => {
        setData({...data, loading: true});
        if(isNil(updateData) || updateData.length == 0){
            toastr.error(t("MIS_SID_NO_CHANGES_HAVE_BEEN_MADE"));
            return;
        }

        settingService.updateMyAccount(updateData).then(res => {
            setData({
                ...data, 
                loading: false,
                items: res.items
            });
            toastr.success(t("MIS_MESSAGE_STATISTICS_SAVE_P"));
            changeMode('INFO');
            let isPasswordChanged = !isNil(updateData.password);
            if(!isNil(updateData.dateFormat) || !isNil(updateData.firstDay) || !isNil(updateData.timeFormat)){
                if(!isNil(updateData.dateFormat)){
                    changeLocalStorage("dateFormat", updateData.dateFormat);
                }
                if(!isNil(updateData.firstDay)){
                    changeLocalStorage("scheduleFirstDay", updateData.firstDay);
                }
                if(!isNil(updateData.timeFormat)){
                    changeLocalStorage("timeFormat", updateData.timeFormat);
                }
                if(!isPasswordChanged) {
                    setTimeout(() => {
                        history.go('/');
                    }, 200);
                }
            }

            if(isPasswordChanged) {
                setTimeout(() => {
                    dispatch(userActions.logout());
                }, 200);
            }
        }).catch((e) => {
            toastr.error(getErrorMessage(e));
        });
    }

    const changePassword = (password) => {
        checkPassword(data.items, password);
    }

    const setItems = (id, value, changed) => {
        let newItems = {...items};
        set(newItems, id, value);
        setData({...data, items: newItems});
        updateMyAccount(newItems, changed);
    }

    const renderMyInformation = () => {
        return (
            <MyAccountInformation items={items} roles={roles} onSave={saveData} onChangeMode={changeMode} mode={mode} 
            onChangeValue={setItems} changedData={changedData} currContent={currContent} />
        )
    };

    const renderConfirmPassword = () => {
        return (
            <ConfirmPassword  onChangeMode={(mode)=>changeMode(mode)} password={password} changePassword={changePassword} currContent={currContent} />
        )
    };

    return (
        <>
        <div>
            { mode == 'INFO' && renderMyInformation(items) }
            { mode == 'UPDATE' && renderMyInformation(items) }
            { mode == 'CONFIRM_PASSWORD' && renderConfirmPassword(items) } 
        </div>
        </>
    )
}

const MyAccountInformation  = ({items, roles, onChangeMode, onSave, mode, onChangeValue, changedData, currContent}) => {
    
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [updateData, setUpdateData] = useState(changedData);
    const [viewmode, setViewMode] = useState(mode);
    const [error, setError] = useState({});
    const isMfa = useMemo(() => isEnableMfa(), []);

    const getRolesSelectData = () => {
        let rolesItems = [];
        if(!isNil(roles) && roles.length > 0){
            roles.map(role => {
                rolesItems.push({value: role.roleId, title: role.roleName});
            })
        }
        return rolesItems;
    }
    const changeView = (changed) => {
        //setViewMode(changed);
        onChangeMode('INFO');
    }

    const getDay = (day) => {
        switch(day) {
            case '0':
                return t("COM_TEXT_DAY_SUNDAY_P");
            case '1':
                return t("COM_TEXT_DAY_MONDAY_P");
            case '6':
                return t("COM_TEXT_DAY_SATURDAY_P");
        }
        return t("COM_TEXT_DAY_SUNDAY_P");
    }

    const getDateByFormat = (date, format = 'yyyy-MM-dd') => {
        if(isNil(date)){
            date = new Date();
        }
        return  Moment(date).format(format.toUpperCase());
    }

    const getTimeByFormat = (date, format = '24hour') => {
        if(isNil(date)){
            date = new Date();
        }
        switch(format) {
            case 'ampm':
                return Moment(date).format('hh:mm A');
            case '24hour' :
                return Moment(date).format('HH:mm');
        }
        return Moment(date).format('HH:mm A');
    }

    const onChangeInput = (e) =>{
        onChange(e.target.getAttribute('data-name'), e.target.value);
    }

    const onChangeWithValidationCheck = (e) => {
        const value = e.target.value;
        const target = e.target.getAttribute('data-name');
        const {isValid, messageId} = userValidator(target, value);

        let valid = isValid;

        let errorTmp = {...error};

        if(target == "password" && value == ""){
            if(updateData.passwordConfirm == ""){
                set(errorTmp, "passwordConfirm", null);
            }
            valid = true;
        }

        if(valid){
            set(errorTmp, target, null);
        } else {
            set(errorTmp, target, t(messageId).replace('<br>', ' '));
        }
        setError(errorTmp);
        onChange(target, value);
    }

    const onChangeConfirmPassword = (e) => {
        const passwordConfirm = e.target.value;
        if(items.password !== passwordConfirm){
            setError({...error, passwordConfirm:t("MESSAGE_USER_DIFF_PASS_REPASS_P")});
        } else{
            setError({...error, passwordConfirm:null});
        }        
        onChange(e.target.getAttribute('data-name'), passwordConfirm);
    }

    const onChangeSelect = (e, selected) => {
        onChange(e.target.getAttribute('data-name'), selected);
    }

    const onChange = (id, value) => {
        let updated = {...updateData};
        if(id == "password" && value == ""){
            delete updated.password;
        }else{
            set(updated, id, value);
        }
        setUpdateData(updated);
        onChangeValue(id, value, updated);
    }

    const getMyBasicItems = (items) => {
        let list = [
            {id: "USER_ID", name: <Trans i18nKey={"COM_DID_ADMIN_USER_USERID"}/>, value: items.userId }
        ];
        if(viewmode === 'UPDATE'){
            list.push({id: "currentPassword", name: <Trans i18nKey={"TEXT_PASSWORD_P"}/>, mandatory: true,
            	value: <TextInput autoComplete={"off"} propertyName={"currentPassword"} value={items.currentPassword} onChange={onChangeWithValidationCheck} placeholder={t("TEXT_PASSWORD_P")}
            	type={"password"} error={error.currentPassword} maxLength={50}/>});
            if(isNil(items.ldapInfo) || items.ldapInfo == ""){
                list.push({id: "password", name: <Trans i18nKey={"TEXT_NEW_PASSWORD_P"}/>, 
                value: <TextInput autoComplete={"off"} propertyName={"password"} value={items.password} onChange={onChangeWithValidationCheck} placeholder={t("TEXT_NEW_PASSWORD_P")} 
                    type={"password"} error={items.password!=""&&error.password} maxLength={50}/>});
                list.push({id: "passwordConfirm", name: <Trans i18nKey={"COM_TEXT_RE_PASSWORD_P"}/>, 
                value: <TextInput autoComplete={"off"} propertyName={"passwordConfirm"} value={items.passwordConfirm} onChange={onChangeConfirmPassword} placeholder={t("COM_TEXT_RE_PASSWORD_P")} 
                                    type={"password"} error={error.passwordConfirm} maxLength={50}/>});
            }
            list.push({id: "userName", name: <Trans i18nKey={"COM_TEXT_USER_NAME_P"}/>, mandatory: true,
            value: <TextInput autoComplete={"off"} propertyName={"userName"} value={items.userName} onChange={onChangeWithValidationCheck} error={error.userName} maxLength={60}/>});
            list.push({id: "email", name: <Trans i18nKey={"COM_TEXT_EMAIL_P"}/>, mandatory: true,
            value: <TextInput propertyName={"email"} value={items.email} onChange={onChangeWithValidationCheck} error={error.email} maxLength={100}/>});
            list.push({id: "mobileNum", name: <Trans i18nKey={"TEXT_MOBILE_NUM_P"}/>, 
            value: <TextInput propertyName={"mobileNum"} value={items.mobileNum} onChange={onChangeInput} error={error.mobileNum} maxLength={60}/>});
            list.push({id: "phoneNum", name: <Trans i18nKey={"COM_MIS_TEXT_PHONE_NUMBER_P"}/>, 
            value: <TextInput propertyName={"phoneNum"} value={items.phoneNum} onChange={onChangeInput} error={error.phoneNum} maxLength={60}/>});
        }else{
            list.push({id: "userName", name: <Trans i18nKey={"COM_TEXT_USER_NAME_P"}/>, value: items.userName});
            list.push({id: "email", name: <Trans i18nKey={"COM_TEXT_EMAIL_P"}/>, value: items.email });
            list.push({id: "mobileNum", name: <Trans i18nKey={"TEXT_MOBILE_NUM_P"}/>, value: items.mobileNum });
            list.push({id: "phoneNum", name: <Trans i18nKey={"COM_MIS_TEXT_PHONE_NUMBER_P"}/>, value: items.phoneNum });
        }

        return list;
    }

    const getOrganItems = (items) => {
        let list = [
            {id: "organization", name: <Trans i18nKey={"TABLE_ORGANIZATION_P"}/>, value:items.organization },
            {id: "group", name: <Trans i18nKey={"TEXT_GROUP_P"} />, value: items.organization=='ROOT'?t("TEXT_ROOT_GROUP_NAME_P"): items.group }
        ];

        let rolesData = getRolesSelectData();
        
        if(viewmode === 'UPDATE'){
            list.push({id: "team", name: <Trans i18nKey={"DID_ADMIN_USER_TEAM"} />, 
            value: <TextInput propertyName={"team"} value={items.team} onChange={onChangeInput} error={error.team} maxLength={60}/>});
            list.push({id: "position", name: <Trans i18nKey={"DID_ADMIN_USER_POSITION"} />, 
            value: <TextInput propertyName={"position"} value={items.position} onChange={onChangeInput} error={error.position} maxLength={60}/>});
            if(!isNil(items.role) && items.role.toLowerCase() == 'administrator'){
                list.push({id: "nextAdminId", name: <Trans i18nKey={"TEXT_TITLE_NEW_ORGAN_ADMIN_ID_P"} />, 
                value: 
                <><div className={"red"}><Trans i18nKey={"MIS_MESSAGE_COMMON_LOGOUT_SETTING_CHANGE_P"} /></div>
                <TextInput propertyName={"nextAdminId"} value={items.nextAdminId} onChange={onChangeInput} error={error.nextAdminId} maxLength={20}/></>});
                list.push({id: "newRoleId", name: <Trans i18nKey={"TEXT_TITLE_SEL_MY_NEW_ROLE_P"} />, 
                        value: <Select width={200} propertyName={'newRoleId'} selects={rolesData} title={(!isNil(rolesData)&&rolesData.length>0)?rolesData[0].title:''} 
                                value={!isNil(items.newRoleId)?items.newRoleId:rolesData[0].value} onChange={onChangeSelect} multiLang={true} useEllipsis={true}/>});
            }else{
                list.push({id: "role", name: <Trans i18nKey={"COM_TEXT_ROLE_P"} />, value: items.role });
            }
        }else{
            list.push({id: "role", name: <Trans i18nKey={"COM_TEXT_ROLE_P"} />, value: items.role });
            list.push({id: "team", name: <Trans i18nKey={"DID_ADMIN_USER_TEAM"} />, value: items.team });
            list.push({id: "position", name: <Trans i18nKey={"DID_ADMIN_USER_POSITION"} />, value: items.position });                
        }

        return list;
    }

    const getDisplayItems = (items) => {
        if(viewmode === 'UPDATE'){
            let now = new Date();
           
            const optionDate = [
                {value: 'yyyy-MM-dd', title: getDateByFormat(now, 'YYYY-MM-DD')},
                {value: 'MM/dd/yyyy', title: getDateByFormat(now, 'MM/DD/YYYY')},
                {value: 'dd/MM/yyyy', title: getDateByFormat(now, 'DD/MM/YYYY')}
            ];

            const optionDay = [
                {value: '0', title: getDay('0')},
                {value: '1', title: getDay('1')},
                {value: '6', title: getDay('6')}
            ];

            const optionTime = [
                {value: '24hour', title: getTimeByFormat(now, '24hour')},
                {value: 'ampm', title: getTimeByFormat(now, 'ampm')}
            ];

            return [
                {id: "dateFormat", name: <Trans i18nKey={"MIS_SID_PREMIUM_DATE_FORMAT"}/>, title: getDateByFormat(null, items.dateFormat), 
                value: <><div className={"red"}><Trans i18nKey={"MIS_SID_PREMIUM_CHANGE_DISPLAY_INFO_SERVER_PAGE"} /></div>
                        <Select width={200} propertyName={'dateFormat'} selects={optionDate} title={optionDate[0].title} 
                        value={items.dateFormat} onChange={onChangeSelect} multiLang={true} /></>},
                {id: "firstDay", name: <Trans i18nKey={"MIS_SID_PREMIUM_FIRST_DAY_OF_WEEK"} />, title: getDay(items.firstDay), value: items.firstDay, option: optionDay, type: 'select', action: onChangeSelect},
                {id: "timeFormat", name: <Trans i18nKey={"TEXT_TIME_P"} />, title: getTimeByFormat(null, items.timeFormat), value: items.timeFormat, option: optionTime, type: 'select', action: onChangeSelect}
            ]
        } else {
            return [
                {id: "dateFormat", name: <Trans i18nKey={"MIS_SID_PREMIUM_DATE_FORMAT"}/>, value: getDateByFormat(null, items.dateFormat)},
                {id: "firstDay", name: <Trans i18nKey={"MIS_SID_PREMIUM_FIRST_DAY_OF_WEEK"} />, value: getDay(items.firstDay)},
                {id: "timeFormat", name: <Trans i18nKey={"TEXT_TIME_P"} />, value: getTimeByFormat(null, items.timeFormat)}
            ]
        }
    }

    const getWindowHeight = () => {
       return window.innerHeight - 180;
    }

    const onSaveMyInfo = (updateData) => {
        if(isEmpty(updateData) || (Object.keys(updateData).length == 1 && !isNil(updateData.currentPassword))){
            toastr.error(t("MIS_SID_NO_CHANGES_HAVE_BEEN_MADE"));
            return;
        }
        let valid = true;
        if(Object.keys(error).length > 0){
            Object.keys(error).map(key => {
                if(!isNil(error[key])){
                    toastr.error(error[key]);
                    valid = false;
                }
            })
        }

        if((isNil(updateData.currentPassword) || updateData.currentPassword == "")) {
            toastr.error(t("COM_MESSAGE_USER_ENTER_PASS_P"))
            valid = false;
        }

        if(!(isNil(updateData.password)||updateData.password == "") && isNil(updateData.passwordConfirm)){
            toastr.error(t("MESSAGE_USER_ENTER_RE_PASS_P"));
            valid = false;
        }

        if(isNil(updateData.newRoleId) && !isNil(updateData.nextAdminId)){
            toastr.error(t("MESSAGE_USER_SEL_ROLE_P"));
            valid = false;
        }

        if(!isNil(updateData.newRoleId) && (isNil(updateData.nextAdminId) || updateData.nextAdminId == "")){
            toastr.error(t("MESSAGE_USER_ENTER_ADMIN_ID_P"));
            valid = false;
        }

        if(!isNil(updateData.nextAdminId) && updateData.nextAdminId != "" && updateData.nextAdminId.trim() == ""){
            toastr.error(t("MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG"));
            valid = false;
        }

        if(!isNil(updateData.nextAdminId) && updateData.nextAdminId == items.userId){
            toastr.error(t("MESSAGE_USER_CANT_TRANSFER_ADMIN_P"));
            valid = false;
        }

        if(valid){
            onSave(updateData);
        }
    }

    return( 
        <>
        <div className="contents_buttonWrap" style={{display: currContent === 'MY_INFORMATION' ? 'block':'none'}}>
            <div className="leftButton float_l">
                {
                    viewmode === 'INFO' &&
                    <WhiteButton id={"EDIT_MY_INFORMATION"} name={t("TEXT_EDIT_P")}  onClick={() => ((JSON.parse(localStorage.getItem("myAccountNeedAuth"))) ? onChangeMode('CONFIRM_PASSWORD') : onChangeMode('UPDATE'))} />
                }
                {
                    viewmode === 'UPDATE' &&
                    <>
                    <WhiteButton id={"UPDATE_MY_INFORMATION"} name={t("COM_BUTTON_SAVE")}  onClick={() => onSaveMyInfo(updateData)} />
                    <WhiteButton id={"CANCEL_MY_INFORMATION"} name={t("BUTTON_CANCEL_P")}  onClick={() => changeView('INFO')} />
                    </>
                }
            </div>
        </div>
        <div className="settings_list mt20 my_info" style={{borderTop: '1px solid#f2f2f2', overflowY: 'auto', overflowX: 'hidden', height: getWindowHeight(), display: currContent === 'MY_INFORMATION' ? 'block':'none'}}>
            {
                viewmode != 'UPDATE' &&
                <SettingInformation id={"BASIC_INFORMATION"} title={t("COM_SID_BASIC_INFORMATION")} 
                list={getMyBasicItems(items)}/>
            }
            {
                viewmode == 'UPDATE' &&
                <SettingInformation id={"BASIC_INFORMATION"} title={t("COM_SID_BASIC_INFORMATION")} 
                list={getMyBasicItems(items)} comment={t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P")}/>
            }
            
            <SettingInformation id={"ORGANIZATION_INFORMATION"} title={t("TEXT_ORGANIZATION_INFO_P")} 
                list={getOrganItems(items)}/>
            <SettingInformation id={"DISPLAY_INFORMATION"} title={t("MIS_DEVICE_DISPLAY_INFO")} 
                list={getDisplayItems(items)}/>
            {viewmode === 'UPDATE' && isMfa &&
            <MfaManagement type={"MY_INFORMATION"} userId={items.userId}/>
            }
        </div>
        </>
    )   
}

export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadMyAccount: (data, mode) =>  dispatch(settingsAction.loadMyAccount(data, mode)),
        updateMyAccount: (data, changed) => dispatch(settingsAction.updateMyAccount(data, changed)),
        checkPassword: (data, password) => dispatch(settingsAction.checkPassword(data, password))
    })
)(withTranslation()(MyInformation));

const changeLocalStorage = (type, value) => {
    let user = JSON.parse(localStorage.getItem('user'));
    set(user, type, value);
    localStorage.setItem('user', JSON.stringify(user));
}
