import React, {useEffect, useRef, useState} from 'react';
import './RuleManager.css';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {ruleManagerService} from '../../services/rulemanager.service';

import {toastr} from 'helper/toastrIntercept';

const RuleManager = (props) => {

    const {t} = useTranslation();

    const width = '100%';
    const height = '100%';
    const frameboarder = 0;

    const [didMount, setMount] = useState(false);

    const [url, setUrl] = useState([]);
    const [ruleManagerInfo, setRuleManagerInfo] = useState({
        ruleManagerURL: '',
        ruleManagerAPIKey: '',
        mHash: '',
        userId: '',
        time: '',
        token: '',
        langCode: '',
        authCode: ''
    });

    useEffect(() => {
        fetchData();
        setMount(true);
    }, []);

    const ruleManagerForm = useRef();

    useEffect(()=> {
        if(didMount){
            if(ruleManagerInfo.ruleManagerURL !== '') {
                ruleManagerForm.current.submit();
            }
        }
    },[ruleManagerInfo]);

    const fetchData = () => {
        ruleManagerService.getRuleManagerServerInformation()
        .then(data => {
            setUrl(`${data.items.ruleManagerURL}/api/v1/mi/sso`) ;
            setRuleManagerInfo({
                ruleManagerURL : data.items.ruleManagerURL,
                ruleManagerAPIKey : data.items.ruleManagerAPIKey,
                mHash : data.items.mHash,
                userId : data.items.userId,
                time : data.items.time,
                token : data.items.token,
                langCode : data.items.langCode,
                authCode : data.items.authCode,
            });
        })
        .catch(error => {
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        })
    }

    return (
        <div className="ruleManagerWrap" style={{display: props.currContent === 'RULE_MANAGER' ? 'block':'none'}}>
            <iframe name="ruleManageriFrame" src={url} frameBorder={frameboarder} width={width} height={height}  id='ruleManageriFrame'>
            </iframe>
            <form ref={ruleManagerForm} action={url} method='post' style={{display:'none'}} target='ruleManageriFrame'>
                <input type="hidden" name="apikey" value={ruleManagerInfo.ruleManagerAPIKey} />
                <input type="hidden" name="userid" value={ruleManagerInfo.userId} />
                <input type="hidden" name="hash" value={ruleManagerInfo.mHash} />
                <input type="hidden" name="date" value={ruleManagerInfo.time} />
                <input type="hidden" name="token" value={ruleManagerInfo.token} />
                <input type="hidden" name="authcode" value={ruleManagerInfo.authCode} />
                <input type="hidden" name="langcode" value={ruleManagerInfo.langCode} />
            </form>
        </div>
    )
}

export default connect(
    state => ({

    }),
    dispatch => ({
    })
)(RuleManager);