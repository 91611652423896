import React from 'react';
import {useTranslation} from "react-i18next";

const PlaylistTypeFilterStatus = ({playlistTypeChanged, selected, playlistTypes}) => {

    const {t} = useTranslation();
    const getPlaylistTypeByTypeId = typeId => {
        let type = playlistTypes.find(type => type.id == typeId)
        return type ? type.name : '';
    }
    return (
        <>

            {
                selected.playlistTypes && (selected.playlistTypes.length === playlistTypes.length) ?
                    <span>
                        <span className="filter_status_playlist_type"><span>{t("COM_SID_ALL")}</span></span>
                    </span>
                    :
                    selected.playlistTypes.map(
                        playlistId => (
                            <span>
                                <span className="filter_status_playlist_type"><span>{t(getPlaylistTypeByTypeId(playlistId))}</span></span>
                            </span>
                    ))
            }



        </>
    )
}
export default PlaylistTypeFilterStatus;