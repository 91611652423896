import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { popupAction } from "../../../../actions";
import { commonConstants } from "../../../../constants";

const PlaylistType = ({ playType, play, device, addContentList }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [contents, setContents] = useState(play?play.contentList:[]);


    const selectContents = selected => {
        const newContents = selected;
        setContents(newContents);
        addContentList(newContents);
    };

    const deleteContent = (id) => {      
        const newContent = contents.filter(i => i.contentId !== id);
        setContents(newContent);
        addContentList(newContent);
    }

    const openContentsPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                type: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                handleSelected: selectContents,
                contentType: playType,
                device: device,
                onClose: () =>
                    dispatch(
                        popupAction.closePopup(
                            commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP
                        )
                    )
            })
        );
    };

    return (
        <>
            <table className="content_result">
                <tbody>
                    <tr>
                        <th width ="150px">{t("TEXT_TITLE_PLAYLIST_P")}</th>
                        <td>
                            <button
                                className="base searchBtn"
                                onClick={openContentsPopup}
                            ></button>
                            </td>
                    </tr>
                        <tr><th></th><td>
                            <div className="ruleset_play_selected_item_box">
                                {contents.map((item, index) => (
                                    <div
                                        style={{
                                            padding: "5px",
                                            borderBottom: "1px solid #e7e7e7"
                                        }}
                                    >
                                        <input
                                            type="hidden"
                                            value={item.contentId}
                                        />
                                        <span>{item.contentName}</span>
                                        <button
                                            style={{ float: "right" }}
                                            className="selectedDeviceDelBtn"
                                            onClick = {() => {deleteContent(item.contentId)}}
                                        ></button>                                        
                                    </div>
                                ))}
                            </div></td></tr>                            
                </tbody>
            </table>
        </>
    );
};

export default PlaylistType;
