import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import toastr from 'toastr';
import {contentService} from "../../../services";
import {popupAction} from "../../../actions";
import { commonConstants} from "../../../constants";
import './ContentUseTab.css';
import {getErrorMessage} from "../../../helper/responseHandler";
import 'react-perfect-scrollbar/dist/css/styles.css';

const ContentUseTab = ({content,closePopup,openDetailView}) => {
    const [playlistUse, setPlaylistUse] = useState([]);
    const [scheduleUse, setScheduleUse] = useState([]);

    const {t} = useTranslation();
    const fetchContentUseByContentId = () => {
        contentService.fetchContentUse(content.contentId)
            .then(res=> {
                if (res && res.items) {
                    setPlaylistUse(res.items.filter(item => item.playlist !== ""));
                    setScheduleUse(res.items.filter(item => item.schedule !== ""));
                }
            }).catch(e=> toastr.error(getErrorMessage(e)))
    };

    const openPlaylistView = (id) => {
        openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'PLAYLIST',
            id: id,
            onSave: () => {
               fetchContentUseByContentId();
            },
            onClose: () => {closePopup(id) ;} 
        });
    };

    const openSchedulePopup = programId => {
        openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW, 
            viewType: 'SCHEDULE_CONTENT', 
            id: programId,
        });

    }

    useEffect(() => {
        fetchContentUseByContentId();
    }, [])

    return (
        <div className={'contentUse_tab'}>
            <div className="detail_view">
                <div>
                    {t("TEXT_TITLE_PLAYLIST_P")}
                </div>
                <table>
                    <colgroup>
                        <col width="150px"/>
                        <col width=""/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th style={{width: '201px'}}>{t("TABLE_PLAYLIST_NAME_P")}</th>
                        <th style={{width: '198px'}}>{t("SETUP_NEW_STRING29_P")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        playlistUse && playlistUse.map((pl , idx) => {
                            return (
                                <tr key={idx}>
                                <td>
                                    <div title={pl.playlist} style={{width: 201,whiteSpace: "normal",wordWrap: "break-word"} }> <span onClick={() => openPlaylistView(pl.playlistId)}>{pl.playlist}</span></div>
                                </td>
                                <td>
                                <div title={pl.playlistOrganization} style={{width: 198,whiteSpace: "normal",wordWrap: "break-word"}}>{pl.playlistOrganization}</div>
                                </td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </table>

                <div className='content_div'>{t("TEXT_CONTENT_SCHEDULE_P")}</div>
                <table>
                    <colgroup>
                        <col width="150px"/>
                        <col width=""/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th style={{width: '201px'}}>{t("TEXT_SCHEDULE_NAME_P")}</th>
                        <th style={{width: '198px'}}>{t("SETUP_NEW_STRING29_P")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        scheduleUse && scheduleUse.map((sch , idx) => {
                            return (
                                <tr key={idx}>
                                <td>
                                    <div title={sch.schedule} style={{width: 201,whiteSpace: "normal",wordWrap: "break-word"}}><span onClick= { () => openSchedulePopup(sch.programId)}>{sch.schedule}</span></div>
                                </td>
                                <td>
                                <div title={sch.scheduleOrganization} style={{width: 198,whiteSpace: "normal",wordWrap: "break-word"}}>{sch.scheduleOrganization}</div>
                                </td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default connect(
    state => ({
        menu: state.menu,
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(ContentUseTab);