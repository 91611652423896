import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {popupAction} from "../../../../actions";
import {commonConstants, CONTENT_SCHEDULE_PROGRAM_TYPE_AD} from "../../../../constants";


const ContentScheduleContents = ({program, openDetailView}) => {

    const {t} = useTranslation();

    const openContentDetail = (contentId) => {
        openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'CONTENT', id: contentId});
    }

    const openPlaylistDetail = (playlistId) => {
        openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'PLAYLIST', id: playlistId});
    }

    const openRulesetDetail = (rulesetId) => {
        openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW,
            id: commonConstants.RULESET_DETIAL_POPUP,
            viewType: "RULESET",
            rulesetId: rulesetId
        });
    }

    const ScheduleTr = () => {

        const renderGeneralContentSchedule = () => {

            return (
                program.channels.map((channel) =>
                    channel.frame !== undefined &&
                    <tr>
                        <td className="va_t">
                            <span style={{width:100,display:'inline-block',flot:'left',overflow:'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis'}}>{channel.channelName}</span>
                            <span style={{width:30,display:'inline-block',float:'right'}}>{channel.channelNo}</span>
                        </td>
                        <td>
                            <ul>
                                {
                                    channel.frame.events !==undefined && channel.frame.events.length > 0 && channel.frame.events.map(
                                        schedule =>
                                        <li style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 225, overflow: 'hidden', height: 19}}>
                                            {
                                                schedule.contentType === 'HW_IS' &&
                                                <span>{schedule.contentName}</span>
                                            }
                                            {
                                                schedule.contentType !== 'HW_IS' &&
                                                <>
                                                {
                                                    schedule.contentType === 'HW_IS' &&
                                                    <span>{schedule.contentName}</span>
                                                }
                                                {
                                                    schedule.contentType == "PLAYLIST" && 
                                                    <a href="#" onClick={()=>openPlaylistDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                }
                                                {
                                                    schedule.contentType == "RULESET" && 
                                                    <a href="#" onClick={()=>openRulesetDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                }
                                                {
                                                    schedule.contentType != "PLAYLIST" && schedule.contentType != "RULESET" && 
                                                    <a href="#" onClick={()=>openContentDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                }
                                                </>
                                            }
                                        </li>
                                    )
                                }
                                
                                {
                                    channel.frame.frames !==undefined && channel.frame.frames.map(
                                        frame => {
                                            if(frame.events !== undefined) {
                                                for(let i = 0; i < frame.events.length; i++) {
                                                    const schedule = frame.events[i];
                                                    return (
                                                        <li style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 225, overflow: 'hidden', height: 19}}>
                                                        {
                                                            schedule.isHW &&
                                                            <span>{schedule.contentName}</span>
                                                        }
                                                        {
                                                            !schedule.isHW &&
                                                            <>
                                                            {
                                                                schedule.contentType == "PLAYLIST" && 
                                                                <a href="#" onClick={()=>openPlaylistDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                            }
                                                            {
                                                                schedule.contentType == "RULESET" && 
                                                                <a href="#" onClick={()=>openRulesetDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                            }
                                                            {
                                                                schedule.contentType != "PLAYLIST" && schedule.contentType != "RULESET" && 
                                                                <a href="#" onClick={()=>openContentDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                            }
                                                            </>
                                                        }
                                                    </li>
                                                )}
                                            }
                                        }
                                    )
                                }
                            </ul>
                        </td>
                    </tr>
                )
            )
        }

        const renderADContentSchedule = () => {
            return (
                program.channels[0].frame.adSlots !== undefined && program.channels[0].frame.adSlots.length > 0 &&
                program.channels[0].frame.adSlots.map(
                    slot =>
                        <tr>
                            <td className="va_t">
                                <span style={{width:100,display:'inline-block',flot:'left',overflow:'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis'}}>{slot.slotName}</span>
                            </td>
                            <td>
                                <ul>
                                    {
                                        slot.events !==undefined && slot.events.length > 0 && slot.events.map(
                                            schedule =>
                                                <li style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 225, overflow: 'hidden'}}>
                                                    <a href="#" onClick={()=>openPlaylistDetail(schedule.contentId)}>{schedule.contentName}</a>
                                                </li>
                                        )
                                    }
                                </ul>
                            </td>
                        </tr>
                )
            )
        }

        return program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? renderADContentSchedule() : renderGeneralContentSchedule()
    }

    return (
        <div className="detail_view mini_p mt8">
            <div style={{paddingTop: 19}}>
                <div className="pop_list_2" style={{height:300}}>
                    <table>
                        <colgroup>
                            <col width="164px"/>
                            <col width="220px"/>
                        </colgroup>
                        <thead>
                        <tr>
                            {
                                program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? <th style={{width:115}}>{t("COM_TEXT_CHANNEL_P")}</th> : <th style={{width:115}}>{t("MIS_SID_20_SLOTS")}</th>
                            }
                            <th style={{width:240}}>{t("TEXT_CONTENT_P")}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <ScheduleTr/>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default connect(
    null,
    dispatch => ({
        openDetailView : (type)=> dispatch(popupAction.openDetailView(type))
    })
)(ContentScheduleContents);