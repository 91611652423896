import {scheduleConstants} from '../constants';

export const scheduleAction = {
    
    initContentSchedule,
    initADSchedule,
    updateContentSchedule,
    updateContentADSchedule,
    destroyContentSchedule,
    addSimpleSchedule,
    initNewMessage,
    addMessage,
    loadMessage,
    removeMessage,
    setRemoveIndex,
    updateMessage,
    destroyMessage,
    initEditMessage,

    initEventCondition,
    addEventCondition,
    removeEventCondition,
    updateEventCondition,

    initEventSchedule,
    addEvent,
    removeEvent,
    loadEvent,
    updateEvent,

    loadSimpleScheduleSubmenu,
    unLoadSimpleScheduleSubmenu,

    startDrag,
    destroyDragInfo
}

function initContentSchedule(mode, device = {deviceType: 'SPLAYER', deviceTypeVersion: 1}, programType = 'LFD', program = {}, deviceTags) {
    return {
        type: scheduleConstants.INIT_CONTENT_SCHEDULE,
        mode: mode,
        device: device,
        programType: programType,
        program: program,
        deviceTags: deviceTags
    }
}

function initADSchedule(mode, device = {deviceType: 'SPLAYER', deviceTypeVersion: 4}, programType = 'ADV', program = {}, adScheduleInfo) {
    return {
        type: scheduleConstants.INIT_CONTENT_ADVERTISEMENT_SCHEDULE,
        mode: mode,
        device: device,
        programType: programType,
        program: program,
        adScheduleInfo: adScheduleInfo
    }
}

function updateContentSchedule(mode, program) {
    return {
        type: scheduleConstants.UPDATE_CONTENT_SCHEDULE,
        mode: mode,
        program: program
    }
}

function destroyContentSchedule(mode) {
    return {
        type: scheduleConstants.DESTROY_CONTENT_SCHEDULE,
        mode: mode,
    }
}

function initNewMessage(device) {
    return {
        type: scheduleConstants.INIT_NEW_MESSAGE,
        device: device
    }
}

function initEditMessage(message) {
    return {
        type: scheduleConstants.INIT_EDIT_MESSAGE,
        message: message
    }
}

function addMessage(mode, message) {
    return {
        type: scheduleConstants.ADD_MESSAGE,
        message: message,
        mode: mode
    }
}

function removeMessage(mode, index) {
    return {
        type: scheduleConstants.REMOVE_MESSAGE,
        messageId: index,
        mode: mode
    }
}

function loadMessage(mode, messageId) {
    return {
        type: scheduleConstants.LOAD_MESSAGE,
        messageId: messageId,
        mode: mode
    }
}

function setRemoveIndex(mode, index) {
    return {
        type: scheduleConstants.SET_REMOVE_INDEX,
        index: index,
        mode: mode
    }
}

function updateMessage(mode, message) {
    return {
        type: scheduleConstants.UPDATE_MESSAGE,
        mode: mode,
        message: message
    }
}

function destroyMessage(mode) {
    return {
        type: scheduleConstants.DESTROY_MESSAGE,
        mode: mode
    }
}

function addEventCondition(mode, value) {
    return {
        type: scheduleConstants.ADD_EVENT_CONDITION,
        mode: mode
    }
}

function initEventCondition(mode, event) {
    if (mode === 'new') {
        return {
            type: scheduleConstants.INIT_NEW_EVENT_CONDITION,
            event: event
        }
    }  else {
        return {
            type: scheduleConstants.INIT_EDIT_EVENT_CONDITION,
            event: event
        }
    }

}

function removeEventCondition(mode, id) {
    return {
        type: scheduleConstants.REMOVE_EVENT_CONDITION,
        mode: mode,
        id: id
    }
}

function updateEventCondition(mode, eventCondition) {
    return {
        type: scheduleConstants.UPDATE_EVENT_CONDITION,
        mode: mode,
        eventCondition: eventCondition
    }
}

function initEventSchedule(mode, event) {
    return {
        type: scheduleConstants.INIT_EVENT_SCHEDULE,
        mode: mode,
        event: event
    }
}

function addEvent(mode) {
    return {
        type: scheduleConstants.ADD_EVENT_SCHEDULE,
        mode: mode
    }
}

function removeEvent(mode, id) {
    return {
        type: scheduleConstants.REMOVE_EVENT_SCHEDULE,
        mode: mode,
        id: id
    }
}

function loadEvent(mode, id) {
    return {
        type: scheduleConstants.LOAD_EVENT,
        id: id,
        mode: mode
    }
}

function updateEvent(mode, event) {
    return {
        type: scheduleConstants.UPDATE_EVENT,
        event: event,
        mode: mode
    }
}

function loadSimpleScheduleSubmenu() {
    return {
        type: scheduleConstants.LOAD_SIMPLE_CONTENT_SCHEDULE,
    }
}

function unLoadSimpleScheduleSubmenu(programId) {
    return {
        type: scheduleConstants.UNLOAD_SIMPLE_CONTENT_SCHEDULE,
        programId: programId
    }
}

function addSimpleSchedule(programId, programType) {
    return {
        type: scheduleConstants.ADD_SIMPLE_SCHEDULE,
        programId: programId,
        programType: programType
    }
}

function updateContentADSchedule(mode, slots, slotCount, slotDuration) {
    return {
        type: scheduleConstants.UPDATE_CONTENT_AD_SCHEDULE,
        mode: mode,
        slots: slots,
        slotCount: slotCount,
        slotDuration: slotDuration
    }
}

function startDrag(id, contentType, mediaType) {
    return {
        type: scheduleConstants.START_DRAG,
        id: id,
        contentType: contentType,
        mediaType: mediaType
    }
}

function destroyDragInfo() {
    return {
        type: scheduleConstants.DESTROY_DRAG_INFO,
    }
}