import React from 'react';
import {Loading} from "../loading/Loading";
import Checkbox from "../checkbox/Checkbox";
import {useTranslation} from "react-i18next";


const PlaylistTypes = ({playlistTypes, togglePlaylistTypeAll, togglePlaylistType, playlistTypeCheckBox, playlistTypeCheckAll,selectedPlaylistType}) => {
    const {t} = useTranslation();

    const isChecked = (playlistTypeId) => {
        if(selectedPlaylistType.length == playlistTypes.length || selectedPlaylistType[0] ==='ALL')
            return true;
        else {
            let idx = selectedPlaylistType.findIndex( (item) => (item == playlistTypeId));
            return idx >= 0 ? true: false
        }
    }

    return (
        <div className="dev_filter_type_checkbox" id="filter_list_device_type">
            <h5><span className="device_type" style={{background:'#ffc446'}}></span>{t("MIS_SID_PLAYLIST_TYPE")}</h5>
            <div>
                <ul className="type_checklist" style={{overflowY:'auto'}}>
                    <li>
                        <Checkbox id={"contentTab_device_type_all"}
                                  name={t("COM_SID_ALL")}
                                  ref={playlistTypeCheckAll}
                                  onChange={togglePlaylistTypeAll}
                                  checked={(selectedPlaylistType.length == playlistTypes.length || selectedPlaylistType[0] ==='ALL') ? true : false}
                                  defaultChecked={true}
                        />
                    </li>
                    {
                        playlistTypes.length > 0 ?
                            playlistTypes.map(
                                (playlistType, index) => (
                                    <li key={index} style={{display: 'flex'}}>
                                        <Checkbox id={"filter_playlist_type_"+index}
                                              name={t(playlistType.name)}
                                              ref={ref => playlistTypeCheckBox[playlistType.id] = ref}
                                              onChange={()=>togglePlaylistType(playlistType.id)}
                                              checked = {isChecked(playlistType.id)}
                                        />
                                    </li>
                                )
                            ) : <Loading />
                    }
                </ul>
            </div>
        </div>
    )
}
export default PlaylistTypes;