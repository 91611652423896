import MISDialog from "../MISDialog";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { rulesetService } from "../../../services";
import { useTranslation } from "react-i18next";
import { commonConstants, playlistConstants } from "../../../constants";
import SubTab from "../../tab/SubTab";
import { getMixString } from "../../../language/languageUtils";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import Moment from "react-moment";
import { SearchButton } from "../../button/SearchButton";
import { menuAction, popupAction, rulesetAction } from "../../../actions";
import { toastr } from "helper/toastrIntercept";
import GroupsPopup from "../GroupsPopup";
import "./RulesetDetailPopup.css";
import Size from "../../utils/Size";
import {getErrorMessage} from "../../../helper/responseHandler";
import {useMISOpt} from "../../../components/misopt";
import {toMomentDateTimeFormat} from 'helper/utils';

const RulesetDetailPopup = ({ id, zIndex, rulesetId, onClose, refresh }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ruleset, setRuleset] = useState({});
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const [group, setGroup] = useState({
        groupId: ruleset.groupId,
        groupName: ruleset.groupName,
    });

    const [contents, setContents] = useState([]);

    const [rulesetName, setRulesetName] = useState("");

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const [defaultContent, setDefaultContent] = useState({
        defaultPlay: ruleset.defaultPlay,
        defaultPlayName: ruleset.defaultPlayName,
    });

    const [popups, setPopups] = useState({
        rulesetGroupPopup: false,
    });
    const [description, setDescription] = useState(ruleset.description !== undefined ? ruleset.description : "");
    const subTabs = ["BUTTON_DETAIL_P", "DID_MAIN_CONTENT"];
    const [subTab, setSubTab] = useState(subTabs[0]);

    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('CONTENT_PLAYLIST_RULESET'));

    const fetchData = () => {
        dispatch(popupAction.addPopup({ id: "RULESET_DETAIL_LOADING_POPUP", type: commonConstants.LOADING_POPUP }));

        rulesetService
            .fetchRulesetById(rulesetId)
            .then((res) => {
                const ruleset = res.items;
                setRuleset(ruleset);
                setRulesetName(ruleset.name);
                setDefaultContent({
                    defaultPlay: ruleset.defaultPlay,
                    defaultPlayName: ruleset.defaultPlayName,
                });
                setDescription(ruleset.description);
                setGroup({
                    groupId: ruleset.groupId,
                    groupName: ruleset.groupName,
                });
            })
            .catch((e) => {
                toastr.error(getErrorMessage(e));
                closePopup();
            })
            .finally(() => {
                dispatch(popupAction.closePopup("RULESET_DETAIL_LOADING_POPUP"));
            });
    };

    const fetchContent = () => {
        rulesetService.fetchContentInRuleset(rulesetId).then((res) => {
            const content = res.items.map((item) => {
                return {
                    contentId: item.contentId,
                    contentName: item.contentName,
                    contentSize: item.contentSize,
                    mediaType: item.mediaType,
                    playTime: item.playTime,
                    contentType: item.contentType,
                };
            });
            const contentList = contents.concat(content);
            setContents(contentList);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setRuleset({
            ...ruleset,
            name: value,
        });
    };

    const onClickSubTab = (id) => {
        setSubTab(id);
    };

    const setRulesetGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
            setGroup(groups[0]);
            ruleset.groupId = groups[0].groupId;
            ruleset.groupName = groups[0].groupName;
        } else {
            toastr.error(t("MESSAGE_COMMON_SELECT_GROUP_P"));
        }
    };

    const saveRulesetDetail = () => {
        if (ruleset.name.length > 200) {
            toastr.error(t("MESSAGE_COMMON_LIMIT_OVER_LENGTH_P"));
            return false;
        } else if (ruleset.name.length === undefined || ruleset.name.length === 0) {
            toastr.error(getMixString(["MIS_SID_MIX_ENTER_A_NAME", "MIS_SID_RULESET"]));
        } else if (ruleset.name.trim() === "") {
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
        } else {
            closePopup();
            rulesetService
                .updateRuleset(ruleset)
                .then((res) => {
                    toastr.success(t("COM_TEXT_SUCCESS_P"));
                    if (refresh) {
                        refresh();
                    }
                })
                .catch((error) => {
                    toastr.error(error);
                })
                .finally(() => {
                    dispatch(menuAction.reloadGroup("RULESET_BY_GROUP"));
                });
        }
    };

    const openEditRuleset = () => {
        closePopup();
        rulesetService.fetchRulesetById(rulesetId).then((res) => {
            if (res.items) {
                const tab = {
                    id: "EDIT_RULESET",
                    title: getMixString(["MIS_SID_MIX_CAFEB_EDIT", "MIS_SID_RULESET"]),
                    active: true,
                    close: true,
                };
                dispatch(menuAction.addTab(tab));
                dispatch(rulesetAction.initEditRuleset(res.items));
                dispatch(menuAction.loadContent("EDIT_RULESET"));
            }
        });
    };

    const renderPlaylistType = (playlistType) => {
        function getPlaylistType(el) {
            return el.id === playlistType;
        }
        const playlist = playlistConstants.PLAYLISTTYPES.find(getPlaylistType);

        let playlistTypeName = t(playlist.name);
        if (playlistType === "5") {
            if (playlist.ignoreTag === 1) {
                playlistTypeName += ", " + t("MIS_SID_SERVER_IGNORE_TAG");
            }
            if (playlist.evennessPlayback === 1) {
                playlistTypeName += ", " + t("MIS_SID_SERVER_PLAY_EVENTLY");
            }
        }
        return (
            <span>
                {t("TEXT_TITLE_PLAYLIST_P")}
                {"("}
                {t(playlistTypeName)}
                {")"}
            </span>
        );
    };

    const renderContentDetailValue = (contentSize, mediaType, playTime, contentType) => {
        return (
            <span>
                {contentType === "CONTENT" ? mediaType : renderPlaylistType(mediaType)}
                {","}
                <Size size={contentSize} /> <br></br>
                <span>{contentType === "PLAYLIST" || mediaType === "MOVIE" ? playTime : ""}</span>
            </span>
        );
    };

    const openContentDetailPopup = (e, contentId, contentType) => {
        dispatch(
            popupAction.openDetailView({
                type: commonConstants.COMMON_DETAIL_VIEW,
                viewType: contentType,
                id: contentId,
                onSuccess: () => fetchData(),
                onClose: () => closePopup(contentId),
            })
        );
    };

    const openGroupPopup = () => {
        dispatch(
            popupAction.addPopup({
                mode: "ruleset",
                checkbox: false,
                type: commonConstants.COMMON_GROUP_POPUP,
                viewType: "CONTENT",
                id: commonConstants.COMMON_GROUP_POPUP,
                save: (groups) => {
                    setRulesetGroup(groups);
                },
                close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
            })
        );
    };

    const showDefaultContentSelectPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                type: commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP,
                handleSelected: (selectedContents) => {
                    if (selectedContents.length === 1) {
                        setDefaultContent({
                            defaultPlay: selectedContents[0].contentId,
                            defaultPlayName: selectedContents[0].contentName,
                        });
                        setRuleset({
                            ...ruleset,
                            defaultPlay: selectedContents[0].contentId,
                            defaultPlayName: selectedContents[0].contentName,
                        });
                    }
                },
                contentType: "content",
                multiSelect: false,
                device: {
                    deviceType: ruleset.deviceType,
                    deviceTypeVersion: ruleset.deviceTypeVersion,
                },
                onClose: () => dispatch(popupAction.closePopup(commonConstants.SELECT_MULTIPLE_CONTENTS_POPUP)),
            })
        );
    };

    useEffect(() => {
        fetchData();
        fetchContent();
    }, []);

    const emptyContent = () => {
        setDefaultContent({
            defaultPlay: "",
            defaultPlayName: "",
        });

        setRuleset({...ruleset, defaultPlay:"", defaultPlayName:""});
    };

    const leftButtons = [];
    const rightButtons = [];

    if(authority.CREATE || authority.MANAGE) {
        leftButtons.push({
            id: "RULESET_DETAIL_EDIT",
            title: t("COM_BUTTON_EDIT"),
            onClick: () => openEditRuleset(),
            disable: false,
        });
        rightButtons.push({
            id: "RULESET_DETAIL_SAVE",
            title: t("COM_BUTTON_SAVE"),
            onClick: () => saveRulesetDetail(),
            disable: false,
        });
    }

    rightButtons.push({
        id: "RULESET_DETAIL_CLOSE",
        title: t("COM_BUTTON_CLOSE_P"),
        onClick: () => closePopup(),
    });

    return (
        <>
            <MISDialog
                dialog={{
                    title: rulesetName,
                    isDraggable: true,
                    closeOnEscape: true,
                    modal: false,
                    zIndex: zIndex,
                    width: 500,
                    height: 400,
                    allowMinimize: false,
                    position: { x: -300, y: -400 },
                    onClose: () => closePopup(),
                }}
                buttons={{
                    leftButtons: leftButtons,
                    rightButtons: rightButtons,
                }}
            >
                {popups.rulesetGroupPopup && (
                    <GroupsPopup
                        mode="ruleset"
                        checkbox={false}
                        close={() => {
                            dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
                        }}
                        save={(groups) => setRulesetGroup(groups)}
                    />
                )}
                <div>
                    <div className="type1">
                        <div className=" mb15">
                            <SubTab tabs={subTabs} selected={subTab} viewSize={4} onClick={onClickSubTab} />
                            {subTab === subTabs[0] && (
                                <div className="detail_view mt15" id="detailsDiv">
                                    <div>
                                        <div style={{ overflowX: "hidden" }}>
                                            <table style={{ borderSpacing: 0 }}>
                                                <colgroup>
                                                    <col width="30%" />
                                                </colgroup>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <span className="popupTh">{getMixString(["MIS_SID_MIX_NAME", "MIS_SID_RULESET"])}</span>
                                                        </th>
                                                        <td>
                                                            <input type="text" id="contentNameInput" name="rulesetName" value={ruleset.name} style={{ width: "85%" }} onChange={handleChange} />
                                                            <div>
                                                                <span className="error_txt2" id="contentNameErrorMsg"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <td>
                                                            <table id="rulesetDetailBox">
                                                                <tbody>
                                                                    <tr>
                                                                        <th width={"30%"}>
                                                                            <span className="popupTh">{t("COM_MAPP_SID_SUPPORTED_DEVICES")}</span>
                                                                        </th>
                                                                        <td className="icon">{ruleset !== undefined && ruleset.deviceType !== undefined && <SupportedDeviceIcon deviceType={ruleset.deviceType} deviceTypeVersion={ruleset.deviceTypeVersion} />}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th width={"30%"}>
                                                                            {" "}
                                                                            <span className="popupTh">{t("COM_TEXT_MODIFY_DATE_P")}</span>
                                                                        </th>
                                                                        <td>
                                                                            {" "}
                                                                            <Moment format={dateTimeFormat}>{ruleset.modifyDate}</Moment>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th width={"30%"}>
                                                                            {" "}
                                                                            <span className="popupTh">{t("TEXT_STATUS_P")}</span>
                                                                        </th>
                                                                        <td>
                                                                            {" "}
                                                                            <span className={ruleset.isTemp ? "ruleset-unschedulable" : ""}>
                                                                                {ruleset.programId && t("COM_SID_USED_KR_MIS20")}
                                                                                {ruleset.programId === null && !ruleset.isTemp && t("MIS_SID_NOT_USED")}
                                                                                {ruleset.programId === null && ruleset.isTemp && t("MIS_SID_CAMAR_IMCOMPLETE")}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                    <tr className="ruleset">
                                                        <th>
                                                            <span className="popupTh">{t("TEXT_GROUP_P")}</span>
                                                        </th>
                                                        <td>
                                                            <div className="menu_select_box">
                                                                <input type="hidden" id="verificationDeviceGroupId" />
                                                                <button className="base un_radius select" style={{ width: 317, borderRadius: "5px" }} id="verificationDeviceGroupBtn" onClick={openGroupPopup}>
                                                                <span>
                                                                    <span title={group.groupName} style={{ width:280, display:"inline-block", overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                                                    {group.groupName}   </span>
                                                                    <span className="arrow" style={{display:'inline-block', float:'right'}} ></span>                                                                 
                                                                </span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="ruleset">
                                                        <th>
                                                            <span className="popupTh">{t("COM_TEXT_DEFAULT_CONTENT_P")}</span>
                                                        </th>
                                                        <td>
                                                            <div className="float_l mr10">
                                                                <SearchButton onClick={showDefaultContentSelectPopup} />
                                                            </div>
                                                            <div
                                                                className="tag_input type2 float_l"
                                                                style={{
                                                                    width: 250,
                                                                    background: "none",
                                                                }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    style={{
                                                                        width: "100%",
                                                                        background: "none",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                    value={defaultContent.defaultPlayName ? defaultContent.defaultPlayName : ""}
                                                                    readOnly
                                                                />
                                                                {
                                                                    defaultContent.defaultPlayName && defaultContent.defaultPlayName !== ''&&
                                                                    <span onClick={emptyContent} className={"ruleset_empty_button"}></span>
                                                                }
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="ruleset">
                                                        <th>{t("TEXT_DESCRIPTION_P")}</th>
                                                        <td>
                                                            <textarea
                                                                id="playlistDescription"
                                                                placeholder={t("MIS_SID_CAFEB_ENTER_DESCRIPTION")}
                                                                name="metaData"
                                                                value={description}
                                                                onChange={(e) => {
                                                                    setDescription(e.target.value);
                                                                    setRuleset({
                                                                        ...ruleset,
                                                                        description: e.target.value,
                                                                    });
                                                                }}
                                                                maxLength={200}
                                                                style={{ width: "85%", minHeight: 80 }}
                                                            ></textarea>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {subTab === subTabs[1] && (
                                <div className="ruleset_detail_tag_wrap">
                                    <div style={{ paddingTop: "1px", paddingBottom: "3px" }}>
                                        <div className="ruleset_detail_popup_tag">
                                            <table>
                                                <colgroup>
                                                    <col width="250px" />
                                                    <col width="250px" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th width="250px">{t("TEXT_CONTENT_NAME_P")}</th>
                                                        <th width="250px">{t("BUTTON_DETAIL_P")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contents &&
                                                        contents.map((item, index) => {
                                                            return (
                                                                <tr key={item.contentId}>
                                                                    <td style={{ wordBreak: "break-all", width: 250 }}>
                                                                        <div>
                                                                            <span onClick={(e) => openContentDetailPopup(e, item.contentId, item.contentType)}>{item.contentName}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ wordBreak: "break-all", width: 250 }}>{renderContentDetailValue(item.contentSize, item.mediaType, item.playTime, item.contentType)}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </MISDialog>
        </>
    );
};
export default RulesetDetailPopup;
