import React, {memo} from 'react';
import './SwitchListNImage.css';
import classNames from 'classnames';

const SwitchListNImage = ({type, view, onClick}) => {
    const iconType = type !== undefined ? type : '';
    return (
        <div className="viewMode_wrap">
            {iconType === ''
                &&
                <div>
                    <button className={classNames({'view_list' : true, 'on': view === 'LIST'})} onClick={() => onClick('LIST')}>
                        <span></span>
                    </button>
                    <button className={classNames({'view_thum' : true, 'on': view === 'IMAGE'})} onClick={() => onClick('IMAGE')}>
                        <span></span>
                    </button>
                </div>
            }
            {iconType === 'contentSchedule'
                &&
                <div className={"content_schedule_switch_wrap"}>
                    <button className={classNames("base", {'view_thum' : view === 'IMAGE', 'view_list': view === 'LIST'})} id="contentBrowserViewBtn" style={{backgroundColor:'#333c4e'}} onClick={onClick}><span></span></button>
                </div>
            }
        </div>
    )
}
export default memo(SwitchListNImage);