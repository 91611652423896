import {isNil} from 'lodash';
import i18n from '../language/i18n';
import {ErrorConstants} from '../constants/ErrorConstants';
import {history} from './history';


/*export function getErrorMessage(data, defaultMsg) {
    const msg = getErrorMessageWithCode(data.errorCode, defaultMsg);
    return i18n.t(msg);
}*/

export function getErrorMessage(data, defaultMsg) {
    try{
        const msg = getErrorMessageWithCode(data.errorCode, defaultMsg);
        return i18n.t(msg);
    } catch(e){
        return i18n.t('COM_IDS_MSG_UNEXPEXTED_ERROR');
    }
    
}

export function getErrorMessageWithCode(code, defaultMsg){
    const defaultMessage = !isNil(defaultMsg)?defaultMsg:'COM_IDS_MSG_UNEXPEXTED_ERROR';

    return !isNil(ErrorConstants[code])?ErrorConstants[code]:defaultMessage;
}

function logout() {
    localStorage.removeItem('user');
    history.push('/login');
}


