import React from 'react';
import {connect} from 'react-redux';
import {alertActions, userActions} from '../../actions';
import queryString from 'query-string';
import {withTranslation} from "react-i18next";
import {userConstants} from "../../constants";
import OtpSetupPopup from "../../components/popup/login/OtpSetupPopup";
import OtpLoginPopup from "../../components/popup/login/OtpLoginPopup";
import ResetPopup from "../../components/popup/login/ResetPopup";
import {history} from "../../helper";
import { authServerUtil } from 'helper/authserver/authServerUtil';
import {commonService} from '../../services';
import {isNil} from 'lodash';

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        const username = localStorage.getItem('magicInfoUserId') || '';

        this.state = {
            username: username,
            password: '',
            resetPopup: false,
            resetTitle : '',
            useAuthServer: true
        };

        this.saveAuthServerInfo();
        this.requestToken();
    }

    saveAuthServerInfo() {
        const params = queryString.parse(this.props.location.search);
        const isAdmin = (!isNil(params) && !isNil(params.isAdmin) && params.isAdmin == 'Y')
        if(authServerUtil.isAuthServerUsed()) {
            authServerUtil.setIsAuthServerAdminLogin(isAdmin);
        } else {
            commonService.fetchCheckAuth().then(res => {
                if(res && res.url) {
                    authServerUtil.setAuthServerUse(true);
                    authServerUtil.setIsAuthServerAdminLogin(isAdmin);
                    if(res.exit_uri) {
                        authServerUtil.setAuthServerExitUrl(res.exit_uri);
                    }
                } else {
                    this.setState({useAuthServer:false});
                }
            }).catch(()=>this.setState({useAuthServer:false}));
        }
    }

    requestToken() {
        const {loginWithCode} = this.props;
        let params = queryString.parse(this.props.location.search);
        loginWithCode(params);
    }


    showClosePopup(title) {
        const {resetPopup, useAuthServer} = this.state;
        this.setState({resetPopup: !resetPopup});
        if(title !== undefined){
            this.setState({resetTitle: title});
        }else{
            this.setState({resetTitle: userConstants.RESET_PASSWORD});
        }

        if(resetPopup && useAuthServer){

            history.push('/');
        }

    }


    showCloseOtpSetupPopup() {

        const { initOtp } = this.props;
        initOtp();
    }


    showCloseOtpLoginPopup() {

        const { initOtp } = this.props;
        initOtp();
    }

    render() {
        const { username,  resetPopup, resetTitle, useAuthServer } = this.state;
        const { mfa } = this.props;
        return (
            <>
                {
                    resetPopup !== undefined && resetPopup &&
                    <ResetPopup type={resetTitle}  userId={username}
                                closePopup={()=>this.showClosePopup(userConstants.RESET_PASSWORD)} {...this.props}/>
                }
                {
                    mfa !== undefined && mfa.type === userConstants.LOGIN_SETUP_OTP &&
                    <OtpSetupPopup mfa={mfa}
                                   closePopup={()=>this.showCloseOtpSetupPopup()} {...this.props}/>
                }

                {
                    mfa !== undefined && mfa.type === userConstants.LOGIN_REQUIRED_OTP &&
                    <OtpLoginPopup mfa={mfa} resetPopup={()=>this.showClosePopup(userConstants.RESET_OTP)}
                                   closePopup={()=>this.showCloseOtpLoginPopup()} {...this.props}/>
                }
            </>
        );
        //<div className="container bg">Loading....</div>
    }
}
export default connect(
    state => ({
        authentication: state.authentication,
        error: state.users.error,
        mfa : state.users.mfa
    }),
    dispatch => ({
        logout: ()=> dispatch(userActions.logout()),
        initOtp: ()=> dispatch(userActions.initOtp()),
        login: (userId, password)=> dispatch(userActions.login(userId, password)),
        loginWithCode:(params)=> dispatch(userActions.loginWithCode(params)),
    })
)(withTranslation()(LandingPage));
