import React from 'react';
import {useTranslation} from "react-i18next";
import {isNil} from 'lodash';
import PlayerIcon from "../icon/players";
import Status3Icon from '../../images/icon/status3.png';
import Status6Icon from '../../images/icon/status6.png';
import Status8Icon from '../../images/icon/status8.png';
import Status9Icon from '../../images/icon/status9.png';
import Status14Icon from '../../images/icon/status14.png';
import Status15Icon from '../../images/icon/status15.png';
import Status16Icon from '../../images/icon/status16.png';
import BackupIcon from '../../images/icon/backup.png';
import PresetIcon from '../../images/icon/icon_preset.png';
import RemoteControlServerLockIcon from '../../images/icon/status_remote_off.png';

/* iconUrl + vwlSource + amsSource + iconBackup + iconError + iconDeviceErrorFlag + iconAlarm + iconProcessContentDownload + iconProcessLog + iconProcessSWDownload + iconMemo + iconPreset */
const DeviceIcons = ({device, onClickMemo}) => {
    const {t} = useTranslation();
    return (
        <>
            <PlayerIcon text={false} deviceType={device.deviceType} deviceTypeVersion={parseInt(device.deviceTypeVersion)}/>
            {
                !isNil(device.vwtId) &&
                <img src={Status3Icon} width="16px" height="16px" className="mr4" alt="Videowall" title="Videowall"/>
            }
            {
                !isNil(device.webcam) && device.webcam &&
                <img src={Status16Icon} width="16px" height="14px" className="mr4" alt={t('COM_LIST_TITLE_AUDIENCE_MEASUREMENT_KR_AUDIENCE')} title="Audience Measurement"/>
            }
            {
                !isNil(device.iconBackup) && device.iconBackup > 0 &&
                <img src={BackupIcon} width="16px" height="16px" className="mr4" style={{cursor: 'ponter'}} alt={t('MIS_TEXT_DEVICE_BACKUPPLAYER_P')} title={t('MIS_TEXT_DEVICE_BACKUPPLAYER_P')} />
            }
            {
                !isNil(device.iconErrorHw) && device.iconErrorHw > 0 &&
                <img src={Status6Icon} className="mr4" alt={t('COM_TEXT_TITLE_ERROR_P')} title={t('COM_TEXT_TITLE_ERROR_P')} />
            }
            {
                !isNil(device.errorFlag) && parseInt(device.errorFlag) > 0 &&
                <img src={Status6Icon} className="mr4" alt={t('MESSAGE_STATISTICS_PAGE_SUB_TITLE_ERROR_P')} title={t('MESSAGE_STATISTICS_PAGE_SUB_TITLE_ERROR_P')} />
            }
            {
                !isNil(device.iconProcessContentDownload) && device.iconProcessContentDownload > 0 &&
                <img src={Status8Icon} className="mr4" alt={t('MIS_SID_20_DOWNLOADING_CONTENT')} title={t('MIS_SID_20_DOWNLOADING_CONTENT')} />
            }
            {
                !isNil(device.iconProcessLog) && device.iconProcessLog > 0 &&
                <img src={Status15Icon} className="mr4" alt={t('MIS_SID_20_COLLECTING_LOGS')} title={t('MIS_SID_20_COLLECTING_LOGS')} />
            }
            {
                !isNil(device.iconProcessSwDownload) && device.iconProcessSwDownload > 0 &&
                <img src={Status9Icon} className="mr4" alt={t('MIS_SID_20_UPDATING_SOFTWARE')} title={t('MIS_SID_20_UPDATING_SOFTWARE')} />
            }
            {
                !isNil(device.iconMemo) && device.iconMemo > 0 &&
                <img src={Status14Icon} width="16px" height="16px" className="mr4" style={{cursor: 'pointer'}} alt={t('COM_IDS_MEMO')} title={t('COM_IDS_MEMO')} onClick={() => onClickMemo(device.deviceId)}/>
            }
            {
                !isNil(device.preconfig) && device.preconfig.length > 5 && device.preconfigVersion !== "-" &&
                <img src={PresetIcon} width="16px" height="16px" className="mr4" alt={t('MIS_SID_SERVER_PRESET')} title={t('MIS_SID_SERVER_PRESET')} />
            }
            {
                device.remoteControlServerLock === 1 && 
                <img src={RemoteControlServerLockIcon} width="16px" height="16px" className="mr4" alt={t('MIS_SID_CBFEB_REMOTE_CONTROL_LOCK')} title={t('MIS_SID_CBFEB_REMOTE_CONTROL_LOCK')} />
            }
        </>
    );
};

export default DeviceIcons;