import React from 'react';
import './ProgressBar.css';

export const Filler = ({percentage, useLabel, fillerColor}) => {

    const leftPosition = (percentage < 5 ? 0 : percentage - 5);

    return (
        <div className="filler" style={{width: `${percentage}%`, background:`${fillerColor}`}}>
            {useLabel &&
                <span style={{left: `${leftPosition}%`}}>{percentage}
                    <span>%</span>
                </span>
             }
        </div>
    )
};

export const ContentScheduleFiller = ({percentage, fillerColor, title, close}) => {
    const leftPosition = (percentage < 5 ? 0 : percentage - 5);

    const onClose = (e) => {
        e.stopPropagation();
        close();
    }
    return (
        <div className="filler" style={{width: `${percentage}%`, background:`${fillerColor}`}}>
            <div className={"contentScheduleProgress"}>
                <div className="progressBarStatus">
                    <div className="programName" style={{color : percentage > 0 ? '#fff':'#8c8c8c'}}>{title}</div>
                    <div className="programStatus"></div>
                </div>
                <div className="progressBarClose">
                    <div className="closeImg" onClick={onClose}></div>
                    <div className="percent" style={{color : percentage > 0 ? '#fff':'#8c8c8c'}}>{percentage}%</div>
                </div>
            </div>
        </div>
    )
}