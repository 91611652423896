import React, {useEffect, useRef, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../../switch/SwitchListNImage";
import SearchBar from "../../../search/SearchBar";
import classNames from "classnames";
import ContentThumbnail from "../../../image/ContentThumbnail";
import Size from "../../../utils/Size";
import {contentService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import PerfectScrollbar from "perfect-scrollbar";
import ContentTypeFilter from "../../../filter/ContentTypeFilter";
import {Loading} from "../../../loading/Loading";
import DateToStr from "../../../utils/DateToStr";
import {STATISTICS_SUPPORT_CONTENT_TYPE} from "../../../../constants";
import {snakeCase} from "lodash";
import SupportedDeviceIcon from "../../../icon/SupportedDeviceIcon";
import {getMixString} from "../../../../language/languageUtils";
import MyGroups from "../../schedule/MyGroups";
import {getErrorMessage} from "../../../../helper/responseHandler";

const ListView = ({contents, selectedId, handleSelect}) => {
    return (
        <table style={{width: "100%"}}>
            <colgroup>
                <col width="87px"/>
                <col width=""/>
            </colgroup>
            <tbody>
            {contents !== undefined &&
            contents.length > 0 &&
            contents.map(item => (
                <tr
                    key={item.contentId}
                    className={classNames({
                        on: selectedId.findIndex(i => i.contentId === item.contentId) !== -1
                    })}
                    onClick={() =>
                        handleSelect(
                            item.contentId,
                            item.contentName,
                            item.thumbFileId,
                            item.totalSize,
                            item.mediaType
                        )
                    }
                >
                    <td className="thum">
                        <div className="thumb_wrap">
                            <div
                                style={{
                                    width: "75px",
                                    height: "42px",
                                    textAlign: "center",
                                    lineHeight: "42px"
                                }}
                            >
                                <ContentThumbnail
                                    id={item.thumbFileId}
                                    width={75}
                                    height={42}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <h4 className="contentName">
                                    <span className="text-overflow" style={{width: "300px"}}>
                                        {item.contentName}
                                    </span>
                        </h4>
                        <ul>
                            <li className="supportedIcon">
                                <SupportedDeviceIcon deviceType={item.deviceType}
                                                     deviceTypeVersion={item.deviceTypeVersion}
                                                     mediaType={item.mediaType}/>
                            </li>
                            <li>
                                        <span>
                                            <Size size={item.totalSize}/>
                                        </span>
                            </li>
                            <li>
                                        <span>
                                            <DateToStr
                                                date={item.lastModifiedDate}
                                                newLine={false}
                                            />
                                        </span>
                            </li>
                        </ul>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

const ImageView = ({contents, selectedId, handleSelect}) => {
    return (
        <div className={"thumbnailView_wrap"}>
            <ul className="clearfix mt20">
                {contents !== undefined &&
                contents.length > 0 &&
                contents.map(item => (
                    <li>
                        <div
                            style={{cursor: "pointer"}}
                            className={classNames("thumbview_box", {
                                on: selectedId.findIndex(i => i.contentId === item.contentId) !== -1
                            })}
                            onClick={() => handleSelect(item.contentId, item.contentName, item.thumbFileId, item.totalSize, item.mediaType)}
                        >
                            <div style={{width: 75, height: 42, textAlign: "center"}}>
                                <ContentThumbnail
                                    id={item.thumbFileId}
                                    width={75}
                                    height={42}
                                />
                            </div>
                            <div className="device_info">
                                <span className="contentName">{item.contentName}</span>
                            </div>
                            <div className="check">
                                <span></span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const Statistics = ({device, selectedContents, multiSelect}) => {
    const {t} = useTranslation();

    const [content, setContent] = useState({
        filter: {
            startIndex: 1,
            pageSize: 35,
            sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
            sortOrder: 'DESC',
            render: false,
            deviceType: device ? device.deviceType : '',
            deviceTypeVersion: device ? device.deviceTypeVersion : '',
            expirationStatus: "VALID",
            contentTypes: STATISTICS_SUPPORT_CONTENT_TYPE
        },
        list: [],
        isFetching: false,
        loading: false,
        count: 0
    });

    const [end, setEnd] = useState(false);
    const [view, setView] = useState("LIST");
    const [active, setActive] = useState('ALL');

    const handleListNImage = () => {
        setView(view === "LIST" ? "IMAGE" : "LIST");
        resetScrollPosition();
    };

    const fetchContents = () => {
        setContent({...content, loading: true});

        contentService.fetchContentFilter(content.filter)
            .then(res => {
                setContent({
                    ...content,
                    list: content.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: content.count + 1,
                    totalCount: res.totalCount,
                    filter: {
                        ...content.filter,
                        startIndex: content.filter.startIndex + res.items.length,
                    }
                })
            }).catch((e) => {
                if(e.errorCode === 408900) {
                    return;
                }

                toastr.error(getErrorMessage(e));
            });
    }


    const handleContentTypes = selected => {
        console.log(selected);
        setContent({
            ...content,
            list: [],
            loading: false,
            isFetching: false,
            count: 0,
            filter: {
                ...content.filter,
                startIndex: 1,
                pages: 0,
                contentTypes: selected.length === 0 ? ["none"] : selected
            }
        });
    };

    const [selected, setSelected] = useState([]);

    if (multiSelect === undefined) {
        multiSelect = true;
    }

    const removeThumb = id => {
        const newContent = selected.filter(i => i.contentId !== id);
        setSelected(newContent);
        selectedContents(newContent);
    };

    const handleSelect = (id, name, thumbFileId, size, type) => {
        const item = {
            contentId: id,
            contentName: name,
            thumbFileId: thumbFileId,
            contentSize: size,
            mediaType: type
        };
        let newSelectedContent = [];
        if (selected.findIndex(i => i.contentId === id) === -1) {
            if (multiSelect) {
                setSelected((prevState) => ([
                    ...prevState,
                    item
                ]));
                newSelectedContent = selected.concat(item);
            } else {
                newSelectedContent = [item];
                setSelected(newSelectedContent);
            }
        } else {
            newSelectedContent = selected.filter(i => i.contentId !== id);
            setSelected((prevState) => prevState.filter(i => i.contentId !== id));
        }
        selectedContents(newSelectedContent);
    };

    const selectAll = () => {
        // DF210902-00634: Select loaded button causing de-selection of contents when content list length = selected length
        // if (selected.length === content.list.length) {
        //     setSelected([]);
        //     selectedContents([]);
        // } else {
        const newItem = content.list.map((item) => {
            return {
                contentId: item.contentId,
                contentName: item.contentName,
                thumbFileId: item.thumbFileId
            }
        });
        setSelected(newItem);
        selectedContents(newItem);
        //}
    };

    const removeAll = () => {
        setSelected([]);
        selectedContents([]);
    }

    const onSearch = value => {

        setContent({
            ...content,
            list: [],
            loading: false,
            isFetching: false,
            count: 0,
            filter: {...content.filter, startIndex: 1, pages: 0, searchText: value}
        });
    };

    const activeMenu = (menu) => {
        if (menu === 'ALL') {
            setContent({
                ...content,
                list: [],
                loading: false,
                isFetching: false,
                count: 0,
                filter: {...content.filter, startIndex: 1, pages: 0, groupId: undefined, groupType: 'ALL', searchText: ''}
            });
        }
        setActive(menu);
    }

    const selectGroup = (groupId) => {
        if (groupId) {
            resetScrollPosition();
            setContent({
                ...content,
                list: [],
                loading: false,
                isFetching: false,
                count: 0,
                filter: {...content.filter, startIndex: 1, pages: 0, groupId: groupId, groupType: 'GROUPED', searchText: ''}
            });
        }
    }

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_content_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    }

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    useEffect(() => {
        if (!initScroll.current) {
            const ps = new PerfectScrollbar('#select_content_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_content_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            perfectScrollbar.current = ps;
            initScroll.current = true;
        } else {
            perfectScrollbar.current.update();
        }
    }, [content.list]);

    useEffect(() => {
        if (content !== undefined && !content.loading && !content.isFetching && content.list !== undefined && content.list.length === 0 && content.count < 1) {
            resetScrollPosition();
            fetchContents();
        }
        if (content !== undefined && !content.loading && content.isFetching) {
            resetScrollPosition();
            fetchContents();
        }
    }, [content]);

    useEffect(() => {
        if (end) {
            if (content.totalCount > content.filter.startIndex) {
                setContent({
                    ...content,
                    filter: {
                        ...content.filter,
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end]);

    useEffect(() => {
        perfectScrollbar && perfectScrollbar.current.update();
    }, [view]);

    const loadContentInfo = useCallback(()=> {
        var contentListCount = 0
        var totalContentCount = 0
        if (content !== undefined){
            if (content.list !== undefined) contentListCount = content.list.length
            if (content.totalCount !== undefined) totalContentCount = content.totalCount;
         }
        
        var areAllContentsLoaded =  contentListCount == totalContentCount;
         return [areAllContentsLoaded, contentListCount, totalContentCount];
    }, [content])
    
    const [areAllContentsLoaded, contentListCount, totalContentCount] = loadContentInfo();
    
        return (
        <div className="">
            <div className="" style={{display: "inline-flex"}}>
                <div className="lnb">
                    <ul>
                        <li className={classNames({'on': active === 'ALL'})} onClick={() => activeMenu('ALL')}>
                            <a href="#" id="allContentListBtn">{t("COM_SID_ALL")}</a>
                        </li>
                        {
                            <li className={classNames({'on': active === 'MY_CONTENT'})}
                                onClick={() => activeMenu('MY_CONTENT')}>
                                <a href="#" id="myContentListBtn">{t("TEXT_TITLE_MY_CONTENT_VIEW_P")}</a>
                                {
                                    active === 'MY_CONTENT' &&
                                    <MyGroups mode={'CONTENT'} selectGroup={selectGroup} mediaTypes={content.filter.contentTypes}/>
                                }
                            </li>
                        }
                    </ul>
                </div>
                <div className="schedule_content_wrap">
                    <div className="mt10 mr10 mb10 ml20" style={{height: 30}}>
                        <div style={{float: "left"}}>
                            <span className="space"></span>
                        </div>
                        {multiSelect &&
                        <div style={{float: "left"}}>
                            <span className="space"></span>
                            <div className="select_box">
                                <button
                                    className="base select"
                                    style={{minWidth: "100px"}}
                                    onClick={selectAll}>
                                    <span>{t("MIS_SID_CBAUG_SELECT_LOADED")}</span>
                                </button>
                            </div>
                        </div>
                        }

                        <div style={{float: "right", display: "flex"}}>
                            <ContentTypeFilter
                                contentTypes={STATISTICS_SUPPORT_CONTENT_TYPE}
                                onChange={(selected) => handleContentTypes(selected)}
                            />
                            <SwitchListNImage view={view} onClick={handleListNImage}/>
                            <SearchBar
                                width={120}
                                onClickSearch={onSearch}
                                placeholder={t("TEXT_CONTENT_NAME_P")}
                                keyword={content.filter.searchText}
                            />
                        </div>
                    </div>
                    <div
                        className="pop_list"
                        id={"select_content_scroll_wrap"}
                        style={{
                            height: "365px",
                            borderTop: "1px solid #e7e7e7",
                            display: "block",
                            position: "absolute",
                            width: "100%"
                        }}
                    >
                        {
                            content.loading &&
                            <div style={{display: 'flex', justifyContent: 'center'}}><Loading/></div>

                        }
                        {view === "LIST" && (
                            <ListView
                                contents={content.list}
                                selectedId={selected}
                                handleSelect={handleSelect}
                            />
                        )}
                        {view === "IMAGE" && (
                            <ImageView
                                contents={content.list}
                                selectedId={selected}
                                handleSelect={handleSelect}
                            />
                        )}
                    </div>
                </div>
                <div className="selected_content_wrap">
                    <div className="mt10 mr10 mb10 ml20 clearfix">
                        <div className="float_l">
                            <div className="select_box float_l mr8">
                                <button
                                    className="base select"
                                    id="removeAllBtn"
                                    style={{minwidth: "140px"}}
                                    onClick={removeAll}
                                >
                                    <span>{t("BUTTON_ALL_DESELECT_P")}</span>
                                </button>
                            </div>
                            <div className="select_box float_l mr8" style={{paddingTop: "5px"}}>
                                <span>{getMixString(["MIS_SID_WEBAUTHOR_MIX_SELECTED_ITEMS", selected.length])}</span>
                                <span id="statisticsSelectedCount"></span>
                            </div>
                        </div>
                    </div>
                    <div className="thumbnailview_wrap">
                        <div className="pop_list" style={{height: "356px"}}>
                            <ul className="clearfix mt20" id="statisticsSelectedContentsList">
                                {selected.map((v, i) => (
                                    <li>
                                        <div>
                                            <button
                                                className="statisticsDelete"
                                                onClick={() => removeThumb(v.contentId)}
                                            ></button>
                                            <input
                                                type="hidden"
                                                className="contentName"
                                                value={v.contentName}
                                                key={i}
                                            />
                                            <div
                                                style={{
                                                    width: "75px",
                                                    height: "61px",
                                                    textAlign: "center",
                                                    lineHeight: "42px"
                                                }}
                                            >
                                                <ContentThumbnail
                                                    id={v.thumbFileId}
                                                    width={75}
                                                    height={42}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="" style={{display: "inline-flex"}}>
               
                <div style={{
                        marginLeft: "185px",
                        lineHeight: "25px",
                        fontSize: "12px",
                        visibility: areAllContentsLoaded ? "hidden" : "visible",
                        color: "red"
                    }}>{t("MIS_SID_CBAUG_SCROLL_TO_THE_END_TO_LOAD_MORE")}</div>
                
                <div style={{
                        marginLeft: "240px",
                        lineHeight: "25px",
                        fontSize: "12px",
                        color: "dodgerblue"
                    }}><b>{t("MIS_SID_CBAUG_LOADED")}</b> {`${contentListCount} / ${totalContentCount}`}</div>
            </div>
        </div>
    );
};
