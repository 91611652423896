import React from 'react';
import { EditBasicSecurity, EditAdvancedSecurity } from '../edit/EditSecurity';

const EditPresetSecurity = (props) => {
    return (
        <div className='device_edit_panel_wrap mt28'>
            <EditBasicSecurity {...props} />
            <EditAdvancedSecurity {...props} />
        </div>
    );
};

export default EditPresetSecurity;