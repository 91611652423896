import React, {Component, Fragment} from 'react';
import MISDialog from '../MISDialog';
import {withTranslation} from "react-i18next";
import "./AddLDAPPopup.css"
import {settingService, userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import TextInput from "../../input/TextInput";
import Select from "../../selectbox/Select";
import CircleButton from "../../button/CircleButton";
import {commonConstants} from "../../../constants";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import {cloneDeep} from "lodash";
import Checkbox from "../../checkbox/Checkbox";
import {getNotificationSIDByType} from "../../../helper/user/roleUtils";
import {getErrorMessage} from "../../../helper/responseHandler";

class AddLDAPPopup extends Component {
    state = {
        roles: [],
        group: {},
        ldapOrganizationName: "",
        selectedOptionRoleIndex: -1,
        deviceHealthNotifications: [],
        normalNotifications: [],
        isAlarmMailingServiceEnable: false,
        isContentsApprovalEnable: false,
        isDevicePermissionsEnable: false,
        isAlarmMailingServiceDetailOpen: false,
        isContentsApprovalDetailOpen: false,
        isDevicePermissionsDetailOpen: false,
        isContentsApprovalChecked: false,
        permissionDeviceGroups: [],
    };

    componentDidMount() {
        this.fetchGetRoleList();
    }

    onClickSave = () => {
        const {t} = this.props;
        const {group, ldapOrganizationName, selectedOptionRoleIndex} = this.state;
        if (!group.groupId) {
            toastr.error(t('MESSAGE_USER_SEL_GROUP_P'));
            return;
        }
        if (!ldapOrganizationName && !ldapOrganizationName.length > 0) {
            toastr.error(t('MIS_SID_20_PLEASE_SELECT_AN_LDAP_ORGANIZATION'));
            return;
        }
        if (selectedOptionRoleIndex === -1) {
            toastr.error(t('MESSAGE_USER_SEL_ROLE_P'));
            return;
        }
        this.fetchCreateLDAPOrganizations();
    };

    onChangeRole = (value) => {
        if(this.state.selectedOptionRoleIndex !== value) {
            this.setState({
                selectedOptionRoleIndex: value,
                isContentsApprovalDetailOpen: false,
                isDevicePermissionsDetailOpen: false,
                isContentsApprovalChecked: false,
                permissionDeviceGroups: [],
            });
        }
    };

    onClickSearchGroup = () => {
        const {addPopup, closePopup} = this.props;
        addPopup({
            id: commonConstants.LDAP_USER_GROUP_SELECTION,
            type: commonConstants.LDAP_USER_GROUP_SELECTION,
            onSave: (groups) => {
                this.onSelectedGroup(groups);
            },
            onClose: () => closePopup(commonConstants.LDAP_USER_GROUP_SELECTION),
        });
    };

    onSelectedGroup = (groups) => {
        const {closePopup} = this.props;
        const {group} = this.state;
        if (groups && groups.length === 1 && groups[0].groupId !== group.groupId) {
            const {group} = this.state;
            if (group.groupId !== groups[0].groupId) {
                this.setState({
                    ldapOrganizationName: "",
                }, () => {
                    this.fetchCheckExistSyncGroup(groups[0]);
                });
            }
        } else {
            closePopup(commonConstants.LDAP_USER_GROUP_SELECTION);
        }
    };

    onClickSearchLdapOrganization = () => {
        const {t, addPopup, closePopup} = this.props;
        const {group, ldapOrganizationName} = this.state;
        if (!group.groupId) {
            toastr.error(t('MESSAGE_USER_SEL_GROUP_P'));
            return;
        }
        addPopup({
            id: commonConstants.LDAP_ORGANIZATION_SELECTION,
            type: commonConstants.LDAP_ORGANIZATION_SELECTION,
            groupId: group.groupId,
            ldapOrganization: ldapOrganizationName,
            onSave: (organization) => {
                this.onSelectedLdapOrganization(organization);
            },
            onClose: () => closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION),
        });
    };

    onSelectedLdapOrganization = (organization) => {
        const {closePopup} = this.props;
        const {ldapOrganizationName} = this.state;
        if (organization && organization.data && ldapOrganizationName !== organization.data) {
            this.fetchCheckExistSyncLDAPOrganization(organization.data);
        } else {
            closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION);
        }
    };

    onClickDeviceHealthCheck = (checked, index) => {
        this.state.deviceHealthNotifications[index].isEnable = checked;
        this.setState({
            deviceHealthNotifications: this.state.deviceHealthNotifications,
        });
    };

    onClickNormalCheck = (checked, index) => {
        this.state.normalNotifications[index].isEnable = checked;
        this.setState({
            normalNotifications: this.state.normalNotifications,
        });
    };

    onChangeContentApproval = e => {
        this.setState({
            isContentsApprovalChecked: e.target.checked,
        })
    };

    onClickSearchDevicePermission = () => {
        const {addPopup, closePopup} = this.props;
        addPopup({
            id: commonConstants.LDAP_DEVICE_GROUP_SELECTION,
            type: commonConstants.LDAP_DEVICE_GROUP_SELECTION,
            groupId: this.state.group.groupId,
            defaultDevices: [],
            onSave: (devices) => {
                this.onSelectedDeviceGroup(devices);
            },
            onClose: () => closePopup(commonConstants.LDAP_DEVICE_GROUP_SELECTION),
        });
    };

    onSelectedDeviceGroup = (devices) => {
        const {closePopup} = this.props;
        closePopup(commonConstants.LDAP_DEVICE_GROUP_SELECTION);
        this.setState({
            permissionDeviceGroups: devices.length > 0 ? devices.map((device) => {
                return {
                    deviceGroupId: device.groupId,
                    deviceGroupName: device.groupName,
                }
            }) : [],
        });
    };

    onClickDeleteDeviceGroup = (index) => {
        this.state.permissionDeviceGroups.splice(index, 1);
        this.setState({
            permissionDeviceGroups: this.state.permissionDeviceGroups,
        });
    };

    fetchGetRoleList = () => {
        const {t} = this.props;
        userService.fetchUserRoles({}).then(res => {
            let roles = [];
            res.items.forEach((role, index) => {
                roles.push({value: index, id: role.roleId, title: role.roleName,});
            });
            this.setState({
                roles: roles,
            })
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchCreateLDAPOrganizations = () => {
        const {t, onSave} = this.props;
        const {group, ldapOrganizationName, selectedOptionRoleIndex, roles, deviceHealthNotifications, normalNotifications, isContentsApprovalChecked, permissionDeviceGroups} = this.state;
        let enableNotifications = [];
        deviceHealthNotifications.forEach((notification) => {
            if (notification.isEnable) {
                enableNotifications.push(notification.type);
            }
        });
        normalNotifications.forEach((notification) => {
            if (notification.isEnable) {
                enableNotifications.push(notification.type);
            }
        });
        userService.createLDAPOrganizations({
            groupId: group.groupId,
            remoteLdapOrganization: ldapOrganizationName,
            roleName: roles[selectedOptionRoleIndex].title,
            notificationSetting: enableNotifications,
            isContentApporver: isContentsApprovalChecked ? 'Y' : 'N',
            deviceGroupIds: permissionDeviceGroups.map(permittedDeviceGroup => permittedDeviceGroup.deviceGroupId),
        }).then(res => {
            toastr.success(t('TEXT_SUCCESS_P'));
            onSave();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchCheckExistSyncGroup = (group) => {
        const {t, closePopup} = this.props;
        userService.fetchCheckExistSyncGroup(group.groupId).then((res) => {
            if (res.items.result !== false) {
                toastr.error(t('MIS_SID_20_THIS_USER_GROUP_IS_ALREADY_SYNCHRONIZING'));
                return;
            }
            userService.fetchGetServerSetUp(group.groupId).then(res => {
                if(res.items.ldapEnable == false) {
                    toastr.error(t('MIS_SID_20_THE_SELECTED_GROUP_DOES_NOT_USE_LDAP'));
                    return;
                }
                if(res.items.ldapSyncEnable == false) {
                    toastr.error(t('MIS_SID_LDAP_SYNC_GROUP_NOT'));
                    return;
                }
                closePopup(commonConstants.LDAP_USER_GROUP_SELECTION);
                this.setState({
                    group: cloneDeep(group),
                    selectedOptionRoleIndex: -1,
                    deviceHealthNotifications: [],
                    normalNotifications: [],
                    isAlarmMailingServiceEnable: false,
                    isContentsApprovalEnable: false,
                    isDevicePermissionsEnable: false,
                    isAlarmMailingServiceDetailOpen: false,
                    isContentsApprovalDetailOpen: false,
                    isDevicePermissionsDetailOpen: false,
                    isContentsApprovalChecked: false,
                    permissionDeviceGroups: [],
                });
                this.setState({
                    isAlarmMailingServiceEnable: res.items.alarmEnable,
                    isContentsApprovalEnable: res.items.contentsApprovalEnable,
                    isDevicePermissionsEnable: res.items.hasDevicePermissions,
                });
                if (res.items.alarmEnable) {
                    Promise.all([
                        settingService.fetchNotificationTypes(group.groupId, 'device-status'),
                        settingService.fetchNotificationTypes(group.groupId, 'etc'),
                    ]).then((res) => {
                        const deviceStatus = res[0].items;
                        const etc = res[1].items;
                        let deviceHealthNotifications = [];
                        deviceStatus.forEach((notification) => {
                            let sid = getNotificationSIDByType(notification.type);
                            deviceHealthNotifications.push({...notification, sid: sid, isEnable: false,})
                        });
                        let normalNotifications = [];
                        etc.forEach((notification) => {
                            let sid = getNotificationSIDByType(notification.type);
                            normalNotifications.push({...notification, sid: sid, isEnable: false,})
                        });
                        this.setState({
                            deviceHealthNotifications: deviceHealthNotifications,
                            normalNotifications: normalNotifications,
                        });
                    }).catch(err => {
                        toastr.error(getErrorMessage(err, err.errorMessage));
                        return;
                    });
                }
            }).catch(err => {
                toastr.error(getErrorMessage(err, err.errorMessage));
                return;
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage));
            return;
        });
    };

    fetchCheckExistSyncLDAPOrganization = (ldapGroup) => {
        const {t, closePopup} = this.props;
        userService.fetchCheckExistSyncLDAPOrganization({ldapOrganizationName: ldapGroup}).then((res) => {
            if(res.items.result !== false) {
                toastr.error(t('MIS_SID_20_THIS_LDAP_ORGANIZATION_IS_ALREADY_SYNCHRONIZING'));
                return;
            }
            closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION);
            this.setState({
                ldapOrganizationName: ldapGroup,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage));
            return;
        });
    };

    render() {
        const {t, onClose} = this.props;
        const {group, ldapOrganizationName, selectedOptionRoleIndex, roles,
            isAlarmMailingServiceEnable, isContentsApprovalEnable, isDevicePermissionsEnable,
            isAlarmMailingServiceDetailOpen, isContentsApprovalDetailOpen, isDevicePermissionsDetailOpen} = this.state;
        const isContentsApproval = isContentsApprovalEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Content Manager';
        const isDevicePermissions = isDevicePermissionsEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Device Manager';

        let height = 152;
        isAlarmMailingServiceEnable ? isAlarmMailingServiceDetailOpen ? height += 706 : height += 46 : height = height;
        isContentsApproval ? isContentsApprovalDetailOpen ? height += 106 : height += 46 : height = height;
        isDevicePermissions ? isDevicePermissionsDetailOpen ? height += 132 : height += 46 : height = height;
        return (
            <MISDialog
                dialog={{
                    title: t('MIS_SID_20_ADD_LDAP_ORGANIZATION'),
                    closeOnEscape: true,
                    modal: true,
                    width: 407,
                    height: height,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {id: "OK", title: t("BUTTON_OK_P"), onClick: this.onClickSave},
                        {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: onClose}
                    ]
                }}>
                <div className="ldap_contents">
                    <div className='ldap_contents_name'>
                        <span className='ldap_contents_title'>{t('TABLE_USER_GROUP_P')}</span>
                        <TextInput width={232} height={27} maxLength={25} value={group.groupName} readOnly={true}/>
                        <div style={{width: '8px'}}/>
                        <CircleButton id={"SEARCH_GROUP"} onClick={this.onClickSearchGroup}>
                            <span className={'ldap_contents_search_btn'}/>
                        </CircleButton>
                    </div>
                    <div style={{height: '10px', minHeight: '10px'}}/>
                    <div className='ldap_contents_name'>
                        <span className='ldap_contents_title'>{t('MIS_TEXT_LDAP_ORGAN_P')}</span>
                        <TextInput width={232} height={27} maxLength={25} value={ldapOrganizationName} readOnly={true}/>
                        <div style={{width: '8px'}}/>
                        <CircleButton id={"SEARCH_ORGANIZATION"} onClick={this.onClickSearchLdapOrganization}>
                            <span className={'ldap_contents_search_btn'}/>
                        </CircleButton>
                    </div>
                    <div style={{height: '10px', minHeight: '10px'}}/>
                    <div className='ldap_contents_name'>
                        <span className='ldap_contents_title'>{t('COM_TEXT_ROLE_P')}</span>
                        <Select multiLang={false} width={270} value={selectedOptionRoleIndex} selects={roles} onChange={(e, value) => this.onChangeRole(value)} useEllipsis={true}/>
                    </div>
                    {this.renderContentsApproval()}
                    {this.renderDevicePermission()}
                    {this.renderMailingServer()}
                </div>
                <div style={{color: "red"}}>{t("MIS_SID_CBAUG_USERS_FAIL_MAGICINFO_SERVER_USER_ID_NAMING_CRITERIA")}</div>
            </MISDialog>
        );
    };

    renderContentsApproval = () => {
        const {t} = this.props;
        const {selectedOptionRoleIndex, roles, isContentsApprovalEnable, isContentsApprovalDetailOpen, isContentsApprovalChecked} = this.state;
        const isContentsApproval = isContentsApprovalEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Content Manager';
        if(!isContentsApproval)
            return (null);
        return (
            <Fragment>
                <div style={{height: '20px', minHeight: '20px'}}/>
                <div className='ldap_contents_detail'>
                    <button className="ldap_contents_detail_btn" onClick={() => {this.setState({isContentsApprovalDetailOpen: !isContentsApprovalDetailOpen,});}}>
                        <span className={isContentsApprovalDetailOpen ? "ldap_contents_detail_btn_open" : "ldap_contents_detail_btn_close"}/>
                        {t('MIS_SID_CONTENT_APPROVAL')}
                    </button>
                    {
                        isContentsApprovalDetailOpen &&
                        <div className='ldap_content_approval'>
                            <div>
                                <span className="mr30">{t('MIS_SID_CONTENT_APPROVAL')}</span>
                                <Checkbox id={'ldapCheckContentApproval'} name={t('TEXT_ENABLE2_P')} onChange={this.onChangeContentApproval} checked={isContentsApprovalChecked}/>
                            </div>
                            <div className='ldap_content_approval_error'>{t('MIS_SID_20_ENABLING_CONTENT_NUMBER_USER_GROUP')}</div>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }

    renderDevicePermission = () => {
        const {t} = this.props;
        const {selectedOptionRoleIndex, roles, isDevicePermissionsEnable, isDevicePermissionsDetailOpen, permissionDeviceGroups} = this.state;
        const isDevicePermissions = isDevicePermissionsEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Device Manager';
        if(!isDevicePermissions)
            return (null);
        return (
            <Fragment>
                <div style={{height: '20px', minHeight: '20px'}}/>
                <div className='ldap_contents_detail'>
                    <button className="ldap_contents_detail_btn" onClick={() => {this.setState({isDevicePermissionsDetailOpen: !isDevicePermissionsDetailOpen,});}}>
                        <span className={isDevicePermissionsDetailOpen ? "ldap_contents_detail_btn_open" : "ldap_contents_detail_btn_close"}/>
                        {t('MIS_TEXT_DEVICE_GROUP_PERMISSION_P')}
                    </button>
                    {
                        isDevicePermissionsDetailOpen &&
                        <table className='ldap_device_permission'>
                            <colgroup>
                                <col width="101px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th><span>{t('MIS_TEXT_DEVICE_PERMISSIONS_P')}</span></th>
                                <td>
                                    <div className='ldap_device_permission_device_permission'>
                                        <div className='ldap_device_permission_device_permission_list'>
                                            {permissionDeviceGroups.map((permissionDeviceGroup, index) =>
                                                <div key={index} style={{height: '25px', width: '100%'}}>
                                                    <div style={{float: 'left', paddingTop: '3px'}}>{permissionDeviceGroup.deviceGroupName}</div>
                                                    <div style={{float: 'right'}}>
                                                        <button className='ldap_device_permission_device_permission_row_del_button' onClick={() => {this.onClickDeleteDeviceGroup(index)}}></button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="float_l">
                                        <CircleButton id={"SEARCH_DEVICE_PERMISSION"} onClick={this.onClickSearchDevicePermission}>
                                            <span className={'ldap_contents_search_btn'}/>
                                        </CircleButton>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </Fragment>
        );
    }

    renderMailingServer = () => {
        const {t} = this.props;
        const {deviceHealthNotifications, normalNotifications, isAlarmMailingServiceEnable, isAlarmMailingServiceDetailOpen} = this.state;
        if(!isAlarmMailingServiceEnable)
            return (null);
        return (
            <Fragment>
                <div style={{height: '20px', minHeight: '20px'}}/>
                <div className='ldap_contents_detail'>
                    <button className="ldap_contents_detail_btn" onClick={() => {this.setState({isAlarmMailingServiceDetailOpen: !isAlarmMailingServiceDetailOpen,});}}>
                        <span className={isAlarmMailingServiceDetailOpen ? "ldap_contents_detail_btn_open" : "ldap_contents_detail_btn_close"}/>
                        {t('TEXT_TITLE_ALARM_MAILING_SERVICE_P')}
                    </button>
                    {
                        isAlarmMailingServiceDetailOpen &&
                        <div className='ldap_contents_notification'>
                            <div className='ldap_contents_notification_subject'>{t('COM_SID_DEVICE_HEALTH')}</div>
                            {
                                deviceHealthNotifications.map((notification, index) =>
                                    <div key={index} className='ldap_contents_notification_row'>
                                        <div className='ldap_contents_notification_title' title={notification.sid ? t(notification.sid) : notification.description}>
                                            {notification.sid ? t(notification.sid) : notification.description}
                                        </div>
                                        <Checkbox id={"HealthCheck" + index} classname='ldap_contents_notification_checkbox' name={t('TEXT_ENABLE2_P')} checked={notification.isEnable}
                                                  onChange={() => this.onClickDeviceHealthCheck(!notification.isEnable, index)}/>
                                    </div>
                                )
                            }
                            <div className='ldap_contents_notification_subject'>{t('COM_TV_NOTIFICATIONS_KR_VOICE')}</div>
                            {
                                normalNotifications.map((notification, index) =>
                                    <div key={index} className='ldap_contents_notification_row'>
                                        <div className='ldap_contents_notification_title' title={notification.sid ? t(notification.sid) : notification.description}>
                                            {notification.sid ? t(notification.sid) : notification.description}
                                        </div>
                                        <Checkbox id={"NormalCheck" + index} classname='ldap_contents_notification_checkbox' name={t('TEXT_ENABLE2_P')} checked={notification.isEnable}
                                                  onChange={() => this.onClickNormalCheck(!notification.isEnable, index)}/>
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
            </Fragment>
        );
    }
};

export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(withTranslation()(AddLDAPPopup));