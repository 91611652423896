import React from 'react'
import STRMIcon from '../../../images/icon/file_type_icon_10.png';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const Streaming = ({onlyIcon = false, embedded = false}) => {
    const {t} = useTranslation();
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={STRMIcon}/>
            {
                !onlyIcon && t('TEXT_IS_STREAMING_P')
            }
        </span>
    )
}
export default Streaming;