import React from 'react';
import {Loading} from "./Loading";

export const LoadingBar = () => {
    return (
        <div style={{position: 'fixed', top: '0px', left: '0px', width: '100%', height: '100%', backgroundColor: 'transparent', zIndex: 3000}}>
            <div style={{lineHeight: window.innerHeight + 'px', textAlign: 'center'}}>
                <Loading isLoading={true}/>
            </div>
        </div>
    )
};