import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import {SearchButton} from "../../../button/SearchButton";
import {scheduleService} from "../../../../services";
import {toastr} from 'helper/toastrIntercept';
import {useDispatch} from "react-redux";
import {menuAction, popupAction} from "../../../../actions";
import GroupsPopup from "../../GroupsPopup";
import SelectedDevices from "../../../input/SelectedDevices";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {misInit, useMISOpt} from "../../../misopt";
import {isEmpty} from "lodash";
import {commonConstants} from "../../../../constants";


const SaveEventSchedulePopup = ({mode, onSave, onClose, state}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {misOption} = useMISOpt();

    const [event, setEvent] = useState({
        ...state
    });

    const [eventGroupPopup, setEventGroupPopup] = useState({
        show: false
    });

    const [deviceGroupPopup, setDeviceGroupPopup] = useState({
        show: false
    })

    const setEventName = e => {
        setEvent({...event, eventScheduleName: e.target.value});
    }

    const setEventGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            const {groupId, groupName, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                setEvent({...event, eventGroupId:  groupId, eventGroupName: groupName});
                showEventGroupPopup();
            }
        }
    }

    const getPriority = (deviceType, deviceTypeVersion) => {
        let device = misOption(deviceType, deviceTypeVersion);
        if (device === undefined) {
            const {devices} = misInit;
            device = devices.find(device => device.deviceType === deviceType && device.deviceTypeVersion === deviceTypeVersion);
        }
        return device !== undefined ? device.priority : 999999;
    }



    const showEventGroupPopup = () => {
        setEventGroupPopup({show: !eventGroupPopup.show});
    }

    const showDeviceGroupPopup = () => {
        //setDeviceGroupPopup({show: !deviceGroupPopup.show});
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            allowSelectOrganization: false,
            allEnabled: false,
            checkbox: true,
            checkedKeys : getCheckedDeviceGroupKeys(),
            priority : getPriority('SPLAYER', 2),
            selected : event.deviceGroups,
            allGroup: true,
            save: setDeviceGroup,
            close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }));
    }

    const setDeviceGroup = groups => {
        if (groups !== undefined && groups.length > 0) {
            let deviceGroups = [];
            groups.map(
                (group) => {
                    deviceGroups.push({groupId: group.groupId, groupName: group.groupNameText !== undefined ? group.groupNameText : group.groupName})
                }
            )
            setEvent({...event, deviceGroups: deviceGroups});
            //showDeviceGroupPopup();sss
            dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        } else {
            toastr.error(t('MESSAGE_SCHEDULE_SEL_DEVICE_GROUP_BEFORE_P'));
        }
    }

    const removeDeviceGroup = id => {
        setEvent({...event, deviceGroups: event.deviceGroups.filter(group=>group.groupId !== id )});
    }

    const closeEventManagerTab = () => {
        if (mode === 'new') {
            dispatch(menuAction.removeTab('NEW_EVENT'));
        } else {
            dispatch(menuAction.removeTab('EDIT_EVENT'));
        }
    }

    const validEventSchedule = () => {
        if (event.eventScheduleName === '') {
            toastr.error(t("MESSAGE_SCHEDULE_ENTER_SCH_NAME_P"));
            return false;
        }
        if (event.eventScheduleName.trim() === ''){                                  
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return;
        }
        if (event.eventGroupId === '') {
            toastr.error(t('MESSAGE_COMMON_SELECT_GROUP_P'))
            return false;
        }
        if (event.deviceGroups === undefined || (event.deviceGroups !== undefined && event.deviceGroups.length === 0)) {
            toastr.error(t('MESSAGE_SCHEDULE_SEL_DEVICE_GROUP_BEFORE_P'));
            return false;
        }
        return true;
    }


    const saveMessage = () => {

        const updateGroupCounter = () => {
            dispatch(menuAction.reloadGroup('EVENT_SCHEDULE_BY_GROUP'));
        }

        if (mode === 'copy') {
            onSave(event.messageName, event.groupId, event.deviceGroupIds);
        } else {
            if (validEventSchedule()) {

                event.conditionList.map(
                    condition => {
                        if (condition.endConditionIndex < 1) {
                            condition.duration = Number(condition.durationHour * 3600) + Number(condition.durationMin * 60);
                        }
                    }
                )
                if (mode === 'new') {
                    scheduleService.createEventSchedule(event).then(
                        res => {
                            if (res) {
                                toastr.success(t("TEXT_SUCCESS_P"));
                                // close tab
                                updateGroupCounter();
                                closeEventManagerTab();
                            }
                        }
                    ).catch(
                        e => toastr.error(getErrorMessage(e))
                    ).finally ( ()=>{
                            dispatch(menuAction.updateSubMenuCounter('SCHEDULE'))
                        }
                    )
                } else if (mode === 'edit') {
                    scheduleService.updateEventSchedule(event, event.eventScheduleId).then(
                        res => {
                            if (res) {
                                toastr.success(t("TEXT_SUCCESS_P"));
                                updateGroupCounter();
                                // close tab
                                closeEventManagerTab();
                            }
                        }
                    ).catch(
                        e => toastr.error(getErrorMessage(e))
                    ).finally ( ()=>{
                            dispatch(menuAction.updateSubMenuCounter('SCHEDULE'))
                        }
                    )
                }
            }
        }

    }

    const getCheckedDeviceGroupKeys = () => {
        let selectedGroupIds = [];
        if(!isEmpty(event.deviceGroups)){
            event.deviceGroups.map((group) => {
                selectedGroupIds.push(group.groupId);
            })
        }
        return selectedGroupIds;
    }

    useEffect(()=>{
        if (mode === 'copy') {
            setEvent({message: {}});
        } else if (mode === 'new') {
            scheduleService.fetchEventScheduleDefaultGroup()
                .then(
                    res => {
                        if (res) {
                            const {groupId, groupName} = res.items;
                            setEvent({...event, eventGroupId: groupId, eventGroupName: groupName})
                        }
                    }
                )
        }
    },[]);


    const dialogProps ={title: t("COM_BUTTON_SAVE"), closeOnEscape : true, width :400, height :190 , modal:true, onClose:()=> onClose()};
    return(
        <MISDialog
            dialog={dialogProps}
            buttons={{
                rightButtons: [
                    {id: 'SAVE_SCHEDULE', title: t("COM_BUTTON_SAVE"), onClick: ()=>saveMessage()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> onClose()}]
            }}
        >

            {
                eventGroupPopup.show &&
                <GroupsPopup
                    mode={"event_schedule"}
                    checkbox={false}
                    save={(groups)=>setEventGroup(groups)}
                    close={()=>showEventGroupPopup()}
                />
            }

            {
                deviceGroupPopup.show &&
                <GroupsPopup
                    checkedKeys={getCheckedDeviceGroupKeys()}
                    mode={"device"}
                    checkbox={true}
                    checkWithChildren={true}
                    priority={getPriority('SPLAYER', 2)}
                    save={(groups)=>setDeviceGroup(groups)}
                    close={()=>showDeviceGroupPopup()}
                />
            }

            <div className="detail_view mini_p">
                <table>
                    <colgroup>
                        <col width="101px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr className="va_t">
                        <th><span className="popupTh90">{t("LIST_TITLE_NAME")}</span></th>
                        <td>
                            <input type="text" id="newMessageSavePopupMessageName" maxLength="90" style={{width:218}} value={event.eventScheduleName} onChange={setEventName} />
                        </td>
                    </tr>
                    <tr>
                        <th><span className="popupTh90">{t("COM_TABLE_GROUP_NAME_P")}</span></th>
                        <td>
                            <div className="float_l mr10">
                                <SearchButton onClick={showEventGroupPopup}/>
                            </div>
                            <div className="tag_input type2 float_l" style={{width: 150,background:'none'}}>
                                <input type="text" style={{width:'100%',background:'none'}} readOnly value={event.eventGroupName}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th><span className="popupTh90">{t("MIS_SID_20_PUBLISH_TO")}</span></th>
                        <td>
                            <div className="float_l mr10">
                                <SearchButton onClick={showDeviceGroupPopup}/>
                            </div>
                            <div className="float_l" style={{width:200}}>
                                <SelectedDevices groups={event.deviceGroups} removeGroup={(groupId)=>removeDeviceGroup(groupId)}/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    )
}
export default SaveEventSchedulePopup;
