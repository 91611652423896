import React from 'react'
import SOUNDIcon from '../../../images/icon/file_type_icon_07.png';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const SOUND = ({onlyIcon = false, embedded = false}) => {
    const {t} = useTranslation();
    return(
        <span className={classNames('content_icon',{'embedded': embedded === true})}>
            <img src={SOUNDIcon}/>
            {
                !onlyIcon && t('TEXT_TITLE_SOUND_P')
            }

        </span>
    )
}
export default SOUND;