import React from 'react';
import MISDialog from "../popup/MISDialog";
import {Loading} from "./Loading";
import {commonConstants} from "../../constants";

export const LoadingPopup = ({title, modal = true, isDraggable = false, hasCloseIcon = false, onCloseBtn}) => {

    return (
        <MISDialog
            dialog={{
                title: title ? title : 'Loading',
                id: commonConstants.LOADING_POPUP,
                hasCloseIcon: hasCloseIcon,
                modal: modal,
                width: 100,
                height: 30,
                position: {x: 0, y: -300},
                isDraggable: isDraggable,
                zIndex: 3000,
                closeOnEscape: hasCloseIcon,
                onClose: onCloseBtn,
            }}
        >
            <div style={{display: 'flex', marginLeft: '20px'}}>
                <Loading isLoading={true}/>
            </div>
        </MISDialog>
    )
}