import React from 'react'
import {useTranslation} from "react-i18next";

const RulesetStatusFilterStatus = ({selectedRulesetStatus, rulesetStatusList}) => {
    const {t} = useTranslation();

    return (
        <span id="filter_box_fileFilter">
            { selectedRulesetStatus && rulesetStatusList&& rulesetStatusList.length === selectedRulesetStatus.length ?
                    <span className="filter_status_ruleset_status_filter">
                        <span>{t("COM_SID_ALL")}</span>
                    </span>
                : selectedRulesetStatus.map(item => (
                    <span className="filter_status_ruleset_status_filter">
                        <span>{t(rulesetStatusList.find(ruleset=> ruleset.value ==item).label)}</span>
                    </span>
                ))
            }
        </span>
    )
}

export default RulesetStatusFilterStatus;