import React from 'react';
import {withTranslation} from "react-i18next";
import GroupsPopup from "../GroupsPopup";
import './LDAPUserGroupSelectionPopup.css'

const LDAPUserGroupSelectionPopup = ({onClose, onSave}) => {

    const close = () => {
        onClose();
    }

    const save = (groups) => {
        if(groups && groups.length === 1 && groups[0].groupId && groups[0].groupDepth > 1) {
            onSave(groups);
        }
    }

    return (
        <div className='ldap_user_group_popup_wrap'>
            <GroupsPopup
                mode={"ldapUser"}
                checkbox={false}
                save={(groups)=>save(groups)}
                close={()=>close()}/>
        </div>
    );
};

export default withTranslation()(LDAPUserGroupSelectionPopup);