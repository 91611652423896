import React, {useRef, useState} from 'react';
import DatePicker from "react-datepicker";
import './DateInput.css';
import Moment from 'moment';
import classNames from 'classnames';

const DateInput = ({date, minDate, maxDate, dateFormat, disabled, width, className, onChange, type = undefined, onChangeCalendar, position}) => {

    const datePicker = useRef();
    const [open, setOpen] = useState(false);

    const handleScheduleDate = (date) => {
        if (onChange) {
            onChange(date);
            setOpen(false);
        }
    };

    const getDate = (tempDate = undefined) => {
        if (tempDate === undefined || tempDate === {} || tempDate === null) {
            return new Date();
        }
        if (tempDate instanceof Date) {
            if(tempDate.getFullYear() > 9999 || tempDate.getFullYear() < 0){
                return new Date();
            }
            return tempDate;
        }
        try {
            let year = Moment(tempDate + ' 00:00:00').toDate().getFullYear();
            if(year > 9999 || year < 0) {
                return new Date();
            }
        }catch {
            return new Date();
        }
        return Moment(tempDate+' 00:00:00').toDate();
    };

/*
    const onButtonClick = () => {
        if(disabled !== true){
            if(open){
                setOpen(false);
                datePicker.current.setOpen(false);
                onChangeCalendar && onChangeCalendar(false);
            }else{
                setOpen(true);
                datePicker.current.setOpen(true);
                onChangeCalendar && onChangeCalendar(true);
            }
        }
    };
*/

    const onCalendarOpen = () => {
        setOpen(false);
        onChangeCalendar && onChangeCalendar(true);
    };

    const onCalendarClose = () => {
        setOpen(false);
        onChangeCalendar && onChangeCalendar(false);
    };

    const DatePickerInput = ({ value, onClick }) => (
        <div className={classNames({"input_date_wrap" : type === undefined}, type !== undefined ? type : '')} style={{width}}>
            <input className=""
                   type="text"
                   style={{height: '28px'}}
                   value={value}
                   onMouseDown={onClick}
                   disabled={disabled}/>
            <button type="button" onMouseDown={onClick}/>
        </div>
    );

    return (
        <DatePicker
            dateFormat={dateFormat ? dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd') : "yyyy-MM-dd"}
            selected={date==''? '':getDate(date)}
            minDate={minDate}
            maxDate={maxDate}
            className={className}
            onChange={date => handleScheduleDate(date)}
            onCalendarOpen={() => onCalendarOpen()}
            onCalendarClose={() => onCalendarClose()}
            ref={datePicker}
            disabled={disabled? disabled : undefined}
            customInput={<DatePickerInput />}
            popperPlacement={position}
        />
    )
};

const user = JSON.parse(localStorage.getItem('user'));

DateInput.defaultProps = {
    dateFormat: user && user.dateFormat ? user.dateFormat : 'yyyy-MM-dd',
};

export default DateInput;