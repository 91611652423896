import {settingConstants} from '../constants';

export const settingsAction = {
    loadSettings,
    updateSettings,
    loadMyAccount,
    checkPassword,
    updateMyAccount
}

function loadMyAccount(data, mode) {
    return {
       type:  settingConstants.LOAD_MY_ACCOUNT,
       data,
       mode
    }
}

function checkPassword(data, password) {
    return {
        type:  settingConstants.CHECK_PASSWORD,
        data,
        password
     }
}

function updateMyAccount(data, changed){
    return {
        type:  settingConstants.UPDATE_MY_ACCOUNT,
        data,
        changed
     }
}

function loadSettings(data, myInfo, organInfo){
    return {
        type: settingConstants.LOAD_SERVER_SETTINGS,
        data,
        myInfo,
        organInfo
    }
}

function updateSettings(data, updated, myInfo, organInfo) {
    return {
        type: settingConstants.UPDATE_SERVER_SETTINGS,
        data,
        updated,
        myInfo,
        organInfo
    }
}