import React, {useEffect, useState} from 'react';
import './TooltipIcon.css';
import {isNil, assign} from "lodash";

const TooltipIcon = ({message, style}) => {
    const [data, setData] = useState({
        visibility: false,
    });
    
    const onChangeVisible = (e, visible) => {
        setData({visibility: visible});
    };

    const {visibility} = data;
    const msgStyle = assign({right: 0, bottom: 0}, style);

    return (
        <div style={{display:'inline-block', position:'relative', left:'4px'}}>
            <span className="tooltip_icon" onMouseOver={e => onChangeVisible(e, true)} onMouseLeave={e => onChangeVisible(e, false)} />
            {
                visibility && <div className="tooltip_msg" style={msgStyle}>{message}</div>
            }
        </div>
    )
};

export default TooltipIcon;