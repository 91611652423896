import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import './Frames.css';
import FramePopup from "../popup/schedule/content/FramePopup";
import RenderFrame from "./RenderFrame";
import classNames from 'classnames';
import {useSelector} from "react-redux";
import {useOutsideClick} from "../../helper";
import {toastr} from 'helper/toastrIntercept';

const Frames = ({resolution = {width: 1920, height: 1080}, currentFrame, currentChannel, selectFrame, mode, deviceType, deviceTypeVersion}) => {

    const {t} = useTranslation();

    const {program} = useSelector(state => ({
        program : state.schedules.contents[mode].program,
    }));

    const outSide = useRef();

    useOutsideClick(outSide, ()=>{
        if (frame.show) {
            setFrame({...frame, show: false})
        }
    });

    const channel = program.channels.find(channel => channel.channelNo === currentChannel);
    const oriFrame = channel.frame;

    const [frame, setFrame] = useState({
        show: false,
        framePopup: false
    })

    const showFrame = () => {
        setFrame({...frame, show: !frame.show});
    }

    const showPopup = () => {
        if ((channel.hasPermissionForFrameProperties !== undefined && !channel.hasPermissionForFrameProperties)
            && (channel.hasPermissionInAuthorityGroups !== undefined && !channel.hasPermissionInAuthorityGroups)) {
            toastr.error(t('ERROR_NO_ROLE'));
            return;
        }
        setFrame({...frame, show: false, framePopup: !frame.framePopup});
    }

    let displayWidth = 212;
    let displayHeight = 115;


    if (resolution.width < resolution.height) {
        displayWidth = 130;	displayHeight = 212;
    }

    const RenderFrame = ({x=0, y=0, width=0, height=0, frameType, frameIndex, frameId}) => {

        if (oriFrame.frames === undefined || oriFrame.frames.length === 0) {
            return (
                <div className={classNames("frameBtn", {'on' : frameId === currentFrame})}
                    style={{position:'absolute',left:0,top:0,width:displayWidth,height:displayHeight,border:'1px solid #c3c3c3'}}
                    onClick={()=>selectFrame(frameId)}/>
            );
        }

        const resolutionW = resolution.width;
        const resolutionH = resolution.height;

        if (frameType === 'FIXED' || frameType === 'CUSTOM_FIXED') {

            const updateX = Math.round(displayWidth * (x * 0.01));
            const updateY = Math.round(displayHeight * (y * 0.01));
            const updateWidth = Math.round(displayWidth * (width * 0.01));
            const updateHeight = Math.round(displayHeight * (height * 0.01));
            return (
                <div className={classNames("frameBtn", {'on' : frameId === currentFrame})}
                     style={{position:'absolute', left:updateX, top:updateY ,width: updateWidth, height:updateHeight, border:'1px solid #c3c3c3'}}
                     onClick={()=>selectFrame(frameId)}>
                </div>
            )
        } else {
            const updateX = Math.floor((x*displayWidth)/resolutionW);
            const updateY = Math.floor((y*displayHeight)/resolutionH);
            const updateWidth = Math.floor((width*displayWidth)/resolutionW);
            const updateHeight = Math.floor((height*displayHeight)/resolutionH);
            return (
                <div className={classNames("frameBtn", {'on' : frameId === currentFrame})}
                     style={{position:'absolute',left:updateX, top:updateY, width:updateWidth, height:updateHeight,  border:'1px solid #c3c3c3'}}
                     onClick={()=>selectFrame(frameId)}></div>
            )

        }
    }

    return (
        <div className={'content_schedule_frame_wrap'}>
            {
                frame.framePopup &&
                    <FramePopup
                        resolution={resolution}
                        close={()=>showPopup()}
                        mode={mode}
                        originalFrame={oriFrame}
                        deviceType={deviceType}
                        deviceTypeVersion={deviceTypeVersion}
                    />
            }
            <button className="base select" id="selectFrameBox" style={{width:150,borderRadius:15,textAlign:'center'}} onClick={showFrame}>
                <span>{t("TEXT_FRAME_P")}<span className="arrow"></span></span>
            </button>
            <div className="option_list" style={{width:240,zIndex:999,display: frame.show === true ? '':'none'}} ref={outSide}>
                <div>
                    <span style={{display: frame.show === true ? '':'none'}}></span>
                    <ul>
                        {
                            oriFrame !== undefined && oriFrame.frames !== undefined && oriFrame.frames.length > 0 ?
                                <li>
                                    <a href={"#"} className={classNames({'on' : oriFrame.frameId === currentFrame})} onClick={()=>selectFrame(oriFrame.frameId)}>{t("TEXT_TITLE_ALL_SCHEDULE_VIEW_P")}</a>
                                </li>
                                :
                                ''
                        }
                        {
                            oriFrame !== undefined && (oriFrame.frames === undefined || oriFrame.frames.length === 0) &&
                                <li style={{display:'flex', justifyContent: 'center'}}>
                                    <div id="scheduleFrameContainer" style={{position:'relative', width: resolution.width > resolution.height ? 212 : 130,height: resolution.width > resolution.height ? 115 : 212,textAlign:'center'}}>
                                        <RenderFrame {...oriFrame} />
                                    </div>
                                </li>

                        }
                        {
                            oriFrame !== undefined && oriFrame.frames !== undefined && oriFrame.frames.length > 0 &&
                                <li style={{display:'flex', justifyContent: 'center'}}>
                                    <div id="scheduleFrameContainer" style={{position:'relative',width: resolution.width > resolution.height ? 212 : 130,height: resolution.width > resolution.height ? 115 : 212,textAlign:'center'}}>
                                        {
                                            oriFrame !== undefined && oriFrame.frames !== undefined && oriFrame.frames.length > 0 &&
                                            oriFrame.frames.map(
                                                (frame, index) => <RenderFrame key={index} {...frame} />
                                            )

                                        }
                                    </div>
                                </li>
                        }
                        <li>
                            <p className="frame_txt"></p>
                            <div style={{cursor:'pointer', textAlign: 'center'}} onClick={showPopup}>
                                {t("MIS_TITLE_FRAME_EDIT_P")}
                            </div>
                            <p></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )


}
export default Frames;