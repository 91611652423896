import React from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import Select from "../../../selectbox/Select";
import WhiteButton from "../../../button/WhiteButton";
import './ChannelPopup.css';
import TextInput from "../../../input/TextInput";

const ChannelPopup = ({channels, close, addChannel, removeChannel, updateChannel, updateChannelName}) => {

    const {t} = useTranslation();

    const dialogProps ={title: t("TEXT_TITLE_EDIT_CHANNEL_P"), closeOnEscape : true, width :300, height :340 , modal:true, position: {x:0, y:340/2 * -1}, onClose: ()=>close()};

    const getChannelSelectItems = () => {
        let items = [];
        for(let i=1; i<100; i++){
            items.push({title: i.toString(), value: i});
        }
        return items;
    }

    return (
        <MISDialog
            dialog={dialogProps}
            buttons={{
                rightButtons: [
                    {id: "CONTENT_SCHEDULE_CHANNEL_OK", title: t("BUTTON_OK_P"), onClick: close}
                ]
            }}
            >
            <div className={"content_schedule_channel_manager"}>
                <div>
                    <WhiteButton className={"mt20 mr8"} name={t("COM_BUTTON_ADD")} onClick={addChannel}/>
                </div>
                <ul className="mr8 mt20" style={{height:295,width:'100%',overflow:'auto'}}>

                    {
                        channels !== undefined && channels.length > 0 &&
                        channels.map(
                            (channel, index)=>
                                <li key={index} style={{display:'flex',width:'100%',lineHeight:'30px'}} className="channelInfo">
                                    <div style={{display: 'flex'}}>
                                        <div className="mr8">
                                            <TextInput maxLength={20} width={135} value={t(channel.channelName)} onChange={(e)=>updateChannelName(e, channel)}/>
                                            {/*<input type="text" maxLength="20" style={{width:135}} value={channel.channelName} readOnly/>*/}
                                        </div>
                                        <Select
                                            value={channel.channelNo}
                                            selects={getChannelSelectItems()}
                                            optionStyle={{marginTop: '-25px'}}
                                            width={70}
                                            multiLang={false}
                                            onChange={(e, value)=>updateChannel(channel.channelNo, value)}
                                        />
                                    </div>
                                    <button className="circle_btn removeChannelBtn ml6" onClick={()=>removeChannel(channel.channelNo)}>
                                        <span></span>
                                    </button>
                                </li>
                        )
                    }

                </ul>
            </div>
        </MISDialog>
    )
}
export default ChannelPopup;