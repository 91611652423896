import React from 'react';
import ContentThumbnail from "../../../image/ContentThumbnail";
import Size from "../../../utils/Size";
import {DEVICE_CUSTOM_FILES_OPTIONS} from "../../../../constants";

const getFilePath = (type, path) => {
    const ind = path.lastIndexOf('.');
    if(type === '04') {
        return path.substring(0, ind) + '.png';
    } else {
        const ext = path.substring(ind);
        if(DEVICE_CUSTOM_FILES_OPTIONS['customLogo'].acceptFilesVideo.indexOf(ext) >= 0) {
            return path.substring(0, ind) + '.png';
        }
        return path;
    }
};

const PresetCustomContent = ({id, thumbnail, fileName, fileSize, type}) => {
        return (
            <tr style={{height: '90px'}}>
                <td>
                    <span></span>
                </td>
                <td style={{paddingLeft: '10px'}}>
                    <span style={{display: 'flex'}}>
                        <ContentThumbnail id={'CUSTOM_LOGO'} width={150} height={84} style={{width: '150px', height: '84px'}}
                                          params={{logoFilePath: getFilePath(type, thumbnail)}} data={thumbnail}/>
                    </span>
                </td>
                <td style={{position: 'absolute'}}>
                    <ul style={{paddingLeft: '10px'}}>
                        <li>
                            <h4 style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                display: 'block',
                                fontSize: '11px',
                                margin: 0,
                                color: '#818181'
                            }}>
                                {fileName}
                            </h4>
                        </li>
                        <li><Size size={fileSize}/></li>
                    </ul>
                </td>
            </tr>
    );
};

export default PresetCustomContent;