import React from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from "../checkbox/Checkbox";
import uuid from "uuid";

const ContentFileSize = ({id, fileSizes, selected, fileSizeCheckAll, toggleFileSizeAll, fileSizeCheckBox, toggleFileSize, toggleCustomSize, customInputBox, fromSize, toSize, changeCustomSize}) => {
    const {t} = useTranslation();

    const isUsingCustom = selected&&(selected.filter(selectedItem=>selectedItem.label=="Custom").length > 0);
    
    return (
        <div className="dev_filter_type_checkbox" id="filter_list_sizeFilter"><h5><span
            className="sizeFilter" style={{background:'#883939'}}></span>{t("ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE")}</h5>
            <div>
                <ul className="type_checklist">
                    <li>
                        <Checkbox id={id+"_contentTab_file_size_all"}
                                  name={t("TEXT_ALL_P")}
                                  ref={fileSizeCheckAll}
                                  onChange={toggleFileSizeAll}
                                  checked={selected&&fileSizes&&selected.filter(selectedItem => selectedItem.label !== "Custom").length >= fileSizes.length}
                                  defaultChecked={true}
                        />
                    </li>
                    {
                        fileSizes && fileSizes.map((size, index) => (
                            <li key={index}>
                                <Checkbox id={id+"_contentTab_file_" + size}
                                          name={size}
                                          ref={ref => fileSizeCheckBox[size] = ref}
                                          onChange={() => toggleFileSize(size)}
                                          checked={selected&&selected.filter(selectedItem => selectedItem.label==size).length > 0}
                                          defaultChecked={true}
                                />
                            </li>
                        ))
                    }
                    <li>
                        <Checkbox id={id+"_contentTab_file_size_custom"} name={t("COM_SID_CUSTOM")} ref={ref => fileSizeCheckBox['Custom'] = ref} onChange={() => toggleCustomSize('Custom')}
                        checked={isUsingCustom}/>
                        <div style={{fontSize:9}}>
                            <input placeholder="0~102400" className="contentSize" type="text" min={0} style={{width:50}} id="fromSize" disabled={!isUsingCustom} value={fromSize} onChange={changeCustomSize}/> - <input placeholder="0~102400" className="contentSize" type="text" id="toSize" min={0} style={{width:50}} disabled={!isUsingCustom} value={toSize} onChange={changeCustomSize}/> MB
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default ContentFileSize;