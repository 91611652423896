import React from 'react';
import './PrivacyPolicyFooter.css';
import isEmpty from "lodash/isEmpty";
import {useTranslation} from 'react-i18next';
import {getLocationSID} from "../../constants";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

const PrivacyPolicyFooter = ({privacyPolicyList, privacyPolicySettings, login}) => {

    const {t} = useTranslation();

    const showPrivacyPolicy = (location, privacyPolicyList) => {

        const windowObject = window.open(backendHost+'/privacypolicy', '', '');
        const select = privacyPolicyList.find(select => select.location === location);

        if(isEmpty(select)){
            return;
        }

        if (select.location === location) {
            let policy = select.policyNotice;

            if (isEmpty(policy)) {
                policy = select.policyNoticeAgree;
            }

            setTimeout(() => {
                windowObject.document.writeln("<link rel='shortcut icon' type='image/x-icon' href=" + backendHost + "'/logo.ico' />");
                windowObject.document.writeln("<title>MagicINFO Privacy Policy</title>");
                windowObject.document.writeln(policy);
                windowObject.document.close();

            }, 1000);

        }
    }

    if(!isEmpty(privacyPolicySettings) && !isEmpty(privacyPolicySettings.privacyPolicyLocation)) {
        return (
            <>
            <a href="#" className="privacy_policy"
               onClick={()=>showPrivacyPolicy(privacyPolicySettings.privacyPolicyLocation, privacyPolicyList)}> {t('COM_TV_SID_PRIVACY_POLICY_FOR_GER')} </a>
                <span>&nbsp;|&nbsp;</span>
            </>
        )
    }else if(!isEmpty(privacyPolicyList) && ((!isEmpty(privacyPolicySettings) && isEmpty(privacyPolicySettings.privacyPolicyLocation)) || !login)) {
        return (
            <>
            {t('COM_TV_SID_PRIVACY_POLICY_FOR_GER')} (
                {
                    privacyPolicyList.map(e => {
                        return (
                            <a href="#" className="privacy_policy"
                               onClick={() => showPrivacyPolicy(e.location, privacyPolicyList)}> {t(getLocationSID(e.location))} </a>
                        );
                    })
                }
                )&nbsp;|&nbsp;
                </>
         )
    }else{
        return(<> </>)
    }
};

PrivacyPolicyFooter.defaultProps = {
    login: false,
    privacyPolicyList : '',
    privacyPolicySettings : ''
};

export default PrivacyPolicyFooter;
