import React, {Component, useState} from "react";
import MISDialog from "../../../components/popup/MISDialog";
import TextInput from "../../../components/input/TextInput";
import Checkbox from "../../../components/checkbox/Checkbox";
import DateInput from "../../../components/input/DateInput";
import './NewNoticePopup.css';
import Moment from "moment";
import {useTranslation, withTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {dashboardService} from "../../../services";
import {getErrorMessage} from "../../../helper/responseHandler";
import {useMISOpt} from "../../../components/misopt";


const NewNoticePopup = ({onClose, onSave}) => {

    const {t} = useTranslation();
    const {misopt: {user: {dateFormat}}} = useMISOpt();

    const [notice, setNotice] = useState({
        selectId: null,
        notice: {},
        mode: "write",
        title: "",
        startDate: Moment(new Date()).format("YYYY-MM-DD"),
        endDate: '2999-12-31',
        important: false,
        always: true,
        content: "",
    })

    const close = () => {
        onClose();
    }

    const save = () => {
        const {title, content, startDate, endDate, important} = notice;
        if (!isValid(title) || !isValid(content) || !isValid(startDate) || !isValid(endDate)) {
            toastr.error(t("MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P"));
            return;
        }
        createNotice({
            noticeTitle: title,
            noticeSubject: content,
            noticeStartDate: startDate,
            noticeEndDate: endDate,
            important: important,
        });
    }

    const onChangeDate = (name, date) => {
        if (name === 'start') {
            const startDate = Moment(date);
            let endDate = Moment(notice.endDate);
            if (endDate < startDate) {
                endDate = Moment(date);
            }
            setNotice({
                ...notice,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            });
        } else if (name === 'end') {
            const endDate = Moment(date);
            let startDate = Moment(notice.startDate);
            if (endDate < startDate) {
                startDate = Moment(date);
            }
            setNotice({
                ...notice,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            });
        }
    }

    const onTitleInputChanged = (value) => {
        setNotice({...notice, title: value});
    }

    const onContentInputChanged = (value) => {
        setNotice({...notice, content: value});
    }

    const onImportCheckChanged = () => {
        setNotice({...notice, important: !notice.important});
    }

    const onAlwaysCheckChanged = () => {
        let always = !notice.always;
        let endDate = always ? '2999-12-31' : Moment(new Date()).format('YYYY-MM-DD');
        let startDate = notice.startDate;
        if(!always) {
            if (Moment(startDate) > Moment(endDate)) {
                startDate = Moment(new Date()).format('YYYY-MM-DD');
            }
        }
        setNotice({
            ...notice,
            always: always,
            startDate: startDate,
            endDate: endDate,
        })
    }

    const isValid = (value) => {
        if (!value || value.trim().length <= 0) {
            return false;
        }
        return true;
    }

    const createNotice = (data) => {
        dashboardService.createNotice(data).then(res => {
            toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_ADD_P"));
            onClose();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(
            ()=> onSave()
        )
    };

    const {title, startDate, endDate, important, always, content} = notice;

    return (
        <MISDialog
            dialog={{title: t("COM_DID_MAIN_NOTICE"), closeOnEscape: true, width: 650, height: 442, modal: true, onClose: close}}
            buttons={{
                rightButtons: [
                    {id: "SAVE", title: t("COM_BUTTON_SAVE"), onClick: save},
                    {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: close}
                ]
            }}>
            <div className='new-notice-popup'>
                <div className='new-notice-popup-title'>
                    <div className='new-notice-popup-title title'>{t('COM_SID_TITLE')}</div>
                    <TextInput width={410} value={title} maxLength={50} onChange={(e) => onTitleInputChanged(e.target.value)}/>
                    <div style={{width: '10px'}}/>
                    <Checkbox id={'new-notice-important'} name={t("TEXT_IMPORTANT_P")} checked={important} onChange={onImportCheckChanged}/>
                </div>
                <div className='new-notice-popup-period'>
                    <div className='new-notice-popup-period title'>{t('TEXT_NOTICE_PERIOD_P')}</div>
                    <DateInput className='new-notice-popup-period date' width={130} dateFormat={dateFormat} date={startDate} maxDate={new Date(endDate)} onChange={(date) => onChangeDate('start', date)}/>
                    <div style={{width: '10px'}}/>
                    ~
                    <div style={{width: '10px'}}/>
                    <DateInput className='new-notice-popup-period date' width={130} dateFormat={dateFormat} date={endDate} minDate={new Date()} disabled={always} onChange={(date) => onChangeDate('end', date)}/>
                    <div style={{width: '40px'}}/>
                    <Checkbox id={'new-notice-always'} name={t("TEXT_ALWAYS_P")} checked={always} onChange={onAlwaysCheckChanged}/>
                </div>
                <div className='new-notice-popup-content'>
                    <div className='new-notice-popup-content title'>{t('DID_ADMIN_NOTICE_CONTENT')}</div>
                    <textarea style={{width: '524px', height: '352px'}} maxLength="1000" value={content} onChange={(e) => onContentInputChanged(e.target.value)}/>
                </div>
            </div>
        </MISDialog>
    );

}
export default NewNoticePopup;