import React, { Component } from 'react';
import { connect } from 'react-redux';
import RuleNode from "./RuleNode";
import LineTo, { SteppedLineTo, Line } from '../react-lineto/index.jsx'
import './RuleNodeEntry.css';
import { rulesetAction } from '../../actions';

class RuleNodeEntry extends Component {

    constructor(props) {
        super(props);

        this.lineInfoList = [];
    }

    generateLineInfo() {
        this.lineInfoList.length = 0;
        const highlightPath = this.props.highlightPath;
        const fixedHighlightPath = this.props.fixedHighlightPath;

        if(this.elem.children && this.elem.children.length > 0) {
            const highlightIncludesIndex = highlightPath.path.indexOf(this.elem.entryIdIndex);
            const fixedHighlightIncludesIndex = fixedHighlightPath.path.indexOf(this.elem.entryIdIndex);

            for(let i = 0; i < this.elem.children.length; i++) {
                if(this.ruleId === highlightPath.ruleId
                    && highlightIncludesIndex >= 0
                    && highlightPath.path[highlightIncludesIndex + 1] === this.elem.children[i].entryIdIndex) {
                    
                    this.lineInfoList.push({
                        to: "ruletree-label-" + this.ruleId + "-" + this.elem.children[i].entryIdIndex,
                        from: "ruletree-label-" + this.ruleId + "-" + this.elem.entryIdIndex,
                        borderColor: "#00b0f0",
                        borderStyle: "solid",
                        borderWidth: 4,
                        arrowSize: 7,
                        zIndex: 3
                    });
                }

                else if(this.ruleId === fixedHighlightPath.ruleId
                    && fixedHighlightIncludesIndex >= 0
                    && fixedHighlightPath.path[fixedHighlightIncludesIndex + 1] === this.elem.children[i].entryIdIndex) {

                    this.lineInfoList.push({
                        to: "ruletree-label-" + this.ruleId + "-" + this.elem.children[i].entryIdIndex,
                        from: "ruletree-label-" + this.ruleId + "-" + this.elem.entryIdIndex,
                        borderColor: "#0070c0",
                        borderStyle: "solid",
                        borderWidth: 4,
                        arrowSize: 7,
                        zIndex: 3
                    });
                }

                else {
                    this.lineInfoList.push({
                        to: "ruletree-label-" + this.ruleId + "-" + this.elem.children[i].entryIdIndex,
                        from: "ruletree-label-" + this.ruleId + "-" + this.elem.entryIdIndex,
                        borderColor: "#b7b7b7",
                        borderStyle: "solid",
                        borderWidth: 1,
                        zIndex: 2
                    });
                }
            }
        }

        else if(this.elem.type === 'addOrButton') {
            let parentEntryIdIndex = this.elem.parentId.split("_")[0];
            this.lineInfoList.push({
                to: "ruletree-label-" + this.ruleId + "-" + this.elem.entryIdIndex,
                from: "ruletree-label-" + this.ruleId + "-" + parentEntryIdIndex,
                borderColor: "#b7b7b7",
                borderStyle: "dashed",
                borderWidth: 1,
                zIndex: 1
            });
        }
    }

    render() {
        this.ruleId = this.props.ruleId;
        this.elem = this.props.elem;
        this.parentElem = this.props.parentElem;

        const branch = this.props.branch;
        this.generateLineInfo();

        let ruleNodeProps = {
            elem: this.elem,
            parentElem: this.parentElem,
            ruleId: this.ruleId
        };

        if(this.props.rule) {
            ruleNodeProps.rule = this.props.rule;
        }
        
        return(
            <div
                className="ruletree-entry"
                data-entry-id={this.elem.entryIdIndex} >
                
                <RuleNode {...ruleNodeProps} />
                { this.lineInfoList.map((value, index) => {
                    return <SteppedLineTo
                                from={value.from}
                                to={value.to}
                                within={"rulesetTree_" + this.ruleId}
                                fromAnchor="center right"
                                toAnchor="center left"
                                orientation="h"
                                borderWidth={value.borderWidth}
                                borderColor={value.borderColor}
                                borderStyle={value.borderStyle}
                                zIndex={value.zIndex}
                                arrow="right"
                                arrowSize={value.arrowSize}
                                key={value.from + "-" + value.to} />
                }) }
                {branch}
            </div>
        )
    }
}

export default connect(
    state => ({
        menu: state.menu,
        highlightPath: state.rulesets.highlightPath,
        fixedHighlightPath: state.rulesets.fixedHighlightPath
    }),
    dispatch => ({
    })
)(RuleNodeEntry);